import React,{useState,useEffect} from "react";
import style from "./signup.module.scss";
// import LogotextImage from "../../assets/fire_crowd.svg";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import BackgroundDown from "../../assets/login_logo.png";
import righticon from "../../assets/right-img-circle.svg";
import cx from "classnames";
import {resetActivationLink} from "../../api/api-services"
import Alert from "@material-ui/lab/Alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const REG_SUCC_LEFT_HEADING="Well Done";
const REG_SUCC_LEFT_MESSAGE_TEXT_ONE="Your Registered now,just quickly verify your email";
const REG_SUCC_LEFT_MESSAGE_TEXT_TWO="and you all set to use your account";

const REG_SUCC_RIGHT_HEADING="Registration Successful";
const REG_SUCC_RIGHT_MESSAGE_TEXT="To activate your account check your email and get activated.";



const RegistrationSuccess = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState({ open: false, message: "" });
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  useEffect(() => {
    const search = window.location.search;
    const email = new URLSearchParams(search).get("email");
    setEmail(email);
    
  }, []);
  function resentActivation(values) {
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);
      const data = {
        emailId: email,
        source: "FireCrowd"
      };
      resetActivationLink(data)
        .then((res) => {
          if (res.data && res.data.responseObject) {
            setTimeout(() => {
              setCreateUpdateCalled(true);
            }, 10000);
            setSuccess({ open: true });
          }

          setTimeout(() => {
            setSuccess({ open: false });
          }, 5000);
        })
        .catch((error) => {
          console.error("resend activation link error", error);
        });
    }
  }
  return (
    <div className="grid grid-cols-12 min-h-screen ">
    <div  className={cx(style["success_activation_link"],"col-span-6 border-r-4")}>
      <div className={cx(style["success_activation_link_leftImage"],"grid grid-cols-span-12 col-span-6 flex justify-center")}
      id="leftImage">
        <div className={cx(style["leftsideLogo"])} >
        <img
         className={cx(style["logo_icon"])}
        
          src={LogotextImage}
          height="200px"
          width="300px"
          alt="Qspider"
        />
        </div>
      </div>
      <div className="grid grid-cols-span-12 col-span-6 flex justify-center">
      <div>
        <img
            height="350px"
            width="350px"
          src={BackgroundDown}
          alt="Firecrowd"
        />
      </div>

      </div>
      <div className={cx(style["heading"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-5")}>
       <div>{REG_SUCC_LEFT_HEADING}</div>
      </div>
      <div className={cx(style["msg"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-2")}>
       <span>{REG_SUCC_LEFT_MESSAGE_TEXT_ONE}</span>
      </div>
      <div className={cx(style["msg"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-1")}>
       <span>{REG_SUCC_LEFT_MESSAGE_TEXT_TWO}</span>
      </div>

      
        
    </div>
    <div className={cx(style["success_activation_page"],"col-span-6 pt-28")}>
    <div className={cx(style["logoimg"])}>
        <img
          src={LogotextImage}
          height="200px"
          width="300px"
          alt="Qspider"
        />
        </div>
    <div className={cx(style["activation_text"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-4")}>
    {success.open && (
        <Alert
         className={cx(style["warningAlert"])}
          iconMapping={{ success: <CheckCircleIcon fontSize="inherit" className={cx(style["alertIconActivationLink"])} /> }}
        >
          <span>
            Activation link sent successfully
          </span>
        </Alert>
      )}{" "}
    </div>
        <div className={cx(style["righticon"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-8")}>
            <div>
            <img alt="warning" src={righticon} />
            </div>
        </div>
        <div className={cx(style["heading"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-4")}>
            <div>{REG_SUCC_RIGHT_HEADING}</div>
        </div>
        <div className={cx(style["msg"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-2")}>
        <span> {REG_SUCC_RIGHT_MESSAGE_TEXT}</span>
        </div>
       
        <div className={cx(style["activation_link_text"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-4")}  onClick={resentActivation}
          onBlur={() => {
            createUpdateCalled(true);
          }}>
        <span>Resend Activation Link</span>
        </div>
        <div className={cx(style["Back_link_text"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-4")}>
        <span>
        <a  href="https://www.testyantra.com/">
        Back to website
          </a>
          </span>
        </div>
    </div>

   </div>
  );
};

export default RegistrationSuccess;
