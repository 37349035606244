import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextareaAutosize, TextField, Select } from "@mui/material";
import { ReactComponent as RejectedIcon } from "../../../assets/rejectedBorderedIcon.svg";
import { ReactComponent as ApprovedIcon } from "../../../assets/approvedBorderedIcon.svg";

import { ReactComponent as ApprovalPendingIcon } from "../../../assets/approvalPending.svg";
import { ReactComponent as NoExecutionIcon } from "../../../assets/noExecutionIcon.svg";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Grid from "@mui/material/Grid";
import FormControl from "@material-ui/core/FormControl";
import RichTextEditor from "../rich-texteditor";
import "./executiondetails.scss";
import { getLabel, getOptionLabel } from "../common-functions";
import { ReactComponent as InReviewIcon } from "../../../assets/inReviewBorderedIcon.svg";

const TesterExecutionList = (props) => {
  console.log("TesterExecutionList", props.dataForApproveFiltered);
  let executionsList = props.testcaseExecutionList;
  let [dataLoading, setDataLoading] = useState();
  let projectType=JSON.parse(localStorage["selected-project"]).project?.projectType?.mobile!==null?"Mobile"
  :JSON.parse(localStorage["selected-project"]).project?.projectType?.web!==null?"Web"
  :JSON.parse(localStorage["selected-project"]).project?.projectType?.webAndMobile!==null?"Web And Mobile"
  :"Others";

  const TemplateData = JSON.parse(
    JSON.stringify(props.testCaseTemplate.manualTestCase)
  );
  const testCaseDetailDescElements = (data) => {
    switch (data.type) {
      case "textField":
        if (data.label === "Status") {
          return (
            <div>
              <InReviewIcon />
            </div>
          );
        } else {
          return (
            <div className="rs-user-input-container">
              <TextField
                onClick={() => {
                  console.log(data?.value);
                }}
                disabled={true}
                InputProps={{ disableUnderline: true }}
                className="lg:w-72"
                title={data.placeholder.length > 30 ? data.placeholder : null}
                placeholder={
                  data.placeholder.length > 30
                    ? data.placeholder.substr(0, 30) + "..."
                    : data.placeholder
                }
                value={data.value}
              />
            </div>
          );
        }

      case "textArea":
        return (
          <>
            <div className="">
              <TextareaAutosize
                disabled={true}
                InputProps={{ disableUnderline: true }}
                title={data.placeholder.length > 30 ? data.placeholder : null}
                id="description"
                name="description"
                maxLength="200"
                maxRows={0}
                className=" bg-transparent block lg:w-72 border-0 border-b-2 rs-input-style-textarea popup-input-bg  -mt-1 pl-px  input-field-color descriptionStyle textarea-resize"
                placeholder={
                  data.placeholder.length > 30
                    ? data.placeholder.substr(0, 30) + "..."
                    : data.placeholder
                }
                value={data.defaultValue}
              />
              {data && data.minLength >= 0 && data.maxLength >= 0 ? (
                <div className="mt-1 text-sm text-gray-500 text-right mr-2">
                  {data.minLength}/{data.maxLength}
                </div>
              ) : data.minLength >= 0 && data.maxLength >= 0 ? (
                <div className="mt-1 text-sm text-gray-500 text-right mr-14">
                  {data.minLength}/{data.maxLength}
                </div>
              ) : null}
            </div>
          </>
        );

      case "radioButton":
        return (
          <>
            <div className="mt-3">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div>
                    <input
                      type="radio"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="details-data-style-common">
                      {getOptionLabel(option)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case "link":
        return (
          <>
            <div className=" flex mt-3">
              <div>
                <InsertLinkOutlinedIcon className="-mt-2 mr-2 origin-center rotate-90 opacity-60" />
              </div>
              <div
                aria-disabled={true}
                className="details-data-style-common opacity-50 w-72"
              >
                {getLabel(
                  "Attached Link will be shown here if any URL is added"
                )}
              </div>
            </div>
          </>
        );

      case "dropdown":
        return (
          <>
            <FormControl variant="standard">
              <Select
                variant="standard"
                disabled={data?.label === "Status" ? false : true}
                displayEmpty
                disableUnderline
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
                renderValue={(selected) => {
                  if (!selected) return data?.placeholder;
                  else return selected;
                }}
                name="selectCustomField"
                id="selectCustomField"
                className="lg:w-72 lg:h-8 sm:w-40 fontPoppinsRegularMd"
              >
                {data.options.map((option, index) => (
                  <option
                    className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 details-data-style-common lg:w-72 sm:w-40 fontPoppinsRegularMd"
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        );

      case "date":
        return (
          <>
            <div className="flex">
              <div className=" h-8 border-2 border-gray-300 w-72"></div>
              <div className=" bg-gray-200">
                <CalendarTodayOutlinedIcon className="mt-1" />
              </div>
            </div>
            {TemplateData?.templateType === "Predefined" ? (
              <div className="details-data-style-common opacity-50">
                [dd-mm-yyyy]
              </div>
            ) : (
              <div className="details-data-style-common opacity-50">
                [{data.dateFormat}]
              </div>
            )}
          </>
        );
      case "checkbox":
        return (
          <>
            <div className="lg:w-72">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div>
                    <input
                      type="checkbox"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="details-data-style-common">
                      {getOptionLabel(option)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case "attachment":
        return (
          <div className="rs-user-input-container">
            <TextField
              InputProps={{ disableUnderline: true }}
              placeholder="Search and select file name"
              disabled={true}
              className="lg:w-72"
            />
          </div>
        );
      case "textEditor":
        return (
          <>
            <RichTextEditor
              convertedContent=""
              readOnly={true}
              toolbarHidden={false}
              className="w-full"
            />
          </>
        );

      default:
        return (
          <>
            <TextField disabled={true} className="w-80" />
          </>
        );
    }
  };

  const testCaseDetailElements = (props) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="labelInputs">
            <h6 className=" custom_labels">{getLabel(props.data.label)}</h6>
          </div>
          <div className="flex">
            <div className="contentDataStyle_custom_fields">
              {testCaseDetailDescElements(props.data)}
            </div>
            <div
              className={
                props.data.type !== "textEditor"
                  ? " flex lg:mr-10"
                  : " flex mt-5"
              }
            >
              {!["Test Case Name", "Requirement Id", "Status"].includes(
                props.data.label
              ) ? (
                <></>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  const testCaseDetailSection = (obj) => {
    let tableRowData = obj.manualTestCase.testSteps.rows;
    let listData = obj.manualTestCase;
    return (
      <>
        <div className=" mt-3 mx-3 my-5 ">
          <div className=" responsiveTestDetails">
            <Accordion defaultExpanded={props?.expand}>
              <div className="flex flex-row ">
                <div className="flex mt-3 w-full">
                  <div
                    style={{ width: "100%" }}
                    className="flex grid grid-cols-12"
                  >
                    <div className="col-span-2">
                      <div className="testcase_lables">OS</div>
                      <div
                        className="testcsae_values mt-1 datatrimmer w-28"
                        title={obj?.os}
                      >
                        {obj?.os}
                      </div>
                    </div>

                    <div className="col-span-2">
                      <div className="testcase_lables">OS Version</div>
                      <div
                        className="testcsae_values mt-1 datatrimmer w-28"
                        title={obj?.osVersion}
                      >
                        {obj?.osVersion}
                      </div>
                    </div>

                    {obj?.deviceName !== undefined && obj.deviceName !== "" ? (
                      <>
                        <div className="col-span-2">
                          <div className="testcase_lables">Device Brand</div>
                          <div
                            className="testcsae_values mt-1 datatrimmer w-28"
                            title={obj?.deviceBrand}
                          >
                            {obj?.deviceBrand}
                          </div>
                        </div>

                        <div className="col-span-2">
                          <div className="testcase_lables">Device Name</div>
                          <div
                            className="testcsae_values mt-1 datatrimmer w-28"
                            title={obj?.deviceName}
                          >
                            {obj?.deviceName}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-span-2">
                          <div className="testcase_lables">Browser</div>
                          <div
                            className="testcsae_values mt-1 datatrimmer w-28"
                            title={obj?.browser}
                          >
                            {projectType==="Others"?"--":obj?.browser}
                          </div>
                        </div>

                        <div className="col-span-2">
                          <div className="testcase_lables">Browser Version</div>
                          <div
                            className="testcsae_values mt-1 datatrimmer w-28"
                            title={obj?.browserVersion}
                          >
                            {projectType==="Others"?"--":obj?.browserVersion}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-span-2">
                      <div className="testcase_lables">Location</div>
                      <div
                        className="testcsae_values mt-1 datatrimmer w-28"
                        title={obj?.city}
                      >
                        {obj?.city}
                      </div>
                    </div>
                    {/* <div className="col-span-2">
                      <div className="testcase_lables">Created On</div>
                      <div
                        className="testcsae_values mt-1 datatrimmer w-28"
                        title={obj?.createdOn}
                      >
                        {obj?.createdOn}
                      </div>
                    </div> */}
                    <div className="col-span-2">
                        <div className="testcase_lables">Status</div>
                        <div
                          className={`testcsae_values mt-1 ${
                            obj?.executionStatus.toUpperCase() === "PASS"
                              ? "text-green-700"
                              : obj?.executionStatus.toUpperCase() === "FAIL"
                              ? "text-red-700"
                              : "text-orange-500"
                          }`}
                        >
                          {obj?.executionStatus}
                        </div>
                      </div>
                  </div>
                </div>

                <div className="h-auto backgroundColor">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="cursor-pointer" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  ></AccordionSummary>
                </div>
              </div>
              <div className="accordionDetails">
                <AccordionDetails className=" h-auto backgroundColor">
                 
                    <div className="grid grid-cols-12">
                  {listData?.testCaseDetails?.slice(0, 7).map((data) =>
                      data?.type === "textEditor" ? (
                        <div className="col-span-11">
                          {testCaseDetailElements({ data })}
                        </div>
                      ) : (
                        <>
                        {
                          data.label!=="Module/Path" && (
                            <div className="col-span-2">
                            {/* {testCaseDetailElements({ data })} */}
                            <div>
                              <div className="testcase_lables">{data.label}</div>
                              <div
                                className="testcsae_values mt-1 datatrimmer w-24"
                                title={
                                  Array.isArray(data.value)
                                    ? data.value[0]
                                    : data.value
                                }
                              >
                            
                                {data.label === "Test Case Name"
                                  ? obj.name
                                  : Array.isArray(data.value)
                                  ? data.value[0] !== undefined &&
                                    data.value[0] !== ""
                                    ? data.value[0]
                                    : "--"
                                  : data.value !== undefined && data.value !== ""
                                  ? data.value
                                  : "--"}
                              </div>
                            </div>
                          </div>

                          )
                        }
                        </>
                        
                       
                      )
                    )}
                     {/* <div className="col-span-2">
                        <div className="testcase_lables">Status</div>
                        <div
                          className={`testcsae_values mt-1 ${
                            obj?.executionStatus.toUpperCase() === "PASS"
                              ? "text-green-700"
                              : obj?.executionStatus.toUpperCase() === "FAIL"
                              ? "text-red-700"
                              : "text-orange-500"
                          }`}
                        >
                          {obj?.executionStatus}
                        </div>
                      </div> */}
                       <div className="col-span-2">
                      <div className="testcase_lables">Created On</div>
                      <div
                        className="testcsae_values mt-1 datatrimmer w-28"
                        title={obj?.createdOn}
                      >
                        {obj?.createdOn}
                      </div>
                    </div>

                  </div>

                  <div
                    className="mt-8"
                    style={{
                      width: "100%",
                      overflow: "auto",
                      maxHeight: "267px",
                      height: "auto",
                      overflowY: "auto",
                    }}
                  >
                    <table className="testStepTable  w-full">
                      <thead
                        style={{
                          display: " table-header-group",
                          verticalAlign: "middle",
                          position: "sticky",
                          top: "0px",
                          zIndex: "1",
                        }}
                        className="bg-gray-100"
                      >
                        <tr key={"tableHeader"}>
                          {listData.testSteps?.headers?.map((data, index) => {
                            return (
                              <>
                                <th
                                  className="p-2 testStepTable common-label-text text-blue-800 fontPoppinsMediumMd"
                                  key={data + index}
                                >
                                  {data}{" "}
                                </th>
                              </>
                              //   )
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          height: "auto",
                          overflowY: "auto",
                          display: " table-row-group",
                          verticalAlign: "middle",
                        }}
                      >
                        {tableRowData?.map((rowData, index) => (
                          <tr
                            style={{
                              display: " table-row",
                              verticalAlign: "inherit",
                            }}
                            className={`h-6 testStepTable ${
                              (index + 1) % 2 === 0
                                ? "test-case-table-even-row"
                                : "test-case-table-odd-row"
                            }`}
                            key={index}
                          >
                            {listData.testSteps?.headers?.map(
                              (cellname, colIndex) => {
                                return (
                                  <>
                                    <td
                                      className={`testStepTable fontPoppinsRegularSm text-left pl-2 table-cell-text focus:border-opacity-0 focus:outline-none ${
                                        cellname === "Status"
                                          ? rowData[cellname].toUpperCase() ===
                                            "PASS"
                                            ? "text-green-700"
                                            : rowData[
                                                cellname
                                              ].toUpperCase() === "FAIL"
                                            ? "text-red-700"
                                            : "text-orange-500"
                                          : ""
                                      }`}
                                      key={index + colIndex}
                                      contentEditable={false}
                                      suppressContentEditableWarning={true}
                                    >
                                      {Array.isArray(rowData[cellname])
                                        ? rowData[cellname].map((data) => (
                                            <>
                                              {data}
                                              <br />
                                            </>
                                          ))
                                        : rowData[cellname]}{" "}
                                    </td>
                                  </>
                                );
                              }
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </AccordionDetails>
              </div>
            </Accordion>
          </div>
        </div>
      </>
    );
  };
  const statusIcons = (state) => {
    switch (state) {
      case "REJECTED":
        return (
          <>
            <RejectedIcon height="22px" width="95px" />
          </>
        );
      case "APPROVED":
        return (
          <>
            <ApprovedIcon height="22px" width="95px" />
          </>
        );
      case "CLOSED":
        return (
          <>
            <ApprovedIcon height="22px" width="95px" />
          </>
        );
      case "INREVIEW":
        return (
          <>
            <InReviewIcon height="25px" width="97px" />
          </>
        );
      default:
        return <>{state}</>;
    }

    // return (
    //   <>
    //     {state === "REJECTED" ? (
    //       <RejectedIcon height="22px" width="95px" />
    //     ) : state === "APPROVED" ? (
    //       <ApprovedIcon height="22px" width="95px" />
    //     ) : state === "CLOSED" ? (
    //       <ApprovedIcon height="22px" width="95px" />
    //     ) : state === "INREVIEW" ? (
    //       <InReviewIcon height="25px" width="97px" />
    //     ) : (
    //       state
    //     )}
    //   </>
    // );
  };

  return (
    <>
      {executionsList?.length > 0 ? (
        executionsList?.map((data, index) => (
          <div className=" overflow-x-hidden">
            <div className=" wrapperForExecution">
              <div className="customAccordian">
                <div className="flex justify-between">
                  <div className="exe_header">
                    {" "}
                    Execution Id - {data.executionId}
                  </div>

                  <div className=" iconGroups  justify-end">
                    <div>{statusIcons(data.status)}</div>
                    {/* {props.dataForApproveFiltered.map((v) => {
                      if (v.id === data.executionId) {
                        return (
                          <>
                            <div>{statusIcons(v.status)}</div>
                          </>
                        );
                      }
                    })} */}
                  </div>
                </div>
                {testCaseDetailSection(data)}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div
          className={`noDefects ${
            props?.showExecution || props.callingAPI ? "hidden" : "block"
          }`}
        >
          <div>
            <NoExecutionIcon />
          </div>
          <div className="mt-3">
            <span className="noExeutionsMsg">No Executions found !</span>
          </div>
        </div>
      )}
    </>
  );
};

export default TesterExecutionList;
