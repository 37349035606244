import { useState, useEffect } from "react";
import React from "react";
import Modal from "react-modal";
import warningIcon from "../../../assets/toast-warning.svg";
import "../../modal/modal.scss";
import { UpdateDefectReq } from "../../../api/api-services";

function ChangeStatusWarning(props) {
  let selectedProject = JSON.parse(localStorage["selected-project"]);
  let selectedUser = JSON.parse(localStorage["selected-user"]);
  let actionApprove = JSON.parse(localStorage.getItem("user-privilege")).defect
    .actions.approve;

  console.log("ChangeStatusWarning",props);
  const [openModal, setOpenModal] = useState(true);

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "45%",
      // height: "33%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      // borderRadius: "4px",
      opacity: "1",
      outline: "none",
      borderTop: "4px solid #FFC600",
    },
  };
  const onEditHandler = async () => {
    let projectId = selectedProject.project.fireFlinkProjectId;
    let licenceID = selectedProject.licenceID;
    let email = selectedUser.email;
    let leadEmail = selectedUser.lead;
    let payload = props.dataForApprove;

    try {
      payload.defectDetails["Assign to"].userEmail = leadEmail;
      let tempHistoryObj = {
        userId: selectedUser.id,
        userEmail: email,
        state: actionApprove,
        userRole: selectedUser.role,
        lead: leadEmail,
        date: new Date(),
      };
      payload.defectDetails.history.push(tempHistoryObj);
      payload.state = actionApprove;
      payload.defectDetails.State = actionApprove;
      let tempViewerObj = {
        userEmail: leadEmail,
        role: getUserRole(leadEmail),
      };
      payload.defectDetails.viewers.push(tempViewerObj);

      let defectid = props.dataForApprove.defectDetails.ID;
      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));
      formData.append("email", email);
      props.CloseWarningStatus(false);
      let response = await UpdateDefectReq(
        formData,
        projectId,
        licenceID,
        email,
        defectid
      );
      if (response.status === 200) {
        props.MyAlert.success("Defect successfully Updated");
        props?.updatetabeldata(response?.data?.defect)
        // props.CloseWarningStatus(false);
        // props.callDefects(0);
      } else {
        props.MyAlert.info("Something went wrong please try again later");
        // props.CloseWarningStatus(false);
      }
    } catch (err) {
      console.log("error");
      // props.CloseWarningStatus(false);
    }
  };
  const getUserRole = (email) => {
    let tempUserArr = selectedProject.project.users.userList;
    let tempRole = "";
    tempUserArr.map((val) => {
      if (val.email === email) {
        tempRole = val.role;
      }
    });
    return tempRole;
  };
  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px">
      <div className="grid grid-cols-12">
        <div className="grid grid-cols-12 col-span-12 warning-popup py-2">
          <div className="col-span-2 warning-icon">
            <div>
              <img alt="warning" src={warningIcon} />
            </div>
          </div>
          <div className="grid grid-cols-12 col-span-10 pl-7 my-2">
            <div className="col-span-12">
              <span className="warning_text">warning!</span>{" "}
              <span className="delete_text">Status Changes </span>
            </div>
            <div className="col-span-12 py-1">
              <span className="message">
                Hi {JSON.parse(localStorage["fire-crowd-user"]).name}
              </span>
            </div>
            <div className="col-span-12">
              <span className="message">
                Do you want to change status of {props?.bugHistoryId} to
                Approved ?
              </span>{" "}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 col-span-12 warning_popup_btn  pt-2">
          <div className="col-span-8"></div>
          <div className="grid grid-cols-12 col-span-4 flex justify-center">
            <div className="grid grid-cols-12 col-span-6 h-8 ">
              <span
                className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer"
                onClick={() => {
                  props.CloseWarningStatus(false);
                }}
              >
                <button>CANCEL</button>
              </span>
            </div>
            <div className="grid grid-cols-12 col-span-6 h-8 ">
              <span
                className="btn_back col-span-10 rounded-sm flex justify-center cursor-pointer"
                onClick={() => {
                  onEditHandler();
                }}
              >
                <button>CONFIRM</button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default ChangeStatusWarning;
