import React, { useState, useEffect, useContext } from "react";
import { useTable, useGlobalFilter } from "react-table";
import { ReactComponent as Search } from "../../../assets/search.svg";
import "./reports.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import NoDefectIcon from "../../../assets/reportsicon/no_reports_icon.png";
import {
  getDefectsByStateReq,
  getDefectsData,
  getUserDetailsByEmailID,
  DownloadDefectExcleFile,
  getTemplateById,
  getDefectsDataCount,
} from "../../../api/api-services";

import DefectsDeatilsPopup from "../../modal/defects-deatils-popup";
import UserDeatilsPopup from "../../modal/user-deatils";
import { CircularLoader } from "../../common/CircularLoader";

function DefectTable(props){
  console.log("DefectTable",props)
    let [selectedTab,setSelectedTab]=useState(true);
    let [tabelData,setTableData]=useState([]);
    let[selectedState,setSelectedState]=useState();
    let [pageNo,setPageNo]=useState(0);
    let [lastPage,setLastPage]=useState(false);
    let [defectsPopUp, setDefectsPopUp] = useState(false);
  let [defectsdetails, setDefectsdetails] = useState();
  let [userDeatils, setusererDeatils] = useState();
  let [userPopup, setUserPopup] = useState(false);
  let [defectTemplatedetails, setDefectTempalteDetails] = useState();
    let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  let Size=10;
  let [dataLoading,setDataLoding]=useState();
  let [downloading,setDownloading]=useState(false)

    
    
    useEffect(()=>{
        setSelectedTab(true);
        getAllDefects(0,"CLOSED")
    },[props?.allowRejectedDefect]);
  

    function SelectedTabData(val){
      setGlobalFilter("")
       console.log("tab",val)
       if(tabelData.length!==0){
        let Ele = document.getElementById("Defect_Card");
        Ele.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
       }
      
        if(val==="Approved"){
          setPageNo(0)
          setLastPage(false)
            setSelectedTab(true)
         setSelectedState("CLOSED") 
         getAllDefects(0,"CLOSED")
        }else{
          setPageNo(0)
          setLastPage(false)
            setSelectedTab(false)
             setSelectedState("REJECTED") 
             
             getAllDefects(0,"REJECTED") 
        }
        
      }
    

      const getAllDefects=async(page,state)=>{
        setSelectedState(state)
        if(page===0){
          setDataLoding(true)
        }
        
        try{
          const response = await getDefectsByStateReq(
            projectId,
            licenseID,
            state,
            page,
            Size
          );
          console.log("getAllDefects",response)
          if(response?.data && response?.data?.status===200){
            let tempArray=[];
            let data=response?.data?.defects;
            data.map((defect)=>{
              tempArray.push({
                bugId: defect?.defectDetails?.ID,
                bugSummary: defect.defectDetails?.Summary,
                createdOn: defect?.defectDetails["Created On"],
                createdBy: defect?.defectDetails["Created By"]?.name,
                Details: defect,
              })
            })
            if(page===0){
              setTableData(tempArray)
              setPageNo(page+1)
            
            }else{
              let tempData=tabelData.concat(tempArray);
              setTableData(tempData);
              setPageNo(page+1)
            }
            setLastPage(response?.data?.lastPage)
          }else{
            setTableData([])
          }

        }catch(error){
          console.log("error")
        }
        setDataLoding(false)
      }
      const handlescroll = (event) => {
        if (
          Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
          event.target.scrollHeight
        ) {
          if(lastPage===false && globalFilter===undefined){
            getAllDefects(pageNo,selectedState)
          }
        }
      };

      const getUserDetails = async (e) => {
        console.log("get user deatil welcome");
        let emailId = e.Details.defectDetails.history[0].userEmail;
        let projectId = JSON.parse(localStorage["selected-project"]).id;
        try {
          let response = await getUserDetailsByEmailID(projectId, emailId);
          if (response?.status === 200){
            setusererDeatils(response.data);
          setUserPopup(true);
         
          } 
          console.log("getUserDetails", response);
        } catch (err) {
          console.log("error");
        }
      };

      const getTemplateDetails = async (e) => {
        console.log("eeee", e.defectTemplateId);
        console.log("eeee Details", e);
        let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])
          .project.fireFlinkProjectId;
        let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
        let templateID = e.defectTemplateId;
        try {
          let response = await getTemplateById(
            fireFlinkProjectId,
            templateID,
            licenseId
          );
          if (response.data.responseCode === 200) {
            setDefectTempalteDetails(response);
            setDefectsdetails(e);
            setDefectsPopUp(true);
          
          } else {
            props.MyAlert.info("Something went wrong");
          }
        } catch (error) {
          console.log("errror");
        }
      };
    
    
      let data = React.useMemo(() => tabelData, [tabelData]);
      let columns = React.useMemo(
    () => [
      {
        Header: "BUG ID",
        accessor: "bugId",
        width: "8%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              getTemplateDetails(e.cell.row.original.Details);
              // setDefectsdetails(e.cell.row.original.Details);
              // setDefectsPopUp(true);
            }}
            className="reportTablecell popins cursor-pointer	"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "BUG SUMMARY",
        accessor: "bugSummary",
        width: "49.7%",
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },
      {
        Header: "CREATED ON",
        accessor: "createdOn",
        width: "12.3%",
      },
      {
        Header: "CREATED BY",
        accessor: "createdBy",
        width: "12.3%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();

              getUserDetails(e.cell.row.original);
            }}
            className="reportTablecell popins cursor-pointer	datatrimmer w-24"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
    ],
    []
  );
  
      function saveFileFromBlob(blob, filename) {
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const a = document.createElement("a");
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = filename;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0);
        }
        setDownloading(false)
      }

      const downloadExcleFile = async () => {
        let filename = selectedTab
          ? "Approved_Defects.xlsx"
          : "Rejected_Defects.xlsx";
        let state = selectedTab ? "CLOSED" : "REJECTED";
        let payload = {};
        if(tabelData.length !==0){
          setDownloading(true)
          try {
            let response = await DownloadDefectExcleFile(
              projectId,
              licenseID,
              state,
              payload
            );
            console.log("response file", response);
            if (response.status === 200) {
              saveFileFromBlob(response.data, filename);
            }else{
              setDownloading(false)
            }
          } catch (err) {
            console.log("error");
          }
        }
       
      };
      function CloseDefectModal(state) {
       
        setDefectsPopUp(state);
      }
    
      function CloseTesterModal(state) {
        setUserPopup(state);
      }
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state,
        setGlobalFilter,
      } = useTable(
        {
          columns,
          data,
        },
     useGlobalFilter
      );
       const { globalFilter } = state;

      
    return(
        <div className="card_section_tabel_container opacity-50" style={{borderTop:"2px solid #FB5951"}}>
      <div className="grid grid-cols-12 flex content-between">
        <div className="col-span-5 heading pl-3">
         
        No. of Defects Raised
        </div>
        <div className={`grid grid-cols-12 col-span-7 flex justify-end ${props?.allowRejectedDefect===true?"":"defect_common_tabel_header"} `}>
        <div className="search_section col-span-5">
          <div className="adminsearch">
            <div>
              <input
                type="text"
                className="searchfield"
                 value={globalFilter || ""}
                autoFocus={false}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
                placeholder="Search"
              />
            </div>
            <div className="search_icon">
              <Search />
            </div>
          </div>
        </div>
        <div className={`col-span-2 report_Download_btn ${tabelData.length===0||downloading===true?"opacity-50":"opacity-100"}`}>
        <button className="flex" onClick={()=>{if(downloading===false){downloadExcleFile()}}}>
                <div className="mt-1.5 ml-2">
                Download
                </div>
                <div className="ml-1.5">
                    <FileDownloadOutlinedIcon/>
                </div>
               
               

            </button>
        </div>
        <div className={`col-span-5 table_tabs_text ${props?.allowRejectedDefect===true?"flex":"hidden"}`}>
            <div className={selectedTab?"selected_tab":"unselected_tab"} onClick={()=>{if(selectedTab===false){SelectedTabData("Approved")}}}>Approved Defects</div>
            <div className="mx-1">|</div>
            <div className={selectedTab?"unselected_tab":"selected_tab"} onClick={()=>{if(selectedTab===true){SelectedTabData("Rejected")}}}>Rejected Defects</div>
        </div>

       
        </div>
      </div>
      

      <div className="card_table_container mt-2">
        <table {...getTableProps()} className=" overflow-x-auto">
            <thead className="text-left">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="defect_table_header">
                  {headerGroup.headers.map((column) => (
                    <th
                      width={column.width}
                      {...column.getHeaderProps()}
                      className={`reportTableheader popins-semi-bold text-lg font-semibold ${
                        column.Header === "BUG ID"
                          ? "text-left pl-3"
                          : "text-left pl-3"
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>


          {
            dataLoading===false ? (
              <tbody
              {...getTableBodyProps()}
              className="defect_table_body"
              onScroll={handlescroll}
            >
              {rows.length === 0 || data?.length === 0 ? (
                <div className="noreportIconCenter">
                  {/* <NoDefectIcon /> */}
                  <img  alt="warning" src={NoDefectIcon}/>
                  <div className="text_msg">No Relevant Data Is Found !</div>
                </div>
              ) : (
                <>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="h-10" id="Defect_Card">
                        {row.cells.map((cell) => {
                          return (
                            <td
                              width={cell?.column?.width}
                              {...cell.getCellProps()}
                              className={`datatrimmer reportTablecell ${
                                cell.column.Header === "BUG ID" 
                                // || cell.column.Header === "CREATED BY"
                                  ? " popins-semi-bold"
                                  : "popins"
                              } ${
                                cell.column.Header === "BUG ID"
                                  ? "text-left pl-3"
                                  : "text-left pl-3"
                              }`}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
            ):(
              <div className="mt-24">
              <CircularLoader/>
              </div>
            )
          }
             
         
          </table>
      </div>
      {defectsPopUp && (
        <DefectsDeatilsPopup
          CloseDefectModal={CloseDefectModal}
          SelectedDefect={defectsdetails}
          defectTemplatedetails={defectTemplatedetails}
        />
      

      )}
      {userPopup && (
        <UserDeatilsPopup
          CloseTesterModal={CloseTesterModal}
          testerDeatils={userDeatils}
        />
        
      )}
     
    </div>
    )
}
export default DefectTable;