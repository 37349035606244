import React from "react";
// import LogotextImage from "../../assets/fire_crowd.svg";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import BackgroundDown from "../../assets/login_logo.png";
import style from "./signup.module.scss";
import cx from "classnames";
import righticon from "../../assets/right-img-circle.svg";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const PASSWORD_SUCC_LEFT_HEADING="Well Done";
const PASSWORD_SUCC_LEFT_MSG="Your password is set, login to access your account";

const PASSWORD_SUCC_RIGHT_MSG="Password Set Successfully"



function PasswordSetSuccessfully() {
  return (
    <div className="grid grid-cols-12 min-h-screen ">
      <div className={cx(style["setpassword_activation_link"], "col-span-6 border-r-4")}>
        <div
          className={cx(style["setpassword_activation_link_leftImage"],"grid grid-cols-span-12 col-span-6 flex justify-center")}
          id="leftImage"
        >
          <div className={cx(style["leftImage"])}>
            <img
            className={cx(style["logo_Icon"])}
              src={LogotextImage}
              height="200px"
              width="300px"
              alt="Qspider"
            />
          </div>
        </div>
        <div className="grid grid-cols-span-12 col-span-6 flex justify-center mt-5">
          <div>
            <img
              height="350px"
              width="350px"
              src={BackgroundDown}
              alt="Firecrowd"
            />
          </div>
        </div>
        <div
          className={cx(
            style["heading"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-5"
          )}
        >
          <div>Well Done</div>
        </div>
        <div
          className={cx(
            style["msg"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-2"
          )}
        >
          <span>{PASSWORD_SUCC_LEFT_MSG}</span>
        </div>
    
      </div>
      <div className={cx(style["setpassword_activation_page"], "col-span-6 pt-28")}>
      <div className={cx(style["logoimg"])}>
            <img
              src={LogotextImage}
              height="200px"
              width="300px"
              alt="Qspider"
            />
          </div>
        <div className={cx(style["righticon"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-8")}>
          <div className="pt-1.5">
            <img alt="warning" src={righticon} />
          </div>
          <div className={cx(style["succcess_msg"] ,"ml-2")}>{PASSWORD_SUCC_RIGHT_MSG}</div>
        </div> 
        {/* <div
          className={cx(
            style["heading"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-4"
          )}
        >
          
          <div className={cx(style["msg_text_one"])}>{PASSWORD_SUC_MSG1}</div>
          <div className={cx(style["msg_text_two"])}>{PASSWORD_SUC_MSG2}</div>
        </div> */}
      
        <div
          className={cx(
            style["Back_link_text"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-4"
          )}
        >
          <div className="flex">
          <KeyboardBackspaceIcon sx={{ fontSize: 12,height:"17px" }}/>
          <div className="ml-1">
          <span>
            <a href="/">Back to sign in</a>
          </span>
          </div>
          </div>
         
         
         
        </div>
      </div>
    </div>
  );
}
export default PasswordSetSuccessfully;
