import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { TextareaAutosize, TextField } from "@mui/material";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useFormik } from "formik";
import * as yup from "yup";
import { Label } from "../../common/inputs/myInput";
import CloseIcon from "@material-ui/icons/Close";

import "../defect-field-forms.scss";

const CheckBoxForm = (props) => {
  const [mandatoryTFValue, setMandatoryTFValue] = useState(
    props.editFieldValue?.type === "checkbox"
      ? props.editFieldValue.mandatory
      : "no"
  );
  const [cbOptions, setCBOptions] = useState(
    props.editFieldValue?.type === "checkbox"
      ? props.editFieldValue.options
      : [""]
  );
  const [cbOption, setCBOption] = useState("");
  const [checkForAlphanumeric, setCheckForAlphanumeric] = useState("");
  const [checkBoxfieldValidate, setCheckBoxfieldValidate] = useState("");
  const [checkBoxFieldOptionValidate, setCheckBoxFieldOptionValidate] =
    useState("");
  const [
    checkBoxFieldOptionUniqueValidate,
    setCheckBoxFieldOptionUniqueValidate,
  ] = useState("");
  const [
    checkBoxFieldOptionAlphaNumericValidate,
    setCheckBoxFieldOptionAlphaNumericValidate,
  ] = useState("");
  const [
    checkBoxFieldOptionDuplicateArray,
    setCheckBoxFieldOptionDuplicateArray,
  ] = useState([]);
  const [checkboxFormCounter, setCheckboxFormCounter] = useState(
    props.editFieldValue?.type === "checkbox"
      ? props.editFieldValue.options.length
      : 1
  );
  let initialValues = {
    label:
      props.editFieldValue?.type === "checkbox"
        ? props.editFieldValue.label
        : "",
  };
  const validationSchema = yup.object({
    label: yup.string().required("Label is required"),
  });
  const formikDetails = useFormik({
    initialValues,

    validationSchema,
  });

  const handleMandatoryTFChange = (e) => {
    setMandatoryTFValue(e.target.value);
  };
  const checkDuplicateOptions = (a) => {
    let b = Array.from(new Set(a.map((e) => e.toLowerCase().trim())));
    return a.length !== b.length;
  };
  const indexOfAll = (arr, val) =>
    arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);
  const getDuplicateOptions = (a) => {
    let tempArray = a.map((v) => v.toLowerCase());
    let b = findDuplicates(tempArray);
    let c = [];
    b.map((d) => {
      c.push([...indexOfAll(tempArray, d)]);
    });
    return c;
  };
  let pattern = /^(?! ).*[^ ]$/;
  let alphaNumericPattern =
    /^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/;
  function findDuplicates(arr) {
    const filtered = arr.filter((item, index) => arr.indexOf(item) !== index);
    return [...new Set(filtered)];
  }
  const haveSameData = function (obj1, obj2) {
    const obj1Length = Object?.keys(obj1)?.length;
    const obj2Length = Object?.keys(obj2)?.length;
    if (obj1Length === obj2Length) {
      return Object?.keys(obj1)?.every(
        (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
      );
    }
    return false;
  };
  const handleAddCheckboxOption = (e) => {
    if (!cbOptions.map((x) => alphaNumericPattern.test(x)).includes(false)) {
      if (!checkDuplicateOptions(cbOptions)) {
        setCBOptions((cbOptions) => [...cbOptions, cbOption]);
        setCBOption("");
        setCheckboxFormCounter(checkboxFormCounter + 1);
      } else {
        setCheckBoxFieldOptionDuplicateArray(getDuplicateOptions(cbOptions));
        setCheckBoxFieldOptionUniqueValidate("Options should be unique");
      }
    } else {
      setCheckBoxFieldOptionAlphaNumericValidate(
        "Checkbox options should be alphanumeric"
      );
    }
  };

  const addCBOptions = (e, index, type) => {
    setCheckBoxFieldOptionValidate("");
    setCheckBoxFieldOptionUniqueValidate("");
    setCheckBoxFieldOptionUniqueValidate("");
    setCheckBoxFieldOptionDuplicateArray([]);
    setCheckBoxFieldOptionAlphaNumericValidate("");
    const cbOptionsCopy = [...cbOptions];
    cbOptionsCopy.forEach((val, i) => {
      if (index === i) {
        type === "input"
          ? (cbOptionsCopy[i] = e.target.value)
          : (cbOptionsCopy[i] = e.target.value.trim());
      }
    });
    setCBOptions(cbOptionsCopy);
  };

  const deleteCBOptions = (e, index) => {
    setCheckBoxFieldOptionValidate("");
    setCheckBoxFieldOptionUniqueValidate("");
    setCheckBoxFieldOptionUniqueValidate("");
    setCheckBoxFieldOptionDuplicateArray([]);
    setCheckBoxFieldOptionAlphaNumericValidate("");
    const cbOptionsCopy = [...cbOptions];
    index !== -1 && cbOptionsCopy.splice(index, 1);
    setCBOptions(cbOptionsCopy);
  };
  const handelCheckBoxFormSubmit = (e) => {
    formikDetails.handleSubmit();
    if (!pattern.test(formikDetails.values.label) && cbOptions.includes("")) {
      setCheckBoxfieldValidate("Label is required");
      setCheckBoxFieldOptionValidate("Option is required");
    }

    if (!pattern.test(formikDetails.values.label)) {
      setCheckBoxfieldValidate("Label is required");
    } else if (!checkDuplicateOptions(cbOptions)) {
      const checkboxFormData = {
        label: formikDetails.values.label,
        options: cbOptions,
        mandatory: mandatoryTFValue,
        type: "checkbox",
        order: props.editFieldValue["order"],
      };

      if (cbOptions.includes("")) {
        setCheckBoxFieldOptionValidate("Option is required");
        return;
      } else if (!alphaNumericPattern.test(formikDetails.values.label)) {
        setCheckForAlphanumeric("Label should be alphanumeric");
      } else if (
        cbOptions.map((x) => alphaNumericPattern.test(x)).includes(false)
      ) {
        setCheckBoxFieldOptionAlphaNumericValidate(
          "Checkbox options should be alphanumeric"
        );
        return;
      } else if (haveSameData(props.editFieldValue, checkboxFormData)) {
        props.MyAlert.info(
          `No changes were done to the ${checkboxFormData.label} field`
        );
        return;
      } else if (pattern.test(formikDetails.values.label)) {
        setCheckBoxFieldOptionValidate("");
        setCheckBoxfieldValidate("");
        setCheckBoxFieldOptionUniqueValidate("");
        setCheckBoxFieldOptionDuplicateArray([]);
        setCheckBoxFieldOptionAlphaNumericValidate("");
        props.addFormField(checkboxFormData);
        props.setAddField(false);
      }
      if ([checkboxFormData.label].includes("")) {
        props.setAddField(true);
        formikDetails.handleSubmit();
      } else {
        props.setAddField(false);
      }
    } else {
      setCheckBoxFieldOptionDuplicateArray(getDuplicateOptions(cbOptions));
      setCheckBoxFieldOptionUniqueValidate("Options should be unique");
    }
  };
  const onBlurAddCheckboxOption = (e) => {
    if (!cbOptions.map((x) => alphaNumericPattern.test(x)).includes(false)) {
      if (checkDuplicateOptions(cbOptions)) {
        setCheckBoxFieldOptionDuplicateArray(getDuplicateOptions(cbOptions));
        setCheckBoxFieldOptionUniqueValidate("Options should be unique");
      }
    } else {
      setCheckBoxFieldOptionAlphaNumericValidate(
        "Checkbox options should be alphanumeric"
      );
    }
  };

  return (
    <div className="form-content_radioForms overflow-y-scroll ">
      <div className="my-5  px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Label
              </span>
            }
            required={true}
            error={formikDetails.errors.label && formikDetails.touched.label}
          />
        </div>
        <TextField
          error={formikDetails.errors.label && formikDetails.touched.label}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          name="label"
          id="label"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={formikDetails.handleBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.label}
        />
        {formikDetails.errors.label && formikDetails.touched.label ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.label}
          </div>
        ) : null}
      </div>
      <div className="my-5 px-2">
        <div className="flex justify-between">
          <div className="">
            {cbOptions.map((c, index) => {
              return (
                <div className="mb-7 user-input-container  relative">
                  <div className="mb-1">
                    <Label
                      label={
                        <span className="textFieldHeader text-sm  popins-medium  ">
                          Option {index + 1}
                        </span>
                      }
                      required={true}
                      error={
                        formikDetails.errors.label &&
                        formikDetails.touched.label
                      }
                    />
                  </div>
                  <TextField
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    // label={
                    //     <>
                    //         <span className="text-red-400 mr-1">&#42;</span>{" "}
                    //         Option {index + 1}
                    //     </>
                    // }
                    key={index}
                    size="small"
                    className={"text-gray-700 w-96 open-sans-regular"}
                    type="text"
                    placeholder="Type Here"
                    autoComplete="off"
                    name="checkboxFormOptionField"
                    id="checkboxFormOptionField"
                    onBlur={(e) => {
                      addCBOptions(e, index, "blur");
                      onBlurAddCheckboxOption(e);
                    }}
                    onInput={(e) => {
                      addCBOptions(e, index, "input");
                    }}
                    value={cbOptions[index]}
                  />
                  {cbOptions.length > 1 && (
                    <button
                      type="button"
                      onClick={(e) => {
                        deleteCBOptions(e, index);
                      }}
                    >
                      <CloseIcon className="iconStyle cursor-pointer mx-2 mt-2 text-gray-500" />
                    </button>
                  )}
                  {index + 1 === cbOptions.length && (
                    <button
                      disabled={!cbOptions[cbOptions.length - 1]}
                      type="button"
                      onClick={() => {
                        handleAddCheckboxOption();
                      }}
                    >
                      <AddCircleOutlineIcon
                        className={
                          !cbOptions[cbOptions.length - 1]
                            ? "opacity-50 mt-2"
                            : "opacity-100 mt-2"
                        }
                      />
                    </button>
                  )}
                  {checkBoxFieldOptionValidate && !cbOptions[index] ? (
                    <p className="errorMessage absolute">
                      {checkBoxFieldOptionValidate}
                    </p>
                  ) : checkBoxFieldOptionUniqueValidate &&
                    checkBoxFieldOptionDuplicateArray[0]?.includes(index) ? (
                    <p className="errorMessage absolute">
                      {checkBoxFieldOptionUniqueValidate}
                    </p>
                  ) : checkBoxFieldOptionAlphaNumericValidate &&
                    !alphaNumericPattern.test(cbOptions[index]) ? (
                    <p className="errorMessage absolute">
                      {checkBoxFieldOptionAlphaNumericValidate}
                    </p>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="mb-4 my-2 px-2 ml-1.5">
        <Label
          label={
            <span className="textFieldHeader text-sm  popins-medium  ">
              Mandatory
            </span>
          }
          required={true}
        />
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              value={mandatoryTFValue}
              onChange={handleMandatoryTFChange}
              className="textFieldHeader text-sm  popins-medium  "
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
        <button
          onClick={() => {
            props.setAddField(false);
            props.clearEditValue({})
          }}
          type="button"
          className="w-24 gray-btn ml-3 button"
        >
          Cancel
        </button>
        <button
          type="submit"
          id="createBatch"
          className="w-24 primary-btn button"
          onClick={handelCheckBoxFormSubmit}
        >
          {props.editFieldValue?.type === "checkbox" ? "Update" : "Add"}
        </button>
      </div>
    </div>
  );
};

export default CheckBoxForm;
