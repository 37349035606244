import React from "react";
import "./integration.scss";
import JiraIcon from "../../assets/integration_icons/jira_logo.png";
import { useNavigate } from "react-router-dom";

function IntegrationHome(){
    const navigate = useNavigate();
    return(
        <div className="integrationHome-container">
            <div className="integrationHome-header pl-3">Integration</div>
            <div className="integrationHome-body grid grid-cols-12">
                <div className="col-span-12 integrationHome-cards grid grid-cols-12">
                <div className="col-span-2 icon_container" onClick={()=>{ navigate("/project/configuration/integration/JIRA", { replace: true });}}>
                    <div className="jira_icon flex justify-center">
                    <img  alt="Jira" src={JiraIcon}/>
                    </div>
               
                <div className="icon_text">JIRA</div>

                </div>
                </div>

            </div>

        </div>
    )
}
export default IntegrationHome