import { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
export const FilterLabels = (props) => {
  console.log("FilterLabels",props)
  let [filterItems, setFilterItems] = useState([]);
  useEffect(() => {
    setFilterItems(props.filterLabelsList);
  }, [props]);

  return (
    <div className="max-h-20 overflow-y-scroll	">
      {filterItems?.map((filterItem, index) => (
        <div className="inline-block mr-1 mt-1">
        <div className="flex bg-slate-300 mb-1 w-fit filterItem_container" key={index} style={{}}>
          {
            props?.label==="User" ?
            (
              <div className="p-1 filterLabel" >{filterItem.value.split(":")[0]}</div>
            ): props?.label==="Testcase-module" ?(
              <div className="p-1 filterLabel" >{filterItem.value.split(":")[1]}</div>
            ):filterItem.name==="segment-modules"?(
              <div className="p-1 filterLabel" >{filterItem.value.split(/[/,:]/)[filterItem.value.split(/[/,:]/).length-2]}</div>
            ):
            (
              <div className="p-1 filterLabel" >{filterItem.value}</div>
            )
          }
        
          <div className="clearIcon">
            <ClearIcon
              className="filter-label-close-icon -mt-0.5 ml-1"
              onClick={() => {
                let tempEvent = {
                  target: {
                    value: props.filterLabelsList[index].value,
                    name: props.filterLabelsList[index].name,
                    checked: false,
                  },
                };
                props.onLabelsChange(tempEvent);
              }}
            />
          </div>          
        </div>
        </div>
      ))}
    </div>
  );
};
