import React, { useState } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup'
import { TextareaAutosize, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Label } from "../../common/inputs/myInput";
import "../defect-field-forms.scss";

const TextFieldForm = (props) => {
    const [enableTFValue, setEnableTFValue] = useState( props.editFieldValue?.type === 'textField' ? props.editFieldValue.enable :"yes");
  const [mandatoryTFValue, setMandatoryTFValue] = useState(props.editFieldValue?.type === 'textField' ? props.editFieldValue.mandatory :"no");
  const [checkLengthValidationDefaultTF, setCheckLengthValidationDefaultTF] = useState("")
  const [checkMinLengthValidationDefaultTF, setCheckMinLengthValidationDefaultTF] = useState("")
  const [textFieldMaxValueValidate, setTextFieldMaxValueValidate] = useState("")
  const [maxEmptyValueCheck, setMaxEmptyValueCheck] = useState("")
    let initialValues = {
        label:props.editFieldValue?.type === 'textField' ? props.editFieldValue.label : "",
        placeholder: props.editFieldValue?.type === 'textField' ? props.editFieldValue.placeholder : "",
        maxLength:props.editFieldValue?.type === 'textField' ? props.editFieldValue.maxLength : 25,
        minLength:props.editFieldValue?.type === 'textField' ? props.editFieldValue.minLength : 3,
        defaultValue:props.editFieldValue?.type === 'textField' ? props.editFieldValue.defaultValue : "",
    
        
      };
      const validationSchema = yup.object({
        label: yup.string().required("Label is required"),
        maxLength:yup.string().required("MaxLength is required"),
        minLength:yup.string().required("MinLength is required"),
       
       
       
      });
      const formikDetails = useFormik({
        initialValues,
       
        validationSchema,
        
      });
      const handleEnableTFChange = (e) => {
        setEnableTFValue(e.target.value)
    }
    const handleMandatoryTFChange = (e) => {
        setMandatoryTFValue(e.target.value)
        setEnableTFValue("yes")
    }
    function handleTrimmedBlur(event) {
      event.target.value = event.target.value.trim();
      formikDetails.handleChange(event);
      formikDetails.handleBlur(event);
  }
    const haveSameData = function (obj1, obj2) {
      const obj1Length = Object.keys(obj1).length;
      const obj2Length = Object.keys(obj2).length;
      if (obj1Length === obj2Length) {
          return Object.keys(obj1).every(
              key => obj2.hasOwnProperty(key)
                  && obj2[key] === obj1[key]);
      }
      return false;
  }
      const handelTextFieldFormSubmit = (values,e) => {
        // e.preventDefault()
        formikDetails.handleSubmit()
        
        const textFieldData = {
                label: formikDetails.values.label,
                placeholder: formikDetails.values.placeholder,
                maxLength: formikDetails.values.maxLength,
                minLength: formikDetails.values.minLength,
                defaultValue: formikDetails.values.defaultValue,
                enable: enableTFValue,
                order: props.editFieldValue["order"],
                mandatory: mandatoryTFValue,
                type: "textField",
            }
            
            
            if([textFieldData.label,textFieldData.maxLength,textFieldData.minLength].includes("")){
              
                props.setAddField(true);
    
            }else if (haveSameData(props.editFieldValue, textFieldData)) {
              props.MyAlert.info(`No changes were done to the ${textFieldData.label} field`)
               return
           } else if (formikDetails.values.defaultValue && formikDetails.values.maxLength < formikDetails.values.defaultValue?.length) {
                setCheckLengthValidationDefaultTF(`Default value cannot be more than ${formikDetails.values.maxLength} characters`)
            } else if (formikDetails.values.defaultValue && formikDetails.values.minLength > formikDetails.values.defaultValue?.length) {
                setCheckMinLengthValidationDefaultTF(`Default value cannot be less than ${formikDetails.values.minLength} characters`)
            }else if (Number(formikDetails.values.maxLength) === 0 || Number(formikDetails.values.maxLength) < Number(formikDetails.values.minLength)) {
              setTextFieldMaxValueValidate(" Max length should be greater than Min length")
          } else{
               props.addFormField(textFieldData);
                props.setAddField(false);
               
            }
    
               
            
      };

  return (
    <div className="form-content overflow-y-scroll ">
      <div className="my-5  px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Label
              </span>
            }
            required={true}
            error={formikDetails.errors.label && formikDetails.touched.label}
          />
        </div>
        <TextField
          error={formikDetails.errors.label && formikDetails.touched.label}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          name="label"
          id="label"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={handleTrimmedBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.label}
        />
        {formikDetails.errors.label && formikDetails.touched.label ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.label}
          </div>
        ) : null}
      </div>
      <div className="my-2 px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Placeholder
              </span>
            }
            error={
              formikDetails.errors.placeholder &&
              formikDetails.touched.placeholder
            }
          />
        </div>
        <TextField
          error={
            formikDetails.errors.placeholder &&
            formikDetails.touched.placeholder
          }
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          name="placeholder"
          id="placeholder"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={handleTrimmedBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.placeholder}
        />
        {formikDetails.errors.placeholder &&
        formikDetails.touched.placeholder ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.placeholder}
          </div>
        ) : null}
      </div>
      <div className="my-2 px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Max Length
              </span>
            }
            required={true}
            error={
              formikDetails.errors.maxLength && formikDetails.touched.maxLength
            }
          />
        </div>
        <TextField
         
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          name="maxLength"
          id="maxLength"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={formikDetails.handleBlur}
          InputProps={{ inputProps: { min: 0 } }}
          onKeyDown={(evt) => {["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.maxLength}
          error={(typeof (formikDetails.values.maxLength) === "string" && maxEmptyValueCheck) || (formikDetails.values.maxLength < formikDetails.values.minLength && textFieldMaxValueValidate) ? true : false}
                                    helperText={<>{typeof (formikDetails.values.maxLength) === "string" ? maxEmptyValueCheck : (formikDetails.values.maxLength < formikDetails.values.minLength ? <div className="errorMessage -ml-2">{textFieldMaxValueValidate}</div> : null)}</>}
        />
        {formikDetails.errors.maxLength && formikDetails.touched.maxLength ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.maxLength}
          </div>
        ) : null}
       
      </div>
      <div className="my-2 px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Min Length
              </span>
            }
            required={true}
            error={
              formikDetails.errors.minLength && formikDetails.touched.minLength
            }
          />
        </div>
        <TextField
          error={
            formikDetails.errors.minLength && formikDetails.touched.minLength
          }
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          name="minLength"
          id="minLength"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={formikDetails.handleBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.minLength}
          InputProps={{ inputProps: { min: 0 } }}
          onKeyDown={(evt) => {["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}}
        />
        {formikDetails.errors.minLength && formikDetails.touched.minLength ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.minLength}
          </div>
        ) : null}
      </div>
      <div className="my-2 px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Default Value
              </span>
            }
           
           
          />
        </div>
        <TextField
         
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          name="defaultValue"
          id="defaultValue"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={formikDetails.handleBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.defaultValue}
        />
       
        {
                                    (formikDetails.values.maxLength && formikDetails.values.maxLength < formikDetails.values.defaultValue?.length) ?

                                        <>
                                            <p className="errorMessage">
                                                {checkLengthValidationDefaultTF}
                                            </p>
                                        </>
                                        :
                                        formikDetails.values.minLength && formikDetails.values.minLength > formikDetails.values.defaultValue?.length ?

                                            <>
                                                <p className="errorMessage">
                                                    {checkMinLengthValidationDefaultTF}
                                                </p>
                                            </>
                                            :
                                            null
                                }
      </div>

      <div className="mb-4 my-2 px-2 ml-1.5">
        <Label
          label={
            <span className="textFieldHeader text-sm  popins-medium  ">
              Enable
            </span>
          }
        />
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              className="textFieldHeader text-sm  popins-medium  "
              value={enableTFValue}
              onChange={handleEnableTFChange}
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    color="primary"
                    disabled={mandatoryTFValue === "yes"}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="mb-4 my-2 px-2 ml-1.5">
        <Label
          label={
            <span className="textFieldHeader text-sm  popins-medium  ">
              Mandatory
            </span>
          }
          required={true}
        />
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              value={mandatoryTFValue}
              onChange={handleMandatoryTFChange}
              className="textFieldHeader text-sm  popins-medium  "
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
        <button
          onClick={() => {
            props.setAddField(false);
            props.clearEditValue({})
          }}
          type="button"
          className="w-24 gray-btn ml-3 button"
        >
          Cancel
        </button>
        <button
          type="submit"
          id="createBatch"
         
          className="w-24 primary-btn button"
          onClick={handelTextFieldFormSubmit}
        >
          {props.editFieldValue?.type === 'textField' ?"Update" :"Add"}
        </button>
      </div>
    </div>
  );
};

export default TextFieldForm;
