import React, { useState, Fragment,useEffect } from "react";
import "./page-header.scss";
import { ReactComponent as FireCrowdIcon } from "../../assets/fireCrowd_white.svg";
import { ReactComponent as UserIcon } from "../../assets/user.svg";
import { ReactComponent as BellIcon } from "../../assets/bell_icon.svg";
import { useNavigate } from "react-router-dom";
import { Popover, Menu, Transition } from "@headlessui/react";
import IconButton from "@material-ui/core/IconButton";
import { SignOutReq } from "../../api/api-services";
import Breadcrumbs from "../common/breadcrumbs";
import { ReactComponent as User } from "../../assets/userProfile/user_solid.svg";
import { ReactComponent as Email } from "../../assets/userProfile/email.svg";
import { ReactComponent as Certificate } from "../../assets/userProfile/Certificate_icon.svg";
import { ReactComponent as SignOut } from "../../assets/userProfile/sign-out.svg";
import {hasCertificateReq, getCertificateimg,} from "../../api/api-services"
import CertificateModal from "../project/modals/Certificate-modal";
// import { CommonLoader } from "../common/common-loader";
import { CommonLoader } from "../common/common-loader";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function PageHeader(props) {
  let username = localStorage.hasOwnProperty("fire-crowd-user")?JSON.parse(localStorage["fire-crowd-user"])?.name:"";
  let email= localStorage.hasOwnProperty("fire-crowd-user")?JSON.parse(localStorage["fire-crowd-user"])?.userName:"";
  
  let hasCerificate=localStorage.hasOwnProperty("has-Certificate")?JSON.parse(localStorage.getItem("has-Certificate")):"";
  const [showcertificat, setShowCertificat] = useState(false);
  let [certificatDetails, setCertficatDetails] = useState({
    projetId: "",
    userId: "",
    userName: "",
  });
  let [islodaing, setisLoading] = useState(false);
  const [img, setImg] = useState();
  let [displayCertificat, setDisplayCertificat] = useState(false);

  function OnCloseCertificatModal(state) {
    setDisplayCertificat(state);
  }

  const navigate = useNavigate();
 
  const downloadCertificate = async () => {
    setisLoading(true);
    setShowCertificat(true);

    let userId = localStorage.hasOwnProperty("fire-crowd-user")?JSON.parse(localStorage["fire-crowd-user"])?.userName:"";
    let username=localStorage.hasOwnProperty("fire-crowd-user")?JSON.parse(localStorage["fire-crowd-user"])?.name:""

    setCertficatDetails({
      userId:userId,
      userName:username,
    });

    try {
      let response = await getCertificateimg(userId);
      if (response.status === 200 ) {
        if(response.data.size !==0){
          console.log("response getCertificateimg", response.data);
          const url = window.URL.createObjectURL(response.data);
          setImg(url);
          setDisplayCertificat(true);
        }else{
          props.MyAlert.info(`${username} may not download a certificate.`)
        }
       
      }

      console.log("download file API", response);
    } catch (error) {
      console.log("error");
    }
    setShowCertificat(false);
    setisLoading(false);
  };

  const signOut = async () => {
    let payload = {
      emailId: email,
      currentLicenseId: null,
      // accessToken: JSON.parse(localStorage["fire-crowd-user"]).access_token,
    };
    try {
      let response = await SignOutReq(payload);
      if (response.status === 200) {
        localStorage.clear();
        navigate(`/signin`, { replace: true });
      }
      console.log("reponse", response);
    } catch (error) {
      console.log("error");
    }
  };
  return (
    <div className="flex header">
      {/* <div className="w-48">
      <div className="logo-background p-2"><FireCrowdIcon/></div>
    </div> */}
      <div className="w-full flex justify-between">
        <div className="mt-2" style={{marginLeft:props?.expandSideMenu?"16%":"6%"}}>
          <Breadcrumbs />
        </div>
        <div className="flex ">
          <div className="bell-icon pt-1 hidden">
            <BellIcon />
          </div>
          {/* <div className="user-icon" onClick={()=>{showLogout?setShowLogout(false):setShowLogout(true)}}><UserIcon/></div> */}
          <Menu
            as="div"
            className="relative inline-block text-left"
            key="option-menu"
          >
            {({ open }) => (
              <>
                <Menu.Button>
                  <IconButton color="primary" size="small" id="user-icon">
                    {/* <PersonOutlineOutlinedIcon /> */}
                    <UserIcon />
                  </IconButton>
                </Menu.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute z-40 right-6 mt-2 w-56 rounded-t-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none py-2"
                    style={{ height: hasCerificate?"200px":"150px" }}
                  >
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                        
                          className={classNames(
                           
                            "text-center  px-4 py-2 user_profile"
                          )}
                        >
                          <div className="flex">
                            <div className="userProfile_icon mr-2">
                              <User />
                            </div>
                            <div className="username_datatrimmer w-34" title={username}>{username}</div>
                          </div>
                          
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          
                          className={classNames(
                            "text-center  px-4 py-2 user_profile"
                          )}
                        >
                          <div className="flex">
                            <div className="userProfile_icon mr-2">
                              <Email/>
                            </div>
                            <div className="username_datatrimmer w-34" title={email}>{email}</div>
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                    {
                      hasCerificate && (
                        <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={downloadCertificate}
                            className={classNames(
                              "text-center  px-4 py-2 user_profile"
                            )}
                          >
                           <div className="flex">
                              <div className="userProfile_icon mr-2">
                                <Certificate/>
                              </div>
                              <div>Certificate</div>
                            </div>
                          </button>
                        )}
                      </Menu.Item>
                      )
                    }
                   
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                           onClick={signOut}
                          className={classNames(
                            "text-center  px-4 py-2 user_profile"
                          )}
                        >
                          <div className="flex">
                            <div className="userProfile_icon mr-2">
                              <SignOut/>
                            </div>
                            <div>Signout</div>
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                    {/* </div> */}
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
      
      {displayCertificat && (
        <CertificateModal
        details={certificatDetails}
        closeModal={OnCloseCertificatModal}
        imgdata={img}
        />
      )}
    </div>
  );
}

export default PageHeader;
