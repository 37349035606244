import React, { useState, useEffect } from "react";
import { useTable, useGlobalFilter } from "react-table";
import Close from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import BugReportIcon from "@mui/icons-material/BugReport";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./report-details.scss";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  getDefectsData,
  getUserDetailsByEmailID,
  getAllUsersListhierarchy,
  getAllDefectTemplate,
  DownloadDefectExcleFile,
  getDefectsDataCount,
} from "../../api/api-services";
import DefectFilterModal from "./defect-filter-modal";
import TestCaseFilterModal from "./testcase-filter-modal";
import DefectsDeatilsPopup from "../modal/defects-deatils-popup";
import TCReportDetails from "../modal/tcreport-details-popup";
import UserDeatilsPopup from "../modal/user-deatils";
import TCUserDeatilsPopup from "../modal/tcuser-details";
import { ReactComponent as NoDefectIcon } from "../../assets/noDefectsAvailable.svg";
import IconButton from "@mui/material/IconButton";
let filterBasePayLoad = {
  segmentSearchFilters: {
    severity: null,
    priority: null,
    modules: null,
    locations: null,
    createdOn: null,
  },
  webSearchFilters: {
    os: null,
    osVersion: null,
    browser: null,
    browserVersion: null,
  },
  mobileSearchFilters: {
    os: null,
    osVersion: null,
    browser: null,
    browserVersion: null,
    deviceBrand: null,
    deviceName: null,
    deviceType: null,
  },
};
let payLoad = JSON.parse(JSON.stringify(filterBasePayLoad));
let defectState = "CLOSED";
let DefectPayload = {};

function TCUserDetailsTabel(props) {
  let [defects, setdefects] = useState([]);
  let [showFilter, setShowFilter] = useState(false);
  let [showSearch, setShowSearch] = useState(false);
  let [dataLoaded, setDataLoaded] = useState(true);
  let [defectsTableData, setdefectsTableData] = useState([]);
  let [segmentData, setSegmentData] = useState({
    Severity: null,
    Priority: null,
    Module: null,
    Location: null,
    createdOn: null,
  });
  const [severity, setSeverity] = useState(null);
  const [priority, setPriority] = useState(null);
  const [module, setModule] = useState(null);
  const [location, setLocation] = useState(null);
  const [webPlatform, setWebPlatform] = useState(null);
  const [mobilePlatform, setMobilePlatform] = useState(null);
  let loggedinUser = JSON.parse(localStorage["user-privilege"]);
  const [approvedDefectsData, setApprovedDefectsData] = useState([]);
  const [rejectedDefectsData, setrejectedDefectsData] = useState([]);
  const [selected, setSelected] = useState(0);
  let [myDefectPageNo, setMyDefectPageNo] = useState(0);
  let [mydefectlastpage, setMyDefectlastpage] = useState();
  let [haveDefect, setHaveCreateDefect] = useState(false);
  let projectstate = JSON.parse(
    localStorage.getItem("selected-project")
  ).projectStatus;
  let [defectsPopUp, setDefectsPopUp] = useState(false);
  let [tcreportPopUp, setTCReportPopUp] = useState(false);
  let [defectsdetails, setDefectsdetails] = useState();
  let [tcreportdetails, setTCReportdetails] = useState();
  let [userDetails, setUserDetails] = useState();
  let [userPopup, setUserPopup] = useState(false);
  let [tcuserPopup, setTCUserPopup] = useState(false);
  let [selectedFilters, setSelectedFilters] = useState({});
  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  const crowdProjectId = JSON.parse(
    localStorage.getItem("selected-project")
  ).id;
  const emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
  // let actualDetails = {
  //   manualTestCase: {
  //     testSteps: {
  //       headers: [
  //         "SL",
  //         "Test Description",
  //         "Test Steps",
  //         "Test Data",
  //         "Expected Result",
  //       ],
  //       rows: [
  //         {
  //           SL: "1",
  //           "Test Description":
  //             "Verify user is able to create template from all project level.",
  //           "Test Steps":
  //             "Navigate to all project level. Click on + Template button. Click on Create button",
  //           "Test Data": "Navigate to all project level",
  //           "Expected Result": "Navigate to all,Verify user is able to create ",
  //         },
  //         {
  //           SL: "2",
  //           "Test Description":
  //             "Verify user is able to create template from all project level.",
  //           "Test Steps":
  //             "Navigate to all project level. Click on + Template button. Click on Create button",
  //           "Test Data": "",
  //           "Expected Result": "",
  //         },
  //         {
  //           SL: "3",
  //           "Test Description":
  //             "Verify user is able to create template from all project level.",
  //           "Test Steps":
  //             "Navigate to all project level. Click on + Template button. Click on Create button",
  //           "Test Data": "",
  //           "Expected Result": "",
  //         },
  //         {
  //           SL: "4",
  //           "Test Description":
  //             "Verify user is able to create template from all project level.",
  //           "Test Steps":
  //             "Navigate to all project level. Click on + Template button. Click on Create button",
  //           "Test Data": "",
  //           "Expected Result": "",
  //         },
  //         {
  //           SL: "5",
  //           "Test Description":
  //             "Verify user is able to create template from all project level.",
  //           "Test Steps":
  //             "Navigate to all project level. Click on + Template button. Click on Create button",
  //           "Test Data": "",
  //           "Expected Result": "",
  //         },
  //         {
  //           SL: "6",
  //           "Test Description":
  //             "Verify user is able to create template from all project level.",
  //           "Test Steps":
  //             "Navigate to all project level. Click on + Template button. Click on Create button",
  //           "Test Data": "",
  //           "Expected Result": "",
  //         },
  //         {
  //           SL: "7",
  //           "Test Description":
  //             "Verify user is able to create template from all project level.",
  //           "Test Steps":
  //             "Navigate to all project level. Click on + Template button. Click on Create button",
  //           "Test Data": "",
  //           "Expected Result": "",
  //         },
  //       ],
  //     },
  //   },
  // };
  const [TemplateData, setTemplateData] = useState(
    JSON.parse(JSON.stringify(props?.tabeldata))
  );
  let [tableRowData, setTableRowData] = useState(
    props?.tabeldata?.testSteps?.rows
  );
  let [displayapproverdefect, setDisplayapproverdefect] = useState(true);
  let [approvedDefectPageNo, setApprovedDefectPageNo] = useState(0);
  let [rejectedDefectPageNo, setRejectedDefectPageNo] = useState(0);
  let [approvedDefectlastpage, setApprovedDefectlastpage] = useState();
  let [rejectedDefectlastpage, setRejectedDefectlastpage] = useState();
  let [defectsCount, setDefectsCount] = useState();

  if (loggedinUser?.role?.includes("_")) {
    loggedinUser.role = loggedinUser.role.replace("_", "-");
  }

  // useEffect(() => {
  //   getAllDefects(payLoad, false, 0, "CLOSED");
  //   getAllUsersList();
  //   getDefaultDefectTemplate();
  // }, []);
  useEffect(() => {
    segmentDataHandler(props.data);
  }, [props]);
  const segmentDataHandler = (data) => {
    // setModule(
    //   data.defectModule !== undefined && data.defectModule.length !== 0
    //     ? data?.defectModule[0].hasOwnProperty("approved") &&
    //       data?.defectModule[0].approved !== null
    //       ? Object.keys(data?.defectModule[0].approved)
    //       : null
    //     : null
    // );
    // setLocation(
    //   data.defectRaisedLocation !== undefined &&
    //     data.defectRaisedLocation.length !== 0
    //     ? data?.defectRaisedLocation[0].hasOwnProperty("approved") &&
    //       data?.defectRaisedLocation[0].approved !== null
    //       ? Object.keys(data?.defectRaisedLocation[0].approved)
    //       : null
    //     : null
    // );
  };

  const Styles = styled.div`
    table {
      border-spacing: 0;
      border: 1px solid #707070;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        border-bottom: 1px solid #707070;
        border-right: 1px solid #707070;

        :last-child {
          border-right: 0;
        }
      }
    }
  `;
  const handlescroll = (event) => {
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
      if (mydefectlastpage === false) {
        getAllDefects(payLoad, false, myDefectPageNo, defectState);
      }
    }
  };

  const getUserDetails = async (e) => {
    let emailId = e.Details.tcDetails.history[0].userEmail;
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    try {
      let response = await getUserDetailsByEmailID(projectId, emailId);
      if (response?.status === 200) setUserDetails(response.data);
      setUserPopup(true);
      setTCUserPopup(true);
      console.log("getUserDetails", response);
    } catch (err) {
      console.log("error");
    }
  };

  function CloseDefectModal(state) {
    setDefectsPopUp(state);
  }

  function CloseTCReportModal(state) {
    setTCReportPopUp(state);
  }

  function CloseTesterModal(state) {
    setTCUserPopup(state);
  }
  const getAllDefectsWithFilter = (payload, filtersAdded, filters, callApi) => {
    payLoad = payload;
    setMyDefectPageNo(0);
    setSelectedFilters(filters);
    if (callApi) {
      getAllDefects(payLoad, filtersAdded, 0, defectState);
    }
  };

  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
  const downloadExcleFile = async () => {
    let filename = displayapproverdefect
      ? "Approved_Defects.xlsx"
      : "Rejected_Defects.xlsx";
    let state = displayapproverdefect ? "CLOSED" : "REJECTED";
    let filter = Object.keys(selectedFilters).length === 0 ? {} : DefectPayload;
    try {
      let response = await DownloadDefectExcleFile(
        projectId,
        licenseID,
        state,
        filter
      );
      console.log("response file", response);
      if (response.status === 200) {
        saveFileFromBlob(response.data, filename);
      }
    } catch (err) {
      console.log("error");
    }
  };

  const getAllDefects = async (payload, filtersAdded, defectPageNo, state) => {
    let page = defectPageNo,
      size = 10;
    try {
      let responseObject = await getDefectsData(
        projectId,
        licenseID,
        payload,
        page,
        size,
        // "CLOSED"
        state
      );
      console.log("responseObject", responseObject.data.responseObject);
      if (responseObject?.data?.responseCode === 200) {
        setMyDefectlastpage(responseObject?.data?.lastPage);
        setMyDefectPageNo(page + 1);
        defectState = state;
        DefectPayload = payload;
        setdefects(responseObject.data.responseObject);
        data = [...responseObject.data.responseObject];
        let tempArray = [];
        data.map((defect, index) => {
          tempArray.push({
            bugId: defect?.tcDetails?.ID,
            bugSummary: defect.tcDetails?.Summary,
            createdBy: defect?.tcDetails["Created By"]?.name,
            Details: defect,
          });
        });
        if (page === 0 || filtersAdded) {
          setdefectsTableData([...tempArray]);
          getAllDefectsCount(payload, filtersAdded, defectPageNo, state);
        } else {
          let defectArray = [];
          defectArray = defectsTableData.concat(tempArray);
          setdefectsTableData(defectArray);
        }
      } else {
        setdefectsTableData([]);
      }
    } catch (err) {
      console.log("error");
    }
  };
  const getAllDefectsCount = async (
    payload,
    filtersAdded,
    defectPageNo,
    state
  ) => {
    try {
      let responseObject = await getDefectsDataCount(
        projectId,
        licenseID,
        payload,
        state
      );
      console.log("responseObject count", responseObject);
      if (responseObject?.data?.responseCode === 200) {
        setDefectsCount(responseObject?.data?.totalDefectCount);
      }
    } catch (err) {
      console.log("error");
    }
  };
  const getAllUsersList = async () => {
    try {
      let response = await getAllUsersListhierarchy(crowdProjectId, emailId);

      if (response.data) {
        console.log("users data", response.data);
        let data = [],
          tempMobile = [],
          tempWeb = [];
        response.data.map((obj) => {
          if (obj.configuration !== null) {
            obj.configuration.configPlatformDetails.map((obj1) => {
              if (
                obj1.deviceType === "smartphone" ||
                obj1.deviceType === "tablet"
              ) {
                tempMobile.push(obj1);
              } else {
                tempWeb.push(obj1);
              }
            });
            setWebPlatform(tempWeb);
            setMobilePlatform(tempMobile);
          }
        });
      }
    } catch (error) {
      console.log("error");
    }
  };
  const getDefaultDefectTemplate = async (e) => {
    try {
      segmentDataHandler(props.data);
      let response = await getAllDefectTemplate(projectId, licenseID);
      if (response?.data?.responseCode === 200) {
        localStorage.setItem(
          "Default-defect-template",
          JSON.stringify(response.data.responseObject.defaultDefectTemplate)
        );
        let tempDetails =
          response.data.responseObject.defaultDefectTemplate.tcDetails;

        tempDetails.map((obj, i) => {
          if (obj.label === "Severity") {
            setSeverity(
              obj.options !== undefined && obj.options.length !== 0
                ? obj.options
                : null
            );
          }
          if (obj.label === "Priority") {
            setPriority(
              obj.options !== undefined && obj.options.length !== 0
                ? obj.options
                : null
            );
          }
        });
      }
    } catch (error) {
      console.log("error");
    }
  };

  let coldata = [
    {
      sl: "1",
      testdescription:
        "Verify user is able to create template from all project level.",
      steps:
        "Navigate to all project level. Click on + Template button. Click on Create button",
      testdata: "",
      exceptedresults: "",
    },
    {
      sl: "2",
      testdescription:
        "Verify user is able to create template from individual project level.",
      steps:
        "Navigate to all project level. Click on + Template button. Click on Create button",
      testdata: "",
      exceptedresults: "",
    },
    {
      sl: "3",
      testdescription:
        "Verify user is able to create template from all project level.",
      steps:
        "Navigate to all project level. Click on + Template button. Click on Create button",
      testdata: "",
      exceptedresults: "",
    },
  ];
  let columns = React.useMemo(
    () => [
      {
        Header: "SL",
        accessor: "sl",
        width: "5%",
      },
      {
        Header: "Test Description",
        accessor: "testdescription",
        width: "45%",
      },
      {
        Header: "Test Steps",
        accessor: "steps",
        width: "35%",
      },
      {
        Header: "Test Data",
        accessor: "testdata",
        width: "5%",
      },
      {
        Header: "Expected Results",
        accessor: "exceptedresults",
        width: "10%",
      },
    ],
    []
  );
  let data = React.useMemo(() => coldata, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  function CloseDefectFilterModal(state) {
    setShowFilter(state);
  }

  function updatetableData(state, value) {
    let tempEle = document.getElementById("defectlog");
    tempEle.scrollIntoViewIfNeeded({
      behavior: "auto",
      block: "start",
      inline: "nearest",
    });
    if (value === "approved") {
      setDisplayapproverdefect(state);
      setMyDefectlastpage();
      setMyDefectPageNo(0);
      getAllDefects(DefectPayload, false, 0, "CLOSED");
    } else {
      setDisplayapproverdefect(state);
      setMyDefectlastpage();
      setMyDefectPageNo(0);
      getAllDefects(DefectPayload, false, 0, "REJECTED");
    }
  }

  return (
    <div className="tcDetails">
      <div className="reporttablelistOutline">
        <div className="reportTablecontainer ">
        <table className="testStepTable  w-full">
          <thead
            style={{
              display: "table-header-group",
              verticalAlign: "middle",
              position: "sticky",
              top: "-1px",
              zIndex: "1",
            }}
            className="bg-gray-100"
          >
            <tr key={"tableHeader"}>
                {TemplateData.testSteps?.headers?.map((data, index) => {
                
                return (
                  !["actual result", "status"].includes(
                    data?.toLowerCase()
                  ) && (
                    <>
                      <th
                        className="p-2 testStepTable common-label-text fontPoppinsMediumMd text-blue-700"
                        key={data + index}
                      >
                        {data}{" "}
                      </th>
                    </>
                  )
                );
              })}
            </tr>
          </thead>
          <tbody
            style={{
              height: "auto",
              overflowY: "auto",
              display: " table-row-group",
              verticalAlign: "middle",
            }}
          >
            {tableRowData?.map((rowData, index) => (
              <tr
                style={{ display: " table-row", verticalAlign: "inherit" }}
                className={`h-6 testStepTable ${
                  (index + 1) % 2 === 0
                    ? "test-case-table-even-row"
                    : "test-case-table-odd-row"
                }`}
                key={index}
              >
                  {TemplateData.testSteps?.headers?.map(
                    (cellname, colIndex) => {
                  return (
                    !["actual result", "status"].includes(
                      cellname?.toLowerCase()
                    ) && (
                      <>
                        <td
                          className="testStepTable fontPoppinsRegularSm text-left pl-2 table-cell-text focus:border-opacity-0 focus:outline-none"
                          key={index + colIndex}
                          suppressContentEditableWarning={true}
                        >
                          {Array.isArray(rowData[cellname])
                            ? rowData[cellname].map((data) => (
                                <>
                                  {data}
                                  <br />
                                </>
                              ))
                            : rowData[cellname]}{" "}
                        </td>
                      </>
                    )
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        
        </div>
      </div>

     
    </div>
  );
}

export default TCUserDetailsTabel;
