import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@material-ui/core";
import "./feedback.scss"

// import { FORM_FIELDS } from "../../pages/config/defectConstant";
import { FORM_FIELDS } from "../../config/defectConstant";
import Rating from "@mui/material/Rating";
import RadioButtonField from "./radioButttonField";
import { CHART_COLOR} from "../../../util/app-constants";
import FeedbackTextAnswer from "./feedback-Text-Answer";
import Avatar from '@mui/material/Avatar';

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { TextareaAutosize } from "@mui/material";
import NoDefectIcon from "../../../assets/reportsicon/no_reports_icon.png";

import { ReactComponent as QuestionIcon1} from "../../../assets/reportsicon/question_icon/question_icon_1.svg";
import { ReactComponent as QuestionIcon2} from "../../../assets/reportsicon/question_icon/question_icon_2.svg";
import { ReactComponent as QuestionIcon3} from "../../../assets/reportsicon/question_icon/question_icon_3.svg";
import { ReactComponent as QuestionIcon4} from "../../../assets/reportsicon/question_icon/question_icon_4.svg";
import { ReactComponent as QuestionIcon5} from "../../../assets/reportsicon/question_icon/question_icon_5.svg";

import { ReactComponent as Avatar1} from "../../../assets/reportsicon/avatar/avatar1.svg";
import { ReactComponent as Avatar2} from "../../../assets/reportsicon/avatar/avatar2.svg";
import { ReactComponent as Avatar3} from "../../../assets/reportsicon/avatar/avatar3.svg";



function FeedbackReports(props) {
  console.log("FeedbackReportsContainer",props)
  const [detailsViewData, setdetailsViewData] = useState([]);
  const [defectTemplate, setDefectTemplate] = useState([]);
  const { TEXTBOX, TEXTEDITOR } = FORM_FIELDS;
  let [question,setQuestion]=useState();
  let [answerPopup,setAnswerPopup]=useState(false)
  let projectID = JSON.parse(localStorage["selected-project"]).id;
  
 

  useEffect(() => {
   
     getTemplateDetails();
  }, []);
  function onCloseAnswerPopUp(state){

    setQuestion()
    setAnswerPopup(state)
  }
  const getTemplateDetails = () => {
  
    setDefectTemplate(props?.feedbackTemplate?.templateDetails);
    mapKeyAndValue(props?.feedbackTemplate?.templateDetails, props?.feedbackReportData?.resultDetails);
  };


  function getAvatarIcons(val){
    switch(val){
      case 0:{
        return(
          <Avatar1/>
        )
      
      }
      case 1:{
        return(
          <Avatar2/>
        )
      
      }
      case 2:{
        return(
          <Avatar3/>
        )
      
      }
     
      default:
        return(
          <Avatar1/>
        )
      
    }
  }

  
  function getQuestionIcons(val){
    let index=val%5;
    switch(index){
      case 0:{
        return(
          <QuestionIcon1/>
        )
      
      }
      case 1:{
        return(
          <QuestionIcon2/>
        )
      
      }
      case 2:{
        return(
          <QuestionIcon3/>
        )
      
      }
      case 3:{
        return(
          <QuestionIcon4/>
        )
      
      }
      case 4:{
        return(
          <QuestionIcon5/>
        )
      
      }
      default:
        return(
          <QuestionIcon1/>
        )
      
    }
  }
 
  function mapKeyAndValue(Templatedata, result) {
    // console.log("defectTemplateData", Templatedata);
    // console.log("defectDetails", result);
   
    let temparray = [];
    let textAnswer=props?.feedbackAnswers
    if(result===""){
      setdetailsViewData(Templatedata);

    }else{
      Templatedata?.map((item) => {
        if (item.type === "text") {
           textAnswer.map((val)=>{
            if(val.question===item.label){
              item.result = val.answer;
            }

           })
         

        } else {
          result?.map((obj) => {
            if (item.label === obj.question) {
              item.result = obj;
            }
          });
        }
      });
      setdetailsViewData(Templatedata);
    }
    

    

    
  }

  
  
  const detailViewComponents = (data) => {
    console.log(" detailViewComponents", data);
    let tempArray = [];
    let optionsArray = [];
    let userCount = 0;
    if (data.type === "radioButton" && data.result) {
      userCount = data?.result?.usersCount;
      data?.options.map((item) => {
        data?.result?.analytics.map((obj) => {
          if (item === obj.value) {
            optionsArray.push(item);
            tempArray.push({
              value: obj.value,
              noOfUsers: obj.noOfUsers,
              percentage: obj.percentage,
            });
          }
        });
      });
      data?.options.map((option) => {
        if (!optionsArray.includes(option)) {
          tempArray.push({
            value: option,
            noOfUsers: 0,
            percentage: 0,
          });
        }
      });
    }

    switch (data.type) {
      case "ratings": {
        if (data.result) {
          return (
            <div className="flex grid grid-cols-12">
              <div className="col-span-12 flex justify-end mr-4">
              <div className="col-span-6">
                <Rating value={data.result.ratings} readOnly size="large" />
              </div>
              <Tooltip title={`${data?.result?.usersCount} ${data?.result?.usersCount===1?"user":"users"} provided ratings`} placement="top">
              <div className="col-span-6 feedback_rating_text cursor-default">{data.result.ratings} out of 5</div>
              </Tooltip>
              
            </div>
              </div>
              
          );
        } else {
          return(
            <div className="flex grid grid-cols-12 mr-4">
            <div className="col-span-12 flex justify-end mr-4">
              <div className="col-span-6">
              <Rating name="no-value" value={0} readOnly size="large" />
              </div>
              <Tooltip title="No one answered for this question" placement="top">
            <div className="col-span-6 feedback_rating_text cursor-default">0 out of 5</div>
            </Tooltip>
            </div>
           
           
            
          </div>
          )
          
        }
      }
      case "text": {
        console.log("switch",data?.result)
        return (
           <div className="mb-2">
            {
                data?.result?.map((item,index)=>{
                  return(
                    <div className="grid grid-cols-12 pl-2 mb-4">
                      <div className="col-span-12 flex mb-1">
                        <div className="avatar_icon">
                          {
                           getAvatarIcons(index)
                          }
                          </div>
                        <div className="feedback_username px-1 pt-1">{item?.userName}</div>
                      </div>
                      <div className="col-span-12 pl-2">
                      <TextareaAutosize
                          value={item?.value}
                          readOnly={true}
                          className="answer_text_feedback"
                          style={{
                            resize: "none",
                            outline: "none",
                             width: "100%",
                             background:"#FFF"
                            // background:"#F8F9FF"
                          }}
                        />
                      </div>
                    </div>
                  )
                })
            }

           </div>
        
        );
      }
      case "radioButton": {
        if (data.result) {
          return (
            <div className="grid grid-cols-12">
              <div className={`col-span-12 flex justify-end ${(data?.options?.length===2 && data?.options?.includes("Yes","No"))||(data?.options?.length===5 && data?.options?.includes("Good", "Very Good","Bad","Average","Worst"))?"":"grid grid-cols-12"}`}>
              <RadioButtonField
                data={data}
                tempArray={tempArray}
                userCount={userCount}
                hasData={true}
              />
              </div>
             
            </div>
          );
        } else {
          return (
            <div className="grid grid-cols-12">
              <div className={`col-span-12 flex justify-end ${(data?.options?.length===2 && data?.options?.includes("Yes","No"))||(data?.options?.length===5 && data?.options?.includes("Good", "Very Good","Bad","Average","Worst"))?"":"grid grid-cols-12"}`}>
              <RadioButtonField
                data={data}
                hasData={false}
              />
              </div>
             
            </div>

          );
        }
      }
      default:
        return <div>--</div>;
    }
    
  };
  const detailViewElements = (data,index) => {
    console.log("detailViewElements",data)
    
    if(data?.type!=="text"){
      return (
        <>
          <div className="flex grid grid-cols-12 feedback_question_container">
                     
            <div className="flex col-span-7 grid grid-cols-12 gap-2">
             
              <div className="flex col-span-11">
              <div className="ml-2 mr-4">
              {
                getQuestionIcons(index)
              }
              </div>
                <label>
                  {data ? (
                    <div className="feedback_questions ml-1">
                      {data?.label}
                    </div>
                  ) : null}
                </label>
              </div>
            </div>
            {/* <div className="col-span-1"></div> */}
            <div className="col-span-5">{detailViewComponents(data)}</div>
          </div>
        </>
      )
    }else{
      return(
       
      <div className="text_answer_container">
         <>
        <div className="flex grid grid-cols-12 feedback_question_container">
                   
          <div className="flex col-span-11 grid grid-cols-12 gap-2">
           
            <div className="flex col-span-11">
            <div className="col-span-1 ml-2 mr-4">
            {
                getQuestionIcons(index)
              }
            </div>
              <label>
                {data ? (
                  <div className="feedback_questions ml-1">
                    {data?.label}
                  </div>
                ) : null}
              </label>
            </div>
          </div>
          <div className={`col-span-1 see_all_btn flex justify-end mr-2 ${data?.result===null || data?.result?.length<3?"hidden":"block"}`} onClick={() => {
                setQuestion(data.label);
                setAnswerPopup(true)
              
            }}>See all {">"}</div>
        </div>
        {
          data.result===null ? (
            <div className="nofeebackIconCenter">
            <img  alt="warning" src={NoDefectIcon}/>
            <div className="text_msg">No Relevant Data Is Found !</div>
          </div>
          ):(
            <div className="col-span-12  feedback_text_answer_container">
              <div>{detailViewComponents(data)}</div>
            </div>
          )
        }
        {/* <div className="col-span-12  feedback_text_answer_container">
          {
            data.result===null ?
            (
              
              <div className="noreportIconCenter">
              <img  alt="warning" src={NoDefectIcon}/>
              <div className="text_msg">No Relevant Data Is Found !</div>
            </div>
            ):(
              <div>{detailViewComponents(data)}</div>
            )
          }
        
      
        </div> */}
      </>
      </div>
  
      )
     
    }
   
  };
  return (
    <div className="feedback_report">
      {
        <div className="pr-4">
          <Grid
            container
            spacing={{ xs: 2, md: 3, sm: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {detailsViewData &&
              detailsViewData.length > 0 &&
              detailsViewData.map((data, index) => {
                return (
                  <Grid item xs={12} sm={12} md={12} key={index}>
                    {detailViewElements(data,index)}
                  </Grid>
                );
              })}
          </Grid>
        </div>
      }
      {
        answerPopup && (
            <FeedbackTextAnswer question={question} closemodal={onCloseAnswerPopUp}/>
        )
      }
    </div>
  );
}
export default FeedbackReports;
