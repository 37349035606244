import { Tooltip } from "@material-ui/core"

export const getLabel = (value) => {
    if (value?.length > 25) {
        return (
            <div title={value} placement='top'>
                <span> {value.slice(0, 23)}... </span>
            </div>
        )
    } else {
        return <span> {value}</span>
    }
}
export const getOptionLabel = (value) => {
    if (value?.length > 20) {
        return (
            <div title={value} placement='top'>
                <span> {value.slice(0, 17)}... </span>
            </div>
        )
    } else {
        return <span> {value}</span>
    }
}

export const getFeedbackQuestions = (value) => {
    return <span>{value}</span>
}
