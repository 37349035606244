import React, { useState, useEffect } from "react";
import { useTable, useGlobalFilter } from "react-table";
import Close from "@mui/icons-material/Close";
// import Search from "@mui/icons-material/Search";
import BugReportIcon from "@mui/icons-material/BugReport";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./report-details.scss";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  getUserDetailsByEmailID,
  getTestcaseDetailsById,
  getAllTestCaseTemplate,
} from "../../api/api-services";
import DefectFilterModal from "./defect-filter-modal";
import DefectsDeatilsPopup from "../modal/defects-deatils-popup";
import UserDeatilsPopup from "../modal/user-deatils";
// import { ReactComponent as NoDefectIcon } from "../../assets/noDefectsAvailable.svg";
import NoDefectIcon from "../../assets/reportsicon/no_reports_icon.png";
import IconButton from "@mui/material/IconButton";
import TCReportDetails from "../modal/tcreport-details-popup";
import TCUserDeatilsPopup from "../modal/tcuser-details";
import SearchField from "../common/search-field";
import { ReactComponent as Search } from "../../assets/search.svg";

function TestcaseExpandModuleTable(props) {
  let [showFilter, setShowFilter] = useState(false);
  let [showSearch, setShowSearch] = useState(false);

  let loggedinUser = JSON.parse(localStorage["user-privilege"]);

  let [defectsPopUp, setDefectsPopUp] = useState(false);
  let [defectsdetails, setDefectsdetails] = useState();
  let [userDetails, setUserDetails] = useState();
  let [userPopup, setUserPopup] = useState(false);
  let [tcreportPopUp, setTCReportPopUp] = useState(false);
  let [tcreportdetails, setTCReportdetails] = useState();
  let [tcuserPopup, setTCUserPopup] = useState(false);
  let [testcaseData, setTestcaseData] = useState(props?.data);
  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  let [testScriptStatus, setTestScriptStatus] = useState();
  let [Tclabel, setTclabel] = useState();
  let [testcaseDetails, setTestaCaseDetails] = useState();
  let [resultPayload, setResultPaylod] = useState({
    modules: [],
    location: [],
    users: [],
    executionResult: [],
    testCaseId: [],
  });
  const [templateTableData, setTemplateTableData] = useState({});
  const [testCaseDeatilsData, setTestCaseDeatilsData] = useState({});
  let [tcResultPopup, setTCResultPopup] = useState(false);
  let [closeSearchField, setCloseSearchField] = useState(false);
  let [showcloseIcon, setShowCloseicon] = useState(false);

  if (loggedinUser?.role?.includes("_")) {
    loggedinUser.role = loggedinUser.role.replace("_", "-");
  }

  const Styles = styled.div`
    table {
      border-spacing: 0;
      border: 1px solid #707070;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        border-bottom: 1px solid #707070;
        border-right: 1px solid #707070;

        :last-child {
          border-right: 0;
        }
      }
    }
  `;
  const handlescroll = (event) => {
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
      if(globalFilter===undefined){
        props?.selectAPI()
      }
    
    }
  };

  function getExecutionResult(values) {
    let testcaseID = [];
    testcaseID.push(values.testCaseId);
    let statusvalue = [];
    if (values.executionResult === "FLAKY") {
      statusvalue.push("PASS");
      statusvalue.push("FAIL");
    } else {
      statusvalue.push(values.executionResult);
    }
    let module = [];
    module.push(values.module);

    if (props?.label === "executionResult") {
      resultPayload.modules = [];
      resultPayload.location = [];
      resultPayload.users = [];
      resultPayload.executionResult = statusvalue;
      resultPayload.testCaseId = testcaseID;
    } else if (props?.label === "module") {
      resultPayload.modules = module;
      resultPayload.location = [];
      resultPayload.users = [];
      resultPayload.executionResult = statusvalue;
      resultPayload.testCaseId = testcaseID;
    } else {
      resultPayload.modules = module;
      resultPayload.location = [];
      resultPayload.users = [];
      resultPayload.executionResult = statusvalue;
      resultPayload.testCaseId = testcaseID;
    }
    console.log("resultPayload", resultPayload);
  }
  const compareTestStepsObjects = (templateData, scriptData) => {
    let a = templateData?.headers;
    let b = scriptData?.headers;
    let testStepsObject = {};
    let matchedObject = {};
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedText = "";
      for (let j = 0; j < b.length; j++) {
        if (a[i]?.toLowerCase() === b[j]?.toLowerCase()) {
          match = true;
          matchedText = b[j];
          break;
        }
      }
      if (!match) {
        matchedObject[a[i]] = a[i];
      } else {
        matchedObject[matchedText] = matchedText;
      }
    }

    for (let i = 0; i < scriptData?.rows.length; i++) {
      const keys = Object.keys(matchedObject);
      const rowKeys = Object.keys(scriptData.rows[i]);
      for (let j = 0; j < keys.length; j++) {
        if (!rowKeys.includes(keys[j])) {
          scriptData.rows[i][keys[j]] = "";
        }
      }
    }
    testStepsObject.headers = Object.keys(matchedObject);
    testStepsObject.rows = scriptData.rows;
    return testStepsObject;
  };
  const compareTestCaseDetailsObjects = (a, b) => {
    let testCaseDetailsArray = [];
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedObject = {};
      for (let j = 0; j < b.length; j++) {
        if (
          a[i]?.label?.toLowerCase() === b[j]?.label?.toLowerCase() &&
          a[i]?.type?.toLowerCase() === b[j]?.type?.toLowerCase()
        ) {
          match = true;
          matchedObject = b[j];
          break;
        }
      }
      if (!match) {
        testCaseDetailsArray.push(a[i]);
      } else {
        a[i].value = matchedObject?.value;
        testCaseDetailsArray.push(a[i]);
      }
    }
    return testCaseDetailsArray;
  };
  const sortBasedOnOrder = (testCaseDetails) => {
    return testCaseDetails.sort((a, b) => {
      return a?.order - b?.order;
    });
  };
  function getTemplateData(scriptData, projectName) {
    getAllTestCaseTemplate("config", projectId, licenseID)
      .then((responseData) => {
        if (responseData?.data?.responseCode === 200) {
          // setdetailsViewData(
          //   responseData?.data?.responseObject?.defaultTemplate?.testCaseDetails
          // );
          let table = JSON.parse(
            JSON.stringify(
              responseData?.data?.responseObject?.defaultTemplate?.testSteps
            )
          );
          setTemplateTableData(table);
          let testCaseDetails =
            responseData?.data?.responseObject?.defaultTemplate
              ?.testCaseDetails;
          testCaseDetails = sortBasedOnOrder(testCaseDetails);
          const testSteps =
            responseData?.data?.responseObject?.defaultTemplate?.testSteps;
          setTestCaseDeatilsData({
            ...scriptData,
            manualTestCase: {
              ...scriptData.manualTestCase,
              testCaseDetails: testCaseDetails,
              testSteps: testSteps,
            },
          });
          setTCResultPopup(true);
          // createValidationSchema(testCaseDetails, testSteps, projectName);
        } else {
          console.error("Template error", responseData?.data?.message);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }

  const getScriptDeatils = async (values) => {
    let moduleId = values.module.split(/[:]/)[0];
    let ScriptId = values.testCaseId;

    try {
      let response = await getTestcaseDetailsById(
        moduleId,
        ScriptId,
        projectId,
        licenseID
      );
      // setTestcaseDetailsById(response?.data?.responseObject);
      let responseData = response?.data?.responseObject;
      let manualTestCaseObj = responseData?.manualTestCase;

      if (manualTestCaseObj) {
        setTestCaseDeatilsData(response?.data?.responseObject);
        if (Object.entries(manualTestCaseObj).length) {
          const response = await getAllTestCaseTemplate(
            "config",
            projectId,
            licenseID
          );
          if (response?.data?.responseCode === 200) {
            let testCaseDetails = compareTestCaseDetailsObjects(
              response?.data?.responseObject?.defaultTemplate?.testCaseDetails,
              manualTestCaseObj?.testCaseDetails
            );
            // setdetailsViewData(
            //   response?.data?.responseObject?.defaultTemplate?.testCaseDetails
            // );
            const testSteps = compareTestStepsObjects(
              response?.data?.responseObject?.defaultTemplate?.testSteps,
              manualTestCaseObj?.testSteps
            );
            testCaseDetails = sortBasedOnOrder(testCaseDetails);
            setTestCaseDeatilsData({
              ...responseData,
              manualTestCase: {
                ...manualTestCaseObj,
                testCaseDetails: testCaseDetails,
                testSteps: testSteps,
              },
            });
            setTCResultPopup(true);
          } else {
            console.error(
              "Get All TestCase Template error",
              response?.data?.message
            );
          }
        } else {
          getTemplateData(responseData, responseData?.name);
        }
      } else {
        getTemplateData(responseData, responseData?.name);
      }
      if (response?.data?.responseCode === 200) {
        let data =
          response?.data?.responseObject?.manualTestCase?.testCaseDetails;
        let table = JSON.parse(
          JSON.stringify(
            response?.data?.responseObject?.manualTestCase?.testSteps
          )
        );
        setTemplateTableData(table);

        setTestCaseDeatilsData(response?.data?.responseObject);
        setTCResultPopup(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function CloseDefectModal(state) {
    setDefectsPopUp(state);
  }

  function CloseTCReportModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      setGlobalFilter();
      setShowCloseicon(false);
    }
    setTCReportPopUp(state);
  }

  function CloseTesterModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      setGlobalFilter();
      setShowCloseicon(false);
    }
    setTCResultPopup(state);
  }

  function getTestCaseTemplate() {
    getAllTestCaseTemplate("config", projectId, licenseID)
      .then((response) => {
        if (response?.data?.responseCode === 200) {
          let manualTestCase = {
            testCaseDetails:
              response?.data?.responseObject?.defaultTemplate.testCaseDetails,
            testSteps:
              response?.data?.responseObject?.defaultTemplate?.testSteps,
          };
          console.log("manualTestCase", manualTestCase);
          setTCReportdetails(manualTestCase);
          setTclabel("Template");
          setTCReportPopUp(true);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }
  const getScript = (values) => {
    console.log("getScripts 123", values);
    let moduleId = values.module.split(/[:]/)[0];
    let ScriptId = values.testCaseId;
    setTestScriptStatus(values?.executionResult);
    setTestaCaseDetails(values);

    getTestcaseDetailsById(moduleId, ScriptId, projectId, licenseID)
      .then((response) => {
        console.log("Response", response);
        if (response?.data?.responseCode === 200) {
          if (
            Object.keys(response?.data?.responseObject?.manualTestCase)
              .length !== 0
          ) {
            setTCReportdetails(response?.data?.responseObject?.manualTestCase);
            setTCReportPopUp(true);
            setTclabel("Script");
          } else {
            getTestCaseTemplate();
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };
  let columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "testCaseId",
        width: "30%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              console.log(
                "e.cell.row.original.Details",
                e.cell.row.original.Details
              );
              getScript(e.cell.row.values);
              // setTCReportdetails(e.cell.row.original.Details);
              // setTCReportPopUp(true);
            }}
            className="reportTablecell popins cursor-pointer	"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "testcaseName",
        width: "59.7%",
        Cell: (e) => (
          <div title={e.value} className="datatrimmer w-28">
            {/* {e.value} */}
            {e.value}
          </div>
        ),
      },
      {
        Header: "Module",
        accessor: "module",
        width: "50%",
        Cell: (e) => (
          <div title={e.value} className="datatrimmer w-28">
            {/* {e.value} */}
            {e.value ? e.value.split(/[:]/)[1] : "--"}
          </div>
        ),
      },
      {
        Header: "No Of Testers",
        accessor: "noOfTesters",
        width: "35.7%",
      },
      {
        Header: "No Of Executions",
        accessor: "noOfExecutions",
        width: "35.7%",
        Cell: (e) => (
          <div title={e.value} >
            {e.value}
          </div>
        ),
      },
      {
        Header: "Execution Result",
        accessor: "executionResult",
        width: "40%",
        Cell: (e) => (
          <div
            // title={e.value}
            className="cursor-pointer"
            style={{
              color:
                e.value === "PASS"
                  ? "green"
                  : e.value === "FAIL"
                  ? "red"
                  : e.value === "FLAKY"
                  ? "orange"
                  : "gray",
            }}
            onClick={(event) => {
              console.log(e);
              event.preventDefault();
              getExecutionResult(e.cell.row.values);
              getScriptDeatils(e.cell.row.values);
              // getUserDetails(e.cell.row.original);
              // setTCUserPopup(true);
            }}
          >
            <Tooltip
               title={`Pass:${e.cell.row.original.passedExecutions},Fail:${e.cell.row.original.failedExecutions}`}
              placement="bottom-start"
            >
              <div>{e.value !== null && e.value !== "" ? e.value : "--"}</div>
            </Tooltip>
          </div>
        ),
      },
    ],
    []
  );
  // let data = React.useMemo(() => testcaseData, [testcaseData]);
  // let data = React.useMemo(() => defectsTableData, [defectsTableData]);
  let data = React.useMemo(() => props?.data, [props?.data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  function CloseDefectFilterModal(state) {
    setShowFilter(state);
  }

  return (
    <div className="commonExecutionTable mx-2 mt-4 mb-2">
      <div className="defecttablelistOutline">
        <div className="pt-5 pb-6 pl-3 flex items-center">
          {/* <span className="darkBlue pr-3 pt-2 heading">{props.header} - {data.lenght}</span> */}
          <div className="darkBlue pr-3 heading-tabel">
            Total Testcases - {props?.TotalCount}
          </div>
          <span className="float-right  ml-auto flex items-center mr-2">
            <>
              <div className="col-span-8  grid grid-cols-10 header_btn">
                <div className="col-span-10">
                  <div className="execution_analytics_search flex justify-end">
                    <div>
                      <input
                        type="text"
                        className="searchfield"
                        value={globalFilter || ""}
                        autoFocus={false}
                        onChange={(e) => {
                          setGlobalFilter(e.target.value);
                        }}
                        placeholder="Search"
                      />
                    </div>
                    <div className="search_icon">
                      <Search />
                    </div>
                  </div>
                </div>
              </div>
            </>
          </span>
        </div>
        <div className="ExecutionreportsTablecontainer">
          <table {...getTableProps()} className=" overflow-x-auto">
            <thead className="text-left  h-8 execution_table_header">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      width={column.width}
                      {...column.getHeaderProps()}
                      className={`reportTableheader popins-semi-bold text-lg font-semibold ${
                        column.Header === "ID" ? "text-left pl-2" : "text-left"
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {data.length === 0 ? (
              <div className="grid grid-cols-12 nodefect_data">
                     
              <div className="col-span-12 flex justify-center">
              <img  alt="warning" src={NoDefectIcon}/>
              
            </div>
            <div className="col-span-12 flex justify-center execution_expand_module_msg">No Relevant Data Is Found !</div>
                </ div>
            ) : (
              <div>
                <tbody {...getTableBodyProps()} onScroll={handlescroll}>
                  {rows.length > 0 ? (
                    <>
                      {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            className="h-10 "
                            id={props.label + index}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  width={cell?.column?.width}
                                  {...cell.getCellProps()}
                                  className={`datatrimmer reportTablecell ${
                                    cell.column.Header === "Bug ID" ||
                                    cell.column.Header === "Created By"
                                      ? "reportBugIdColumn popins-semi-bold"
                                      : "popins"
                                  } ${
                                    cell.column.Header === "ID"
                                      ? "text-left pl-2":(cell.column.Header === "Name"|| cell.column.Header === "Module")?"text-left"
                                      : "text-left pl-1.5"
                                  }`}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <div className="grid grid-cols-12 nodefect_data">
                     
                  <div className="col-span-12 flex justify-center">
                  <img  alt="warning" src={NoDefectIcon}/>
                  
                </div>
                <div className="col-span-12 flex justify-center execution_expand_module_msg">No Relevant Data Is Found !</div>
                    </ div>
                  )}
                </tbody>
              </div>
            )}
          </table>
          {/* <Styles>
            </Styles> */}
        </div>
      </div>

      {tcreportPopUp && (
        <TCReportDetails
          CloseTCReportModal={CloseTCReportModal}
          SelectedDefect={tcreportdetails}
          testScriptStatus={testScriptStatus}
          label={Tclabel}
          testcaseDetails={testcaseDetails}
        />
      )}
      {tcResultPopup && (
        <TCUserDeatilsPopup
          CloseTesterModal={CloseTesterModal}
          testerDeatils={userDetails}
          payload={resultPayload}
          testCaseTemplate={testCaseDeatilsData}
        />
      )}
    </div>
  );
}

export default TestcaseExpandModuleTable;
