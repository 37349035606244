import React, { useState, useEffect } from "react";
import "./project.scss";
// import { ReactComponent as NoTestCaseIcon } from "../../assets/noTestCaseIcon.svg";
import { ReactComponent as NoTestCaseIcon } from "../../assets/reportsicon/noReportsAvailable.svg";
import { ReactComponent as AddIcon } from "../../assets/addIcon.svg";
import "../testCase/testcases.scss";
import CreateModule from "../testCase/modals/create_module";
import { useLocation, useNavigate } from "react-router-dom";
import TableTree from "../common/table_tree/table_tree";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { ReactComponent as EditIcon } from "../../assets/editBlack.svg";
import { ReactComponent as DeleteIcon } from "../../assets/deleteBlack.svg";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import MoreInfoButton from "../common/more-info-button";
import { plusIconArray } from "../../util/app-constants";
import ContextMenu from "../modal/contex-modal";
import { Tooltip } from "@mui/material";
import ModuleDeatilsPopup from "../testCase/modals/module_details";
import CreatTeastCase from "../testCase/modals/create-testcase";
import Asignee_testCase from "../testCase/modals/asignee_testCase";
import AssigneeUserListModal from "./AssigneeUserListModal";
import {
  deleteModuleReq,
  getAllModuleTreeReq,
  getExpandedModuleReq,
  gethierarchyModuleReq,
  getModuleAndChildModuleReq,
  getModuleByIdReq,
  getModuleDetailsByIdReq,
  deleteTestCaseReq,
  getTestcaseDetailsById,
  getAllLeadUser,
  getUserHierarchyReq,
  getModuleAndChildModuleByEmailReq,
  gethierarchyModuleByEmailReq,
  getExpandedModuleByEmailReq,
  getAllModuleTreeByEmailReq,
  getModuleIdByEmailReq,
  getAllTestCaseTemplate
} from "../../api/api-services";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from "react-redux";
import WarningModal from "../common/warning-modal";
import {
  modifyIndividualTreeData,
  modifyModuleIdsData,
  moduleIds,
} from "../../actions/individual-tree-Slice";
import {
  fireCrowdUserData,
  selectedProjectData,
} from "../../actions/firecrowd-User-slice";
import { Email } from "@material-ui/icons";
import { ReactComponent as ModuleIcon } from "../../assets/testcase_icon.svg";
import { ReactComponent as AssigneIcon } from "../../assets/assigne_icon.svg";
import ExecutionListModule from "../testCase/modals/executionList_module";

function ProjectTestCases(props) {
  const navigate = useNavigate();
  const [createModuleModal, setCreateModuleModal] = useState(false);
  const [pageTree, setPageTree] = useState([]);
  const [allPageTree, setAllPageTree] = useState([]);
  const [rootData, setRootData] = useState({});
  const [treeValue, setTreeValue] = useState("");
  const [isExpandAll, setIsExpandAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [parentData, setParentData] = useState();
  const [selectedData, setSelectedData] = useState();
  const [selectedNode, setSelectedNode] = useState();
  const [moduleIdForAdd, setModuleIdForAdd] = useState();
  const [TestcaseCreateModal, setTestcaseCreateModal] = useState(false);
  const [testcaseAsigneeModal, setTestcaseAsigneeModal] = useState(false);
  const [TestcaseEditeModal, setTestcaseEditModal] = useState(false);
  const [testcaseDetails, setTestcaseDetails] = useState();
  const [selectedTemplate, setselectedTemplate] = useState([]);
  let [moretypes, setMoreTypes] = useState(false);
  let [usersAssignedList, setUsersAssignedList] = useState();
  const [testcaseParentData, settestcasePrentData] = useState();
  let selectedproject = JSON.parse(localStorage["selected-project"]);
  let selectedUser = JSON.parse(localStorage["selected-user"]);
  let loggedInUser = JSON.parse(localStorage["fire-crowd-user"]);
  const [renderApiDependency, setRenderApiDependency] = useState(false);
  const [hierarchyLoaded, setHierarchyLoaded] = useState(false);
  const search = window.location.search;
  const id = new URLSearchParams(search).get("id");
  const [moduleDetailsModal, setModuleDetailsModal] = useState(false);
  const [mode, setMode] = useState();
  let [warningPopup, setWarningPopup] = useState(false);
  let location = useLocation();
  const dispatch = useDispatch();
  const moduleIdsData = useSelector(moduleIds);
  const fireCrowdUser = useSelector(fireCrowdUserData);
  const selectedProj = useSelector(selectedProjectData);
  const localStorageSelectedProject = JSON.parse(
    localStorage.getItem("selected-project")
  );
  let proj =
    Object.keys(selectedProj).length > 0
      ? selectedProj
      : localStorageSelectedProject;
  const [testCasewarningPopup, setTestCaseWarningPopup] = useState(false);
  const [moduleID, setModuleID] = useState([]);
  const [payload, setpayload] = useState([]);
  const [checkedNodes, setCheckedNodes] = useState([]);
  

  // let [removeModule,setRemoveModule]=useState([]);
  let removeModule = [];
  const [detailsType, setDetailsType] = useState();
  const [childnode, setChildNode] = useState([]);
  let myrole = JSON.parse(localStorage.getItem("selected-user")).role;
  let emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
  let create_action = JSON.parse(localStorage.getItem("user-privilege"))
    .testcases.actions.create;
  let project_status = JSON.parse(
    localStorage.getItem("selected-project")
  ).projectStatus;

  let edit_action = JSON.parse(localStorage.getItem("user-privilege")).testcases
    .actions.edit;
  let delete_action = JSON.parse(localStorage.getItem("user-privilege"))
    .testcases.actions.delete;
  let projectstate = JSON.parse(
    localStorage.getItem("selected-project")
  ).projectStatus;


  const [detailsViewData, setdetailsViewData] = useState([]);
  const [templateTableData, setTemplateTableData] = useState({});
  const [testCaseData, setTestCaseData] = useState({});
  const [testcaseDetailsById, setTestcaseDetailsById] = useState();
  const [executionListPopUp,setExecutionListPopUp]=useState(false)












  const onNodeSelected = (nodeObj) => {
    if (location.pathname === "/project/testcases") {
      localStorage.removeItem("moduleData");
    }
    if (nodeObj.data.testCaseType) {
      navigate(
        `/project/testcases/testCase-Details?modeType="view"&moduleId=${nodeObj?.data?.parentId}&scriptId=${nodeObj.node.key}`,
        { replace: true }
      );
    } else if (nodeObj.data.folder === false) {
      //  navigate(`/project/testCases/testCase-Details?modeType=view&moduleId=MOD1054&scriptId=SCR1033`,{replace: true,});
    } else {
      let parentData = [];
      let parentIds = [];
      if (localStorage.getItem("moduleData") !== null) {
        // parentData = JSON.parse(localStorage.getItem("moduleData"));
      }
      if (moduleIdsData.length > 0) {
        parentIds = [...moduleIdsData];
      }
      let returnParentName = (data) => {
        let title;
        if (data.title !== "root") {
          title = data.title;
          if (data.folder) {
            parentData.unshift({
              Name: data.title,
              id: data.key,
              // parentId:data.data.parentId,
              type: "page",
              ...data?.data,
            });
            if (!parentIds.includes(data.key)) {
              parentIds.push(data.key);
              dispatch(
                modifyIndividualTreeData(
                  JSON.parse(
                    JSON.stringify({
                      Name: data.title,
                      id: data.key,
                      type: "page",
                      ...data?.data,
                    })
                  )
                ) || 0
              );
            }
          } else {
            parentData.unshift({
              Name: data.title,
              id: data.key,
              parentId: data.data.parentId,
            });
            if (!parentIds.includes(data.key)) {
              parentIds.push(data.key);
              dispatch(
                modifyIndividualTreeData(
                  JSON.parse(
                    JSON.stringify({
                      Name: data.title,
                      id: data.key,
                      parentId: data.data.parentId,
                    })
                  )
                ) || 0
              );
            }
          }
          // parentName.push(encodeURIComponent(title))
          returnParentName(data.parent);
        }
      };
      returnParentName(nodeObj.node);
      dispatch(modifyModuleIdsData(parentIds) || 0);
      localStorage.setItem("moduleData", JSON.stringify(parentData));
      let moduleId = nodeObj?.data?.searchKey
        .substr(8)
        .replace(/^\/([^/]+)\/.*$/, "$1");

      navigate(
        `/project/testcases${nodeObj?.data?.searchKey.substr(8)}?id=${
          nodeObj.node.key
        }`
      );
    }
  };
  const closeModal = (val) => {
    if (moduleDetailsModal) {
      setModuleDetailsModal(val);
    } else if (warningPopup) {
      setSelectedData();
      setMode("");
      setParentData();
      setModuleIdForAdd("");
      setWarningPopup(val);
    }
  };
  const closeWarningModal = (val) => {
    setTestCaseWarningPopup(val);
  };
  const onRootSelected = (nodeObj) => {
    console.log(nodeObj);
  };
  function functiontypeModalClose(status) {
    setMoreTypes(status);
  }
  // const getUserHierarchy = async () => {
  //   let selectedhirarchy;
  //   try {
  //     let response = await getUserHierarchyReq();
  //     if (response.data) {
  //       response.data.map((value) => {
  //         let userList = Object.keys(value?.template);
  //         let name = userList.join(">");
  //         if (
  //           selectedproject?.usersHierarchyTemplateID &&
  //           value.id === selectedproject?.usersHierarchyTemplateID
  //         ) {
  //           selectedhirarchy = value;
  //           setselectedTemplate([{ ...value, label: name }]);
  //         }
  //       });
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  //   setHierarchyLoaded(true);
  // };
  const [leadUserResponse, setLeadUserResponce] = useState([]);
  let getUserLead = async () => {
    let leadData;
    let roles = Object?.keys(selectedTemplate[0]?.template);
    if(myrole!=="TESTER"){
      try {
        let response = await getAllLeadUser(
          selectedproject?.id,
          loggedInUser?.userName
        );
        console.log("getUserLead",response)
        setLeadUserResponce(response.data);
      } catch (err) {
        console.error(err);
      }
    }
    
  };

  // useEffect(() => {
  //   if (hierarchyLoaded && myrole !== "TESTER") {
  //     getUserLead();
  //   }
  // }, [hierarchyLoaded]);

  function findchildren(node) {
    if (node.children !== null) {
      if (node.selected) {
        if (!moduleID.includes(node.key)) {
          let obj = {
            moduleId: node.key,
            scriptIds: [],
          };
          payload.push(obj);
          moduleID.push(node.key);
        }
        node.children.map((item) => {
          findchildren(item);
        });
      }
    } else {
      if (node.selected) {
        if (node.type === "Script") {
          if (moduleID.includes(node.data.parentId)) {
            let index = moduleID.indexOf(node.data.parentId);
            payload[index].scriptIds.push(node.key);
          } else {
            let obj = {
              moduleId: node.data.parentId,
              scriptIds: [node.key],
            };
            payload.push(obj);
            moduleID.push(node.data.parentId);
          }
        } else {
          if (!moduleID.includes(node.key)) {
            if (node.key.includes("MOD")) {
              let obj = {
                moduleId: node.key,
                scriptIds: [],
              };
              payload.push(obj);
              moduleID.push(node.key);
            }
          }
        }
      }
    }
  }

  function findparent(node) {
    if (node.title !== "root") {
      if (moduleID.includes(node.key)) {
        findparent(node.parent);
      } else {
        let obj = {
          moduleId: node.key,
          scriptIds: [],
        };
        payload.push(obj);
        moduleID.push(node.key);
        findparent(node.parent);
      }
    }
  }

  function removechild(node) {
    if (node.children !== null) {
      if (moduleID.includes(node.key)) {
        if (node.selected === false) {
          if (!removeModule.includes(node.key)) {
            removeModule.push(node.key);
          }

          node.children.map((item) => {
            removechild(item);
          });
        } else {
          node.children.map((item) => {
            removechild(item);
          });
        }
      }
    } else {
      if (node.type === "Script") {
        if (moduleID.includes(node.data.parentId)) {
          let index = moduleID.indexOf(node.data.parentId);

          let scriptIndex = payload[index].scriptIds.indexOf(node.key);
          payload[index].scriptIds.splice(scriptIndex, 1);
          if (
            !removeModule.includes(node.data.parentId) &&
            node.parent.partsel === false
          ) {
            removeModule.push(node.data.parentId);
          }
          removeparent(node.parent);
        } else {
          if (node.selected === false) {
            removeModule.push(node.data.parentId);
          }
        }
      } else {
        if (moduleID.includes(node.key) && node.selected === false) {
          removeModule.push(node.key);
        }
      }
    }
  }

  function removeparent(node) {
    if (node.title !== "root") {
      if (moduleID.includes(node.key) && node.partsel === false) {
        if (!removeModule.includes(node.key)) {
          removeModule.push(node.key);
        }
        removeparent(node.parent);
      }
    }
  }

  const onCheckedNodes = (nodeObjectss, node) => {
    setCheckedNodes([...nodeObjectss]);
    if (node?.selected) {
      if (node?.children === null) {
        if (node?.type === "Script") {
          if (moduleID.includes(node.data.parentId)) {
            let index = moduleID.indexOf(node.data.parentId);
            payload[index].scriptIds.push(node.key);
          } else {
            let obj = {
              moduleId: node.data.parentId,
              scriptIds: [node.key],
            };
            payload.push(obj);
            moduleID.push(node.data.parentId);
            findparent(node.parent);
          }
        } else {
          if (moduleID.includes(node.key)) {
            findparent(node.parent);
          } else {
            let obj = {
              moduleId: node.key,
              scriptIds: [],
            };
            payload.push(obj);
            moduleID.push(node.key);
            findparent(node.parent);
          }
        }
      } else {
        if (!node.type) {
          if (moduleID.includes(node.key)) {
            findparent(node.parent);
            node.children.map((item) => {
              findchildren(item);
            });
          } else {
            let obj = {
              moduleId: node.key,
              scriptIds: [],
            };
            payload.push(obj);
            moduleID.push(node.key);
            findparent(node.parent);
            node.children.map((item) => {
              findchildren(item);
            });
          }
        }
      }
    } else {
      if (node.children === null) {
        if (node.type === "Script") {
          if (moduleID.includes(node.data.parentId)) {
            let index = moduleID.indexOf(node.data.parentId);
            let scriptIndex = payload[index].scriptIds.indexOf(node.key);
            payload[index].scriptIds.splice(scriptIndex, 1);
            if (
              !removeModule.includes(node.data.parentId) &&
              node.parent.partsel === false
            ) {
              removeModule.push(node.data.parentId);
            }
            removeparent(node.parent);
          }
        } else {
          if (node.selected === false) {
            removeModule.push(node.key);
          }

          removeparent(node.parent);
        }
      } else {
        if (!node.type) {
          if (node.selected === false) {
            removeModule.push(node.key);
          }
          removeparent(node.parent);
          node.children.map((item) => {
            removechild(item);
          });
        }
      }
    }
    removeModule.map((Id) => {
      let index = moduleID.indexOf(Id);
      moduleID.splice(index, 1);
      payload.splice(index, 1);
    });
    removeModule = [];
  };
  const getTreeData = async (id, delay) => {
    let rootresponse;
    let treeresponse;
    setModuleID([]);
    setpayload([]);
    setCheckedNodes([]);
    removeModule = [];
    if (myrole === "ACCOUNT_MANAGER" || myrole === "PROJECT_OWNER") {
      let email =
        myrole === "ACCOUNT_MANAGER" ? emailId : "projectOwner@fireflink.com";
      if (id) {
        try {
          rootresponse = await getModuleByIdReq(
            id,
            true,
            true,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID
          );
        } catch (err) {
          console.error("hierarchy api error", err);
        }
        try {
          treeresponse = await getModuleByIdReq(
            id,
            true,
            false,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID
          );
        } catch (err) {
          console.error("hierarchy api error", err);
        }
        let individualTree = rootresponse?.data?.responseObject?.moduleTree;
        setAllPageTree(
          JSON.parse(
            JSON.stringify(treeresponse?.data?.responseObject?.moduleTree)
          )
        );
        setPageTree(JSON.parse(JSON.stringify(individualTree)));
        delete individualTree[0].children;
        setRootData({ ...individualTree[0] });
      } else {
        try {
          rootresponse = await gethierarchyModuleReq(
            true,
            true,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID
          );
        } catch (err) {
          console.error("hierarchy api error", err);
        }
        try {
          treeresponse = await getModuleAndChildModuleReq(
            true,
            false,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID,
            email
          );
        } catch (err) {
          console.error("hierarchy api error", err);
        }
        setPageTree([...rootresponse?.data?.responseObject?.moduleTree]);
        setAllPageTree(treeresponse?.data?.responseObject?.moduleTree);
        setRootData(rootresponse?.data?.responseObject?.moduleTree?.[0]);
      }
    } else {
      if (id) {
        try {
          rootresponse = await getExpandedModuleByEmailReq(
            id,
            true,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID,
            emailId
          );
          // rootresponse = await getModuleIdByEmailReq(
          //   id,
          //   true,
          //   true,
          //   proj?.project?.fireFlinkProjectId,
          //   proj?.licenceID,
          //   emailId
          // );
        } catch (err) {
          console.error("hierarchy api error", err);
        }
        try {
          treeresponse = await getModuleIdByEmailReq(
            id,
            true,
            false,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID,
            emailId
          );
        } catch (err) {
          console.error("hierarchy api error", err);
        }
        let individualTree = rootresponse?.data?.responseObject?.moduleTree;
        individualTree[0].lazyLoaded = true;
        setAllPageTree(
          JSON.parse(
            JSON.stringify(treeresponse?.data?.responseObject?.moduleTree)
          )
        );
        setPageTree(JSON.parse(JSON.stringify(individualTree)));
        delete individualTree[0].children;
        setRootData({ ...individualTree[0] });
      } else {
        try {
          rootresponse = await gethierarchyModuleByEmailReq(
            true,
            true,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID,
            emailId
          );
        } catch (err) {
          console.error("hierarchy api error", err);
        }
        try {
          treeresponse = await getModuleAndChildModuleByEmailReq(
            true,
            false,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID,
            emailId
          );
        } catch (err) {
          console.error("hierarchy api error", err);
        }
        setPageTree([...rootresponse?.data?.responseObject?.moduleTree]);
        setAllPageTree(treeresponse?.data?.responseObject?.moduleTree);
        setRootData(rootresponse?.data?.responseObject?.moduleTree?.[0]);
      }
    }

    setIsLoading(false);
  };

  
    
  

  useEffect(() => {
    if (
      projectstate === "INPROGRESS" ||
      projectstate === "CLOSED" || projectstate === "COMPLETED"||
      projectstate === "APPROVED"
    ) {
      if (id) {
        setIsLoading(true);
        getTreeData(id);
     
        // getUserHierarchy();
      } else if (location.pathname === "/project/testcases") {
        setIsLoading(true);
        getTreeData();
     
        // getUserHierarchy();
      }
    }
    if (localStorage.getItem("radioBtnTreeexpanded") !== null) {
      localStorage.removeItem("radioBtnTreeexpanded");
    }
  }, [id]);

  // useEffect(() => {
  //   if (!id) {
  //     console.log("get tree");
  //     setIsLoading(true);
  //     getTreeData();
  //   }
  // }, []);
  const onAddClicked = (ev, nodeObj) => {
    console.log(nodeObj);
    const selectedNode = nodeObj.node;
    selectedNode.addChildren({
      modifiedOn: "03/06/2021 11:47",
      ver: 1,
      modifiedBy: "USR1004",
      title: "Dynamic Node",
      scriptType: "Web",
      type: "stepgroup",
      folder: false,
      status: "Passed",
      key: "SCR1018",
      totalSteps: "4",
      isRecorded: "3",
    });
    selectedNode.setExpanded(true);
  };

  const onMoreClicked = async (ev, option, nodeObj) => {
    if (option === "Module Details" || option === "Testcase Details") {
      if (nodeObj.data.type === "Script") {
        let parentId = nodeObj.data.parentId;
        let scripId = nodeObj.data._key;

        try {
          let response = await getTestcaseDetailsById(
            parentId,
            scripId,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID
          );
          if (response.data.responseCode === 200) {
            setDetailsType("Script");
            setSelectedData(response.data.responseObject);
            setModuleDetailsModal(true);
          }
        } catch (err) {
          console.log("error");
        }
      } else {
        try {
          let response = await getModuleDetailsByIdReq(
            nodeObj.data._key,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID
          );
          if (response.data.responseCode === 200) {
            setDetailsType("Module");
            setSelectedData(response.data.responseObject);
            setModuleDetailsModal(true);
          }
        } catch (err) {
          console.error("hierarchy api error", err);
        }
      }
    }
    // const selectedNode = nodeObj.node;
    // selectedNode.addChildren({
    //     "modifiedOn": "03/06/2021 11:47",
    //     "ver": 1,
    //     "modifiedBy": "USR1004",
    //     "title": "Dynamic Node",
    //     "scriptType": "Web",
    //     "type":"stepgroup",
    //     "folder": false,
    //     "status":"Passed",
    //     "key": "SCR1018",
    //     "totalSteps": "4",
    //     "isRecorded": "3",
    // });
    // selectedNode.setExpanded(true);
  };
  const getTestcaseDetails = async (e) => {
    // console.log("e values",e.node.data.parentId)
    // console.log("e values",e.node.key)
    let parentId = e.node.data.parentId;
    let scripId = e.node.key;
    let projectId = JSON.parse(localStorage.getItem("selected-project")).project
      .fireFlinkProjectId;
    let licenseId = JSON.parse(
      localStorage.getItem("selected-project")
    ).licenceID;
    try {
      let response = await getTestcaseDetailsById(
        parentId,
        scripId,
        projectId,
        licenseId
      );
      if (response.status === 200) {
        setTestcaseDetails(response.data.responseObject);
        setTestcaseEditModal(true);
      }
    } catch (error) {
      console.log("error");
    }
  };
  const onEditClicked = async (ev, nodeObj) => {
    console.log("onEditClicked",nodeObj)
    if (nodeObj.node.type === "Script") {
      if (
        nodeObj?.data?.assigneeCount &&
        project_status === "INPROGRESS"
      ) {
        props.MyAlert.info(
          `The assigned testcase cannot be edited in the In-progress state`
        );
      } else {
        getTestcaseDetails(nodeObj);
        setSelectedData(nodeObj.data);
        setChildNode(nodeObj?.node?.parent?.children);
      }
    } else {
      if (nodeObj?.data?.assigneeCount && project_status === "INPROGRESS") {
        props.MyAlert.info(
          
          `The assigned module cannot be edited in the In-progress state`
        );
      } else {
        try {
          let response = await getModuleDetailsByIdReq(
            nodeObj.data._key,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID
          );
          if (response.data.responseCode === 200) {
            setSelectedData(response.data.responseObject);
          }
        } catch (err) {
          console.error("hierarchy api error", err);
        }
        setModuleIdForAdd(nodeObj.data._key);
        setMode("edit");
        setCreateModuleModal(true);
      }
    }
  };

  const deleteModule = async (data) => {
    let response;
    try {
      response = await deleteModuleReq(
        data._key,
        proj?.project?.fireFlinkProjectId,
        proj?.licenceID
      );
      if (response.data.responseObject) {
        selectedNode.node.remove();
        const search = window.location.search;
        const id = new URLSearchParams(search).get("id");
        setIsLoading(true);
        props.MyAlert.success(`${data?.title} deleted successfully`);
        getTreeData(id);
      } else {
        props.MyAlert.info(`${response.data.message}`);
      }
    } catch (err) {
      console.error(err, "delete module");
    }
  };
  const deleteTestScript = async (data) => {
    let parentID = data.parentId;
    let scriptID = data._key;
    try {
      let response = await deleteTestCaseReq(
        parentID,
        scriptID,
        proj?.project?.fireFlinkProjectId,
        proj?.licenceID
      );
      if (response?.data?.responseCode === 200) {
        const search = window.location.search;
        const id = new URLSearchParams(search).get("id");
        setIsLoading(true);
        props.MyAlert.success(
          `"${data.name} test script deleted Successfully"`
        );
        getTreeData(id);
      }
    } catch (error) {
      console.log("error");
    }
  };
  const onDeleteClicked = (ev, nodeObj) => {
    if (nodeObj.node.type === "Script") {
      if (
        nodeObj?.data?.assigneeCount &&
        (project_status === "INPROGRESS" || project_status === "APPROVED")
      ) {
        props.MyAlert.info(
          `The assigned testcase cannot be deleted in the ${project_status} state`
        );
      } else {
        setTestcaseDetails(nodeObj.data);
        setTestCaseWarningPopup(true);
      }
    } else {
      if (
        nodeObj?.data?.assigneeCount &&
        (project_status === "INPROGRESS" || project_status === "APPROVED")
      ) {
        props.MyAlert.info(
          `The assigned module cannot be deleted in the ${project_status} state`
         
        );
      } else {
        if (nodeObj?.node?.children?.length > 0) {
          props.MyAlert.info(
            `${nodeObj?.node.title} cannot be deleted as it has some sub modules`
          );
        } else {
          setSelectedNode(nodeObj);
          setSelectedData(nodeObj.data);
          setWarningPopup(true);
        }
      }
    }
  };

  const moduleMenuData = [
    {
      option: "Module Details",
    },
  ];

  const testcaseMenuData = [
    {
      option: "Testcase Details",
    },
  ];

  const actionColumnHtml = (nodeObj) => {
    return (
      <>
        {!nodeObj.isRootNode ? (
          <div className="action-col-wrp">
            <IconButton
              sx={
                false
                  ? {
                      "&:hover": {
                        opacity: "0.4",
                        backgroundColor: "#FAF9F6",
                        boxShadow:
                          " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      },
                    }
                  : {
                      "&:hover": {
                        backgroundColor: "#FAF9F6",
                        boxShadow:
                          " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      },
                    }
              }
            >
              <Tooltip
                title={
                  nodeObj.isRootNode
                    ? "Root Module cannot be edit"
                    : edit_action === false
                    ? `${myrole} cannot edit the resource`
                    : edit_action === true && (project_status === "CLOSED" || project_status === "COMPLETED")
                    ? `Project status ${project_status} cannot edit the resource`
                    : "Edit"
                }
                placement="top"
              >
                <button
                  disabled={
                    nodeObj.isRootNode ||
                    edit_action === false ||
                    (edit_action === true && (project_status === "CLOSED" || project_status === "COMPLETED"))
                  }
                  className="state-button "
                  onClick={(event) => {
                    onEditClicked(event, nodeObj);
                  }}
                >
                  <EditIcon height="15px" width="15px" />
                </button>
              </Tooltip>
            </IconButton>
            <IconButton
              sx={
                false
                  ? {
                      "&:hover": {
                        opacity: "0.4",
                        backgroundColor: "#FAF9F6",
                        boxShadow:
                          " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      },
                    }
                  : {
                      "&:hover": {
                        backgroundColor: "#FAF9F6",
                        boxShadow:
                          " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      },
                    }
              }
            >
              <Tooltip
                title={
                  nodeObj.isRootNode
                    ? "Root module cannot be delete"
                    : delete_action === false
                    ? `${myrole} cannot delete the resource`
                    : delete_action === true && (project_status === "CLOSED"|| project_status === "COMPLETED")
                    ? `Project status ${project_status} cannot delete the resource`
                    : "Delete"
                }
                placement="top"
              >
                <button
                  disabled={
                    nodeObj.isRootNode ||
                    delete_action === false ||
                    (delete_action === true && (project_status === "CLOSED"|| project_status === "COMPLETED"))
                  }
                  className="state-button "
                  onClick={(event) => {
                    onDeleteClicked(event, nodeObj);
                  }}
                >
                  <DeleteIcon height="15px" width="15px" />
                </button>
              </Tooltip>
            </IconButton>
            {/* <Tooltip title="More" placement="top"> */}
            <button
              disabled={nodeObj.isRootNode}
              className="state-button py-0 px-1 rounded-l "
            >
              <ContextMenu
                contextMenuData={
                  nodeObj.data.folder ? moduleMenuData : testcaseMenuData
                }
                func={(e, option) => {
                  onMoreClicked(e, option, nodeObj);
                }}
                onMouseOutz
              />
            </button>
            {/* </Tooltip> */}
          </div>
        ) : null}
      </>
    );
  };

  const mouseOverControll = () => {};
  const handlePlusIcon = (name, node) => {
    console.log("handlePlusIcon", node);
    if (name === "folder") {
      setSelectedData(node.data);
      setMode("add");
      setModuleIdForAdd(node.data._key);
      setCreateModuleModal(true);
      setParentData(node.data);
    } else {
      setTestcaseDetails(node);
      settestcasePrentData(node);
      setTestcaseCreateModal(true);
    }
  };

  const colDefs = [
    {
      field: "title",
      title: "Module",
      class: "title",
      isTitle: true,
      width: "220px",
      
      render: (nodeObj) => {
        return (
          <>
            <>
              {nodeObj.data.folder && (
                <>
                  <span
                    className="count count-badge"
                    onMouseOver={mouseOverControll}
                  >
                    {" "}
                    M{nodeObj.data.subModuleCount}{" "}
                  </span>
                  <span className="count count-badge">
                    {" "}
                    S{nodeObj.data.scriptCount}{" "}
                  </span>
                </>
              )}
              {!nodeObj.isRootNode && nodeObj.data.folder && (
                <button className={`add-btn ${create_action ? "" : "hidden"}`}>
                  <MoreInfoButton
                    type="add"
                    nodeObj={nodeObj}
                    plusIconArray={plusIconArray}
                    methodName={handlePlusIcon}
                    disable={
                      create_action &&
                      (project_status === "APPROVED" ||
                        project_status === "INPROGRESS")
                        ? false
                        : true
                    }
                  />
                </button>
              )}
            </>
          </>
        );
      },
    },
    {
      field: "result",
      title: "RESULT",
      class: "modified-by text-overflow-style",
      width: "150px",
      render: (nodeObj) => {
        return (
          <span
            title={nodeObj?.data?.result}
            className={`link cursor-default ml-1 testcase_table_values ${
              nodeObj?.data?.result === "PASS"
                ? "text-green-600"
                : nodeObj?.data?.result === "FAIL"
                ? "text-red-600"
                : nodeObj?.data?.result === "FLAKY"
                ? "text-yellow-500"
                : "text-blue-600"
            }`}
            
          >
            {nodeObj?.data?.result ? nodeObj?.data?.result : nodeObj.data.name==="Root Module"?"":""}
          </span>
        );
      },
    },
    {
      field: "assignee",
      title: "ASSIGNEE",
      // class: {`modified-by text-overflow-style ${(myrole==="TESTER"||myrole==="PROJECT_OWNER")?"hidden":null}`},
      class:
        myrole === "TESTER" || myrole === "PROJECT_OWNER"
          ? "modified-by text-overflow-style hidden"
          : "modified-by text-overflow-style",
      width: "170px",
      render: (nodeObj) => {
        return (
          <div
           
            className="text-blue-700 link  ml-1"
          >
            <div className="values col-span-12 ">
              {
                !nodeObj.isRootNode &&
                  (nodeObj?.data?.assigneeCount ? (
                    <div
                      className="text-blue-700"
                      // onClick={() => {
                      //   setMoreTypes(true);
                      //   setUsersAssignedList(nodeObj?.data);
                      // }}
                    >
                     <span className="cursor-pointer" onClick={() => {
                        setMoreTypes(true);
                        setUsersAssignedList(nodeObj?.data);
                      }}>{nodeObj?.data?.assigneeCount}</span> 
                    </div>
                  ) : (
                    ""
                  ))
                
              }
            </div>
           
          </div>
        );
      },
    },
    {
      field: "status",
      title: "STATUS",
      class:
        myrole === "TESTER" || myrole === "PROJECT_OWNER"
          ? "modified-by text-overflow-style hidden"
          : "modified-by text-overflow-style",
      width: "150px",
      render: (nodeObj) => {
        if (nodeObj?.data?.status > 0) {
          return (
            <div className="text-blue-700 link cursor-pointer flex ml-1 testcase_table_values text-yellow-500" onClick={()=>{getScript(nodeObj)}}>    
              <div className="mr-1">Pending</div>
              <div className="status_count pending_count">{nodeObj?.data?.status}</div>
            </div>
          );
        } else {
          return (
            <div className="text-blue-700 link cursor-pointer flex ml-1 testcase_table_values  text-green-600" onClick={()=>{getScript(nodeObj)}}>
              {nodeObj?.data?.status === 0 ? (
                <>
                  <div className="mr-1 ">Verified</div>
                </>
              ) : (
                <>
                  <div className="text-blue-700 link cursor-pointer ml-1">
                    <div>{ nodeObj.data.name==="Root Module"?"":""}</div>
                  </div>
                </>
              )}
            </div>
          );
        }
      },
    },
    {
      field: "action",
      title: "ACTIONS",
      class: "tree-action",
      width: "100px",
      render: actionColumnHtml,
    },
  ];
  const LABELS = {
    EXPAND_ALL: "Expand All",
    COLLAPSE_ALL: "Collapse All",
    SHOW_ALL_ELEMENTS: "Show all scripts",
    HIDE_ALL_ELEMENTS: "Hide all scripts",
    SHOW_PRE_POST_CONDITION: "Show Pre/Post Conditions",
    HIDE_PRE_POST_CONDITION: "Hide Pre/Post Conditions",
    NO_DATA_MSG: "",
  };

  const loadMoreChildren = async (_modId) => {
    console.log("_modId",_modId)
    let _result_tree = [];
    try {
      let res;
      if (myrole === "ACCOUNT_MANAGER" || myrole === "PROJECT_OWNER") {
        let email =
          myrole === "ACCOUNT_MANAGER" ? emailId : "projectOwner@fireflink.com";
        if (_modId) {
          res = await getExpandedModuleReq(
            _modId,
            true,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID,
            email
          );
        } else {
          res = await getAllModuleTreeReq(
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID,
            email
          );
        }
      } else {
        if (_modId) {
          res = await getExpandedModuleByEmailReq(
            _modId,
            true,
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID,
            emailId
          );
        } else {
          res = await getAllModuleTreeByEmailReq(
            proj?.project?.fireFlinkProjectId,
            proj?.licenceID,
            emailId
          );
        }
      }

      if (
        res &&
        res.data &&
        res.data.responseCode === 200 &&
        res.data.responseObject
      ) {
        _result_tree = res.data.responseObject.moduleTree[0].children;
      }
      return _result_tree;
    } catch (error) {
      console.error("error during load more data:", error);
      return _result_tree;
    }
  };
  function CloseCreateModuleModal(state) {
    setSelectedData();
    setMode("");
    setParentData();
    setModuleIdForAdd("");
    setCreateModuleModal(state);
  }
  function CloseCreateTestcaseModal(state) {
    setTestcaseCreateModal(state);
  }
  function CloseEditTestcaseModal(state) {
    setTestcaseEditModal(state);
  }
  function CloseAsigneeTestcaseModal(state) {
    setTestcaseAsigneeModal(state);
  }






  const sortBasedOnOrder = (testCaseDetails) => {
    return testCaseDetails.sort((a, b) => {
      return a?.order - b?.order;
    });
  };
  const compareTestStepsObjects = (templateData, scriptData) => {
    let a = templateData?.headers;
    let b = scriptData?.headers;
    let testStepsObject = {};
    let matchedObject = {};
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedText = "";
      for (let j = 0; j < b.length; j++) {
        if (a[i]?.toLowerCase() === b[j]?.toLowerCase()) {
          match = true;
          matchedText = b[j];
          break;
        }
      }
      if (!match) {
        matchedObject[a[i]] = a[i];
      } else {
        matchedObject[matchedText] = matchedText;
      }
    }

    for (let i = 0; i < scriptData?.rows.length; i++) {
      const keys = Object.keys(matchedObject);
      const rowKeys = Object.keys(scriptData.rows[i]);
      for (let j = 0; j < keys.length; j++) {
        if (!rowKeys.includes(keys[j])) {
          scriptData.rows[i][keys[j]] = "";
        }
      }
    }
    testStepsObject.headers = Object.keys(matchedObject);
    testStepsObject.rows = scriptData.rows;
    return testStepsObject;
  };
  const compareTestCaseDetailsObjects = (a, b) => {
    let testCaseDetailsArray = [];
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedObject = {};
      for (let j = 0; j < b.length; j++) {
        if (
          a[i]?.label?.toLowerCase() === b[j]?.label?.toLowerCase() &&
          a[i]?.type?.toLowerCase() === b[j]?.type?.toLowerCase()
        ) {
          match = true;
          matchedObject = b[j];
          break;
        }
      }
      if (!match) {
        testCaseDetailsArray.push(a[i]);
      } else {
        a[i].value = matchedObject?.value;
        testCaseDetailsArray.push(a[i]);
      }
    }
    return testCaseDetailsArray;
  };




  async function getTemplateData(scriptData, projectName) {
    let projectId=selectedproject?.project?.fireFlinkProjectId;
    let licenseId=selectedproject?.licenceID;

    try {
      const responseData = await getAllTestCaseTemplate(
        "config",
        projectId,
        licenseId
      );

      if (responseData?.data?.responseCode === 200) {
        setdetailsViewData(
          responseData?.data?.responseObject?.defaultTemplate?.testCaseDetails
        );
        let table = JSON.parse(
          JSON.stringify(
            responseData?.data?.responseObject?.defaultTemplate?.testSteps
          )
        );
        setTemplateTableData(table);
        let testCaseDetails =
          responseData?.data?.responseObject?.defaultTemplate?.testCaseDetails;
        testCaseDetails = sortBasedOnOrder(testCaseDetails);
        const testSteps =
          responseData?.data?.responseObject?.defaultTemplate?.testSteps;
        setTestCaseData
        ({
          ...scriptData,
          manualTestCase: {
            ...scriptData.manualTestCase,
            testCaseDetails: testCaseDetails,
            testSteps: testSteps,
          },
        });
        setExecutionListPopUp(true)
        
      } else {
        console.error("Template error", responseData?.data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  }



  const getScript = async (node) => {
    let moduleId = node?.data?.parentId;
    let ScriptId = node?.data?._key;
   

    let projectId=selectedproject?.project?.fireFlinkProjectId;
    let licenseId=selectedproject?.licenceID;
    // resetProgress();
    // startOrResumeProgress({
    //   stopAt: 100,
    //   intervalDuration: 80,
    // });

    try {
      let response = await getTestcaseDetailsById(
        moduleId,
        ScriptId,
        projectId,
        licenseId
      );
      setTestcaseDetailsById(response?.data?.responseObject);
      let responseData = response?.data?.responseObject;
      let manualTestCaseObj = responseData?.manualTestCase;

      if (manualTestCaseObj) {
        setTestCaseData(response?.data?.responseObject);
        if (Object.entries(manualTestCaseObj).length) {
          const response = await getAllTestCaseTemplate(
            "config",
            projectId,
            licenseId
          );
          if (response?.data?.responseCode === 200) {
            let testCaseDetails = compareTestCaseDetailsObjects(
              response?.data?.responseObject?.defaultTemplate?.testCaseDetails,
              manualTestCaseObj?.testCaseDetails
            );
            setdetailsViewData(
              response?.data?.responseObject?.defaultTemplate?.testCaseDetails
            );
            const testSteps = compareTestStepsObjects(
              response?.data?.responseObject?.defaultTemplate?.testSteps,
              manualTestCaseObj?.testSteps
            );
            setTemplateTableData(testSteps);
            testCaseDetails = sortBasedOnOrder(testCaseDetails);
            setTestCaseData({
              ...responseData,
              manualTestCase: {
                ...manualTestCaseObj,
                testCaseDetails: testCaseDetails,
                testSteps: testSteps,
              },
            });
            setExecutionListPopUp(true)
          } else {
            console.error(
              "Get All TestCase Template error",
              response?.data?.message
            );
          }
        } else {
          getTemplateData(responseData, responseData?.name);
        }
      } else {
        getTemplateData(responseData, responseData?.name);
      }
      if (response?.data?.responseCode === 200) {
        let data =
          response?.data?.responseObject?.manualTestCase?.testCaseDetails;
        let table = JSON.parse(
          JSON.stringify(
            response?.data?.responseObject?.manualTestCase?.testSteps
          )
        );
        setTemplateTableData(table);
        setdetailsViewData(data);
        setTestCaseData(response?.data?.responseObject);
        setExecutionListPopUp(true)
      }

      // resetProgress();
    } catch (error) {
      // resetProgress();
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };
  // const reloadPage = () => {
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 500);
  // };

  function closeExecutionListModal(state){
    setExecutionListPopUp(state)
    // reloadPage()
    // const search = window.location.search;
    // const id = new URLSearchParams(search).get("id");
    // getTreeData(id);
    // loadMoreChildren("MOD1096")
  }

  return (
    <div className="testcases">
      <div className="grid grid-cols-12 flex header">
        <div className="col-span-10 heading  pt-2">Testcases</div>
        {!["TESTER", "ADMIN", "PROJECT_OWNER"]?.includes(
          selectedUser?.role
        ) && (
          <Tooltip title="Assign">
            <div
              className={`col-span-2 grid grid-cols-12 add_assign_btn ${
                create_action ? `assing_btn` : `assign_testcase`
              }`}
            >
         
           <div
                className={`flex  cursor-pointer grid grid-cols-12 col-span-12 ${
                  checkedNodes.length &&
                  (project_status === "INPROGRESS" ||
                    project_status === "APPROVED")
                    ? null
                    : "opacity-50"
                }`}
                onClick={() => {
                  if (
                    checkedNodes.length &&
                    (project_status === "INPROGRESS" ||
                      project_status === "APPROVED")
                  ) {
                    setTestcaseAsigneeModal(true);
                  }
                }}
              >
                  <AssigneIcon/>
              </div>
            </div>
          </Tooltip>
        )}

        {create_action && (
          <div className="col-span-2 grid grid-cols-12 add_testcase_btn">
            <Tooltip title="Create Testcase">
              <div
                className={`flex  cursor-pointer grid grid-cols-12 col-span-12 ${
                  (!pageTree?.[0]?.children?.length &&
                    pageTree?.[0]?.name === "Root Module") ||
                  (project_status !== "APPROVED" &&
                    project_status !== "INPROGRESS")
                    ? "opacity-50"
                    : null
                }`}
                onClick={() => {
                  if (
                    (pageTree?.[0]?.children?.length ||
                      pageTree?.[0]?.name !== "Root Module") &&
                    (project_status === "APPROVED" ||
                      project_status === "INPROGRESS")
                  ) {
                    settestcasePrentData();
                    setTestcaseCreateModal(true);
                  }
                }}
              >
                <span className="col-span-2 add_icon">
                  {/* <Add /> */}
                  <AddIcon />
                </span>
                <div className="col-span-10 py-2 px-2">Testcase</div>
              </div>
            </Tooltip>
          </div>
        )}
        {create_action && (
          <div
            className={`col-span-2 grid grid-cols-12 add_module_btn ${
              project_status === "APPROVED" || project_status === "INPROGRESS"
                ? ""
                : "opacity-50"
            }`}
          >
            <Tooltip title="Add module">
              <div
                className="flex  cursor-pointer grid grid-cols-12 col-span-12"
                onClick={() => {
                  if (
                    project_status === "APPROVED" ||
                    project_status === "INPROGRESS"
                  ) {
                    if (!pageTree?.[0]?.children?.length) {
                      setSelectedData(pageTree?.[0]);
                      setMode("add");
                      setModuleIdForAdd(pageTree?.[0]?.key);
                      setCreateModuleModal(true);
                      setParentData(pageTree?.[0]);
                    } else {
                      setCreateModuleModal(true);
                      setSelectedData();
                    }
                  }
                }}
              >
                <span className="col-span-2 add_icon">
                  {/* <Add /> */}
                  <AddIcon />
                </span>
                <div className="col-span-10 py-2 pl-4 pr-2">Module</div>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      {(project_status === "INREVIEW" || project_status === "REJECTED") && (
        <div>
          <div className="grid grid-cols-12 col-span-1">
            <div className=" grid grid-cols-12 col-span-12 flex">
              <div className="noDefects">
                <NoTestCaseIcon />
              </div>
              <div className="no_data_text testcase_text">No Testcases found!</div>
            </div>
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="testcases_container flex  grid grid-cols-1 ">
          {pageTree?.length === 0 ? (
            <div className="grid grid-cols-12 col-span-1">
              <div className=" grid grid-cols-12 col-span-12 flex">
                <div className="noDefects">
                  <NoTestCaseIcon />
                </div>
                <div className="no_data_text testcase_text">No Testcases found!</div>
              </div>
            </div>
          ) : id ? (
            <TableTree
              expandAll={isExpandAll}
              id="moduleTreeTD"
              onRootNodeClicked={onRootSelected}
              childrenCountKeysArray={[
                "moduleLevelScriptCount",
                "subModuleCount",
                "postCondtionCount",
                "preCondtionCount",
              ]}
              data={
                id
                  ? pageTree
                  : pageTree?.[0]?.children
                  ? pageTree?.[0]?.children
                  : []
              }
              rootData={rootData}
              colDefs={colDefs}
              filter={treeValue}
              //  showCheckbox={true}
              showCheckbox={
                myrole === "PROJECT_OWNER" || myrole === "TESTER" ? false : true
              }
              // hideCheckboxConditions={{key: 'type', value: 'step'}}
              onCheckedNodes={onCheckedNodes}
              hideElements={false}
              hideConditions={true}
              conditions={[
                { key: "title", value: "Preconditions" },
                { key: "title", value: "Postconditions" },
              ]}
              hideConditionBtn={true}
              labels={LABELS}
              dnd={true}
              section="module"
              onNodeSelected={onNodeSelected}
              loadMoreUrl={loadMoreChildren}
              loadMoreData={loadMoreChildren}
            />
          ) : (
            <TableTree
              expandAll={isExpandAll}
              id="moduleTreeTD"
              onRootNodeClicked={onRootSelected}
              expandAllApi={loadMoreChildren}
              childrenCountKeysArray={[
                "moduleLevelScriptCount",
                "subModuleCount",
                "postCondtionCount",
                "preCondtionCount",
              ]}
              data={
                id
                  ? pageTree
                  : pageTree?.[0]?.children
                  ? pageTree?.[0]?.children
                  : []
              }
              rootData={rootData}
              colDefs={colDefs}
              filter={treeValue}
              //  showCheckbox={true}
              showCheckbox={
                myrole === "PROJECT_OWNER" || myrole === "TESTER" ? false : true
              }
              // hideCheckboxConditions={{key: 'type', value: 'step'}}
              onCheckedNodes={onCheckedNodes}
              hideElements={false}
              hideConditions={true}
              conditions={[
                { key: "title", value: "Preconditions" },
                { key: "title", value: "Postconditions" },
              ]}
              hideConditionBtn={true}
              labels={LABELS}
              dnd={true}
              section="module"
              onNodeSelected={onNodeSelected}
              loadMoreUrl={loadMoreChildren}
              loadMoreData={loadMoreChildren}
            />
          )}

          {!(pageTree?.[0]?.children?.length || id) ? (
            <div className="grid grid-cols-12 col-span-1">
              <div className=" grid grid-cols-12 col-span-12 flex">
                <div
                  className={create_action ? "nomodule" : "noDefects"}
                  // className="noDefects"
                >
                  <ModuleIcon />
                  {create_action && (
                    <div className="flex grid grid-cols-12 mt-4">
                      <div className="col-span-2 message_text">Click On</div>
                      <div
                        className={`col-span-4  module_btn ${
                          project_status === "APPROVED" ||
                          project_status === "INPROGRESS"
                            ? ""
                            : "opacity-50"
                        }`}
                      >
                        <div
                          className="flex  cursor-pointer grid grid-cols-12 col-span-12"
                          onClick={() => {
                            if (
                              project_status === "APPROVED" ||
                              project_status === "INPROGRESS"
                            ) {
                              if (!pageTree?.[0]?.children?.length) {
                                setSelectedData(pageTree?.[0]);
                                setMode("add");
                                setModuleIdForAdd(pageTree?.[0]?.key);
                                setCreateModuleModal(true);
                                setParentData(pageTree?.[0]);
                              } else {
                                setCreateModuleModal(true);
                              }
                            }
                          }}
                        >
                          <span className="col-span-2 add_icon">
                            {/* <Add /> */}
                            <AddIcon />
                          </span>
                          <div className="col-span-10 py-2 px-2">
                            Module
                          </div>
                        </div>
                      </div>

                      <div className="col-span-3 message_text ml-1">
                        to add Module
                      </div>
                    </div>
                  )}
                  {!create_action && (
                    <div>
                      <div className="col-span-2 testcase_message">
                        No Testcases Found !
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      {moduleDetailsModal && (
        <ModuleDeatilsPopup
          closeModal={closeModal}
          moduleDeatils={selectedData}
        />
      )}
      {createModuleModal && (
        <CreateModule
          treeData={allPageTree}
          getTreeData={getTreeData}
          MyAlert={props.MyAlert}
          moduleIdForAdd={moduleIdForAdd}
          data={selectedData}
          closeModal={CloseCreateModuleModal}
          mode={mode}
          setIsLoading={setIsLoading}
        />
      )}
      {warningPopup && (
        <WarningModal
          executionMethod={deleteModule}
          onCloseWarningPopup={closeModal}
          data={selectedData}
          body={`Your data will be lost. Are you sure you want to delete ${selectedData.name} ?`}
          confirmation={true}
          rejectbtn={"Cancel"}
          acpttbtn={"Delete"}
        />
      )}
      {TestcaseCreateModal && (
        <CreatTeastCase
          treeData={allPageTree}
          MyAlert={props.MyAlert}
          closeModal={CloseCreateTestcaseModal}
          getTreeData={getTreeData}
          setIsLoading={setIsLoading}
          // moduleIdForAdd={moduleIdForAdd}
          modeType="create"
          data={""}
          parentData={testcaseParentData}
        />
      )}
      {TestcaseEditeModal && (
        <CreatTeastCase
          treeData={allPageTree}
          MyAlert={props.MyAlert}
          closeModal={CloseEditTestcaseModal}
          getTreeData={getTreeData}
          setIsLoading={setIsLoading}
          // moduleIdForAdd={moduleIdForAdd}
          modeType="edit"
          data={testcaseDetails}
          childnode={childnode}
        />
      )}
      {testcaseAsigneeModal && (
        <Asignee_testCase
          MyAlert={props.MyAlert}
          closeModal={CloseAsigneeTestcaseModal}
          leadUserResponse={leadUserResponse}
          payload={payload}
          getTreeData={getTreeData}
          setIsLoading={setIsLoading}
        />
      )}
      {moretypes && (
        <AssigneeUserListModal
          functiontypeModalClose={functiontypeModalClose}
          usersAssignedList={usersAssignedList}
        />
      )}
      {testCasewarningPopup && (
        <WarningModal
          executionMethod={deleteTestScript}
          onCloseWarningPopup={closeWarningModal}
          data={testcaseDetails}
          body={`Your data will be lost. Are you sure you want to delete ${testcaseDetails.name} ?`}
          confirmation={true}
          rejectbtn={"Cancel"}
          acpttbtn={"Delete"}
        />
      )}
      {
        executionListPopUp && (
          <ExecutionListModule  
          MyAlert={props.MyAlert}
          testCaseTemplate={testCaseData}
          CloseModal={closeExecutionListModal}
         
          
          
          />
        )
      }
    </div>
  );
}

export default ProjectTestCases;
