import React, { useState, useEffect}  from 'react';
import { useTable, useGlobalFilter } from "react-table";

import { Tooltip } from "@material-ui/core";
import { ReactComponent as EditIcon } from "../../../assets/editBlack.svg";

import IconButton from '@mui/material/IconButton';

import {getAllFeedbackTemplateReq} from "../../../api/api-services"

import FeedbackTemplateDetails from './feedback-template-details';
import EditFeedbackTemplate from './edit-feedbacktemplate';

// import ContextMenu from '@src/pages/modal/contex-modal';
import ContextMenu from '../../modal/contex-modal';

function FeedbackTemplate (props) {
  let [templatePopUp,setTemplatePopUp]=useState(false);
  let [templateData,setTemaplateData]=useState();
  const[templateDetails, setTemplateDetails] = useState([]);
  const[editTemplate,setEditTemplate]=useState(false);
  let selectedproject = JSON.parse(localStorage["selected-project"]);
  let myRole=JSON.parse(localStorage.getItem("user-privilege")).role;
  let project_Status=JSON.parse(localStorage["selected-project"]).projectStatus;
  const contextMenuData = [
    {
      option: "Template Details",
    },
    
  ];
  function contextMenuFunction(event, resObj) {
    if (event.target.textContent === "Template Details") {
     console.log("contextMenuFunction",resObj)
     setTemaplateData(resObj);
      setTemplatePopUp(true)
    }
   
  }
  const stateColumnHtml = (e) => {
   
    return (
      <div className="actionIcons flex justify-start align-middle" style={{marginLeft: "23%"}}>
        <>
          <div className="editIcon mr-4"  >
          <Tooltip title={(myRole==="ADMIN" || myRole==="ACCOUNT_MANAGER")?(selectedproject.projectStatus!=="INPROGRESS" && selectedproject.projectStatus!=="CLOSED")?"Edit":"Cannot be edited":"Cannot be edited"} placement="top"> 
         
          <IconButton sx={(myRole==="ADMIN" || myRole==="ACCOUNT_MANAGER")?(selectedproject.projectStatus!=="INPROGRESS" && selectedproject.projectStatus!=="CLOSED")?{ "&:hover": { backgroundColor: "#FAF9F6" ,boxShadow:" 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"} }:{ "&:hover": {opacity: "0.4", backgroundColor: "#FAF9F6" ,boxShadow:" 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"} }:{ "&:hover": {opacity: "0.4", backgroundColor: "#FAF9F6" ,boxShadow:" 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"} }}>
          <button onClick={()=>{setTemaplateData(e);setEditTemplate(true)}} disabled={(myRole==="ADMIN" || myRole==="ACCOUNT_MANAGER")?(selectedproject.projectStatus!=="INPROGRESS" && selectedproject.projectStatus!=="CLOSED")?false:true:true}>
          <EditIcon
              height="15px"
              width="15px"
              
            />
          </button>
            </IconButton>
          </Tooltip>
           
          </div>
          <ContextMenu
          contextMenuData={contextMenuData}
          func={(event) => {
            contextMenuFunction(event,e);
          }}
          onMouseOutz
          />
        </>
      </div>
    );
  };
  let columns = React.useMemo(
    () => [
      {
        id: "checkbox-table-column",
        className: "cursor-pointer",
        accessor: "_id",
        width: "4%",
        column: [],
        Cell: (e) => (
          <div className="ml-4  -left-3 cursor-pointer">
            {" "}
            <input
        type="radio"
        className="cursor-pointer"
        id={e.row.original._id}
        name="licenses"
        checked={true}
        value={e.row.original._id}
        disabled={["CLOSED","COMPLETED"].includes(project_Status)}
        
       
        
      /> 
          </div>
        ),
      },
      {
        Header: "TEMPLATE NAME",
        accessor: "templateName",
         width: "40%",
         Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              console.log("event.preventDefault",e.cell.row.original)
              setTemaplateData(e.cell.row.original);
              setTemplatePopUp(true)
             
            }}
            className="dataShowText w-32 table_cell_links"
            style={{ marginLeft: "-85%" }}
          >
            {e.value}
          </div>
        ),
        
      },
      {
        Header: "TEMPLATE TYPE",
        accessor: "templateType",
         width: "38%",
         Cell: (e) => (
          <div
            title={e.value}
            
            
            style={{ marginLeft: "-25%" }}
          >
            {e.value}
          </div>
        ),
       
      },
      {
        Header: "ACTIONS",
        accessor:stateColumnHtml,
        width: "20%",
      },
  
  
  
  
  
  
  
    ],[]

  );

    useEffect(()=>{
        getAllFeedbackTemplate()
      
    },[]);

    

    

   
async function getAllFeedbackTemplate() {
  let projectId = JSON.parse(localStorage["selected-project"]).id;
  try {
    let response = await getAllFeedbackTemplateReq(projectId);
    if (response.data.status === 200) {
     
      let temparray = {
        templateName: response.data.template.templateName,
        templateType: "Pre-defined",
        details: response.data.template,
      
       
       
      };
     
      setTemplateDetails(() => [temparray]);
    }
  } catch (error) {
    console.log(error);
  }
}
function closeTemplateDetailsPopUp(state){
  setTemplatePopUp(state)
}
  
function closeEditPopup(state){
  setEditTemplate(state)
}
   
    let data = React.useMemo(() =>templateDetails,[templateDetails]); 
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state,
        setGlobalFilter,
      } = useTable(
        {
          columns,
          data,
        },
        useGlobalFilter
      );
      const { globalFilter } = state;
    return(
        <div className="testcase-templateList overflow-x-hidden">
   
        <div className="grid grid-cols-12 flex header content-between">
        <div className="col-span-11 heading pl-3" >
        Feedback Template

        </div>
        <div className="grid grid-cols-12 col-span-1 flex justify-end">
        {/* <SearchField setGlobalFilter={setGlobalFilter}  colSpan="col-span-7 ml-auto mt-0.5" /> */}
          
        </div>
      </div>

    <div className="">
      <table {...getTableProps()}>
        <thead className="text-left table_column_header h-10 border-b-2">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th width={column.width} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
     
            <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              <>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="h-10 table_cell_value  border-b-2 configHover"
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} className="">
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                <div className="noDefects">
                  {/* <NoUserIcon /> */}
                </div>
              </>
            )}
          </tbody>
        
        
      
       
      </table>
    </div>

    {
    templatePopUp && (
      <FeedbackTemplateDetails closeModule={closeTemplateDetailsPopUp} data={templateData}/>
    )
    }
    {
      editTemplate && (
         <EditFeedbackTemplate data={templateData} closeModule={closeEditPopup} MyAlert={props.MyAlert} getAllFeedbackTemplate={getAllFeedbackTemplate}/>
        // <AddFeedback selectedDefectData={""}/>
      )
    }
    
  </div>
  
    )
}
export default FeedbackTemplate;