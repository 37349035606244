import React from 'react'
import { Outlet } from 'react-router-dom'

const IntegrationRouter = (props) => {

  return (
    <>
        <Outlet MyAlert={props.MyAlert}/>
    </>
  )
}

export default IntegrationRouter