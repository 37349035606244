import React, { useState, useEffect,useContext } from "react";
import "./config.scss";
import { useTable, useGlobalFilter } from "react-table";
import Search from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import { ReactComponent as AddIcon } from "../../assets/addIcon.svg";
import { Tooltip } from "@material-ui/core";
import { ReactComponent as EditIcon } from "../../assets/editBlack.svg";
// import { ReactComponent as NoDefectTemplateIcon } from "../../assets/noDefectTemplateIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/deleteBlack.svg";
import SetDefaultModal from "./set-default-modal";
import IconButton from '@mui/material/IconButton';
import CreateDefectTemplate from "./create-defect-template";
import DefectTemplateDetails from "./template-details";
import DefectsDeatilsPopup from "../modal/defects-deatils-popup";
import EditDefectTemplate from "./edit-defect-template";
import {getAllDefectTemplate,updateDefaultDefectTemplateRequest,deleteDefectTemplateRequest,getAllDefectFromFireFlink,updatedefaultDefectTemplate} from "../../api/api-services"
import { CommonLoader } from "../common/common-loader";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer.js";
import SearchField from "../common/search-field";
import NoRelevantDataIcon from "../../assets/reportsicon/no_reports_icon.png";
import { ReactComponent as NoDefectTemplateIcon } from "../../assets/reportsicon/noReportsAvailable.svg";


function DefectDetailsTemplate(props) {
  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
  let [defectDetails, setDefects] = useState([]);
  const [isLoading, setIsLoading] = useState();
  let [showSearch, setShowSearch] = useState(false);
  let [defectsPopUp, setDefectsPopUp] = useState(false);
  let [defectsdeatils, setDefectsdeatils] = useState();
  const [openChangeDefaultModal, setopenChangeDefaultModal] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [defaultTemplate, setDefaultTemplate] = useState({});
  const [warningPopup,setWarningPopup]=useState(false);
  const [delteTemplate,setDeleteTemplate]=useState();
  const [defectTemplateList,setDefectTemplateList]=useState([]);
  let selectedproject = JSON.parse(localStorage["selected-project"]);
  let data = React.useMemo(() => defectDetails, [defectDetails,defaultTemplate]);
  let loggedinUser = JSON.parse(localStorage["user-privilege"]);
  let [createTemplate,setCreateTemplate]=useState(false);
  let [editTemplate,setTemplate]=useState(false);
  let [editTemplateDetails,setEditTemplateDetails]=useState();
  const [defaultTempEvent, setDefaultTemplateEvent] = useState({})
  const [checkProjectStatus,setCheckProjectStatus]=useState(selectedproject.projectStatus === "INREVIEW" ||
  selectedproject.projectStatus === "REJECTED" || selectedproject.projectStatus === "INPROGRESS" || 
  selectedproject.projectStatus === "CLOSED" ?true:false)
  const [validateRole,setValidateRole]=useState(loggedinUser.role=== "ADMIN" || loggedinUser.role=== "ACCOUNT_MANAGER" ? true :false)
  const [defectsFromFireFlink,setdDfectsFromFireFlink]=useState([]);
  let [closeSearchField, setCloseSearchField] = useState(false)
 
  function CloseWarnignPopup(state){
    setWarningPopup(state)
  }

  function closeCreateTemplateModal(sataus){
    if(globalFilter!==undefined){
      setShowSearch(true)
    }else{
      setShowSearch(false)
    }
    setCreateTemplate(sataus);
  }

  function DefectsDetails(value){
    setEditTemplateDetails(value);
    setTemplate(true);
    setCloseSearchField(true)
  }
  useEffect(() => {
   
  if(selectedproject.projectStatus !== "INREVIEW" && selectedproject.projectStatus !== "REJECTED"){
    getAllDefectLists();
  }else{
    setIsLoading(false)
  }
  
    // console.log(getAllDefectsList,getAllDefectsList.length)
   
   
  }, []);
 
  const deleteDefectTemplate= async(e)=>{
    let tempid=delteTemplate._id;
    let licenseId=JSON.parse(localStorage["selected-project"]).licenceID;
    let projectId=JSON.parse(localStorage["selected-project"]).project.fireFlinkProjectId;
    let temp_name=delteTemplate.name;
    try{
      const response=await deleteDefectTemplateRequest(tempid,projectId,licenseId);
      if(response.data.responseCode===200){
        props.MyAlert.success(`${temp_name} defect template deleted successfully`);
        // setDefectTemplateList([])
        getAllDefectLists()
        
      }
    }catch(error){
      console.error("error",error);
    }
    
  }

  const getAllDefectLists =async (e) => {
    let licenseId=JSON.parse(localStorage["selected-project"]).licenceID;
    let projectId=JSON.parse(localStorage["selected-project"]).project.fireFlinkProjectId;
    let tempArray=[]
    // setDefectTemplateList([])
    setIsLoading(true)
    resetProgress();
      startOrResumeProgress(
        {
          stopAt: 100,
          intervalDuration: 80,
        });
    try {
      const getdata = await getAllDefectTemplate(projectId,licenseId);
      
       
          setDefects(getdata.data.responseObject.defectTemplates)
       
        getdata?.data?.responseObject?.defectTemplates?.map((temp)=>{
          tempArray.push(temp.name);
          // defectTemplateList.push(temp.name)
        })
        setDefectTemplateList(tempArray)
        if (getdata.data.responseObject.defaultDefectTemplate) {
          setDefaultTemplate({ ...getdata.data.responseObject.defaultDefectTemplate })
      }
      
      setIsLoading(false)
      resetProgress();
    } catch (error) {
      resetProgress();
      console.error("error",error);
    }
  };
  let fireFlinkProjectId = JSON.parse(localStorage["selected-project"]).project
  .fireFlinkProjectId;
let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
  const getDefaultdefecttemplateFireFlink = async (e) => {
    try {
      let response = await getAllDefectFromFireFlink(fireFlinkProjectId, licenseId);
      if (response?.data?.responseCode === 200) {
        localStorage.setItem(
          "Default-defect-template-fireFlink",
          JSON.stringify(response.data.responseObject.defects)
        );
        setdDfectsFromFireFlink(response.data.responseObject.defects)
      }
    } catch (error) {
      console.error("error");
    }
  };
  // useEffect(() => {
   
  //   if(selectedproject.projectStatus==="INPROGRESS"){
  //     getDefaultdefecttemplateFireFlink()
  //   }
   
  // }, [fireFlinkProjectId]);

  const stateColumnHtml = (e) => {
    return (
      <div className="actionIcons flex justify-start align-middle">
        <>
          <div className="editIcon"  >
          <Tooltip title={e.templateType==="Predefined"?"Predefined template cannot be Edited":"Edit"}>
          <IconButton sx={e.templateType==="Predefined" ? { "&:hover": {opacity: "0.4", backgroundColor: "#FAF9F6" ,boxShadow:" 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"} } :{ "&:hover": { backgroundColor: "#FAF9F6" ,boxShadow:" 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"} }}>
          <button className={e.templateType==="Predefined" || !validateRole ||selectedproject.projectStatus==="INPROGRESS"|| selectedproject.projectStatus==="CLOSED"  ? "opacity-40":""} disabled={e.templateType==="Predefined" || !validateRole || selectedproject.projectStatus==="INPROGRESS"|| selectedproject.projectStatus==="CLOSED" }  onClick={()=>{DefectsDetails(e)}}>
          <EditIcon
              height="15px"
              width="15px"
              
            />
          </button>
            </IconButton>
          </Tooltip>
           
          </div>
          <div className="deleteIcon" >
          <Tooltip title={e.templateType==="Predefined"?"Predefined template cannot be deleted":e._id=== defaultTemplate._id?"Default template cannot be deleted":"Delete"}>
          <IconButton sx={e.templateType==="Predefined" ? { "&:hover": {opacity: "0.4", backgroundColor: "#FAF9F6" ,boxShadow:" 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"} } :{ "&:hover": { backgroundColor: "#FAF9F6" ,boxShadow:" 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"} }}>
          <button className={e.templateType==="Predefined"|| e._id=== defaultTemplate._id || !validateRole ? "opacity-40":""} disabled={e.templateType==="Predefined"||e._id=== defaultTemplate._id || !validateRole} onClick={()=>{setWarningPopup(true);setDeleteTemplate(e)}}>
          <DeleteIcon
              height="15px"
              width="15px"
              
            />
          </button>
            </IconButton>
          
            </Tooltip>
          </div>
        </>
      </div>
    );
  };


  const  updateprojectDefectDefaultTemplate=async (e)=>{
    let projectObj={
      id:selectedproject.id,
     project:{
      defaultDefectTemplateId:e

     }
    };
    const formData = new FormData();
    formData.append("project", JSON.stringify(projectObj));
    formData.append(
        "createdBy",
        JSON.parse(localStorage.getItem("fire-crowd-user")).userName
      );
    try{
      let response=updatedefaultDefectTemplate(formData)
      console.log("responseqqqq",response)

    }catch(error){
      console.log("error")
    }
  }

const defaulTemplateRadioHandler = () => {
  const templateId = defaultTempEvent.row.original._id;
  const tempName = defaultTempEvent.row.original.name;
  let { Actions, ...tempTemplateData } = defaultTempEvent.row.original;
  let licenseId=JSON.parse(localStorage["selected-project"]).licenceID;
  let projectId=JSON.parse(localStorage["selected-project"]).project.fireFlinkProjectId;
  setDefaultTemplate({ ...tempTemplateData })
  updateDefaultDefectTemplateRequest( tempTemplateData, templateId,projectId,licenseId)
      .then((results) => {
          if (results.data && results.data.responseCode === 200) {
              
             props.MyAlert.success(`${tempName} has been successfully changed as your default template`);
             updateprojectDefectDefaultTemplate(templateId)
             
          } 
      })
      .catch((error) => {
          if (error.response) {
              console.error('Update Default Template Error', error.response.message);
          }
      });
}

  let columns = React.useMemo(
    () => [
      {
        id: "checkbox-table-column",
        className: "cursor-pointer",
        accessor: "_id",
        width: "3.6%",
        column: [],
        Cell: (e) => (
          <div className="ml-4  -left-3 cursor-pointer">
            {" "}
            <input
        type="radio"
        className="cursor-pointer"
        id={e.row.original._id}
        name="licenses"
        checked={e.row.original._id === defaultTemplate._id }
        value={e.row.original._id}
        disabled={!validateRole   || ["INPROGRESS","CLOSED","COMPLETED"].includes(selectedproject.projectStatus)}
        // disabled={!validateRole || defectsFromFireFlink?.length >=1}
        onClick={() => {
          
          if(e.row.original._id === defaultTemplate._id){
            setopenChangeDefaultModal(false)
          }else{
            setTemplateName(e.cell.row.original.name);
                 setDefaultTemplateEvent(e);
               setopenChangeDefaultModal(true);
               setCloseSearchField(true)
          }
              
        }}
        
      /> 
          </div>
        ),
      },
    
     
      {
        Header: "TEMPLATE NAME",
        accessor: "name",
        width: "20%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              setDefectsdeatils(e.cell.row.original);
              setDefectsPopUp(true);
              setCloseSearchField(true)
            }}
            className="dataShowText w-32 table_cell_links"
            style={{ marginLeft: "-75%" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "TEMPLATE TYPE",
        accessor: "templateType",
        width: "15%",
        Cell: ({ value }) => {
          return (
            <div style={{ marginLeft: "-30%" }} className="datatrimmer w-32">
              {value}
            </div>
          );
        },
      },
      {
        Header: "MODIFIED BY",
        accessor: "modifiedByUname",
        width: "15%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "-22%" }}
              className="datatrimmer w-32"
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "MODIFIED ON",
        accessor: "modifiedOn",
        width: "15%",
        Cell: ({ value }) => {
          return (
            <div style={{ marginLeft: "-14%" }} className="datatrimmer w-32">
              {value}
            </div>
          );
        },
      },
      {
        Header: "ACTIONS",
        accessor: stateColumnHtml,
        width: "15%",
        Cell: (e) => {
          return (
            <div
             
              style={{ marginLeft: "-10%" }}
            >
              {e.value}
            </div>
          );
        },
      },
    ],
    [defectDetails,defaultTemplate]
  );

  // function CloseBugHistoryModal(state) {
  //   setopenChangeDefaultModal(state);
  // }

  function closeDefectsPopup(state){
    if(globalFilter!==undefined){
      setShowSearch(true)
    }else{
      setShowSearch(false)
    }
    setDefectsPopUp(state)
  }

  function closeEditDefectPopup(state){
    if(globalFilter!==undefined){
      setShowSearch(true)
    }else{
      setShowSearch(false)
    }
    
    setTemplate(state)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  return (
    <div className="defects-list overflow-x-hidden">
      {
        loggedinUser?.config?.defectTemplate?.actions?.create?
        (
          <div className="grid grid-cols-12 flex header content-between">
        <div className="col-span-10 heading pl-2">Defect Details Template</div>

        <div className="grid grid-cols-12 col-span-2 flex justify-end">
        <SearchField setGlobalFilter={setGlobalFilter} closeField={closeSearchField} setCloseField={setCloseSearchField} colSpan="col-span-7 ml-auto mr-14 mt-0.5" />
          <div className={defectDetails?.length<4 && !checkProjectStatus ?"grid grid-cols-12 col-span-5 addTempBtn":"grid grid-cols-12 col-span-5 addTempBtn opacity-50"}>
          <Tooltip
                  title={
                    (
                      selectedproject.projectStatus === "INREVIEW") ||
                    selectedproject.projectStatus === "REJECTED" ||
                    selectedproject.projectStatus === "CLOSED"||selectedproject.projectStatus === "INPROGRESS" 
                      ? selectedproject.projectStatus === "INREVIEW"
                        ? "To start creating the defect detail template, the project should be in APPROVED"
                        : selectedproject.projectStatus === "REJECTED"
                        ? "Project is in Rejected state"
                        : selectedproject.projectStatus === "CLOSED"
                        ? "Project is in Closed state"
                        : selectedproject.projectStatus === "INPROGRESS"
                        ? "Project is in In-progress state"
                        : null
                      : "Add template"
                  }
                  placement="top"
                >
            <div  className="grid grid-cols-12 col-span-11 create_buttton cursor-pointer" onClick={()=>{if(defectDetails?.length<4  && !checkProjectStatus){setCreateTemplate(true);setCloseSearchField(true)}else{setCreateTemplate(false)}console.log(checkProjectStatus)}}>
              <span className="col-span-2 ">
                <AddIcon className="addDefectIcon" />
              </span>
              <span className=" col-span-10 absolute  left-3 mt-2 ml-6">
                Template
              </span>
            </div>
            </Tooltip>
          </div>
        </div>
      </div>
        ):(
          <div className="grid grid-cols-12 flex header content-between">
          <div className="col-span-10 heading pl-3">
          Defect Details Template
          </div>
          <div className="grid grid-cols-12 col-span-2 flex justify-end">
          <SearchField setGlobalFilter={setGlobalFilter} closeField={closeSearchField} setCloseField={setCloseSearchField} colSpan="col-span-11 ml-auto mt-0.5" />
          </div>
        </div>
       

        )
      }
      

      <div className="">
        <table {...getTableProps()}>
          <thead className={`text-left table_column_header h-10 ${(selectedproject.projectStatus !== "INREVIEW" && selectedproject.projectStatus !== "REJECTED")?"border-b-2":"" }`}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th width={column.width} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {
            isLoading===false && (
              <>
               { selectedproject.projectStatus === "INREVIEW" || selectedproject.projectStatus === "REJECTED" ? (
                <>
                <div className="noDefectsIcon">
              <NoDefectTemplateIcon />
            </div>
            <div className="no_data_text defect_text">No Defect Template found!</div>
                </>
            
          ) : (
            <tbody {...getTableBodyProps()} className="">
              {rows.length > 0 ? (
                <>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="h-10 table_cell_value configHover border-b-2"
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  {/* <div className="noDefectsIcon">
                    <NoDefectTemplateIcon />
                  </div> */}
                   <div className="no_relevant_data">
                    <img  alt="warning" src={NoRelevantDataIcon}/>
                    <div className="text_msg">No Relevant Data Is Found !</div>
                  </div>
                </>
              )}
            </tbody>
          )}
              </>
            )
          }
         
        </table>
      </div>
      {openChangeDefaultModal && (
        <SetDefaultModal defaulTemplateRadioHandler={defaulTemplateRadioHandler} CloseModal={()=>{setopenChangeDefaultModal(false)}} message={"Are you sure you want to change default template ?"}/>
      )}
      {
        createTemplate && (
          <CreateDefectTemplate MyAlert={props.MyAlert} closeCreateTemplateModal={closeCreateTemplateModal} getAllDefectLists={getAllDefectLists} defectTemplateList={defectTemplateList} />
        )
      }
      {
        defectsPopUp &&(
          <DefectTemplateDetails closeDefectsPopup={closeDefectsPopup} defectsdeatils={defectsdeatils}
/>
        )
      }
      {
        editTemplate &&(
          <EditDefectTemplate  MyAlert={props.MyAlert} closeEditDefectPopup={closeEditDefectPopup} editTemplateDetails={editTemplateDetails} getAllDefectLists={getAllDefectLists} defectTemplateList={defectTemplateList}/>
        )
      }
      {
        warningPopup &&(
          
          <SetDefaultModal CloseModal={CloseWarnignPopup} message={"Are you sure you want to delete the template ?"} mode={"Delete template"} button={"delete"} delete={deleteDefectTemplate}/>
        )

      }

    </div>
  );
}

export default DefectDetailsTemplate;
