import React, { useState } from "react";

import { Tooltip } from "@material-ui/core";

import "./importManualTestcase.scss";

import { ReactComponent as ImportFileIcon } from "../../../assets/importtestcase/file_icon.svg";
import { ReactComponent as ImportIcon } from "../../../assets/importtestcase/import_icon.svg";
import { ReactComponent as ImportFile } from "../../../assets/importtestcase/file_White_icon.svg";
import {
  downloadTestcaseTemplateExcel,
  ImporttestCaseReq,
} from "../../../api/api-services";

const ImportManualTestCase = (props) => {
  let [excelData, setExcelData] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFilename, setSelecteFilename] = useState();
  const [callingAPI, setCallingAPI] = useState(true);
  const [downloadingFile,setDownloadingFile]=useState(true);
  let [importButton,setImportButton]=useState("Import")

  let project_status = JSON.parse(
    localStorage.getItem("selected-project")
  ).projectStatus;
  let projectId = JSON.parse(localStorage.getItem("selected-project")).project
    .fireFlinkProjectId;
  let licenseId = JSON.parse(
    localStorage.getItem("selected-project")
  ).licenceID;

  const fileHandler = (e) => {
    let fileObj = e.target.files[0];
    let fileName = fileObj.name;
    if (project_status !== "APPROVED" && project_status !== "INPROGRESS") {
      props.MyAlert.info(
        `In ${project_status} state you cannot import testcase`
      );
    } else {
      if (
        (fileName && fileObj && fileObj.type === "application/vnd.ms-excel") ||
        fileObj.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setSelectedFile(fileObj);
        setExcelData([]);
        setSelecteFilename(fileObj.name);
        setCallingAPI(true);
      } else {
        props.MyAlert.info("mandatory to upload .xlsx file");
      }
    }
    e.target.value = ''
  };
  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
  const downloadExcel = async () => {
    setDownloadingFile(false)
    try {
      let response = await downloadTestcaseTemplateExcel(projectId, licenseId);
      if (response.status === 200) {
        console.log("excel download", response);
        saveFileFromBlob(response.data, "TestCase_Template.xlsx");
      }
    } catch (err) {
      console.log("error");
    }
    setDownloadingFile(true)
  };
  const ImportTestcase = async () => {
    setImportButton("Importing...")
    const formdata = new FormData();
    formdata.append("testCaseFile", selectedFile);
    console.log("selectedFile",selectedFilename)
    if(selectedFilename){
      setCallingAPI(false);
      try {
        let response = await ImporttestCaseReq(projectId, licenseId, formdata);
        if (
          response.data.responseCode === 200 &&
          response.data.message === "Test cases imported successfully"
        ) {
          props.MyAlert.success("Test cases imported successfully");
        } else if (response.data.responseCode === 200) {
          props.MyAlert.info(response.data.message);
        }
  
        setSelecteFilename();
      } catch (err) {
        console.log("error");
      }
      setCallingAPI(true);
    }else{
      props.MyAlert.info(`Please Upload file`)
    }
    setImportButton("Import")
   
  };

  return (
    <div className="testcase-import overflow-x-hidden">
      <div className="grid grid-cols-12 flex header content-between">
        <div className="col-span-8 heading pl-3">Import</div>
      </div>

      <div className="p-2.5">
        <div className="import_file_form_label">Import From</div>
        <div className="flex relative">
          <span
            style={{ display: "inline-block", marginTop: "10px" }}
            className="big-size-file"
          >
            <button
              style={{
                padding: "1px 12px",
                border: " 1px solid #989797",
                borderRadius: "4px",
                color: "#727171",
                cursor: "pointer !important",
                fontSize: "10px",
                fontFamily: "OpenSans-Regular",
              }}
              
            >
              {selectedFilename ? "Replace File" : "Choose file"}
            </button>
            <input
              type="file"
              accept=".xlsx, .xls"
              multiple={false}
              disabled={(project_status==="CLOSED"|| project_status==="COMPLETED")}

              onChange={fileHandler}
              id="contained-button-file"
              className="fileStyle"
              title=""
              style={{
                position: "absolute",
                zIndex: 2,
                opacity: 0,
                height: "100%",
                width: "80px",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            />
          </span>

          <span
            style={{ display: "inline-block", marginTop: "10px" }}
            className="ml-6"
          >
            <button
              className={`import_btn ${(callingAPI && (project_status!=="CLOSED" && project_status!=="COMPLETED")  ) ? "" : "opacity-50"}`}
              onClick={() => {
                ImportTestcase();
              }}
              disabled={  (project_status==="CLOSED"|| project_status==="COMPLETED") || !callingAPI} 
            >
              <div className="mr-1">
                <ImportIcon />
              </div>
              <div>{importButton}</div>
            </button>
          </span>
        </div>
        {selectedFilename && (
          <div className="flex mt-4">
            <div className="mr-1 importfile_icon">
              <ImportFileIcon />
            </div>
            <Tooltip title={selectedFilename}>
              <div className="import_filename datatrimmer">
                {selectedFilename}
              </div>
            </Tooltip>
          </div>
        )}

        <div
          className={`import_download_btn mt-4 ${downloadingFile?"":"opacity-50"} `}
          onClick={() => {
            if(downloadingFile){
              downloadExcel()(true);
             
            }
            
          }}
        >
          <div className="mr-1">
            <ImportFile />
          </div>
          <div>Download Template</div>
        </div>
      </div>
    </div>
  );
};

export default ImportManualTestCase;
