import dateFormat from "dateformat";
import { Tooltip } from "@material-ui/core";
import JSEncrypt from 'jsencrypt';

let rsaPublickey = process.env.REACT_APP_RSA_PUBLIC_KEY;
let rsaPrivatekey = process.env.REACT_APP_RSA_PRIVATE_KEY;

function logger(...args) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV !== "production") {
    console.log(args);
  }
}
function duplicatesCheck(list, existingValue, value, key) {
  let duplicateExist = false;
  if (value !== undefined && existingValue !== value) {
    for (var i = 0; i < list.length; i++) {
      if (value === list[i][key]) {
        duplicateExist = true;
      }
    }
  }
  return !duplicateExist;
}
function isValidDateFormatYYYYMMDD(inputDate) {
  var date_regex = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;
  return date_regex.test(inputDate);
}
function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputFormat);
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("-");
}
function validateEmail(email) {
  var email_regex = /[A-Za-z0-9]+@[a-zA-Z]+\.[A-Za-z]{2,3}/;
  return email?.length > 0 && email_regex.test(email);
}
function validatePhone(phone) {
  var phone_regex = /^[0-9]{10}$/;
  // var phone_regex = /^[6-9]{1}[0-9]{9}$/;
  return phone?.toString().length > 0 && phone_regex.test(phone);
}
function validateName(name) {
  var name_regex = /^[a-zA-Z. ]+$/;
  return name?.length > 2 && name_regex.test(name);
}
//2022-08-05T00:00:00
function convertToAPIDate(cDate) {
  return dateFormat(cDate, "yyyy-mm-dd") + "T00:00:00";
}
function getLabeledValue(value) {
  if (value?.length > 25) {
      return (
          <Tooltip title={value} placement='top'>
              <span> {value.slice(0, 23)}... </span>
          </Tooltip>
      )
  } else {
      return <span> {value}</span>
  }
}
export {
  logger,
  duplicatesCheck,
  isValidDateFormatYYYYMMDD,
  convertDate,
  validateEmail,
  validatePhone,
  validateName,
  convertToAPIDate,
  getLabeledValue
};

  // TODO ::  use this function to encrypt data using RSA
 export const getEncryptData = (data, publicKey=rsaPublickey) => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    return encrypt.encrypt(data);
  };
