import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useTable, useGlobalFilter } from "react-table";
import AttachFile from "@mui/icons-material/AttachFile";
import Close from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DefectsDeatilsPopup from "./defects-deatils-popup";

function BugsDeatilsPopup(props) {
  const [openModal, setOpenModal] = useState(true);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const customStylesPage = {
    overlay: {
      background:"rgba(90, 90, 90,0.5)", 
      
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "75%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderTopLeftRadius: "4px",
      opacity: "1",
      outline: "none",
    },
  };

  let items = {
    data: [
      {
        bugId: "67675",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67676",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67675",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67676",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67675",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67676",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67675",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67676",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67675",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67676",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67675",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67676",
        summary: "Account manager",
        created_by: "Account manager",
      },
      {
        bugId: "67",
        summary: "Account manager",
        created_by: "Account manager",
      },
    ],
  };
  let columns = React.useMemo(
    () => [
      {
        Header: "BugId",
        accessor: "bugId",
        width: "20%",
        Cell: ({ value }) => {
          return <div style={{ marginLeft: "6%" }}>{value}</div>;
        },
      },
      {
        Header: "Summary",
        accessor: "summary",
        width: "20%",
        Cell: ({ value }) => {
          return <div style={{ marginLeft: "6%" }}>{value}</div>;
        },
      },
      {
        Header: "Created By",
        accessor: "created_by",
        width: "20%",
        Cell: ({ value }) => {
          return <div style={{ marginLeft: "6%" }}>{value}</div>;
        },
      },
    ],
    []
  );
  let data = React.useMemo(() => items.data, [items.data]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
    });

  function BugDetails(details) {
    setShowDetailsModal(true);
    console.log("Bug edetails", details);
  }
  function CloseDefectModal(state) {
    setShowDetailsModal(state);
    props.CloseBugModal(state);
  }
  function BackToBugList(state) {
    setShowDetailsModal(state);
  }

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        // props.CloseDefectModal(false);
        // setOpenModal(false);
      }}
    >
      <div className="defect-details">
        <div className="grid grid-cols-12 felx header">
          <span className="col-span-11 heading pl-2">Defect List</span>
          <div className="col-span-1 flex justify-end mr-2">
            {/* <div className="modal_clos_btn">
            <Close
              onClick={() => {
                props.CloseBugModal(false);
              }}
            />
            </div> */}
             <button
          onClick={() => {
            props.CloseBugModal(false);
          }}
          type="button"
          className="modal_clos_btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
            
          </div>
        </div>
        <div>
          <div className="table_container">
            <table {...getTableProps()}>
              <thead className="text-left table_column_header h-10 table_column_header_bg">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        width={column.width}
                        {...column.getHeaderProps()}
                        className="pl-4"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {data.length === 0 ? (
                <div className="table_message">
                  <span>No,data found</span>
                </div>
              ) : (
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="h-10 table_cell_value border-t-2 defect-table-row"
                        onClick={() => {
                          BugDetails(row.cells);
                        }}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>

        <div className="grid grid-cols-12 col-span-12 warning_popup_btn  h-12 pt-2">
          <div className="col-span-10"></div>
          <div className="grid grid-cols-12 col-span-2 flex justify-end">
            <div className="grid grid-cols-12 col-span-12 h-8 flex justify-end">
              <span
                className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer"
                onClick={() => {
                  props.CloseBugModal(false);
                }}
              >
                <button>Close</button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {showDetailsModal && (
        <DefectsDeatilsPopup
          CloseDefectModal={CloseDefectModal}
          BackToBugList={BackToBugList}
          heading="Bug"
        />
      )}
    </Modal>
  );
}
export default BugsDeatilsPopup;
