import React, { useState, useEffect } from "react";
import { useTable, useGlobalFilter } from "react-table";
import Close from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import BugReportIcon from "@mui/icons-material/BugReport";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./report-details.scss";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  getDefectsData,
  getUserDetailsByEmailID,
  getAllUsersListhierarchy,
  getAllTesterReq,
  getAllDefectTemplate,
  DownloadDefectExcleFile,
  getDefectsDataCount,
  getTemplateById,
} from "../../api/api-services";
import DefectFilterModal from "./defect-filter-modal";
import DefectsDeatilsPopup from "../modal/defects-deatils-popup";
import UserDeatilsPopup from "../modal/user-deatils";
import { ReactComponent as NoDefectIcon } from "../../assets/noDefectsAvailable.svg";
import IconButton from "@mui/material/IconButton";
import SearchField from "../common/search-field";
let filterBasePayLoad = {
  segmentSearchFilters: {
    severity: null,
    priority: null,
    modules: null,
    locations: null,
    createdOn: null,
  },
  webSearchFilters: {
    os: null,
    osVersion: null,
    browser: null,
    browserVersion: null,
  },
  mobileSearchFilters: {
    os: null,
    osVersion: null,
    browser: null,
    browserVersion: null,
    deviceBrand: null,
    deviceName: null,
    deviceType: null,
  },
};
let payLoad = JSON.parse(JSON.stringify(filterBasePayLoad));
let defectState = "CLOSED";
let DefectPayload = {};
function DefectlistTable(props) {
  let [defects, setdefects] = useState([]);
  let [showFilter, setShowFilter] = useState(false);
  let [dataLoaded, setDataLoaded] = useState(true);
  let [defectsTableData, setdefectsTableData] = useState([]);
  let [segmentData, setSegmentData] = useState({
    Severity: null,
    Priority: null,
    Module: null,
    Location: null,
    createdOn: null,
  });
  const [severity, setSeverity] = useState(null);
  const [priority, setPriority] = useState(null);
  const [module, setModule] = useState(null);
  const [location, setLocation] = useState(null);
  const [webPlatform, setWebPlatform] = useState(null);
  const [mobilePlatform, setMobilePlatform] = useState(null);
  let loggedinUser = JSON.parse(localStorage["user-privilege"]);
  const [approvedDefectsData, setApprovedDefectsData] = useState([]);
  const [rejectedDefectsData, setrejectedDefectsData] = useState([]);
  const [selected, setSelected] = useState(0);
  let [myDefectPageNo, setMyDefectPageNo] = useState(0);
  let [mydefectlastpage, setMyDefectlastpage] = useState();
  let [haveDefect, setHaveCreateDefect] = useState(false);
  let projectstate = JSON.parse(
    localStorage.getItem("selected-project")
  ).projectStatus;
  let [defectsPopUp, setDefectsPopUp] = useState(false);
  let [defectsdetails, setDefectsdetails] = useState();
  let [userDetails, setUserDetails] = useState();
  let [userPopup, setUserPopup] = useState(false);
  let [selectedFilters, setSelectedFilters] = useState({});
  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  const crowdProjectId = JSON.parse(
    localStorage.getItem("selected-project")
  ).id;
  const emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
  let [displayapproverdefect, setDisplayapproverdefect] = useState(true);
  let [approvedDefectPageNo, setApprovedDefectPageNo] = useState(0);
  let [rejectedDefectPageNo, setRejectedDefectPageNo] = useState(0);
  let [approvedDefectlastpage, setApprovedDefectlastpage] = useState();
  let [rejectedDefectlastpage, setRejectedDefectlastpage] = useState();
  let [defectsCount, setDefectsCount] = useState();
  let [defectTemplatedetails,setDefectTempalteDetails]=useState()
  let [closeSearchField, setCloseSearchField] = useState(false)
  let [showcloseIcon, setShowCloseicon] =useState(false);
  let [showSearch, setShowSearch] = useState(false);

  if (loggedinUser?.role?.includes("_")) {
    loggedinUser.role = loggedinUser.role.replace("_", "-");
  }

  useEffect(() => {
    payLoad = JSON.parse(JSON.stringify(filterBasePayLoad));
    setDisplayapproverdefect(true)
    getAllDefects(payLoad, false, 0, "CLOSED");
    getAllUsersList();
    getDefaultDefectTemplate();
  }, [props.allowRejectedDefect]);
  useEffect(() => {
    segmentDataHandler(props.data);
  }, [props]);
  const segmentDataHandler = (data) => {    
    if(data?.defectModule !== undefined){
      let tempModule1 = data?.defectModule[0]?.approved  === null ? []: Object.keys(data?.defectModule[0]?.approved);
      let tempModule2 = data?.defectModule[1]?.rejected  === null ? []:Object.keys(data?.defectModule[1]?.rejected);
      console.log("modlues", tempModule1.concat(tempModule2));
      let tempModule3 = removeDuplicateModules(tempModule1.concat(tempModule2))
      console.log("modlues no duplicates", tempModule3);      
      setModule(tempModule3);
    }   
  };
  const removeDuplicateModules = (arr) => {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}

  const Styles = styled.div`
    table {
      border-spacing: 0;
      border: 1px solid #707070;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        border-bottom: 1px solid #707070;
        border-right: 1px solid #707070;

        :last-child {
          border-right: 0;
        }
      }
    }
  `;
  const handlescroll = (event) => {
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
      if (mydefectlastpage === false) {
        getAllDefects(payLoad, false, myDefectPageNo, defectState);
      }
    }
  };

  const getUserDetails = async (e) => {
    let emailId = e.Details.defectDetails.history[0].userEmail;
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    try {
      let response = await getUserDetailsByEmailID(projectId, emailId);
      if (response?.status === 200) setUserDetails(response.data);
      setUserPopup(true);
      setCloseSearchField(true)
      console.log("getUserDetails", response);
    } catch (err) {
      console.log("error");
    }
  };
  const getTemplateDetails=async (e)=>{
    console.log("eeee",e.defectTemplateId)
    console.log("eeee Details",e)
    let fireFlinkProjectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
    let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
    let templateID=e.defectTemplateId;
    try{
      let response =await getTemplateById(fireFlinkProjectId, templateID, licenseId);
      if(response.data.responseCode===200){
        
        setDefectTempalteDetails(response)
        setDefectsdetails(e);
        setDefectsPopUp(true);
        setCloseSearchField(true)
      }else{
        props.MyAlert.info("Something went wrong")
      }
      
  
    }catch(error){
      console.log("errror")
    }
   
   }

  function CloseDefectModal(state) {
    if(globalFilter!==undefined){
      setShowSearch(true)
    }else{
      setShowSearch(false)
      setGlobalFilter();
      setShowCloseicon(false);
     
    }
    setDefectsPopUp(state);
  }

  function CloseTesterModal(state) {
    if(globalFilter!==undefined){
      setShowSearch(true)
    }else{
      setShowSearch(false)
      setGlobalFilter();
      setShowCloseicon(false);
     
    }
    setUserPopup(state);
  }
  const getAllDefectsWithFilter = (payload, filtersAdded, filters, callApi) => {
   console.log("defectState",defectState)
    payLoad = payload;
    setMyDefectPageNo(0);
    setSelectedFilters(filters);
    if (callApi) {
      getAllDefects(payLoad, filtersAdded, 0, defectState);
      localStorage.setItem("currentReportTab", JSON.stringify("DefectReports"));
    }
  };


  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
  const downloadExcleFile=async ()=>{
    let filename=displayapproverdefect?"Approved_Defects.xlsx":"Rejected_Defects.xlsx";
    let state=displayapproverdefect?"CLOSED":"REJECTED";
    let filter=Object.keys(selectedFilters).length === 0?{}:DefectPayload
      try{
        let response=await DownloadDefectExcleFile(projectId,licenseID,state,filter);
        console.log("response file", response);
        if (response.status === 200) {
          saveFileFromBlob(response.data, filename);
        }
  
      }catch(err){
        console.log("error")
  
      }
    }

  const getAllDefects = async (payload, filtersAdded, defectPageNo, state) => {
    let page = defectPageNo,
      size = 10;
    try {
      let responseObject = await getDefectsData(
        projectId,
        licenseID,
        payload,
        page,
        size,
        // "CLOSED"
        state
      );
      console.log("responseObject", responseObject.data.responseObject);
      defectState = state;
      DefectPayload = payload;
      if (responseObject?.data?.responseCode === 200) {
        setMyDefectlastpage(responseObject?.data?.lastPage);
        setMyDefectPageNo(page + 1);
        // defectState = state;
        // DefectPayload = payload;
        console.log("defectState 123",defectState )
        setdefects(responseObject.data.responseObject);
        data = [...responseObject.data.responseObject];
        let tempArray = [];
        data.map((defect, index) => {
          tempArray.push({
            bugId: defect?.defectDetails?.ID,
            bugSummary: defect.defectDetails?.Summary,
            createdBy: defect?.defectDetails["Created By"]?.name,
            Details: defect,
          });
        });
        if (page === 0 || filtersAdded) {
          setdefectsTableData([...tempArray]);
          getAllDefectsCount(payload, filtersAdded, defectPageNo, state);
        } else {
          let defectArray = [];
          defectArray = defectsTableData.concat(tempArray);
          setdefectsTableData(defectArray);
        }
      } else {
        setdefectsTableData([]);
        setDefectsCount(0)
      }
    } catch (err) {
      console.log("error");
    }
  };
  const getAllDefectsCount = async (payload, filtersAdded, defectPageNo, state) => {
    try {
      let responseObject = await getDefectsDataCount(
        projectId,
        licenseID,
        payload,
        state
      );
      console.log("responseObject count", responseObject);
      if (responseObject?.data?.responseCode === 200) {
        setDefectsCount(responseObject?.data?.totalDefectCount);
      }
    } catch (err) {
      console.log("error");
    }
  }
  const getAllUsersList = async () => {
    try {
      let response = await getAllTesterReq(crowdProjectId, emailId);

      if (response?.data) {
        console.log("users data", response.data);
        let tempCityArray = [],
          tempMobile = [],
          tempWeb = [];
        response.data.map((obj) => {
          if (obj.configuration !== null) {
            obj.configuration.configPlatformDetails.map((obj1) => {
              if (
                obj1.deviceType === "smartphone" ||
                obj1.deviceType === "tablet"
              ) {
                tempMobile.push(obj1);
              } else {
                tempWeb.push(obj1);
              }
              if (!tempCityArray.includes(obj1.city)) {
                tempCityArray.push(obj1.city);
              }
            });
            
          }
        });
        setWebPlatform(tempWeb);
        setMobilePlatform(tempMobile);
        setLocation(tempCityArray)
      }
    } catch (error) {
      console.log("error");
    }
  };
  const getDefaultDefectTemplate = async (e) => {
    try {
      segmentDataHandler(props.data);
      let response = await getAllDefectTemplate(projectId, licenseID);
      if (response?.data?.responseCode === 200) {
        localStorage.setItem(
          "Default-defect-template",
          JSON.stringify(response.data.responseObject.defaultDefectTemplate)
        );
        let tempDetails =
          response.data.responseObject.defaultDefectTemplate.defectDetails;

        tempDetails.map((obj, i) => {
          if (obj.label === "Severity") {
            setSeverity(
              obj.options !== undefined && obj.options.length !== 0
                ? obj.options
                : null
            );
          }
          if (obj.label === "Priority") {
            setPriority(
              obj.options !== undefined && obj.options.length !== 0
                ? obj.options
                : null
            );
          }
        });
      }
    } catch (error) {
      console.log("error");
    }
  };

  let columns = React.useMemo(
    () => [
      {
        Header: "BUG ID",
        accessor: "bugId",
        width: "9%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              console.log(
                "e.cell.row.original.Details",
                e.cell.row.original.Details
              );
              getTemplateDetails(e.cell.row.original.Details)
              // setDefectsdetails(e.cell.row.original.Details);
              // setDefectsPopUp(true);
            }}
            className="reportTablecell popins cursor-pointer	"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "BUG SUMMARY",
        accessor: "bugSummary",
        width: "59.7%",
        Cell: (e) => (
          <div
            
            className="datatrimmer"
            title={e.value}
           >
            {e.value}
          </div>
        )
      },
      {
        Header: "CREATED BY",
        accessor: "createdBy",
        width: "20%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              // setShowSearch(false)
              getUserDetails(e.cell.row.original);
            }}
            className="reportTablecell popins cursor-pointer	"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
    ],
    []
  );
  let data = React.useMemo(() => defectsTableData, [defectsTableData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  function CloseDefectFilterModal(state) {
    setShowFilter(state);
  }

  function updatetableData(state, value) {
    if(defectsTableData.length!==0){
      let tempEle = document.getElementById("defectlog");
        tempEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
    }
    
    if (value === "approved") {
      setDisplayapproverdefect(state);
      setMyDefectlastpage();
      setMyDefectPageNo(0);
     
      getAllDefects(DefectPayload, false, 0, "CLOSED");
    } else {
      setDisplayapproverdefect(state);
      setMyDefectlastpage();
      setMyDefectPageNo(0);
      getAllDefects(DefectPayload, false, 0, "REJECTED");
    }
  }

  return (
    <div className="defectDetails">
      <div className="defecttablelistOutline">
        <div className="pt-5 pb-6 pl-3 flex items-center">
          <span className="darkBlue pr-3 heading">Defect Log - {defectsCount}</span>
          {/* <span className="darkBlue pr-3 text-2xII">{props?.listCount}</span> */}
          <span className="float-right dropdownList3 ml-auto flex items-center">
            <span className="mr-2 mt-1">
            {/* <SearchField setGlobalFilter={setGlobalFilter} closeField={closeSearchField} setCloseField={setCloseSearchField} /> */}
            <>
      <Tooltip title={showcloseIcon?"":"Search"}>
      <div id="container" className={`${props.colSpan?props.colSpan:"col-span-11"}`} >
      <input
        className={`${showSearch ? "expanded-table-search" : "table-search"} ${props.inputClass?props.inputClass:""}`}
        type="text"
        value={globalFilter || ""}
        autoFocus={false}
        placeholder="Search"
       
        onChange={(e) => {
          setGlobalFilter(e.target.value);
        }}
        onClick={() => {
          setShowSearch(true);
          setTimeout(() => {
            setShowCloseicon(true);
          },200);
        }}
       
      />
      {showcloseIcon && (
        <div
          className={`col-span-2 search_icon_close  ml-2 cursor-pointer ${props.closeIconClass?props.closeIconClass:""}`}
          // onClick={() => {
          //   setSearchInputValue("")
          //   if(props.page === "projectList" && props?.onClose){
          //       props.onClose()
          //   }else if(props.page === "platformDetails" && props?.onClose){
          //     props.onClose()
          //   }else{
          //       props.setGlobalFilter();
          //   }
          //   setShowCloseicon(false);
          //   setShowSearch(false);
          // }}
          onClick={()=>{
            setGlobalFilter();
            setShowCloseicon(false);
            setShowSearch(false)
          }}

        >
          <Close />
        </div>
      )}
    </div>
        </Tooltip>
      
</>
  

            </span>
          
            <Tooltip title="Download">
              <span className={`pr-4 downloadIcon ${defectsTableData.length===0?"opacity-30":""}`}>
                <FileDownloadOutlinedIcon className="darkBlue" onClick={()=>{if(defectsTableData.length>0){downloadExcleFile()}}}/>
              </span>
              
            </Tooltip>

            <Tooltip title="Filters">
              <span className="filterIcon">
                <FilterAltOutlinedIcon
                  onClick={() => {
                    CloseDefectFilterModal(true);
                    setCloseSearchField(true)
                  }}
                />
              </span>
            </Tooltip>
          </span>
        </div>
        {props.allowRejectedDefect ? (
          <div className="mb-5 pl-3">
            <span
              className={`cursor-pointer popins ${
                displayapproverdefect ? "selectedDefects darkBlue" : null
              }  font-12 pb-2 mr-8 h-8`}
              onClick={() => {
                updatetableData(true, "approved");
              }}
            >
              Approved Defects
            </span>
            <span
              className={`h-8 pb-2 popins cursor-pointer ${
                !displayapproverdefect ? "selectedDefects darkBlue" : null
              } font-12`}
              onClick={() => {
                updatetableData(false, "rejected");
              }}
            >
              Rejected Defects
            </span>
          </div>
        ) : null}
        <div className="defectTablecontainer ">
          <table {...getTableProps()} className=" overflow-x-auto">
            <thead className="text-left  h-10">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      width={column.width}
                      {...column.getHeaderProps()}
                      className={` reportTableheader popins-semi-bold text-lg font-semibold ${
                        column.Header === "BUG SUMMARY"
                          ? "text-left pl-3"
                          : "text-center"
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {data.length === 0 ? (
              <div className="grid grid-cols-12 nodefect_container">
                <div className="col-span-12 flex justify-center">
                  <NoDefectIcon />
                </div>
              </div>
            ) : (
              <div>
                <tbody
                  {...getTableBodyProps()}
                  // className="border-t-2"
                  onScroll={handlescroll}
                >
                  {rows.length > 0 ? (
                    <>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="h-10 " id="defectlog" >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  width={cell?.column?.width}
                                  {...cell.getCellProps()}
                                  className={`datatrimmer reportTablecell ${
                                    cell.column.Header === "BUG ID" ||
                                    cell.column.Header === "CREATED BY"
                                      ? "reportBugIdColumn popins-semi-bold"
                                      : "popins"
                                  } ${
                                    cell.column.Header === "BUG SUMMARY"
                                      ? "text-left pl-3"
                                      : "text-center"
                                  }`}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="grid grid-cols-12  nodefect_container">
                        <div className="col-span-12 flex justify-center">
                          <NoDefectIcon />
                        </div>
                      </div>
                    </>
                  )}
                </tbody>
              </div>
            )}
          </table>
          {/* <Styles>
          </Styles> */}
        </div>
      </div>

      {showFilter && (
        <DefectFilterModal
          CloseDefectFilterModal={CloseDefectFilterModal}
          data={defects}
          Severity={severity}
          Priority={priority}
          Module={module}
          Location={location}
          web={webPlatform}
          mobile={mobilePlatform}
          filterApply={getAllDefectsWithFilter}
          filters={selectedFilters}
        />
      )}

      {defectsPopUp && (
        <DefectsDeatilsPopup
          CloseDefectModal={CloseDefectModal}
          SelectedDefect={defectsdetails}
          defectTemplatedetails={defectTemplatedetails}
        />
      )}
      {userPopup && (
        <UserDeatilsPopup
          CloseTesterModal={CloseTesterModal}
          testerDeatils={userDetails}
        />
      )}
    </div>
  );
}

export default DefectlistTable;
