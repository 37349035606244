import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import MyPassword from "../common/inputs/my-password";
import LabeledInput from "../common/inputs/labeled-input";
// import LogotextImage from "../../assets/fire_crowd.svg";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import BackgroundDown from "../../assets/login_logo.png";
import { Link } from "react-router-dom";
import "./login-page.css";
import {
  createTheme,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { loginInRequest, getMyRole,getALLDomainReq,hasCertificateReq } from "../../api/api-services";
import ErrorPopup from "../modal/error-popup";
import { CREATE_ACCOUNT } from "../../util/app-constants.js";
import LabeledPasswordInput from "../common/inputs/passwordInputs";
import { useSelector, useDispatch } from 'react-redux'
import { saveFireCrowdUser } from "../../actions/firecrowd-User-slice";

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        background: "transparent",
        padding: 0,
        minWidth: 320,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  name: {
    "& .MuiInputBase-input": {
      background: "transparent",
      color: "#000",
      // minWidth: "320px !important",
    },
    " &.MuiInput-underline": {
      minWidth: "330px !important",
    },
    
  },
  pass: {
    "& .MuiInputBase-input": {
      background: "transparent",
      color: "#000",
      // marginTop: "10px",
      // minWidth: "320px !important",
    },
    " &.MuiInput-underline": {
      minWidth: "330px !important",
    },
  },
 
}));
export default function LoginPage({ setToken }) {
  const classes = useStyles();
  const [showError, setShowError] = useState(false);
  const [errormodal, setErrorModal] = useState(false);
  const [createUpdateCalled, setCreateUpdateCalled] = React.useState(true);
  const [errorContent, setErrorContent] = React.useState();
  const [signInButton,setSignInButton]=React.useState("Sign in");
  const [callingAPI,setCallingAPI]=useState(false)
  const dispatch = useDispatch()

  useEffect(()=>{
    if(callingAPI){
      setSignInButton("Signing In...") 
    }else{
      setSignInButton("Sign in") 
    }

  },[callingAPI])

  useEffect(() => {
    localStorage.setItem("sessionData", "");
    // Temporarily added
    localStorage.clear();
  }, []);
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const errorPopup = (status) => {
    setErrorModal(status);
  };

  const formik = useFormik({
    initialValues: {
      emailId: "",
      password: "",
    },
    validationSchema: Yup.object({
      emailId: Yup.string()
        .matches(
          /[A-Za-z0-9]+@[a-zA-Z]+\.[A-Za-z]{2,3}/,
          "Enter valid email Id"
        )
        .email("Enter valid email Id")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      // setSignInButton("Signing In...")
      setCallingAPI(true)
      if (createUpdateCalled) {
        
        setCreateUpdateCalled(false);
        const data = {
          emailId: values.emailId.toLowerCase(),
          password: values.password,
          source: "FireCrowd",
        };
        setShowError(false);
        loginInRequest(data)
          .then((res) => {
            if (res.data && res.data.responseObject) {
              const response = res.data.responseObject;
              if (response.access_token) {
                localStorage.setItem(
                  "fire-crowd-user",
                  JSON.stringify(response)
                );
                dispatch(saveFireCrowdUser(response) || 0)
                hasCerificateCheck(values.emailId)
                getRole();
                getDomain();
               
                // navigate("/project/list", { replace: true });
                setToken({ token: "test123" });
              } else {
                
                const error = response && response.length ? response[0] : {};
                // setSignInButton("Sign in")
               
                setShowError(true);
                setErrorContent(
                  error.message
                    ? error.message
                    : "Username or Password is invalid. Please try again"
                );
              }
            } else {
              setCallingAPI(false)
              setShowError(true);
              setErrorContent(res.data.message);
            }
            if (res.data && res.data.message === "User is not activated") {
              setCallingAPI(false)
              navigate("/registration-activation?email=" + values.emailId, {
                replace: true,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
     
    },
  });
  const check = ({ target }) => {
    if (formik.values.password.length === 0) {
      setShowError(false);
    }
  };
  const doubleError = (e) => {
    if (e.target.value.length === 0) {
      setShowError(false);
    }
  };
  const getRole = async () => {
    let emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
    try {
      let response = await getMyRole(emailId);
      console.log("my role",response)
      if (response.status === 202) {
        localStorage.setItem("my-role", JSON.stringify("admin"));
        navigate("/all-Projects", { replace: true });
      } else {
        localStorage.setItem("my-role", JSON.stringify(""));
        navigate("/all-Projects", { replace: true });
      }
    
      
    } catch (error) {
      console.log("error");
    }
  };
  const getDomain=async ()=>{
    let domainArry=[]
    try{
      let response=await getALLDomainReq();
      if(response.status===200){
        response?.data.map((domain)=>{
          domainArry.push(domain.domainName);
        });
        localStorage.setItem("domain-list",JSON.stringify(domainArry))
      }else{
        
      }

    }catch(error){
      console.log("error")
    }
  }
  
  const hasCerificateCheck=(emailId)=>{
  hasCertificateReq(emailId).then((response)=>{
    if(response.status===200){
      console.log("response",response.data)
      localStorage.setItem("has-Certificate",JSON.stringify(response.data));
    }

  }).catch((error)=>{
    console.log("Error")
  })
   
   
  }
  return (
    <div className="rootClass">
      <div className="min-h-screen bg-white flex mainContainer loginbody">
        <div className="flex-1 login-left-container" id="leftImage">
          <div className="leftsideimg-container">
            <img
              className=" mobile-hide leftsideimg"
              src={LogotextImage}
              height="200px"
              width="300px"
              alt="Qspider"
            />
          </div>
          {/* <div>
            <span className="secondText">
              One application for multiple types of testing
            </span>
          </div> */}
          <div className="leftsideimg2-container">
            <img
              className=" mobile-hide leftsideimg2"
              src={BackgroundDown}
              alt="Qspider"
            />
            <div className="flex flex-col items-center justify-center">
              <h1 className="page-for">Already An User</h1>
              <h2 className="page-note">
                Enter email id, password and access your account quickly
              </h2>
            </div>
          </div>
          {/* <div>
            <h1 className="belowText ml-32">Already An User</h1>
            <label className="text-center lastText">
              Enter email id,password and access your account quickly
            </label>
          </div> */}
        </div>
        <div className="flex-1 login-right-container">
          <div className="mx-auto w-full max-w-sm lg:w-96 loginGrid">
            <div className="gridContent">
              <div className="signinUserText  landscapeSignIn">
                <span className="mt-6 signin_header">Sign in</span>
                <br />
                <div className="text-xs newUser">
                Don't have an account?{" "}
                  <Link to="/signup" underline="none">
                    <a onMouseDown={(e) => e.preventDefault()}>
                      <span
                        className="ml-1 create_account"
                        onClick={() => formik.setErrors({})}
                      >
                        {CREATE_ACCOUNT}
                      </span>
                    </a>
                  </Link>
                </div>
              </div>
              <div className="mt-4">
                <form
                  onSubmit={formik.handleSubmit}
                  className="space-y-2 landScapeLogin"
                  noValidate
                >
                  <div className="signinAll">
                    <div className="signinUserEmail">
                    <div>
                            {" "}
                            <span className="star text-red-600">*</span>
                            <span className="grayText login-labels">
                              {" "}
                              Email{" "}
                            </span>
                          </div>
                      <LabeledInput
                        //  label={<span className="login-labels">Email</span>}
                        //  required
                        id="email"
                        placeholder="Enter Email ID"
                        error={
                          (formik.errors.emailId && formik.touched.emailId) ||
                          (formik.touched.password && !formik.values.emailId)
                        }
                        value={formik.values.emailId}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          setCreateUpdateCalled(true);
                        }}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        onFocus={() => {setShowError(false); setCallingAPI(false)}}
                        name="emailId"
                        autoFocus
                        helperText={
                          <span className="error_text -ml-2">
                            {(formik.touched.emailId &&
                              formik.errors.emailId) ||
                              (formik.touched.password &&
                                !formik.values.emailId)}
                          </span>
                        }
                      />
                      {showError === true ? (
                        <div className="error-msg error_text">{``}</div>
                      ) : (
                        ""
                      )}
                    </div>
                    {!formik.values.emailId &&
                      formik.touched.password &&
                      !formik.touched.emailId && (
                        <div className="errorBelow error_text -ml-2">
                          Email is required
                        </div>
                      )}
                    <div className="extraHeight"></div>
                    <div className="h-20">
                      <div className="passwordField">
                        {formik.errors.password && formik.touched.password ? (
                          <>
                            {" "}
                            <span className="star text-red-600">*</span>
                            <span className="grayText login-labels">
                              {" "}
                              Password{" "}
                            </span>
                          </>
                        ) : (
                          <>
                            {" "}
                            <span className="star text-red-600">*</span>
                            <span className="grayText login-labels">
                              {" "}
                              Password{" "}
                            </span>
                          </>
                        )}
                      </div>
                      <MuiThemeProvider theme={theme}>
                        <LabeledPasswordInput
                          className={classes.pass}
                          placeholder="Enter Password"
                          id="password"
                          error={
                            formik.errors.password && formik.touched.password
                          }
                          value={formik.values.password}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            setCreateUpdateCalled(true);
                          }}
                          onChange={(e) => {
                            formik.handleChange(e);
                            doubleError(e);
                          }}
                          onFocus={() => {setShowError(false); setCallingAPI(false)}}
                          name="password"
                          onKeyUp={(e) => check(e)}
                        />
                        {formik.touched.password && formik.errors.password && (
                          // <FormHelperText>
                          <span className="helperText text-red-600 password_error_text error_msg mt-2">
                            {formik.touched.password && formik.errors.password}
                          </span>
                          // </FormHelperText>
                        )}
                        {showError === true ? (
                          <span
                            style={{ position: "relative", top: "-39px" ,left:"10px"}}
                            className="error-msg error_text"
                          >
                            {`Email Id or Password is invalid. Please try again`}
                          </span>
                        ) : (
                          ""
                        )}
                      </MuiThemeProvider>
                    </div>
                    {/* <div className="extraHeight"></div> */}
                    <div className="flex items-center justify-between division loginDivision">
                      <div className="flex items-center"></div>
                      <div>
                        <span
                          class="text-blue-500 forgetPassword font-semibold"
                          id="forgetPassword"
                        >
                          <a
                            onClick={() => formik.setErrors({})}
                            href="/forgot-password"
                          >
                            Forgot password?
                          </a>
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="mt-2.5">
                        <button
                          type="submit"
                          className="submitButton"
                        >
                          {/* Sign in */}
                          {signInButton}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
