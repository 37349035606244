import React, { useEffect, useState, useRef } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./config.scss";

const RichTextEditor = ({
  convertedContent,
  setConvertedContent,
  readOnly,
  toolbarHidden,
  toolbarOptions,
}) => {
  const [editorState, setEditorState] = useState(null);
  const editorRef = useRef(null);
  const defaultToolBarOptions = [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "emoji",
    "image",
    "remove",
    "history",
  ];
  useEffect(() => {
    let editorState = null;
    if (convertedContent) {
      editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(convertedContent))
      );
    } else {
      editorState = EditorState.createEmpty();
    }
    setEditorState(editorState);
  }, []);

  useEffect(() => {
    let editorState = null;
    if (!convertedContent) {
      editorState = EditorState.createEmpty();
      setEditorState(editorState);
    }
  }, [convertedContent]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToRAW();
  };
  const convertContentToRAW = () => {
    let currentContentAsRaw = convertToRaw(editorState.getCurrentContent());
    setConvertedContent(JSON.stringify(currentContentAsRaw));
  };
  // const handlePastedText = (text, html, editorState) => {
  //   const newState = EditorState.push(
  //     editorState,
  //     contentStateWithNewLine(editorState, text),
  //     "insert-characters"
  //   );
  //   setEditorState(EditorState.forceSelection(newState, newState.getSelectionAfter()));
  //   return true;
  // };
  const contentStateWithNewLine = (editorState, text) => {
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const endOffset = selectionState.getEndOffset();
    const block = currentContent.getBlockForKey(selectionState.getEndKey());
    const blockLength = block.getLength();
    const textWithNewLine = `${block.getText().slice(0, endOffset)}\n${text}${block.getText().slice(
      endOffset,
      blockLength
    )}`;
    const newContentState = currentContent.merge({
      blockMap: currentContent.getBlockMap().set(
        block.getKey(),
        block.merge({
          text: textWithNewLine,
        })
      ),
    });
    return EditorState.createWithContent(newContentState);
  };
  return (
    <div>
      <Editor
        // ref={editorRef}
        editorState={editorState}
         onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapperClass"
        editorClassName="editorClass heading-TextEditor-style"
        readOnly={readOnly}
        toolbarHidden={toolbarHidden}
        toolbarClassName="toolbarClass"
        // handlePastedText={handlePastedText}
        // stripPastedStyles={true}
        toolbar={{
          options:
            Array.isArray(toolbarOptions) && toolbarOptions?.length > 0
              ? toolbarOptions
              : defaultToolBarOptions,
          fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'OpenSans-Regular', 'Tahoma', 'Times New Roman', 'Verdana']
          }
        }}
      />
    </div>
  );
};
export default RichTextEditor;