import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import Close from "@mui/icons-material/Close";
import { blue } from "@mui/material/colors";
import dateFormat from "dateformat";
import TCExecutionTabel from "../reports/tcexecution-tabel";
// import useCollapse from 'react-collapsed';
import AttachFile from "@mui/icons-material/AttachFile";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ReactComponent as ArrowIcon } from "../../assets/left-arrow-icon.svg";
import "./modal.scss";
import { ReactComponent as Attachments } from "../../assets/attachment_icon.svg";
import { ReactComponent as DownLoad } from "../../assets/download_icon.svg";
import PlatformDetails from "./platform-details";
import DefectDetails from "./defect-deatils";
import { getTemplateById } from "../../api/api-services";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { SelectDropdown } from "../project/modals/select-dropdown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { TextareaAutosize, TextField, Select } from "@mui/material";
import { getLabel, getOptionLabel } from "../config/common-functions";
import { ReactComponent as Search } from "../../assets/search.svg";

import {
  getAllExecutionResultReq,
  getTolatExecutionResultCount,
  getTestcaseExecitionbySearch
} from "../../api/api-services";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import FormControl from "@material-ui/core/FormControl";
import RichTextEditor from "../config/rich-texteditor";
import { ReactComponent as NoExecutionIcon } from "../../assets/noExecutionIcon.svg";
import { CommonLoader } from "../common/common-loader";

function TCResultDeatilsPopup(props) {
  console.log("TCResultDeatilsPopup", props);
  const [openModal, setOpenModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [testerData, setTesterData] = useState(props?.testerDeatils);
  // const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const [header, setHeader] = useState();
  const [userName, setUserName] = useState();
  const [role, setRole] = useState();
  const [reason, setReason] = useState();
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  const [activeKeys, setActiveKeys] = React.useState(["0"]);
  const [expanded, setExpanded] = React.useState("panel1");
  let pageSize = 5;
  let [pageNo, setpageNo] = useState(0);
  let [lastPage, setLastPage] = useState(false);
  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  let [TestcaseResulltData, setTestcaseResulltData] = useState([]);
  let [resultCount, setResultCount] = useState(0);
  let [callingAPI, setCallingAPI] = useState();
  let projectType =
    JSON.parse(localStorage["selected-project"]).project?.projectType
      ?.mobile !== null
      ? "Mobile"
      : JSON.parse(localStorage["selected-project"]).project?.projectType
          ?.web !== null
      ? "Web"
      : JSON.parse(localStorage["selected-project"]).project?.projectType
          ?.webAndMobile !== null
      ? "Web And Mobile"
      : "Others";

  useEffect(() => {
    console.log("getAllExecutionResult", props.payload);
    getAllExecutionResult(pageNo);
  }, [props]);
  function getToatalResultCount(payload) {
    getTolatExecutionResultCount(payload, projectId, licenseID)
      .then((response) => {
        console.log("getToatalResultCount", response);
        if (response?.status === 200) {
          setResultCount(response?.data);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }
  // function handleSearch(){
  //   console.log("handleSearch")
  //   let payload={
  //       modules: [],
  //       location: [],
  //       users: [],
  //       executionResult: [
  //           "PASS",
  //           "FAIL"
  //       ],
  //       testCaseId: [
  //           "SCR1002"
  //       ]
  //   }
  //   getTestcaseExecitionbySearch(payload,0,10,"10").then((res)=>{
  //     console.log("response",res)

  //   }).catch((error)=>{
  //     console.log("error",error)
  //   })
  // }
  function getAllExecutionResult(page) {
    if (page === 0) {
      setIsLoading(true);
    }

    setCallingAPI(true);
    getAllExecutionResultReq(
      projectId,
      licenseID,
      props.payload,
      page,
      pageSize
    )
      .then((response) => {
        console.log("getAllExecutionResultReq", response);

        if (response?.data?.responseCode === 200) {
          let tempList = [];
          let data = response?.data?.responseObject.testCaseResults;
          setLastPage(response.data.responseObject.lastPage);
          setpageNo(page + 1);
          console.log("props.testCaseTemplate 123", props.testCaseTemplate);
          data.map((obj) => {
            console.log("props.testCaseTemplate 345", obj);
            let tempData = JSON.parse(JSON.stringify(props.testCaseTemplate));
            tempData.executionId = obj.fireCrowdTestcaseId
              ? obj.fireCrowdTestcaseId
              : obj.id;
            tempData.executionStatus = obj.executionStatus;
            tempData.manualTestCase.testSteps.rows.map((obj1, index1) => {
              obj1.Status =
                obj.results[index1]?.status !== undefined
                  ? obj.results[index1].status
                  : "";
              obj1["Actual Result"] = obj.results[index1]?.actualResult;
              tempData["platformName"] = obj.platformName;
              tempData["os"] = obj.os;
              tempData["osVersion"] = obj.osVersion;
              tempData["browser"] = obj.browser;
              tempData["browserVersion"] = obj.browserVersion;
              tempData["deviceBrand"] = obj.deviceBrand;
              tempData["deviceName"] = obj.deviceName;
              tempData["city"] = obj.location.city;
              tempData["executionStatus"] = obj.executionStatus;
              tempData["tester"] = obj.users[0];
              tempData["createdOn"] = obj?.createdOn;
              return obj1;
            });
            tempList.push(tempData);
            console.log("tempList", tempList);
          });

          if (page === 0) {
            console.log("tempList 2", tempList);
            setTestcaseResulltData(tempList);
            getToatalResultCount(props.payload);
          } else {
            let tempArray = TestcaseResulltData.concat(tempList);
            console.log("tempList 3", tempArray);
            setTestcaseResulltData(tempArray);
          }
        }
        setIsLoading(false);
        setCallingAPI(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  let actualDetails = {
    manualTestCase: {
      testSteps: {
        headers: [
          "SL",
          "Test Description",
          "Test Steps",
          "Test Data",
          "Expected Result",
          "Actual Result",
          "Status",
        ],
        rows: [
          {
            SL: "1",
            "Test Description":
              "Verify user is able to create template from all project level.",
            "Test Steps":
              "Navigate to all project level. Click on + Template button. Click on Create button",
            "Test Data": "Navigate to all project level",
            "Expected Result": "Navigate to all,Verify user is able to create ",
            "Actual Result":
              "Navigate to all project,Verify user is able to create",
            Status: "",
          },
          {
            SL: "2",
            "Test Description":
              "Verify user is able to create template from all project level.",
            "Test Steps":
              "Navigate to all project level. Click on + Template button. Click on Create button",
            "Test Data": "",
            "Expected Result": "",
            "Actual Result": "",
            Status: "",
          },
          {
            SL: "3",
            "Test Description":
              "Verify user is able to create template from all project level.",
            "Test Steps":
              "Navigate to all project level. Click on + Template button. Click on Create button",
            "Test Data": "",
            "Expected Result": "",
            "Actual Result": "",
            Status: "",
          },
          {
            SL: "4",
            "Test Description":
              "Verify user is able to create template from all project level.",
            "Test Steps":
              "Navigate to all project level. Click on + Template button. Click on Create button",
            "Test Data": "",
            "Expected Result": "",
            "Actual Result": "",
            Status: "",
          },
          {
            SL: "5",
            "Test Description":
              "Verify user is able to create template from all project level.",
            "Test Steps":
              "Navigate to all project level. Click on + Template button. Click on Create button",
            "Test Data": "",
            "Expected Result": "",
            "Actual Result": "",
            Status: "",
          },
          {
            SL: "6",
            "Test Description":
              "Verify user is able to create template from all project level.",
            "Test Steps":
              "Navigate to all project level. Click on + Template button. Click on Create button",
            "Test Data": "",
            "Expected Result": "",
            "Actual Result": "",
            Status: "",
          },
          {
            SL: "7",
            "Test Description":
              "Verify user is able to create template from all project level.",
            "Test Steps":
              "Navigate to all project level. Click on + Template button. Click on Create button",
            "Test Data": "",
            "Expected Result": "",
            "Actual Result": "",
            Status: "",
          },
        ],
      },
    },
  };
  // const handleSelect = (eventKey: AccordionEventKey) => setActiveKeys(eventKey as string[]);
  const [TemplateData, setTemplateData] = useState(
    JSON.parse(JSON.stringify(actualDetails.manualTestCase))
  );
  // const TemplateData = JSON.parse(
  //   JSON.stringify(props.testCaseTemplate.manualTestCase)
  // );
  let [tableRowData, setTableRowData] = useState(
    actualDetails?.manualTestCase?.testSteps?.rows
  );
  let [statusOption, setStatusOption] = useState([
    {
      label: "Pass",
      value: "Pass",
    },
    {
      label: "Fail",
      value: "Fali",
    },
  ]);
  let [selectedStatus, setSelectedStatus] = useState([
    { label: "pass", value: "Pass" },
  ]);
  function OnchangeStatus(data) {
    console.log("status of testcase", data);
  }

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "63%",
      transform: "translate(-50%, -50%)",
      width: "75%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };
  function PageLoader() {
    // setIsLoading(state);
    setIsLoading(true);
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
  }

  function emailMask(data) {
    let length = data.length;
    let email = "*********" + data.slice(length / 2, length);
    return email;
  }
  function phoneNumberMask(phone) {
    let num = "*****" + phone.slice(5, 10);
    return num;
  }
  const handleToggleClick = () => {
    const index = activeKeys.indexOf("0");
    if (index > -1) {
      activeKeys.splice(index, 1);
      setActiveKeys([...activeKeys]);
    } else {
      setActiveKeys(activeKeys.concat("0"));
    }
  };
  const handleCollapseClick = () => {
    setActiveKeys([]);
  };
  // const handleChange =
  //     (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //         setExpanded(newExpanded ? panel : false);
  //     };

  const testCaseDetailDescElements = (data) => {
    switch (data.type) {
      case "textField":
        return (
          <div className="rs-user-input-container">
            <TextField
              onClick={() => {
                console.log(data?.value);
              }}
              disabled={true}
              InputProps={{ disableUnderline: true }}
              className="lg:w-72"
              title={data.placeholder.length > 30 ? data.placeholder : null}
              placeholder={
                data.placeholder.length > 30
                  ? data.placeholder.substr(0, 30) + "..."
                  : data.placeholder
              }
              value={data.value}
            />
          </div>
        );

      case "textArea":
        return (
          <>
            <div className="">
              <TextareaAutosize
                disabled={true}
                InputProps={{ disableUnderline: true }}
                title={data.placeholder.length > 30 ? data.placeholder : null}
                id="description"
                name="description"
                maxLength="200"
                maxRows={0}
                className=" bg-transparent block lg:w-72 border-0 border-b-2 rs-input-style-textarea popup-input-bg  -mt-1 pl-px  input-field-color descriptionStyle textarea-resize"
                placeholder={
                  data.placeholder.length > 30
                    ? data.placeholder.substr(0, 30) + "..."
                    : data.placeholder
                }
                value={data.defaultValue}
              />
              {data && data.minLength >= 0 && data.maxLength >= 0 ? (
                <div className="mt-1 text-sm text-gray-500 text-right mr-2">
                  {data.minLength}/{data.maxLength}
                </div>
              ) : data.minLength >= 0 && data.maxLength >= 0 ? (
                <div className="mt-1 text-sm text-gray-500 text-right mr-14">
                  {data.minLength}/{data.maxLength}
                </div>
              ) : null}
            </div>
          </>
        );

      case "radioButton":
        return (
          <>
            <div className="mt-3">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div>
                    <input
                      type="radio"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="details-data-style-common">
                      {getOptionLabel(option)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case "link":
        return (
          <>
            <div className=" flex mt-3">
              <div>
                <InsertLinkOutlinedIcon className="-mt-2 mr-2 origin-center rotate-90 opacity-60" />
              </div>
              <div
                aria-disabled={true}
                className="details-data-style-common opacity-50 w-72"
              >
                {getLabel(
                  "Attached Link will be shown here if any URL is added"
                )}
              </div>
            </div>
          </>
        );

      case "dropdown":
        return (
          <>
            <FormControl variant="standard">
              <Select
                variant="standard"
                disabled={data?.label === "Status" ? false : true}
                displayEmpty
                disableUnderline
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
                renderValue={(selected) => {
                  if (!selected) return data?.placeholder;
                  else return selected;
                }}
                name="selectCustomField"
                id="selectCustomField"
                className="lg:w-72 lg:h-8 sm:w-40 fontPoppinsRegularMd"
              >
                {data.options.map((option, index) => (
                  <option
                    className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 details-data-style-common lg:w-72 sm:w-40 fontPoppinsRegularMd"
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        );

      case "date":
        return (
          <>
            <div className="flex">
              <div className=" h-8 border-2 border-gray-300 w-72"></div>
              <div className=" bg-gray-200">
                <CalendarTodayOutlinedIcon className="mt-1" />
              </div>
            </div>
            {TemplateData?.templateType === "Predefined" ? (
              <div className="details-data-style-common opacity-50">
                [dd-mm-yyyy]
              </div>
            ) : (
              <div className="details-data-style-common opacity-50">
                [{data.dateFormat}]
              </div>
            )}
          </>
        );
      case "checkbox":
        return (
          <>
            <div className="lg:w-72">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div>
                    <input
                      type="checkbox"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="details-data-style-common">
                      {getOptionLabel(option)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case "attachment":
        return (
          <div className="rs-user-input-container">
            <TextField
              InputProps={{ disableUnderline: true }}
              placeholder="Search and select file name"
              disabled={true}
              className="lg:w-72"
            />
          </div>
        );
      case "textEditor":
        return (
          <>
            <RichTextEditor
              convertedContent=""
              readOnly={true}
              toolbarHidden={false}
              className="w-full"
            />
          </>
        );

      default:
        return (
          <>
            <TextField disabled={true} className="w-80" />
          </>
        );
    }
  };
  const testCaseDetailElements = (props) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="labelInputs">
            <h6 className=" custom_labels">{getLabel(props.data.label)}</h6>
          </div>
          <div className="flex">
            <div className="contentDataStyle_custom_fields">
              {testCaseDetailDescElements(props.data)}
            </div>
            <div
              className={
                props.data.type !== "textEditor"
                  ? " flex lg:mr-10"
                  : " flex mt-5"
              }
            >
              {!["Test Case Name", "Requirement Id", "Status"].includes(
                props.data.label
              ) ? (
                <></>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  const testCaseDetailSection = (obj) => {
    let tableRowData = obj.manualTestCase.testSteps.rows;
    let listData = obj.manualTestCase;

    return (
      <>
        <div className=" mt-3 mx-3 my-5 ">
          <div className=" responsiveTestDetails">
            <Accordion defaultExpanded={false} className="">
              <div className="flex flex-row ml-1.5">
                <div className="flex mt-3 w-full">
                  <div
                    style={{ width: "100%" }}
                    className="flex grid grid-cols-12"
                  >
                    <div className="col-span-2">
                      <div className="testcase_lables">OS</div>
                      <div className="testcsae_values mt-1 datatrimmer w-28">
                        {obj?.os}
                      </div>
                    </div>

                    <div className="col-span-2">
                      <div className="testcase_lables">OS Version</div>
                      <div className="testcsae_values mt-1 datatrimmer w-28">
                        {obj?.osVersion}
                      </div>
                    </div>

                    {obj?.deviceName !== undefined && obj.deviceName !== "" ? (
                      <>
                        <div className="col-span-2">
                          <div className="testcase_lables">Device Brand</div>
                          <div className="testcsae_values mt-1 datatrimmer w-28">
                            {obj?.deviceBrand}
                          </div>
                        </div>

                        <div className="col-span-2">
                          <div className="testcase_lables">Device Name</div>
                          <div className="testcsae_values mt-1 datatrimmer w-28">
                            {obj?.deviceName}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-span-2">
                          <div className="testcase_lables">Browser</div>
                          <div className="testcsae_values mt-1 datatrimmer w-28">
                            {projectType === "Others" ? "--" : obj?.browser}
                          </div>
                        </div>

                        <div className="col-span-2">
                          <div className="testcase_lables">Browser Version</div>
                          <div className="testcsae_values mt-1 datatrimmer w-28">
                            {projectType === "Others"
                              ? "--"
                              : obj?.browserVersion}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-span-2">
                      <div className="testcase_lables">Location</div>
                      <div className="testcsae_values mt-1 datatrimmer w-28">
                        {obj?.city}
                      </div>
                    </div>
                    {/* <div className="col-span-2">
                            <div className="testcase_lables">Created On</div>
                            <div className="testcsae_values mt-1 datatrimmer w-28">{obj?.createdOn}</div>
                          </div> */}
                    <div className="col-span-2">
                      <div className="testcase_lables">Status</div>
                      <div
                        className={`testcsae_values mt-1 ${
                          obj?.executionStatus.toUpperCase() === "PASS"
                            ? "text-green-700"
                            : obj?.executionStatus.toUpperCase() === "FAIL"
                            ? "text-red-700"
                            : "text-orange-500"
                        }`}
                      >
                        {obj?.executionStatus}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="h-auto backgroundColor">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="cursor-pointer" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  ></AccordionSummary>
                </div>
              </div>
              <div className="testcase_accordionDetails">
                <AccordionDetails className=" h-auto backgroundColor">
                  <div className="grid grid-cols-12">
                    {listData?.testCaseDetails?.slice(0, 7).map((data) =>
                      data?.type === "textEditor" ? (
                        <div className="col-span-11">
                          {testCaseDetailElements({ data })}
                        </div>
                      ) : (
                        <>
                          {data.label !== "Module/Path" && (
                            <div className="col-span-2">
                              {/* {testCaseDetailElements({ data })} */}
                              <div>
                                <div className="testcase_lables">
                                  {data.label}
                                </div>
                                <div
                                  className="testcsae_values mt-1 datatrimmer w-24"
                                  title={
                                    Array.isArray(data.value)
                                      ? data.value[0]
                                      : data.value
                                  }
                                >
                                  {data.label === "Test Case Name"
                                    ? obj.name
                                    : Array.isArray(data.value)
                                    ? data.value[0] !== undefined &&
                                      data.value[0] !== ""
                                      ? data.value[0]
                                      : "--"
                                    : data.value !== undefined &&
                                      data.value !== ""
                                    ? data.value
                                    : "--"}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )
                    )}
                    {/* <div className="col-span-2">
                        <div className="testcase_lables">Status</div>
                        <div
                          className={`testcsae_values mt-1 ${
                            obj?.executionStatus.toUpperCase() === "PASS"
                              ? "text-green-700"
                              : obj?.executionStatus.toUpperCase() === "FAIL"
                              ? "text-red-700"
                              : "text-orange-500"
                          }`}
                        >
                          {obj?.executionStatus}
                        </div>
                      </div> */}
                    <div className="col-span-2">
                      <div className="testcase_lables">Created On</div>
                      <div className="testcsae_values mt-1 datatrimmer w-28">
                        {obj?.createdOn}
                      </div>
                    </div>
                  </div>

                  <div
                    className="mt-3"
                    style={{
                      width: "100%",
                      overflow: "auto",
                      maxHeight: "267px",
                      height: "auto",
                      overflowY: "auto",
                    }}
                  >
                    <table className="testStepTable  w-full">
                      <thead
                        style={{
                          display: " table-header-group",
                          verticalAlign: "middle",
                          position: "sticky",
                          top: "-1px",
                          zIndex: "1",
                        }}
                        className="bg-gray-100"
                      >
                        <tr key={"tableHeader"}>
                          {listData.testSteps?.headers?.map((data, index) => {
                            return (
                              <>
                                <th
                                  className="p-2 testStepTable common-label-text text-blue-800 fontPoppinsMediumMd"
                                  key={data + index}
                                >
                                  {data}{" "}
                                </th>
                              </>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          height: "auto",
                          overflowY: "auto",
                          display: " table-row-group",
                          verticalAlign: "middle",
                        }}
                      >
                        {tableRowData?.map((rowData, index) => (
                          <tr
                            style={{
                              display: " table-row",
                              verticalAlign: "inherit",
                            }}
                            className={`h-6 testStepTable ${
                              (index + 1) % 2 === 0
                                ? "test-case-table-even-row"
                                : "test-case-table-odd-row"
                            }`}
                            key={index}
                          >
                            {TemplateData.testSteps?.headers?.map(
                              (cellname, colIndex) => {
                                return (
                                  <>
                                    <td
                                      className={`testStepTable fontPoppinsRegularSm text-left pl-2 table-cell-text focus:border-opacity-0 focus:outline-none ${
                                        cellname === "Status"
                                          ? "text-left " +
                                            (rowData[cellname].toUpperCase() ===
                                            "PASS"
                                              ? "text-green-700"
                                              : rowData[
                                                  cellname
                                                ].toUpperCase() === "FAIL"
                                              ? "text-red-700"
                                              : "text-orange-500")
                                          : ""
                                      }`}
                                      key={index + colIndex}
                                      contentEditable={false}
                                      suppressContentEditableWarning={true}
                                    >
                                      {Array.isArray(rowData[cellname])
                                        ? rowData[cellname].map((data) => (
                                            <>
                                              {data}

                                              <br />
                                            </>
                                          ))
                                        : rowData[cellname]}
                                    </td>
                                  </>
                                );
                              }
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </AccordionDetails>
              </div>
            </Accordion>
          </div>
        </div>
      </>
    );
  };

  function handlescroll(event) {
    console.log("event.target.scrollTop", event.target.scrollTop);
    console.log("event.target.clientHeight", event.target.clientHeight);
    console.log(" event.target.scrollHeight", event.target.scrollHeight);
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
      console.log("hello123");
      if (lastPage === false && callingAPI === false) {
        getAllExecutionResult(pageNo);
      }
    }
  }

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        // props.CloseDefectModal(false);
        // setOpenModal(false);
      }}
    >
      <div className="tcuser-details">
        <div className="grid grid-cols-12 flex header rounded-tl-xl">
          <div className="col-span-9 darkBlue pr-3 heading">
            TestCase Execution-{resultCount}
          </div>
          <div className="col-span-2">
          <div className="adminsearch">
            <div className="flex">
              <input
                type="search"
                className="searchfield"
                //  value={globalFilter || ""}
                autoFocus={false}
                onChange={(e) => {
                //   setGlobalFilter(e.target.value);
                //  handleSearch(e)
                }}
                placeholder="Search"
              />
               <div className="search_icon" onClick={()=>{console.log("kkiiki")}} >
              <Search />
            </div>
            </div>
           
          </div> 
          </div>
          <div className="col-span-1 flex justify-end mr-1.5">
            <button
              onClick={() => {
                props.CloseTesterModal(false);
              }}
              type="button"
              className="modal_clos_btn mr-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        {isLoading ? null : null}
        <div className="p-4 body" onScroll={handlescroll}>
          {isLoading ? (
            <CommonLoader />
          ) : (
            <>
              {TestcaseResulltData?.length > 0 ? (
                <div>
                  {TestcaseResulltData?.map((data, index) => (
                    <div className=" overflow-x-hidden">
                      <div className=" wrapperForExecution abc">
                        <div className="customAccordian">
                          <div className="flex">
                            <div className="testcase_exe_header">
                              {" "}
                              Execution Id - {data.executionId}
                            </div>
                          </div>
                          {testCaseDetailSection(data)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className={`noExecutionResult ${
                    props.callingAPI ? "hidden" : "block"
                  }`}
                >
                  <div>
                    <NoExecutionIcon />
                  </div>
                  <div className="mt-3">
                    <span className="noExeutionsMsg">
                      No Executions found !
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <>
          <div className="grid grid-cols-12 col-span-12 user-details_footer  h-12 pt-2">
            <div className="col-span-10"></div>
            <div className="grid grid-cols-12 col-span-2 flex justify-end">
              <div className="grid grid-cols-12 col-span-12 h-8 flex justify-end">
                <span
                  className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer"
                  onClick={() => {
                    props.CloseTesterModal(false);
                  }}
                >
                  <button>Close</button>
                </span>
              </div>
            </div>
          </div>
        </>
      </div>
    </Modal>
  );
}

export default TCResultDeatilsPopup;
