import React, { useState, useEffect, useContext, useRef } from "react";
import { useTable, useGlobalFilter } from "react-table";
import Search from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import { ReactComponent as AddIcon } from "../../../assets/plusIcon.svg";
import "../config.scss";
import AddTestingDeviceModal from "../../project/modals/add-testingDevice-modal";
import SetDefaultModal from "../set-default-modal";
import {
  getAllPlatformReq,
  setDefaultPlatforms,
  editPlatformName,
} from "../../../api/api-services";
import { ProgressContainerContext } from "../../common/inputs/ProgressContainer.js";
import { ReactComponent as NoPlatformIcon } from "../../../assets/noPlatformIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/editBlack.svg";
import { ReactComponent as UpdateIcon } from "../../../assets/updateIcon.svg";
import IconButton from "@mui/material/IconButton";
import PlatformModal from "../../project/modals/platform-modal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Tooltip } from "@mui/material";
import { TextField } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchField from "../../common/search-field";
import NoRelevantDataIcon from "../../../assets/reportsicon/no_reports_icon.png";

const PlatformDetails = (props) => {
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  let [platforms, setplatforms] = useState([]);
  const [defaultPlatform, setDefaultPlatform] = useState({});
  let [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false);
  const [openEditPlatformModal, setOpenEditPlatformModal] = useState(false);
  const [editPlatformData, setEditPlatformData] = useState({});
  const [defaultPlatFormEvent, setDefaultPlatformEvent] = useState({});
  let loggedinUser = JSON.parse(localStorage["user-privilege"]);
  let selectedproject = JSON.parse(localStorage["selected-project"]);
  let [closeSearchField, setCloseSearchField] = useState(false);
  let data = React.useMemo(() => platforms, [platforms, defaultPlatform]);
  let typeofProject=JSON.parse(localStorage["selected-project"]).project?.projectType?.others?.typeOfProject?JSON.parse(localStorage["selected-project"]).project?.projectType?.others?.typeOfProject:""

  const [editName, setEditName] = useState("");
  const [isEditName, setIsEditName] = useState(true);
  const [callingAPI,setCallingAPI]=useState(false)

  const [isEditingName, setIsEditingName] = useState(false);
  const [platFormId, setPlatFormId] = useState("");
  const [filterData, setFilterData] = useState([]);
  let specialChars = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;


  // if click outside of Searchbar also it will close
  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowSearch(false);
      }
    };
    document.addEventListener("mousedown", handeler);
    return () => {
      document.removeEventListener("mouseleave", handeler);
    };
  }, []);

  useEffect(() => {
    getAllPlatform();
  }, []);
  const getAllPlatform = async () => {
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    let emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    try {
      let response = await getAllPlatformReq(projectId, emailId);
      if (response?.data?.statusCode === 200) {
        setplatforms(response?.data?.platforms);
        setFilterData(response.data?.platforms);
      }
      resetProgress();
    } catch (error) {
      resetProgress();
      console.log("error");
    } finally {
      setLoading(false);
    }
  };
  const defaulTemplateRadioHandler = () => {
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    let emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
    let payload = defaultPlatFormEvent;     
    payload.isDefault= true;

    setDefaultPlatforms(projectId, emailId, payload)
      .then((results) => {
        setplatforms(results.data.platforms);
        if (results.data && results.data.statusCode === 200) {
          props.MyAlert.success(
            `${payload.platformName} has been successfully added as your default platform`
          );

          setWarningPopup(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error(
            "Update Default Platform Error",
            error.response.message
          );
        }
      });
  };
  const haveSameData = function (obj1, obj2) {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;
    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every(
        (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
      );
    }
    return false;
  };
  const editPlatFormData = async () => {
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    let emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
    let payload = editPlatformData;
    payload.platformName = editName;
    if(callingAPI===false){
      setCallingAPI(true)
      try {
        let response = await editPlatformName(projectId, emailId, payload);
        console.log(response, editPlatformData);
        if (editPlatformData.name === editName) {
          props.MyAlert.danger(`Platform name alraedy exist`);
          setIsEditingName(true);
          return;
        }
        
        if (response.status === 200) {
          setIsEditingName(false);
          props.MyAlert.success(`${payload.platformName} updated successFully`);
          getAllPlatform();
          // props.setplatforms(response.data.platforms);
        }
        //  else if (haveSameData(editPlatformData, payload) || isEditName) {
        //   props.MyAlert.info(
        //     `No changes were done to the ${payload.platformName} platform`
        //   );
  
        //   return;
        // }
          else {
          props.MyAlert.danger(`${response.response.data.errorMessage}`);
          setIsEditingName(true);
        }
      } catch (error) {
        console.error(error);
      }
      setCallingAPI(false)
    }

  
  };

  function CloseWarnignPopup(state) {
    setWarningPopup(state);
  }

  function onCloseAddPlatform(status) {
    setOpenAddDeviceModal(status);
  }
  function onCloseEditPlatform(status) {
    setOpenEditPlatformModal(status);
  }
  let editNameRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (
        editNameRef.current &&
        !editNameRef?.current?.contains(event.target)
      ) {
        setIsEditingName(false);
      }
    };
    document.addEventListener("mousedown", handeler);
    return () => {
      document.removeEventListener("mouseleave", handeler);
    };
  }, []);

  const handlePlatformNameEdit = (event) => {
    setEditName(event.target.value.replace(/\s+/gi, " "));
    console.log(event.target);
    platforms.map((val) => {
      if (val.platformName === event.target.value) {
        console.log(
          val.platformName === event.target.value,
          val.platformName,
          event.target.value
        );
        setIsEditName(false);
      }
    });
  };
  function handleSearch(e) {
    console.log(e);
    let searchValue = e;
    if (searchValue) {
      let tempSimilarArray = [];
      filterData.map((proVal, index) => {
        let name = proVal?.platformName;
        let os = proVal?.os;
        let osVersion = proVal?.osVersion;
        let browser = proVal?.browser;
        let browserVersion = proVal?.browserVersion;
        let deviceType = proVal?.deviceType;
        let deviceName = proVal?.deviceName;
        let deviceBrand = proVal?.deviceBrand;

        let similarExistName = name?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistOS = os?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistOsVersion = osVersion?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistBrowser = browser?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistBrowserVersion = browserVersion?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistDeviceType = deviceType?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistDeviceName = deviceName?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistDeviceBrand = deviceBrand?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );

        if (
          similarExistName ||
          similarExistOS ||
          similarExistOsVersion ||
          similarExistBrowser ||
          similarExistBrowserVersion ||
          similarExistDeviceType ||
          similarExistDeviceName ||
          similarExistDeviceBrand
        ) {
          tempSimilarArray.push(proVal);
          setplatforms(tempSimilarArray);
        } else {
          setplatforms(tempSimilarArray);
        }
      });
    } else {
      setplatforms(filterData);
    }
  }

  return (
   
    <div className="defects-list overflow-x-hidden">
       {console.log("platformValues",platforms)}
      {loggedinUser?.config?.platformDetails?.actions?.create ? (
        <div className="grid grid-cols-12 flex header content-between">
          <div className="col-span-9 heading pl-2">
            Platform Details - {platforms?.length}
          </div>

          <div
            className="grid grid-cols-12 col-span-2 flex justify-end"
            ref={menuRef}
          >
            <SearchField
              setGlobalFilter={handleSearch}
              closeField={closeSearchField}
              setCloseField={setCloseSearchField}
              page="platformDetails"
              onClose={() => {
                setplatforms(filterData);
              }}
              colSpan="col-span-11 ml-auto mr-6 mt-0.5"
            />
            <Tooltip
              title={
                !["APPROVED", "INPROGRESS"].includes(
                  selectedproject.projectStatus
                )
                  ? "Platform cannot be added"
                  : "Add Platform"
              }
            >
              <button
                disabled={
                  !["APPROVED", "INPROGRESS"].includes(
                    selectedproject.projectStatus
                  )
                }
                className={
                  !["APPROVED", "INPROGRESS"].includes(
                    selectedproject.projectStatus
                  )
                    ? "grid grid-cols-12 col-span-5 addTempBtn  opacity-40"
                    : "grid grid-cols-12 col-span-5 addTempBtn "
                }
                onClick={() => {
                  setOpenAddDeviceModal(true);
                  setCloseSearchField(true);
                }}
              >
                <div className="grid grid-cols-12 col-span-11 create_buttton cursor-pointer">
                  <span className="col-span-2 ">
                    <AddIcon className="addDefectIcon" />
                  </span>
                  <span className=" col-span-10  mt-2 ml-3">
                   Platform
                  </span>
                </div>
              </button>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-12 flex header content-between">
          <div className="col-span-10 heading pl-3">
            Platform Details - {platforms?.length}
          </div>
          <div className="grid grid-cols-12 col-span-2 flex justify-end">
            <SearchField
              setGlobalFilter={handleSearch}
              closeField={closeSearchField}
              setCloseField={setCloseSearchField}
              page="platformDetails"
              onClose={() => {
                setplatforms(filterData);
              }}
              colSpan="col-span-11 ml-auto mt-0.5"
            />
          </div>
        </div>
      )}
      <div className="px-2 mt-2 project_list_body_platform" ref={editNameRef}>
        {platforms.map((platformValues) => {
          return (
            <>
              {(selectedproject?.project?.projectType?.web !== null) ? (
                <>
                  <div
                    key={platformValues.os}
                    className="platFormContainer wrapperForContainer flex"
                  >
                    <div className="platFormNameDiv flex">
                      <input
                        type="radio"
                        className="cursor-pointer"
                        id={platformValues.isDefault}
                        name="licenses"
                        disabled={selectedproject?.projectStatus === "CLOSED" || selectedproject?.projectStatus === "COMPLETED"}
                        checked={platformValues.isDefault}
                        value={platformValues.isDefault}
                        onClick={(e) => {
                          platformValues.isDefault
                            ? setWarningPopup(false)
                            : setWarningPopup(true);
                          setDefaultPlatformEvent(platformValues);
                          console.log(e, defaultPlatform);
                        }}
                      />

                      {isEditingName && platFormId === platformValues.id ? (
                        <TextField
                          error={
                            !editName ||
                            editName.length > 25 ||
                            editName.length < 3 ||
                            specialChars.test(editName)
                          }
                          value={editName}
                          onChange={handlePlatformNameEdit}
                          variant="standard"
                          autoFocus
                          className="nameTextField"
                        />
                      ) : (
                        <p
                          className="platFormName"
                          onClick={() => {
                            console.log(platformValues);
                          }}
                        >
                          {platformValues.platformName}
                        </p>
                      )}

                      {isEditingName && platFormId === platformValues.id ? (
                        <>
                          {editName.length > 25 ||
                          editName.length < 3 ||
                          specialChars.test(editName) ? (
                            <Tooltip
                              title={
                                <div>
                                  {editName && editName.length > 25
                                    ? "Platform name should contain max 25 characters"
                                    : editName && editName.length < 3
                                    ? "Platform name should contain atleast 3 characters"
                                    : specialChars.test(editName)
                                    ? "Special characters not allowed"
                                    : !editName
                                    ? "Platform name required "
                                    : ""}
                                </div>
                              }
                            >
                              <InfoOutlinedIcon
                                style={{ color: "red", height: "15px" }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Update" placement="top">
                              <button className="editPlatformIcon">
                                <UpdateIcon
                                  onClick={() => {
                                    if (editName) {
                                      editPlatFormData();
                                    } else {
                                      props.MyAlert.danger(
                                        "Platform Name required"
                                      );
                                    }
                                  }}
                                />
                              </button>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <Tooltip
                          title={
                            (selectedproject.projectStatus === "CLOSED"|| selectedproject.projectStatus === "COMPLETED")
                              ? "cannot be edit"
                              : "Edit"
                          }
                          placement="top"
                        >
                          <button
                            title="Edit"
                            className={`editPlatformIcon ${
                              (selectedproject.projectStatus === "CLOSED"|| selectedproject.projectStatus === "COMPLETED")
                                ? "opacity-50"
                                : null
                            } `}
                          >
                            <EditIcon
                              onClick={() => {
                                if (
                                  selectedproject.projectStatus !== "CLOSED" && selectedproject.projectStatus !== "COMPLETED"
                                ) {
                                  setEditName(platformValues.platformName);
                                  setIsEditingName(true);
                                  setPlatFormId(platformValues?.id);
                                  setEditPlatformData(platformValues);
                                }
                              }}
                              width="13px"
                            />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                    <div className="platFormDetailsDiv">
                      <div className="grid grid-cols-10 flex  mt-4">
                        <div className="col-span-2">
                          <div className="label">OS</div>
                          <div className="value datatrimmer  w-20  cursor-default" title={platformValues.os}>{platformValues.os}</div>
                        </div>
                        <div className="col-span-2">
                          <div className="label">OS Version</div>
                          <div className="value datatrimmer  w-20  cursor-default" title={platformValues.osVersion}>
                            {platformValues.osVersion?platformValues.osVersion:"--"}
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="label">Browser</div>
                          <div className="value datatrimmer  w-20  cursor-default" title={platformValues.browser}>{platformValues.browser}</div>
                        </div>
                        <div className="col-span-2">
                          <div className="label">Browser Version</div>
                          <div className="value datatrimmer  w-20  cursor-default" title={platformValues.browserVersion}>
                            {platformValues.browserVersion}
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="label">Device Type</div>
                          <div className="value datatrimmer  w-20  cursor-default" title={platformValues.deviceType} >
                            {platformValues.deviceType}
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-10 flex  mt-4">
                        <div className="col-span-2">
                          <div className="label">City</div>
                          <div className="value datatrimmer  w-24  cursor-default" title={platformValues.city}>{platformValues.city}</div>
                        </div>
                        <div className="col-span-2">
                          <div className="label">State</div>
                          <div className="value datatrimmer  w-24  cursor-default" title={platformValues.state}>
                            {platformValues.state}
                          </div>
                        </div>
                        <div className="col-span-3">
                          <div className="label">Country</div>
                          <div className="value datatrimmer  w-24  cursor-default" title={platformValues.country}>{platformValues.country}</div>
                        </div>
                       
                      </div>
                      
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    key={platformValues.os}
                    className="platFormContainer wrapperForContainer flex"
                  >
                    <div className="platFormNameDiv flex">
                      <input
                        type="radio"
                        className="cursor-pointer"
                        id={platformValues.isDefault}
                        name="licenses"
                        checked={platformValues.isDefault}
                        value={platformValues.isDefault}
                        disabled={selectedproject?.projectStatus === "CLOSED"  || selectedproject?.projectStatus === "COMPLETED"}
                        onClick={(e) => {
                          platformValues.isDefault
                            ? setWarningPopup(false)
                            : setWarningPopup(true);
                          setDefaultPlatformEvent(platformValues);
                          console.log(e, defaultPlatform);
                        }}
                      />

                      {isEditingName && platFormId === platformValues.id ? (
                        <>
                          <TextField
                            error={
                              !editName ||
                              editName.length > 25 ||
                              editName.length < 3 ||
                              specialChars.test(editName)
                            }
                            value={editName}
                            onChange={handlePlatformNameEdit}
                            variant="standard"
                            autoFocus={editPlatformData.name === editName}
                            className={
                              platformValues.deviceType === "desktop"
                                ? "nameTextField"
                                : "nameTextFieldMobile"
                            }
                          />
                        </>
                      ) : (
                        <p
                          className="platFormName"
                          onClick={() => {
                            console.log(platformValues);
                          }}
                        >
                          {platformValues.platformName}
                        </p>
                      )}
                      <button className="editPlatformIcon">
                        {isEditingName && platFormId === platformValues.id ? (
                          <>
                            {editName.length > 25 ||
                            editName.length < 3 ||
                            specialChars.test(editName) ? (
                              <Tooltip
                                title={
                                  <div>
                                    {editName && editName.length > 25
                                      ? "Platform name should contain max 25 characters"
                                      : editName && editName.length < 3
                                      ? "Platform name should contain atleast 3 characters"
                                      : specialChars.test(editName)
                                      ? "Special characters not allowed"
                                      : !editName
                                      ? "Platform name required "
                                      : ""}
                                  </div>
                                }
                              >
                                <InfoOutlinedIcon
                                  style={{ color: "red", height: "15px" }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Update" placement="top">
                                <button className="editPlatformIcon">
                                  <UpdateIcon
                                    onClick={() => {
                                      if (editName) {
                                        editPlatFormData();
                                      } else {
                                        props.MyAlert.danger(
                                          "Platform Name required"
                                        );
                                      }
                                    }}
                                  />
                                </button>
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          <Tooltip
                            // title={
                            //   ["CLOSED","COMPLETED"].includes(selectedproject.projectStatus)
                            //     ? `Closed project can't be edited`
                            //     : "Edit"
                            // }
                            title={selectedproject.projectStatus==="CLOSED"?"Closed project can't be edited":selectedproject.projectStatus==="COMPLETED"?"Completed project can't be edited":"Edit"}
                            placement="top"
                          >
                            <button
                              disabled={["CLOSED","COMPLETED"].includes(
                                selectedproject.projectStatus
                              )}
                              onClick={() => {
                                setEditName(platformValues.platformName);
                                setIsEditingName(true);
                                setPlatFormId(platformValues?.id);
                                setEditPlatformData(platformValues);
                              }}
                            >
                              <EditIcon
                                className={
                                  ["CLOSED","COMPLETED"].includes(
                                    selectedproject.projectStatus
                                  )
                                    ? "opacity-50"
                                    : "opacity-100"
                                }
                                width="13px"
                              />
                            </button>
                          </Tooltip>
                        )}
                      </button>
                    </div>

                    <div className="platFormDetailsDiv">
                      {platformValues.deviceType === "desktop" ? (
                        <div className="platFormDetailsDiv">
                          <div className="grid grid-cols-10 flex  mt-4">
                            <div className="col-span-2">
                              <div className="label">OS</div>
                              <div className="value datatrimmer  w-20  cursor-default" title={platformValues.os}>{platformValues.os}</div>
                            </div>
                            <div className="col-span-2">
                              <div className="label">OS Version </div>
                              <div className="value datatrimmer  w-20  cursor-default" title={platformValues.osVersion}>
                                {platformValues.osVersion?platformValues.osVersion:"--"}
                              </div>
                            </div>
                           {
                            typeofProject===""? (
                              <>
                               <div className="col-span-2">
                              <div className="label">Browser</div>
                              <div className="value datatrimmer  w-20  cursor-default" title={platformValues.browser}>
                                {platformValues.browser}
                              </div>
                            </div>
                            <div className="col-span-2">
                              <div className="label">Browser Version</div>
                              <div className="value datatrimmer  w-20  cursor-default" title={platformValues.browserVersion}>
                                {platformValues.browserVersion}
                              </div>
                            </div>
                            <div className="col-span-2">
                              <div className="label">Device Type</div>
                              <div className="value datatrimmer  w-20  cursor-default" title={platformValues.deviceType}>
                                {platformValues.deviceType}
                              </div>
                            </div>
                              </>
                            ):(
                              <>
                              <div className="col-span-2">
                              <div className="label">Device Type</div>
                              <div className="value datatrimmer  w-20  cursor-default" title={platformValues.deviceType}>
                                {platformValues.deviceType}
                              </div>
                            </div>
                            <div className="col-span-2">
                          <div className="label">City</div>
                          <div className="value datatrimmer  w-24  cursor-default" title={platformValues.city}>{platformValues.city}</div>
                        </div>
                        <div className="col-span-2">
                          <div className="label">State</div>
                          <div className="value datatrimmer  w-24  cursor-default" title={platformValues.state}>
                            {platformValues.state}
                          </div>
                        </div>

                              </>
                            )
                           }
                           
                            
                          </div>
                          {
                            typeofProject===""?(
                              <div className="grid grid-cols-10 flex  mt-4">
                              <div className="col-span-2">
                                <div className="label">City</div>
                                <div className="value datatrimmer  w-24  cursor-default" title={platformValues.city}>{platformValues.city}</div>
                              </div>
                              <div className="col-span-2">
                                <div className="label">State</div>
                                <div className="value datatrimmer  w-24  cursor-default" title={platformValues.state}>
                                  {platformValues.state}
                                </div>
                              </div>
                              <div className="col-span-2">
                                <div className="label">Country</div>
                                <div className="value datatrimmer  w-24  cursor-default" title={platformValues.country}>{platformValues.country}</div>
                              </div>
                             
                            </div>
                            ):(
                              <div className="grid grid-cols-10 flex  mt-4">
                              <div className="col-span-2">
                                <div className="label">Country</div>
                                <div className="value datatrimmer  w-24  cursor-default" title={platformValues.country}>{platformValues.country}</div>
                              </div>
                             
                            </div>
                            )
                          }
                         
                        </div>
                      ) : (
                        <>
                          <div className="grid grid-cols-10 flex  ">
                            <div className="col-span-2">
                              <div className="label">OS</div>
                              <div className="value datatrimmer  w-20  cursor-default" title={platformValues.os}>{platformValues.os}</div>
                            </div>
                            <div className="col-span-2">
                              <div className="label">OS Version</div>
                              <div className="value datatrimmer  w-20  cursor-default" title={platformValues.osVersion}>
                                {platformValues.osVersion?platformValues.osVersion:""}
                              </div>
                            </div>
                            <div className="col-span-2">
                              <div className="label">Device Name</div>
                              <div className="value datatrimmer  w-20  cursor-default" title={platformValues.deviceName}>
                                {platformValues.deviceName}
                              </div>
                            </div>
                            <div className="col-span-2">
                              <div className="label">Device Brand</div>
                              <div className="value datatrimmer  w-20  cursor-default" title={platformValues.deviceBrand}>
                                {platformValues.deviceBrand}
                              </div>
                            </div>
                            <div className="col-span-2">
                              <div className="label">Device Type</div>
                              <div className="value datatrimmer  w-20  cursor-default" title={platformValues.deviceType}>
                                {platformValues.deviceType}
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-10 flex  mt-4">
                          
                        <div className="col-span-2">
                          <div className="label">City</div>
                          <div className="value datatrimmer  w-24  cursor-default" title={platformValues.city}>{platformValues.city}</div>
                        </div>
                        <div className="col-span-2">
                          <div className="label">State</div>
                          <div className="value datatrimmer  w-24  cursor-default" title={platformValues.state}>
                            {platformValues.state}
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="label">Country</div>
                          <div className="value datatrimmer  w-24  cursor-default" title={platformValues.country}>{platformValues.country}</div>
                        </div>
                        {["Native", "Hybrid"].includes(
                              selectedproject?.project?.projectType?.mobile
                                ?.appType
                            ) ? (
                              ""
                            ) : (
                              
                                // typeofProject === "" && (
                                  <>
                                  <div className="col-span-2">
                                    <div className="label">Browser</div>
                                    <div className="value datatrimmer  w-24  cursor-default" title={platformValues.browser}>
                                      {platformValues.browser===""?"--":platformValues.browser}
                                    </div>
                                  </div>
                                  <div className="col-span-2">
                                    <div className="label">Browser Version</div>
                                    <div className="value datatrimmer  w-24  cursor-default" title={platformValues.browserVersion} >
                                      {platformValues.browserVersion===""?"--":platformValues.browserVersion}
                                    </div>
                                  </div>
                                </>
                                // )
                              
                             
                            )}
                       
                       
                      </div>
                         
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          );
        })}
        {platforms.length === 0 && !loading && (
          <>
            {/* <div className="project_list_page_msg grid grid-cols-12">
              <div className="col-span-12 flex justify-center pt-56">
                <div className="noDefects">
                  <NoPlatformIcon />
                </div>
              </div>
            </div> */}
            <div className="no_relevant_data">
                    <img  alt="warning" src={NoRelevantDataIcon}/>
                    <div className="text_msg platform_msg">No Relevant Data Is Found !</div>
                  </div>
          </>
        )}
      </div>

      {openAddDeviceModal && (
        <AddTestingDeviceModal
          setShowSearch={setShowSearch}
          setOpenAddDeviceModal={setOpenAddDeviceModal}
          onCloseAddPlatform={onCloseAddPlatform}
          MyAlert={props?.MyAlert}
          label={"platformDetails"}
          getAllPlatform ={getAllPlatform }
        />
        
      )}
      {warningPopup && (
        <SetDefaultModal
          CloseModal={CloseWarnignPopup}
          message={"Are you sure you want to change the default template ?"}
          mode={"Change default template"}
          button={"Change"}
          defaulTemplateRadioHandler={defaulTemplateRadioHandler}
        />
      )}
      {openEditPlatformModal && (
        <PlatformModal
          onCloseEditPlatform={onCloseEditPlatform}
          editPlatformData={editPlatformData}
          MyAlert={props.MyAlert}
          getAllPlatform={getAllPlatform}
          setplatforms={setplatforms}
        />
      )}
    </div>
  );
};

export default PlatformDetails;
