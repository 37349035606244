import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import RichTextEditor from "../../config/rich-texteditor";
import { UpdateExecutiveRepot } from "../../../api/api-services";
import * as yup from "yup";
import { useFormik } from "formik";
import { SelectDropdown } from "./select-dropdown";

function EditExectiveReportsPopup(props) {
  console.log("executive report", props.projectDetails?.project?.projectName);
  console.log("block", props?.projectDetails?.certificateValidations);
  const [openModal, setOpenModal] = useState(true);
  const tempDetails = JSON.parse(localStorage["selected-project"]).id;
  
  let [options, setOptions] = useState([
    { label: "All Testers", value: "All_Testers" },
    { label: "Only Platforms", value: "Testers_Platforms" },
    { label: "Only Defects", value: "Testers_Defects" },
    { label: "Only Feedback", value: "Testers_Feedback" },
    { label: "Platform with Feedback", value: "Testers_Platform_Feedback" },
    { label: "Defects with Feedback", value: "Testers_Feedback_Defects" },
    { label: "Minimum Three Defects with Feedback", value: "Testers_Feedback_Defects_Three" },
    { label: "Minimum Five Defects with Feedback", value: "Testers_Feedback_Defects_Five" },
    { label: "Minimum Three Defects", value: "Testers_Defects_Three" },
    { label: "Minimum Five Defects", value: "Testers_Defects_Five" },
  ]);
  let [selectedvalue,setSelectedValue]=useState([]);
  useEffect(()=>{
    switch(props?.projectDetails?.certificateValidations){
      case  "All_Testers" :
        setSelectedValue([{ label: "All Testers", value: "All_Testers" }])
        break;
        case "Testers_Platforms" :
          setSelectedValue([{ label: "Only Platforms", value: "Testers_Platforms"}])
          break;
          case "Testers_Defects" :
          setSelectedValue([ { label: "Only Defects", value: "Testers_Defects" }])
          break;case "Testers_Feedback" :
          setSelectedValue([{ label: "Only Feedback", value: "Testers_Feedback" }])
          break;
          case "Testers_Platform_Feedback" :
          setSelectedValue([ { label: "Platform with Feedback", value: "Testers_Platform_Feedback" }])
          break;
          case "Testers_Feedback_Defects" :
          setSelectedValue([ { label: "Defects with Feedback", value: "Testers_Feedback_Defects" }])
          break;
          case "Testers_Feedback_Defects_Three" :
          setSelectedValue([{ label: "Minimum Three Defects with Feedback", value: "Testers_Feedback_Defects_Three" }])
          break;
          case "Testers_Feedback_Defects_Five" :
          setSelectedValue([{ label: "Minimum Five Defects with Feedback", value: "Testers_Feedback_Defects_Five" }])
          break;
          case "Testers_Defects_Three" :
          setSelectedValue([ { label: "Minimum Three Defects", value: "Testers_Defects_Three" }])
          break;
          case "Testers_Defects_Five" :
          setSelectedValue([{ label: "Minimum Five Defects", value: "Testers_Defects_Five" }])
          break;

        default:
          break;

    }

  },[props])

  let initialValues = {
    projectName: props.projectDetails?.project?.projectName,
    defectExecutiveReport: props?.projectDetails?.defectExecutiveReport,
    certificateValidations:props?.projectDetails?.certificateValidations
  };


  const validationSchema = yup.object({
    defectExecutiveReport: yup
      .string()
      .required("Executive report is required"),
  });
  let onSubmit = (values) => {
    let projectObj = {
      id: JSON.parse(localStorage["selected-project"]).id,
      defectExecutiveReport: values.defectExecutiveReport,
      certificateValidations:selectedvalue[0]?.value
    };

    const formData = new FormData();
    formData.append("project", JSON.stringify(projectObj));
    formData.append(
      "createdBy",
      JSON.parse(localStorage.getItem("fire-crowd-user")).userName
    );
    if (
      values.defectExecutiveReport ===
      props?.projectDetails?.defectExecutiveReport && selectedvalue[0]?.value===props?.projectDetails?.certificateValidations
    ) {
      props.MyAlert.info("No changes were done");
    } else {
      UpdateExecutiveRepot(formData, "CUSTOMER").then((response) => {
        console.log("reponse", response);
        if (response.data.status === 200) {
          props.MyAlert.success("ExcutiveRepot Updated Successfully");
          props.getProjectDeatails(tempDetails);
        }
      });
    }

    props.closemodal(false);
  };
  const formikDetails = useFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema,
  });

  const customStylesPage = {
    overlay: {
      background:"rgba(90, 90, 90,0.5)", 
      
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "80%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  function handleCertificate(data){
    setSelectedValue([
      { 
        label: data[0].label,
        value: data[0].value,
      },
      
    ])
  }

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px">
      <div className="tester_deatils">
        <div className="grid grid-cols-12 flex header">
          <span className="col-span-11 heading pl-2">
            Edit Executive Report
          </span>
          <div className="col-span-1 flex justify-end mr-1">
             <button
          onClick={() => {
            
            props.closemodal(false);
          }}
          type="button"
          className="modal_clos_btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
            
          </div>
        </div>
        <form onSubmit={formikDetails.handleSubmit}>
          <div className="pt-2 body px-5">
            <div className="mb-4">
              <div className="mb-2">
                <span className="create-labels">Name</span>
              </div>
              <div className="">
                <TextField
                  disabled={true}
                  className="w-60"
                  value={formikDetails.values.projectName}
                  size="small"
                  fullWidth
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="mb-2">
                <span className="text-red-600 mr-2">*</span>
                <span className="create-labels">Certificate Validation</span>
              </div>
              <div className="">
              <SelectDropdown
                        data={options}
                        selectedData={selectedvalue}
                        onChange={handleCertificate}
                        searchable={false}
                      />
                  
                 
                
              </div>
              <div>
                {formikDetails.errors.defectExecutiveReport &&
                  formikDetails.touched.defectExecutiveReport && (
                    <p className="MuiFormHelperText-root Mui-error mt-1 defect-error ml-2 mt-2">
                      {formikDetails.errors.defectExecutiveReport}
                    </p>
                  )}
              </div>
            </div>
            <div className="">
              <div className="mb-2">
              <span className="text-red-600 mr-2">*</span>
                <span className="create-labels">Executive Report</span>
              </div>
              <div className="">
                <RichTextEditor
                  readOnly={false}
                  toolbarHidden={false}
                  convertedContent={formikDetails.values.defectExecutiveReport}
                  setConvertedContent={(content) => {
                    if (content) {
                      const validateContent = JSON.parse(content);

                      if (
                        validateContent?.blocks?.length === 1 &&
                        validateContent?.blocks[0]?.text === ""
                      ) {
                        formikDetails.setFieldValue(
                          "defectExecutiveReport",
                          ""
                        );
                      } else {
                        formikDetails.setFieldValue(
                          "defectExecutiveReport",
                          content
                        );
                      }
                    } else {
                      formikDetails.setFieldValue("defectExecutiveReport", "");
                    }
                  }}
                />
              </div>
              <div>
                {formikDetails.errors.defectExecutiveReport &&
                  formikDetails.touched.defectExecutiveReport && (
                    <p className="MuiFormHelperText-root Mui-error mt-1 defect-error ml-2 mt-2">
                      {formikDetails.errors.defectExecutiveReport}
                    </p>
                  )}
              </div>
            </div>
          </div>

          <>
            <div className="py-2 px-3 flex justify-end gap-4 user-create-button z-50">
              <button
                onClick={() => {
                  props.closemodal(false);
                }}
                type="button"
                className="w-24 gray-btn ml-3 button"
              >
                Cancel
              </button>
              <button type="submit" className="w-24 primary-btn button">
                Update
              </button>
            </div>
          </>
        </form>
      </div>
    </Modal>
  );
}
export default  EditExectiveReportsPopup;
