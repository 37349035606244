export const ENVIRONMENT_URLS = {
  LOCAL_HOST: "localhost",
  DEV_URL: "http://dev.testyantra.com",
  TEST_URL: "http://test-firecrowd.fireflink.com",
  PRE_PROD_URL: "https://preprodcrowd.testyantra.com",
  PRODUCTION_URL: "https://crowd.testyantra.com",
  DEV1_URL:"https://betafirecrowd.fireflink.com"
};

export default ENVIRONMENT_URLS;
