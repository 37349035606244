import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { TextareaAutosize, TextField } from "@mui/material";
import { Label } from "../../common/inputs/myInput";
import { getOptionLabel, getFeedbackQuestions } from "../common-functions";
import Rating from "@mui/material/Rating";
import { ReactComponent as DeleteIcon } from "../../../assets/deleteBlack.svg";
import { ReactComponent as EditIcon } from "../../../assets/editBlack.svg";
import IconButton from "@mui/material/IconButton";
import DefectFieldForm from "../defect-field-forms";
import FeedbackFieldForm from "./feedback-fieldforms";
import {updateFeedbackTemplateReq} from "../../../api/api-services"


function EditFeedbackTemplate(props){
  console.log("props",props)
    const [openModal, setOpenModal] = useState(true);
    const [TemplateData, setTemplateData] = useState(
        JSON.parse(JSON.stringify(props?.data?.details))
      );
      const [addField,setAddField]=useState(false);
      const [editFieldValue, setEditFieldValue] = useState({});
      const [showErrorMsg, setShowErrorMsg] = useState("");
      const [templateName, setTemplateName] = useState(props?.data?.details?.templateName);
    const customStylesPage = {
        overlay: {
          background: "rgba(90, 90, 90,0.5)",
        },
        content: {
          position: "fixed",
          top: "50%",
          left: "82.5%",
          transform: "translate(-50%, -50%)",
          width: "35%",
          height: "100%",
          background: "#fbfcfd 0% 0% no-repeat padding-box",
          boxShadow: "0px 0px 11px #000000a1",
          opacity: "1",
          outline: "none",
          borderTopLeftRadius: "15px",
          borderBottomLeftRadius: "15px",
        },
      };
      const onEditFieldHandler = (e, data) => {
        setEditFieldValue(data)
        setAddField(true);
        
    }
    const removeTestDetailsData = (value) => {
        let tempTemplateData = TemplateData
        if (value) {
            let index = tempTemplateData.templateDetails.findIndex(x => (x.type === value.type && x.label === value.label));
            tempTemplateData.templateDetails.forEach((value, i) => {
                if (i > index) {
                    value.order = (parseInt(value.order) - 1) + ''
                }
            })
            index !== -1 && tempTemplateData.templateDetails.splice(index, 1);
            setTemplateData({ ...tempTemplateData })
            props.MyAlert.success(`${value.label} field removed successfully`)
        }
    }
    const testCaseDetailDescElements = (data) => {
        switch (data.type) {
          case "text":
            return (
              <>
                <div className="">
                  <TextareaAutosize
                    disabled={true}
                    title={data?.placeholder?.length > 30 ? data?.placeholder : null}
                    id="description"
                    name="description"
                    style={{ height: "60px" }}
                    className="w-full border-2 rounded border-gray-300 pl-2"
                    placeholder={
                      data?.placeholder?.length > 30
                        ? data?.placeholder?.substr(0, 30) + "..."
                        : data?.placeholder
                    }
                     value={data.defaultValue}
                  />
                </div>
              </>
            );
            case "ratings":
                return (
                  <>
                    <div className="">
                      <div className="grid grid-cols-2 gap-1">
                        <Rating name="no-value" value={null} readOnly size="large" />
                      </div>
                    </div>
                  </>
                );
    
          case "radioButton":
            return (
              <>
                <div className="mt-3">
                  <div className="grid grid-cols-2 gap-1">
                    {data.options.map((option, index) => (
                      <div className="flex">
                        <input
                          type="radio"
                          className="mr-2"
                          name={`option${index}`}
                          id={`option${index}`}
                          value={option}
                          disabled={true}
                        />
                        <label className="defect-template-btn-label datatrimmer">{getOptionLabel(option)}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            );
          default:
            return ( 
              <>
                <TextField disabled={true} className="w-60" />
              </>
            );
        }
      };
    
      const testCaseDetailElements = (props) => {
        return (
          <>
            <div className="flex flex-col justify-center">
              <div className="flex">
                <h6 className="defect-template-modal-values w-11/12 mb-2">
                  {props.data.mandatory === "yes" ? (
                    <span className="text-red-400 mr-1">&#42;</span>
                  ) : null}
              
                  {getFeedbackQuestions(props.data.label)}
                </h6>
                <div className={props.data.type !== "textEditor" ? "flex" : "flex"}>
                <>
                          
                          <div className="editIcon" title="Edit">
                           
                              <IconButton
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#FAF9F6",
                                    boxShadow:
                                      " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                                  },
                                }}
                              >
                                <button
                                  onClick={(e) => {
                                    onEditFieldHandler(e, props.data);                            
                                  }}
                                >
                                  <EditIcon height="15px" width="15px" />
                                </button>
                              </IconButton>
                         
                          </div>
     
                          <div className="editIcon" title="Delete">
                           
                              <IconButton
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#FAF9F6",
                                    boxShadow:
                                      " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                                  },
                                }}
                              >
                                <button
                                  onClick={() => removeTestDetailsData(props?.data)}
                                >
                              
                                  <DeleteIcon height="23px" width="20px" />
                                </button>
                              </IconButton>
                            
                          </div>
     
                          
                        </>
                 
                </div>
              </div>
              <div className="">
                <div className="">{testCaseDetailDescElements(props.data)}</div>
              </div>
            </div>
          </>
        );
      };

      const feedbackDetailSection = () => {
        return (
          <>
            <div className="">
              <div className="">
                {TemplateData?.templateDetails?.map((data) => (
                  <div className="my-5">
                    {testCaseDetailElements({ data })}
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      };
      const addNameField = (e) => {
        setShowErrorMsg("");
        setTemplateName(e.target.value);
        TemplateData.templateName = templateName;
      };
      async function updateTemplate(){
        console.log("TemplateData",TemplateData)
        let payload={
          id:props?.data?.details?.id,
         projectId:props?.data?.details?.projectId,
         templateName: props?.data?.details?.templateName,
         templateDetails:TemplateData.templateDetails
        }
        if(TemplateData.templateDetails.length===0){
          props.MyAlert.info(`Minimum one question is mandatory`);
          return
        }else{
          if (JSON.stringify(props?.data?.details?.templateDetails)===JSON.stringify(TemplateData?.templateDetails          )) {
            props.MyAlert.info(`No Changes were done to the ${TemplateData.templateName} template`);
            return
        }else{
          try{
            let response =await updateFeedbackTemplateReq(payload);
            if(response?.data && response?.data==="template edited successfully"){
              props.MyAlert.success(`Template edited successfully`);
              props.closeModule(false)
              props.getAllFeedbackTemplate()
            }
           }catch(error){
            console.log("error")
           }
          }

        }
       
      }
      
      const addFormField = (value) => {
        if (Object.keys(editFieldValue).length !== 0) {
          let tempTemplateData = TemplateData;
          const index = tempTemplateData.templateDetails.findIndex(
            (x) => x.order === editFieldValue.order
          );
          const indexD = tempTemplateData.templateDetails.findIndex(
            (x) => x.label.toLowerCase() === value.label.toLowerCase()
          );
          if (indexD === -1 || indexD === index) {
            value.order = editFieldValue?.order;
            tempTemplateData.templateDetails[index] = value;
            setTemplateData(tempTemplateData);
            props.MyAlert.success(`${value.label} field updated successfully`);
            setEditFieldValue({});
          } else {
            props.MyAlert.info(`Form field with ${value.label} label already exists`);
          }
        } else {
          value.order = TemplateData.templateDetails.length + 1 + "";
          let tempTemplateData = TemplateData;
          const index = tempTemplateData.templateDetails.findIndex(
            (x) => x?.label?.toLowerCase() === value?.label?.toLowerCase()
          );
          console.log(index);
          if (index === -1 ) {
            tempTemplateData?.templateDetails?.push(value);
            props.MyAlert.success(`${value?.label} field added successfully`);
            
          } else {
            
            props.MyAlert.info(`Form field with ${value?.label} label already exists`);
          }
          setTemplateData(tempTemplateData);
        }
      };
    
    return(
   
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
      <div className="flex justify-between rounded-tl-xl defect-template-modal-heading h-10 p-2">
        <div>Edit Feedback Details Template</div>
        <div className="ml-28">
          <button
           disabled={editFieldValue.type}
            className={editFieldValue.type ? "filed_btn opacity-50" : "filed_btn" }
            onClick={() => {
              setAddField(true);
              setEditFieldValue({});
            }}
          >
            + Fields
          </button>
        </div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModule(false);
          }}
          type="button"
          className="modal-close-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
         </div>
      <div>
        {!addField ? (
          <>
            <div
              className="main-content-defect overflow-y-scroll "
              id="journal-scroll"
            >
             <div className="pr-4">
                <div className="px-2">
                  <div>
                    {/* start   */}
                    <div className="my-5 pb-7">
                      <div className="mb-4">
                        <Label
                          label={
                      <>
                        <span className="text-red-400 mr-1">&#42;</span>{" "}
                        <span className="defect-template-modal-values">Template Name</span>
                      </>
                    }
                  />
                  <TextField
                    name="templateName"
                    id="templateName"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="off"
                    onBlur={addNameField}
                    onInput={addNameField}
                    fullWidth
                    disabled
                    value={TemplateData?.templateName}
                    // value={templateName.replace(/ +(?= )/g,'')}
                    className=" w-60 "
                    type="text"
                    
                  />
                  {/* {showErrorMsg && (
                    <div className="errorMessage">{showErrorMsg}</div>
                  )} */}
                </div>
                <div className="">
                  <span className="popins-semi-bold text-blue-600">
                    Questions
                  </span>
                </div>
                <div className="">{feedbackDetailSection()}</div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="py-2 px-3 flex justify-end gap-4 user-create-button z-50">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModule(false);
            }}
            type="button"
            className="w-24 gray-btn ml-3 button"
          >
            Cancel
          </button>
          <button
            className="w-24 primary-btn button"
            onClick={() => {
              updateTemplate();
            }}
          >
            Update
          </button>
        </div>
  </>
        ) : (
          <>
            
            <FeedbackFieldForm
            addFormField={addFormField}
            setAddField={setAddField}
            editFieldValue={editFieldValue}
            setOpenModal={setOpenModal}
            MyAlert={props.MyAlert}
            setEditFieldValue={setEditFieldValue}
            />
          </>
        )}
      </div>
    </Modal>
    )
}
export default EditFeedbackTemplate;