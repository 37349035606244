import React, { useState, useEffect } from "react";
import "./Dashboard.scss";
import DashboardProjectTable from "./Dashboard-project-table";
import DashboardOSTable from "./Dashboard-OS-table";
import DashboardProjectType from "./Dashboard-project-type";
import { getDashboardDetails } from "../../api/api-services";
import { ReactComponent as  ProjectIcon} from "../../assets/Dashboard-icon/projectIcon.svg";
import { ReactComponent as  OSIcon} from "../../assets/Dashboard-icon/OSIcon.svg";
import { ReactComponent as  LocationIcon} from "../../assets/Dashboard-icon/LocationIcon.svg";
import { ReactComponent as  DeviceIcon} from "../../assets/Dashboard-icon/DeviceIcon.svg";
import { ReactComponent as  BrowserIcon} from "../../assets/Dashboard-icon/BrowserIcon.svg";

function DashboardReports(props) {
  const [selectedCard, setSelectedCard] = useState("project");
  const [tableData, settableData] = useState([]);
  const [DashboardData, setDashboardData] = useState({});
  let [tableHeder,setTableHeader]=useState("Project Details")
  useEffect(() => {
    getAllDashboardData();
  }, []);

  const getAllDashboardData = () => {
    getDashboardDetails()
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          setDashboardData(response?.data);
          settableData(response?.data?.projectDetails);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getselectedcard = (label) => {
    switch (label) {
      case "project":
        setSelectedCard("project");
        setTableHeader("Project Details")
        settableData(DashboardData?.projectDetails);
        
        break;
      case "browser":
        setSelectedCard("browser");
        setTableHeader("Total Browser Details")
        settableData(DashboardData?.browserDetails
          );
        break;
      case "OS":
        setSelectedCard("OS");
        setTableHeader("Total OS Details")
        settableData(DashboardData?.osDetails);
        break;
      case "device":
        setSelectedCard("device");
        setTableHeader("Device Details")
        settableData(DashboardData?.deviceDetails);
        break;
      case "location":
        setSelectedCard("location");
        setTableHeader("Location Details")
        settableData(DashboardData?.locationDetails);
        break;
      default:
        break;
    }
  };
  return (
    <div className="Dashboard-container">
      <div className="Dashboard-header grid grid-cols-12">
        <div className="col-span-11 pt-2 pl-4">Overall Reports</div>
      </div>
      <div className="Dashboard-body pl-4 pr-4">
        <div className="grid grid-cols-10 gap-3 pt-4">
          <div
            className={`col-span-2 grid grid-cols-12 ${
              selectedCard === "project"
                ? "selected-dashboard-card project"
                : "dashbord-card project"
            }`}
            onClick={() => {
              getselectedcard("project");
            }}
          >
           
            <div className="col-span-4 dashbord-card-icons">
              <div className="Icons">
              <ProjectIcon/>
              </div>
           
            </div>
          
            <div className="col-span-8 grid grid-cols-12 dashbord-card-label">
              <div className="col-span-12 label-text">Total Projects</div>
              <div className="col-span-11 label-count">
                <div className="datatrimmer" title={DashboardData?.projects}>
                  {DashboardData?.projects}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`col-span-2 grid grid-cols-12 ${
              selectedCard === "device"
                ? "selected-dashboard-card device"
                : "dashbord-card device"
            }`}
            onClick={() => {
              getselectedcard("device");
            }}
          >
            
             <div className="col-span-4 dashbord-card-icons">
              <div className="Icons">
              <DeviceIcon />
              </div>
           
            </div>
          
            <div className="col-span-8 grid grid-cols-12 dashbord-card-label">
              <div className="col-span-12 label-text">Total Brands</div>
              <div className="col-span-11 label-count">
                <div
                  className="datatrimmer"
                  title={DashboardData?.devicesCount}
                >
                  {DashboardData?.devicesCount}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`col-span-2 grid grid-cols-12 ${
              selectedCard === "OS"
                ? "selected-dashboard-card OS"
                : "dashbord-card OS"
            }`}
            onClick={() => {
              getselectedcard("OS");
            }}
          >
         
             <div className="col-span-4 dashbord-card-icons">
              <div className="Icons">
              <OSIcon />
              </div>
           
            </div>
           
            <div className="col-span-8 grid grid-cols-12 dashbord-card-label">
              <div className="col-span-12 label-text">Total OS</div>
              <div className="col-span-11 label-count">
                <div className="datatrimmer" title={DashboardData?.osCount}>
                  {DashboardData?.osCount}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`col-span-2 grid grid-cols-12 ${
              selectedCard === "browser"
                ? "selected-dashboard-card browser"
                : "dashbord-card browser"
            }`}
            onClick={() => {
              getselectedcard("browser");
            }}
          >
            
            <div className="col-span-4 dashbord-card-icons">
              <div className="Icons">
              <BrowserIcon />
              </div>
           
            </div>
          
            <div className="col-span-8 grid grid-cols-12 dashbord-card-label">
              <div className="col-span-12 label-text">Total Browser</div>
              <div className="col-span-11 label-count">
                <div
                  className="datatrimmer"
                  title={DashboardData?.browsersCount}
                >
                  {DashboardData?.browsersCount}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`col-span-2 grid grid-cols-12 ${
              selectedCard === "location"
                ? "selected-dashboard-card location"
                : "dashbord-card location"
            }`}
            onClick={() => {
              getselectedcard("location");
            }}
          >
         
             <div className="col-span-4 dashbord-card-icons">
              <div className="Icons">
              <LocationIcon />
              </div>
           
            </div>
          
            <div className="col-span-8 grid grid-cols-12 dashbord-card-label">
              <div className="col-span-12 label-text">Locations</div>
              <div className="col-span-11 label-count">
                <div
                  className="datatrimmer"
                  title={DashboardData?.locationCount}
                >
                  {DashboardData?.locationCount}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-4 Dashboard-tabel-header" >{tableHeder}</div> */}
        <div className="">
          {selectedCard === "project" || selectedCard === "location" ? (
            <DashboardProjectTable label={selectedCard} data={tableData} MyAlert={props?.MyAlert} header={tableHeder}/>
          ) : (
            <DashboardOSTable label={selectedCard} data={tableData} header={tableHeder}/>
          )}
        </div>
        <div>
          <DashboardProjectType />
        </div>
      </div>
    </div>
  );
}
export default DashboardReports;
