import React,{useState} from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from '@mui/material/Checkbox';


function NDAFormData(){
    let myRole = JSON.parse(localStorage.getItem("user-privilege")).role;
    let [checkCondition,setCheckCondition]=useState(false)
    return(
        <div>
            {
               myRole==="ACCOUNT_MANAGER" ?(
                <div>
                <TextareaAutosize  value="helllo" readOnly={true} style={{ height: "67vh", resize: "none", overflow: "auto" ,marginTop:"12px",outline:"none"}}  className="NDAform-description" />
            </div>
               ):(  <div>
                <div>
                    <TextareaAutosize  value="hihihi" readOnly={true} style={{ height: "60vh", resize: "none", overflow: "auto" ,marginTop:"12px"}}  className="editNDAform-description" autoFocus/>
                </div>
                <div className="grid grid-cols-12 mt-2">
                    <div className="col-span-11 flex">
                    <input
                type="checkbox"
                checked={checkCondition} 
                name="I agree with all the terms and conditions."
                value="I agree with all the terms and conditions."
                onChange={(e) => {
                    console.log("e.target.value",e)
                    setCheckCondition(e.target.checked)
                  
                }}
              />
               <div className=" checkbox-label">I agree with all the terms and conditions.</div>
                    </div>
                    <div className={`col-span-1 editNDAform-btn save_btn ml-2 ${checkCondition?"opacity-100":"opacity-50"}`} >
                        <button disabled={!checkCondition} onClick={()=>{console.log("hello")}}>Submit</button>
                    </div>
                   
    
                </div>
            </div>)
            }
           
        </div>
    )
}
export default NDAFormData