import React, { props } from "react";
import Chart from "react-apexcharts";

function SegmentChart(props) {
  // let defectsDonutChartCustomTooltip=({ series, seriesIndex, w })=>{
  //   const { labels } = w.globals;
  //   let seriesColor=  w.globals?.colors
  //   return labels?.[seriesIndex] !== "nodata"
  //     ? `
  //     <div class="defects-donut-chart-custom-tooltip" style="background: ${seriesColor?.[seriesIndex]}">
  //       ${labels?.[seriesIndex]} - ${series?.[seriesIndex]}
  //     </div>
  //   `
  //     : null;
  // }
  return (
    <Chart
      type="donut"
      width={430}
      height={300}
      series={props?.series}
      options={{
        chart: {
          type: "donut",
          // width: 200,
          events: {
            dataPointSelection: function (event, chartContext, config) {
              props.selectedChartlabelData(props.value, [
                props.labels[config.dataPointIndex],
              ]);
            },
          },
        },
        labels: props?.labels,
        colors: props?.color,
        theme: {
          monochrome: {
            enabled: false,
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: "80%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: "#6F7075",
                  offsetY: -10,
                  label: "Total Defects",
                  formatter: function (val) {
                    // return <div>{val}</div>
                    if (props.value === "module") {
                      if (val === "Total Defects") {
                        return val;
                      } else {
                        return `${
                          val.split(/[/,:]/)[val.split(/[/,:]/).length - 2]
                        }`;
                      }
                    } else {
                      return val;
                    }
                  },
                },

                total: {
                  // showAlways: true,
                  show: true,
                  fontSize: "12px",
                  color: "#6F7075",
                  offsetY: 30,
                  fontFamily: "Poppins-Regular",
                  label: "Total Defects",
                  formatter: function (w) {
                    if (props.value === "severity") {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return JSON.parse(localStorage["severity-defect"]);
                      }, JSON.parse(localStorage["severity-defect"]));
                    } else if (props.value === "priority") {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return JSON.parse(localStorage["priority-defect"]);
                      }, JSON.parse(localStorage["priority-defect"]));
                    } else if (props.value === "module") {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return JSON.parse(localStorage["module-defect"]);
                      }, JSON.parse(localStorage["module-defect"]));
                    } else {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return JSON.parse(localStorage["location-defect"]);
                      }, JSON.parse(localStorage["location-defect"]));
                    }
                  },
                },
                value: {
                  show: true,
                  color: "#434DB8",
                  fontSize: "14px",
                  offsetY: -5,
                  fontFamily: "Poppins-SemiBold",
                  fontWeight: "10",
                  margin: {
                    top: 20,
                    bottom: 10,
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            if (props.value !== "module") {
              return `<div style="background: ${w.globals.colors[seriesIndex]}" class="bar-chart-custom-tooltip"> 
              <span> ${w.globals.labels[seriesIndex]} : ${series[seriesIndex]}   </span> 
              </div>`;
            } else {
              let temarray = w.globals.labels[seriesIndex].split(":")[0].split("/").reverse();
              let tempindex = temarray.indexOf("Root");
              let value = temarray.slice(0, tempindex).reverse().join("/");
              return `<div style="background: ${w.globals.colors[seriesIndex]}" class="bar-chart-custom-tooltip"> 
              <span> ${value} : ${series[seriesIndex]}</span> 
              </div>`;
            }
          },
        },

        legend: {
          width: 200,
          position: "right",
          fontFamily: "Poppins-Regular",
          fontSize: "12px",
          onItemClick: {
            toggleDataSeries: false,
          },
          formatter: function (val, opts) {
            // return val + " - " + opts.w.globals.series[opts.seriesIndex];
            if (props.value === "module") {
              return `${
                val.split(/[/,:]/)[val.split(/[/,:]/).length - 2] +
                " - " +
                opts.w.globals.series[opts.seriesIndex]
              }`;
            } else {
              return val + " - " + opts.w.globals.series[opts.seriesIndex];
            }
          },
        },
      }}
    />
  );
}

export default SegmentChart;
