import React from "react";
import Modal from "react-modal";
import { useState } from "react";
import { ReactComponent as Failure } from "../../assets/failure_icon.svg";
function ErrorPopup(props) {
  const [openModal, setOpenModal] = useState(true);
  const customStylesPage = {
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "30%",
      height: "30%",
      background: "#fbfcfd 0% 0% no-repeat",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
    },
  };
  
  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px">
      <div className="grid modal-header place-items-center">
        <div>
          <Failure/>
        </div>
        <div className="modal-header-text">
          <span className="modal-header-first-text">Error</span>
        </div>
        <div className="my-2 popup_text">{props?.message?props.message:"Please try it later"}</div>
        <button
          onClick={() => {
            props.errorPopup(false);
            // setOpenModal(false);
          }}
          className="w-1/8 primary-btn popup_button"
        >
          ok
        </button>
      </div>
    </Modal>
  );
}
export default ErrorPopup;
