import React, { useState, useEffect } from "react";
import { SelectDropdown } from "../project/modals/select-dropdown";
import "./testcases.scss";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@material-ui/core";
import { FORM_FIELDS } from "../../pages/config/defectConstant";
import RichTextEditor from "../../pages/config/rich-texteditor";
import { Download } from "@mui/icons-material";
import { TextField } from "@mui/material";
import {
  getAllPlatformReq,
  createTestcaseExecution,
} from "../../api/api-services";
let tableRowData = [];
function TestCaseExecution(props) {
  let [platformDetails, setPlatformDetails] = useState();
  let [selectedPlatform, setSelectedPlatform] = useState([]);
  const [detailsViewData, setdetailsViewData] = useState([]);
  const [tempState, setTempState] = useState(true);
  useEffect(() => {
    let tempFlag = localStorage.getItem("exectuionOpened");
    if (tempFlag === "true") {
      tableRowData = JSON.parse(JSON.stringify(props.testcaseRows?.rows));
      setTempState(!tempState);
    }
  }, []);
  tableRowData =
    tableRowData.length > 0
      ? tableRowData
      : JSON.parse(JSON.stringify(props.testcaseRows?.rows));
  const [callingAPI, setCallingAPI] = useState(false);
  const { TEXTBOX, LINK, ATTACHMENT, TEXTEDITOR } = FORM_FIELDS;
  let statusOption = [
    {
      label: "Pass",
      value: "Pass",
    },
    {
      label: "Fail",
      value: "Fail",
    },
  ];
  let [platformList, setPlatformlist] = useState([]);
  let [platformDetailsList, setPlatformDetailslist] = useState([]);
  let projectId = JSON.parse(localStorage.getItem("selected-project")).project
    .fireFlinkProjectId;
  let projectType =
    JSON.parse(localStorage["selected-project"]).project?.projectType
      ?.mobile !== null
      ? "Mobile"
      : JSON.parse(localStorage["selected-project"]).project?.projectType
          ?.web !== null
      ? "Web"
      : JSON.parse(localStorage["selected-project"]).project?.projectType
          ?.webAndMobile !== null
      ? "Web And Mobile"
      : "Others";

  let licenseId = JSON.parse(
    localStorage.getItem("selected-project")
  ).licenceID;
  let emailId = JSON.parse(localStorage["selected-user"]).email;
  let leadEmailId = JSON.parse(localStorage["selected-user"]).lead;
  let resultsArray = [];
  let tempTestCaseTemplate = JSON.parse(
    JSON.stringify(props?.testCaseTemplate)
  );
  let tempRows = JSON.parse(JSON.stringify(tableRowData));
  tempRows.map((obj, index) => {
    let tempKeys = Object.keys(obj);
    tempKeys.map((obj1, index1) => {
      let tempArray = [];
      if (obj1 !== "SL No" && obj1 !== "Status") {
        if (!Array.isArray(obj[obj1])) {
          tempArray.push(obj[obj1]);
          obj[obj1] = tempArray;
        }
      }
    });
    return obj;
  });
  resultsArray = tempRows.map((obj, index) => {
    let StepID = Array.isArray(obj["SL No"]) ? obj["SL No"][0] : obj["SL No"];
    let tempObj = {
      stepId: StepID,
      description: obj["Test Description"],
      testStep: obj["Test Steps"],
      status: obj.Status?.value !== undefined ? obj.Status?.value : "",
      expectedResult: obj["Expected Result"],
      actualResult:
        obj["Actual Result"] !== [] && obj["Actual Result"] !== ""
          ? obj["Actual Result"]
          : [],
      testData: obj["Test Data"],
    };
    return tempObj;
  });
  let executionPayload = {
    testCaseId: tempTestCaseTemplate.id,
    testCaseName: tempTestCaseTemplate.name,
    results: resultsArray,
    users: [emailId, leadEmailId],
    assignedTo: leadEmailId,
    status: "INREVIEW",
    history: [
      {
        approver: emailId,
        status: "INREVIEW",
      },
    ],
    module:
      tempTestCaseTemplate.parentId + ":" + tempTestCaseTemplate.parentName,
    platformName: "",
    os: "",
    osVersion: "",
    browser: "",
    browserVersion: "",
    deviceName: "",
    deviceBrand: "",
    location: {
      city: "",
      state: "",
      country: "",
    },
  };
  const getAllPlatforms = async () => {
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    let emailId = JSON.parse(localStorage["selected-user"]).email;

    try {
      let response = await getAllPlatformReq(projectId, emailId);
      if (response?.data?.statusCode === 200) {
        let temparray = [];
        let tempPlatforms = [];
        props.testcaseExecutionList.forEach((obj) => {
          tempPlatforms.push(obj.platformName);
        });
        setPlatformDetailslist(response?.data?.platforms);
        let tempSelectedPlatform = "";
        response?.data?.platforms.map((item) => {
          if (tempPlatforms.indexOf(item.platformName) === -1) {
            if (item.isDefault === true) {
              setSelectedPlatform([
                {
                  label: item.platformName,
                  value: item.platformName,
                },
              ]);
              tempSelectedPlatform = item.platformName;
              setPlatformDetails(item);
            }
            temparray.push({
              label: item.platformName,
              value: item.platformName,
            });
          }
        });
        if (tempSelectedPlatform === "") {
          if (temparray.length > 0) {
            setSelectedPlatform([temparray[0]]);
            response?.data?.platforms?.map((item) => {
              if (item.platformName === temparray[0].value) {
                setPlatformDetails(item);
              }
            });
          }
        }
        setPlatformlist(temparray);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getAllPlatforms();
    let data = tempTestCaseTemplate.manualTestCase.testCaseDetails;
    console.log("data111111111111", tempTestCaseTemplate);
    setdetailsViewData(data);
    let tempEle = document.getElementById("testcase_execution_header_Label");
    tempEle.scrollIntoViewIfNeeded({
      behavior: "auto",
      block: "middle",
      inline: "nearest",
    });
  }, []);

  const onSaveExecution = () => {
    let statusNotGiven = 0;
    let resultNotGiven = 0;
    if (platformList.length > 0) {
      setCallingAPI(true);
      executionPayload.results.map((element) => {
        if (element.status === "") {
          statusNotGiven++;
        }
        if (
          element.status.toUpperCase() === "FAIL" &&
          (element.actualResult.length === 0 ||
            element.actualResult[0].length === 0)
        ) {
          resultNotGiven++;
        }
      });
      if (statusNotGiven === 0 && resultNotGiven === 0) {
        createExecution();
      } else {
        if (statusNotGiven !== 0 && resultNotGiven !== 0) {
          props.MyAlert.info(`Please update status and actual result`);
        } else {
          if (statusNotGiven !== 0) {
            props.MyAlert.info(`Please update status for every step`);
          } else if (resultNotGiven !== 0) {
            props.MyAlert.info(`Please update actual result for failed steps`);
          }
        }
        setCallingAPI(false);
      }
    } else {
      props.MyAlert.info(`No platforms avalibale for execution`);
    }
  };
  const createExecution = async () => {
    console.log("execution request", executionPayload);
    try {
      executionPayload["platformName"] = platformDetails.platformName;
      executionPayload["os"] = platformDetails.os;
      executionPayload["osVersion"] = platformDetails.osVersion;
      executionPayload["browser"] = platformDetails.browser;
      executionPayload["browserVersion"] = platformDetails.browserVersion;
      executionPayload["deviceName"] = platformDetails.deviceName;
      executionPayload["deviceBrand"] = platformDetails.deviceBrand;
      executionPayload["deviceType"] = platformDetails.deviceType;
      executionPayload["location"] = {
        city: platformDetails.city,
        country: platformDetails.country,
        state: platformDetails.state,
      };
      executionPayload["projectId"] = projectId;

      let response = await createTestcaseExecution(
        projectId,
        licenseId,
        executionPayload
      );
      if (response.status === 200 && response.data.responseCode === 201) {
        console.log("execution response", response);
        props.MyAlert.success(
          `"${executionPayload.testCaseId}" - Testcase executed successfully`
        );
        props.callList("ExecutionDashBoard");
        props.closeExecutionscreen();
      }
      setCallingAPI(false);
    } catch (error) {
      console.log("error", error);
      setCallingAPI(false);
    }
  };
  function OnchangeStatus(data, index) {
    executionPayload.results[index].status = data[0].value;
    tableRowData[index].Status = data[0];
  }
  function OnchangeActualResult(data, index) {
    executionPayload.results[index].actualResult = data.value
      .trim()
      .split("\n");
    tableRowData[index]["Actual Result"] = data.value.trim().split("\n");
  }
  function onChangePlatform(data) {
    setSelectedPlatform([...data]);
    platformDetailsList.map((item) => {
      if (item.platformName === data[0].value) {
        console.log("item details", item);
        setPlatformDetails(item);
      }
    });
  }

  const TestStepsTable = (data) => {
    let listData = data.tableData.manualTestCase;

    return (
      <>
        <table className="testStepTable mt-8 w-full">
          <thead
            style={{ display: " table-header-group", verticalAlign: "middle" }}
            className="bg-gray-100"
          >
            <tr key={"tableHeader"}>
              {listData.testSteps?.headers?.map((data, index) => {
                return (
                  <>
                    <th
                      className="p-2 testStepTable common-label-text fontPoppinsMediumMd"
                      key={data + index}
                    >
                      {data}{" "}
                    </th>
                  </>
                );
              })}
            </tr>
          </thead>
          <tbody
            style={{
              height: "auto",
              overflowY: "auto",
              display: " table-row-group",
              verticalAlign: "middle",
            }}
          >
            {tableRowData?.map((rowData, index) => (
              <tr
                style={{ display: " table-row", verticalAlign: "inherit" }}
                className={`h-11 testStepTable ${
                  (index + 1) % 2 === 0
                    ? "test-case-table-even-row"
                    : "test-case-table-odd-row"
                }`}
                key={index}
              >
                {listData.testSteps?.headers?.map((cellname, colIndex) => {
                  return (
                    <>
                      <td
                        className="testStepTable fontPoppinsRegularSm text-left pl-2 table-cell-text focus:border-opacity-0 focus:outline-none"
                        key={index + colIndex}
                        contentEditable={false}
                        suppressContentEditableWarning={true}
                      >
                        {cellname === "Actual Result" ? (
                          <TextField
                            multiline={true}
                            onBlur={(data) => {
                              OnchangeActualResult(data.target, index);
                            }}
                            placeholder="Enter actual result"
                            value={data.value}
                            defaultValue={rowData[cellname]}
                            style={{ width: "200px" }}
                            InputLabelProps={{ shrink: true }}
                          />
                        ) : cellname !== "Status" ? (
                          rowData[cellname]
                        ) : Array.isArray(rowData[cellname]) ? (
                          rowData[cellname].map((data) => (
                            <>
                              {data}
                              <br />
                            </>
                          ))
                        ) : (
                          <div id="tc_result">
                            <SelectDropdown
                              data={statusOption}
                              onChange={(data) => {
                                OnchangeStatus(data, index);
                              }}
                              selectedData={
                                rowData[cellname] === ""
                                  ? []
                                  : [rowData[cellname]]
                              }
                              searchable={false}
                            />
                          </div>
                        )}
                        {""}
                      </td>
                    </>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  const detailViewComponents = (data) => {
    let value;

    if (data?.type !== ATTACHMENT) {
      value =
        data?.value && Array.isArray(data.value)
          ? data.value.join(",")
          : data.value;
    }

    if (data.type === LINK && value) {
      return (
        <>
          <a
            href={value}
            target="_blank"
            className="label-overflow tab-style-common"
          >
            {value}
          </a>
        </>
      );
    }
    if (data.type === ATTACHMENT && data?.value && Array.isArray(data?.value)) {
      return (
        <div>
          {data?.value?.length === 0 || data?.value === "" ? (
            "--"
          ) : (
            <>
              {data?.value?.map((option, index) => (
                <div key={index} className="flex my-2" title={option?.name}>
                  <div className="datatrimmer w-24 cursor-default	">
                    {option?.name || "--"}
                  </div>
                  <Download
                    color="primary"
                    className="h-6 w-6  -mt-2 mr-2 origin-center cursor-pointer"
                  />
                </div>
              ))}
            </>
          )}
        </div>
      );
    }
    if (data.type === TEXTEDITOR) {
      return (
        <div className="mt-2 text-justify defect-texteditor">
          {value !== "" ? (
            <RichTextEditor
              convertedContent={value || ""}
              readOnly={true}
              toolbarHidden={true}
            />
          ) : (
            "--"
          )}
        </div>
      );
    }

    return (
      <>
        {value !== "--" && value?.length > 30 ? (
          <Tooltip
            title={value !== "--" && value?.length > 30 && value}
            placement="top"
          >
            <label className="label-overflow cursor-pointer text-justify">
              {value || "--"}
            </label>
          </Tooltip>
        ) : (
          <label className="label-overflow text-justify">{value || "--"}</label>
        )}
      </>
    );
  };
  const detailViewElements = (data) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="flex ">
              <label>
                {data ? (
                  <Tooltip title={data?.label} placement="top">
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap labels">
                      {data?.label.length > 32
                        ? `${data?.label.substring(0, 32)}...`.toUpperCase()
                        : data?.label.toUpperCase()}
                    </div>
                  </Tooltip>
                ) : null}
              </label>
            </div>
          </div>
          <div
            className={`contentDataStyle  values ${
              data?.value?.length > 32 ? "datatrimmer w-34" : ""
            }`}
          >
            {detailViewComponents(data)}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <span
          className="testcase_execution_header"
          id="testcase_execution_header_Label"
        >
          Execution
        </span>
      </div>
      <div className="testcase_execution" id="testcaseExecutionContainer">
        <div className="testcaseExecution_container mt-4">
          <div className="testcasedetails_container">
            <div>
              <Grid
                container
                spacing={{ xs: 2, md: 3, sm: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {detailsViewData &&
                  detailsViewData.length > 0 &&
                  detailsViewData.map((data, index) => {
                    return [TEXTBOX, TEXTEDITOR].includes(data?.type) ? (
                      <Grid item xs={11} sm={11} md={11} key={index}>
                        {detailViewElements(data)}
                      </Grid>
                    ) : (
                      <Grid item xs={4} sm={4} md={4} key={index}>
                        {detailViewElements(data)}
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          </div>
          <div className="mt-8">
            <div className="flex justify-between mr-3">
              <div>
                <div className="labels">OS</div>
                <div className="testcase_values datatrimmer w-28">
                  {platformDetails?.os}
                </div>
              </div>

              <div>
                <div className="labels">OS VERSION</div>
                <div className="testcase_values datatrimmer w-28">
                  {platformDetails?.osVersion}
                </div>
              </div>

              {platformDetails?.deviceName !== undefined &&
              platformDetails.deviceName !== "" &&
              platformDetails.deviceName !== null ? (
                <>
                  <div>
                    <div className="labels">DEVICE BRAND</div>
                    <div className="testcase_values datatrimmer w-28">
                      {platformDetails?.deviceBrand}
                    </div>
                  </div>

                  <div>
                    <div className="labels">DEVICE NAME</div>
                    <div className="testcase_values datatrimmer w-28">
                      {platformDetails?.deviceName}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {projectType !== "Others" && (
                    <>
                      <div>
                        <div className="labels">BROWSER</div>
                        <div className="testcase_values datatrimmer w-28">
                          {platformDetails?.browser}
                        </div>
                      </div>

                      <div>
                        <div className="labels">BROWSER VERSION</div>
                        <div className="testcase_values datatrimmer w-28">
                          {platformDetails?.browserVersion}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <div>
                <div className="labels">DEVICE</div>
                <div className="testcase_values datatrimmer w-28">
                  {platformDetails?.deviceType}
                </div>
              </div>
              <div>
                <div className="labels">LOCATION</div>
                <div className="testcase_values datatrimmer w-28">
                  {platformDetails?.city}
                </div>
              </div>
              <div>
                <div className="labels">PLATFORM</div>
                <div className="testcase_values  tc_selectdropdown">
                  <SelectDropdown
                    data={platformList}
                    selectedData={selectedPlatform}
                    onChange={onChangePlatform}
                    searchable={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <br></br>

          <div className="col-span-12 ">
            <TestStepsTable tableData={props?.testCaseTemplate} />
            <div className="flex description_value mt-1">
              <span className="text-red-400 mr-1">&#42;</span>
              <span>Please update actual result for failed steps</span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 h-10 mt-4">
          <div className="col-span-12 flex justify-end">
            <div className="flex">
              <div
                className="testcase_cancle_btn  flex justify-center cursor-pointer"
                onClick={props.closeExecutionscreen}
              >
                <button>Cancel</button>
              </div>
              <div
                className={
                  "testcase_save_btn  flex justify-center cursor-pointer"
                }
                onClick={() => {
                  if (callingAPI === false) {
                    onSaveExecution();
                  }
                }}
              >
                <button
                  disabled={callingAPI}
                  className={`${callingAPI ? "opacity-50" : ""}`}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TestCaseExecution;
