import React, { useState, useEffect } from "react";
import { useTable, useGlobalFilter } from "react-table";
import Close from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import TesterDeatilsPopup from "../modal/tester-deatils-popup";
import {
  getUsersByUserId,
  getAllUsersList,
  getAllUsersListhierarchy,
  getUserCountReq,
  
} from "../../api/api-services";
import BugsDeatilsPopup from "../modal/bugs-list-popup";
import Add from "@mui/icons-material/Add";
import CreateUser from "./modals/create-user";
// import { ReactComponent as NoUserIcon } from "../../assets/noUsersIcon.svg";
import { Tooltip } from "@material-ui/core";
import SearchField from "../common/search-field";
import NoRelevantDataIcon from "../../assets/reportsicon/no_reports_icon.png";
import { ReactComponent as NoUserIcon } from "../../assets/reportsicon/noReportsAvailable.svg";
function Users(props) {
  let [tester, setTester] = useState([]);
  let [showSearch, setShowSearch] = useState(false);
  let [testerDeatils, setTesterDeatils] = useState();
  let [testerPopup, setTesterPopup] = useState(false);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [bugdetailsPopup, setBugdetailsPopup] = useState(false);
  let [closeSearchField, setCloseSearchField] = useState(false);
  let [pageno,setpageNo]=useState(0);
  let [lastPage,setLastpage]=useState(false);
  let size=20;
  const [TotaluserCount,setTotaltUserCount]=useState(0)

  useEffect(() => {
    console.log("all ueser called");
    getAllUsers(0);
  }, []);
  // function getUserCount (projectID,leadEmail){
  //   getUserCountReq(projectID,leadEmail).then((result)=>{
  //    if(result.status===200){
  //     setTotaltUserCount(result.data.userCount)
  //    }

  //   }).catch((error)=>{
  //     console.log("Error")
  //   })
  // }

  const getAllUsers = (page) => {
    console.log("all ueser");
    const projectID = JSON.parse(localStorage.getItem("selected-project")).id;
    const email = JSON.parse(localStorage["fire-crowd-user"]).userName;
    try {
      getAllUsersList(projectID,page,size).then((response) => {
        if (response.data) {
           setLastpage(response?.data?.lastPage)
           setTotaltUserCount(response.data.userCount)
          let data = [];
          response?.data?.userList?.map((user) => {
            if (user.role !== "ADMIN") {
              data.push({
                ...user,
                defectCount: {
                  totalDefectCount: user.totalDefectCount,
                  approvedDefectCount: user.approvedDefectCount,
                },
              });
            }
          });
          if(page===0){
            setTester([...data]);
            setDataLoaded(true);
            if(response?.data?.lastPage===false){
                setpageNo(page+1);
              }
              // getUserCount(projectID, email)
          }else{
          let temparray=tester.concat(data);
          setTester(temparray);
          setDataLoaded(true);
          if(response?.data?.lastPage===false){
            setpageNo(page+1);
           }
          }

         
        }
      });
    } catch (error) {
      console.log("error");
    }
  };
  const projectId = JSON.parse(localStorage["selected-project"]).id;
  const getUsersByUser = async (projectId, id) => {
    console.log("project Deatils", projectId, id);

    try {
      // const response = await getUsersByUserId(projectId,id);

      // if (response.status === 200) {
      //   console.log("project Deatils", response);

      //   setTesterDeatils(response.data);
      // }
      getUsersByUserId(projectId, id).then((response) => {
        if (response.status === 200) {
          console.log("project Deatils", response);

          setTesterDeatils(response.data);
        }
      });
    } catch (e) {
      console.log("error");
    }
  };

  let data = React.useMemo(() => tester, [tester]);
  function CloseTesterModal(state) {
    if(globalFilter!==undefined){
      setShowSearch(true)
    }else{
      setShowSearch(false)
    }
    setTesterPopup(state);
  }

  function CloseBugModal(state) {
    setBugdetailsPopup(state);
  }
  function getRole(role){
    switch(role){
      case "PROJECT_OWNER":{
        return "Project Owner"
      }
      case "PROJECT_MANAGER":{
        return "Project Manager"
      }
      case "ACCOUNT_MANAGER":{
        return "Account Manager"
      }
      case "TEST_LEAD":{
        return "Test Lead"
      }
      case "TESTER":{
        return "Test Engineer"
      }
      case "ADMIN":{
        return "Admin"
      }
      default:{
        return role;
      }
    }

  }
  let columns = React.useMemo(
    () => [
      {
        Header: "NAME",
        accessor: "name",
        width: "25%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              setTesterDeatils(e.cell.row.original);
              // getUsersByUser(projectId, e.cell.row.original.id);
              if(globalFilter===""){
                setShowSearch(false);
              }
             
              setTesterPopup(true);
              setCloseSearchField(true)
            }}
            className="datatrimmer w-32 table_cell_links cursor-pointer"
            style={{ marginLeft: "6%" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "ROLE",
        accessor: "role",
        width: "25%",
        Cell: ({ value }) => {
          return (
            <div style={{ marginLeft: "6%" }}>{getRole(value)}</div>
          );
        },
      },
      {
        Header: "REPORTING TO",
        accessor: "leadName",
        width: "25%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "8%" }}
              className="datatrimmer w-60"
            >
              {value?value:"none"}
            </div>
          );
        },
      },
      {
        Header: "DEFECTS",
        accessor: "defectCount",
        width: "25%",
        Cell: (e) => (
          <div
            // title={e.value}
            onClick={(event) => {
              // event.preventDefault();
              // setBugdetailsPopup(true);
            }}
            className="table_cell_links datatrimmer cursor-pointer"
            style={{ marginLeft: "9%" }}
          >
            {e?.value?.approvedDefectCount || e?.value?.totalDefectCount ? (
              <span>
                <span title={"Approved Defects"}>
                  {e.value?.approvedDefectCount === null ||
                  e?.value?.approvedDefectCount <= 0
                    ? 0
                    : e.value?.approvedDefectCount}
                </span>/
                <span title={"Total Defects"}>
                  {e.value?.totalDefectCount === null ||
                  e?.value?.totalDefectCount <= 0
                    ? 0
                    : e.value?.totalDefectCount}
                </span>
              </span>
            ) : (
              "--"
            )}
          </div>
        ),
        // Cell: ({ value }) => {
        //     return (
        //       <div style={{ marginLeft: "15%" }} >
        //         {value === null ? "--" : value}
        //       </div>
        //     );
        //   },
      },
      // {
      //   Header: "TESTCASES",
      //   accessor: "totalTestCasesCount",
      //   width: "25%",
      //   Cell: ({ value }) => {
      //     return (
      //       <div style={{ marginLeft: "15%" }}>
      //         {(value === null || value===0 )? "--" : value}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Defects",
      //   accessor: "defects",
      //   width: "20%",
      //   Cell: (e) => (
      //     <div
      //       title={e.value}
      //       onClick={(event) => {
      //         event.preventDefault();

      //         setBugdetailsPopup(true);
      //       }}
      //       className="table_cell_links cursor-pointer"
      //       style={{ marginLeft: "15%" }}
      //     >
      //       {e.value === null ? "--" : e.value}
      //     </div>
      //   ),

      // },
    ],
    []
  );
  const handlescroll = (event) => {
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ){
      if(lastPage===false){
        getAllUsers(pageno)
      }
    }
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  return (
    <div className="user-deatils">
      <div className="grid grid-cols-12 flex content-between">
        <div className="col-span-10 heading pl-3">
          Users List - {TotaluserCount}
        </div>
        <div className="grid grid-cols-12 col-span-2 flex justify-end">
        <SearchField setGlobalFilter={setGlobalFilter} closeField={closeSearchField} setCloseField={setCloseSearchField} colSpan="col-span-11 ml-auto"/>
        </div>
      </div>

      <div className="tablecontainer">
        <table {...getTableProps()}>
          <thead className="text-left table_column_header h-10 ml-4">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th width={column.width} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {data.length === 0 && dataLoaded ? (
            <div className="user_list_page_msg grid grid-cols-12">
              <div className="col-span-12 ">
                <div className="noDefects flex justify-center">
                  <NoUserIcon />
                </div>
                <div className="no_data_text">No Users found!</div>
              </div>
            </div>
          ) : dataLoaded ? (
            <tbody {...getTableBodyProps()} className="border-t-2" onScroll={handlescroll}>
              {rows.length > 0 ? (
                <>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="h-10 table_cell_value  border-b-2"
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                
                  <div className="no_relevant_data">
                    <img  alt="warning" src={NoRelevantDataIcon}/>
                    <div className="text_msg">No Relevant Data Is Found !</div>
                  </div>
                </>
              )}
            </tbody>
          ) : null}
        </table>
      </div>
      {testerPopup ? (
        <TesterDeatilsPopup
          testerDeatils={testerDeatils}
          CloseTesterModal={CloseTesterModal}
        />
      ) : (
        ""
      )}

      {bugdetailsPopup && <BugsDeatilsPopup CloseBugModal={CloseBugModal} />}
    </div>
  );
}
export default Users;
