import { useEffect, useState, useContext } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import "../project.scss";
import cx from "classnames";
import Modal from "react-modal";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import MyInput, { Label } from "../../common/inputs/myInput";
import { SIGNUP_LABELS, EMAIL_PATTERN } from "../../../util/app-constants";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { ExcelRenderer } from "react-excel-renderer";
import style from "../../common/inputs/inputs.module.scss";
import {
  createUser,
  defaultCountry,
  downloadFileRequest,
  editProject,
  editUser,
  getAlldependentUser,
  getAllLeadUser,
  getProjectDetails,
  getUserByEmailId,
  getUserHierarchyReq,
  checkForUserExistReq,
} from "../../../api/api-services";
import { SelectDropdown } from "./select-dropdown";
import { duplicatesCheck } from "../../../util/common-utils";
import { ProgressContainerContext } from "../../common/inputs/ProgressContainer";
import { CommonLoader } from "../../../pages/common/common-loader";
import WarningModal from "../../../pages/common/warning-modal";
import RejecteduserPopup from "../../modal/rejecteduser-modal";
import ProgressContainer from "../../common/inputs/ProgressContainer";

function CreateUser(props) {
  console.log("CreateUSer",props)
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  const [openModal, setOpenModal] = useState(true);
  const [phoneNumberFields, setPhoneNumberFields] = useState([
    { value: "", error: "", countryData: null },
  ]);
  const [presentCountry, setPresentCountry] = useState("");
  const [userAlreadyExist, setUserAlreadyExist] = useState(false);
  const [emailError, setEmailError] = useState();
  const [displayReportingTo, setDisplayReportingTo] = useState(false);
  const [hideReportingTo, setHideReportingTo] = useState(false);
  const [reportingToError, setReportingToError] = useState(false);
  const [bulkUpload, setBulkUpload] = useState(false);
  const [leaduserRole, setLeaduserRole] = useState("");
  const [selectedPrivilege, setSelectedPrivilege] = useState(
    props.defaultRole
      ? [{ label: props.defaultRole==="TESTER"?"TEST ENGINEER":props.defaultRole, value: props.defaultRole }]
      : []
  );
  const [leadData, setLeadData] = useState([]);
  const project = JSON.parse(localStorage.getItem("selected-project"));
  const logedinUser = JSON.parse(localStorage.getItem("fire-crowd-user"));
  const userPrivilege = JSON.parse(localStorage.getItem("user-privilege"));
  const projectStatus=JSON.parse(localStorage.getItem("selected-project")).projectStatus
  let [selectedProj, setSelectedProj] = useState({});
  const [selectedReporter, setSelectedReporter] = useState([]);
  const [editMode, setEditMode] = useState(
    props?.mode === "Edit User" ? true : false
  );
  let [excelData, setExcelData] = useState([]);
  let [role, setRole] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [selectedTemplate, setselectedTemplate] = useState([]);
  const [tempselectedtemp, setTempselectedtemp] = useState([]);
  let [warningPopup, setWarningPopup] = useState(false);
  let [dltWarningPopup, setDltWarningPopup] = useState(false);
  const [showrejectedUser, setRejectedUser] = useState(false);
  
  if (userPrivilege?.role?.includes("_")) {
    userPrivilege.role = userPrivilege.role.replace("_", "-");
  }
  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .min(3, "Mininum 3 characters")
      .max(25, "Maximum 25 characters")
      .matches(/^[A-Za-z ]*$/, "Name should be alphabetic")
      .required("Name is required"),
    role: yup.string().required("Role is required"),
    emailId: yup
      .string()
      .trim()
      .required("Email id is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Enter valid email address"),
      // .test("Unique", "User already Exist!", (values) => {
      //   if (props.mode !== "Edit User") {
      //      return duplicatesCheck(props.tester, "", values, "email");
      //     // return checkforuser(values)
      //   } else {
      //     return true;
      //   }
      // }),
    phoneNumbers: yup
      .string()
      .min(9, "Valid Phone number is required")
      .max(13, "Valid Phone number is required")
      .required("Valid Phone number is required"),
  });
  let initialValues;
  if (props?.mode === "Edit User") {
    initialValues = {
      name: props?.userData?.name ? props.userData.name : "",
      role:
        props.mode === "Edit User" || props.defaultRole
          ? props.defaultRole
          : "",
      emailId: props?.userData?.email ? props.userData.email : "",
      phoneNumbers: props?.userData?.phoneNumbers?.primaryPhone
        ? props.userData.phoneNumbers?.primaryPhone
        : "",
    };
  } else {
    initialValues = {
      name: "",
      role:
        props.mode === "Edit User" || props.defaultRole
          ? props.defaultRole
          : "",
      emailId: "",
      phoneNumbers: "",
    };
  }
 
  
  const onSubmit = async (values) => {
    let response;
    if (phoneNumberFields[0].error !== "Valid Phone number is required") {
      if (displayReportingTo && !selectedReporter.length) {
        setReportingToError(true);
      } else {
        if (props.mode === "Edit User") {
          console.log("props.userdata",props?.userData)
          let payload = {
            projectId: project.id,
            editorEmail: logedinUser.userName,
            id:props?.userData.id,
            lead: "none",
            userList: [
              {
                name: props?.userData.name,
                email: props?.userData.email,
                phoneNumbers: props?.userData?.phoneNumbers,
                createdBy: props?.userData.createdBy,
                createdOn: props?.userData.createdOn,
              },
            ],
          };
          payload.role = values.role;
          if (displayReportingTo) {
            payload.lead = selectedReporter[0]?.value;
          } else if (values.role === "ACCOUNT-MANAGER") {
            payload.lead = props?.userData?.lead;
          }
          if (payload?.role?.includes("-")) {
            payload.role = payload.role.replace("-", "_");
          }
          if (
            props.userData?.role === payload.role.replace("_", "-") &&
            props.userData?.configuration
          ) {
            payload.userList[0].configuration = props.userData?.configuration;
          }
          if (
            props.userData?.lead !== payload?.lead ||
            props.userData?.role !== payload.role.replace("_", "-")
          ) {
            setIsloading(true);
            // resetProgress();
            // startOrResumeProgress({
            //   stopAt: 100,
            //   intervalDuration: 80,
            // });
            try {
              response = await editUser(payload);
              if (response.data || response.status === 200) {
                console.log("edit user",response)
                setIsloading(false);
                // resetProgress();
                if (response?.data?.httpStatusCode === 200 && response?.data?.httpStatus==="OK" && response?.data?.errorMessage===null) {
                  props.MyAlert.success(
                    `${props?.userData?.name} updated successfully`
                  );
                } else if(response?.data?.errorMessage==="User cannot be edited; test cases have been assigned."){
                  props.MyAlert.info(
                    response?.data?.errorMessage
                  );
                }
                else if (
                  response?.data?.rejectProjectStatusErrorMessage !== null
                ) {
                  props.MyAlert.info(
                    response?.data?.rejectProjectStatusErrorMessage
                  );
                } else if (
                  response?.data?.rejectUserDependencyErrorMessage !== null
                ) {
                  props.MyAlert.info(
                    response?.data?.rejectUserDependencyErrorMessage
                  );
                } else {
                  props.MyAlert.info(
                    response?.data?.rejectUserNotFoundErrorMessage
                  );
                }
                props.getAllUsers(0);
                props.closeModal(false);
              } else if (response) {
                setIsloading(false);
                // resetProgress();
                if (
                  response?.response?.data?.rejectedUsers[0]?.dbStatusMessage
                ) {
                  props.MyAlert.info(
                    response.response.data.rejectedUsers[0].dbStatusMessage
                  );
                }
                props.closeModal(false);
              }
            } catch (err) {
              setIsloading(false);
              // resetProgress();
              console.error(err);
            }
          } else {
            props.MyAlert.info("No Changes has been Updated");
          }
        } else {
          setIsloading(true)
          let payload = {
            projectId: project.id,
            editorEmail: logedinUser.userName,
            role: values.role,
            lead: "none",
            userList: [
              {
                name: values.name.trim(),
                email: values.emailId?.toLowerCase(),
                phoneNumbers: {
                  primaryPhone: values.phoneNumbers,
                },
              },
            ],
          };

let projectID=JSON.parse(localStorage.getItem("selected-project")).id
  try{
    let response=await checkForUserExistReq(values.emailId,projectID);
    if(response.status===200){
      if(response.data){
        formikDetails.setFieldError('emailId', `User already Exist!`)
        setIsloading(false)
      }else{
        setIsloading(true)
        if (displayReportingTo) {
          payload.lead = selectedReporter[0].email;
        } else if (values.role === "ACCOUNT-MANAGER") {
          payload.lead = props?.projectowner;
        }
        if (payload?.role?.includes("-")) {
          payload.role = payload.role.replace("-", "_");
        }
        if (
          props.userData?.lead !== payload?.lead ||
          props.userData?.role !== payload.role.replace("_", "-")
        ) {
          try {
            response = await createUser(payload);
            if (response.data || response.status === 200) {
              setIsloading(false);
              // resetProgress();
              if (response?.data?.errorMessage === null) {
                console.log("response?.data?.successMessage",response?.data?.successMessage)
                props.MyAlert.success(`${values.name} added successfully`);
              } else if(response?.data?.errorMessage==="ALL GIVEN USERS ARE DUPLICATES"){
                props.MyAlert.danger(response?.data?.errorMessage)

              }
              else if (
                response?.data?.rejectAccountManagerNotExistErrorMessage !==
                null
              ) {
                props.MyAlert.info(
                  response?.data?.rejectAccountManagerNotExistErrorMessage
                );
              } else if (response?.data?.rejectErrorMessage !== null) {
                props.MyAlert.info(response?.data?.rejectErrorMessage);
              } else if (
                response?.data?.rejectTestLeadNotExistErrorMessage !== null
              ) {
                props.MyAlert.info(
                  response?.data?.rejectTestLeadNotExistErrorMessage
                );
              } 
              else {
                props.MyAlert.info(
                  response?.data?.rejectProjectManagerNotExistErrorMessage
                );
              }
              props.getAllUsers(0);
              props.closeModal(false);
            } else if (response) {
              setIsloading(false);
              // resetProgress();
              if (response?.response?.data?.rejectedUsers?.length > 0) {
                props.MyAlert.info(
                  response.response.data.rejectedUsers[0].dbStatusMessage
                );
              } else if (
                response?.response?.data?.duplicateUsers?.length > 0
              ) {
                props.MyAlert.info(
                  response.response.data.duplicateUsers[0].dbStatusMessage
                );
              }
            }
          } catch (err) {
            setIsloading(false);
            // resetProgress();
            console.error(err);
          }
        } else {
          props.MyAlert.info("No Changes has been Updated");
        }
      }
     
    }
  }catch(error){
    console.log("Error")
  }
          
            
          
          
        }
      }
    }
  };
  function validatePhone(phone) {
    // var phone_regex = /^[0-9]{12}$/;
    var phone_regex=/^(?:[0-9] ?){6,14}[0-9]$/
    // var phone_regex = /^[6-9]{1}[0-9]{9}$/;
    return phone?.toString().length > 0 && phone_regex.test(phone);
  }
  function validateName(name) {
    var name_regex = /^[a-zA-Z ]+$/;
    return name?.length > 2 && name_regex.test(name);
  }
  const uploadbulkUser = async () => {
    if (
      (displayReportingTo && !selectedReporter.length) ||
      !selectedPrivilege.length
    ) {
      if (!selectedPrivilege.length) {
        formikDetails.setFieldError("role", "Role is required");
        formikDetails.setFieldTouched("role", true);
      }
      setReportingToError(true);
    } else {
      let selectedData = [];
      let removedData = [];
      let rejectedData = [];
      let excledataCount=0;
      let duplicateData=[];
      if (formikDetails.values.role && excelData.length > 1) {
        setIsloading(true);
        // resetProgress();
        // startOrResumeProgress({
        //   stopAt: 100,
        //   intervalDuration: 80,
        // });
        let response;
        let usersData = [];
        let exldata = [...excelData];
        let payload = {
          projectId: project.id,
          editorEmail: logedinUser.userName,
          userList: [],
          lead: "none",
        };
        let regectedCount = 0;
        let duplicateCount = 0;
        if (
          exldata[0].includes("countrycode") &&
          (exldata[0].includes("phone") ||
            exldata[0].includes("phoneNumbers")) &&
          exldata[0].includes("email") &&
          exldata[0].includes("name")
        ) {
          exldata?.map((data, index) => {
            if (index !== 0 && data.length > 0) {
              let obj = {};
              if(data.length!==0){
                excledataCount=excledataCount+1;
              }
              data.map((value, i) => {
               
                if (
                  ["phone", "phoneNumbers"]?.includes(exldata[0][i]?.trim())
                ) {
                  obj.phoneNumbers = {
                    primaryPhone:
                      typeof value === "string" && value && value?.includes(" ")
                        ? obj?.phoneNumbers?.primaryPhone
                          ? obj.phoneNumbers.primaryPhone +
                            value?.split(" ")?.join("")
                          : value?.split(" ")?.join("")
                        : obj?.phoneNumbers?.primaryPhone
                        ? obj.phoneNumbers.primaryPhone + value.toString()
                        : value.toString(),
                  };
                } else if (["countrycode"]?.includes(exldata[0][i]?.trim())) {
                  obj.phoneNumbers = {
                    primaryPhone: obj?.phoneNumbers?.primaryPhone
                      ? value.toString() + obj.phoneNumbers.primaryPhone
                      : value.toString(),
                  };
                } else {
                  if (exldata[0][i] === "email") {
                    obj[exldata[0][i]?.trim()] =
                      value && value?.includes(" ")
                        ? value?.split(" ")?.join("")?.trim()?.toLowerCase()
                        : value?.toLowerCase();
                  } else {
                    obj[exldata[0][i]?.trim()] = value?.trim();
                  }
                }
              });
              payload.role = selectedPrivilege[0]?.label==="TEST ENGINEER"?"TESTER":selectedPrivilege[0]?.label;
              if (displayReportingTo) {
                payload.lead = selectedReporter[0]?.email;
              } else if (selectedPrivilege[0]?.label === "ACCOUNT-MANAGER") {
                payload.lead = props?.projectowner;
              }
              let duplicateCheck = duplicatesCheck(props.tester, "", obj.email, "email")
              if (
                isEmailValid(obj.email) &&
                validatePhone(obj?.phoneNumbers?.primaryPhone) &&
                validateName(obj.name) &&
                duplicateCheck &&
                !selectedData?.includes(obj.email) &&
                !removedData?.includes(obj.email)
              ) {
                console.log(obj.email);
                selectedData.push(obj.email);
                usersData.push({ ...obj });
              } else {
                if (selectedData?.includes(obj.email)) {
                  let index = selectedData.indexOf(obj.email);
                  // duplicateCount = duplicateCount + 2;
                  rejectedData.push(obj);
                  rejectedData.push(obj);
                  removedData.push(obj.email);
                  selectedData.splice(index, 1);
                  usersData.splice(index, 1);
                } else if (removedData?.includes(obj.email)) {
                  duplicateCount = duplicateCount + 1;
                  rejectedData.push(obj);
                }else {
                  if(!duplicateCheck){
                    duplicateData.push(obj.email)
                    duplicateCount=duplicateCount+1;
                  }
                  if (
                    (obj.name ||
                    obj.email ||
                    obj?.phoneNumbers?.primaryPhone) && duplicateCheck
                  ) {
                    regectedCount = regectedCount + 1;
                    rejectedData.push(obj);
                   
                  } 
                  
                  
                }
              }
            }
          });
          if (payload?.role?.includes("-")) {
            payload.role = payload.role.replace("-", "_");
          }
          payload.userList = usersData;
          if (usersData.length !== 0) {
            try {
              response = await createUser(payload);
              if (response.data || response.status === 200) {
                setIsloading(false);
                if (
                  
                  rejectedData.length === 0
                ) {
                  
                  props.MyAlert.success(`Users Added : 
                    ${response?.data?.addedUsersCount}
                     Rejected : 
                    ${response.data.rejectedUsersCount + rejectedData.length}
                    Duplicate:${
                      response.data.duplicateUsersCount + duplicateCount
                    }`)
                } else {
                  
                  props.closeRejecteduserPopup(true,rejectedData,payload);
                  props.MyAlert.success(`Users Added : 
                    ${response?.data?.addedUsersCount}
                     Rejected : 
                    ${response.data.rejectedUsersCount + rejectedData.length}
                    Duplicate:${
                      response.data.duplicateUsersCount + duplicateCount
                    }`)
                  
                }
                props.getAllUsers(0);
                props.closeModal(false);
              } else if (response) {
                setIsloading(false);
                if (response?.response?.data?.errorMessage) {
                  props.MyAlert.info(response.response?.data?.errorMessage);
                }
                props.closeModal(false);
              }
            } catch (err) {
              setIsloading(false);
              console.error(err);
            }
          } else {
            setIsloading(false);
            console.log("excledataCount",excledataCount);
            console.log("duplicate",duplicateCount)
            console.log("duplicateData",duplicateData)
            console.log("rejected",rejectedData)
            if(rejectedData.length !== 0 ){
              props.closeRejecteduserPopup(true,rejectedData,payload);
              props.MyAlert.danger(`${duplicateCount?"Duplicate Users:"+duplicateCount+",":""} Rejected Users:${rejectedData.length}`);
                props.closeModal(false);
            }else if(excledataCount===0){
              props.MyAlert.info("No data found in excle");
            }else if (duplicateCount === excledataCount) {
              props.MyAlert.danger("ALL USERS ALREADY EXISTS IN THE DATABASE");
            } else if(rejectedData.length===excledataCount) {
              if(duplicateCount===0){
                props.MyAlert.danger("All date are rejected");
              }else{
                props.MyAlert.danger(`Duplicate:${duplicateCount},Rejected:${rejectedData.length-duplicateCount}`);
              }
             
            }else{
              props.MyAlert.danger("Failed to Add Users as No data Found");
            }
          }
        } else {
          props.closeModal(false);
          props.MyAlert.danger("Template is In-appropriate");
        }
      } else if (excelData.length < 2 || !selectedFile?.name) {
        props.MyAlert.info("please upload the file");
      }
    }
  };
  let leadDropdown = async (role, lead, projManager, type) => {
    setIsloading(true);
    let leadSet = true;
    let response;
    let leadRole = lead ? lead : leaduserRole;
    let selectedrole = role ? role : selectedPrivilege[0]?.value;
    if (leadRole === "ACCOUNT_MANAGER") {
      let value = {
        label: props?.accountManager?.name + "-" + props?.accountManager?.email,
        value: props?.accountManager?.email,
        ...props?.accountManager,
      };
      setSelectedReporter([{ ...value }]);
      setLeadData([{ ...value }]);
      setIsloading(false);
    } else if (
      userPrivilege.role === "TEST-LEAD" ||
      userPrivilege.role === leadRole.replace("_", "-")
    ) {
      let value = {
        label: logedinUser?.name + "-" + logedinUser?.userName,
        value: logedinUser?.userName,
        email: logedinUser?.userName,
        ...logedinUser,
      };
      setSelectedReporter([{ ...value }]);
      setLeadData([{ ...value }]);
      setIsloading(false);
      // resetProgress();
    } else {
      try {
        response = await getAllLeadUser(
          project.id,
          leadRole,
          logedinUser?.userName
        );
        if (response?.data?.length > 0) {
          setIsloading(false);
          // resetProgress();
          let data = [];
          response?.data.map((user) => {
            if (
              (props.mode === "Edit User" && props.userData?.lead) ||
              response?.data?.length === 1 ||
              leadRole === userPrivilege?.role ||
              leadRole?.replace("_", "-") === userPrivilege?.role
            ) {
              if (
                props.userData?.lead === user.email ||
                response?.data?.length === 1 ||
                (user.email === logedinUser?.userName &&
                  (leadRole === userPrivilege?.role ||
                    leadRole?.replace("_", "-") === userPrivilege?.role))
              ) {
                leadSet = false;
                setSelectedReporter([
                  {
                    label: user.name + "-" + user.email,
                    value: user.email,
                    ...user,
                  },
                ]);
              }
            }
            if (
              !(
                props.mode === "Edit User" &&
                props.userData.email === user.email
              )
            ) {
              data.push({
                label: user.name + "-" + user.email,
                value: user.email,
                ...user,
              });
            }
          });
          if (leadSet && data.length === 1) {
            setSelectedReporter([...data]);
          }
          if (
            Object.keys(props?.selectedTemplate[0].template).length > 5 &&
            (selectedPrivilege[0]?.value === "TESTER" ||
              selectedrole === "TESTER") &&
            data.length === 0
          ) {
            setHideReportingTo(true);
          } else if (hideReportingTo) {
            setHideReportingTo(false);
          }
          setLeadData([...data]);
        } else {
          if (type === "PROJECT-MANAGER") {
            props.MyAlert.info("Assigned PROJECT-MANAGER has no TEST-LEAD");
          }
          if (
            Object.keys(props?.selectedTemplate[0].template).length > 5 &&
            (selectedPrivilege[0]?.value === "TESTER" ||
              selectedrole === "TESTER")
          ) {
            setHideReportingTo(true);
          }
          setSelectedReporter([]);
          setIsloading(false);
          // resetProgress();
          setLeadData([]);
        }
      } catch (err) {
        setIsloading(false);
        // resetProgress();
        console.error(err);
      }
    }
  };
  let roleDropdownData = () => {
    let data = [];
    let leadData;
    let roleDropDownData = [...props.roleDropDownData];
    if (roleDropDownData.length > 0) {
      if (props.userCount && props.mode === "Edit User") {
        if (
          props.userCount[props.userData.role] === 1 &&
          props.userData.dependentsCount === 0
        ) {
          let tlIndex = roleDropDownData.indexOf(props.userData.role);
          roleDropDownData.splice(
            tlIndex + 1,
            roleDropDownData.length - tlIndex
          );
        } else if (
          props.userCount[props.userData.role] > 1 &&
          props.userData.dependentsCount === 0
        ) {
          let tlIndex = roleDropDownData.indexOf(props.userData.role) + 1;
          for (let i = tlIndex; i < roleDropDownData.length; i++) {
            if (props.userCount[roleDropDownData[i]] === 0) {
              roleDropDownData.splice(i + 1, roleDropDownData.length - i);
              break;
            }
          }
        }
      }
      roleDropDownData.map((role) => {
        console.log("roleDropDownData",role)
        if(role!=='TESTER'){
          data.push({ label: role, value: role });
        }else{
          data.push({ label: "TEST ENGINEER", value: role });
        }
        
      });
    } else {
      if (props.defaultRole !== "" && props.defaultRole) {
        if(props.defaultRole!=="TESTER"){
          data = [{ label: props.defaultRole, value: props.defaultRole }];
        }else{
          data = [{ label: "TEST ENGINEER", value: props.defaultRole }];
        }
       
      }
    }
    if (
      ["PROJECT-MANAGER", "TEST-LEAD", "TESTER"]?.includes(props?.defaultRole)
    ) {
      let selectedrole = props?.defaultRole;
      if (selectedrole?.includes("-")) {
        selectedrole = selectedrole.replace("-", "_");
      }
      let roles = Object.keys(props.selectedTemplate[0]?.template);

      if (
        ["PROJECT_MANAGER", "TEST_LEAD", "TESTER"]?.includes(selectedrole) &&
        roles.indexOf(selectedrole) - 1 > 0
      ) {
        setLeaduserRole(roles[roles.indexOf(selectedrole) - 1]);
        leadData = roles[roles.indexOf(selectedrole) - 1];
      }
      leadDropdown(props?.defaultRole, leadData);

      setDisplayReportingTo(true);
    }
    setRole([...data]);
  };
  const getProject = async () => {
    try {
      const response = await getProjectDetails(project.id);
      if (response.status === 200) {
        setSelectedProj({ ...response?.data });
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (props.roleDropDownData?.length > 0) {
      roleDropdownData();
    }
    if (props.defaultRole) {
      console.log("hemnjknjn",props.defaultRole)
      if (props.defaultRole !== selectedPrivilege[0]?.value) {
        setSelectedPrivilege([
          { label: props.defaultRole==="TESTER"?"TEST ENGINEER": props.defaultRole, value: props.defaultRole },
        ]);
      }
      formikDetails.setFieldValue("role", props.defaultRole);
    }
  }, [props.roleDropDownData]);
  useEffect(() => {
    if (props.mode === "Edit User") {
      const values = [...phoneNumberFields];
      values[0].value = props?.userData?.phoneNumbers?.primaryPhone;
      setPhoneNumberFields(values);
    } else {
      // setIsloading(true);
      resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 80,
      });
      defaultCountry()
        .then((res) => {
          // setIsloading(false);
          resetProgress();
          setPresentCountry(res.data.country);
        })
        .catch((err) => {
          // setIsloading(false);
          resetProgress();
        })
        .finally(() => {
          // setIsloading(false);
          resetProgress();
        });
    }
    getProject();
  }, []);
  useEffect(() => {
    gettemplateData();
  }, [props?.allTemplate]);
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "82%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      // borderRadius: "4px",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };
  function isEmailValid(email) {
    return EMAIL_PATTERN.test(email);
  }
  async function checkIfEmailExists() {
    setIsloading(true);
    // resetProgress();
    //   startOrResumeProgress({
    //     stopAt: 100,
    //     intervalDuration: 80,
    //   });
    try {
      const response = await getUserByEmailId(formikDetails.values.emailId);
      let isExists = false;
      if (response.data?.responseObject) {
        setIsloading(false);
        // resetProgress();
        let userData = response.data?.responseObject;
        formikDetails.setFieldValue("name", userData?.name);
        formikDetails.setFieldValue("phoneNumbers", userData["phone number"]);
        formikDetails.setFieldTouched("name", false, true);
        formikDetails.setFieldTouched("phoneNumbers", false, true);
        const values = [...phoneNumberFields];
        values[0].error = "";
        values[0].value = userData["phone number"];
        setPhoneNumberFields(values);
        isExists = true;
        setUserAlreadyExist(true);
        setEmailError(response.data?.message);
        setEditMode(true);
      } else {
        // const values = [...phoneNumberFields];
        // values[0].value = "";
        // console.log(phoneNumberFields);
        // setPhoneNumberFields([...values]);

        const values = [...phoneNumberFields];
        values[0].value = values[0].value?.substring(
          0,
          2
        );
        setPhoneNumberFields(values);

        formikDetails.setFieldValue("name", "");
        formikDetails.setFieldValue("phoneNumbers", "");
        setIsloading(false);
        // resetProgress();
        isExists = false;
        setUserAlreadyExist(false);
        setEmailError("");
        setEditMode(false);
        if (props.mode !== "Edit User") {
          setEditMode(false);
        }
      }
      return isExists;
    } catch (err) {
      setIsloading(false);
      // resetProgress();
      return true;
    }
  }
  function handleTrimmedBlur(event) {
  
    event.target.value = event.target.value.trim();
    formikDetails.handleChange(event);
    formikDetails.handleBlur(event);
  }
  function checkValidEmail() {
    if (formikDetails.values.emailId) {
      isEmailValid(formikDetails.values.emailId) && checkIfEmailExists();
    } else {
      // const values = [...phoneNumberFields];
      // values[0].value = "";
      // console.log(phoneNumberFields,values);
      // setPhoneNumberFields([...values]);
      const values = [...phoneNumberFields];
        values[0].value = values[0].value?.substring(
          0,
          2
        );
        setPhoneNumberFields(values);
      setEmailError("");
      formikDetails.setFieldValue("name", "");
      formikDetails.setFieldValue("phoneNumbers", "");
      if (props.mode !== "Edit User") {
        setEditMode(false);
      }
    }
  }
  function validatePhoneNumberField(phoneNumberIndex, event) {
    const values = [...phoneNumberFields];
    values[phoneNumberIndex].error = "";

    if (
      formikDetails.values.phoneNumbers?.length === 0 ||
      formikDetails.values?.phoneNumbers?.length <= 8
    ) {
      values[phoneNumberIndex].error = "Phone number is required";
    }
    if (
      isValidPhoneNumber(
        "+" + formikDetails.values.phoneNumbers?.toString()
      ) === false
    ) {
      formikDetails.setFieldError(
        "phoneNumbers",
        "Valid Phone number is required"
      );
      values[phoneNumberIndex].error = "Valid Phone number is required";
    }

    setPhoneNumberFields(values);
  }
  function resetPhoneNumberFieldError(phoneNumberIndex) {
    const values = [...phoneNumberFields];
    values[phoneNumberIndex].error = "";
    setPhoneNumberFields(values);
  }
  function handleTemplateChange(data) {
    let leadData;
    if (data.length > 0 && selectedPrivilege[0]?.value !== data[0]?.value) {
      if (
        ["PROJECT-MANAGER", "TEST-LEAD", "TESTER"]?.includes(data[0]?.value)
      ) {
        setSelectedReporter([]);
        let selectedrole = data[0]?.value;
        if (selectedrole?.includes("-")) {
          selectedrole = selectedrole.replace("-", "_");
        }
        let roles = Object.keys(props.selectedTemplate[0]?.template);

        if (
          ["PROJECT_MANAGER", "TEST_LEAD", "TESTER"]?.includes(selectedrole) &&
          roles.indexOf(selectedrole) - 1 > 0
        ) {
          setLeaduserRole(roles[roles.indexOf(selectedrole) - 1]);
          leadData = roles[roles.indexOf(selectedrole) - 1];
        }
        leadDropdown(data[0]?.value, leadData);
        setDisplayReportingTo(true);
      } else {
        setDisplayReportingTo(false);
      }
      formikDetails.setFieldValue("role", data[0]?.value);
      setSelectedPrivilege([...data]);
    } else if (data.length === 0) {
      setDisplayReportingTo(false);
      formikDetails.setFieldValue("role", "");
      setSelectedPrivilege([]);
    }
  }
  function handleReportingChange(data) {
    if (displayReportingTo && data.length) {
      setReportingToError(false);
    }
    setSelectedReporter([...data]);
  }
  function handlePhoneNumberChange(phoneNumberIndex, phoneNumber, countryData) {
    if (phoneNumber) {
      const values = [...phoneNumberFields];
      values[phoneNumberIndex].value = phoneNumber;
      values[phoneNumberIndex].countryData = countryData;

      setPhoneNumberFields(values);
      formikDetails.setFieldValue("phoneNumbers", values[0]?.value);
    }
  }
  function handelBulkupload() {
    if (bulkUpload) {
      setBulkUpload(false);
    } else {
      setBulkUpload(true);
    }
  }
  const downloadFiles = async (path) => {
    try {
      let user_template_path = process.env.REACT_APP_USER_TEMPLATE_DOWNLOAD_URL;
      const response = await downloadFileRequest(user_template_path);
      if (response.status === 200) {
        let requestURLArr = response?.request?.responseURL.split("/");
        // const url = window.URL.createObjectURL(
        //   new Blob([response.data]),
        // );
        const link = document.createElement("a");
        link.href = response?.request?.responseURL;
        // link.setAttribute(
        //   'download',
        //   requestURLArr[requestURLArr.length-1],
        // );
        link.download = requestURLArr[requestURLArr.length - 1];

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  function deleteFile() {
  
    setSelectedFile({});
  }
  const fileHandler = (e) => {
    let fileObj = e.target.files[0];
    let fileName = fileObj.name;
    
    if (fileName) {
      setSelectedFile(fileObj);
      setExcelData([]);
    }
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        if (resp?.rows.length > 1) {
          setExcelData([...resp?.rows]);
          formikDetails.setFieldError("emailId", "");
        } else {
          setExcelData([]);
        }
      }
    });
  };
  const handleUserTemplateChange = (data) => {

    
    if(data[0].templateName!==selectedTemplate[0].templateName){
      setWarningPopup(true);
      setTempselectedtemp([...data]);
    }
  };
  function onCloseWarningPopup(status) {
    if (warningPopup) {
      setWarningPopup(status);
    } else if (dltWarningPopup) {
      setSelectedFile({});
      setExcelData([]);
      setDltWarningPopup(status);
    }
  }

  const handelSelection = (data, dontSet) => {
    if (dontSet) {
      setselectedTemplate([{ ...selectedTemplate[0] }]);
    } else {
      setselectedTemplate([{ ...tempselectedtemp[0] }]);
      setDefaultTemplate({ ...tempselectedtemp[0] });
    }
  };

  const setDefaultTemplate = async (tempData) => {
    let data = { ...tempData };
    delete data.label;
    delete data.value;
    let proj = { ...selectedProj };
    const formData = new FormData();
    proj.usersHierarchyTemplateID = data.id;
    formData.append("project", JSON.stringify(proj));
    formData.append(
      "createdBy",
      JSON.parse(localStorage.getItem("fire-crowd-user")).userName
    );
    try {
      let response = await editProject(formData, "CUSTOMER");
      if (
        response.data.status === 200 ||
        response.data.successMessage === "Project details updated successfully"
      ) {
        props.MyAlert.success("User Hierarchy Template updated successfully");
        localStorage.setItem("selected-project", JSON.stringify(proj));
      }
    } catch (err) {
      console.error(err);
    }
  };
  function getRole(role){
    switch(role){
      case "PROJECT_OWNER":{
        return "Project Owner"
      }
      case "PROJECT_MANAGER":{
        return "Project Manager"
      }
      case "ACCOUNT_MANAGER":{
        return "Account Manager"
      }
      case "TEST_LEAD":{
        return "Test Lead"
      }
      case "TESTER":{
        return "Tester"
      }
      case "ADMIN":{
        return "Admin"
      }
      default:{
        return role;
      }
    }

  }

  const gettemplateData = async () => {
    let data = [];
    if (props?.allTemplate) {
      props?.allTemplate?.map((value) => {
        let userList = Object.keys(value?.template);
        userList.shift()
        let name = userList.join(">").replace("TESTER","TEST ENGINEER");
        console.log("name",name)
        // name.replace("TESTER","TEST ENGINEER")
        if (
          project?.usersHierarchyTemplateID &&
          value.id === project?.usersHierarchyTemplateID
        ) {
          setselectedTemplate([{ ...value, label: name }]);
        }
        data.push({ ...value, label: name, value: value.id });
      });
    }
    setTemplateData([...data]);
  };
  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex justify-between rounded-tl-xl create-modal-heading h-10 px-2 items-center">
        <div>{props.header}</div>
        <span className="flex items-center">
          {props.mode !== "Edit User" ? (
            <button
              onClick={() => {
                if (
                  props.mode !== "Edit User" &&
                  selectedPrivilege[0]?.value !== "ACCOUNT-MANAGER"
                ) {
                  handelBulkupload();
                }
              
              }}
              disabled={!props?.accountManager?.email}
              type="button"
              className={`w-40 h-7 mr-6 user-upload-btn ${
                !props?.accountManager?.email ? "opacity-50" : null
              }`}
              title={bulkUpload?"Switch to single user":"Bulk User Upload"}
            >
              {bulkUpload ? (
                "Switch to single user"
              ) : (
                <>
                  Bulk User Upload{" "}
                  <FileUploadOutlinedIcon
                    className={`cursor-pointer ${
                      props.mode === "Edit User" ||
                      selectedPrivilege[0]?.value === "ACCOUNT-MANAGER"
                        ? "opacity-50"
                        : null
                      }`}
                  />
                </>
              )}
            </button>
          ) : null}
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="modal-close-btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>
      <form onSubmit={formikDetails.handleSubmit}>
        <div
          className="user-main-container overflow-y-scroll"
          id="journal-scroll"
        >
          <div>
            <div className="px-2">
              <div>
                <div className="my-4">
                  <div
                    className={`mb-1 ${
                      props?.accountManager?.email || props.mode === "Edit User"
                        ? "opacity-50"
                        : null
                      }`}
                  >
                    <Label
                      label={
                        <span className="create-labels">
                          User Hierarchy
                        </span>
                      }
                      required={true}
                      error={
                        formikDetails.errors.role && formikDetails.touched.role
                      }
                    />
                  </div>
                  <div className="popins-medium" id="user_hierarchy_dropdown">
                    <SelectDropdown
                      data={templateData}
                      onChange={handleUserTemplateChange}
                      handelSelection={handelSelection}
                      disabled={
                        props?.accountManager?.email ||
                          props.mode === "Edit User"||projectStatus==="CLOSED"||projectStatus==="COMPLETED"
                          ? true
                          : false
                      }
                      userhierarchy={true}
                      selectedData={selectedTemplate}
                      searchable={false}
                    />
                  </div>
                </div>
                {!bulkUpload ? (
                  <div className="my-4">
                    <div className="mb-1">
                      <Label
                        label={
                          <span className="create-labels">
                            Email
                          </span>
                        }
                        required={true}
                        error={
                          formikDetails.errors.emailId &&
                          formikDetails.touched.emailId
                        }
                      />
                    </div>
                    <div className="popins-medium">
                      <div
                        className={cx(
                          "w-full user-email-input-tyle",
                          style["labeled-input"],
                          {
                            [style["number-input-control"]]:
                              props?.type === "number",
                          }
                        )}
                      >
                        <TextField
                          error={
                            formikDetails.errors.emailId &&
                            formikDetails.touched.emailId
                          }
                          fullWidth
                          hiddenLabel
                          disabled={props.mode === "Edit User"}
                          className={cx(
                            "text-xs open-sans-regular",
                            style["form-control"]
                          )}
                          placeholder={SIGNUP_LABELS.placeHolders.email}
                          value={formikDetails.values.emailId.trim()}
                          onBlur={(event) => {
                            formikDetails.handleBlur(event);
                             handleTrimmedBlur(event);
                            checkValidEmail(event);
                            
                          }}
                          onChange={formikDetails.handleChange}
                          autoComplete="off"
                          name="emailId"
                          id="emailId"
                          onFocus={() => setEmailError("")}
                          InputProps={{
                            className: cx(
                              "!text-xs open-sans-regular boxedInput"
                            ),
                          }}
                          FormHelperTextProps={{
                            className: "open-sans-regular",
                          }}
                          onKeyDown={(evt) => ["Enter"].includes(evt.key) && evt.preventDefault()}
                        />
                      </div>
                    </div>
                    {formikDetails.errors.emailId &&
                      formikDetails.touched.emailId ? (
                      <div className="defect-error md:mt-1 ml-1">
                        {formikDetails.errors.emailId}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="my-4 flex items-center popins-medium bulk-upload-unit">
                    <div className="input-area w-8/12 mr-5 h-11 flex items-center justify-between px-4">
                      {selectedFile?.name ? (
                        <div className="w-full flex items-center justify-between">
                          <span>{selectedFile?.name}</span>
                          <span
                            onClick={() => {
                              setDltWarningPopup(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.54"
                              height="18.647"
                              viewBox="0 0 15.54 18.647"
                            >
                              <path
                                id="Path_1452"
                                data-name="Path 1452"
                                d="M97.554,80.58a2.076,2.076,0,0,0,2.072,2.067h8.288a2.076,2.076,0,0,0,2.072-2.067V68.662H97.554ZM111.54,65.554h-3.885L106.354,64h-5.168l-1.3,1.554H96v1.554h15.54Z"
                                transform="translate(-96 -64)"
                                fill="#3c3838"
                              />
                            </svg>
                          </span>
                        </div>
                      ) : (
                        
                        <div className="w-full">
                          <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={fileHandler}
                           
                            disabled={
                              props.mode === "Edit User" ||
                              selectedPrivilege[0]?.value === "ACCOUNT-MANAGER"
                            }
                            id="contained-button-file"
                          />
                          <label htmlFor="contained-button-file">
                            <div className="w-full flex items-center justify-between" >
                              <span>upload File</span>
                              <FileUploadOutlinedIcon
                                className={`cursor-pointer ${
                                  props.mode === "Edit User" ||
                                  selectedPrivilege[0]?.value ===
                                    "ACCOUNT-MANAGER"
                                    ? "opacity-50"
                                    : null
                                  }`}
                              />
                            </div>
                          </label>
                          
                        </div>
                        
                        
                      )}
                    </div>
                    <div
                      onClick={() => {
                        downloadFiles(
                          "sample_user_hierarchy_template/Bulk User Upload with country code.xlsx"
                        );
                      }}
                      className="upload-template-area w-4/12 h-11 flex items-center cursor-pointer"
                    >
                      <div className="text-center w-full cursor-pointer">
                        Template for bulk users upload
                      </div>
                    </div>
                    
                  </div>
                
                )}
                <div className="my-4">
                  <div className="mb-1">
                    <Label
                      label={
                        <span className="create-labels">
                          Role
                        </span>
                      }
                      required={true}
                      error={
                        formikDetails.errors.role && formikDetails.touched.role
                      }
                    />
                  </div>
                  <div className="popins-medium" id="user_role_dropdown">
                    <SelectDropdown
                      data={role}
                      onChange={handleTemplateChange}
                      selectedData={selectedPrivilege}
                      searchable={false}
                      section="users"
                    />
                  </div>
                  {formikDetails.errors.role && formikDetails.touched.role ? (
                    <div className="defect-error md:mt-1 ml-1">
                      {formikDetails.errors.role}
                    </div>
                  ) : null}
                </div>
                {!bulkUpload ? (
                  <div className="my-4">
                    <div className="mb-1">
                      <Label
                        label={
                          <span className="create-labels">
                            Name
                          </span>
                        }
                        required={true}
                        error={
                          formikDetails.errors.name &&
                          formikDetails.touched.name
                        }
                      />
                    </div>
                    <div className="popins-medium">
                      <TextField
                        error={
                          formikDetails.errors.name &&
                          formikDetails.touched.name
                        }
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onClick={() => { }}
                        type="text"
                        name="name"
                        id="name"
                        inputProps={{
                          className: cx(
                            `!text-xs ${editMode?"cursor-not-allowed":""} open-sans-regular boxedInput popins-medium`
                          ),
                          style: {
                            padding: "9.5px 14px",
                            height: "0.43em !important",
                          },
                        }}
                        autoComplete="off"
                        placeholder="Enter your name"
                        disabled={editMode ? true : false}
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.name}
                      />
                    </div>
                    {formikDetails.errors.name &&
                      // !formikDetails.values.name &&
                      formikDetails.touched.name ? (
                      <div className="defect-error md:mt-1 ml-1">
                        {formikDetails.errors.name}
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {!bulkUpload ? (
                  <div className="my-4">
                    <div className="mb-1">
                      <Label
                        label={
                          <span className="create-labels">
                            Phone Number
                          </span>
                        }
                        required={true}
                        error={
                          formikDetails.errors.phoneNumbers &&
                          formikDetails.touched.phoneNumbers
                        }
                      />
                    </div>
                    <div>
                      <PhoneInput
                        type="text"
                        placeholder="Enter phone number"
                        value={phoneNumberFields[0]?.value || ""}
                        searchPlaceholder="Search..."
                        searchNotFound="No Countries Found"
                        specialLabel=""
                        id="phoneNumbers"
                        inputClass="user-phone-input-tyle popins-medium"
                        defaultCountry={
                          presentCountry && presentCountry.toLowerCase()
                        }
                        enableAreaCodeStretch
                        country={presentCountry && presentCountry.toLowerCase()}
                        enableSearch
                        international
                        disabled={editMode ? true : false}
                        name="phoneNumbers"
                        autoComplete="off"
                        onBlur={(e) => {
                          formikDetails.handleBlur(e);
                          validatePhoneNumberField(0, e);
                        }}
                        onKeyUp={() => resetPhoneNumberFieldError(0)}
                        onChange={(phoneNumber, country) =>
                          handlePhoneNumberChange(0, phoneNumber, country)
                        }
                        countryCodeEditable={false}
                      />
                      <div className="flex justify-between">
                        {phoneNumberFields[0]?.value &&
                          phoneNumberFields[0]?.value?.length
                            .toString()
                            .replace(/^\s+|\s+$/g, "") > 12 &&
                          !isValidPhoneNumber(phoneNumberFields[0].value) ? (
                          <div className="defect-error md:mt-1 ml-1">
                            Phone number is required (Valid)
                          </div>
                        ) : !!phoneNumberFields[0]?.error ||
                          ((formikDetails.touched.phoneNumbers ||
                            formikDetails.touched.phoneNumbers?.primaryPhone) &&
                            formikDetails.errors.phoneNumbers) ? (
                          <div className="defect-error md:mt-1- ml-1">
                            {phoneNumberFields[0]?.error ||
                              ((formikDetails.touched.phoneNumbers ||
                                formikDetails.touched.phoneNumbers) &&
                                formikDetails.errors.phoneNumbers)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                {displayReportingTo ? (
                  <div className="my-4">
                    <div className="mb-1">
                      <Label
                        label={
                          <span className="create-labels">
                            {/* Reporting To - {leaduserRole.replace("_", " ")} */}
                            Reporting To-{getRole(leaduserRole)}
                          </span>
                        }
                        required={true}
                      />
                    </div>
                    <div className="popins-medium" id="user_reporting_to">
                      <SelectDropdown
                        data={leadData}
                        onChange={handleReportingChange}
                        selectedData={selectedReporter}
                        searchable={true}
                        section="users"
                      />
                    </div>
                    {reportingToError ? (
                      <div className="defect-error md:mt-1 ml-1 ml-1">
                        Assign reporting to - {leaduserRole.replace("_", "-")}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="w-24 gray-btn ml-3 button"
          >
            Cancel
          </button>
          {!bulkUpload ? (
            <button
              type="submit"
              id="createBatch"
              onClick={() => {
                if (displayReportingTo && !selectedReporter.length) {
                  setReportingToError(true);
                }
              }}
              className="w-24 primary-btn button"
              disabled={isLoading===true?true:false}
            >
              {props?.mode === "Edit User" ? "Update" : "Create"}
            </button>
          ) : (
            <button
              type="button"
              id="createBatch"
              onClick={() => {
                if (bulkUpload) {
                  uploadbulkUser();
                }
              }}
              className="w-24 primary-btn button"
              disabled={isLoading===true?true:false}
            >
              {props?.mode === "Edit User" ? "Update" : "Create"}
            </button>
          )}
        </div>
      </form>
      {warningPopup && (
        <WarningModal
          executionMethod={handelSelection}
          onCloseWarningPopup={onCloseWarningPopup}
          data={tempselectedtemp}
          dropDownConfirm={true}
          body={"Are you sure you want to make this template as default?"}
          confirmation={false}
          rejectbtn={"No"}
          acpttbtn={"Yes"}
        />
      )}
      {dltWarningPopup && (
        <WarningModal
          executionMethod={deleteFile}
          onCloseWarningPopup={onCloseWarningPopup}
          data={""}
          status={"Delete"}
          dropDownConfirm={false}
          body={"Are you sure you want to delete this file?"}
          confirmation={false}
          rejectbtn={"Cancel"}
          acpttbtn={"Delete"}
        />
      )}
      {isLoading ? <CommonLoader /> : null}
      {showrejectedUser?(<RejecteduserPopup
                            />):null}
    </Modal>

  );
}

export default CreateUser;
