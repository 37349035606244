import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { TextareaAutosize, TextField } from "@mui/material";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useFormik } from "formik";
import * as yup from "yup";
import { Label } from "../../common/inputs/myInput";
import CloseIcon from "@material-ui/icons/Close";

import "../defect-field-forms.scss";

const DropDownForm = (props) => {
  const [mandatoryTFValue, setMandatoryTFValue] = useState(
    props.editFieldValue?.type === "dropdown"
      ? props.editFieldValue.mandatory
      : "no"
  );
  const [ddOptions, setDDOptions] = useState(
    props.editFieldValue?.type === "dropdown"
      ? props.editFieldValue.options
      : [""]
  );
  const [dropdownFormCounter, setDropdownFormCounter] = useState(
    props.editFieldValue?.type === "dropdown"
      ? props.editFieldValue.options.length
      : 1
  );
  const [
    dropDownFieldOptionDuplicateArray,
    setDropDownFieldOptionDuplicateArray,
  ] = useState([]);
  const [
    dropDownFieldOptionUniqueValidate,
    setDropDownFieldOptionUniqueValidate,
  ] = useState("");
  const [
    dropDownFieldOptionAlphaNumericValidate,
    setDropDownFieldOptionAlphaNumericValidate,
  ] = useState("");
  const [dropDownFieldValidate, setDropDownFieldValidate] = useState("");
  const [dropDownFieldOptionValidate, setDropDownFieldOptionValidate] =
    useState("");
  const [checkForAlphanumeric, setCheckForAlphanumeric] = useState("");
  const [ddOption, setDDOption] = useState("");
  let initialValues = {
    label:
      props.editFieldValue?.type === "dropdown"
        ? props.editFieldValue.label
        : "",
  };
  const validationSchema = yup.object({
    label: yup.string().required("Label is required"),
  });
  const formikDetails = useFormik({
    initialValues,

    validationSchema,
  });

  const handleMandatoryTFChange = (e) => {
    setMandatoryTFValue(e.target.value);
  };
  const checkDuplicateOptions = (a) => {
    let b = Array.from(new Set(a.map((e) => e.toLowerCase().trim())));
    return a.length !== b.length;
  };
  const indexOfAll = (arr, val) =>
    arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);
  const getDuplicateOptions = (a) => {
    let tempArray = a.map((v) => v.toLowerCase());
    let b = findDuplicates(tempArray);
    let c = [];
    b.map((d) => {
      c.push([...indexOfAll(tempArray, d)]);
    });
    return c;
  };
  let pattern = /^(?! ).*[^ ]$/;
  let alphaNumericPattern =
    /^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/;
  function findDuplicates(arr) {
    const filtered = arr.filter((item, index) => arr.indexOf(item) !== index);
    return [...new Set(filtered)];
  }
  const haveSameData = function (obj1, obj2) {
    const obj1Length = Object?.keys(obj1)?.length;
    const obj2Length = Object?.keys(obj2)?.length;
    if (obj1Length === obj2Length) {
      return Object?.keys(obj1)?.every(
        (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
      );
    }
    return false;
  };

  const handleAddDropdownOption = () => {
    if (!ddOptions.map((x) => alphaNumericPattern.test(x)).includes(false)) {
      if (!checkDuplicateOptions(ddOptions)) {
        setDDOptions((ddOptions) => [...ddOptions, ddOption]);
        setDDOption("");
        setDropdownFormCounter(dropdownFormCounter + 1);
      } else {
        setDropDownFieldOptionDuplicateArray(getDuplicateOptions(ddOptions));
        setDropDownFieldOptionUniqueValidate("Options should be unique");
      }
    } else {
      setDropDownFieldOptionAlphaNumericValidate(
        "Dropdown options should be alphanumeric"
      );
    }
  };

  const addDDOptions = (e, index, type) => {
    setDropDownFieldOptionValidate("");
    setDropDownFieldOptionUniqueValidate("");
    setDropDownFieldOptionAlphaNumericValidate("");
    setDropDownFieldOptionDuplicateArray([]);
    const ddOptionsCopy = [...ddOptions];
    ddOptionsCopy.forEach((val, i) => {
      if (index === i) {
        type === "input"
          ? (ddOptionsCopy[i] = e.target.value)
          : (ddOptionsCopy[i] = e.target.value.trim());
      }
    });
    setDDOptions(ddOptionsCopy);
  };

  const deleteDDOptions = (e, index) => {
    setDropDownFieldOptionValidate("");
    setDropDownFieldOptionUniqueValidate("");
    setDropDownFieldOptionAlphaNumericValidate("");
    setDropDownFieldOptionDuplicateArray([]);
    const ddOptionsCopy = [...ddOptions];
    index !== -1 && ddOptionsCopy.splice(index, 1);
    setDDOptions(ddOptionsCopy);
  };
  const handelDropdownFormSubmit = (e) => {
    formikDetails.handleSubmit();
    if (!pattern.test(formikDetails.values.label) && ddOptions.includes("")) {
      setDropDownFieldValidate("Label is required");
      setDropDownFieldOptionValidate("Option is required");
    }
    if (!pattern.test(formikDetails.values.label)) {
      setDropDownFieldValidate("Label is required");
    } else if (!checkDuplicateOptions(ddOptions)) {
      const dropdownFormData = {
        label: formikDetails.values.label,
        options: ddOptions,
        mandatory: mandatoryTFValue,
        type: "dropdown",
        order: props.editFieldValue["order"],
      };
      e.preventDefault();

      if (ddOptions.includes("")) {
        setDropDownFieldOptionValidate("Option is required");
        return;
      } else if (!alphaNumericPattern.test(formikDetails.values.label)) {
        setCheckForAlphanumeric("Label should be alphanumeric");
      } else if (
        ddOptions.map((x) => alphaNumericPattern.test(x)).includes(false)
      ) {
        setDropDownFieldOptionAlphaNumericValidate(
          "Dropdown options should be alphanumeric"
        );
        return;
      } else if (haveSameData(props.editFieldValue, dropdownFormData)) {
        props.MyAlert.info(
          `No changes were done to the ${dropdownFormData.label} field`
        );
        return;
      } else if (pattern.test(formikDetails.values.label)) {
        setDropDownFieldOptionValidate("");
        setDropDownFieldValidate("");
        setDropDownFieldOptionUniqueValidate("");
        setDropDownFieldOptionAlphaNumericValidate("");
        setDropDownFieldOptionDuplicateArray([]);
        props.addFormField(dropdownFormData);
        props.setAddField(false);
      }
      if ([dropdownFormData.label].includes("")) {
        formikDetails.handleSubmit();
        props.setAddField(true);
      } else {
        props.setAddField(false);
      }
    } else {
      setDropDownFieldOptionDuplicateArray(getDuplicateOptions(ddOptions));
      setDropDownFieldOptionUniqueValidate("Options should be unique");
    }
  };
  const onBlurDropdownOption = () => {
    if (!ddOptions.map((x) => alphaNumericPattern.test(x)).includes(false)) {
      if (checkDuplicateOptions(ddOptions)) {
        setDropDownFieldOptionDuplicateArray(getDuplicateOptions(ddOptions));
        setDropDownFieldOptionUniqueValidate("Options should be unique");
        
      } 
    } else {
      setDropDownFieldOptionAlphaNumericValidate(
        "Dropdown options should be alphanumeric"
      );
    }
  };
  return (
    <div className="form-content_radioForms overflow-y-scroll ">
      <div className="my-5  px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Label
              </span>
            }
            required={true}
            error={formikDetails.errors.label && formikDetails.touched.label}
          />
        </div>
        <TextField
          error={formikDetails.errors.label && formikDetails.touched.label}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          name="label"
          id="label"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={formikDetails.handleBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.label}
        />
        {formikDetails.errors.label && formikDetails.touched.label ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.label}
          </div>
        ) : null}
      </div>
      <div className="my-5 px-2">
        <div className="grid grid-cols-1">
          <div className="flex justify-between">
            <div className="">
              {ddOptions.map((c, index) => {
                return (
                  <div className=" mb-7 user-input-container  relative">
                    <div className="mb-1">
                      <Label
                        label={
                          <span className="textFieldHeader text-sm  popins-medium  ">
                            Option {index + 1}
                          </span>
                        }
                        required={true}
                      />
                    </div>
                    <TextField
                      // InputLabelProps={{
                      //     shrink: true,
                      // }}
                      // label={
                      //     <>
                      //         <span className="text-red-400 mr-1">&#42;</span>{" "} Option {index + 1}
                      //     </>
                      // }
                      key={index}
                      size="small"
                      className={"text-gray-700 w-96 open-sans-regular"}
                      type="text"
                      autoComplete="off"
                      placeholder="Type here"
                      name="dropdownFormOptionField"
                      id="dropdownFormOptionField"
                      onBlur={(e) => {
                        addDDOptions(e, index, "blur");
                        onBlurDropdownOption(e)
                      }}
                      onInput={(e) => {
                        addDDOptions(e, index, "input");
                      }}
                      value={ddOptions[index]}
                    />
                    {ddOptions.length > 1 && (
                      <button
                        type="button"
                        onClick={(e) => {
                          deleteDDOptions(e, index);
                        }}
                      >
                        <CloseIcon className="iconStyle cursor-pointer mx-2 mt-3 text-gray-500" />
                      </button>
                    )}
                    {index + 1 === ddOptions.length && (
                      <button
                        disabled={!ddOptions[ddOptions.length - 1]}
                        type="button"
                        onClick={() => {
                          handleAddDropdownOption();
                        }}
                      >
                        <AddCircleOutlineIcon
                          className={
                            !ddOptions[ddOptions.length - 1]
                              ? "opacity-50 mt-2"
                              : "opacity-100 mt-2"
                          }
                        />
                      </button>
                    )}
                    {dropDownFieldOptionValidate && !ddOptions[index] ? (
                      <p
                        className="errorMessage absolute"
                        style={{ marginTop: "8px" }}
                      >
                        {dropDownFieldOptionValidate}
                      </p>
                    ) : dropDownFieldOptionUniqueValidate &&
                      dropDownFieldOptionDuplicateArray[0]?.includes(index) ? (
                      <p
                        className="errorMessage absolute"
                        style={{ marginTop: "1px" }}
                      >
                        {dropDownFieldOptionUniqueValidate}
                      </p>
                    ) : dropDownFieldOptionAlphaNumericValidate &&
                      !alphaNumericPattern.test(ddOptions[index]) ? (
                      <p
                        className="errorMessage absolute"
                        style={{ marginTop: "1px" }}
                      >
                        {dropDownFieldOptionAlphaNumericValidate}
                      </p>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="mb-4 my-2 px-2 ml-1.5">
        <Label
          label={
            <span className="textFieldHeader text-sm  popins-medium  ">
              Mandatory
            </span>
          }
          required={true}
        />
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              value={mandatoryTFValue}
              onChange={handleMandatoryTFChange}
              className="textFieldHeader text-sm  popins-medium  "
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
        <button
          onClick={() => {
            props.setAddField(false);
            props.clearEditValue({})
          }}
          type="button"
          className="w-24 gray-btn ml-3 button"
        >
          Cancel
        </button>
        <button
          type="submit"
          id="createBatch"
          className="w-24 primary-btn button"
          onClick={(e) => {
            handelDropdownFormSubmit(e);
          }}
        >
          {props.editFieldValue?.type === "dropdown" ? "Update" : "Add"}
        </button>
      </div>
    </div>
  );
};

export default DropDownForm;
