import React,{useState,useEffect} from "react";
// import LogotextImage from "../../assets/fire_crowd.svg";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import BackgroundDown from "../../assets/login_logo.png";
import style from "./signup.module.scss";
import cx from "classnames";
import warningIcon from "../../assets/warning.svg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {resetActivationLink} from "../../api/api-services"
import Alert from "@material-ui/lab/Alert";

const ACTIVATION_LINK_LEFT_HEADING="Activate Quickly";
const ACTIVATION_LINK_LEFT_MESSAGE_TEXT_ONE="Account not activated? Don't worry, just follow the activation";
const ACTIVATION_LINK_LEFT_MESSAGE_TEXT_TWO="process and access your account quickly";

const ACTIVATION_LINK_RIGHT_HEADING="Warning Activation";
const ACTIVATION_LINK_RIGHT_MESSAGE_TEXT_ONE="Your account is not yet activated,click on the activation link which has been sent";
const ACTIVATION_LINK_RIGHT_MESSAGE_TEXT_TWO="to your registered Email ID"

function ActivationLink(){
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState({ open:false, message: "" });
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  useEffect(() => {
    const search = window.location.search;
    const email = new URLSearchParams(search).get("email");
    setEmail(email);
    
  }, []);
  function resentActivation(values) {
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);
      const data = {
        emailId: email,
        source: "FireCrowd"
      };
      resetActivationLink(data)
        .then((res) => {
          if (res.data && res.data.responseObject) {
            setTimeout(() => {
              setCreateUpdateCalled(true);
            }, 10000);
            setSuccess({ open: true });
          }

          setTimeout(() => {
            setSuccess({ open: false });
          }, 5000);
        })
        .catch((error) => {
          console.error("resend activation link error", error);
        });
    }
  }
    return(
       <div className="grid grid-cols-12 min-h-screen ">
        <div  className={cx(style["warning_activation_link"],"col-span-6 border-r-4")}>
          <div className={cx(style["warning_activation_link_leftImg"],"grid grid-cols-span-12 col-span-6 flex justify-center")}
          id="leftImage">
            <div className={cx(style["leftImg"])}>
            <img
             className={cx(style["logo_icon"])}
              src={LogotextImage}
              height="200px"
              width="300px"
              alt="Qspider"
            />
            </div>
          </div>
          <div className="grid grid-cols-span-12 col-span-6 flex justify-center mt-5">
          <div>
            <img
                height="350px"
                width="350px"
              src={BackgroundDown}
              alt="Firecrowd"
            />
          </div>

          </div>
          <div className={cx(style["heading"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-5")}>
           <div>{ACTIVATION_LINK_LEFT_HEADING}</div>
          </div>
          <div className={cx(style["msg"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-2")}>
           <span>{ACTIVATION_LINK_LEFT_MESSAGE_TEXT_ONE}</span>
          </div>
          <div className={cx(style["msg"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-1")}>
           <span>{ACTIVATION_LINK_LEFT_MESSAGE_TEXT_TWO}</span>
          </div>

          
            
        </div>
        <div className={cx(style["warning_activation_page"],"col-span-6 pt-28")}>
        <div className={cx(style["logoimg"])}>
        <img
          src={LogotextImage}
          height="200px"
          width="300px"
          alt="Qspider"
        />
        </div>
        <div className={cx(style["activation_text"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-4")}>
        {success.open && (
            <Alert
             className={cx(style["warningAlert"])}
              iconMapping={{ success: <CheckCircleIcon fontSize="inherit" className={cx(style["alertIconActivationLink"])} /> }}
            >
              <span>
                Activation link sent successfully
              </span>
            </Alert>
          )}{" "}
        </div>
            <div className={cx(style["warningIcon"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-8")}>
                <div>
                <img alt="warning" src={warningIcon} />
                </div>
            </div>
            <div className={cx(style["heading"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-4")}>
                <div>{ACTIVATION_LINK_RIGHT_HEADING}</div>
            </div>
            <div className={cx(style["msg_one"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-2")}>
            <span>{ACTIVATION_LINK_RIGHT_MESSAGE_TEXT_ONE}</span>
            </div>
            <div className={cx(style["msg_two"],"grid grid-cols-span-12 col-span-6 flex justify-center")}>
                <span>{ACTIVATION_LINK_RIGHT_MESSAGE_TEXT_TWO}</span>
            </div>
            <div className={cx(style["activation_link_text"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-4")}  onClick={resentActivation}
              onBlur={() => {
                createUpdateCalled(true);
              }}>
            <span>Resend Activation Link</span>
            </div>
            <div className={cx(style["Back_link_text"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-4")}>
            <span>
            <a href="/">
                Back to sign in
              </a>
              </span>
            </div>
        </div>

       </div>
    )
}
export default ActivationLink;