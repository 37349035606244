import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CloseIcon from '@mui/icons-material/Close';
import ModulechartExpand from "../charts/modulechart-expand";
import TestcaseExpandModuleTable from "../tc_expand_table";
//  import ExecutionReportsTable from "../execution-reports-table;
import {getAllTestcaseReq,getTotalTestcaseCount} from "../../../api/api-services";
import "./reports.scss"


function TestcaseExpandModule(props) {
    console.log("props",props)
  const [openModal, setOpenModal] = useState(true);
  const[pageNo,setpageNo]=useState(0);
  const[lastpage,setlastPage]=useState(false);
  let pageSize=10;
  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  let [modulePayload,setModulePayload]=useState({payload:[]});
  let [moduleData,setModuleData]=useState([]);
  let [moduleCount,setModuleCount]=useState(0)

  useEffect(()=>{
    getModuleData(props?.labels,0)

  },[])

  function getTestcaseCount(payload){
    getTotalTestcaseCount(payload,projectId, licenseID).then((response)=>{
      console.log("getTestcaseCount",response)
      if(response.status===200){
       
          setModuleCount(response.data)
        
      }

    }).catch((error)=>{
      console.log("error")
    })
  }

  const getModuleData=async(filter,page)=>{
    let payload={
      modules:filter,
      location: [],
      users: [],
      executionResult: [],

    }
    getAllTestcaseReq(payload, pageNo, pageSize, projectId, licenseID).then((response)=>{
      
     modulePayload.payload=filter
      if(response?.status===200 && response?.data?.responseObject){
        if(page===0){
          setModuleData(response?.data?.responseObject?.testcaseLogs)
        }else{
          let data=moduleData.concat(response?.data?.responseObject?.testcaseLogs)
          setModuleData(data)
        }
       
        setlastPage(response?.data?.responseObject?.lastPage)
        
        if(response?.data?.responseObject?.lastPage===false){
          setpageNo(pageNo+1)
        }
        if(page===0){
          getTestcaseCount(payload,"testcase-module")
        }
        console.log("response", response);
      }
     

    }).catch((error)=>{
      console.log("error")
    })

  }

  function getNextPageData(){
    if(lastpage===false){
      console.log("getNextPageData")
      getModuleData(modulePayload.payload,pageNo)
    }
  }

  function selectedChartlabelData(label,value){
    console.log("label",label)
    console.log("selectedChartlabelData",value)
 
    let filterData;
        let executionResultEle = document.getElementById(label + 0);
        executionResultEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        setpageNo(0);
        setlastPage(false);
        if(modulePayload.payload.length===1 && modulePayload.payload[0]===value[0]){
          filterData=props?.labels
        }else{
          filterData=value;
        }
        getModuleData(filterData,0)
  }

 

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "49%",
      left: "56%",
      transform: "translate(-50%, -50%)",
      width: "69%",
      height: "95%",
      background: "#fff 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderRadius: "12px",
     
    },
  };

 
 

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
        <div className="p-2 expand_module_container">
            <div className="grid grid-cols-12 flex expand_module_header">
                <div className="col-span-11 header_text">Execution By Result</div>
                <div className="col-span-1 flex justify-end mr-1"><CloseIcon onClick={()=>{props?.closeModal(false)}}/></div>
            </div>
            <div style={{height:"250px"}} className="flex justify-center">
            <ModulechartExpand
            series={props?.series}
            labels={props?.labels}
            selectedChartlabelData={selectedChartlabelData}
            header={"testcase_module"}
            />
            </div>
            <div>
               
                <TestcaseExpandModuleTable
                 header={"Total Test Cases"}
                 data={moduleData}
                 label={"testcase_module"}
                 selectAPI={getNextPageData}
                 TotalCount={moduleCount}
                />
               

            </div>
        </div>
     
    </Modal>
  );
}
export default TestcaseExpandModule;
