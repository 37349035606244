import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CloseIcon from '@mui/icons-material/Close';
import {getDashboadPieChartData} from "../../api/api-services";
import DashboardDonutChart from "./Dashboard-donut-chart";
import "./Dashboard.scss"

function DashboardPirchartExpandModal(props){
    console.log("props",props);
    let [chartdata,setChartdata]=useState({series:[],label:[]});
    useEffect(()=>{
    getPieChartData(props?.label); 
    },[props]);
    const getPieChartData=(label)=>{
        getDashboadPieChartData(label).then((response)=>{
          console.log("getPieChartData",response);
          if(response?.status===200){
            let temp_Browser={series:[],label:[]};
            let temp_OS={series:[],label:[]} 
            if(props?.header==="Browser"){
                response?.data?.browserPieChart.map((item,index)=>{
                    
                      temp_Browser.series.push(item.userCount);
                      temp_Browser.label.push(item.name)
                   
                   
                  });
                  setChartdata({series:temp_Browser.series,label:temp_Browser.label})
            }else{
                response?.data?.osPieChart.map((item,index)=>{
                   
                      temp_OS.series.push(item.userCount);
                      temp_OS.label.push(item.name)
                 
                   
                  });
                  setChartdata({series:temp_OS.series,label:temp_OS.label})
            }
           
            
           
            
    
          }
    
        }).catch((error)=>{
          console.log("error")
        })
      }
    const [openModal, setOpenModal] = useState(true);
    const customStylesPage = {
        overlay: {
          background: "rgba(90, 90, 90,0.5)",
        },
        content: {
          position: "fixed",
          top: "49%",
          left: "56%",
          transform: "translate(-50%, -50%)",
          width: "69%",
          height: "95%",
          background: "#fff 0% 0% no-repeat padding-box",
          boxShadow: "0px 0px 11px #000000a1",
          opacity: "1",
          outline: "none",
          borderRadius: "12px",
         
        },
      };

      return(
        <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
            <div className="grid grid-cols-12 Dashboard-expand-modal">
                <div className="col-span-12 grid grid-cols-12 Dashboard-expand-header">
                    <div className="col-span-10 header_label">{props?.header}</div>
                    <div className="col-span-2 flex justify-end cursor-pointer" onClick={()=>{props?.closeModal(false)}}><CloseIcon/></div>
                </div>
                <div className="col-span-12 mt-20">
                    <div className="flex justify-center">
                    <DashboardDonutChart  
                    labels={chartdata.label}
                    series={chartdata.series}/>
                    </div>
                   
                </div>
            </div>
        </Modal>
      )

}
export default DashboardPirchartExpandModal