import React, { useState, useEffect } from "react";
import "./client-list.scss";
import { ReactComponent as Opera } from "../../assets/platform/opera.svg";
import { ReactComponent as AndroidBrowser } from "../../assets/platform/android-browser.svg";
import { ReactComponent as FirefoxIcon } from "../../assets/platform/firefox.svg";
import { ReactComponent as MicrosoftEdge } from "../../assets/platform/microsoft edge.svg";
import { ReactComponent as ChromeIcon } from "../../assets/platform/chrome.svg";
import linux from "../../assets/platform/linux.svg";
import { ReactComponent as Windows } from "../../assets/platform/windows.svg";
import ubantu from "../../assets/ubantu-icon.svg";
import macIcon from "../../assets/mac-icon.svg";
import Carousel, { consts } from "react-elastic-carousel";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { ReactComponent as LeftArrow } from "../../assets/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../assets/right-arrow.svg";
import { Tooltip } from "@mui/material";
const AllWebVersions = (props) => {
  let projectDetails = JSON.parse(localStorage["selected-project"]);
  let typeOfProject=projectDetails?.project?.projectType?.others?.typeOfProject;
  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <div className={isEdge ? "invisible " : "visible customizedLeftArrow"}>
          <LeftArrow height="22px" width="22px" />
        </div>
      ) : (
        <div
          className={isEdge ? "invisible " : "visible customizedRightArrow "}
        >
          {" "}
          <RightArrow height="22px" width="22px" />
        </div>
      );
    return (
      <button onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  }
  function selectedOs(ostype) {
    let customOsType = ostype?.toLowerCase();
    switch (customOsType) {
      case "windows":
        return <Windows />;

      case "mac":
        // return <MacIcon className="-ml-5" height="46px" />;
        return (
          <img src={macIcon} style={{ height: "35px", marginLeft: "-5px" }} />
        );
      case "ios":
        return (
          <img src={macIcon} style={{ height: "35px", marginLeft: "-5px" }} />
        );

      case "linux":
        return <img src={linux} style={{ height: "27px" }} />;
      case "gnu/linux":
        return <img src={linux} style={{ height: "27px" }} />;

      case "ubuntu":
        return (
          <img src={ubantu} style={{ height: "28px", marginLeft: "1px" }} />
        );

      default:
        return <div className="datatrimmer w-18">{customOsType}</div>;
    }
  }

  function BrowserVersionGradients(browserName) {
    let alterBrowserName = browserName?.toLowerCase();
    switch (alterBrowserName) {
      case "windows":
        return "color-palette__item_windows";
      case "mac":
        return "color-palette__item_mac";
      case "ios":
        return "color-palette__item_mac";
      case "linux":
        return "color-palette__item_linux";
      case "ubuntu":
        return "color-palette__item_ubuntu";
      case "gnu/linux":
        return "color-palette__item_linux";

      default:
        return "color-palette__item_windows";
    }
  }
  function selectedBgColor(browserName) {
    let alterBrowserName = browserName?.toLowerCase();
    switch (alterBrowserName) {
      case "windows":
        return "bg-customColorsClients-sky_100";
      case "mac2":
        return "bg-customColorsClients-mac_100";
      case "mac1":
        return "bg-customColorsClients-mac_100";
      case "ios":
        return "bg-customColorsClients-mac_100";
      case "mac":
        return "bg-customColorsClients-mac_100";
      case "ubuntu":
        return "bg-customColorsClients-red_100";
      case "linux":
        return "bg-amber-400";

      case "gnu/linux":
        return "bg-customColorsClients-browsesamsung_100";

      default:
        return "bg-customColorsClients-mac_100";
    }
  }
  const [selectedRow, setSelectedRow] = useState();
  const [bgColor, setBgColor] = useState("");

  const handleBackgroundChange = (osData) => {
    let alterBrowserName = osData?.toLowerCase();
    if (alterBrowserName === "windows") {
      setBgColor("#CBE8FF");
    } else if (alterBrowserName === "ubuntu") {
      setBgColor("#F1B39E");
    } else if (alterBrowserName === "linux") {
      setBgColor("#F6E09D");
    } else if (alterBrowserName === "gnu/linux") {
      setBgColor("#C2DDFF");
    } else if (alterBrowserName === "mac") {
      setBgColor("#DDE6FB");
    } else if (alterBrowserName?.indexOf("mac") !== -1) {
      setBgColor("#DDE6FB");
    } else {
      setBgColor("#CBE8FF");
    }
  };

  const handleDocumentClick = (event) => {
    if (!event.target.closest(".tableComponent")) {
      setBgColor("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  const [activeIndex, setIsActiveIndex] = useState("");
  const handleColorChange = (index) => {
    setIsActiveIndex(index);
  };
  return (
    <>
      <div className="tableHead_wrapper">
        <table className=" text-left">
          <thead >
            <tr style={{background:"#F2F2F2",height:"30px"}}>
              <th className="w-1/6 labels text-xs">Defects</th>
              <th className=" w-1/6 labels text-xs">OS</th>
              <th className="text-xs labels" style={{ width: "80.3333%" }}>
                OS Version
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="tableBody_wrapper tableComponent">
        <table className=" text-left">
          <tbody className="mt-2">
            {props?.osInfo.map((osData, index) => {
              return (
                <>
                  <tr
                    style={{
                      backgroundColor:(projectDetails?.project?.projectType?.others===null &&  typeOfProject==="Standalone_Application")?"":
                        index === activeIndex ? `${bgColor}` : "",
                        border:"1px solid #E5E5E5",margin:"4px 0px"
                    }}
                    className={
                      osData?.osName?.toLowerCase() === "windows"
                        ? "hover:bg-customColorsClients-sky_100 cursor-pointer"
                        : osData?.osName?.toLowerCase() === "mac"
                        ? "hover:bg-customColorsClients-mac_100 cursor-pointer"
                        : osData?.osName?.toLowerCase() === "ubuntu"
                        ? "hover:bg-customColorsClients-red_100 cursor-pointer"
                        : osData?.osName?.toLowerCase() === "linux" ||
                          osData?.osName?.toLowerCase() === "gnu/linux"
                        ? "hover:bg-customColorsClients-linux_100 cursor-pointer"
                        : "hover:bg-customColorsClients-sky_100"
                    }
                    // className={`hover:${selectedBgColor(osData?.osName)} cursor-pointer`}
                    onClick={() => {
                      handleColorChange(index);
                      handleBackgroundChange(osData?.osName);
                    }}
                    key={`row-index-${index}`}
                  >
                    <td className="width_custom_defect_browser_os">
                      {osData?.defectCount}
                    </td>
                    <Tooltip
                      PopperProps={{
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            border: "1px solid #434DB8",
                            color: "deepskyblue",
                            background: " #FFFFFF 0% 0% no-repeat padding-box",
                            width: "118px",

                            height: "auto",
                            borderRadius: " 10px",
                            textAlign: "center",
                            opacity: 1,
                            boxShadow: "0px 3px 6px #0000005E",
                          },
                        },
                      }}
                      title={
                        <div className="block">
                          <span className="block  text-xs">
                            {" "}
                            {osData?.osName}:{osData?.defectCount}
                          </span>

                          <span className="block  text-xs ">
                            {osData.totalDefectPercent}%
                          </span>
                        </div>
                      }
                      placement="top"
                    >
                      <td
                        className={`w-1/6 `}
                        onClick={() => {
                          props?.selectOsBrowser(osData.totalOSBrowsers);
                          props?.selectedWebInfos(osData);
                        }}
                      >
                        {selectedOs(osData?.osName)}{" "}
                      </td>
                    </Tooltip>
                    <td
                      className="osVersionList flex "
                      style={{
                        width: "118%",
                        position: "relative",
                        left: " -39px",
                      }}
                    >
                      <Carousel
                        renderArrow={myArrow}
                        itemsToShow={3}
                        pagination={false}
                      >
                        {osData?.osVersionList?.map((version) => {
                          return (
                            <>
                              <Tooltip
                                PopperProps={{
                                  sx: {
                                    "& .MuiTooltip-tooltip": {
                                      border: "1px solid #434DB8",
                                      color: "deepskyblue",
                                      background:
                                        " #FFFFFF 0% 0% no-repeat padding-box",
                                      width: "118px",
                                      height: "auto",
                                      borderRadius: " 10px",
                                      opacity: 1,
                                      textAlign: "center",
                                      boxShadow: "0px 3px 6px #0000005E",
                                    },
                                  },
                                }}
                                title={
                                  <div className="text-xs">
                                    {version.osVersion}:{version?.defectCount}
                                    <br />
                                    <span>
                                      {props?.webInfo?.osName === osData?.osName
                                        ? version.osDefectPercent
                                        : version.totalDefectPercent}
                                      %
                                    </span>
                                  </div>
                                }
                                placement="top"
                              >
                                <div
                                  className={`${BrowserVersionGradients(
                                    osData?.osName
                                  )} cursor-pointer`}
                                  onClick={() => {
                                    props?.selectOsBrowser(version.browserList);
                                    props?.selectedWebInfos({
                                      ...version,
                                      osName: osData?.osName,
                                    });
                                  }}
                                >
                                  {version.osVersion?.substring(0, 6)}
                                </div>
                              </Tooltip>
                            </>
                          );
                        })}
                      </Carousel>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="totalBrowserDefect pt-2.5">
        Total Defects :{" "}
        <span className="mx-1">
          {props?.webClientReport?.totalBrowserDefects}
        </span>
      </div>
    </>
  );
};

export default AllWebVersions;
