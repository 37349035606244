import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import AttachFile from "@mui/icons-material/AttachFile";
import Close from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ReactComponent as ArrowIcon } from "../../assets/left-arrow-icon.svg";
import "./modal.scss";
import { ReactComponent as Attachments } from "../../assets/attachment_icon.svg";
import { ReactComponent as DownLoad } from "../../assets/download_icon.svg";
import PlatformDetails from "./platform-details";
import DefectDetails from "./defect-deatils";
import { getTemplateById } from "../../api/api-services";
import { CommonLoader } from "../common/common-loader";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer";
import TCUserDetailsTabel from "../reports/tcuser-details-tabel";
import { Tooltip } from "@material-ui/core";
import { FORM_FIELDS } from "../../pages/config/defectConstant";
import RichTextEditor from "../../pages/config/rich-texteditor";
import { getTestcaseExecutionStatus,
    getTestcaseExecutionStatusForOwner} from "../../api/api-services";
    import Grid from "@mui/material/Grid";
    import { Download } from "@mui/icons-material";
    import ColumnResizer from "react-table-column-resizer";

function TCReportDetails(props) {
    console.log("TCReportDetails",props)
    const { TEXTBOX, LINK, ATTACHMENT, TEXTEDITOR } = FORM_FIELDS;
    const [openModal, setOpenModal] = useState(true);
    const [defectTemplate, setDefectTemplate] = useState([]);
    const [header, setHeader] = useState();
    const [userName, setUserName] = useState();
    const [role, setRole] = useState();
    const [reason, setReason] = useState();
    const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
    const [isLoading, setIsLoading] = useState(false);
    let myrole = JSON.parse(localStorage["user-privilege"]).role;
    const [tsInitialRow, setTsInitialRow] = useState(3);
    const [TemplateDataTable, setTemplateDataTable] = useState( JSON.parse(JSON.stringify(props?.SelectedDefect)) )

   


   
   


    const customStylesPage = {
        overlay: {
            background: "rgba(90, 90, 90,0.5)",
        },
        content: {
            position: "fixed",
            top: "50%",
            left: "63%",
            transform: "translate(-50%, -50%)",
            width: "75%",
            height: "100%",
            background: "#fbfcfd 0% 0% no-repeat padding-box",
            boxShadow: "0px 0px 11px #000000a1",
            opacity: "1",
            outline: "none",
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
        },
    };
    
      
     
    
      const detailViewComponents = (data) => {
        let value;
    
        if (data?.type !== ATTACHMENT) {
          value =
            data?.value && Array.isArray(data.value)
              ? data.value.join(",")
              : data.value;
        } else if (data.type === LINK && value) {
          return (
            <>
              <a
                href={value}
                target="_blank"
                className="label-overflow tab-style-common"
              >
                {value}
              </a>
            </>
          );
        } else if (
          data.type === ATTACHMENT &&
          data?.value &&
          Array.isArray(data?.value)
        ) {
          return (
            <div>
              {data?.value?.length === 0 || data?.value === "" ? (
                "--"
              ) : (
                <>
                  {data?.value?.map((option, index) => (
                    <div key={index} className="flex my-2" title={option?.name}>
                      <div className="datatrimmer w-24 cursor-default	">
                        {option?.name || "--"}
                      </div>
                      <Download
                        color="primary"
                        className="h-6 w-6  -mt-2 mr-2 origin-center cursor-pointer"
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          );
        } else if (data.type === TEXTEDITOR) {
          return (
            <div className="mt-2 text-justify defect-texteditor">
              {value !== "" ? (
                <RichTextEditor
                  convertedContent={value || ""}
                  readOnly={true}
                  toolbarHidden={true}
                />
              ) : (
                "--"
              )}
            </div>
          );
        } else {
          ///
        }
    
     
       if(data.label==="Status"){
        return(
            <div className={value==="PASS"?"text-green-600":value==="FAIL"?"text-red-600":value==="FLAKY"?"text-yellow-500":"text-gray-600"} style={{width:"100%"}} title={value}>{value}</div>
        )
        
       }else{
        if(props.label==="Template"){
          if(data.label==="Module/Path"){
            return(
              <div title={value}>{props?.testcaseDetails?.module?.split(/[:]/)[1]}</div>
          )
          }else if(data.label==="Test Case Name"){
            return(
              <div title={props?.testCaseDetails?.testcaseName}>{props?.testcaseDetails?.testcaseName}</div>
          )
          }else{
            return(
              <div title={value}>{value?value:"--"}</div>
          )
          }

        }else{
          return(
            <div title={value}>{value?value:"--"}</div>
        )
        }
        
        
       }
        

      };
      const detailViewElements = (data) => {
      
        return (
          <>
            <div className="flex flex-col justify-center">
              <div className="flex">
                <div className="flex ">
                  <label>
                    {data ? (
                      <Tooltip title={data?.label} placement="top">
                        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap labels">
                          {data?.label.length > 32
                            ? `${data?.label.substring(0, 32)}...`.toUpperCase()
                            : data?.label.toUpperCase()}
                        </div>
                      </Tooltip>
                    ) : null}
                  </label>
                </div>
              </div>
              <div
                className={`contentDataStyle  values ${
                  data?.value?.length > 32 ? "datatrimmer w-34" : ""
                }`}
              >
                {detailViewComponents(data)}
              </div>
            </div>
          </>
        );
      };

    
    const testStepsTable = (view) => {
      return (
        <>
          <div className='mx-2.5 table_container '>
            <div className="mt-3 my-5">
              <div className="">
                <div className="flex justify-between h-16">
                  <div className="mx-1 my-5"><h3 className="project_label_TCM fontPoppinsSemiboldLg text-sm flex-auto">Test Steps</h3></div>
                  <div className="flex justify-evenly mx-4 my-5">
                 
                  </div>
                </div>
                <div className="w-auto" id="journal-scroll">
                  <table className="testStepTable" style={{ width: "100%" }}>
                    <thead
                      style={{
                        display: "table-header-group",
                        verticalAlign: "middle",
                        position: "sticky",
                        top: "-1px",
                        zIndex: "1",
                      }}
                      className="bg-gray-100"
                    >
                      <tr key={"tableHeader"}>
                        {TemplateDataTable.testSteps?.headers?.map((data, index) => {
                          if (!["actual result", "status"].includes(data?.toLowerCase())) {
                            return (
                              <th
                                className="p-2 testStepTable common-label-text fontPoppinsMediumMd text-blue-700"
                                key={data + index}
                              >
                                {data}{" "}
                              </th>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tr>
                    </thead>
                    <tbody style={{ height: "auto", overflowY: "auto", display: "table-row-group", verticalAlign: "middle" }}>
                      {Array.from(Array(tsInitialRow)).map((_, rowIndex) => (
                        <tr style={{ display: "table-row", verticalAlign: "inherit" }} className="h-9 testStepTable focus:outline-none" contentEditable={false} key={rowIndex}>
                          {TemplateDataTable.testSteps?.headers?.map((header, columnIndex) => {
                            if (!["actual result", "status"].includes(header?.toLowerCase())) {
                              return (
                                <td className="testStepTable" key={`${rowIndex}-${columnIndex}`}></td>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };
    
  


    return (
        <Modal
            isOpen={openModal}
            style={customStylesPage}
            className="p-px"
            onRequestClose={() => {
                // props.CloseDefectModal(false);
                // setOpenModal(false);
            }}
        >
            <div className="defect-details">
                <div className="grid grid-cols-12 flex header  rounded-tl-xl">
                        <div className="col-span-11 heading pl-2">TestCase Details</div>

                    <div className="col-span-1 flex justify-end mr-1.5">
                        <button
                            onClick={() => {
                                props.CloseTCReportModal(false);
                            }}
                            type="button"
                            className="modal_clos_btn mr-0.5"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="p-4 body">
               

                <div className="flex">
            <Grid
              container
              spacing={{ xs: 2, md: 3, sm: 2 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {props?.SelectedDefect?.testCaseDetails &&
                props?.SelectedDefect?.testCaseDetails.length > 0 &&
                props?.SelectedDefect?.testCaseDetails.map((data, index) => {
                  return [TEXTBOX, TEXTEDITOR].includes(data?.type) ? (
                    <Grid item xs={11} sm={11} md={11} key={index}>
                      {detailViewElements(data)}
                    </Grid>
                  ) : (
                    <Grid item xs={4} sm={4} md={12 / 2} key={index}>
                      {detailViewElements(data)}
                    </Grid>
                  );
                })}
              
              <Grid item xs={4} sm={4} md={12/2}>
                {detailViewElements({
                  label: "Status",
                  value: props?.testScriptStatus,
                })}
              </Grid>
            </Grid>
           
          </div>
                <div className="grid grid-cols-12 flex h-12 mt-4">
                  {
                    props.label==="Script"? (
                      <div className="col-span-12">
                      <TCUserDetailsTabel 
                      tabeldata={props?.SelectedDefect}
                      />
                      
                  </div>
                    ):(
                      <div className="col-span-12">
                     
                       {testStepsTable()}
                  </div>
                    )
                  }
                    
                </div>

                </div>

               
                <div className="grid grid-cols-12 col-span-12 user-details_footer  pt-2">
                    <div className="col-span-10"></div>
                    <div className="grid grid-cols-12 col-span-2 flex justify-end">
                        <div className="grid grid-cols-12 col-span-12 h-8 flex justify-end">
                            <span
                                className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer"
                                onClick={() => {
                                    props.CloseTCReportModal(false);
                                }}
                            >
                                <button>Close</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    );
}

export default TCReportDetails;