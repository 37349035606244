import React,{useState,Component} from "react"
import Modal from "react-modal";
import ReactPlayer from "react-player/lazy";
import CloseIcon from '@mui/icons-material/Close';

function VideoPlayer(props) {
    const [openModal, setOpenModal] = useState(true);
    const customStylesPage = {
      overlay: {
        background: "rgba(90, 90, 90,0.5)",
      },
      content: {
        position: "fixed",
        top: "50%",
        left: "57%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "90%",
        background:"rgb(60, 56, 56,0.5)",
        // boxShadow: "0px 0px 11px #000000a1",
        // borderRadius:"15px",
        opacity: "1",
        outline: "none",
      },
    };
    
  
    return (
      <Modal
        isOpen={openModal}
        style={customStylesPage}
        className="p-px"
        onRequestClose={() => {
          // props.CloseDefectModal(false);
          // setOpenModal(false);
        }}
      >
      <div>
        <div className="video_close_btn" onClick={props?.toggleModal}>
          <CloseIcon/>
        </div>
      <ReactPlayer
          url="https://www.youtube.com/watch?v=2ysz_DHNdg4"
          controls={true}
          width="100%"
          height="calc(100vh - 65px)"
        />
      </div>
      </Modal>
    );
   
  }
 
  
  export default VideoPlayer;                                