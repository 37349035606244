import { useState, useEffect } from "react";
import { Outlet, Routes, Route, useRoutes } from "react-router-dom";
import PageHeader from "./page-header";
import PageLeftMenu from "./page-left-menu";
import ProjectList from "../project/project-list";
import ProjectCreate from "../project/project-create";
import ProjectTestCases from "../project/project-testcases";
import ProjectDetails from "../project/project-details";
import ProjectDefects from "../project/project-defects";
import ProjectReport from "../reports/project-report";
import ProjectTesters from "../project/project-testers";
import Alerts from "../common/alert-service/alert";
import {useAlert} from "../common/alert-service/useAlert";
import UserTemplate from "../config/user-template";
import DefectDetailsTemplate from "../config/defect-details-template";
import ProgressContainer from "../common/inputs/ProgressContainer";
import PlatformDetails from "../config/platforms/platformDetails";
import TreeDemo from "../project/tree_demo";
import ManualTestcaseTemplate from "../config/testCaseTemplate/manualTestcaseTemplate";
import TestcaseTemplateDetails from "../config/testCaseTemplate/testcase-template-details";
import TestCaseDetails from "../testCase/testcase-details";
import TestCaseExecution from "../testCase/testcase-execution";
import ExecutionDetailsList from "../config/testCase/executionDetailsList";
import ImportManualTestCase from "../config/import_config/importManualTestCases";
import TestcaseRouter from "../testCase/testcase_router";
import TestCaseDetailsHome from "../testCase/testcase-details-home";
import FeedbackTemplate from "../config/feedback/feedback-template";
import ProjectReports from "../reports/project_reports";
import DashboardReports from "../Dashboard/Dashboard-reports";
import VideoTutorialsHome from "../Video Tutorials/videoTutorialsHome";
import NDAFromHome from "../NDA-Form/NDAForm-home";
import IntegrationHome from "../integrations/integration-home";
import IntegrationRouter from "../integrations/integration_router";
import JiraIntegrationHome from "../integrations/jira-integrationhome";

// user-Hierarchy-Template





function Home() {
  const [expandSideMenu, setExpandSideMenu] = useState(true);
  
  
  const { AlertContatiner, MyAlert } = useAlert();

  function leftMenuExpand(state){
    console.log("leftMenuExpand",state)
     setExpandSideMenu(state);
  }
  return (
    <div>
     <ProgressContainer>
      <AlertContatiner/>
       
      <div className="">
        <PageHeader MyAlert={MyAlert} expandSideMenu={expandSideMenu}/>
      </div>
      <div className="flex">
        <div >
          <PageLeftMenu  leftMenuExpand={leftMenuExpand}/>
        </div>
        <div className="w-full px-3 overflow-auto">
          <Routes>
            <Route path="/all-Projects" element={<ProjectList MyAlert={MyAlert}/>} />
            <Route path="/dashboard" element={<DashboardReports MyAlert={MyAlert}/>} />
            <Route path="/dashboard/project-Analytics" element={<ProjectReports/>} MyAlert={MyAlert}/>
            <Route path="/video-Tutorials" element={<VideoTutorialsHome/>}/>


            <Route path="/project/users" element={<ProjectTesters MyAlert={MyAlert}/>} />
            <Route path="/project/testcases/*" element={<TestcaseRouter MyAlert={MyAlert} />} >
              <Route index element={<ProjectTestCases MyAlert={MyAlert}/>} />
              <Route path="*" element={<ProjectTestCases MyAlert={MyAlert}/>} />
              <Route path="testCase-Details" element={<TestCaseDetailsHome  MyAlert={MyAlert}/>} />
              <Route path="testcaseExecution" element={<TestCaseExecution  MyAlert={MyAlert}/>} />
              <Route path="execution/details" element={<ExecutionDetailsList MyAlert={MyAlert}/>} />
            </Route>
            <Route path="/project/tree" element={<TreeDemo />} />
            <Route path="/project/overview" element={<ProjectDetails MyAlert={MyAlert}/>} />
            <Route path="/project/defects" element={<ProjectDefects MyAlert={MyAlert}/>} />
             {/* <Route path="/report" element={<ProjectReport />} MyAlert={MyAlert}/>  */}
            <Route path="/project/analytics" element={<ProjectReports/>} MyAlert={MyAlert}/>
            <Route path="/project/create" element={<ProjectCreate />} />
            <Route path="/project/configuration/user-Hierarchy-Template" element={<UserTemplate MyAlert={MyAlert}/>} />
            <Route path="/project/configuration/defect-Details-Template" element={<DefectDetailsTemplate MyAlert={MyAlert}/>} />
            <Route path="/project/configuration/platform-Details" element={<PlatformDetails MyAlert={MyAlert}/>} />
            <Route path="/project/configuration/manual-TestCase-Template" element={<ManualTestcaseTemplate MyAlert={MyAlert}/>} />
            <Route path="/project/configuration/manual-TestCase-Template/details" element={<TestcaseTemplateDetails MyAlert={MyAlert}/>} />
            <Route path="/project/configuration/import-Manual-TestCase" element={<ImportManualTestCase MyAlert={MyAlert}/>} />
            <Route path="/project/configuration/feedback-Template" element={<FeedbackTemplate MyAlert={MyAlert}/>} />
	     <Route path="/project/configuration/NDA-form" element={<NDAFromHome/>} />
            <Route path="/project/configuration/integration/*" element={<IntegrationRouter />} >
              <Route index element={<IntegrationHome MyAlert={MyAlert}/>} />
              <Route path="*" element={<IntegrationHome MyAlert={MyAlert}/>} />
              <Route path="JIRA" element={<JiraIntegrationHome  MyAlert={MyAlert}/>} />
             
            </Route>
            {/* <Route path="/project/configuration/integration" element={<IntegrationHome MyAlert={MyAlert}/>} /> */}
            
          </Routes>
        </div>
       
      </div>
      </ProgressContainer>
    </div>
  );
}

export default Home;
