import React, { useState, useEffect } from 'react';
import Select from 'react-dropdown-select';
import styled from "@emotion/styled";
import GitlabStyles from './dropdown-styles.module.scss'
import cx from 'classnames';
import { Checkbox, Tooltip } from '@mui/material';
import "../project.scss"

// css constants starts

const SearchAndToggle = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    z-index:1;
    top:0;
    background-color: #fbfcfd;

    input {
      margin: 6px 10px 6px;
      line-height: 30px;
      padding: 0 5px;
      border: 1px solid #ccc;
      border-radius: 3px;
      :focus {
        outline: none;
        border: 1px solid ${({ color }) => color};
      }
    }
  `;

const Item = styled.div`
display: flex;
margin: 10px;
align-items: baseline;
cursor: pointer;
border-bottom: 1px dotted transparent;

${({ disabled }) =>
    disabled
      ? `
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
`
      : ""}
`;

const ItemLabel = styled.div`
margin: 5px 10px;
`;

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "#F0F3F9" : "",
  }),
};

// css constants ends

const dropdownRenderer = ({ props, state, methods, },otherStates,otherMethoids,userhierarchy) => {
  const regexp = new RegExp(state.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "i");
  const options = props.options.filter((item) =>
    regexp.test(item[props.searchBy] || item[props.labelField])
  );
  return (
    <div>
      {
        options.length === 0 ?
          <div className="noData p-2">No options</div>
          :
          <div className="exe-env-options-dropdown">
            {
              options.map((option) => {
                return (
                  <div key={option[props.labelField]} title={option[props.labelField]} className={`${state.values[0]?.label === option[props.labelField] && userhierarchy ? "selected-hierarchy-style":""}`}>
                    <Item
                      disabled={option.disabled}
                      key={option[props.valueField]}
                      className=""
                      onClick={
                        option.disabled ? null : () => methods.addItem(option)
                      }
                    >
                      {/* <Tooltip title={option[props.labelField]} placement='top'> */}
                        <ItemLabel className='text-overflow-style'>{option[props.labelField]?.length>64?option[props.labelField].slice(0,61)+"...":option[props.labelField]}</ItemLabel>
                      {/* </Tooltip> */}
                    </Item>
                  </div>
                );
              })}
          </div>
      }
    </div>
  );
};

const customContentRenderer = ({ props, state, methods }) => {
  const getCountPill = (values) => {
    if (values?.length > 1) {
      return (<span className={GitlabStyles['count-pill-temp']} >
        {' '}+{values.length - 1} {' '} More {' '}
      </span >)
    } else {
      return null
    }
  }

  return (
    <div style={{ overflowWrap: "anywhere" }}>
      <span className='test-sm'>
        {
          state?.values?.length ?
            state.values[0].label
            :
            props.placeholder
        }
        {
          getCountPill(state?.values)
        }
      </span>
    </div>
  );
}

const contentRenderer = ({ props, state, methods }) => {

  return (
    <div>
      <span className='test-sm ' title={state?.values[0]?.label}>
        {
          state?.values?.length ?
            (
              state.values[0]?.label?.length > 64 ?
                <Tooltip title={state.values[0]?.label}>
                  <span>
                    {
                      state.values[0]?.label?.slice(0, 61) + '...'
                    }
                  </span>
                </Tooltip>
                :
                state.values[0].label
            )
            :
            props.placeholder
        }
      </span>
    </div>
  );
}

const customDropdownRenderer = ({ props, state, methods }) => {
  const regexp = new RegExp(state.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "i");
  const options = props.options.filter((item) =>
    regexp.test(item[props.searchBy] || item[props.labelField])
  );
  return (
    <div>
      <SearchAndToggle>
        <input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder='Search and select...'
        />
      </SearchAndToggle>
      {
        options.length === 0 ?
          <div className="noData pl-5 pt-5 pb-5">No options</div>
          :
          <div className="overflow-y-auto" id="journal-scroll">
            <div className="exe-env-options-dropdown">
              {
                options.map((option) => {
                  return (
                    <div key={option[props.labelField]} className="">
                      <Item
                        disabled={option.disabled}
                        key={option[props.valueField]}
                        className=""
                        onClick={
                          option.disabled ? null : () => methods.addItem(option)
                        }
                      >
                        <Tooltip title={option[props.labelField]} placement='top'>
                          <ItemLabel className='text-overflow-style'> <Checkbox size='small' color='primary' checked={state.values?.find(value => value.label === option.label)} /> {option[props.labelField]}</ItemLabel>
                        </Tooltip>
                      </Item>
                    </div>
                  );
                })}
            </div>
          </div>
      }
    </div>
  );
};
export const SelectDropdown = ({ data, disabled, placeholder,searchable, onChange, selectedData, onDropdownOpen, section, userhierarchy,backspaceDelete }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  const handleChange = values => {
    if (values?.length > 0 && values[0]?.value !== selectedOption?.[0]?.value) {
      setSelectedOption([...values]);
      onChange(values)
    } else if (section === "email" || section === "users") {
      onChange(values)
    }
  }

  useEffect(() => {
    setOptions(data?.length ? [...data] : []);
  }, [data]);
  useEffect(() => {
    setSelectedOption(selectedData?.length ? [...selectedData] : []);
  }, [selectedData])

  return (
    <Select
      options={options}
      closeOnSelect={false}
      searchable={searchable}
      backspaceDelete={backspaceDelete?true:false}
    
      className={cx("text-sm searchable-dropdown-custom", GitlabStyles['select-dropdown'])}
      values={selectedOption}
      disabled={disabled}
      styles={customStyles}
      contentRenderer={userhierarchy?contentRenderer:null}
      dropdownRenderer={(innerProps, innerState, innerMethods) =>
        dropdownRenderer(innerProps, innerState, innerMethods,userhierarchy)
      }
      onChange={(values) => {
        handleChange(values)
      }}
      multi={false}
      placeholder={placeholder}
      onDropdownOpen={onDropdownOpen}
      inputProps={{ readOnly: true }}
    />
  )
}

export const MultiSelectDropdown = ({ data, disabled, placeholder, handleSelectChange, selectedData, onDropdownOpen }) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = values => {
    if (JSON.stringify(values) !== JSON.stringify(selectedOptions)) {
      setSelectedOptions([...values]);
      handleSelectChange([...values]);
    }
  }

  useEffect(() => {
    setOptions(data?.length ? [...data] : []);
  }, [data]);
  useEffect(() => {
    setSelectedOptions(selectedData?.length ? [...selectedData] : []);
  }, [selectedData])

  return (
    <Select
      options={options}
      closeOnSelect={false}
      searchable={true}
      backspaceDelete={true}
      className={cx("text-sm searchable-dropdown-custom", GitlabStyles['select-dropdown'])}
      values={selectedOptions}
      disabled={disabled}
      styles={customStyles}
      dropdownRenderer={(innerProps, innerState, innerMethods) =>
        customDropdownRenderer(innerProps, innerState, innerMethods)
      }
      contentRenderer={customContentRenderer}
      onChange={(values) => {
        handleChange(values)
      }}
      multi={true}
      placeholder={placeholder}
      onDropdownOpen={onDropdownOpen}
    />
  )
}
