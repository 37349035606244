import React, { useState } from "react";
import "./NDAForm.scss";
import { ReactComponent as Edit } from "../../assets/edit_white.svg";
import { Tooltip } from "@mui/material";
import NDAFormData from "./NDAFormData";

import { ReactComponent as FireflinkLogo } from "../../assets/Logo/Fireflink_logo.svg";
import EditNDAFormData from "./editNDAForm";

function NDAFromHome() {
  let [editNDAform, setEditNDAForm] = useState(false);
  let myRole = JSON.parse(localStorage.getItem("user-privilege")).role;
  const CloseEditmodal=()=>{
    setEditNDAForm(false)
  }
  return (
    <div className="NDAform-container">
      <div className="NDAform-header pl-3 grid grid-cols-12">
        <div className="col-span-11 header-text">NDA Form</div>
        {
            (!editNDAform && myRole==="ACCOUNT_MANAGER")&& (<>
             <Tooltip title="edit">
          <div
            className="col-span-1 edit-nda-form-btn grid grid-cols-12 mr-2"
            onClick={() => {
              setEditNDAForm(true);
            }}
          >
            <div className="col-span-2 edit_icon">
              <Edit />
            </div>
            <div className="col-span-5 edit_text">Edit</div>
          </div>
        </Tooltip>
            </>)
        }
       
      </div>
      <div className="NDAform-body grid grid-cols-12">
        <div className="col-span-12 grid grid-cols-12 NDAForm-body-header">
          <div className="col-span-12 logo">
            <FireflinkLogo />
          </div>
          <div className="col-span-12 form-name">NON-DISCLOSURE AGREEMENT</div>
        </div>

        <div className="col-span-12 p-2.5">
          {editNDAform ? (
            <>
              <EditNDAFormData CloseEditmodal={CloseEditmodal}/>
            </>
          ) : (
            <>
              <NDAFormData />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default NDAFromHome;
