import React, { useState, useEffect } from 'react';
import Select from 'react-dropdown-select';
import styled from "@emotion/styled";
// import GitlabStyles from './dropdown-styles.module.scss'
import GitlabStyles from "../project/modals/dropdown-styles.module.scss"
import cx from 'classnames';
import { Checkbox, Tooltip } from '@mui/material';

const SearchAndToggle = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    z-index:1;
    top:0;
    background-color: #fbfcfd;

    input {
      margin: 6px 10px 6px;
      line-height: 30px;
      padding: 0 5px;
      border: 1px solid #ccc;
      border-radius: 3px;
      :focus {
        outline: none;
        border: 1px solid ${({ color }) => color};
      }
    }
  `;

const Item = styled.div`
display: flex;
margin: 10px;
align-items: baseline;
cursor: pointer;
border-bottom: 1px dotted transparent;

${({ disabled }) =>
    disabled
      ? `
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
`
      : ""}
`;

const ItemLabel = styled.div`
margin: 5px 10px;
`;
const customStyles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? "#F0F3F9" : "",
    }),
  };
  const dropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "i");
    const options = props.options.filter((item) =>
      regexp.test(item[props.searchBy] || item[props.labelField])
    );
    return (
      <div>
        {
          options.length === 0 ?
            <div className="noData pl-5 pt-5 pb-5">No options</div>
            :
            <div className="exe-env-options-dropdown">
              {
                options.map((option) => {
                  return (
                    <div key={option[props.labelField]} title={option[props.labelField]} className="dropdownlabels">
                      <Item
                        disabled={option.disabled}
                        key={option[props.valueField]}
                        className=""
                        onClick={
                          option.disabled ? null : () => methods.addItem(option)
                        }
                      >
                        <Tooltip title={option[props.labelField]} placement='top'>
                          <ItemLabel className='text-overflow-style '>{option[props.labelField]}</ItemLabel>
                        </Tooltip>
                      </Item>
                    </div>
                  );
                })}
            </div>
        }
      </div>
    );
  };
export const Dropdown = ({ data, disabled, placeholder,searchable, onChange, selectedData, onDropdownOpen, section }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
  
    const handleChange = values => {
      if (values?.length > 0 && values[0]?.value !== selectedOption?.[0]?.value) {
        setSelectedOption([...values]);
        onChange(values)
      } else if (section === "email") {
        onChange(values)
      }
    }
  
    useEffect(() => {
      setOptions(data?.length ? [...data] : []);
    }, [data]);
    useEffect(() => {
      setSelectedOption(selectedData?.length ? [...selectedData] : []);
    }, [selectedData])
  
    return (
      <Select
        options={options}
        closeOnSelect={false}
        searchable={searchable}
        backspaceDelete={true}
        className={cx("text-sm searchable-dropdown-custom", GitlabStyles['select-dropdown'])}
        values={selectedOption}
        disabled={disabled}
        styles={customStyles}
        dropdownRenderer={(innerProps, innerState, innerMethods) =>
          dropdownRenderer(innerProps, innerState, innerMethods)
        }
        onChange={(values) => {
          handleChange(values)
        }}
        multi={false}
        placeholder={placeholder}
        onDropdownOpen={onDropdownOpen}
      />
    )
  }
  