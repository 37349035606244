import React from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

function EditNDAFormData(props){
    return(
        <div>
            <div>
                <TextareaAutosize  style={{ height: "60vh", resize: "none", overflow: "auto" ,marginTop:"12px"}}  className="editNDAform-description" autoFocus/>
            </div>
            <div className="flex justify-end mt-2">
                <div className="editNDAform-btn cancle_btn mr-4" onClick={props?.CloseEditmodal}>
                    <button>Cancle</button>
                </div>
                <div className="editNDAform-btn save_btn">
                    <button>Submit</button>
                </div>

            </div>
        </div>
    )
}
export default EditNDAFormData