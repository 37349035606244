import React, { useState } from "react";
import Modal from "react-modal";

import { ReactComponent as MacIcon } from "../../../assets/platform/mac.svg";
import { ReactComponent as Windows } from "../../../assets/platform/windows.svg";
import { ReactComponent as Linux } from "../../../assets/platform/linux.svg";
import { ReactComponent as Ubantu } from "../../../assets/platform/ubantu.svg";
import { ReactComponent as Android } from "../../../assets/platform/android.svg";

import { ReactComponent as Opera } from "../../../assets/platform/opera.svg";
import { ReactComponent as AndroidBrowser } from "../../../assets/platform/android-browser.svg";
import { ReactComponent as FirefoxIcon } from "../../../assets/platform/firefox.svg";
import { ReactComponent as MicrosoftEdge } from "../../../assets/platform/microsoft edge.svg";
import { ReactComponent as ChromeIcon } from "../../../assets/platform/chrome.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import { Label } from "../../common/inputs/myInput";
import { TextareaAutosize, TextField } from "@mui/material";
import { editPlatformName } from "../../../api/api-services";

function PlatformModal(props) {
  const [openModal, setOpenModal] = useState(true);
  let initialValues = {
    platformName: props?.editPlatformData
      ? props?.editPlatformData?.platformName
      : "",
  };
  const validationSchema = yup.object({
    platformName: yup.string().required("Platform name is required"),
  });
  const formikDetails = useFormik({
    initialValues,

    validationSchema,
  });

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "85%",
      transform: "translate(-50%, -50%)",
      width: "30%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: " 12px",
    },
  };
  function selectedOS(ostype) {
    switch (ostype) {
      case "Mac":
        return <MacIcon />;

      case "Windows":
        return <Windows />;

      case "linux":
        return <Linux />;

      case "ubantu":
        return <Ubantu />;

      case "Android":
        return <Android />;

      case "iOS":
        return <MacIcon />;

      default:
        return "--";
    }
  }
  function selectedBrowser(browsertype) {
    switch (browsertype) {
      case "Opera":
        return <Opera />;

      case "Android":
        return <AndroidBrowser />;

      case "Linux":
        return <Linux />;

      case "Firefox":
        return <FirefoxIcon />;

      case "Microsoft Edge":
        return <MicrosoftEdge />;

      case "Chrome":
        return <ChromeIcon />;

      default:
        return "--";
    }
  }
  const editPlatFormData = async () => {
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    let emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
    let payload = {
      id: props?.editPlatformData?.id,
      platformName: formikDetails.values.platformName,
      os: props?.editPlatformData?.os,
      osVersion: props?.editPlatformData?.osVersion,
      platformType: props?.editPlatformData?.platformType,
      browser: props?.editPlatformData?.browser,
      browserVersion: props?.editPlatformData?.browserVersion,
      deviceName: props?.editPlatformData?.deviceName,
      deviceType: props?.editPlatformData?.deviceType,
      deviceBrand: props?.editPlatformData?.deviceBrand,
      isDefault: props?.editPlatformData?.isDefault,
    };
    try {
      let response = await editPlatformName(projectId, emailId, payload);
      console.log(response);
      if (response.status === 200) {
        props.onCloseEditPlatform(false);
        props.MyAlert.success(`${payload.platformName} updated successFully`);
        // props.getAllPlatform();
        props.setplatforms(response.data.platforms);
      } else {
        props.MyAlert.danger(`${response.response.data.errorMessage}`);
      }
      if (haveSameData(props?.editPlatformData, payload)) {
        props.MyAlert.info(
          `No changes were done to the ${payload.platformName} platform`
        );
        props.onCloseEditPlatform(true);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };
  function handleTrimmedBlur(event) {
    event.target.value = event.target.value.trim();
    formikDetails.handleChange(event);
    formikDetails.handleBlur(event);
  }
  const haveSameData = function (obj1, obj2) {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;
    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every(
        (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
      );
    }
    return false;
  };
  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
      <div className="flex justify-between rounded-tl-xl create-modal-heading h-10 p-2">
        <div>Edit Platform</div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.onCloseEditPlatform(false);
          }}
          type="button"
          className="modal-close-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {
        <div className="platform-main-content">
          <div>
            {props?.editPlatformData?.platformType === "web" ? (
              <>
                <div className="px-2">
                  <div>
                    <div className="my-5 add_platform ">
                      <div className="mb-2">
                        <Label
                          label={
                            <span className="labelPlatFormName text-sm  popins-medium  ">
                              Platform Name
                            </span>
                          }
                          required={true}
                          error={
                            formikDetails.errors.platformName &&
                            formikDetails.touched.platformName
                          }
                        />
                      </div>
                      <TextField
                        error={
                          formikDetails.errors.platformName &&
                          formikDetails.touched.platformName
                        }
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        size="small"
                        name="platformName"
                        id="platformName"
                        className="custom_height"
                        autoComplete="off"
                        placeholder="Type here"
                        onBlur={handleTrimmedBlur}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.platformName}
                      />
                      {formikDetails.errors.platformName &&
                      formikDetails.touched.platformName ? (
                        <div className="text-red-500 error_text md:mt-1 ml-3">
                          {formikDetails.errors.platformName}
                        </div>
                      ) : null}
                    </div>

                    <div
                      className="disabledContainer"
                      style={{ background: "#f6f6f6" }}
                    >
                      <div className="my-5">
                        <div className="add_platform mb-1">
                          <div className="label">OS</div>
                        </div>

                        <div className="ostype flex">
                          <div className="mx-2 mt-1">
                            {selectedOS(props?.editPlatformData?.os)}
                          </div>
                          <div className="osvalue">
                            {props?.editPlatformData?.os}
                          </div>
                        </div>
                      </div>

                      {/* new line */}
                      <div className="my-5">
                        <div className="add_platform mb-1">
                          <div className="label">OS Version</div>
                        </div>

                        <div className="ostype">
                          <div className="osvalue ml-2">
                            {props?.editPlatformData?.osVersion}
                          </div>
                        </div>
                      </div>

                      {/* new line */}
                      {/* <div className="my-5">
                    <div className=" add_platform mb-1">
                      <div className="label">Device</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">samsung</div>
                    </div>
                  </div> */}

                      {/* new line */}

                      <div className="my-5">
                        <div className="add_platform mb-1">
                          <div className="label">Browser Type</div>
                        </div>

                        <div className="ostype flex">
                          <div className="mx-2 mt-1">
                            {selectedBrowser(props?.editPlatformData?.browser)}
                            {/* <Windows /> */}
                          </div>
                          <div className="osvalue">
                            {props?.editPlatformData?.browser}
                          </div>
                        </div>
                      </div>

                      {/* newline */}
                      <div className="my-5">
                        <div className="add_platform mb-1">
                          <div className="label">Browser Version</div>
                        </div>

                        <div className="ostype">
                          <div className="osvalue ml-2">
                            {props?.editPlatformData?.browserVersion}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* new line */}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="px-2">
                  <div>
                    <div className="my-5 add_platform ">
                      <div className="mb-2">
                        <Label
                          label={
                            <span className="labelPlatFormName text-sm  popins-medium  ">
                              Platform Name
                            </span>
                          }
                          required={true}
                          error={
                            formikDetails.errors.platformName &&
                            formikDetails.touched.platformName
                          }
                        />
                      </div>
                      <TextField
                        error={
                          formikDetails.errors.platformName &&
                          formikDetails.touched.platformName
                        }
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        size="small"
                        name="platformName"
                        id="platformName"
                        className="custom_height"
                        autoComplete="off"
                        placeholder="Type here"
                        onBlur={handleTrimmedBlur}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.platformName}
                      />
                      {formikDetails.errors.platformName &&
                      formikDetails.touched.platformName ? (
                        <div className="text-red-500 error_text md:mt-1">
                          {formikDetails.errors.platformName}
                        </div>
                      ) : null}
                    </div>

                    <div
                      className="mobileTypeDisabledContainer"
                      style={{ background: "#f6f6f6" }}
                    >
                      {/* new line */}
                      <div className="my-5">
                        <div className=" add_platform mb-1">
                          <div className="label">Device Name</div>
                        </div>

                        <div className="ostype">
                          <div className="osvalue ml-2">
                            {props?.editPlatformData?.deviceBrand +
                              " " +
                              props?.editPlatformData?.deviceName}
                          </div>
                        </div>
                      </div>

                      {/* new line */}

                      <div className="my-5">
                        <div className="add_platform mb-1">
                          <div className="label">Device Type</div>
                        </div>

                        <div className="ostype flex">
                          {/* <div className="mx-2 mt-1">
                              {selectedOS(
                                props?.editPlatformData?.os
                              )}
                             
                            </div> */}
                          <div className="osvalue  ml-2">
                            {props?.editPlatformData?.deviceType[0]?.toUpperCase() +
                              props?.editPlatformData?.deviceType?.slice(1)}
                          </div>
                        </div>
                      </div>

                      {/* newline */}
                      <div className="my-5">
                        <div className="add_platform mb-1">
                          <div className="label">Device Brand</div>
                        </div>

                        <div className="ostype">
                          <div className="osvalue ml-2">
                            {props?.editPlatformData?.deviceBrand}
                          </div>
                        </div>
                      </div>

                      <div className="my-5">
                        <div className="add_platform mb-1">
                          <div className="label">OS</div>
                        </div>

                        <div className="ostype flex">
                          <div className="mx-2 mt-1">
                            {selectedOS(props?.editPlatformData?.os)}
                          </div>
                          <div className="osvalue">
                            {props?.editPlatformData?.os}
                          </div>
                        </div>
                      </div>

                      {/* new line */}
                      <div className="my-5">
                        <div className="add_platform mb-1">
                          <div className="label">OS Version</div>
                        </div>

                        <div className="ostype">
                          <div className="osvalue ml-2">
                            {props?.editPlatformData?.osVersion}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* new line */}
                  </div>
                </div>
              </>
            )}
          </div>

          {/* mobile project */}
        </div>
      }

      <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
        <button
          onClick={() => {
            setOpenModal(false);
            props.onCloseEditPlatform(false);
          }}
          type="button"
          className="w-24 gray-btn ml-3 button"
        >
          Cancel
        </button>
        <button
          type="submit"
          id="createBatch"
          className="w-24 primary-btn button"
          disabled={formikDetails.errors.platformName}
          onClick={() => {
            formikDetails.handleSubmit();
            editPlatFormData();
          }}
        >
          Update
        </button>
      </div>
    </Modal>
  );
}
export default PlatformModal;
