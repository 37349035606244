import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  TextareaAutosize,
  TextField,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Label } from "../common/inputs/myInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { Dropdown } from "./dropdown";
import TYDateTimePicker from "../modal/ty-data-picker";
import "./config.scss";
import DefectFieldForm from "./defect-field-forms";
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import FormControl from "@material-ui/core/FormControl";
//import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { getLabel, getOptionLabel } from "./common-functions";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import DeleteIcon from "@mui/icons-material/Delete";
import { Alert } from "@material-ui/lab";
import {ReactComponent as Multiplefiles} from "../../assets/multiple-files-icon.svg";
import RichTextEditor from "./rich-texteditor";
import {ReactComponent as VideoIcon} from "../../assets/feather-video-icon.svg";
import {ReactComponent as Attachments} from "../../assets/attachment_icon.svg"
import AttachFile from "@mui/icons-material/AttachFile";
import {updateDefectTemplateRequest} from "../../api/api-services";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as DeleteIcon } from "../../assets/deleteBlack.svg";
import { ReactComponent as EditIcon } from "../../assets/editBlack.svg";

function EditDefectTemplate(props) {
    
  const [openModal, setOpenModal] = useState(true);




  const [TemplateData, setTemplateData] = useState(
    JSON.parse(JSON.stringify(props.editTemplateDetails))
  );
  const [templateName, setTemplateName] = useState(props.editTemplateDetails.name);
  const [showErrorMsg, setShowErrorMsg] = useState("");
  const [tempName,settempName]=useState(props.editTemplateDetails.name);

  const testCaseDetailDescElements = (data) => {
    switch (data.type) {
      case "textField":
        return (
          <>
            <TextField
              disabled={true}
              className="w-60"
             
              size="small"
              fullWidth
              title={data?.placeholder?.length > 30 ? data?.placeholder : null}
              placeholder={
                data?.placeholder?.length > 30
                  ? data?.placeholder?.substr(0, 30) + "..."
                  : data?.placeholder
              }
              value={data?.defaultValue}
            />
          </>
        );

      case "textArea":
        return (
          <>
            <div className="">
              <TextareaAutosize
                disabled={true}
                title={data?.placeholder?.length > 30 ? data?.placeholder : null}
                id="description"
                name="description"
                style={{ height: "60px" }}
                className="w-full border-2 rounded border-gray-300 pl-2"
                placeholder={
                  data?.placeholder?.length > 30
                    ? data?.placeholder?.substr(0, 30) + "..."
                    : data?.placeholder
                }
                 value={data.defaultValue}
              />
            </div>
          </>
        );

      case "radioButton":
        return (
          <>
            <div className="mt-3">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div>
                    <input
                      type="radio"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="defect-template-btn-label">{getOptionLabel(option)}</label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case "link":
        return (
          <>
            <div className=" flex mt-3">
              <div>
                {/* <Attachments/> */}
                <InsertLinkOutlinedIcon className="-mt-2 mr-2 origin-center rotate-90 opacity-60 text-blue-600" />
              </div>
              <div
                aria-disabled={true}
                className=" font-sans text-xs opacity-50 lg:w-64"
              >
                {getLabel(
                  "Attached Link will be shown here if any URL is added"
                )}
              </div>
            </div>
          </>
        );

      case "dropdown":
        return (
          <>
            
            <FormControl fullWidth>
              <Select
                name="selectCustomField"
                id="selectCustomField"
                displayEmpty
                size="small"
                 disabled={true}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
              >
                <MenuItem>{data?.placeholder}</MenuItem>
                {data?.options?.map((option, index) => (
                  <option value={option}>{option}</option>
                ))}
              </Select>
            </FormControl>
          </>
        );

      case "date":
        return (
          <>
            <div className="flex">
              <div className="border-2 rounded border-gray-300 w-full flex py-1">
                <div className="w-11/12"></div>
                <div className="border-l-2  pl-2">
                  <CalendarTodayOutlinedIcon className="mt-1" />
                </div>
              </div>
            </div>
            {TemplateData?.templateType === "Predefined" ? (
              <div className="text-xs opacity-50">[dd-mm-yyyy]</div>
            ) : (
              <div className="text-xs opacity-50">[{data.dateFormat}]</div>
            )}
          </>
        );
      case "checkbox":
        return (
          <>
            <div className="lg:w-72">
              <div className="grid grid-cols-2 gap-1">
                {data?.options?.map((option, index) => (
                  <div>
                    <input
                      type="checkbox"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="text-xs defect-template-btn-label">{getOptionLabel(option)}</label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case "attachment":
        return (
          <>
            
            <div>
              {data?.label==="Attach multiple images" ?<Multiplefiles/>:data?.label==="Attach Video"?<VideoIcon/>:<span className="text-blue-600"><AttachFile/></span>}
            </div>
            
          </>
        );

      case "textEditor":
        return (
          <div>
            <RichTextEditor
                      convertedContent=''
                      readOnly={true}
                      toolbarHidden={false}
                      className="w-full"
                  />
          </div>
        );

      default:
        return (
          <>
            <TextField disabled={true} className="w-60" />
          </>
        );
    }
  };
  const addNameField = (e) => {
    setShowErrorMsg("");
    setTemplateName(e.target.value);
    TemplateData.name = templateName;
  };

  const removeTestDetailsData = (value) => {
    let tempTemplateData = TemplateData
    if (value) {
        let index = tempTemplateData.defectDetails.findIndex(x => (x.type === value.type && x.label === value.label));
        tempTemplateData.defectDetails.forEach((value, i) => {
            if (i > index) {
                value.order = (parseInt(value.order) - 1) + ''
            }
        })
        index !== -1 && tempTemplateData.defectDetails.splice(index, 1);
        setTemplateData({ ...tempTemplateData })
        props.MyAlert.success(`${value.label} field removed successfully`)
    }
}

  let checkValidationForAlphanumeric =
    /^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/;
  const templateNameValidation = () => {
    if (!TemplateData.name.length) {
      setShowErrorMsg("This field is required");
      return true;
    }
    if (TemplateData.name.length >= 50 || TemplateData.name.length < 2) {
      setShowErrorMsg("Name must be in between 2 to 50 characters");
      return true;
    }
    if (TemplateData.name.length !== TemplateData.name.trim().length) {
      setShowErrorMsg(
        "Name must not contain space at the beginning or at the end"
      );
      return true;
    }
    if (!checkValidationForAlphanumeric.test(TemplateData.name)) {
      setShowErrorMsg("Name must not contain special characters");
      return true;
    }
    if(tempName!==TemplateData.name){
      if(props.defectTemplateList.includes(TemplateData.name)){
        setShowErrorMsg("Template name is already exist");
        return true;
      }
    }
    
    return false;
  };
  const  updateTemplate = (e) => {
    const tempTemplateData = JSON.parse(JSON.stringify(localStorage.getItem("templateList")))
    let licenseId=JSON.parse(localStorage["selected-project"]).licenceID;
    let projectId=JSON.parse(localStorage["selected-project"]).project.fireFlinkProjectId;
    let tempid=TemplateData._id;
    if (templateNameValidation()) {
      // setCreateCalled(false)
      return;
    }
    if (JSON.stringify(props.editTemplateDetails)===JSON.stringify(TemplateData)) {
      props.MyAlert.info(`No Changes were done to the ${TemplateData.name} template`);
      return
  }
   updateDefectTemplateRequest(TemplateData,projectId,tempid,licenseId).then((response)=>{
    console.log("updateDefectTemplateRequest",response,tempTemplateData,TemplateData,tempTemplateData===JSON.stringify(TemplateData));
    if(response.data.responseCode===200){
     
      props.closeEditDefectPopup(false);
      props.MyAlert.success(`${response?.data?.responseObject?.name} updated successfully`);
      props.getAllDefectLists();
      localStorage.removeItem("templateList")
    }

   }).catch((error)=>{
    console.error('edit Template Error');
   })
   
    
  };
  const onEditFieldHandler = (e, data) => {
    setEditFieldValue(data)
    setAddField(true);
    
}
  const testCaseDetailElements = (props) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <h6 className="defect-template-modal-values w-11/12 mb-2">
              {props.data.mandatory === "yes" ? (
                <span className="text-red-400 mr-1">&#42;</span>
              ) : null}
              {getLabel(props.data.label)}
            </h6>
            <div className={props.data.type !== "textEditor" ? "flex" : "flex"}>
              {![
                "ID",
                "Module",
                "Summary",
                "Description",
                "Severity",
                "Priority",
                "State",
                "Created By",
                "Created On",
                "Modified By",
                "Modified On",
                "Assign to",
              ].includes(props.data.label) ? (
                <>
                  {
                     <>
                      
                     <div className="editIcon" title="Edit">
                      
                         <IconButton
                           sx={{
                             "&:hover": {
                               backgroundColor: "#FAF9F6",
                               boxShadow:
                                 " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                             },
                           }}
                         >
                           <button
                             onClick={(e) => {
                               onEditFieldHandler(e, props.data);                            
                             }}
                           >
                             <EditIcon height="15px" width="15px" />
                           </button>
                         </IconButton>
                    
                     </div>

                     <div className="editIcon" title="Delete">
                      
                         <IconButton
                           sx={{
                             "&:hover": {
                               backgroundColor: "#FAF9F6",
                               boxShadow:
                                 " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                             },
                           }}
                         >
                           <button
                             onClick={() => removeTestDetailsData(props?.data)}
                           >
                         
                             <DeleteIcon height="23px" width="20px" />
                           </button>
                         </IconButton>
                       
                     </div>

                     
                   </>
                  }
                </>
              ) : null}
            </div>
          </div>
          <div className="">
            <div className="">{testCaseDetailDescElements(props.data)}</div>
          </div>
        </div>
      </>
    );
  };

  const testCaseDetailSection = () => {
    return (
      <>
        <div className="">
          <div className="">
            {TemplateData.defectDetails.map((data) => (
              <div className="my-5">
                {testCaseDetailElements({ data })}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "82.5%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };


  const [editFieldValue, setEditFieldValue] = useState({});
  console.log(TemplateData);
  const addFormField = (value) => {
    if (Object.keys(editFieldValue).length !== 0) {
      let tempTemplateData = TemplateData;
      const index = tempTemplateData.defectDetails.findIndex(
        (x) => x.order === editFieldValue.order
      );
      const indexD = tempTemplateData.defectDetails.findIndex(
        (x) => x.label.toLowerCase() === value.label.toLowerCase()
      );
      if (indexD === -1 || indexD === index) {
        value.order = editFieldValue?.order;
        tempTemplateData.defectDetails[index] = value;
        setTemplateData(tempTemplateData);
        props.MyAlert.success(`${value.label} field updated successfully`);
        setEditFieldValue({});
      } else {
        props.MyAlert.info(`Form field with ${value.label} label already exists`);
      }
    } else {
      value.order = TemplateData.defectDetails.length + 1 + "";
      let tempTemplateData = TemplateData;
      const index = tempTemplateData.defectDetails.findIndex(
        (x) => x?.label?.toLowerCase() === value?.label?.toLowerCase()
      );
      console.log(index);
      if (index === -1 ) {
        tempTemplateData?.defectDetails?.push(value);
        props.MyAlert.success(`${value?.label} field added successfully`);
        
      } else {
        
        props.MyAlert.info(`Form field with ${value?.label} label already exists`);
      }
      setTemplateData(tempTemplateData);
    }
  };


   

  
const [addField,setAddField]=useState(false)
  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
      <div className="flex justify-between rounded-tl-xl defect-template-modal-heading h-10 p-2">
        <div>Edit Defect Details Template</div>
        <div className="ml-28">
          <button
           disabled={editFieldValue.type}
            className={editFieldValue.type ? "filed_btn opacity-50" : "filed_btn" }
            onClick={() => {
              setAddField(true);
              setEditFieldValue({});
            }}
          >
            + Fields
          </button>
        </div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeEditDefectPopup(false)
          }}
          type="button"
          className="modal-close-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
         </div>
      <div>
        {!addField ? (
          <>
            <div
              className="main-content-defect overflow-y-scroll "
              id="journal-scroll"
            >
             <div className="pr-4">
                <div className="px-2">
                  <div>
                    {/* start   */}
                    <div className="my-5 pb-7">
                      <div className="mb-4">
                        <Label
                          label={
                      <>
                        <span className="text-red-400 mr-1">&#42;</span>{" "}
                        <span className="defect-template-modal-values">Template Name</span>
                      </>
                    }
                  />
                  <TextField
                    name="templateName"
                    id="templateName"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="off"
                    onBlur={addNameField}
                    onInput={addNameField}
                    fullWidth
                    value={templateName.replace(/ +(?= )/g,'')}
                    className=" w-60 "
                    type="text"
                    
                  />
                  {showErrorMsg && (
                    <div className="errorMessage">{showErrorMsg}</div>
                  )}
                </div>
                <div className="">
                  <span className="popins-semi-bold text-blue-600">
                    Defect Details
                  </span>
                </div>
                <div className="">{testCaseDetailSection()}</div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="py-2 px-3 flex justify-end gap-4 user-create-button z-50">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeEditDefectPopup(false)
            }}
            type="button"
            className="w-24 gray-btn ml-3 button"
          >
            Cancel
          </button>
          <button
            className="w-24 primary-btn button"
            onClick={() => {
              updateTemplate();
            }}
          >
            Update
          </button>
        </div>
  </>
        ) : (
          <>
            <DefectFieldForm
              addFormField={addFormField}
              setAddField={setAddField}
              editFieldValue={editFieldValue}
              setOpenModal={setOpenModal}
              MyAlert={props.MyAlert}
              setEditFieldValue={setEditFieldValue}
            />
          </>
        )}
      </div>
    </Modal>
  );
}
export default EditDefectTemplate;
