import React, { useState, useEffect } from "react";
import { useTable, useGlobalFilter } from "react-table";
import Close from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import BugReportIcon from "@mui/icons-material/BugReport";
import { ReactComponent as DefectIcon } from "../../assets/Defects_Icon.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./report-details.scss";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getDefectsByStateReq,
  getDefectsData,
  getUserDetailsByEmailID,
  DownloadDefectExcleFile,
  getTemplateById,
} from "../../api/api-services";
import DefectsDeatilsPopup from "../modal/defects-deatils-popup";
import UserDeatilsPopup from "../modal/user-deatils";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as NoDefectIcon } from "../../assets/noDefectsAvailable.svg";
import ReportsContextMenu from "../modal/reports-contex-modal";
import SearchField from "../common/search-field";

function TotalDefectsTable(props) {
  let [defects, setdefects] = useState([]);
  let [dataLoaded, setDataLoaded] = useState(false);
  let loggedinUser = JSON.parse(localStorage["user-privilege"]);
  let [displayapproverdefect, setDisplayapproverdefect] = useState(true);
  const [approvedDefectsData, setApprovedDefectsData] = useState([]);
  const [rejectedDefectsData, setrejectedDefectsData] = useState([]);
  let [approvedDefectPageNo, setApprovedDefectPageNo] = useState(0);
  let [rejectedDefectPageNo, setRejectedDefectPageNo] = useState(0);
  let [approvedDefectlastpage, setApprovedDefectlastpage] = useState();
  let [rejectedDefectlastpage, setRejectedDefectlastpage] = useState();
  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;

  let [defectsPopUp, setDefectsPopUp] = useState(false);
  let [defectsdetails, setDefectsdetails] = useState();
  let [userDeatils, setusererDeatils] = useState();
  let [userPopup, setUserPopup] = useState(false);
  let [defectTemplatedetails, setDefectTempalteDetails] = useState();
  let [closeSearchField, setCloseSearchField] = useState(false);
  let [showSearch, setShowSearch] = useState(false);
  let [showcloseIcon, setShowCloseicon] = useState(false);

  if (loggedinUser?.role?.includes("_")) {
    loggedinUser.role = loggedinUser.role.replace("_", "-");
  }

  useEffect(() => {
    if (displayapproverdefect) {
      setdefects([...approvedDefectsData]);
    } else {
      if (rejectedDefectsData.length === 0) {
        getAllRejectedDefects();
      }
      setdefects([...rejectedDefectsData]);
    }
  }, [displayapproverdefect]);

  useEffect(() => {
    if (!props.allowRejectedDefect && !displayapproverdefect) {
      setDisplayapproverdefect(true);
    }
  }, [props.allowRejectedDefect]);

  useEffect(() => {
    getAllApprovedDefects();
  }, []);

  const Styles = styled.div`
    table {
      border-spacing: 0;
      border: 1px solid #707070;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        border-bottom: 1px solid #707070;
        border-right: 1px solid #707070;

        :last-child {
          border-right: 0;
        }
      }
    }
  `;

  function CloseDefectModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      setGlobalFilter();
      setShowCloseicon(false);
    }
    setDefectsPopUp(state);
  }

  function CloseTesterModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      setGlobalFilter();
      setShowCloseicon(false);
    }
    setUserPopup(state);
  }

  const handlescroll = (event) => {
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
      if (displayapproverdefect && approvedDefectlastpage === false) {
        getAllApprovedDefects();
      } else if (!displayapproverdefect && rejectedDefectlastpage === false) {
        getAllRejectedDefects();
      }
    }
  };

  let getAllRejectedDefects = async () => {
    let page = rejectedDefectPageNo,
      size = 10,
      defectFilter = "REJECTED";
    let rejectedData = [...rejectedDefectsData];
    try {
      const response = await getDefectsByStateReq(
        projectId,
        licenseID,
        defectFilter,
        page,
        size
      );
      if (response.status === 200) {
        setDataLoaded(true);
        setRejectedDefectlastpage(response?.data?.lastPage);
        setRejectedDefectPageNo(page + 1);
        // setDefectsData(response.data.responseObject);
        data = [...response?.data?.defects];
        data.map((defect, index) => {
          rejectedData.push({
            bugId: defect?.defectDetails?.ID,
            bugSummary: defect.defectDetails?.Summary,
            createdOn: defect?.defectDetails["Created On"],
            createdBy: defect?.defectDetails["Created By"]?.name,
            Details: defect,
          });
        });
      } else {
        console.log("defect failed", response);
      }
    } catch (e) {
      console.log("error");
    }
    if (rejectedDefectsData.length !== rejectedData.length) {
      setrejectedDefectsData([...rejectedData]);
    }
    if (!displayapproverdefect) {
      setdefects([...rejectedData]);
    }
  };

  let getAllApprovedDefects = async () => {
    let page = approvedDefectPageNo,
      size = 10,
      defectFilter = "CLOSED";
    let approvedData = [...approvedDefectsData];
    try {
      const response = await getDefectsByStateReq(
        projectId,
        licenseID,
        defectFilter,
        page,
        size
      );
      if (response?.status === 200) {
        setDataLoaded(true);
        setApprovedDefectlastpage(response?.data?.lastPage);
        setApprovedDefectPageNo(page + 1);
        data = [...response?.data?.defects];
        data.map((defect, index) => {
          approvedData.push({
            bugId: defect?.defectDetails?.ID,
            bugSummary: defect.defectDetails?.Summary,
            createdOn: defect?.defectDetails["Created On"],
            createdBy: defect?.defectDetails["Created By"]?.name,
            Details: defect,
          });
        });
      } else {
        console.log("defect failed", response);
      }
    } catch (e) {
      console.log("error", e);
    }
    if (approvedDefectsData.length !== approvedData.length) {
      setApprovedDefectsData([...approvedData]);
    }
    if (displayapproverdefect) {
      setdefects([...approvedData]);
    }
  };

  const getUserDetails = async (e) => {
    console.log("get user deatil welcome");
    let emailId = e.Details.defectDetails.history[0].userEmail;
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    try {
      let response = await getUserDetailsByEmailID(projectId, emailId);
      if (response?.status === 200) setusererDeatils(response.data);
      setUserPopup(true);
      setCloseSearchField(true);
      console.log("getUserDetails", response);
    } catch (err) {
      console.log("error");
    }
  };

  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }

  const downloadExcleFile = async () => {
    let filename = displayapproverdefect
      ? "Approved_Defects.xlsx"
      : "Rejected_Defects.xlsx";
    let state = displayapproverdefect ? "CLOSED" : "REJECTED";
    let payload = {};
    try {
      let response = await DownloadDefectExcleFile(
        projectId,
        licenseID,
        state,
        payload
      );
      console.log("response file", response);
      if (response.status === 200) {
        saveFileFromBlob(response.data, filename);
      }
    } catch (err) {
      console.log("error");
    }
  };
  const getTemplateDetails = async (e) => {
    console.log("eeee", e.defectTemplateId);
    console.log("eeee Details", e);
    let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])
      .project.fireFlinkProjectId;
    let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
    let templateID = e.defectTemplateId;
    try {
      let response = await getTemplateById(
        fireFlinkProjectId,
        templateID,
        licenseId
      );
      if (response.data.responseCode === 200) {
        setDefectTempalteDetails(response);
        setDefectsdetails(e);
        setDefectsPopUp(true);
        setCloseSearchField(true);
      } else {
        props.MyAlert.info("Something went wrong");
      }
    } catch (error) {
      console.log("errror");
    }
  };

  let columns = React.useMemo(
    () => [
      {
        Header: "BUG ID",
        accessor: "bugId",
        width: "8%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              getTemplateDetails(e.cell.row.original.Details);
              // setDefectsdetails(e.cell.row.original.Details);
              // setDefectsPopUp(true);
            }}
            className="reportTablecell popins cursor-pointer	"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "BUG SUMMARY",
        accessor: "bugSummary",
        width: "49.7%",
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },
      {
        Header: "CREATED ON",
        accessor: "createdOn",
        width: "12.3%",
      },
      {
        Header: "CREATED BY",
        accessor: "createdBy",
        width: "12.3%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();

              getUserDetails(e.cell.row.original);
            }}
            className="reportTablecell popins cursor-pointer	"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
    ],
    []
  );
  let data = React.useMemo(() => defects, [defects]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  const downloadDefectsExcleFile = async (state) => {
    let filename =
      state === "Approved"
        ? "Approved_Defects.xlsx"
        : state === "Rejected"
        ? "Rejected_Defects.xlsx"
        : "All_Defects.xlsx";
    let status =
      state === "Approved"
        ? "CLOSED"
        : state === "Rejected"
        ? "REJECTED"
        : "BOTH";
    let payload = {};
    try {
      let response = await DownloadDefectExcleFile(
        projectId,
        licenseID,
        status,
        payload
      );
      console.log("response file", response);
      if (response.status === 200) {
        saveFileFromBlob(response.data, filename);
      }
    } catch (err) {
      console.log("error");
    }
  };
  const contextMenuData = props.allowRejectedDefect
    ? [
        {
          option: "Approved Defects",
        },
        {
          option: "Rejected Defects",
        },
        {
          option: "All Defects",
        },
      ]
    : [
        {
          option: "Approved Defects",
        },
      ];
  function contextMenuFunction(event) {
    if (event.target.textContent === "Approved Defects") {
      downloadDefectsExcleFile("Approved");
    }
    if (event.target.textContent === "Rejected Defects") {
      downloadDefectsExcleFile("Rejected");
    }
    if (event.target.textContent === "All Defects") {
      downloadDefectsExcleFile("All");
    }
  }

  return (
    <div className="reportDetails">
      <div className="defecttableOutline">
        <div className="pt-5 pb-6 pl-3 flex items-center">
          <span className="pr-3 flex items-center">
            <DefectIcon className="common_darkBlue_icon" />
          </span>
          <span className="darkBlue pr-3 ">No. of Defects Raised</span>
          <span className="darkBlue pr-3 text-2xII">
            {props.allowRejectedDefect
              ? props?.listCount
              : props?.reportsDefectCount.approvedDefects}
          </span>
          <span className="float-right dropdownList3 ml-auto flex items-center">
            <div className="grid grid-cols-12 col-span-2 flex justify-end mt-1 mr-2">
              {/* <SearchField setGlobalFilter={setGlobalFilter} closeField={closeSearchField} setCloseField={setCloseSearchField} /> */}
              <>
                <Tooltip title={showcloseIcon ? "" : "Search"}>
                  <div
                    id="container"
                    className={`${
                      props.colSpan ? props.colSpan : "col-span-11"
                    }`}
                  >
                    <input
                      className={`${
                        showSearch ? "expanded-table-search" : "table-search"
                      } ${props.inputClass ? props.inputClass : ""}`}
                      type="text"
                      value={globalFilter || ""}
                      autoFocus={false}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalFilter(e.target.value);
                      }}
                      onClick={() => {
                        setShowSearch(true);
                        setTimeout(() => {
                          setShowCloseicon(true);
                        }, 200);
                      }}
                    />
                    {showcloseIcon && (
                      <div
                        className={`col-span-2 search_icon_close  ml-2 cursor-pointer ${
                          props.closeIconClass ? props.closeIconClass : ""
                        }`}
                        // onClick={() => {
                        //   setSearchInputValue("")
                        //   if(props.page === "projectList" && props?.onClose){
                        //       props.onClose()
                        //   }else if(props.page === "platformDetails" && props?.onClose){
                        //     props.onClose()
                        //   }else{
                        //       props.setGlobalFilter();
                        //   }
                        //   setShowCloseicon(false);
                        //   setShowSearch(false);
                        // }}
                        onClick={() => {
                          setGlobalFilter();
                          setShowCloseicon(false);
                          setShowSearch(false);
                        }}
                      >
                        <Close />
                      </div>
                    )}
                  </div>
                </Tooltip>
              </>
            </div>
            {defects.length === 0 ? (
              <Tooltip title="Download">
                <span
                  className={`pr-2 cursor-pointer ${
                    defects.length === 0 ? "opacity-50" : ""
                  }`}
                >
                  <IconButton
                    className={`reportsIconHover`}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#FAF9F6",
                        boxShadow:
                          " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      },
                    }}
                  >
                    <FileDownloadOutlinedIcon className="darkBlue downloadIconSize" />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <ReportsContextMenu
                contextMenuData={contextMenuData}
                func={(event) => {
                  contextMenuFunction(event);
                }}
                onMouseOutz
                state={props.allowRejectedDefect}
              />
            )}

            <Tooltip title="Collapse" placement="top">
              <span className="pr-7 dark-collpase">
                <IconButton
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FAF9F6",
                      boxShadow:
                        " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                    },
                  }}
                  onClick={() => {
                    props.closeDetailsTable(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 21 20.996"
                  >
                    <path
                      d="M19.349.239a.98.98,0,0,0-1.39,0L14.391,3.807l-1.6-1.6a.985.985,0,0,0-1.681.7V8.81a.982.982,0,0,0,.984.984H18a.985.985,0,0,0,.7-1.681l-1.6-1.6,3.568-3.568a.98.98,0,0,0,0-1.39ZM8.81,11.106H2.9a.985.985,0,0,0-.7,1.681l1.6,1.6L.239,17.954a.98.98,0,0,0,0,1.39l1.312,1.312a.98.98,0,0,0,1.39,0l3.568-3.568,1.6,1.6a.985.985,0,0,0,1.681-.7v-5.9a.982.982,0,0,0-.984-.984Z"
                      transform="translate(0.05 0.05)"
                      fill="#434db8"
                    />
                  </svg>
                </IconButton>
              </span>
            </Tooltip>
          </span>
        </div>
        {props.allowRejectedDefect ? (
          <div className="mb-5 pl-3">
            <span
              className={`cursor-pointer popins ${
                displayapproverdefect ? "selectedDefects darkBlue" : null
              }  font-12 pb-2 mr-8 h-8`}
              onClick={() => {
                setDisplayapproverdefect(true);
              }}
            >
              Approved Defects - {props?.reportsDefectCount.approvedDefects}
            </span>
            <span
              className={`h-8 pb-2 popins cursor-pointer ${
                !displayapproverdefect ? "selectedDefects darkBlue" : null
              } font-12`}
              onClick={() => {
                setDisplayapproverdefect(false);
              }}
            >
              Rejected Defects - {props?.reportsDefectCount.rejectedDefects}
            </span>
          </div>
        ) : null}
        <div
          className={`reportTablecontainer ${
            rows.length > 9 ? "reportTableScrollbar" : null
          }`}
        >
          <table {...getTableProps()} className=" overflow-x-auto">
            <thead className="text-left  h-10">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      width={column.width}
                      {...column.getHeaderProps()}
                      className={` reportTableheader popins-semi-bold text-lg font-semibold ${
                        column.Header === "BUG SUMMARY"
                          ? "text-left pl-3"
                          : "text-center"
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {dataLoaded ? (
              <tbody
                {...getTableBodyProps()}
                className=""
                onScroll={handlescroll}
              >
                {rows.length === 0 || data?.length === 0 ? (
                  <div className="noreportIconCenter">
                    <NoDefectIcon />
                  </div>
                ) : (
                  <>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="h-10 ">
                          {row.cells.map((cell) => {
                            return (
                              <td
                                width={cell?.column?.width}
                                {...cell.getCellProps()}
                                className={`datatrimmer reportTablecell ${
                                  cell.column.Header === "BUG ID" ||
                                  cell.column.Header === "CREATED BY"
                                    ? "reportBugIdColumn popins-semi-bold"
                                    : "popins"
                                } ${
                                  cell.column.Header === "BUG SUMMARY"
                                    ? "text-left pl-3"
                                    : "text-center"
                                }`}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            ) : null}
          </table>
        </div>
      </div>
      {defectsPopUp && (
        <DefectsDeatilsPopup
          CloseDefectModal={CloseDefectModal}
          SelectedDefect={defectsdetails}
          defectTemplatedetails={defectTemplatedetails}
        />
      )}
      {userPopup && (
        <UserDeatilsPopup
          CloseTesterModal={CloseTesterModal}
          testerDeatils={userDeatils}
        />
      )}
    </div>
  );
}
export default TotalDefectsTable;
