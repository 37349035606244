import React, { useState, useEffect, useContext, useRef } from "react";
import { useTable, useGlobalFilter } from "react-table";
function PlatformTable (props) {
    let projectType=JSON.parse(localStorage.getItem('selected-project')).project.projectType.web!==null?"WEB":
    JSON.parse(localStorage.getItem('selected-project')).project.projectType.mobile!==null?"MOBILE":JSON.parse(localStorage.getItem('selected-project')).project.projectType.others!==null?"Others":"WEB/MOBILE";
    let typeofProject=JSON.parse(localStorage.getItem('selected-project'))?.project?.projectType?.others?.typeOfProject;

    let data=props.details;
    let standAloneColumn=React.useMemo(
      ()=>[
        {
          Header: "OS",
          accessor: "os",
          width: "25%",
          Cell: (e) => (
            <div
              title={e.value}
              
             
              style={{ marginLeft: "1%" }}
            >
              {e.value}
            </div>
          ),
        },
        {
          Header: "OS VERSION",
          accessor: "osVersion",
          width: "25%",
          Cell: ({ value }) => {
            return (
              <div style={{ marginLeft: "2%" }}>{value===null|| value===""?"--":value}</div>
            );
          },
        },
      ],[]
    )
    let webColumn = React.useMemo(
        () => [
          {
            Header: "OS",
            accessor: "os",
            width: "25%",
            Cell: (e) => (
              <div
                title={e.value}
                
               
                style={{ marginLeft: "1%" }}
              >
                {e.value}
              </div>
            ),
          },
          {
            Header: "OS VERSION",
            accessor: "osVersion",
            width: "25%",
            Cell: ({ value }) => {
              return (
                <div style={{ marginLeft: "2%" }}  title={value}>{value===null|| value===""?"--":value}</div>
              );
            },
          },
          {
            Header: "BROWSER",
            accessor: "browser",
            width: "25%",
            Cell: ({ value }) => {
              return (
                <div
                  title={value}
                  style={{ marginLeft: "2%" }}
                  className=""
                >
                  {value===null?"--":value}
                </div>
              );
            },
          },
          {
            Header: "BROWSER VERSION",
            accessor: "browserVersion",
            width: "25%",
            Cell: ({ value }) => {
                return (
                  <div
                    title={value}
                    style={{ marginLeft: "3%" }}
                    
                  >
                    {value===null?"--":value}
                  </div>
                );
              },
            
          },
        //   {
        //     Header: "DEVICE TYPE",
        //     accessor: "deviceType",
        //     width: "20%",
        //     Cell: ({ value }) => {
        //       return (
        //         <div style={{ marginLeft: "15%" }}>
        //           {value === null ? "--" : value}
        //         </div>
        //       );
        //     },
        //   },
         
        ],
        []
      );
      let mobileColumn=React.useMemo(
        () => [
          {
            Header: "OS",
            accessor: "os",
            width: "10%",
            Cell: (e) => (
              <div
                title={e.value}
                
                className="datatrimmer w-24"
                style={{ marginLeft: "1%" }}
                onClick={()=>{console.log("OS",e)}}
              >
                {e.value}
              </div>
            ),
          },
          {
            Header: "OS VERSION",
            accessor: "osVersion",
            width: "10%",
            Cell: ({ value }) => {
              return (
                <div style={{ marginLeft: "2%" }}  title={value} className="datatrimmer w-24">{value===null|| value===""?"--":value}</div>
              );
            },
          },
          // {
          //   Header: "BROWSER",
          //   accessor: "browser",
          //   width: "10%",
          //   Cell: (e) => {
          //     return (
          //       <div
          //         title={e.value}
          //         style={{ marginLeft: "2%" }}
          //         className=""
          //       >
          //         {e.value===null?"--":e.row.original.platformType==="web"?e.value:"--"}
          //       </div>
          //     );
          //   },
          // },
          // {
          //   Header: "BROWSER VERSION",
          //   accessor: "browserVersion",
          //   width: "10%",
          //   Cell: (e) => {
          //       return (
          //         <div
          //           title={e.value}
          //           style={{ marginLeft: "4%" }}
                    
          //         >
          //           {e.value===null?"--":e.value:"--"}
          //         </div>
          //       );
          //     },
            
          // },
        //   {
        //     Header: "DEVICE TYPE",
        //     accessor: "deviceType",
        //     width: "10%",
        //     Cell: ({ value }) => {
        //       return (
        //         <div style={{ marginLeft: "15%" }}>
        //           {value === null ? "--" : value}
        //         </div>
        //       );
        //     },
        //   },
          {
            Header: "DEVICE NAME",
            accessor: "deviceName",
            width: "10%",
            Cell: ({ value }) => {
              return (
                <div style={{ marginLeft: "3%" }}  title={value} className="datatrimmer w-24">
                  {value === null ? "--" : value}
                </div>
              );
            },
          },
          {
            Header: "DEVICE BRAND",
            accessor: "deviceBrand",
            width: "10%",
            Cell: ({ value }) => {
              return (
                <div style={{ marginLeft: "3%" }}  title={value} className="datatrimmer w-20">
                  {value === null ? "--" : value}
                </div>
              );
            },
          },
         
        ],
        []
      );
      let WebAndMobileColumn=React.useMemo(
        () => [
          {
            Header: "OS",
            accessor: "os",
            width: "10%",
            Cell: (e) => (
              <div
                title={e.value}
                
               
                style={{ marginLeft: "1%" }}
                onClick={()=>{console.log("OS",e)}}
              >
                {e.value}
              </div>
            ),
          },
          {
            Header: "OS VERSION",
            accessor: "osVersion",
            width: "10%",
            Cell: ({ value }) => {
              return (
                <div style={{ marginLeft: "2%" }}  title={value}>{value===null|| value===""?"--":value}</div>
              );
            },
          },
          {
            Header: "BROWSER",
            accessor: "browser",
            width: "10%",
            Cell: (e) => {
              return (
                <div
                  title={e.value}
                  style={{ marginLeft: "2%" }}
                  className=""
                >
                  {e.value===null?"--":e.row.original.platformType==="web"?e.value:"--"}
                </div>
              );
            },
          },
          {
            Header: "BROWSER VERSION",
            accessor: "browserVersion",
            width: "10%",
            Cell: (e) => {
                return (
                  <div
                    title={e.value}
                    style={{ marginLeft: "4%" }}
                    
                  >
                    {e.value===null?"--":e.row.original.platformType==="web"?e.value:"--"}
                  </div>
                );
              },
            
          },
        //   {
        //     Header: "DEVICE TYPE",
        //     accessor: "deviceType",
        //     width: "10%",
        //     Cell: ({ value }) => {
        //       return (
        //         <div style={{ marginLeft: "15%" }}>
        //           {value === null ? "--" : value}
        //         </div>
        //       );
        //     },
        //   },
          {
            Header: "DEVICE NAME",
            accessor: "deviceName",
            width: "10%",
            Cell: ({ value }) => {
              return (
                <div style={{ marginLeft: "7%" }}  title={value}>
                  {value === null ? "--" : value}
                </div>
              );
            },
          },
          {
            Header: "DEVICE BRAND",
            accessor: "deviceBrand",
            width: "10%",
            Cell: ({ value }) => {
              return (
                <div style={{ marginLeft: "6%" }}  title={value}>
                  {value === null ? "--" : value}
                </div>
              );
            },
          },
         
        ],
        []
      );
      let columns=projectType==="WEB"?webColumn:(projectType==="MOBILE"||(projectType==="Others" && typeofProject!=="Standalone_Application"))?mobileColumn:(projectType==="Others" && typeofProject==="Standalone_Application")?standAloneColumn:WebAndMobileColumn;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state,
        setGlobalFilter,
      } = useTable(
        {
          columns,
          data,
        },
        useGlobalFilter
      );
      const { globalFilter } = state;
    return(
        <div className="user-deatils">
      
      <div className="platform_tablecontainer ml-4">
        <table {...getTableProps()}>
          <thead className="text-left table_column_header h-10  border-t-2">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th width={column.width} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
      
            <tbody {...getTableBodyProps()} className="border-t-2">
             
                <>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="h-10 table_cell_value  border-b-2"
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
             
            </tbody>
        
        </table>
      </div>
      
    </div>
    )
}
export default PlatformTable;