import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { TextareaAutosize, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Label } from "../../common/inputs/myInput";
import "../defect-field-forms.scss";

const AttachmentForm = (props) => {
  const [mandatoryTFValue, setMandatoryTFValue] = useState(props.editFieldValue?.type === 'attachment' ? props.editFieldValue.mandatory :"no");
  let initialValues = {
    label: props.editFieldValue?.type === 'attachment' ? props.editFieldValue.label : "",
  };
  const validationSchema = yup.object({
    label: yup.string().required("Label is required"),
  });

  const formikDetails = useFormik({
    initialValues,

    validationSchema,
  });
  const handleMandatoryTFChange = (e) => {
    setMandatoryTFValue(e.target.value)
   
}
const haveSameData = function (obj1, obj2) {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;
  if (obj1Length === obj2Length) {
      return Object.keys(obj1).every(
          key => obj2.hasOwnProperty(key)
              && obj2[key] === obj1[key]);
  }
  return false;
}
  const handelAttachmentFormSubmit = (e) => {
    const attachmentLabelData = {
      label: formikDetails.values.label,
      mandatory: mandatoryTFValue,
      type: "attachment",
      order: props.editFieldValue["order"],
    };
    if (
        [
            attachmentLabelData.label,
          
        ].includes("")
      ) {
        props.setAddField(true);
        formikDetails.handleSubmit()
      }else if (haveSameData(props.editFieldValue, attachmentLabelData)) {
        props.MyAlert.info(`No changes were done to the ${attachmentLabelData.label} field`)
         return
     } else {
        props.addFormField(attachmentLabelData);
        props.setAddField(false);
      }
    
  };
  return (
    <div className="form-content_radioForms overflow-y-scroll ">
      <div className="my-5  px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Label
              </span>
            }
            required={true}
            error={formikDetails.errors.label && formikDetails.touched.label}
          />
        </div>
        <TextField
          error={formikDetails.errors.label && formikDetails.touched.label}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          name="label"
          id="label"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={formikDetails.handleBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.label}
        />
        {formikDetails.errors.label && formikDetails.touched.label ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.label}
          </div>
        ) : null}
      </div>

      <div className="mb-4 my-2 px-2 ml-1.5">
        <Label
          label={
            <span className="textFieldHeader text-sm  popins-medium  ">
              Mandatory
            </span>
          }
          required={true}
        />
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              className="textFieldHeader text-sm  popins-medium  "
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              value={mandatoryTFValue}
              onChange={handleMandatoryTFChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 my-5  px-2 textFieldHeader text-sm  popins-regular ">
                                <div className="flex flex-col text-xs attachmentinfoFontColor">
                                    <span className="my-2.5"> Maximum file(s) can be uploaded: 5 </span>
                                    <span className="my-2.5">Maximum file(s) size: 30 MB</span>
                                </div>
                            </div>
      <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
        <button
          onClick={() => {
            props.setAddField(false);
            props.clearEditValue({})
          }}
          type="button"
          className="w-24 gray-btn ml-3 button"
        >
          Cancel
        </button>
        <button
          type="submit"
          id="createBatch"
          className="w-24 primary-btn button"
          onClick={handelAttachmentFormSubmit}
        >
          {props.editFieldValue?.type === 'attachment' ?"Update" :"Add"}
        </button>
      </div>
    </div>
  );
};

export default AttachmentForm;
