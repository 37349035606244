import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./executiondetails.scss";
import TestcaseWarningPopUp from "./testcase-warningPopup";

function ExecutionListFilter(props) {
  const [openModal, setOpenModal] = useState(true);
  const[selectedState,setSelectedState]=useState("");
  const[applyFilter,setApplyFilter]=useState(false)
  const customStylesPage = {
    content: {
      position: "fixed",
      top: "33%",
      left: "86%",
      transform: "translate(-50%, -50%)",
      width: "25%",
      height: "25%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
    },
  };
  function handleState(value){
    console.log("handleState",value);
    setSelectedState(value)
  }
  const ClosetestcaseWarningPopUp=(state)=>{
    setApplyFilter(state)
  }
  const TestcaseApproveOrReject=()=>{
    props?.handleExectionStatus(selectedState,"PASS")
  }
  return (
    <div className={props?.label==="ExecutionListPopUp"?"exectionlist_filter_container popup_modal":"exectionlist_filter_container screen_modal"}>
      <div className="flex grid grid-cols-10 filter_divider_line">
        <div className="col-span-5">
          <div className="flex mr-2">
            <div>
              <input
                type="radio"
                checked={selectedState==="APPROVED"}
                name="APPROVED"
                value="APPROVED"
                onChange={(e) => {
                  console.log("radio", e.target.value);
                  handleState(e.target.value)
                }}
              />
            </div>
            <div className={`ml-2 ${selectedState==="APPROVED"?"selected_value":"unselected_value"}`} style={{ marginTop: "2px" }}>
              Approve
            </div>
          </div>
        </div>
        <div className="col-span-5">
          <div className="flex mr-2 ">
            <div>
              <input
                type="radio"
                checked={selectedState==="REJECTED"}
                name="REJECTED"
                value="REJECTED"
                onChange={(e) => {
                  console.log("radio", e.target.value);
                  handleState(e.target.value)
                }}
              />
            </div>
            <div className={`ml-2 ${selectedState==="REJECTED"?"selected_value":"unselected_value"}`} style={{ marginTop: "2px" }}>
              Reject
            </div>
          </div>
        </div>
      </div>
      <div className="flex  grid grid-cols-10">
        <div className="col-span-5">
          <div className="flex mr-2 gap-1">
            <div>
              <input
                type="radio"
                checked={true}
                name="PASS"
                value="PASS"
                onChange={(e) => {
                  console.log("radio", e.target.value);
                }}
              />
            </div>
            <div className="selected_value ml-2" style={{ marginTop: "2px" }}>
              Pass
            </div>
          </div>
        </div>
        <div className={`col-span-5 fliter_btn ${selectedState===""?"opacity-50":""}`}>
            <button className="apply_btn" 
            // onClick={()=>{selectedState!=="" && props?.handleExectionStatus(selectedState,"PASS")}}
             onClick={()=>{selectedState!=="" && setApplyFilter(true)}}
            >
                Apply
            </button>

        </div>
        
      </div>
      {
        applyFilter && (
          <TestcaseWarningPopUp 
          closeModal={ClosetestcaseWarningPopUp}
          confirm={TestcaseApproveOrReject}
          state={selectedState}
          />
        )
      }
    </div>
  );
}
export default ExecutionListFilter;
