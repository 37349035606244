import { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import "../project.scss";
import { ReactComponent as DownLoad } from "../../../assets/certificat_download.svg";
import { ReactComponent as LinkIcon } from "../../../assets/copy_certificat_link.svg";
import { ReactComponent as Close } from "../../../assets/close-circle.svg";
import {getCertificate} from "../../../api/api-services";
import { CommonLoader } from "../../common/common-loader";
import { ProgressContainerContext } from "../../common/inputs/ProgressContainer";
import Tooltip from "@mui/material/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
const BASE_URL = process.env.REACT_APP_BASE_URL;

function CertificateModal(props) {
  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(true);
    let [islodaing,setisLoading]=useState(false);
    let [downloading,setDownloading]=useState(false);
    let userId=JSON.parse(localStorage["fire-crowd-user"]).userName;
    let [linktitle,setLinkTitle]=useState("Copy URL");
    const customStylesPage = {
      overlay: {
        background:"rgba(90, 90, 90,0.5)", 
        
      },
      content: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        //  background: "rgba(90, 90, 90,0.5) no-repeat padding-box",
        // boxShadow: "0px 0px 11px #000000a1",
        // borderRadius: "4px",
        background:"rgb(60, 56, 56,0.5)",
        opacity: "1",
        outline:"none",
       
      },
    };
    const downloadCertificate=async()=>{
      setIsLoading(true)
      resetProgress();
        startOrResumeProgress(
          {
            stopAt: 100,
            intervalDuration: 80,
          });
        // setisLoading(true);
        setDownloading(true);
        let userId=JSON.parse(localStorage["fire-crowd-user"]).userName
        try{
          let response=await getCertificate(userId);
         
          if(response.status===200){
           
            let requestURLArr = response?.request?.responseURL.split("/");
            console.log("requestURLArr", requestURLArr[requestURLArr.length - 1]);
            
            const link = document.createElement("a");
            link.href = response?.request?.responseURL;
            link.download = requestURLArr[requestURLArr.length - 1];
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
    
          console.log("download file API",response)
    
        }catch(error){
          console.log("error")
        }
        setDownloading(false)
        // setisLoading(false)
        setIsLoading(false)
    resetProgress();
      startOrResumeProgress(
        {
          stopAt: 100,
          intervalDuration: 80,
        });
      
    
      }
    
    
  
  

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px">
        <div className="certificat_close_btn">
            <button onClick={()=>{props.closeModal(false)}}><Close/></button>
        </div>
       <div className="certificat">
        <>
        <div className="certificat_header">CONGRATULATIONS!</div>
        <div className=" flex">
            <div className="w-11/12 certificat_username">{props.details.userName}</div>
            <div className="flex">
            <CopyToClipboard text={`${BASE_URL}/v1/public/file?filePath=Certificate/${userId}/Certificate_${userId}.pdf`}
               onCopy={() =>{setLinkTitle("Copied")} }>
              <button className="certificat_download copy_url" >
                    <LinkIcon/>
                    <span class="tooltiptext">{linktitle}</span>
                </button>
              </CopyToClipboard>
               
                <button className="ml-8 certificat_download" onClick={()=>{downloadCertificate()}} disabled={downloading?true:false}>
                <DownLoad/>
                <span class="tooltiptext">Download</span>
                </button>
  

             
                </div>

        </div>
        </>
    
        
        <div className="bg-white border-2 certificat_img">
          <img src={props?.imgdata} />
           

        </div>
        <div className="certificat_footer">TESTYANTRA CROWD CERTIFICATE</div>
       </div>
       {
        islodaing? null :null
       }
     
            
         

        


        
    </Modal>
  );
}
export default  CertificateModal;
