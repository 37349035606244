import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal";

import { TextField, Tooltip } from "@mui/material";
import { TextareaAutosize } from "@material-ui/core";

import * as yup from "yup";

import { Field, FormikProvider, useFormik } from "formik";

import {
  FORM_FIELDS,
  FORM_FIELDS_ARRAY,
  MESSAGES,
} from "../../config/defectConstant";
import { checkValidationForAlphanumericSpecialCharacters } from "../../config/defectvalidation";
import { ProgressContainerContext } from "../../common/inputs/ProgressContainer";
import Rating from "@mui/material/Rating";
import {addUserFeedbackReq} from "../../../api/api-services"

function AddFeedback(props) {
  console.log(" AddFeedback",props?.TemplateData)
  const [openModal, setOpenModal] = useState(true);
 
  const [initialValues, setInitialValues] = useState({});
  const [validateSchema, setValidateSchema] = useState({});
  const [feedbackTemplateFields, setFeedbackTemplateFields] = useState([]);
  let [enablebtn, setEnableBtn] = useState(true);
  const TemplateData = localStorage.hasOwnProperty("feedback-template-details")
    ? JSON.parse(localStorage.getItem("feedback-template-details"))
    : "";

 

  
   const {
    TEXTFIELD,
    TEXTBOX,
    DROPDOWN,
    CHECKBOX,
    RADIOBUTTON,
    LINK,
    ATTACHMENT,
    DATE,
    TEXTEDITOR,
    TEXT,
    RATINGS,
  } = FORM_FIELDS;
  const {
    ALPHA_ERR_MSG,
    REQUIRED_ERR_MSG,
    NO_SPACE_AT_START_AND_END_MSG,
    YES,
    VALID_URL,
    ADD,
    EDIT,
    NO,
  } = MESSAGES;

  let tempValidateSchema = "";
  let tempInitialValues = {};

  useEffect(() => {
    createValidationSchema();
  }, []);

  const handleOnSubmit = async (values) => {
 console.log("handleOnSubmit ",values);
 let tempArray=[]
 TemplateData.templateDetails.map((item)=>{
   console.log(values[item.label])
   if(values[item.label]!=="" && values[item.label]!==null && values[item.label]!==undefined && values[item.label].trim().length!==0){
    tempArray.push({
        type:item.type,
        question:item.label,
        value:values[item.label]
    })
   }
 })
 let payload={
  projectId: props?.projectID,
  templateName: TemplateData?.templateName,
  feedBackDetails:tempArray,
  createdBy:JSON.parse(localStorage["fire-crowd-user"]).userName,
  userName:JSON.parse(localStorage["fire-crowd-user"]).name
 }
 if(tempArray.length===0){
  props?.MyAlert?.info("Atlist answer for one question");
 }else{
  try{
    let response=await addUserFeedbackReq(payload);
    if(response?.data?.status===200){
      props?.MyAlert?.success("Feedback Added Successfully");
      props.closeModal(false)
    }
   }catch(error){
    console.log("Error")
   }
 }

 
  };

  function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  }

  const createValidationSchema = (allUsers) => {
    let defectTemplateData = TemplateData?.templateDetails;
    for (let i = 0; i < defectTemplateData?.length; i++) {
      let element = defectTemplateData[i];
      let validations = [];
      if (element?.label === "State" && props.modalType === ADD) {
        element.enable = NO;
      }

      if (FORM_FIELDS_ARRAY.includes(element?.type)) {
        if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
          element.validationType = "array";
        } else if (element?.type === DATE) {
          element.validationType = DATE;
        } else if (["Modified By", "Created By"].includes(element?.label)) {
          element.validationType = "object";
        } else {
          element.validationType = "string";
        }
      }
      if ([TEXTFIELD, TEXTBOX].includes(element.type)) {
        let validationObj = {
          type: "matches",
          params: [
            checkValidationForAlphanumericSpecialCharacters,
            ALPHA_ERR_MSG,
          ],
        };
        validations.push(
          validationObj,
          {
            type: "trim",
            params: [NO_SPACE_AT_START_AND_END_MSG],
          },
          {
            type: "strict",
            params: [true],
          }
        );
        if (element?.label === "Summary") {
          validations.push(
            validationObj,
            {
              type: "max",
              params: [250, `Input cannot be more than 250 characters`],
            },
            {
              type: "min",
              params: [3, `Input cannot be less than 3 characters`],
            }
          );
        }
      }
      if (element?.minLength) {
        let validationObj = {
          type: "min",
          params: [
            element.minLength,
            `Input cannot be less than ${element.minLength} characters`,
          ],
        };
        validations.push(validationObj);
      }
      if (element?.maxLength) {
        let validationObj = {
          type: "max",
          params: [
            element.maxLength,
            `Input cannot be more than ${element.maxLength} characters`,
          ],
        };
        validations.push(validationObj);
      }
      if (element?.mandatory === YES) {
        let validationObj = {
          type: "required",
          params: [REQUIRED_ERR_MSG],
        };
        validations.push(validationObj);

        if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
          let validationObj = {
            type: "min",
            params: [1, REQUIRED_ERR_MSG],
          };
          validations.push(validationObj);
        }
      }
      if (element.type === LINK) {
        let validationObj = {
          type: "url",
          params: [VALID_URL],
        };
        validations.push(validationObj);
      }
      element.validations = validations;
      element.id = element.label;
    }
    createFormikSchema(defectTemplateData, allUsers);
  };

  function createFormikSchema(defectTemplateData, allUsers) {
    const { defectDetails } = props?.selectedDefectData;

    defectTemplateData?.forEach((item) => {
      if (item?.label === "State") {
        if (props.modalType === ADD) {
          // item.options =
          //   defectMgmtData?.defect_life_cycle_templates[0]?.states || [];
        } else if (props.modalType === EDIT) {
          // const nextState = handleDynamicStateValues(defectTemplateData.state, defectMgmtData?.defect_life_cycle_templates[0]?.state_transitions)
        }
        if (props.modalType === ADD) {
          tempInitialValues[item.label] = item?.options?.[0] || "";
        } else if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = "";
        }
      } else if (item?.label === "Assign to") {
        item.options = allUsers;
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label]?.id;
        } else {
          tempInitialValues[item.label] = "";
        }
      } else if (item?.label?.toLowerCase() === "description") {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        }
        // else if (failedSteps || envDetails) {
        //   tempInitialValues[item.label] = `{"blocks":${getDefectDescription(failedSteps, envDetails)},"entityMap":{}}`;
        // }
        else {
          tempInitialValues[item.label] = "";
        }
      }
      // else if (item?.label?.toLowerCase() === "module") {
      //   if (selectedModule) {
      //     tempInitialValues[item.label] = selectedModule;
      //   } else {
      //     tempInitialValues[item.label] = (defectDetails && defectDetails[item.label]) || "";
      //   }
      // }
      else if ([CHECKBOX, ATTACHMENT].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = [];
        }
      } else if ([TEXTFIELD, TEXTBOX, TEXT].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = item?.defaultValue || "";
        }
      } else {
        tempInitialValues[item.label] =
          (defectDetails && defectDetails[item.label]) || "";
      }
    });
    setInitialValues(tempInitialValues);
    setFeedbackTemplateFields(defectTemplateData);
    const yepSchema = defectTemplateData?.reduce(createYupSchema, {});
    tempValidateSchema = yup.object().shape(yepSchema);
    setValidateSchema(tempValidateSchema);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
      // position:"inherit",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "80%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  const handleDataChange = (e, props, id, type) => {
    formik.handleChange(e);
  };

  const  FeedbackMgmtDetailElements = (data, props, error, index) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="flex ">
              <label className="feedback_questions_text">
                {data ? (
                  <div className="">
                    {data?.mandatory === YES ? (
                      <span className="text-red-400 mr-1">&#42;</span>
                    ) : null}
                    {data?.label}
                  </div>
                ) : null}
              </label>
            </div>
          </div>
          <div className="mb-4">
            {feedbackMgmtComponents(data, props, error, index)}
          </div>
        </div>
      </>
    );
  };

  const  feedbackMgmtComponents = (data, props, error) => {
    if (data && props) {
      const value = props?.values[data.id];
      const { id, type, placeholder, maxLength } = data;

      switch (type) {
        case TEXT:
          return (
            <>
              <div className="mt-2">
                <TextareaAutosize
                  title={value}
                  name={id}
                  id={id}
                  onChange={(e) => handleDataChange(e, props, id, type)}
                  maxLength={maxLength}
                  value={value}
                  style={{ height: "60px" }}
                  className="w-full pl-2 textarea-content-style"
                  placeholder={placeholder}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
                {maxLength ? (
                  <div className="flex justify-between">
                    <div>
                      {formik.errors[id] && formik.touched[id] && (
                        <p className="MuiFormHelperText-root Mui-error mt-1 defect-error ml-2">
                          {error}
                        </p>
                      )}
                    </div>
                    <div className="mt-1 text-sm text-gray-500 text-right mr-2">
                      {value ? value.length : 0}/{maxLength}
                    </div>
                  </div>
                ) : (
                  <div>
                    {formik.errors[id] && formik.touched[id] && (
                      <p className="MuiFormHelperText-root Mui-error mt-1 defect-error ml-2">
                        {error}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </>
          );

        case RADIOBUTTON:
          return (
            <>
              <div className="w-100">
                <div className="grid grid-cols-2 gap-2">
                  {data?.options?.map((option, index) => (
                    <div key={index} className="flex" title={option}>
                      <input
                        type="radio"
                        onChange={(e) => handleDataChange(e, props, id, type)}
                        className="mr-2"
                        name={id}
                        id={id}
                        value={option}
                        checked={option === value}
                      />
                      <label className="text-xs overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                {formik.errors[id] && formik.touched[id] && (
                  <p className="MuiFormHelperText-root Mui-error defect-error mt-1 ml-1">
                    {error}
                  </p>
                )}
              </div>
            </>
          );

        case RATINGS: {
          return (
            <div>
              <Rating
                value={value}
                name={id}
                id={id}
                onChange={(e) => handleDataChange(e, props, id, type)}
              />
              {formik.errors[id] && formik.touched[id] && (
                <p className="MuiFormHelperText-root Mui-error defect-error mt-1 ml-1">
                  {error}
                </p>
              )}
            </div>
          );
        }

        default:
          return (
            <>
              <label className="label-overflow testCase"></label>
            </>
          );
      }
    }
  };
  const feedbackDetailSection = () => {
    return (
      <>
        <div className="">
          <div className="">
            {feedbackTemplateFields &&
              feedbackTemplateFields?.length > 0 &&
              feedbackTemplateFields?.map((data, index) => {
                let error =
                  formik.errors.hasOwnProperty(data.id) &&
                  formik.errors[data.id];

                return [TEXTEDITOR, TEXTBOX].includes(data?.type)
                  ? FeedbackMgmtDetailElements(data, formik, error, index)
                  :  FeedbackMgmtDetailElements(data, formik, error, index);
              })}
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px defectpostion"
    >
      <div className="flex justify-between rounded-tl-xl defect-template-modal-heading h-10 p-2">
        <div>
         
          Add Feedback
        </div>

        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModal(false);
          }}
          type="button"
          className="modal-close-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      <div className="main-content overflow-y-scroll" id="journal-scroll">
    
        <div className="px-3 pb-8">{feedbackDetailSection()}</div>

        <div className="py-2 px-3 flex justify-end gap-4 user-create-button z-50">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="w-24 gray-btn ml-3 button"
          >
            Cancel
          </button>
          <button
            className={
              enablebtn
                ? "w-24 primary-btn button"
                : "w-24 primary-btn button opacity-50"
            }
            onClick={() => {
              if (enablebtn) {
                formik.handleSubmit();
              }
            }}
          >
            {props?.modalType === ADD ? "Create" : "Update"}
          </button>
        </div>
      </div>
    </Modal>
  );
}
export default AddFeedback;
