import { TextField } from "@mui/material";
import cx from "classnames";
import style from "./inputs.module.scss";
import PropTypes from "prop-types";

export function Label({ label, required, error, showLabelError }) {
  return (
    <label
      className={cx(style["label"], "mb-3 text-xs", {
        [style["required"]]: required,
        [style["error"]]: error && showLabelError,
      })}
    >
      {label}
    </label>
  );
}

function LabeledInput({
  label,
  required,
  error,
  className,
  inputProps,
  wrapperClassName,
  showLabelError,
  ...props
}) {
  return (
    <div className={cx(wrapperClassName, style['labeled-input'])}>
      <Label
        label={label}
        required={required}
        error={error}
        showLabelError={showLabelError}
      />

      <TextField
        error={!!error}
        fullWidth
        hiddenLabel
        // variant="standard"
        variant="outlined"
        size="small"
        className={cx(
          "text-xs open-sans-regular",
          className,
          style["form-control"]
        )}
        inputProps={{
          className: cx(
            "!text-xs open-sans-regular",
            props.inputProps?.className
          ),
        }}
        FormHelperTextProps={{ className: "open-sans-regular" }}
        {...props}
      />
    </div>
  );
}

LabeledInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  wrapperClassName: PropTypes.string,
  showLabelError: PropTypes.bool,
};

LabeledInput.defaultProps = {
  label: "",
  required: false,
  error: "",
  className: "",
  inputProps: {},
  wrapperClassName: "",
  showLabelError: false,
};

Label.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  error: PropTypes.any,
  showLabelError: PropTypes.bool,
};

Label.defaultProps = {
  label: "",
  required: false,
  error: "",
  showLabelError: false,
};

export default LabeledInput;
