import { ReactComponent as Search } from "../../../assets/search.svg";
import  React ,{useEffect}from "react";
import "./reports.scss"
import { useTable, useGlobalFilter } from "react-table";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@mui/material/IconButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import NoDefectIcon from "../../../assets/reportsicon/no_reports_icon.png";
import { CircularLoader} from "../../common/CircularLoader";


const borderStyle = {
  padding: "8px 10px",
};

function useInstance(instance) {
  console.log("useInstance",instance)
  const { allColumns } = instance;

  let rowSpanHeaders = [];

  allColumns.forEach((column, i) => {
    const { id, enableRowSpan } = column;

    if (enableRowSpan !== undefined) {
      rowSpanHeaders = [
        ...rowSpanHeaders,
        { id, topCellValue: null, topCellIndex: 0 },
      ];
    }
  });

  Object.assign(instance, { rowSpanHeaders });
}

export default function ReportsRowSpanCommonTable(props) {
  console.log("ReportsRowSpanCommonTable",props)
  let [devices, setdevices] = React.useState([]);
  let [closeSearchField, setCloseSearchField] = React.useState(false);
  let [selectedTab,setSelectedTab]=React.useState(true);
  let[selectedState,setSelectedState]=React.useState();
  let [dataLoding,setDataLoaing]=React.useState()
  let [filedownloading,setDownloadingFile]=React.useState()


 useEffect(()=>{
  setDownloadingFile(props?.downloadingFile)

 },[props?.downloadingFile])
  useEffect(()=>{
    setDataLoaing(props?.dataloading)
  },[props?.dataloading])
  let getAllDevices = () => {
    const newData = [];
    props?.tableData?.forEach((brandDetails) => {
      if (props.detailsSection === "devices") {
        brandDetails.devicelist?.forEach((device) => {
          newData.push({
            brand: brandDetails.brand,
            brandtotalCount: brandDetails.brandtotalCount,
            ...device,
          });
        });
      } else if (props.detailsSection === "os") {
        brandDetails.oslist?.forEach((os) => {
          newData.push({
            os: brandDetails.os,
            brandtotalCount: brandDetails.ostotalCount,
            ...os,
          });
        });
      } else if (props.detailsSection === "browser") {
        brandDetails.browserlist?.forEach((eachbrowser) => {
          newData.push({
            browser: brandDetails.browser,
            brandtotalCount: brandDetails.browsertotalCount,
            ...eachbrowser,
          });
        });
      }
    });
    setdevices([...newData]);
  };
  React.useEffect(() => {
    getAllDevices();
  }, [props?.tableData, props.detailsheaders]);
  const data = React.useMemo(() => devices, [devices]);
  const columns = React.useMemo(
    () => [...props.detailsheaders],
    [props.detailsheaders]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    rowSpanHeaders,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, (hooks) => {
    hooks.useInstance.push(useInstance);
  });
  const { globalFilter } = state;
  function SelectedTabData(val){
       
    console.log("tab",val)
     if(val==="Approved"){
         setSelectedTab(true)
      setSelectedState("CLOSED")
     }else{
         setSelectedTab(false)
          setSelectedState("REJECTED")  
     }
     
   }
  return (
 
    <div className="card_section_tabel_container spanedreporttable" style={{borderTop:props.detailsSection === "devices"?"2px solid #3B36DC":props.detailsSection === "os" ?" 2px solid #F89751":"2px solid #048FFF"}}>
     <div className="grid grid-cols-12 flex content-between">
        <div className="col-span-6 heading pl-3">
        {props?.countHeader}
        </div>
        <div className="grid grid-cols-12 col-span-6 flex justify-end defect_common_tabel_header">
        <div className="search_section col-span-5">
          <div className="adminsearch">
           
            <div>
              <input
                type="text"
                className="searchfield"
                 value={globalFilter || ""}
                autoFocus={true}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
                placeholder="Search"
              />
            </div>
            <div className="search_icon">
              <Search />
            </div>
          </div>
        </div>
        <div className={`col-span-2 report_Download_btn ${devices?.length===0?"opacity-50":"opacity-100"}`}>
        <button className="flex" onClick={()=>{if(devices?.length!==0){props?.downloadFile(props?.detailsSection)}}}>
                <div className="mt-1.5 ml-2.5">
                Download
                </div>
                <div className="ml-2">
                    <FileDownloadOutlinedIcon/>
                </div>
               
               

            </button>
        </div>
       

       
        </div>
      </div>

    <div className="card_table_container spanedreporttable mt-2">
          <table {...getTableProps()} className="w-full">
            <thead className={`text-left ${(rows.length === 0 || data?.length === 0)?"":"revert "}`}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="defect_table_header">
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      width={column.width}
                      className={`${
                        column.Header === "OPERATING SYSTEM" ||
                        column.Header === "VERSIONS" ||
                        column.Header === "BROWSERS" ||
                        column.Header === "DEVICES" ||
                        column.Header === "MODEL - VERSION"
                          ? "text-left pl-3"
                          : "text-center"
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {
              dataLoding===false? (
                <tbody {...getTableBodyProps()} className="contents">
                {
                  (rows.length === 0 || data?.length === 0)?( <div className="noreportIconCenter">
                  {/* <NoDefectIcon /> */}
                  <img  alt="warning" src={NoDefectIcon}/>
                  <div className="text_msg">No Relevant Data Is Found !</div>
                </div>):(
                    <>
                    {rows.map((row, i) => {
                      prepareRow(row);
      
                      for (let j = 0; j < row.allCells.length; j++) {
                        let cell = row.allCells[j];
                        let rowSpanHeader = rowSpanHeaders.find(
                          (x) => x.id === cell.column.id
                        );
      
                        if (rowSpanHeader !== undefined) {
                          if (
                            rowSpanHeader.topCellValue === null ||
                            rowSpanHeader.topCellValue !== cell.value
                          ) {
                            cell.isRowSpanned = false;
                            rowSpanHeader.topCellValue = cell.value;
                            rowSpanHeader.topCellIndex = i;
                            cell.rowSpan = 1;
                          } else {
                            rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
                            cell.isRowSpanned = true;
                          }
                        }
                      }
                      return null;
                    })}
                    {rows.map((row) => {
                      return (
                        <tr {...row.getRowProps()} className="revert h-10  ">
                          {row.cells.map((cell) => {
                            if (cell.isRowSpanned) return null;
                            else
                              return (
                               
                                <td
                                  width={cell?.column?.width}
                                  rowSpan={cell.rowSpan}
                                  {...cell.getCellProps()}
                                  className={`datatrimmer reportTablecell rowSpanedTableCell  popins ${
                                    cell.column.Header === "OPERATING SYSTEM" ||
                                    cell.column.Header === "VERSIONS" ||
                                    cell.column.Header === "BROWSERS" ||
                                    cell.column.Header === "DEVICES" ||
                                    cell.column.Header === "MODEL - VERSION"
                                      ? "text-left pl-3"
                                      : "text-center "
                                  } ${ cell.column.Header === "OPERATING SYSTEM" ||cell.column.Header === "DEVICES" || cell.column.Header === "BROWSERS"?"border-r":""}`}
                                >
                                  
                                  <div
                                    className={`${
                                      cell.rowSpan ? "rowSpan_darkBlack" : null
                                    }`}
                                  >
                                    {cell.render("Cell")}
                                  </div>
                                  {cell.rowSpan ? (
                                    <div className="rowSpan_count">
                                      (Total {props.detailsSection} count:{" "}
                                      {row.original.brandtotalCount})
                                    </div>
                                  ) : null}
                                </td>
                              );
                          })}
                        </tr>
                      );
                    })}
                    </>
                  )
                }
               
              </tbody>
              ):(
                <div className="mt-24">
                  <CircularLoader/>
                </div>
              )
            }
           
          </table>
         
        </div>
    


   
  </div>
  );
}
