import { useState,useEffect } from "react";
import "../project/project.scss";
import Modal from "react-modal";
import {gettestcaseAssigneeListBymodule,gettestcaseAssigneeListByTestcase} from "../../api/api-services";
import { ReactComponent as Search } from "../../assets/search.svg";



function AssigneeUserListModal(props) {
  let projectID=JSON.parse(localStorage.getItem("selected-project")).project.fireFlinkProjectId;
  let licenceID=JSON.parse(localStorage.getItem("selected-project")).licenceID;
  let [userList,setUserList]=useState([])
  useEffect(()=>{
    if(props?.usersAssignedList?.type){
      getAllAssigneeListByScript()
    }else{
      getAllAssigneeListByModule();
    }
    console.log("getAllAssigneeListByScript",props)
     
     
  

  },[props])
  async function getAllAssigneeListByModule(){
 
    let moduleID=props?.usersAssignedList?._key
    try{
      let response=await gettestcaseAssigneeListBymodule(licenceID, projectID,moduleID);
      console.log("getAllAssigneeList",response)
      if(response?.data?.responseCode===200){
        setUserList(response?.data?.responseObject)
        setFilterData(response?.data?.responseObject)
      }

    }catch(error){
      console.log("Error")
    }
  }

  async function getAllAssigneeListByScript(){
 
    let moduleID=props?.usersAssignedList?.parentId
    let ScriptID=props?.usersAssignedList?._key
    try{
      let response=await gettestcaseAssigneeListByTestcase(licenceID, projectID,moduleID,ScriptID);
      console.log("getAllAssigneeList",response)
      if(response?.data?.responseCode===200){
        setUserList(response?.data?.responseObject)
        setFilterData(response?.data?.responseObject)
      
      }

    }catch(error){
      console.log("Error")
    }
  }
    const [openModal, setOpenModal] = useState(true);
    const [filterData, setFilterData] = useState([]);
    console.log(props)
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "80%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  function handleSearch(e) {
    console.log("filterdata",filterData)
    let searchValue = e.target.value;
    console.log("searchValue",searchValue)
    if (searchValue) {
      let tempSimilarArray = filterData.filter((proVal, index) => {
        console.log("proVal",proVal);
        let email= proVal.email;
        let name= proVal.username;
        
        
  
        let similarExistEmail = email.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistName = name.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        
  
        return (
          similarExistEmail || similarExistName
         
        );
      });
       setUserList(tempSimilarArray);
    } else {
       setUserList(filterData);
    }
  }
 
  
  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex justify-between  rounded-tl-xl create-modal-heading h-10 px-2 items-center">
        <div>Assignee List - {userList?.length}</div>
        <div className="assignelist-search">
        <div className="adminsearch">
            <div>
              <input
                type="text"
                className="searchfield"
                //  value={globalFilter || ""}
                autoFocus={false}
                onChange={(e) => {
                //   setGlobalFilter(e.target.value);
                 handleSearch(e)
                }}
                placeholder="Search"
              />
            </div>
            <div className="search_icon">
              <Search />
            </div>
          </div> 
        </div>
        <span className="flex items-center">
          <button
            onClick={() => {
              setOpenModal(false);
              props.functiontypeModalClose(false);
            }}
            type="button"
            className="modal-close-btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>
      <form >
       
          <div className="px-2 assignee-list-container">
         
           
            {
                userList.map((user,index)=>{
                    return(<div className="p-2 fontPoppinsMediumMd cursor-default  flex grid grid-cols-12">
                     <div className="col-span-2 text-blue-700">{index+1}</div>
                    
                     <div className="col-span-5 text-blue-700">
                      <div className="datatrimmer w-32" title={user.username}>{user.username}</div>
                      </div>
                    
                     <div className="col-span-5 useremail ">
                      <div className="datatrimmer w-48" title={user.email}>{user.email}</div>
                      </div>
                      </div>)
                })
            }
            
         
           
           
            <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
              <button
                onClick={() => {
                  setOpenModal(false);
                  props.functiontypeModalClose(false);
                }}
                type="button"
                className="w-24 gray-btn ml-3 button"
              >
                Cancel
              </button>
            
            </div>
          </div>
       
       
      </form>
     
    </Modal>
  );
}

export default AssigneeUserListModal;




