import React, { useState, useEffect } from "react";
import AllBrowsersContent from "./allBrowsersContent";
import { ReactComponent as Windows } from "../../assets/platform/windows.svg";
import { ReactComponent as NoReportsIcon } from "../../assets/noReportsAvailable.svg";
import { ReactComponent as RefreshIcon } from "../../assets/platform/refresh-btn.svg";
import NoDefectIcon from "../../assets/reportsicon/no_reports_icon.png";

import "./client-list.scss";
import AllWebVersions from "./allWebVersions";
import ClientMobileSection from "./client-mobile-section";
import MobileDeviceContent from "./mobile-device-content";
import { getReportsClientData } from "../../api/api-services";
import { CircularLoader } from "../common/CircularLoader";

function ClientReport(props) {
  const [browserInfo, setBrowserInfo] = useState([]);
  const [webClientReport, setWebClientReport] = useState([]);
  const [osInfo, setOsInfo] = useState();
  const [refreshed, setRefreshed] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState();
  const [allOsInfo, setAllOsInfo] = useState();
  const [webInfo, setWebInfo] = useState();
  const [mobileInfo, setMobileInfo] = useState();
  const [ClientDataLoading,setClientDataloading]=useState()
  let project = JSON.parse(localStorage["selected-project"]);
  let typeOfProject=project?.project?.projectType?.others?.typeOfProject;
  let [displayapproverdefect, setDisplayapproverdefect] = useState(true);
  // let projectState="CLOSED"
  let [projectState, setProjectState] = useState(
    displayapproverdefect || !props.allowRejectedDefect ? "CLOSED" : "REJECTED"
  );
  useEffect(() => {
    if (props?.reportsData?.defectOverview?.browserReportsList?.length > 0) {
      // setBrowserInfo([...props?.reportsData?.defectOverview?.browserReportsList])
    }
  }, [props.reportsData]);
  const selectOsBrowser = (data) => {
    setBrowserInfo([...data]);
  };
  const selectAllOs = (data) => {
    console.log(data);
    setAllOsInfo([...data]);
  };
  const selectedWebInfos = (data) => {
    setWebInfo(data);
  };
  const selectedMobileInfos = (data) => {
    console.log(data, "mobInfo");
    setMobileInfo(data);
  };
  const setRefreshBack = (data) => {
    setRefreshed(data);
  };
  const getClientData = async () => {
    let response;
    setClientDataloading(true)
    try {
      response = await getReportsClientData(
        project.project.fireFlinkProjectId,
        project.licenceID,
        projectState
      );
      if (response.status === 200) {
        setOsInfo(response.data.data?.webClientReport?.osList);
        setBrowserInfo(response?.data?.data?.webClientReport?.totalBrowser);
        setWebClientReport(response?.data?.data?.webClientReport);
        if (response?.data?.data?.mobileClientReport) {
          setDeviceInfo(response?.data?.data?.mobileClientReport);
          setAllOsInfo(response?.data?.data?.mobileClientReport?.osList);
        }
      }
    } catch (err) {
      console.log(err);
    }
    setClientDataloading(false)
  };
  useEffect(() => {
    getClientData();
  }, [displayapproverdefect, projectState]);
  useEffect(() => {
    if (!props.allowRejectedDefect) {
      setProjectState("CLOSED");
      setDisplayapproverdefect(true);
    }
  }, [props.allowRejectedDefect, projectState]);
  return (
    <div className="client-report mb-6">
     
      {!osInfo && !deviceInfo?.deviceBrand?.length ? (
        <div className="noReport_container">
          <div className="grid grid-cols-12 p-3">
            <div className="heading header-style">Clients</div>
          </div>

          
           <div className="noreportIconCenter">
                  {/* <NoDefectIcon /> */}
                  <img  alt="warning" src={NoDefectIcon}/>
                  <div className="text_msg">No Relevant Data Is Found !</div>
                </div>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-12 p-3">
            <div className="heading header-style col-span-8">Clients</div>
            <div className="col-span-4 flex justify-end">
            {props.allowRejectedDefect ? (
                <div className="pl-3">
                  <span
                    className={`cursor-pointer client_reports_tab ${
                      displayapproverdefect ? "selected_tab" : "unselcted_tab"
                    }   pb-2 mr-2 h-8`}
                    onClick={() => {
                      setDisplayapproverdefect(true);
                      setProjectState("CLOSED");
                      setWebInfo(undefined);
                      setMobileInfo(undefined);
                    }}
                  >
                    Approved Defects
                  </span>
                  <span className="client_reports_tab unselcted_tab ">|</span>
                  <span
                    className={`h-8 pb-2 ml-2 client_reports_tab cursor-pointer ${
                      !displayapproverdefect ? "selected_tab" : "unselcted_tab"
                    } `}
                    onClick={() => {
                      setDisplayapproverdefect(false);
                      setProjectState("REJECTED");
                      setWebInfo(undefined);
                      setMobileInfo(undefined);
                    }}
                    id="rejeced"
                  >
                    Rejected Defects
                  </span>
                </div>
              ) : null}
            </div>
          </div>
         {
           ClientDataLoading===false ?(
            <>
             {osInfo?.length > 0 ? (
            <div>
              {project?.project?.projectType.webAndMobile !== null ? (
                <div className="heading grid grid-cols-12 px-3 py-2">
                  <div
                    className="col-span-1 text-base"
                    style={{ fontFamily: "poppins-Regular" }}
                  >
                    Web
                  </div>
                  {/* <div className="col-span-11">
                    {" "}
                    <hr className="customizeHr"></hr>
                  </div> */}
                </div>
              ) : null}
             
              <div className={(project?.project?.projectType?.others!==null && typeOfProject==="Standalone_Application")?"grid grid-cols-12 gap-2 grid-flow-row p-3 ":"grid grid-cols-2 gap-2 grid-flow-row p-3 "}>
                <div className={`client_content_headings  web_versions_container ${(project?.project?.projectType?.others!==null && typeOfProject==="Standalone_Application")?"col-span-12":""}`}>
                  <p className="content_subheadings">OS & OS Versions</p>
                  <div className="mt-2">
                    <AllWebVersions
                      osInfo={osInfo}
                      selectOsBrowser={selectOsBrowser}
                      selectedWebInfos={selectedWebInfos}
                      webClientReport={webClientReport}
                      webInfo={webInfo}
                    />
                  </div>
                </div>

                {
                  !(project?.project?.projectType?.others!==null && typeOfProject==="Standalone_Application") && (
                    <div className="">
                    <div
                      className="refreshBtn_web cursor-pointer"
                      onClick={() => {
                        selectOsBrowser(webClientReport?.totalBrowser);
                        setWebInfo(undefined);
                      }}
                    >
                      {" "}
                      <RefreshIcon />
                    </div>
                    <div>
                      <AllBrowsersContent
                        browserInfo={browserInfo}
                        reportsData={props.reportsData}
                        webClientReport={webClientReport}
                        webInfo={webInfo}
                      />
                    </div>
                  </div>
                  )
                }
              
               
              </div>
            </div>
          ) : null}
          {/* -----------------------------Mobile Section-------------------------------- */}
          {deviceInfo?.deviceBrand?.length > 0 ? (
            <div>
              {project?.project?.projectType.webAndMobile !== null ? (
                <div className="heading grid grid-cols-12 p-3  ">
                  <div
                    className="col-span-1 text-base"
                    style={{ fontFamily: "poppins-Regular" }}
                  >
                    Mobile
                  </div>
                  {/* <div className="col-span-11">
                    {" "}
                    <hr className="customizeHrMobileSection"></hr>
                  </div> */}
                </div>
              ) : null}
              <div className="grid grid-cols-2 gap-2 grid-flow-row p-3 ">
                <div className="client_content_headings mobile_versions_container">
                  <p className="content_subheadings">
                    Device & Device Versions
                  </p>
                  <div className="mt-2">
                    <MobileDeviceContent
                      deviceInfo={deviceInfo}
                      browserInfo={browserInfo}
                      mobileInfo={mobileInfo}
                      selectAllOs={selectAllOs}
                      setRefreshBack={setRefreshBack}
                      refreshed={refreshed}
                      selectedMobileInfos={selectedMobileInfos}
                    />
                  </div>
                </div>
                <div className="">
                  <div
                    className="refreshBtnMobileSection cursor-pointer"
                    onClick={() => {
                      selectAllOs(deviceInfo?.osList);
                      setMobileInfo(undefined);
                      setRefreshed(true);
                      console.log(deviceInfo);
                    }}
                  >
                    {" "}
                    <RefreshIcon />
                  </div>
                  <div>
                    <ClientMobileSection
                      allOsInfo={allOsInfo}
                      deviceInfo={deviceInfo}
                      mobileInfo={mobileInfo}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
            </>
           ):(
            <div className="h-64 mt-28">
              <CircularLoader/>
            </div>
           )
         }
         
        </>
      )}
    </div>
  );
}

export default ClientReport;
