import React, { useState, useEffect, useContext } from "react";
import { useTable, useGlobalFilter } from "react-table";
import { ReactComponent as Search } from "../../../assets/search.svg";
import "./reports.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import { ReactComponent as NoDefectIcon } from "../../../assets/noDefectsAvailable.svg";
import NoDefectIcon from "../../../assets/reportsicon/no_reports_icon.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  getDefectsData,
  getUserDetailsByEmailID,
  getAllTesterReq,
  getAllDefectTemplate,
  DownloadDefectExcleFile,
  getDefectsDataCount,
  getTemplateById,
  getAllModuleByState
} from "../../../api/api-services";
import DefectFilterModal from "../defect-filter-modal";
import UserDeatilsPopup from "../../modal/user-deatils";
// import DefectsDeatilsPopup from "@src/pages/modal/defects-deatils-popup";
import DefectsDeatilsPopup from "../../modal/defects-deatils-popup";
import NewFilters from "./newFilter";
import { CircularLoader } from "../../common/CircularLoader";
let filterBasePayLoad = {
  segmentSearchFilters: {
    severity: null,
    priority: null,
    modules: null,
    locations: null,
    createdOn: null,
  },
  webSearchFilters: {
    os: null,
    osVersion: null,
    browser: null,
    browserVersion: null,
  },
  mobileSearchFilters: {
    os: null,
    osVersion: null,
    browser: null,
    browserVersion: null,
    deviceBrand: null,
    deviceName: null,
    deviceType: null,
  },
};
let payLoad = JSON.parse(JSON.stringify(filterBasePayLoad));
let defectState = "CLOSED";
let DefectPayload = {};

function DefectLogTable(props) {
  let [selectedTab, setSelectedTab] = useState(true);
  let [myDefectPageNo, setMyDefectPageNo] = useState(0);
  let [mydefectlastpage, setMyDefectlastpage] = useState();
  let [defectsTableData, setdefectsTableData] = useState([]);
  const [severity, setSeverity] = useState(null);
  const [priority, setPriority] = useState(null);
  const [module, setModule] = useState(null);
  const [location, setLocation] = useState(null);
  const [webPlatform, setWebPlatform] = useState(null);
  const [mobilePlatform, setMobilePlatform] = useState(null);
  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  let loggedinUser = JSON.parse(localStorage["user-privilege"]);
  let [defects, setdefects] = useState([]);
  let [selectedFilters, setSelectedFilters] = useState({});
  let [showFilter, setShowFilter] = useState(false);
  let [defectsPopUp, setDefectsPopUp] = useState(false);
  let [defectsdetails, setDefectsdetails] = useState();
  let [userDetails, setUserDetails] = useState();
  let [userPopup, setUserPopup] = useState(false);
  let [defectTemplatedetails,setDefectTempalteDetails]=useState()
  const crowdProjectId = JSON.parse(
    localStorage.getItem("selected-project")
  ).id;
  const emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
  let [selectedState, setSelectedState] = useState();
  let [downloadData,setDownloadData]=useState(false);
  let [defectCount,setDefectsCount]=useState(0);
  let [defectDataLoading,setDefectDataLoading]=useState()
  if (loggedinUser?.role?.includes("_")) {
    loggedinUser.role = loggedinUser.role.replace("_", "-");

  }
  useEffect(() => {
    payLoad = JSON.parse(JSON.stringify(filterBasePayLoad));
    setSelectedTab(true);
    getAllDefects(payLoad, false, 0, "CLOSED");
    getAllUsersList();
    getDefaultDefectTemplate();
    getAllModule()
  }, [props?.allowRejectedDefect]);
  function SelectedTabData(val) {
    console.log("tab", val);
    setGlobalFilter("");
   
    if (val === "Approved") {
      if(defectsTableData.length!==0){
        let Ele = document.getElementById("defectlog");
        Ele.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
       }
      setSelectedTab(true);
      setSelectedState("CLOSED");
      setMyDefectlastpage();
      setMyDefectPageNo(0);

      getAllDefects(DefectPayload, false, 0, "CLOSED");
    } else {
      if(defectsTableData.length!==0){
        let Ele = document.getElementById("defectlog");
        Ele.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
       }
      setSelectedTab(false);
      setSelectedState("REJECTED");
      setMyDefectlastpage();
      setMyDefectPageNo(0);
      getAllDefects(DefectPayload, false, 0, "REJECTED");
    }
  }
  const handlescroll = (event) => {
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
      if (mydefectlastpage === false && globalFilter===undefined) {
        getAllDefects(payLoad, false, myDefectPageNo, defectState);
      }
    }
  };
  const getAllDefectsCount = async (payload, filtersAdded, defectPageNo, state) => {
    try {
      let responseObject = await getDefectsDataCount(
        projectId,
        licenseID,
        payload,
        state
      );
      console.log("responseObject count", responseObject);
      if (responseObject?.data?.responseCode === 200) {
        setDefectsCount(responseObject?.data?.totalDefectCount);
      }
    } catch (err) {
      console.log("error");
    }
  }
  const getAllDefects = async (payload, filtersAdded, defectPageNo, state) => {
    let page = defectPageNo,
      size = 10;
      if(defectPageNo===0){
        setDefectDataLoading(true)
      }
    try {
      let responseObject = await getDefectsData(
        projectId,
        licenseID,
        payload,
        page,
        size,
        // "CLOSED"
        state
      );
      console.log("responseObject", responseObject.data.responseObject);
      defectState = state;
      DefectPayload = payload;
      if (responseObject?.data?.responseCode === 200) {
        setMyDefectlastpage(responseObject?.data?.lastPage);
        setMyDefectPageNo(page + 1);
        // defectState = state;
        // DefectPayload = payload;
        console.log("defectState 123", defectState);
        setdefects(responseObject.data.responseObject);
        data = [...responseObject.data.responseObject];
        let tempArray = [];
        data.map((defect, index) => {
          tempArray.push({
            bugId: defect?.defectDetails?.ID,
            bugSummary: defect.defectDetails?.Summary,
            createdBy: defect?.defectDetails["Created By"]?.name,
            Details: defect,
          });
        });
        if (page === 0 || filtersAdded) {
          setdefectsTableData([...tempArray]);
           getAllDefectsCount(payload, filtersAdded, defectPageNo, state);
        } else {
          let defectArray = [];
          defectArray = defectsTableData.concat(tempArray);
          setdefectsTableData(defectArray);
        }
      } else {
        setdefectsTableData([]);
         setDefectsCount(0)
      }
    } catch (err) {
      console.log("error");
    }
    setDefectDataLoading(false)
  };

  const getAllModule=async ()=>{
  try{
    let response=await getAllModuleByState(licenseID,projectId,crowdProjectId)
    console.log("getAllModuleByState",response)
    if(response.data.responseCode===200){
      setModule(response?.data?.responseObject)

    }

  }catch(error){
    console.log("error")
  }
  }
  
  

  const getAllDefectsWithFilter = (payload, filtersAdded, filters, callApi) => {
    console.log("defectState", defectState);
    payLoad = payload;
    setMyDefectPageNo(0);
    setSelectedFilters(filters);
    if (callApi) {
      getAllDefects(payLoad, filtersAdded, 0, defectState);
      localStorage.setItem("currentReportTab", JSON.stringify("DefectReports"));
    }
  };

  const getAllUsersList = async () => {
    try {
      let response = await getAllTesterReq(crowdProjectId, emailId);

      if (response?.data) {
        console.log("users data", response.data);
        let tempCityArray = [],
          tempMobile = [],
          tempWeb = [];
        response.data.map((obj) => {
          if (obj.configuration !== null) {
            obj.configuration.configPlatformDetails.map((obj1) => {
              if (
                obj1.deviceType === "smartphone" ||
                obj1.deviceType === "tablet"||
                obj1.deviceType === "tv"||
                obj1.deviceType === "SmartWatch"
              ) {
                tempMobile.push(obj1);
              } else {
                tempWeb.push(obj1);
              }
              if (!tempCityArray.includes(obj1.city)) {
                tempCityArray.push(obj1.city);
              }
            });
          }
        });
        setWebPlatform(tempWeb);
        setMobilePlatform(tempMobile);
        setLocation(tempCityArray);
      }
    } catch (error) {
      console.log("error");
    }
  };
  const getDefaultDefectTemplate = async (e) => {
    try {
      // segmentDataHandler(props.data);
      let response = await getAllDefectTemplate(projectId, licenseID);
      if (response?.data?.responseCode === 200) {
        localStorage.setItem(
          "Default-defect-template",
          JSON.stringify(response.data.responseObject.defaultDefectTemplate)
        );
        let tempDetails =
          response.data.responseObject.defaultDefectTemplate.defectDetails;

        tempDetails.map((obj, i) => {
          if (obj.label === "Severity") {
            setSeverity(
              obj.options !== undefined && obj.options.length !== 0
                ? obj.options
                : null
            );
          }
          if (obj.label === "Priority") {
            setPriority(
              obj.options !== undefined && obj.options.length !== 0
                ? obj.options
                : null
            );
          }
        });
      }
    } catch (error) {
      console.log("error");
    }
  };
  const getUserDetails = async (e) => {
    let emailId = e.Details.defectDetails.history[0].userEmail;
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    try {
      let response = await getUserDetailsByEmailID(projectId, emailId);
      if (response?.status === 200){
        setUserDetails(response.data);
      setUserPopup(true);
     
      } 
      console.log("getUserDetails", response);
    } catch (err) {
      console.log("error");
    }
  };
  const getTemplateDetails=async (e)=>{
    console.log("eeee",e.defectTemplateId)
    console.log("eeee Details",e)
    let fireFlinkProjectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
    let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
    let templateID=e.defectTemplateId;
    try{
      let response =await getTemplateById(fireFlinkProjectId, templateID, licenseId);
      if(response.data.responseCode===200){
        
        setDefectTempalteDetails(response)
        setDefectsdetails(e);
        setDefectsPopUp(true);
        
      }else{
        props.MyAlert.info("Something went wrong")
      }
      
  
    }catch(error){
      console.log("errror")
    }
   
   }
   function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
    setDownloadData(false)
  }
  const downloadExcleFile=async ()=>{
    setDownloadData(true)
    let filename=selectedTab?"Approved_Defects.xlsx":"Rejected_Defects.xlsx";
    let state=selectedTab?"CLOSED":"REJECTED";
    let filter=Object.keys(selectedFilters).length === 0?{}:DefectPayload
      try{
        let response=await DownloadDefectExcleFile(projectId,licenseID,state,filter);
        console.log("response file", response);
        if (response.status === 200) {
          saveFileFromBlob(response.data, filename);
        }else{
          setDownloadData(false)
        }
  
      }catch(err){
        console.log("error")
  
      }
    }
  function CloseDefectFilterModal(state) {
    setShowFilter(state);
  }
  function CloseTesterModal(state) {
    setUserPopup(state);
  }

  function CloseDefectModal(state) {
    setDefectsPopUp(state);
  }

  let data = React.useMemo(() => defectsTableData, [defectsTableData]);
  let columns = React.useMemo(
    () => [
      {
        Header: "BUG ID",
        accessor: "bugId",
        width: "8%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              getTemplateDetails(e.cell.row.original.Details);

            }}
            className="reportTablecell popins cursor-pointer	"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "BUG SUMMARY",
        accessor: "bugSummary",
        width: "49.7%",
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },

      {
        Header: "CREATED BY",
        accessor: "createdBy",
        width: "12.3%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();

              getUserDetails(e.cell.row.original);
            }}
            className="reportTablecell popins cursor-pointer datatrimmer w-32"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;

  return (
    <div className="reports_defectlog_table_container">
      <div className="grid grid-cols-12 flex content-">
        <div className="col-span-6 heading pl-3">Defect Log - {defectCount}</div>
        <div
          className={`grid grid-cols-12 col-span-6 flex justify-end ${
            props?.allowRejectedDefect === true
              ? ""
              : "defect_common_tabel_header"
          } `}
        >
          <div className="search_section col-span-3">
            <div className="defectlog_search">
              <div>
                <input
                  type="text"
                  className="searchfield"
                  value={globalFilter || ""}
                  autoFocus={false}
                  onChange={(e) => {
                    setGlobalFilter(e.target.value);
                  }}
                  placeholder="Search"
                />
              </div>
              <div className="search_icon">
                <Search />
              </div>
            </div>
          </div>
          <div className={`col-span-2 defeclog_Download_btn ${downloadData===true || defectsTableData?.length===0?"opacity-50":"opacity-100"}`}>
            <button className="flex" onClick={()=>{if(downloadData===false && defectsTableData?.length!==0){downloadExcleFile()}}}>
              <div className="mt-1.5 ml-2">Download</div>
              <div className="ml-1.5">
                <FileDownloadOutlinedIcon />
              </div>
            </button>
          </div>
          <div className="col-span-2 defectlog_filter_btn">
            <button
              className="flex"
              onClick={() => {
                CloseDefectFilterModal(true);
              }}
            >
              <div className="mt-1.5 ml-2.5">Filter</div>
              <div className="ml-2">
                {/* <FileDownloadOutlinedIcon/> */}
                <FilterListIcon />
              </div>
            </button>
          </div>
          <div
            className={`col-span-5 table_tabs_text ${
              props?.allowRejectedDefect === true ? "flex" : "hidden"
            }`}
          >
            <div
              className={selectedTab ? "selected_tab" : "unselected_tab"}
              onClick={() => {
                if(selectedTab===false){
                  SelectedTabData("Approved");
                }
                
              }}
            >
              Approved Defects
            </div>
            <div className="mx-1">|</div>
            <div
              className={selectedTab ? "unselected_tab" : "selected_tab"}
              onClick={() => {
                if(selectedTab===true){
                  SelectedTabData("Rejected");
                }
               
              }}
            >
              Rejected Defects
            </div>
          </div>
        </div>
      </div>

      <div className="card_table_container mt-2">
      <table {...getTableProps()} className=" overflow-x-auto">
            <thead className="text-left">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="defect_table_header">
                  {headerGroup.headers.map((column) => (
                    <th
                      width={column.width}
                      {...column.getHeaderProps()}
                      className={` reportTableheader popins-semi-bold text-lg font-semibold ${
                        column.Header === "BUG ID"
                          ? "text-left pl-3"
                          : "text-left pl-3"
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {
              defectDataLoading===false?(
                <>
                 {data.length === 0 ? (
               <div className="noreportIconCenter">
               <img  alt="warning" src={NoDefectIcon}/>
               <div className="text_msg">No Relevant Data Is Found !</div>
             </div>
            ) : (
              <div>
                <tbody
                  {...getTableBodyProps()}
                  // className="border-t-2"
                   className="defect_table_body"
                  onScroll={handlescroll}
                >
                  {rows.length > 0 ? (
                    <>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="h-10 " id="defectlog" >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  width={cell?.column?.width}
                                  {...cell.getCellProps()}
                                  className={`datatrimmer reportTablecell ${
                                    cell.column.Header === "BUG ID" 
                                    // || cell.column.Header === "CREATED BY"
                                      ? "popins-semi-bold"
                                      : "popins"
                                  } ${
                                    cell.column.Header === "BUG ID"
                                      ? "text-left pl-3"
                                      : "text-left pl-3"
                                  }`}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                     
                      <div className="noreportIconCenter nodefect">
                    <img  alt="warning" src={NoDefectIcon}/>
                    <div className="text_msg">No Relevant Data Is Found !</div>
                  </div>
                    </>
                  )}
                </tbody>
              </div>
            )}
                </>
              ):(
                <div className="mt-28">
                  <CircularLoader/>
                </div>
              )
            }

           
          </table>
      </div>
      {showFilter && (
        <NewFilters
        CloseDefectFilterModal={CloseDefectFilterModal}
        data={defects}
        Severity={severity}
        Priority={priority}
        Module={module}
        Location={location}
        web={webPlatform}
        mobile={mobilePlatform}
        filterApply={getAllDefectsWithFilter}
        filters={selectedFilters}
        />
      )}
       {userPopup && (
        <UserDeatilsPopup
        CloseTesterModal={CloseTesterModal}
        testerDeatils={userDetails}
        />
      )}
      {defectsPopUp && (
        <DefectsDeatilsPopup
          CloseDefectModal={CloseDefectModal}
          SelectedDefect={defectsdetails}
          defectTemplatedetails={defectTemplatedetails}
        />
       
      )}
    </div>
  );
}
export default DefectLogTable;
