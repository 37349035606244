import React, { useState } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup'
import { TextareaAutosize, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Label } from "../../common/inputs/myInput";
import "../defect-field-forms.scss";

const TextForm = (props) => {
    const [enableTFValue, setEnableTFValue] = useState(props.editFieldValue?.type === 'text' ? props.editFieldValue.enable :"yes");
  const [mandatoryTFValue, setMandatoryTFValue] = useState(props.editFieldValue?.type === 'text' ? props.editFieldValue.mandatory :"no");
  
  const [checkForAlphanumeric, setCheckForAlphanumeric] = useState("");
  let pattern = /^(?! ).*[^ ]$/;

  let alphaNumericPattern =
    /^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9- ]*[-_A-Za-z-_?]$/;
    let initialValues = {
       
        label:props.editFieldValue?.type === 'text' ? props.editFieldValue.label : "",

   
      };
      const validationSchema = yup.object({
       
        label:yup.string().required("Label is required").max(250,"Label should be max 250 character"),
    
       
      });
      const haveSameData = function (obj1, obj2) {
        const obj1Length = Object.keys(obj1).length;
        const obj2Length = Object.keys(obj2).length;
        if (obj1Length === obj2Length) {
            return Object.keys(obj1).every(
                key => 
                obj2.hasOwnProperty(key)
                    && obj2[key] === obj1[key]);
        }
        return false;
    }
    function handleTrimmedBlur(event) {
      event.target.value = event.target.value.trim();
      formikDetails.handleChange(event);
      formikDetails.handleBlur(event);
  }
  function handleInputChange(event) {
    const inputValue = event.target.value;
    if (!inputValue.includes('.')) {
      formikDetails.handleChange(event);
    }
  }
      const handelTextAreadFormSubmit = (values,e) => {
        formikDetails.handleSubmit()
        const textAreaFieldData = {
            label: formikDetails.values.label,
            order: props.editFieldValue["order"],
            mandatory: mandatoryTFValue,
            type: "text",
        }
       console.log(textAreaFieldData)
        if([textAreaFieldData.label,textAreaFieldData.maxLength,textAreaFieldData.minLength].includes("")){
            
            props.setAddField(true);

        }else if (haveSameData(props.editFieldValue, textAreaFieldData)) {
          props.MyAlert.info(`No changes were done to the ${textAreaFieldData.label} field`)
           return
       
  } else if (!alphaNumericPattern.test(formikDetails.values.label)) {
    console.log("checkDuplicateOptions2")
    setCheckForAlphanumeric("Label should be alphanumeric");
  } 
  else{
           props.addFormField(textAreaFieldData);
            props.setAddField(false);
           
        }
      };
    
      const formikDetails = useFormik({
        initialValues,
       
        validationSchema,
    
      });
      const handleEnableTFChange = (e) => {
        setEnableTFValue(e.target.value)
    }
    const handleMandatoryTFChange = (e) => {
        setMandatoryTFValue(e.target.value)
        setEnableTFValue("yes")
    }
  return (
    <div className="form-content_radioForms overflow-y-scroll ">
      <div className="my-5  px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Label
              </span>
            }
            required={true}
            error={
              formikDetails.errors.label &&
              formikDetails.touched.label
            }
          />
        </div>
        <TextField
          error={
            formikDetails.errors.label &&
            formikDetails.touched.label
          }
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          name="label"
          id="label"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={handleTrimmedBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.label}
        />
        {formikDetails.errors.label &&
        formikDetails.touched.label ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.label}
          </div>
        ) : null}
         {checkForAlphanumeric && (
          <div className="text-red-500 error_text md:mt-1">{checkForAlphanumeric}</div>
        )}
      </div>
     
    
     
     
      <div className="mb-4 my-2 px-2 ml-1.5">
        <Label
          label={
            <span className="textFieldHeader text-sm  popins-medium  ">
              Mandatory
            </span>
          }
          required={true}
        />
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              className="textFieldHeader text-sm  popins-medium  "
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              value={mandatoryTFValue}
              onChange={handleMandatoryTFChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
        <button
          onClick={() => {
            props.setAddField(false);
            props.clearEditValue({})
          }}
          type="button"
          className="w-24 gray-btn ml-3 button"
        >
          Cancel
        </button>
        <button
          type="submit"
          id="createBatch"
          className="w-24 primary-btn button"
          onClick={handelTextAreadFormSubmit}
        >
           {props.editFieldValue?.type === 'text' ?"Update" :"Add"}
        </button>
      </div>
    </div>
  );
};

export default TextForm;
