import { createSlice } from '@reduxjs/toolkit'

export const treeSlice = createSlice({
  name: 'individualTreeData',
  initialState: {
    value: [],
    moduleIds:[]
  },
  reducers: {
    modifyIndividualTreeData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value.push(action.payload)
    } ,
    modifyModuleIdsData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.moduleIds = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { modifyIndividualTreeData, modifyModuleIdsData } = treeSlice.actions

export const IndividualModuleTree = (state) => state.individualTreeData.value
export const moduleIds = (state) => state.individualTreeData.moduleIds

export default treeSlice.reducer