import React from "react";
import Modal from "react-modal";
import "../../modal/modal.scss";

import { UpdateExecutionReq } from "../../../api/api-services";
import warningIcon from "../../../assets/toast-warning.svg";

function RejectExecutionDefectModal(props) {
  console.log(props.dataForApprove);
  let projectId = JSON.parse(localStorage.getItem("selected-project")).project
    .fireFlinkProjectId;
  let selectedProject = JSON.parse(localStorage["selected-project"]);
  let selectedUser = JSON.parse(localStorage["selected-user"]);
  let actionReject = JSON.parse(localStorage.getItem("user-privilege")).defect
    .actions.reject;

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "45%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTop: "4px solid #FFC600",
      
    },
  };
  const onSubmit = async (values) => {
    let licenceID = selectedProject.licenceID;
    let email = selectedUser.email;
    let payload = props.dataForApprove;
    try {
      let tempHistoryObj = {
        approver: email,
        status: actionReject,
      };
      payload.history.push(tempHistoryObj);
      payload.status = actionReject;
      payload.projectId = projectId;

      let defectid = props.dataForApprove.id;
      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));

      let response = await UpdateExecutionReq(
        payload,
        projectId,
        licenceID,

        defectid
      );
      if (response.status === 200) {
        props.MyAlert.success("Testcase execution rejected");
        props.CloseDefectReasonModal(false);
        console.log(payload, formData);
      } else {
        props.CloseDefectReasonModal(false);
      }
      console.log(formData, "formData", payload);
    } catch (err) {
      console.log("error");
      props.CloseDefectReasonModal(false);
    }
  };

  return (
    <Modal
      onRequestClose={() => {
        props.CloseDefectReasonModal(false);
      }}
      isOpen={props.CloseDefectReasonModal}
      style={customStylesPage}
      className="p-px"
    >
      <div className="grid grid-cols-12">
        <div className="grid grid-cols-12 col-span-12 warning-popup py-2">
          <div className="col-span-2 warning-icon">
            <div>
              <img alt="warning" src={warningIcon} />
            </div>
          </div>
          <div className="grid grid-cols-12 col-span-10 pl-7 my-2">
            <div className="col-span-12">
              <span className="warning_text">warning!</span>{" "}
              <span className="delete_text">Status Changes </span>
            </div>
            <div className="col-span-12 py-1">
              <span className="message">
                Hi {JSON.parse(localStorage["fire-crowd-user"]).name}
              </span>
            </div>
            <div className="col-span-12">
              <span className="message">
                Do you want to change status to Rejected ?
              </span>{" "}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 col-span-12 warning_popup_btn  pt-2">
          <div className="col-span-8"></div>
          <div className="grid grid-cols-12 col-span-4 flex justify-center">
            <div className="grid grid-cols-12 col-span-6 h-8 ">
              <span
                className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer"
                onClick={() => {
                  props.CloseDefectReasonModal(false);
                }}
              >
                <button>CANCEL</button>
              </span>
            </div>
            <div className="grid grid-cols-12 col-span-6 h-8 ">
              <span
                className="btn_back col-span-10 rounded-sm flex justify-center cursor-pointer"
                onClick={onSubmit}
              >
                <button>REJECT</button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default RejectExecutionDefectModal;
