import React, { useState } from "react";
import Close from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";
import { useEffect } from "react";

const SearchField = (props) => {
  let [showSearch, setShowSearch] = React.useState(false);
  let [searchInputValue, setSearchInputValue] = useState("")
  let [showcloseIcon, setShowCloseicon] = React.useState(false);
  let closeIconDelay = props.page === "projectList"?200:200

  useEffect(() => {
    if (props.closeField && showcloseIcon) {
      setSearchInputValue("");
      if (props.page === "projectList" && props?.onClose) {
        props.onClose();
      } else if (props.page === "platformDetails" && props?.onClose) {
        props.onClose();
      } else {
        props.setGlobalFilter();
      }
      setShowCloseicon(false);
      setShowSearch(false);
      props.setCloseField(false)
    }
  }, [props.closeField]);

  return (
  <>
      <Tooltip title={showcloseIcon?"":"Search"}>
      <div id="container" className={`${props.colSpan?props.colSpan:"col-span-11"}`} >
      <input
        className={`${showSearch ? "expanded-table-search" : "table-search"} ${props.inputClass?props.inputClass:""}`}
        type="text"
        value={searchInputValue}
        autoFocus={false}
        placeholder={props.placeholder?props.placeholder:"Search"}
        onChange={(e) => {
            setSearchInputValue(e.target.value)
            if(props.page === "projectList"){
                props.setGlobalFilter(e);
            }else{
                props.setGlobalFilter(e.target.value);
            }
        }}
        onClick={() => {
          setShowSearch(true);
          setTimeout(() => {
            setShowCloseicon(true);
          }, closeIconDelay);
        }}
        onBlur={() => {}}
      />
      {showcloseIcon && (
        <div
          className={`col-span-2 search_icon_close  ml-2 cursor-pointer ${props.closeIconClass?props.closeIconClass:""}`}
          onClick={() => {
            setSearchInputValue("")
            if(props.page === "projectList" && props?.onClose){
                props.onClose()
            }else if(props.page === "platformDetails" && props?.onClose){
              props.onClose()
            }else{
                props.setGlobalFilter();
            }
            setShowCloseicon(false);
            setShowSearch(false);
          }}
        >
          <Close />
        </div>
      )}
    </div>
        </Tooltip>
      
</>
    
  );
};

export default SearchField;
