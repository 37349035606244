import React, { useState, useEffect, useContext } from "react";
import { useTable, useGlobalFilter } from "react-table";
import { ReactComponent as Search } from "../../../assets/search.svg";
import "./reports.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {getUserDetailsByEmailID,getTemplateById} from "../../../api/api-services"
import DefectsDeatilsPopup from "../../modal/defects-deatils-popup";
import UserDeatilsPopup from "../../modal/user-deatils";
import NoDefectIcon from "../../../assets/reportsicon/no_reports_icon.png";
import {ReactComponent as BugIcon} from "../../../assets/reportsicon/bug_icon.svg"
import { Tooltip } from "@material-ui/core";
import AllDefectsList from "./AllDefectList";


function SegmentTable(props){
  let [defectsPopUp, setDefectsPopUp] = useState(false);
  let [defectsdetails, setDefectsdetails] = useState();
  let [userDeatils, setusererDeatils] = useState();
  let [userPopup, setUserPopup] = useState(false);
  let [defectTemplatedetails, setDefectTempalteDetails] = useState();
  let [downloadingFile,setDownloadingFile]=useState();
  let [allDefects,setAllDefects]=useState(false)

  useEffect(()=>{
    setDownloadingFile(props?.downloadingfile) 

  },[props?.downloadingfile])
  

  const handlescroll = (event) => {

    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
   
      if(props.label==="severity"  && globalFilter===undefined){
        
         props.selectAPI("severity")
   
      }
      if(props.label==="priority"  && globalFilter===undefined){
     
          props.selectAPI("priority")
      
      }
      
      
      
    }
  };
  const getUserDetails = async (e) => {
    console.log("get user deatil welcome");
    let emailId = e.Details.defectDetails.history[0].userEmail;
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    try {
      let response = await getUserDetailsByEmailID(projectId, emailId);
      if (response?.status === 200) {
        setusererDeatils(response.data);
        setUserPopup(true);
      }
      console.log("getUserDetails", response);
    } catch (err) {
      console.log("error");
    }
  };

  const getTemplateDetails = async (e) => {
    console.log("eeee", e.defectTemplateId);
    console.log("eeee Details", e);
    let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])
      .project.fireFlinkProjectId;
    let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
    let templateID = e.defectTemplateId;
    try {
      let response = await getTemplateById(
        fireFlinkProjectId,
        templateID,
        licenseId
      );
      if (response.data.responseCode === 200) {
        setDefectTempalteDetails(response);
        setDefectsdetails(e);
        setDefectsPopUp(true);
      } else {
        props.MyAlert.info("Something went wrong");
      }
    } catch (error) {
      console.log("errror");
    }
  };
  function CloseDefectModal(state) {
   
    setDefectsPopUp(state);
  }

  function CloseTesterModal(state) {
   
    setUserPopup(state);
  }

  function CloseDefectsModal(state){
    setAllDefects(state)
  }

 
 
    let [tabelData,setTableData]=useState([]);
    useEffect(()=>{
      setTableData([...props?.data]) 

    },[props])
   
    let columns = React.useMemo(
        () => [
          {
            Header: "BUG ID",
            accessor: "bugId",
            width: "12%",
            Cell: (e) => (
              <div
                title={e.value}
                onClick={(event) => {
                  event.preventDefault();
                  getTemplateDetails(e.cell.row.original.Details);
                 
                }}
                className="reportTablecell popins cursor-pointer	datatrimmer"
                style={{ color: "#434db8" }}
              >
                {e.value}
              </div>
            ),
          },
          {
            Header: "BUG SUMMARY",
            accessor: "bugSummary",
            width: "50.7%",
            Cell: (e) => (
              <div className="datatrimmer" title={e.value}>
                {e.value}
              </div>
            ),
          },
        
          {
            Header: "CREATED BY",
            accessor: "createdBy",
            width: "14.3%",
            Cell: (e) => (
              <div
                title={e.value}
                onClick={(event) => {
                  event.preventDefault();
    
                  getUserDetails(e.cell.row.original);
                }}
                className="reportTablecell popins cursor-pointer	datatrimmer w-16"
                style={{ color: "#434db8" }}
              >
                {e.value}
              </div>
            ),
          },
        ],
        []
      );
      let data = React.useMemo(() => tabelData, [tabelData]);
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state,
        setGlobalFilter,
      } = useTable(
        {
          columns,
          data,
        },
     useGlobalFilter
      );
       const { globalFilter } = state;
    return(
        <div className="segment_table">
            <div className="grid grid-cols-12 table_header">
            <div className="col-span-4 header_text ml-2">Defects - {props?.defectCount}</div>
            <div className="col-span-8  grid grid-cols-12 header_btn">
                <div className="col-span-7">
                <div className="adminsearch">
            <div>
              <input
                type="text"
                className="searchfield"
                 value={globalFilter || ""}
                autoFocus={false}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
                placeholder="Search"
              />
            </div>
            <div className="search_icon">
              <Search />
            </div>
          </div> 
                </div>
                <div className={`col-span-4 download_btn  ml-1.5 ${tabelData.length===0|| downloadingFile===true?"opacity-50":"opacity-100"}`}>
                <button className="flex" onClick={()=>{if(tabelData.length!==0 && downloadingFile===false){props?.downloadFile(props?.label)}}}>
                <div className="mt-1.5 ml-2">
                Download
                </div>
                <div className="ml-1.5">
                    <FileDownloadOutlinedIcon/>
                </div>
               
               

            </button>
                </div>
                <div className="col-span-1 bug_Icon">
                  <Tooltip title="Defects">
                  <button disabled={data?.length===0} className={data?.length===0?"opacity-50":"opacity-100"}>
                  <BugIcon onClick={()=>{setAllDefects(true)}}/>
                  </button>
                  </Tooltip>
                  
               
                </div>
            </div>

            </div>
            <div className="segment_table_container">
            <table {...getTableProps()}>
          <thead className="">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="table_headers">
                {headerGroup.headers.map((column) => (
                  <th width={column.width}
                  {...column.getHeaderProps()}
                  className={`reportTableheader popins text-lg font-semibold ${
                    column.Header === "BUG ID"
                      ? "text-left pl-3"
                      : "text-left pl-3"
                  }`}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
       
            <tbody {...getTableBodyProps()} className="segment_table_body"  onScroll={handlescroll}>
              {
                rows.length === 0 || data?.length === 0 ? (
                  <div className="segment_noreportIcon">
                    <img  alt="warning" src={NoDefectIcon}/>
                    <div className="text_msg">No Relevant Data Is Found !</div>
                  </div>):(
                     <>
                     {rows.map((row) => {
                       prepareRow(row);
                       return (
                         <tr
                           {...row.getRowProps()}
                           className="h-8" 
                         >
                           {row.cells.map((cell) => {
                             return (
                               <td  width={cell?.column?.width}
                               {...cell.getCellProps()}
                               className={`datatrimmer reportTablecell ${
                                 cell.column.Header === "BUG ID" 
                                //  || cell.column.Header === "CREATED BY"
                                   ? "popins"
                                   : "popins"
                               } ${
                                 cell.column.Header === "BUG ID"
                                   ? "text-left pl-3"
                                   : "text-left pl-3"
                               }`}>
                                 {cell.render("Cell")}
                               </td>
                             );
                           })}
                         </tr>
                       );
                     })}
                   </>
                  )
              }
             
            </tbody>
       
        </table>
            </div>
            {defectsPopUp && (
        <DefectsDeatilsPopup
          CloseDefectModal={CloseDefectModal}
          SelectedDefect={defectsdetails}
          defectTemplatedetails={defectTemplatedetails}
        />
     
      )}
      {userPopup && (
        <UserDeatilsPopup
          CloseTesterModal={CloseTesterModal}
          testerDeatils={userDeatils}
        />
        
      )}
      {allDefects && (
        <AllDefectsList CloseModal={CloseDefectsModal} payload={props?.payload} state={props?.state==="APPROVED"?"CLOSED":"REJECTED"} label={props?. label}/>
      )}
        </div>
    )
}
export default  SegmentTable;