import React, { useState, useEffect, props, useContext } from "react";
import "./report-details.scss";
import {
  getReportsData,
  getDefectsData,
  DownloadDefectExcleFile,
  getDefectsDataCount,
} from "../../api/api-services";
import Chart from "react-apexcharts";
import ChartLocation from "./charts/chart-location";
import StaticChart from "./charts/static-chart";
import DynamicChart from "./charts/dynamic-chart";
import ModulechartExpand from "./charts/modulechart-expand";
import { colors } from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@mui/material/IconButton";
import DonutChart from "./charts/donut-chart";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer.js";
import CommonDefectTable from "./common-Defects-table";
import RejectedDefectTable from "./rejected-defect-table";

let chartColor = [
  "#C52C2C",
  "#1F78B4",
  "#A6CEE3",
  "#FB9A99",
  "#434db8",
  "#21BBE2",
  "#E221D0",
  "#E24721",
];
let Severity_Color = [
  "#00FA9A",
  "#008B8B",
  "#7B68EE",
  "#9400D3",
  "#DA70D6",
  "#C71585",
  "#D2691E",
  "#BC8F8F",
];
let Priority_Color=[
  "#ff0000", "#ee82ee", "#6a5acd", "#ffa500","#0000ff","#3cb371"," #bb5588","#ffcc99"
]
let Module_Color=["#DFFF00", "#FFBF00", "#FF9633", "#FF7F50","#DE3163","#9FE2BF","#40E0D0","#6495ED"]
let location_color=["#000080","#00FFFF","#800080","#FF00FF","#008080","#008000","#FFFF00","#00FF00"]
let webSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
};
let mobileSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
  deviceBrand: null,
  deviceName: null,
  deviceType: null,
};
let severityState = "CLOSED";
let severityPayload = [];

let priorityState = "CLOSED";
let priorityPayload = [];

let moduleState = "CLOSED";
let modulePayload = [];

let locationState = "CLOSED";
let locationPayload = [];

let ExpandmoduleState = "CLOSED";
let ExpandmodulePayload = [];

let ExpandlocationState = "CLOSED";
let ExpandlocationPayload = [];

function SegmentChartTableContainer(props) {

  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  const [moduleTotalDefects, setModuleTotalDefects] = useState(false);
  const [locationTotalDefects, setLocationTotalDefects] = useState(false);
  const [reportsData, setReportsData] = useState({});

  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  let [displaySeverityapproverdefect, setSeverityDisplayapproverdefect] =
    useState(true);
  let [displaypriorityapproverdefect, setPriorityDisplayapproverdefect] =
    useState(true);
  let [displaymoduleapproverdefect, setModuleDisplayapproverdefect] =
    useState(true);
  let [displaylocationapproverdefect, setLocationDisplayapproverdefect] =
    useState(true);

  let [
    displayExpandmoduleapproverdefect,
    setExpandModuleDisplayapproverdefect,
  ] = useState(true);

  let [
    displayExpandlocationapproverdefect,
    setExpandLocationDisplayapproverdefect,
  ] = useState(true);

  let [severityDefects, setSeverityDefects] = useState([]);
  let [severityDefectPageNo, setSeverityPageNo] = useState(0);
  let [severitylastpage, setSeveritylastpage] = useState();
  const [severityDefectsCount, setSeverityDefectsCount] = useState();

  let [priorityDefects, setPriorityDefects] = useState([]);
  let [priorityDefectPageNo, setPriorityPageNo] = useState(0);
  let [prioritylastpage, setPrioritylastpage] = useState();
  const [priorityDefectsCount, setPriorityDefectsCount] = useState();

  let [moduleDefects, setModuleDefects] = useState([]);
  let [moduleDefectPageNo, setModulePageNo] = useState(0);
  let [modulelastpage, setModulelastpage] = useState();
  const [moduleDefectsCount, setModuleDefectsCount] = useState();

  let [expandmoduleDefects, setExpandModuleDefects] = useState([]);
  let [expandmoduleDefectPageNo, setExpandModulePageNo] = useState(0);
  let [expnadmodulelastpage, setExpandModulelastpage] = useState();
  const [expandModuleDefectsCount, setExpandModuleDefectsCount] = useState();

  let [locationDefects, setLocationDefects] = useState([]);
  let [locationDefectPageNo, setLocationPageNo] = useState(0);
  let [locationlastpage, setLocationlastpage] = useState();
  const [locationDefectsCount, setLocationDefectsCount] = useState();

  let [expandlocationDefects, setExpandLocationDefects] = useState([]);
  let [expandlocationDefectPageNo, setExpandLocationPageNo] = useState(0);
  let [expandlocationlastpage, setExpandLocationlastpage] = useState();
  const [expandLocationDefectsCount, setExpandLocationDefectsCount] =
    useState();

  const [expandmodual, setExpandModule] = useState({ lables: [], series: [] });
  const [expandlocation, setExpandLocation] = useState({
    lables: [],
    series: [],
  });
  let [severitySeries,setSeveritySeries]=useState([]);
  let [severitySeriesLabel,setseveritySeriesLabel]=useState([]);

  let [prioritySeries,setPrioritySeries]=useState([]);
  let [prioritySeriesLabel,setPrioritySeriesLabel]=useState([]);

  let [moduleSeries,setModuleSeries]=useState([]);
  let [moduleSeriesLabel,setmoduleSeriesLabel]=useState([]);

  let [locationSeries,setLocationSeries]=useState([]);
  let [locationSeriesLabel,setLocationSeriesLabel]=useState([]);

  let [moduleSeriesLength,setModuleSeriesLength]=useState()
  let [locationSeriesLength,setLocationSeriesLength]=useState()

 



  const[hasdata,setHasData]=useState(false)
  useEffect(() => {
    setSeverityDisplayapproverdefect(true);
    setPriorityDisplayapproverdefect(true);
    setModuleDisplayapproverdefect(true);
    setLocationDisplayapproverdefect(true);
    setseveritySeriesLabel([]);
    setSeveritySeries([]);
    setPrioritySeries([]);
    setPrioritySeriesLabel([]);
    setModuleSeries([]);
    setmoduleSeriesLabel([]);
    setLocationSeries([]);
    setLocationSeriesLabel([]);
    expandmodual.lables=[];
    expandlocation.lables=[];
     setModuleDefects([])
     setLocationDefects([])
     setModuleTotalDefects(false)
     setLocationTotalDefects(false)
     setModuleSeriesLength()
     setLocationSeriesLength()
    ReportsDataAPI();
  }, [props.allowRejectedDefect]);

  const getAllseveritydefects = async (payload, pageno, state) => {
    let segmentSearchFilters = {
      severity: payload,
      priority: null,
      modules: null,
      locations: null,
      createdOn: null,
    };

    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 10;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          projectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
          setSeveritylastpage(res?.data?.lastPage);
          setSeverityPageNo(page + 1);
          severityState = state;
          severityPayload =payload;
          console.log("severityPayload",severityPayload)

          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
            setSeverityDefects([...tempArray]);
            getAllDefectsCount(filterdata, state, "severity");
          } else {
            let defectArray = [];
            defectArray = severityDefects.concat(tempArray);
            setSeverityDefects(defectArray);
          }
        }
      } catch (error) {
        console.log("Error");
      }
    } else {
      setSeverityDefects([]);
      setSeverityDefectsCount(0)
    }
  };

  const getAllprioritydefects = async (payload, pageno, state) => {
    let segmentSearchFilters = {
      severity: null,
      priority: payload,
      modules: null,
      locations: null,
      createdOn: null,
    };
    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 10;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          projectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
          setPrioritylastpage(res?.data?.lastPage);
          setPriorityPageNo(page + 1);
          priorityState = state;
          priorityPayload = payload;
          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
            setPriorityDefects([...tempArray]);
            getAllDefectsCount(filterdata, state, "priority");
          } else {
            let defectArray = [];
            defectArray = priorityDefects.concat(tempArray);
            setPriorityDefects(defectArray);
          }
        }
      } catch (err) {
        console.log("error");
      }
    } else {
      setPriorityDefects([]);
      setPriorityDefectsCount(0)
    }
  };

  const getAllmoduledefects = async (payload, pageno, state) => {
    let segmentSearchFilters = {
      severity: null,
      priority: null,
      modules: payload,
      locations: null,
      createdOn: null,
    };
    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 10;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          projectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
          console.log("defect123", res?.data?.responseObject);
          setModulelastpage(res?.data?.lastPage);
          setModulePageNo(page + 1);
          moduleState = state;
          modulePayload = payload;

          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
            setModuleDefects([...tempArray]);
            getAllDefectsCount(filterdata, state, "module");
          } else {
            let defectArray = [];
            defectArray = moduleDefects.concat(tempArray);
            setModuleDefects(defectArray);
          }
        }
      } catch (err) {
        console.log("error");
      }
    } else {
      setModuleDefects([]);
      setModuleDefectsCount(0)
    }
  };

  const getAllExpandmoduledefects = async (payload, pageno, state) => {
    let segmentSearchFilters = {
      severity: null,
      priority: null,
      modules: payload,
      locations: null,
      createdOn: null,
    };

    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 10;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          projectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
          console.log("defect123", res?.data?.responseObject);
          setExpandModulelastpage(res?.data?.lastPage);
          setExpandModulePageNo(page + 1);
          ExpandmoduleState = state;
          ExpandmodulePayload = payload;
          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
            setExpandModuleDefects([...tempArray]);
            getAllDefectsCount(filterdata, state, "expandModule");
          } else {
            let defectArray = [];
            defectArray = expandmoduleDefects.concat(tempArray);
            setExpandModuleDefects(defectArray);
          }
        }
      } catch (err) {
        console.log("error");
      }
    } else {
      setExpandModuleDefects([]);
      setExpandModuleDefectsCount(0);
    }
  };

  const getAlllocationDefects = async (payload, pageno, state) => {
    let segmentSearchFilters = {
      severity: null,
      priority: null,
      modules: null,
      locations: payload,
      createdOn: null,
    };
    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 10;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          projectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
          console.log("defect123", res?.data?.responseObject);
          setLocationlastpage(res?.data?.lastPage);
          setLocationPageNo(page + 1);
          locationState = state;
          locationPayload = payload;
          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
            setLocationDefects([...tempArray]);
            getAllDefectsCount(filterdata, state, "location");
          } else {
            let defectArray = [];
            defectArray = locationDefects.concat(tempArray);
            setLocationDefects(defectArray);
          }
        }
      } catch (err) {
        console.log("error");
      }
    } else {
      setLocationDefects([]);
      setLocationDefectsCount(0)
    }
  };

  const getAllExpandlocationDefects = async (payload, pageno, state) => {
    let segmentSearchFilters = {
      severity: null,
      priority: null,
      modules: null,
      locations: payload,
      createdOn: null,
    };
    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 10;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          projectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
          console.log("defect123", res?.data?.responseObject);
          setExpandLocationlastpage(res?.data?.lastPage);
          setExpandLocationPageNo(page + 1);
          ExpandlocationState = state;
          ExpandlocationPayload = payload;
          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
            setExpandLocationDefects([...tempArray]);
            getAllDefectsCount(filterdata, state, "expandLocation");
          } else {
            let defectArray = [];
            defectArray = expandlocationDefects.concat(tempArray);
            setExpandLocationDefects(defectArray);
          }
        }
      } catch (err) {
        console.log("error");
      }
    } else {
      setExpandLocationDefects([]);
      setExpandLocationDefectsCount(0)
    }
  };

  function selectAPI(lable) {
    if (lable === "severity") {
      let state = displaySeverityapproverdefect ? "CLOSED" : "REJECTED";
      getAllseveritydefects(severityPayload, severityDefectPageNo, state);
    } else if (lable === "priority") {
      let state = displaypriorityapproverdefect ? "CLOSED" : "REJECTED";
      getAllprioritydefects(priorityPayload, priorityDefectPageNo, state);
    } else if (lable === "module") {
      let state = displaymoduleapproverdefect ? "CLOSED" : "REJECTED";
      getAllmoduledefects(modulePayload, moduleDefectPageNo, state);
    } else if (lable === "Ex-module") {
      let state = displayExpandmoduleapproverdefect ? "CLOSED" : "REJECTED";
      getAllExpandmoduledefects(
        ExpandmodulePayload,
        expandmoduleDefectPageNo,
        state
      );
    } else if (lable === "Ex-location") {
      let state = displayExpandlocationapproverdefect ? "CLOSED" : "REJECTED";
      getAllExpandlocationDefects(
        ExpandlocationPayload,
        expandlocationDefectPageNo,
        state
      );
    } else {
      let state = displaylocationapproverdefect ? "CLOSED" : "REJECTED";
      getAlllocationDefects(locationPayload, locationDefectPageNo, state);
    }
  }

  const ReportsDataAPI = async () => {
    let severitySeriesLabel_key=[],prioritySeriesLabel_key=[];
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    try {
      const response = await getReportsData(projectId, licenseID);
      if (response.status === 200) {
        console.log("chart response", response);
        localStorage.setItem(
          "severity-defect",
          JSON.stringify(response.data.data.defectOverview.approvedDefects)
        );
        localStorage.setItem(
          "priority-defect",
          JSON.stringify(response.data.data.defectOverview.approvedDefects)
        );
        localStorage.setItem(
          "module-defect",
          JSON.stringify(response.data.data.defectOverview.approvedDefects)
        );
        localStorage.setItem(
          "location-defect",
          JSON.stringify(response.data.data.defectOverview.approvedDefects)
        );
       
        setReportsData(response.data.data);
       
        if (
          response.data.data.defectSeverity.length !== 0 &&
          response.data.data.defectSeverity[0].approved !== null
        ) {
           severitySeriesLabel_key = Object.keys(
            response.data.data.defectSeverity[0].approved
          );
          let severitySeries_key = Object.values(
            response.data.data.defectSeverity[0].approved
          );
          setSeveritySeries(severitySeries_key);
          setseveritySeriesLabel(severitySeriesLabel_key)
        }

        if (
          response.data.data.defectPriority.length !== 0 &&
          response.data.data.defectPriority[0].approved !== null
        ) {
           prioritySeriesLabel_key = Object.keys(
            response.data.data.defectPriority[0].approved
          );
         let  prioritySeries_key = Object.values(
            response.data.data.defectPriority[0].approved
          );
          setPrioritySeries(prioritySeries_key)
          setPrioritySeriesLabel(prioritySeriesLabel_key)
        }

        if (
          response.data.data.defectModule.length !== 0 &&
          response.data.data.defectModule[0].approved !== null
        ) {
          let moduleSeriesLabel_key = Object.keys(
            response.data.data.defectModule[0].approved
          );
          let moduleSeries_key = Object.values(
            response.data.data.defectModule[0].approved
          );
          expandmodual.lables = moduleSeriesLabel_key;
          expandmodual.series = moduleSeries_key;
          setModuleSeriesLength( moduleSeries_key.length)
          // moduleSeriesLength = moduleSeries_key.length;
          if (moduleSeriesLabel_key.length > 8) {
            let label = moduleSeriesLabel_key.slice(0, 8);
            let series = moduleSeries_key.slice(0, 8);

            // moduleSeriesLabel = label;
            // moduleSeries = series;
            setModuleSeries(series);
            setmoduleSeriesLabel(label)
          }else{
            setModuleSeries(moduleSeries_key);
            setmoduleSeriesLabel(moduleSeriesLabel_key)
          }
        }

        if (
          response.data.data.defectRaisedLocation.length !== 0 &&
          response.data.data.defectRaisedLocation[0].approved !== null
        ) {
          let locationSeriesLabel_key = Object.keys(
            response.data.data.defectRaisedLocation[0].approved
          );
          let locationSeries_key = Object.values(
            response.data.data.defectRaisedLocation[0].approved
          );
          expandlocation.lables = locationSeriesLabel_key;
          expandlocation.series = locationSeries_key;
          setLocationSeriesLength(locationSeries_key.length)
          // locationSeriesLength = locationSeries_key.length;
          if (locationSeries_key.length > 8) {
            let label = locationSeriesLabel_key.slice(0, 8);
            let series = locationSeries_key.slice(0, 8);
            // locationSeriesLabel = label;
            // locationSeries = series;
            setLocationSeries(series);
            setLocationSeriesLabel(label)
          }else{
            setLocationSeries(locationSeries_key);
            setLocationSeriesLabel(locationSeriesLabel_key)
          }
        }
        setHasData(true);
        getAllseveritydefects(severitySeriesLabel_key, 0, "CLOSED");
        getAllprioritydefects(prioritySeriesLabel_key, 0, "CLOSED");
        getAllmoduledefects(expandmodual.lables, 0, "CLOSED");
        getAlllocationDefects(expandlocation.lables, 0, "CLOSED");
        resetProgress();
      }
    } catch (e) {
      console.log("error");
      resetProgress();
    }
  };

  function updatechartvalues(key, state, val) {
    console.log("val",val)
    console.log("key",key)
    switch (val) {
      case "severity": {
        if(severitySeriesLabel !== null && severitySeriesLabel.length !==0){
          let severityEle = document.getElementById(val + 0);
        severityEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        }
        
        setSeverityPageNo(0);
        setSeveritylastpage();
        if (key === "approved") {
          
          localStorage.setItem(
            "severity-defect",
            JSON.stringify(reportsData.defectOverview.approvedDefects)
          );
          let severitySeriesLabel_key=[],severitySeries_key=[]
          if (
            reportsData.defectSeverity.length !== 0 &&
            reportsData.defectSeverity[0].approved !== null
          ) {
           severitySeriesLabel_key = Object.keys(
              reportsData.defectSeverity[0].approved
            );
            severitySeries_key = Object.values(
              reportsData.defectSeverity[0].approved
            );
            setSeveritySeries(severitySeries_key);
            setseveritySeriesLabel(severitySeriesLabel_key)
          } else {
            if (reportsData.defectSeverity[0].approved === null) {
              // severitySeriesLabel = [];
              // severitySeries = [];
              setSeveritySeries([]);
              setseveritySeriesLabel([])
            }
          }

          getAllseveritydefects(severitySeriesLabel_key, 0, "CLOSED");
          setSeverityDisplayapproverdefect(state);
        
        } else {
          
          localStorage.setItem(
            "severity-defect",
            JSON.stringify(reportsData.defectOverview.rejectedDefects)
          );
          let severitySeriesLabel_key=[],severitySeries_key=[];
          if (
            reportsData.defectSeverity.length !== 0 &&
            reportsData.defectSeverity[1].rejected !== null
          ) {
            severitySeriesLabel_key = Object.keys(
              reportsData.defectSeverity[1].rejected
            );
            severitySeries_key= Object.values(
              reportsData.defectSeverity[1].rejected
            );
            setSeveritySeries(severitySeries_key);
            setseveritySeriesLabel(severitySeriesLabel_key)
          } else {
            if (reportsData.defectSeverity[1].rejected === null) {
              // severitySeriesLabel = [];
              // severitySeries = [];
              setSeveritySeries([]);
              setseveritySeriesLabel([])
            }
          }

          getAllseveritydefects(severitySeriesLabel_key, 0, "REJECTED");

          setSeverityDisplayapproverdefect(state);
          
        }

        break;
      }
      case "priority": {
        if( prioritySeriesLabel !==null &&  prioritySeriesLabel.length !==0){
          let priorityEle = document.getElementById(val + 0);
        priorityEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });

        }
        let prioritySeriesLabel_key=[],prioritySeries_key=[];
        setPriorityPageNo(0);
        setPrioritylastpage();
        if (key === "approved") {
          localStorage.setItem(
            "priority-defect",
            JSON.stringify(reportsData.defectOverview.approvedDefects)
          );
          if (
            reportsData.defectPriority.length !== 0 &&
            reportsData.defectPriority[0].approved !== null
          ) {
             prioritySeriesLabel_key = Object.keys(
              reportsData.defectPriority[0].approved
            );
             prioritySeries_key = Object.values(
              reportsData.defectPriority[0].approved
            );
            setPrioritySeries(prioritySeries_key)
          setPrioritySeriesLabel(prioritySeriesLabel_key)
          } else {
            if (reportsData.defectPriority[0].approved === null) {
              // prioritySeriesLabel = [];
              // prioritySeries = [];
              setPrioritySeries([])
              setPrioritySeriesLabel([])
            }
          }

          getAllprioritydefects(prioritySeriesLabel_key, 0, "CLOSED");
          setPriorityDisplayapproverdefect(state);
        } else {
          localStorage.setItem(
            "priority-defect",
            JSON.stringify(reportsData.defectOverview.rejectedDefects)
          );
          let prioritySeriesLabel_key=[],prioritySeries_key=[];
          if (
            reportsData.defectPriority.length !== 0 &&
            reportsData.defectPriority[1].rejected !== null
          ) {
             prioritySeriesLabel_key = Object.keys(
              reportsData.defectPriority[1].rejected
            );
             prioritySeries_key = Object.values(
              reportsData.defectPriority[1].rejected
            );
            setPrioritySeries(prioritySeries_key)
          setPrioritySeriesLabel(prioritySeriesLabel_key)
          } else {
            if (reportsData.defectPriority[1].rejected === null) {
              // prioritySeriesLabel = [];
              // prioritySeries = [];
              setPrioritySeries([])
              setPrioritySeriesLabel([])
            }
          }

          getAllprioritydefects(prioritySeriesLabel_key, 0, "REJECTED");
          setPriorityDisplayapproverdefect(state);
        }

        break;
      }
      case "module": {
        if(moduleSeriesLabel !==null && moduleSeriesLabel.length!==0){
          let moduleEle = document.getElementById(val + 0);
        moduleEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });

        }
        
        setModulePageNo(0);
        setModulelastpage();
        if (key === "approved") {
          let tempApprovedModule = [];
          localStorage.setItem(
            "module-defect",
            JSON.stringify(reportsData.defectOverview.approvedDefects)
          );
          if (
            reportsData.defectModule.length !== 0 &&
            reportsData.defectModule[0].approved !== null
          ) {
            let moduleSeriesLabel_key = Object.keys(
              reportsData.defectModule[0].approved
            );
            expandmodual.lables=Object.keys(
              reportsData.defectModule[0].approved
            );
           let  moduleSeries_key= Object.values(reportsData.defectModule[0].approved);
            tempApprovedModule = Object.keys(
              reportsData.defectModule[0].approved
            );
            setModuleSeriesLength( moduleSeries_key.length)
            // moduleSeriesLength = moduleSeries_key.length;
            if (moduleSeriesLabel_key.length > 8) {
              let label = moduleSeriesLabel_key.slice(0, 8);
              let series = moduleSeries_key.slice(0, 8);

              // moduleSeriesLabel = label;
              // moduleSeries = series;
              setModuleSeries(series);
              setmoduleSeriesLabel(label)
            }else{
              setModuleSeries(moduleSeries_key);
              setmoduleSeriesLabel(moduleSeriesLabel_key)
            }
          } else {
            if (reportsData.defectModule[0].approved === null) {
              // moduleSeriesLabel = [];
              // moduleSeries = [];
              setModuleSeries([]);
              setmoduleSeriesLabel([])
              setModuleSeriesLength(0)
              tempApprovedModule = [];
              expandmodual.lables=[]
            }
          }

          getAllmoduledefects(tempApprovedModule, 0, "CLOSED");

          setModuleDisplayapproverdefect(state);
        } else {
          let tempRejectedmodule = [];
          localStorage.setItem(
            "module-defect",
            JSON.stringify(reportsData.defectOverview.rejectedDefects)
          );

          if (
            reportsData.defectModule.length !== 0 &&
            reportsData.defectModule[1].rejected !== null
          ) {
            let moduleSeriesLabel_key = Object.keys(
              reportsData.defectModule[1].rejected
            );
            expandmodual.lables=Object.keys(
              reportsData.defectModule[1].rejected
            );
            let moduleSeries_key = Object.values(reportsData.defectModule[1].rejected);
            tempRejectedmodule = Object.keys(
              reportsData.defectModule[1].rejected
            );
            setModuleSeriesLength( moduleSeries_key.length)
            // moduleSeriesLength = moduleSeries_key.length;
            if (moduleSeriesLabel_key.length > 8) {
              let label = moduleSeriesLabel_key.slice(0, 8);
              let series = moduleSeries_key.slice(0, 8);

              // moduleSeriesLabel = label;
              // moduleSeries = series;
              setModuleSeries(series);
              setmoduleSeriesLabel(label)
            }else{
              // console.log("fdhgfgghfghf",moduleSeries_key)
              setModuleSeries(moduleSeries_key);
              setmoduleSeriesLabel(moduleSeriesLabel_key)
            }
          } else {
            if (reportsData.defectModule[1].rejected === null) {
              // moduleSeriesLabel = [];
              // moduleSeries = [];
              setModuleSeries([]);
              setmoduleSeriesLabel([])
              setModuleSeriesLength(0)
              tempRejectedmodule = [];
              expandmodual.lables=[]
            }
          }

          getAllmoduledefects(tempRejectedmodule, 0, "REJECTED");

          setModuleDisplayapproverdefect(state);
        }

        break;
      }
      case "Ex-module": {
        if(expandmodual.lables !==null && expandmodual.lables.length !==0){
          let expandModuleEle = document.getElementById(val + 0);
        expandModuleEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });

        }
        
        setExpandModulePageNo(0);
        setExpandModulelastpage();
        if (key === "approved") {
          if (
            reportsData.defectModule.length !== 0 &&
            reportsData.defectModule[0].approved !== null
          ) {
            expandmodual.lables = Object.keys(
              reportsData.defectModule[0].approved
            );
            expandmodual.series = Object.values(
              reportsData.defectModule[0].approved
            );
            let length=Object.values(reportsData.defectModule[0].approved).length
            setModuleSeriesLength(length)
          } else {
            if (reportsData.defectModule[0].approved === null) {
              expandmodual.lables = [];
              expandmodual.series = [];
              setModuleSeriesLength(0)
            }
          }

          getAllExpandmoduledefects(expandmodual.lables, 0, "CLOSED");
          setExpandModuleDisplayapproverdefect(state);
        } else {
          if (
            reportsData.defectModule.length !== 0 &&
            reportsData.defectModule[1].rejected !== null
          ) {
            expandmodual.lables = Object.keys(
              reportsData.defectModule[1].rejected
            );
            expandmodual.series = Object.values(
              reportsData.defectModule[1].rejected
            );
            let length=Object.values(reportsData.defectModule[1].rejected).length
            setModuleSeriesLength(length)
          } else {
            if (reportsData.defectModule[1].rejected === null) {
              expandmodual.lables = [];
              expandmodual.series = [];
              setModuleSeriesLength(0)
            }
          }

          getAllExpandmoduledefects(expandmodual.lables, 0, "REJECTED");

          setExpandModuleDisplayapproverdefect(state);
        }

        break;
      }
      case "location": {
        if(locationSeries !==null && locationSeries.length !==0){
          let locationEle = document.getElementById(val + 0);
          locationEle.scrollIntoViewIfNeeded({
            behavior: "auto",
            block: "start",
            inline: "nearest",
          });

        }
        setLocationPageNo(0);
        setLocationlastpage();
       
        if (key === "approved") {
          let tepmApprovedLocation = [];
          localStorage.setItem(
            "location-defect",
            JSON.stringify(reportsData.defectOverview.approvedDefects)
          );
          if (
            reportsData.defectRaisedLocation.length !== 0 &&
            reportsData.defectRaisedLocation[0].approved !== null
          ) {
            let locationSeriesLabel_key = Object.keys(
              reportsData.defectRaisedLocation[0].approved
            );
            expandlocation.lables= Object.keys(
              reportsData.defectRaisedLocation[0].approved
            );
            let locationSeries_key = Object.values(
              reportsData.defectRaisedLocation[0].approved
            );
            tepmApprovedLocation = Object.keys(
              reportsData.defectRaisedLocation[0].approved
            );
            setLocationSeriesLength(locationSeries_key.length)
            // locationSeriesLength = locationSeries_key.length;
            if (locationSeries_key.length > 8) {
              let label = locationSeriesLabel_key.slice(0, 8);
              let series = locationSeries_key.slice(0, 8);
              // locationSeriesLabel = label;
              // locationSeries = series;
              setLocationSeries(series);
            setLocationSeriesLabel(label)
            }else{
              setLocationSeries(locationSeries_key);
              setLocationSeriesLabel(locationSeriesLabel_key)
            }
          } else {
            if (reportsData.defectRaisedLocation[0].approved === null) {
              // locationSeries = [];
              // locationSeriesLabel = [];
              setLocationSeries([]);
            setLocationSeriesLabel([])
            setLocationSeriesLength(0)
              tepmApprovedLocation = [];
              expandlocation.lables=[]
            }
          }

          getAlllocationDefects(tepmApprovedLocation, 0, "CLOSED");

          setLocationDisplayapproverdefect(state);
        } else {
          let tempRejectedmodule = [];
          localStorage.setItem(
            "location-defect",
            JSON.stringify(reportsData.defectOverview.rejectedDefects)
          );
          if (
            reportsData.defectRaisedLocation.length !== 0 &&
            reportsData.defectRaisedLocation[1].rejected !== null
          ) {
            let locationSeriesLabel_key = Object.keys(
              reportsData.defectRaisedLocation[1].rejected
            );
            expandlocation.lables=Object.keys(
              reportsData.defectRaisedLocation[1].rejected
            );
            tempRejectedmodule = Object.keys(
              reportsData.defectRaisedLocation[1].rejected
            );
            let locationSeries_key = Object.values(
              reportsData.defectRaisedLocation[1].rejected
            );
            setLocationSeriesLength(locationSeries_key.length)
            // locationSeriesLength = locationSeries_key.length;
            if (locationSeries_key.length > 8) {
              let label = locationSeriesLabel_key.slice(0, 8);
              let series = locationSeries_key.slice(0, 8);
              // locationSeriesLabel = label;
              // locationSeries = series;
              setLocationSeries(series);
            setLocationSeriesLabel(label)
            }else{
              setLocationSeries(locationSeries_key);
            setLocationSeriesLabel(locationSeriesLabel_key)
            }
          } else {
            if (reportsData.defectRaisedLocation[1].rejected === null) {
              // locationSeriesLabel = [];
              // locationSeries = [];
              setLocationSeries([]);
            setLocationSeriesLabel([])
            setLocationSeriesLength(0)
              tempRejectedmodule = [];
              expandlocation.lables=[]
            }
          }

          getAlllocationDefects(tempRejectedmodule, 0, "REJECTED");

          setLocationDisplayapproverdefect(state);
        }

        break;
      }

      case "Ex-location": {
        if(expandlocation.lables!==null && expandlocation.lables.length !==0){
          let expandLocationEle = document.getElementById(val + 0);
        expandLocationEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });

        }
        

        setExpandLocationPageNo(0);
        setExpandLocationlastpage();
        if (key === "approved") {
          localStorage.setItem(
            "location-defect",
            JSON.stringify(reportsData.defectOverview.approvedDefects)
          );
          if (
            reportsData.defectRaisedLocation.length !== 0 &&
            reportsData.defectRaisedLocation[0].approved !== null
          ) {
            expandlocation.lables = Object.keys(
              reportsData.defectRaisedLocation[0].approved
            );
            expandlocation.series = Object.values(
              reportsData.defectRaisedLocation[0].approved
            );
            let length=Object.values(reportsData.defectRaisedLocation[0].approved).length;
            setLocationSeriesLength(length)
          } else {
            if (reportsData.defectRaisedLocation[0].approved === null) {
              expandlocation.lables = [];
              expandlocation.series = [];
              setLocationSeriesLength(0)
            }
          }

          getAllExpandlocationDefects(expandlocation.lables, 0, "CLOSED");
          setExpandLocationDisplayapproverdefect(state);
        } else {
          localStorage.setItem(
            "location-defect",
            JSON.stringify(reportsData.defectOverview.rejectedDefects)
          );
          if (
            reportsData.defectRaisedLocation.length !== 0 &&
            reportsData.defectRaisedLocation[1].rejected !== null
          ) {
            expandlocation.lables = Object.keys(
              reportsData.defectRaisedLocation[1].rejected
            );
            expandlocation.series = Object.values(
              reportsData.defectRaisedLocation[1].rejected
            );
            let length=Object.values(reportsData.defectRaisedLocation[1].rejected).length;
            setLocationSeriesLength(length)
          } else {
            if (reportsData.defectRaisedLocation[1].rejected === null) {
              expandlocation.lables = [];
              expandlocation.series = [];
              setLocationSeriesLength(0)
            }
          }

          getAllExpandlocationDefects(expandlocation.lables, 0, "REJECTED");

          setExpandLocationDisplayapproverdefect(state);
        }

        break;
      }

      default: {
        console.log("wrong value");
        break;
      }
    }
  }
  function updateModuleDate(state) {
    if (state === true) {
      setModuleTotalDefects(state);
      if(displaymoduleapproverdefect===true){
        setExpandModuleDisplayapproverdefect(true);

      if (
        reportsData.defectModule.length !== 0 &&
        reportsData.defectModule[0].approved !== null
      ) {
        expandmodual.lables = Object.keys(reportsData.defectModule[0].approved);
        expandmodual.series = Object.values(
          reportsData.defectModule[0].approved
        );
        let length=Object.values(reportsData.defectModule[0].approved).length
        setModuleSeriesLength(length)
      } else {
        if (reportsData.defectModule[0].approved === null) {
          expandmodual.lables = [];
          expandmodual.series = [];
          setModuleSeriesLength(0)
        }
      }
      getAllExpandmoduledefects(expandmodual.lables, 0, "CLOSED");
      }else{
        setExpandModuleDisplayapproverdefect(false);

      if (
        reportsData.defectModule.length !== 0 &&
        reportsData.defectModule[1].rejected !== null
      ) {
        expandmodual.lables = Object.keys(reportsData.defectModule[1].rejected);
        expandmodual.series = Object.values(
          reportsData.defectModule[1].rejected
        );
        let length=Object.values(reportsData.defectModule[1].rejected).length
        setModuleSeriesLength(length)
      } else {
        if (reportsData.defectModule[1].rejected === null) {
          expandmodual.lables = [];
          expandmodual.series = [];
          setModuleSeriesLength(0)
        }
      }
      getAllExpandmoduledefects(expandmodual.lables, 0, "REJECTED");
      }
      
    } else {
      setModuleTotalDefects(state);
      if(displayExpandmoduleapproverdefect===true){
        setModuleDisplayapproverdefect(true);
      let tempmoduleArray = [];
      localStorage.setItem(
        "module-defect",
        JSON.stringify(reportsData.defectOverview.approvedDefects)
      );

      if (
        reportsData.defectModule.length !== 0 &&
        reportsData.defectModule[0].approved !== null
      ) {
        let moduleSeriesLabel_key = Object.keys(reportsData.defectModule[0].approved);
        tempmoduleArray = Object.keys(reportsData.defectModule[0].approved);
        expandmodual.lables=Object.keys(reportsData.defectModule[0].approved)

        let moduleSeries_key = Object.values(reportsData.defectModule[0].approved);
        setModuleSeriesLength( moduleSeries_key.length)
        // moduleSeriesLength = moduleSeries_key.length;
        if (moduleSeriesLabel_key.length > 8) {
          let label = moduleSeriesLabel_key.slice(0, 8);
          let series = moduleSeries_key.slice(0, 8);

          // moduleSeriesLabel = label;
          // moduleSeries = series;
          setModuleSeries(series);
            setmoduleSeriesLabel(label)
        }else{
          setModuleSeries(moduleSeries_key);
            setmoduleSeriesLabel(moduleSeriesLabel_key)
        }
      } else {
        if (reportsData.defectModule[0].approved === null) {
          // moduleSeriesLabel = [];
          // moduleSeries = [];
          setModuleSeries([]);
            setmoduleSeriesLabel([])
            setModuleSeriesLength(0)
          tempmoduleArray = [];
          expandmodual.lables=[];
        }
      }

      getAllmoduledefects(tempmoduleArray, 0, "CLOSED");
      }else{
        setModuleDisplayapproverdefect(false);
      let tempmoduleArray = [];
      localStorage.setItem(
        "module-defect",
        JSON.stringify(reportsData.defectOverview.rejectedDefects)
      );

      if (
        reportsData.defectModule.length !== 0 &&
        reportsData.defectModule[1].rejected !== null
      ) {
        let moduleSeriesLabel_key = Object.keys(reportsData.defectModule[1].rejected);
        tempmoduleArray = Object.keys(reportsData.defectModule[1].rejected);
        expandmodual.lables=Object.keys(reportsData.defectModule[1].rejected)

        let moduleSeries_key = Object.values(reportsData.defectModule[1].rejected);
        setModuleSeriesLength( moduleSeries_key.length)
        // moduleSeriesLength = moduleSeries_key.length;
        if (moduleSeriesLabel_key.length > 8) {
          let label = moduleSeriesLabel_key.slice(0, 8);
          let series = moduleSeries_key.slice(0, 8);

          // moduleSeriesLabel = label;
          // moduleSeries = series;
          setModuleSeries(series);
          setmoduleSeriesLabel(label)
        }else{
          setModuleSeries(moduleSeries_key);
          setmoduleSeriesLabel(moduleSeriesLabel_key)
        }
      } else {
        if (reportsData.defectModule[1].rejected === null) {
          // moduleSeriesLabel = [];
          // moduleSeries = [];
          setModuleSeries([]);
          setmoduleSeriesLabel([])
          setModuleSeriesLength(0)
          tempmoduleArray = [];
          expandmodual.lables=[];
        }
      }

      getAllmoduledefects(tempmoduleArray, 0, "REJECTED");
      }
      
    }
  }

  function selectedChartlabelData(lable, value) {
    console.log("values", value);

    switch (lable) {
      case "severity": {
        let severityFilterData;
        let severityEle = document.getElementById(lable + 0);
        severityEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        setSeverityPageNo(0);
        setSeveritylastpage();
        console.log("severityPayload API",severityPayload)
        if (severityPayload.length === 1 && severityPayload[0] === value[0]) {
          severityFilterData = severitySeriesLabel;
        } else {
          severityFilterData = value;
        }

        getAllseveritydefects(severityFilterData, 0, severityState);
        break;
      }
      case "priority": {
        let priorityFilterdata;
        let priorityEle = document.getElementById(lable + 0);
        priorityEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        setPriorityPageNo(0);
        setPrioritylastpage();
        if (priorityPayload.length === 1 && priorityPayload[0] === value[0]) {
          priorityFilterdata = prioritySeriesLabel;
        } else {
          priorityFilterdata = value;
        }

        getAllprioritydefects(priorityFilterdata, 0, priorityState);

        break;
      }
      case "module": {
        let moduleFilterdata;
        let moduleEle = document.getElementById(lable + 0);
        moduleEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        setModulePageNo(0);
        setModulelastpage();
        if (modulePayload.length === 1 && modulePayload[0] === value[0]) {
          moduleFilterdata = expandmodual.lables;
        } else {
          moduleFilterdata = value;
        }

        getAllmoduledefects(moduleFilterdata, 0, moduleState);

        break;
      }
      case "location": {
        let locationFilterdata;
        let locationEle = document.getElementById(lable + 0);
        locationEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        setLocationPageNo(0);
        setLocationlastpage();
        if (locationPayload.length === 1 && locationPayload[0] === value[0]) {
          locationFilterdata = expandlocation.lables;
        } else {
          locationFilterdata = value;
        }

        getAlllocationDefects(locationFilterdata, 0, locationState);

        break;
      }
      case "Ex-location": {
        let ExpandLocationFilterdata;
        let expandLocationEle = document.getElementById(lable + 0);
        expandLocationEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        setExpandLocationPageNo(0);
        setExpandLocationlastpage();
        if (
          ExpandlocationPayload.length === 1 &&
          ExpandlocationPayload[0] === value[0]
        ) {
          ExpandLocationFilterdata = expandlocation.lables;
        } else {
          ExpandLocationFilterdata = value;
        }

        getAllExpandlocationDefects(
          ExpandLocationFilterdata,
          0,
          ExpandlocationState
        );
        break;
      }
      case "Ex-module": {
        let ExpandModuleFilterdata;
        let expandModuleEle = document.getElementById(lable + 0);
        expandModuleEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });

        setExpandModulePageNo(0);
        setExpandModulelastpage();
        if (
          ExpandmodulePayload.length === 1 &&
          ExpandmodulePayload[0] === value[0]
        ) {
          ExpandModuleFilterdata = expandmodual.lables;
        } else {
          ExpandModuleFilterdata = value;
        }

        getAllExpandmoduledefects(ExpandModuleFilterdata, 0, ExpandmoduleState);
        break;
      }
      default: {
        break;
      }
    }
  }

  function updateLocationDate(state) {
    if (state === true) {
      setLocationTotalDefects(state);
      if(displaylocationapproverdefect){
        setExpandLocationDisplayapproverdefect(true);
        if (
          reportsData.defectRaisedLocation.length !== 0 &&
          reportsData.defectRaisedLocation[0].approved !== null
        ) {
          expandlocation.lables = Object.keys(
            reportsData.defectRaisedLocation[0].approved
          );
          expandlocation.series = Object.values(
            reportsData.defectRaisedLocation[0].approved
          );
	      let length=Object.values(reportsData.defectRaisedLocation[0].approved).length
        setLocationSeriesLength(length)
        } else {
          if (reportsData.defectRaisedLocation[0].approved !== null) {
            expandlocation.lables = [];
            expandlocation.series = [];
	          setLocationSeriesLength(0)
          }
        }
        getAllExpandlocationDefects(expandlocation.lables, 0, "CLOSED");
      }else{
        setExpandLocationDisplayapproverdefect(false);
        if (
          reportsData.defectRaisedLocation.length !== 0 &&
          reportsData.defectRaisedLocation[1].rejected !== null
        ) {
          expandlocation.lables = Object.keys(
            reportsData.defectRaisedLocation[1].rejected
          );
          expandlocation.series = Object.values(
            reportsData.defectRaisedLocation[1].rejected
          );
          let length=Object.values(reportsData.defectRaisedLocation[0].approved).length
        setLocationSeriesLength(length)
        } else {
          if (reportsData.defectRaisedLocation[1].rejected!== null) {
            expandlocation.lables = [];
            expandlocation.series = [];
            setLocationSeriesLength(0)
          }
        }
        getAllExpandlocationDefects(expandlocation.lables, 0, "REJECTED");
      }
      

     
    } else {
      setLocationTotalDefects(state);
      if(displayExpandlocationapproverdefect===true){
        setLocationDisplayapproverdefect(true);
      let templocation = [];
      localStorage.setItem(
        "location-defect",
        JSON.stringify(reportsData.defectOverview.approvedDefects)
      );

      if (
        reportsData.defectRaisedLocation.length !== 0 &&
        reportsData.defectRaisedLocation[0].approved !== null
      ) {
        let locationSeriesLabel_key = Object.keys(
          reportsData.defectRaisedLocation[0].approved
        );
        templocation = Object.keys(
          reportsData.defectRaisedLocation[0].approved
        );
        expandlocation.lables=Object.keys(
          reportsData.defectRaisedLocation[0].approved
        );
        let locationSeries_key = Object.values(
          reportsData.defectRaisedLocation[0].approved
        );
        setLocationSeriesLength(locationSeries_key.length)
        // locationSeriesLength = locationSeries_key.length;
        if (locationSeries_key.length > 8) {
          let label = locationSeriesLabel_key.slice(0, 8);
          let series = locationSeries_key.slice(0, 8);
          // locationSeriesLabel = label;
          // locationSeries = series;
          setLocationSeries(series);
            setLocationSeriesLabel(label)
        }else{
          setLocationSeries(locationSeries_key);
          setLocationSeriesLabel(locationSeriesLabel_key)
        }
      } else {
        if (reportsData.defectRaisedLocation[0].approved === null) {
          // locationSeriesLabel = [];
          // locationSeries = [];
          setLocationSeries([]);
            setLocationSeriesLabel([])
            setLocationSeriesLength(0)
          templocation = [];
          expandlocation.lables=[];
        }
      }

      getAlllocationDefects(templocation, 0, "CLOSED");

      }else{
        setLocationDisplayapproverdefect(false);
      let templocation = [];
      localStorage.setItem(
        "location-defect",
        JSON.stringify(reportsData.defectOverview.rejectedDefects)
      );

      if (
        reportsData.defectRaisedLocation.length !== 0 &&
        reportsData.defectRaisedLocation[1].rejected !== null
      ) {
        let locationSeriesLabel_key = Object.keys(
          reportsData.defectRaisedLocation[1].rejected
        );
        templocation = Object.keys(
          reportsData.defectRaisedLocation[1].rejected
        );
        expandlocation.lables=Object.keys(
          reportsData.defectRaisedLocation[1].rejected
        );
        let locationSeries_key = Object.values(
          reportsData.defectRaisedLocation[1].rejected
        );
        // locationSeriesLength = locationSeries_key.length;
        setLocationSeriesLength(locationSeries_key.length)
        if (locationSeries_key.length > 8) {
          let label = locationSeriesLabel_key.slice(0, 8);
          let series = locationSeries_key.slice(0, 8);
          setLocationSeries(series);
          setLocationSeriesLabel(label)
      }else{
        setLocationSeries(locationSeries_key);
        setLocationSeriesLabel(locationSeriesLabel_key)
      }
      } else {
        if (reportsData.defectRaisedLocation[1].rejected !== null) {
          // locationSeriesLabel = [];
          // locationSeries = [];
          setLocationSeries([]);
          setLocationSeriesLabel([])
          setLocationSeriesLength(0)
          templocation = [];
          expandlocation.lables=[];
        }
      }

      getAlllocationDefects(templocation, 0, "REJECTED");
      }
      
    }
  }
  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
  const downloadExcleFile = async (state, payload) => {
    let filename =
      state === "CLOSED" ? "Approved_Defects.xlsx" : "Rejected_Defects.xlsx";
    try {
      let response = await DownloadDefectExcleFile(
        projectId,
        licenseID,
        state,
        payload
      );
      console.log("response file", response);
      if (response.status === 200) {
        saveFileFromBlob(response.data, filename);
      }
    } catch (err) {
      console.log("error");
    }
  };

  function downloadFile(label) {
    switch (label) {
      case "severity": {
        let segmentSearchFilters = {
          severity: severityPayload,
          priority: null,
          modules: null,
          locations: null,
          createdOn: null,
        };
        let filterdata = {
          segmentSearchFilters: segmentSearchFilters,
          webSearchFilters: webSearchFilters,
          mobileSearchFilters: mobileSearchFilters,
        };
        downloadExcleFile(severityState, filterdata);
        break;
      }

      case "priority":
        {
          let segmentSearchFilters = {
            severity: null,
            priority: priorityPayload,
            modules: null,
            locations: null,
            createdOn: null,
          };
          let filterdata = {
            segmentSearchFilters: segmentSearchFilters,
            webSearchFilters: webSearchFilters,
            mobileSearchFilters: mobileSearchFilters,
          };
          downloadExcleFile(priorityState, filterdata);
          break;
        }

    
      case "module": {
        let segmentSearchFilters = {
          severity: null,
          priority: null,
          modules: modulePayload,
          locations: null,
          createdOn: null,
        };
        let filterdata = {
          segmentSearchFilters: segmentSearchFilters,
          webSearchFilters: webSearchFilters,
          mobileSearchFilters: mobileSearchFilters,
        };
        downloadExcleFile(moduleState, filterdata);
        break;
      }

      case "location": {
        let segmentSearchFilters = {
          severity: null,
          priority: null,
          modules: null,
          locations: locationPayload,
          createdOn: null,
        };
        let filterdata = {
          segmentSearchFilters: segmentSearchFilters,
          webSearchFilters: webSearchFilters,
          mobileSearchFilters: mobileSearchFilters,
        };
        downloadExcleFile(locationState, filterdata);
        break;
      }

      case "Ex-module": {
        let segmentSearchFilters = {
          severity: null,
          priority: null,
          modules: ExpandmodulePayload,
          locations: null,
          createdOn: null,
        };
        let filterdata = {
          segmentSearchFilters: segmentSearchFilters,
          webSearchFilters: webSearchFilters,
          mobileSearchFilters: mobileSearchFilters,
        };
        downloadExcleFile(ExpandmoduleState, filterdata);
        break;
      }

      case "Ex-location": {
        let segmentSearchFilters = {
          severity: null,
          priority: null,
          modules: null,
          locations: ExpandlocationPayload,
          createdOn: null,
        };
        let filterdata = {
          segmentSearchFilters: segmentSearchFilters,
          webSearchFilters: webSearchFilters,
          mobileSearchFilters: mobileSearchFilters,
        };
        downloadExcleFile(ExpandlocationState, filterdata);
        break;
      }

      default:
        break;
    }
  }
  const getAllDefectsCount = async (payload, state, type) => {
    try {
      let responseObject = await getDefectsDataCount(
        projectId,
        licenseID,
        payload,
        state
      );
      console.log("req count", payload);
      console.log("responseObject count", responseObject);
      if (responseObject?.data?.responseCode === 200) {
        switch (type) {
          case "severity":
            setSeverityDefectsCount(responseObject?.data?.totalDefectCount);
            break;
          case "priority":
            setPriorityDefectsCount(responseObject?.data?.totalDefectCount);
            break;
          case "module":
            setModuleDefectsCount(responseObject?.data?.totalDefectCount);
            break;
          case "expandModule":
            setExpandModuleDefectsCount(responseObject?.data?.totalDefectCount);
            break;
          case "location":
            setLocationDefectsCount(responseObject?.data?.totalDefectCount);
            break;
          case "expandLocation":
            setExpandLocationDefectsCount(
              responseObject?.data?.totalDefectCount
            );
            break;
          default:
        }
      }
    } catch (err) {
      console.log("error");
    }
  };

  return (
    <div>
      <div className="testcases">
        {/* Serverity */}
        <div className="chart-container">
          <div className="col-span-12 heading pl-2 pt-2 ">
            Severity
            {props.allowRejectedDefect ? (
              <div className="text-align">
                <span
                  className={`cursor-pointer popins ${
                    displaySeverityapproverdefect
                      ? "selectedDefects darkBlue"
                      : null
                  }  font-12 pb-2 mr-8 h-8`}
                  onClick={() => {
                    if(displaySeverityapproverdefect===false){
                      updatechartvalues("approved", true, "severity");
                    }
                   
                  }}
                >
                  Approved Defects
                </span>
                <span
                  className={`h-8 pb-2 popins cursor-pointer ${
                    !displaySeverityapproverdefect
                      ? "selectedDefects darkBlue"
                      : null
                  } font-12`}
                  onClick={() => {
                    if(displaySeverityapproverdefect===true){
                      updatechartvalues("rejected", false, "severity");
                    }
                   
                  }}
                >
                  Rejected Defects
                </span>
              </div>
            ) : null}
          </div>
          <div className="col-span-12 grid grid-cols-12 flex px-4 py-4">
            <div className="col-span-5">
              {severitySeries.length === 0 || !hasdata ? (
                <div>
                  <DonutChart />
                </div>
              ) : (
                <div>
                  {
                    displaySeverityapproverdefect?(<StaticChart
                      series={severitySeries}
                      labels={severitySeriesLabel}
                      colors={Severity_Color}
                      approved={displaySeverityapproverdefect}
                      header={"severity"}
                      selectedChartlabelData={selectedChartlabelData}
                    />):(
                      <DynamicChart
                      series={severitySeries}
                      labels={severitySeriesLabel}
                      colors={Severity_Color}
                      approved={displaySeverityapproverdefect}
                      header={"severity"}
                      selectedChartlabelData={selectedChartlabelData}
                      />
                    )
                  }
                 
                </div>
              )}
            </div>
            <div className="col-span-7 grid grid-cols-12">
              <div className="col-span-12">
                <CommonDefectTable
                  data={severityDefects}
                  header={"Defects By Severity"}
                  lable={"severity"}
                  approved={displaySeverityapproverdefect}
                  lastPage={severitylastpage}
                  selectAPI={selectAPI}
                  downloadFile={downloadFile}
                  count={severityDefectsCount}
                />
              </div>
            </div>
          </div>
        </div>
        {/* priority */}
        <div className="chart-container">
          <div className="col-span-12 heading pl-2 pt-2 ">priority</div>
          {props.allowRejectedDefect ? (
            <div className="text-align-left">
              <span
                className={`cursor-pointer popins ${
                  displaypriorityapproverdefect
                    ? "selectedDefects darkBlue"
                    : null
                }  font-12 pb-2 mr-8 h-8`}
                onClick={() => {
                  if(displaypriorityapproverdefect===false){
                    updatechartvalues("approved", true, "priority");
                  }
                 
                }}
              >
                Approved Defects
              </span>
              <span
                className={`h-8 pb-2 popins cursor-pointer ${
                  !displaypriorityapproverdefect
                    ? "selectedDefects darkBlue"
                    : null
                } font-12`}
                onClick={() => {
                  if(displaypriorityapproverdefect===true){
                    updatechartvalues("rejected", false, "priority");
                  }
                  
                }}
              >
                Rejected Defects
              </span>
            </div>
          ) : null}

          <div className="col-span-12 grid grid-cols-12 flex px-4 py-4">
            <div className="col-span-7 grid grid-cols-12">
              <div className="col-span-12">
                <CommonDefectTable
                  data={priorityDefects}
                  header={"Defects By Priority"}
                  lable={"priority"}
                  approved={displaypriorityapproverdefect}
                  lastPage={prioritylastpage}
                  selectAPI={selectAPI}
                  downloadFile={downloadFile}
                  count={priorityDefectsCount}
                />
              </div>
            </div>
            <div className="col-span-5">
              {prioritySeries.length === 0 || !hasdata? (
                <div>
                  <DonutChart />
                </div>
              ) : (
                <div>
                  {
                    displaypriorityapproverdefect?(
                      <StaticChart
                    series={prioritySeries}
                    labels={prioritySeriesLabel}
                    colors={Priority_Color}
                    approved={displaypriorityapproverdefect}
                    header={"priority"}
                    selectedChartlabelData={selectedChartlabelData}
                  />
                    ):(
                      <DynamicChart
                      series={prioritySeries}
                      labels={prioritySeriesLabel}
                      colors={Priority_Color}
                      approved={displaypriorityapproverdefect}
                      header={"priority"}
                      selectedChartlabelData={selectedChartlabelData}
                      />
                    )
                  }
                
                </div>
              )}
            </div>
          </div>
        </div>

        {/* module */}
        {moduleTotalDefects ? (
          <>
            <div className="expande_modal">
              <div className="col-span-12 heading pl-2 pt-2 grid grid-cols-12 pr-2">
                <div className="col-span-10">module</div>
                <div 
                // className="col-span-2 flex justify-end block editIcon"
                 className={moduleSeriesLength > 8
                 ? "col-span-2 flex justify-end block editIcon"
                 : "col-span-2 flex justify-end hidden"}
                  >
                  <Tooltip title="Collapse" placement="top">
                    <IconButton
                      sx={{
                        "&:hover": {
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }}
                    >
                      <button
                        onClick={() => {
                          updateModuleDate(false);
                        }}
                      >
                        <span className="darkBlue-expand">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20.498 20.498"
                          >
                            <path
                              d="M29.555,0h5.951A.989.989,0,0,1,36.5.992V6.943a.992.992,0,0,1-1.694.7L33.192,6.034l-3.6,3.6a.988.988,0,0,1-1.4,0L26.873,8.307a.988.988,0,0,1,0-1.4l3.6-3.6L28.853,1.694A.992.992,0,0,1,29.555,0ZM22.943,20.5H16.992A.989.989,0,0,1,16,19.506V13.555a.992.992,0,0,1,1.694-.7l1.612,1.612,3.6-3.6a.988.988,0,0,1,1.4,0l1.322,1.322a.988.988,0,0,1,0,1.4l-3.6,3.6L23.641,18.8a.992.992,0,0,1-.7,1.694Z"
                              transform="translate(-16 0)"
                              fill="#434db8"
                            />
                          </svg> */}
                           <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 21 20.996"
                  >
                    <path
                      d="M19.349.239a.98.98,0,0,0-1.39,0L14.391,3.807l-1.6-1.6a.985.985,0,0,0-1.681.7V8.81a.982.982,0,0,0,.984.984H18a.985.985,0,0,0,.7-1.681l-1.6-1.6,3.568-3.568a.98.98,0,0,0,0-1.39ZM8.81,11.106H2.9a.985.985,0,0,0-.7,1.681l1.6,1.6L.239,17.954a.98.98,0,0,0,0,1.39l1.312,1.312a.98.98,0,0,0,1.39,0l3.568-3.568,1.6,1.6a.985.985,0,0,0,1.681-.7v-5.9a.982.982,0,0,0-.984-.984Z"
                      transform="translate(0.05 0.05)"
                      fill="#434db8"
                    />
                  </svg>
                        </span>
                      </button>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              {props.allowRejectedDefect ? (
                <div className="text-align">
                  <span
                    className={`cursor-pointer popins ${
                      displayExpandmoduleapproverdefect
                        ? "selectedDefects darkBlue"
                        : null
                    }  font-12 pb-2 mr-8 h-8`}
                    onClick={() => {
                      if( displayExpandmoduleapproverdefect===false){
                        updatechartvalues("approved", true, "Ex-module");
                      }
                     
                    }}
                  >
                    Approved Defects
                  </span>
                  <span
                    className={`h-8 pb-2 popins cursor-pointer ${
                      !displayExpandmoduleapproverdefect
                        ? "selectedDefects darkBlue"
                        : null
                    } font-12`}
                    onClick={() => {
                      if(displayExpandmoduleapproverdefect===true){
                        updatechartvalues("rejected", false, "Ex-module");
                      }
                     
                    }}
                  >
                    Rejected Defects
                  </span>
                </div>
              ) : null}
              <div className="col-spn-12">
                {expandmodual.series.length === 0 || !hasdata? (
                  <div className="flex justify-center">
                    <DonutChart />
                  </div>
                ) : (
                  <div className="flex justify-center p-5">
                    <ModulechartExpand
                      series={expandmodual.series}
                      labels={expandmodual.lables}
                      header={"Ex-module"}
                      selectedChartlabelData={selectedChartlabelData}
                    />
                  </div>
                )}
              </div>
              <div className="col-span-11">
                <CommonDefectTable
                  data={expandmoduleDefects}
                  header={"Defects By Modules"}
                  lable={"Ex-module"}
                  approved={displayExpandmoduleapproverdefect}
                  lastPage={expnadmodulelastpage}
                  selectAPI={selectAPI}
                  downloadFile={downloadFile}
                  count={expandModuleDefectsCount}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="chart-container ">
              <div className="col-span-12 heading pl-2 pt-2 grid grid-cols-12 pr-2">
                <div className="col-span-10">module</div>

                <div
                  className={
                    moduleSeriesLength > 8
                      ? "col-span-2 flex justify-end block editIcon"
                      : "col-span-2 flex justify-end hidden"
                  }
                >
                  <Tooltip title="Expand" placement="top">
                    <IconButton
                      sx={{
                        "&:hover": {
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }}
                    >
                      <button
                        onClick={() => {
                          updateModuleDate(true);
                        }}
                      >
                        <span className="darkBlue-expand">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20.498 20.498"
                          >
                            <path
                              d="M29.555,0h5.951A.989.989,0,0,1,36.5.992V6.943a.992.992,0,0,1-1.694.7L33.192,6.034l-3.6,3.6a.988.988,0,0,1-1.4,0L26.873,8.307a.988.988,0,0,1,0-1.4l3.6-3.6L28.853,1.694A.992.992,0,0,1,29.555,0ZM22.943,20.5H16.992A.989.989,0,0,1,16,19.506V13.555a.992.992,0,0,1,1.694-.7l1.612,1.612,3.6-3.6a.988.988,0,0,1,1.4,0l1.322,1.322a.988.988,0,0,1,0,1.4l-3.6,3.6L23.641,18.8a.992.992,0,0,1-.7,1.694Z"
                              transform="translate(-16 0)"
                              fill="#434db8"
                            />
                          </svg>
                        </span>
                      </button>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              {props.allowRejectedDefect ? (
                <div className="text-align-left">
                  <span
                    className={`cursor-pointer popins ${
                      displaymoduleapproverdefect
                        ? "selectedDefects darkBlue"
                        : null
                    }  font-12 pb-2 mr-8 h-8`}
                    onClick={() => {
                      if( displaymoduleapproverdefect===false){
                        updatechartvalues("approved", true, "module");
                      }
                      
                    }}
                  >
                    Approved Defects
                  </span>
                  <span
                    className={`h-8 pb-2 popins cursor-pointer ${
                      !displaymoduleapproverdefect
                        ? "selectedDefects darkBlue"
                        : null
                    } font-12`}
                    onClick={() => {
                      if(displaymoduleapproverdefect===true){
                        updatechartvalues("rejected", false, "module");
                      }
                      
                    }}
                  >
                    Rejected Defects
                  </span>
                </div>
              ) : null}

              <div className="col-span-12 grid grid-cols-12 flex px-4 py-4">
                <div className="col-span-5">
                  {moduleSeries.length === 0 || !hasdata ? (
                    <div>
                      <DonutChart />
                    </div>
                  ) : (
                    <div>
                      {
                        displaymoduleapproverdefect?(
                          <StaticChart
                        series={moduleSeries}
                        labels={moduleSeriesLabel}
                        colors={Module_Color}
                        approved={displaymoduleapproverdefect}
                        header={"module"}
                        selectedChartlabelData={selectedChartlabelData}
                      />
                        ):(
                          <DynamicChart
                          series={moduleSeries}
                          labels={moduleSeriesLabel}
                          colors={Module_Color}
                          approved={displaymoduleapproverdefect}
                          header={"module"}
                          selectedChartlabelData={selectedChartlabelData}

                          />
                        )
                      }
                      
                    </div>
                  )}
                </div>
                <div className="col-span-7 grid grid-cols-12">
                  <div className="col-span-12">
                    <CommonDefectTable
                      data={moduleDefects}
                      header={"Defects By Modules"}
                      lable={"module"}
                      approved={displaymoduleapproverdefect}
                      lastPage={modulelastpage}
                      selectAPI={selectAPI}
                      downloadFile={downloadFile}
                      count={moduleDefectsCount}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {locationTotalDefects ? (
          <>
            <div className="expande_modal">
              <div className="col-span-12 heading pl-2 pt-2 grid grid-cols-12 pr-2">
                <div className="col-span-10">location</div>
                <div 
                // className="col-span-2 flex justify-end block editIcon"
                className={locationSeriesLength > 8
                  ? "col-span-2 flex justify-end block editIcon"
                  : "col-span-2 flex justify-end hidden"}
                >
                  <Tooltip title="Collapse" placement="top">
                    <IconButton
                      sx={{
                        "&:hover": {
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }}
                    >
                      <button
                        onClick={() => {
                          updateLocationDate(false);
                        }}
                      >
                        <span className="darkBlue-expand">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20.498 20.498"
                          >
                            <path
                              d="M29.555,0h5.951A.989.989,0,0,1,36.5.992V6.943a.992.992,0,0,1-1.694.7L33.192,6.034l-3.6,3.6a.988.988,0,0,1-1.4,0L26.873,8.307a.988.988,0,0,1,0-1.4l3.6-3.6L28.853,1.694A.992.992,0,0,1,29.555,0ZM22.943,20.5H16.992A.989.989,0,0,1,16,19.506V13.555a.992.992,0,0,1,1.694-.7l1.612,1.612,3.6-3.6a.988.988,0,0,1,1.4,0l1.322,1.322a.988.988,0,0,1,0,1.4l-3.6,3.6L23.641,18.8a.992.992,0,0,1-.7,1.694Z"
                              transform="translate(-16 0)"
                              fill="#434db8"
                            />
                          </svg> */}
                           <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 21 20.996"
                  >
                    <path
                      d="M19.349.239a.98.98,0,0,0-1.39,0L14.391,3.807l-1.6-1.6a.985.985,0,0,0-1.681.7V8.81a.982.982,0,0,0,.984.984H18a.985.985,0,0,0,.7-1.681l-1.6-1.6,3.568-3.568a.98.98,0,0,0,0-1.39ZM8.81,11.106H2.9a.985.985,0,0,0-.7,1.681l1.6,1.6L.239,17.954a.98.98,0,0,0,0,1.39l1.312,1.312a.98.98,0,0,0,1.39,0l3.568-3.568,1.6,1.6a.985.985,0,0,0,1.681-.7v-5.9a.982.982,0,0,0-.984-.984Z"
                      transform="translate(0.05 0.05)"
                      fill="#434db8"
                    />
                  </svg>
                        </span>
                      </button>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              {props.allowRejectedDefect ? (
                <div className="text-align">
                  <span
                    className={`cursor-pointer popins ${
                      displayExpandlocationapproverdefect
                        ? "selectedDefects darkBlue"
                        : null
                    }  font-12 pb-2 mr-8 h-8`}
                    onClick={() => {
                      if( displayExpandlocationapproverdefect===false){
                        updatechartvalues("approved", true, "Ex-location");
                      }
                     
                    }}
                  >
                    Approved Defects
                  </span>
                  <span
                    className={`h-8 pb-2 popins cursor-pointer ${
                      !displayExpandlocationapproverdefect
                        ? "selectedDefects darkBlue"
                        : null
                    } font-12`}
                    onClick={() => {
                      if(displayExpandlocationapproverdefect===true){
                        updatechartvalues("rejected", false, "Ex-location");
                      }
                      
                    }}
                  >
                    Rejected Defects
                  </span>
                </div>
              ) : null}
              <div className="col-spn-12">
                {expandlocation.series.length === 0 ? (
                  <div>
                    <DonutChart />
                  </div>
                ) : (
                  <div className="flex justify-center p-5">
                    <ModulechartExpand
                      series={expandlocation.series}
                      labels={expandlocation.lables}
                      header={"Ex-location"}
                      selectedChartlabelData={selectedChartlabelData}
                    />
                  </div>
                )}
              </div>
              <div className="col-span-11">
                <CommonDefectTable
                  data={expandlocationDefects}
                  header={"Defects By Location"}
                  lable={"Ex-location"}
                  approved={displayExpandlocationapproverdefect}
                  lastPage={expandlocationlastpage}
                  selectAPI={selectAPI}
                  downloadFile={downloadFile}
                  count={expandLocationDefectsCount}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="chart-container">
            <div className="col-span-12 heading pl-2 pt-2 grid grid-cols-12 pr-2">
              <div className="col-span-10">location</div>

              <div
                className={
                  locationSeriesLength > 8
                    ? "col-span-2 flex justify-end block editIcon"
                    : "col-span-2 flex justify-end hidden"
                }
              >
                <Tooltip title="Expand" placement="top">
                  <IconButton
                    sx={{
                      "&:hover": {
                        backgroundColor: "#FAF9F6",
                        boxShadow:
                          " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      },
                    }}
                  >
                    <button
                      onClick={() => {
                        updateLocationDate(true);
                      }}
                    >
                      <span className="darkBlue-expand">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20.498 20.498"
                        >
                          <path
                            d="M29.555,0h5.951A.989.989,0,0,1,36.5.992V6.943a.992.992,0,0,1-1.694.7L33.192,6.034l-3.6,3.6a.988.988,0,0,1-1.4,0L26.873,8.307a.988.988,0,0,1,0-1.4l3.6-3.6L28.853,1.694A.992.992,0,0,1,29.555,0ZM22.943,20.5H16.992A.989.989,0,0,1,16,19.506V13.555a.992.992,0,0,1,1.694-.7l1.612,1.612,3.6-3.6a.988.988,0,0,1,1.4,0l1.322,1.322a.988.988,0,0,1,0,1.4l-3.6,3.6L23.641,18.8a.992.992,0,0,1-.7,1.694Z"
                            transform="translate(-16 0)"
                            fill="#434db8"
                          />
                        </svg>
                      </span>
                    </button>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            {props.allowRejectedDefect ? (
              <div className="text-align-left">
                <span
                  className={`cursor-pointer popins ${
                    displaylocationapproverdefect
                      ? "selectedDefects darkBlue"
                      : null
                  }  font-12 pb-2 mr-8 h-8`}
                  onClick={() => {
                    if(displaylocationapproverdefect===false){
                      updatechartvalues("approved", true, "location");
                    }
                    
                  }}
                >
                  Approved Defects
                </span>
                <span
                  className={`h-8 pb-2 popins cursor-pointer ${
                    !displaylocationapproverdefect
                      ? "selectedDefects darkBlue"
                      : null
                  } font-12`}
                  onClick={() => {
                    if(displaylocationapproverdefect===true){
                      updatechartvalues("rejected", false, "location");
                    }
                    
                  }}
                >
                  Rejected Defects
                </span>
              </div>
            ) : null}
            <div className="col-span-12 grid grid-cols-12 flex px-4 py-4">
              <div className="col-span-7 grid grid-cols-12">
                <div className="col-span-12">
                  <CommonDefectTable
                    data={locationDefects}
                    header={"Defects By Location"}
                    lable={"location"}
                    approved={displaylocationapproverdefect}
                    lastPage={locationlastpage}
                    selectAPI={selectAPI}
                    downloadFile={downloadFile}
                    count={locationDefectsCount}
                  />
                </div>
              </div>
              <div className="col-span-5">
                {locationSeries.length === 0 ||  !hasdata? (
                  <div>
                    <DonutChart />
                  </div>
                ) : (
                  <div>
                    {
                      displaylocationapproverdefect?(
                        <StaticChart
                      series={locationSeries}
                      labels={locationSeriesLabel}
                      colors={location_color}
                      approved={displaylocationapproverdefect}
                      header={"location"}
                      selectedChartlabelData={selectedChartlabelData}
                    />
                      ):(
                        <DynamicChart
                        series={locationSeries}
                      labels={locationSeriesLabel}
                      colors={location_color}
                      approved={displaylocationapproverdefect}
                      header={"location"}
                      selectedChartlabelData={selectedChartlabelData}

                        />
                      )
                    }
                    
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* Location */}
      </div>
    </div>
  );
}

export default SegmentChartTableContainer;
