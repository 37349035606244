import React, { useState, useEffect } from "react";
import "./filters.scss";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { ReactComponent as Search } from "../../assets/search.svg";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import { Height } from "@material-ui/icons";

const Option = (props) => {
  return (
    <div>
      <components.Option
        {...props}
        style={{ width: "100px" }}
        className="datatrimmer cursor-pointer"
      >
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={(e) => {
            // props.onClickFilterItem(e);
            console.log("input onchange", props);
          }}
        />{" "}
        <label title={props.value}>{props.label}</label>
      </components.Option>
    </div>
  );
};
const MultiValue = ({ getValue, index }) => {
  return !index && `${getValue().length} selected`;
};

export const FilterBox = (props) => {
  let [expandFilterBox, setExpandFilterBox] = useState(false);
  let [searchvalue, setSearchValue] = useState("");
  let [filteredItems, setFilteredItems] = useState([]);
  let [hasFilter, setHasFilter] = useState(false);
  let [filterCount, setFilterCount] = useState(0);
  let [optionSelected, setOptionSelected] = useState();
  let itemsList = props?.filterList;
  let selectedCount = 0;
  const handleChange = (selected) => {
    setOptionSelected(selected);
  };
  const selectStyles = {
    control: (base) => ({
      ...base,
      color: "4b566b",
      fontFamily: "Poppins-regular",
      fontSize: "12px",
     
    
    
    }),
    menu: (base) => ({
      ...base,
      color: "4b566b",
      fontFamily: "Poppins-regular",
      fontSize: "12px",
     

      
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "white" : "",
      cursor:"pointer",
      color: "4b566b",
      '&:hover': { backgroundColor:'rgb(222, 235, 255)' }
  }),
  };
  useEffect(() => {
    if (searchvalue === "") {
      let tempArr = [];
      let tempOptionSelected = [];
      props.filterList?.map((filterItem, index) => {
        if (props?.addedFilters?.includes(filterItem)) {
          selectedCount++;
            tempOptionSelected.push({
              value: filterItem,
              label: props.label === "Module" ? moduleNameHandler(filterItem) : props.type === "module-type" ? executionModuleNameHandler(filterItem) : props.type === "user-type" ? executionUserNameHandler(filterItem) : filterItem,
              type: props.type,
            });
        }
          tempArr.push({
            value: filterItem,
            label: props.label === "Module" ? moduleNameHandler(filterItem) : props.type === "module-type" ? executionModuleNameHandler(filterItem) : props.type === "user-type" ? executionUserNameHandler(filterItem) : filterItem,
            type: props.type,
          });
      });
      setFilteredItems(tempArr);
      setOptionSelected(tempOptionSelected);
      if (selectedCount > 0) {
        setHasFilter(true);
        setFilterCount(selectedCount);
      }
    }
  }, [props]);
  const moduleNameHandler = (name) => {
    let modulePath = name.split(":")[0];
    let tempModules = modulePath.split("/");
    let moduleName = tempModules[tempModules.length - 1];
    return moduleName;
  };
  const executionModuleNameHandler = (name) => {
    let moduleName = name.split(":")[1];
    return moduleName;
  };
  const executionUserNameHandler = (name) => {
    let userName = name.split(":")[0];
    return userName;
  };
  let searchVariable = (data) => {
    if (data.trim().length) {
      setFilteredItems(
        itemsList.filter((item) =>
          item.toLowerCase().includes(data.toLowerCase())
        )
      );
    } else {
      setFilteredItems(itemsList);
    }
  };

  return (
    <div className="mt-2">
      <div className="filter-box-header text-sm" title={props.label}>
        {props.label}
      </div>
      <div style={{ width: "145px" }}>
        <ReactSelect
          styles={selectStyles}
          options={filteredItems}
          placeholder="Select"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          menuPlacement="auto"
          // menuPosition="fixed"
          maxMenuHeight={130}
          isSearchable={true}
          isClearable={false}
          components={{
            Option,
            MultiValue,
          }}
          onChange={(selector, event) => {
            let tempEvent = {
              target: {
                value: event.option.value,
                name: event.option.type,
                checked: event.action === "select-option",
              },
            };
            props.onClickFilterItem(tempEvent);
            console.log("select onchange", tempEvent);
            handleChange(selector);
          }}
          value={optionSelected}
        />
      </div>
    </div>
  );
};
