import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Tooltip } from '@mui/material';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
function ContextMenu(props) {
    const [pageIndexValue, setPageIndex] = React.useState(0);
    const [pageIndex, setPage] = React.useState(0);
    const [margin, setMargin] = React.useState(0);

    useEffect(()=>{
        if(pageIndexValue > 550) {
            setPage(-250);
            setMargin(150);
        }else {
            setPage(100);
            setMargin();

        }
    }, [pageIndexValue])
    return (
        <Menu as="div" className="relative inline-block text-left " >
            {({ open }) => (
                <>
                    <div data-title="More" id="more" >
                        <Tooltip title="More" placement="top">
                        <Menu.Button
                        //  className="bg-gray-100 rounded-full items-center text-gray-400 hover:text-gray-600 focus:outline-none w-5 h-5 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100  focus:ring-indigo-500"
                        onMouseOver={(e)=>{
                            let mouse = e.clientY
                            setPageIndex(mouse)
                        }}
                        className="mt-1"
                        >
                            <span className="sr-only ">Open options</span>
                            {/* <DotsVerticalIcon className="h-5 w-5 more_icon " aria-hidden="true" /> */}
                            <MoreVertIcon className='h-5 w-5'/>
                        </Menu.Button>
                        </Tooltip>
                    </div>
                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            static
                            style={{ top: `${pageIndex}%`, marginRight:`${margin}%` ,width:'215px'}}
                            className="origin-top-right absolute right-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  z-10 w-48"
                        >
                            <div className="py-1">
                                {props.contextMenuData.map((data, index) => (

                                    <Menu.Item key={`context-menu-item-${index}`}>

                                        {({ active }) => (
                                            <a
                                                
                                                onClick={e=>{props.func(e,data.option)}}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm',
                                                    'hover:text-blue-700'
                                                )}
                                            >

                                                {/* <img src={data.icon} height="20px" width="20px" className="float-left mr-3"></img> */}

                                                <label className={props?.state==="ACTIVE" && data.option==="Resend Activation Link"?"opacity-50":"cursor-pointer"}>{data.option}</label>



                                            </a>
                                        )}
                                    </Menu.Item>



                                ))}
                               
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
}

export default ContextMenu
