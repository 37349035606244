import React, { props } from "react";
import Chart from "react-apexcharts";

function DashboardPieChart(props) {
 let seriesdata=props?.series;
 
  return (
   <>
   <Chart
      type="donut"
      width={200}
      height={225}
      series={seriesdata}
      options={{
        chart: {
          type: "donut",
          // width: 200,
        //   events: {
        //     dataPointSelection: function (event, chartContext, config) {
        //       props.selectedChartlabelData(props.value, [
        //         props.labels[config.dataPointIndex],
        //       ]);
        //     },
        //   },
        },
        labels: props?.labels,
        colors: ["#AED6F1","#D7BDE2","#ABEBC6","#F5CBA7","#ECEDC5","#F5B7B1"],
        theme: {
          monochrome: {
            enabled: false,
          },
        },
        plotOptions: {
            pie: {
           
              expandOnClick: false,
              donut: {
                size:"70%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: '22px',
                    fontFamily: 'Poppins-Medium',
                    fontWeight: 600,
                    color: "#6F7075",
                    offsetY: -10,
                    label:"Total",
                    
                },
                 
                  total: {
                    // showAlways: true,
                    show: true,
                    fontSize: "12px",
                    color: "#6F7075",
                    offsetY: 30,
                    fontFamily: "Poppins-Regular",
                    label:"Total",
                    formatter: function (w) {
                      console.log("wwww hello",w)
                      if(props?.header!=="User"){
                        // return  w.config.series.length
                        return  props?.count
                      }else{
                        
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a+b
                      },0)
                      }
                     
                      
                    }
                  },
                  value: {
                    show: true,
                    color: "#434DB8",
                    fontSize: "14px",
                    offsetY: -5,
                    fontFamily: "Poppins-SemiBold",
                    fontWeight: "10",
                    margin: {
                      top: 20,
                      bottom: 10,
                    },
                  },
                },
              },
            },
          },
        dataLabels: {
          enabled: false,
        },
        tooltip:{
          enabled: true,
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            return `<div style="background: ${ w.globals.colors[seriesIndex]}" class="bar-chart-custom-tooltip"> 
              <span> ${ w.globals.labels[seriesIndex] } : ${series[seriesIndex]}   </span> 
              </div>`
          }
        },
      
        legend: {
            show:false,
        //    width:200,
          position: "right",
          fontFamily: "Poppins-Regular",
          fontSize:"12px",
          onItemClick: {
            toggleDataSeries: false,
          },
        //   formatter: function (val, opts) {
        //     // return val + " - " + opts.w.globals.series[opts.seriesIndex];
        //     if(props.value === "module") {
        //       return `${val.split(/[/,:]/)[val.split(/[/,:]/).length-2]+ " - " + opts.w.globals.series[opts.seriesIndex]}`;
        //     }else{
        //       return val + " - " + opts.w.globals.series[opts.seriesIndex];
        //     }
            
        //   },
        },
      
      }}
    />
    
   </>
    
  );
}

export default DashboardPieChart;
