import React, { Fragment, useState } from 'react'
import TableTree from './table_tree';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import MoreInfoButton from '../more-info-button';
import { plusIconArray } from '../../../util/app-constants';

const TableTreeDemo = (props) => {
    const pageTree =  [
        {
            "ver": 1.0,
            "hierarchy": 0,
            "postCondtionCount": 1,
            "subModuleCount": 33,
            "searchKey": "/MOD1001",
            "scriptCount": 2558,
            "modifiedByUname": "--",
            "title": "Root Module",
            "executionOrder": 0.0,
            "createdByUname": "Sonu",
            "preCondtionCount": 1,
            "path": "/Root",
            "expanded": false,
            "modifiedOn": "--",
            "folder": true,
            "moduleLevelScriptCount": 0,
            "children": [
                {
                    "parentName": "Root Module",
                    "folder": true,
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "hierarchy": 1,
                    "name": "Preconditions",
                    "title": "Preconditions",
                    "type": "PRE",
                    "scriptCount": 1,
                    "key": "PRE_MOD1001",
                    "parentId": "MOD1001",
                    "executionOrder": 0.0
                },
                {
                    "ver": 1.0,
                    "hierarchy": 1,
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "searchKey": "/MOD1001/MOD1007",
                    "scriptCount": 5,
                    "modifiedByUname": "--",
                    "title": "moduleAAA1",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "preCondtionCount": 1,
                    "path": "/Root/moduleAAA1",
                    "expanded": false,
                    "modifiedOn": "--",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 5,
                    "children": [
                        {
                            "expanded": true,
                            "isMockNode": true,
                            "folder": true,
                            "title": "..."
                        }
                    ],
                    "lastExecutionOrder": 1.0,
                    "name": "moduleAAA1",
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1007"
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleQAAA82",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleQAAA82",
                    "expanded": false,
                    "modifiedOn": "10-11-2022 18:10",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "USR4549",
                    "state": "NEW",
                    "key": "MOD1008",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1008",
                    "modifiedByUname": "Sonu",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleQAAA82",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 62,
                    "title": "moduleQAAA163",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleQAAA163",
                    "expanded": false,
                    "modifiedOn": "10-11-2022 18:11",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "USR4549",
                    "state": "NEW",
                    "key": "MOD1009",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1009",
                    "modifiedByUname": "Sonu",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 62,
                    "name": "moduleQAAA163",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleAAA82",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleAAA82",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1010",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1010",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleAAA82",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleAAA163",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleAAA163",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1011",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1011",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleAAA163",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleAAA244",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleAAA244",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1012",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1012",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleAAA244",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleAAA325",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleAAA325",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1013",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1013",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleAAA325",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleAAA406",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleAAA406",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1014",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1014",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleAAA406",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleAAA487",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleAAA487",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1015",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1015",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleAAA487",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleAAA568",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleAAA568",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1016",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1016",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleAAA568",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleAAA649",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleAAA649",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1017",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1017",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleAAA649",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 40,
                    "title": "moduleAAA730",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleAAA730",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1018",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1018",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 40,
                    "name": "moduleAAA730",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleBBB1",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB1",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1019",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1019",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleBBB1",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleBBB82",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB82",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1020",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1020",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleBBB82",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleBBB163",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB163",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1021",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1021",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleBBB163",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleBBB244",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB244",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1022",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1022",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleBBB244",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleBBB325",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB325",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1023",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1023",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleBBB325",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleBBB406",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB406",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1024",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1024",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleBBB406",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleBBB487",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB487",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1025",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1025",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleBBB487",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleBBB568",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB568",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1026",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1026",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleBBB568",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleBBB649",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB649",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1027",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1027",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleBBB649",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleBBB730",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB730",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1028",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1028",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleBBB730",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 79,
                    "title": "moduleBBB811",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB811",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1029",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1029",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 79,
                    "name": "moduleBBB811",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 80,
                    "title": "moduleBBB892",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleBBB892",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1030",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1030",
                    "modifiedByUname": "--",
                    "executionOrder": 2.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 80,
                    "name": "moduleBBB892",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 5,
                    "title": "moduleAAA1 - Copy",
                    "preCondtionCount": 0,
                    "path": "/Root/moduleAAA1 - Copy",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1052",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1052",
                    "modifiedByUname": "--",
                    "executionOrder": 2.5,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 5,
                    "name": "moduleAAA1 - Copy",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 150,
                    "title": "ModulBWQeA152",
                    "preCondtionCount": 0,
                    "path": "/Root/ModulBWQeA152",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1045",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1045",
                    "modifiedByUname": "--",
                    "executionOrder": 3.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 150,
                    "name": "ModulBWQeA152",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 1,
                    "title": "module1Qascdfe",
                    "preCondtionCount": 0,
                    "path": "/Root/module1Qascdfe",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1038",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1038",
                    "modifiedByUname": "--",
                    "executionOrder": 3.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 1,
                    "name": "module1Qascdfe",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 16,
                    "title": "ModulBeA152",
                    "preCondtionCount": 0,
                    "path": "/Root/ModulBeA152",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1040",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1040",
                    "modifiedByUname": "--",
                    "executionOrder": 3.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 16,
                    "name": "ModulBeA152",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 150,
                    "title": "ModulBeA1i8y",
                    "preCondtionCount": 0,
                    "path": "/Root/ModulBeA1i8y",
                    "expanded": false,
                    "modifiedOn": "18-11-2022 18:57",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "USR4549",
                    "state": "NEW",
                    "key": "MOD1041",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1041",
                    "modifiedByUname": "Sonu",
                    "executionOrder": 3.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 150,
                    "name": "ModulBeA1i8y",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 150,
                    "title": "ModulBeA1",
                    "preCondtionCount": 0,
                    "path": "/Root/ModulBeA1",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1042",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1042",
                    "modifiedByUname": "--",
                    "executionOrder": 3.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 150,
                    "name": "ModulBeA1",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 150,
                    "title": "ModulBQeA1",
                    "preCondtionCount": 0,
                    "path": "/Root/ModulBQeA1",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1043",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1043",
                    "modifiedByUname": "--",
                    "executionOrder": 3.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 150,
                    "name": "ModulBQeA1",
                    "conditions": [
        
                    ]
                },
                {
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "scriptCount": 150,
                    "title": "ModulBWQeA1",
                    "preCondtionCount": 0,
                    "path": "/Root/ModulBWQeA1",
                    "expanded": false,
                    "modifiedOn": "--",
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "lastExecutionOrder": 1.0,
                    "modifiedBy": "--",
                    "state": "NEW",
                    "key": "MOD1044",
                    "ver": 1.0,
                    "hierarchy": 1,
                    "searchKey": "/MOD1001/MOD1044",
                    "modifiedByUname": "--",
                    "executionOrder": 3.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 150,
                    "name": "ModulBWQeA1",
                    "conditions": [
        
                    ]
                },
                {
                    "ver": 1.0,
                    "hierarchy": 1,
                    "postCondtionCount": 0,
                    "subModuleCount": 0,
                    "searchKey": "/MOD1001/MOD1046",
                    "scriptCount": 0,
                    "modifiedByUname": "--",
                    "title": "Modulek1",
                    "executionOrder": 4.0,
                    "createdByUname": "Sonu",
                    "parentId": "MOD1001",
                    "preCondtionCount": 0,
                    "path": "/Root/Modulek1",
                    "expanded": false,
                    "modifiedOn": "--",
                    "parentName": "Root Module",
                    "folder": true,
                    "moduleLevelScriptCount": 0,
                    "lastExecutionOrder": 0.0,
                    "name": "Modulek1",
                    "modifiedBy": "--",
                    "state": "NEW",
                    "conditions": [
        
                    ],
                    "key": "MOD1046"
                },
                {
                    "parentName": "Root Module",
                    "folder": true,
                    "children": [
                    {
                        "expanded": true,
                        "isMockNode": true,
                        "folder": true,
                        "title": "..."
                    }
                    ],
                    "hierarchy": 1,
                    "name": "Postconditions",
                    "title": "Postconditions",
                    "type": "POST",
                    "scriptCount": 1,
                    "key": "POST_MOD1001",
                    "parentId": "MOD1001",
                    "executionOrder": 9999.0
                }
            ],
            "lastExecutionOrder": 4.0,
            "name": "Root Module",
            "modifiedBy": "--",
            "state": "NEW",
            "key": "MOD1001"
                
        }
    ];
    const rootData = {
        "modifiedOn": "03/06/2021 11:47",
        "ver": 1,
        "folder": false,
        "modifiedBy": "USR1004",
        "title": "Root Page",
        "elementType": "root",
        "subPageCount": 2,
        "elementCount": 5,
        "key": "ELE1018",
        "isShared": "N",
        "isRecorded": "N",
        "parentId": "PAG1125"
    };


  const [treeValue, setTreeValue] = useState('');
  const [isExpandAll, setIsExpandAll] = useState(false);

  const searchChange = (e) => {
      setTreeValue(e.target.value);
  }
  
  const onNodeSelected = (nodeObj) => {
      console.log(nodeObj);
  }

  const onCheckedNodes = (nodeObjectss) => {
      console.log(nodeObjectss);
  }

  const onAddClicked = (ev, nodeObj) => {
    console.log(nodeObj);
    const selectedNode = nodeObj.node;
    selectedNode.addChildren({
        "modifiedOn": "03/06/2021 11:47",
        "ver": 1,
        "modifiedBy": "USR1004",
        "title": "Dynamic Node",
        "scriptType": "Web",
        "type":"stepgroup",
        "folder": false,
        "status":"Passed",
        "key": "SCR1018",
        "totalSteps": "4",
        "isRecorded": "3",
    });
    selectedNode.setExpanded(true);
  }

  const onEditClicked = (ev, nodeObj) => {
    const selectedNode = nodeObj.node;
    selectedNode.title = 'Updated title...';
    selectedNode.render(true, true);
  }

  const onDeleteClicked = (ev, nodeObj) => {
    console.log(nodeObj)
    nodeObj.node.remove();
  }

  const stateColumnHtml = (nodeObj) => {
        return (
            <div className="state-col-wrp">
                <button className="state-button py-0 px-1 rounded-l">New</button>
                <button className="state-active py-0 px-1 rounded-l">Commit</button>
                <button className="state-button py-0 px-1 rounded-l">Publish</button>
            </div>
        );
    }

    const actionColumnHtml = (nodeObj) => {
        return (
            <div className="action-col-wrp">
                <button disabled={nodeObj.isRootNode} className="state-button py-0 px-1 rounded-l" onClick={(event)=> {onAddClicked(event, nodeObj)}}><AddOutlinedIcon /></button>
                <button disabled={nodeObj.isRootNode} className="state-button py-0 px-1 rounded-l" onClick={(event)=> {onEditClicked(event, nodeObj)}}><EditOutlinedIcon /></button>
                <button disabled={nodeObj.isRootNode} className="state-button py-0 px-1 rounded-l" onClick={(event)=> {onDeleteClicked(event, nodeObj)}}><DeleteOutlinedIcon /></button>
            </div>
        );
    }

    const mouseOverControll = ()=>{

    }
    const handlePlusIcon = ()=>{

    }

    const colDefs = [
        {
            field: "title",
            title: "Module",
            class: "title",
            isTitle: true,
            width: '220px',
            render: (nodeObj) => {
                console.log('nodeObj==',nodeObj);
                return (
                    <>
                        <>
                            <span className="folder-count count-badge" onMouseOver={mouseOverControll}> M{nodeObj.data.subModuleCount} </span>
                            <span className="file-count count-badge"> S{nodeObj.data.scriptCount} </span>
                            {!nodeObj.isRootNode && nodeObj.data.folder && (
                                <button className="add-btn">
                                    <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} methodName={handlePlusIcon} />
                                </button>
                            )}
                        </>
                        
                    </>
                );
            },
        },
        {
            field: "scriptType",
            title: "Type",
            class: "Type",
            width: "70px",
            render: (nodeObj) => {
                return <span style={{ textTransform: 'capitalize' }} className="text-xs table-non-link-color-common">{nodeObj.data.scriptType?.toLowerCase()}</span>;
            },
        },
        {
            field: "State",
            title: "State",
            class: "state",
            width: "160px",
            render: stateColumnHtml,
        },
        {
            field: "version",
            title: "Version",
            class: "version",
            width: "60px",
            render: (nodeObj) => {
                return <span className="version-text-color cursor-pointer">{nodeObj.data.ver ? nodeObj.data.ver.toFixed(1) : ""}</span>;
            },
        },
        {
            field: "modifiedByUname",
            title: "Modified By",
            class: "modified-by text-overflow-style",
            width: "100px",
            render: (nodeObj) => {
                return (
                    <span title={nodeObj.data.modifiedByUname} className="text-blue-700 link cursor-pointer">
                        {nodeObj.data.modifiedByUname}
                    </span>
                );
            },
        },
        {
            field: "action",
            title: "Actions",
            class: "tree-action",
            width: "110px",
            render: actionColumnHtml,
        },
    ];
    const LABELS = {
        EXPAND_ALL: 'Expand All',
        COLLAPSE_ALL: 'Collapse All',
        SHOW_ALL_ELEMENTS: 'Show all scripts',
        HIDE_ALL_ELEMENTS: 'Hide all elements',
        SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
        HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
        NO_DATA_MSG: ''
    };
    
    const loadMoreChildren = async (_modId)=>{
        let _result_tree = [];
        try{
            let res;
            if(_modId){
                // res = await getExpandModuleTreeReq("MOD1002");
            } else {
                // res = await getAllModuleTreeReq();
            }
            if(res && res.data && res.data.responseCode === 200 && res.data.responseObject){
                _result_tree = res.data.responseObject.moduleTree[0].children;
            }
            return _result_tree;
        } catch(error){
            console.error("error during load more data:",error);
            return _result_tree;
        }
    }

    return (
        <div className="page-container">
            <div className="page-list-body">
                <div className="page-table-body w-full">
                    <div className="content_area_style" id="journal-scroll">
                        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
                            <label className="h-2 text-sm flex-auto">Scripts</label>
                        </div>
                        <div className="table-height overflow-auto" id="journal-scroll">
                            <div  className="h-full" >
                                <TableTree 
                                    data={pageTree[0].children}
                                    rootData={rootData}
                                    colDefs={colDefs}
                                    filter={treeValue}
                                    showCheckbox={true}
                                    // hideCheckboxConditions={{key: 'type', value: 'step'}}
                                    onCheckedNodes={onCheckedNodes}
                                    expandAll={isExpandAll}
                                    hideElements={false}
                                    hideConditions={true}
                                    conditions={[{ key: 'title', value: 'Preconditions' }, { key: 'title', value: 'Postconditions' }]}
                                    hideConditionBtn={false}
                                    labels={LABELS}
                                    dnd={true}
                                    onNodeSelected={onNodeSelected} 
                                    loadMoreUrl={loadMoreChildren}
                                    loadMoreData={loadMoreChildren}
                                />
                            </div>
                            {!pageTree.length && (
                            <div className="base-h flex items-center justify-center">
                                <span className="text-center font-extrabold align-middle empty_page_info -mt-20"><h2>No Pages and Elements are available<br />click +Page start adding the Page</h2>
                                    <button
                                        onClick={() => console.log('create eleemnt')}
                                        className=" mt-2 bg-transparent font-bold hover:text-blue-700 py-1 px-3 border border-blue hover:border-blue-500"      >
                                        + Page
                                    </button>
                                </span>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TableTreeDemo;
