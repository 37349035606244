import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Close from "@mui/icons-material/Close";
import { blue } from "@mui/material/colors";
// import { ReactComponent as DownLoad } from "../../assets/download_icon.svg";
// import { ReactComponent as Attachments } from "../../assets/attachment_icon.svg";

import { ReactComponent as DownLoad } from "../../../assets/download_icon.svg";
import { ReactComponent as Attachments } from "../../../assets/attachment_icon.svg";


function SupportingDocsPopup(props) {
  const [openModal, setOpenModal] = useState(true);
 
  
  const customStylesPage = {
    overlay: {
      background:"rgba(90, 90, 90,0.5)", 
      
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "85%",
      transform: "translate(-50%, -50%)",
      width: "30%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
      <div className="tester_deatils">
        <div className="grid grid-cols-12 flex header">
          <span className="col-span-11 heading pl-2">
            {props.heading} 
          </span>
          <div className="col-span-1 flex justify-end mr-1">
             <button
          onClick={() => {
            
             props.functiontypeModalClose(false);
          }}
          type="button"
          className="modal_clos_btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
            
          </div>
        </div>
        {
            props.heading==="Testing Types"?
            (
                <div className="p-4 body">
            
            {props?.list?.map((type)=>{
                return(
                    
                    <li className="values h-10">{(type.charAt(0).toUpperCase() +
                           type.slice(1).toLowerCase()).replaceAll("_", "-")}</li>
                    
                )

            })}
            
        </div>
            ):(
                <div className="p-4 body">
                {props.list.map(
                          (attachments,index)=> {
                            return (
                              <div className="flex ml-2 h-8 supporting_doc_values mt-2 grid grid-cols-12">
                                <div className="attachment_icon mr-2">
                                  {/* <Attachments /> */}
                                  {
                                    index+1
                                  }
                                </div>
                                <div
                                  className="col-span-10 datatrimmer w-34"
                                  title={attachments.slice(
                                    attachments.lastIndexOf("/") + 1,
                                    attachments.length
                                  )}
                                >
                                  {attachments.slice(
                                    attachments.lastIndexOf("/") + 1,
                                    attachments.length
                                  )} 
                                </div>
                                <span
                                  onClick={() => {
                                    props.downloadFiles(attachments);
                                  }}
                                  className="-mt-2 cursor-pointer "
                                >
                                  <DownLoad />
                                </span>
                              </div>
                            );
                          }
                        )}
                </div>
            )
        }
        

        <>
          <div className="grid grid-cols-12 col-span-12 user-details_footer pt-2">
            <div className="col-span-10"></div>
            <div className="grid grid-cols-12 col-span-2 flex justify-end">
              <div className="grid grid-cols-12 col-span-12 h-8 flex justify-end">
                <span
                  className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer"
                  onClick={() => {
                     props.functiontypeModalClose(false);
                  }}
                >
                  <button>Close</button>
                </span>
              </div>
            </div>
          </div>
        </>
      </div>
    </Modal>
  );
}
export default SupportingDocsPopup;
