import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { TextareaAutosize } from "@mui/material";
import { getAnswerForParticularQuestionReq ,getAnswerCountReq} from "../../../api/api-services";
import "./feedback.scss";
import { ReactComponent as Avatar1} from "../../../assets/reportsicon/avatar/avatar1.svg"
import { ReactComponent as Avatar2} from "../../../assets/reportsicon/avatar/avatar2.svg";
import { ReactComponent as Avatar3} from "../../../assets/reportsicon/avatar/avatar3.svg";
import { ReactComponent as Avatar4} from "../../../assets/reportsicon/avatar/avatar4.svg"
import { ReactComponent as Avatar5} from "../../../assets/reportsicon/avatar/avatar5.svg";
import { ReactComponent as Avatar6} from "../../../assets/reportsicon/avatar/avatar6.svg";
import { ReactComponent as Avatar7} from "../../../assets/reportsicon/avatar/avatar7.svg"
import { ReactComponent as Avatar8} from "../../../assets/reportsicon/avatar/avatar8.svg";
import { ReactComponent as Avatar9} from "../../../assets/reportsicon/avatar/avatar9.svg";
import { ReactComponent as Avatar10} from "../../../assets/reportsicon/avatar/avatar10.svg"


function FeedbackTextAnswer(props) {
  const [openModal, setOpenModal] = useState(true);
  let [answer, setAnswer] = useState([]);
  let [pageNo,setPageNo]=useState(0);
  let [lastpage,setlastPage]=useState(false);
  let [totalAnswerCount,setTotalAnswerCount]=useState(0);
  let [isloading,setIsLoading]=useState(false);
  let size =40;
  let [callingAPI,setCallAPI]=useState();
  useEffect(() => {
    getAnswerCount();
    getAnswer(0);
  }, [props]);

  function getAvatarIcons(val){
    let index=val%10
    switch(index){
      case 0:{
        return(
          <Avatar1/>
        )
      
      }
      case 1:{
        return(
          <Avatar2/>
        )
      
      }
      case 2:{
        return(
          <Avatar3/>
        )
      
      }
      case 3:{
        return(
          <Avatar4/>
        )
      
      }
      case 4:{
        return(
          <Avatar5/>
        )
      
      }
      case 5:{
        return(
          <Avatar6/>
        )
      
      }
      case 6:{
        return(
          <Avatar7/>
        )
      
      }
      case 7:{
        return(
          <Avatar8/>
        )
      
      }
      case 8:{
        return(
          <Avatar9/>
        )
      
      }
      case 9:{
        return(
          <Avatar10/>
        )
      
      }
     
      default:
        return(
          <Avatar1/>
        )
      
    }
  }

  function getAnswerCount (){
    let projectID = JSON.parse(localStorage["selected-project"]).id;
    let question = props?.question;
   
    getAnswerCountReq(projectID,question).then((result)=>{
      if(result.data.status===200){
        setTotalAnswerCount(result.data.userCount)
      }

    }).catch((error)=>{
      console.log("error")
    })
   
  }

  async function getAnswer(page) {
    let projectID = JSON.parse(localStorage["selected-project"]).id;
    let question = props?.question;
    if(page===0){
      setIsLoading(true)
    }
   
    setCallAPI(true)
    try {
      let response = await getAnswerForParticularQuestionReq(
        projectID,
        question,
        page,
        size
      );
      console.log("response", response);
      if (response?.data?.status === 200) {
        if(page===0){
          setAnswer(response?.data?.details);
          setPageNo(page+1);
          setlastPage(response?.data?.lastPage)
          console.log("response", response);
        }else{
          let temparray=answer.concat(response?.data?.details)
          setAnswer(temparray)
          setPageNo(page+1);
          setlastPage(response?.data?.lastPage)
        }
        
      }
    } catch (error) {
      console.log("error");
    }
    setCallAPI(false)
    setIsLoading(false)
  }
  console.log("answer",answer)
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "73%",
      transform: "translate(-50%, -50%)",
      width: "55%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  const handleScroll=(event)=>{
    if( Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
    event.target.scrollHeight){
     if(lastpage===false && callingAPI===false){
       getAnswer(pageNo)
    
     }
    }
    
  }

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
      <div className="tester_deatils">
        <div className="grid grid-cols-12  rounded-tl-xl flex header">
          <span className="col-span-11 heading pl-2">Answers - {totalAnswerCount}</span>
          <div className="col-span-1 flex justify-end mr-1">
            <button
              onClick={() => {
                props.closemodal(false);
              }}
              type="button"
              className="modal_clos_btn mr-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="pl-5 pr-2">
          <div>
            <div className="feedback_label mb-2">Question</div>
            <div className="feedback_question_text">{props?.question}</div>
            
            </div>
            <div className="feedback_label mb-2 mt-2">Answers</div>
            
         
          <div className="feedback_body cursor-default mb-10" onScroll={handleScroll}>
            {
              isloading===false  && (
                <>
                 {
              answer?.length===0 && (
                <div className="feedback_text_message">No Answers Found For This Question</div>
              )
            }
            {answer?.map((item,index) => {
                return(
                    <div className="mb-4">
                <div className="flex mb-1">
                 <div className="avatar_icon -ml-2">
                  {
                    getAvatarIcons(index)
                  }
                 
                  </div> 
                 <div className="feedback_user_name datatrimmer w-34 px-1" title={item?.userName}>{item?.userName}</div>
                </div>
                <div>
                  <TextareaAutosize
                    value={item?.value}
                    readOnly={true}
                    className="feedback_answer_text"
                    style={{
                      resize: "none",
                      outline: "none",
                      width: "100%",
                      background:"#FBFCFD"
                    }}
                  />
                </div>
              </div>
                )
              
            })}
                </>
              )
            }
           
           
          </div>
        </div>

        {/* <>
          <div className="grid grid-cols-12 col-span-12 warning_popup_btn  h-12 pt-2">
            <div className="col-span-10"></div>
            <div className="grid grid-cols-12 col-span-2 flex justify-end">
              <div className="grid grid-cols-12 col-span-12 h-8 flex justify-end">
                <span
                  className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer"
                  onClick={() => {
                    props.closemodal(false);
                  }}
                >
                  <button>Close</button>
                </span>
              </div>
            </div>
          </div>
        </> */}
      </div>
    </Modal>
  );
}
export default FeedbackTextAnswer;
