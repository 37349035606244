import React, { useState } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup'
import { TextareaAutosize, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Label } from "../../common/inputs/myInput";
import "../defect-field-forms.scss";

const RatingForm = (props) => {
  let pattern = /^(?! ).*[^ ]$/;

  let alphaNumericPattern =
    /^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9- ]*[-_A-Za-z-_? ]$/;
   
    const [enableTFValue, setEnableTFValue] = useState( props.editFieldValue?.type === 'ratings' ? props.editFieldValue.enable :"yes");
  const [mandatoryTFValue, setMandatoryTFValue] = useState(props.editFieldValue?.type === 'ratings' ? props.editFieldValue.mandatory :"no");
  const [checkForAlphanumeric, setCheckForAlphanumeric] = useState("");
 
    let initialValues = {
        label:props.editFieldValue?.type === 'ratings' ? props.editFieldValue.label : "",
        mandatory: mandatoryTFValue,
       
    
        
      };
      const validationSchema = yup.object({
        label: yup.string().required("Label is required").max(250,"Label should be max 250 character"),
       
       
       
      });
      const formikDetails = useFormik({
        initialValues,
       
        validationSchema,
        
      });
      const handleEnableTFChange = (e) => {
        setEnableTFValue(e.target.value)
    }
    const handleMandatoryTFChange = (e) => {
        setMandatoryTFValue(e.target.value)
        setEnableTFValue("yes")
    }
    function handleTrimmedBlur(event) {
      event.target.value = event.target.value.trim();
      formikDetails.handleChange(event);
      formikDetails.handleBlur(event);
  }
  
  const haveSameData = function (obj1, obj2) {
    console.log("obj1Length",obj1)
    console.log("obj12Length",obj2)
    const obj1Length = Object?.keys(obj1)?.length;
    const obj2Length = Object?.keys(obj2)?.length;
    if (obj1Length === obj2Length) {
      // return Object?.keys(obj1)?.every(
      //   (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
      // );
      return (obj2.label===obj1.label && obj2.mandatory===obj1.mandatory)

      
    }
    return false;
  };
  
  
      const handelTextFieldFormSubmit = (values,e) => {
        // e.preventDefault()
        formikDetails.handleSubmit()
        
        const ratingFieldData = {
                label: formikDetails.values.label,
                options:['1','2','3','4','5'],
                order: props.editFieldValue["order"],
                mandatory: mandatoryTFValue,
                type: "ratings",
            }
            
            
            if([ratingFieldData.label].includes("")){
              
                props.setAddField(true);
    
            }else if (haveSameData(props.editFieldValue, ratingFieldData)) {
              props.MyAlert.info(`No changes were done to the ${ratingFieldData.label} field`)
               return

          }else if (!alphaNumericPattern.test(formikDetails.values.label)) {
            console.log("checkDuplicateOptions2")
            setCheckForAlphanumeric("Label should be alphanumeric");
          } 
           else{
               props.addFormField(ratingFieldData);
               props.setAddField(false);
               
            }
    
               
            
      };

  return (
    <div className="form-content_radioForms overflow-y-scroll ">
      <div className="my-5  px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Label
              </span>
            }
            required={true}
            error={formikDetails.errors.label && formikDetails.touched.label}
          />
        </div>
        <TextField
          error={formikDetails.errors.label && formikDetails.touched.label}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          name="label"
          id="label"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={handleTrimmedBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.label}
        />
        {formikDetails.errors.label && formikDetails.touched.label ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.label}
          </div>
        ) : null}
         {checkForAlphanumeric && (
          <div className="text-red-500 error_text md:mt-1">{checkForAlphanumeric}</div>
        )}
        
      </div>
     
     
     
    

     
      <div className="mb-4 my-2 px-2 ml-1.5">
        <Label
          label={
            <span className="textFieldHeader text-sm  popins-medium  ">
              Mandatory
            </span>
          }
          required={true}
        />
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              value={mandatoryTFValue}
              onChange={handleMandatoryTFChange}
              className="textFieldHeader text-sm  popins-medium  "
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
        <button
          onClick={() => {
            props.setAddField(false);
            props.clearEditValue({})
          }}
          type="button"
          className="w-24 gray-btn ml-3 button"
        >
          Cancel
        </button>
        <button
          type="submit"
          id="createBatch"
         
          className="w-24 primary-btn button"
          onClick={handelTextFieldFormSubmit}
        >
          {props.editFieldValue?.type === 'ratings' ?"Update" :"Add"}
        </button>
      </div>
    </div>
  );
};

export default RatingForm;
