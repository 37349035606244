import { createSlice } from '@reduxjs/toolkit'

export const logedinUser = createSlice({
    name:"fireCrowdUser",
    initialState:{
        value:{},
        selectedProject:{}
    },
    reducers:{
        saveFireCrowdUser: (state, action)=>{
            state.value = action.payload
        },
        saveSelectedProject: (state, action)=>{
            state.selectedProject = action.payload
        }
    }
})

export const {saveFireCrowdUser, saveSelectedProject} = logedinUser.actions

export const fireCrowdUserData = (state) => state.fireCrowdUser.value
export const selectedProjectData = (state) => state.fireCrowdUser.selectedProject

export default logedinUser.reducer;