import React, { useState, Fragment,useEffect } from "react";
import { FilterBox } from "../../reports/filter-box";
import { FilterLabels } from "../../reports/filter-labels"
import ClearFilterWarningPopup from "../clear-filter-warning-popup";
import CloseIcon from '@mui/icons-material/Close';

let segmentSelected = [],
  segmentTempSelected = [],
  webSelected = [],
  webTempSelected = [],
  mobileSelected = [],
  mobileTempSelected = [];

let filterBasePayLoad = {
  segmentSearchFilters: {
    severity: null,
    priority: null,
    modules: null,
    locations: null,
    createdOn: null,
  },
  webSearchFilters: {
    os: null,
    osVersion: null,
    browser: null,
    browserVersion: null,
  },
  mobileSearchFilters: {
    os: null,
    osVersion: null,
    browser: null,
    browserVersion: null,
    deviceBrand: null,
    deviceName: null,
    deviceType: null,
  },
};
let filterPayLoad = JSON.parse(JSON.stringify(filterBasePayLoad));

function NewFilters(props){
    const [openModal, setOpenModal] = useState(true);
  const [severity, setSeverity] = useState(props.Severity);
  const [priority, setPriority] = useState(props.Priority);
  const [module, setModule] = useState(props.Module);
  const [location, setLocation] = useState(props.Location);
  const [byDate, setByDate] = useState([]);
  const [webArray, setWebArray] = useState([]);
  const [mobileArray, setMobilrArray] = useState([]);
  const [webOS, setWebOS] = useState([]);
  const [webOSVersion, setWebOSVersion] = useState([]);
  const [webBrowser, setWebBrowser] = useState([]);
  const [webBrowserVersion, setWebBrowserVersion] = useState([]);
  const [mobileOS, setMobileWebOS] = useState([]);
  const [mobileOSVersion, setMobileOSVersion] = useState([]);
  const [mobileBrowser, setMobileBrowser] = useState([]);
  const [mobileBrowserVersion, setMobileBrowserVersion] = useState([]);
  const [deviceName, setDeviceName] = useState([]);
  const [deviceType, setDeviceType] = useState([]);
  const [deviceBrand, setDeviceBrand] = useState([]);
  const [filterChanged, setFilterChanged] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [confirmClearFilter, setConfirmClearFilter] = useState(false);
  // let [closeFilterBox, setCloseFilterBox] = useState(false);
  const severityLabel = "Severity";
  const priorityLabel = "Priority";
  const moduleLabel = "Module";
  const locationLabel = "Location";
  const osLabel = "OS";
  const osVersionLabel = "OS Version";
  const browserLabel = "Browser";
  const browserVersionLabel = "Browser Version";
  const deviceNameLabel = "Device Name";
  const deviceBrandLabel = "Device Brand";
  const deviceTypeLabel = "Device Type";
  let [segmentfilter,setSegmentFilter]=useState(segmentSelected);
  let typeOfProject=JSON.parse(localStorage["selected-project"])?.project?.projectType?.others?.typeOfProject?JSON.parse(localStorage["selected-project"])?.project?.projectType?.others?.typeOfProject:"";

  

  


  

  useEffect(()=>{
    let currentTab = JSON.parse(localStorage["currentReportTab"]);
    if(!(currentTab === "DefectReports")){
      segmentSelected = [];
      segmentTempSelected = [];
      webSelected = [];
      webTempSelected = [];
      mobileSelected = [];
      mobileTempSelected = [];
      filterPayLoad = JSON.parse(JSON.stringify(filterBasePayLoad));
    }
  }, []);
  segmentTempSelected =
    segmentTempSelected.length > 0
      ? segmentTempSelected
      : props?.filters?.segment
      ? props?.filters?.segment
      : [];
  webTempSelected =
    webTempSelected.length > 0
      ? webTempSelected
      : props?.filters?.web
      ? props?.filters?.web
      : [];
  mobileTempSelected =
    mobileTempSelected.length > 0
      ? mobileTempSelected
      : props?.filters?.mobile
      ? props?.filters?.mobile
      : [];

  useEffect(() => {
    filterDataHandler(props.data);
    
  }, [filterChanged]);

  


  const filterDataHandler = (data) => {
    let tempMobile = props.mobile,
      tempWeb = props.web,
      tempWebOS = [],
      tempWebOSVersion = [],
      tempWebBrowser = [],
      tempWebBrowserVersion = [],
      tempMobileOS = [],
      tempMobileOSVersion = [],
      tempMobileBrowser = [],
      tempMobileBrowserVersion = [],
      tempDeviceType = [],
      tempDeviceBrand = [],
      tempDeviceName = [];

    if (props.web !== null) {
      tempWeb.map((obj, i) => {
        if (duplicateCheckHandler(obj, "os", tempWebOS)) {
          tempWebOS.push(obj.os);
        }
        if (duplicateCheckHandler(obj, "osVersion", tempWebOSVersion)) {
          tempWebOSVersion.push(obj.osVersion);
        }
        if (duplicateCheckHandler(obj, "browser", tempWebBrowser)) {
          tempWebBrowser.push(obj.browser);
        }
        if (
          duplicateCheckHandler(obj, "browserVersion", tempWebBrowserVersion)
        ) {
          tempWebBrowserVersion.push(obj.browserVersion);
        }
      });
    }
    if (props.mobile !== null) {
      tempMobile.map((obj, i) => {
        if (duplicateCheckHandler(obj, "os", tempMobileOS)) {
          tempMobileOS.push(obj.os);
        }
        if (duplicateCheckHandler(obj, "osVersion", tempMobileOSVersion)) {
          tempMobileOSVersion.push(obj.osVersion);
        }
        if (duplicateCheckHandler(obj, "browser", tempMobileBrowser)) {
          tempMobileBrowser.push(obj.browser);
        }
        if (
          duplicateCheckHandler(obj, "browserVersion", tempMobileBrowserVersion)
        ) {
          tempMobileBrowserVersion.push(obj.browserVersion);
        }
        if (duplicateCheckHandler(obj, "deviceType", tempDeviceType)) {
          tempDeviceType.push(obj.deviceType);
        }
        if (duplicateCheckHandler(obj, "deviceName", tempDeviceName)) {
          tempDeviceName.push(obj.deviceName);
        }
        if (duplicateCheckHandler(obj, "deviceBrand", tempDeviceBrand)) {
          tempDeviceBrand.push(obj.deviceBrand);
        }
      });
    }
    setWebArray(tempWeb);
    setMobilrArray(tempMobile);

    setWebOS(tempWebOS);
    setWebOSVersion(tempWebOSVersion);
    setWebBrowser(tempWebBrowser);
    setWebBrowserVersion(tempWebBrowserVersion);
    setMobileWebOS(tempMobileOS);
    setMobileOSVersion(tempMobileOSVersion);
    setMobileBrowser(tempMobileBrowser);
    setMobileBrowserVersion(tempMobileBrowserVersion);
    setDeviceType(tempDeviceType);
    setDeviceName(tempDeviceName);
    setDeviceBrand(tempDeviceBrand);
  };
  const duplicateCheckHandler = (obj, key, tempArray) => {
    return (
      obj[key] !== undefined &&
      obj[key] !== null &&
      obj[key] !== "" &&
      !tempArray.includes(obj[key])
    );
  };
  const clearAllFilters = (callApi) => {
    filterPayLoad = JSON.parse(JSON.stringify(filterBasePayLoad));
    segmentSelected = [];
    webSelected = [];
    mobileSelected = [];
    segmentTempSelected = [];
    webTempSelected = [];
    mobileTempSelected = [];
    if (callApi) {
      props.filterApply(
        filterPayLoad,
        true,
        {
          segment: segmentTempSelected,
          web: webTempSelected,
          mobile: mobileTempSelected,
        },
        true
      );
    }
    setFilterChanged(!filterChanged);
    setFilterApplied(false);
  };

  
 

  function onCloseWarningPopup() {
    setConfirmClearFilter(false);
  }

  const handleFilterReq = (event) => {
    let filterVal = event.target.value;
    let filterKey = event.target.name;
    let tempSegment = filterKey.split("-")[0];
    let tempKey = filterKey.split("-")[1];

    if (event.target.checked) {
      if (tempSegment === "segment") {
        segmentSelected.push(event.target);
        segmentTempSelected.push(filterVal);
        if (filterPayLoad.segmentSearchFilters[tempKey] === null) {
          filterPayLoad.segmentSearchFilters[tempKey] = [];
          filterPayLoad.segmentSearchFilters[tempKey].push(filterVal);
        } else {
          filterPayLoad.segmentSearchFilters[tempKey].push(filterVal);
        }
      } else if (tempSegment === "web") {
        webSelected.push(event.target);
        webTempSelected.push(filterVal);
        if (filterPayLoad.webSearchFilters[tempKey] === null) {
          filterPayLoad.webSearchFilters[tempKey] = [];
          filterPayLoad.webSearchFilters[tempKey].push(filterVal);
        } else {
          filterPayLoad.webSearchFilters[tempKey].push(filterVal);
        }
      } else {
        mobileSelected.push(event.target);
        mobileTempSelected.push(filterVal);
        if (filterPayLoad.mobileSearchFilters[tempKey] === null) {
          filterPayLoad.mobileSearchFilters[tempKey] = [];
          filterPayLoad.mobileSearchFilters[tempKey].push(filterVal);
        } else {
          filterPayLoad.mobileSearchFilters[tempKey].push(filterVal);
        }
      }
    } else {
      let tempIndex = "";
      if (tempSegment === "segment") {
        tempIndex = segmentTempSelected.indexOf(filterVal);
        segmentSelected.splice(tempIndex, 1);
        segmentTempSelected.splice(tempIndex, 1);
        filterPayLoad.segmentSearchFilters[tempKey].splice(
          filterPayLoad.segmentSearchFilters[tempKey].indexOf(filterVal),
          1
        );
        if (filterPayLoad.segmentSearchFilters[tempKey].length === 0) {
          filterPayLoad.segmentSearchFilters[tempKey] = null;
        }
      } else if (tempSegment === "web") {
        tempIndex = webTempSelected.indexOf(filterVal);
        webSelected.splice(tempIndex, 1);
        webTempSelected.splice(tempIndex, 1);
        filterPayLoad.webSearchFilters[tempKey].splice(
          filterPayLoad.webSearchFilters[tempKey].indexOf(filterVal),
          1
        );
        if (filterPayLoad.webSearchFilters[tempKey].length === 0) {
          filterPayLoad.webSearchFilters[tempKey] = null;
        }
      } else {
        tempIndex = mobileTempSelected.indexOf(filterVal);
        mobileSelected.splice(tempIndex, 1);
        mobileTempSelected.splice(tempIndex, 1);
        filterPayLoad.mobileSearchFilters[tempKey].splice(
          filterPayLoad.mobileSearchFilters[tempKey].indexOf(filterVal),
          1
        );
        if (filterPayLoad.mobileSearchFilters[tempKey].length === 0) {
          filterPayLoad.mobileSearchFilters[tempKey] = null;
        }
      }
    }
    console.log("filter payload", filterPayLoad);
    setFilterChanged(!filterChanged);
  };
    return(
        <div className={props?.page==="Defect"?"filter_containers":"new_filter_containers"}>
            
       
       <div className="flex  rounded-tl-xl justify-between defect-filter-modal-heading h-10 p-2">
        <div>Defect Log Filter</div>
        
        <button
          onClick={() => {
           
            setOpenModal(false);
            props.CloseDefectFilterModal(false);
            if (!props.filters.hasOwnProperty("segment")) {
              clearAllFilters(false);
            }
           
            
          }}
          type="button"
          className="modal-close-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="new_filter_section">
      <div>
        <div className={`p-3 mx-3 mt-2 filter-lables-container mb-4 ${(segmentSelected.length === 0 &&
            webSelected.length === 0 &&
            mobileSelected.length === 0 )?"hidden":"block"}`}>

          {segmentSelected.length === 0 &&
            webSelected.length === 0 &&
            mobileSelected.length === 0 && (
              <div className="flex justify-center">
                <span className="no-defects-label">No Selected Filter</span>
              </div>
            )}
          {segmentSelected.length > 0 && (
            <div>
              <div className="selected_filter_header">Segment</div>
              <FilterLabels
                filterLabelsList={segmentSelected}
                onLabelsChange={handleFilterReq}
              />
            </div>
          )}
          {webSelected.length > 0 && (
            <div>
              <div className="selected_filter_header">{JSON.parse(localStorage["selected-project"])?.project?.projectType?.others?.typeOfProject?JSON.parse(localStorage["selected-project"])?.project?.projectType?.others?.typeOfProject.replace("_"," "):"Web"}</div>
              <FilterLabels
                filterLabelsList={webSelected}
                onLabelsChange={handleFilterReq}
              />
            </div>
          )}
          {mobileSelected.length > 0 && (
            <div>
              <div className="selected_filter_header">{JSON.parse(localStorage["selected-project"])?.project?.projectType?.others?.typeOfProject?JSON.parse(localStorage["selected-project"])?.project?.projectType?.others?.typeOfProject.replace("_"," "):"Mobile"}</div>
              <FilterLabels
                filterLabelsList={mobileSelected}
                onLabelsChange={handleFilterReq}
              />
            </div>
          )}
        </div>
      </div>
      <div >
        <div className="p-3 grid grid-cols-12">
          <div className="filter-header col-span-12">Segment</div>
          <div className="col-span-12 grid grid-cols-12 gap-2">
            <div className={`col-span-3 ${(severity !== null && severity?.length > 0)?"block":"hidden"}`}>
              {severity !== null && severity?.length > 0 && (
                <FilterBox
                  filterList={severity}
                  label={severityLabel}
                  type={`segment-severity`}
                  // closeFilterBox={closeFilterBox}
                  onClickFilterItem={handleFilterReq}
                  addedFilters={segmentTempSelected}
                />
              )}
            </div>
            <div className={`col-span-3 ${(priority !== null && priority?.length > 0)?"block":"hidden"}`}>
              {priority !== null && priority?.length > 0 && (
                <FilterBox
                  filterList={priority}
                  label={priorityLabel}
                  type={`segment-priority`}
                  onClickFilterItem={handleFilterReq}
                  addedFilters={segmentTempSelected}
                />
              )}
            </div>
            <div className={`col-span-3 ${(module !== null && module?.length > 0)?"block":"hidden"}`}>
              {module !== null && module?.length > 0 && (
                <FilterBox
                  filterList={module}
                  label={moduleLabel}
                  type={`segment-modules`}
                  onClickFilterItem={handleFilterReq}
                  addedFilters={segmentTempSelected}
                />
              )}
            </div>
            <div className={`col-span-3 ${(location !== null && location?.length > 0)?"block":"hidden"}`}>
              {location !== null && location?.length > 0 && (
                <FilterBox
                  filterList={location}
                  label={locationLabel}
                  type={`segment-locations`}
                  onClickFilterItem={handleFilterReq}
                  addedFilters={segmentTempSelected}
                />
              )}
            </div>
          </div>
        </div>
        {webArray?.length > 0 && (
          <div className="p-3 grid grid-cols-12">
            <div className="filter-header col-span-12">{JSON.parse(localStorage["selected-project"])?.project?.projectType?.others?.typeOfProject?JSON.parse(localStorage["selected-project"])?.project?.projectType?.others?.typeOfProject?.replace("_"," "):"Web"}</div>
            <div className="col-span-12 grid grid-cols-12 gap-2">
              <div className={`col-span-3 ${webOS?.length > 0?"block":"hidden"}`}>
                {webOS?.length > 0 && (
                  <FilterBox
                    filterList={webOS}
                    label={osLabel}
                    type={`web-os`}
                    onClickFilterItem={handleFilterReq}
                    addedFilters={webTempSelected}
                  />
                )}
              </div>
              <div className={`col-span-3 ${webOSVersion?.length > 0?"block":"hidden"}`}>
                {webOSVersion?.length > 0 && (
                  <FilterBox
                    filterList={webOSVersion}
                    label={osVersionLabel}
                    type={`web-osVersion`}
                    onClickFilterItem={handleFilterReq}
                    addedFilters={webTempSelected}
                  />
                )}
              </div>
              {
                typeOfProject==="" && (
                  <>
                   <div className={`col-span-3 ${webBrowser?.length > 0?"block":"hidden"}`}>
                {webBrowser?.length > 0 && (
                  <FilterBox
                    filterList={webBrowser}
                    label={browserLabel}
                    type={`web-browser`}
                    onClickFilterItem={handleFilterReq}
                    addedFilters={webTempSelected}
                  />
                )}
              </div>
              <div className={`col-span-3 ${webBrowserVersion?.length > 0?"block":"hidden"}`}>
                {webBrowserVersion?.length > 0 && (
                  <FilterBox
                    filterList={webBrowserVersion}
                    label={browserVersionLabel}
                    type={`web-browserVersion`}
                    onClickFilterItem={handleFilterReq}
                    addedFilters={webTempSelected}
                  />
                )}
              </div>
                  </>
                )
              }
             
            </div>
          </div>
        )}
        {mobileArray?.length > 0 && (
          <div className="p-3 grid grid-cols-12">
            <div className="filter-header col-span-12">{JSON.parse(localStorage["selected-project"])?.project?.projectType?.others?.typeOfProject?JSON.parse(localStorage["selected-project"])?.project?.projectType?.others?.typeOfProject?.replace("_"," "):"Mobile"}</div>
            <div className="col-span-12 grid grid-cols-12 gap-2">
            <div className={`col-span-3 ${mobileOS?.length > 0?"block":"hidden"}`}>
            {mobileOS?.length > 0 && (
                    <FilterBox
                      filterList={mobileOS}
                      label={osLabel}
                      type={`mobile-os`}
                      onClickFilterItem={handleFilterReq}
                      addedFilters={mobileTempSelected}
                    />
                  )}
            </div>
            <div className={`col-span-3 ${mobileOSVersion?.length > 0?"block":"hidden"}`}>
            {mobileOSVersion?.length > 0 && (
                    <FilterBox
                      filterList={mobileOSVersion}
                      label={osVersionLabel}
                      type={`mobile-osVersion`}
                      onClickFilterItem={handleFilterReq}
                      addedFilters={mobileTempSelected}
                    />
                  )}
            </div>
          
            <div className={`col-span-3 ${deviceName?.length > 0?"block":"hidden"}`}>
                  {deviceName?.length > 0 && (
                    <FilterBox
                      filterList={deviceName}
                      label={deviceNameLabel}
                      type={`mobile-deviceName`}
                      onClickFilterItem={handleFilterReq}
                      addedFilters={mobileTempSelected}
                    />
                  )}
            </div>
            <div className={`col-span-3 ${deviceBrand?.length > 0 ?"block":"hidden"}`}>
                  {deviceBrand?.length > 0 && (
                    <FilterBox
                      filterList={deviceBrand}
                      label={deviceBrandLabel}
                      type={`mobile-deviceBrand`}
                      onClickFilterItem={handleFilterReq}
                      addedFilters={mobileTempSelected}
                    />
                  )}
                </div>

                <div className={`col-span-3 ${(deviceType?.length > 0 && !(JSON.parse(localStorage["selected-project"])?.project?.projectType?.others))?"block":"hidden"}`}>
                  {deviceType?.length > 0 && (
                    <FilterBox
                      filterList={deviceType}
                      label={deviceTypeLabel}
                      type={`mobile-deviceType`}
                      onClickFilterItem={handleFilterReq}
                      addedFilters={mobileTempSelected}
                    />
                  )}
                </div>

            </div>

          
          </div>
        )}
      </div>
      </div>
     
     
      <div className="py-2 px-3 flex justify-end gap-6  filter-modal-buttons">
        <button
          onClick={() => {
            setConfirmClearFilter(true);
          }}
          type="button"
          className="w-24 clear-button button"
        >
          Clear
        </button>
   
        <button
          className={"w-24 primary-btn button"}
          onClick={() => {
            setOpenModal(false);
            props.CloseDefectFilterModal(false);
            setFilterApplied(true);
            
            props.filterApply(
              filterPayLoad,
              true,
              {
                segment: segmentTempSelected,
                web: webTempSelected,
                mobile: mobileTempSelected,
              },
              true
            );
          }}
        >
          Apply
        </button>
      </div>
      {confirmClearFilter && (
        <ClearFilterWarningPopup
          onCloseWarningPopup={onCloseWarningPopup}
          clearFilters={clearAllFilters}
        />
        
      )}
        </div>
    )
   


}
export default NewFilters;