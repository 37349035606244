import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import LogotextImage from "../../assets/fire_crowd.svg";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import BackgroundDown from "../../assets/login_logo.png";
import style from "./signup.module.scss";
import cx from "classnames";
import MyInput, { Label } from "../common/inputs/myInput";
import Alert from "@material-ui/lab/Alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { forgotPasswordRequest} from "../../api/api-services";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceSharpIcon from '@mui/icons-material/KeyboardBackspaceSharp';

const FORGOT_PASSWORD_LEFT_HEADING="Reset Password Quickly";
const FORGOT_PASSWORD_LEFT_MSG_ONE="Forgot your password? Don't Worry";
const FORGOT_PASSWORD_LEFT_MSG_TWO="By following a few simple steps, you can rapidly recover your password.";

const FORGOT_PASSWORD_RIGHT_HEADING="Forgot Password";
const FORGOT_PASSWORD_RIGHT_MSG_ONE="Please enter your registered email to receive reset password link.";
const FORGOT_PASSWORD_RIGHT_MSG_TWO="We will send you email to reset your password";


function ForgotPassword() {
  const [success, setSuccess] = useState({ open: false, message: "" });
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [errorshow, setErrorShow] = useState(false);
  
  const navigate = useNavigate();
  
  const formik = useFormik({
    initialValues: {
      emailId: "",
    },
    validationSchema: Yup.object({
      emailId: Yup.string()
        .matches(
          /[A-Za-z0-9]+@[a-zA-Z]+\.[A-Za-z]{2,3}/,
          "Enter valid email Id"
        )
        .email("Enter valid email Id")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      if (createUpdateCalled) {
        setCreateUpdateCalled(false);
        const data = {
          emailId: values.emailId,
          source: "FireCrowd"
        };
        forgotPasswordRequest(data)
          .then((res) => {
            const response = res.data.responseCode;
            if (response === 200 && res.status === 200) {
              // history.push("/email-send");
              setTimeout(() => {
                setCreateUpdateCalled(true);
              }, 10000);
              // setSuccess({ open: true });
              navigate("/reset-password" ,{ replace: true });
            } else if (response !== 200) {
              setErrorShow(true);
            }
            // setTimeout(() => {
            //   setSuccess({ open: false });
            // }, 5000);
            if (res.data && res.data.message === "User is not activated") {
              navigate("/registration-activation?email=" + values.emailId ,{ replace: true });
            }
          })
          .catch((error) => {});
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 min-h-screen">
        <div className={cx(style["activation_link"], "col-span-6 border-r-4")}>
          <div
            className={cx(style["activation_link_leftImage"], "grid grid-cols-span-12 col-span-6 flex justify-center")}
            id="leftImage"
          >
            <div className={cx(style["leftImage"])}>
              <img
                className={cx(style["logo_Icon"])}
                src={LogotextImage}
                height="200px"
                width="300px"
                alt="Qspider"
              />
            </div>
          </div>
          <div className="grid grid-cols-span-12 col-span-6 flex justify-center">
            <div>
              <img
                height="350px"
                width="350px"
                src={BackgroundDown}
                alt="Firecrowd"
              />
            </div>
          </div>
          <div
            className={cx(
              style["heading"],
              "grid grid-cols-span-12 col-span-6 flex justify-center"
            )}
          >
            <div>{FORGOT_PASSWORD_LEFT_HEADING}</div>
          </div>
          <div
            className={cx(
              style["msg"],
              "grid grid-cols-span-12 col-span-6 flex justify-center mt-2"
            )}
          >
            <span>{FORGOT_PASSWORD_LEFT_MSG_ONE}</span>
          </div>
          <div
            className={cx(
              style["msg"],
              "grid grid-cols-span-12 col-span-6 flex justify-center mt-1"
            )}
          >
            <span>
              {FORGOT_PASSWORD_LEFT_MSG_TWO}
              
            </span>
          </div>
        </div>
        <div className={cx(style["activation_page"], "col-span-6 pt-28")}>
        <div className={cx(style["mobile-screen-logo"])}>
              <img
                src={LogotextImage}
                height="200px"
                width="300px"
                alt="Qspider"
              />
            </div> 
        <div className={cx(style["password_change_mail_alert"], "grid grid-cols-span-12 col-span-6 flex justify-center mt-4")}>
            {success.open && (
            <Alert
             className={cx(style["warningAlert"])}
              iconMapping={{ success: <CheckCircleIcon fontSize="inherit" className={cx(style["alertIconActivationLink"])} /> }}
            >
              
              <span className={cx(style["alertHeading"])}>Email sent successfully</span><br></br>
              <span className={cx(style["alertMsg"])}>check your email and open the link we sent,to continue</span>

            </Alert>
          )}{" "}
            </div>
          <div
            className={cx(
              style["heading"],
              "grid grid-cols-span-12 col-span-6 flex justify-center mt-4"
            )}
          >
            
            <div className="col-span-12">{FORGOT_PASSWORD_RIGHT_HEADING}</div>
            <div
            className={cx(
              style["msg"],
              "grid grid-cols-span-12 col-span-6 flex justify-center"
            )}
          >
            <span>{FORGOT_PASSWORD_RIGHT_MSG_ONE}</span>
            {/* <span>{FORGOT_PASSWORD_RIGHT_MSG_TWO}</span> */}
            <div className="mt-6">
          <MyInput
                    label="Email"
                    required
                    placeholder=" Enter registered  email ID"
                    variant="outlined"
                    error={formik.errors.emailId && formik.touched.emailId}
                    value={formik.values.emailId}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      setCreateUpdateCalled(true);
                    }}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    name="emailId"
                    onFocus={() => setErrorShow(false)}
                    helperText={
                      <div  className={cx(style["error_msg"])}>{formik.touched.emailId && formik.errors.emailId}</div>
                    }
                   
                  />
                  {errorshow === true && (
                <div  
                 className={cx(style["error_msg"],"-mt-6 absolute left-2")}
               
                >
                This email id doesn't exist, please enter valid email id
                </div>
              )}
          </div>
          <div>
              <button
                type="submit"
                className={cx(
                  style["forgotPassButton"],"mt-4"
                  // "flex justify-center py-2 mt-6 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium  hover:bg-blue-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                )}
                
              >
               Send Email To Reset The Password
              </button>
            </div>
            <div className={cx(style["link_text"],"mt-4")}>
            <KeyboardBackspaceSharpIcon sx={{ fontSize: 16 }}/> {" "}
              <a href="/">
                Back to sign in
              </a>
            </div>
          </div>
          </div>
          
          
        </div>
      </div>
    </form>
  );
}
export default ForgotPassword;
