import React from 'react'
import { useNavigate } from 'react-router-dom';
import righticon from "../../assets/right-img-circle.svg";
// import LogotextImage from "../../assets/fire_crowd.svg";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import BackgroundDown from "../../assets/login_logo.png";
import style from "./signup.module.scss";
import cx from "classnames";
import "./signup.scss";
const ActivationSuccess = () => {
    
  return (
    <div className="grid grid-cols-12 min-h-screen ">
    <div className={cx(style["activation_success_link"], "col-span-6 border-r-4")}>
      <div
        className={cx(style["activation_success_link_leftImage"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-10")}
        id="leftImage"
      >
        <div className={cx(style["leftImage"])}>
          <img
           className={cx(style["logo_Icon"])}
            src={LogotextImage}
            height="200px"
            width="300px"
            alt="Qspider"
          />
        </div>
      </div>
      <div className="grid grid-cols-span-12 col-span-6 flex justify-center ">
        <div>
          <img
            height="350px"
            width="350px"
            src={BackgroundDown}
            alt="Firecrowd"
          />
        </div>
      </div>
      <div
        className={cx(
          style["heading"],
          "grid grid-cols-span-12 col-span-6 flex justify-center mt-5"
        )}
      >
        <div>Crowd Testing Platform</div>
      </div>
     
      
    </div>
    <div className={cx(style["activation_success_page"], "col-span-6 pt-28")}>
    <div className={cx(style["logoimg"])}>
          <img
            src={LogotextImage}
            height="200px"
            width="300px"
            alt="Qspider"
          />
        </div>
      <div className={cx(style["righticon"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-16")}>
        <div>
          <img alt="warning" src={righticon} />
        </div>
      </div>
      <div
        className={cx(
          style["heading"],
          "grid grid-cols-span-12 col-span-6 flex justify-center mt-4"
        )}
      >
        <div>Activation Successful</div>
      </div>
      
      <div
        className={cx(
          style["Back_link_text"],
          "grid grid-cols-span-12 col-span-6 flex justify-center mt-4"
        )}
      >
        <span>
          <a href="/"> Proceed to SignIn</a>
        </span>
      </div>
    </div>
  </div>
  )
}

export default ActivationSuccess