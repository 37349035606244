import React, { useState, useEffect } from "react";
import "./deviceVerification.scss";
// import { ReactComponent as FireCrowdIcon } from "../../assets/new_firecrowd_icon.svg";
import { ReactComponent as FireCrowdIcon } from "../../assets/Logo/Fireflink_logo_new.svg";
import {
  capturePlatformDetails,
  postPlatformDetails,
} from "../../api/api-services";
import { useNavigate, useLocation } from "react-router-dom";
import { useAlert } from "../common/alert-service/useAlert";
import { ReactComponent as Desktop } from "../../assets/desktopIcon.svg";
import { ReactComponent as MobileIcon } from "../../assets/mobileIcon.svg";
import { SelectDropdown } from "../project/modals/select-dropdown";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import * as yup from "yup";
import { ReactComponent as CheckCircle } from "../../assets/checkCircle.svg";
import { Tooltip } from "@mui/material";
import { GetLocation } from "../../api/api-services";

let longLoc = "",
  latLoc = "";
const filter = createFilterOptions();
const DeviceToBeVarified = (props) => {
  let initialValues = {
    label: "",
  };
  const validationSchema = yup.object({
    label: yup.string().required("required"),
  });
  const formikDetails = useFormik({
    initialValues,

    validationSchema,
  });
  const [value, setValue] = React.useState(null);
  const [modelValue, setModelValue] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [createCalled, setCreateCalled] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [statusCodes, setStatusCodes] = useState();
  const [addDataCheck, setAddDataCheck] = useState(false);
  const [captureDetails_platform, setCaptureDetails_platform] = useState({});

  const navigate = useNavigate();
  const { AlertContatiner, MyAlert } = useAlert();
  const location = useLocation();

  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userCountry, setUserCountry] = useState("");
  // const [userLocationAvailable, setUserLocationAvailable] = useState(false);
  const [userLocationAvailable, setUserLocationAvailable] = useState(true);
  const EncryptedStringFromUrl = location.pathname.replace(
    "/device-to-be-verified/",
    ""
  );
  const captureDetailsOfPlatform = async () => {
    let UserAgent = navigator.userAgent;
    // let UserAgent="Mozilla/5.0 (Linux; Android 12; 2109119DI) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Mobile Safari/537.36"

    try {
      let response = await capturePlatformDetails(UserAgent);
      localStorage.setItem("platformDetails", JSON.stringify(response?.data));
      if (response.status === 200) {
        setCaptureDetails_platform(response?.data);
        console.log(response.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  let tempLocationArray = [
    {
      city: "Bengaluru",
      principalSubdivision: "Karnataka",
      countryName: "India",
    },
    {
      city: "Hyderabad",
      principalSubdivision: "Telangana",
      countryName: "India",
    },
    {
      city: "Chennai",
      principalSubdivision: "Tamilnadu",
      countryName: "India",
    },
    {
      city: "Kochi",
      principalSubdivision: "Kerala",
      countryName: "India",
    },
    {
      city: "Vizag",
      principalSubdivision: "Andhra Pradesh",
      countryName: "India",
    },
  ];
  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        longLoc = position.coords.longitude;
        latLoc = position.coords.latitude;
        GetLocation(longLoc, latLoc)
          .then((res) => {
            console.log("location response", res);
            setUserLocationAvailable(true);
            setUserCity(res.data.city);
            setUserCountry(res.data.countryName);
            if (res.data.principalSubdivision === "") {
              setUserState("N/A");
            } else {
              setUserState(res.data.principalSubdivision);
            }
          })
          .catch((error) => {
            setUserLocationAvailable(false);
            console.error("location api error", error);
          });
      },
      (err) => {
        setUserLocationAvailable(false);
        // console.log("location err", err);
        props.MyAlert.info(
          "Please, Activate your location in the system settings."
        );
      }
    );
  };
  const setTempLocation = () => {
    let tempIndex = Math.floor(Math.random() * 5);
    setUserCity(tempLocationArray[tempIndex]?.city);
    setUserState(tempLocationArray[tempIndex]?.principalSubdivision);
    setUserCountry(tempLocationArray[tempIndex]?.countryName);
  };
  useEffect(() => {
    localStorage.setItem("EncryptedStrings", EncryptedStringFromUrl);
    captureDetailsOfPlatform();
  }, []);
  useEffect(() => {
    console.log("env :", process.env.NODE_ENV);
    console.log("env1 :", process.env);
    if (
      process.env.REACT_APP_ENV === "DEV" ||
      process.env.REACT_APP_ENV === "TEST"
    ) {
      setTempLocation();
    } else {
      getUserLocation();
    }
  }, []);
  const filteredValue =
    captureDetails_platform?.deviceBrands &&
    captureDetails_platform?.deviceBrands?.filter((valueFilter) => {
      if (value?.brandName) {
        return valueFilter?.brandName === value?.brandName;
      } else {
        return false;
      }
    });
  let AllplatformDetails = captureDetails_platform;
  const captureDetails = async () => {
    let UserAgent = navigator.userAgent;
    const EncryptedString = localStorage.getItem("EncryptedStrings");

    let platformDetails = JSON.parse(localStorage["platformDetails"]);
    console.log(platformDetails);
    try {
      let payload = {
        os: platformDetails.os.name,
        osVersion: platformDetails.os.version,
        platformType: ["smartphone", "tablet", "phablet"].includes(
          platformDetails.device.type
        )
          ? "mobile"
          : "web",
        browser: platformDetails.client.name,
        browserVersion: platformDetails.client.version,
        // isDefault: JSON.parse(localStorage.getItem("selected-user"))?.configuration ===
        // null ? true : false,
        city: userCity,
        state: userState,
        country: userCountry,
        deviceName:
          platformDetails?.device?.model !== null &&
          ["smartphone", "tablet", "phablet"].includes(
            platformDetails.device.type
          )
            ? platformDetails?.device?.model
            : modelValue?.model,
        deviceType: platformDetails.device.type,
        deviceBrand:
          platformDetails?.device?.brand !== null &&
          ["smartphone", "tablet", "phablet"].includes(
            platformDetails.device.type
          )
            ? platformDetails.device.brand
            : value?.brandName,
      };
      let response = await postPlatformDetails(payload, EncryptedString);
      if (response) {
        console.log(response.request.status);
        if (response.request.status == 200) {
          // navigate("/device-verified", { replace: true });
          setSuccessMsg(response?.data?.successMessage);
          setStatusCodes(response.request.status);
          setCreateCalled(false);
        } else {
          if (
            ["Duplicate configuration details"].includes(
              response.response.data.errorMessage
            )
          ) {
            setErrorMsg(`Platform already exists`);
            setStatusCodes(response.request.status);
          } else {
            setErrorMsg(response.response.data.errorMessage);
            setStatusCodes(response.request.status);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  function signOut() {
    localStorage.clear();
    navigate(`/signin`, { replace: true });
    window.location.reload();
  }
  return (
    <>
      <div className="mainDiv">
        <AlertContatiner />
        <div className="headerSectionDevice">
          <p>
            <FireCrowdIcon height="32px" width="auto" />
          </p>
        </div>

        <div
          onClick={() => {
            console.log(AllplatformDetails);
          }}
          className="textContainer  flex flex-col justify-center items-center"
        >
          <p className="platformHeading">Platform Details</p>
          {AllplatformDetails?.device?.type === "desktop" && !loading ? (
            <div className="flex">
              <Tooltip title="Web Platform" placement="top">
                <div className="mx-2 mt-6">
                  <Desktop />
                </div>
              </Tooltip>
              <div className="item_container_platform ">
                <div className="grid grid-cols-12 flex   p-4">
                  <div className="col-span-3">
                    <div className="label">OS</div>
                    <div className="value">
                      {AllplatformDetails?.os?.name?.toUpperCase()}
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="label osVersionLabel">OS VERSION</div>
                    <div className="value osVersionLabel">
                      {" "}
                      {AllplatformDetails?.os?.version?.toUpperCase()}
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="label">BROWSER</div>
                    <div className="value">
                      {AllplatformDetails?.client?.name?.toUpperCase()}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <div className="label">BROWSER VERSION</div>
                    <div className="value">
                      {AllplatformDetails?.client?.version?.toUpperCase()}
                    </div>
                  </div>
                </div>
                {userLocationAvailable ? (
                  <div className="grid grid-cols-12 flex  p-4">
                    <div className="col-span-3">
                      <div className="label">CITY</div>
                      <div className="value">{userCity}</div>
                    </div>
                    <div className="col-span-3">
                      <div className="label osVersionLabel">STATE</div>
                      <div className="value osVersionLabel"> {userState}</div>
                    </div>
                    <div className="col-span-3">
                      <div className="label">COUNTRY</div>
                      <div className="value">{userCountry}</div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ) : ["smartphone", "tablet", "phablet"].includes(
              AllplatformDetails?.device?.type
            ) ? (
            <div className="flex">
              <Tooltip title="Mobile Platform" placement="top">
                <div className="mx-2 mt-6 mobileIcon">
                  <MobileIcon />
                </div>
              </Tooltip>
              <div className="item_container_platform ">
                <div className="grid grid-cols-12 flex  my-2 p-2">
                  <div className="col-span-2">
                    <div className="label">OS</div>
                    <div className="value">
                      {AllplatformDetails?.os?.name?.toUpperCase()}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="label osVersionLabel">OS VERSION</div>
                    <div className="value osVersionLabel">
                      {" "}
                      {AllplatformDetails?.os?.version?.toUpperCase()}
                    </div>
                  </div>
                  <div className="col-span-4">
                    <div className="label">BROWSER</div>
                    <div className="value">
                      {AllplatformDetails?.client?.name?.toUpperCase()}
                    </div>
                  </div>

                  <div className="col-span-4">
                    <div className="label">BROWSER VERSION</div>
                    <div className="value">
                      {AllplatformDetails?.client?.version?.toUpperCase()}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 flex  my-2 p-2">
                  <div className="col-span-4">
                    <div className="label">DEVICE TYPE</div>
                    <div className="value">
                      {AllplatformDetails?.device?.type?.toUpperCase()}
                    </div>
                  </div>
                  <div className="col-span-4">
                    <div className="label">DEVICE BRAND</div>
                    {AllplatformDetails.device?.brand !== null ? (
                      <div className="value">
                        {AllplatformDetails.device?.brand?.toUpperCase()}
                      </div>
                    ) : (
                      <div className="w-64 autoCompleteDiv">
                        {/* <SelectDropdown
                        // data={mobileModels?.map((option) => ({
                        //   ...option,
                        //   label: option,
                        //   value: option,
                        // }))}
                        data={mobileModels}
                        // disabled={enable && enable === NO ? true : false}
                        // placeholder={placeholder ? placeholder : ""}
                        selectedData={getSearchableSelectedOption(mobileModels)}
                        onChange={(e) => {
                          handleDropdownChange(e);
                          console.log(mobileModels);
                        }}
                      /> */}
                        <Autocomplete
                          disabled={statusCodes === 200}
                          value={value}
                          title={value?.brandName}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setValue({
                                brandName: newValue,
                              });
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setValue({
                                brandName: newValue.inputValue,
                              });
                            } else {
                              setValue(newValue);
                            }
                          }}
                          className="autocomplete"
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) =>
                                inputValue?.toLowerCase() ===
                                option.brandName?.toLowerCase()
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                brandName: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={captureDetails_platform.deviceBrands}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue.toUpperCase();
                            }
                            // Regular option
                            return option.brandName.length > 15
                              ? option.brandName
                                  .toUpperCase()
                                  .substring(0, 15) + "..."
                              : option.brandName.toUpperCase();
                          }}
                          renderOption={(props, option) => (
                            <li
                              className="listFonts"
                              style={{ fontSize: "12px" }}
                              {...props}
                            >
                              {option.brandName}
                            </li>
                          )}
                          sx={{ width: 180 }}
                          // freeSolo
                          renderInput={(params) => (
                            <TextField
                              placeholder="Mobile brands"
                              {...params}
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>

                  <div className="col-span-4">
                    <div className="label">DEVICE NAME</div>
                    {AllplatformDetails.device?.model !== null ? (
                      <div className="value">
                        {AllplatformDetails?.device?.model?.toUpperCase()}
                      </div>
                    ) : (
                      <div className="autoCompleteDiv">
                        <Autocomplete
                          disabled={!value?.brandName || statusCodes === 200}
                          value={modelValue}
                          title={modelValue?.model}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setModelValue({
                                model: newValue,
                              });
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setModelValue({
                                model: newValue.inputValue,
                              });
                            } else {
                              setModelValue(newValue);
                            }
                          }}
                          className="autocomplete"
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            console.log(filtered, "asdf");
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option
                            );
                            console.log(isExisting, "asdf");
                            if (inputValue !== "" && !isExisting) {
                              // filtered.push({
                              //   // inputValue,
                              //   // model: `Add "${inputValue}"`
                              // });
                              filtered.push(inputValue);
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          // options={filteredValue[0]?.model}
                          options={
                            filteredValue.length === 0
                              ? []
                              : filteredValue[0]?.model
                          }
                          getOptionLabel={(option) => {
                            console.log(filteredValue[0]?.model);
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option.toUpperCase();
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue.toUpperCase();
                            }
                            // Regular option
                            return option.model.length > 20
                              ? option.model.toUpperCase().substring(0, 20) +
                                  "..."
                              : option.model.toUpperCase();
                          }}
                          renderOption={(props, option) => (
                            <li
                              className="listFonts"
                              onClick={() => {
                                console.log(option);
                              }}
                              style={{ fontSize: "12px" }}
                              {...props}
                            >
                              {option}
                            </li>
                          )}
                          sx={{ width: 180 }}
                          // freeSolo
                          renderInput={(params) => (
                            <TextField placeholder="Device Name" {...params} />
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {userLocationAvailable ? (
                  <div className="grid grid-cols-12 flex  p-4">
                    <div className="col-span-4">
                      <div className="label">CITY</div>
                      <div className="value">{userCity}</div>
                    </div>
                    <div className="col-span-4">
                      <div className="label osVersionLabel">STATE</div>
                      <div className="value osVersionLabel"> {userState}</div>
                    </div>
                    <div className="col-span-4">
                      <div className="label">COUNTRY</div>
                      <div className="value">{userCountry}</div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="">
            <p className="platFormNote flex mt-1">
              <input
                type="checkbox"
                className="-ml-1 "
                value={addDataCheck}
                disabled={statusCodes === 200 || errorMsg}
                onClick={() => {
                  setAddDataCheck(!addDataCheck);
                }}
              ></input>
              &nbsp;&nbsp; I agree to save the above captured platform data.
            </p>
          </div>
          <div className="restTexts">
            <p>
              {statusCodes === 200 ? (
                <p className="success-Text">
                  {successMsg === "Platform added successfully" ? (
                    <p className="flex">
                      <span className="mx-1 checkCircleIcon">
                        <CheckCircle />
                      </span>{" "}
                      <span className="success-Text">
                        Verified Successfully
                      </span>
                    </p>
                  ) : (
                    successMsg
                  )}
                </p>
              ) : (
                <span className="error-Text">{errorMsg}</span>
              )}
            </p>
          </div>
          <div>
            {statusCodes === 200 || errorMsg ? (
              <button onClick={signOut} className="verifyBtn">
                Sign in
              </button>
            ) : userLocationAvailable ? (
              <>
                {["smartphone", "tablet", "phablet"].includes(
                  AllplatformDetails?.device?.type
                ) && AllplatformDetails.device?.brand === null ? (
                  <button
                    title={
                      !addDataCheck
                        ? "Please check the checkBox"
                        : !value?.brandName || !modelValue?.model
                        ? "Please select Device Brand and Device Name"
                        : ""
                    }
                    className={
                      !addDataCheck || !value?.brandName || !modelValue?.model
                        ? "opacity-50 verifyBtn"
                        : "opacity-100 verifyBtn"
                    }
                    disabled={
                      !addDataCheck || !value?.brandName || !modelValue?.model
                    }
                    onClick={() => {
                      if (!createCalled) {
                        setCreateCalled(true);
                        captureDetails();
                      }
                    }}
                  >
                    Verify
                  </button>
                ) : (
                  <button
                    title={!addDataCheck ? "Please check the checkBox" : ""}
                    className={
                      !addDataCheck
                        ? "opacity-50 verifyBtn"
                        : "opacity-100 verifyBtn"
                    }
                    disabled={!addDataCheck}
                    onClick={() => {
                      if (!createCalled) {
                        setCreateCalled(true);
                        captureDetails();
                      }
                    }}
                  >
                    Verify
                  </button>
                )}
              </>
            ) : (
              <Tooltip
                title="Fetch location to proceed and save platform"
                placement="top"
              >
                <button
                  onClick={getUserLocation}
                  className="verifyBtn fetch-location-btn"
                >
                  Fetch location
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceToBeVarified;
