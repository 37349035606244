import React from 'react';
import "./deviceVerification.scss"
// import { ReactComponent as FireCrowdIcon } from "../../assets/new_firecrowd_icon.svg";
// import { ReactComponent as FireCrowdIcon } from "../../assets/Logo/fireflink-crowd-logo.svg";
import { ReactComponent as FireCrowdIcon } from "../../assets/Logo/Fireflink_logo_new.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/verifiedIcon.svg";


const DeviceVerified = () => {
  return (
    <>
    <div className='mainDiv'>
    <div className='headerSectionDeviceMobile'>
<p><FireCrowdIcon height="32px" width="auto"/></p>
    </div>
    
    <div className='textContainerVarified  flex flex-col justify-center items-center'>
    <div className='verifiedIcon'><VerifiedIcon/></div>
    <p>Verified</p>
    <div className='hrLine'></div>
    <p>You can begin adding defects by </p>
    <p> reloading the TestYantra Crowd application or</p>
    <p> logging in the TestaYantra Crowd</p>

    </div>
   
   


    </div>

    </>
  )
}

export default DeviceVerified;


