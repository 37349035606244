import React,{useState} from "react";
import "./videoTutorials.scss";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoPlayer from "./videoPlayer";


function VideoTutorialsHome() {
  const [open, isOpen] = useState(false);
  // const [breadCrumbData, setBreadCrumbData] =useState("initial text");
 

  const onOpenModal = () => {
    isOpen(!open);
    // isOpen(prevState => ({
    //     open: !prevState.open
    // }));
  };

  return (
    <div className="VideoTutorials-container">
      <div className="VideoTutorials-header grid grid-cols-12">
        <div className="col-span-11 pt-2 pl-4">Video Tutorials</div>
      </div>
      <div className="VideoTutorials-body pl-4 pr-4">
        <div className="VideoTutorials-card grid grid-cols-12 mt-4 gap-6">
            <div className="col-span-4">
            <img className="video_layout_back" src={require("../../assets/video/capture-web-ui.png")} />
              <div className="video_icon_tut" onClick={onOpenModal}>
                <div className="icon">
                {/* <BsFillPlayFill /> */}
                <PlayCircleOutlineIcon/>
                </div>
              </div>
             
            </div>
            <div className="col-span-8 video-description">
                <div className="video-header">Know about FireFlink Crowd application?</div>
                <div className="video-line"></div>
                <div className="video-summary mt-4">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam feugiat ac mauris sed imperdiet. Vivamus scelerisque vehicula dolor, vel dignissim lorem cursus vitae.</p>
                </div>
            </div>

        </div>
        <div className="VideoTutorials-card grid grid-cols-12 mt-8 gap-6">
            <div className="col-span-4">
            <img className="video_layout_back" src={require("../../assets/video/capture-web-ui.png")} />
              <div className="video_icon_tut" onClick={onOpenModal}>
                <div className="icon">
                {/* <BsFillPlayFill /> */}
                <PlayCircleOutlineIcon/>
                </div>
              </div>
             
            </div>
            <div className="col-span-8 video-description">
                <div className="video-header">How to raise Defects in FireFlink Crowd application?</div>
                <div className="video-line"></div>
                <div className="video-summary mt-4">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam feugiat ac mauris sed imperdiet. Vivamus scelerisque vehicula dolor, vel dignissim lorem cursus vitae.</p>
                </div>
            </div>

        </div>
        <div className="VideoTutorials-card grid grid-cols-12 mt-8 gap-6">
            <div className="col-span-4">
            <img className="video_layout_back" src={require("../../assets/video/capture-web-ui.png")} />
              <div className="video_icon_tut" onClick={onOpenModal}>
                <div className="icon">
                {/* <BsFillPlayFill /> */}
                <PlayCircleOutlineIcon/>
                </div>
              </div>
             
            </div>
            <div className="col-span-8 video-description">
                <div className="video-header">How to download certificate in FireFlink Crowd application?</div>
                <div className="video-line"></div>
                <div className="video-summary mt-4">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam feugiat ac mauris sed imperdiet. Vivamus scelerisque vehicula dolor, vel dignissim lorem cursus vitae.</p>
                </div>
            </div>

        </div>
        <div className="VideoTutorials-card grid grid-cols-12 mt-8 gap-6">
            <div className="col-span-4">
            <img className="video_layout_back" src={require("../../assets/video/capture-web-ui.png")} />
              <div className="video_icon_tut" onClick={onOpenModal}>
                <div className="icon">
                {/* <BsFillPlayFill /> */}
                <PlayCircleOutlineIcon/>
                </div>
              </div>
             
            </div>
            <div className="col-span-8 video-description">
                <div className="video-header">How to Add platforms in FireFlink Crowd application?</div>
                <div className="video-line"></div>
                <div className="video-summary mt-4">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam feugiat ac mauris sed imperdiet. Vivamus scelerisque vehicula dolor, vel dignissim lorem cursus vitae.</p>
                </div>
            </div>

        </div>

        <div className="VideoTutorials-card grid grid-cols-12 mt-8 gap-6">
            <div className="col-span-4">
            <img className="video_layout_back" src={require("../../assets/video/capture-web-ui.png")} />
              <div className="video_icon_tut" onClick={onOpenModal}>
                <div className="icon">
                {/* <BsFillPlayFill /> */}
                <PlayCircleOutlineIcon/>
                </div>
              </div>
             
            </div>
            <div className="col-span-8 video-description">
                <div className="video-header">How to Execute testcase scripts in FireFlink Crowd application?</div>
                <div className="video-line"></div>
                <div className="video-summary mt-4">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam feugiat ac mauris sed imperdiet. Vivamus scelerisque vehicula dolor, vel dignissim lorem cursus vitae.</p>
                </div>
            </div>

        </div>

        

      </div>
      {
        open &&
        <VideoPlayer
        url={"https://www.youtube.com/watch?v=2ysz_DHNdg4"}
        open={open}
        toggleModal={onOpenModal}
        />
      }
    </div>
  );
}
export default VideoTutorialsHome;
