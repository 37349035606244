import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./modal.scss";
import { ReactComponent as RejectedIcon } from "../../assets/rejectedBorderedIcon.svg";
import { ReactComponent as ApproveddIcon } from "../../assets/approvedBorderedIcon.svg";
import { ReactComponent as ApprovalPendingIcon } from "../../assets/approvalPending.svg";
import moment from "moment";
function BugHistoryPopup(props) {
 

  const [openModal, setOpenModal] = useState(true);
  let [details, setDetails] = useState(props.historyDetails);
  
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "75%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
      

    },
  };

  return (
    <Modal
      isOpen={props.CloseBugHistoryModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        props.CloseBugHistoryModal(false);
        // setOpenModal(false);
      }}
    >
      <div className="defect-details">
        <div className="grid grid-cols-12 flex header rounded-tl-xl">
          <div className="col-span-11 heading pl-2">
            {props.bugHistoryId} - History
          </div>

          <div className="col-span-1 flex justify-end mr-1">
            <button
              onClick={() => {
                props.CloseBugHistoryModal(false);
              }}
              type="button"
              className="modal_clos_btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="pt-1 body">
          <div>
            {details?.slice(0)?.reverse().map((data,i,arr) => {
              return (
                <>
                  <div className="flex">
                    <div>
                      <div
                        className=" rounded-full roundedText
                      "
                      >
                        <p className="text-white text-xs relative top-1">{data.userRole==="TESTER" ? "T" :data.userRole==="PROJECT_MANAGER" ? "PM" :data.userRole==="ACCOUNT_MANAGER" ? "AM":data.userRole==="TEST_LEAD" ? "TL":"T"}</p>
                        <div className="arrow_box text-gray-500 justify-start cursor-pointer">
                          <div className=" text-left relative ">
                          
                            <p className="my-1 flex" title={data.userEmail} style={{width:"166px"}}>
                              Email :{" "}
                              {data.userEmail.length > 15
                                ? data.userEmail.substring(0, 15) + "..."
                                : data.userEmail}
                            </p>
                            <p>Role : {data.userRole}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="historyDetails flex">
                      {data.userEmail} has
                     
                        {data.state === "REJECTED" ? (
                          <div className="mx-1"><RejectedIcon height="22px" width="95px" /></div>
                        ) : data.state === "APPROVED" ? (
                        <div className="mx-1"><ApprovalPendingIcon height="22px" width="95px"/></div> 
                        ): data.state === "CLOSED" ? (
                          <div className="mx-1"><ApproveddIcon height="22px" width="95px" /></div>
          
        ) : data.state === "INREVIEW" ? (
                         
                          <p className="mx-1">{arr.length - 1 === i ? "created" :"resubmitted"}</p>
                        ) : (
                          data?.state?.toLowerCase()
                        )}
                    
                      the defect on {moment(data.date).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                     
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <div className="grid grid-cols-12 col-span-12 user-details_footer  h-12 pt-2">
          <div className="col-span-10"></div>
          <div className="grid grid-cols-12 col-span-2 flex justify-end">
            <div className="grid grid-cols-12 col-span-12 h-8 closedDiv flex justify-end">
              <span
                className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer"
                onClick={() => {
                  props.CloseBugHistoryModal(false);
                }}
              >
                <button>Close</button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default BugHistoryPopup;
