import { useState, useEffect } from "react";
import "./project.scss";

function ProjectRadioItem(props) {
  return (
    <div className="flex mr-2 gap-1">
      <div>
        <input
          type="radio"
          checked={props.projectType === props.data.value}
          name={props.data.value}
          value={props.data.value}
          onChange={(e) => {
            props.setProjectTypeValue(e.target.value);
          }}
          disabled={props?.editDetails?.projectStatus==="INPROGRESS" || props?.editDetails?.projectStatus==="APPROVED"  ||!props.loading||props?.editDetails?.projectStatus === "CLOSED"}
        />
      </div>
      <div className="project-type-values" style={{marginTop:"2px"}}>{props.data.key}</div>
    </div>
  );
}

export default ProjectRadioItem;
