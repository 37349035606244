import React from 'react'
import MobileBrowserContent from './mobile-browser-content'
import MobileOsContent from './mobile-os-content'

const ClientMobileSection = (props) => {
  // console.log(props.allOsInfo);
  return (
    <div>
        <MobileOsContent   mobileInfo={props?.mobileInfo} allOsInfo={props.allOsInfo} deviceInfo={props?.deviceInfo}/>
        {/* <hr className='my-4'/> */}
        {/* <MobileBrowserContent browserInfo={props.browserInfo}/> */}
    </div>
  )
}

export default ClientMobileSection