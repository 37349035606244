import React from "react";
import Chart from "react-apexcharts";
import "./Dashboard.scss"

const DashboardBarChart = (props) => {
  console.log("DashboardBarChart",props)
  
  //  let defectsbarChartCustomTooltip=({ series, seriesIndex, w,dataPointIndex })=>{
  //   let usercount=props?.user;
  // let projectCount=props?.project
  //   console.log("projectCount[dataPointIndex]",projectCount,dataPointIndex)
  //   console.log("usercount[dataPointIndex]",usercount,dataPointIndex)
  //   return `<div style="background: ${ w.globals.colors[dataPointIndex]}" class="bar-chart-custom-tooltip">
  //         <div>Percentage = ${series[seriesIndex][dataPointIndex]}%</div>
  //         <div>Count = ${props?.project[dataPointIndex]}</div>
  //         <div>Test Engineers = ${usercount[dataPointIndex]}</div>
  //         </div>`
  // }
  
  const colors=["#AED6F1","#D7BDE2","#ABEBC6","#F5CBA7","#ECEDC5","#F5B7B1"];

  const options = {
    chart: {
      type: "bar",
      toolbar: { show: true },
      
    },
    colors,
    noData: {
      text: "loading...",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
     
    },
    plotOptions: {
      bar: {
        columnWidth: "15px",
        distributed: true,
      },
    },
    
    tooltip:{
      enabled: true,
      // custom:defectsbarChartCustomTooltip
    
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          return `<div style="background: ${ w.globals.colors[dataPointIndex]}" class="bar-chart-custom-tooltip">
          <div>Percentage = ${series[seriesIndex][dataPointIndex]}%</div>
          <div>Count = ${props?.project[dataPointIndex]}</div>
          <div>Test Engineers = ${props?.user[dataPointIndex]}</div>
          </div>`
        }
      
    },
    legend: {
      show: true,
      offsetX: 0,
      offsetY: 10,
      fontSize: "12px",
      fontFamily: "Poppins-Medium",
      color: "#525252",
    },
    dataLabels: { enabled: false },
    xaxis: {
      categories: [
        "Web",
        "Mobile",
        "Web and Mobile",
        "Smart Watch",
        "Standalone",
        "Television",
      ],
      crosshairs: {
        show: false ,
      },
      axisTicks: {
        show: false,
        borderType: 'solid',
        // color: '#78909C',
        // height: 6,
        // offsetX: 0,
        // offsetY: 0
    },
     
      labels:{
        
        style: {
            fontSize: "12px",
            fontFamily: "Poppins-Medium",
            colors: "#525252",
          },
      },
      
      title: {
        text: "Project Type",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "Poppins-Medium",
          color: "#434DB8",
        },
      },
    },
    yaxis: {
      title: {
        text: "Percentage",
        style: {
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "Poppins-Medium",
          color: "#434DB8",
        },
      },
      labels: {
        style:  {
            fontSize: "12px",
            fontFamily: "Poppins-Medium",
            colors: "#525252",
          },
        formatter: function (value) {
          if (value === 0) return "0 %";

          return value + "%";
        },
      },
    },
  };

  const series = [
    {
      name: "Percentage",
      data: props?.series,
    },
  ];

  return (
    <div className="col-span-11 p-6">
      <Chart
        type="bar"
        //   width="100%"
        height={350}
        options={options}
        series={series}
      />
    </div>
  );

 
};
export default DashboardBarChart;
