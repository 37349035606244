import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./feedback.scss";
import { CHART_COLOR } from "../../../util/app-constants";
import { ReactComponent as LikeIcon } from "../../../assets/reportsicon/like_icon.svg";
import { ReactComponent as DisLikeIcon } from "../../../assets/reportsicon/dislike_icon.svg";
import { ReactComponent as VeryGoodIcon} from "../../../assets/reportsicon/Feedback/VeryGood_icon.svg";
import { ReactComponent as GoodIcon} from "../../../assets/reportsicon/Feedback/Good_icon.svg";
import { ReactComponent as BadIcon} from "../../../assets/reportsicon/Feedback/Bad_icon.svg";
import { ReactComponent as AverageIcon} from "../../../assets/reportsicon/Feedback/Average_icon.svg";
import { ReactComponent as WorstIcon} from "../../../assets/reportsicon/Feedback/worst_icon.svg";


import { ReactComponent as VeryGoodwhiteIcon} from "../../../assets/reportsicon/Feedback/white_icon/veryGood.svg";
import { ReactComponent as GoodwhiteIcon} from "../../../assets/reportsicon/Feedback/white_icon/good.svg";
import { ReactComponent as BadwhiteIcon} from "../../../assets/reportsicon/Feedback/white_icon/bad.svg";
import { ReactComponent as AveragewhiteIcon} from "../../../assets/reportsicon/Feedback/white_icon/average.svg";
import { ReactComponent as WorstwhiteIcon} from "../../../assets/reportsicon/Feedback/white_icon/worst.svg";




function RadioButtonField(props) {
  console.log("RadioButtonField", props);
  let [answerFormat, setAnswerFormat] = useState();
  useEffect(() => {
    getAnswerFormat(props?.data?.options);
  }, []);
  function getAnswerFormat(options) {
    if (options.length === 2 && options.includes("Yes", "No")) {
      setAnswerFormat("likeIcon");
    } else if (options.length === 5 && options.includes("Good", "Very Good","Bad","Average","Worst")) {
      setAnswerFormat("emoji");
    } else {
      setAnswerFormat("blocks");
    }
  }
  function getAnswers() {
    if(props?.hasData){
      if (answerFormat === "blocks") {
       if(props?.tempArray.length===1){
        return (
          <>
            <div className="col-span-3"></div>
            <div className="col-span-3"></div>
            <div className="col-span-3"></div>
            <div className="col-span-3">
            <Tooltip
                  title={`${props?.tempArray[0].noOfUsers} answered "${props?.tempArray[0].value}" out of ${props?.tempArray[0].userCount}`}
                  placement="top"
                >
                  <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                    <>
                      <div
                        className="feedback_card flex"
                       
                      >
                        <div className="datatrimmer w-22">
                        {props?.tempArray[0].value}
                        </div>
                        <div className="mx-0.5">-</div>
                        <div>{props?.tempArray[0].percentage}%</div>
                      
                      </div>
                     
                    </>
                  </div>
                </Tooltip>
            </div>

           
          </>
        );
       }else if(props?.tempArray.length===2){
        return (
          <>
            <div className="col-span-3"></div>
            <div className="col-span-3"></div>
            <div className="col-span-3">
            <Tooltip
                  title={`${props?.tempArray[0].noOfUsers} answered "${props?.tempArray[0].value}" out of ${props?.tempArray[0].userCount}`}
                  placement="top"
                >
                  <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                    <>
                      <div
                        className="feedback_card flex"
                       
                      >
                        <div className="datatrimmer w-22">
                        {props?.tempArray[0].value}
                        </div>
                        <div className="mx-0.5">-</div>
                        <div>{props?.tempArray[0].percentage}%</div>
                      
                      </div>
                     
                    </>
                  </div>
                </Tooltip>
            </div>
            <div className="col-span-3">
            <Tooltip
                  title={`${props?.tempArray[1].noOfUsers} answered "${props?.tempArray[1].value}" out of ${props?.tempArray[1].userCount}`}
                  placement="top"
                >
                  <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                    <>
                      <div
                        className="feedback_card flex"
                       
                      >
                        <div className="datatrimmer w-22">
                        {props?.tempArray[1].value}
                        </div>
                        <div className="mx-0.5">-</div>
                        <div>{props?.tempArray[1].percentage}%</div>
                      
                      </div>
                     
                    </>
                  </div>
                </Tooltip>
            </div>

           
          </>
        );
       }else if(props?.tempArray.length===3){
        return (
          <>
            <div className="col-span-3"></div>
          
            <div className="col-span-3">
            <Tooltip
                  title={`${props?.tempArray[0].noOfUsers} answered "${props?.tempArray[0].value}" out of ${props?.tempArray[0].userCount}`}
                  placement="top"
                >
                  <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                    <>
                      <div
                        className="feedback_card flex"
                       
                      >
                        <div className="datatrimmer w-22">
                        {props?.tempArray[0].value}
                        </div>
                        <div className="mx-0.5">-</div>
                        <div>{props?.tempArray[0].percentage}%</div>
                      
                      </div>
                     
                    </>
                  </div>
                </Tooltip>
            </div>
            <div className="col-span-3">
            <Tooltip
                  title={`${props?.tempArray[1].noOfUsers} answered "${props?.tempArray[1].value}" out of ${props?.tempArray[1].userCount}`}
                  placement="top"
                >
                  <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                    <>
                      <div
                        className="feedback_card flex"
                       
                      >
                        <div className="datatrimmer w-22">
                        {props?.tempArray[1].value}
                        </div>
                        <div className="mx-0.5">-</div>
                        <div>{props?.tempArray[1].percentage}%</div>
                      
                      </div>
                     
                    </>
                  </div>
                </Tooltip>
            </div>
            <div className="col-span-3">
            <Tooltip
                  title={`${props?.tempArray[2].noOfUsers} answered "${props?.tempArray[2].value}" out of ${props?.tempArray[2].userCount}`}
                  placement="top"
                >
                  <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                    <>
                      <div
                        className="feedback_card flex"
                       
                      >
                        <div className="datatrimmer w-22">
                        {props?.tempArray[2].value}
                        </div>
                        <div className="mx-0.5">-</div>
                        <div>{props?.tempArray[2].percentage}%</div>
                      
                      </div>
                     
                    </>
                  </div>
                </Tooltip>
            </div>

           
          </>
        );
       }
       else{
        return (
          <>

            {props?.tempArray?.map((item, index) => {
              console.log("item", item);
             
              return (
                <Tooltip
                  title={`${item.noOfUsers} answered "${item.value}" out of ${props?.userCount}`}
                  placement="top"
                >
                  <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                    <>
                      <div
                        className="feedback_card flex"
                       
                      >
                        <div className="datatrimmer w-22">
                        {item.value}
                        </div>
                        <div className="mx-0.5">-</div>
                        <div>{item.percentage}%</div>
                      
                      </div>
                     
                    </>
                  </div>
                </Tooltip>
              );
            })}
          </>
        );
       }
        
       
      } else if (answerFormat === "likeIcon") {
        return (
          <>
            {props?.tempArray?.map((item, index) => {
              console.log("item", item);
  
                return (
                  <Tooltip
                    title={`${item.noOfUsers} answered "${item.value}" out of ${props?.userCount}`}
                    placement="top"
                  >
                    <div className="w-2/6">
                  
                        <div className="flex feedback_answer_like_icon justify-end mr-4">
                          <div className="like_icon">
                            {
                              item.value === "Yes"?<LikeIcon/>:<DisLikeIcon/>
                            }
                          </div>
                          <div  className="answer">{item.percentage}%</div>
                        </div>
                     
                    </div>
                  </Tooltip>
                );
             
            })}
          </>
        );
      } else {
      
          return (
              <>
                {props?.tempArray?.map((item, index) => {
                  console.log("item", item);
                  return (
                    <Tooltip
                      title={`${item.noOfUsers} answered "${item.value}" out of ${props?.userCount}`}
                      placement="top"
                    >
                      <div className="col-span-1 cursor-default feedback_radioBtn_card">
                        <>
                          <div className="flex justify-center">
                          {/* "Good", "Very Good","Bad","Average","Worst" */}
                            {
                              item.value==="Good"?item.noOfUsers===0?<GoodwhiteIcon/>:<GoodIcon/>:item.value==="Very Good"?item.noOfUsers===0?<VeryGoodwhiteIcon/>:<VeryGoodIcon/>:
                              item.value==="Bad"?item.noOfUsers===0?<BadwhiteIcon/>:<BadIcon/>:item.value==="Average"?item.noOfUsers===0?<AveragewhiteIcon/>:<AverageIcon/>:item.noOfUsers===0?<WorstwhiteIcon/>:<WorstIcon/>
                            }
                            
                          
                           
                          </div>
                          <div className="feedback_label">
                            <div className="datatrimmer">{item.value}</div>
                          </div>
                        </>
                      </div>
                    </Tooltip>
                  );
                })}
              </>
            );
      }
    }else{
      if (answerFormat === "blocks") {
        if(props?.data?.options?.length===1){
          return (
            <>
              <div className="col-span-3"></div>
              <div className="col-span-3"></div>
              <div className="col-span-3"></div>
              <div className="col-span-3">
              <Tooltip
                    title={`No one answered for this question`}
                    placement="top"
                  >
                    <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                      <>
                        <div
                          className="feedback_card flex"
                         
                        >
                          <div className="datatrimmer w-22">
                          {props?.data?.options[0]}
                          </div>
                          <div className="mx-0.5">-</div>
                          <div>{0}%</div>
                        
                        </div>
                       
                      </>
                    </div>
                  </Tooltip>
              </div>
  
             
            </>
          );
         }else if(props?.data?.options?.length===2){
          return (
            <>
              <div className="col-span-3"></div>
              <div className="col-span-3"></div>
              <div className="col-span-3">
              <Tooltip
                    title={`No one answered for this question`}
                    placement="top"
                  >
                    <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                      <>
                        <div
                          className="feedback_card flex"
                         
                        >
                          <div className="datatrimmer w-22">
                          {props?.data?.options[0]}
                          </div>
                          <div className="mx-0.5">-</div>
                          <div>{0}%</div>
                        
                        </div>
                       
                      </>
                    </div>
                  </Tooltip>
              </div>
              <div className="col-span-3">
              <Tooltip
                    title={`No one answered for this question`}
                    placement="top"
                  >
                    <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                      <>
                        <div
                          className="feedback_card flex"
                         
                        >
                          <div className="datatrimmer w-22">
                          {props?.data?.options[1]}
                          </div>
                          <div className="mx-0.5">-</div>
                          <div>{0}%</div>
                        
                        </div>
                       
                      </>
                    </div>
                  </Tooltip>
              </div>
  
             
            </>
          );
         }else if(props?.data?.options?.length===3){
          return (
            <>
              <div className="col-span-3"></div>
            
              <div className="col-span-3">
              <Tooltip
                    title={`No one answered for this question`}
                    placement="top"
                  >
                    <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                      <>
                        <div
                          className="feedback_card flex"
                         
                        >
                          <div className="datatrimmer w-22">
                          {props?.data?.options[0]}
                          </div>
                          <div className="mx-0.5">-</div>
                          <div>{0}%</div>
                        
                        </div>
                       
                      </>
                    </div>
                  </Tooltip>
              </div>
              <div className="col-span-3">
              <Tooltip
                    title={`No one answered for this question`}
                    placement="top"
                  >
                    <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                      <>
                        <div
                          className="feedback_card flex"
                         
                        >
                          <div className="datatrimmer w-22">
                          {props?.data?.options[1]}
                          </div>
                          <div className="mx-0.5">-</div>
                          <div>{0}%</div>
                        
                        </div>
                       
                      </>
                    </div>
                  </Tooltip>
              </div>
              <div className="col-span-3">
              <Tooltip
                    title={`No one answered for this question`}
                    placement="top"
                  >
                    <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                      <>
                        <div
                          className="feedback_card flex"
                         
                        >
                          <div className="datatrimmer w-22">
                          {props?.data?.options[2]}
                          </div>
                          <div className="mx-0.5">-</div>
                          <div>{0}%</div>
                        
                        </div>
                       
                      </>
                    </div>
                  </Tooltip>
              </div>
  
             
            </>
          );
         }else{
          return (
            <>
           
              {props?.data?.options?.map((item, index) => {
                console.log("item", item);
               
                return (
                  <Tooltip
                    title={`No one answered for this question`}
                    placement="top"
                  >
                    <div className="col-span-3 cursor-default feedback_radioBtn_card mr-2 mt-1">
                      <>
                        <div
                          className="feedback_card flex"
                         
                        >
                          <div className="datatrimmer w-22">
                          {item}
                          </div>
                          <div className="mx-0.5">-</div>
                          <div>{0}%</div>
                        
                        </div>
                       
                      </>
                    </div>
                  </Tooltip>
                );
              })}
            </>
          );
         }
       
       
        
      } else if (answerFormat === "likeIcon") {
        return (
          <>
            {props?.data?.options?.map((item, index) => {
              console.log("item", item);
  
                return (
                  <Tooltip
                  title={`No one answered for this question`}
                    placement="top"
                  >
                    <div className="w-2/6">
                  
                        <div className="flex feedback_answer_like_icon justify-end mr-4">
                          <div className="like_icon">
                            {
                              item=== "Yes"?<LikeIcon/>:<DisLikeIcon/>
                            }
                          </div>
                          <div  className="answer">{0}%</div>
                        </div>
                     
                    </div>
                  </Tooltip>
                );
             
            })}
          </>
        );
      } else {
      
          return (
              <>
                {props?.data?.options?.map((item, index) => {
                  console.log("item", item);
                  return (
                    <Tooltip
                    title={`No one answered for this question`}
                      placement="top"
                    >
                      <div className="col-span-1 cursor-default feedback_radioBtn_card">
                        <>
                          <div className="flex justify-center">
                          {/* "Good", "Very Good","Bad","Average","Worst" */}
                            {
                              item==="Good"?<GoodwhiteIcon/>:item==="Very Good"?<VeryGoodwhiteIcon/>:
                              item==="Bad"?<BadwhiteIcon/>:item==="Average"?<AveragewhiteIcon/>:<WorstwhiteIcon/>
                            }
                            
                          
                           
                          </div>
                          <div className="feedback_label">
                            <div className="datatrimmer">{item}</div>
                          </div>
                        </>
                      </div>
                    </Tooltip>
                  );
                })}
              </>
            );
      }

      
    }
    
  }
  return (

  <>{getAnswers()}</>
  );
}
export default RadioButtonField;
