import {
  editProject,
  getProjectDetails,
  getUserHierarchyReq,
} from "../../api/api-services";
import React, { useState, useEffect } from "react";
import "../project/project.scss";
import "../config/config.scss";
import WarningModal from "../common/warning-modal";

function UserTemplate(props) {
  const [userHierarchy, setUserHierarchy] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  let loggedinUser = JSON.parse(localStorage["user-privilege"]);
  let [selectedProj, setSelectedProj] = useState({});
  let [warningPopup, setWarningPopup] = useState(false);
  let [disableselection, setdisableselection] = useState(false);
  let project = JSON.parse(localStorage["selected-project"]);
  let [selectedDefaultTemplate, setSelectedDefaultTemplate] = useState({});
  const getuserHierarchy = async () => {
    let response;
    if (project.usersHierarchyTemplateID) {
      setSelectedTemplate(project.usersHierarchyTemplateID);
    }
    try {
      response = await getUserHierarchyReq();
      if (response.data) {
        setUserHierarchy([...response.data]);
      }
    } catch (err) {
      console.error(err);
    }
  };
  function onCloseWarningPopup(status) {
    setWarningPopup(status);
  }
  const returnRoleImage = (role) => {
    if (role === "PROJECT_OWNER") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34.429"
          height="39.095"
          viewBox="0 0 34.429 39.095"
        >
          <g
            id="Group_8543"
            data-name="Group 8543"
            transform="translate(0.375 0.375)"
          >
            <path
              id="Path_3345"
              data-name="Path 3345"
              d="M242.425,299.7l.346-1.3q.783-2.945,1.566-5.891a.615.615,0,0,1,.617-.521.63.63,0,0,1,.609.536l1.845,6.964c.016.059.036.118.063.205.049-.07.087-.118.118-.17l5.386-8.755c.266-.431.439-.5.938-.378a20.131,20.131,0,0,1,3.137.966,5,5,0,0,1,2.923,2.96c.24.664.472,1.334.653,2.016a29.619,29.619,0,0,1,.811,4.668c.13,1.308.232,2.619.333,3.93a1.759,1.759,0,0,1-1.531,1.831,3.6,3.6,0,0,1-.515.038q-14.768,0-29.535,0a2.069,2.069,0,0,1-1.836-.87,1.356,1.356,0,0,1-.251-.868c.053-.886.106-1.772.191-2.655.091-.939.2-1.878.333-2.812a23.7,23.7,0,0,1,1.285-5.3,5.1,5.1,0,0,1,2.852-2.909,19.455,19.455,0,0,1,3.43-1.05.6.6,0,0,1,.666.342q.966,1.564,1.928,3.129l3.52,5.724C242.339,299.578,242.372,299.622,242.425,299.7Zm2.54-4.613-.042.007q-.829,3.121-1.657,6.243a.64.64,0,0,1-.548.539.627.627,0,0,1-.639-.364q-1.55-2.53-3.106-5.056-1.428-2.324-2.857-4.648c-.047-.076-.07-.177-.211-.132-.824.264-1.662.489-2.474.787a3.927,3.927,0,0,0-2.442,2.472,24.163,24.163,0,0,0-1.139,4.848c-.131.915-.233,1.836-.322,2.756-.08.826-.122,1.655-.184,2.483a.379.379,0,0,0,.222.41,1.73,1.73,0,0,0,.619.143q13.141.01,26.281.006c1.131,0,2.262,0,3.393,0a.613.613,0,0,0,.691-.754c-.049-.611-.091-1.222-.139-1.834a43.975,43.975,0,0,0-.659-4.967,19.262,19.262,0,0,0-.91-3.253,3.851,3.851,0,0,0-2.061-2.179,16.616,16.616,0,0,0-2.642-.885.3.3,0,0,0-.4.167q-2.948,4.818-5.908,9.629a.65.65,0,0,1-.634.379.629.629,0,0,1-.565-.539q-.675-2.569-1.365-5.135Q245.115,295.644,244.965,295.084Z"
              transform="translate(-228.101 -268.454)"
              fill="#3c3838"
              stroke="#3c3838"
              stroke-width="0.75"
            />
            <path
              id="Path_3346"
              data-name="Path 3346"
              d="M274.848,216.653c0,.761,0,1.521,0,2.281a.809.809,0,0,0,.106.372,4.556,4.556,0,0,1,.323,4.323,5.154,5.154,0,0,1-1.248,1.656.873.873,0,0,0-.211.306,8.767,8.767,0,0,1-3.094,4.119,6.25,6.25,0,0,1-4.041,1.167,6.59,6.59,0,0,1-4.538-2.194,9.48,9.48,0,0,1-1.911-3.113.8.8,0,0,0-.205-.288,4.764,4.764,0,0,1-1.544-2.522,4.708,4.708,0,0,1,.61-3.44.864.864,0,0,0,.115-.407c.008-1.527,0-3.054.012-4.581a1.032,1.032,0,0,1,.164-.532,9.208,9.208,0,0,1,3.99-3.442,9.193,9.193,0,0,1,8.322.531,9.461,9.461,0,0,1,3.017,2.974.885.885,0,0,1,.121.451C274.854,215.094,274.848,215.874,274.848,216.653Zm-1.227.283c0-.786,0-1.572,0-2.357a.563.563,0,0,0-.1-.3,7.964,7.964,0,0,0-1.375-1.538,7.807,7.807,0,0,0-11.531,1.429.828.828,0,0,0-.172.526c.007,1.469,0,2.939.008,4.408a1.277,1.277,0,0,1-.212.734,3.347,3.347,0,0,0-.49,2.9,3.578,3.578,0,0,0,1.162,1.677,1.463,1.463,0,0,1,.458.66,7.447,7.447,0,0,0,2.165,3.264,5.253,5.253,0,0,0,6.424.414,7.258,7.258,0,0,0,2.743-3.683,1.432,1.432,0,0,1,.433-.633,3.644,3.644,0,0,0,1.145-1.578,3.353,3.353,0,0,0-.481-3.074,1.022,1.022,0,0,1-.179-.59C273.625,218.444,273.621,217.69,273.621,216.936Z"
              transform="translate(-250.181 -209.607)"
              fill="#3c3838"
              stroke="#3c3838"
              stroke-width="0.75"
            />
          </g>
        </svg>
      );
    } else if (role === "ACCOUNT_MANAGER") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32.837"
          height="35.222"
          viewBox="0 0 32.837 35.222"
        >
          <path
            id="Path_3347"
            data-name="Path 3347"
            d="M248.3,80.888a8.81,8.81,0,0,1-4.654-7.064,8.549,8.549,0,0,1,2.408-6.929A8.865,8.865,0,0,1,259,66.965a8.563,8.563,0,0,1,2.26,7.523,8.761,8.761,0,0,1-4.56,6.388c.5.119,1,.214,1.479.355a13.927,13.927,0,0,1,8.849,7.336,13.6,13.6,0,0,1,1.462,6.189q0,.921,0,1.841a1.829,1.829,0,0,1-1.9,1.9q-11.94,0-23.879,0h-4.277a1.828,1.828,0,0,1-1.932-1.919c0-.907-.015-1.817.039-2.722a13.479,13.479,0,0,1,2.236-6.72,13.946,13.946,0,0,1,8.647-6.065C247.711,81,248,80.951,248.3,80.888Zm11.87-7.863a7.678,7.678,0,1,0-7.662,7.682A7.681,7.681,0,0,0,260.174,73.025ZM252.13,97.279a3.927,3.927,0,0,1-.643-.309c-.837-.609-1.672-1.221-2.484-1.863a1.694,1.694,0,0,1-.634-1.568c.047-.482.132-.961.2-1.442q.336-2.38.671-4.76.3-2.114.6-4.229c.055-.388.107-.777.168-1.216-.616.081-1.2.128-1.776.24a12.694,12.694,0,0,0-7.882,4.873,12.385,12.385,0,0,0-2.586,6.851c-.059.9-.034,1.8-.042,2.7,0,.491.235.721.732.721H252.13Zm.735,0h13.708a.625.625,0,0,0,.7-.716c-.01-.6-.007-1.2-.044-1.8a18.867,18.867,0,0,0-.2-2.21,12.9,12.9,0,0,0-11.352-10.545c-.236-.026-.474-.034-.711-.05a.655.655,0,0,0,0,.074q.414,2.843.828,5.685c.268,1.845.527,3.691.808,5.534a1.946,1.946,0,0,1-.807,2.028c-.66.482-1.31.979-1.961,1.475A2.946,2.946,0,0,1,252.866,97.279ZM252.507,82.2c-.371,0-.742.008-1.112,0-.155-.005-.191.055-.21.194-.1.759-.213,1.517-.321,2.275l-.635,4.436c-.212,1.479-.42,2.958-.637,4.436a.683.683,0,0,0,.272.707q1.11.835,2.22,1.672a.621.621,0,0,0,.827,0c.742-.554,1.481-1.113,2.222-1.669a.671.671,0,0,0,.27-.689q-.379-2.605-.749-5.212-.428-3-.85-5.991c-.017-.118-.064-.16-.185-.158C253.249,82.2,252.878,82.2,252.507,82.2Z"
            transform="translate(-236.079 -63.699)"
            fill="#3c3838"
            stroke="#3c3838"
            stroke-width="0.85"
          />
        </svg>
      );
    } else if (role === "PROJECT_MANAGER") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="65.479"
          height="38.7"
          viewBox="0 0 65.479 38.7"
        >
          <g
            id="Group_8570"
            data-name="Group 8570"
            transform="translate(-397.942 -504.975)"
          >
            <g
              id="Group_8553"
              data-name="Group 8553"
              transform="translate(3407.362 -14525.132)"
            >
              <path
                id="Path_3341"
                data-name="Path 3341"
                d="M225.726,625.361a4.459,4.459,0,0,1-1.531-3.127,10.541,10.541,0,0,1,1.646-7.02,5.779,5.779,0,0,1,2.569-2.277,4.12,4.12,0,0,1,1.556-.361q.829-.02,1.659-.011a4.013,4.013,0,0,1,2.482.932,7.933,7.933,0,0,1,2.146,2.729,11.788,11.788,0,0,1,1.339,5.565,6.238,6.238,0,0,1-.234,1.636,3.583,3.583,0,0,1-1.229,1.936c.153.048.28.09.408.129.311.095.622.194.935.28a1.9,1.9,0,0,1,.977.707,7.753,7.753,0,0,1,1.11,2.1,29.291,29.291,0,0,1,1.321,4.815c.181.91.33,1.825.489,2.739a1.356,1.356,0,0,1-1.408,1.59q-2.954,0-5.907,0H221.906a1.378,1.378,0,0,1-1.4-.907,1.713,1.713,0,0,1-.028-.868,46.16,46.16,0,0,1,1.223-5.694,15.963,15.963,0,0,1,1.063-2.814,4.767,4.767,0,0,1,.808-1.188,1.788,1.788,0,0,1,.811-.491C224.825,625.641,225.261,625.5,225.726,625.361Zm5.032,5.562c-.046-.056-.078-.093-.108-.132q-1.741-2.291-3.479-4.584a.219.219,0,0,0-.287-.095c-.72.228-1.443.45-2.169.659a1.138,1.138,0,0,0-.675.449,7.8,7.8,0,0,0-.6,1.043,18.224,18.224,0,0,0-1.042,3.311c-.369,1.547-.652,3.111-.89,4.684-.041.272.064.4.339.4h18.1c.3,0,.416-.122.367-.4-.184-1.055-.354-2.112-.559-3.163a27.64,27.64,0,0,0-1.092-4.141,6.356,6.356,0,0,0-.819-1.657,1.346,1.346,0,0,0-.726-.519c-.747-.216-1.49-.448-2.233-.677a.176.176,0,0,0-.224.064q-1.3,1.595-2.606,3.183Zm.022-1.711c.043-.046.078-.081.109-.118q1.221-1.488,2.439-2.976c.335-.41.66-.828.994-1.24a.174.174,0,0,1,.117-.04,1.871,1.871,0,0,0,1.671-1.122,4.428,4.428,0,0,0,.421-1.993,10.827,10.827,0,0,0-1.242-5.065,6.814,6.814,0,0,0-1.733-2.26,3.014,3.014,0,0,0-1.932-.764q-.738,0-1.477,0a3.441,3.441,0,0,0-1.859.569,5.4,5.4,0,0,0-1.755,1.891,9.843,9.843,0,0,0-1.342,5.507,4.9,4.9,0,0,0,.446,1.941,2.012,2.012,0,0,0,1.771,1.3.158.158,0,0,1,.1.06q1.089,1.432,2.175,2.867Z"
                transform="translate(-3229.437 14427.903)"
                fill="#3c3838"
                stroke="#3c3838"
                stroke-width="0.85"
              />
              <path
                id="Path_3342"
                data-name="Path 3342"
                d="M259.845,641.7c.34.253.667.514,1.012.747a1.468,1.468,0,0,0,1.109.3,2.612,2.612,0,0,0,.5-.172,11.887,11.887,0,0,1,1.109-.466,3.044,3.044,0,0,1,2,.208.994.994,0,0,1,.585.56,4.544,4.544,0,0,1,.355,2.3,5.087,5.087,0,0,1-.958,2.591,3.3,3.3,0,0,1-1.978,1.345,3.136,3.136,0,0,1-2.79-.723,5.205,5.205,0,0,1-1.735-3.035,4.513,4.513,0,0,1,.146-2.553A4.656,4.656,0,0,1,259.845,641.7Zm.249,1.4c-.017.046-.036.09-.051.136a3.6,3.6,0,0,0-.083,1.848,4.343,4.343,0,0,0,1.4,2.558,2.237,2.237,0,0,0,1.94.558,2.364,2.364,0,0,0,1.5-1.012,4.194,4.194,0,0,0,.783-2.632,3.072,3.072,0,0,0-.274-1.242.272.272,0,0,0-.122-.132,2.185,2.185,0,0,0-1.959.056c-.2.109-.411.208-.62.307a1.942,1.942,0,0,1-1.786-.031C260.582,643.39,260.349,643.244,260.093,643.1Z"
                transform="translate(-3261.456 14403.578)"
                fill="#3c3838"
                stroke="#3c3838"
                stroke-width="0.85"
              />
            </g>
            <g
              id="Group_8555"
              data-name="Group 8555"
              transform="translate(3449.53 -14477.366)"
            >
              <path
                id="Path_3343"
                data-name="Path 3343"
                d="M237.888,469.658c-2.727,0-5.454-.005-8.181,0a1.291,1.291,0,0,1-1.135-.575,6.193,6.193,0,0,1-1.124-2.943,11.641,11.641,0,0,1,.753-5.32c.276-.78.632-1.533.958-2.295a4.457,4.457,0,0,1,2.181-2.186,11.7,11.7,0,0,1,3.551-1.1,18.977,18.977,0,0,1,5.007-.14,13.086,13.086,0,0,1,4.121,1.023,4.844,4.844,0,0,1,2.1,1.577,9.617,9.617,0,0,1,.794,1.481,16.454,16.454,0,0,1,1.313,4.178,8.033,8.033,0,0,1-.318,4.4,9.6,9.6,0,0,1-.746,1.359,1.139,1.139,0,0,1-1.056.539Q242,469.651,237.888,469.658Zm0-1.385c-.774-.844-1.53-1.677-2.3-2.5a.371.371,0,0,1-.094-.4q.891-3.294,1.772-6.59a.25.25,0,0,0-.049-.265c-.6-.681-1.2-1.37-1.806-2.05a.3.3,0,0,0-.233-.086,10.568,10.568,0,0,0-3.261.984,3.26,3.26,0,0,0-1.6,1.476c-.344.754-.7,1.507-.981,2.285a10.6,10.6,0,0,0-.719,4.884,5.005,5.005,0,0,0,.862,2.328.319.319,0,0,0,.3.151c1.559,0,3.118,0,4.677,0q5.769,0,11.537,0c.125,0,.224-.012.287-.136a11.26,11.26,0,0,0,.57-1.1,6.922,6.922,0,0,0,.244-3.542,15.041,15.041,0,0,0-1.229-4.012,9.482,9.482,0,0,0-.658-1.265,3.409,3.409,0,0,0-1.391-1.106,10.74,10.74,0,0,0-3.377-.977.254.254,0,0,0-.187.1c-.515.668-1.022,1.342-1.537,2.009a.348.348,0,0,0-.062.336q.836,3.3,1.667,6.6a.282.282,0,0,1-.083.305c-.185.187-.356.387-.533.581Zm-.005-1.736c.366-.4.721-.8,1.085-1.184a.261.261,0,0,0,.063-.279c-.1-.366-.187-.733-.279-1.1l-.791-3.145a.56.56,0,0,0-.054.1q-.57,2.12-1.134,4.242a.227.227,0,0,0,.031.181C237.152,465.746,237.512,466.136,237.878,466.537Zm.052-8.978,1.029-1.355h-2.214Z"
                transform="translate(-3234.912 14548.204)"
                fill="#3c3838"
                stroke="#3c3838"
                stroke-width="0.85"
              />
              <path
                id="Path_3344"
                data-name="Path 3344"
                d="M256.9,377.835a6.474,6.474,0,1,1-6.488,6.417A6.473,6.473,0,0,1,256.9,377.835Zm-.005,1.173a5.3,5.3,0,1,0,5.294,5.316A5.309,5.309,0,0,0,256.9,379.008Z"
                transform="translate(-3253.92 14612.946)"
                fill="#3c3838"
                stroke="#3c3838"
                stroke-width="0.85"
              />
            </g>
            <g
              id="Group_8554"
              data-name="Group 8554"
              transform="translate(3300 -14343)"
            >
              <path
                id="Path_3348"
                data-name="Path 3348"
                d="M-2209.511,14885.712v-2.369l.88-5.619,2.1-6.9,2.166-1.827s2.166-1.761,2.978-1.558,6.9,7.379,6.9,7.379l6.3-7.379,4.265,1.558,2.234,5.076,2.031,6.634v5.009Z"
                transform="translate(-675)"
                fill="#fff"
                stroke="#3c3838"
                stroke-width="0.35"
              />
              <g
                id="Group_8546"
                data-name="Group 8546"
                transform="translate(123.431 -192.317)"
              >
                <path
                  id="Path_3341-2"
                  data-name="Path 3341"
                  d="M228.5,632.067a6.8,6.8,0,0,1-2.334-4.765,16.065,16.065,0,0,1,2.509-10.7,8.808,8.808,0,0,1,3.916-3.469,6.278,6.278,0,0,1,2.371-.55q1.263-.031,2.528-.017a6.116,6.116,0,0,1,3.782,1.42,12.09,12.09,0,0,1,3.27,4.159,17.966,17.966,0,0,1,2.041,8.481,9.5,9.5,0,0,1-.357,2.494,5.46,5.46,0,0,1-1.874,2.951c.232.074.427.137.622.2.475.144.947.295,1.425.427a2.893,2.893,0,0,1,1.488,1.077,11.817,11.817,0,0,1,1.691,3.2,44.639,44.639,0,0,1,2.014,7.339c.275,1.386.5,2.781.745,4.174a2.066,2.066,0,0,1-2.145,2.423q-4.5.006-9,0H222.676a2.1,2.1,0,0,1-2.133-1.383,2.61,2.61,0,0,1-.042-1.322,70.339,70.339,0,0,1,1.864-8.678,24.33,24.33,0,0,1,1.62-4.289,7.265,7.265,0,0,1,1.231-1.811,2.725,2.725,0,0,1,1.237-.749C227.124,632.493,227.788,632.281,228.5,632.067Zm7.669,8.477c-.069-.085-.119-.142-.164-.2q-2.653-3.491-5.3-6.986a.333.333,0,0,0-.437-.144c-1.1.348-2.2.686-3.305,1a1.735,1.735,0,0,0-1.028.684,11.893,11.893,0,0,0-.921,1.589,27.775,27.775,0,0,0-1.587,5.046c-.563,2.358-.994,4.741-1.356,7.138-.063.415.1.607.517.608q3.148,0,6.3,0h21.291c.461,0,.634-.186.559-.614-.28-1.608-.539-3.219-.852-4.821a42.122,42.122,0,0,0-1.663-6.311,9.688,9.688,0,0,0-1.249-2.525,2.051,2.051,0,0,0-1.106-.791c-1.139-.329-2.27-.682-3.4-1.032a.269.269,0,0,0-.341.1q-1.98,2.43-3.972,4.85Zm.034-2.607c.065-.07.118-.123.166-.18q1.86-2.267,3.717-4.536c.51-.625,1.006-1.263,1.514-1.89a.265.265,0,0,1,.178-.061,2.851,2.851,0,0,0,2.546-1.71,6.748,6.748,0,0,0,.641-3.037,16.5,16.5,0,0,0-1.893-7.719,10.385,10.385,0,0,0-2.642-3.444,4.593,4.593,0,0,0-2.945-1.165q-1.125,0-2.25,0a5.244,5.244,0,0,0-2.833.867,8.236,8.236,0,0,0-2.674,2.882,15,15,0,0,0-2.046,8.392,7.474,7.474,0,0,0,.68,2.958,3.066,3.066,0,0,0,2.7,1.979.241.241,0,0,1,.154.092q1.66,2.182,3.314,4.369C235.082,636.467,235.637,637.2,236.2,637.937Z"
                  transform="translate(-3229.437 14427.903)"
                  fill="#3c3838"
                  stroke="#3c3838"
                  stroke-width="0.35"
                />
                <path
                  id="Path_3342-2"
                  data-name="Path 3342"
                  d="M260.322,641.7c.519.385,1.016.784,1.543,1.138a2.237,2.237,0,0,0,1.69.453,3.979,3.979,0,0,0,.758-.262,18.134,18.134,0,0,1,1.69-.711,4.638,4.638,0,0,1,3.044.317,1.514,1.514,0,0,1,.892.854,6.925,6.925,0,0,1,.541,3.508,7.753,7.753,0,0,1-1.46,3.948,5.022,5.022,0,0,1-3.014,2.05,4.779,4.779,0,0,1-4.251-1.1,7.933,7.933,0,0,1-2.645-4.626,6.878,6.878,0,0,1,.223-3.891A7.1,7.1,0,0,1,260.322,641.7Zm.379,2.139c-.026.07-.054.137-.077.207a5.485,5.485,0,0,0-.126,2.816,6.618,6.618,0,0,0,2.139,3.9,3.408,3.408,0,0,0,2.956.851,3.6,3.6,0,0,0,2.288-1.542,6.391,6.391,0,0,0,1.193-4.011,4.682,4.682,0,0,0-.418-1.893.415.415,0,0,0-.186-.2,3.33,3.33,0,0,0-2.985.085c-.309.166-.627.317-.944.468a2.96,2.96,0,0,1-2.722-.047C261.446,644.278,261.09,644.056,260.7,643.835Z"
                  transform="translate(-3258.063 14406.097)"
                  fill="#3c3838"
                  stroke="#3c3838"
                  stroke-width="0.35"
                />
              </g>
            </g>
          </g>
        </svg>
      );
    } else if (role === "TEST_LEAD") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="59.628"
          height="38.599"
          viewBox="0 0 59.628 38.599"
        >
          <g
            id="Group_8560"
            data-name="Group 8560"
            transform="translate(2899.497 -14920.525)"
          >
            <g
              id="Group_8557"
              data-name="Group 8557"
              transform="translate(108.53 -59.105)"
            >
              <path
                id="Path_3343"
                data-name="Path 3343"
                d="M237.2,468.7c-2.55,0-5.1,0-7.649,0a1.207,1.207,0,0,1-1.061-.538,5.79,5.79,0,0,1-1.051-2.752,10.883,10.883,0,0,1,.7-4.974c.258-.73.591-1.433.9-2.146a4.167,4.167,0,0,1,2.039-2.044,10.941,10.941,0,0,1,3.32-1.03,17.739,17.739,0,0,1,4.681-.131,12.234,12.234,0,0,1,3.853.956,4.529,4.529,0,0,1,1.963,1.474,8.992,8.992,0,0,1,.743,1.384,15.384,15.384,0,0,1,1.228,3.907,7.51,7.51,0,0,1-.3,4.116,8.977,8.977,0,0,1-.7,1.271,1.064,1.064,0,0,1-.987.5Q241.047,468.7,237.2,468.7Zm-2.318-12.334a.283.283,0,0,0-.218-.08,9.881,9.881,0,0,0-3.049.92,3.048,3.048,0,0,0-1.5,1.38c-.321.705-.654,1.409-.917,2.137a9.91,9.91,0,0,0-.673,4.566,4.68,4.68,0,0,0,.806,2.177.3.3,0,0,0,.28.141c1.458,0,2.915,0,4.373,0q5.393,0,10.787,0c.117,0,.209-.011.269-.127a10.529,10.529,0,0,0,.533-1.03,6.472,6.472,0,0,0,.228-3.312,14.063,14.063,0,0,0-1.149-3.751,8.87,8.87,0,0,0-.615-1.182,3.188,3.188,0,0,0-1.3-1.034,10.042,10.042,0,0,0-3.158-.914.237.237,0,0,0-.175.093C238.932,456.976,235.453,457.005,234.887,456.37Zm3.32-.245h-2.07A1.8,1.8,0,0,0,238.207,456.125Z"
                transform="translate(-3234.912 14547.396)"
                fill="#3c3838"
                stroke="#3c3838"
                stroke-width="1"
              />
              <path
                id="Path_3344"
                data-name="Path 3344"
                d="M256.481,377.835a6.053,6.053,0,1,1-6.066,6A6.052,6.052,0,0,1,256.481,377.835Zm-.005,1.1a4.956,4.956,0,1,0,4.95,4.97A4.964,4.964,0,0,0,256.476,378.932Z"
                transform="translate(-3254.182 14612.946)"
                fill="#3c3838"
                stroke="#3c3838"
                stroke-width="1"
              />
            </g>
            <g
              id="Group_8559"
              data-name="Group 8559"
              transform="translate(147.53 -59.105)"
            >
              <path
                id="Path_3343-2"
                data-name="Path 3343"
                d="M237.2,468.7c-2.55,0-5.1,0-7.649,0a1.207,1.207,0,0,1-1.061-.538,5.79,5.79,0,0,1-1.051-2.752,10.883,10.883,0,0,1,.7-4.974c.258-.73.591-1.433.9-2.146a4.167,4.167,0,0,1,2.039-2.044,10.941,10.941,0,0,1,3.32-1.03,17.739,17.739,0,0,1,4.681-.131,12.234,12.234,0,0,1,3.853.956,4.529,4.529,0,0,1,1.963,1.474,8.992,8.992,0,0,1,.743,1.384,15.384,15.384,0,0,1,1.228,3.907,7.51,7.51,0,0,1-.3,4.116,8.977,8.977,0,0,1-.7,1.271,1.064,1.064,0,0,1-.987.5Q241.047,468.7,237.2,468.7Zm-2.318-12.334a.283.283,0,0,0-.218-.08,9.881,9.881,0,0,0-3.049.92,3.048,3.048,0,0,0-1.5,1.38c-.321.705-.654,1.409-.917,2.137a9.91,9.91,0,0,0-.673,4.566,4.68,4.68,0,0,0,.806,2.177.3.3,0,0,0,.28.141c1.458,0,2.915,0,4.373,0q5.393,0,10.787,0c.117,0,.209-.011.269-.127a10.529,10.529,0,0,0,.533-1.03,6.472,6.472,0,0,0,.228-3.312,14.063,14.063,0,0,0-1.149-3.751,8.87,8.87,0,0,0-.615-1.182,3.188,3.188,0,0,0-1.3-1.034,10.042,10.042,0,0,0-3.158-.914.237.237,0,0,0-.175.093C238.932,456.976,235.453,457.005,234.887,456.37Zm3.32-.245h-2.07A1.8,1.8,0,0,0,238.207,456.125Z"
                transform="translate(-3234.912 14547.396)"
                fill="#3c3838"
                stroke="#3c3838"
                stroke-width="1"
              />
              <path
                id="Path_3344-2"
                data-name="Path 3344"
                d="M256.481,377.835a6.053,6.053,0,1,1-6.066,6A6.052,6.052,0,0,1,256.481,377.835Zm-.005,1.1a4.956,4.956,0,1,0,4.95,4.97A4.964,4.964,0,0,0,256.476,378.932Z"
                transform="translate(-3254.182 14612.946)"
                fill="#3c3838"
                stroke="#3c3838"
                stroke-width="1"
              />
            </g>
            <g
              id="Group_8558"
              data-name="Group 8558"
              transform="translate(0 -70)"
            >
              <path
                id="Path_3349"
                data-name="Path 3349"
                d="M-2881.329,15028.484h23.351l2.029-1.337.6-3.325v-3.5l-.6-4.274-2.029-4.056-3.885-1.812-3.669-.734h-6.474l-3.5.734-4.316,1.813-1.511,2.935-1.468,2.892v8.2Z"
                fill="#fff"
                stroke="#3c3838"
                stroke-width="0.25"
              />
              <g
                id="Group_8545"
                data-name="Group 8545"
                transform="translate(123.53 -0.131)"
              >
                <path
                  id="Path_3343-3"
                  data-name="Path 3343"
                  d="M242.257,475.754c-3.861,0-7.722-.007-11.583.006a1.827,1.827,0,0,1-1.607-.815,8.768,8.768,0,0,1-1.592-4.167,16.481,16.481,0,0,1,1.065-7.533c.391-1.1.9-2.17,1.357-3.249a6.311,6.311,0,0,1,3.089-3.095,16.571,16.571,0,0,1,5.028-1.559,26.868,26.868,0,0,1,7.089-.2,18.528,18.528,0,0,1,5.835,1.448,6.859,6.859,0,0,1,2.973,2.233,13.616,13.616,0,0,1,1.125,2.1,23.3,23.3,0,0,1,1.859,5.916,11.373,11.373,0,0,1-.451,6.233,13.592,13.592,0,0,1-1.056,1.925,1.612,1.612,0,0,1-1.495.764Q248.075,475.745,242.257,475.754Zm-.006-1.961c-1.1-1.2-2.167-2.374-3.253-3.538a.525.525,0,0,1-.133-.572q1.262-4.663,2.509-9.33a.354.354,0,0,0-.07-.376c-.856-.964-1.7-1.94-2.558-2.9a.428.428,0,0,0-.33-.121,14.961,14.961,0,0,0-4.618,1.393,4.616,4.616,0,0,0-2.264,2.09c-.487,1.068-.99,2.134-1.389,3.236a15.008,15.008,0,0,0-1.019,6.915,7.087,7.087,0,0,0,1.22,3.3.451.451,0,0,0,.425.214c2.207-.007,4.415,0,6.622,0q8.167,0,16.335,0c.176,0,.316-.017.407-.193a15.943,15.943,0,0,0,.807-1.56,9.8,9.8,0,0,0,.346-5.016,21.3,21.3,0,0,0-1.739-5.68,13.424,13.424,0,0,0-.931-1.791,4.827,4.827,0,0,0-1.969-1.566,15.208,15.208,0,0,0-4.782-1.384.36.36,0,0,0-.265.14c-.73.945-1.447,1.9-2.177,2.845a.492.492,0,0,0-.088.475q1.184,4.671,2.36,9.344a.4.4,0,0,1-.118.432c-.261.265-.5.548-.754.823Zm-.008-2.458c.519-.569,1.021-1.129,1.535-1.677a.369.369,0,0,0,.089-.4c-.137-.518-.265-1.037-.4-1.556-.371-1.472-.741-2.944-1.12-4.452a.8.8,0,0,0-.076.137q-.806,3-1.606,6.006a.322.322,0,0,0,.044.256C241.214,470.215,241.725,470.768,242.243,471.335Zm.074-12.712,1.457-1.919h-3.135Z"
                  transform="translate(-3234.912 14553.37)"
                  fill="#3c3838"
                  stroke="#3c3838"
                  stroke-width="0.25"
                />
                <path
                  id="Path_3344-3"
                  data-name="Path 3344"
                  d="M259.6,377.835a9.166,9.166,0,1,1-9.185,9.086A9.165,9.165,0,0,1,259.6,377.835Zm-.008,1.66a7.506,7.506,0,1,0,7.5,7.527A7.517,7.517,0,0,0,259.593,379.5Z"
                  transform="translate(-3252.246 14612.946)"
                  fill="#3c3838"
                  stroke="#3c3838"
                  stroke-width="0.25"
                />
              </g>
            </g>
          </g>
        </svg>
      );
    } else if (role === "TESTER") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="68.172"
          height="44.953"
          viewBox="0 0 68.172 44.953"
        >
          <g
            id="Group_8561"
            data-name="Group 8561"
            transform="translate(2909.61 -14993.658)"
          >
            <path
              id="Path_3339"
              data-name="Path 3339"
              d="M26.073,30.027V27.518A5.018,5.018,0,0,0,21.055,22.5H11.018A5.018,5.018,0,0,0,6,27.518v2.509"
              transform="translate(-2891.561 15007.509)"
              fill="none"
              stroke="#3c3838"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.15"
            />
            <path
              id="Path_3340"
              data-name="Path 3340"
              d="M22.037,9.518A5.018,5.018,0,1,1,17.018,4.5,5.018,5.018,0,0,1,22.037,9.518Z"
              transform="translate(-2892.542 15010.454)"
              fill="none"
              stroke="#3c3838"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.15"
            />
            <path
              id="Path_3339-2"
              data-name="Path 3339"
              d="M26.073,30.027V27.518A5.018,5.018,0,0,0,21.055,22.5H11.018A5.018,5.018,0,0,0,6,27.518v2.509"
              transform="translate(-2868.586 15007.509)"
              fill="none"
              stroke="#3c3838"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.15"
            />
            <path
              id="Path_3340-2"
              data-name="Path 3340"
              d="M22.037,9.518A5.018,5.018,0,1,1,17.018,4.5,5.018,5.018,0,0,1,22.037,9.518Z"
              transform="translate(-2869.568 15010.454)"
              fill="none"
              stroke="#3c3838"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.15"
            />
            <path
              id="Path_3351"
              data-name="Path 3351"
              d="M22.037,9.518A5.018,5.018,0,1,1,17.018,4.5,5.018,5.018,0,0,1,22.037,9.518Z"
              transform="translate(-2880.997 14999.699)"
              fill="none"
              stroke="#3c3838"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.15"
            />
            <path
              id="Path_3352"
              data-name="Path 3352"
              d="M22.037,9.518A5.018,5.018,0,1,1,17.018,4.5,5.018,5.018,0,0,1,22.037,9.518Z"
              transform="translate(-2892.542 14990.233)"
              fill="none"
              stroke="#3c3838"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.15"
            />
            <path
              id="Path_3339-3"
              data-name="Path 3339"
              d="M26.073,30.027V27.518A5.018,5.018,0,0,0,21.055,22.5H11.018A5.018,5.018,0,0,0,6,27.518v2.509"
              transform="translate(-2914.535 15007.509)"
              fill="none"
              stroke="#3c3838"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.15"
            />
            <path
              id="Path_3340-3"
              data-name="Path 3340"
              d="M22.037,9.518A5.018,5.018,0,1,1,17.018,4.5,5.018,5.018,0,0,1,22.037,9.518Z"
              transform="translate(-2915.517 15010.454)"
              fill="none"
              stroke="#3c3838"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.15"
            />
            <path
              id="Path_3350"
              data-name="Path 3350"
              d="M22.037,9.518A5.018,5.018,0,1,1,17.018,4.5,5.018,5.018,0,0,1,22.037,9.518Z"
              transform="translate(-2903.458 14999.699)"
              fill="none"
              stroke="#3c3838"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.15"
            />
          </g>
        </svg>
      );
    }
  };

  const setDefaultTemplate = async (data) => {
    const formData = new FormData();
    let proj = { ...selectedProj };
    proj.usersHierarchyTemplateID = data.id;
    formData.append("project", JSON.stringify(proj));
    formData.append(
      "createdBy",
      JSON.parse(localStorage.getItem("fire-crowd-user")).userName
    );
    try {
      let response = await editProject(formData, "CUSTOMER");
      if (
        response?.data?.status === 200 ||
        response?.data?.successMessage ===
          "Project details updated successfully"
      ) {
        setSelectedTemplate(data.id);
        props.MyAlert.success("User Hierarchy Template updated successfully");
        localStorage.setItem("selected-project", JSON.stringify(proj));
      } else if (response.response.data?.errorMessage) {
        props.MyAlert.danger(response.response.data?.errorMessage);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const getProject = async () => {
    try {
      const response = await getProjectDetails(project.id);
      if (response.status === 200) {
        let users = response?.data?.project?.users?.userList;
        if(response?.data?.projectStatus==="APPROVED"|| response?.data?.projectStatus==="INPROGRESS"){
          for (let i = 0; i < users.length; i++) {
            if (users[i]?.role === "ACCOUNT_MANAGER") {
              setdisableselection(true);
              break;
            }
          }
        }else if(response?.data?.projectStatus==="CLOSED"|| response?.data?.projectStatus==="REJECTED" ||response?.data?.projectStatus==="COMPLETED"){
          setdisableselection(true);
        }else{
          setdisableselection(false);
        }
        
        setSelectedProj({ ...response?.data });
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getProject();
    getuserHierarchy();
  }, []);
  return (
    <div className="user_template_page grid grid-cols-12 overflow-hidden">
      <div className="grid grid-cols-12 header col-span-12">
        <div className="col-span-12 heading pl-3">User Hierarchy Templates</div>
      </div>
      <div class="container user_template_heading mx-auto  col-span-12">
        <div class="flex items-stretch items grid grid-cols-4 -mx-4">
          {userHierarchy.map((wholetemplate, index) => {
            let userList = Object.keys(wholetemplate.template);
            if (userList.includes("ADMIN")) {
              userList.splice(userList.indexOf("ADMIN"), 1);
            }
            let name = userList.join(">");
            return (
              <div class="flex-1 pl-4 pr-4">
                <div
                  class={`block each_hierarchy pt-4 pb-4 mb-6 overflow-hidden  ${
                    selectedTemplate !== wholetemplate.id ? "" : null
                  }`}
                  style={{height:"95%"}}
                >
                  <div className="grid justify-items-center">
                    <input
                      type="radio"
                      className={`h-6 w-6 ${
                        loggedinUser?.config?.userHierarchyTemplate?.actions
                          ?.edit && !disableselection
                          ? ""
                          : ""
                      }`}
                      disabled={disableselection?true:false}
                      name="defaulttemplate"
                      id={wholetemplate.id}
                      checked={selectedTemplate === wholetemplate.id}
                      value={wholetemplate}
                      onChange={(e) => {
                        if (
                          loggedinUser?.config?.userHierarchyTemplate?.actions
                            ?.edit === true 
                            && !disableselection
                        ) {
                          setSelectedDefaultTemplate(wholetemplate);
                          setWarningPopup(true);
                        }
                      }}
                    />
                    {userList.map((role, index) => {
                      return (
                        <>
                          <div className="each_role grid justify-items-center">
                            {returnRoleImage(role)}
                            <div className="role_name popins-medium">
                            {role==="TESTER"?"TEST ENGINEER":role?.replace("_", "-")}
                            </div>
                          </div>
                          {index !== userList.length - 1 && (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11.25"
                                height="18"
                                viewBox="0 0 11.25 18"
                              >
                                <path
                                  id="Icon_open-arrow-bottom"
                                  data-name="Icon open-arrow-bottom"
                                  d="M4.5,0V11.25H0L5.693,18l5.557-6.75H6.75V0Z"
                                  fill="#3c3838"
                                />
                              </svg>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
                {/* <div className="hierarchy_desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur lobortis mauris in vestibulum convallis.
                </div> */}
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="grid grid-cols-4 user_template_heading">
        <div className="each_hierarchy">hii</div>

      </div> */}

      {/* <div className="pageContainer">
        {userHierarchy.map((alltemplate) => {
          let userList = Object.keys(alltemplate.template);
          let name = userList.join(">");
          return (
            <div className="grid grid-cols-12 flex items-center mt-4 pl-6 ">
              <div className="col-span-12 flex items-center">
                <span className="values pr-4">
                  <input
                    type="radio"
                    className="h-6 w-6 flex items-center"
                    name="defaulttemplate"
                    value={alltemplate}
                    onChange={(e) => {}}
                  />
                </span>
                <span className="userTemplate_data">{name}</span>
              </div>
            </div>
          );
        })}
      </div> */}
      {warningPopup && (
        <WarningModal
          executionMethod={setDefaultTemplate}
          onCloseWarningPopup={onCloseWarningPopup}
          data={selectedDefaultTemplate}
          body={"Are you sure you want to make this template as default?"}
          confirmation={false}
          rejectbtn={"No"}
          acpttbtn={"Yes"}
        />
      )}
    </div>
  );
}

export default UserTemplate;
