import { InputAdornment } from "@mui/material";
import MyInput from "./myInput";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useState } from "react";

function LabeledPasswordInput(props) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <MyInput
      type={isPasswordVisible ? 'text' : 'password'}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <span
              aria-label="toggle password visibility"
              onClick={() => setIsPasswordVisible((isVisible) => !isVisible)}
            >
              {isPasswordVisible ? (
                <VisibilityOutlinedIcon className="mt-0 text-[#727171] cursor-pointer" />
              ) : (
                <VisibilityOffOutlinedIcon className="mt-0 text-[#727171] cursor-pointer" />
              )}
            </span>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export default LabeledPasswordInput;
