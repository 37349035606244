import React, { useEffect, useState } from "react";
import { ReactComponent as Search } from "../../../assets/search.svg";
import "./reports.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ModuleTable from "./module-table";
import {DownloadDefectExcleFile} from "../../../api/api-services";
import NoDefectIcon from "../../../assets/reportsicon/no_reports_icon.png";
import {ReactComponent as BugIcon} from "../../../assets/reportsicon/bug_icon.svg"
import { Tooltip } from "@material-ui/core";
import AllDefectsList from "./AllDefectList";

let webSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
};
let mobileSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
  deviceBrand: null,
  deviceName: null,
  deviceType: null,
};

function ModuleSegment(props){
  console.log("ModuleSegment",props)
  let [LabelData,setlabelData]=useState();
 let [header,setHeader]=useState();
 let [moduleSelectedLabel,setModuleSelectedLabel]=useState([]);
 let [locationSelectedLabel,setlocationSelectedLabel]=useState([]);
 let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])?.project?.fireFlinkProjectId;
 let licenseID = JSON.parse(localStorage["selected-project"])?.licenceID;
 const [filterData, setFilterData] = useState([]);
 let [selectedLabel,setSelectedLabel]=useState([])
let [allDefects,setAllDefects]=useState(false)

let moduleDatadownloding=false;
 let locationDataDownloading=false;


  useEffect(()=>{
    setlabelData(props?.data)
    setHeader(props?.label)
    setFilterData(props?.data)
    setSelectedLabel(props?.selectedlabel)
  },[props])

  function handleSearch(e) {
    console.log("filterdata",filterData)
    let searchValue = e.target.value;
    console.log("searchValue",searchValue)
    if (searchValue) {
      let tempSimilarArray = filterData.filter((proVal, index) => {
        let label= proVal;
        
        
  
        let similarExist = label.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        
  
        return (
          similarExist 
         
        );
      });
      setlabelData(tempSimilarArray);
    } else {
      setlabelData(filterData);
    }
  }

  function saveFileFromBlob(blob, filename,label) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
    // if(label==="module"){
    //   setModuleDataDownloading(false)
    // }else{
    //   setLoactionDataDownloading(false)
    // }
    if(label==="module"){
      moduleDatadownloding=false;
    }else{
      locationDataDownloading=false
    }
  }
  const downloadExcleFile = async (state, payload,label) => {
    // if(label==="module"){
    //   setModuleDataDownloading(true)
    // }else{
    //   setLoactionDataDownloading(true)
    // }
    if(label==="module"){
      moduleDatadownloding=true;
    }else{
      locationDataDownloading=true;
    }
    let filename =
      state === "CLOSED" ? "Approved_Defects.xlsx" : "Rejected_Defects.xlsx";
    try {
      let response = await DownloadDefectExcleFile(
        fireFlinkProjectId,
        licenseID,
        state,
        payload
      );
      console.log("response file", response);
      if (response.status === 200) {
        saveFileFromBlob(response.data, filename,label);
      }
      // else{
      //   if(label==="module"){
      //     setModuleDataDownloading(false)
      //   }else{
      //     setLoactionDataDownloading(false)
      //   }
      // }
      else{
        if(label==="module"){
          moduleDatadownloding=false;
        }else{
          locationDataDownloading=false
        }
      }
    } catch (err) {
      console.log("error");
    }
  };

  function getDownload(label){
    let state=props?.displayapproverdefect;
    switch (label) {
      case "module": {
        let modulepayload=selectedLabel
        // let modulepayload=moduleSelectedLabel.length!==0?moduleSelectedLabel:props?.chartlabels.label;
        let segmentSearchFilters = {
          severity: null,
          priority: null,
          modules:  modulepayload,
          locations: null,
          createdOn: null,
        };
        let filterdata = {
          segmentSearchFilters: segmentSearchFilters,
          webSearchFilters: webSearchFilters,
          mobileSearchFilters: mobileSearchFilters,
        };
        downloadExcleFile(state, filterdata,label);
        break;
      }

      case "location":
        {
          let locationpayload=selectedLabel;
          // let locationpayload=locationSelectedLabel.length!==0?locationSelectedLabel:props?.chartlabels.label;
          let segmentSearchFilters = {
            severity: null,
            priority: null,
            modules: null,
            locations: locationpayload,
            createdOn: null,
          };
          let filterdata = {
            segmentSearchFilters: segmentSearchFilters,
            webSearchFilters: webSearchFilters,
            mobileSearchFilters: mobileSearchFilters,
          };
          downloadExcleFile(state, filterdata,label);
          break;
        }
      
      default:
        break;
    }

  }

  function CloseDefectsModal(state){
    setAllDefects(state)
  }


  function getdownloadFilePayload(val,state,label){
    console.log("getdownloadFilePayload",val,state,label)
    

  }
 
    return(
        <div className="module_segment_container">
            <div className="grid grid-cols-12 table_header">
            <div className="col-span-4 header_text ml-2">
              {props?.label==="module"?"Modules":"Locations"}-{props?.data?.length}
              </div>
            <div className="col-span-8  grid grid-cols-12 header_btn">
                <div className="col-span-7">
                <div className="adminsearch">
            <div>
              <input
                type="text"
                className="searchfield"
                //  value={globalFilter || ""}
                autoFocus={false}
                onChange={(e) => {
                //   setGlobalFilter(e.target.value);
                handleSearch(e)
                }}
                placeholder="Search"
              />
            </div>
            <div className="search_icon">
              <Search />
            </div>
          </div> 
          </div>
                {
                  props?.label==="module"?(
                    <div className={`col-span-4 download_btn ml-1.5 ${props?.data?.length===0 || moduleDatadownloding===true?"opacity-50":"opacity-100"}`}>
                    <button className="flex" onClick={()=>{if(props?.data?.length!==0 && moduleDatadownloding===false){getDownload(header)}}}>
                    <div className="mt-1.5 ml-2">
                    Download
                    </div>
                    <div className="ml-1.5">
                        <FileDownloadOutlinedIcon/>
                    </div>
                   
                   
    
                </button>
                    </div>
                  ):(
                    <div className={`col-span-4 download_btn ml-1.5 ${props?.data?.length===0 || locationDataDownloading===true?"opacity-50":"opacity-100"}`}>
                    <button className="flex" onClick={()=>{if(props?.data?.length!==0 && locationDataDownloading===false){getDownload(header)}}}>
                    <div className="mt-1.5 ml-2">
                    Download
                    </div>
                    <div className="ml-1.5">
                        <FileDownloadOutlinedIcon/>
                    </div>
                   
                   
    
                </button>
                    </div>
                  )
                }
                 <div className="col-span-1 bug_Icon">
                  <Tooltip title="Defects">
                  <button disabled={props?.data?.length===0} className={props?.data?.length===0?"opacity-50":"opacity-100"}>
                  <BugIcon onClick={()=>{setAllDefects(true)}}/>
                  </button>
                  </Tooltip>
                  
               
                </div>
               
            </div>
           

            </div>
            {
              LabelData?.length >0?(
                <div style={{maxHeight:"198px",overflowY:"scroll"}}>
                {
                 LabelData?.map((item,index)=>{
                    return(
                      <div>
                  <ModuleTable label={header} data={item} downloadFilePayload={getdownloadFilePayload}
                  state={props?.displayapproverdefect}
                  />
                </div>
                    )
                  
                   
    
                  })
                }
                </div>
              ):(
                <div style={{maxHeight:"230px",overflowY:"scroll"}}>
                  <div className="segment_noreportIcon">
                    <img  alt="warning" src={NoDefectIcon}/>
                    <div className="text_msg">No Relevant Data Is Found!</div>
                  </div>
                  </div>
              )
            }
            {
              allDefects && (
                <AllDefectsList CloseModal={CloseDefectsModal} payload={props?.selectedlabel} state={props?.displayapproverdefect} label={props?.label}/>
              )
            }
           
           
            
           
        </div>
    )
}
export default  ModuleSegment;
