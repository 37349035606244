import React, { useState, useEffect, useContext, useRef } from "react";
import "./project.scss";
import { useTable, useGlobalFilter } from "react-table";
import Search from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import DefectsDeatilsPopup from "../modal/defects-deatils-popup";
import TesterDeatilsPopup from "../modal/tester-deatils-popup";
import BugHistoryPopup from "../modal/bug-history-popup";
import { ReactComponent as AddIcon } from "../../assets/addIcon.svg";
import { ReactComponent as RejectedIcon } from "../../assets/rejectedBorderedIcon.svg";
import { ReactComponent as ApproveddIcon } from "../../assets/approvedBorderedIcon.svg";
import { ReactComponent as InReviewIcon } from "../../assets/inReviewBorderedIcon.svg";
import { ReactComponent as ApprovedShortBtn } from "../../assets/approvedShortBtn.svg";
import { ReactComponent as RejectedShortBtn } from "../../assets/rejectedShortBtn.svg";
// import { ReactComponent as NoDefectIcon } from "../../assets/noDefectsAvailable.svg";
import { ReactComponent as ApprovalPendingIcon } from "../../assets/approvalPending.svg";
import DefectReasonModal from "../modal/defect-reason-modal";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChangeStatusWarning from "./modals/changeStatusWarning";
import { ReactComponent as EditIcon } from "../../assets/editBlack.svg";
import IconButton from "@mui/material/IconButton";
import NoRelevantDataIcon from "../../assets/reportsicon/no_reports_icon.png";
import { ReactComponent as NoDefectIcon } from "../../assets/reportsicon/noReportsAvailable.svg";
import BugReportIcon from "@mui/icons-material/BugReport";
import FilterListIcon from "@mui/icons-material/FilterList";
import {  ReactComponent as JiraIcon } from "../../assets/integration_icons/jira_icon.svg";

import { Tooltip } from "@material-ui/core";
import CreateDefect from "./modals/defect-create";
import {
  getAllDefectTemplate,
  getDefectLifecycleReq,
  getDefectsByUserReq,
  getDefectsByStateReq,
  getDefectsByEmailIdReq,
  getAllDefectsReq,
  getAllPlatformReq,
  getUserDetailsByEmailID,
  getUserByEmailId,
  getDefectsByEmailIdCount,
  getDefectsByUserCount,
  getAllDefectsCount,
  getDefectsByStateCount,
  getTemplateById,
  getDefectsData,
  getDefectsDataCount,
  getAllModuleByState,
  getAllTesterReq,
} from "../../api/api-services";
import UserDeatilsPopup from "../modal/user-deatils";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer.js";
import ContextMenu from "../modal/contex-modal";
import SearchField from "../common/search-field";
import CreateJiraDefect from "../integrations/integration-modals/create-jira-defects";
import NewFilters from "../reports/new_reports/newFilter";

function ProjectDefects(props) {
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  let [defects, setDefects] = useState([]);
  let [showSearch, setShowSearch] = useState(false);
  let [defectsPopUp, setDefectsPopUp] = useState(false);
  let [defectsdetails, setDefectsdetails] = useState();
  let [userPopup, setUserPopup] = useState(false);
  let [userDeatils, setusererDeatils] = useState();
  const [bugHistoryModal, setBugHistoryModal] = useState(false);
  const [defectReasonModalOpen, setDefectReasonModalOpen] = useState(false);
  const [statusWarnOpen, setStatusWarnOpen] = useState(false);
  const [bugHistoryId, setBugHistoryId] = useState("");

  let myRole = JSON.parse(localStorage.getItem("user-privilege")).role;
  const [showRejectedBtn, setShowRejectedBtn] = useState(false);
  const [createDefect, setCreateDefect] = useState(false);
  const [editDefect, setEditDefect] = useState(false);
  const [selectedDefectData, setSelectedDefectData] = useState();
  const [platform, setplatform] = useState();
  const [webplatform, setwebplatform] = useState();
  const [Mobileplatform, setMobileplatform] = useState();
  const [editWebplatform, setWebEditplatform] = useState();
  const [editMobileplatform, setEditMobileplatform] = useState();
  let loggedinUser = JSON.parse(localStorage["user-privilege"]);
  let projectId = JSON.parse(localStorage["selected-project"]).id;
  let fireFlinkProjectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
  let emailId = JSON.parse(localStorage["selected-user"]).email;
  let leadEmailId = JSON.parse(localStorage["selected-user"]).lead;
 
  const [historyDetails, setHistoryDetails] = useState([]);
  let userId = JSON.parse(localStorage["fire-crowd-user"]).id;
  const [dataForApprove, setDataForApprove] = useState([]);
  let projectstate = JSON.parse(
    localStorage.getItem("selected-project")
  ).projectStatus;
  const PageSize = 30;
  let [haveDefect, setHaveCreateDefect] = useState(false);
  let [myDefectPageNo, setMyDefectPageNo] = useState(0);
  let [mydefectlastpage, setMyDefectlastpage] = useState();

  let [allDefectPageNo, setAllDefectPageNo] = useState(0);
  let [allDefectlastpage, setAllDefectLastpage] = useState();
  let [allDefects, setAllDefects] = useState([]);
  let [defectsCount, setDefectsCount] = useState(0);
  let [closeSearchField, setCloseSearchField] = useState(false);
  let [dataloading, setdataloading] = useState(true);
  let [DefectID, setDefectID] = useState([]);

  let [defectTemplatedetails, setDefectTempalteDetails] = useState();
  let menuRef = useRef();

  // jira
  let [jirabug, setJirabug] = useState(false);
  let [jiraDefectdetails, setJiraDefectDetails] = useState();

  let [attachmentsFields, setAttachmentsFields] = useState([]);
  let [attachmentList, setAttachmentsList] = useState([]);

  const [severity, setSeverity] = useState(null);
  const [priority, setPriority] = useState(null);
  const [module, setModule] = useState(null);
  const [location, setLocation] = useState(null);
  const [webPlatform, setWebPlatform] = useState(null);
  const [mobilePlatform, setMobilePlatform] = useState(null);
  let [showFilter, setShowFilter] = useState(false);
  let [selectedFilters, setSelectedFilters] = useState({});

  let filterBasePayLoad = {
    segmentSearchFilters: {
      severity: null,
      priority: null,
      modules: null,
      locations: null,
      createdOn: null,
    },
    webSearchFilters: {
      os: null,
      osVersion: null,
      browser: null,
      browserVersion: null,
    },
    mobileSearchFilters: {
      os: null,
      osVersion: null,
      browser: null,
      browserVersion: null,
      deviceBrand: null,
      deviceName: null,
      deviceType: null,
    },
  };

  let payLoad = JSON.parse(JSON.stringify(filterBasePayLoad));

  const getAllModule = async () => {
    try {
      let response = await getAllModuleByState(
        licenseId,
        fireFlinkProjectId,
        projectId
      );
      console.log("getAllModuleByState", response);
      if (response.data.responseCode === 200) {
        setModule(response?.data?.responseObject);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const getAllUsersList = async () => {
    try {
      let response = await getAllTesterReq(projectId, emailId);

      if (response?.data) {
        console.log("users data", response.data);
        let tempCityArray = [],
          tempMobile = [],
          tempWeb = [];
        response.data.map((obj) => {
          if (obj.configuration !== null) {
            obj.configuration.configPlatformDetails.map((obj1) => {
              if (
                obj1.deviceType === "smartphone" ||
                obj1.deviceType === "tablet" ||
                obj1.deviceType === "tv" ||
                obj1.deviceType === "SmartWatch"
              ) {
                tempMobile.push(obj1);
              } else {
                tempWeb.push(obj1);
              }
              if (!tempCityArray.includes(obj1.city)) {
                tempCityArray.push(obj1.city);
              }
            });
          }
        });
        setWebPlatform(tempWeb);
        setMobilePlatform(tempMobile);
        setLocation(tempCityArray);
      }
    } catch (error) {
      console.log("error");
    }
  };

  // let bugIDArray=[]
  let [bugIDArray, setBugIDArray] = useState([]);
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowSearch(false);
        // setGlobalFilter();
      }
    };
    document.addEventListener("mousedown", handeler);
    return () => {
      document.removeEventListener("mouseleave", handeler);
    };
  }, []);
  const contextMenuData = [
    {
      option: "Defect Details",
    },
    {
      option: "User Details",
    },
  ];

  const [testerTabs, setTesterTabs] = useState([
    {
      name: "My defects",
      selectedName: "defects",
    },

    {
      name: "All other defects",
    },
  ]);
  const [leadTabs, setLeadTabs] = useState([
    {
      name: "My team defects",
    },
    {
      name: "All other defects",
    },
  ]);
  const [selected, setSelected] = useState(0);
  const renderTabs = () => {
    return (
      <div>
        {myRole === "TESTER" ? (
          <ul className="navBar text-blue-600 flex flex-row renderedTabs">
            {testerTabs.map((tab, index) => {
              return (
                <li key={tab.name}>
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      if (tab.name === "My defects") {
                        setAllDefectPageNo(0);
                        setAllDefectLastpage();
                        setSelected(index);
                        getMyDefectsList(0);
                      } else {
                        setMyDefectPageNo(0);
                        setMyDefectlastpage();
                        setSelected(index);
                        getAllDefectsList(0);

                        document.getElementById("selectTab").innerHTML ===
                          "My defects" &&
                          document
                            .getElementById("selectTab")
                            .classList.remove("selected_tab_style");
                      }
                    }}
                    id="selectTab"
                    className={
                      index === selected
                        ? "selected_tab_style mx-2"
                        : "mx-2 renderedTabs  hover:border-b-blue-600 hover:border-b"
                    }
                  >
                    {tab.name}
                  </a>
                </li>
              );
            })}
          </ul>
        ) : (
          ["TEST_LEAD", "PROJECT_MANAGER"].includes(myRole) && (
            <ul className="navBar text-blue-600 flex flex-row renderedTabs">
              {leadTabs.map((tab, index) => {
                return (
                  <li key={tab.name}>
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();

                        if (tab.name === "My team defects") {
                          setAllDefectPageNo(0);
                          setAllDefectLastpage();
                          getMyTeamDefectsList(0);
                          setSelected(index);
                        } else {
                          setMyDefectPageNo(0);
                          setMyDefectlastpage();
                          getAllDefectsList(0);
                          setSelected(index);

                          document.getElementById("selectTab").innerHTML ===
                            "My team defects" &&
                            document
                              .getElementById("selectTab")
                              .classList.remove("selected_tab_style");
                        }
                      }}
                      id="selectTab"
                      className={
                        index === selected
                          ? "selected_tab_style mx-2"
                          : "mx-2 renderedTabs  hover:border-b-blue-600 hover:border-b"
                      }
                    >
                      {tab.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          )
        )}
      </div>
    );
  };

  function hasDefaultTemp() {
    if (localStorage.hasOwnProperty("Default-defect-template")) {
      setCreateDefect(true);
      setCloseSearchField(true);
    } else {
      setCreateDefect(false);
      props.MyAlert.info("Something went wrong");
    }
  }
  function contextMenuFunction(event, resObj) {
    console.log("event", event.target.textContent);
    console.log("resObj", resObj.row.original);
    if (event.target.textContent === "Defect Details") {
      console.log("contextMenuFunction", resObj.row.original.Details);
      getTemplateDetails(resObj.row.original.Details);
      //  setDefectsdetails(resObj.row.original.Details);
      //  setDefectsPopUp(true);
      //  setCloseSearchField(true)
    }
    if (event.target.textContent === "User Details") {
      getUserDetails(resObj.row.original);
    }
  }

  const callDefectsAPI = (page, val) => {
    if (val === "parent") {
      getDefaultdefecttemplate();
      getAllModule();
      getAllUsersList();
    }

    if (myRole === "TESTER") {
      // if(val==="parent"){
      //   getAllplatform();
      // }

      if (selected === 0) {
        getMyDefectsList(page);
        document.getElementById("selectTab").innerHTML === "My defects" &&
          document
            .getElementById("selectTab")
            .classList.add("selected_tab_style");
      } else {
        getAllDefectsList(page);

        document.getElementById("selectTab").innerHTML === "My defects" &&
          document
            .getElementById("selectTab")
            .classList.remove("selected_tab_style");
      }
    } else if (myRole === "PROJECT_OWNER") {
      getDefectsByStatusList();
    } else if (myRole === "ACCOUNT_MANAGER") {
      getMyTeamDefectsList(page);
    } else {
      getMyTeamDefectsList(page);
      getDefectLifecycle();
      document.getElementById("selectTab").innerHTML === "My team defects" &&
        document
          .getElementById("selectTab")
          .classList.add("selected_tab_style");
    }
  };
  useEffect(() => {
    localStorage.setItem("currentReportTab", JSON.stringify(""));
    if (
      projectstate === "INPROGRESS" ||
      projectstate === "CLOSED" ||
      projectstate === "COMPLETED"
    ) {
      callDefectsAPI(0, "parent");
    } else {
      setdataloading(false);
    }
  }, []);

  const getAllplatform = async (e, details) => {
    console.log("HIII", details);
    try {
      let response = await getAllPlatformReq(projectId, emailId);
      if (response?.data.statusCode === 200) {
        localStorage.setItem(
          "All-platform",
          JSON.stringify(response?.data?.platforms)
        );
        response?.data?.platforms?.map((platform) => {
          if (platform?.isDefault === true) {
            if (platform?.platformType === "web") {
              setwebplatform(platform);
            }
            if (
              platform?.platformType === "mobile" ||
              platform?.platformType === "watch" ||
              platform?.platformType === "tv"
            ) {
              setMobileplatform(platform);
            }

            setplatform(platform);
            if (e === "create") {
              hasDefaultTemp();
            } else {
              editDefectPlatfrom(details);
            }
          }
        });
      } else {
        props.MyAlert.info("Something went wrong");
      }
    } catch (err) {
      console.log("error");
    }
  };

  const getDefectLifecycle = async (e) => {
    let response = await getDefectLifecycleReq(
      fireFlinkProjectId,
      licenseId,
      emailId
    );
  };

  const getDefaultdefecttemplate = async (e) => {
    try {
      let response = await getAllDefectTemplate(fireFlinkProjectId, licenseId);
      if (response?.data?.responseCode === 200) {
        let temparray = [];
        localStorage.setItem(
          "Default-defect-template",
          JSON.stringify(response.data.responseObject.defaultDefectTemplate)
        );
        let tempDetails =
          response.data.responseObject.defaultDefectTemplate.defectDetails;

        tempDetails.map((obj, i) => {
          if (obj.label === "Severity") {
            setSeverity(
              obj.options !== undefined && obj.options.length !== 0
                ? obj.options
                : null
            );
          }
          if (obj.label === "Priority") {
            setPriority(
              obj.options !== undefined && obj.options.length !== 0
                ? obj.options
                : null
            );
          }
        });
        response?.data?.responseObject?.defaultDefectTemplate?.defectDetails.map(
          (ele) => {
            if (ele.type === "attachment") {
              attachmentsFields.push(ele.label);
            }
          }
        );
        console.log("attachments", temparray);
        setAttachmentsFields(attachmentsFields);
      }
    } catch (error) {
      console.log("error");
    }
  };
  const getDefectsByStatusList = async () => {
    

    try {
      let response = await getDefectsData(
        fireFlinkProjectId,
        licenseId,
        payLoad,
        myDefectPageNo,
        PageSize,
        "CLOSED"
      );
      console.log("getDefectsData", response);
      if (response?.status === 200 && response?.data?.responseCode === 200) {
        console.log("defect my status", response);
        ResponseHandler(response?.data,myDefectPageNo);
        if (myDefectPageNo === 0) {
          getDefectStateCount();
        }

        if (response?.data?.lastPage === false) {
          let pageno = myDefectPageNo + 1;

          setMyDefectPageNo(pageno);
          setMyDefectlastpage(response?.data?.lastPage);
        } else {
          setMyDefectlastpage(response?.data?.lastPage);
        }
      }else if(response?.status === 200 && response?.data?.errorCode === 200 && response?.data?.message==="NOT_FOUND" ){
        setMyDefectPageNo(0);
        setDefects([]);
        setDefectsCount(0)
         setdataloading(false)
      }
    } catch (error) {
      console.log("error");
    }
  };
  const getMyDefectsList = async (page) => {
    let tempPageNo = page !== undefined && page === 0 ? page : myDefectPageNo;

    if (tempPageNo === 0) {
      resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 80,
      });
    }

    try {
      let response = await getDefectsByUserReq(
        fireFlinkProjectId,
        licenseId,
        tempPageNo,
        PageSize,
        emailId
      );
      console.log("globalFilter", response);
      if (response?.data?.status === 200) {
        if (page === 0) {
          getMyDefectsCount();
        }
        defectsResponseHandler(response?.data, page);
        setHaveCreateDefect(true);

        if (response?.data?.lastPage === false) {
          let pageno =
            page !== undefined && page === 0 ? page + 1 : myDefectPageNo + 1;

          setMyDefectPageNo(pageno);
          setMyDefectlastpage(response?.data?.lastPage);
        } else {
          setMyDefectlastpage(response?.data?.lastPage);
        }
      } else {
        setDefectsCount(0);
      }

      if (
        response?.data?.status === 0 &&
        response?.data?.errorMessag === "NOT_FOUND"
      ) {
        setHaveCreateDefect(false);
      }
      resetProgress();
    } catch (error) {
      resetProgress();
      console.log("error");
    }
    setdataloading(false);
  };
  const getAllDefectsList = async (page) => {
    let tempPageNo = page !== undefined && page === 0 ? page : allDefectPageNo;

    if (tempPageNo === 0) {
      resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 80,
      });
    }
    try {
      let response = await getAllDefectsReq(
        fireFlinkProjectId,
        licenseId,
        userId,
        tempPageNo,
        PageSize
      );
      console.log("getAllDefectsReq filters", response);
      if (response?.data?.status === 200) {
        if (page === 0) {
          getAllOtherDefectsCount();
        }
        alldefectsResponseHandler(response?.data, page);

        if (response?.data?.lastPage === false) {
          let pageno =
            page !== undefined && page === 0 ? page + 1 : allDefectPageNo + 1;

          setAllDefectPageNo(pageno);
          setAllDefectLastpage(response?.data?.lastPage);
        } else {
          setAllDefectLastpage(response?.data?.lastPage);
        }
      } else {
        setDefectsCount(0);
      }
      resetProgress();
    } catch (error) {
      resetProgress();
      console.log("error");
    }
    setdataloading(false);
  };
  const getMyTeamDefectsList = async (page) => {
    let tempPageNo = page !== undefined && page === 0 ? page : myDefectPageNo;
    console.log("tempPageNo", tempPageNo);
    if (tempPageNo === 0) {
      resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 80,
      });
    }

    try {
      let response = await getDefectsByEmailIdReq(
        fireFlinkProjectId,
        licenseId,
        emailId,
        tempPageNo,
        PageSize
      );
      console.log("response my team defctes", response);

      if (response?.status === 200) {
        defectsResponseHandler(response?.data, page);
        if (page === 0) {
          getMyTeamDefectsCount();
        }
        if (response?.data?.lastPage === false) {
          let pageno =
            page !== undefined && page === 0 ? page + 1 : myDefectPageNo + 1;

          setMyDefectPageNo(pageno);
          setMyDefectlastpage(response?.data?.lastPage);
        } else {
          setMyDefectlastpage(response?.data?.lastPage);
        }
      } else {
        setDefectsCount(0);
      }
      resetProgress();
    } catch (error) {
      console.log("error");
      resetProgress();
    }
    setdataloading(false);
  };
  const getMyTeamDefectsCount = async () => {
    try {
      let response = await getDefectsByEmailIdCount(
        fireFlinkProjectId,
        licenseId,
        emailId
      );
      console.log("response my team defects count", response);
      defectsCountResponseHandler(response);
    } catch (error) {
      console.log("error");
    }
  };
  const getMyDefectsCount = async () => {
    try {
      let response = await getDefectsByUserCount(
        fireFlinkProjectId,
        licenseId,
        emailId
      );
      console.log("response my team defects count", response);
      defectsCountResponseHandler(response);
    } catch (error) {
      console.log("error");
    }
  };
  const getAllOtherDefectsCount = async () => {
    try {
      let response = await getAllDefectsCount(
        fireFlinkProjectId,
        licenseId,
        emailId
      );
      console.log("response all other defects count", response);
      defectsCountResponseHandler(response);
    } catch (error) {
      console.log("error");
    }
  };
  // const getDefectStateCount = async () => {
  //   try {
  //     let response = await getDefectsByStateCount(
  //       fireFlinkProjectId,
  //       licenseId,
  //       "CLOSED"
  //     );
  //     console.log("response defects state count", response);
  //     defectsCountResponseHandler(response);
  //   } catch (error) {
  //     console.log("error");
  //   }
  // };
  const getDefectStateCount = async () => {
    try {
      let responseObject = await getDefectsDataCount(
        fireFlinkProjectId,
        licenseId,
        payLoad,
        "CLOSED"
      );
      console.log("responseObject count", responseObject);
      if (responseObject?.data?.responseCode === 200) {
        setDefectsCount(responseObject?.data?.totalDefectCount);
      } else {
        setDefectsCount(0);
      }
    } catch (err) {
      console.log("error");
    }
  };
  const defectsResponseHandler = (res, page) => {
    let temparray = [];
    let defectArray = [];
    let details;
    let tempBugID = [];
    res?.defects?.map((obj, i) => {
      details = obj.defectDetails;
      console.log("details.Module", details.Module.split(/[/,:]/));
      tempBugID.push(details.ID);
      temparray.push({
        id: details.ID,
        Summary: details.Summary,
        Module: details.Module,
        Severity: details.Severity,
        Priority: details.Priority,
        createdBy: details["Created By"].name,
        State: obj,
        Details: obj,
      });
    });

    if (page !== undefined && page === 0) {
      setDefects(temparray);
      setMyDefectPageNo(page);
      setBugIDArray(tempBugID);
    } else {
      defectArray = defects.concat(temparray);
      localStorage.setItem("my-defects-list", JSON.stringify(res.defects));
      setDefects(defectArray);
      console.log("defectArray", defectArray);
      let bugIdlist = bugIDArray.concat(tempBugID);
      setBugIDArray(bugIdlist);
    }
  };

  const ResponseHandler = (res, page) => {
    let temparray = [];
    let defectArray = [];
    let details;
    let tempBugID = [];
    res?.responseObject?.map((obj, i) => {
      details = obj.defectDetails;
      console.log("details.Module", details.Module.split(/[/,:]/));
      tempBugID.push(details.ID);
      temparray.push({
        id: details.ID,
        Summary: details.Summary,
        Module: details.Module,
        Severity: details.Severity,
        Priority: details.Priority,
        createdBy: details["Created By"].name,
        State: obj,
        Details: obj,
      });
    });

    if (page !== undefined && page === 0) {
      setDefects(temparray);
      setMyDefectPageNo(page);
      setBugIDArray(tempBugID);
    } else {
      defectArray = defects.concat(temparray);
      localStorage.setItem("my-defects-list", JSON.stringify(res.defects));
      setDefects(defectArray);
      console.log("defectArray", defectArray);
      let bugIdlist = bugIDArray.concat(tempBugID);
      setBugIDArray(bugIdlist);
    }
  };

  const UpdateBugdetails = (data) => {
    console.log("UpdateBugdetails", bugIDArray);
    console.log("UpdateBugdetails data", data);
    let temparray = {};
    temparray = {
      id: data?.defectDetails.ID,
      Summary: data?.defectDetails.Summary,
      Module: data?.defectDetails.Module,
      Severity: data?.defectDetails.Severity,
      Priority: data?.defectDetails.Priority,
      createdBy: data?.defectDetails["Created By"].name,
      State: data,
      Details: data,
    };
    let index = bugIDArray.indexOf(data?.defectDetails.ID);
    let tempdata = defects;
    tempdata[index] = temparray;
    console.log("tempdata", tempdata);
    setDefects([...tempdata]);
  };

  const alldefectsResponseHandler = (res, page) => {
    let temparray = [];
    let defectArray = [];
    let details;
    let tempBugID = [];
    res?.defects?.map((obj, i) => {
      details = obj.defectDetails;
      tempBugID.push(details.ID);
      temparray.push({
        id: details.ID,
        Summary: details.Summary,
        Module: details.Module,
        Severity: details.Severity,
        Priority: details.Priority,
        createdBy: details["Created By"].name,
        State: obj,
        Details: obj,
      });
    });
    if (page !== undefined && page === 0) {
      setAllDefects(temparray);
      setAllDefectPageNo(page);
      setBugIDArray(tempBugID);
    } else {
      defectArray = allDefects.concat(temparray);
      setAllDefects(defectArray);
      let bugIdlist = bugIDArray.concat(tempBugID);
      setBugIDArray(bugIdlist);
    }
  };
  const defectsCountResponseHandler = (res) => {
    console.log("Defect count", res);
    if (res?.data?.status === 200) {
      setDefectsCount(res?.data?.totalDefects);
    } else {
      setDefectsCount(0);
    }
  };

  const getUserDetails = async (e) => {
    let emailId = e.Details.defectDetails.history[0].userEmail;
    try {
      let response = await getUserDetailsByEmailID(projectId, emailId);
      if (response?.status === 200) {
        setusererDeatils(response.data);
        setUserPopup(true);
        setCloseSearchField(true);
        console.log("getUserDetails", response);
      }
    } catch (err) {
      console.log("error");
    }
  };

  function CloseTesterModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
    setUserPopup(state);
  }
  function CloseCreateDefectModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
    setCreateDefect(state);
  }
  function CloseEditDefectModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
    setEditDefect(state);
  }
  function editDefectPlatfrom(details) {
    console.log("hellooo", details);
    setWebEditplatform("");
    setEditMobileplatform("");
    console.log("editDefectPlatfrom", details.defectDetails.platformDetails);
    details.defectDetails.platformDetails.map((platform) => {
      if (platform.platformType === "web") {
        console.log("have web", platform);
        setWebEditplatform(platform);
      } else {
        console.log("have mobile", platform);
        setEditMobileplatform(platform);
      }
    });

    setSelectedDefectData(details);
    setEditDefect(true);
    setCloseSearchField(true);
  }

  function CloseDefectFilterModal(state) {
    setShowFilter(state);
  }

  const getTemplateDetails = async (e) => {
    console.log("eeee", e.defectTemplateId);
    console.log("eeee Details", e);
    let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])
      .project.fireFlinkProjectId;
    let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
    let templateID = e.defectTemplateId;
    try {
      let response = await getTemplateById(
        fireFlinkProjectId,
        templateID,
        licenseId
      );
      if (response.data.responseCode === 200) {
        setDefectTempalteDetails(response);
        setDefectsdetails(e);
        setDefectsPopUp(true);
        setCloseSearchField(true);
      } else {
        props.MyAlert.info("Something went wrong");
      }
    } catch (error) {
      console.log("errror");
    }
  };

  const mapKeyAndValue = (data) => {
    let filearray = [],
      filenamelist = [];
    attachmentsFields.map((item) => {
      filearray = filearray.concat(data?.defectDetails[item]);
    });
    filearray.map((filedetails) => {
      filenamelist.push(filedetails?.name);
    });

    setAttachmentsList(filenamelist);
    setJiraDefectDetails(data);
    setJirabug(true);
  };

  const stateColumnHtml = (e) => {
    return (
      <div className=" flex justify-start align-middle defect_action">
        <>
          {/* edit for tester */}
          {myRole === "TESTER" && (
            <div
              className="editIcon"
              style={{ marginLeft: "47%", marginTop: "3px" }}
            >
              <Tooltip
                title={
                  e.Details.defectDetails.State === "REJECTED" &&
                  e.Details.defectDetails.allowTestersToEdit === true &&
                  projectstate !== "CLOSED" &&
                  projectstate !== "COMPLETED"
                    ? "Test engineer allow to edit"
                    : (e.Details.defectDetails.State === "REJECTED" &&
                        e.Details.defectDetails.allowTestersToEdit === false) ||
                      e.Details.defectDetails.State === "APPROVED" ||
                      ((projectstate === "CLOSED" ||
                        projectstate === "COMPLETED") &&
                        e.Details.defectDetails.State === "INREVIEW")
                    ? "Test engineer not allowed to edit"
                    : "Edit"
                }
                placement="top"
              >
                <IconButton
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FAF9F6",
                      boxShadow:
                        " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                    },
                  }}
                >
                  <button
                    disabled={
                      (loggedinUser?.defect?.actions?.edit === true &&
                        e.Details.defectDetails.State === "REJECTED" &&
                        e.Details.defectDetails.allowTestersToEdit === true &&
                        projectstate !== "CLOSED" &&
                        projectstate !== "COMPLETED") ||
                      (e.Details.defectDetails.State === "INREVIEW" &&
                        (projectstate !== "CLOSED" ||
                          projectstate !== "COMPLETED"))
                        ? false
                        : true
                    }
                    onClick={() => {
                      getAllplatform("edit", e.Details);
                    }}
                    className={
                      (loggedinUser?.defect?.actions?.edit === true &&
                        e.Details.defectDetails.State === "REJECTED" &&
                        e.Details.defectDetails.allowTestersToEdit === true &&
                        (projectstate !== "CLOSED" ||
                          projectstate !== "COMPLETED")) ||
                      (e.Details.defectDetails.State === "INREVIEW" &&
                        (projectstate !== "CLOSED" ||
                          projectstate !== "COMPLETED"))
                        ? "block"
                        : "opacity-50"
                    }
                  >
                    <EditIcon height="15px" width="15px" />
                  </button>
                </IconButton>
              </Tooltip>
            </div>
          )}
          {/* edit for others */}
          {myRole !== "TESTER" && myRole !== "PROJECT_OWNER" && (
            <div
              className="editIcon"
              style={{ marginLeft: "47%", marginTop: "3px" }}
            >
              <Tooltip title="Edit" placement="top">
                <IconButton
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FAF9F6",
                      boxShadow:
                        " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                    },
                  }}
                >
                  <button
                    disabled={
                      e?.Details?.defectDetails["Assign to"]?.userEmail ===
                        emailId &&
                      loggedinUser?.defect?.actions?.edit === true &&
                      (projectstate !== "CLOSED" ||
                        projectstate !== "COMPLETED")
                        ? false
                        : true
                    }
                    className={
                      e.Details.defectDetails["Assign to"]?.userEmail ===
                        emailId &&
                      loggedinUser?.defect?.actions?.edit === true &&
                      (projectstate !== "CLOSED" ||
                        projectstate !== "COMPLETED")
                        ? "Block"
                        : "opacity-50"
                    }
                    onClick={() => {
                      editDefectPlatfrom(e.Details);
                    }}
                  >
                    <EditIcon height="15px" width="15px" />
                  </button>
                </IconButton>
              </Tooltip>
            </div>
          )}

          <div className="mx-3 defect_more_btn">
            {/* <Tooltip title="More">
              <IconButton
                sx={{
                  "&:hover": {
                    backgroundColor: "#FAF9F6",
                    boxShadow:
                      " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                  },
                }}
              >
                <button>
                  <MoreVertIcon height="15px" width="15px" />
                </button>
              </IconButton>
            </Tooltip> */}
            <ContextMenu
              contextMenuData={contextMenuData}
              func={(event) => {
                contextMenuFunction(event, e);
              }}
              onMouseOutz
            />
          </div>
        </>
      </div>
    );
  };

  let myDefectsTable = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        width: "10%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              console.log("preventDefault", e.cell.row.original.Details);
              getTemplateDetails(e.cell.row.original.Details);
              // setDefectsdetails(e.cell.row.original.Details);
              //  setDefectsPopUp(true);
            }}
            className="datatrimmer w-32 defect-table-cell-link"
            style={{ marginLeft: "10%" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "MODULE",
        accessor: "Module",
        width: "15%",
        Cell: ({ value }) => {
          return (
            <div
              style={{ marginLeft: "-25%" }}
              className="datatrimmer w-20"
              title={value}
            >
              {value
                ? value.split(/[/,:]/)[value.split(/[/,:]/).length - 2]
                : "--"}
            </div>
          );
        },
      },
      {
        Header: "SUMMARY",
        accessor: "Summary",
        width: "33%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "-29%" }}
              className="datatrimmer w-60"
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "SEVERITY",
        accessor: "Severity",
        width: "14%",
        Cell: ({ value }) => {
          return (
            <div
              style={{ marginLeft: "84%" }}
              className="datatrimmer w-24"
              title={value}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "PRIORITY",
        accessor: "Priority",
        width: "13%",
        Cell: ({ value }) => {
          return (
            <div
              style={{ marginLeft: "72%" }}
              className="datatrimmer w-24"
              title={value}
            >
              {value ? value : "--"}
            </div>
          );
        },
      },
      {
        Header: "CREATED BY",
        accessor: "createdBy",
        width: "15%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              // setusererDeatils(e.cell.row.original);
              // setUserPopup(true);
              getUserDetails(e.cell.row.original);
            }}
            className="datatrimmer w-24 defect-table-cell-link"
            style={{ marginLeft: "55%" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: [
          "TEST_LEAD",
          "ACCOUNT_MANAGER",
          "TESTER",
          "PROJECT_MANAGER",
        ]?.includes(myRole)
          ? "STATUS"
          : [],
        accessor: "State",
        columns:
          ![
            "TEST_LEAD",
            "ACCOUNT_MANAGER",
            "TESTER",
            "PROJECT_MANAGER",
          ]?.includes(myRole) && [],

        width: "16%",

        Cell: (e) => {
          return (
            <div className="cursor-pointer" style={{ marginLeft: "45%" }}>
              {["TEST_LEAD", "ACCOUNT_MANAGER", "PROJECT_MANAGER"]?.includes(
                myRole
              ) && e.value.defectDetails["Assign to"]?.userEmail === emailId ? (
                <div
                  className={`flex ${
                    projectstate === "COMPLETED" ? "opacity-40" : "opacity-100"
                  }`}
                >
                  <Tooltip title="Reject Defect" placement="top">
                    <div
                      onClick={() => {
                        if (projectstate !== "COMPLETED") {
                          setDefectReasonModalOpen(true);
                          setCloseSearchField(true);
                          setBugHistoryId(e.cell.row.original.id);
                          setDataForApprove(e.value);
                        }
                      }}
                    >
                      <RejectedShortBtn height="25px" width="25px" />{" "}
                    </div>
                  </Tooltip>
                  <Tooltip title="Approve Defect" placement="top">
                    <div
                      className="mx-1"
                      onClick={() => {
                        if (projectstate !== "COMPLETED") {
                          setStatusWarnOpen(true);
                          setBugHistoryId(e.cell.row.original.id);
                          setDataForApprove(e.value);
                        }
                      }}
                    >
                      {" "}
                      <ApprovedShortBtn height="25px" width="25px" />
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setBugHistoryModal(true);
                    setCloseSearchField(true);
                    setBugHistoryId(e.cell.row.original.id);
                    setHistoryDetails(
                      e.cell.row.original.Details.defectDetails.history
                    );
                  }}
                >
                  {e.value.state === "REJECTED" ? (
                    <RejectedIcon height="22px" width="95px" />
                  ) : e.value.state === "CLOSED" ? (
                    <ApproveddIcon height="22px" width="95px" />
                  ) : e.value.state === "INREVIEW" ? (
                    myRole === "ACCOUNT_MANAGER" ||
                    myRole === "PROJECT_MANAGER" ? (
                      <RejectedIcon height="22px" width="95px" />
                    ) : (
                      <InReviewIcon height="22px" width="95px" />
                    )
                  ) : e.value.defectDetails.history === undefined ? (
                    statusIcons(e.value.state)
                  ) : leadEmailId ===
                    e.value.defectDetails["Assign to"].userEmail ? (
                    statusIcons(e.value.state)
                  ) : (
                    historyIcons(
                      getStateFromHistory(e.value.defectDetails.history)
                    )
                  )}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "ACTIONS",
        accessor: stateColumnHtml,
        width: "10%",
        Cell: (e) => {
          return (
            myRole !== "PROJECT_OWNER" && (
              // <div style={{ marginLeft: "30%" }}>{e.value}</div>
              <div className=" flex justify-start align-middle defect_action">
                <>
                  {/* edit for tester */}
                  {myRole === "TESTER" && (
                    <div
                      className="editIcon"
                      style={{ marginLeft: "47%", marginTop: "3px" }}
                    >
                      <Tooltip
                        title={
                          e.row.original.Details.defectDetails.State ===
                            "REJECTED" &&
                          e.row.original.Details.defectDetails
                            .allowTestersToEdit === true &&
                          projectstate !== "CLOSED" &&
                          projectstate !== "COMPLETED"
                            ? "Test engineer allow to edit"
                            : (e.row.original.Details.defectDetails.State ===
                                "REJECTED" &&
                                e.row.original.Details.defectDetails
                                  .allowTestersToEdit === false) ||
                              e.row.original.Details.defectDetails.State ===
                                "APPROVED" ||
                              ((projectstate === "CLOSED" ||
                                projectstate === "COMPLETED") &&
                                e.row.original.Details.defectDetails.State ===
                                  "INREVIEW")
                            ? "Test engineer not allowed to edit"
                            : "Edit"
                        }
                      >
                        <IconButton
                          sx={{
                            "&:hover": {
                              backgroundColor: "#FAF9F6",
                              boxShadow:
                                " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                            },
                          }}
                        >
                          <button
                            disabled={
                              (loggedinUser?.defect?.actions?.edit === true &&
                                e.row.original.Details.defectDetails.State ===
                                  "REJECTED" &&
                                e.row.original.Details.defectDetails
                                  .allowTestersToEdit === true &&
                                projectstate !== "CLOSED" &&
                                projectstate !== "COMPLETED") ||
                              (e.row.original.Details.defectDetails.State ===
                                "INREVIEW" &&
                                projectstate !== "CLOSED" &&
                                projectstate !== "COMPLETED")
                                ? false
                                : true
                            }
                            onClick={() => {
                              // console.log("e.row.original",e.cell.row.original.Details)
                              //  editDefectPlatfrom(e.cell.row.original.Details);
                              getAllplatform(
                                "edit",
                                e.cell.row.original.Details
                              );
                            }}
                            className={
                              (loggedinUser?.defect?.actions?.edit === true &&
                                e.row.original.Details.defectDetails.State ===
                                  "REJECTED" &&
                                e.row.original.Details.defectDetails
                                  .allowTestersToEdit === true &&
                                projectstate !== "CLOSED" &&
                                projectstate !== "COMPLETED") ||
                              (e.row.original.Details.defectDetails.State ===
                                "INREVIEW" &&
                                projectstate !== "CLOSED" &&
                                projectstate !== "COMPLETED")
                                ? "block"
                                : "opacity-50"
                            }
                          >
                            <EditIcon height="15px" width="15px" />
                          </button>
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                  {/* edit for others */}
                  {myRole !== "TESTER" && myRole !== "PROJECT_OWNER" && (
                    <div
                      className="editIcon"
                      style={{ marginLeft: "47%", marginTop: "3px" }}
                    >
                      <Tooltip title="Edit">
                        <IconButton
                          sx={{
                            "&:hover": {
                              backgroundColor: "#FAF9F6",
                              boxShadow:
                                " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                            },
                          }}
                        >
                          <button
                            disabled={
                              e.row.original.Details.defectDetails["Assign to"]
                                ?.userEmail === emailId &&
                              loggedinUser?.defect?.actions?.edit === true &&
                              projectstate !== "COMPLETED"
                                ? false
                                : true
                            }
                            className={
                              e.row.original.Details.defectDetails["Assign to"]
                                ?.userEmail === emailId &&
                              loggedinUser?.defect?.actions?.edit === true &&
                              projectstate !== "COMPLETED"
                                ? "Block"
                                : "opacity-50"
                            }
                            onClick={() => {
                              editDefectPlatfrom(e.row.original.Details);
                              console.log(e.row.original);
                            }}
                          >
                            <EditIcon height="15px" width="15px" />
                          </button>
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}

                  <div className="mx-3 defect_more_btn">
                   
                    <ContextMenu
                      contextMenuData={contextMenuData}
                      func={(event) => {
                        contextMenuFunction(event, e);
                      }}
                      onMouseOutz
                    />
                  </div>
                </>
              </div>
            )
          );
        },
      },
    ],
    []
  );

  let allDefectsTable = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        width: "10%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              getTemplateDetails(e.cell.row.original.Details);
              console.log(
                "e.cell.row.original.Details",
                e.cell.row.original.Details
              );
              // setDefectsdetails(e.cell.row.original.Details);
              // setDefectsPopUp(true);
            }}
            className="datatrimmer w-32 defect-table-cell-link"
            style={{ marginLeft: "9%" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "MODULE",
        accessor: "Module",
        width: "15%",
        Cell: ({ value }) => {
          return (
            // <div style={{ marginLeft: "-33%" }} className="datatrimmer w-32" title={value}>
            //   {value ? value : "--"}
            // </div>
            <div
              style={{ marginLeft: "-33%" }}
              className="datatrimmer w-20"
              title={value}
            >
              {value
                ? value.split(/[/,:]/)[value.split(/[/,:]/).length - 2]
                : "--"}
            </div>
          );
        },
      },
      {
        Header: "SUMMARY",
        accessor: "Summary",
        width: "40%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "-44%" }}
              className="datatrimmer w-60"
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "SEVERITY",
        accessor: "Severity",
        width: "12%",
        Cell: ({ value }) => {
          return (
            <div style={{ marginLeft: "88%" }} className="datatrimmer w-24">
              {value}
            </div>
          );
        },
      },
      {
        Header: "PRIORITY",
        accessor: "Priority",
        width: "13%",
        Cell: ({ value }) => {
          return (
            <div
              style={{ marginLeft: "56%" }}
              className="datatrimmer w-24"
              title={value}
            >
              {value ? value : "--"}
            </div>
          );
        },
      },
      {
        Header: "CREATED BY",
        accessor: "createdBy",
        width: "15%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();

              getUserDetails(e.cell.row.original);
            }}
            className="datatrimmer w-24 defect-table-cell-link"
            style={{ marginLeft: "33%" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "STATUS",
        accessor: "State",

        width: "16%",

        Cell: (e) => {
          return (
            <div className="cursor-pointer" style={{ marginLeft: "19%" }}>
              <div
                onClick={() => {
                  setBugHistoryModal(true);
                  setCloseSearchField(true);
                  setBugHistoryId(e.cell.row.original.id);
                  setHistoryDetails(
                    e.cell.row.original.Details.defectDetails.history
                  );
                }}
              >
                {statusIcons(e.value.state)}
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  let projectOwnerTable = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        width: "10%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              getTemplateDetails(e.cell.row.original.Details);
              // setDefectsdetails(e.cell.row.original.Details);
              // setDefectsPopUp(true);
            }}
            className="datatrimmer w-32 defect-table-cell-link"
            style={{ marginLeft: "8%" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "MODULE",
        accessor: "Module",
        width: "15%",
        Cell: ({ value }) => {
          return (
            <div
              style={{ marginLeft: "-23%" }}
              className="datatrimmer w-20"
              title={value}
            >
              {value
                ? value.split(/[/,:]/)[value.split(/[/,:]/).length - 2]
                : "--"}
            </div>
          );
        },
      },
      {
        Header: "SUMMARY",
        accessor: "Summary",
        width: "30%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "-22%" }}
              className="datatrimmer w-72"
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "SEVERITY",
        accessor: "Severity",
        width: "12%",
        Cell: ({ value }) => {
          return (
            <div
              style={{ marginLeft: "83%" }}
              className="datatrimmer w-24"
              title={value}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "PRIORITY",
        accessor: "Priority",
        width: "12%",
        Cell: ({ value }) => {
          return (
            <div
              style={{ marginLeft: "64%" }}
              className="datatrimmer w-24"
              title={value}
            >
              {value ? value : "--"}
            </div>
          );
        },
      },
      {
        Header: "CREATED BY",
        accessor: "createdBy",
        width: "15%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();

              getUserDetails(e.cell.row.original);
            }}
            className="datatrimmer w-24 defect-table-cell-link"
            style={{ marginLeft: "46%" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "ACTION",
        accessor: "action",
        width: "10%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              if (!e.cell.row.original.Details?.defectDetails?.jiraId) {
                event.preventDefault();
                console.log("e.cell.row.original", e.cell.row.original.Details);
                mapKeyAndValue(e.cell.row.original.Details);
              }
            }}
            className={`datatrimmer w-24 defect_action  cursor-pointer ${
              e.cell.row.original.Details?.defectDetails?.jiraId
                ? "disbale_jira_icon"
                : "jira_icon"
            }`}
            style={{ marginLeft: "51%" }}
          >
            <Tooltip
              title={
                e.cell.row.original.Details?.defectDetails?.jiraId
                  ? "Defect raised In jira"
                  : "Add defect to jira"
              }
            >
              
              <JiraIcon />
            </Tooltip>
          </div>
        ),
      },
    ],
    []
  );
  const getStateFromHistory = (history) => {
    let tempState = "";
    let tempArray = history.slice(0).reverse();

    for (var i = 0; i < tempArray.length; i++) {
      if (myRole === "ACCOUNT_MANAGER" && emailId === tempArray[i].userEmail) {
        tempState = tempArray[i].state;
        break;
      } else if (leadEmailId === tempArray[i].userEmail) {
        tempState = tempArray[i].state;
        break;
      }
    }

    return tempState;
  };
  const getAllDefectsWithFilter = (payload, filtersAdded, filters, callApi) => {
    console.log("getAllDefectsWithFilter",payload, filtersAdded, filters, callApi)
    
     payLoad = payload;
     myDefectPageNo=0
    // setMyDefectPageNo(0);
    setSelectedFilters(filters);
    if (callApi) {
      getDefectsByStatusList();
      localStorage.setItem("currentReportTab", JSON.stringify("DefectReports"));
    }
  };
  const statusIcons = (state) => {
    return (
      <>
        {state === "REJECTED" ? (
          <RejectedIcon height="22px" width="95px" />
        ) : state === "APPROVED" ? (
          <ApprovalPendingIcon height="22px" width="95px" />
        ) : state === "CLOSED" ? (
          <ApproveddIcon height="22px" width="95px" />
        ) : state === "INREVIEW" ? (
          <InReviewIcon height="25px" width="97px" />
        ) : (
          state
        )}
      </>
    );
  };
  const historyIcons = (state) => {
    return (
      <>
        {state === "REJECTED" ? (
          <RejectedIcon height="22px" width="95px" />
        ) : state === "APPROVED" ? (
          <ApproveddIcon height="22px" width="95px" />
        ) : state === "INREVIEW" ? (
          <InReviewIcon height="25px" width="97px" />
        ) : (
          state
        )}
      </>
    );
  };
  function CloseDefectModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
    setDefectsPopUp(state);
  }
  function CloseBugHistoryModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }

    setBugHistoryModal(state);
  }
  function CloseDefectReasonModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
    setDefectReasonModalOpen(state);
  }

  function CloseWarningStatus(state) {
    setStatusWarnOpen(state);
  }
  function closeJiraModal() {
    setJirabug(false);
  }
  const handlescroll = (event) => {
    if (globalFilter === undefined) {
      if (
        Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
        event.target.scrollHeight
      ) {
        if (myRole === "TESTER") {
          if (selected === 0 && mydefectlastpage === false) {
            getMyDefectsList();
          } else {
            if (selected === 1 && allDefectlastpage === false) {
              getAllDefectsList();
            }
          }
        } else if (myRole === "PROJECT_OWNER") {
          if (mydefectlastpage === false) {
            getDefectsByStatusList();
          }
        } else if (myRole === "ACCOUNT_MANAGER") {
          if (mydefectlastpage === false) {
            getMyTeamDefectsList();
          }
        } else {
          if (selected === 0 && mydefectlastpage === false) {
            getMyTeamDefectsList();
          } else {
            if (selected === 1 && allDefectlastpage === false) {
              getAllDefectsList();
            }
          }
        }
      }
    }
  };

  function EditSelectedDefect(data) {
    if (myRole === "TESTER") {
      getAllplatform("edit", data);
    } else {
      editDefectPlatfrom(data);
    }
  }

  function ApproveOrRejectDefect(label, data) {
    if (label === "approve") {
      setStatusWarnOpen(true);
      setBugHistoryId(data?.defectDetails?.ID);
      setDataForApprove(data);
    } else {
      setDefectReasonModalOpen(true);
      setCloseSearchField(true);
      setBugHistoryId(data?.defectDetails?.ID);
      setDataForApprove(data);
    }
  }

  const UpdateJiraID = (data) => {
    console.log("UpdateBugdetails", bugIDArray);
    console.log("UpdateBugdetails data", data);
    let temparray = {};
    temparray = {
      id: data?.defectDetails.ID,
      Summary: data?.defectDetails.Summary,
      Module: data?.defectDetails.Module,
      Severity: data?.defectDetails.Severity,
      Priority: data?.defectDetails.Priority,
      createdBy: data?.defectDetails["Created By"].name,
      State: data,
      Details: data,
    };
    let index = bugIDArray.indexOf(data?.defectDetails.ID);
    let tempdata = defects;
    tempdata[index] = temparray;
    console.log("tempdata", tempdata);
    setDefects([...tempdata]);
  };

  let columns =
    myRole === "PROJECT_OWNER"
      ? projectOwnerTable
      : selected === 0
      ? myDefectsTable
      : allDefectsTable;
  let mydefect = React.useMemo(() => defects, [defects]);
  let alldefect = React.useMemo(() => allDefects, [allDefects]);
  let data = selected === 0 ? mydefect : alldefect;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  return (
    <div className="defects-list">
      {loggedinUser?.defect?.actions?.create === true ? (
        <div className="grid grid-cols-12 flex header content-between">
          <div className="col-span-10 heading pl-2">
            Defects List - {defectsCount}
          </div>

          <div
            className="grid grid-cols-12 col-span-2 flex justify-end"
            ref={menuRef}
          >
            <div className="col-span-7 ml-4">
              <SearchField
                setGlobalFilter={setGlobalFilter}
                closeField={closeSearchField}
                setCloseField={setCloseSearchField}
                colSpan="col-span-11 ml-auto"
              />
            </div>

            <div
              className="grid grid-cols-12 col-span-5 addDefectBtn"
              onClick={() => {
                if (projectstate === "INPROGRESS") {
                  getAllplatform("create", "");
                  // hasDefaultTemp();
                }
              }}
            >
              <Tooltip
                title={
                  projectstate === "INPROGRESS"
                    ? "Add Defect"
                    : "Cannot add defect"
                }
              >
                <div
                  className={
                    projectstate === "INPROGRESS"
                      ? "grid grid-cols-12 col-span-11 create_buttton cursor-pointer"
                      : "grid grid-cols-12 col-span-11 create_buttton cursor-pointer opacity-50"
                  }
                >
                  <span className="col-span-2 ">
                    <AddIcon className="addDefectIcon" />
                  </span>
                  <span className=" col-span-10  mt-2 ml-3">Defect</span>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-12 flex header content-between">
          <div
            className={`${
              myRole === "PROJECT_OWNER" ? "col-span-9" : "col-span-10"
            } heading pl-3`}
          >
            Defects List-{defectsCount}
          </div>
          <div
            className="grid grid-cols-12 col-span-2 flex justify-end"
            ref={menuRef}
          >
            <SearchField
              setGlobalFilter={setGlobalFilter}
              closeField={closeSearchField}
              setCloseField={setCloseSearchField}
              colSpan="col-span-11 ml-auto"
            />
          </div>
          {myRole === "PROJECT_OWNER" && (
            <>
              <div className="grid grid-cols-12 col-span-1 defectFilterBtn">
                <Tooltip title="Filter">
                  <div
                    className={`grid grid-cols-12 col-span-11 create_buttton cursor-pointer ${(projectstate==="INPROGRESS" || projectstate==="CLOSED" || projectstate==="COMPLETED")?"opacity-100":"opacity-50"}`}
                    onClick={() => {
                      if(projectstate==="INPROGRESS" || projectstate==="CLOSED" || projectstate==="COMPLETED"){
                        CloseDefectFilterModal(true);
                      }
                     
                    }}
                  >
                    <span className="col-span-3 defectFilterIcon">
                      <FilterListIcon />
                    </span>
                    <span className="col-span-9  mt-2 ml-8">Filter</span>
                  </div>
                </Tooltip>
              </div>
            </>
          )}
        </div>
      )}
      <div>{renderTabs()}</div>
      <div className="tablecontainer">
        <table {...getTableProps()}>
          <thead className="text-left table_column_header h-10 ml-4">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th width={column.width} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {data.length === 0 ? (
            haveDefect === false &&
            loggedinUser?.defect?.actions?.create === true ? (
              <div className="defect_list_page_msg grid grid-cols-12">
                {dataloading === false && (
                  <div className="col-span-12 flex justify-center pt-56">
                    <div className="msg_text">Click </div>
                    <div
                      className={
                        projectstate === "INPROGRESS"
                          ? "grid grid-cols-6 col-span-4 create_buttton cursor-pointer"
                          : "grid grid-cols-6 col-span-4 create_buttton cursor-pointer opacity-50"
                      }
                      onClick={() => {
                        if (projectstate === "INPROGRESS") {
                          getAllplatform("create", "");
                          // hasDefaultTemp();
                        }
                      }}
                    >
                      <span className="col-span-2 add_icon">
                        {/* <Add /> */}
                        <AddIcon />
                      </span>
                      <span className="col-span-4 py-2 px-1">Defect</span>
                    </div>{" "}
                    <div className="msg_text"> to create defect</div>
                  </div>
                )}
              </div>
            ) : (
              <div className="">
                {dataloading === false && (
                  <>
                  {
                    Object.keys(selectedFilters).length === 0 ?
                    (
                      <>
                      <div className="noDefects flex justify-center">
                      <NoDefectIcon />
                    </div>
                    <div className="no_data_text testcase_text">
                      No Defects found!
                    </div>
                      </>

                    ):(
                      <>
                      <div className="no_relevant_data">
                      <img alt="warning" src={NoRelevantDataIcon} />
                      <div className="text_msg">
                        No Relevant Data Is Found !
                      </div>
                    </div>
                      </>
                    )
                  }
                    
                  </>
                )}
              </div>
            )
          ) : (
            <div>
              <tbody
                {...getTableBodyProps()}
                className="border-t-2"
                onScroll={handlescroll}
              >
                {rows.length > 0 ? (
                  <>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="h-10 defect-table-cell-value border-b-2"
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()} className="">
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <>
                   
                    <div className="no_relevant_data">
                      <img alt="warning" src={NoRelevantDataIcon} />
                      <div className="text_msg">
                        No Relevant Data Is Found !
                      </div>
                    </div>
                  </>
                )}
              </tbody>
            </div>
          )}
        </table>
      </div>
      {defectsPopUp && (
        <DefectsDeatilsPopup
          CloseDefectModal={CloseDefectModal}
          SelectedDefect={defectsdetails}
          defectTemplatedetails={defectTemplatedetails}
          editDefect={EditSelectedDefect}
          ApproveOrRejectDefect={ApproveOrRejectDefect}
        />
      )}
      {userPopup && (
        <UserDeatilsPopup
          CloseTesterModal={CloseTesterModal}
          testerDeatils={userDeatils}
        />
      )}
      {bugHistoryModal && (
        <BugHistoryPopup
          bugHistoryId={bugHistoryId}
          CloseBugHistoryModal={CloseBugHistoryModal}
          historyDetails={historyDetails}
        />
      )}
      {defectReasonModalOpen && (
        <DefectReasonModal
          CloseDefectReasonModal={CloseDefectReasonModal}
          setShowRejectedBtn={setShowRejectedBtn}
          dataForApprove={dataForApprove}
          callDefects={callDefectsAPI}
          MyAlert={props.MyAlert}
        />
      )}
      {statusWarnOpen && (
        <ChangeStatusWarning
          CloseWarningStatus={CloseWarningStatus}
          bugHistoryId={bugHistoryId}
          dataForApprove={dataForApprove}
          callDefects={callDefectsAPI}
          MyAlert={props.MyAlert}
        />
      )}
      {createDefect && (
        <CreateDefect
          CloseCreateDefectModal={CloseCreateDefectModal}
          modalType={"add"}
          MyAlert={props.MyAlert}
          selectedDefectData={""}
          platform={platform}
          getMyDefectsList={getMyDefectsList}
          callDefects={callDefectsAPI}
          webplatform={platform?.platformType === "web" ? webplatform : ""}
          Mobileplatform={
            platform?.platformType === "mobile" ||
            platform?.platformType === "watch" ||
            platform?.platformType === "tv"
              ? Mobileplatform
              : ""
          }
        />
      )}
      {editDefect && (
        <CreateDefect
          CloseCreateDefectModal={CloseEditDefectModal}
          modalType={"edit"}
          MyAlert={props.MyAlert}
          selectedDefectData={selectedDefectData}
          platform={selectedDefectData.defectDetails.platformDetails[0]}
          getMyDefectsList={getMyDefectsList}
          callDefects={UpdateBugdetails}
          webplatform={editWebplatform ? editWebplatform : ""}
          Mobileplatform={editMobileplatform ? editMobileplatform : ""}
        />
      )}
      {jirabug && (
        <CreateJiraDefect
          closeModal={closeJiraModal}
          MyAlert={props.MyAlert}
          data={jiraDefectdetails}
          attchmentList={attachmentList}
          UpdateJiraID={UpdateJiraID}
        />
      )}
        {showFilter && (
        <NewFilters
        CloseDefectFilterModal={CloseDefectFilterModal}
        data={defects}
        Severity={severity}
        Priority={priority}
        Module={module}
        Location={location}
        web={webPlatform}
        mobile={mobilePlatform}
        filterApply={getAllDefectsWithFilter}
        filters={selectedFilters}
        page={"Defect"}
        />
       
      )}
    </div>
  );
}

export default ProjectDefects;
