import React, { props } from "react";
import Chart from "react-apexcharts";
import {CHART_COLOR,MODULE_CHART_COLOR,DASHBOARD_CHART_COLOR} from "../../util/app-constants"

function DashboardDonutChart(props) {
 let seriesdata=props?.series;
 
  return (
   <>
   <Chart
      type="donut"
      width={600}
      height={400}
      series={seriesdata}
      options={{
        chart: {
          type: "donut",
          
        },
        labels: props?.labels,
        colors:DASHBOARD_CHART_COLOR,
        theme: {
          monochrome: {
            enabled: false,
          },
        },
        plotOptions: {
            pie: {
           
              expandOnClick: false,
              donut: {
                size:"70%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: '22px',
                    fontFamily: 'Poppins-Medium',
                    fontWeight: 600,
                    color: "#6F7075",
                    offsetY: -10,
                    label:"Total",
                   
                },
                 
                  total: {
                    // showAlways: true,
                    show: true,
                    fontSize: "12px",
                    color: "#6F7075",
                    offsetY: 30,
                    fontFamily: "Poppins-Regular",
                    label:"Total",
                    formatter: function (w) {
                      console.log("wwww hello",w)
                     
                        return  w.config.series.length
                      
                     
                      
                    }
                   
                  },
                  value: {
                    show: true,
                    color: "#434DB8",
                    fontSize: "14px",
                    offsetY: -5,
                    fontFamily: "Poppins-SemiBold",
                    fontWeight: "10",
                    margin: {
                      top: 20,
                      bottom: 10,
                    },
                  },
                },
              },
            },
          },
          tooltip:{
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              return `<div style="background: ${ w.globals.colors[seriesIndex]}" class="bar-chart-custom-tooltip"> 
                <span> ${ w.globals.labels[seriesIndex] } : ${series[seriesIndex]}   </span> 
                </div>`
            }
          },
        dataLabels: {
          enabled: false,
        },
        
      
        legend: {
            show:true,
            width:200,
          position: "right",
          fontFamily: "Poppins-Regular",
          fontSize:"12px",
          onItemClick: {
            toggleDataSeries: false,
          },
          formatter: function (val, opts) {
             return val + " - " + opts.w.globals.series[opts.seriesIndex];
           
            
          },
        },
      
      }}
    />
    
   </>
    
  );
}

export default DashboardDonutChart;
