import React, { useEffect, useState } from "react";

import { ReactComponent as AddDeviceIcon } from "../../../assets/add-devices-Icon.svg";

import { ReactComponent as MacIcon } from "../../../assets/platform/mac.svg";
import { ReactComponent as Windows } from "../../../assets/platform/windows.svg";
import { ReactComponent as Linux } from "../../../assets/platform/linux.svg";
import { ReactComponent as Ubantu } from "../../../assets/platform/ubantu.svg";
import { ReactComponent as Android } from "../../../assets/platform/android.svg";

import { ReactComponent as Opera } from "../../../assets/platform/opera.svg";
import { ReactComponent as AndroidBrowser } from "../../../assets/platform/android-browser.svg";
import { ReactComponent as FirefoxIcon } from "../../../assets/platform/firefox.svg";
import { ReactComponent as MicrosoftEdge } from "../../../assets/platform/microsoft edge.svg";
import { ReactComponent as ChromeIcon } from "../../../assets/platform/chrome.svg";
import { SelectDropdown } from "./select-dropdown";
import PlatformModal from "./platform-modal";
import AddTestingDeviceModal from "./add-testingDevice-modal";
import "../../modal/modal.scss"
// import { getAllPlatformReq } from "../../../api/api-services";

function Addplatform(props) {
  console.log("Addplatform",props)
  
  let [webdetails, setWebdetails] = useState();
  let [mobiledetails, setMobileDetails] = useState();
  let [webplatformdetails, setWebplatformdetails] = useState();
  let [Mobileplatformdetails, setMobileplatformdetails] = useState();
  let [webplatformlist, setWebplatformlist] = useState([]);
  let [mobilePlatformlist,setMobileplatformlist]=useState([])
  let [webselectedPlatform, setWebSelectedPlatform] = useState([]);
  let [mobileselectedPlatform,setmobileSelectedPlatform]=useState([]);
  let [addNewDevice,setAddNewDevice]=useState(false)
  let loggedinUser = JSON.parse(localStorage["user-privilege"]).role;
  
  localStorage.hasOwnProperty("All-platform")
  useEffect(() => {
    let list = localStorage.hasOwnProperty("All-platform")?JSON.parse(localStorage.getItem("All-platform")):"";
    let webtemparray = [];
   let mobiletemparray=[];
   setWebplatformdetails(props?.webplatform);
   setMobileplatformdetails(props?.mobileplatform)
    if (JSON.parse(localStorage.getItem("selected-project")).project?.projectType?.web !== null || JSON.parse(localStorage.getItem("selected-project")).project?.projectType?.webAndMobile !== null ||(JSON.parse(localStorage.getItem("selected-project")).project?.projectType?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project?.projectType?.others?.typeOfProject==="Standalone_Application")) 
      {
        if(props?.webplatform?.platformType==="web"){
          setWebSelectedPlatform([{
            label: props?.webplatform?.platformName,
            value: props?.webplatform?.platformName,
          }])
          setWebdetails(true)
        }
      if(loggedinUser==="TESTER"){
        list.map((item) => {
          if (item.platformType === "web") {
            webtemparray.push({
              label: item.platformName,
              value: item.platformName,
            });
          }
        });
        
        setWebplatformlist(webtemparray);
      }
      if(JSON.parse(localStorage.getItem("selected-project")).project?.projectType?.webAndMobile !== null && props?.webplatform==="" && loggedinUser==="TESTER" && webtemparray.length!==0){
        setWebSelectedPlatform([{
          label:  webtemparray[0].label,
          value:  webtemparray[0].value
        }])
      list.map((item)=>{
        if (item.platformName === webtemparray[0].value) {
          setWebplatformdetails(item);
          
        }

      })
      }
      
    
      
      
    }
    if(JSON.parse(localStorage.getItem("selected-project")).project?.projectType?.mobile !== null ||  JSON.parse(localStorage.getItem("selected-project")).project?.projectType?.webAndMobile !== null|| (JSON.parse(localStorage.getItem("selected-project")).project?.projectType?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project?.projectType?.others?.typeOfProject!=="Standalone_Application"
    )){
      if(props?.mobileplatform?.platformType==="mobile" || props?.mobileplatform?.platformType==="Tablet"||props?.mobileplatform?.platformType==="tv"||props?.mobileplatform?.platformType==="watch"){
        setmobileSelectedPlatform([{
          label: props?.mobileplatform?.platformName,
          value: props?.mobileplatform?.platformName,
        }])
        setMobileDetails(true)
      }
      if(loggedinUser==="TESTER"){
        list.map((item)=>{
          if (item.platformType==="mobile"||item.platformType==="tv"||item.platformType==="watch") {
            mobiletemparray.push({
              label: item.platformName,
              value: item.platformName,
            });
          }
        })
    
        setMobileplatformlist(mobiletemparray);
      }
      if(JSON.parse(localStorage.getItem("selected-project")).project?.projectType?.webAndMobile !== null && loggedinUser==="TESTER" && mobiletemparray.length!==0 && props?.mobileplatform===""){
      
        setmobileSelectedPlatform([{
          label:  mobiletemparray[0].label,
          value:  mobiletemparray[0].value
        }])
        list.map((item)=>{
        if (item.platformName === mobiletemparray[0].value) {
          setMobileplatformdetails(item);
          
        }

      })
      }

      
      
    }
    
  }, []);

  function onCloseAddPlatform(state){
    console.log("state",state)
    setAddNewDevice(state);
  }

  function add(state){
    console.log("state",state)
    setAddNewDevice(state);
  }
  
  function selectplatform(type,state){
    
    console.log("type",type);
    console.log("state",state)
    if(state===true){
      let list = JSON.parse(localStorage.getItem("All-platform"));
      if(type==="web"){
        list.map((item)=>{
          if(item?.platformName === webselectedPlatform[0]?.value){
            setWebplatformdetails(item)
          }
        })
        
        props?.platformselect(webplatformdetails,"web")
        
      }else{
        
         list.map((item)=>{
          if(item.platformName ===mobileselectedPlatform[0]?.value){
            setMobileplatformdetails(item)
          }
        })
        props?.platformselect(Mobileplatformdetails,"mobile")
        
      }

    }else{
      if(type==="web"){
        
        props?.platformselect("","web")
      }else{
       
        props?.platformselect("","mobile")
      }
    }
    
    
    
    
    
  }

  function OnchangeWebplatform(data) {
    console.log("data", data);
    let list = JSON.parse(localStorage.getItem("All-platform"));
    setWebSelectedPlatform([...data]);
    list.map((item) => {
      console.log("item label", item.platformName);
      console.log("data value", data[0].value);
      if (item.platformName === data[0].value) {
        setWebplatformdetails(item);
        props.getplatformdetails(item)
      }
    });
   
  }

  function OnchangeMobileplatform(data) {
    console.log("data", data);
    let list = JSON.parse(localStorage.getItem("All-platform"));
    setmobileSelectedPlatform([...data]);
    list.map((item) => {
      console.log("item label", item.platformName);
      console.log("data value", data[0].value);
      if (item.platformName === data[0].value) {
        setMobileplatformdetails(item);
        props.getplatformdetails(item)
      }
    });
   
  }

  function selectedOS(ostype) {
    switch (ostype?.toLowerCase()) {
      case "mac":
        return <MacIcon />;

      case "windows":
        return <Windows />;

      case "linux":
        return <Linux />;

      case "ubantu":
        return <Ubantu />;

      case "android":
        return <Android />;

      case "IOS":
        return <MacIcon />;

      default:
        return "";
    }
  }
  function selectedBrowser(browsertype) {
    switch (browsertype?.toLowerCase()) {
      case "opera":
        return <Opera />;

      case "android":
        return <AndroidBrowser />;

      case "linux":
        return <Linux />;

      case "firefox":
        return <FirefoxIcon />;

      case "microsoft edge":
        return <MicrosoftEdge />;

      case "chrome":
        return <ChromeIcon />;

      default:
        return "";
    }
  }
 
  console.log("ertyui gfgfgg",(JSON.parse(localStorage.getItem("selected-project")).project?.projectType
  ?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project?.projectType
  ?.others?.typeOfProject!=="Standalone_Application"))
  return (
    <div className="defect-add-platform">
      
      <div className="flex pt-2">
        <div className="platform_heading ml-2 ">Add Platform</div>
        {
          JSON.parse(localStorage['user-privilege']).config?.platformDetails?.actions?.create===true &&
          (
            <div className="flex add_new_device cursor-pointer" onClick={()=>{add(true)}}>
          <div className="pt-1 px-2">
            <AddDeviceIcon />
          </div>
          <div className="btn_txt">Add new device</div>
        </div>
          )

        }
        
      </div>
      <>
      {((JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.web !== null ) || (JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others?.typeOfProject==="Standalone_Application") )&& (
        <div>
          <div className="px-2">
            <div className="mt-1">
              <div className="w-5/12 platform_dropdown" id="defect_platform">
                <SelectDropdown
                  data={webplatformlist}
                  selectedData={webselectedPlatform}
                  onChange={OnchangeWebplatform}
                  searchable={false}
                  disabled={loggedinUser==="TESTER"?false:true}
                />
              </div>
            </div>

            <div>
              <div className="my-5">
                <div className="add_platform mb-1">
                  <div className="label">OS</div>
                </div>

                <div className="ostype flex">
                  <div className="mx-2 mt-1">
                    {selectedOS(webplatformdetails?.os)}
                  </div>
                  <div className="osvalue">{webplatformdetails?.os}</div>
                </div>
              </div>

              <div className="my-5">
                <div className="add_platform mb-1">
                  <div className="label">OS Version</div>
                </div>

                <div className="ostype">
                  <div className="osvalue ml-2">
                    {webplatformdetails?.osVersion}
                  </div>
                </div>
              </div>

              {
                !(JSON.parse(localStorage.getItem("selected-project")).project?.projectType
                ?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project?.projectType
                ?.others?.typeOfProject==="Standalone_Application") && (
                  <>
                   <div className="my-5">
                <div className="add_platform mb-1">
                  <div className="label">Browser</div>
                </div>

                <div className="ostype flex">
                  <div className="mx-2 mt-1">{selectedBrowser(webplatformdetails?.browser)}</div>
                  <div className="osvalue">{webplatformdetails?.browser}</div>
                </div>
              </div>

              <div className="my-5">
                <div className="add_platform mb-1">
                  <div className="label">Browser Version</div>
                </div>

                <div className="ostype">
                  <div className="osvalue ml-2">
                    {webplatformdetails?.browserVersion}
                  </div>
                </div>
              </div>
                  </>
                )
              }

             
              <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">City</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">
                        {webplatformdetails?.city}
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      )}
    

      {((JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.mobile !== null) ||(JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.others?.typeOfProject!=="Standalone_Application")) && (
        <div>
          <div className="mt-1">
            <div className="w-5/12  platform_dropdown" id="defect_platform">
              <SelectDropdown
                data={mobilePlatformlist}
                selectedData={mobileselectedPlatform}
                onChange={OnchangeMobileplatform}
                searchable={false}
                disabled={loggedinUser==="TESTER"?false:true}
               />
            </div>
          </div>
          <div className="px-2">
            <div>
              <div className="my-5">
                <div className="add_platform mb-1">
                  <div className="label">OS</div>
                </div>

                <div className="ostype flex">
                  <div className="mx-2 mt-1">{selectedOS(Mobileplatformdetails?.os)}</div>
                  <div className="osvalue">{Mobileplatformdetails?.os}</div>
                </div>
              </div>

              {/* new line */}
              <div className="my-5">
                <div className="add_platform mb-1">
                  <div className="label">OS Version</div>
                </div>

                <div className="ostype">
                  <div className="osvalue ml-2">
                    {Mobileplatformdetails?.osVersion}
                  </div>
                </div>
              </div>

              {JSON.parse(localStorage.getItem("selected-project")).project
                ?.projectType?.mobile?.appType === "mWeb" && (
                <>
                  <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">Browser</div>
                    </div>

                    <div className="ostype flex">
                      <div className="mx-2 mt-1">
                        {selectedBrowser(Mobileplatformdetails?.browser)} 
                      </div>
                      <div className="osvalue">{Mobileplatformdetails?.browser}</div>
                    </div>
                  </div>

                  <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">Browser Version</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">
                        {Mobileplatformdetails?.browserVersion}
                      </div>
                    </div>
                  </div>
                  
                </>
              )}

              {/* new line */}
              <div className="my-5">
                <div className="add_platform mb-1">
                  <div className="label">Device Name</div>
                </div>

                <div className="ostype">
                  <div className="osvalue ml-2">{Mobileplatformdetails?.deviceName}</div>
                </div>
              </div>

              <div className="my-5">
                <div className="add_platform mb-1">
                  <div className="label">Device Type</div>
                </div>

                <div className="ostype">
                  <div className="osvalue ml-2">{Mobileplatformdetails?.deviceType}</div>
                </div>
              </div>
              <div className="my-5">
                <div className="add_platform mb-1">
                  <div className="label">Device Brand</div>
                </div>

                <div className="ostype">
                  <div className="osvalue ml-2">{Mobileplatformdetails?.deviceBrand}</div>
                </div>
              </div>
              <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">City</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">
                        {Mobileplatformdetails?.city}
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      )}

      {JSON.parse(localStorage.getItem("selected-project")).project?.projectType
        ?.webAndMobile !== null && (
        <div>
          <div className="flex pl-5">
            <div className="flex platform_checkbox">
              <input
                type="checkbox"
                checked={webdetails} 
                // defaultChecked={webdetails}
                name="Web"
                value="Web"
                disabled={webplatformlist?.length===0?true:false}
                onChange={(e) => {
                 setWebdetails(e.target.checked);
                selectplatform("web",e.target.checked);
                  
                }}
              />
              <span className="checkbox_label">Web</span>
            </div>
            <div className="flex">
              <input
                type="checkbox"
                checked={mobiledetails} 
                // defaultChecked={mobiledetails}
                name="Mobile"
                value="Mobile"
                disabled={mobilePlatformlist?.length===0?true:false}
                onChange={(e) => {
                  
                  setMobileDetails(e.target.checked);
                  selectplatform("mobile",e.target.checked)
                  
                }}
              />
              <span className="checkbox_label">Mobile</span>
            </div>
          </div>
          <div className="flex mt-1">
            <div className="w-5/12 ml-4 platform_dropdown" id="defect_platform">
              {
                webdetails && (<SelectDropdown 
                  data={webplatformlist}
                  selectedData={webselectedPlatform}
                  onChange={OnchangeWebplatform}
                  searchable={false}
                  disabled={loggedinUser==="TESTER" && webplatformlist?.length!==0?false:true}
                  />)
              }
              
            </div>
            <div className="w-5/12 ml-10 platform_dropdown" id="defect_platform">
              {
                mobiledetails && (
                  <SelectDropdown 
              data={mobilePlatformlist}
              selectedData={mobileselectedPlatform}
              onChange={OnchangeMobileplatform}
              searchable={false}
              disabled={loggedinUser==="TESTER" && mobilePlatformlist?.length!==0?false:true}
              
              />
                )
              }
              
            </div>
          </div>
          {webdetails && (
            <div className="mx-4">
              
              <div className="px-2">
              <div className="platform_type_header py-4">Web</div>
                <div>
                  <div className="">
                    <div className="add_platform mb-1">
                      <div className="label">OS</div>
                    </div>

                    <div className="ostype flex">
                      <div className="mx-2 mt-1">{selectedOS(webplatformdetails?.os)}</div>
                      <div className="osvalue">{webplatformdetails?.os}</div>
                    </div>
                  </div>

                  <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">OS Version</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">
                        {webplatformdetails?.osVersion}
                      </div>
                    </div>
                  </div>

                 

                  <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">Browser Type</div>
                    </div>

                    <div className="ostype flex">
                      <div className="mx-2 mt-1">
                        {selectedBrowser(webplatformdetails?.browser)}
                      </div>
                      <div className="osvalue">{webplatformdetails?.browser}</div>
                    </div>
                  </div>

                  <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">Browser Version</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">
                        {webplatformdetails?.browserVersion}
                      </div>
                    </div>
                  </div>
                  <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">City</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">
                        {webplatformdetails?.city}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {mobiledetails && (
            <div className="mx-4">
               
              <div className="px-2">
              <div className="platform_type_header py-2">Mobile</div>
                <div>
                  <div className="">
                    <div className="add_platform mb-1">
                      <div className="label">OS</div>
                    </div>

                    <div className="ostype flex">
                      <div className="mx-2 mt-1">{selectedOS(Mobileplatformdetails?.os)}</div>
                      <div className="osvalue">{Mobileplatformdetails?.os}</div>
                    </div>
                  </div>

                  <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">OS Version</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">{Mobileplatformdetails?.osVersion}</div>
                    </div>
                  </div>

                  <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">Device Name</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">{Mobileplatformdetails?.deviceName}</div>
                    </div>
                  </div>
                  {JSON.parse(localStorage.getItem("selected-project")).project
                    ?.projectType?.mobile?.appType === "mWeb" && (
                    <>
                      <div className="my-5">
                        <div className="add_platform mb-1">
                          <div className="label">Browser</div>
                        </div>

                        <div className="ostype flex">
                          <div className="mx-2 mt-1">
                            {selectedBrowser(Mobileplatformdetails?.browser)}
                          </div>
                          <div className="osvalue">{Mobileplatformdetails?.browser}</div>
                        </div>
                      </div>

                      <div className="my-5">
                        <div className="add_platform mb-1">
                          <div className="label">Browser Version</div>
                        </div>

                        <div className="ostype">
                          <div className="osvalue ml-2">{Mobileplatformdetails?.browserVersion}</div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">Device Type</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">{Mobileplatformdetails?.deviceType}</div>
                    </div>
                  </div>
                  <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">Device Brand</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">{Mobileplatformdetails?.deviceBrand}</div>
                    </div>
                  </div>
                  <div className="my-5">
                    <div className="add_platform mb-1">
                      <div className="label">City</div>
                    </div>

                    <div className="ostype">
                      <div className="osvalue ml-2">{Mobileplatformdetails?.city}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          
        </div>
      )}
      </>
      {
        addNewDevice && 
        (
          
          <AddTestingDeviceModal onCloseAddPlatform={onCloseAddPlatform} mode={"defect"}/>
        )
      }

      
    </div>
  );
}
export default Addplatform;
