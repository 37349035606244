import React, { Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as PlusIcon } from "../../assets/icon-feather-plus-circle.svg";
import { ArrowRight } from "@material-ui/icons";

function MoreInfoButton(props) {
  const [pageIndexValue, setPageIndex] = React.useState(0);
  const [pageIndex, setPage] = React.useState(0);
  const [margin, setMargin] = React.useState(0);
  useEffect(() => {
    if (pageIndexValue > 550) {
      setPage(-320);
      setMargin(150);
    } else {
      setPage(100);
      setMargin();
    }
  }, [pageIndexValue]);

  const sendvalue = (name, node) => {
    props.methodName(name, node);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="inline">
      {props.typeToDisplay === "more" ? (
        <>
          <Menu
            as="div"
            className="more-button relative inline-block text-left"
          >
            {({ open }) => {
              props.moreButtonClicked && props.moreButtonClicked(open);
              return (
                <>
                  <div>
                    <Tooltip
                      title={props.folderCount === 0 ? "More" : "More"}
                      placement="top"
                    >
                      <Menu.Button
                        disabled={props.folderCount === 0 ? false : false}
                        className=" flex items-center text-gray-400 focus:outline-none"
                      >
                        <span className="sr-only">Open options</span>
                        <PlusIcon />
                      </Menu.Button>
                    </Tooltip>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <div className="py-1">
                        {props.type === "folder"
                          ? props.methodListOfFolder?.map((data, index) => (
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    className="menu-item"
                                    onClick={() => {
                                      sendvalue(data.name, props.nodeObj);
                                    }}
                                  >
                                    <button
                                      className={classNames(
                                        active
                                          ? "bg-gray-200 hyper_link_color-common"
                                          : "",
                                        "text-gray-700 block w-full text-left px-4 py-2 text-sm"
                                      )}
                                    >
                                      {data.name}
                                    </button>
                                  </div>
                                )}
                              </Menu.Item>
                            ))
                          : props.type === "root"
                          ? props.methodListForRoot?.map((data, index) => (
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    className="menu-item"
                                    onClick={() => {
                                      sendvalue(data.name, props.nodeObj);
                                    }}
                                  >
                                    <button
                                      className={classNames(
                                        active
                                          ? "bg-gray-200 hyper_link_color-common"
                                          : "",
                                        "text-gray-700 block w-full text-left px-4 py-2 text-sm"
                                      )}
                                    >
                                      {data.name}
                                    </button>
                                  </div>
                                )}
                              </Menu.Item>
                            ))
                          : props.methodListOfNonFolder?.map((data, index) => (
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    className="menu-item"
                                    onClick={() => {
                                      sendvalue(data.name, props.nodeObj);
                                    }}
                                  >
                                    <button
                                      className={classNames(
                                        active
                                          ? "bg-gray-200 hyper_link_color-common"
                                          : "",
                                        "text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                      )}
                                    >
                                      {data.name}
                                    </button>
                                  </div>
                                )}
                              </Menu.Item>
                            ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              );
            }}
          </Menu>
        </>
      ) : (
        <>
          <Menu
            as="div"
            className="more-button relative inline-block text-left"
          >
            {({ open }) => (
              <>
                <div>
                  <Menu.Button
                    onMouseOver={(e) => {
                      let mouse = e.clientY;
                      setPageIndex(mouse);
                    }}
                    className={`bg-gray-100 text-gray-400 hover:text-gray-600 ${
                      props?.disable ? "opacity-50" : ""
                    } `}
                    disabled={props?.disable}
                  >
                    <span className="sr-only">Open options</span>
                    <AddCircleOutlineOutlinedIcon />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    style={{
                      top: `${pageIndex}%`,
                      marginRight: `${margin}%`,
                      width: "215px",
                    }}
                    className="origin-top-right absolute left-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none  z-10 w-48"
                  >
                    <div className="py-1">
                      {props.plusIconArray.map((data, index) => (
                        <Menu.Item key={`context-menu-item-${index}`}>
                          {({ active }) => (
                            <a
                              onClick={(e) => {
                                props.func(e, data.option);
                              }}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm",
                                "hover:text-blue-700"
                              )}
                            >
                              {/* <img src={data.icon} height="20px" width="20px" className="float-left mr-3"></img> */}

                              <label
                                className={
                                  props?.state === "ACTIVE" &&
                                  data.option === "Resend Activation Link"
                                    ? "opacity-50"
                                    : "cursor-pointer"
                                }
                                onClick={() => {
                                  sendvalue(data.value, props.nodeObj);
                                }}
                              >
                                {data.name}
                              </label>
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </>
      )}
    </div>
  );
}

export default MoreInfoButton;
