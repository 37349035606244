import React, { useState, useEffect } from "react";
import { useTable, useGlobalFilter } from "react-table";
import styled from "styled-components";
import { ReactComponent as Search } from "../../../assets/search.svg";
import "./reports.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ReactComponent as NoReportsIcon } from "../../../assets/noReportsAvailable.svg";
import NoDefectIcon from "../../../assets/reportsicon/no_reports_icon.png";
import { CircularLoader} from "../../common/CircularLoader";

function DefectsCommonTable(props) {
  console.log("DefectsCommonTable",props)
  let [defects, setdefects] = useState([]);
  let [dataLoaded, setDataLoaded] = useState(true);
  let [displayapproverdefect, setDisplayapproverdefect] = useState(true);
  let [closeSearchField, setCloseSearchField] = useState(false);
  let [dataLoding,setDataLoaing]=useState();
  let [filedownloading,setDownloadingFile]=useState()


 useEffect(()=>{
  setDownloadingFile(props?.downloadingFile)

 },[props?.downloadingFile])
  useEffect(()=>{
    setDataLoaing(props?.dataloading)
  },[props?.dataloading])
  useEffect(() => {
    getAllDefects();
  }, [props?.tableData]);

  const Styles = styled.div`
    table {
      border-spacing: 0;
      border: 1px solid #707070;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        border-bottom: 1px solid #707070;
        border-right: 1px solid #707070;

        :last-child {
          border-right: 0;
        }
      }
    }
  `;

  const getAllDefects = () => {
  
     setdefects(props?.tableData);
    
    
  };

  let columns = React.useMemo(
    () => [...props.detailsheaders],
    [props.detailsheaders]
  );
  let data = React.useMemo(() => defects, [defects]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;

  const handlescroll = (event) => {
    
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
      if(props?.detailsSection==="testers"){
        props?.gettrainerDataByScroll()
      }
      
    }
  };

  return (
    <div className="card_section_tabel_container" style={{borderTop:props.detailsSection === "days"?"2px solid #DC5BEF":"2px solid #F6B960"}}>
      <div className="grid grid-cols-12 flex content-between">
        <div className="col-span-6 heading pl-3">
        {props?.countHeader}
        </div>
        <div className="grid grid-cols-12 col-span-6 flex justify-end defect_common_tabel_header">
        <div className="search_section col-span-5">
          <div className="adminsearch">
           
            <div>
              <input
                type="text"
                className="searchfield"
                 value={globalFilter || ""}
                autoFocus={true}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
                placeholder="Search"
              />
            </div>
            <div className="search_icon">
              <Search />
            </div>
          </div>
        </div>
        <div className={`col-span-2 report_Download_btn ${defects.length===0 ||filedownloading===true?"opacity-50":"opacity-100"}`}>
        <button className="flex" onClick={()=>{if(defects.length!==0|| filedownloading===false){props?.downloadFile(props?.detailsSection)}}}>
                <div className="mt-1.5 ml-2.5">
                Download
                </div>
                <div className="ml-2">
                    <FileDownloadOutlinedIcon/>
                </div>
               
               

            </button>
        </div>
       

       
        </div>
      </div>
        <div
          className="card_table_container mt-2"
        >
          <table {...getTableProps()} className="">
            <thead className="">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="defect_table_header">
                  {headerGroup.headers.map((column) => (
                    <th
                      width={column.width}
                      {...column.getHeaderProps()}
                      className={`${
                        column.Header === "BUG SUMMARY" ||
                        column.Header === "DATE OF TESTING" ||
                        column.Header === "PARTICIPANTS NAME"
                          ? "text-left pl-3"
                          : "text-center"
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {
              dataLoding===false ? (
                <tbody {...getTableBodyProps()} className="defect_table_body" onScroll={handlescroll}>
                {
                  (rows.length === 0 || data?.length === 0)?
                  (
                    <div className="noreportIconCenter">
                    <img  alt="warning" src={NoDefectIcon}/>
                    <div className="text_msg">No Relevant Data Is Found !</div>
                  </div>
                  ):(
                   <>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="h-10 ">
                          {row.cells.map((cell) => {
                            return (
                              <td
                                width={cell?.column?.width}
                                {...cell.getCellProps()}
                                className={`datatrimmer reportTablecell ${
                                  cell.column.Header === "BUG ID" ||
                                  cell.column.Header === "CREATED BY"
                                    ? "reportBugIdColumn popins-semi-bold"
                                    : "popins"
                                } ${
                                  cell.column.Header === "BUG SUMMARY" ||
                                  cell.column.Header === "DATE OF TESTING" ||
                                  cell.column.Header === "PARTICIPANTS NAME"
                                    ? "text-left pl-3"
                                    : "text-center"
                                }`}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                   </>
                  )
                }
               
              </tbody>
              ):(
                <div className="mt-20">
                  <CircularLoader/>
                </div>
              )
            }
           
         
           
             
      
          </table>
         
        </div>
      
    </div>
  );
}
export default DefectsCommonTable;
