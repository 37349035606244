import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Close from "@mui/icons-material/Close";
import { blue } from "@mui/material/colors";
import dateFormat from "dateformat";
import { TextareaAutosize } from "@material-ui/core";

function ModuleDeatilsPopup(props) {
  console.log(props.moduleDeatils,"here");
  const [openModal, setOpenModal] = useState(true);
  const [moduleData, setmoduleData] = useState(props?.moduleDeatils);

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "77.5%",
      transform: "translate(-50%, -50%)",
      width: "45%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      // borderTopLeftRadius: "4px",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

 

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
      <div className="tester_deatils">
        <div className="grid grid-cols-12 flex header">
          <span className="col-span-11 heading pl-2">
            {props?.moduleDeatils?.type==="Script"?"Script Details":"Module Details"} - {moduleData?.name}
          </span>
          <div className="col-span-1 flex justify-end mr-1">
            <button
              onClick={() => {
                props.closeModal(false);
              }}
              type="button"
              className="modal_clos_btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="p-4 body">
          <div className="grid grid-cols-12 flex">
            <div className="col-span-6">
              <span className="labels">Name</span>
              <br></br>
              <div className="values datatrimmer w-20" title={moduleData?.name}>
                {moduleData?.name}
              </div>
            </div>
            <div className="col-span-6">
              <span className="labels">Path</span>
              <br></br>
              <div className="values datatrimmer w-32 cursor-pointer" title={moduleData?.path}>{moduleData?.path}</div>
            </div>
          </div>
          <div className="grid grid-cols-12 flex mt-4">
            <div className="col-span-12">
              <span className="labels">Description</span>
              <br></br>
              <div className="values">
                {moduleData?.desc !== null && moduleData?.desc.trim().length !==0?
                 (<div>
                 <TextareaAutosize
               value= {moduleData?.desc}
               style={{width:"100%",resize:"none",outline:"none"}}
               disabled={true}
               className="tester_instruction"
               />
               </div>)
                
                 : "--"}
              </div>
            </div>
          </div>
          {/* <div className={`grid grid-cols-12 flex h-12 mt-4 ${props?.moduleDeatils?.type==="Script"?"hidden":"block"}`}>
          <div className="col-span-6">
              <span className="labels">Total Modules</span>
              <br></br>
              <span className="values">
                {moduleData?.subModuleCount === null
                  ? "--"
                  : moduleData?.subModuleCount}
              </span>
            </div>
            <div className="col-span-6">
              <span className="labels">Total TestCase</span>
              <br></br>
              <span className="values">
                {moduleData?.scriptCount === null
                  ? "--"
                  : moduleData?.scriptCount}
              </span>
            </div>
          </div> */}
          <div className="grid grid-cols-12 flex h-12 mt-4">
            <div className="col-span-6">
              <span className="labels">Created By</span>
              <br></br>
              {JSON.parse(localStorage.getItem("user-privilege")).role ===
              "PROJECT_OWNER" ? (
                <span className="values">
                  {moduleData?.createdByUname !== null
                    ? moduleData?.createdByUname
                    : "--"}
                </span>
              ) : (
                <span className="values">
                  {moduleData?.createdByUname !== null
                    ? moduleData?.createdByUname
                    : "--"}
                </span>
              )}
            </div>
            <div className="col-span-6">
              <span className="labels">Created On</span>
              <br></br>
              <span className="values">
                {moduleData?.createdOn !== null ? moduleData?.createdOn : "--"}
              </span>
            </div>
          </div>        
          <div className="grid grid-cols-12 flex h-12 mt-4">
          <div className="col-span-6">
              <span className="labels">Modified By</span>
              <br></br>
              <span className="values">
                {moduleData?.modifiedByUname!== null
                  ? moduleData?.modifiedByUname
                  : "--"}
              </span>
            </div>
          <div className="col-span-6">
              <span className="labels">Modified On</span>
              <br></br>
              <span className="values">
                {moduleData?.modifiedOn !== null
                  ? moduleData?.modifiedOn
                  : "--"}
              </span>
            </div>
          </div>
        </div>

        <>
          <div className="grid grid-cols-12 col-span-12 user-details_footer  h-12 pt-2">
            <div className="col-span-10"></div>
            <div className="grid grid-cols-12 col-span-2 flex justify-end">
              <div className="grid grid-cols-12 col-span-12 h-8 flex justify-end">
                <span
                  className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer"
                  onClick={() => {
                    props.closeModal(false);
                  }}
                >
                  <button>Close</button>
                </span>
              </div>
            </div>
          </div>
        </>
      </div>
    </Modal>
  );
}
export default ModuleDeatilsPopup;
