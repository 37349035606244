import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CloseIcon from '@mui/icons-material/Close';
import ModulechartExpand from "../charts/modulechart-expand";
import TestcaseExpandModuleTable from "../tc_expand_table";
//  import ExecutionReportsTable from "../execution-reports-table;
import {getAllTestcaseReq,getTotalTestcaseCount} from "../../../api/api-services";
import "./reports.scss";
import {getDefectSegmentData,getLocationSegmentData} from "../../../api/api-services"
import ModuleSegment from "./module-segment";
import ExpandDefectSegmnet from "./expand-defect-segmnet";
import { CircularLoader } from "../../common/CircularLoader";




function DefectExpandSegment(props) {
    console.log("props",props)
  const [openModal, setOpenModal] = useState(true);
  let [ModulePayload,setModulePayload]=useState([]);
  let fireFlinkProjectId= JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  let projectID=JSON.parse(localStorage["selected-project"]).id;
  let [ModuleState,seModuleState]=useState("CLOSED");
  let [segmentLabelCount,setSegmentLabelCount]=useState([])
  let [displaymoduleapproverdefect, setModuleDisplayapproverdefect] =
    useState(true);
    let SelectedModulePayload=[]
    let [selecteLabel,setSelectelabel]=useState([])
  let [dataLoading,setdataLoading]=useState();

    let [module, setModule] = useState({
        label: [],
        series: [],
        payload: [],
      });
      useEffect(()=>{
        setModuleDisplayapproverdefect(true);
        getAllSegmentData("CLOSED");
      },[])

      async function getAllSegmentData(state) {
        setdataLoading(true)
        if(props?.header==="Modules"){
          try {
            let response = await getDefectSegmentData(
              licenseID,
              fireFlinkProjectId,
              projectID,
              state
            );
           
            if (response?.data?.statusCode === 200) {
              let tempdata = response?.data?.data;
              tempdata.map((item) => {
                if (item.name === "Module") {
                  let obj1 = {
                    label: [],
                    series: [],
                    payload: [],
                  };
                 
                  let tempArray=[]
                  item.defectData.map((data, index) => {
                    let val=data?.name + "-" + data?.count;
                    tempArray.push(val)
                      obj1.label.push(data?.name);
                      obj1.series.push(data?.count);
                      obj1.payload.push(data?.name);
                     
                    
                  });
                  setSegmentLabelCount(tempArray)
                  setModulePayload(tempArray);
                  setSelectelabel(obj1.label)
                  setModule(obj1);
                  
                }
              });
      
             
            }
          } catch (error) {
            console.log("error");
          }
        }else{
          try {
            let response = await getLocationSegmentData(
              licenseID,
              fireFlinkProjectId,
              projectID,
              state
            );
            console.log("getAllLocationSegment", response);
            if (response?.status === 200) {
              let tempData = response?.data?.data;
             
      
              tempData.map((item) => {
                let obj1 = {
                  label: [],
                  series: [],
                  payload: [],
                };
               
                let tempArray=[];
                item.defectData.map((data, index) => {
                  let val=data?.name + "-" + data?.count;
                  tempArray.push(val)
                    obj1.label.push(data?.name);
                    obj1.series.push(data?.count);
                    obj1.payload.push(data?.name);
                   
                  
                });
                // ModulePayload=obj2.label;
                setSegmentLabelCount(tempArray)
                setModulePayload(tempArray);
                setModule(obj1);
                setSelectelabel(obj1.label)
              });
            }
          } catch (error) {
            console.log("error");
          }
        }
        setdataLoading(false)
       
      }

  

 

 

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "49%",
      left: "56%",
      transform: "translate(-50%, -50%)",
      width: "69%",
      height: "95%",
      background: "#fff 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderRadius: "12px",
     
    },
  };

  function selectedChartlabelData(label,value){
    console.log("segmentLabelCount",segmentLabelCount)
   
      if (SelectedModulePayload.length === 1 && SelectedModulePayload[0] === value[0]) {
        setModulePayload(segmentLabelCount);
        SelectedModulePayload=[]
        setSelectelabel(module?.label)
      } else {
        let index=module?.label.indexOf(value[0])
        let tempval=segmentLabelCount[index]
        SelectedModulePayload=value;
        setModulePayload([tempval]);
        setSelectelabel(value);
        
      }
      
  
  }

  function updateSegmentData(key, state) {
    if (key === "approved") {
        // ModuleState = "CLOSED";
        seModuleState("CLOSED")
        getAllSegmentData("CLOSED");
       
      } else {
        // ModuleState = "REJECTED";
        seModuleState("REJECTED")
        getAllSegmentData("REJECTED");
        
      }
      setModuleDisplayapproverdefect(state);
  }

 
 

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
        <div className="p-2 expand_module_Segment_container">
            <div className="grid grid-cols-12 flex expand_module_segment_header">
                <div className="col-span-4 header_text">{props?.header}</div>
                 <div className="col-span-7 flex segment_tab justify-end">
                <div
                  className={`mr-1.5 ${
                    displaymoduleapproverdefect
                      ? "selected_tab"
                      : "unselected_tab"
                  }`}
                  onClick={() => {
                    if (displaymoduleapproverdefect === false) {
                      updateSegmentData("approved", true);
                    }
                  }}
                >
                  Approved Defects
                </div>
                <div className="unselected_tab">|</div>
                <div
                  className={`ml-1.5 ${
                    displaymoduleapproverdefect
                      ? "unselected_tab"
                      : "selected_tab"
                  }`}
                  onClick={() => {
                    if (displaymoduleapproverdefect === true) {
                      updateSegmentData("rejected", false);
                    }
                  }}
                >
                  Rejected Defects
                </div>
              </div>
                <div className="col-span-1 flex justify-end mr-1"><CloseIcon onClick={()=>{props?.closeModal(false)}}/></div>
            </div>
            {
              dataLoading===false ? (
                <>
                 <div style={{height:"250px"}} className="flex justify-center">
            <ModulechartExpand
            series={module?.series}
            labels={module?.label}
            selectedChartlabelData={selectedChartlabelData}
            header={props?.header==="Modules"?"module":"location"}
            />
            </div>
            <div className="segment-divider mb-2 mt-3"></div>
            <div>
               
                <ExpandDefectSegmnet
                label={props?.header==="Modules"?"module":"location"}
                data={ModulePayload}
                displayapproverdefect={ModuleState}
                state={props?.displayapproverdefect}
                chartlabels={module}
                selectlabel={selecteLabel}
                />
               

            </div>
                </>
              ):(
                <div className="mt-36">
                  <CircularLoader/>
                </div>
              )
            }
           
        </div>
     
    </Modal>
  );
}
export default DefectExpandSegment;
