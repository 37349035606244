import { Tooltip } from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import TableTree from "../../common/table_tree/table_tree";

const getContainerPillValue = (label, data) => {
  switch (label) {
    case "Module":
      return "M " + data.subModuleCount;
    case "Script":
      return "M " + data.subModuleCount;
    case "Folder":
      return "F " + data.subFolderCount;
    case "Page":
      return "P " + data.subPageCount;
    case "Library":
      return "L " + data.subLibraryCount;
    case "Screen":
      return "S " + data.subPageCount;
    default:
      return "";
  }
};

const TreeModal = (props) => {
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
      // position:"inherit",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "82.5%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
      
    },
  };
  const [openModal, setOpenModal] = useState(true);
  const [selectedNode, setSelectedNode] = useState([]);
  const colDefs = [
    {
      field: "title",
      title: ["Module", "Script"].includes(props.label)
        ? "Module"
        : props.label,
      class: "title",
      isTitle: true,
      width: `calc(100% - 100px)`,
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                <span className="folder-count count-badge">
                  {" "}
                  {getContainerPillValue(props.label, nodeObj.data)}{" "}
                </span>
                {!props.moduleSelection && (
                  <span className="file-count count-badge">
                    {" "}
                    S{nodeObj.data.scriptCount}{" "}
                  </span>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      field: "scriptType",
      title: "Type",
      class: "Type",
      width: "70px",
      render: (nodeObj) => {
        return (
          <span className="text-xs table-non-link-color-common capitalize">
            {nodeObj.data.scriptType?.toLowerCase()}
          </span>
        );
      },
    },
  ];
  const onCheckedNodes = (node) => {
    setSelectedNode([...node]);
  };
  const handleSubmit = () => {
    props.handleNodeSelect(selectedNode);
    setOpenModal(false);
    props.closeModal(false);
  };

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {}}
    >
      {/* <div className="mt-2 p-1">
        <span className="Popup-header-common pl-3">Select {props.label}</span>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div> */}
       <div className="flex justify-between  rounded-tl-xl create-modal-heading h-10 px-2 items-center">
        <div>Select Module</div>
        <span className="flex items-center">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="modal-close-btn mr-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>
      <div>
        <div className=" mt-px mb-px pb-3">
          <div className="mx-6 mt-3">
            {!props.defect ? (
              <div
                className="overflow-auto modal-tree-height"
                id="journal-scroll"
              >
                <TableTree
                  showOnlyFolders={props.moduleSelection ? true : false}
                  defaultDisabled={
                    props.defaultDisabled ? props.defaultDisabled : []
                  }
                  defaultChecked={
                    props.defaultCheckedNode ? props.defaultCheckedNode : []
                  }
                  disableRootRadioButton={
                    props.disableRootRadioButton
                      ? props.disableRootRadioButton
                      : false
                  }
                  id={"radioBtnTree"}
                  data={props.data ? props.data : []}
                  rootData={props.rootData ? props.rootData : []}
                  colDefs={
                    props.moduleSelection ? colDefs.slice(0, 1) : colDefs
                  }
                  filter={""}
                  expandAll={false}
                  hideElements={props.hideElements}
                  hideElementsBtn={props.hideElementsBtn}
                  hideRootRadioBtn={props.hideRootRadioBtn}
                  hideConditions={true}
                  showCheckbox={false}
                  showRadioButton={true}
                  conditions={[
                    { key: "title", value: "Preconditions" },
                    { key: "title", value: "Postconditions" },
                  ]}
                  hideConditionBtn={true}
                  onCheckedNodes={onCheckedNodes}
                  labels={props.labels}
                  onRootNodeClicked={() => {}}
                  onNodeSelected={() => {}}
                  noHeader={true}
                />
              </div>
            ) : props?.data?.length > 0 || props?.rootData?.children ? (
              <div
                className="overflow-auto flex"
                id="journal-scroll"
              >
                <TableTree
                  showOnlyFolders={props.moduleSelection ? true : false}
                  defaultDisabled={
                    props.defaultDisabled ? props.defaultDisabled : []
                  }
                  defaultChecked={
                    props.defaultCheckedNode ? props.defaultCheckedNode : []
                  }
                  disableRootRadioButton={
                    props.disableRootRadioButton
                      ? props.disableRootRadioButton
                      : false
                  }
                  id={"radioBtnTree"}
                  data={props.data ? props.data : []}
                  rootData={props.rootData ? props.rootData : []}
                  colDefs={
                    props.moduleSelection ? colDefs.slice(0, 1) : colDefs
                  }
                  filter={""}
                  expandAll={false}
                  hideElements={props.hideElements}
                  hideElementsBtn={props.hideElementsBtn}
                  hideRootRadioBtn={props.hideRootRadioBtn}
                  hideConditions={true}
                  showCheckbox={false}
                  showRadioButton={true}
                  conditions={[
                    { key: "title", value: "Preconditions" },
                    { key: "title", value: "Postconditions" },
                  ]}
                  hideConditionBtn={true}
                  onCheckedNodes={onCheckedNodes}
                  labels={props.labels}
                  onRootNodeClicked={() => {}}
                  onNodeSelected={() => {}}
                  noHeader={true}
                
                />
              </div>
            ) : (
              <div className="overflow-auto modal-tree-height flex justify-center align-middle fontPoppinsRegularXLg">
                There is no module to select
              </div>
            )}
          </div>
        </div>
        <div className="float-right mt-8 mx-5">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="gray-btn"
          >
            Cancel
          </button>
          <button
            type="button"
            className="primary-btn ml-3"
            onClick={handleSubmit}
            disabled={!(props?.data?.length > 0 || props?.rootData?.children)}
          >
            Select
          </button>
        </div>
      </div>
    </Modal>
  );
};

const DefectTreeWithRadioButton = (props) => {
  console.log("DefectTreeWithRadioButton",props)
  const [treeData, setTreeData] = useState([]);
  const [rootData, setRootData] = useState([]);
  const [selectedNode, setSelectedNode] = useState();
  const [selectedNodeName, setSelectedNodeName] = useState(
    props.operation === "edit"
      ? props.placeholder
      : props.editDefect
      ? props.moduleName
      : props.rootLibrary
      ? "Root Library"
      : ""
  );
  const [selectedNodePath, setSelectedNodePath] = useState("");
  const [isNodeSelected, setIsNodeSelected] = useState(
    props.operation === "edit" || props.editDefect || props.rootLibrary
      ? true
      : false
  );
  const [defaultCheckedNode, setDefaultCheckedNode] = useState(
    props.defaultCheckedNode || []
  );
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [hideRootRadioBtn, setHideRootRadioBtn] = useState(
    props.hideRootRadioBtn ? props.hideRootRadioBtn : false
  );
  const handleNodeSelect = (nodeObj) => {
    if (nodeObj.length) {
      setIsNodeSelected(true);
      setSelectedNode(nodeObj);
      setDefaultCheckedNode([{ key: "key", value: nodeObj[0].data._key }]);
      setSelectedNodeName(nodeObj[0].data.title);
      setSelectedNodePath(nodeObj[0].data.path);
      props.nodeSelected(nodeObj);
    } else {
      setIsNodeSelected(false);
      setSelectedNode(nodeObj);
      setDefaultCheckedNode([]);
      setSelectedNodeName("");
      setSelectedNodePath("");
      props.nodeSelected(nodeObj);
    }
  };
  const handleChooseNode = () => {
    setOpenSelectModal(true);
  };
  const closeModal = (value) => {
    setOpenSelectModal(value);
  };
  function getNameAndIcon() {
    return (
      <div className="table-non-link-color-common font-style-common flex">
        <Tooltip title={selectedNodePath?.split(":")?.shift()} placement="top">
          <p className="ml-2 mr-3 text-overflow-style cursor-pointer widthSet">
            {props.editDefect
              ? selectedNodePath
                ? selectedNodePath?.split("/").pop()?.split(":")?.shift()
                : selectedNodeName?.split("/").pop()?.split(":")?.shift()
              : selectedNodeName}
          </p>
        </Tooltip>

        {props.operation !== "edit" && !props.rootLibrary && (
          <button
            type="button"
            onClick={handleChooseNode}
            disabled={props.operation === "edit"}
            className={`${props.operation === "edit" ? "opacity-50" : ""}`}
          >
            <Tooltip title="Replace" placement="top">
              <Sync color="primary" fontSize="small" />
            </Tooltip>
          </button>
        )}
      </div>
    );
  }

  useEffect(() => {
    if (props.moduleName === "") {
      setIsNodeSelected(false);
    }
    if (props.editDefect) {
      setSelectedNodeName(props.moduleName);
      setSelectedNodePath(props.moduleName);
    }
  }, [props.moduleName, props.editDefect]);

  useEffect(() => {
    const tree = [...props.data];
    if (tree.length) {
      setRootData({ ...tree[0] });
      if (props.individualTree && !tree[0].title?.startsWith("Root")) {
        setTreeData([...tree]);
        setHideRootRadioBtn(true);
      } else {
        if (tree[0].children) {
          setTreeData([...tree[0].children]);
        } else {
          setTreeData([]);
        }
      }
    } else {
      setTreeData([]);
      setRootData([]);
    }
  }, [props.data, props.individualTree]);

  return (
    <div className="py-2">
      {isNodeSelected ? (
        getNameAndIcon()
      ) : (
        <button type="button" className="gray-btn" onClick={handleChooseNode}>
          Choose {props.buttonLabel}
        </button>
      )}
      {openSelectModal && (
        <TreeModal
          moduleSelection={props.moduleSelection}
          label={props.buttonLabel}
          data={treeData}
          rootData={rootData}
          disableRootRadioButton={props.disableRootRadioButton}
          hideRootRadioBtn={hideRootRadioBtn}
          defaultCheckedNode={defaultCheckedNode}
          defaultDisabled={props.defaultDisabled}
          hideElements={props.hideElements}
          hideElementsBtn={props.hideElementsBtn}
          handleNodeSelect={handleNodeSelect}
          closeModal={closeModal}
          moduleLevelScriptCount={props.moduleLevelScriptCount}
          defect={props.defect}
        />
      )}
    </div>
  );
};

export default DefectTreeWithRadioButton;
