import React from 'react'
import {IconButton, InputAdornment, TextField} from '@material-ui/core'

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
function MyPassword(props) {
    const [password,setPassword]=React.useState(false)
    return (
       <TextField type={password?'text':'password'}  fullWidth InputLabelProps={{
        shrink: true,
      }}
     
      InputProps={{
        // disableUnderline:true,
        endAdornment: <InputAdornment position="end">
        <span 
          aria-label="toggle password visibility"
          onClick={()=>setPassword(v=>!v)}
          
        >
       
          {password ? <VisibilityOutlinedIcon style={{marginTop:'8px',marginLeft:'-41px',color:'#727171',cursor:"pointer"}} /> : <VisibilityOffOutlinedIcon style={{color:'#727171',marginLeft:'-41px',marginTop:'8px',cursor:"pointer"}}/>}
        </span>
      </InputAdornment>,
      }}
      {...props}/>
    )
}

export default MyPassword;


