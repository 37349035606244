import { FormHelperText, MenuItem, Select } from "@mui/material";
import style from "./inputs.module.scss";
import PropTypes from "prop-types";
import { Label } from "./myInput";
import { useEffect } from "react";
import cx from "classnames";

function LabeledSelect({
  label,
  required,
  error,
  className,
  inputProps,
  wrapperClassName,
  placeholder,
  options,
  labelField,
  helperText,
  value,
  onChange,
  showLabelError,
  disabledMenuItemFunc,
  ...props
}) {
  useEffect(() => {
    if (!placeholder && !value && options?.length) {
      onChange({ target: { value: options[0] } });
    }
  }, []);

  return (
    <div className={cx(style["select-box-wrapper"], wrapperClassName)}>
      <Label
        label={label}
        required={required}
        error={error}
        showLabelError={showLabelError}
      />
      <Select
    
        displayEmpty
        error={!!error}
        fullWidth
        // variant="standard"
       
        MenuProps={{
          classes: {
            root: style["select-box-menu-list-wrapper"],
            ...props.MenuProps?.classes,
          },
          ...props.MenuProps,
        }}
        value={value || ""}
        onChange={onChange}
        renderValue={(selected) =>
          selected?.[labelField] || (
            <span className="opacity-25">{placeholder}</span>
          )
        }
        {...props}
      >
        {placeholder && (
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
        )}
        {options.map((option, optionIndex) => (
          <MenuItem
            key={`labeled-select-${label}-${optionIndex}`}
            value={option}
            disabled={disabledMenuItemFunc(option)}
            className={cx(style["regularFont"])}
          >
            {option[labelField]}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!error} className={cx(style["regularFont"])}>
        {helperText}
      </FormHelperText>
    </div>
  );
}

LabeledSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  error: PropTypes.any,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  wrapperClassName: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  labelField: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  showLabelError: PropTypes.bool,
  disabledMenuItemFunc: PropTypes.func,
};

LabeledSelect.defaultProps = {
  label: "",
  required: false,
  error: "",
  className: "",
  inputProps: {},
  wrapperClassName: "",
  placeholder: "Select",
  options: [],
  labelField: "label",
  helperText: "",
  showLabelError: false,
  value: null,
  disabledMenuItemFunc: () => false,
};

export default LabeledSelect;
