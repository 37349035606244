import React, { useState, useEffect } from "react";
import "./testcases.scss";
import { Tooltip } from "@material-ui/core";
import { FORM_FIELDS } from "../../pages/config/defectConstant";
import RichTextEditor from "../../pages/config/rich-texteditor";
import { Download } from "@mui/icons-material";
import Grid from "@mui/material/Grid";

import { useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../assets/edit_TestCase.svg";
import { ReactComponent as BackIconTC } from "../../assets/back_TestCase.svg";
import NoRelevantDataIcon from "../../assets/reportsicon/no_reports_icon.png";
import {
  getTestcaseExecutionStatus,
  getTestcaseExecutionStatusForOwner,
} from "../../api/api-services";
function TestCaseDetails(props) {
  const { TEXTBOX, LINK, ATTACHMENT, TEXTEDITOR } = FORM_FIELDS;
  let emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
  const navigate = useNavigate();
  const [testCaseStatus, setTestCaseStatus] = useState("");
  const search = window.location.search;
  const ScriptID = new URLSearchParams(search).get("scriptId");
  let licenseId = JSON.parse(
    localStorage.getItem("selected-project")
  ).licenceID;
  let fireFlinkProjectId = JSON.parse(localStorage.getItem("selected-project"))
    .project.fireFlinkProjectId;
    let edit_action=JSON.parse(localStorage.getItem("user-privilege")).testcases
    .actions.edit;;
    let project_status=JSON.parse(
      localStorage.getItem("selected-project")
    ).projectStatus;

  let testcase = {
    createdBy: "USR5336",
    modifiedBy: "USR5336",
    createdByUname: "Akash",
    modifiedByUname: "Akash",
    createdOn: "01-02-2023 16:56",
    modifiedOn: "01-02-2023 16:56",
    state: "NEW",
    path: "/Root/ModuleCreation/subModuleCreation/Script123",
    searchKey: "/MOD1082/MOD1087/MOD1088/SCR1058",
    parentId: "MOD1088",
    parentName: "subModuleCreation",
    ver: 1.0,
    id: "SCR1058",
    name: "Script123",
    scriptType: "Web",
    desc: "",
    type: "Script",
    testCaseType: "manual",
    templateId: "TEMP1001",
    executionOrder: 2.0,
    stepCount: 0,
    hierarchy: 3,
    preConditionCount: 0,
    postConditionCount: 0,
    dependentScriptCount: 0,
    manualTestCase: {
      testCaseDetails: [
        {
          label: "Test Case Name",
          placeholder: "",
          maxLength: "",
          minLength: "",
          defaultValue: "",
          enable: "no",
          mandatory: "yes",
          type: "textField",
          order: "1",
          value: ["Script123"],
        },
        {
          label: "Test Case Id",
          placeholder: "",
          maxLength: "",
          minLength: "",
          defaultValue: "",
          enable: "yes",
          mandatory: "no",
          type: "textField",
          order: "2",
          value: [],
        },
        {
          label: "Severity",
          options: ["Blocker", "Critical", "Major", "Minor"],
          mandatory: "no",
          type: "dropdown",
          order: "3",
          value: [],
        },
        {
          label: "Created By",
          placeholder: "",
          maxLength: "",
          minLength: "",
          defaultValue: "",
          enable: "yes",
          mandatory: "no",
          type: "textField",
          order: "4",
          value: [],
        },
        {
          label: "Created On",
          dateFormat: "dd-mm-yyyy",
          mandatory: "no",
          type: "date",
          order: "5",
          value: [],
        },
        {
          label: "Test Case Type",
          options: [
            "Functional",
            "Integration",
            "End to End",
            "Regression",
            "Smoke",
            "Acceptance",
          ],
          mandatory: "no",
          type: "dropdown",
          order: "6",
          value: [],
        },
        {
          label: "Reviewed By",
          placeholder: "",
          maxLength: "",
          minLength: "",
          defaultValue: "",
          enable: "yes",
          mandatory: "no",
          type: "textField",
          order: "7",
          value: [],
        },
        {
          label: "Reviewed On",
          dateFormat: "dd-mm-yyyy",
          mandatory: "no",
          type: "date",
          order: "8",
          value: [],
        },
        {
          label: "Brief Description",
          placeholder: "",
          maxLength: "",
          minLength: "",
          defaultValue: "",
          enable: "yes",
          mandatory: "no",
          type: "textArea",
          order: "9",
          value: [],
        },
        {
          label: "Approved By",
          placeholder: "",
          maxLength: "",
          minLength: "",
          defaultValue: "",
          enable: "yes",
          mandatory: "no",
          type: "textField",
          order: "10",
          value: [],
        },
        {
          label: "Approved On",
          dateFormat: "dd-mm-yyyy",
          mandatory: "no",
          type: "date",
          order: "11",
          value: [],
        },
      ],
      testSteps: {
        headers: [
          "slNo",
          "Test Description",
          "Input",
          "Expected Result",
          "Actual Result",
          "Status",
        ],
        rows: [
          {
            slNo: "1",
            "Test Description": "ABC",
            Input: "",
            "Expected Result": "",
            "Actual Result": "",
            Status: "",
          },
          {
            slNo: "2",
            "Test Description": "DEF",
            Input: "",
            "Expected Result": "",
            "Actual Result": "",
            Status: "",
          },
          {
            slNo: "3",
            "Test Description": "GHI",
            Input: "",
            "Expected Result": "",
            "Actual Result": "",
            Status: "",
          },
          {
            slNo: "3",
            "Test Description": "GHI",
            Input: "",
            "Expected Result": "",
            "Actual Result": "",
            Status: "",
          },
          {
            slNo: "3",
            "Test Description": "GHI",
            Input: "",
            "Expected Result": "",
            "Actual Result": "",
            Status: "",
          },
        ],
      },
    },
  };

  useEffect(() => {
    mapKeyAndValue(testcase);
    getTestcaseExecutionState();
  }, []);
  const getTestcaseExecutionState = async () => {
    try {
      let response =
        JSON.parse(localStorage.getItem("selected-user")).role ===
        "PROJECT_OWNER"
          ? await getTestcaseExecutionStatusForOwner(
              fireFlinkProjectId,
              licenseId,
              ScriptID
            )
          : await getTestcaseExecutionStatus(
              fireFlinkProjectId,
              licenseId,
              emailId,
              ScriptID
            );
      console.log(response);
      if (response?.data?.responseCode === 200) {
        setTestCaseStatus(response?.data?.responseObject);
      }
    } catch (error) {
      console.error(error);
    }
  };
  function mapKeyAndValue(testcase) {
    let table = JSON.parse(JSON.stringify(testcase.manualTestCase.testSteps));
    console.log("tables", table);
  }
 
  const TestStepsTable = (props) => {
    let [tableRowData, setTableRowData] = useState(props.tableData.rows);

    return (
      <>
        <table className="testStepTable  w-full">
          <thead
            style={{
              display: "table-header-group",
              verticalAlign: "middle",
              position: "sticky",
              top: "-1px",
              zIndex: "1",
            }}
            className="bg-gray-100"
          >
            <tr key={"tableHeader"}>
              {props.tableData?.headers?.map((data, index) => {
                return (
                  !["actual result", "status"].includes(
                    data?.toLowerCase()
                  ) && (
                    <>
                      <th
                        className="p-2 testStepTable common-label-text fontPoppinsMediumMd text-blue-700"
                        key={data + index}
                      >
                        {data}{" "}
                      </th>
                    </>
                  )
                );
              })}
            </tr>
          </thead>
          <tbody
            style={{
              height: "auto",
              overflowY: "auto",
              display: " table-row-group",
              verticalAlign: "middle",
            }}
          >
            {
              tableRowData?.length>0?(
                <>
                 {tableRowData?.map((rowData, index) => (
              <tr
                style={{ display: " table-row", verticalAlign: "inherit" }}
                className={`h-6 testStepTable ${
                  (index + 1) % 2 === 0
                    ? "test-case-table-even-row"
                    : "test-case-table-odd-row"
                }`}
                key={index}
              >
                {props.tableData?.headers?.map((cellname, colIndex) => {
                  return (
                    !["actual result", "status"].includes(
                      cellname?.toLowerCase()
                    ) && (
                      <>
                        <td
                          className="testStepTable fontPoppinsRegularSm text-left pl-2 table-cell-text focus:border-opacity-0 focus:outline-none"
                          key={index + colIndex}
                          suppressContentEditableWarning={true}
                        >
                          {Array.isArray(rowData[cellname])
                            ? rowData[cellname].map((data) => (
                                <>
                                  {data}
                                  <br />
                                </>
                              ))
                            : rowData[cellname]}{" "}
                        </td>
                      </>
                    )
                  );
                })}
              </tr>
            ))}
                </>
              ):(
                <>
                {
                  !props?.dataloading && (
                    <div className="no_relevant_data">
                   <img  alt="warning" src={NoRelevantDataIcon}/>
                <div className="text_msg">No Test Steps  Found !</div>

                </div>
                  )
                }
                </>
                
                
               
                
              )
            }
           
          </tbody>
        </table>
      </>
    );
  };

  const detailViewComponents = (data) => {
    let value;

    if (data?.type !== ATTACHMENT) {
      value =
        data?.value && Array.isArray(data.value)
          ? data.value.join(",")
          : data.value;
    } else if (data.type === LINK && value) {
      return (
        <>
          <a
            href={value}
            target="_blank"
            className="label-overflow tab-style-common"
          >
            {value?value:"--"}
          </a>
        </>
      );
    } else if (
      data.type === ATTACHMENT &&
      data?.value &&
      Array.isArray(data?.value)
    ) {
      return (
        <div>
          {data?.value?.length === 0 || data?.value === "" ? (
            "--"
          ) : (
            <>
              {data?.value?.map((option, index) => (
                <div key={index} className="flex my-2" title={option?.name}>
                  <div className="datatrimmer w-24 cursor-default	">
                    {option?.name || "--"}
                  </div>
                  <Download
                    color="primary"
                    className="h-6 w-6  -mt-2 mr-2 origin-center cursor-pointer"
                  />
                </div>
              ))}
            </>
          )}
        </div>
      );
    } else if (data.type === TEXTEDITOR) {
      return (
        <div className="mt-2 text-justify defect-texteditor">
          {value !== "" ? (
            <RichTextEditor
              convertedContent={value || ""}
              readOnly={true}
              toolbarHidden={true}
            />
          ) : (
            "--"
          )}
        </div>
      );
    } else {
      ///
    }

    return (
      <>
        {value !== "--" && value?.length > 30 ? (
          <Tooltip
            title={value !== "--" && value?.length > 30 && value}
            placement="top"
          >
            <label className="label-overflow cursor-pointer text-justify">
              {value || "--"}
            </label>
          </Tooltip>
        ) :!value && data.label==="Test Case Name"?<> <label className="label-overflow cursor-pointer text-justify">
             {props.testcaseDetailsById.name}
            </label></> :!value && data.label==="Module/Path"?<> <label className="label-overflow cursor-pointer text-justify">
            {props.testcaseDetailsById.parentName}
            </label></>: (
          value !== undefined ? <label 
          
          className={`label-overflow text-justify ${
            data?.label === "Status"
              ? data?.value.toUpperCase() === "PASS"
                ? "text-green-700"
                : data?.value.toUpperCase() === "FAIL"
                ? "text-red-700"
                : "text-orange-500"
              : ""
          }`}
          
          >{data.label==="Test Case Name"? props.testcaseDetailsById.name : value || "--"}</label>:"--"
        )}
      </>
    );
  };
  const detailViewElements = (data) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="flex ">
              <label>
                {data ? (
                  <Tooltip title={data?.label} placement="top">
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap labels">
                      {data?.label.length > 32
                        ? `${data?.label.substring(0, 32)}...`.toUpperCase()
                        : data?.label.toUpperCase()}
                    </div>
                  </Tooltip>
                ) : null}
              </label>
            </div>
          </div>
          <div
            className={`contentDataStyle  values ${
              data?.value?.length > 32 ? "datatrimmer w-34" : ""
            }`}
          >
            {detailViewComponents(data)}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="test_case_details" >
        <div className="testcasedetails_container " style={{ height: "auto" }}>
          <div className="flex">
            <Grid
              container
              spacing={{ xs: 2, md: 3, sm: 2 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {props.detailsViewData &&
                props.detailsViewData.length > 0 &&
                props.detailsViewData.map((data, index) => {
                  return [TEXTBOX, TEXTEDITOR].includes(data?.type) ? (
                    <Grid item xs={11} sm={11} md={11} key={index}>
                      {detailViewElements(data)}
                    </Grid>
                  ) : (
                    <Grid item xs={4} sm={4} md={12 / 3} key={index}>
                      {detailViewElements(data)}
                    </Grid>
                  );
                })}
              {props.detailsViewData && 
              props.detailsViewData.length > 0 && <Grid item xs={4} sm={4} md={12/12}>
                {detailViewElements({
                  label: "Status",
                  value: testCaseStatus,
                })}
              </Grid>}
            </Grid>
            <div className="flex absolute top-12 right-2.5">
              <div className="col-span-1 close_btn cursor-pointer">
              <Tooltip title={"Back"}>
                <div className="">
                  <BackIconTC
                    height="35px"
                    width="90px"
                    onClick={() => {
                      navigate("/project/testcases");
                    }}
                  />
                </div>
                </Tooltip>
              </div>
              {
                edit_action && (
                  <Tooltip title={(edit_action===false||(project_status==="INPROGRESS" && props?.testcaseDetailsById?.assignee))?"Cannot be edit":"Edit"}>
                  <div
                    className={`ml-auto col-span-2 ${(edit_action===false?"opacity-50":(project_status==="INPROGRESS" && props?.testcaseDetailsById?.assignee))?"opacity-50":""}`}
                    style={{ position: "relative", left: "10px" }}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        if(edit_action && (project_status==="APPROVED"||project_status==="INPROGRESS")){
                          if(project_status==="APPROVED"){
                            props.setEditMode(true);
                          }else{
                            if(project_status==="INPROGRESS" && !props?.testcaseDetailsById?.assignee){
                              props.setEditMode(true);
                            }
                          }
                         
                        }
                        
                      }}
                      className="py-0.5 px-3.5 "
                    >
                      <EditIcon />
                    </button>
                  </div>
                  </Tooltip>
                )
              }
             

            </div>
          </div>
          {!props.loading && (
            <div
              style={{ maxHeight: "410px", overflowY: "auto" }}
              className="mt-10"
            >
              <TestStepsTable
                tableData={props.templateTableData}
                dataloading={props?.loading}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default TestCaseDetails;
