import React from "react";
// import LogotextImage from "../../assets/fire_crowd.svg";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import BackgroundDown from "../../assets/login_logo.png";
import style from "./signup.module.scss";
import cx from "classnames";
import righticon from "../../assets/right-img-circle.svg";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function ResetPassword() {
  const PASSWORD_SUC_MSG1="Sent email successfully to reset the password."
const PASSWORD_SUC_MSG2="Check your email and click on the link sent to continue"
const SUC_MSG="Successfully Sent Email"
  return (
    <div className="grid grid-cols-12 min-h-screen ">
      <div className={cx(style["mail_send_activation_link"], "col-span-6 border-r-4")}>
        <div
          className={cx(style["mail_send_activation_link_leftImage"],"grid grid-cols-span-12 col-span-6 flex justify-center")}
          id="leftImage"
        >
          <div className={cx(style["leftImage"])}>
            <img
            className={cx(style["logo_Icon"])}
              src={LogotextImage}
              height="200px"
              width="300px"
              alt="Qspider"
            />
          </div>
        </div>
        <div className="grid grid-cols-span-12 col-span-6 flex justify-center mt-5">
          <div>
            <img
              height="350px"
              width="350px"
              src={BackgroundDown}
              alt="Firecrowd"
            />
          </div>
        </div>
        <div
          className={cx(
            style["heading"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-5"
          )}
        >
          <div>Simply Verify</div>
        </div>
        <div
          className={cx(
            style["msg"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-2"
          )}
        >
          <span>Follow the quick verification process, and you all set</span>
        </div>
        <div
          className={cx(
            style["msg"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-1"
          )}
        >
          <span>to use your account</span>
        </div>
      </div>
      <div className={cx(style["reset-password-page"], "col-span-6 pt-28")}>
      <div className={cx(style["logoimg"])}>
        <img
          src={LogotextImage}
          height="200px"
          width="300px"
          alt="Qspider"
        />
        </div>
        <div className={cx(style["righticon"],"grid grid-cols-span-12 col-span-6 flex justify-center mt-8")}>
          <div className="pt-1.5">
            <img alt="warning" src={righticon} />
          </div>
          <div className={cx(style["succcess_msg"] ,"ml-2")}>{SUC_MSG}</div>
        </div>
        {/* <div
          className={cx(
            style["heading"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-4"
          )}
        >
          <div>Email Sent</div>
        </div> */}
         <div
          className={cx(
            style["msg_one"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-2"
          )}
        >
          <span>{PASSWORD_SUC_MSG1}</span>
        </div>
        <div
          className={cx(
            style["msg"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-2"
          )}
        >
          <span>{PASSWORD_SUC_MSG2}</span>
        </div>
        <div
          className={cx(
            style["Back_link_text"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-4"
          )}
        >
          <div className="flex">
            <KeyboardBackspaceIcon sx={{ fontSize: 12,height:"17px" }}/>
            <div className="ml-1">
            <span>
            <a href="/">Back to sign in</a>
          </span>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
}
export default ResetPassword;
