import { useState, useEffect } from "react";
import React from "react";
import Modal from "react-modal";
import warningIcon from "../../assets/toast-warning.svg";
import "./modal.scss"
import { yellow } from "@mui/material/colors";


function WarningPopup(props) {
  const [openModal, setOpenModal] = useState(true);
  const customStylesPage = {
    overlay: {
      background:"rgba(90, 90, 90,0.5)", 
      
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      // height: "33%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      // borderRadius: "4px",
      opacity: "1",
      outline:"none",
      borderTop:"4px solid #FFC600"
    },
  };
  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      // onRequestClose={() => {
      //   props.closeModal(false);
      //   setOpenModal(false);
      // }}
    >
      <div className="grid grid-cols-12">
        <div className="grid grid-cols-12 col-span-12 warning-popup py-2">
          <div className="col-span-2 warning-icon">
          <div><img alt="warning" src={warningIcon} /></div>
          </div>
          <div className="grid grid-cols-12 col-span-10 pl-7 my-2">
            <div className="col-span-12">
              <span className="warning_text">warning!</span>{" "}
              <span className="delete_text">{props?.mode==="Delete project"?<>Delete</>:<>Status Changes</>} </span>
            </div>
            <div className="col-span-12 py-1">
              <span className="message">Hi {JSON.parse(localStorage["fire-crowd-user"]).name}</span>
              
            </div>
            <div className="col-span-12">
              {
                props?.mode==="Delete project"?(
                  <>
                  <span className="message">Your data will be lost,Are you sure you want to delete <span className="project_name">{props?.project?.project?.projectName}</span> project?</span>{" "}
                  </>
                ):(
                  <>
                  <span className="message">Are you sure you want to change  project status from <span className="project_name">{props?.fromstatus}</span> to <span className="project_name">{props?.tostatus}</span></span>{" "}
                  </>
                )
              }
              
              
            </div>
            <div className="col-span-12"><span className="message">How do you want to proceed?</span></div>
            
          </div>

        </div>
      
        <div className="grid grid-cols-12 col-span-12 warning_popup_btn  pt-2">
          <div className="col-span-8"></div>
         <div className="grid grid-cols-12 col-span-4 flex justify-center">
          <div className="grid grid-cols-12 col-span-6 h-8 ">
            <span className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer" onClick={()=>{props.onCloseWarningPopup(false)}}><button>Cancel</button></span>
            </div>
          <div className="grid grid-cols-12 col-span-6 h-8 ">
            {
              props?.mode==="Delete project"?(
                <span className="btn_back col-span-10 rounded-sm flex justify-center cursor-pointer"     onClick={()=>{props?.onCloseWarningPopup(false);props?.deleteProject(props.project)}}><button>Delete</button></span>
              ):(<span className="btn_back col-span-10 rounded-sm flex justify-center cursor-pointer" onClick={()=>{props?.updateProjectStatus()}}><button>Change</button></span>)
            }
            
           
            </div>
         </div>

        </div>
        
      </div>
    </Modal>
  );
}
export default WarningPopup;
