import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as Search } from "../../../assets/search.svg";
import "./reports.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SegmentTable from "./segment_table";
import { useTable, useGlobalFilter } from "react-table";
import {
  getDefectsData,
  getUserDetailsByEmailID,
  getTemplateById,
} from "../../../api/api-services";
import DefectsDeatilsPopup from "../../modal/defects-deatils-popup";
import UserDeatilsPopup from "../../modal/user-deatils";
import NoDefectIcon from "../../../assets/reportsicon/no_reports_icon.png";

let webSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
};
let mobileSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
  deviceBrand: null,
  deviceName: null,
  deviceType: null,
};

function ModuleTable(props) {
  console.log(" ModuleTable",props)
  let [expand, setExpand] = useState(false);
  let [pageNo, setPageNo] = useState(0);
  let [lastPage, setLastPage] = useState(false);
  let [defectsPopUp, setDefectsPopUp] = useState(false);
  let [defectsdetails, setDefectsdetails] = useState();
  let [userDeatils, setusererDeatils] = useState();
  let [userPopup, setUserPopup] = useState(false);
  let [defectTemplatedetails, setDefectTempalteDetails] = useState();
  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  let [defect, setDefect] = useState([]);
  let [Defectstate,setDefectState]=useState();
  let [dataLoading,setDataloading]=useState()

  useEffect(()=>{
    setDefectState(props?.state)
    setExpand(false)
  },[props])

  const getAllModuleData = async (payload, pageno, state) => {
    if(pageno===0){
      setDataloading(true)
    }
   
   
    if (props?.label === "module") {
      let lastindex=payload.lastIndexOf("-")
      let temppayload=payload.substring(0, lastindex);
      let segmentSearchFilters = {
        severity: null,
        priority: null,
        modules: [temppayload],
        locations: null,
        createdOn: null,
      };

      let filterdata = {
        segmentSearchFilters: segmentSearchFilters,
        webSearchFilters: webSearchFilters,
        mobileSearchFilters: mobileSearchFilters,
      };
      let page = pageno,
        size = 10;
      let tempArray = [];
   
      try {
        let res = await getDefectsData(
          projectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
          setLastPage(res?.data?.lastPage);
          setPageNo(page + 1);

          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
            setDefect([...tempArray]);
            // getAllDefectsCount(filterdata, state, "severity");
          } else {
            let defectArray = [];
            defectArray = defect.concat(tempArray);
            setDefect(defectArray);
          }
        }else{
          setDefect([])
        }
      } catch (error) {
        console.log("Error");
      }
      
    } else {
      let temppayload=payload.split(/[-]/)[0];
      let segmentSearchFilters = {
        severity: null,
        priority: null,
        modules: null,
        locations: [temppayload],
        createdOn: null,
      };

      let filterdata = {
        segmentSearchFilters: segmentSearchFilters,
        webSearchFilters: webSearchFilters,
        mobileSearchFilters: mobileSearchFilters,
      };
      let page = pageno,
        size = 10;
      let tempArray = [];
      try {
        let res = await getDefectsData(
          projectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
          setLastPage(res?.data?.lastPage);
          setPageNo(page + 1);

          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
            setDefect([...tempArray]);
            // getAllDefectsCount(filterdata, state, "severity");
          } else {
            let defectArray = [];
            defectArray = defect.concat(tempArray);
            setDefect(defectArray);
          }
        }else{
          setDefect([])
        }
      } catch (error) {
        console.log("Error");
      }
      
    }
    setDataloading(false)
  };

  const handlescroll = (event) => {
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
      console.log("module handlescroll");

      if (lastPage === false) {
        getAllModuleData(props?.data, pageNo, Defectstate);
      }
    }
  };
  const getUserDetails = async (e) => {
    console.log("get user deatil welcome");
    let emailId = e.Details.defectDetails.history[0].userEmail;
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    try {
      let response = await getUserDetailsByEmailID(projectId, emailId);
      if (response?.status === 200) {
        setusererDeatils(response.data);
        setUserPopup(true);
      }
      console.log("getUserDetails", response);
    } catch (err) {
      console.log("error");
    }
  };

  const getTemplateDetails = async (e) => {
    console.log("eeee", e.defectTemplateId);
    console.log("eeee Details", e);
    let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])
      .project.fireFlinkProjectId;
    let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
    let templateID = e.defectTemplateId;
    try {
      let response = await getTemplateById(
        fireFlinkProjectId,
        templateID,
        licenseId
      );
      if (response.data.responseCode === 200) {
        setDefectTempalteDetails(response);
        setDefectsdetails(e);
        setDefectsPopUp(true);
      } else {
        props.MyAlert.info("Something went wrong");
      }
    } catch (error) {
      console.log("errror");
    }
  };
  function CloseDefectModal(state) {
    setDefectsPopUp(state);
  }

  function CloseTesterModal(state) {
    setUserPopup(state);
  }

  let data = React.useMemo(() => defect, [defect]);
  let columns = React.useMemo(
    () => [
      {
        Header: "BUG ID",
        accessor: "bugId",
        width: "12%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              getTemplateDetails(e.cell.row.original.Details);
            }}
            className="reportTablecell popins cursor-pointer	datatrimmer"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "BUG SUMMARY",
        accessor: "bugSummary",
        width: "47.7%",
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },

      {
        Header: "CREATED BY",
        accessor: "createdBy",
        width: "12.3%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();

              getUserDetails(e.cell.row.original);
            }}
            className="reportTablecell popins cursor-pointer	datatrimmer w-16"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  function gettable() {
    return (
      <div className="segment_table">
       
        <div className="segment_table_container mt-2">
          <table {...getTableProps()}>
            <thead className="">
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="table_headers"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      width={column.width}
                      {...column.getHeaderProps()}
                      className={`reportTableheader popins text-lg font-semibold ${
                        column.Header === "BUG ID"
                          ? "text-left pl-3"
                          : "text-left pl-3"
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {
              dataLoading===false && (
                <tbody
                {...getTableBodyProps()}
                className="segment_table_body"
                onScroll={handlescroll}
              >
                {
                   rows.length === 0 || data?.length === 0 ? (
                    <div className="segment_noreportIcon">
                      <img  alt="warning" src={NoDefectIcon}/>
                      <div className="text_msg">No Relevant Data Is Found!</div>
                    </div>):(
                  <>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="h-8">
                          {row.cells.map((cell) => {
                            return (
                              <td
                                width={cell?.column?.width}
                                {...cell.getCellProps()}
                                className={`datatrimmer reportTablecell ${
                                  cell.column.Header === "BUG ID" 
                                  // ||cell.column.Header === "CREATED BY"
                                    ? "popins"
                                    : "popins"
                                } ${
                                  cell.column.Header === "BUG ID"
                                    ? "text-left pl-3"
                                    : "text-left pl-3"
                                }`}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                ) 
                }
                 
                 
              
              </tbody>
              )
            }

           
          </table>
        </div>
        {defectsPopUp && (
          <DefectsDeatilsPopup
            CloseDefectModal={CloseDefectModal}
            SelectedDefect={defectsdetails}
            defectTemplatedetails={defectTemplatedetails}
          />
        )}
        {userPopup && (
          <UserDeatilsPopup
            CloseTesterModal={CloseTesterModal}
            testerDeatils={userDeatils}
          />
        )}
      </div>
    );
  }
  return (
    <div className="module_segment_container">
      <div className="mt-2 mb-2">
        <div className="flex grid grid-cols-12 module_segment_header cursor-pointer" onClick={()=>{if(expand){
           setExpand(!expand);
           props?.downloadFilePayload(props?.label,"remove",props?.data);
        }else{
          setExpand(!expand);
          props?.downloadFilePayload(props?.label,"add",props?.data);
          getAllModuleData(props?.data,0, Defectstate);
        }}}>
          {
            props?.label==="location"?(
              <div className="col-span-11 module_name">{(props?.data.split(/[-]/)[props?.data.split(/[-]/).length-2])} - { (props?.data.split(/[-]/)[props?.data.split(/[-]/).length-1])}</div>
            ):(
              <div className="col-span-11 module_name">{(props?.data.split(/[/,:]/)[props?.data.split(/[/,:]/).length-2])} - { (props?.data.split(/[-]/)[props?.data.split(/[-]/).length-1])}</div>
            )
          }
         
          {expand ? (
            <div
              className="col-span-1 cursor-pointer"
              // onClick={() => {
              //   setExpand(!expand);
              //   props?.downloadFilePayload(props?.label,"remove",props?.data);
              // }}
            >
               <KeyboardArrowUpIcon />
             
            </div>
          ) : (
            <div
              className="col-span-1 cursor-pointer"
              // onClick={() => {
              //   setExpand(!expand);
              //   props?.downloadFilePayload(props?.label,"add",props?.data);
              //   getAllModuleData(props?.data,0, Defectstate);
              // }}
            >
              <ExpandMoreIcon />
            </div>
          )}
        </div>
        <div
          className={
            expand ? "testcase_details block" : " testcase_details hidden"
          }
        >
          {gettable()}
        </div>
      </div>
    </div>
  );
}
export default ModuleTable;
