/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";

import $ from "jquery";
import "jquery.fancytree/dist/modules/jquery.fancytree.filter";
import "./skin/ui.fancytree.css";
import "./table_tree.scss";

import { Checkbox, Radio } from "@material-ui/core";

import {
  ExpandAllIcon,
  CollapseAllIcon,
  ShowAllChildsIcon,
  HideAllChildsIcon,
  ShowAllConditionIcon,
  HideAllConditionIcon,
} from "./table_tree_icons";
import { CommonLoader } from "../common-loader";

const GLOBAL = {
  SHOW_ALL_CHILD: true,
  SHOW_ALL_CONDITIONS: true,
  LABELS: {
    EXPAND_ALL: "Expand All",
    COLLAPSE_ALL: "Collapse All",
    SHOW_ALL_ELEMENTS: "Show all elements",
    HIDE_ALL_ELEMENTS: "Hide all elements",
    SHOW_PRE_POST_CONDITION: "Show Pre/Post Conditions",
    HIDE_PRE_POST_CONDITION: "Hide Pre/Post Conditions",
    NO_DATA_MSG: "No data found.",
  },
};

const TableTree = (props) => {
  function initSimpleTree(sourceData) {
    $(`#${treeTableId} .no-data-msg`).hide();
    const showCheckbox = props.showCheckbox ? props.showCheckbox : false;
    const showRadioButton = props.showRadioButton
      ? props.showRadioButton
      : false;
    if (sourceData) {
      $(`#${treeTableId}`).fancytree({
        autoScroll: false,
        selectMode: showRadioButton ? 1 : 3,
        source: sourceData,
        checkbox: showRadioButton ? "radio" : showCheckbox,
        expanded: true,
        extensions: ["filter"],
        filter: {
          counter: false,
          mode: "hide",
          highlight: false,
        },
        table: {
          indentation: 20,
          nodeColumnIdx: 0,
        },
        click: function (event, data) {
          let targetType = $.ui.fancytree.getEventTargetType(
            event.originalEvent
          );
          if (targetType === "title" && !data.node.data.isMockNode) {
            props.onNodeSelected({
              data: {
                ...data.node.data,
                _key: data.node.key,
                title: data.node.title,
                folder: data.node.folder,
              },
              node: data.node,
            });
          }
        },
        select: function (event, data) {
          const node = data.node;
          const selectedNodes = [];
          const totalNodes = $.ui.fancytree.getTree(`#${treeTableId}`).count();
          const nodes = $.ui.fancytree
            .getTree(`#${treeTableId}`)
            .getSelectedNodes();
          if (
            node.children &&
            data.node.children.length === 1 &&
            node.children[0].data.isMockNode
          ) {
            //when module is lazyload and not loaded with children
            if (node.type === "PRE" || node.type === "POST") {
              //for pre/post condition expand at root level
              expandContainer(node, true, node.data.parentId, node.type);
            } else {
              //for module expand at root level
              expandContainer(node, true);
            }
          }
          // Root node radio button status
          setIsSelectRootRB(false);
          // Root node checkbox status
          setIsCheckAllInterminate(false);
          setIsCheckAll(totalNodes === nodes.length);

          // Partial check status
          if (nodes.length && totalNodes !== nodes.length) {
            setIsCheckAllInterminate(true);
          }

          nodes.map((node) => {
            if (node.checkbox) {
              selectedNodes.push({
                data: {
                  ...node.data,
                  key: node.key,
                  _key: node.key,
                  title: node.title,
                  folder: node.folder,
                },
                node: node,
              });
            } else if (treeTableId === "moduleTreeTD") {
              selectedNodes.push({
                data: {
                  ...node.data,
                  key: node.key,
                  _key: node.key,
                  title: node.title,
                  folder: node.folder,
                },
                node: node,
              });
            }
          });
          props.onCheckedNodes(selectedNodes, node);
        },
        dblclick: function (e, data) {
          e.preventDefault();
          e.stopPropagation();
        },
        init: function () {},
        createNode: function (event, data) {
          const node = data.node;
          if (node.statusNodeType === "nodata") {
            $(`#${treeTableId}`).html("");
            $(`#${treeTableId}`).show();
            return;
          }
          const nodeData = {
            data: {
              ...node.data,
              _key: node.key,
              title: node.title,
              folder: node.folder,
              type: node.type,
            },
            node: node,
          };
          const _liNode = node.span;

          const hideCBConditions = props.hideCheckboxConditions;

          props.colDefs.map((column, index) => {
            // Render Title column logic
            if (index === 0) {
              const _node = $(node.span);
              _node.addClass(
                node.folder ? "tree-folder-node" : "tree-not-folder-node"
              );

              _node.addClass(`type-${node.type}`);

              if (showCheckbox) {
                node.checkbox = true;
              }
              if (showRadioButton) {
                node.checkbox = "radio";
              }
              if (
                hideCBConditions &&
                hideCBConditions.key &&
                node[hideCBConditions.key] === hideCBConditions.value
              ) {
                node.checkbox = false;
                _node.find(".fancytree-checkbox").remove();
              }
              // Move title related items into tree-title container
              const _nodeHtml = _node.html();
              _node.html("");
              _node.append(`<span class="tree-title">${_nodeHtml}</span>`);

              const _span = $(node.span).find(".fancytree-title");
              if (column.render) {
                let title = _span.text();
                if (props.titleKey && data.node.folder === false) {
                  title = node.data[props.titleKey];
                }
                _span.text("");
                _span.append(
                  `<span class="fancytree-title datatrimmer" title="${title}">${title}</span>`
                );
                _span.append(`<span class="node-title-render"></span>`);
                _span
                  .removeClass("fancytree-title")
                  .addClass("fancytree-title-wrapper");
                if (node.data.isMockNode) {
                  _span.addClass("fancytree-mock-node-wrapper");
                }
                const _titleRender = _span.find(".node-title-render")[0];
                const el = React.createElement(column.render, nodeData);
                ReactDOM.render(el, _titleRender);
              }
            }

            // Render other columns logic
            if (index > 0) {
              $(_liNode).append(
                `<span class="node-col fontPoppinsRegularMd ${
                  column.class
                }" style="width: ${
                  node.folder && column.field === "scriptType"
                    ? "0px"
                    : column.width
                }">${index}</span>`
              );
              const _colRenderer = $(_liNode).find(".node-col")[index - 1];
              if (column.render) {
                const el = React.createElement(column.render, nodeData);
                ReactDOM.render(el, _colRenderer);
              } else {
                $(_colRenderer).html(node.data[column.field]);
              }
            }
          });
        },
        beforeExpand: function (event, data) {
          const node = data.node;
          const _firstChild = node.getFirstChild();
          if (
            node.folder &&
            node.children.length === 1 &&
            _firstChild &&
            _firstChild.data.isMockNode
          ) {
            //if the node is lazy(custom) node
            _firstChild.checkbox = false;
          }
          if (
            props.hasOwnProperty("loadMoreData") &&
            !isExpandAllDataRef.current
          ) {
            if (!node.data.hasOwnProperty("lazyLoaded")) {
              //lazy data not loaded
              if (!isChildOfLazyLoaded(node)) {
                const _nodeChildren = node.getChildren();
                if (node.type === "PRE" || node.type === "POST") {
                  //for pre/post condition expand at root level
                  if (_nodeChildren.length <= node.data.scriptCount) {
                    expandContainer(node, false, node.data.parentId, node.type);
                    node.data["lazyLoaded"] = true;
                  }
                } else {
                  //for module expand at root level
                  if (
                    _firstChild.data.isMockNode &&
                    _firstChild.data.isSharedElement
                  ) {
                    //if child is mockNode and of page tree
                    expandContainer(node);
                    node.data["lazyLoaded"] = true;
                  } else {
                    if (
                      props.hasOwnProperty("childrenCountKeysArray") &&
                      _nodeChildren.length <= getTotalChildrenCount(node)
                    ) {
                      //for other trees
                      expandContainer(node);
                      node.data["lazyLoaded"] = true;
                    }
                  }
                }
              } else {
                node.data["lazyLoaded"] = true; // if the node is child of lazyLoaded node, then update child node too with lazyLoaded flag
              }
            }
          }
        },
        expand: function (event, data) {
          let persistData = [];
          const localData = localStorage.getItem(`${treeTableId}expanded`);
          if (localData) {
            persistData = localData.split("~");
            persistData.push(data?.node?.key);
            persistData = [...new Set(persistData)];
            localStorage.setItem(
              `${treeTableId}expanded`,
              persistData.join("~")
            );
          } else {
            persistData.push(data?.node?.key);
            localStorage.setItem(
              `${treeTableId}expanded`,
              persistData.join("~")
            );
          }
          showHideAllChild(GLOBAL.SHOW_ALL_CHILD);
          showHideAllConditions(GLOBAL.SHOW_ALL_CONDITIONS);
        },
        collapse: function (event, data) {
          let persistData = [];
          const localData = localStorage.getItem(`${treeTableId}expanded`);
          if (localData) {
            persistData = localData.split("~");
            persistData = [...new Set(persistData)];
            const index = persistData.indexOf(data?.node?.key);
            if (index > -1) {
              persistData.splice(index, 1);
            }
            localStorage.setItem(
              `${treeTableId}expanded`,
              persistData.join("~")
            );
          }
        },
        renderColumns: function (event, data) {},
        create: function (event, data) {},
      });
    }

    $(".fancytree-container").addClass("fancytree-connectors");

    // Events for fancytree-expander - hightlight lines on hover
    $(".fancytree-container").on(
      "mouseenter",
      ".fancytree-expander",
      function (e) {
        e.preventDefault();
        $(this).closest("ul").find("> li").addClass("highlight-lines");
      }
    );

    $(".fancytree-container").on(
      "mouseleave",
      ".fancytree-expander",
      function (e) {
        e.preventDefault();
        $(this).closest("ul").find("> li").removeClass("highlight-lines");
      }
    );
  }

  const [isExpandAll, setIsExpandAll] = useState(false);
  const [isShowAllChild, setIsShowAllChild] = useState(true);
  const [isShowAllConditions, setIsAllConditions] = useState(true);
  const [isCheckAll, setIsCheckAll] = useState(
    props?.rootData?.selected || false
  );
  const [isCheckAllIndeterminate, setIsCheckAllInterminate] = useState(
    props?.rootData?.partsel || false
  );
  const [isSelectRootRB, setIsSelectRootRB] = useState(false);
  const [treeTableId, setTreeTableId] = useState(
    props?.id ? props?.id : "table-tree"
  );
  const [disableExpandCollapse, setDisableExpandCollapse] = useState(false);
  const [callExpandApi, setCallExpandApi] = useState(true);
  const [isExpandAllData, setIsExpandAllData] = useState(false);
  const isExpandAllDataRef = useRef({});
  isExpandAllDataRef.current = isExpandAllData;
  const [treeLoading, setTreeLoading] = useState(false);
  const filterTreeData = (query) => {
    $.ui.fancytree.getTree(`#${treeTableId}`).filterNodes(query, {
      autoExpand: true,
    });
  };

  const getTotalChildrenCount = (node) => {
    let _count = 0;
    props.childrenCountKeysArray.forEach((_key) => {
      if (node.data.hasOwnProperty(_key)) {
        _count += node.data[_key];
      }
    });
    return _count;
  };
  /**
   * Method to check if the given node is child of a lazy loaded parent
   * @param {*} node
   * @returns boolean
   */
  const isChildOfLazyLoaded = (node) => {
    let _node = node;
    while (_node && _node.parent) {
      if (_node.parent.data.hasOwnProperty("lazyLoaded")) {
        return true;
      } else {
        _node = _node.parent;
      }
    }
    return false;
  };
  /**
   *
   * @param {*} node
   * @param {*} selectNode Boolean value | true: node is selected
   * @param {*} rootMod_id | on root level Pre/Post condition expand | for root level condition lazy load
   * @param {*} conditionType | type of the condition container | for root level condition lazy load
   * @returns
   */
  const expandContainer = (node, selectNode, rootMod_id, conditionType) => {
    if (props.hasOwnProperty("loadMoreData")) {
      const _li = $(node.li);
      _li.addClass("expand-data-loading"); //to have lazy loading icon on module expand api call
      props.loadMoreData(node.key, rootMod_id, conditionType).then((res) => {
        if (res && res.length) {
          node.removeChildren();
          node.data["lazyLoaded"] = true;
          node.expanded = true;
          node.addChildren(res);
          showHideAllConditions(GLOBAL.SHOW_ALL_CONDITIONS);
          _li.removeClass("expand-data-loading");
          if (props.defaultDisabled && props.defaultDisabled.length) {
            defaultDisableNodes(props.defaultDisabled);
          }
          if (selectNode) {
            node.setSelected(true);
            node.visit(function (_childNode) {
              _childNode.setSelected(true);
            });
          }
          return res;
        } else {
          //if no response/network call fails
          _li.removeClass("expand-data-loading");
          if (node.data.hasOwnProperty("lazyLoaded")) {
            delete node.data.lazyLoaded;
          }
          node.setExpanded(false);
          node.setSelected(false);
          node.visit(function (_childNode) {
            _childNode.setSelected(false);
          });
        }
      });
    }
    return false;
  };
  const expandCollapseAll = (isExpand) => {
    const _currentTree = $.ui.fancytree.getTree(`#${treeTableId}`);
    if (callExpandApi && isExpand && props.hasOwnProperty("expandAllApi")) {
      if (_currentTree) _currentTree.clear();
      setTreeLoading(true);
      props.expandAllApi().then((_treeData) => {
        if (_treeData) {
          setIsExpandAllData(true);
          if (_currentTree) _currentTree.reload(_treeData);
          if (props.defaultDisabled && props.defaultDisabled.length) {
            defaultDisableNodes(props.defaultDisabled);
          }
          setCallExpandApi(false);
          setTreeLoading(false);
          handleExpandCollapseAllOperation(isExpand);
        }
      });
    } else {
      handleExpandCollapseAllOperation(isExpand);
    }
    setIsExpandAll(isExpand);
  };

  const handleExpandCollapseAllOperation = (isExpand) => {
    if (isExpand) {
      try {
        // to address the performance issue on expand all
        setDisableExpandCollapse(true);
        $.ui.fancytree
          .getTree(`#${treeTableId}`)
          ?.visit(async function (node) {
            await sleep(2);
            node.setExpanded(isExpand);
          })
          .then(() => {
            setDisableExpandCollapse(false);
            setIsExpandAll(isExpand);
          });
      } catch (error) {
        console.error("Error while expanding tree node:", error);
      }
    } else {
      setDisableExpandCollapse(true);
      $.ui.fancytree.getTree(`#${treeTableId}`).expandAll(isExpand);
      setDisableExpandCollapse(false);
      setIsExpandAll(isExpand);
    }
  };

  const showHideAllChild = (isShow) => {
    $.ui.fancytree.getTree(`#${treeTableId}`)?.visit(function (node) {
      if (!node.folder) {
        if (isShow) {
          $(node.li).show();
        } else {
          $(node.li).hide();
        }
      }
    });
    GLOBAL.SHOW_ALL_CHILD = isShow;
    setIsShowAllChild(isShow);
  };

  const showHideAllConditions = (isShow) => {
    const conditions = props.conditions;
    const isExecutionTree = props.isExecutionTree;
    $.ui.fancytree.getTree(`#${treeTableId}`)?.visit(function (node) {
      conditions.map((condition) => {
        if (
          condition &&
          condition.key &&
          node[condition.key] === condition.value
        ) {
          if (isShow) {
            if (node?.children && isExecutionTree) {
              node?.children.map((nodeData) => {
                nodeData.removeChildren();
              });
            }
            if (node.folder) {
              $(node.li).show();
            }
          } else {
            if (node.folder) {
              $(node.li).hide();
            }
          }
        }
      });
    });

    GLOBAL.SHOW_ALL_CONDITIONS = isShow;
    setIsAllConditions(isShow);
  };

  const defaultCheckNodes = (conditions) => {
    $.ui.fancytree.getTree(`#${treeTableId}`)?.visit(function (node) {
      conditions.map((condition) => {
        if (
          !isSelectRootRB &&
          condition &&
          condition.key &&
          props.rootData[condition.key] === condition.value
        ) {
          setIsSelectRootRB(true);
        }
        if (
          condition &&
          condition.key &&
          node[condition.key] === condition.value
        ) {
          node.setSelected(true);
        }
      });
    });
  };

  const defaultDisableNodes = (conditions) => {
    $.ui.fancytree.getTree(`#${treeTableId}`)?.visit(function (node) {
      const _span = $(node.span);
      conditions.map((condition) => {
        if (condition && condition.key) {
          if (
            condition.key.startsWith("data.") &&
            node.data[condition.key.slice(5)] === condition.value
          ) {
            node.unselectable = true;
            node.unselectableStatus = false;
            if (_span) _span.addClass("fancytree-unselectable");
          } else if (node[condition.key] === condition.value) {
            node.unselectable = true;
            node.unselectableStatus = false;
            if (_span) _span.addClass("fancytree-unselectable");
          }
        }
      });
    });
  };

  const checkUncheckAll = (e) => {
    console.log("checkUncheckAll",e)
    const isChecked = e.target.checked;
    $.ui.fancytree.getTree(`#${treeTableId}`).selectAll(isChecked);
    setIsCheckAll(isChecked);
  };

  const rootNodeClicked = (e) => {
    console.log("rootNodeClicked",e)
    e.preventDefault();
    if (props.onRootNodeClicked) {
      props.onRootNodeClicked(props.rootData);
    }
  };

  const selectUnselectRootRB = (e) => {
    e.preventDefault();
    const radioBtnChecked = isSelectRootRB;
    if (radioBtnChecked) {
      setIsSelectRootRB(false);
      props.onCheckedNodes([]);
    } else {
      $.ui.fancytree.getTree(`#${treeTableId}`).selectAll(false);
      setIsSelectRootRB(true);
      props.onCheckedNodes([
        {
          data: {
            ...props.rootData,
            key: props.rootData.key,
            _key: props.rootData.key,
            title: props.rootData.title,
            folder: props.rootData.folder,
            isRootNode: true,
          },
          node: null,
        },
      ]);
    }
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const expandPersistedNodes = () => {
    $.ui.fancytree.getTree(`#${treeTableId}`)?.visit(async function (node) {
      const localData = localStorage.getItem(`${treeTableId}expanded`);
      if (localData) {
        let persistData = localData.split("~");
        if (persistData.includes(node.key)) {
          if (!node.expanded) {
            node.setExpanded(true);
          }
        }
      }
    });
  };
  const updateRoot = async (subTreeData, addCount) => {
    let startCount = 0;
    let subsetCount = addCount;
    let subTreeLength = subTreeData.length;
    let allowRender = true;
    await sleep(10);
    while (allowRender) {
      let tempData = subTreeData.slice(startCount, subsetCount);
      var treeRootNode = $.ui.fancytree.getTree(`#${treeTableId}`);
      if (treeRootNode) {
        treeRootNode?.rootNode?.addNode(tempData);
      }
      if (subsetCount === subTreeLength || subsetCount >= subTreeLength) {
        allowRender = false;
      }
      startCount = startCount + addCount;
      subsetCount = subsetCount + addCount;
      await sleep(5);
    }
    expandPersistedNodes();
  };

  useEffect(() => {
    if (props.id) {
      setTreeTableId(props.id);
    }
    let defaultCount = 7;
    let addCount = 5;
    let sourceData = [];
    if (props.data && props.data.length) {
      sourceData = props.data.slice(0, defaultCount);
    }
    initSimpleTree(sourceData);
    expandPersistedNodes();
    let subTreeData = props.data.slice(defaultCount, props.data.length);
    if (subTreeData && subTreeData.length) {
      updateRoot(subTreeData, addCount);
    }
  }, [treeTableId]);

  useEffect(() => {}, [props.conditions]);

  useEffect(() => {
    GLOBAL.LABELS = { ...GLOBAL.LABELS, ...props.labels };
  }, [props.labels]);

  useEffect(() => {
    showHideAllConditions(!props.hideConditions);
  }, [props.hideConditions]);

  useEffect(() => {
    if (props.defaultChecked && props.defaultChecked.length) {
      defaultCheckNodes(props.defaultChecked);
    }
  }, [props.defaultChecked]);

  useEffect(() => {
    if (props.defaultDisabled && props.defaultDisabled.length) {
      defaultDisableNodes(props.defaultDisabled);
    }
  }, [props.defaultDisabled]);

  useEffect(() => {
    showHideAllChild(!props.hideElements);
  }, [props.hideElements]);

  useEffect(() => {
    if (props.filter) {
      filterTreeData(props.filter);
    }
  }, [props.filter]);

  return (
    <div
      className={`table-tree table-height pb-2 ${
        props.section === "module" ? "" : null
      }`}
    >
      {/* Header */}
      {props.noHeader ? null : (
        <div className="tree-header-wrp">
          {props.colDefs.map((column) => {
            if (column.field === "title") {
              return (
                <span className={`tree-header ${column.class} noMargin`}>
                  <div className="tree-root-wrp">
                    {props.colDefs.map((column) => {
                      const showCheckbox = props.showCheckbox
                        ? props.showCheckbox
                        : false;
                      const showRadioButton = props.showRadioButton
                        ? props.showRadioButton
                        : false;
                      const rootValue = props.rootData
                        ? props.rootData[column.field]
                        : "";
                      const colRender = column.render
                        ? column.render({
                            data: props.rootData,
                            node: {},
                            isRootNode: true,
                          })
                        : "";

                      if (column.field === "title") {
                        return (
                          <span className="root-title-wrp">

                            <div className="tree-title">{column.title}</div>

                           
                      
                            {/* {showCheckbox && (
                              <Checkbox
                                color="primary"
                                disableRipple
                                disabled={
                                  !props.data ||
                                  (props.data && !props.data.length) ||
                                  props.disableRootCheckbox
                                }
                                checked={isCheckAll}
                                indeterminate={isCheckAllIndeterminate}
                                onChange={checkUncheckAll}
                                className="root-check-all"
                              />
                            )}
                            {!props.hideRootRadioBtn && showRadioButton && (
                              <Radio
                                color="primary"
                                id="rootRB"
                                name="rootRB"
                                disableRipple
                                disabled={props.disableRootRadioButton}
                                checked={isSelectRootRB}
                                onClick={selectUnselectRootRB}
                              />
                            )} */}
                            <button
                              type="button"
                              className="root-btn exapnd-all-btn"
                              onClick={() => {
                                if (props?.data?.length > 0) {
                                  expandCollapseAll(!isExpandAll);
                                }
                              }}
                              title={
                                isExpandAll
                                  ? GLOBAL.LABELS.COLLAPSE_ALL
                                  : GLOBAL.LABELS.EXPAND_ALL
                              }
                              disabled={disableExpandCollapse}
                            >
                              {isExpandAll ? (
                                <CollapseAllIcon
                                  disable={disableExpandCollapse}
                                />
                              ) : (
                                <ExpandAllIcon
                                  disable={disableExpandCollapse}
                                />
                              )}
                            </button>
                            {!props.hideElementsBtn && (
                              <button
                                type="button"
                                className="root-btn show-all"
                                onClick={() => {
                                  showHideAllChild(!isShowAllChild);
                                }}
                                title={
                                  isShowAllChild
                                    ? GLOBAL.LABELS.HIDE_ALL_ELEMENTS
                                    : GLOBAL.LABELS.SHOW_ALL_ELEMENTS
                                }
                              >
                                {isShowAllChild ? (
                                  <HideAllChildsIcon />
                                ) : (
                                  <ShowAllChildsIcon />
                                )}
                              </button>
                            )}

                            {!props.hideConditionBtn && (
                              <button
                                type="button"
                                className="root-btn show-all-condition"
                                onClick={() => {
                                  showHideAllConditions(!isShowAllConditions);
                                }}
                                title={
                                  isShowAllConditions
                                    ? GLOBAL.LABELS.HIDE_PRE_POST_CONDITION
                                    : GLOBAL.LABELS.SHOW_PRE_POST_CONDITION
                                }
                              >
                                {isShowAllConditions ? (
                                  <HideAllConditionIcon />
                                ) : (
                                  <ShowAllConditionIcon />
                                )}
                              </button>
                            )}

                            {/* <span className={`root-header ${column.class}`}>
                              <a
                                href="#"
                                className="root-title modifiedBy"
                                title={rootValue}
                                onClick={rootNodeClicked}
                              >
                                {rootValue}
                              </a>
                              {colRender ? colRender : ""}
                            </span> */}
                          </span>
                        );
                      }
                      // return (
                      //   <span
                      //     className={`root-header ${column.class}`}
                      //     style={{
                      //       width:
                      //         column.field === "scriptType"
                      //           ? "0px"
                      //           : column.width,
                      //     }}
                      //   >
                      //     {colRender ? colRender : rootValue}
                      //   </span>
                      // );
                    })}
                  </div>
                </span>
              );
            }
            return (
              <span
                className={`tree-header ${column.class}`}
                style={{ width: column.width }}
              >
                {column.title}
              </span>
            );
          })}
        </div>
      )}

      {/* Root Node */}
      {/* {props.modal && (
        <div className="tree-root-wrp">
          {props.colDefs.map((column) => {
            const showCheckbox = props.showCheckbox
              ? props.showCheckbox
              : false;
            const showRadioButton = props.showRadioButton
              ? props.showRadioButton
              : false;
            const rootValue = props.rootData
              ? props.rootData[column.field]
              : "";
            const colRender = column.render
              ? column.render({
                  data: props.rootData,
                  node: {},
                  isRootNode: true,
                })
              : "";

            if (column.field === "title") {
              return (
                <span
                  className={`root-title-wrp ${
                    !props.hideRootRadioBtn && showRadioButton
                      ? "select-parent-module-bottom"
                      : "select-parent-module-bottom"
                  }`}
                >
                  {showCheckbox && (
                    <Checkbox
                      color="primary"
                      disableRipple
                      disabled={
                        !props.data ||
                        (props.data && !props.data.length) ||
                        props.disableRootCheckbox
                      }
                      checked={isCheckAll}
                      indeterminate={isCheckAllIndeterminate}
                      onChange={checkUncheckAll}
                      className="root-check-all"
                    />
                  )}
                  {!props.hideRootRadioBtn && showRadioButton && (
                    <Radio
                      color="primary"
                      id="rootRB"
                      name="rootRB"
                      disableRipple
                      disabled={props.disableRootRadioButton}
                      checked={isSelectRootRB}
                      onClick={selectUnselectRootRB}
                    />
                  )}
                  <button
                    type="button"
                    className="root-btn exapnd-all-btn"
                    onClick={() => {
                      expandCollapseAll(!isExpandAll);
                    }}
                    title={
                      isExpandAll
                        ? GLOBAL.LABELS.COLLAPSE_ALL
                        : GLOBAL.LABELS.EXPAND_ALL
                    }
                    disabled={disableExpandCollapse}
                  >
                    {isExpandAll ? (
                      <CollapseAllIcon disable={disableExpandCollapse} />
                    ) : (
                      <ExpandAllIcon disable={disableExpandCollapse} />
                    )}
                  </button>
                  {!props.hideElementsBtn && (
                    <button
                      type="button"
                      className="root-btn show-all"
                      onClick={() => {
                        showHideAllChild(!isShowAllChild);
                      }}
                      title={
                        isShowAllChild
                          ? GLOBAL.LABELS.HIDE_ALL_ELEMENTS
                          : GLOBAL.LABELS.SHOW_ALL_ELEMENTS
                      }
                    >
                      {isShowAllChild ? (
                        <HideAllChildsIcon />
                      ) : (
                        <ShowAllChildsIcon />
                      )}
                    </button>
                  )}

                  {!props.hideConditionBtn && (
                    <button
                      type="button"
                      className="root-btn show-all-condition"
                      onClick={() => {
                        showHideAllConditions(!isShowAllConditions);
                      }}
                      title={
                        isShowAllConditions
                          ? GLOBAL.LABELS.HIDE_PRE_POST_CONDITION
                          : GLOBAL.LABELS.SHOW_PRE_POST_CONDITION
                      }
                    >
                      {isShowAllConditions ? (
                        <HideAllConditionIcon />
                      ) : (
                        <ShowAllConditionIcon />
                      )}
                    </button>
                  )}

                  <span className={`root-header ${column.class}`}>
                    <a
                      href="#"
                      className="root-title modifiedBy"
                      title={rootValue}
                      onClick={rootNodeClicked}
                    >
                      {rootValue}
                    </a>
                    {colRender ? colRender : ""}
                  </span>
                </span>
              );
             
            }
            return (
              <span
                className={`root-header ${column.class}`}
                style={{
                  width: column.field === "scriptType" ? "0px" : column.width,
                }}
              >
                {colRender ? colRender : rootValue}
              </span>
            );
          })}
        </div>
      )} */}

<div className="tree-root-wrp">
        {props.colDefs.map(column => {
          const showCheckbox = props.showCheckbox ? props.showCheckbox : false;
          const showRadioButton = props.showRadioButton ? props.showRadioButton : false;
          const rootValue = props.rootData ? props.rootData[column.field] : '';
          const colRender = column.render ? column.render({ data: props.rootData, node: {}, isRootNode: true }) : '';
          if (column.field === 'title') {
            return (
              <span className="root-title-wrp" style={{marginLeft:showCheckbox?"-10px":"10px"}}>
                {showCheckbox && (
                  <Checkbox
                    color="primary"
                    disableRipple
                    // props.disableRootCheckbox added by prajwal
                    disabled={!props.data || (props.data && !props.data.length) || props.disableRootCheckbox}
                    checked={isCheckAll}
                    indeterminate={isCheckAllIndeterminate}
                    onChange={checkUncheckAll}
                    className="root-check-all"
                  />
                )}
                {( showRadioButton) && (
                  <Radio
                    color='primary'
                    id='rootRB'
                    name='rootRB'
                    disableRipple
                    className={props.hideRootRadioBtn?"opacity-50":""}
                    disabled={props.hideRootRadioBtn}
                    checked={isSelectRootRB}
                    onClick={selectUnselectRootRB}
                  />
                )}

                <div className={`root-header ${column.class}`}>
                  <span href="#" className="fancytree-title modifiedBy" title={rootValue} onClick={rootNodeClicked}>{rootValue}</span>
                  {colRender ? colRender : ''}
                </div>
              </span>
            )
          }
          return (
            <span className={`root-header ${column.class}`} style={{ 'width': (column.field === 'scriptType' ? '0px' : column.width) }}>
              {colRender ? colRender : rootValue}
            </span>
          )
        })}
      </div>
      
      {treeLoading && <CommonLoader />}
      <div
        className="table-tree-wrp table-height h-full overflow-auto overflow-x-hidden"
        style={{marginLeft: props?.showRadioButton?"":"-24px"}}
        id="journal-scroll"
      >
        <div id={treeTableId} className=""></div>
      </div>

      <div className="no-data-msg">{GLOBAL.LABELS.NO_DATA_MSG}</div>
    </div>
  );
};



export default TableTree;
