export const KEY_USER_ID = "userId";
export const CREATE_ACCOUNT = "Create an account";
export const HAVE_ACCOUNT = "Already have an account";
export const USER_NAME = "Name";
export const CAPTCH_TERMS =
  "By clicking Register, I agree that I have read and accepted theTerms of Use & Privacy policy";
export const SIGNUP_NOTE =
  "Get Quick Account Create your account quickly, just providing some basic information";
export const SIGNUP_LABELS = {
  label: {
    name: "Name",
    email: "Email",
    password: "Password",
    confirm_password: "Confirm Password",
    phone: "Phone Number",
    organisation_name: "Organisation Name",
    job_title: "Job Title",
  },
  placeHolders: {
    name: "Enter your name",
    email: "Enter your email",
    password: "Enter your password",
    confirm_password: "Confirm your password",
    organisation_name: "Enter organisation name",
    job_title: "Select job title",
  },
};
export const OTP_QUICK_NOTE =
  "Simply Verify Follow the quick verification process, and you all set to use your account";
export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const LABELS = {
  EXPAND_ALL: "Expand All",
  COLLAPSE_ALL: "Collapse All",
  SHOW_ALL_ELEMENTS: "Show all scripts",
  HIDE_ALL_ELEMENTS: "Hide all scripts",
  SHOW_PRE_POST_CONDITION: "Show Pre/Post Conditions",
  HIDE_PRE_POST_CONDITION: "Hide Pre/Post Conditions",
};

export const actionMenuFolder = [
  {
    name: "Details",
  },
  {
    name: "History",
  },
  {
    name: "Clone",
  },
];
export const actionMenuFile = [
  {
    name: "Details",
  },
  {
    name: "History",
  },
  {
    name: "Clone",
  },
  {
    name: "Export in Java",
  },
  {
    name: "Export in Yaml",
  },
];

export const actionMenuFileNoClone = [
  {
    name: "Details",
  },
  {
    name: "History",
  },
  {
    name: "Export in Java",
  },
  {
    name: "Export in Yaml",
  },
];
export const actionMenuRoot = [
  {
    name: "Details",
  },
  {
    name: "History",
  },
];

export const plusIconArray = [
  {
    name: "Add Module",
    value: "folder",
  },
  // {
  //   name: "Import Manual Testcase and Download",
  //   value: "import file",
  // },
  {
    name: "Create Manual Testcase",
    value: "file",
  },
];

export const CHART_COLOR = [
  "#000080",
  "#00FFFF",
  "#800080",
  "#FF00FF",
  "#008080",
  "#008000",
  "#FFFF00",
  "#FFFF00",
  "#838996",
  "#737CA1",
  "#728FCE",
  "#5667DE",
  "#728FCE",
  "#4863A0",
  "#2B547E",
  "#36454F",
  "#BDEDFF",
  "#D5D6EA",
  "#CCFFFF",
  "#7DFDFE",
  "#50EBEC",
  "#4EE2EC",
  "#16E2F5",
  "#8EEBEC",
  "#AFEEEE",
  "#CFECEC",
  "#81D8D0",
  "#77BFC7",
  "#7BCCB5",
  "#66CDAD",
  "#AAF0D1",
  "#7FFFD4",
  "#00827F",
  "#045F5F",
  "#033E3E",
  "#25383C",
  "#3C565B",
  "#4C787E",
  "#307D7E",
  "#348781",
  "#4E8975",
  "#306754",
  "#2E8B57",
  "#31906E",
  "#00A36C",
  "#34A56F",
  "#50C878",
  "#3EB489",
  "#3CB371",
  "#7C9D8E",
  "#78866B",
  "#848B79",
  "#617C58",
  "#728C00",
  "#6B8E23",
  "#808000",
  "#556B2F",
  "#4E5B31",
  "#4B5320",
  "#667C26",
  "#4E9258",
  "#387C44",
  "#347C2C",
  "#228B22",
  "#006400",
  "#254117",
  "#437C17",
  "#6AA121",
  "#6CC417",
  "#32CD32",
  "#52D017",
  "#4CC552",
  "#54C571",
  "#99C68E",
  "#89C35C",
  "#85BB65",
  "#9CB071",
  "#B0BF1A",
  "#B2C248",
  "#9DC209",
  "#40E0D0",
  "#87F717",
  "#ADFF2F",
  "#DAEE01",
  "#E2F516",
  "#CCFB5D",
  "#64E986",
  "#90EE90",
  "#98FF98",
  "#B5EAAA",
  "#C3FDB8",
  "#DBF9DB",
  "#E8F1D4",
  "#F0FFF0",
  "#FFFACD",
  "#FFFFC2",
  "#F5F5DC",
  "#FFF8DC",
  "#FBF6D9",
  "#F7E7CE",
  "#FAEBD7",
  "#FFEFD5",
  "#F5DEB3",
  "#FFE4B5",
  "#F1E5AC",
  "#F0E68C",
  "#FFE87C",
  "#FFF380",
  "#FAF884",
  "#FFFF33",
  "#FFDB5",
  "#800080",
  "#FFCE44",
  "#EAC117",
  "#F2BB66",
  "#FFAE42",
  "#FBB117",
  "#F4A460",
  "#E2A76F",
  "#C19A6B",
  "#E6BF83",
  "#DEB887",
  "#D2B48C",
  "#C8AD7F",
  "#C2B280",
  "#BCB88A",
  "#B87333",
  "#AB784E",
  "#483C32",
  "#493D26",
  "#513B1C",
  "#EB5406",
  "#C35817",
  "#C04000",
  "#7E3517",
  "#954535",
  "#C34A2C",
  "#B7673",
  "#C36241",
  "#CB6D51",
  "#C47451",
  "#FF8674",
  "#FA8072",
  "#F75D59",
  "#A70D2A",
  "#560319",
  "#550A35",
  "#810541",
  "#F6358A",
];

export const MODULE_CHART_COLOR = [
  "#DFFF00",
  "#FFBF00",
  "#FF9633",
  "#FF7F50",
  "#DE3163",
  "#9FE2BF",
  "#40E0D0",
  "#6495ED",
  "#838996",
  "#737CA1",
  "#728FCE",
  "#5667DE",
  "#728FCE",
  "#4863A0",
  "#2B547E",
  "#36454F",
  "#BDEDFF",
  "#D5D6EA",
  "#CCFFFF",
  "#7DFDFE",
  "#50EBEC",
  "#4EE2EC",
  "#16E2F5",
  "#8EEBEC",
  "#AFEEEE",
  "#CFECEC",
  "#81D8D0",
  "#77BFC7",
  "#7BCCB5",
  "#66CDAD",
  "#AAF0D1",
  "#7FFFD4",
  "#00827F",
  "#008080",
  "#045F5F",
  "#033E3E",
  "#25383C",
  "#3C565B",
  "#4C787E",
  "#307D7E",
  "#348781",
  "#4E8975",
  "#306754",
  "#2E8B57",
  "#31906E",
  "#00A36C",
  "#34A56F",
  "#50C878",
  "#3EB489",
  "#3CB371",
  "#7C9D8E",
  "#78866B",
  "#848B79",
  "#617C58",
  "#728C00",
  "#6B8E23",
  "#808000",
  "#556B2F",
  "#4E5B31",
  "#4B5320",
  "#667C26",
  "#4E9258",
  "#387C44",
  "#347C2C",
  "#228B22",
  "#006400",
  "#254117",
  "#437C17",
  "#6AA121",
  "#6CC417",
  "#32CD32",
  "#52D017",
  "#4CC552",
  "#54C571",
  "#99C68E",
  "#89C35C",
  "#85BB65",
  "#9CB071",
  "#B0BF1A",
  "#B2C248",
  "#9DC209",
  "#00FF00",
  "#87F717",
  "#ADFF2F",
  "#DAEE01",
  "#E2F516",
  "#CCFB5D",
  "#64E986",
  "#90EE90",
  "#98FF98",
  "#B5EAAA",
  "#C3FDB8",
  "#DBF9DB",
  "#E8F1D4",
  "#F0FFF0",
  "#FFFACD",
  "#FFFFC2",
  "#F5F5DC",
  "#FFF8DC",
  "#FBF6D9",
  "#F7E7CE",
  "#FAEBD7",
  "#FFEFD5",
  "#F5DEB3",
  "#FFE4B5",
  "#F1E5AC",
  "#F0E68C",
  "#FFE87C",
  "#FFF380",
  "#FAF884",
  "#FFFF33",
  "#FFDB5",
  "#FFCE44",
  "#EAC117",
  "#F2BB66",
  "#FFAE42",
  "#FBB117",
  "#F4A460",
  "#E2A76F",
  "#C19A6B",
  "#E6BF83",
  "#DEB887",
  "#D2B48C",
  "#C8AD7F",
  "#C2B280",
  "#BCB88A",
  "#B87333",
  "#AB784E",
  "#483C32",
  "#493D26",
  "#513B1C",
  "#EB5406",
  "#C35817",
  "#C04000",
  "#7E3517",
  "#954535",
  "#C34A2C",
  "#B7673",
  "#C36241",
  "#CB6D51",
  "#C47451",
  "#FF8674",
  "#FA8072",
  "#F75D59",
  "#A70D2A",
  "#560319",
  "#550A35",
  "#810541",
  "#F6358A",
];
export const DASHBOARD_CHART_COLOR =
["#AED6F1",
"#D7BDE2",
"#ABEBC6",
"#F5CBA7",
"#ECEDC5",
"#F5B7B1",
"#957DAD",
"#D291BC",
"#FEC8D8",
"#FFDFD3",
"#FFECDE",
"#FFB6A6",
"#787BA2",
"#97C8E1",
"#CEEDD8",
"#B19BB6",
"#C6D5B1",
"#F1E1BF",
"#F8ABB3",
"#9EA9CD",
"#98AFF9",
"#90D4E7",
"#FDA7BE",
"#F3D67F",
"#BEA28C",
"#E8B499",
"#E7D8A9",
"#C1C59B",
"#557B8B",
"#324066",
"#886A4B",
"#533C36",
"#749297",
"#C89239",
"#D8AB69",
"#ECCB98",
"#B74E27",
"#704F38",
"#6B5570",
"#484B66",
"#556658",
"#6D6C5E",
"#6B594D",
"#5E3F43",
"#586488",
"#437F87",
"#727787",
"#36422B",
"#4C5236",
"#7E6E52",
"#613E3E",
"#6B5044",
"#B39571",
"#5D416A",
"#754E72",
"#AA7180",
"#DE8F4E",
"#DA680E",
"#EB6662",
"#F7B172",
"#F7D37E",
"#82C881",
"#1D8F94",
"#203D85",
"#4B2882",
"#2E5793",
"#177245",
"#C4A705",
"#C96112",
"#C1301C",
"#C6AEFF",
"#BDD8FF",
"#BDFFB2",
"#FCFFA6",
"#FFC8AE",
"#FF9FBB",
"#A091BD",
"#F8D1D5",
"#83D0E0F",
"#BB4B9F",
"#FACF9A",
"#7B1200",
"#B21F01",
"#DE700F",
"#F7C32D",
"#6C8A1A",
"#2E541A",
"#95F116",
"#00C834",
"#8E0067",
"#FA6B16",
"#FFCC00",
"#620A48",
"#A0001D",
"#F29900",
"#E06C00",
"#617A32",
"#255669",
"#C5832A",
"#6D7101"
];