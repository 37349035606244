import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  TextareaAutosize,
  TextField,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Label } from "../common/inputs/myInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { Dropdown } from "./dropdown";
import TYDateTimePicker from "../modal/ty-data-picker";
import "./config.scss";
import FormControl from "@material-ui/core/FormControl";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { getLabel, getOptionLabel } from "./common-functions";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { Alert } from "@material-ui/lab";
import {ReactComponent as Multiplefiles} from "../../assets/multiple-files-icon.svg";
import RichTextEditor from "./rich-texteditor";
import {ReactComponent as VideoIcon} from "../../assets/feather-video-icon.svg";
import {ReactComponent as Attachments} from "../../assets/attachment_icon.svg";
import AttachFile from "@mui/icons-material/AttachFile";
import {getDefecttemplateDetails} from "../../api/api-services";
import { CustomDefectDropDown } from "./customDefectDropDown";



function DefectTemplateDetails(props) {

    console.log("props data defect template",props)
  const [openModal, setOpenModal] = useState(true);
  const [TemplateData, setTemplateData] = useState(
    JSON.parse(JSON.stringify(props?.defectsdeatils))
  );
 
  const [templateName, setTemplateName] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState("");

  
  const testCaseDetailDescElements = (data) => {
    switch (data.type) {
      case "textField":
        return (
          <>
            <TextField
              disabled={true}
              className="w-60"
              size="small"
              fullWidth
              title={data?.placeholder?.length > 30 ? data?.placeholder : null}
              placeholder={
                data?.placeholder?.length > 30
                  ? data.placeholder?.substr(0, 30) + "..."
                  : data?.placeholder
              }
              value={data.defaultValue}
            />
          </>
        );

      case "textArea":
        return (
          <>
            <div className="">
              <TextareaAutosize
                 disabled={true}
                title={data?.placeholder?.length > 30 ? data.placeholder : null}
                id="description"
                name="description"
                style={{ height: "60px" }}
                className="w-full border-2 rounded border-gray-300 pl-2"
                placeholder={
                  data?.placeholder?.length > 30
                    ? data?.placeholder?.substr(0, 30) + "..."
                    : data?.placeholder
                }
                value={data.defaultValue}
              />
            </div>
          </>
        );

      case "radioButton":
        return (
          <>
            <div className="">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div className="">
                    <input
                      type="radio"
                      className="mr-2"
                     
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="defect-template-btn-label">{getOptionLabel(option)}</label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case "link":
        return (
          <>
            <div className=" flex mt-3">
              <div>
                {/* <Attachments className="-mt-2 mr-2"/> */}
                <InsertLinkOutlinedIcon className="-mt-2 mr-2 origin-center   text-blue-600" />
              </div>
              
              <div
                aria-disabled={true}
                className=" font-sans text-xs opacity-50 lg:w-64"
                title="Attached Link will be shown here if any URL is added"
              >
                {/* {getLabel(
                  "Attached Link will be shown here if any URL is added"
                )} */}
                
                
                  <span>Attached Link will be shown here if any URL is added</span>
                 
              </div>
              
              
            </div>
          </>
        );

      case "dropdown":
        return (
          <>
            
            {/* <FormControl fullWidth>
              <Select
                name="selectCustomField"
                id="selectCustomField"
                size="small"
                displayEmpty
                 disabled={false}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
                renderValue={selected => {
                                    if (!selected) return data?.placeholder;
                                    else return selected;
                                }}
              >
                
                {data?.options?.map((option, index) => (
                  <option value={option}>{option}</option>
                ))}
              </Select>
            </FormControl> */}
            <CustomDefectDropDown
                data={data?.options}
                selectedData={data?.placeholder}
                searchable={false}
                onChange={(e) => {
                  e.preventDefault()
                }}
                placeholder={data?.placeholder}
              />
          </>
        );

      case "date":
        return (
          <>
            <div className="flex">
              <div className="border-2 rounded border-gray-300 w-full  flex py-1">
                <div className="w-11/12"></div>
                <div className="border-l-2  pl-2">
                  <CalendarTodayOutlinedIcon className="mt-1" />
                </div>
              </div>
            </div>
            {TemplateData?.templateType === "Predefined" ? (
              <div className="text-xs opacity-50">[dd-mm-yyyy]</div>
            ) : (
              <div className="text-xs opacity-50">[{data.dateFormat}]</div>
            )}
          </>
        );
      case "checkbox":
        return (
          <>
            <div className="">
              <div className="grid grid-cols-2 gap-1">
                {data?.options?.map((option, index) => (
                  <div className="">
                    <input
                      type="checkbox"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="defect-template-btn-label">{getOptionLabel(option)}</label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case "attachment":
        return (
          <>
            
            {/* {
              data?.label==="Attach multiple images"?
              (
                <div>
                  <Multiplefiles/>
                </div>
              ):(
                <div><VideoIcon/></div>
              )
            } */}
            <div>
              {data?.label==="Attach multiple images" ?<Multiplefiles/>:data?.label==="Attach Video"?<VideoIcon/>:<span className="text-blue-600"><AttachFile/></span>}
            </div>
          </>
        );

      case "textEditor":
        return (
          <div>
            <RichTextEditor
                      convertedContent=''
                      readOnly={true}
                      toolbarHidden={false}
                      className="w-full"
                  />
          </div>
        );

      default:
        return (
          <>
            <TextField disabled={true} className="w-60" />
          </>
        );
    }
  };


  const testCaseDetailElements = (props) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <h6 className="defect-template-modal-values w-11/12 mb-2">
              {props.data.mandatory === "yes" ? (
                <span className="text-red-400 mr-1">&#42;</span>
              ) : null}
              {getLabel(props.data.label)}
            </h6>
            
          </div>
          <div className="">
            <div className="">{testCaseDetailDescElements(props.data)}</div>
          </div>
        </div>
      </>
    );
  };

  const testCaseDetailSection = () => {
    return (
      <>
        <div className="">
          <div className="">
            {TemplateData.defectDetails.map((data) => (
              <div className="my-5">
                {testCaseDetailElements({ data })}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "82.5%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
      <div className="flex rounded-tl-xl justify-between defect-template-modal-heading h-10 p-2">
        <div title={props?.defectsdeatils?.name} className="defect-template-name">Defect Details Template-{props?.defectsdeatils?.name}</div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeDefectsPopup(false)
          }}
          type="button"
          className="modal-close-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      <div className="main-content overflow-y-scroll " id="journal-scroll">
        <div className="pr-4">
          <div className="px-2">
            <div>
              {/* start   */}
              <div className="my-5 pb-7">
                <div className="mb-4">
                  <Label
                    label={
                      <>
                        <span className="text-red-400 mr-1">&#42;</span>{" "}
                        <span className="defect-template-modal-values">Template Name</span>
                      </>
                    }
                  />
                  <TextField
                    name="templateName"
                    id="templateName"
                    disabled="true"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="off"
                    fullWidth
                    value={props?.defectsdeatils?.name}
                    className=" w-60 "
                    type="text"
                    
                  />
                  {showErrorMsg && (
                    <div className="errorMessage">{showErrorMsg}</div>
                  )}
                </div>
                <div className="">
                  <span className="popins-semi-bold text-blue-600">
                    Defect Details
                  </span>
                </div>
                <div className="">{testCaseDetailSection()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-2 px-3 flex justify-end gap-4 user-create-button z-50">
          <button
            onClick={() => {
              setOpenModal(false);
             props.closeDefectsPopup(false)
            }}
            type="button"
            className="w-24 gray-btn ml-3 button"
          >
            Close
          </button>
          
        </div>
      </div>
    </Modal>
  );
}
export default DefectTemplateDetails;
