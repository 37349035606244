import React, { useEffect, useState, Fragment } from "react";
import Modal from "react-modal";
import { duplicatesCheck, validateEmail } from "../../util/common-utils";
import { createUser } from "../../api/api-services";
import { EMAIL_PATTERN } from "../../util/app-constants";
import { ReactComponent as EditIcon } from "../../assets/editBlack.svg";
import IconButton from "@mui/material/IconButton";
import { Input } from "@mui/material";
import styled from "styled-components";

const Span = styled.span`
  color: red;
  font-size: 12px;
  font-family: Poppins-Regular;
`;
let inputErrorCount = 0;
function RejecteduserPopup(props) {
  const [openModal, setOpenModal] = useState(true);
  const [rejectedData, setRejectedData] = useState(props.rejectedData);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editClickedRows, setEditClickedRows] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const [dataChanged, setDataChanged] = useState(false);

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "54%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      height: "93%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderTopLeftRadius: "4px",
      opacity: "1",
      outline: "none",
    },
  };
  function isEmailValid(email, index) {
    if (email === "") {
      // inputErrorCount++;
      setRowValues("emailError", "Email ID is required", index);
    } else if (!EMAIL_PATTERN.test(email)) {
      // inputErrorCount++;
      setRowValues("emailError", "Please enter valid email id", index);
    } else {
      // inputErrorCount--;
      setRowValues("emailError", "", index);
    }
  }
  function validatePhone(phone, index) {
    var phone_regex = /^[0-9]{12}$/;
    if (phone === "") {
      setRowValues("phoneError", "Phone is required", index);
      // inputErrorCount++;
    } else if (!phone_regex.test(phone)) {
      setRowValues("phoneError", "Please enter valid phone no", index);
      // inputErrorCount++;
    } else {
      setRowValues("phoneError", "", index);
      // inputErrorCount--;
    }
  }
  function validateName(name, index) {
    var name_regex = /^[a-zA-Z. ]+$/;
    if (name === "") {
      // inputErrorCount++;
      setRowValues("nameError", "Name is required", index);
    } else if (name?.length < 3 || !name_regex.test(name)) {
      // inputErrorCount++;
      setRowValues("nameError", "Please enter valid name", index);
    } else {
      // inputErrorCount--;
      setRowValues("nameError", "", index);
    }
  }

  const uploadBulkUser = async (payloadData) => {
    setIsloading(true);
    let reUploadProceedFlag = false;
    let tempUsersArray = [];
    let reUploadPayload = payloadData;
    console.log("reupload payload", reUploadPayload);
    rejectedData?.map((obj, index) => {
      if (editClickedRows.indexOf(index) !== -1) {
        if (
          obj.nameError === "" &&
          obj.phoneError === "" &&
          obj.emailError === ""
        ) {
          reUploadProceedFlag = true;
          let tempObj = {
            name: obj.editedName,
            email: obj.editedEmail,
            phoneNumbers: { primaryPhone: obj.editedPhone },
          };
          tempUsersArray.push(tempObj);
        } else {
          reUploadProceedFlag = false;
        }
      }
    });
    reUploadPayload.userList = tempUsersArray;
    if (reUploadProceedFlag) {
      try {
        let response = await createUser(reUploadPayload);
        if (response.data || response.status === 200) {
          setIsloading(false);
          // props.closeRejecteduserPopup(
          //   true,
          //   rejectedData,
          //   reUploadPayload
          // );
          props.MyAlert.success(`Users Added :
                  ${response?.data?.addedUsersCount}
                    Rejected :
                  ${response.data.rejectedUsersCount}
                  Duplicate:${response.data.duplicateUsersCount}`);

          props.getAllUsers(0);
          props.closeModal(false);
        } else if (response) {
          setIsloading(false);
          if (response?.response?.data?.errorMessage) {
            props.MyAlert.info(response.response?.data?.errorMessage);
          }
          props.closeModal(false);
        }
      } catch (err) {
        setIsloading(false);
        console.error(err);
      }
    }
  };
  const handleCheckbox = (event, row) => {
    console.log("before selecting row ", selectedRows);
    if (event.target.checked === true) {
      setSelectedRows([...selectedRows, row]);
    } else {
      const nextSelectedRows = selectedRows.filter(
        (selectedRow) => selectedRow !== row
      );
      setSelectedRows(nextSelectedRows);
      handleEditClick(false, row);
    }
    console.log("selected row ", selectedRows);
  };
  const handleEditClick = (clicked, row) => {
    if (clicked) {
      setEditClickedRows([...editClickedRows, row]);
      let tempRejectedData = rejectedData;
      tempRejectedData[row].editedName = tempRejectedData[row].name;
      tempRejectedData[row].editedPhone =
        tempRejectedData[row].phoneNumbers.primaryPhone;
      tempRejectedData[row].editedEmail = tempRejectedData[row].email;
      tempRejectedData[row].nameError = "";
      tempRejectedData[row].phoneError = "";
      tempRejectedData[row].emailError = "";
      setRejectedData(tempRejectedData);
    } else {
      console.log("next clicked for edit", editClickedRows);
      const nextEditClickedRows = editClickedRows.filter(
        (selectedRow) => selectedRow !== row
      );
      console.log("next clicked for edit", editClickedRows);
      setEditClickedRows(nextEditClickedRows);
    }
    console.log("selected row clicked for edit", editClickedRows);
  };
  const setRowValues = (keyValue, value, index) => {
    let tempRejectedData = rejectedData;
    tempRejectedData[index][keyValue] = value;
    setRejectedData(tempRejectedData);
    setDataChanged(!dataChanged);
  };

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        setOpenModal(false);
      }}
    >
      <div className="defect-details">
        <div className="grid grid-cols-12 felx header">
          <span className="col-span-11 heading pl-2">Rejected users list</span>
          <div className="col-span-1 flex justify-end mr-1">
            <button
              onClick={() => {
                props.closeModal(false);
              }}
              type="button"
              className="modal_clos_btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div>
          <div className="rejectedUsertable_container">
            <div>
              <div className="flex testStepTable">
                <div className="w-1/12"></div>
                <div className="py-2 text-blue-800 fontPoppinsMediumMd w-2/5">
                  Name
                </div>
                <div className="py-2 text-blue-800 fontPoppinsMediumMd w-2/5">
                  Phone no
                </div>
                <div className="py-2 text-blue-800 fontPoppinsMediumMd w-2/5">
                  Email
                </div>
                <div className="py-2 text-blue-800 fontPoppinsMediumMd w-1/5">
                  Actions
                </div>
              </div>
              {rejectedData.length === 0 ? (
                <div className="table_message">
                  <span>No,data found</span>
                </div>
              ) : (
                <div>
                  {rejectedData.map((row, index) => {
                    return (
                      <div className="flex testStepTable">
                        <div className="p-2 w-1/12">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              console.log("input onchange", e.target.checked);
                              handleCheckbox(e, index);
                            }}
                          />
                        </div>
                        {editClickedRows.indexOf(index) === -1 ? (
                          <>
                            <div className="py-2  w-2/5">{row.name}</div>
                            <div className="py-2  w-2/5">
                              {row.phoneNumbers.primaryPhone}
                            </div>
                            <div className="py-2 h-10 w-2/5">{row.email}</div>
                          </>
                        ) : (
                          <>
                            <div className="pt-2 w-2/5">
                              <Input
                                value={row.editedName}
                                onChange={(e) => {
                                  setRowValues(
                                    "editedName",
                                    e.target.value,
                                    index
                                  );
                                }}
                                onBlur={(e) => {
                                  validateName(e.target.value, index);
                                }}
                              />
                              {row.nameError !== undefined &&
                              row.nameError !== "" ? (
                                <Span className="errorMsg">
                                  {row.nameError}
                                </Span>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="pt-2 w-2/5">
                              <Input
                                value={row.editedPhone}
                                onChange={(e) => {
                                  setRowValues(
                                    "editedPhone",
                                    e.target.value,
                                    index
                                  );
                                }}
                                onBlur={(e) => {
                                  validatePhone(e.target.value, index);
                                }}
                              />
                              {row.phoneError !== undefined &&
                              row.phoneError !== "" ? (
                                <Span>{row.phoneError}</Span>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="pt-2 w-2/5">
                              <Input
                                value={row.editedEmail}
                                onChange={(e) => {
                                  setRowValues(
                                    "editedEmail",
                                    e.target.value,
                                    index
                                  );
                                }}
                                onBlur={(e) => {
                                  isEmailValid(e.target.value, index);
                                }}
                              />
                              {row.emailError !== undefined &&
                              row.emailError !== "" ? (
                                <Span>{row.emailError}</Span>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        )}

                        <div className="h-10 w-1/5">
                          <IconButton>
                            <button
                              disabled={selectedRows.indexOf(index) === -1}
                              className={
                                selectedRows.indexOf(index) === -1
                                  ? "opacity-50"
                                  : "block"
                              }
                              onClick={() => {
                                handleEditClick(true, index);
                              }}
                            >
                              <EditIcon />
                            </button>
                          </IconButton>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <>
          <div className="grid grid-cols-12 col-span-12 warning_popup_btn  h-12 pt-2">
            <div className="col-span-8"></div>
            <div className="grid grid-cols-12 col-span-2 flex justify-end">
              <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
                <button
                  onClick={() => {
                    props?.closeModal(false);
                  }}
                  type="button"
                  className="w-24 gray-btn ml-3 button"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  id="createBatch"
                  onClick={() => {
                    if (editClickedRows.length > 0 && inputErrorCount === 0) {
                      uploadBulkUser(props?.rejectedPayload);
                    }
                  }}
                  className={`w-24 primary-btn button ${
                    (editClickedRows.length <= 0 || inputErrorCount > 0) &&
                    "opacity-50"
                  }`}
                  disabled={
                    (isLoading &&
                      (editClickedRows.length <= 0 || inputErrorCount > 0)) ===
                    true
                      ? true
                      : false
                  }
                >
                  Reupload
                </button>
              </div>
            </div>
          </div>
        </>
      </div>
    </Modal>
  );
}
export default RejecteduserPopup;
