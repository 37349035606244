import React, { useState, useEffect } from "react";
import "./client-list.scss";
import Carousel, { consts } from "react-elastic-carousel";
import { Tooltip } from "@mui/material";
import { ReactComponent as NoReportsIcon } from "../../assets/noReportsAvailable.svg";
import { ReactComponent as LeftArrow } from "../../assets/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../assets/right-arrow.svg";
import { ReactComponent as Vivo } from "../../assets/allMobileLogo/vivo.svg";
import { ReactComponent as Oppo } from "../../assets/allMobileLogo/oppo.svg";
import { ReactComponent as Oneplus } from "../../assets/allMobileLogo/one plus.svg";
import { ReactComponent as Samsung } from "../../assets/allMobileLogo/samsung.svg";
import { ReactComponent as Huawei } from "../../assets/allMobileLogo/Huawei.svg";
import { ReactComponent as Mi } from "../../assets/allMobileLogo/MI.svg";
import { ReactComponent as Apple } from "../../assets/allMobileLogo/apple.svg";
import micromax from "../../assets/allMobileLogo/micromax.svg";
import { ReactComponent as Infinix } from "../../assets/allMobileLogo/infinix.svg";
import { ReactComponent as Sony } from "../../assets/allMobileLogo/Sony.svg";
import { ReactComponent as Gionee } from "../../assets/allMobileLogo/gionee.svg";
import poco from "../../assets/allMobileLogo/poco.svg";
import google from "../../assets/allMobileLogo/google pixel.svg";
import { ReactComponent as Lenovo } from "../../assets/allMobileLogo/Lenovo.svg";
import { ReactComponent as Motorola } from "../../assets/allMobileLogo/Motorola.svg";
import { ReactComponent as Itel } from "../../assets/allMobileLogo/itel.svg";
import { ReactComponent as Blackberry } from "../../assets/allMobileLogo/blackberry.svg";
import { ReactComponent as Nokia } from "../../assets/allMobileLogo/nokia.svg";
import { ReactComponent as Realme } from "../../assets/allMobileLogo/Realme.svg";
import { ReactComponent as Lg } from "../../assets/allMobileLogo/LG.svg";
import { ReactComponent as Asus } from "../../assets/allMobileLogo/Asus.svg";
import { ReactComponent as Coolpad } from "../../assets/allMobileLogo/coolpad.svg";
import { ReactComponent as Tecno } from "../../assets/allMobileLogo/Tecno Mobile.svg";
import { ReactComponent as Zte } from "../../assets/allMobileLogo/ZTE.svg";
import { ReactComponent as Nothing } from "../../assets/allMobileLogo/Nothing_Logo.svg";
import { ReactComponent as Htc } from "../../assets/allMobileLogo/htc-logo-icon.svg";

import { ReactComponent as PANASONIC } from "../../assets/allMobileLogo/panasonic.svg";
import { ReactComponent as PHILIPS } from "../../assets/allMobileLogo/philips.svg";
import { ReactComponent as TCL } from "../../assets/allMobileLogo/tcl.svg";
import { ReactComponent as HISENSE } from "../../assets/allMobileLogo/hisense.svg";
import { ReactComponent as VIZIO } from "../../assets/allMobileLogo/vizio.svg";
import { ReactComponent as SHARP } from "../../assets/allMobileLogo/sharp.svg";
import { ReactComponent as TOSHIBA } from "../../assets/allMobileLogo/toshiba.svg";
import { ReactComponent as JVC } from "../../assets/allMobileLogo/jvc.svg";
import { ReactComponent as HAIER } from "../../assets/allMobileLogo/haier.svg";
import { ReactComponent as SCEPTRE } from "../../assets/allMobileLogo/sceptre.svg";
import { ReactComponent as RCA } from "../../assets/allMobileLogo/htc-logo-icon.svg";
import { ReactComponent as SKYWORTH } from "../../assets/allMobileLogo/skyworth.svg";
import { ReactComponent as GRUNDIG } from "../../assets/allMobileLogo/grundig.svg";
import { ReactComponent as HITACHI } from "../../assets/allMobileLogo/hitachi.svg";
import { ReactComponent as FUNAI } from "../../assets/allMobileLogo/funai.svg";
import { ReactComponent as PIONEER } from "../../assets/allMobileLogo/pioneer.svg";
import { ReactComponent as FITBIT } from "../../assets/allMobileLogo/fitbit.svg";
import { ReactComponent as AMAZFIT } from "../../assets/allMobileLogo/amazfit-logo.svg";
import { ReactComponent as TICWATCH } from "../../assets/allMobileLogo/amazfit-logo.svg";
import { ReactComponent as GRAMIN } from "../../assets/allMobileLogo/garmin.svg";
import { ReactComponent as FOSSIL } from "../../assets/allMobileLogo/fossil.svg";

const MobileDeviceContent = (props) => {
  const [deviceInfo, setDeviceInfo] = useState({ ...props?.deviceInfo });
  const [mobileFilter, setMobileFilter] = useState("All");
  const [bgColor, setBgColor] = useState("");
  useEffect(() => {
    setDeviceInfo({ ...props?.deviceInfo });
  }, [props.deviceInfo]);
  useEffect(() => {
    if (props.refreshed) {
      setMobileFilter("All");
      setDeviceInfo({ ...props?.deviceInfo });
      props.setRefreshBack(false);
    }
  }, [props.refreshed]);
  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <div className={isEdge ? "invisible " : "visible customizedLeftArrow"}>
          <LeftArrow height="22px" width="22px" />
        </div>
      ) : (
        <div
          className={
            isEdge
              ? "invisible "
              : "visible customizedRightArrow relative -left-6"
          }
        >
          {" "}
          <RightArrow height="22px" width="22px" />
        </div>
      );
    return (
      <button onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  }

  const filterData = (filterCondition) => {
    if (mobileFilter !== filterCondition.target.value) {
      let filteredData = { ...JSON.parse(JSON.stringify(props?.deviceInfo)) };
      filteredData.deviceBrand = [];
      if (filterCondition.target.value !== "All") {
        props?.deviceInfo.deviceBrand.map((data) => {
          if (filterCondition.target.value === data.deviceType) {
            filteredData.deviceBrand.push(data);
          }
        });
        setDeviceInfo({ ...filteredData });
      } else {
        setDeviceInfo({ ...JSON.parse(JSON.stringify(props?.deviceInfo)) });
      }
      setMobileFilter(filterCondition.target.value);
    }
  };

  function selectedDeviceModel(model) {
    let deviceName = model.toLowerCase();
    console.log("selectedDeviceModel", deviceName);
    switch (deviceName) {
      case "vivo":
        return <Vivo height="32px" width="35px" />;

      case "oppo":
        return <Oppo height="35px" width="35px" />;

      case "oneplus":
        return <Oneplus height="32px" width="35px" />;

      case "samsung":
        return <Samsung height="35px" width="35px" />;

      case "huawei":
        return <Huawei height="32px" width="35px" />;
      case "mi":
        return <Mi height="32px" width="35px" />;
      case "xiaomi":
        return <Mi height="32px" width="35px" />;
      case "iphone":
        return <Apple height="32px" width="35px" />;
      case "apple":
        return <Apple height="32px" width="35px" />;
      case "micromax":
        return (
          <img
            src={micromax}
            style={{ height: "35px", width: "35px", marginLeft: "1px" }}
          />
        );
      case "infinix":
        return <Infinix height="32px" width="35px" />;
      case "sony":
        return <Sony height="32px" width="35px" />;
      case "gionee":
        return <Gionee height="32px" width="35px" />;
      case "poco":
        return <img src={poco} style={{ height: "32px", marginLeft: "1px" }} />;
      case "google":
        return (
          <img src={google} style={{ height: "32px", marginLeft: "1px" }} />
        );
      case "lenovo":
        return <Lenovo height="32px" width="35px" />;
      case "motorola":
        return <Motorola height="32px" width="35px" />;
      case "itel":
        return <Itel height="32px" width="35px" />;
      case "blackberry":
        return <Blackberry height="32px" width="35px" />;
      case "nokia":
        return <Nokia height="32px" width="35px" />;
      case "realme":
        return <Realme height="32px" width="35px" />;
      case "lg":
        return <Lg height="32px" width="35px" />;
      case "asus":
        return <Asus height="32px" width="35px" />;
      case "coolpad":
        return <Coolpad height="32px" width="35px" />;
      case "tecno":
        return <Tecno height="32px" width="35px" />;
      case "zte":
        return <Zte height="32px" width="35px" />;
      case "honor":
        return <Huawei height="32px" width="35px" />;
      case "iqoo":
        return <Vivo height="32px" width="35px" />;
      case "rim":
        return <Blackberry height="32px" width="35px" />;
      case "nothing":
        return <Nothing height="32px" width="35px" />;
      case "htc":
        return <Htc height="32px" width="35px" />;
      case "tecno mobile":
        return <Tecno height="32px" width="35px" />;
       




        case "panasonic":
          return <PANASONIC height="32px" width="35px" />;
          case "philips":
        return <PHILIPS height="32px" width="35px" />;
        case "tcl":
        return <TCL height="32px" width="35px" />;
        case "hisense":
        return <HISENSE height="32px" width="35px" />;
        case "vizio":
        return <VIZIO height="32px" width="35px" />;
        case "sharp":
        return <SHARP height="32px" width="35px" />;
        case "toshiba":
        return <TOSHIBA height="32px" width="35px" />;
        case "jvc":
        return <JVC height="32px" width="35px" />;
        case "haier":
        return <HAIER height="32px" width="35px" />;
        case "sceptre":
        return <SCEPTRE height="32px" width="35px" />;
        case "rca":
        return <RCA height="32px" width="35px" />;
        case "skyworth":
        return <SKYWORTH height="32px" width="35px" />;
        case "grundig":
        return <GRUNDIG height="32px" width="35px" />;
        case "hitachi":
        return <HITACHI height="32px" width="35px" />;
        case "funai":
        return <FUNAI height="32px" width="35px" />;
        case "pioneer":
        return <PIONEER  height="32px" width="35px" />;
        case "fitbit":
          return <FITBIT  height="32px" width="35px" />;
          
          case "amazfit":
            return <AMAZFIT height="32px" width="35px" />;
            case "ticwatch":
              return <TICWATCH height="32px" width="35px" />;

              case "garmin":
              return <GRAMIN height="32px" width="35px" />;
              case "fossil":
              return <FOSSIL height="32px" width="35px" />;
              
              

            
        
      default:
        if (deviceName.includes("oneplus")) {
          return <Oneplus height="32px" width="35px" />;
        } else if (deviceName.includes("poco")) {
          return (
            <img src={poco} style={{ height: "32px", marginLeft: "1px" }} />
          );
        } else if (deviceName.includes("vivo")) {
          return <Vivo height="32px" width="35px" />;
        } else if (deviceName.includes("oppo")) {
          return <Oppo height="35px" width="35px" />;
        } else if (deviceName.includes("samsung")) {
          return <Samsung height="35px" width="35px" />;
        } else if (deviceName.includes("nokia")) {
          return <Nokia height="32px" width="35px" />;
        } else if (deviceName.includes("redmi")) {
          return <Mi height="32px" width="35px" />;
        } else if (deviceName.includes("iq")) {
          return <Vivo height="32px" width="35px" />;
        } else if (deviceName.includes("xiaomi")) {
          return <Mi height="32px" width="35px" />;
        } else if (deviceName.includes("google")) {
          return (
            <img src={google} style={{ height: "32px", marginLeft: "1px" }} />
          );
        } else {
          return <div className="datatrimmer w-12">{deviceName}</div>;
        }
    }
  }

  function BrowserVersionGradients(browserName) {
    let alterBrowserName = browserName?.toLowerCase();
    switch (alterBrowserName) {
      case "vivo":
        return "color-palette__item_vivoMobile";

      case "oppo":
        return "color-palette__item_OPPO";

      case "oneplus":
        return "color-palette__item_ONEPLUS";

      case "samsung":
        return "color-palette__item_samsung";

      case "huawei":
        return "color-palette__item_huaweimobile";
      case "mi":
        return "color-palette__item_MI";
      case "xiaomi":
        return "color-palette__item_MI";
      case "iphone":
        return "color-palette__item_APPLEmOBILE";
      case "apple":
        return "color-palette__item_APPLEmOBILE";
      case "micromax":
        return "color-palette__item_micromax";
      case "infinix":
        return "color-palette__item_infinix";
      case "sony":
        return "color-palette__item_sony";
      case "gionee":
        return "color-palette__item_gionee";
      case "poco":
        return "color-palette__item_poco";
      case "google":
        return "color-palette__item_google";
      case "lenovo":
        return "color-palette__item_lenovo";
      case "motorola":
        return "color-palette__item_motorola";
      case "itel":
        return "color-palette__item_itel";
      case "blackberry":
        return "color-palette__item_blackberry";
      case "nokia":
        return "color-palette__item_nokia";
      case "realme":
        return "color-palette__item_realme";
      case "lg":
        return "color-palette__item_LG";
      case "asus":
        return "color-palette__item_ASUS";
      case "coolpad":
        return "color-palette__item_coolpad";
      case "tecno":
        return "color-palette__item_tecno";
      case "zte":
        return "color-palette__item_zte";
      case "honor":
        return "color-palette__item_honor";
      case "iqoo":
        return "color-palette__item_iqoo";
      case "rim":
        return "color-palette__item_blackberry";
      case "nothing":
        return "color-palette__item_blackberry";

      default:
        return "color-palette__item_chrome";
    }
  }

  const handleBackgroundChange = (device) => {
    let mobile_deviceName = device?.toLowerCase();
    if (mobile_deviceName === "vivo") {
      setBgColor("#C9E9FF");
    } else if (mobile_deviceName === "oppo") {
      setBgColor("#CBE6BE");
    } else if (mobile_deviceName === "oneplus") {
      setBgColor("#FFB3C0");
    } else if (mobile_deviceName === "samsung") {
      setBgColor("#A6D7FF");
    } else if (mobile_deviceName === "mi") {
      setBgColor("#FFE3D0");
    } else if (mobile_deviceName === "xiaomi") {
      setBgColor("#FFE3D0");
    } else if (mobile_deviceName === "iphone") {
      setBgColor("#D4D4D4");
    } else if (mobile_deviceName === "apple") {
      setBgColor("#D4D4D4");
    } else if (mobile_deviceName === "micromax") {
      setBgColor("#FFE9D4");
    } else if (mobile_deviceName === "infinix") {
      setBgColor("#EAEAEA");
    } else if (mobile_deviceName === "sony") {
      setBgColor("#E2E4E6");
    } else if (mobile_deviceName === "gionee") {
      setBgColor("#FFE0CB");
    } else if (mobile_deviceName === "poco") {
      setBgColor("#FFF0C0");
    } else if (mobile_deviceName === "google") {
      setBgColor("#C8DDFF");
    } else if (mobile_deviceName === "lenovo") {
      setBgColor("#9BC6E3");
    } else if (mobile_deviceName === "motorola") {
      setBgColor("#AEDBFF");
    } else if (mobile_deviceName === "itel") {
      setBgColor("#FFCBCF");
    } else if (mobile_deviceName === "blackberry") {
      setBgColor("#E0E0E0");
    } else if (mobile_deviceName === "nokia") {
      setBgColor("#D5E0FF");
    } else if (mobile_deviceName === "realme") {
      setBgColor("#FFF7D1");
    } else if (mobile_deviceName === "lg") {
      setBgColor("#FFD7E6");
    } else if (mobile_deviceName === "asus") {
      setBgColor("#C8D9FF");
    } else if (mobile_deviceName === "coolpad") {
      setBgColor("#C8D9FF");
    } else if (mobile_deviceName === "tecno") {
      setBgColor("#B8DCFF");
    } else if (mobile_deviceName === "zte") {
      setBgColor("#B5E5FF");
    } else if (mobile_deviceName === "honor") {
      setBgColor("#FFD2D4");
    } else if (mobile_deviceName === "iqoo") {
      setBgColor("#D7D7D7");
    } else if (mobile_deviceName === "rim") {
      setBgColor("#D0D0D0");
    } else if (mobile_deviceName === "huawei") {
      setBgColor("#FFD2D4");
    } else if (mobile_deviceName === "nothing") {
      setBgColor("#D4D4D4");
    } else {
      setBgColor("#CBE8FF");
    }
  };

  const handleDocumentClick = (event) => {
    if (!event.target.closest(".tableBody_wrapper")) {
      setBgColor("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  const [activeIndex, setIsActiveIndex] = useState("");
  const handleColorChange = (index) => {
    setIsActiveIndex(index);
  };
  return (
    <>
      <div className="tableHead_wrapper">
        <table className=" text-left">
          <thead>
            <tr style={{ background: "#F2F2F2", height: "30px" }}>
              <th className="  labels" style={{ width: "14.666667%" }}>
                Defects
              </th>
              <th className=" w-1/6 labels">Device</th>
              <th className="labels">Device Model</th>
              {/* -----------------it requires in future------------------ */}
              {/* <th
                className="w-3/12 "
                style={{
                  fontFamily: "poppins-Regular",
                  color: " #6f7075",
                  fontSize: "12px",
                }}
              >
                Sort:{" "}
                <select
                  onChange={(e) => {
                    filterData(e);
                  }}
                  value={mobileFilter}
                  name="type"
                  className="mobileFilter"
                  id="deviceType"
                >
                  <option value="All">All</option>
                  <option value="smartphone">SmartPhone</option>
                  <option value="tablet">Tablet</option>
                  <option value="phablet">Phablet</option>
                </select>
              </th> */}
            </tr>
          </thead>
        </table>
      </div>
      <div className="tableBody_wrapper">
        <table className="w-full wrapper_Device text-left">
          <tbody className="mt-2">
            {deviceInfo?.deviceBrand?.map((deviceData, index) => {
              return (
                <>
                  <tr
                    style={{
                      backgroundColor:
                        index === activeIndex ? `${bgColor}` : "",
                      border: "1px solid #E5E5E5",
                      margin: "4px 0px",
                    }}
                    onClick={() => {
                      handleColorChange(index);
                      handleBackgroundChange(deviceData?.deviceBrand);
                    }}
                    key={`row-index-${index}`}
                    // className={`hover:${selectedBgColor(
                    //   deviceData?.deviceBrand
                    // )}`}
                    className={
                      deviceData?.deviceBrand.toLowerCase() === "vivo"
                        ? "hover:bg-mobileBrands-vivo_100"
                        : deviceData?.deviceBrand.toLowerCase() === "oppo"
                        ? "hover:bg-mobileBrands-oppo_100"
                        : deviceData?.deviceBrand.toLowerCase() === "oneplus"
                        ? "hover:bg-mobileBrands-oneplus_100"
                        : deviceData?.deviceBrand.toLowerCase() === "samsung"
                        ? "hover:bg-mobileBrands-samsung_100"
                        : deviceData?.deviceBrand.toLowerCase() === "huawei"
                        ? "hover:bg-mobileBrands-huawei_100"
                        : deviceData?.deviceBrand.toLowerCase() === "mi"
                        ? "hover:bg-mobileBrands-mi_100"
                        : deviceData?.deviceBrand.toLowerCase() === "xiaomi"
                        ? "hover:bg-mobileBrands-mi_100"
                        : deviceData?.deviceBrand.toLowerCase() === "iphone"
                        ? "hover:bg-mobileBrands-iphone_100"
                        : deviceData?.deviceBrand.toLowerCase() === "apple"
                        ? "hover:bg-mobileBrands-iphone_100"
                        : deviceData?.deviceBrand.toLowerCase() === "micromax"
                        ? "hover:bg-mobileBrands-micromax_100"
                        : deviceData?.deviceBrand.toLowerCase() === "infinix"
                        ? "hover:bg-mobileBrands-infinix_100"
                        : deviceData?.deviceBrand.toLowerCase() === "sony"
                        ? "hover:bg-mobileBrands-sony_100"
                        : deviceData?.deviceBrand.toLowerCase() === "gionee"
                        ? "hover:bg-mobileBrands-gionee_100"
                        : deviceData?.deviceBrand.toLowerCase() === "poco"
                        ? "hover:bg-mobileBrands-poco_100"
                        : deviceData?.deviceBrand.toLowerCase() === "google"
                        ? "hover:bg-mobileBrands-google_100"
                        : deviceData?.deviceBrand.toLowerCase() === "lenovo"
                        ? "hover:bg-mobileBrands-lenovo_100"
                        : deviceData?.deviceBrand.toLowerCase() === "motorola"
                        ? "hover:bg-mobileBrands-motorola_100"
                        : deviceData?.deviceBrand.toLowerCase() === "itel"
                        ? "hover:bg-mobileBrands-itel_100"
                        : deviceData?.deviceBrand.toLowerCase() === "blackberry"
                        ? "hover:bg-mobileBrands-blackberry_100"
                        : deviceData?.deviceBrand.toLowerCase() === "nokia"
                        ? "hover:bg-mobileBrands-nokia_100"
                        : deviceData?.deviceBrand.toLowerCase() === "realme"
                        ? "hover:bg-mobileBrands-realme_100"
                        : deviceData?.deviceBrand.toLowerCase() === "lg"
                        ? "hover:bg-mobileBrands-lg_100"
                        : deviceData?.deviceBrand.toLowerCase() === "asus"
                        ? "hover:bg-mobileBrands-asus_100"
                        : deviceData?.deviceBrand.toLowerCase() === "coolpad"
                        ? "hover:bg-mobileBrands-coolpad_100"
                        : deviceData?.deviceBrand.toLowerCase() === "tecno"
                        ? "hover:bg-mobileBrands-tecno_100"
                        : deviceData?.deviceBrand.toLowerCase() === "zte"
                        ? "hover:bg-mobileBrands-zte_100"
                        : deviceData?.deviceBrand.toLowerCase() === "honor"
                        ? "hover:bg-mobileBrands-honor_100"
                        : deviceData?.deviceBrand.toLowerCase() === "iqoo"
                        ? "hover:bg-mobileBrands-iqoo_100"
                        : deviceData?.deviceBrand.toLowerCase() === "nothing"
                        ? "hover:bg-mobileBrands-iphone_100"
                        : deviceData?.deviceBrand.toLowerCase() === "rim"
                        ? "hover:bg-mobileBrands-rim_100"
                        : "hover:bg-mobileBrands-iqoo_100"
                    }
                  >
                    <td className="deviceDefectValue w-1/6">
                      {deviceData?.defectCount}
                    </td>
                    <Tooltip
                      PopperProps={{
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            border: "1px solid #434DB8",
                            color: "deepskyblue",
                            background: " #FFFFFF 0% 0% no-repeat padding-box",
                            width: "118px",
                            // height: `${version.deviceName.length>=13}?"50px":"auto"`,
                            height: "auto",
                            borderRadius: " 10px",
                            textAlign: "center",
                            opacity: 1,
                            boxShadow: "0px 3px 6px #0000005E",
                          },
                        },
                      }}
                      title={
                        <div className="block">
                          <span className="block  text-xs">
                            {" "}
                            {deviceData?.deviceBrand}-{deviceData?.deviceType}:
                            {deviceData?.defectCount}
                          </span>

                          <span className="block  text-xs ">
                            {deviceData?.totalDefectPercent}%
                          </span>
                        </div>
                      }
                      placement="top"
                    >
                      <td
                        className=" w-1/6 cursor-pointer"
                        onClick={() => {
                          props.selectAllOs(deviceData.osList);
                          props?.selectedMobileInfos(deviceData);
                        }}
                      >
                        {selectedDeviceModel(deviceData?.deviceBrand)}{" "}
                      </td>
                    </Tooltip>
                    <td
                      className="browserVersionList flex  cursor-pointer"
                      style={{
                        width: "114%",
                        position: "relative",
                        left: " -29px",
                      }}
                    >
                      <Carousel
                        renderArrow={myArrow}
                        itemsToShow={3}
                        pagination={false}
                      >
                        {deviceData?.deviceNameList?.map((version) => {
                          return (
                            <>
                              <Tooltip
                                PopperProps={{
                                  sx: {
                                    "& .MuiTooltip-tooltip": {
                                      border: "1px solid #434DB8",
                                      color: "deepskyblue",
                                      background:
                                        " #FFFFFF 0% 0% no-repeat padding-box",
                                      width: "118px",
                                      // height: `${version.deviceName.length>=13}?"50px":"auto"`,
                                      height: "auto",
                                      borderRadius: " 10px",
                                      textAlign: "center",
                                      opacity: 1,
                                      boxShadow: "0px 3px 6px #0000005E",
                                    },
                                  },
                                }}
                                title={
                                  <div className="block">
                                    <span className="block  text-xs">
                                      {" "}
                                      {version.deviceName}:{version.defectCount}
                                    </span>

                                    <span className="block  text-xs ">
                                      {props?.mobileInfo?.deviceBrand ===
                                      deviceData?.deviceBrand
                                        ? version.deviceDefectPercent
                                        : version.totalDefectPercent}
                                      %
                                    </span>
                                  </div>
                                }
                                placement="top"
                              >
                                <div
                                  // className={
                                  //   deviceData?.deviceType === "Chrome"
                                  //     ? "color-palette__item_chrome"
                                  //     : deviceData?.deviceType === "Firefox"
                                  //     ? "color-palette__item_fox"
                                  //     : deviceData?.deviceType === "Opera"
                                  //     ? "color-palette__item_opera"
                                  //     : "color-palette__item_chrome"
                                  // }
                                  className={`${BrowserVersionGradients(
                                    deviceData?.deviceBrand
                                  )}`}
                                  onClick={() => {
                                    props.selectAllOs([{ ...version }]);
                                    props?.selectedMobileInfos({
                                      ...version,
                                      deviceBrand: deviceData?.deviceBrand,
                                      deviceType: deviceData?.deviceType,
                                    });
                                  }}
                                >
                                  <div
                                    className="text-sm datatrimmer w-20"
                                    onClick={() => {
                                      console.log(deviceData?.deviceNameList);
                                    }}
                                  >
                                    {" "}
                                    {deviceData?.deviceNameList?.length === 1
                                      ? version.deviceName
                                      : deviceData?.deviceNameList?.length === 2
                                      ? version.deviceName?.substring(0, 17)
                                      : deviceData?.deviceNameList?.length === 3
                                      ? version.deviceName?.substring(0, 13)
                                      : version.deviceName?.substring(0, 11)}
                                    {/* {version.deviceName?.substring(0, 6)} */}
                                  </div>
                                </div>
                              </Tooltip>
                            </>
                          );
                        })}
                      </Carousel>
                    </td>
                  </tr>
                </>
              );
            })}
            {deviceInfo?.deviceBrand?.length === 0 ? (
              <span className="w-full flex justify-center ">
                <NoReportsIcon height="148px" />
              </span>
            ) : null}
          </tbody>
        </table>
      </div>
      <div className="totalmobile_BrowserDefect">
        Total Defects :{" "}
        <span className="mx-1">{props?.deviceInfo?.totalDeviceDefects}</span>
      </div>
    </>
  );
};

export default MobileDeviceContent;
