import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import * as yup from "yup";
import { Field, Formik, useFormik } from "formik";
import { TextField } from "@material-ui/core";
import { addJiraAccount, updateJiraBasedOnID } from "../../../api/api-services";
function JiraInstanceCreateModal(props) {
  console.log("JiraInstanceCreateModal", props);
  const [openModal, setOpenModal] = useState(true);
  const domintimeRef = useRef();
  let userID = JSON.parse(localStorage["selected-user"]).id;
  let projectID = JSON.parse(localStorage["selected-user"]).projectId;
  let userName = JSON.parse(localStorage["selected-user"]).name;
  let [callingAPI,setCallingAPI]=useState(false)

  let initialValues;
  if (props?.mode !== "ADD") {
    initialValues = {
      userName: props?.data?.userName,
      serverUrl: props?.data?.serverUrl,
      domain: props?.data?.domain,
      apiToken: props?.data?.apiToken,
      accountId: props?.data?.accountId,
    };
  } else {
    initialValues = {
      userName: "",
      serverUrl: "",
      domain: "",
      apiToken:"",
      accountId: "",
    };
  }
  const validationSchema = yup.object({
    userName: yup
      .string()
      .ensure()
      .required("Username is required")
      .matches(
        /^(?! ).*[^ ]$/,
        "White spaces are not allowed at beginning and at end"
      ),
    serverUrl: yup
      .string()
      .ensure()
      .required("Server URL is required")
      .matches(
        /^(?! ).*[^ ]$/,
        "White spaces are not allowed at beginning and at end"
      ),
    apiToken: yup
      .string()
      .ensure()
      .required("API token is required")
      .matches(
        /^(?! ).*[^ ]$/,
        "White spaces are not allowed at beginning and at end"
      ),
    domain: yup
      .string()
      .ensure()
      .required("Domain is required")
      .matches(
        /^(?! ).*[^ ]$/,
        "White spaces are not allowed at beginning and at end"
      ),
    accountId: yup
      .string()
      .ensure()
      .required("Account ID is required")
      .matches(
        /^(?! ).*[^ ]$/,
        "White spaces are not allowed at beginning and at end"
      ),
  });

  const onSubmit = () => {
    setCallingAPI(true)
    if (props.mode === "ADD" || props.mode === "ADD_SUB") {
      createAccout();
    } else {
      updateAccount();
    }
  };
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const updateAccount = () => {
    if (
      formikDetails.values.apiToken !== "" &&
      formikDetails.values.userName !== "" &&
      formikDetails.values.serverUrl !== "" &&
      formikDetails.values.domain !== "" &&
      formikDetails.values.accountId
    ) {
      let data = {
        id: props?.data?.id,
        projectId: props?.data?.projectId,
        userId: props?.data?.userId,
        serverUrl: formikDetails.values.serverUrl,
        userName: formikDetails.values.userName,
        apiToken: formikDetails.values.apiToken,
        domain: formikDetails.values.domain,
        accountId: formikDetails.values.accountId,
        createdBy: props?.data?.createdBy,
        modifiedBy: userName,
        createdOn: props?.data?.createdOn,
      };
      if (
        props?.data?.serverUrl === data?.serverUrl &&
        props?.data?.apiToken === data?.apiToken &&
        props?.data?.userName === data?.userName &&
        props?.data?.domain === data?.domain &&
        props?.data?.accountId === data?.accountId
      ) {
        props?.MyAlert.info("No changes were done");
        return;
      }
      updateJiraBasedOnID(data, props.data._id).then((response) => {
        if (response.data.responseCode ===0 && response.data.errorCode===400) {
          props?.MyAlert.info(response.data.message);
          props.closemodal(false);
        } else if (response.data.responseCode === 200) {
          setCallingAPI(false)
          setOpenModal(false);
          props.MyAlert.success(`Jira instance successfully`);
          props.closemodal(false);
           props.reloadTree();
        }
      });
    }
  };
  const createAccout = () => {
    console.log("createAccout", formikDetails.values);

    if (
      formikDetails.values.apiToken !== "" &&
      formikDetails.values.userName !== "" &&
      formikDetails.values.serverUrl !== "" &&
      formikDetails.values.domain !== "" &&
      formikDetails.values.accountId
    ) {
      let data = {
        projectId: projectID,
        userId: userID,
        serverUrl: formikDetails.values.serverUrl,
        userName: formikDetails.values.userName,
        apiToken: formikDetails.values.apiToken,
        domain: formikDetails.values.domain,
        accountId: formikDetails.values.accountId,
        createdBy: userName,
      };
      addJiraAccount(data).then((response) => {
        console.log(" addJiraAccount",response)
        if (response.data.responseCode ===0 && response.data.errorCode===400) {
          props?.MyAlert.info(response.data.message);
          props.closemodal(false);
        } else if (
          response.data.responseCode === 200 &&
          response.data.message === "SUCCESS"
        ) {
          setCallingAPI(false)
          setOpenModal(false);
          props.MyAlert.success(`Jira instance created successfully`);
          props.closemodal(false);
          props.reloadTree();
        }
      });
    }
  };
  useEffect(() => {
    if (domintimeRef.current) {
      clearTimeout(domintimeRef.current);
    }

    domintimeRef.current = setTimeout(() => {
      try {
        new URL(formikDetails.values.serverUrl);
        const regex =
          /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/gim;
        const domainValue = regex.exec(formikDetails.values.serverUrl);
        if (domainValue && domainValue?.length > 0) {
          formikDetails.setFieldValue(
            "domain",
            domainValue[1].split(".", 1)[0]
          );
        } else {
          formikDetails.setFieldValue("domain", "");
        }
      } catch (err) {
        formikDetails.setFieldValue("domain", "");
      }
    }, 500);
    return () => clearTimeout(domintimeRef.current);
  }, [formikDetails.values.serverUrl]);

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "81.5%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };
  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px">
      <div className="create_instance">
        <div className="grid grid-cols-12  rounded-tl-xl flex header">
          <span className="col-span-11 heading pl-2.5">{props?.mode==="ADD"?"Create":"Edit"} Jira Instance</span>
          <div className="col-span-1 flex justify-end mr-1">
            <button
              onClick={() => {
                props.closemodal(false);
              }}
              type="button"
              className="modal_clos_btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div>
          <form onSubmit={formikDetails.handleSubmit}>
            <div className="px-2 pt-2 body">
              <div className="mb-5">
                <div>
                  <span className="text-red-600 mr-0.5">*</span>
                  <span className="create-labels">Account ID</span>
                </div>
                <div className="mt-1">
                  <TextField
                    error={
                      formikDetails.errors.accountId &&
                      formikDetails.touched.accountId
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    name="accountId"
                    id="accountId"
                    autoComplete="off"
                    placeholder="Enter Account ID"
                    className="create-project-values"
                   
                    onBlur={(event) => {
                      formikDetails.handleBlur(event);
                    }}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.accountId}
                    variant="outlined"
                    size="small"
                  />
                </div>
                {formikDetails.errors.accountId &&
                formikDetails.touched.accountId ? (
                  <div className="text-red-500 errortext md:mt-1">
                    {formikDetails.errors.accountId}
                  </div>
                ) : null}
              </div>

              <div className="my-5">
                <div>
                  <span className="text-red-600 mr-0.5">*</span>
                  <span className="create-labels">Server URL</span>
                </div>
                <div className="mt-1">
                  <TextField
                    error={
                      formikDetails.errors.serverUrl &&
                      formikDetails.touched.serverUrl
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    name="serverUrl"
                    id="serverUrl"
                    autoComplete="off"
                    placeholder="Enter Server URL"
                    className="create-project-values"
                    onBlur={(event) => {
                      formikDetails.handleBlur(event);
                    }}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.serverUrl}
                    variant="outlined"
                    size="small"
                  />
                </div>
                {formikDetails.errors.serverUrl &&
                formikDetails.touched.serverUrl ? (
                  <div className="text-red-500 errortext md:mt-1">
                    {formikDetails.errors.serverUrl}
                  </div>
                ) : null}
              </div>
             
              <div className="my-5">
                <div>
                  <span className="text-red-600 mr-0.5">*</span>
                  <span className="create-labels">Domain</span>
                </div>
                <div className="mt-1">
                  <TextField
                    error={
                      formikDetails.errors.domain &&
                      formikDetails.touched.domain
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    name="domain"
                    id="domain"
                    disabled
                    autoComplete="off"
                    placeholder="Enter Domain"
                    className="create-project-values"
                    onBlur={(event) => {
                      formikDetails.handleBlur(event);
                    }}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.domain}
                    variant="outlined"
                    size="small"
                  />
                </div>
                {formikDetails.errors.domain && formikDetails.touched.domain ? (
                  <div className="text-red-500 errortext md:mt-1">
                    {formikDetails.errors.domain}
                  </div>
                ) : null}
              </div>
              <div className="my-5">
                <div>
                  <span className="text-red-600 mr-0.5">*</span>
                  <span className="create-labels">User name</span>
                </div>
                <div className="mt-1">
                  <TextField
                    error={
                      formikDetails.errors.userName &&
                      formikDetails.touched.userName
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    name="userName"
                    id="userName"
                    autoComplete="off"
                    placeholder="Enter user name"
                    className="create-project-values"
                    onBlur={(event) => {
                      formikDetails.handleBlur(event);
                    }}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.userName}
                    variant="outlined"
                    size="small"
                  />
                </div>
                {formikDetails.errors.userName &&
                formikDetails.touched.userName ? (
                  <div className="text-red-500 errortext md:mt-1">
                    {formikDetails.errors.userName}
                  </div>
                ) : null}
              </div>
              <div className="my-5">
                <div>
                  <span className="text-red-600 mr-0.5">*</span>
                  <span className="create-labels">API Token</span>
                </div>
                <div className="mt-1">
                  <TextField
                    error={
                      formikDetails.errors.apiToken &&
                      formikDetails.touched.apiToken
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    name="apiToken"
                    id="apiToken"
                    autoComplete="off"
                    placeholder="Enter API Token"
                    className="create-project-values"
                    onBlur={(event) => {
                      formikDetails.handleBlur(event);
                    }}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.apiToken}
                    variant="outlined"
                    size="small"
                  />
                </div>
                {formikDetails.errors.apiToken &&
                formikDetails.touched.apiToken ? (
                  <div className="text-red-500 errortext md:mt-1">
                    {formikDetails.errors.apiToken}
                  </div>
                ) : null}
              </div>
            </div>
            <>
              <div className="pt-2 px-3 flex justify-end gap-4 button-content modal_footer">
                <button type="button" className="w-24 gray-btn ml-3 button" onClick={()=>{props.closemodal(false)}}>
                  Cancel
                </button>
                <button
                  type="submit"
                  // id="createBatch"
                  className="w-24 primary-btn button"
                  disabled={callingAPI}
                >
                  {
                    props?.mode==="ADD"?"Create":"Edit"
                  }
                  
                </button>
              </div>
            </>
          </form>
        </div>
      </div>
    </Modal>
  );
}
export default JiraInstanceCreateModal;
