import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./signup.scss";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { activateEmail,validateUserToken } from "../../api/api-services";
import Alert from "@material-ui/lab/Alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import LogotextImage from "../../assets/fire_crowd.svg";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import BackgroundDown from "../../assets/login_logo.png";
import style from "./signup.module.scss";
import cx from "classnames";
import { OTP_QUICK_NOTE } from "../../util/app-constants.js";
import { CommonLoader } from "../common/common-loader";


const REACT_APP_FIREBASE_APIKEY = "AIzaSyBOlUG6ESmIUd7r9y8LKIIQmpcJJwwjiok";
const REACT_APP_FIREBASE_AUTHDOMAIN = "flinko-a4a99.firebaseapp.com";
const REACT_APP_FIREBASE_DATABASEURL =
  "https://testoptimize-f7eb8.firebaseio.com";
const REACT_APP_FIREBASE_PROJECTID = "flinko-a4a99";
const REACT_APP_FIREBASE_STORAEBUCKET = "flinko-a4a99.appspot.com";
const REACT_APP_FIREBASE_MESSAINSENDERID = "908438350218";
const REACT_APP_MANAGEMENT_APPID = "1:908438350218:web:ea6fd145d742bdab3fc073";
const REACT_APP_MANAGEMENT_MEASUREMENTID = "G-G8TQ8S7F37";
export default function VerificationCode(props) {
  const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_APIKEY,
    authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: REACT_APP_FIREBASE_DATABASEURL,
    projectId: REACT_APP_FIREBASE_PROJECTID,
    storageBucket: REACT_APP_FIREBASE_STORAEBUCKET,
    messagingSenderId: REACT_APP_FIREBASE_MESSAINSENDERID,
    appId: REACT_APP_MANAGEMENT_APPID,
    measurementId: REACT_APP_MANAGEMENT_MEASUREMENTID,
  };
  const navigate = useNavigate();
  const [success, setSuccess] = useState({ open: false, message: "" });
  const [otp, setOtp] = useState("");
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [third, setThird] = useState("");
  const [fourth, setFourth] = useState("");
  const [five, setFive] = useState("");
  const [six, setSix] = useState("");
  const [otpValue, setOtpValue] = useState([]);
  const [mobileNo, setMobileNo] = useState("");
  const [errorContent, setErrorContent] = useState();
  const [showError, setShowError] = useState(false);
  const [errorContent2, setErrorContent2] = useState();
  const [showError2, setShowError2] = useState(false);
  const ref = React.useRef();
  const [phoneMask, setPhoneMask] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const respUrlItem = sessionStorage.getItem("respUrl");
  function getDecodedPhoneNumber(phoneNumber){
    let decodedPhoneNumber =  atob(phoneNumber).includes("+") ? atob(phoneNumber).slice(1) :atob(phoneNumber)
    return decodedPhoneNumber
  }
  const [validToken, setValidToken] = useState(false);
  const [tokenRef, setTokenRef] = useState(false);
  
  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
      window.recaptchaVerifier.render();
      const search = window.location.search;
      const userId = new URLSearchParams(search).get("userid");
      const privilege = new URLSearchParams(search).get("privilege");
      const token = new URLSearchParams(search).get("token");
      const phone = getDecodedPhoneNumber(new URLSearchParams(search).get("phone"));
      setPhoneMask(phone);
      setMobileNo("+" + phone);
      sessionStorage.setItem("userid", userId);
    }
  }, []);
  useEffect(() => {
    if (mobileNo) {
      const search = window.location.search;
      const userId = new URLSearchParams(search).get("userid");
      sessionStorage.setItem("userid", userId);
      const phone = getDecodedPhoneNumber(new URLSearchParams(search).get("phone"));
      const emailId = new URLSearchParams(search).get("emailId");
      const token = new URLSearchParams(search).get("token");
      setPhoneMask(phone);
       verifyToken(userId, emailId, token);
      //  sendOTP();
    }
  }, [mobileNo]);
  useEffect(() => {
    if (tokenRef) {
      const search = window.location.search;
      const emailId = new URLSearchParams(search).get("emailId");
       if(validToken) {
        sendOTP();
      } else {
        redirectTo(emailId);
      }
      sessionStorage.removeItem("ChangedPhoneNumber")
    }
  }, [tokenRef]);
  function redirectTo(emailId) {
    navigate(
      `/token-expired?emailId=${emailId}&activationStatus="PENDING"`
    );
  }
  function verifyToken(id, emailId, token) {
    let payload = {
      id: id,
      emailId: emailId,
      passwordToken: token,
      fromPage: "otp-verification",
      source:"FireCrowd"
    };
    validateUserToken(payload)
      .then(result => {
        if (result.data && result.data.responseCode === 200) {
          setValidToken(true);
        }
        setTokenRef(true);
      })
      .catch(error => {
        setTokenRef(true);
        console.error("Verify Token Failed!", error);
      });
  }

  useEffect(() => {
    const respUrlItem = sessionStorage.getItem("respUrl");
    if (respUrlItem === null) {
    } else if (respUrlItem === "undefined") {
    } else if (
      respUrlItem != "" &&
      respUrlItem != null &&
      respUrlItem != "undefined"
    ) {
      const ChangedPhoneNumber = sessionStorage.getItem("ChangedPhoneNumber");
      navigate(`/otp-verification?${respUrlItem}`, { replace: true });
      setMobileNo("+" + ChangedPhoneNumber);
      setSuccess({ open: true });
      setTimeout(() => {
        setSuccess({ open: false });
      }, 5000);
    } else if (respUrlItem === "") {
    }
    setTimeout(() => {
      setSuccess({ open: false });
    }, 5000);
  }, [respUrlItem !== ""]);

  function onOtpChange(e) {
    setOtp(e.target.value);
  }

  function sendOTP() {
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(mobileNo, appVerifier)
      .then((result) => {
        window.confirmationResult = result;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function registerKeypress() {
    const inputs = document.querySelectorAll("#otp > *[id]");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("keydown", function (event) {
        if (event.key === "Backspace") {
          let idx = event.currentTarget.id;
          idx = Number(idx.split("input")[1]);

          if (inputs[idx - 1] === undefined || inputs[idx - 1].value < 10) {
            if (idx - 1 !== 0) {
              inputs[idx - 2].focus();
              inputs[idx - 1].value = "";
              // inputs[i].value = "";
              otpValue[i] = inputs[i].value;
              setOtpValue(otpValue);

              event.preventDefault();
            } else if (idx - 1 == 0) {
              inputs[idx - 1].focus();
              inputs[idx - 1].value = "";
              // inputs[i].value = "";
              otpValue[i] = inputs[i].value;
              setOtpValue(otpValue);

              event.preventDefault();
            }
          } else if (inputs[i - 1] !== "" && i === inputs.length - 1) {
            inputs[i - 1].value = "";
            if (i !== 0) inputs[i - 1].focus();
          }
        } else {
          if (
            i === inputs.length - 1 &&
            inputs[i].value !== "" &&
            !event.shiftKey
          ) {
            return true;
          } else if (
            event.keyCode > 47 &&
            event.keyCode < 58 &&
            !event.shiftKey
          ) {
            inputs[i].value = event.key;
            otpValue[i] = inputs[i].value;
            setOtpValue(otpValue);

            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (
            (event.keyCode > 64 && event.keyCode < 91) ||
            event.keyCode == 190 ||
            event.keyCode == 188 ||
            event.keyCode == 191 ||
            event.keyCode == 186 ||
            event.keyCode == 222 ||
            event.keyCode == 219 ||
            event.keyCode == 221 ||
            event.keyCode == 220 ||
            event.keyCode == 189 ||
            event.keyCode == 187 ||
            event.keyCode == 219 ||
            event.keyCode == 221 ||
            event.keyCode == 192 ||
            event.keyCode == 32 ||
            event.shiftKey
          ) {
            event.preventDefault();
            return false;
          }
        }
      });
    }
  }

  registerKeypress();
  function handleKeyPress(event, index) {
    let key = event.keyCode || event.charCode;

    if (key == 8 || key == 46) return false;
    else {
      let data = [...otpValue];

      data[index] = event.target.value;

      let ob = event.target;

      setOtpValue(data);

      if (ob.nextElementSibling != null) {
        ob.nextElementSibling?.focus();
      } else {
      }
    }
  }
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  function verifyOTP(event) {
    if (createUpdateCalled) {
      setShowLoader(true);
      setCreateUpdateCalled(false);
      let data = [...otpValue];

      const result = data.filter((word) => word != "");

      let a = document.getElementById("errorId");
      if (result.length == 0) {
        let a = document.getElementById("errorId");
        a.innerHTML = "Verification code is required";
        setShowLoader(false);
      } else if (result.length == 6) {
        a.innerHTML = "";

        const search = window.location.search;
        const userId = new URLSearchParams(search).get("userid");
        const privilege = new URLSearchParams(search).get("privilege");
        const token = new URLSearchParams(search).get("token");
        const phone = getDecodedPhoneNumber(new URLSearchParams(search).get("phone"));
        setPhoneMask(phone);
        setMobileNo("+" + phone);
      } else {
        a.innerHTML = "";
        setShowLoader(false);
      }
      const myOtp = otpValue.join("");

      setShowError(false);
      window.confirmationResult
        ?.confirm(myOtp)
        .then((result) => {
          activateEmailandlogin();
        })
        .catch((error) => {
          setShowError(true);
          setErrorContent(
            "This verification code is invalid, please enter a valid code"
          );
          setShowLoader(false);
        });
    }
  }

  function activateEmailandlogin() {
    const search = window.location.search;
    const userId = new URLSearchParams(search).get("userid");
    const privilege = new URLSearchParams(search).get("privilege");
    const token = new URLSearchParams(search).get("token");
    const phone = new URLSearchParams(search).get("phone");

    if (userId && token) {
      const payload = {
        id: userId,
        passwordToken: token,
        source: "FireCrowd"
      };
      activateEmail(payload)
        .then((res) => {
          setShowLoader(false);
          navigate("/activation-successful", { replace: true });
        })
        .catch((error) => {
          console.error(error);
          setShowLoader(false);
        });
    }
  }

  React.useEffect(() => {
    document.getElementById("input1")?.focus();
  }, []);

  function MaskCharacter(str, mask, n = 1) {
    return ("" + str).slice(0, -n).replace(/./g, mask) + ("" + str).slice(-n);
  }

  return showLoader ? (
    <div><CommonLoader /></div>
    
  ) : (
    <div className="min-h-screen bg-white flex mainContainer loginbody">
      <div id="recaptcha-container"></div>
      <div className="flex-1 otp-left-container" id="leftImage">
        <div>
          <img
            className="verification_leftsideimg"
            src={LogotextImage}
            height="200px"
            width="300px"
            alt="Qspider"
          />
        </div>
        {/* <div>
            <span className="secondText">
              One application for multiple types of testing
            </span>
          </div> */}
        <div>
          <img
            className="verification_leftsideimg2"
            src={BackgroundDown}
            alt="Qspider"
          />
          <div className="flex flex-col items-center justify-center">
            <h1 className={style["codeVerifyText"]}>
              {OTP_QUICK_NOTE?.substring(0, 14)}
            </h1>
            <h2 className={style["page-note"]}>
              {OTP_QUICK_NOTE?.substring(14)}
            </h2>
          </div>
        </div>
        {/* <div>
            <h1 className="belowText ml-32">Already An User</h1>
            <label className="text-center lastText">
              Enter email id,password and access your account quickly
            </label>
          </div> */}
      </div>
      <div className="flex-1 flex flex-col otp-right-container relative justify-right py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 loginbody">
        <div className="w-full veriFicationGrid">
          {success.open && (
            <Alert
              className="alertVeri"
              iconMapping={{
                success: (
                  <CheckCircleIcon
                    className="mt-1.5 checkCircle"
                    fontSize="inherit"
                  />
                ),
              }}
            >
              <span className="Phone_success_msg">
                Phone number changed successfully
              </span>
            </Alert>
          )}
          <div class="gridContent verificationGrid warningMessages mt-20">
          <div className="logo_text_Image">
          <img
            
            src={LogotextImage}
            height="200px"
            width="300px"
            alt="Qspider"
          />
        </div>
            <h5 className="mt-6  font-extrabold text-gray-900 verificationHead">
              Verification Code
            </h5>
            <div class="warning-textotp verificationSubHead">
              Please confirm your phone number by entering the authorization
              code sent to {MaskCharacter(phoneMask, "*", 4)}
            </div>
            <div className="ifThisIs">
              If this is not your phone number then
              <a href="/phone-validation">
                <span class="font-bold" className="veriFicationCodeSpan mx-1">
                  {" "}
                  Click here
                </span>
              </a>{" "}
               to change it.
            </div>
          </div>
          <div id="otpText" className="otpText mt-10">
            <div id="otp" class="flex flex-row  text-center px-2 mt-0 relative otp_number_field">
              {[1, 2, 3, 4, 5, 6].map((item, i) => (
                <input
                  id={`input${item}`}
                  autoComplete="off"
                  onChange={(e) => {
                    handleKeyPress(e, i);
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  onKeyUp={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  class="m-2 border h-10 w-10 text-center  "
                  type="text"
                  maxlength="1"
                  onBlur={() => {
                    setCreateUpdateCalled(true);
                  }}
                />
              ))}{" "}
            </div>
            {showError && (
              <span className="verification_err_msg">{errorContent}</span>
            )}
            <span
              id="errorId"
              className= "verification_err_msg"
            ></span>
            <div class="mt-5 resendOtp relative itMayTakeText">
              <div class="msg1">It may take a minute to receive your code</div>
              <span class="flex msg2">
                <span>Haven't received the code yet? </span>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    sendOTP();
                  }}
                  class="flex text-blue-500 hover:text-blue-500 cursor-pointer"
                >
                  <span class="font-bold" className="veriFicationCodeSpan ml-1">
                    Resend a new Code.
                  </span>
                  <i class="bx bx-caret-right ml-1"></i>
                </a>
              </span>
            </div>
          </div>
          <div className="warning_button veriButton verificatiom_btn">
            <button
              type="button"
              onClick={verifyOTP}
              className="w-full flex justify-center py-2 px-4 border border-transparent  shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 verifyContinueButton"
            >
              Verify & Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
