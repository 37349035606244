import React ,{useEffect,useState}from "react";
import { useTable, useGlobalFilter } from "react-table";
import "./Dashboard.scss"
import { ReactComponent as Search } from "../../assets/search.svg";
import NoDefectIcon from "../../assets/reportsicon/no_reports_icon.png";
import { CircularLoader } from "../common/CircularLoader";



function useInstance(instance) {
    const { allColumns } = instance;
   

    let rowSpanHeaders = [];

    allColumns.forEach((column, i) => {
      const { id, enableRowSpan } = column;

      if (enableRowSpan !== undefined) {
        rowSpanHeaders = [
          ...rowSpanHeaders,
          { id, topCellValue: null, topCellIndex: 0 },
        ];
      }
    });

    Object.assign(instance, { rowSpanHeaders });
  }
function DashboardOSTable(props) {
  console.log("DashboardOSTable",props)
    let [Devicedata,setDevicedata]=useState([])
    let [dataLoding,setDataLoaing]=React.useState(false)
    let [callingAPI,setCallingAPI]=useState(true)

  useEffect(()=>{
    getAllData()

  },[props])
  const getAllData=()=>{
    const newData = [];
    let index=1;
    if(props?.label==="browser"){
      props?.data?.forEach((brandDetails) => {

        brandDetails.browserVersion?.forEach((device) => {
          newData.push({
             sl:index,
             browserName: brandDetails.browserName,
            ...device,
          });
        });
        index=index+1;
     
       } );
       setDevicedata([...newData]);
       setCallingAPI(false);
      

    }else if(props?.label==="OS"){
      props?.data?.forEach((brandDetails) => {

        brandDetails.osVersion?.forEach((device) => {
          newData.push({
             sl:index,
             osName: brandDetails.osName,
            ...device,
          });
        });
        index=index+1;
     
       } );
       setDevicedata([...newData]);
       setCallingAPI(false);
    }
    else{
      props?.data?.forEach((brandDetails) => {

        brandDetails.modelVersion?.forEach((device) => {
          newData.push({
             sl:index,
            deviceBrand: brandDetails.deviceBrand,
            type: brandDetails.type,
            ...device,
          });
        });
        index=index+1;
     
       } );
       setDevicedata([...newData]);
       setCallingAPI(false);
    }
  
  }
  const data = React.useMemo(() => Devicedata, [Devicedata]);

  let browsercolumns = React.useMemo(
    () => [
      {
        Header: "SERIAL NO",
        accessor: "sl",
        width: "15%",
        enableRowSpan: true,
        Cell: (e) => <div title={e.value}>{e.value}</div>,
      },
      {
        Header: "BROWSER",
        accessor: "browserName",
        width: "30%",
        enableRowSpan: true,
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },
      {
        Header: "BROWSER VERSION",
        accessor: "version",
        width: "30%",
      },
      {
        Header: "TOTAL USERS",
        accessor: "userCount",
        width: "30%",
        Cell: (e) => <div title={e.value}>{e.value}</div>,
      },
     
    ],
    []
  );

  let OScolumns = React.useMemo(
    () => [
      {
        Header: "SERIAL NO",
        accessor: "sl",
        width: "15%",
        enableRowSpan: true,
        Cell: (e) => <div title={e.value}>{e.value}</div>,
      },
      {
        Header: "OS",
        accessor: "osName",
        width: "30%",
        enableRowSpan: true,
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },
      {
        Header: "OS VERSION",
        accessor: "version",
        width: "30%",
        Cell: (e) => (
          <div>
            {e.value?e.value:"--"}
          </div>
        ),
      },
      {
        Header: "TOTAL USERS",
        accessor: "userCount",
        width: "30%",
        Cell: (e) => <div title={e.value}>{e.value}</div>,
      },
     
    ],
    []
  );

  let Devicecolumns = React.useMemo(
    () => [
      {
        Header: "SERIAL NO",
        accessor: "sl",
        width: "12.3%",
        enableRowSpan: true,
        Cell: (e) => <div title={e.value}>{e.value}</div>,
      },
      {
        Header: "DEVICE BRANDS",
        accessor: "deviceBrand",
        width: "20%",
        enableRowSpan: true,
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },
      {
        Header: "TYPE",
        accessor: "type",
        width: "20%",
        Cell: (e) => <div title={e.value}>{e.value==="watch"?"Smart Watch":e.value==="tv"?"Television":e.value}</div>,
      },
      {
        Header: "MODEL - VERSION",
        accessor: "version",
        width: "20%",
        
      },
      {
        Header: "TOTAL USERS",
        accessor: "userCount",
        width: "20%",
        Cell: (e) => <div title={e.value}>{e.value}</div>,
      },
     
    ],
    []
  );

  let columns=props?.label==="device"?Devicecolumns:props?.label==="browser"?browsercolumns:OScolumns
 
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    rowSpanHeaders,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, (hooks) => {
    hooks.useInstance.push(useInstance);
  });
  const { globalFilter } = state;
  return (
    <div className="grid grid-cols-12 ">
      <div className="col-span-12 mt-4 Dashboard-tabel-header grid grid-cols-12 ">
        <div className="col-span-9">{props?.header}</div>
        <div className="col-span-3 flex justify-end">
        <div className="">
                <div className="DashboardSearch">
            <div>
              <input
                type="text"
                className="searchfield"
                value={globalFilter || ""}
                autoFocus={false}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
                placeholder="Search"
              />
            </div>
            <div className="search_icon">
              <Search />
            </div>
          </div> 
                </div>
        </div>
      </div>
       <div className="col-span-12 Dashboard-table-container">
      <div className="card_table_container dashboardspanedreporttable mt-2">
        <table {...getTableProps()} className="w-full">
          <thead
            className={`text-left ${
              rows.length === 0 || data?.length === 0 ? "" : "revert "
            }`}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="defect_table_header"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    width={column.width}
                    className={`h-8 ${
                      (column.Header === "TOTAL USERS" || column.Header==="SERIAL NO")
                    
                       ? "text-center"
                       : "text-left pl-3"
                   }`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="contents">
            {
              callingAPI?(
                <div className="py-32">
                  <CircularLoader/>
                </div>
              ):(
                <>
                 {
                  rows.length>0 ?(<>
                   {
                  <>
                    {rows.map((row, i) => {
                      prepareRow(row);
    
                      for (let j = 0; j < row.allCells.length; j++) {
                        let cell = row.allCells[j];
                        let rowSpanHeader = rowSpanHeaders.find(
                          (x) => x.id === cell.column.id
                        );
    
                        if (rowSpanHeader !== undefined) {
                          if (
                            rowSpanHeader.topCellValue === null ||
                            rowSpanHeader.topCellValue !== cell.value
                          ) {
                            cell.isRowSpanned = false;
                            rowSpanHeader.topCellValue = cell.value;
                            rowSpanHeader.topCellIndex = i;
                            cell.rowSpan = 1;
                          } else {
                            rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
                            cell.isRowSpanned = true;
                          }
                        }
                      }
                      return null;
                    })}
                    {rows.map((row) => {
                      return (
                        <tr {...row.getRowProps()} className="revert h-10  border-b">
                          {row.cells.map((cell) => {
                            if (cell.isRowSpanned) return null;
                            else
                              return (
                                <td
                                  width={cell?.column?.width}
                                  rowSpan={cell.rowSpan}
                                  {...cell.getCellProps()}
                                  className={`datatrimmer reportTablecell rowSpanedTableCell  popins ${
                                    (cell.column.Header === "TOTAL USERS" || cell.column.Header==="SERIAL NO")
                                  
                                     ? "text-center"
                                     : "text-left pl-3"
                                 } ${(cell.column.Header === "OS"||cell.column.Header === "DEVICE BRANDS"||cell.column.Header === "BROWSER")?"border-r":""}`}
                                >
                                  <div
                                    className={`${
                                      cell.rowSpan ? "rowSpan_darkBlack" : null
                                    }`}
                                  >
                                    {cell.render("Cell")}
                                  </div>
                                  {cell.rowSpan ? (
                                    <div className="rowSpan_count">
                                      {/* (Total {props.detailsSection} count:{" "} */}
                                      {/* {row.original.brandtotalCount} */}
                                    </div>
                                  ) : null}
                                </td>
                              );
                          })}
                        </tr>
                      );
                    })}
                  </>
                }
                  </>):(                <>
                        <div className="noExeutionsreportsMsg">
                      <img  alt="warning" src={NoDefectIcon}/>
                      <div className="text_msg">No Relevant Data Is Found !</div>
                    </div>
                          </>)
                }
                </>
               
              )
            }
           
           
          </tbody>
        </table>
      </div>
    </div>
   
    </div>
   
    
  );
}
export default DashboardOSTable;
