import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { Label } from "../../common/inputs/myInput";
import { TextField } from "@mui/material";
import "../project.scss";
import WarningLinkDeviceModal from "./warningLinkDeviceModal";
import { logDOM } from "@testing-library/react";
import { platFormEmailSend } from "../../../api/api-services";
import { ReactComponent as HomeIcon } from "../../../assets/homeIcon.svg";
import { useNavigate } from "react-router-dom";
import { CommonLoader } from "../../common/common-loader";
import { ProgressContainerContext } from "../../common/inputs/ProgressContainer";
import { SelectDropdown } from "./select-dropdown";
import {
  GetLocation,
  getPlatformDetails,
  AddTvOrSmartWatchPlatform,
} from "../../../api/api-services";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
function AddTestingDeviceModal(props) {
  const [brandnameValidation, setBrandNameValidation] = useState(
    "Brand name is required"
  );
  const [deviceNameValidation, setDeviceNameValidation] = useState(
    "Device name is required"
  );
  const [OSValidation, setOSValidation] = useState("OS is required");
  const [OSVersionValidation, setOSVersionValidation] = useState(
    "OS Version is required"
  );

  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [activationPendingModal, setActivationPendingModal] = useState(false);
  const navigate = useNavigate();
  let allProjects = [{ id: "", name: "All Projects" }];
  let [addDevice, setAddDevice] = useState(false);

  let longLoc = "",
    latLoc = "";
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userLocationAvailable, setUserLocationAvailable] = useState(true);
  const [value, setValue] = React.useState(null);
  const [captureDetails_platform, setCaptureDetails_platform] = useState();

  // const [brandOptions, setBrandOptions] = useState([]);
  // const [selectedBrand, setSelectedBrand] = useState([]);

  // const [OSdetails, setOSDetails] = useState();
  const [DeviceName, setDeviceName] = useState(null);
  const [osVersions, setOsVersion] = useState(null);
  // const [selecteDevice, setSelectedDevice] = useState({});
  const [brandName, setBrandName] = useState(null);
  const [DeviceNameList, setDeviceNameList] = useState();
  const [osNameList, setOsNameList] = useState();
  const [osVersionList, setOsVersionList] = useState();

  let [brandNamemsg, setbrandNamemsg] = useState(false);
  let [deviceNamemsg, setdeviceNamemsg] = useState(false);
  let [osmsg, setOSmsg] = useState(false);
  let [osVersionsmsg, setOsVersionmsg] = useState(false);
  let [callingAPI, setCallingAPI] = useState(false);

  let projectId = JSON.parse(localStorage["selected-project"]).id;
  let userId = JSON.parse(localStorage["selected-user"]).id;

  const filter = createFilterOptions();

  let tempLocationArray = [
    {
      city: "Bengaluru",
      principalSubdivision: "Karnataka",
      countryName: "India",
    },
    {
      city: "Hyderabad",
      principalSubdivision: "Telangana",
      countryName: "India",
    },
    {
      city: "Chennai",
      principalSubdivision: "Tamilnadu",
      countryName: "India",
    },
    {
      city: "Kochi",
      principalSubdivision: "Kerala",
      countryName: "India",
    },
    {
      city: "Vizag",
      principalSubdivision: "Andhra Pradesh",
      countryName: "India",
    },
  ];

  const setTempLocation = () => {
    let tempIndex = Math.floor(Math.random() * 5);
    setUserCity(tempLocationArray[tempIndex]?.city);
    setUserState(tempLocationArray[tempIndex]?.principalSubdivision);
    setUserCountry(tempLocationArray[tempIndex]?.countryName);
  };

  useEffect(() => {
    if (
      JSON.parse(localStorage["selected-project"]).project?.projectType
        ?.others !== null
    ) {
      if (
        JSON.parse(localStorage["selected-project"]).project?.projectType
          ?.others?.typeOfProject !== "Standalone_Application"
      ) {
        if (
          process.env.REACT_APP_ENV === "DEV" ||
          process.env.REACT_APP_ENV === "TEST"
        ) {
          setTempLocation();
        } else {
          getUserLocation();
        }
        setAddDevice(true);
        platfromDetails();
      }
    }
  }, []);

  const platfromDetails = () => {
    let type =
      JSON.parse(localStorage["selected-project"]).project?.projectType?.others
        ?.typeOfProject === "Smart_Watches"
        ? "SmartWatch"
        : "Television";
    getPlatformDetails(type)
      .then((res) => {
        if (res.status === 200) {
          console.log("responser platform", res);
          setCaptureDetails_platform(res?.data);
          // let tempArray = [];
          // res?.data?.map((item) => {
          //   tempArray.push({
          //     label: item?.brand,
          //     value: item?.brand,
          //   });
          // });
          // setBrandOptions(tempArray);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        longLoc = position.coords.longitude;
        latLoc = position.coords.latitude;

        GetLocation(longLoc, latLoc)
          .then((res) => {
            console.log("location response", res);
            setUserLocationAvailable(true);
            setUserCity(res.data.city);
            setUserCountry(res.data.countryName);
            if (res.data.principalSubdivision === "") {
              setUserState("N/A");
            } else {
              setUserState(res.data.principalSubdivision);
            }
          })
          .catch((error) => {
            setUserLocationAvailable(false);
            console.error("location api error", error);
          });
      },
      (err) => {
        setUserLocationAvailable(false);
        // console.log("location err", err);
        props.MyAlert.info(
          "Please, Activate your location in the system settings."
        );
      }
    );
  };
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "82.5%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  let initialValues = {
    label: "",
  };
  const validationSchema = yup.object({
    label: yup
      .string()
      .required("Email/Phone number is required")
      .test("test-name", "Enter Valid Phone/Email", function (value) {
        const emailRegex =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
        let isValidEmail = emailRegex.test(value);
        let isValidPhone = phoneRegex.test(value);
        if (!isValidEmail && !isValidPhone) {
          return false;
        }
        return true;
      }),
  });
  const formikDetails = useFormik({
    initialValues,

    validationSchema,
  });
  const createTestingDevice = async () => {
    setIsLoading(true);
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    let UserId = JSON.parse(localStorage["selected-user"]).id;
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    let emailId = formikDetails.values.label;
    formikDetails.handleSubmit();
    try {
      const getdata = await platFormEmailSend(projectId, UserId, emailId);
      if (getdata.status === 200) {
        setActivationPendingModal(true);
        setIsLoading(true);
        resetProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 80,
        });
      } else {
        setActivationPendingModal(false);
        formikDetails.handleSubmit();
        setIsLoading(true);
        resetProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 80,
        });
      }

      console.log(getdata.status);
    } catch (error) {
      console.error("error", error);
    }
  };

  function handleBrand(data, label) {
    console.log("response", captureDetails_platform);
    console.log("captureDetails_platform", data);
    setDeviceName(null);
    setValue(null);
    setOsVersion(null);
    if (label === "New Value") {
      setDeviceNameList([]);
      setOsNameList([]);
      setOsVersionList([]);
    } else {
      setDeviceNameList(data?.models[0].name);
      setOsNameList(data?.models[0].os);
      setOsVersionList(data?.models[0].osVersion);
    }
  }

  function submitPlatform() {
    let hasError = false;

    if (brandName === null) {
      hasError = true;
      setbrandNamemsg(true);
    } else {
      if (brandName !== null) {
        if (brandName?.brand.trim().length === 0) {
          hasError = true;
          setbrandNamemsg(true);
          setBrandNameValidation("White space is not allowed");
        } else if (brandName?.brand.trim().length < 3) {
          hasError = true;
          setbrandNamemsg(true);
          setBrandNameValidation(
            "Brand name should contain atleast 3 characters"
          );
        } else if (brandName?.brand.trim().length > 25) {
          hasError = true;
          setbrandNamemsg(true);
          setBrandNameValidation("Brand name should contain max 25 characters");
        } else {
          hasError = false;
          setbrandNamemsg(false);
          setBrandNameValidation("");
        }
      }
    }
    if (DeviceName === null) {
      hasError = true;
      setdeviceNamemsg(true);
    } else {
      if (DeviceName !== null) {
        if (DeviceName?.name.trim().length === 0) {
          hasError = true;
          setdeviceNamemsg(true);
          setDeviceNameValidation("White space is not allowed");
        } else if (DeviceName?.name.trim().length < 3) {
          hasError = true;
          setdeviceNamemsg(true);
          setDeviceNameValidation(
            "Device name should contain atleast 3 characters"
          );
        } else if (DeviceName?.name.trim().length > 25) {
          hasError = true;
          setdeviceNamemsg(true);
          setDeviceNameValidation(
            "Device name should contain max 25 characters"
          );
        } else {
          hasError = false;
          setdeviceNamemsg(false);
          setDeviceNameValidation("");
        }
      }
    }
    if (value === null) {
      hasError = true;
      setOSmsg(true);
    } else {
      if (value !== null) {
        if (value?.os.trim().length === 0) {
          hasError = true;
          setOSmsg(true);
          setOSValidation("White space is not allowed");
        } else if (value?.os.trim().length < 3) {
          hasError = true;
          setOSmsg(true);
          setOSValidation("OS should contain atleast 3 characters");
        } else if (value?.os.trim().length > 25) {
          hasError = true;
          setOSmsg(true);
          setOSValidation("OS should contain max 25 characters");
        } else {
          hasError = false;
          setOSmsg(false);
          setOSValidation("");
        }
      }
    }
    if (osVersions === null) {
      hasError = true;
      setOsVersionmsg(true);
    } else {
      if (osVersions !== null) {
        if (osVersions?.osVersion?.trim().length === 0) {
          hasError = true;
          setOsVersionmsg(true);
          setOSVersionValidation("White space is not allowed");
        }
        // else if(osVersions?.osVersion?.trim().length<3){
        //   hasError=true;
        //   setOsVersionmsg(true)
        //   setOSVersionValidation("OS Version should contain atleast 3 characters")
        // }
        else if (osVersions?.osVersion?.trim().length > 15) {
          hasError = true;
          setOsVersionmsg(true);
          setOSVersionValidation("OS Version should contain max 15 characters");
        } else {
          hasError = false;
          setOsVersionmsg(false);
          setOSVersionValidation("");
        }
      }
    }

    if (!hasError) {
      let devicetype =
        JSON.parse(localStorage["selected-project"]).project?.projectType
          ?.others?.typeOfProject === "Smart_Watches"
          ? "SmartWatch"
          : "tv";
      let platformtype =
        JSON.parse(localStorage["selected-project"]).project?.projectType
          ?.others?.typeOfProject === "Smart_Watches"
          ? "watch"
          : "tv";
      let payload = {
        browser: "",
        browserVersion: "",
        city: userCity,
        country: userCountry,
        deviceBrand: brandName?.brand,
        deviceName: DeviceName?.name,
        deviceType: devicetype,
        os: value?.os,
        osVersion: osVersions?.osVersion,
        platformType: platformtype,
        state: userState,
      };
      AddTvOrSmartWatchPlatform(userId, projectId, payload)
        .then((res) => {
          if (
            res?.data?.statusCode === 200 &&
            res?.data?.successMessage === "Platform added successfully"
          ) {
            setCallingAPI(false);
            props?.MyAlert.success("Platform added successfully");
            props?.onCloseAddPlatform(false);
            if (props?.label === "platformDetails") {
              props?.getAllPlatform();
            }
          } else {
            props?.MyAlert.info("Platform already exists");
          }
          console.log("response", res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <>
      {addDevice ? (
        <div className="adddeviceCSS">
          <Modal
            isOpen={openModal}
            style={customStylesPage}
            className={`p-px ${openModal === false ? "" : "static"}`}
          >
            <div className="flex  rounded-tl-xl justify-between defect-template-modal-heading h-10 p-2">
              <div>Add Testing Device</div>
              {JSON.parse(localStorage.getItem("selected-user"))
                ?.configuration === null ? (
                <button
                  onClick={() => {
                    // navigate("/project/list", { replace: true });
                    navigate("/all-Projects", { replace: true });
                    localStorage.setItem(
                      "selected-projectId-Name",
                      JSON.stringify(allProjects[0])
                    );
                  }}
                >
                  <HomeIcon />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setOpenModal(false);
                    props?.onCloseAddPlatform(false);
                  }}
                  type="button"
                  className="modal-close-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div>
              <div className="Add-Device-Container pl-2 pr-5">
                <div className="my-5 ">
                  <div>
                    <span className="text-red-600">*</span>
                    <span className="create-labels">Device Brand</span>
                  </div>
                  <div className="mt-0.5 add-Device-autocomplete">
                    <Autocomplete
                      value={brandName}
                      title={brandName?.brand}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setBrandName({
                            brand: newValue,
                          });
                          handleBrand(newValue, "Dropdown Value");
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setBrandName({
                            brand: newValue.inputValue,
                          });
                          handleBrand(newValue.inputValue, "New Value");
                        } else {
                          setBrandName(newValue);
                          handleBrand(newValue, "Dropdown Value");
                        }
                        setbrandNamemsg(false);
                      }}
                      className="autocomplete"
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) =>
                            inputValue?.toLowerCase() ===
                            option?.brand?.toLowerCase()
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            brand: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={captureDetails_platform}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option?.inputValue.toUpperCase();
                        }
                        // Regular option
                        return option.brand.length > 15
                          ? option?.brand?.toUpperCase().substring(0, 15) +
                              "..."
                          : option?.brand?.toUpperCase();
                      }}
                      renderOption={(props, option) => (
                        <li
                          className="listFonts"
                          style={{ fontSize: "12px" }}
                          {...props}
                        >
                          {option?.brand}
                        </li>
                      )}
                      sx={{ width: 180 }}
                      // freeSolo
                      renderInput={(params) => (
                        <TextField
                          placeholder="Select Device brands "
                          {...params}
                        />
                      )}
                    />
                  </div>
                  {brandNamemsg && (
                    <div className="errortext ml-0.5">
                      {brandnameValidation}
                    </div>
                  )}
                </div>
                <div className="my-5">
                  <div>
                    <span className="text-red-600">*</span>
                    <span className="create-labels">Device Name</span>
                  </div>
                  <div className="mt-0.5 add-Device-autocomplete">
                    <Autocomplete
                      disabled={brandName ? false : true}
                      value={DeviceName}
                      title={DeviceName?.name}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setDeviceName({
                            name: newValue,
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setDeviceName({
                            name: newValue.inputValue,
                          });
                        } else {
                          setDeviceName(newValue);
                        }
                        setdeviceNamemsg(false);
                      }}
                      className="autocomplete"
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) =>
                            inputValue?.toLowerCase() ===
                            option?.name?.toLowerCase()
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push(inputValue);
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={DeviceNameList}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option?.inputValue.toUpperCase();
                        }
                        // Regular option
                        return option?.name?.length > 15
                          ? option?.name?.toUpperCase().substring(0, 15) + "..."
                          : option?.name?.toUpperCase();
                      }}
                      renderOption={(props, option) => (
                        <li
                          className="listFonts"
                          style={{ fontSize: "12px" }}
                          {...props}
                        >
                          {option}
                        </li>
                      )}
                      sx={{ width: 180 }}
                      // freeSolo
                      renderInput={(params) => (
                        <TextField
                          placeholder="Select Device name"
                          {...params}
                        />
                      )}
                    />
                  </div>
                  {deviceNamemsg && (
                    <div className="errortext ml-0.5">
                      {deviceNameValidation}
                    </div>
                  )}
                </div>

                <div className="my-5">
                  <div>
                    <span className="text-red-600">*</span>
                    <span className="create-labels">OS</span>
                  </div>
                  <div className="mt-0.5 add-Device-autocomplete">
                    <Autocomplete
                      disabled={DeviceName ? false : true}
                      value={value}
                      title={value?.os}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setValue({
                            os: newValue,
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setValue({
                            os: newValue.inputValue,
                          });
                        } else {
                          setValue(newValue);
                        }
                        setOSmsg(false);
                      }}
                      className="autocomplete"
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options?.some(
                          (option) =>
                            inputValue?.toLowerCase() ===
                            option?.os?.toLowerCase()
                        );
                        if (inputValue !== "" && !isExisting) {
                          // filtered.push({
                          //   inputValue,
                          //   os: `Add "${inputValue}"`,
                          // });
                          filtered.push(inputValue);
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={osNameList}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option?.inputValue?.toUpperCase();
                        }
                        // Regular option
                        return option?.os?.length > 15
                          ? option?.os?.toUpperCase().substring(0, 15) + "..."
                          : option?.os?.toUpperCase();
                      }}
                      renderOption={(props, option) => (
                        <li
                          className="listFonts"
                          style={{ fontSize: "12px" }}
                          {...props}
                        >
                          {option}
                        </li>
                      )}
                      sx={{ width: 180 }}
                      // freeSolo
                      renderInput={(params) => (
                        <TextField placeholder="Select OS" {...params} />
                      )}
                    />
                  </div>
                  {osmsg && (
                    <div className="errortext ml-0.5">{OSValidation}</div>
                  )}
                </div>
                <div className="my-5">
                  <div>
                    <span className="text-red-600">*</span>
                    <span className="create-labels">OS Version</span>
                  </div>
                  <div className="mt-0.5 add-Device-autocomplete">
                    <Autocomplete
                      disabled={value ? false : true}
                      value={osVersions}
                      title={osVersions?.osVersion}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setOsVersion({
                            osVersion: newValue,
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setOsVersion({
                            osVersion: newValue.inputValue,
                          });
                        } else {
                          setOsVersion(newValue);
                        }
                        setOsVersionmsg(false);
                      }}
                      className="autocomplete"
                      filterOptions={(options, params) => {
                        console.log("options", options);
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) =>
                            inputValue?.toLowerCase() ===
                            option.osVersion?.toLowerCase()
                        );

                        if (inputValue !== "" && !isExisting) {
                          // filtered.push({
                          //   inputValue,
                          //   osVersion: `Add "${inputValue}"`,
                          // });
                          filtered.push(inputValue);
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={osVersionList} // Extract osVersion from the first item or provide an empty array
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }

                        if (option.inputValue) {
                          return option.inputValue.toUpperCase();
                        }

                        return option.osVersion;
                      }}
                      renderOption={(props, option) => (
                        <li
                          className="listFonts"
                          style={{ fontSize: "12px" }}
                          {...props}
                        >
                          {option}
                        </li>
                      )}
                      sx={{ width: 180 }}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Select OS version"
                          {...params}
                        />
                      )}
                    />
                  </div>
                  {osVersionsmsg && (
                    <div className="errortext ml-0.5">
                      {OSVersionValidation}
                    </div>
                  )}
                </div>

                <div className="my-5">
                  <div>
                    <span className="text-red-600">*</span>
                    <span className="create-labels">City</span>
                  </div>
                  <div className="mt-0.5">
                    <TextField
                      disabled={true}
                      className="w-60"
                      value={userCity}
                      size="small"
                      fullWidth
                    />
                  </div>
                </div>

                <div className="my-5">
                  <div>
                    <span className="text-red-600">*</span>
                    <span className="create-labels">State</span>
                  </div>
                  <div className="mt-0.5">
                    <TextField
                      disabled={true}
                      className="w-60"
                      value={userState}
                      size="small"
                      fullWidth
                    />
                  </div>
                </div>
                <div className="my-5">
                  <div>
                    <span className="text-red-600">*</span>
                    <span className="create-labels">Country</span>
                  </div>
                  <div className="mt-0.5">
                    <TextField
                      disabled={true}
                      className="w-60"
                      value={userCountry}
                      size="small"
                      fullWidth
                    />
                  </div>
                </div>
              </div>
              <div className="pt-3 px-3 flex justify-end gap-4 button-content modal_footer Add-Device-Container_btn">
                {JSON.parse(localStorage.getItem("selected-user"))
                  ?.configuration === null ? (
                  ""
                ) : (
                  <button
                    onClick={() => {
                      if (props?.mode === "defect") {
                        setOpenModal(false);
                        props?.onCloseAddPlatform(false);
                      } else {
                        setOpenModal(false);

                        props?.onCloseAddPlatform(false);
                      }
                    }}
                    type="button"
                    className="w-24 gray-btn ml-3 button"
                  >
                    Cancel
                  </button>
                )}
                <button
                  onClick={() => {
                    setCallingAPI(true);
                    submitPlatform();
                  }}
                  className="w-24 primary-btn button"
                  disabled={callingAPI ? true : false}
                >
                  Add
                </button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <>
          <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
            <div className="flex  rounded-tl-xl justify-between defect-template-modal-heading h-10 p-2">
              <div>Add Testing Device</div>
              {JSON.parse(localStorage.getItem("selected-user"))
                ?.configuration === null ? (
                <button
                  onClick={() => {
                    // navigate("/project/list", { replace: true });
                    navigate("/all-Projects", { replace: true });
                    localStorage.setItem(
                      "selected-projectId-Name",
                      JSON.stringify(allProjects[0])
                    );
                  }}
                >
                  <HomeIcon />
                </button>
              ) : (
                <button
                  onClick={() => {
                    setOpenModal(false);
                    props?.onCloseAddPlatform(false);
                  }}
                  type="button"
                  className="modal-close-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>

            <div className="overflow-y-scroll " id="journal-scroll">
              <div className="my-4  px-2">
                <div className="mb-4">
                  <Label
                    label={
                      <span className="textFieldHeader text-sm  popins-medium  ">
                        Email/Phone Number
                      </span>
                    }
                    required={true}
                    error={
                      formikDetails.errors.label && formikDetails.touched.label
                    }
                  />
                </div>
                <TextField
                  error={
                    formikDetails.errors.label && formikDetails.touched.label
                  }
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  name="label"
                  id="label"
                  className="custom_height"
                  autoComplete="off"
                  placeholder="Type here"
                  onBlur={formikDetails.handleBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.label}
                />
                {formikDetails.errors.label && formikDetails.touched.label ? (
                  <div className="text-red-500 error_text md:mt-1">
                    {formikDetails.errors.label}
                  </div>
                ) : null}
              </div>
              <div className="content-box px-2">
                <p>
                  <span className="notes">Note :</span> &nbsp;Device
                  Verification link will be sent to entered Email ID or Mobile
                  no.
                </p>
              </div>

              <div className="py-2 px-3 flex justify-end gap-4 user-create-button z-50">
                {JSON.parse(localStorage.getItem("selected-user"))
                  ?.configuration === null ? (
                  ""
                ) : (
                  <button
                    onClick={() => {
                      if (props?.mode === "defect") {
                        setOpenModal(false);
                        props?.onCloseAddPlatform(false);
                      } else {
                        setOpenModal(false);

                        props?.onCloseAddPlatform(false);
                      }
                    }}
                    type="button"
                    className="w-24 gray-btn ml-3 button"
                  >
                    Cancel
                  </button>
                )}

                <button
                  className="w-24 primary-btn button"
                  disabled={
                    formikDetails.errors.label && formikDetails.touched.label
                  }
                  onClick={() => {
                    createTestingDevice();
                  }}
                >
                  Send
                </button>
              </div>
            </div>
            {!isLoading ? null : ""}
            {activationPendingModal && (
              <WarningLinkDeviceModal
                openModal={openModal}
                setActivationPendingModal={props.onCloseAddPlatform}
                prevEmail={formikDetails.values.label}
              />
            )}
          </Modal>
        </>
      )}
    </>
  );
}
export default AddTestingDeviceModal;
