import React, { useState, useEffect } from "react";
import "./integration.scss";
import { Tooltip } from "@mui/material";
import { ReactComponent as AddIcon } from "../../assets/addIcon.svg";
import { useTable, useGlobalFilter } from "react-table";
import NoRelevantDataIcon from "../../assets/reportsicon/no_reports_icon.png";
import JiraInstanceCreateModal from "./integration-modals/jira-instance-create-modal";
import { getAllJiraList, DeleteJiraInstance } from "../../api/api-services";
import { ReactComponent as DeleteIcon } from "../../assets/deleteBlack.svg";
import { ReactComponent as EditIcon } from "../../assets/editBlack.svg";
import ContextMenu from "../../pages/modal/contex-modal";
import JiraInstanceDetailsModal from "./integration-modals/jira-instance-details";
import InstanceWarningPopup from "./integration-modals/jira-instance-warning-modal";
import IconButton from "@mui/material/IconButton";

function JiraIntegrationHome(props) {
  let [tableData, setTableData] = useState([]);
  let [createinstance, setCreateInstance] = useState(false);
  const [reloadTree, setReloadTree] = useState(true);
  const [account, setAccount] = useState([]);
  const [accountData, setAccountData] = useState();
  const [mode, setMode] = useState("");
  let projectID = JSON.parse(localStorage["selected-project"]).id;
  const [detailsPopup, setDetailsPopup] = useState(false);
  const [instancedata, setInstanceData] = useState("");
  const [deletePopup, setDeletePopUp] = useState(false);
  const [callingAPI,setCallingAPI]=useState("")

  const contextMenuData = [
    {
      option: "Details",
    },
  ];

  function contextMenuFunction(event, resObj) {
    console.log("data", resObj);
    if (event.target.textContent === "Details") {
      setDetailsPopup(true);
      setInstanceData(resObj);
    }
  }

  const deleteInstnace = (id) => {
    DeleteJiraInstance(id)
      .then((res) => {
        console.log("deleteInstnace", res);
        if (
          res?.data?.responseCode === 200 &&
          res?.data?.message === "SUCCESS"
        ) {
          props?.MyAlert?.success("Jira Instance Deleted");
          setReloadTree(true);
        }
      })
      .catch((error) => {
        console.log("");
      });
  };

  let reloadpage = () => {
    setReloadTree(true);
  };
  useEffect(() => {
    if (reloadTree) {
      getAllJira();
    }
    setReloadTree(false);
  }, [reloadTree]);

  const getAllJira = () => {
    setCallingAPI(false)
    getAllJiraList(projectID)
      .then((response) => {
        if (response.data.responseObject) {
          response.data.responseObject.map((res, i) => {
            res.Actions = [];
            res.Actions.push(
              <div className="flex action" style={{ margin: "3%" }}>
             
                  <div className="editIcon">
                    <Tooltip title={"Edit"} placement="top">
                      <IconButton
                        sx={{
                          "&:hover": {
                            backgroundColor: "#FAF9F6",
                            boxShadow:
                              " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                          },
                        }}
                        onClick={() => {
                          setMode("EDIT");
                          setAccountData(res);
                          setCreateInstance(true);
                        }}
                      >
                        <button>
                          <EditIcon height="15px" width="15px" />
                        </button>
                      </IconButton>
                    </Tooltip>
                  </div>
               
                <div className="editIcon">
                  <Tooltip title={"Delete"} placement="top">
                    <IconButton
                      sx={{
                        "&:hover": {
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }}
                      onClick={() => {
                        setDeletePopUp(true);
                        setAccountData(res);
                      }}
                    >
                      <button>
                        <DeleteIcon
                          height="15px"
                          width="15px"
                          //  className="float-left cursor-pointer text-base mr-3 text-blue-700"
                        />
                      </button>
                    </IconButton>
                  </Tooltip>
                </div>

                <div>
                  <ContextMenu
                    contextMenuData={contextMenuData}
                    func={(e) => {
                      contextMenuFunction(e, res);
                    }}
                    onMouseOutz
                  />
                </div>
              </div>
            );
            res.slNo = i + 1;
          });
          setAccount((d) => response.data.responseObject);
          setCallingAPI(true)
        } else {
          setAccount([]);
          setCallingAPI(true)
        }
      })
      .catch((err) => {
        setCallingAPI(true)
        console.error("GET_JIRA_LIST :", err);
      });
  };

  const closemodal = () => {
    setCreateInstance(false);
  };
  const closedetailsPopup = () => {
    setDetailsPopup(false);
  };
  const CloseWarningPopUp = () => {
    setDeletePopUp(false);
  };
  let columns = React.useMemo(
    () => [
      {
        Header: "ACCOUNT ID",
        accessor: "accountId",
        width: "12%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              // style={{ marginLeft: "3%" }}
              className="datatrimmer w-24"
            >
              {value}
            </div>
          );
        },
      },
      
      {
        Header: "SERVER URL",
        accessor: "serverUrl",
        width: "12%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "1%" }}
              className="datatrimmer w-24"
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "DOMAIN",
        accessor: "domain",
        width: "12%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "1%" }}
              className="datatrimmer w-24"
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "USER NAME",
        accessor: "userName",
        width: "12%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "2%" }}
              className="datatrimmer w-24"
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "API TOKEN",
        accessor: "apiToken",
        width: "12%",
        Cell: ({ value }) => {
          return (
            <div
              // title={value}
              style={{ marginLeft: "1%" }}
              className="w-24 encode apitoken_trimmer"
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "CREATED BY",
        accessor: "createdBy",
        width: "12%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "3%" }}
              className="datatrimmer w-24"
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "CREATED ON",
        accessor: "createdOn",
        width: "12%",
        Cell: ({ value }) => {
          return (
            <div
              // title={value}
              style={{ marginLeft: "4%" }}
              className="datatrimmer w-24"
            >
              {value.split(" ")[0]}
            </div>
          );
        },
      },
      {
        Header: "ACTIONS",
        accessor: "Actions",
        width: "12%",
      },
    ],
    []
  );
  let data = React.useMemo(() => account, [account]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;

  return (
    <div className="integration-Container">
      <div className="integration-Container-header grid grid-cols-12">
        <div className="col-span-10 pl-3 heading-text">Jira configuration </div>
        <Tooltip title="Create Instance">
          <div
            className="grid grid-cols-12 col-span-2 add_instance_btn cursor-pointer mr-2"
            onClick={() => {
              setMode("ADD");
              setCreateInstance(true);
            }}
          >
            <div className="col-span-2 add_instance_btn_icon">
              <AddIcon />
            </div>
            <div className="col-span-7  instance_text">Instance</div>
          </div>
        </Tooltip>
      </div>
      <div className="integration-Container-body">
       
        <div className="tablecontainer">
          <table {...getTableProps()}>
            <thead className=" table_column_header h-10">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      width={column.width}
                      {...column.getHeaderProps()}
                      className="text-left"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {data.length === 0 ? (
              
                callingAPI && (
                  <>
                  <div className="user_list_page_msg grid grid-cols-12">
                  <div className="col-span-12 flex nodata_quick_msg justify-center">
                    <div className="text_msg">Click</div>
                    <div className="quick_msg_btn">
                      <Tooltip title="Add">
                        <div
                          className="grid grid-cols-12 col-span-2 add_instance_btn cursor-pointer mr-2"
                          onClick={() => {
                            setMode("ADD");
                            setCreateInstance(true);
                          }}
                        >
                          <div className="col-span-2 add_instance_btn_icon">
                            <AddIcon />
                          </div>
                          <div className="col-span-7  instance_text">
                            Instance
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                    <div className="text_msg">to create instnace</div>
                  </div>
                </div>
                  </>
                )
              
             
            ) : (
              // <tbody {...getTableBodyProps()} className="border-t-2">
              //   {rows.length > 0 ? (
              //     <>
              //       {rows.map((row) => {
              //         prepareRow(row);
              //         return (
              //           <tr
              //             {...row.getRowProps()}
              //             className="h-10 table_cell_value configHover border-b-2 text-left"
              //             // id="userlist"
              //           >
              //             {row.cells.map((cell) => {
              //               return (
              //                 <td
              //                   {...cell.getCellProps()}
              //                   className="datatrimmer w-24 text-left"
              //                 >
              //                   {cell.render("Cell")}
              //                 </td>
              //               );
              //             })}
              //           </tr>
              //         );
              //       })}
              //     </>
              //   ) : (
              //     <>
                   
              //       <div className="no_relevant_data">
              //         <img alt="warning" src={NoRelevantDataIcon} />
              //         <div className="text_msg">
              //           No Relevant Data Is Found !
              //         </div>
              //       </div>
              //     </>
              //   )}
              // </tbody>
              <tbody {...getTableBodyProps()} className="border-t-2" >
              {rows.length > 0 ? (
                <>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="h-10 table_cell_value configHover border-b-2"
                        id="userlist"
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  
                  <div className="no_relevant_data">
                    <img  alt="warning" src={NoRelevantDataIcon}/>
                    <div className="text_msg">No Relevant Data Is Found !</div>
                  </div>
                </>
              )}
            </tbody>
            
            )}
          </table>
        </div>
      </div>
      {createinstance && (
        <JiraInstanceCreateModal
          closemodal={closemodal}
          reloadTree={reloadpage}
          mode={mode}
          MyAlert={props.MyAlert}
          data={accountData}
        />
      )}
      {detailsPopup && (
        <JiraInstanceDetailsModal
          closemodal={closedetailsPopup}
          data={instancedata}
        />
      )}
      {deletePopup && (
        <InstanceWarningPopup
          closemodal={CloseWarningPopUp}
          removeData={deleteInstnace}
          data={accountData}
        />
      )}
    </div>
  );
}
export default JiraIntegrationHome;
