import { Tooltip } from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import TableTree from "./table_tree";

const getContainerPillValue = (label, data) => {
  switch (label) {
    case "Module":
      return "M " + data.subModuleCount;
    case "Script":
      return "M " + data.subModuleCount;
    case "Folder":
      return "F " + data.subFolderCount;
    case "Page":
      return "P " + data.subPageCount;
    case "Library":
      return "L " + data.subLibraryCount;
    default:
      return "";
  }
};

const TreeModal = (props) => {
  console.log("TreeModal22343",props)
  const customStylesPage = {
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "fit-content",
      width: "645px",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderRadius: "4px",
      opacity: "1",
    },
  };
  const [openModal, setOpenModal] = useState(true);
  const [selectedNode, setSelectedNode] = useState([]);
  const [nodeSelectlength,setNodeSelectLength]=useState(props.defaultCheckedNode)
  const colDefs = [
    {
      field: "title",
      title: ["Module", "Script"].includes(props.label)
        ? "MODULE"
        : props.label,
      class: "title",
      isTitle: true,
      width: "",
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                <span className="folder-count count-badge">
                  {" "}
                  {getContainerPillValue(props.label, nodeObj.data)}{" "}
                </span>
                {!props.moduleSelection && (
                  <span className="file-count count-badge">
                    {" "}
                    S{nodeObj.data.scriptCount}{" "}
                  </span>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      field: "scriptType",
      title: "TYPE",
      class: "Type",
      width: "70px",
      render: (nodeObj) => {
        return (
          <span className="text-xs table-non-link-color-common capitalize">
            {nodeObj.data.scriptType?.toLowerCase()}
          </span>
        );
      },
    },
  ];
  const onCheckedNodes = (node) => {
    setSelectedNode([...node]);
    setNodeSelectLength([...node])
  };
  const handleSubmit = () => {
    console.log("handleSubmit",selectedNode)
  
    props.handleNodeSelect(selectedNode);
    setOpenModal(false);
    props.closeModal(false);
  };

  return (
    <>
      <div>
        <div className="border-blue-100 mt-px mb-px pb-3">
          <div className="">
            <div
              className="overflow-auto modal-tree-height"
              id="journal-scroll"
            >
              <TableTree
                showOnlyFolders={props.moduleSelection ? true : false}
                defaultDisabled={
                  props.defaultDisabled ? props.defaultDisabled : []
                }
                defaultChecked={
                  props.defaultCheckedNode ? props.defaultCheckedNode : []
                }
                disableRootRadioButton={
                  props.disableRootRadioButton
                    ? props.disableRootRadioButton
                    : false
                }
                id={"radioBtnTree"}
                data={props.data ? props.data : []}
                rootData={props.rootData ? props.rootData : []}
                colDefs={props.moduleSelection ? colDefs.slice(0, 1) : colDefs}
                filter={""}
                noHeader={true}
                expandAll={false}
                hideElements={props.hideElements}
                hideElementsBtn={props.hideElementsBtn}
                hideRootRadioBtn={props.hideRootRadioBtn}
                hideConditions={true}
                showCheckbox={false}
                showRadioButton={true}
                conditions={[
                  { key: "title", value: "Preconditions" },
                  { key: "title", value: "Postconditions" },
                ]}
                hideConditionBtn={true}
                onCheckedNodes={onCheckedNodes}
                labels={props.labels}
                onRootNodeClicked={() => {}}
                onNodeSelected={() => {}}
                modal={props.modal}
              />
            </div>
          </div>
        </div>
        <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="w-24 gray-btn ml-3 button"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="w-24 primary-btn button"
            disabled={(nodeSelectlength?.length===0)?true:false}
          >
            Select
          </button>
        </div>
        {/* <div className="float-right my-3 mx-5">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="gray-btn"
          >
            Cancel
          </button>
          <button
            type="button"
            className="primary-btn ml-3"
            onClick={handleSubmit}
          >
            Select
          </button>
        </div> */}
      </div>
    </>
  );
};

const TreeWithRadioButton = (props) => {
  const [treeData, setTreeData] = useState([]);
  const [rootData, setRootData] = useState([]);
  const [selectedNode, setSelectedNode] = useState();
  const [selectedNodeName, setSelectedNodeName] = useState(
    props.operation === "edit"
      ? props.placeholder
      : props.editDefect
      ? props.moduleName
      : ""
  );
  const [isNodeSelected, setIsNodeSelected] = useState(
    props.operation === "edit" || props.editDefect ? true : false
  );
  const [defaultCheckedNode, setDefaultCheckedNode] = useState(props.defaultCheckedNode || []);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [hideRootRadioBtn, setHideRootRadioBtn] = useState(
    props.hideRootRadioBtn ? props.hideRootRadioBtn : false
  );
  const handleNodeSelect = (nodeObj) => {
    if (nodeObj.length) {
      setIsNodeSelected(true);
      setSelectedNode(nodeObj);
      setDefaultCheckedNode([{ key: "key", value: nodeObj[0].data._key }]);
      setSelectedNodeName(nodeObj[0].data.title);
      props.nodeSelected(nodeObj);
    } else {
      setIsNodeSelected(false);
      setSelectedNode(nodeObj);
      setDefaultCheckedNode([]);
      setSelectedNodeName("");
      props.nodeSelected(nodeObj);
    }
  };
  const handleChooseNode = () => {
    console.log("selectedNodeName",selectedNodeName)
    props?.selectingTree(true);
    setOpenSelectModal(true);
  };
  const closeModal = (value) => {
    props?.selectingTree(false);
    setOpenSelectModal(value);
  };
  function getNameAndIcon() {
    return (
      <div className="table-non-link-color-common font-style-common flex">
        <Tooltip title={selectedNodeName} placement="top">
          <div
            className={`ml-2 mr-3  flex items-center justify-center select-parent-btn w-40 cursor-pointer ${
              props.operation === "edit" ? "opacity-50" : ""
            }`}
          >
            <p className="datatrimmer" title={selectedNodeName}>
              {selectedNodeName}
            </p>
          </div>
        </Tooltip>

        {props.operation !== "edit" && (
          <button
            type="button"
            onClick={handleChooseNode}
            disabled={props.operation === "edit"}
            className={`${props.operation === "edit" ? "opacity-50" : ""}`}
          >
            <Tooltip title="Replace" placement="top">
              <span className="replace-icon-styles">Replace</span>
              {/* <Sync color="primary" fontSize="small" /> */}
            </Tooltip>
          </button>
        )}
      </div>
    );
  }

  useEffect(() => {
    if (props.moduleName === "") {
      setIsNodeSelected(false);
    }
    if (props.editDefect) {
      setSelectedNodeName(props.moduleName);
    }
  }, [props.moduleName, props.editDefect]);

  useEffect(() => {
    const tree = [...props.data];
    if (tree.length) {
      setRootData({ ...tree[0] });
      if (props.individualTree && !tree[0].title?.startsWith("Root")) {
        setTreeData([...tree]);
        setHideRootRadioBtn(true);
      } else {
        if (tree[0].children) {
          setTreeData([...tree[0].children]);
        } else {
          setTreeData([]);
        }
      }
    } else {
      setTreeData([]);
      setRootData([]);
    }
  }, [props.data, props.individualTree]);

  return (
    <div className="py-2">
      {isNodeSelected && !openSelectModal ? (
        getNameAndIcon()
      ) : !openSelectModal ? (
        <button
          type="button"
          className="select-parent-btn mx-2 px-4"
          onClick={handleChooseNode}
        >
          Choose {props.buttonLabel}
        </button>
      ) : null}
      {openSelectModal && (
        <TreeModal
          moduleSelection={props.moduleSelection}
          label={props.buttonLabel}
          data={treeData}
          rootData={rootData}
          disableRootRadioButton={props.disableRootRadioButton}
          hideRootRadioBtn={hideRootRadioBtn}
          defaultCheckedNode={defaultCheckedNode}
          defaultDisabled={props.defaultDisabled}
          hideElements={props.hideElements}
          hideElementsBtn={props.hideElementsBtn}
          handleNodeSelect={handleNodeSelect}
          closeModal={closeModal}
          modal={props.modal}
        />
      )}
    </div>
  );
};

export default TreeWithRadioButton;
