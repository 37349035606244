import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Close from "@mui/icons-material/Close";
import { blue } from "@mui/material/colors";
import dateFormat from "dateformat";
import PlatformTable from "./platform-table";


function TesterDeatilsPopup(props) {
  console.log("props.testerDeatils",props.testerDeatils)
  const [openModal, setOpenModal] = useState(true);
  const [testerData, setTesterData] = useState(props.testerDeatils);
  let projectType=JSON.parse(localStorage.getItem('selected-project')).project.projectType.web!==null?"WEB":
    JSON.parse(localStorage.getItem('selected-project')).project.projectType.mobile!==null?"MOBILE": JSON.parse(localStorage.getItem('selected-project')).project.projectType.others!==null?"OTHERS":"WEB/MOBILE";
    let typeofProject=JSON.parse(localStorage.getItem('selected-project')).project.projectType.others?.typeOfProject;
  
  const customStylesPage = {
    overlay: {
      background:"rgba(90, 90, 90,0.5)", 
      
    },
    content: {
      position: "fixed",
      top: "50%",
      left: `${projectType==="WEB"||projectType==="MOBILE" ?"75%":"68.5%"}`,
      transform: "translate(-50%, -50%)",
      width: `${projectType==="WEB"||projectType==="MOBILE"?"50%":"63%"}`,
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };
  function emailMask(data) {
    let length = data.length;
    let email = "*********" + data.slice(length / 2, length);
    return email;
  }
  function getRole(role){
    switch(role){
      case "PROJECT_OWNER":{
        return "Project Owner"
      }
      case "PROJECT_MANAGER":{
        return "Project Manager"
      }
      case "ACCOUNT_MANAGER":{
        return "Account Manager"
      }
      case "TEST_LEAD":{
        return "Test Lead"
      }
      case "TESTER":{
        return "Test Engineer"
      }
      case "ADMIN":{
        return "Admin"
      }
      default:{
        return role;
      }
    }

  }
  function getstatus(val){
    switch(val){
      case "ACTIVE":{
        return "Active"
        break;
      }
      case "PENDING":{
        return "Pending"
        break;
      }
      default:{
        return val
        break;
      }
    

    }
  }

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
      <div className="tester_deatils">
        <div className="grid grid-cols-12   flex header rounded-tl-xl">
          <span className="col-span-11 heading pl-2">
            User Details - {testerData?.name}
          </span>
          <div className="col-span-1 flex justify-end mr-1">
             <button
          onClick={() => {
            
            props.CloseTesterModal(false);
          }}
          type="button"
          className="modal_clos_btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
            
          </div>
        </div>
        <div className=" pt-1 body">
          <div className="grid grid-cols-12 flex pl-4">
            <div className="col-span-4">
              <span className="labels">Name</span>
              <br></br>
              <div className="values datatrimmer w-20" title={testerData?.name}>{testerData?.name}</div>
              
            </div>
            <div className="col-span-4">
              <span className="labels">Status</span>
              <br></br>
              <div className={`${testerData?.status === "ACTIVE" ? "status" : "pending-status"}`}> {testerData.status === null ? "--" : getstatus(testerData.status)}</div>
            </div>
            <div className="col-span-4">
              <span className="labels">Role</span>
              <br></br>
              <div className="values datatrimmer w-20">{getRole(testerData?.role)}</div>
            </div>
          </div>
          <div className="grid grid-cols-12 flex h-12 mt-4 pl-4">
            <div className="col-span-4">
              <span className="labels">Created By</span>
              <br></br>
              {JSON.parse(localStorage.getItem("user-privilege")).role ===
              "PROJECT_OWNER" ? (
                <span className="values">
                  {testerData?.createdBy !== null
                    ? emailMask(testerData?.createdBy)
                    : "--"}
                </span>
              ) : (
                <span className="values">
                  {testerData?.createdBy !== null
                    ? testerData?.createdBy
                    : "--"}
                </span>
              )}
              {/* <span className="values">{testerData?.createdBy !== null ?testerData?.createdBy:"--"}</span> */}
            </div>
            <div className="col-span-4">
              <span className="labels">Modified By</span>
              <br></br>
              <span className="values">{testerData?.modifiedBy !== null ?testerData?.modifiedBy:"--"}</span>
            </div>
            <div className="col-span-4">
              <span className="labels">Modified On</span>
              <br></br>
              <span className="values">{testerData?.modifiedOn !== null ?dateFormat(testerData?.modifiedOn,"dd/mm/yyyy"):"--"}</span>
            </div>
          </div>
          <div className="grid grid-cols-12 flex h-12 mt-4 pl-4">
            <div className="col-span-4">
              <span className="labels">Created On</span>
              <br></br>
              <span className="values">{testerData?.createdOn !== null ?dateFormat(testerData?.createdOn,"dd/mm/yyyy").replaceAll("/","-"):"--"}</span>
            </div>
            
            

            <div className="col-span-4">
              <span className="labels">No Of Defects</span>
              <br></br>
              <span className="values">
              {testerData?.approvedDefectCount ||
                testerData?.totalDefectCount ? (
                  <span>
                    <span title={"Approved Defects"}>
                      {testerData?.approvedDefectCount === null ||
                      testerData?.approvedDefectCount <= 0
                        ? 0
                        : testerData?.approvedDefectCount}
                    </span>
                    /
                    <span title={"Total Defects"}>
                      {testerData?.totalDefectCount === null ||
                      testerData?.totalDefectCount <= 0
                        ? 0
                        : testerData?.totalDefectCount}
                    </span>
                  </span>
                ) : (
                  "--"
                )}
                {/* {!testerData.totalDefectCount  ? "--" : testerData.totalDefectCount} */}
              </span>
            </div>
          </div>

          {/* <div className="grid grid-cols-12 flex h-12 mt-4">
            <div className="col-span-4">
              <span className="labels">Device</span>
              <br></br>
              <span className="values"> {testerData.device === null ? "--" : testerData.device}</span>
            </div>
            <div className="col-span-4">
              <span className="labels">OS</span>
              <br></br>
              <span className="values">{testerData.os === null ? "--" : testerData.os}</span>
            </div>
            <div className="col-span-4">
              <span className="labels">Browser</span>
              <br></br>
              <span className="values">{testerData.browser === null ? "--" : testerData.browser}</span>
            </div>
          </div> */}
         
         {
          testerData.configuration !==null && (
            <>
            <div className="w-full border-t-2 pr-5 mt-4 border-dotted"></div>
            {
              projectType==="OTHERS"?(
                <div className="mt-4 mb-2 pl-4 platform-header">{typeofProject.replace("_"," ").toUpperCase()}-PLATFORM-DETAILS-{testerData?.configuration?.configPlatformDetails.length}</div>
              ):(<div className="mt-4 mb-2 pl-4 platform-header">{projectType}-PLATFORM-DETAILS-{testerData?.configuration?.configPlatformDetails.length}</div>)
            }
            
            <PlatformTable details={testerData?.configuration?.configPlatformDetails}/>
            </>
           
          )

         }
        
        </div>
       

        <>
          <div className="grid grid-cols-12 col-span-12  user-details_footer pt-2">
            <div className="col-span-10"></div>
            <div className="grid grid-cols-12 col-span-2 flex justify-end">
              <div className="grid grid-cols-12 col-span-12 h-8 flex justify-end">
                <span
                  className="btn_close col-span-10 rounded-sm flex justify-center cursor-pointer"
                  onClick={() => {
                    props.CloseTesterModal(false);
                  }}
                >
                  <button>Close</button>
                </span>
              </div>
            </div>
          </div>
        </>
      </div>
    </Modal>
  );
}
export default TesterDeatilsPopup;
