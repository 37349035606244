import React, { useState, useEffect, useContext } from "react";
import "./report-details.scss";

import ResultChart from "./charts/result-chart";
import ExecutionModuleChart from "./charts/execution-module-chart";

import { ProgressContainerContext } from "../common/inputs/ProgressContainer.js";
import ExecutionReportsTable from "./execution-reports-table";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@mui/material/IconButton";

import { ReactComponent as NoReportsIcon } from "../../assets/noReportsAvailable.svg";
import {
  getAllTestcaseReq,
  getTestCaseResultReportReq,
  getModuleReq,
  getTotalTestcaseCount,
} from "../../api/api-services";
import ModulechartExpand from "./charts/modulechart-expand";
import TestcaseExpandModule from "./new_reports/tc-expand-module";
import { ReactComponent as Noreports } from "../../assets/reportsicon/noReportsAvailable.svg";
import { CircularLoader } from "../common/CircularLoader";
let Module_Color = [
  "#DFFF00",
  "#FFBF00",
  "#FF9633",
  "#FF7F50",
  "#DE3163",
  "#9FE2BF",
  "#40E0D0",
  "#6495ED",
];
// let chart_Color=["#29FA30", "#FF4933", "#FF9633", "#CECCCA"];
// let  ExecutionResult_Color=[];
function TestcaseChartTableContainer(props) {
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );

  let [ExecutionResult, setExecutionResult] = useState({
    label: [],
    series: [],
    payload: [],
  });
  let [TestCaseModule, setTestCaseModule] = useState({
    label: [],
    series: [],
    payload: [],
  });
  let [ExpandTestCaseModule, setTestCaseExpandModule] = useState({
    label: [],
    series: [],
    payload: [],
  });

  let [ExecutionResultData, setExecutionResultData] = useState([]);
  let [TestCaseModuleData, setTestCaseModuleData] = useState([]);
  let [ExpandTestCaseModuleData, setTestCaseExpandModuleData] = useState([]);

  let [callingAPI, setCallingAPI] = useState(false);

  let [ExecutionResultPageNo, setExecutionResultPageNO] = useState(0);
  let [ExecutionResultLastPage, setExecutionResultLastPage] = useState(false);

  let [TestCaseModulePageNo, setTestCaseModulePageNo] = useState(0);
  let [TestCaseModuleLastPage, setTestCaseModuleLastPage] = useState(false);

  let [ExpandTestCaseModulePageNo, setTestCaseExpandModulePageNo] = useState(0);
  let [ExpandTestCaseModuleLastPage, setTestCaseExpandModuleLastPage] =
    useState(false);

  let [ExpandModuel, setExpandModule] = useState(false);
  let [ExecutionResult_Color, setExecutionResult_Color] = useState([]);

  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  let pageSize = 10;
  let [TotalTestcase, setTotalTestcase] = useState(0);
  const [Resulthasdata, setResultHasData] = useState(false);
  const [Modulehasdata, setModuleHasData] = useState(false);
  const [executionResultCount, setExecutionResultCount] = useState(0);
  const [ModuleCount, setModuleCount] = useState(0);
  const [ExpandModuleCount, setExpanModuleCount] = useState(0);
  let [resultDataLoading, setResultDataLoading] = useState();
  let [moduleDataLoading, setModuleDataLoading] = useState();

  useEffect(() => {
    if(JSON.parse(localStorage["selected-project"]).projectStatus!=="INREVIEW" && JSON.parse(localStorage["selected-project"]).projectStatus!=="REJECTED"){
      TestcaseReportsDataAPI();
    getAllModuleReq();
    getTestCaseResultReportDataAPI();
    }else{
      setResultDataLoading(false);
      setModuleDataLoading(false)
    }
    
  }, []);

  const TestcaseReportsDataAPI = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    // getExecutionResult(filter,0,pageSize)
    // getTestcaseModule(filter,0,pageSize)
  };

  function getTestCaseResultReportDataAPI() {
    let series = [];
    let label = [];
    let colors = [];

    setResultDataLoading(true);
    getTestCaseResultReportReq(projectId, licenseID)
      .then((result) => {
        console.log("getTestCaseResultReportReq", result);
        if (result?.status === 200 && result?.data?.responseObject) {
          let response = result?.data?.responseObject;
          localStorage.setItem(
            "total-testcase",
            JSON.stringify(result?.data?.responseObject.totalTestcaseCount)
          );
          setTotalTestcase(result?.data?.responseObject.totalTestcaseCount);
          if (response.pass !== 0) {
            series.push(response.pass);
            label.push("PASS");
            colors.push("#29FA30");
          }
          if (response.fail !== 0) {
            series.push(response.fail);
            label.push("FAIL");
            colors.push("#FF4933");
          }
          if (response.flaky !== 0) {
            series.push(response.flaky);
            label.push("FLAKY");
            colors.push("#FF9633");
          }
          if (response.notExecuted !== 0) {
            series.push(response.notExecuted);
            label.push("NOT EXECUTED");
            colors.push("#6C5B5B");
          }
        }
        ExecutionResult.label = label;
        ExecutionResult.series = series;
        setExecutionResult_Color(colors);
        console.log("ExecutionResult", label);
        setResultHasData(true);
        getExecutionResult(label, 0);
      })
      .catch((error) => {
        console.log("Error");
      });
  }

  function getAllModuleReq() {
    let series = [];
    let label = [];
    setModuleDataLoading(true);
    getModuleReq(projectId, licenseID)
      .then((response) => {
        console.log("getModuleReq", response);
        if (response.status === 200 && response.data.responseObject) {
          response.data.responseObject.map((obj) => {
            if (obj.totalTestcaseCount !== 0) {
              console.log("totalTestcaseCount", obj.totalTestcaseCount);
              // series.push(obj.module);
              // label.push(obj.totalTestcaseCount)
              series.push(obj.totalTestcaseCount);
              label.push(obj.module);
            }
          });
          ExpandTestCaseModule.label = label;
          ExpandTestCaseModule.series = series;
          if (label.length > 8) {
            TestCaseModule.series = series.slice(0, 8);
            TestCaseModule.label = label.slice(0, 8);
          } else {
            TestCaseModule.series = series;
            TestCaseModule.label = label;
          }
        }
        setModuleHasData(true);
        getTestcaseModule(label, 0);
      })
      .catch((error) => {
        console.log("error");
      });
  }

  function getTestcaseCount(payload, label) {
    getTotalTestcaseCount(payload, projectId, licenseID)
      .then((response) => {
        console.log("getTestcaseCount", response);
        if (response.status === 200) {
          if (label === "executionResult") {
            setExecutionResultCount(response.data);
          } else if (label === "module") {
            setModuleCount(response.data);
          } else {
            setExpanModuleCount(response.data);
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }

  function getExecutionResult(filter, pageNo) {
    let payload = {
      modules: [],
      location: [],
      users: [],
      executionResult: filter,
    };

    getAllTestcaseReq(payload, pageNo, pageSize, projectId, licenseID)
      .then((response) => {
        ExecutionResult.payload = filter;

        if (response?.status === 200 && response?.data?.responseObject) {
          if (pageNo === 0) {
            setExecutionResultData(
              response?.data?.responseObject?.testcaseLogs
            );
          } else {
            let data = ExecutionResultData.concat(
              response?.data?.responseObject?.testcaseLogs
            );
            setExecutionResultData(data);
          }

          setExecutionResultLastPage(response?.data?.responseObject?.lastPage);

          if (response?.data?.responseObject?.lastPage === false) {
            setExecutionResultPageNO(pageNo + 1);
          }
          if (pageNo === 0) {
            getTestcaseCount(payload, "executionResult");
          }
          console.log("response", response);
        }
      })
      .catch((error) => {
        console.log("error");
      });
    setResultDataLoading(false);
  }

  function getTestcaseModule(filter, pageNo) {
    let payload = {
      modules: filter,
      location: [],
      users: [],
      executionResult: [],
    };
    getAllTestcaseReq(payload, pageNo, pageSize, projectId, licenseID)
      .then((response) => {
        TestCaseModule.payload = filter;
        if (response?.status === 200 && response?.data?.responseObject) {
          if (pageNo === 0) {
            setTestCaseModuleData(response?.data?.responseObject?.testcaseLogs);
          } else {
            let data = TestCaseModuleData.concat(
              response?.data?.responseObject?.testcaseLogs
            );
            setTestCaseModuleData(data);
          }

          setTestCaseModuleLastPage(response?.data?.responseObject?.lastPage);

          if (response?.data?.responseObject?.lastPage === false) {
            setTestCaseModulePageNo(pageNo + 1);
          }
          if (pageNo === 0) {
            getTestcaseCount(payload, "module");
          }
          console.log("response", response);
        }
      })
      .catch((error) => {
        console.log("error");
      });
    setModuleDataLoading(false);
  }

  function selectAPI(label) {
    switch (label) {
      case "module":
        getTestcaseModule(TestCaseModule.payload, TestCaseModulePageNo);
        break;

      case "executionResult":
        getExecutionResult(ExecutionResult.payload, ExecutionResultPageNo);
        break;
      default:
        break;
    }
  }
  function selectedChartlabelData(label, value) {
    console.log("values", value);
    console.log("label", label);

    switch (label) {
      case "executionResult": {
        let filterData;
        let executionResultEle = document.getElementById(label + 0);
        executionResultEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        setExecutionResultPageNO(0);
        setExecutionResultLastPage(false);
        console.log("ExecutionResult.payload", ExecutionResult.payload);
        if (
          ExecutionResult.payload.length === 1 &&
          ExecutionResult.payload[0] === value[0]
        ) {
          filterData = ExecutionResult.label;
        } else {
          filterData = value;
        }
        getExecutionResult(filterData, 0);

        break;
      }

      case "module": {
        let filterData;
        let moduleEle = document.getElementById(label + 0);
        moduleEle.scrollIntoViewIfNeeded({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
        setTestCaseModulePageNo(0);
        setTestCaseModuleLastPage(false);
        if (
          TestCaseModule.payload.length === 1 &&
          TestCaseModule.payload[0] === value[0]
        ) {
          filterData = ExpandTestCaseModule.label;
        } else {
          filterData = value;
        }
        getTestcaseModule(filterData, 0);
        break;
      }

      default: {
        break;
      }
    }
  }

  function closeExpandModulePopUp(state) {
    setExpandModule(state);
  }

  return (
    <div>
      {!callingAPI && (
        <div className="ExecutionAnalytics">
          <div className={resultDataLoading?"":"tcchart-container"} style={{ marginTop: "2px" }}>
            <div className="col-span-12 heading pl-2 pt-2 ">
              Execution By Result
            </div>
            {
              resultDataLoading === false?(
                <>
                 {ExecutionResult.label.length !== 0 && Resulthasdata ? (
              <div className="col-span-12 grid grid-cols-12 flex px-4 py-4">
                <div className="col-span-3">
                  <ExecutionModuleChart
                    seriesdata={ExecutionResult.series}
                    labels={ExecutionResult.label}
                    colors={ExecutionResult_Color}
                    header={"executionResult"}
                    selectedChartlabelData={selectedChartlabelData}
                    TotalTestcase={TotalTestcase}
                  />
                </div>
                <div className="col-span-9 grid grid-cols-12">
                  <div className="col-span-12">
                    <ExecutionReportsTable
                      header={"Total Test Cases"}
                      data={ExecutionResultData}
                      label={"executionResult"}
                      lastPage={ExecutionResultLastPage}
                      selectAPI={selectAPI}
                      TotalCount={executionResultCount}
                    />
                  </div>
                </div>
              </div>
            ) : (
             
               (
                <div style={{ marginTop: "5%", marginBottom: "5%" }}>
                  <div className="flex justify-center">
                    <Noreports />
                  </div>
                  <div className="no_defect_reports_msg">No Reports found!</div>
                </div>
              )
            )}
                </>
              ):(
                <div className="h-80 mt-28">
                  <CircularLoader/>
                </div>
              )
            }
           
          </div>
          <div className={moduleDataLoading?"":"tcchart-container"} style={{ marginTop: "50px" }}>
            <div className="col-span-12 heading pl-2 pt-2 grid grid-cols-12 pr-2">
              <div className="col-span-10">Execution By Module</div>

              {/* <div
                className={`col-span-2 flex justify-end editIcon ${
                  ExpandTestCaseModule.series.length > 8 ? "block" : "hidden"
                }`}
                onClick={() => {
                  setExpandModule(true);
                }}
              >
                <Tooltip title="Expand" placement="top">
                  <IconButton
                    sx={{
                      "&:hover": {
                        backgroundColor: "#FAF9F6",
                        boxShadow:
                          " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                      },
                    }}
                  >
                    <button>
                      <span className="darkBlue-expand">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20.498 20.498"
                        >
                          <path
                            d="M29.555,0h5.951A.989.989,0,0,1,36.5.992V6.943a.992.992,0,0,1-1.694.7L33.192,6.034l-3.6,3.6a.988.988,0,0,1-1.4,0L26.873,8.307a.988.988,0,0,1,0-1.4l3.6-3.6L28.853,1.694A.992.992,0,0,1,29.555,0ZM22.943,20.5H16.992A.989.989,0,0,1,16,19.506V13.555a.992.992,0,0,1,1.694-.7l1.612,1.612,3.6-3.6a.988.988,0,0,1,1.4,0l1.322,1.322a.988.988,0,0,1,0,1.4l-3.6,3.6L23.641,18.8a.992.992,0,0,1-.7,1.694Z"
                            transform="translate(-16 0)"
                            fill="#434db8"
                          />
                        </svg>
                      </span>
                    </button>
                  </IconButton>
                </Tooltip>
              </div> */}
            </div>
            {
               moduleDataLoading === false ?(
                <>
                 {TestCaseModule.label.length !== 0 &&
            Resulthasdata &&
            Modulehasdata ? (
              <>
                <div className="col-span-12 grid grid-cols-12 flex px-4 py-4">
                  <div className="col-span-3 grid grid-cols-12">
                    <div className="col-span-11">
                    <ExecutionModuleChart
                      seriesdata={TestCaseModule.series}
                      labels={TestCaseModule.label}
                      colors={Module_Color}
                      header={"module"}
                      selectedChartlabelData={selectedChartlabelData}
                      TotalTestcase={TotalTestcase}
                    />
                    {
                       ExpandTestCaseModule.series.length > 8  && ( <div className="testcase_seemore_btn"  onClick={() => {
                        setExpandModule(true);
                      }}>see more</div>)
                    }
                   

                   
                    <div>
                      
                    </div>
                    </div>
                  
                   
                    
                  </div>
                  <div className="col-span-9 grid grid-cols-12">
                    <div className="col-span-12">
                      <ExecutionReportsTable
                        header={"Total Test Cases"}
                        data={TestCaseModuleData}
                        label={"module"}
                        lastPage={TestCaseModuleLastPage}
                        selectAPI={selectAPI}
                        TotalCount={ModuleCount}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              
              (
                <div>
                  <div style={{ marginTop: "5%", marginBottom: "5%" }}>
                    <div className="flex justify-center">
                      <Noreports />
                    </div>
                    <div className="no_defect_reports_msg">
                      No Reports found!
                    </div>
                  </div>
                </div>
              )
            )}
                </>
               ):(
                <div className="h-80 mt-28">
                <CircularLoader/>
              </div>
               )

            }
           
          </div>
        </div>
      )}
      {ExpandModuel && (
        <TestcaseExpandModule
          series={ExpandTestCaseModule.series}
          labels={ExpandTestCaseModule.label}
          header={"testcase_Module"}
          closeModal={closeExpandModulePopUp}
        />
      )}
    </div>
  );
}

export default TestcaseChartTableContainer;
