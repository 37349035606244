import React from "react";
import "./project.scss";
import TableTreeDemo from "../common/table_tree/table_tree_demo";

function TreeDemo () {
  return (
    <div className="table-tree-demo">
      <div className="grid grid-cols-12 h-12 ">
        <div className="col-span-12 heading pl-2 pt-2">Tree Demo</div>
      </div>
      <div className="testcases_container">
        <TableTreeDemo/>
      </div>
    </div>
  );
}

export default TreeDemo;
