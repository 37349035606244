import React, { useState, useEffect } from "react";

function PlatformDetails(props) {
  console.log("props?.Platformdetails?.platformDetails",props)
  let [webDetails,setWebDetails]=useState();
  let [mobileDetails,setMobileDetails]=useState();
  useEffect(()=>{
    props?.Platformdetails?.platformDetails?.map((platform)=>{
      if(platform.platformType==="web"){
        setWebDetails(platform)
      }else{
        setMobileDetails(platform)
      }

    })
  },[props])
 
  
  return (
    <div>
      {
        webDetails && (
          <>
          {(JSON.parse(localStorage.getItem("selected-project")).project
        ?.projectType?.web !== null ||
        JSON.parse(localStorage.getItem("selected-project")).project
          ?.projectType?.webAndMobile !== null || (JSON.parse(localStorage.getItem("selected-project")).project
          ?.projectType?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project
          ?.projectType?.others?.typeOfProject==="Standalone_Application")) && (
        <div className="my-4">
          {JSON.parse(localStorage.getItem("selected-project")).project
            ?.projectType?.webAndMobile !== null && (
            <span className="platform_type_header border-b-2 border-black">
              Web
            </span>
          )}
          <div className="grid grid-cols-12 flex content-between mt-2">
            <div className="col-span-4">
              <span className="labels">OS</span>
              <br></br>
              <div className="values datatrimmer w-28" title={webDetails?.os}>{webDetails?.os}</div>
            </div>
            <div className="col-span-4">
              <span className="labels">OS VERSION</span>
              <br></br>
              <div className="values datatrimmer w-28" title={webDetails?.osVersion}>{webDetails?.osVersion}</div>
            </div>
            {
              ((JSON.parse(localStorage.getItem("selected-project")).project
              ?.projectType?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project
              ?.projectType?.others?.typeOfProject==="Standalone_Application") )? (
                <div className="col-span-4">
                <span className="labels">LOCATION</span>
                <br></br>
                <div className="values datatrimmer w-28" title={webDetails?.city}>{webDetails?.city?webDetails?.city:"--"}</div>
              </div>
              ):(
                <div className="col-span-4">
                <span className="labels">BROWSER</span>
                <br></br>
                <div className="values datatrimmer w-28" title={webDetails?.browser}>{webDetails?.browser}</div>
              </div>
              )
            }
           
           
          </div>
          {
            !((JSON.parse(localStorage.getItem("selected-project")).project
            ?.projectType?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project
            ?.projectType?.others?.typeOfProject==="Standalone_Application") ) && (
              <div className="grid grid-cols-12 flex content-between mt-2">
            
              <div className="col-span-4">
                <span className="labels">BROWSER VERSION</span>
                <br></br>
                <div className="values datatrimmer w-28" title={webDetails?.browserVersion}>{webDetails?.browserVersion}</div>
              </div>
              <div className="col-span-4">
                <span className="labels">LOCATION</span>
                <br></br>
                <div className="values datatrimmer w-28" title={webDetails?.city}>{webDetails?.city?webDetails?.city:"--"}</div>
              </div>
            </div>
            )
          }

         
        </div>
      )}
          </>
        )
      }
      
      
      {
        mobileDetails && (
          <>
          {(JSON.parse(localStorage.getItem("selected-project")).project
        ?.projectType?.mobile !== null ||
        JSON.parse(localStorage.getItem("selected-project")).project
          ?.projectType?.webAndMobile !== null ||(JSON.parse(localStorage.getItem("selected-project")).project
          ?.projectType?.others !== null && JSON.parse(localStorage.getItem("selected-project")).project
          ?.projectType?.others?.typeOfProject!=="Standalone_Application") )&& (
            
        <>
          {JSON.parse(localStorage.getItem("selected-project")).project
            ?.projectType?.webAndMobile !== null && (
            <span className="platform_type_header border-b-2 border-black">
              Mobile
            </span>
          )}
          
          <div className="grid grid-cols-12 flex content-between mt-2">
            <div className="col-span-4">
              <span className="labels">OS</span>
              <br></br>
              <div className="values datatrimmer w-28" title={mobileDetails?.os}>{mobileDetails?.os}</div>
            </div>
            <div className="col-span-4">
              <span className="labels">OS VERSION</span>
              <br></br>
              <div className="values datatrimmer w-28" title={mobileDetails?.osVersion}>{mobileDetails?.osVersion}</div>
            </div>

            <div className="col-span-4">
              <span className="labels">DEVICE NAME</span>
              <br></br>
              <div className="values datatrimmer w-28" title={mobileDetails?.deviceName}>{mobileDetails?.deviceName}</div>
            </div>
          </div>
          <div className="grid grid-cols-12 flex content-between mt-2">
            <div className="col-span-4">
              <span className="labels">DEVICE BRAND</span>
              <br></br>
              <div className="values datatrimmer w-28" title={mobileDetails?.deviceBrand}>{mobileDetails?.deviceBrand}</div>
            </div>
            <div className="col-span-4">
              <span className="labels">DEVICE TYPE</span>
              <br></br>
              <div className="values datatrimmer w-28" title={mobileDetails?.deviceType}>{mobileDetails?.deviceType}</div>
            </div>
            <div className="col-span-4">
              <span className="labels">LOCATION</span>
              <br></br>
              <div className="values datatrimmer w-28" title={mobileDetails?.city}>{mobileDetails?.city?mobileDetails?.city:"--"}</div>
            </div>
            {(JSON.parse(localStorage.getItem("selected-project")).project
              ?.projectType?.mobile?.appType === "mWeb" ||
              JSON.parse(localStorage.getItem("selected-project")).project
                ?.projectType?.webAndMobile?.appType === "mWeb") && (
              <div className="col-span-4">
                <span className="labels">BROWSER</span>
                <br></br>
                <div className="values datatrimmer w-28" title={mobileDetails?.browser}>{mobileDetails?.browser}</div>
              </div>
            )}
          </div>
          {(JSON.parse(localStorage.getItem("selected-project")).project
            ?.projectType?.mobile?.appType === "mWeb" ||
            JSON.parse(localStorage.getItem("selected-project")).project
              ?.projectType?.webAndMobile?.appType === "mWeb") && (
            <div className="grid grid-cols-12 flex content-between mt-2">
              <div className="col-span-4">
                <span className="labels">BROWSER VERSION</span>
                <br></br>
                <div className="values datatrimmer w-28" title={mobileDetails?.browserVersion}>{mobileDetails?.browserVersion}</div>
              </div>
            </div>
          )}
        </>
      )}
          </>
        )
      }
      
      
    </div>
  );
}
export default PlatformDetails;
