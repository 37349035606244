import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/login/login-page";
import Home from "./pages/home/home";
import "./App.scss";
import SignupForm from "./pages/SignupPage/signup-page";
import RegistrationSuccess from "./pages/SignupPage/registration-success";
import ActivationLink from "./pages/SignupPage/warning-activation-link";
import ForgotPassword from "./pages/SignupPage/forgot-password";
import EmailSend from "./pages/SignupPage/email-send";
import PasswordSetSuccessfully from "./pages/SignupPage/password-set-successfully";
import PhoneValidation from "./pages/SignupPage/phone-validation";
import VerificationCode from "./pages/SignupPage/verification-code";
import SetPassword from "./pages/SignupPage/set-password";
import DeviceToBeVarified from "./pages/deviceVerification/deviceToBeVerified";
import DeviceVerified from "./pages/deviceVerification/deviceVerified";
import ActivationSuccess from "./pages/SignupPage/activationSuccess";
import ActivationPending from "./pages/SignupPage/activationPending";
// import {useAlert} from "../common/alert-service/useAlert";
import { useAlert } from "./pages/common/alert-service/useAlert";
import ProjectHome from "./pages/home/project_home";
import ResetPassword from "./pages/SignupPage/resetPassword";


const App = () => {
  const { AlertContatiner, MyAlert } = useAlert();
  return (
    <>
    <div><AlertContatiner/></div>
    <div>
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/signin" element={<LoginPage />} />
      <Route path="/*" element={<Home />} />
      {/* <Route path="/allProjects/*" element={<ProjectHome/>}/> */}
      <Route path="/signup" element={<SignupForm />} />
      <Route
        path="/registration-successful"
        element={<RegistrationSuccess />}
      />
      <Route path="/registration-activation" element={<ActivationLink />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/email-send" element={<EmailSend />} />
      <Route path="/reset-password" element={<ResetPassword/>} />
      <Route
        path="/set-password-successfully"
        element={<PasswordSetSuccessfully />}
      />
          <Route path="/activation-successful" element={<ActivationSuccess />} />
      <Route path="/phone-validation" element={<PhoneValidation />} />
      <Route path="/otp-verification" element={<VerificationCode />} />
      <Route path="/change-password" element={<SetPassword/>}/>
      <Route path="/token-expired" element={<ActivationPending/>}/>
      <Route path="/device-to-be-verified/*" element={<DeviceToBeVarified  MyAlert={MyAlert}/>} />
      <Route path="/device-verified" element={<DeviceVerified />} />
    </Routes>
    </div>
    </>
   
  );
};

export default App;
