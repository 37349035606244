import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { CHART_COLOR ,MODULE_CHART_COLOR} from "../../../util/app-constants";


function ModulechartExpand(props) {
  let index;
  return (
    <div>
      <div className=" flex">
         <Chart
        type="donut"
         width={500}
        height={250}
        series={props?.series}
        options={{
          chart: {
            type: "donut",
            width: 200,
            events: {
              dataPointSelection: function (event, chartContext, config) {
                props.selectedChartlabelData(props.header, [
                  props.labels[config.dataPointIndex],
                ]);
              },
            },
          },
          labels: props?.labels,
           colors: MODULE_CHART_COLOR,
          theme: {
            monochrome: {
              enabled: false,
            },
          },
          plotOptions: {
              pie: {
             
                expandOnClick: true,
                donut: {
                size:"80%",
                  labels: {
                    show: true,
                    name: {
                      show: true,
                      fontSize: '22px',
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 600,
                      color: "#6F7075",
                      offsetY: -10,
                      label:"Total Defects",
                      formatter: function (val) {
                        // return <div>{val}</div>
                        if(props.header === "module") {
                          if(val==="Total Defects"){
                            return val;
                          }else{
                            return `${val.split(/[/,:]/)[val.split(/[/,:]/).length-2]}`;
                          }
                          
                        }else if (props.header === "testcase_module"){
                          if(val==="Total Testcases"){
                            return val;
                          }else{
                            return `${val.split(/[/,:]/)[val.split(/[/,:]/).length-1]}`;
                          }

                        }else{
                          return val
                        }
                      }},
                  
                    total: {
                      // showAlways: true,
                      show: true,
                      fontSize: "12px",
                      color: "#333333",
                      fontFamily: "Poppins-Regular",
                      label:props.header === "testcase_module"?"Total Testcases":"Total Defects",
                    
                    },
                    value: {
                      color: "#282828",
                      fontSize: "24px",
                      fontFamily: "Poppins-SemiBold",
                      fontWeight: "10",
                      
                    },
                  },
                },
              },
            },
          dataLabels: {
            enabled: false,
          },
          legend: {
            //    width:200,
            fontSize: '10px',
            fontFamily: 'Poppins-Regular',
            onItemClick: {
              toggleDataSeries: false,
            },
            formatter: function (val, opts) {
              // return val + " - " + opts.w.globals.series[opts.seriesIndex];
              if(props.header === "module") {
                return `${val.split(/[/,:]/)[val.split(/[/,:]/).length-2]+ " - " + opts.w.globals.series[opts.seriesIndex]}`;
              }else if(props.header === "testcase_module") {
                return `${val.split(/[/,:]/)[val.split(/[/,:]/).length-1]+ " - " + opts.w.globals.series[opts.seriesIndex]}`;
              }
              else{
                return val + " - " + opts.w.globals.series[opts.seriesIndex];
              }
              
            },
          },
        
        }}
      />
      </div>

      {/* <div className="expantdonutchart_label">Module</div> */}
    </div>
  );
}

export default ModulechartExpand;
