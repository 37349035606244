import React, { useState, useEffect } from "react";
// import LogotextImage from "../../assets/fire_crowd.svg";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import BackgroundDown from "../../assets/login_logo.png";
import style from "./signup.module.scss";
import cx from "classnames";
import PhoneInput from "react-phone-input-2";
import {
  changeActivePhoneNumber,
  defaultCountry,
} from "../../api/api-services";
import { useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";

function PhoneValidation() {
  const [ChangedPhoneNumber, setChangedPhoneNumber] = useState("");
  const [phoneValidate, setPhoneValidate] = useState("");
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [presentCountry, setPresentCountry] = useState("");
  const navigate = useNavigate();
  function handleChange(event) {
    if (event) {
      const values = event;
      setChangedPhoneNumber(values);
      setPhoneValidate("");
    }
  }
  const OnSubmitActivePhoneNumber = () => {
    if (ChangedPhoneNumber.length === 0) {
      setPhoneValidate("Phone number required");
    }
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);
      const userId = sessionStorage.getItem("userid");
      const data = {
        id: userId,
        phoneNumbers: {
          primaryPhone: ChangedPhoneNumber,
        },
        source: "FireCrowd",
      };
      changeActivePhoneNumber(data)
        .then((resp) => {
          let respUrl = resp.data.responseObject.url;
          sessionStorage.setItem("respUrl", respUrl);
          sessionStorage.setItem("ChangedPhoneNumber", ChangedPhoneNumber);
          if (ChangedPhoneNumber.length > 8 && !phoneValidate) {
            navigate("/otp-verification", { replace: true });
          } else {
            setPhoneValidate("Valid phone number required");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    defaultCountry().then((res) => {
      setPresentCountry(res.data.country);
    });
  }, []);
  function validateSecondaryPhoneNumber(phoneNumberIndex, event) {
    if (isValidPhoneNumber("+" + ChangedPhoneNumber?.toString()) === false) {
      setPhoneValidate("Valid Phone number is required");
    }
  }
  return (
    <div className="grid grid-cols-12 min-h-screen">
      <div className={cx(style["phone_validation_activation_link"], "col-span-6 border-r-4")}>
        <div
          className={cx(style["phone_validation_activation_link_leftImage"], "grid grid-cols-span-12 col-span-6 flex justify-center")}
          id="leftImage"
        >
          <div className={cx(style["leftImage"])}>
            <img
             className={cx(style["logo_Icon"])}
              src={LogotextImage}
              height="200px"
              width="300px"
              alt="Qspider"
            />
          </div>
        </div>
        <div className="grid grid-cols-span-12 col-span-5 flex justify-center mt-5">
          <div>
            <img
              height="350px"
              width="350px"
              src={BackgroundDown}
              alt="Firecrowd"
            />
          </div>
        </div>
        <div
          className={cx(
            style["heading"],
            "grid grid-cols-span-12 col-span-5 flex justify-center mt-5"
          )}
        >
          <div>Simply Verify</div>
        </div>
        <div
          className={cx(
            style["msg"],
            "grid grid-cols-span-12 col-span-5 flex justify-center mt-2"
          )}
        >
          <span>Follow the quick verification process, and you all set</span>
        </div>
        <div
          className={cx(
            style["msg"],
            "grid grid-cols-span-12 col-span-5 flex justify-center mt-1"
          )}
        >
          <span>to use your account</span>
        </div>
      </div>
      <div className={cx(style["phone_validation_activation_page"], "col-span-6  pt-44 px-20")}>
      <div className={cx(style["logoimg"])}>
            <img
              src={LogotextImage}
              height="200px"
              width="300px"
              alt="Qspider"
            />
          </div>
        {/* <div className=""> */}
          <div className={cx(style["heading"])}>Phone Number</div>
          <div className={cx(style["msg"], "mt-5")}>
            <div>
              {" "}
              Please enter the phone number, for which you want to receive the
              verification code
            </div>
            <div className="mt-6">
              <label>
                <span className="text-red-600">*</span>{" "}
                <span className="text-xs">Phone Number </span>
              </label>

              <div className="flex flex-row ">
                <PhoneInput
                  type="text"
                  value={""}
                  autoComplete="off"
                  name="phoneNumbers"
                  id="phoneNumbers"
                  specialLabel=""
                  searchPlaceholder="search..."
                  searchNotFound="please try again"
                  defaultCountry={
                    presentCountry && presentCountry.toLowerCase()
                  }
                  enableAreaCodeStretch
                  country={presentCountry && presentCountry.toLowerCase()}
                  enableSearch
                  inputStyle={{
                    height: "26px",
                    width: "62%",
                    border: "1px solid #989797",
                    borderRadius: "4px",
                    // borderTop: "none",
                    // borderLeft: "none",
                    // borderRight: "none",
                    boxShadow: "0px 0px",
                    marginLeft: "-1px",
                  }}
                  searchStyle={{
                    borderBottom: "1px solid #989797",
                    borderRadius: "0px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "0px 0px",
                    width: "90%",
                  }}
                  dropdownStyle={{
                    width: "250px",
                    height: "236px",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 6px #00000029",
                    textAlign: "left",
                    position: "fixed",
                    backgroundColor: "#FBFCFD",
                  }}
                  international
                  countryCodeEditable={false}
                  onChange={(e) => handleChange(e)}
                  className="max-w-lg block w-11/12  "
                  placeholder="Enter Your Phone Number"
                  onBlur={(e) => {
                    validateSecondaryPhoneNumber(1, e);
                  }}
                />
              </div>

              <span id="validPhn">{phoneValidate}</span>
            </div>
            <div className="warning_button phn_warning_btn  relative top-7 ">
              <button
                onClick={() => OnSubmitActivePhoneNumber()}
                className="phnValidBtn"
              >
                Change & Resend OTP
              </button>
            </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
export default PhoneValidation;
