import React, { useState } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup'
import { TextareaAutosize, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Label } from "../../common/inputs/myInput";
import "../defect-field-forms.scss";

const TextAreaForm = (props) => {
    const [enableTFValue, setEnableTFValue] = useState(props.editFieldValue?.type === 'textArea' ? props.editFieldValue.enable :"yes");
  const [mandatoryTFValue, setMandatoryTFValue] = useState(props.editFieldValue?.type === 'textArea' ? props.editFieldValue.mandatory :"no");
  const [checkLengthValidationDefaultTF, setCheckLengthValidationDefaultTF] = useState("")
  const [checkMinLengthValidationDefaultTF, setCheckMinLengthValidationDefaultTF] = useState("")
  const [textFieldMaxValueValidate, setTextFieldMaxValueValidate] = useState("")
  const [maxEmptyValueCheck, setMaxEmptyValueCheck] = useState("")
    let initialValues = {
       
        label:props.editFieldValue?.type === 'textArea' ? props.editFieldValue.label : "",
    textAreaPlaceHolder:props.editFieldValue?.type === 'textArea' ? props.editFieldValue.placeholder : "",
    textAreaMaxLength:props.editFieldValue?.type === 'textArea' ? props.editFieldValue.maxLength : 200,
    textAreaMinLength:props.editFieldValue?.type === 'textArea' ? props.editFieldValue.minLength : 0,
    defaultValue:props.editFieldValue?.type === 'textArea' ? props.editFieldValue.defaultValue : "",
        
      };
      const validationSchema = yup.object({
       
        label:yup.string().required("Label is required"),
        // textAreaPlaceHolder:yup.string().required("Label is required"),
        textAreaMaxLength:yup.string().required("MaxLength is required"),
        textAreaMinLength:yup.string().required("MinLength is required"),
        defaultValue:yup.string().required("DefaultValue is required"),
       
      });
      const haveSameData = function (obj1, obj2) {
        const obj1Length = Object.keys(obj1).length;
        const obj2Length = Object.keys(obj2).length;
        if (obj1Length === obj2Length) {
            return Object.keys(obj1).every(
                key => obj2.hasOwnProperty(key)
                    && obj2[key] === obj1[key]);
        }
        return false;
    }
    function handleTrimmedBlur(event) {
      event.target.value = event.target.value.trim();
      formikDetails.handleChange(event);
      formikDetails.handleBlur(event);
  }
      const handelTextAreadFormSubmit = (values,e) => {
        formikDetails.handleSubmit()
        const textAreaFieldData = {
            label: formikDetails.values.label,
            placeholder: formikDetails.values.textAreaPlaceHolder,
            maxLength: formikDetails.values.textAreaMaxLength,
            minLength: formikDetails.values.textAreaMinLength,
            defaultValue: formikDetails.values.defaultValue,
            enable: enableTFValue,
            order: props.editFieldValue["order"],
            mandatory: mandatoryTFValue,
            type: "textArea",
        }
       console.log(textAreaFieldData)
        if([textAreaFieldData.label,textAreaFieldData.maxLength,textAreaFieldData.minLength].includes("")){
            
            props.setAddField(true);

        }else if (haveSameData(props.editFieldValue, textAreaFieldData)) {
          props.MyAlert.info(`No changes were done to the ${textAreaFieldData.label} field`)
           return
       } else if (formikDetails.values.defaultValue && formikDetails.values.textAreaMaxLength < formikDetails.values.defaultValue?.length) {
        setCheckLengthValidationDefaultTF(`Default value cannot be more than ${formikDetails.values.textAreaMaxLength} characters`)
    } else if (formikDetails.values.defaultValue && formikDetails.values.textAreaMinLength > formikDetails.values.defaultValue?.length) {
        setCheckMinLengthValidationDefaultTF(`Default value cannot be less than ${formikDetails.values.textAreaMinLength} characters`)
    }else if (Number(formikDetails.values.textAreaMaxLength) === 0 || Number(formikDetails.values.textAreaMaxLength) < Number(formikDetails.values.textAreaMinLength)) {
      setTextFieldMaxValueValidate(" Max length should be greater than Min length")
  } else{
           props.addFormField(textAreaFieldData);
            props.setAddField(false);
           
        }
      };
    
      const formikDetails = useFormik({
        initialValues,
       
        validationSchema,
    
      });
      const handleEnableTFChange = (e) => {
        setEnableTFValue(e.target.value)
    }
    const handleMandatoryTFChange = (e) => {
        setMandatoryTFValue(e.target.value)
        setEnableTFValue("yes")
    }
  return (
    <div className="form-content overflow-y-scroll ">
      <div className="my-5  px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Label
              </span>
            }
            required={true}
            error={
              formikDetails.errors.label &&
              formikDetails.touched.label
            }
          />
        </div>
        <TextField
          error={
            formikDetails.errors.label &&
            formikDetails.touched.label
          }
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          name="label"
          id="label"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={handleTrimmedBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.label}
        />
        {formikDetails.errors.label &&
        formikDetails.touched.label ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.label}
          </div>
        ) : null}
      </div>
      <div className="my-2 px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Placeholder
              </span>
            }
            error={
              formikDetails.errors.textAreaPlaceHolder &&
              formikDetails.touched.textAreaPlaceHolder
            }
          />
        </div>
        <TextField
          error={
            formikDetails.errors.textAreaPlaceHolder &&
            formikDetails.touched.textAreaPlaceHolder
          }
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          name="textAreaPlaceHolder"
          id="textAreaPlaceHolder"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={handleTrimmedBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.textAreaPlaceHolder}
        />
        {formikDetails.errors.textAreaPlaceHolder &&
        formikDetails.touched.textAreaPlaceHolder ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.textAreaPlaceHolder}
          </div>
        ) : null}
      </div>
      <div className="my-2 px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Max Length
              </span>
            }
            required={true}
            error={
              formikDetails.errors.textAreaMaxLength &&
              formikDetails.touched.textAreaMaxLength
            }
          />
        </div>
        <TextField
          // error={
          //   formikDetails.errors.textAreaMaxLength &&
          //   formikDetails.touched.textAreaMaxLength
          // }
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          name="textAreaMaxLength"
          id="textAreaMaxLength"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={formikDetails.handleBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.textAreaMaxLength}
          InputProps={{ inputProps: { min: 0 } }}
          onKeyDown={(evt) => {["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}}
          error={(typeof (formikDetails.values.textAreaMaxLength) === "string" && maxEmptyValueCheck) || (formikDetails.values.textAreaMaxLength < formikDetails.values.textAreaMinLength && textFieldMaxValueValidate) ? true : false}
                                    helperText={<>{typeof (formikDetails.values.textAreaMaxLength) === "string" ? maxEmptyValueCheck : (formikDetails.values.textAreaMaxLength < formikDetails.values.textAreaMinLength ? <div className="errorMessage -ml-2">{textFieldMaxValueValidate}</div> : null)}</>}
        />
        {formikDetails.errors.textAreaMaxLength &&
        formikDetails.touched.textAreaMaxLength ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.textAreaMaxLength}
          </div>
        ) : null}
      </div>
      <div className="my-2 px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Min Length
              </span>
            }
            required={true}
            error={
              formikDetails.errors.textAreaMinLength &&
              formikDetails.touched.textAreaMinLength
            }
          />
        </div>
        <TextField
          error={
            formikDetails.errors.textAreaMinLength &&
            formikDetails.touched.textAreaMinLength
          }
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          name="textAreaMinLength"
          id="textAreaMinLength"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={formikDetails.handleBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.textAreaMinLength}
          InputProps={{ inputProps: { min: 0 } }}
          onKeyDown={(evt) => {["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}}
        />
        {formikDetails.errors.textAreaMinLength &&
        formikDetails.touched.textAreaMinLength ? (
          <div className="text-red-500 error_text md:mt-1">
            {formikDetails.errors.textAreaMinLength}
          </div>
        ) : null}
      </div>
      <div className="my-2 px-2">
        <div className="mb-4">
          <Label
            label={
              <span className="textFieldHeader text-sm  popins-medium  ">
                Default Value
              </span>
            }
            
           
          />
        </div>
        <TextField
          
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          name="defaultValue"
          id="defaultValue"
          className="custom_height"
          autoComplete="off"
          placeholder="Type here"
          onBlur={formikDetails.handleBlur}
          onChange={formikDetails.handleChange}
          value={formikDetails.values.defaultValue}
        />
       
        {
                                    (formikDetails.values.textAreaMaxLength && formikDetails.values.textAreaMaxLength < formikDetails.values.defaultValue?.length) ?

                                        <>
                                            <p className="errorMessage">
                                                {checkLengthValidationDefaultTF}
                                            </p>
                                        </>
                                        :
                                        formikDetails.values.textAreaMinLength && formikDetails.values.textAreaMinLength > formikDetails.values.defaultValue?.length ?

                                            <>
                                                <p className="errorMessage">
                                                    {checkMinLengthValidationDefaultTF}
                                                </p>
                                            </>
                                            :
                                            null
                                }
        
      </div>

      <div className="mb-4 my-2 px-2 ml-1.5">
        <Label
          label={
            <span className="textFieldHeader text-sm  popins-medium  ">
             Enable
            </span>
          }
        />
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              className="textFieldHeader text-sm  popins-medium  "
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              value={enableTFValue}
              onChange={handleEnableTFChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
                disabled={mandatoryTFValue === "yes"}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="mb-4 my-2 px-2 ml-1.5">
        <Label
          label={
            <span className="textFieldHeader text-sm  popins-medium  ">
              Mandatory
            </span>
          }
          required={true}
        />
        <div>
          <FormControl component="fieldset">
            <RadioGroup
              className="textFieldHeader text-sm  popins-medium  "
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              value={mandatoryTFValue}
              onChange={handleMandatoryTFChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
        <button
          onClick={() => {
            props.setAddField(false);
            props.clearEditValue({})
          }}
          type="button"
          className="w-24 gray-btn ml-3 button"
        >
          Cancel
        </button>
        <button
          type="submit"
          id="createBatch"
          className="w-24 primary-btn button"
          onClick={handelTextAreadFormSubmit}
        >
           {props.editFieldValue?.type === 'textArea' ?"Update" :"Add"}
        </button>
      </div>
    </div>
  );
};

export default TextAreaForm;
