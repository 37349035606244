import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "../project/project.scss";
import { ReactComponent as CalendarSvg } from "../../assets/awesome-calendar.svg";
import "react-datepicker/dist/react-datepicker.css";

const TYDateTimePicker = (props) => {
  const datepickerRef = useRef(null);
  const dateString = props.editDate;
  const newDateString = new Date(dateString);

  const EditedDate =
    newDateString.getMonth() +
    1 +
    "/" +
    newDateString.getDate() +
    "/" +
    newDateString.getFullYear();

  let [selectedValue, setSelectedValue] = useState(props.selectedDate);
  let [editSelectedValue, setEditSelectedValue] = useState(EditedDate);
  let [errorMsg, setErrorMsg] = useState("");
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  }
  function getTomorrowDate() {
    let tdate = new Date();
    // if(props?.parentProp?.header !== "Edit Project"){
    //   return new Date(tdate.setDate(tdate.getDate() + 1));
    // }else{
    //   return new Date(tdate.setDate(tdate.getDate()));
    // }
    return new Date(tdate.setDate(tdate.getDate()));
    
    
  }
  return (
    <>
    <div  className={!errorMsg && !props.formikError ? "flex datepicker-input rounded h-9" :" flex border-2 border-red-500 rounded h-9"}>
      <div className="w-full">
        <DatePicker
          dateFormat="dd MMM yyyy"
          className="datepicker"
          
          selected={
            props?.parentProp?.header === "Edit Project"
              ? new Date(" ' " + editSelectedValue + " ' ")
              : selectedValue
          }
          id={props.dateId}
        
          maxDate={props?.maxDate ? new Date() : ""}
          minDate={props?.minDate ? getTomorrowDate() : ""}
          onChange={(date,e) => {
            if (props?.parentProp?.header !== "Edit Project") {
              setSelectedValue(date);
              props?.setDateRange(date,e, props.dateId);
              setErrorMsg("");
             
             
            
            } else {
              setEditSelectedValue(date);
              props.setDateRange(date, props.dateId);
              console.log(props?.minDate,props)
            }
          }}
          onFocus={() => {
            if(props?.parentProp?.header !== "Edit Project"){
              if(!selectedValue){
                setSelectedValue(null);
              }
              //  setSelectedValue (null);
               props?.setDateRange(null);
              console.log(props)
            
            }
           
           
          }}
          autoComplete="off"
          ref={datepickerRef}
          onKeyDown={(e) => {
        e.preventDefault();
       
      }}
      onBlur={()=>{
        if(selectedValue===null && !props.formikError){
          setErrorMsg("Start date is required")
          console.log(selectedValue,props.selectedDate)
        }else{
          setErrorMsg("")
          console.log(selectedValue)
        }
      }}
      disabled={props?.parentProp?.editDetails?.projectStatus==="INPROGRESS" || !props.loading || props?.parentProp?.editDetails?.projectStatus==="CLOSED" }
        />
       
      </div>
      <div className="calendar-icon" onClick={handleClickDatepickerIcon}>
        <CalendarSvg />
      </div>
    </div>
    <p className="text-red-500 errortext md:mt-1">{errorMsg}</p>
    </>
  );
};

export default TYDateTimePicker;
