import React, { useState, useEffect } from "react";
import Users from "./users";
import AdminUsers from "./admin-users";
function ProjectTesters(props) {
  const [privilageApiResp, setPrivilageApiResp] = useState(
    localStorage.hasOwnProperty("user-privilege")
      ? JSON.parse(localStorage["user-privilege"])
      : {}
  );

  useEffect(() => {
    setPrivilageApiResp(
      localStorage.hasOwnProperty("user-privilege")
        ? JSON.parse(localStorage["user-privilege"])
        : {}
    );
  }, [localStorage["user-privilege"]]);

  return (
    <>
      {privilageApiResp?.role === "PROJECT_OWNER" ? (
        <Users MyAlert={props.MyAlert} />
      ) : (
        <AdminUsers
          MyAlert={props.MyAlert}
          actions={privilageApiResp?.user?.actions}
        />
      )}
    </>
  );
}
export default ProjectTesters;
