import React, { useState, useEffect, useContext } from "react";
import { useTable, useGlobalFilter } from "react-table";
import Close from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import TesterDeatilsPopup from "../modal/tester-deatils-popup";
import BugsDeatilsPopup from "../modal/bugs-list-popup";
import Add from "@mui/icons-material/Add";
import CreateUser from "./modals/create-user";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";
import ContextMenu from "../modal/contex-modal";
import WarningPopup from "../modal/warning-popup";
import {
  getAllUsersList,
  getAllUsersListhierarchy,
  resetActivationLink,
  removeUserReq,
  getAllUserRoles,
  getUserHierarchyReq,
  getUserCountReq,
} from "../../api/api-services";
import { ReportSharp } from "@material-ui/icons";
import ActivationLink from "../SignupPage/warning-activation-link";
import UserWarningPopup from "../modal/user-warning-popup";
import { Tooltip } from "@mui/material";
import { ReactComponent as AddIcon } from "../../assets/addIcon.svg";
import AddTemplate from "./modals/user-template-modal";
import SuccessPopup from "../modal/status-popup";
import { ReactComponent as DeleteIcon } from "../../assets/deleteBlack.svg";
import { ReactComponent as EditIcon } from "../../assets/editBlack.svg";
import IconButton from "@mui/material/IconButton";
//  import { ReactComponent as NoUserIcon } from "../../assets/noUsersIcon.svg";
import RejecteduserPopup from "../modal/rejecteduser-modal";
// import ProgressContainer from "../common/inputs/ProgressContainer";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer.js";
import { fireCrowdUserData, selectedProjectData } from "../../actions/firecrowd-User-slice";
import { useSelector, useDispatch } from 'react-redux'
import SearchField from "../common/search-field";
import NoUserIcon from "../../assets/reportsicon/no_reports_icon.png";

function AdminUsers(props) {
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  let [tester, setTester] = useState([]);
  let [showSearch, setShowSearch] = useState(false);
  let [testerDeatils, setTesterDeatils] = useState();
  let [testerPopup, setTesterPopup] = useState(false);
  let [createUserModal, setCreateUserModal] = useState(false);
  let [addUserTemplateModal, setAddUserTemplateModal] = useState(false);
  let [role, setRole] = useState("");
  let [dataLoaded, setDataLoaded] = useState(false);
  let [roleDropDownData, setRoleDropDownData] = useState([]);
  let [confirmDelete, setConfirmDelete] = useState(false);
  let [removeUser, setRemoveUser] = useState();
  let [projectowner, setProjectOwner] = useState("");
  let [accountManager, setAccountManager] = useState("");
  let [userData, setUserData] = useState({});
  let [createUserHeader, setCreateUserHeader] = useState("");
  let [userMode, setUserMode] = useState("");
  let loggedinUser = JSON.parse(localStorage["user-privilege"]);
  const user = JSON.parse(localStorage.getItem("fire-crowd-user"));
  let selectedproject = JSON.parse(localStorage["selected-project"]);
  let [templateconfig, settemplateconfig] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  let [userCount, setUserCount] = useState([]);
  const [selectedTemplate, setselectedTemplate] = useState([]);
  const [allTemplate, setAllTemplate] = useState([]);
  const [statusData, setStatusData] = useState({});
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showrejectedUser, setRejectedUser] = useState(false);
  const [rejectedData, setrejectedData] = useState([]);
  const fireCrowdUser = useSelector(fireCrowdUserData)
  const selectedProj = useSelector(selectedProjectData)
  const [hasProjectOwner,setHasProjectOwner]=useState(false);
  const [userdatacount,setUsataCount]=useState(0);
  const [TotaluserCount,setTotaltUserCount]=useState(0)
  let [closeSearchField, setCloseSearchField] = useState(false);
  let [pageno,setpageNo]=useState(0);
  let [lastPage,setLastpage]=useState(false);
  let [rejectedPayload,setRejectedPayload]=useState({});
  let size=20;



  const contextMenuData = [
    {
      option: "User Details",
    },
    {
      option: "Resend Activation Link"

    }
    
  ];

  if (loggedinUser?.role?.includes("_")) {
    loggedinUser.role = loggedinUser.role.replace("_", "-");
  }

  const isUserConfigured = () => {
    if (
      loggedinUser.role === "ADMIN" &&
      templateconfig &&
      (selectedproject.projectStatus === "APPROVED" ||
        selectedproject.projectStatus === "INPROGRESS")
    ) {
      
      findRole();
      setCreateUserModal(true);
      setCloseSearchField(true)
    }
  };

  useEffect(() => {
    getAllUsers(0);
  }, []);

  function getUserCount (projectID,leadEmail){
    getUserCountReq(projectID,leadEmail).then((result)=>{
     if(result.status===200){
      setTotaltUserCount(result.data.userCount)
     }

    }).catch((error)=>{
      console.log("Error")
    })
  }

  const editUserDetails = async (user, state, data) => {
    console.log("editUserDetails",user)
    let userData=tester;
    resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 80,
      });
    if (user?.role !== "PROJECT-OWNER") {
      let editable = true;
      let roleData = [];
      let loggedinUserrole = loggedinUser.role;
      let roledropdoenData = [];
      let selectedhirarchy;
      try {
        let response = await getUserHierarchyReq();
        if (response.data) {
          response.data.map((value) => {
            let userList = Object.keys(value?.template);
            let name = userList.join(">");
            if (
              selectedproject?.usersHierarchyTemplateID &&
              value.id === selectedproject?.usersHierarchyTemplateID
            ) {
              selectedhirarchy = value;
              setselectedTemplate([{ ...value, label: name }]);
            }
          });
        }
      } catch (err) {
        resetProgress();
        console.error(err);
      }
      if (selectedhirarchy) {
        roleData = [];
        Object.keys(selectedhirarchy?.template).map((data) => {
          if (data?.includes("_")) {
            roleData.push(data.replace("_", "-"));
          } else {
            roleData.push(data);
          }
        });
      }
      let numberofRole = {};
      roleData.map((data) => {
        numberofRole[data] = 0;
      });
      if (roleData.indexOf(loggedinUser.role) < roleData.indexOf(user.role)) {
       
        if (data.length > 0) {
          console.log("ACCOUNT-MANAGER",data)
          for (let i = 0; i < data.length; i++) {
          
            if (data[i].role === "PROJECT-OWNER") {
              setProjectOwner(data[i].email);
            }
            if (data[i].role === "ACCOUNT-MANAGER") {
             
              setAccountManager(data[i]);
            }
            numberofRole[data[i].role] = numberofRole[data[i].role] + 1;
          }
        }
        let userdrop = [...roleData];
        let userIndex = roleData.indexOf(loggedinUser.role);
        roledropdoenData = [
          ...userdrop.splice(userIndex + 1, roleData.length - userIndex),
        ];
        if (roledropdoenData.includes(loggedinUser.role)) {
          let i = roledropdoenData.indexOf(loggedinUser.role);
          roledropdoenData.splice(i, 1);
        }
        if (
          roledropdoenData.includes("ACCOUNT-MANAGER") &&
          user?.role !== "ACCOUNT-MANAGER"
        ) {
          let i = roledropdoenData.indexOf("ACCOUNT-MANAGER");
          roledropdoenData.splice(i, 1);
        }
      } else {
        editable = false;
        resetProgress();
      }
      if (editable) {
        setUserCount({ ...numberofRole });
        setCreateUserHeader("Edit user in project");
        setRoleDropDownData([...roledropdoenData]);
        setUserData(user);
        setRole(user.role);
        setUserMode("Edit User");
        setCreateUserModal(true);
        setCloseSearchField(true)
      }
    }
  };
  function removeUserDetails(user, state) {
    if (user.role !== "PROJECT_OWNER") {
      setRemoveUser(user);
      setConfirmDelete(state);
    }
  }
  function closeRejecteduserPopup (state,data,payload){
    setrejectedData(data);
    setRejectedUser(state);
    setRejectedPayload(payload);
  }
  function closeRejectedModal (state){
    setRejectedUser(state);
  }
  const removeUsers = async (e) => {
    let userId = removeUser.id;
    let projectId = JSON.parse(localStorage.getItem("selected-project")).id;
    try {
      let response = await removeUserReq(
        [removeUser.email],
        projectId,
        user.userName
      );
      console.log("removeUsers",response)
      if (response.status === 200) {
        if (response?.data?.httpStatusCode=== 200 && response?.data?.httpStatus==="OK" && response?.data?.errorMessage===null) {
          props.MyAlert.success(`${removeUser?.name} removed successfully`);
          if(removeUser.role==="ACCOUNT-MANAGER"){
            setAccountManager("")
          }
        } else if(response?.data?.errorMessage===
          "User cannot be deleted; test cases have been assigned."){
          props.MyAlert.info(
            response?.data?.errorMessage
          );
        }
        else if (
          response?.data?.rejectDefectsOrTestcaseErrorMessage !== null
        ) {
          props.MyAlert.info(
            response?.data?.rejectDefectsOrTestcaseErrorMessage
          );
        } else if (response?.data?.rejectProjectStatusErrorMessage !== null) {
          props.MyAlert.info(response?.data?.rejectProjectStatusErrorMessage);
        } else {
          props.MyAlert.info(response?.data?.rejectUserDependencyErrorMessage);
        }
      }
      getAllUsers(0);
    } catch (error) {
      console.log("error in users");
    }
  };
  const getAllUsers = async (page) => {

    console.log(fireCrowdUser,selectedProj, "---------------------------user");
    const projectID = JSON.parse(localStorage.getItem("selected-project")).id;
    const email = JSON.parse(localStorage["fire-crowd-user"]).userName;
    let Edit = JSON.parse(localStorage.getItem("user-privilege"))?.user?.actions
      ?.edit;
    let Delete = JSON.parse(localStorage.getItem("user-privilege"))?.user
      ?.actions?.delete;
    let UserArray = [];
    let count = 0;
    let selectedHirerachy;
    if(page===0){
      try {
        let response = await getUserHierarchyReq();
        resetProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 80,
        });
        if (response.data) {
          setAllTemplate([...response.data]);
         
          response.data.map((value) => {
            let userList = Object.keys(value?.template);
            let name = userList.join(">");
            if (
              selectedproject?.usersHierarchyTemplateID &&
              value.id === selectedproject?.usersHierarchyTemplateID
            ) {
              localStorage.setItem("selected-hirerachy", JSON.stringify(value));
              selectedHirerachy = value;
              setselectedTemplate([{ ...value, label: name }]);
            }
          });
        }
      } catch (err) {
        resetProgress();
        console.error(err);
      }
    } else {
      selectedHirerachy = JSON.parse(localStorage.getItem("selected-hirerachy"));
    }
   
    try {
      if (JSON.parse(localStorage.getItem("user-privilege")).role !== "ADMIN") {
        getAllUsersListhierarchy(projectID, email,page,size).then((response) => {
          console.log("responser rrrrr",response)
          if (response.data) {
            setLastpage(response?.data?.lastPage)
            response?.data?.userList?.map((res, i) => {
              if (res?.role !== "ADMIN") {
                if(res?.role==="PROJECT_OWNER" ){
                  setHasProjectOwner(true)
                }
                
                 
                const Action = [
                  <div className="flex action" style={{marginLeft:"30%"}}>
                    <Tooltip
                      title={`${
                        !Edit ||
                        res?.dependentsCount > 0 ||
                        res?.totalDefectCount > 0 ||
                        res?.role === "PROJECT_OWNER" ||
                        res?.role === "ACCOUNT-MANAGER" ||
                                res?.role === "ACCOUNT_MANAGER" ||
                        res?.role.replace("_", "-") === loggedinUser?.role ||
                        res?.role === loggedinUser?.role ||
                        selectedproject.projectStatus === "REJECTED" ||
                        selectedproject.projectStatus === "CLOSED" 
                        ||selectedproject.projectStatus === "COMPLETED"||
                        (Object.keys(selectedHirerachy?.template).length ===
                          5 &&
                          loggedinUser?.role === "PROJECT-MANAGER")
                          ? "User cannot be edited"
                          : "Edit"
                      }`}
                      placement="top"
                    >
                      <div>
                        <IconButton
                          sx={
                            !Edit ||
                            res?.dependentsCount > 0 ||
                            res?.totalDefectCount > 0 ||
                            res?.testCases?.length > 0 ||
                            res?.role === "PROJECT_OWNER" ||
                            res?.role === "ACCOUNT-MANAGER" ||
                                res?.role === "ACCOUNT_MANAGER" ||
                            res?.role.replace("_", "-") ===
                              loggedinUser?.role ||
                            res?.role === loggedinUser?.role ||
                            selectedproject.projectStatus === "REJECTED" ||
                            selectedproject.projectStatus === "CLOSED"|| selectedproject.projectStatus === "COMPLETED"
                              ? {
                                  "&:hover": {
                                    opacity: "0.4",
                                    backgroundColor: "#FAF9F6",
                                    boxShadow:
                                      " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                                  },
                                }
                              : {
                                  "&:hover": {
                                    backgroundColor: "#FAF9F6",
                                    boxShadow:
                                      " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                                  },
                                }
                          }
                          style={{marginLeft:"16%"}}
                         
                        >
                          <button
                            disabled={
                              !Edit ||
                              res?.dependentsCount > 0 ||
                              res?.totalDefectCount > 0 ||
                              res?.testCases?.length > 0 ||
                              res?.role === "PROJECT_OWNER" ||
                              res?.role === "ACCOUNT-MANAGER" ||
                                res?.role === "ACCOUNT_MANAGER" ||
                              res?.role.replace("_", "-") ===
                                loggedinUser?.role ||
                              res?.role === loggedinUser?.role ||
                              selectedproject.projectStatus === "REJECTED" ||
                              selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"||
                              (Object.keys(selectedHirerachy?.template)
                                .length === 5 &&
                                loggedinUser?.role === "PROJECT-MANAGER")
                                ? true
                                : false
                            }
                            className={
                              !Edit ||
                              res?.dependentsCount > 0 ||
                              res?.totalDefectCount > 0 ||
                              res?.testCases?.length > 0 ||
                              res?.role === "PROJECT_OWNER" ||
                              res?.role === "ACCOUNT-MANAGER" ||
                                res?.role === "ACCOUNT_MANAGER" ||
                              res?.role.replace("_", "-") ===
                                loggedinUser?.role ||
                              res?.role === loggedinUser?.role ||
                              selectedproject.projectStatus === "REJECTED" ||
                              selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"||
                              (Object.keys(selectedHirerachy?.template)
                                .length === 5 &&
                                loggedinUser?.role === "PROJECT-MANAGER")
                                ? "opacity-50"
                                : "block"
                            }
                            onClick={() => {
                              editUserDetails(res, true, tester.concat(response.data?.userList));
                            }}
                          >
                            <EditIcon />
                          </button>
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={`${
                        !Delete ||
                        res?.dependentsCount > 0 ||
                        res?.totalDefectCount > 0 ||
                        res?.testCases?.length > 0 ||
                        res?.role === "PROJECT_OWNER" ||
                        res?.role.replace("_", "-") === loggedinUser?.role ||
                        res?.role === loggedinUser?.role ||
                        selectedproject.projectStatus === "REJECTED" ||
                        selectedproject.projectStatus === "CLOSED"|| selectedproject.projectStatus === "COMPLETED"
                          ? "User cannot be deleted"
                          : "Remove"
                      } `}
                      placement="top"
                    >
                      <div className="mx-4">
                        <IconButton
                          sx={
                            !Delete ||
                            res?.dependentsCount > 0 ||
                            res?.totalDefectCount > 0 ||
                            res?.testCases?.length > 0 ||
                            res?.role === "PROJECT_OWNER" ||
                            res?.role.replace("_", "-") ===
                              loggedinUser?.role ||
                            res?.role === loggedinUser?.role ||
                            res.role === "PROJECT-OWNER" ||
                            selectedproject.projectStatus === "REJECTED" ||
                            selectedproject.projectStatus === "CLOSED" ||selectedproject.projectStatus === "COMPLETED"
                              ? {
                                  "&:hover": {
                                    opacity: "0.4",
                                    backgroundColor: "#FAF9F6",
                                    boxShadow:
                                      " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                                  },
                                }
                              : {
                                  "&:hover": {
                                    backgroundColor: "#FAF9F6",
                                    boxShadow:
                                      " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                                  },
                                }
                          }
                          onClick={() => {
                            !Delete ||
                            res?.dependentsCount > 0 ||
                            res?.totalDefectCount > 0 ||
                            res?.testCases?.length > 0 ||
                            res?.role === "PROJECT_OWNER" ||
                            res?.role.replace("_", "-") ===
                              loggedinUser?.role ||
                            res?.role === loggedinUser?.role ||
                            res.role === "PROJECT-OWNER" ||
                            selectedproject.projectStatus === "REJECTED" ||
                            selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"
                              ? setConfirmDelete(false)
                              : removeUserDetails(res, true);
                          }}
                        >
                          <button
                            className={
                              !Delete ||
                              res?.dependentsCount > 0 ||
                              res?.totalDefectCount > 0 ||
                              res?.testCases?.length > 0 ||
                              res?.role === "PROJECT_OWNER" ||
                              res?.role.replace("_", "-") ===
                                loggedinUser?.role ||
                              res?.role === loggedinUser?.role ||
                              selectedproject.projectStatus === "REJECTED" ||
                              selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"
                                ? "opacity-50"
                                : "block"
                            }
                          >
                            {/* <PersonRemoveAlt1Icon
                            
                          /> */}
                            <DeleteIcon
                              // onClick={() => {
                              //   !Delete ||
                              //   res?.dependentsCount > 0 ||
                              //   res?.totalDefectCount > 0 ||
                              //   res?.testCases?.length > 0 ||
                              //   res?.role === "PROJECT_OWNER" ||
                              //   res?.role.replace("_", "-") ===
                              //     loggedinUser?.role ||
                              //   res?.role === loggedinUser?.role ||
                              //   res.role === "PROJECT-OWNER" ||
                              //   selectedproject.projectStatus === "REJECTED" ||
                              //   selectedproject.projectStatus === "CLOSED"
                              //     ? setConfirmDelete(false)
                              //     : removeUserDetails(res, true);
                              // }}
                              height="15px"
                              width="15px"
                            />
                          </button>
                        </IconButton>
                      </div>
                    </Tooltip>

                    {/* <Tooltip title="More" placement="top"> */}
                      <div style={{marginLeft:"-8%"}}>
                        {/* <MoreVertIcon /> */}
                        <ContextMenu
                          contextMenuData={contextMenuData}
                          state={res.status} 
                          func={(e) => {
                            contextMenuFunction(e, res);
                          }}
                          onMouseOutz
                        />
                      </div>
                    {/* </Tooltip> */}
                  </div>,
                ];
                if (res?.role?.includes("_")) {
                  res.role = res.role.replace("_", "-");
                }
                UserArray.push({ ...res, Action: Action });
                if (res.role !== "ADMIN") {
                  count = count + 1;
                }
              }
            });
            if (count === 1) {
              settemplateconfig(true);
            } else {
              settemplateconfig(false);
            }
            // setTester(UserArray);
            // setUsataCount(UserArray.length)
            if(page===0){
              setTester(UserArray);
              setUsataCount(UserArray.length)
              if(response?.data?.lastPage===false){
                setpageNo(page+1);
               
              }
              getUserCount(projectID, email)
            }else{
               let tempArray=tester.concat(UserArray);
               setTester(tempArray);
               setUsataCount(tempArray.length);
               if(response?.data?.lastPage===false){
                setpageNo(page+1);
              }
            }
          }
          setDataLoaded(true);
        });
      } else {
        getAllUsersList(projectID,page,size).then((response) => {
          console.log("responser rrrrr",response)
          setLastpage(response?.data?.lastPage)
          if (response.data.userList) {
             setTotaltUserCount(response.data.userCount)
            response.data?.userList?.map((res, i) => {
              if (res?.role !== "ADMIN") {
                if(res?.role==="PROJECT_OWNER" ){
                  setHasProjectOwner(true)
                }
                const Action = [
                  <div className="flex action" style={{marginLeft:"30%"}}>
                    {}
                    <Tooltip
                      title={`${
                        !Edit ||
                        res?.dependentsCount > 0 ||
                        res?.totalDefectCount > 0 ||
                        res?.testCases?.length > 0 ||
                        res?.role === "PROJECT_OWNER" ||
                        res?.role === "ACCOUNT-MANAGER" ||
                                res?.role === "ACCOUNT_MANAGER" ||
                        res?.role.replace("_", "-") === loggedinUser?.role ||
                        res?.role === loggedinUser?.role ||
                        selectedproject.projectStatus === "REJECTED" ||
                        selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"
                          ? "User cannot be edited"
                          : "Edit"
                      }`}
                      placement="top"
                    >
                      <div>
                        <IconButton
                          sx={
                            !Edit ||
                            res?.dependentsCount > 0 ||
                            res?.totalDefectCount > 0 ||
                            res?.testCases?.length > 0 ||
                            res?.role === "PROJECT_OWNER" ||
                            res?.role === "ACCOUNT-MANAGER" ||
                                res?.role === "ACCOUNT_MANAGER" ||
                            res?.role.replace("_", "-") ===
                              loggedinUser?.role ||
                            res?.role === loggedinUser?.role ||
                            selectedproject.projectStatus === "REJECTED" ||
                            selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"
                              ? {
                                  "&:hover": {
                                    opacity: "0.4",
                                    backgroundColor: "#FAF9F6",
                                    boxShadow:
                                      " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                                  },
                                }
                              : {
                                  "&:hover": {
                                    backgroundColor: "#FAF9F6",
                                    boxShadow:
                                      " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                                  },
                                }
                          }
                           style={{marginLeft:"16%"}}
                          
                        >
                          <button
                            disabled={
                              !Edit ||
                              res?.dependentsCount > 0 ||
                              res?.totalDefectCount > 0 ||
                              res?.testCases?.length > 0 ||
                              res?.role === "PROJECT_OWNER" ||
                              res?.role === "ACCOUNT-MANAGER" ||
                                res?.role === "ACCOUNT_MANAGER" ||
                              res?.role.replace("_", "-") ===
                                loggedinUser?.role ||
                              res?.role === loggedinUser?.role ||
                              selectedproject.projectStatus === "REJECTED" ||
                              selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"
                                ? true
                                : false
                            }
                            className={
                              !Edit ||
                              res?.dependentsCount > 0 ||
                              res?.totalDefectCount > 0 ||
                              res?.testCases?.length > 0 ||
                              res?.role === "PROJECT_OWNER" ||
                              res?.role === "ACCOUNT-MANAGER" ||
                                res?.role === "ACCOUNT_MANAGER" ||
                              res?.role.replace("_", "-") ===
                                loggedinUser?.role ||
                              res?.role === loggedinUser?.role ||
                              selectedproject.projectStatus === "REJECTED" ||
                              selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"
                                ? "opacity-50"
                                : "block"
                            }
                            onClick={() => {
                              editUserDetails(
                                res,
                                true,
                                tester.concat(response.data?.userList)
                                
                              );
                            }}
                           
                          >
                            <EditIcon />
                          </button>
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={`${
                        !Delete ||
                        res?.dependentsCount > 0 ||
                        res?.totalDefectCount > 0 ||
                        res?.testCases?.length > 0 ||
                        res?.role === "PROJECT_OWNER" ||
                        res?.role.replace("_", "-") === loggedinUser?.role ||
                        res?.role === loggedinUser?.role ||
                        selectedproject.projectStatus === "REJECTED" ||
                        selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"
                          ? "User cannot be deleted"
                          : "Remove"
                      } `}
                      placement="top"
                    >
                      <div className="mx-4">
                        <IconButton
                          sx={
                            !Delete ||
                            res?.dependentsCount > 0 ||
                            res?.totalDefectCount > 0 ||
                            res?.testCases?.length > 0 ||
                            res?.role === "PROJECT_OWNER" ||
                            res?.role.replace("_", "-") ===
                              loggedinUser?.role ||
                            res?.role === loggedinUser?.role ||
                            res.role === "PROJECT-OWNER" ||
                            selectedproject.projectStatus === "REJECTED" ||
                            selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"
                              ? {
                                  "&:hover": {
                                    opacity: "0.4",
                                    backgroundColor: "#FAF9F6",
                                    boxShadow:
                                      " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                                  },
                                }
                              : {
                                  "&:hover": {
                                    backgroundColor: "#FAF9F6",
                                    boxShadow:
                                      " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                                  },
                                }
                          }
                          onClick={() => {
                            !Delete ||
                            res?.dependentsCount > 0 ||
                            res?.totalDefectCount > 0 ||
                            res?.testCases?.length > 0 ||
                            res?.role === "PROJECT_OWNER" ||
                            res?.role.replace("_", "-") ===
                              loggedinUser?.role ||
                            res?.role === loggedinUser?.role ||
                            res.role === "PROJECT-OWNER" ||
                            selectedproject.projectStatus === "REJECTED" ||
                            selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"
                              ? setConfirmDelete(false)
                              : removeUserDetails(res, true);
                          }}
                        >
                          <button
                            className={
                              !Delete ||
                              res?.dependentsCount > 0 ||
                              res?.totalDefectCount > 0 ||
                              res?.testCases?.length > 0 ||
                              res?.role === "PROJECT_OWNER" ||
                              res?.role.replace("_", "-") ===
                                loggedinUser?.role ||
                              res?.role === loggedinUser?.role ||
                              selectedproject.projectStatus === "REJECTED" ||
                              selectedproject.projectStatus === "CLOSED" || selectedproject.projectStatus === "COMPLETED"
                                ? "opacity-50"
                                : "block"
                            }
                          >
                            {/* <PersonRemoveAlt1Icon /> */}
                            <DeleteIcon
                              // onClick={() => {
                              //   !Delete ||
                              //   res?.dependentsCount > 0 ||
                              //   res?.totalDefectCount > 0 ||
                              //   res?.testCases?.length > 0 ||
                              //   res?.role === "PROJECT_OWNER" ||
                              //   res?.role.replace("_", "-") ===
                              //     loggedinUser?.role ||
                              //   res?.role === loggedinUser?.role ||
                              //   res.role === "PROJECT-OWNER" ||
                              //   selectedproject.projectStatus === "REJECTED" ||
                              //   selectedproject.projectStatus === "CLOSED"
                              //     ? setConfirmDelete(false)
                              //     : removeUserDetails(res, true);
                              // }}
                              height="15px"
                              width="15px"
                            />
                          </button>
                        </IconButton>
                      </div>
                    </Tooltip>

                    <Tooltip title="More" placement="top">
                      <div style={{marginLeft:"-8%"}}>
                        {/* <MoreVertIcon /> */}
                        <ContextMenu
                          contextMenuData={contextMenuData}
                          state={res.status}
                          
                          func={(e) => {
                            contextMenuFunction(e, res);
                          }}
                          onMouseOutz
                        />
                      </div>
                    </Tooltip>
                  </div>,
                ];
                if (res?.role?.includes("_")) {
                  res.role = res.role.replace("_", "-");
                }
                UserArray.push({ ...res, Action: Action });
                if (res.role !== "ADMIN") {
                  count = count + 1;
                }
              }
            });
            if (count === 1) {
              settemplateconfig(true);
            } else {
              settemplateconfig(false);
            }
            if(page===0){
              setTester(UserArray);
              setUsataCount(UserArray.length)
              if(response?.data?.lastPage===false){
                setpageNo(page+1);
              }
            }else{
               let tempArray=tester.concat(UserArray);
               setTester(tempArray);
               setUsataCount(tempArray.length);
               if(response?.data?.lastPage===false){
                setpageNo(page+1);
              }
            }
            // setTester(UserArray);
            // setUsataCount(UserArray.length)

          }
          setDataLoaded(true);
        });
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handlescroll = (event) => {
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ){
      if(lastPage===false && globalFilter===undefined){
        getAllUsers(pageno)
      }
    }
  }

  

  const sendActivationLink = async (emailId) => {
    const data = {
      emailId: emailId,
      source: "FireCrowd",
    };
    try {
      let response = await resetActivationLink(data);
      if (response.status === 200) {
        if (response.data.responseCode === 200) {
          props.MyAlert.success(`Activation link sent successfully`);
        } else if (response.data.responseCode === 404) {
          props.MyAlert.info(`Email Id does not exist`);
        } else {
          console.log("somthing went wrong");
        }
      }
    } catch {
      console.log("error");
    }
  };
  function contextMenuFunction(event, resObj) {
    if (event.target.textContent === "User Details") {
      setTesterDeatils(resObj);
      setTesterPopup(true);
      setCloseSearchField(true)
    }
    if (event.target.textContent === "Resend Activation Link") {
      if(resObj.status!=="ACTIVE"){
        sendActivationLink(resObj?.email);
      }
     
    }
  }
  function CloseTesterModal(state) {
    if(globalFilter!==undefined){
      setShowSearch(true)
    }else{
      setShowSearch(false)
    }
    
    setTesterPopup(state);
  }
  function CloseUserWarningModal(state) {
    setConfirmDelete(state);
  }
  let findRole = async () => {
    let roleData = [];
    let roledropdoenData = [];
    let presentRole = [];
    let selectedRole = "";
    let selectedhirarchy;
    try {
      let response = await getUserHierarchyReq();
      if (response.data) {
        setAllTemplate([...response.data]);
        response.data.map((value) => {
          let userList = Object.keys(value?.template);
          let name = userList.join(">");
          if (
            selectedproject?.usersHierarchyTemplateID &&
            value.id === selectedproject?.usersHierarchyTemplateID
          ) {
            selectedhirarchy = value;
            setselectedTemplate([{ ...value, label: name }]);
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
    if (selectedhirarchy) {
      roleData = [];
      Object.keys(selectedhirarchy?.template).map((data) => {
        if (data?.includes("_")) {
          roleData.push(data.replace("_", "-"));
        } else {
          roleData.push(data);
        }
      });
    }
    if (data.length === 0 || selectedproject.projectStatus === "INREVIEW") {
      setRole("PROJECT-OWNER");
      roledropdoenData = ["PROJECT-OWNER"];
    } else if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].role === "PROJECT-OWNER") {
          setProjectOwner(data[i].email);
        }
        if (data[i].role === "ACCOUNT-MANAGER") {
          setAccountManager(data[i]);
        }
        if (!presentRole.includes(data[i].role)) {
          presentRole.push(data[i].role);
        }
      }
      if (loggedinUser.role === "TEST-LEAD" || roledropdoenData.length === 0) {
        roledropdoenData = [...roleData];
      }
      if (
        !presentRole.includes("PROJECT-OWNER") &&
        loggedinUser.role === "ADMIN"
      ) {
        selectedRole = "PROJECT-OWNER";
        roledropdoenData = ["PROJECT-OWNER"];
      } else if (
        !presentRole.includes("ACCOUNT-MANAGER") &&
        loggedinUser.role === "ADMIN"
      ) {
        selectedRole = "ACCOUNT-MANAGER";
        roledropdoenData = ["ACCOUNT-MANAGER"];
      } else if (
        !presentRole.includes("PROJECT-MANAGER") &&
        roleData.includes("PROJECT-MANAGER") &&
        ["ADMIN", "ACCOUNT-MANAGER"].includes(loggedinUser.role)
      ) {
        selectedRole = "PROJECT-MANAGER";
        roledropdoenData = ["PROJECT-MANAGER"];
      } else if (
        !presentRole.includes("TEST-LEAD") &&
        roleData.includes("TEST-LEAD") &&
        roleData.indexOf(loggedinUser.role) < roleData.indexOf("TEST-LEAD")
      ) {
        selectedRole = "TEST-LEAD";
        let userIndex = roleData.indexOf(loggedinUser.role) + 1;
        let tlIndex = roleData.indexOf("TEST-LEAD");
        if (!["PROJECT-OWNER"].includes(loggedinUser.role)) {
          roledropdoenData = [
            ...roleData.splice(userIndex, roleData.length - (userIndex + 1)),
          ];
        }
      } else {
        if (!presentRole.includes("TESTER")) {
          selectedRole = "TESTER";
        }
        let userIndex = roleData.indexOf(loggedinUser.role);
        roledropdoenData = [
          ...roleData.splice(userIndex + 1, roleData.length - userIndex),
        ];
      }
      if (
        loggedinUser.role === "ADMIN" &&
        !roledropdoenData.includes("PROJECT-OWNER")
      ) {
        roledropdoenData.unshift("PROJECT-OWNER");
      } else if (loggedinUser.role !== "ADMIN") {
        if (roledropdoenData.includes("PROJECT-OWNER")) {
          roledropdoenData.splice(roleData.indexOf("PROJECT-OWNER"), 1);
        }
      }
      if (
        presentRole.includes("ACCOUNT-MANAGER") &&
        roledropdoenData.includes("ACCOUNT-MANAGER")
      ) {
        roledropdoenData.splice(roledropdoenData.indexOf("ACCOUNT-MANAGER"), 1);
      }
      if (roledropdoenData.includes("ADMIN")) {
        roledropdoenData.splice(roleData.indexOf("ADMIN"), 1);
      }
      setRole(selectedRole);
    }
    setRoleDropDownData([...roledropdoenData]);
  };
  function CloseCreateUserModal(state) {
    setRole("");
    setRoleDropDownData([]);
    setUserData({})
    setCreateUserModal(state);
  }
  const closeModal = (val) => {
    if (showStatusModal) {
      setShowStatusModal(val);
    }
  };
  function getRole(role){
    switch(role){
      case "PROJECT-OWNER":{
        return "Project Owner"
      }
      case "PROJECT-MANAGER":{
        return "Project Manager"
      }
      case "ACCOUNT-MANAGER":{
        return "Account Manager"
      }
      case "TEST-LEAD":{
        return "Test Lead"
      }
      case "TESTER":{
        return "Test Engineer"
      }
      case "ADMIN":{
        return "Admin"
      }
      default:{
        return role;
      }
    }

  }

  let columns = React.useMemo(
    () => [
      {
        Header: "NAME",
        accessor: "name",
        width: "15%",
        Cell: (e) => (
          <div
            title={e.value}
            className="datatrimmer w-28 table_cell_links"
            style={{ marginLeft: "8%" }}
          >
          <span onClick={(event) => {
              event.preventDefault();
              setTesterDeatils(e.cell.row.original);

              setTesterPopup(true);
              setCloseSearchField(true)
            }}>{e.value}</span>  
          </div>
        ),
      },
      {
        Header: "EMAIL",
        accessor: "email",
        width: "22%",
        Cell: ({ value }) => {
          return (
            <div
              className="datatrimmer w-44"
              style={{ marginLeft: "-9%" }}
              title={value}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "ROLE",
        accessor: "role",
        width: "20%",
        Cell: ({ value }) => {
          return <div style={{ marginLeft: "17%" }}>{getRole(value)}</div>;
        },
      },
      {
        Header: "REPORTING TO",
        accessor: "lead",
        width: "25%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "30%" }}
              className="datatrimmer w-44"
            >
              {value === "" ? "none" : value}
            </div>
          );
        },
      },
      {
        Header: "STATUS",
        accessor: "status",
        width: "12%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "71%" }}
              className={value === "ACTIVE" ? "active" : "pending"}
            >
              {value === null ? "--" : value==="ACTIVE"?"Active":value==="PENDING"?"Pending":"--"}
            </div>
          );
        },
      },
      {
        Header: "ACTIONS",
        accessor: "Action",
        width: "13%",
      },
    ],
    []
  );
  const bulkuploadStatus = (data) => {
    setShowStatusModal(true);
    setStatusData({ ...data });
  };
  let data = React.useMemo(() => tester, [tester]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  return (
    <div className="user-deatils">
      {props?.actions?.create === true ? (
        <div className="grid grid-cols-12 flex content-between">
          <div className="col-span-8 heading pl-4 pt-2">
            {" "}
            {/* Users List - {rows.length}{" "}TotaluserCount */}
            Users List - {TotaluserCount}
          </div>
          <div className="grid grid-cols-12 col-span-4">
          <SearchField setGlobalFilter={setGlobalFilter} closeField={closeSearchField} setCloseField={setCloseSearchField} colSpan="col-span-8 ml-auto"/>
            <div className="grid grid-cols-12 ml-4 col-span-3">
              <Tooltip
                title={
                  (templateconfig &&
                    selectedproject.projectStatus === "INREVIEW") ||
                  selectedproject.projectStatus === "REJECTED" ||
                  selectedproject.projectStatus === "CLOSED"
                    ? selectedproject.projectStatus === "INREVIEW"
                      ? "Project is in Inreview state"
                      : selectedproject.projectStatus === "REJECTED"
                      ? hasProjectOwner?"Project is in Rejected state":"You can add only Project-Owner"
                      : (selectedproject.projectStatus === "CLOSED" && loggedinUser?.role==="ADMIN")
                      ? "Add user"
                      : "Project is in Closed state"
                    : "Add user"
                }
                placement="bottom"
              >
                <div
                  className={`grid grid-cols-12 col-span-11 create_buttton cursor-pointer ${
                    (templateconfig &&
                      selectedproject.projectStatus === "INREVIEW") ||
                    (selectedproject.projectStatus === "REJECTED" && hasProjectOwner) ||
                    ( (selectedproject.projectStatus === "CLOSED"|| selectedproject.projectStatus === "COMPLETED") && loggedinUser?.role!=="ADMIN")||
                    !dataLoaded
                      ? "opacity-50"
                      : null
                  }`}
                  onClick={() => {
                    if (
                      !(
                        (templateconfig &&
                          selectedproject.projectStatus === "INREVIEW") ||
                          (selectedproject.projectStatus === "REJECTED" && hasProjectOwner)||
                        ((selectedproject.projectStatus === "CLOSED"|| selectedproject.projectStatus === "COMPLETED") && loggedinUser?.role!=="ADMIN")||
                        !dataLoaded
                      )
                    ) {
                      setUserMode("Add User");
                      setCreateUserHeader("Add user to project");
                      if (
                        templateconfig &&
                        (selectedproject.projectStatus === "APPROVED" ||
                          selectedproject.projectStatus === "INPROGRESS")
                      ) {
                        isUserConfigured();
                      } else {
                        if((selectedproject.projectStatus === "CLOSED"|| selectedproject.projectStatus === "COMPLETED") && loggedinUser?.role ==="ADMIN"){
                          setRole("PROJECT-OWNER"); 
                          setRoleDropDownData(["PROJECT-OWNER"]);

                      }else{
                        findRole();
                      }
                        // findRole();
                        setCreateUserModal(true);
                        setCloseSearchField(true)
                      }
                    }
                  }}
                >
                  <span className="col-span-2 add_icon">
                    {/* <Add /> */}
                    <AddIcon />
                  </span>
                  <div className=" col-span-10 py-2 pl-6">User</div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-12 flex content-between">
          <div className="col-span-10 heading pl-3">
            {/* Users List - {rows.length} */}
            Users List - {TotaluserCount}
          </div>
          <div className="grid grid-cols-12 col-span-2 flex justify-end">
          <SearchField setGlobalFilter={setGlobalFilter} closeField={closeSearchField} setCloseField={setCloseSearchField} colSpan="col-span-11 ml-auto"/>
          </div>
        </div>
      )}

      <div className="tablecontainer">
        <table {...getTableProps()}>
          <thead className="text-left table_column_header h-10 ml-4">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th width={column.width} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {data.length === 0 && dataLoaded ? (
            <div className={`user_list_page_msg grid grid-cols-12 ${props?.actions?.create === true?"block":"hidden"}`}>
              <div className="col-span-12 flex justify-center pt-56">
                <div className="msg_text">Click </div>
                <Tooltip
                  title={
                    (templateconfig &&
                      selectedproject.projectStatus === "INREVIEW") ||
                    selectedproject.projectStatus === "REJECTED" ||
                    selectedproject.projectStatus === "CLOSED"
                      ? selectedproject.projectStatus === "INREVIEW"
                        ? "Project is in Inreview state"
                        : selectedproject.projectStatus === "REJECTED"
                        ? hasProjectOwner?"Project is in Rejected state":"You can add only Project-Owner"
                        : selectedproject.projectStatus === "CLOSED"
                        ? "Project is in Closed state"
                        : null
                      : "Add user"
                  }
                  placement="top"
                >
                  <div
                    className={`grid grid-cols-6 col-span-4 create_buttton cursor-pointer ${
                      (templateconfig &&
                        selectedproject.projectStatus === "INREVIEW") ||
                        (selectedproject.projectStatus === "REJECTED" && hasProjectOwner) ||
                      selectedproject.projectStatus === "CLOSED" ||
                      !dataLoaded
                        ? "opacity-50"
                        : null
                    }`}
                    onClick={() => {
                      if (
                        !(
                          (templateconfig &&
                            selectedproject.projectStatus === "INREVIEW") ||
                            (selectedproject.projectStatus === "REJECTED" && hasProjectOwner) ||
                          selectedproject.projectStatus === "CLOSED" ||
                          !dataLoaded
                        )
                      ) {
                        setUserMode("Add User");
                        setCreateUserHeader("Add user to project");
                        if (
                          templateconfig &&
                          (selectedproject.projectStatus === "APPROVED" ||
                            selectedproject.projectStatus === "INPROGRESS")
                        ) {
                          isUserConfigured();
                        } else {
                          findRole();
                          setCreateUserModal(true);
                          setCloseSearchField(true)
                        }
                      }
                    }}
                  >
                    <span className="col-span-2 add_icon">
                      {/* <Add /> */}
                      <AddIcon />
                    </span>
                    <div className=" col-span-3 py-2 px-3">User</div>
                  </div>
                </Tooltip>{" "}
                <div className="msg_text"> to add user</div>
              </div>
            </div>
          ) : dataLoaded ? (
            <tbody {...getTableBodyProps()} className="border-t-2" onScroll={handlescroll}>
              {rows.length > 0 ? (
                <>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="h-10 table_cell_value configHover border-b-2"
                        id="userlist"
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  {/* <div className="noDefects">
                    <NoUserIcon />
                  </div> */}
                  <div className="no_relevant_data">
                    <img  alt="warning" src={NoUserIcon}/>
                    <div className="text_msg">No Relevant Data Is Found !</div>
                  </div>
                </>
              )}
            </tbody>
          ) : null}
        </table>
      </div>
      {testerPopup ? (
        <TesterDeatilsPopup
          testerDeatils={testerDeatils}
          CloseTesterModal={CloseTesterModal}
        />
      ) : (
        ""
      )}
      {createUserModal && (
        <CreateUser
          MyAlert={props.MyAlert}
          closeModal={CloseCreateUserModal}
          header={createUserHeader}
          defaultRole={role}
          userData={userData}
          mode={userMode}
          getAllUsers={getAllUsers}
          tester={tester}
          userCount={userCount}
          selectedTemplate={selectedTemplate}
          allTemplate={allTemplate}
          projectowner={projectowner}
          accountManager={accountManager}
          bulkuploadStatus={bulkuploadStatus}
          roleDropDownData={roleDropDownData}
          closeRejecteduserPopup={closeRejecteduserPopup}
        />
      )}
      {confirmDelete && (
        <UserWarningPopup
          CloseUserWarningModal={CloseUserWarningModal}
          user={removeUser}
          removeUsers={removeUsers}
        />
      )}
      {/* {showStatusModal && (
        <SuccessPopup data={statusData} confirmMessage={closeModal} />
      )} */}
      {showrejectedUser && (<RejecteduserPopup
                            getAllUsers={getAllUsers}
                            MyAlert={props.MyAlert}
                            closeModal={closeRejectedModal}
                            rejectedData={rejectedData}
                            rejectedPayload={rejectedPayload}
                            closeRejecteduserPopup={closeRejecteduserPopup}
                            />)}
    </div>
  );
}
export default AdminUsers;
