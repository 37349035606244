import React, { useState, useEffect, props, useContext } from "react";
import { ReactComponent as NoReportsIcon } from "../../assets/noReportsAvailable.svg";
import { ReactComponent as DefectIcon } from "../../assets/reportsicon/Defect_icon.svg";
import { ReactComponent as DeviceIcon } from "../../assets/reportsicon/Device_icon.svg";
import { ReactComponent as BrowserIcon } from "../../assets/reportsicon/Browser_icon.svg";
import { ReactComponent as DaysIcon } from "../../assets/reportsicon/Days_icon.svg";
import { ReactComponent as ParticipentIcon } from "../../assets/reportsicon/Tester_icon.svg";
import { ReactComponent as Noreports } from "../../assets/reportsicon/noReportsAvailable.svg";
import { ReactComponent as OSICONDEFECTS } from "../../assets/reportsicon/OS_icon.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ReactComponent as DefectWhiteIcon } from "../../assets/reportsicon/white_icon/Defect_white_icon.svg";
import { ReactComponent as OSWHITEICONDEFECTS } from "../../assets/reportsicon/white_icon/OS_white_icon.svg";
import { ReactComponent as DeviceWhiteIcon } from "../../assets/reportsicon/white_icon/Device_white_icon.svg";
import { ReactComponent as DaysWhiteIcon } from "../../assets/reportsicon/white_icon/Days_white_icon.svg";
import { ReactComponent as ParticipentWhiteIcon } from "../../assets/reportsicon/white_icon/Tester_white_icon.svg";
import { ReactComponent as BrowserWhiteIcon } from "../../assets/reportsicon/white_icon/browser_white.svg";
import "../project/project.scss";
import "./report-details.scss";
import Switch from "@mui/material/Switch";
import {
  getProjectDetails,
  UpdateDefectExecutiveReport,
  DownLoadExcelFile,
  getFeedbackTemplateByprojectID,
  getFeedbackReportsReq,
  getAnswerForParticularQuestionReq,
  //new API
  getAllReportsData,
  getAlltrainerData,
  getDailyData,
  getAllOSData,
  getAllBrowserData,
  getAllDeviceData,
} from "../../api/api-services";

import dateFormat from "dateformat";
import ClientReport from "./client-report";
import DefectWarningPopup from "../modal/defect-warning-popup";
import RichTextEditor from "../config/rich-texteditor";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer.js";
import TestCaseLogTabel from "../reports/testcase-log-tabel";
import TestcaseChartTableContainer from "./testcase-chart-tabel-container";
import { useAlert } from "../common/alert-service/useAlert";
import DefectTable from "./new_reports/Defect-table";
import DefectsCommonTable from "./new_reports/defect_common_table";
import ReportsRowSpanCommonTable from "./new_reports/reports_rowspan_table";
import SegmentChart from "./new_reports/segmentchart";
import SegmentTable from "./new_reports/segment_table";
import DefectLogTable from "./new_reports/reports_defect_log";
import ModuleSegment from "./new_reports/module-segment";
import FeedbackReports from "./feedback-reports/feedbackReports";
import SegmentContainer from "./new_reports/SegmentContainer";
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { Tooltip } from "@material-ui/core";

function ProjectReports(props) {
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  const [projectDetails, setProjectDetails] = useState({});
  let [dataLoaded, setDataLoaded] = useState(false);
  let [accountmanager, setAccountmanager] = useState("--");
  let [myRole, setMyrole] = useState();
  let [customer, setCustomer] = useState("--");
  const [showTotalDefects, setShowTotalDefects] = useState(true);
  const [detailsSection, setDetailsSection] = useState("");
  const [detailsheaders, setDetailsheaders] = useState([]);
  const [deviceTableHeader, setDeviceTableHeader] = useState([]);
  const [osTableHeader, setOsTableHeader] = useState([]);
  const [browserTableHeader, setBrowserTableHeader] = useState([]);
  const [daysTestedtableHeader, setDaysTestedTableHeader] = useState([]);
  const [testerTableHeader, setTesterTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [countHeader, setCountHeader] = useState("");
  const [listCount, setListCount] = useState(0);
  const tempDetails = JSON.parse(localStorage["selected-project"]).id;
  const [privilageApiResp, setPrivilageApiResp] = useState({});
  const [showChartTotalDefects, setshowChartTotalDefects] = useState("");
  const [filterData, setFilterData] = useState([]);
  let [projectList, setProjectList] = useState([]);
  const [reportsData, setReportsData] = useState({});
  const [allowRejectedDefect, setAllowRejectedDefect] = useState(false);
  const [rejectedCheckox, setRejectedCheckox] = useState(
    JSON.parse(localStorage["selected-project"])?.allowRejectedDefects === true
      ? true
      : false
  );
  const [showRejectedDefects, setShowRejectedDefects] = useState(
    JSON.parse(localStorage["selected-project"])?.allowRejectedDefects === true
      ? true
      : false
  );
  const selectedProject = JSON.parse(localStorage["selected-project"]);
  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;

  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  let myrole = JSON.parse(localStorage["user-privilege"]).role;
  let projecttype =
    projectDetails?.project?.projectType?.web !== null
      ? "Web"
      : projectDetails?.project?.projectType?.mobile !== null
      ? "Mobile"
      : projectDetails?.project?.projectType?.webAndMobile !== null
      ? "Web and Mobile"
      : projectDetails?.project?.projectType?.webservice !== null
      ? "Web Service"
      : projectDetails?.project?.projectType?.others !== null
      ? "Others"
      : "Salesforce";
  let userRole = JSON.parse(localStorage.getItem("user-privilege")).role;
  let ProjectStatus = JSON.parse(
    localStorage["selected-project"]
  ).projectStatus;
  let executiontype = JSON.parse(localStorage["selected-project"])
    .executionDetails.guideType;
  let ID = JSON.parse(localStorage["selected-project"]).id;
  let typeOfProject =
    projectDetails?.project?.projectType?.others?.typeOfProject;

  let [displaySeverityapproverdefect, setSeverityDisplayapproverdefect] =
    useState(true);
  let [displaypriorityapproverdefect, setPriorityDisplayapproverdefect] =
    useState(true);
  let [displaymoduleapproverdefect, setModuleDisplayapproverdefect] =
    useState(true);
  let [displaylocationapproverdefect, setLocationDisplayapproverdefect] =
    useState(true);

  let [feedbackTemplate, setFeedbackTemplate] = useState();
  let [feedbackReportData, setFeedbackReportData] = useState();
  let [selectedTab, setSelctedTab] = useState("Defects analytics");

  let [dataloading, setDataloding] = useState();
  let [downloadingFile, setDownloadingFile] = useState();

  let [pageNo, setpageNo] = useState(0);
  let [lastPage, setlastpage] = useState();


  //NEW code
  let [CardsCount, setCardsCount] = useState();

  const [checked, setChecked] = useState(
    JSON.parse(localStorage["selected-project"])?.allowRejectedDefects === true
      ? true
      : false
  );
  const [show, setShow] = useState(checked);
  let [feedbackAnswers, setFeedbackAnswers] = useState([]);

  let [showtexteditor,setShowTextEditor]=useState(false);
  let [expantextEditor,setExpandTextEditor]=useState(false)

  const handleChange = (event) => {
    console.log("handleChange", event.target.checked);
    setShow(event.target.checked);
    setAllowRejectedDefect(true);
    // setChecked(event.target.checked);
  };

  const { AlertContatiner, MyAlert } = useAlert();

  useEffect(() => {
    if (
      ProjectStatus === "INPROGRESS" ||
      ProjectStatus === "CLOSED" ||
      ProjectStatus === "COMPLETED"
    ) {
      const projectId = JSON.parse(localStorage["selected-project"]).id;
      ProjectDetailsAPI(projectId);
    }
    // const projectId = JSON.parse(localStorage["selected-project"]).id;
    // ProjectDetailsAPI(projectId);
  }, [tempDetails]);
  useEffect(() => {
    localStorage.setItem("currentReportTab", JSON.stringify(""));
    if (
      ProjectStatus === "INPROGRESS" ||
      ProjectStatus === "CLOSED" ||
      ProjectStatus === "COMPLETED"
    ) {
      ReportsDataAPI();
    }
  }, []);
  useEffect(() => {
    if (
      ProjectStatus === "INPROGRESS" ||
      ProjectStatus === "CLOSED" ||
      ProjectStatus === "COMPLETED"
    ) {
      getfeedbackTemplate();
    }
  }, []);

  function getAnswerForQuestion(question) {
    const projectID = JSON.parse(localStorage["selected-project"]).id;
    getAnswerForParticularQuestionReq(projectID, question, 0, 3)
      .then((response) => {
        console.log("getAnswerforQuestions", response);
        if (response?.status === 200 && response?.data?.status === 200) {
          let data = {
            question: question,
            answer: response?.data?.details,
          };
          feedbackAnswers.push(data);
        } else {
          let data = {
            question: question,
            answer: null,
          };
          feedbackAnswers.push(data);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }
  const getfeedbackTemplate = () => {
    getFeedbackTemplateByprojectID(ID)
      .then((result) => {
        console.log("result", result);
        if (result?.data?.status === 200 && result?.data?.template) {
          setFeedbackTemplate(result?.data?.template);
          getFeedbackReports();
          getFeedbackAnswers(result?.data?.template?.templateDetails);
        }
      })
      .catch((error) => {});
  };

  function getFeedbackAnswers(template) {
    console.log("getFeedbackAnswers", template);
    template.map((item) => {
      if (item.type === "text") {
        getAnswerForQuestion(item?.label);
      }
    });
  }
  const getFeedbackReports = () => {
    getFeedbackReportsReq(ID)
      .then((result) => {
        console.log("getFeedbackReportsReq", result);
        if (result?.status === 200 && result?.data) {
          setFeedbackReportData(result?.data);
        }
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  useEffect(() => {
    setPrivilageApiResp(
      localStorage.hasOwnProperty("user-privilege")
        ? JSON.parse(localStorage["user-privilege"])
        : {}
    );
  }, [localStorage["user-privilege"]]);

  useEffect(() => {
    if (detailsSection !== "") {
      console.log("allowRejectedDefect", allowRejectedDefect);
      console.log("showRejectedDefects", showRejectedDefects);
      getAllData(detailsSection);
    }
  }, [showRejectedDefects]);

  function closeWarningModal(status) {
    setAllowRejectedDefect(status);
    setRejectedCheckox(!rejectedCheckox);
    // setChecked(!show)
  }

  const DefectExecutive = async () => {
    let project = {
      id: JSON.parse(localStorage["selected-project"]).id,
      //   allowRejectedDefects: rejectedCheckox,
      allowRejectedDefects: show,
    };
    const formData = new FormData();
    formData.append(
      "createdBy",
      JSON.parse(localStorage.getItem("fire-crowd-user")).userName
    );
    formData.append("project", JSON.stringify(project));
    setAllowRejectedDefect(false);
    setChecked(show);
    if (JSON.parse(localStorage["user-privilege"]).role !== "ACCOUNT_MANAGER") {
      props.MyAlert.info("Modifying user is not ACCOUNT_MANAGER");
    } else {
      try {
        let response = await UpdateDefectExecutiveReport(formData, "CUSTOMER");
        if ((response.data.status = 200)) {
          console.log("UpdateDefectExecutiveRepor", response.data);
          localStorage.setItem(
            "selected-project",
            JSON.stringify(response.data.projectDTO)
          );

          //   setShowRejectedDefects(rejectedCheckox);
          setShowRejectedDefects(show);
          setpageNo(0);
          setlastpage();
        }
      } catch (error) {
        console.log("error");
      }
    }
  };

  const ProjectDetailsAPI = async (e) => {
    let role = "";
    try {
      const response = await getProjectDetails(e);

      if (response.status === 200) {
        setDataLoaded(true);
        setProjectDetails(response.data);
        localStorage.setItem("selected-project", JSON.stringify(response.data));
        response?.data?.project?.users?.userList?.map((user) => {
          if (user.role === "ACCOUNT_MANAGER") {
            setAccountmanager(user.name);
          }
          if (user.role === "PROJECT_OWNER") {
            setCustomer(user.name);
          }
          if (
            user.email === JSON.parse(localStorage["fire-crowd-user"]).userName
          ) {
            role = user.role;
          }
        });
        // if (
        //   localStorage.hasOwnProperty("my-role") &&
        //   JSON.parse(localStorage["my-role"]) === "admin"
        // ) {
        //   setMyrole("ADMIN");
        // } else {
        //   setMyrole(role);
        // }
        if (localStorage.hasOwnProperty("selected-user")) {
          let role = JSON.parse(localStorage["selected-user"]).role;
          setMyrole(role);
        }
      }
    } catch (e) {
      console.log("error");
    }
  };
  const ReportsDataAPI = async () => {
    let state = showRejectedDefects ? "REJECTED" : "CLOSED";
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    try {
      const response = await getAllReportsData(projectId, licenseID, state);
      console.log("ReportsDataAPI", response);
      if (response.status === 200) {
        console.log(" setReportsDat", response.data.data);
        reportsMainPageDetails();
        setCardsCount(response?.data?.data);
        resetProgress();
      }
    } catch (e) {
      console.log("error");
      resetProgress();
    }
  };
  const reportsMainPageDetails = () => {
    let daystestedHeader = [
      {
        Header: "DATE OF TESTING",
        accessor: "dateOfTesting",
        width: "15%",
      },
      {
        Header: "COUNT OF BUGS",
        accessor: "bugCount",
        width: "25%",
      },
      {
        Header: "APPROVED DEFECTS",
        accessor: "approvedDefects",
        width: "15%",
      },
      {
        Header: "REJECTED DEFECTS",
        accessor: "rejectedDefects",
        width: "15%",
      },
    ];
    let testersHeader = [
      {
        Header: "SERIAL NO.",
        accessor: "id",
        width: "10%",
      },
      {
        Header: "PARTICIPANTS NAME",
        accessor: "name",
        width: "30%",
        Cell: (e) => (
          <div>
            {userRole !== "PROJECT_OWNER" ? (
              <div>{e.value}</div>
            ) : (
              <div>{e?.value?.slice(0, e?.value?.indexOf("("))}</div>
            )}
          </div>
        ),
      },
      {
        Header: "TOTAL NO. OF DEFECTS",
        accessor: "totalDefects",
        width: "15%",
      },
      {
        Header: "APPROVED DEFECTS",
        accessor: "approvedDefects",
        width: "15%",
      },
      {
        Header: "REJECTED DEFECTS",
        accessor: "rejectedDefects",
        width: "15%",
      },
    ];
    let deviceHeader = [
      {
        Header: "DEVICES",
        accessor: "brand",
        enableRowSpan: true,
      },
      {
        Header: "MODEL - VERSION",
        accessor: "model",
      },
      {
        Header: "TOTAL DEVICE COUNT",
        accessor: "totalCount",
      },
      {
        Header: "APPROVED DEFECTS",
        accessor: "approvedDefects",
      },
      {
        Header: "REJECTED DEFECTS",
        accessor: "rejectedDefects",
      },
    ];
    let osHeader = [
      {
        Header: "OPERATING SYSTEM",
        accessor: "os",
        enableRowSpan: true,
      },
      {
        Header: "VERSIONS",
        accessor: "version",
      },
      {
        Header: "TOTAL OS COUNT",
        accessor: "totalCount",
      },
      {
        Header: "APPROVED DEFECTS",
        accessor: "approvedDefects",
      },
      {
        Header: "REJECTED DEFECTS",
        accessor: "rejectedDefects",
      },
    ];
    let browserHeader = [
      {
        Header: "BROWSERS",
        accessor: "browser",
        enableRowSpan: true,
      },
      {
        Header: "VERSIONS",
        accessor: "version",
      },
      {
        Header: "TOTAL BROWSER COUNT",
        accessor: "totalCount",
      },
      {
        Header: "APPROVED DEFECTS",
        accessor: "approvedDefects",
      },
      {
        Header: "REJECTED DEFECTS",
        accessor: "rejectedDefects",
      },
    ];

    if (showRejectedDefects) {
      setDeviceTableHeader([...deviceHeader]);
      setDaysTestedTableHeader([...daystestedHeader]);
      setTesterTableHeader([...testersHeader]);
      setOsTableHeader([...osHeader]);
      setBrowserTableHeader([...browserHeader]);
    } else {
      if (deviceHeader[deviceHeader.length - 1].Header === "REJECTED DEFECTS") {
        deviceHeader.splice(deviceHeader.length - 1, 1);
      }
      if (
        daystestedHeader[daystestedHeader.length - 1].Header ===
        "REJECTED DEFECTS"
      ) {
        daystestedHeader.splice(daystestedHeader.length - 1, 1);
      }
      if (
        testersHeader[testersHeader.length - 1].Header === "REJECTED DEFECTS"
      ) {
        testersHeader.splice(testersHeader.length - 1, 1);
      }
      if (osHeader[osHeader.length - 1].Header === "REJECTED DEFECTS") {
        osHeader.splice(osHeader.length - 1, 1);
      }
      if (
        browserHeader[browserHeader.length - 1].Header === "REJECTED DEFECTS"
      ) {
        browserHeader.splice(browserHeader.length - 1, 1);
      }
      setDeviceTableHeader([...deviceHeader]);
      setDaysTestedTableHeader([...daystestedHeader]);
      setTesterTableHeader([...testersHeader]);
      setOsTableHeader([...osHeader]);
      setBrowserTableHeader([...browserHeader]);
    }
  };
  const getAllData = async (val) => {
    setDataloding(true);
    let state = showRejectedDefects ? "REJECTED" : "CLOSED";
    console.log("allowRejectedDefect", state);
    let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])
      .project.fireFlinkProjectId;
    let projectID = JSON.parse(localStorage["selected-project"]).id;
    if (val === "browser") {
      try {
        let response = await getAllBrowserData(
          licenseID,
          fireFlinkProjectId,
          projectID,
          state
        );
        console.log("getALlBrowsers", response);
        if (response.status === 200) {
          let tempBrowserReport = response?.data?.data;
          let browserReport = [];
          tempBrowserReport.map((uniqBrowser, index) => {
            let obj = {
              browser: uniqBrowser?.browserName,
              browsertotalCount: uniqBrowser?.browserCount,
              browserlist: [],
            };
            let browserIndex = 0;
            for (let key in uniqBrowser?.browserVersionAndCount) {
              let eachObj = {
                ...uniqBrowser?.browserDefectReport[browserIndex],
              };
              // eachObj.version = key;
              eachObj.totalCount =
                uniqBrowser?.browserVersionAndCount[
                  uniqBrowser?.browserDefectReport[browserIndex].version
                ];
              obj.browserlist.push(eachObj);
              browserIndex += 1;
            }
            browserReport.push(obj);
          });
          setTableData([...browserReport]);
        }
      } catch (error) {
        console.log("error");
      }
    } else if (val === "devices") {
      try {
        let response = await getAllDeviceData(
          licenseID,
          fireFlinkProjectId,
          projectID,
          state
        );
        if (response?.status === 200) {
          let tempDeviceReport = response?.data?.data;
          let deviceReport = [];
          tempDeviceReport.map((uniqDevice, index) => {
            let obj = {
              brand: uniqDevice?.deviceBrand,
              brandtotalCount: uniqDevice?.deviceBrandCount,
              devicelist: [],
            };
            let deviceIndex = 0;
            for (let key in uniqDevice?.deviceNameAndCount) {
              let eachObj = { ...uniqDevice?.deviceDefectReport[deviceIndex] };
              eachObj.model =
                uniqDevice?.deviceDefectReport[deviceIndex].version;
              eachObj.totalCount =
                uniqDevice?.deviceNameAndCount[
                  uniqDevice?.deviceDefectReport[deviceIndex].version
                ];
              obj.devicelist.push(eachObj);
              deviceIndex += 1;
            }
            deviceReport.push(obj);
          });
          setTableData([...deviceReport]);
        }
      } catch (error) {
        console.log("error");
      }
    } else if (val === "days") {
      try {
        let response = await getDailyData(
          licenseID,
          fireFlinkProjectId,
          projectID,
          state
        );
        if (response.status === 200 && response?.data?.statusCode === 200) {
          setTableData([...response?.data?.data]);
        }
      } catch (error) {
        console.log("error");
      }
    } else if (val === "testers") {
      try {
        let response = await getAlltrainerData(
          licenseID,
          fireFlinkProjectId,
          projectID,
          state,
          pageNo,
          10
        );
        console.log("getALl data", response);
        if (response.status === 200 && response?.data?.statusCode === 200) {
          if (pageNo === 0) {
            setlastpage(response?.data?.lastaPage);
            setpageNo(pageNo + 1);
            let temparray = [];
            response?.data?.data?.map((item, index) => {
              let data = {
                id: index + 1,
                name: item?.name,
                totalDefects: item?.totalDefects,
                approvedDefects: item?.approvedDefects,
                rejectedDefects: item?.rejectedDefects,
              };
              temparray.push(data);
            });
            setTableData([...temparray]);
          } else {
            let temparray = [];
            let testerdata = tableData.concat(response?.data?.data);
            testerdata.map((item, index) => {
              let data = {
                id: index + 1,
                name: item?.name,
                totalDefects: item?.totalDefects,
                approvedDefects: item?.approvedDefects,
                rejectedDefects: item?.rejectedDefects,
              };
              temparray.push(data);
            });
            setlastpage(response?.data?.lastPage);
            setTableData([...temparray]);
            setpageNo(pageNo + 1);
          }
          console.log("response", response);
        } else {
          setlastpage();
          setpageNo(0);
          setTableData([]);
        }
      } catch (error) {
        console.log("error");
      }
    } else {
      try {
        let response = await getAllOSData(
          licenseID,
          fireFlinkProjectId,
          projectID,
          state
        );
        if (response.status === 200) {
          let tempOsReport = response?.data?.data;
          let osReport = [];
          tempOsReport.map((uniqOs, index) => {
            let obj = {
              os: uniqOs?.osName,
              ostotalCount: uniqOs?.osCount,
              oslist: [],
            };
            let osIndex = 0;
            for (let key in uniqOs?.osVersionAndCount) {
              let eachObj = { ...uniqOs?.osDefectReport[osIndex] };
              // eachObj.version = key;
              eachObj.totalCount =
                uniqOs?.osVersionAndCount[
                  uniqOs?.osDefectReport[osIndex].version
                ];
              obj.oslist.push(eachObj);
              osIndex += 1;
            }
            osReport.push(obj);
          });
          setTableData([...osReport]);
        }
      } catch (error) {
        console.log("error");
      }
    }
    setDataloding(false);
  };
  async function gettrainerDataByScroll() {
    let state = showRejectedDefects ? "REJECTED" : "CLOSED";

    let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])
      .project.fireFlinkProjectId;
    let projectID = JSON.parse(localStorage["selected-project"]).id;
    if (!lastPage) {
      console.log("allowRejectedDefect", state);
      try {
        let response = await getAlltrainerData(
          licenseID,
          fireFlinkProjectId,
          projectID,
          state,
          pageNo,
          10
        );
        console.log("getALl data", response);
        if (response.status === 200 && response?.data?.statusCode === 200) {
          if (pageNo === 0) {
            setlastpage(response?.data?.lastaPage);
            setpageNo(pageNo + 1);
            let temparray = [];
            response?.data?.data?.map((item, index) => {
              let data = {
                id: index + 1,
                name: item?.name,
                totalDefects: item?.totalDefects,
                approvedDefects: item?.approvedDefects,
                rejectedDefects: item?.rejectedDefects,
              };
              temparray.push(data);
            });
            setTableData([...temparray]);
          } else {
            let temparray = [];
            let testerdata = tableData.concat(response?.data?.data);
            testerdata.map((item, index) => {
              let data = {
                id: index + 1,
                name: item?.name,
                totalDefects: item?.totalDefects,
                approvedDefects: item?.approvedDefects,
                rejectedDefects: item?.rejectedDefects,
              };
              temparray.push(data);
            });
            setlastpage(response?.data?.lastPage);
            setTableData([...temparray]);
            setpageNo(pageNo + 1);
          }
          console.log("response", response);
          // setTableData([...response?.data?.data])
        }
      } catch (error) {
        console.log("error");
      }
    }
  }
  const buildDetailsSection = (type) => {
    setShowTotalDefects(false);
    setListCount();
    setTableData([]);
    setCountHeader("");
    setDetailsSection("");
    setpageNo(0);
    setlastpage(false);

    let headers = [];
    if (type === "days") {
      headers = [
        {
          Header: "DATE OF TESTING",
          accessor: "dateOfTesting",
          width: "15%",
        },
        {
          Header: "COUNT OF BUGS",
          accessor: "bugCount",
          width: "25%",
        },
        {
          Header: "APPROVED DEFECTS",
          accessor: "approvedDefects",
          width: "15%",
        },
        {
          Header: "REJECTED DEFECTS",
          accessor: "rejectedDefects",
          width: "15%",
        },
      ];

      setTableData([]);
      setCountHeader("No. of Days Tested");
      setDetailsSection("days");
      getAllData("days");
    } else if (type === "testers") {
      headers = [
        {
          Header: "SERIAL NO.",
          accessor: "id",
          width: "10%",
        },
        {
          Header: "PARTICIPANTS NAME",
          accessor: "name",
          width: "30%",
          Cell: (e) => (
            <div>
              {/* {userRole !== "PROJECT_OWNER" ? (
                <div>{e.value}</div>
              ) : (
                <div>{e?.value?.slice(0, e?.value?.indexOf("("))}</div>
              )} */}
              {e.value}
            </div>
          ),
        },
        {
          Header: "TOTAL NO. OF DEFECTS",
          accessor: "totalDefects",
          width: "15%",
        },
        {
          Header: "APPROVED DEFECTS",
          accessor: "approvedDefects",
          width: "15%",
        },
        {
          Header: "REJECTED DEFECTS",
          accessor: "rejectedDefects",
          width: "15%",
        },
      ];
      setTableData([]);
      setCountHeader("No. of Test Engineers");
      setDetailsSection("testers");
      getAllData("testers");
    } else if (type === "devices") {
      headers = [
        {
          Header: "DEVICES",
          accessor: "brand",
          enableRowSpan: true,
        },
        {
          Header: "MODEL - VERSION",
          accessor: "model",
        },
        {
          Header: "TOTAL DEVICE COUNT",
          accessor: "totalCount",
        },
        {
          Header: "APPROVED DEFECTS",
          accessor: "approvedDefects",
        },
        {
          Header: "REJECTED DEFECTS",
          accessor: "rejectedDefects",
        },
      ];
      setTableData([]);
      setCountHeader("No. of Devices Tested");
      setDetailsSection("devices");
      getAllData("devices");
    } else if (type === "os") {
      headers = [
        {
          Header: "OPERATING SYSTEM",
          accessor: "os",
          enableRowSpan: true,
        },
        {
          Header: "VERSIONS",
          accessor: "version",
        },
        {
          Header: "TOTAL OS COUNT",
          accessor: "totalCount",
        },
        {
          Header: "APPROVED DEFECTS",
          accessor: "approvedDefects",
        },
        {
          Header: "REJECTED DEFECTS",
          accessor: "rejectedDefects",
        },
      ];

      setTableData([]);
      setCountHeader("No. of OS Tested");
      setDetailsSection("os");
      getAllData("os");
    } else if (type === "browser") {
      headers = [
        {
          Header: "BROWSERS",
          accessor: "browser",
          enableRowSpan: true,
        },
        {
          Header: "VERSIONS",
          accessor: "version",
        },
        {
          Header: "TOTAL BROWSER COUNT",
          accessor: "totalCount",
        },
        {
          Header: "APPROVED DEFECTS",
          accessor: "approvedDefects",
        },
        {
          Header: "REJECTED DEFECTS",
          accessor: "rejectedDefects",
        },
      ];

      setTableData([]);
      setCountHeader("No. of Browser Tested");
      setDetailsSection("browser");
      getAllData("browser");
    } else if (type === "defects") {
      setShowTotalDefects(true);
    }
    if (type && type !== "defects") {
      if (showRejectedDefects) {
        setDetailsheaders([...headers]);
      } else {
        if (headers[headers.length - 1].Header === "REJECTED DEFECTS") {
          headers.splice(headers.length - 1, 1);
        }
        setDetailsheaders([...headers]);
      }
    }
  };

  useEffect(() => {
    if (!showTotalDefects) {
      let headers = [...detailsheaders];
      if (showRejectedDefects) {
        headers.push({
          Header: "REJECTED DEFECTS",
          accessor: "rejectedDefects",
          width: "15%",
        });
        setDetailsheaders([...headers]);
      } else {
        if (headers[headers.length - 1]?.Header === "REJECTED DEFECTS") {
          headers.splice(headers.length - 1, 1);
        }
        setDetailsheaders([...headers]);
      }
      let daystestedHeader = [...daysTestedtableHeader];
      let testersHeader = [...testerTableHeader];
      let deviceHeader = [...deviceTableHeader];
      let osHeader = [...osTableHeader];
      let browserHeader = [...browserTableHeader];
      if (showRejectedDefects) {
        deviceHeader.push({
          Header: "REJECTED DEFECTS",
          accessor: "rejectedDefects",
          width: "15%",
        });
        daystestedHeader.push({
          Header: "REJECTED DEFECTS",
          accessor: "rejectedDefects",
          width: "15%",
        });
        testersHeader.push({
          Header: "REJECTED DEFECTS",
          accessor: "rejectedDefects",
          width: "15%",
        });
        osHeader.push({
          Header: "REJECTED DEFECTS",
          accessor: "rejectedDefects",
          width: "15%",
        });
        browserHeader.push({
          Header: "REJECTED DEFECTS",
          accessor: "rejectedDefects",
          width: "15%",
        });
        setDeviceTableHeader([...deviceHeader]);
        setDaysTestedTableHeader([...daystestedHeader]);
        setTesterTableHeader([...testersHeader]);
        setOsTableHeader([...osHeader]);
        setBrowserTableHeader([...browserHeader]);
      } else {
        if (
          deviceHeader[deviceHeader.length - 1]?.Header === "REJECTED DEFECTS"
        ) {
          deviceHeader.splice(deviceHeader.length - 1, 1);
        }
        if (
          daystestedHeader[daystestedHeader.length - 1]?.Header ===
          "REJECTED DEFECTS"
        ) {
          daystestedHeader?.splice(daystestedHeader.length - 1, 1);
        }
        if (
          testersHeader[testersHeader.length - 1]?.Header === "REJECTED DEFECTS"
        ) {
          testersHeader.splice(testersHeader.length - 1, 1);
        }
        if (osHeader[osHeader.length - 1]?.Header === "REJECTED DEFECTS") {
          osHeader.splice(osHeader.length - 1, 1);
        }
        if (
          browserHeader[browserHeader.length - 1]?.Header === "REJECTED DEFECTS"
        ) {
          browserHeader.splice(browserHeader.length - 1, 1);
        }
        setDeviceTableHeader([...deviceHeader]);
        setDaysTestedTableHeader([...daystestedHeader]);
        setTesterTableHeader([...testersHeader]);
        setOsTableHeader([...osHeader]);
        setBrowserTableHeader([...browserHeader]);
      }
    }
  }, [showRejectedDefects]);
  const closeDetailsTable = (val) => {
    if (showTotalDefects) {
      setShowTotalDefects(false);
    }
    setDetailsSection("");
  };

  function handleSearch(e) {
    let searchValue = e.target.value;
    if (searchValue) {
      let tempSimilarArray = [];
      filterData.map((proVal, index) => {
        let name = proVal?.project?.projectName;
        let duration = proVal.duration?.toString();
        let numberOfTesters = proVal?.numberOfTesters?.toString();
        let startDate = dateFormat(
          proVal.startDate?.slice(0, 10),
          "dd/mm/yyyy"
        );
        let accountManager = proVal?.project?.users.userList.find((user) => {
          if (user.role === "ACCOUNT_MANAGER") {
            return user.name;
          }
        });

        let similarExist = name.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistByDuration = duration.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistByNumberOfTesters = numberOfTesters.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistByStartDate = startDate.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistByManager = accountManager?.name.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );

        if (
          similarExist ||
          similarExistByDuration ||
          similarExistByNumberOfTesters ||
          similarExistByStartDate ||
          similarExistByManager
        ) {
          tempSimilarArray.push(proVal);
          setProjectList(tempSimilarArray);
        } else {
          setProjectList(tempSimilarArray);
        }
      });
    } else {
      setProjectList(filterData);
    }
  }
  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
    setDownloadingFile(false);
  }
  const downloadExcleFile = async (key, name) => {
    let state = showRejectedDefects ? "REJECTED" : "CLOSED";
    let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])
      .project.fireFlinkProjectId;
    let projectID = JSON.parse(localStorage["selected-project"]).id;
    setDownloadingFile(true);
    try {
      let response = await DownLoadExcelFile(
        key,
        fireFlinkProjectId,
        projectID,
        licenseID,
        name,
        state
      );
      console.log("response file", response);
      if (response.status === 200) {
        saveFileFromBlob(response.data, name);
      } else {
        setDownloadingFile(false);
      }
    } catch (err) {
      console.log("error");
    }
  };

  function downloadFile(lable, file) {
    console.log("labels", lable);

    switch (lable) {
      case "days": {
        downloadExcleFile("isNoOfDays", "NoOfDays_Reports.xlsx");
        break;
      }

      case "testers": {
        downloadExcleFile("isParticipants", "Participants_Reports.xlsx");
        break;
      }

      case "devices": {
        downloadExcleFile("isDevice", "Device_Reports.xlsx");
        break;
      }

      case "os": {
        downloadExcleFile("isOS", "Os_Reports.xlsx");
        break;
      }

      case "browser": {
        downloadExcleFile("isBrowsers", "Browsers_Reports.xlsx");
        break;
      }

      default:
        console.log("Error");
        break;
    }
  }
  return (
    <div>
      <AlertContatiner />

      <div className="reports_section">
        {myrole === "ACCOUNT_MANAGER" && (
          <div
            className={`reports_toggle_btn ${
              selectedTab === "Defects analytics" &&
              (ProjectStatus === "INPROGRESS" ||
                ProjectStatus === "CLOSED" ||
                ProjectStatus === "COMPLETED")
                ? "block"
                : "hidden"
            }`}
          >
            <>
              <span className="text">Hide</span>
              <Switch size="small" checked={checked} onChange={handleChange} />
              <span className="text">Show</span>
            </>
          </div>
        )}

        <Tabs className="Tabs">
          <TabList className="flex cursor-pointer tab_section mb-4">
            <Tab
              className="tab-label mr-10"
              onClick={() => {
                setSelctedTab("Defects analytics");
                localStorage.setItem("currentReportTab", JSON.stringify(""));
              }}
            >
              Defect analytics
            </Tab>
            <Tab
              className="tab-label mr-10"
              onClick={() => {
                setSelctedTab("Feedback analytics");
                localStorage.setItem("currentReportTab", JSON.stringify(""));
              }}
            >
              Feedback
            </Tab>
            {executiontype === "GUIDED" && (
              <Tab
                className="tab-label"
                onClick={() => {
                  setSelctedTab("Execution analytics");
                  localStorage.setItem("currentReportTab", JSON.stringify(""));
                }}
              >
                Execution analytics
              </Tab>
            )}
          </TabList>
          <TabPanel>
            {ProjectStatus !== "INPROGRESS" && ProjectStatus !== "CLOSED" && ProjectStatus !== "COMPLETED" ? (
              <>
                <div
                  className="flex justify-center"
                  style={{ marginTop: "10%" }}
                >
                  <Noreports />
                </div>
                <div className="no_defect_reports_msg">No Reports found!</div>
              </>
            ) : (
              <div
                className="mainContainer_report"
                style={{
                  height: "78vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div
                  className={
                    projecttype === "Web and Mobile"
                      ? "grid grid-cols-12"
                      : projecttype === "Others" &&
                        typeOfProject === "Standalone_Application"
                      ? "grid grid-cols-8"
                      : "grid grid-cols-10"
                  }
                >
                  <div
                    className="col-span-2 grid grid-cols-12"
                    onClick={() => {
                      buildDetailsSection("defects");
                    }}
                  >
                    <div
                      className={`reports_card col-span-11 grid grid-cols-12 cursor-pointer ${
                        showTotalDefects
                          ? "defect_selected"
                          : "defect_unselected"
                      }`}
                    >
                      <div className="col-span-12 grid grid-cols-12 flex">
                        <div className="col-span-4 mt-2">
                          {showTotalDefects ? (
                            <DefectWhiteIcon className="cards_icon" />
                          ) : (
                            <DefectIcon className="cards_icon" />
                          )}
                        </div>
                        <div className="col-span-8 mt-2">
                          <div className="cards_count">
                            {showRejectedDefects
                              ? CardsCount?.totalDefectsRaised
                                ? CardsCount?.totalDefectsRaised
                                : 0
                              : CardsCount?.approvedDefects
                              ? CardsCount?.approvedDefects
                              : 0}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="cards_label col-span-12">
                          No. of Approved Defects
                        </div>
                      </div>
                    </div>
                  </div>
                  {(projecttype === "Mobile" ||
                    projecttype === "Web and Mobile" ||
                    (projecttype === "Others" &&
                      typeOfProject !== "Standalone_Application")) && (
                    <div
                      className="col-span-2 grid grid-cols-12"
                      onClick={() => {
                        if (detailsSection !== "devices") {
                          buildDetailsSection("devices");
                        }
                      }}
                    >
                      <div
                        className={`reports_card col-span-11 grid grid-cols-12 cursor-pointer ${
                          detailsSection === "devices"
                            ? "device_selected"
                            : "device_unselected"
                        }`}
                      >
                        <div className="col-span-12 grid grid-cols-12 flex">
                          <div className="col-span-4 mt-2">
                            {detailsSection === "devices" ? (
                              <DeviceWhiteIcon className="cards_icon" />
                            ) : (
                              <DeviceIcon className="cards_icon" />
                            )}
                          </div>
                          <div className="col-span-8 mt-2">
                            <div className="cards_count">
                              {CardsCount?.totalDeviceTested
                                ? CardsCount?.totalDeviceTested
                                : 0}
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 grid grid-cols-12">
                          <div className="cards_label col-span-12">
                            No. of Devices Tested
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className="col-span-2 grid grid-cols-12"
                    onClick={() => {
                      if (detailsSection !== "os") {
                        buildDetailsSection("os");
                      }
                    }}
                  >
                    <div
                      className={`reports_card col-span-11 grid grid-cols-12 cursor-pointer ${
                        detailsSection === "os"
                          ? "OS_selected"
                          : "OS_unselected"
                      }`}
                    >
                      <div className="col-span-12 grid grid-cols-12 flex">
                        <div className="col-span-4 mt-2">
                          {detailsSection === "os" ? (
                            <OSWHITEICONDEFECTS className="cards_icon" />
                          ) : (
                            <OSICONDEFECTS className="cards_icon" />
                          )}
                        </div>
                        <div className="col-span-8 mt-2">
                          <div className="cards_count">
                            {CardsCount?.totalOsTested
                              ? CardsCount?.totalOsTested
                              : 0}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="cards_label col-span-12">
                          No. of OS Tested
                        </div>
                      </div>
                    </div>
                  </div>

                  {(projecttype === "Web" ||
                    projecttype === "Web and Mobile") && (
                    <div
                      className="col-span-2 grid grid-cols-12"
                      onClick={() => {
                        if (detailsSection !== "browser") {
                          buildDetailsSection("browser");
                        }
                      }}
                    >
                      <div
                        className={`reports_card col-span-11 grid grid-cols-12 cursor-pointer ${
                          detailsSection === "browser"
                            ? "browser_selected"
                            : "browser_unselected"
                        }`}
                      >
                        <div className="col-span-12 grid grid-cols-12 flex">
                          <div className="col-span-4 mt-2">
                            {detailsSection === "browser" ? (
                              <BrowserWhiteIcon className="cards_icon" />
                            ) : (
                              <BrowserIcon className="cards_icon" />
                            )}
                          </div>
                          <div className="col-span-8 mt-2">
                            <div className="cards_count">
                              {CardsCount?.totalBrowserTested
                                ? CardsCount?.totalBrowserTested
                                : 0}
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 grid grid-cols-12">
                          <div className="cards_label col-span-12">
                            No. of Browsers Tested
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className="col-span-2 grid grid-cols-12"
                    onClick={() => {
                      if (detailsSection !== "days") {
                        buildDetailsSection("days");
                      }
                    }}
                  >
                    <div
                      className={`reports_card col-span-11 grid grid-cols-12 cursor-pointer ${
                        detailsSection === "days"
                          ? "days_selected"
                          : "days_unselected"
                      }`}
                    >
                      <div className="col-span-12 grid grid-cols-12 flex">
                        <div className="col-span-4 mt-2">
                          {detailsSection === "days" ? (
                            <DaysWhiteIcon className="cards_icon" />
                          ) : (
                            <DaysIcon className="cards_icon" />
                          )}
                        </div>
                        <div className="col-span-8 mt-2">
                          <div className="cards_count">
                            {CardsCount?.totalDaysTested
                              ? CardsCount?.totalDaysTested
                              : 0}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="cards_label col-span-12">
                          No. of Days Tested
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-span-2 grid grid-cols-12"
                    onClick={() => {
                      if (detailsSection !== "testers") {
                        buildDetailsSection("testers");
                      }
                    }}
                  >
                    <div
                      className={`reports_card col-span-11 grid grid-cols-12 cursor-pointer ${
                        detailsSection === "testers"
                          ? "tester_selected"
                          : "tester_unselected"
                      }`}
                    >
                      <div className="col-span-12 grid grid-cols-12 flex">
                        <div className="col-span-4 mt-2">
                          {detailsSection === "testers" ? (
                            <ParticipentWhiteIcon className="cards_icon" />
                          ) : (
                            <ParticipentIcon className="cards_icon" />
                          )}
                        </div>
                        <div className="col-span-8 mt-2">
                          <div className="cards_count">
                            {CardsCount?.totalTesterParticipated
                              ? CardsCount?.totalTesterParticipated
                              : 0}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="cards_label col-span-12">
                          No. of Test Engineers
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  {showTotalDefects ? (
                    <DefectTable allowRejectedDefect={showRejectedDefects} />
                  ) : detailsSection === "days" ||
                    detailsSection === "testers" ? (
                    <DefectsCommonTable
                      detailsheaders={detailsheaders}
                      countHeader={countHeader}
                      detailsSection={detailsSection}
                      tableData={tableData}
                      closeDetailsTable={closeDetailsTable}
                      listCount={listCount}
                      allowRejectedDefect={showRejectedDefects}
                      downloadFile={downloadFile}
                      dataloading={dataloading}
                      downloadingFile={downloadingFile}
                      gettrainerDataByScroll={gettrainerDataByScroll}
                    />
                  ) : detailsSection === "devices" ||
                    detailsSection === "os" ||
                    detailsSection === "browser" ? (
                    <ReportsRowSpanCommonTable
                      detailsSection={detailsSection}
                      detailsheaders={detailsheaders}
                      tableData={tableData}
                      listCount={listCount}
                      countHeader={countHeader}
                      closeDetailsTable={closeDetailsTable}
                      downloadFile={downloadFile}
                      allowRejectedDefect={showRejectedDefects}
                      dataloading={dataloading}
                      downloadingFile={downloadingFile}
                    />
                  ) : null}
                </div>
                <div>
                  <SegmentContainer allowRejectedDefect={showRejectedDefects} />
                </div>

                <div>
                  <DefectLogTable allowRejectedDefect={showRejectedDefects} />
                </div>

                <div className="" style={{ marginRight: "0.8%" }}>
                  <ClientReport
                    reportsData={reportsData}
                    allowRejectedDefect={showRejectedDefects}
                  />
                </div>

                {projectDetails.defectExecutiveReport && (
                  <div className="executive-report mb-6 pr-4">
                    <div className={expantextEditor?"expand_executive-report-content p-3 relative":"executive-report-content p-3 relative"}>
                      <div className="heading executivereports_header-style mb-2 ml-1.5 flex">
                        <div className="header_text">Executive Report</div>
                        {
                         expantextEditor? <Tooltip title="Collapse"><div onClick={()=>{setExpandTextEditor(false)}} className="expand_icon "><UnfoldLessIcon/></div></Tooltip>:""
                        }
                      </div>
                      <div onMouseEnter={()=>{setShowTextEditor(true)}} onMouseLeave={()=>{setShowTextEditor(false)}}>
                      <RichTextEditor
                        convertedContent={projectDetails?.defectExecutiveReport}
                        readOnly={true}
                        toolbarHidden={true}
                      />
                      </div>
                     
                      {
                        expantextEditor?(""):(<div id="myModal" class="modal" style={{display:showtexteditor?"block":"none"}} onMouseEnter={()=>{setShowTextEditor(true)}} onMouseLeave={()=>{setShowTextEditor(false)}}>
                        <div class="modal-content" onClick={()=>{setExpandTextEditor(true)}}>
                          <span class="close"></span>
                          <p> + Click here to see more...</p>
                        </div>
                      </div>)
                      }
                      

                    </div>
                  </div>
                )}
              </div>
            )}
          </TabPanel>
          <TabPanel>
            <div className="feedback_report_container">
              {ProjectStatus === "INPROGRESS" ||
              ProjectStatus === "CLOSED" ||
              ProjectStatus === "COMPLETED" ? (
                <FeedbackReports
                  feedbackTemplate={feedbackTemplate}
                  feedbackReportData={feedbackReportData}
                  feedbackAnswers={feedbackAnswers}
                />
              ) : (
                <>
                  <div
                    className="flex justify-center"
                    style={{ marginTop: "10%" }}
                  >
                    <Noreports />
                  </div>
                  <div className="no_defect_reports_msg">No Reports found!</div>
                </>
              )}
            </div>
          </TabPanel>
          <TabPanel>
            {executiontype === "GUIDED" && (
              <div
                className="mainContainer_report"
                style={{
                  height: "79vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div className="testcasesreport mr-2">
                  <div>
                    <TestcaseChartTableContainer
                      allowRejectedDefect={showRejectedDefects}
                      data={reportsData}
                    />
                    {/* <ExecutionReports/> */}
                  </div>
                  <div className="mb-12">
                    <TestCaseLogTabel
                      data={reportsData}
                      allowRejectedDefect={showRejectedDefects}
                    />
                  </div>
                </div>
              </div>
            )}
          </TabPanel>
        </Tabs>

        {allowRejectedDefect && (
          <DefectWarningPopup
            closeWarningModal={closeWarningModal}
            DefectExecutive={DefectExecutive}
          />
        )}
      </div>
    </div>
  );
}

export default ProjectReports;
