import React from 'react'
import { Skeleton } from "@mui/material";
import "./project.scss";
const ProjectListLoader = () => {
  return (
    <>
        <div className="col-span-11 flex">
                <div className="title">PROJECT NAME : </div>
                 <Skeleton width="10%"  className='ml-2.5'  />
              </div>
<div className="grid grid-cols-12 mt-4">
  <div className="col-span-12">
    <div className="title">DESCRIPTION</div>
    <Skeleton width="8%" className='ml-2.5'/>
  </div>
</div>
<div className="grid grid-cols-10 flex  mt-4">
  <div className="col-span-2">
    <div className="title">EVENT START DATE</div>
    <Skeleton width="25%"  className='ml-2.5'/>
  </div>
  <div className="col-span-2">
    <div className="title">EVENT DURATION</div>
    <Skeleton width="45%"  className='ml-2.5' />
  </div>
  <div className="col-span-2">
    <div className="title">NUMBER OF TESTERS</div>
    <Skeleton width="45%"  className='ml-2.5'/>
  </div>
  <div className="col-span-2">
    <div className="title">STATUS</div>
    <Skeleton width="18%"  className='ml-2.5'/>
  </div>
  <div className="col-span-2">
    <div className="title">ACCOUNT MANAGER</div>
    <Skeleton width="55%"  className='ml-2.5'/>
  </div>
</div>

    </>
  )
}

export default ProjectListLoader