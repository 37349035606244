import React, { useState } from "react";
import Alerts from "./alert";
const initial = { autoClose: true, duration: 5000 }



export const useAlert = (a = initial) => {
    const [alert, setAlert] = useState({ open: false, message: '', type: '' });
    const closeAlert = () => setTimeout(() => setAlert(() => ({ open: false, message: '', type: '' })), a.duration)

    const clickClose = () => setAlert({ open: false, message: '', type: '' })

    function success(message) {
        setAlert(() => ({ open: true, message: message, type: 'success' }))
        a.autoClose && closeAlert()
    }
    function warning(message) {
        setAlert(() => ({ open: true, message: message, type: 'warning' }))
        a.autoClose && closeAlert()
    }
    function danger(message) {
        setAlert(() => ({ open: true, message: message, type: 'danger' }))
        a.autoClose && closeAlert()
    }
    function alertbanner(message) {
        setAlert(() => ({ open: true, message: message, type: 'alertbanner' }))
        a.autoClose && closeAlert()
    }
    function info(message) {
        setAlert(() => ({ open: true, message: message, type: 'info' }))
        a.autoClose && closeAlert()
    }
    function close(){
        setAlert({ open: false, message: '', type: '' })
    }
    const MyAlert = { success, danger, warning, info, alertbanner,close }


    const AlertContatiner = () => <Alerts ClassName="z-40" open={alert.open} message={alert.message} type={alert.type} close={clickClose} />


    return { AlertContatiner, MyAlert }

}
// HOC to add alertContainer
//@Param{Componet}
export const withAlertContainer = (Component) => {

    return (props) => {

        const { MyAlert, AlertContatiner } = useAlert();
        return <Component MyAlert={MyAlert} AlertContatiner={AlertContatiner} {...props} />
    }
}