import React, { useState, useEffect } from "react";
import "./client-list.scss";
import opera from "../../assets/platform/opera.svg";
import { ReactComponent as AndroidBrowser } from "../../assets/platform/android-browser.svg";
import firefoxIcon from "../../assets/platform/firefox.svg";
import microsoftEdge from "../../assets/platform/microsoft edge.svg";
import chromeIcon from "../../assets/platform/chrome.svg";
import { ReactComponent as Linux } from "../../assets/platform/linux.svg";
import Carousel, { consts } from "react-elastic-carousel";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import safariIcon from "../../assets/platform/safariNewicon.png";
import { ReactComponent as IEicon } from "../../assets/platform/IE-icon.svg";
import { ReactComponent as Windows } from "../../assets/platform/windows.svg";
import { Tooltip } from "@mui/material";
import { ReactComponent as Ubantu } from "../../assets/ubantu-icon.svg";
import { ReactComponent as MacIcon } from "../../assets/mac-icon.svg";
import { ReactComponent as LeftArrow } from "../../assets/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../assets/right-arrow.svg";
import samsungBrowser from "../../assets/platform/samsung-browser.svg";
import vivoBrowser from "../../assets/platform/vivo-browser.svg";
import huawei from "../../assets/platform/huawei-browser.svg";
import miui from "../../assets/platform/haytap-browser.svg"
const AllBrowsersContent = (props) => {
  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <div className={isEdge ? "invisible " : "visible customizedLeftArrow"}>
          <LeftArrow height="22px" width="22px" />
        </div>
      ) : (
        <div
          className={
            isEdge
              ? "invisible "
              : "visible customizedRightArrow relative -left-6"
          }
        >
          {" "}
          <RightArrow height="22px" width="22px" />
        </div>
      );
    return (
      <button onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  }
  function selectedOs(ostype) {
    switch (ostype) {
      case "windows":
        return <Windows height="21px" width="21px" className="mt-1.5 pr-px" />;

      case "mac":
        return <MacIcon height="34px" width="38px" />;

      case "linux":
        return <Linux height="16px" width="16px" />;

      case "ubantu":
        return <Ubantu height="16px" width="16px" />;

      default:
        return "";
    }
  }
  function selectedOsName(ostype) {
    switch (ostype) {
      case "windows":
        return "Windows";

      case "mac":
        return "Mac";

      case "linux":
        return "Linux";

      case "ubantu":
        return "ubantu";

      default:
        return<div className="datatrimmer w-18">{ostype}</div> ;
    }
  }

  function selectedBrowser(browsertype) {
    let customizedBrowserType = browsertype?.toLowerCase();
    console.log("customizedBrowserType",customizedBrowserType)
    switch (customizedBrowserType) {
      case "opera":
        return (
          <img src={opera} style={{ height: "30px", marginLeft: "-2px" }} />
        );

      case "android":
        return <AndroidBrowser />;

      case "linux":
        return <Linux />;

      case "firefox":
        return (
          <img
            src={firefoxIcon}
            style={{ height: "30px", marginLeft: "-2px" }}
          />
        );

      case "microsoft edge":
        return (
          <img
            src={microsoftEdge}
            style={{ height: "30px", marginLeft: "-2px" }}
          />
        );

      case "microsoft":
        return (
          <img
            src={microsoftEdge}
            style={{ height: "30px", marginLeft: "-2px" }}
          />
        );
      case "edge":
        return (
          <img
            src={microsoftEdge}
            style={{ height: "30px", marginLeft: "-2px" }}
          />
        );
      case "chrome":
        return (
          <img
            src={chromeIcon}
            style={{ height: "30px", marginLeft: "-2px" }}
          />
        );
      case "samsung browser":
        return (
          <img
            src={samsungBrowser}
            style={{ height: "30px", marginLeft: "-2px" }}
          />
        );
      case "vivo browser":
        return (
          <img
            src={vivoBrowser}
            style={{ height: "30px", marginLeft: "-2px" }}
          />
        );

      case "safari":
        return (
          <img
            src={safariIcon}
            style={{ height: "35px", marginLeft: "-5px" }}
          />
        );
      case "huawei browser":
        return (
          <img src={huawei} style={{ height: "30px", marginLeft: "-5px" }} />
        );
      case "heytapbrowser":
        return(
          <img src={miui} style={{ height: "30px", marginLeft: "-5px" }}/>
        )
        case  "miui":
          return(
            <img src={miui} style={{ height: "30px", marginLeft: "-5px" }}/>
          )
        

      default:
        return <div className="datatrimmer w-18">{customizedBrowserType?.split(/\s(.+)/)[0]}</div>;
      // everything before the first space
    }
  }

  function selectedBgColor(browserName) {
    let alterBrowserName = browserName?.toLowerCase();
    switch (alterBrowserName) {
      case "firefox":
        return "bg-customizedBrowser-firefox_100";
      case "chrome1":
        return "bg-customizedBrowser-chrome_100";
      case "chrome":
        return "bg-customizedBrowser-chrome_100";
      case "edge":
        return "bg-customizedBrowser-edge_100";
      case "microsoft edge":
        return "bg-customizedBrowser-edge_100";
      case "safari":
        return "bg-customizedBrowser-safari_100";
      case "opera":
        return "bg-customizedBrowser-opera_100";
      //  case "vivo browser":
      //         return "bg-greenCustom-100";
      //   case "samsung browser":
      //           return "bg-customizedBrowser-samsung_100";

      default:
        return "bg-customizedBrowser-chrome_100";
    }
  }

  function BrowserVersionGradients(browserName) {
    let alterBrowserName = browserName?.toLowerCase();
    switch (alterBrowserName) {
      case "firefox":
        return "color-palette__item_fox";
      case "chrome":
        return "color-palette__item_chrome";
      case "edge":
        return "color-palette__item_edge";
      case "microsoft edge":
        return "color-palette__item_edge";
      case "safari":
        return "color-palette__item_safari";
      case "opera":
        return "color-palette__item_opera";
      case "vivo browser":
        return "color-palette__item_vivoBrowser";
      case "samsung browser":
        return "color-palette__item_samsungBrowser";
      case "huawei browser":
        return "color-palette__item_huawei";

      default:
        return "color-palette__item_safari";
    }
  }

  return (
    <div className="web_versions_container ">
      <div className="flex client_content_headings">
        {props?.webInfo === undefined ? (
          <>
            <p className="mt-1">
              {/* <Windows height="16px" width="16px" /> */}
            </p>
            <p className="mx-2 content_subheadings">
              <span className=" ">All</span> Browser & Browser Versions
            </p>
          </>
        ) : (
          <>
            <p className="mx-1 content_subheadings">
              Browser & Browser Versions
            </p>
          </>
        )}
      </div>
      <div className="mt-2">
        <div className="tableHead_wrapper ">
          <table className=" text-left">
            <thead>
              <tr style={{background:"#F2F2F2",height:"30px"}}>
                <th className=" w-1/6 labels text-xs">Defects</th>
                <th className=" w-1/6 labels text-xs">Browser</th>
                <th className="text-xs labels" style={{ width: "67.3333%" }}>
                  Browser Version
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="tableBody_wrapper">
          <table className=" text-left">
            <tbody className="mt-2">
              {props?.browserInfo?.map((value) => {
                return (
                  <tr
                    // className={`hover:${selectedBgColor(value?.browserName)}`}
                    className={value?.browserName.toLowerCase()==="firefox"?`hover:bg-customizedBrowser-firefox_100`
                    :value?.browserName.toLowerCase()==="chrome1"?`hover:bg-customizedBrowser-chrome_100`
                    :value?.browserName.toLowerCase()==="chrome"?`hover:bg-customizedBrowser-chrome_100`
                    :value?.browserName.toLowerCase()==="edge"?`hover:bg-customizedBrowser-edge_100`
                    :value?.browserName.toLowerCase()==="microsoft edge"?`hover:bg-customizedBrowser-edge_100`
                    :value?.browserName.toLowerCase()==="safari"?`hover:bg-customizedBrowser-safari_100`
                    :value?.browserName.toLowerCase()==="opera"?`hover:bg-customizedBrowser-opera_100`
                    :value?.browserName.toLowerCase()==="vivo browser"?`hover:bg-greenCustom-100`
                    :value?.browserName.toLowerCase()==="samsung browser"?`hover:bg-customizedBrowser-samsung_100`
                    :"bg-customizedBrowser-chrome_100"
                    
                    }
                    style={{ border:"1px solid #E5E5E5",margin:"4px 0px"}}
                  >
                    <td className="width_custom_defect_browser">
                      {value?.defectCount}
                    </td>
                    <Tooltip
                      PopperProps={{
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            border: "1px solid #434DB8",
                            color: "deepskyblue",
                            background: " #FFFFFF 0% 0% no-repeat padding-box",
                            width: "95px",
                            height: "auto",
                            borderRadius: " 7px",
                            opacity: 1,
                            textAlign: "center",
                            boxShadow: "0px 3px 6px #0000005E",
                          },
                        },
                      }}
                      title={
                        <div className="text-xs">
                          {value?.browserName}:{value?.defectCount}
                          <br />
                          <span>{value.totalDefectPercent}%</span>
                        </div>
                      }
                      placement="top"
                    >
                      <td className=" w-1/6">
                        {selectedBrowser(
                          value?.browserName?.split(/\s(.+)/)[0]
                        )}{" "}
                      </td>
                    </Tooltip>
                    <td
                      className="browserVersionList flex wrapperForContainer_clent"
                      style={{
                        width: "118%",
                        position: "relative",
                        left: " -39px",
                      }}
                    >
                      {props?.webInfo === undefined ? (
                        <Carousel
                          renderArrow={myArrow}
                          itemsToShow={5}
                          pagination={false}
                        >
                          {value?.browserVersionList
                            ? value?.browserVersionList?.map((version) => {
                                return (
                                  <>
                                    <Tooltip
                                      PopperProps={{
                                        sx: {
                                          "& .MuiTooltip-tooltip": {
                                            border: "1px solid #434DB8",
                                            color: "deepskyblue",
                                            background:
                                              " #FFFFFF 0% 0% no-repeat padding-box",
                                            width: "118px",
                                            height: "auto",
                                            borderRadius: " 10px",
                                            opacity: 1,
                                            boxShadow: "0px 3px 6px #0000005E",
                                          },
                                        },
                                      }}
                                      title={
                                        <div className="text-xs">
                                          <p className="flex text-center justify-center">
                                            {version.versionName} :{" "}
                                            {version?.defectCount}
                                          </p>
                                          <p className="flex text-center justify-center">
                                            {version?.totalDefectPercent} %
                                          </p>
                                        </div>
                                      }
                                      placement="top"
                                    >
                                      <div
                                        // className={
                                        //   value?.browserName === "chrome"
                                        //     ? "color-palette__item_chrome"
                                        //     : value?.browserName === "firefox"
                                        //     ? "color-palette__item_fox"
                                        //     : value?.browserName === "opera"
                                        //     ? "color-palette__item_opera"
                                        //     : value?.browserName === "edge"
                                        //     ? "color-palette__item_edge"
                                        //     : value?.browserName === "safari"
                                        //     ? "color-palette__item_safari"
                                        //     : "color-palette__item_chrome"
                                        // }
                                        className={`${BrowserVersionGradients(
                                          value?.browserName
                                        )}`}
                                      >
                                        {version.versionName?.substring(0, 6)}
                                      </div>
                                    </Tooltip>
                                  </>
                                );
                              })
                            : Object.keys(value?.browserVersionAndCount).map(
                                (version) => {
                                  return (
                                    <>
                                      <Tooltip
                                        PopperProps={{
                                          sx: {
                                            "& .MuiTooltip-tooltip": {
                                              border: "1px solid #434DB8",
                                              color: "deepskyblue",
                                              background:
                                                " #FFFFFF 0% 0% no-repeat padding-box",
                                              width: "118px",
                                              height: "35px",
                                              borderRadius: " 10px",
                                              opacity: 1,
                                              boxShadow:
                                                "0px 3px 6px #0000005E",
                                            },
                                          },
                                        }}
                                        title={value.browserDefectReport.map(
                                          (v) => v.approvedDefects
                                        )}
                                        placement="top"
                                      >
                                        <div
                                          // className={
                                          //   value?.browserName === "chrome"
                                          //     ? "color-palette__item_chrome"
                                          //     : value?.browserName === "firefox"
                                          //     ? "color-palette__item_fox"
                                          //     : value?.browserName === "opera"
                                          //     ? "color-palette__item_opera"
                                          //     : value?.browserName === "edge"
                                          //     ? "color-palette__item_edge"
                                          //     : "color-palette__item_chrome"
                                          // }
                                          className={`${BrowserVersionGradients(
                                            value?.browserName
                                          )}`}
                                        >
                                          {version}
                                        </div>
                                      </Tooltip>
                                    </>
                                  );
                                }
                              )}
                        </Carousel>
                      ) : (
                        <Carousel
                          renderArrow={myArrow}
                          itemsToShow={5}
                          pagination={false}
                        >
                          {value?.browserVersionList
                            ? value?.browserVersionList?.map((version) => {
                                return (
                                  <>
                                    <Tooltip
                                      PopperProps={{
                                        sx: {
                                          "& .MuiTooltip-tooltip": {
                                            border: "1px solid #434DB8",
                                            color: "deepskyblue",
                                            background:
                                              " #FFFFFF 0% 0% no-repeat padding-box",
                                            width: "118px",
                                            height: "35px",
                                            borderRadius: " 10px",
                                            opacity: 1,
                                            boxShadow: "0px 3px 6px #0000005E",
                                          },
                                        },
                                      }}
                                      title={
                                        <div>
                                          <p className="flex text-center justify-center">
                                            {version.versionName} :{" "}
                                            {version?.defectCount}
                                          </p>
                                          <p className="flex text-center justify-center">
                                            {version?.totalDefectPercent} %
                                          </p>
                                        </div>
                                      }
                                      placement="top"
                                    >
                                      <div
                                        // className={
                                        //   value?.browserName === "chrome"
                                        //     ? "color-palette__item_chrome"
                                        //     : value?.browserName === "firefox"
                                        //     ? "color-palette__item_fox"
                                        //     : value?.browserName === "opera"
                                        //     ? "color-palette__item_opera"
                                        //     : value?.browserName === "edge"
                                        //     ? "color-palette__item_edge"
                                        //     : "color-palette__item_chrome"
                                        // }
                                        className={`${BrowserVersionGradients(
                                          value?.browserName
                                        )}`}
                                      >
                                        {version.versionName?.substring(0, 6)}
                                      </div>
                                    </Tooltip>
                                  </>
                                );
                              })
                            : Object.keys(value?.browserVersionAndCount).map(
                                (version) => {
                                  return (
                                    <>
                                      <Tooltip
                                        PopperProps={{
                                          sx: {
                                            "& .MuiTooltip-tooltip": {
                                              border: "1px solid #434DB8",
                                              color: "deepskyblue",
                                              background:
                                                " #FFFFFF 0% 0% no-repeat padding-box",
                                              width: "118px",
                                              height: "35px",
                                              borderRadius: " 10px",
                                              opacity: 1,
                                              boxShadow:
                                                "0px 3px 6px #0000005E",
                                            },
                                          },
                                        }}
                                        title={value.browserDefectReport.map(
                                          (v) => v.approvedDefects
                                        )}
                                        placement="top"
                                      >
                                        <div
                                          // className={
                                          //   value?.browserName === "chrome"
                                          //     ? "color-palette__item_chrome"
                                          //     : value?.browserName === "firefox"
                                          //     ? "color-palette__item_fox"
                                          //     : value?.browserName === "opera"
                                          //     ? "color-palette__item_opera"
                                          //     : value?.browserName === "edge"
                                          //     ? "color-palette__item_edge"
                                          //     : "color-palette__item_chrome"
                                          // }
                                          className={`${BrowserVersionGradients(
                                            value?.browserName
                                          )}`}
                                        >
                                          {version}
                                        </div>
                                      </Tooltip>
                                    </>
                                  );
                                }
                              )}
                        </Carousel>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="browserVersion_Defect">
          {props?.webInfo ? (
            <>
              <span>{selectedOs(props?.webInfo?.osName)}</span>{" "}
              <div className="osName_browserVersion mx-1 flex">
              <div className="flex mt-5">
              <span>{selectedOsName(props?.webInfo?.osName)}</span> 
               <span className="ml-1">{props?.webInfo?.osVersion}{" "}</span>
                
              </div>
              </div>
            </>
          ) : null}
        
        </div>
        <div className="totalBrowserversionDefect">
      
          Total Defects :{" "}
          <span className="mx-1">
            {props?.webInfo
              ? props?.webInfo?.defectCount
              : props?.webClientReport?.totalBrowserDefects}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AllBrowsersContent;
