import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {ReactComponent as Warning} from "../../../assets/toast-warning.svg"
import {ReactComponent as Success} from "../../../assets/toast-success.svg"
import {ReactComponent as Info} from "../../../assets/toast-info.svg"

function Alerts({ type = "success", message, open = false, close }) {
  if (!open) return <></>;
  else
    return (
      <div  className={type==="success"?"toast active toast-success":(type==="warning"||type==="alertbanner")?"toast active toast-warning":type==="danger"?"toast active toast-danger":"toast active toast-info"}>
        <div className="toast-content">
          <div className="toast-icon">
            <span className={type==="success"?"check success":(type==="warning"||type==="alertbanner")?"check warning":type==="danger"?"check danger":"check info"}>{getIcons(type)}</span>
          </div>

          <div className="message">
            <span className={type==="success"?"success":(type==="warning"||type==="alertbanner")?"warning":type==="danger"?"danger":"info"}>{getType(type)}</span>
            <span className="text text-2">{message}</span>
          </div>
        </div>
        <span className={type==="success"?"close success":(type==="warning"||type==="alertbanner")?"close warning":type==="danger"?"close danger":"close info"}>
          <CloseIcon onClick={close} />
        </span>

        <div className={type==="success"?"successprogress active":(type==="warning"||type==="alertbanner")?"warningprogress active":type==="danger"?"dangerprogress active":"progress active"}></div>
      </div>
    );
}

export default Alerts;
const getIcons = (type) => {
  switch (type?.toLowerCase()) {
    case "danger":
      return <RemoveCircleIcon />;
    case "warning":
      // return <WarningIcon />;
      return <Warning/>;
    case "alertbanner":
      return <Warning/>;
    case "info":
      // return <InfoIcon/>;
      return <Info/>
    default:
      // return <CheckCircleIcon />;
      return <Success/>
  }
};

const getType = (type) => {
  switch (type?.toLowerCase()) {
    case "danger":
      return "Failed.";
    case "warning":
      return "Warning.";
    case "alertbanner":
      return "Alert.";
    case "info":
      return "Information.";
    default:
      return "Success.";
  }
};
