import React, { useState, useContext } from "react";
import "./project.scss";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import ProjectCreate from "./project-create";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import WarningPopup from "../modal/warning-popup";
import Tooltip from "@mui/material/Tooltip";
import {
  getUserPrivilege,
  getCertificateimg,
  getCertificate,
  getUserDetailsByEmailID,
  getprojectList,
  getProjectDetails,
  getUserFeedbackByProject,
  getFeedbackTemplateByprojectID,
} from "../../api/api-services";
import { ReactComponent as DownLoad } from "../../assets/Certificate_icon.svg";
import CertificateModal from "./modals/Certificate-modal";
import { CommonLoader } from "../common/common-loader";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer";
import { useDispatch } from "react-redux";
import { saveSelectedProject } from "../../actions/firecrowd-User-slice";
import AddFeedback from "../config/feedback/add-feedback";
import FeedbackIcon from '@mui/icons-material/Feedback';


function ProjectListItem(props) {
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  // console.log("props", props);
  const navigate = useNavigate();
  let [projectEditModal, setProjectEditModal] = useState(false);
  let [warningPopup, setWarningPopup] = useState(false);
  let [selectedProject, setSelectedProject] = useState([]);
  let [editDetails, setEditDetails] = useState();
  let myRole = "";
  let Accountmanager = "";
  let [certificatDetails, setCertficatDetails] = useState({
    projetId: "",
    userId: "",
    userName: "",
  });
  let [displayCertificat, setDisplayCertificat] = useState(false);
  let [islodaing, setisLoading] = useState(false);
  const [img, setImg] = useState();
  const [showcertificat, setShowCertificat] = useState(false);
  let [ProjectNameList, setProjectNameList] = useState([]);
  let [userList, setUserList] = useState([]);
  const dispatch = useDispatch();
  let [addFeedback,setAddFeedback]=useState(false);
  let [projectID,setProjectID]=useState()


  
  async function onEditProjectDetials(status,item) {
    resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 80,
      });
    let role;
    if (
      localStorage.hasOwnProperty("my-role") &&
      JSON.parse(localStorage["my-role"]) === "admin"
    ) {
      role = "ADMIN";
    } else {
      // item?.project.users.userList.map((user) => {
      //   if (
      //     user.email === JSON.parse(localStorage["fire-crowd-user"]).userName
      //   ) {
      //     role = user.role === "PROJECT-OWNER" ? "PROJECT_OWNER" : user.role;
      //   }
      // });
      role = item?.project.users.userResponse.role;
    }
    try {
      let response = await getUserPrivilege(role);
      resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 80,
      });
      if (response.status === 200) {
        localStorage.setItem("user-privilege", JSON.stringify(response.data));

        
      }
    } catch {
      resetProgress();
      console.log("error");
    }
    if(status){
      props?.setCloseSearchField(true)
    }
    setProjectEditModal(status);
  }
  function onEditProjectModalClose(status) {
   
    // resetProgress();
    // startOrResumeProgress({
    //   stopAt: 100,
    //   intervalDuration: 80,
    // });
    if(status){
      props?.setCloseSearchField(true)
    }
    setProjectEditModal(status);
  }
  
  async function projectDetailsPage(item) {
    let tempdetails = {
      id: item?.id,
      projectName: item?.project.projectName,
      role: item?.project.users.userResponse.role,
    };
    resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 80,
      });
    let role;
    if (
      localStorage.hasOwnProperty("my-role") &&
      JSON.parse(localStorage["my-role"]) === "admin"
    ) {
      role = "ADMIN";
    } else {
      // item?.project.users.userList.map((user)=>{
      //   if(user.email===JSON.parse(localStorage['fire-crowd-user']).userName){
      //     localStorage.setItem("selected-user", JSON.stringify(user));
      //     role=user.role;
      //   }
      // })
      role = item?.project.users.userResponse.role;
    }
    
    // console.log("itemvvh",item)
    try {
      let response = await getUserPrivilege(role);
      resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 80,
      });
      if (response.status === 200) {
        localStorage.setItem("user-privilege", JSON.stringify(response.data));
	dispatch(saveSelectedProject(item))
        localStorage.setItem(
          "selected-projectId-Name",
          JSON.stringify(tempdetails)
        );
        // localStorage.setItem("selected-project", JSON.stringify(item));
        navigate("/project/overview", { replace: true });
      }
    } catch {
      resetProgress();
      console.log("error");
    }
  }
  function onCloseWarningPopup(status) {
    setWarningPopup(status);
  }
  
  function OnDeleteProject(item) {
    if (
      item?.projectStatus === "INREVIEW" ||
      item?.projectStatus === "REJECTED"
    ) {
      setWarningPopup(true);
      setSelectedProject(item);
    }
  }
  const getAllProjectList = async () => {
    let email = JSON.parse(localStorage["fire-crowd-user"]).userName;
    try {
      let response = await getprojectList(email);
      if (response.status === 200) {
        setProjectNameList(response.data);
        console.log("response", response);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const ProjectUserDetails = async (id) => {
    try {
      const response = await getProjectDetails(id);
      if (response.status === 200) {
        setUserList(response.data.project.users.userList);
      }
    } catch (error) {
      console.log("error");
    }
  };

  function onEditProject(item) {
    console.log("my role", myRole);
    console.log("status",item?.projectStatus);
    if (
      (item.project.users.userResponse?.role === "ADMIN" && item?.projectStatus !== "COMPLETED") ||
      (item.project.users.userResponse?.role === "PROJECT_OWNER" &&
        (item?.projectStatus === "INREVIEW" ||
          item?.projectStatus === "APPROVED" ||
          item?.projectStatus === "INPROGRESS")) ||
      (item.project.users.userResponse?.role === "ACCOUNT_MANAGER" &&
        (item?.projectStatus === "APPROVED" ||
          item?.projectStatus === "INPROGRESS"))
    ) {
      onEditProjectDetials(true, item);
      ProjectUserDetails(item.id);
      setEditDetails(item);
      if(item?.projectStatus === "INREVIEW" || item?.projectStatus === "REJECTED"){
        getAllProjectList();
      }
      
    } else {
      setProjectEditModal(false);
    }
    
  }
  
  function OnCloseCertificatModal(state) {
    setDisplayCertificat(state);
  }

  function onCloseFeedbackPopUp(status) {
    setAddFeedback(status);
  }
  async function getTemplate (id){
    try{
      let response=await getFeedbackTemplateByprojectID(id);
      console.log("response",response)
      console.log("response123",response?.data?.template)
      if(response?.data?.status===200){
        setProjectID(id)
        localStorage.setItem("feedback-template-details",JSON.stringify(response?.data?.template));
        setAddFeedback(true)
  
      }
  
  
    }catch(error){
      console.log("error")
    }
  }
  async function checkPlatform(item){
    let projectId=item.id;
    let userId = JSON.parse(localStorage["fire-crowd-user"]).userName;
    let name=JSON.parse(localStorage["fire-crowd-user"]).name;
    try{
      let response= await getUserDetailsByEmailID(projectId, userId);
      if(response.status===200 && response?.data){
       if(response?.data?.configuration!==null){
        givenFeedback (item)
       }else{
        props?.MyAlert?.info("Not added the platform")
       }
      }
      

    }catch(error){
      console.log("error")
    }

  }
  async function givenFeedback (item){
    console.log("givenFeedback",item)
    let projectId = item.id;
    let email=JSON.parse(localStorage["fire-crowd-user"]).userName;
    let name=JSON.parse(localStorage["fire-crowd-user"]).name;
    let project_name=item?.project?.projectName;

    
 
    
  
    try{
      let response=await getUserFeedbackByProject(projectId,email);
     if(response?.status===200){
      if(response?.data===false){
        getTemplate(item.id)
      }else{
        props?.MyAlert?.info(`${name} you have already provided your feedback to ${project_name}`)
      }
     }

    }catch(error){
      console.log("Error")
    }
  }
  const downloadCertificate = async (item) => {
    setisLoading(true);
    setShowCertificat(true);
    let projectId = item.id;
    let userId = JSON.parse(localStorage["fire-crowd-user"]).userName;
    let username=JSON.parse(localStorage["fire-crowd-user"]).name

    setCertficatDetails({
      projetId: item.id,
      userId:userId,
      userName:  username,
    });

    try {
      let response = await getCertificateimg(userId);
      if (response.status === 200 && response.data.size !==0) {
        console.log("response getCertificateimg", response.data);

        const url = window.URL.createObjectURL(response.data);
        setImg(url);
        setDisplayCertificat(true);
      }else{
        props.MyAlert.info(`${username} may not download a certificate.`);
      }

      console.log("download file API", response);
    } catch (error) {
      console.log("error");
    }
    setShowCertificat(false);
    setisLoading(false);
  };
  function getRole(role) {
    switch (role) {
      case "PROJECT_OWNER": {
        return "Project Owner";
      }
      case "PROJECT_MANAGER": {
        return "Project Manager";
      }
      case "ACCOUNT_MANAGER": {
        return "Account Manager";
      }
      case "TEST_LEAD": {
        return "Test Lead";
      }
      case "TESTER": {
        return "Test Engineer";
      }
      case "ADMIN": {
        return "Admin";
      }
      default: {
        return role;
      }
    }
  }
  function getstatus(val) {
    switch (val) {
      case "INREVIEW": {
        return "Inreview";
        break;
      }
      case "APPROVED": {
        return "Approved";
        break;
      }
      case "INPROGRESS": {
        return "In-Progress";
        break;
      }
      case "REJECTED": {
        return "Rejected";
        break;
      }
      case "CLOSED": {
        return "Closed";
        break;
      }
      case "COMPLETED": {
        return "Completed";
        break;
      }
      default: {
        return val;
        break;
      }
    }
  }

  return (
    <div className="px-2 mt-2 project_list_body">
      {props.projectDetails.map((item, index) => {
        return (
          <div
            key={index}
            className="project_item_container wrapperForContainer"
          >
            {/* {getMyRole(item?.project?.users?.userResponse)} */}

            
            <div className="grid grid-cols-12">
              <div className="col-span-11 flex">
                <div className="value">Project Name : </div>
                <div
                  className="label hover:underline hover:decoration-2 cursor-pointer mx-1"
                  onClick={() => {
                    projectDetailsPage(item);
                  }}
                >
                  {item.project.projectName}
                </div>
              </div>
              <div className="col-span-1 flex justify-end	">
                <Tooltip
                  title={
                    (item.project.users.userResponse?.role === "ADMIN" && item?.projectStatus !== "COMPLETED") ||
                    (item.project.users.userResponse?.role=== "PROJECT_OWNER" &&
                      (item?.projectStatus === "INREVIEW" ||
                        item?.projectStatus === "APPROVED" ||
                        item?.projectStatus === "INPROGRESS")) ||
                    (item.project.users.userResponse?.role === "ACCOUNT_MANAGER" &&
                      (item?.projectStatus === "APPROVED" ||
                        item?.projectStatus === "INPROGRESS"))
                      ? "Edit"
                      : "Project cannot be edited"
                  }
                  placement="top"
                >
                  <span>
                    <button
                      className={
                        (item.project.users.userResponse?.role=== "ADMIN" &&
                          item?.projectStatus !== "COMPLETED") ||
                        (item.project.users.userResponse?.role=== "PROJECT_OWNER" &&
                          (item?.projectStatus === "INREVIEW" ||
                            item?.projectStatus === "APPROVED" ||
                            item?.projectStatus === "INPROGRESS")) ||
                        (item.project.users.userResponse?.role=== "ACCOUNT_MANAGER" &&
                          (item?.projectStatus === "APPROVED" ||
                            item?.projectStatus === "INPROGRESS"))
                          ? "mr-4 cursor-pointer "
                          : "mr-4 cursor-pointer opacity-40"
                      }
                      // className="mr-4 cursor-pointer"
                      onClick={() => {
                        onEditProject(item);
                        // onEditProjectDetials(true,item);
                        // setEditDetails(item);
                      }}
                      disabled={
                        (item.project.users.userResponse?.role=== "ADMIN" &&
                          item?.projectStatus !== "COMPLETED") ||
                        (item.project.users.userResponse?.role=== "PROJECT_OWNER" &&
                          (item?.projectStatus === "INREVIEW" ||
                            item?.projectStatus === "APPROVED" ||
                            item?.projectStatus === "INPROGRESS")) ||
                        (item.project.users.userResponse?.role=== "ACCOUNT_MANAGER" &&
                          (item?.projectStatus === "APPROVED" ||
                            item?.projectStatus === "INPROGRESS"))
                          ? false
                          : true
                      }
                    >
                      <Edit />
                    </button>
                  </span>
                </Tooltip>

                <Tooltip
                  title={
                    item?.projectStatus === "INREVIEW" &&
                    (item.project.users.userResponse?.role === "ADMIN" || item.project.users.userResponse?.role === "PROJECT_OWNER")
                      ? "Delete"
                      : "Project cannot be deleted"
                  }
                  placement="top"
                >
                  <span className="-mt-1">
                    <button
                      className={
                        item?.projectStatus === "INREVIEW" &&
                        (item.project.users.userResponse?.role=== "ADMIN" || item.project.users.userResponse?.role=== "PROJECT_OWNER")
                          ? "-mt-1 text-blue-600 cursor-pointer "
                          : "-mt-1 text-blue-600 cursor-pointer opacity-40"
                      }
                      onClick={() => {
                        OnDeleteProject(item);
                      }}
                      disabled={
                        item?.projectStatus === "INREVIEW" &&
                        (item.project.users.userResponse?.role === "ADMIN" || item.project.users.userResponse?.role=== "PROJECT_OWNER")
                          ? false
                          : true
                      }
                    >
                      <DeleteForeverOutlinedIcon />
                    </button>
                  </span>
                </Tooltip>

                {/* {item.project.users.userResponse?.role === "TESTER" && item?.projectStatus === "CLOSED" && (
                  <Tooltip title="Certificate" placement="top">
                    <div className="ml-2" style={{ marginTop: "-2px" }}>
                      <button
                        // onClick={() => checkPlatforms(item)}
                        onClick={()=>downloadCertificate(item)}
                        disabled={showcertificat ? true : false}
                      >
                        <DownLoad />
                      </button>
                    </div>
                  </Tooltip>
                )} */}

              { item.project.users.userResponse?.role === "TESTER" && (item?.projectStatus === "CLOSED"||item?.projectStatus ==="INPROGRESS" || item?.projectStatus === "COMPLETED") && (
                  <Tooltip title="Feedback" placement="top">
                    <div className="ml-2" style={{ marginTop: "-2px" }}>
                      <button className="feedback_icon">    
                        <FeedbackIcon  onClick={()=>checkPlatform(item)} sx={{color:"#5681fb"}}/>
                      </button>
                    </div>
                  </Tooltip>
                 )
                }
              </div>
            </div>
            <div className="grid grid-cols-12 mt-4">
              <div className="col-span-11">
                <div className="label">Description</div>
                <div
                  className="description_value datatrimmer"
                  title={item.project.projectDesc}
                >
                  {item.project.projectDesc === "" ||
                  item.project.projectDesc === null
                    ? "--"
                    : item.project.projectDesc}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 flex  mt-4">
              <div className="col-span-2">
                <div className="label">Event Start Date</div>
                <div className="value">
                  {dateFormat(item.startDate.slice(0, 10), "dd/mm/yyyy")}
                </div>
              </div>
              <div className="col-span-2">
                <div className="label">Event Duration</div>
                <div className="value">{item?.duration}</div>
              </div>
              <div className="col-span-2">
                <div className="label">Number Of Testers</div>
                <div className="value">{item?.numberOfTesters}</div>
              </div>
              <div className="col-span-2">
                <div className="label">Status</div>
                <div
                  className={
                    item?.projectStatus === "INREVIEW"
                      ? "status_inreview"
                      : item?.projectStatus === "APPROVED"
                      ? "status_approved"
                      : item?.projectStatus === "REJECTED"
                      ? "status_rejected"
                      : item?.projectStatus === "INPROGRESS"
                      ? "status_inprogress": item?.projectStatus === "CLOSED"?
                       "status_close":"status_completed"
                  }
                >
                  {/* {item?.projectStatus === "INREVIEW" ||
                  item?.projectStatus === "INPROGRESS"
                    ? item?.projectStatus.replace("N", "N-")
                    : item?.projectStatus} */}
                  {getstatus(item?.projectStatus)}
                </div>
              </div>
              <div className="col-span-2">
                <div className="label">Account Manager</div>
                <div className="value">
                  {/* {Accountmanager} */}
                  {item.project.users.userResponse?.accountManagerName === null
                    ? "--"
                    : item.project.users.userResponse?.accountManagerName}
                </div>
              </div>
              <div className="col-span-2">
                <div className="label">My Role</div>
                <div className="value">
                  {/* {myRole.replace("_", "-")} */}
                  {/* {item.project.users.userResponse?.role.replace("_", "-")} */}
                  {getRole(item.project.users?.userResponse?.role)}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {projectEditModal && (
        <ProjectCreate
          MyAlert={props.MyAlert}
          closeModal={onEditProjectModalClose}
          onProjectEdited={props.onProjectEdited}
          header={"Edit Project"}
          editDetails={editDetails}
          ProjectNameList={ProjectNameList}
          userList={userList}
        />
      )}
      {warningPopup && (
        <WarningPopup
          deleteProject={props.deleteProject}
          onCloseWarningPopup={onCloseWarningPopup}
          project={selectedProject}
          mode={"Delete project"}
        />
      )}
      {displayCertificat && (
        <CertificateModal
          details={certificatDetails}
          closeModal={OnCloseCertificatModal}
          imgdata={img}
        />
      )}
      {
        addFeedback && (
          <AddFeedback selectedDefectData={""} MyAlert={props.MyAlert} closeModal={onCloseFeedbackPopUp} projectID={projectID}/>
        )
      }
      {islodaing ? <CommonLoader /> : null}
    </div>
  );
}
export default ProjectListItem;
