import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import "../../integrations/integration.scss"

function JiraInstanceDetailsModal(props) {
  console.log("JiraInstanceCreateModal", props);
  const [openModal, setOpenModal] = useState(true);
  const [detaislData, setDetailsData] = useState(props?.data);
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "80%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px">
      <div className="create_instance">
        <div className="grid grid-cols-12  rounded-tl-xl flex header">
          <span className="col-span-11 heading pl-2.5">
            {" "}
            Jira Instance Details
          </span>
          <div className="col-span-1 flex justify-end mr-1">
            <button
              onClick={() => {
                props.closemodal(false);
              }}
              type="button"
              className="modal_clos_btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="body">
          <div className="grid grid-cols-10 px-4 pb-4">
            <div className="col-span-5">
              <span className="labels">Server URL</span>
              <br></br>
              <div className="values" title={detaislData?.serverUrl}>{detaislData?.serverUrl}</div>
            </div>
            <div className="col-span-5">
              <span className="labels">Username</span>
              <br></br>
              <div className="values " title={detaislData?.serverUrl}>{detaislData?.userName}</div>
            </div>
          </div>
          <div className="grid grid-cols-10 px-4 pb-4">
            <div className="col-span-5">
              <span className="labels">API Token</span>
              <br></br>
              <div className="apiToken_values w-36" >{detaislData?.apiToken}</div>
            </div>
            <div className="col-span-5">
              <span className="labels">Domain</span>
              <br></br>
              <div className="values" title={detaislData?.domain}>{detaislData?.domain}</div>
            </div>
          </div>
          <div className="grid grid-cols-10 px-4 pb-4">
            <div className="col-span-5">
              <span className="labels">Created By</span>
              <br></br>
              <div className="values" title={detaislData?.createdBy}>{detaislData?.createdBy}</div>
            </div>
            <div className="col-span-5">
              <span className="labels">Created On</span>
              <br></br>
              <div className="values">{detaislData?.createdOn}</div>
            </div>
          </div>
        </div>
        <>
          <div className="pt-2 px-3 flex justify-end gap-4 button-content modal_footer">
            <button
              type="button"
              className="w-24 gray-btn ml-3 button"
              onClick={() => {
                props.closemodal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </>
      </div>
    </Modal>
  );
}
export default JiraInstanceDetailsModal;
