import React,{useState,useRef,useEffect} from "react";
import { ReactComponent as ArrowIcon } from "../../../assets/back_button.svg";
import {
    
    TextField,
    Select,
    MenuItem,
    Tooltip,
  } from "@mui/material";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Label } from "../../common/inputs/myInput";  
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { getLabel, getOptionLabel } from '../common-functions';
import FormControl from '@material-ui/core/FormControl';
import RichTextEditor from "../rich-texteditor";
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFile from "@mui/icons-material/AttachFile";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "./manualTestCaseTemplate.scss";
import ColumnResizer from "react-table-column-resizer";
// import ColumnResizer from "react-column-resizer";
import { useNavigate, useLocation } from "react-router-dom";


function TestcaseTemplateDetails(props) {
    const location = useLocation();
 
    let [expand,setExpand]=useState(true);
    const [showOptions, setShowOptions] = useState(false);
    const [rowId, setRowId] = useState();
    const [tsInitialRow, setTsInitialRow] = useState(3);
    let menuRef = useRef();
    const navigate = useNavigate();
    useEffect(() => {
        let handeler = (event) => {
            if (menuRef.current && !menuRef?.current?.contains(event.target)) {
                setShowOptions(false)

            }
        };
        document.addEventListener("mousedown", handeler)
        return () => {
            document.removeEventListener("mouseleave", handeler)
        }
    }, [])
   
      const [TemplateDataTable, setTemplateDataTable] = useState( JSON.parse(JSON.stringify(location?.state)) )
      
   
      function navigateToTempList(){
        navigate("/project/configuration/manual-TestCase-Template",{ replace: true })
      }
      const [TemplateData, setTemplateData] = useState(
        JSON.parse(JSON.stringify(location?.state))
      );
    const testCaseDetailDescElements = (data) => {
        switch (data.type) {
            case 'textField':
                return (
                    <div className='rs-user-input-container'>
                        <TextField
                            disabled={true}
                            // InputProps={{ disableUnderline: true }}
                            // fullWidth
                            size="small"
                            // className="w-full"
                            className="lg:w-60 lg:h-10 sm:w-full"
                            title={data.placeholder.length > 30 ? data.placeholder : null}
                            placeholder={data.placeholder.length > 30 ? data.placeholder.substr(0, 30) + '...' : data.placeholder}
                            value={data.defaultValue}
                        />
                    </div>
                )

            case 'textArea':
                return (
                    <>
                        <div className="">
                            <TextareaAutosize
                                disabled={true}
                                // InputProps={{ disableUnderline: true }}
                                title={data.placeholder.length > 30 ? data.placeholder : null}
                                id="description"
                                name="description"
                                maxLength="200"
                                maxRows={0}
                                style={{resize: "none",height:"70px",width:"100%",background:"white"}}
                                className="project-description"
                                placeholder={data.placeholder.length > 30 ? data.placeholder.substr(0, 30) + '...' : data.placeholder}
                                value={data.defaultValue} />
                            {/* {data && (data.minLength >= 0 && data.maxLength >= 0) ? <div className="mt-1 text-sm text-gray-500 text-right mr-14">{data.minLength}/{data.maxLength}</div> : null} */}
                        </div>
                    </>
                )

            case 'radioButton':
                return (
                    <>
                        <div className='mt-3'>
                            <div className="grid grid-cols-2 gap-1">
                                {data.options.map((option, index) => (
                                    <div>
                                        <input type="radio" className='mr-2' name={`option${index}`} id={`option${index}`} value={option} disabled={true} />
                                        <label className='details-data-style-common'>{getOptionLabel(option)}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )

            case 'link':
                return (
                    <>
                        <div className=' flex mt-3'>
                            <div><InsertLinkOutlinedIcon className='-mt-2 mr-2 origin-center rotate-90 opacity-60' /></div>
                            <div aria-disabled={true} className="details-data-style-common opacity-50 w-72">{getLabel('Attached Link will be shown here if any URL is added')}</div>
                        </div>
                    </>
                )

            case 'dropdown':
                return (
                    <>
                        <FormControl>
                            <Select
                            
                                disabled={true}
                                displayEmpty
                             
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }
                                }}
                                
                                name="selectCustomField"
                                id='selectCustomField'
                                className="lg:w-60 lg:h-10 sm:w-40 fontPoppinsRegularMd">
                                {data.options.map((option, index) => (
                                    <option className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 details-data-style-common lg:w-72 sm:w-40 fontPoppinsRegularMd" value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Select>
                            
                          
                        </FormControl>
                    </>
                )

            case 'date':
                return (
                    // <>
                    //     <div className='flex'>
                    //         <div className=' h-8 border-2 border-gray-300 w-72'></div>
                    //         <div className=' bg-gray-200'><CalendarTodayOutlinedIcon className='mt-1' /></div>
                    //     </div>
                    //     {/* {TemplateData?.templateType === "Predefined" ? <div className='details-data-style-common opacity-50'>[dd-mm-yyyy]</div> : <div className='details-data-style-common opacity-50'>[{data.dateFormat}]</div>} */}
                    // </>
                    <>
                    <div className="flex">
                      <div className="border-2 rounded border-gray-300  flex py-1" style={{width:"68%"}}>
                        <div className="w-11/12"></div>
                        <div className="border-l-2  pl-2">
                          <CalendarTodayOutlinedIcon className="mt-1" />
                        </div>
                      </div>
                    </div>
                    {TemplateData?.templateType === "Predefined" ? (
                      <div className="text-xs opacity-50">[dd-mm-yyyy]</div>
                    ) : (
                      <div className="text-xs opacity-50">[{data.dateFormat}]</div>
                    )}
                  </>
                )
            case 'checkbox':
                return (
                    <>
                        <div className='lg:w-72'>
                            <div className="grid grid-cols-2 gap-1">
                                {data.options.map((option, index) => (
                                    <div>
                                        <input type="checkbox" className='mr-2' name={`option${index}`} id={`option${index}`} value={option} disabled={true} />
                                        <label className='details-data-style-common'>{getOptionLabel(option)}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </>
                )

            case 'attachment':
                return (
                    // <div className='rs-user-input-container'>
                    //     <TextField InputProps={{ disableUnderline: true }} placeholder='Search and select file name' disabled={true} className='lg:w-72' />
                    // </div>
                    <>
            
            <div>
              <div className="text-blue-600"><AttachFile/>{""}Attach</div>
            </div>
            
          </>
                )
            case 'textEditor':
                return (
                    <>
                        <RichTextEditor
                            convertedContent=''
                            readOnly={true}
                            toolbarHidden={false}
                            className="w-full"
                        />
                    </>
                )

            default:
                return (
                    <>
                        <TextField disabled={true} className='w-80' />
                    </>
                )

        }
    }
    const testCaseDetailElements = (props) => {
        return (
            <>
                <div className="flex flex-col justify-center">
                    <div className=''>
                        <h6 className='tc_lables'>
                            {props.data.mandatory === "yes" ? <span className="text-red-400 mr-1">&#42;</span> : null}{getLabel(props.data.label)}
                        </h6>
                    </div>
                    <div className='flex'>
                        <div className='contentDataStyle'>{testCaseDetailDescElements(props.data)}</div>
                       
                    </div>
                </div>
            </>
        )
    }
    const testCaseDetailSection = () => {
        return (
            <>
                <div className="testCaseDetail_Section">
                    <div>
                        {/* <Accordion defaultExpanded={true}>
                            <div className="" >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon className="cursor-pointer" />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className="tc_subheading">Test Case Details</div>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails className="testcase_details">
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} className='mb-7'>
                                    {TemplateData.testCaseDetails.map(data => (
                                        data?.type === 'textEditor' ? (
                                            <Grid item xs={12} sm={12} md={12}>
                                                {testCaseDetailElements({ data })}
                                            </Grid>
                                        ) :
                                            <Grid item xs={2} sm={4} md={4}>
                                                {testCaseDetailElements({ data })}
                                            </Grid>
                                    ))}
                                </Grid>
                            </AccordionDetails>
                        </Accordion> */}
                        <div className="mt-4 mb-2">
                            <div className="flex grid grid-cols-12" >
                            <div className="tc_subheading col-span-11">Test Case Details</div>
                            {
                                expand?(
                                    <div className="col-span-1 cursor-pointer" onClick={()=>{setExpand(!expand)}}>
                                <ExpandMoreIcon />
                                
                                </div>
                                
                                ):(
                                    <div className="col-span-1 cursor-pointer" onClick={()=>{setExpand(!expand)}}>
                                    <KeyboardArrowUpIcon/>
                                    </div>  
                                )
                            }
                            
                                    
                               
                            </div>
                            <div className={expand?"testcase_details block":" testcase_details hidden"}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} className='mb-7'>
                                    {TemplateData.testCaseDetails.map(data => (
                                        data?.type === 'textEditor'|| data?.type === 'textArea'? (
                                            <Grid item xs={11} sm={11} md={11}>
                                                {testCaseDetailElements({ data })}
                                            </Grid>
                                        ) :
                                            <Grid item xs={2} sm={4} md={4}>
                                                {testCaseDetailElements({ data })}
                                            </Grid>
                                    ))}
                                </Grid>
               </div>
                        </div>
                    </div>
                     <div className="">
    {testStepsTable()}
    </div>
                </div>
               
             
            </>
        )
    }
    const tsHeaderChangeHandler = (e, index) => {
        let tempTemplateData = TemplateDataTable
        let headerValue = e.target.textContent.toString()
        if (e.target.textContent === "Status") {
            tempTemplateData.testSteps.headers[index] = ''
            props.MyAlert.warning('Creating column name with either Status or Actual Result is Not Allowed')
            setTemplateDataTable({ ...tempTemplateData })
        } else if (e.target.textContent === '') {
            tempTemplateData.testSteps.headers[index] = ''
            props.MyAlert.warning('Creating column name with empty name is Not Allowed')
            setTemplateDataTable({ ...tempTemplateData })
    
        } else if (e.target.textContent === "Actual Result") {
            tempTemplateData.testSteps.headers[index] = ''
            props.MyAlert.warning('Creating column name with Actual Result is Not Allowed')
            setTemplateDataTable({ ...tempTemplateData })
        } else {
            tempTemplateData.testSteps.headers[index] = headerValue
            setTemplateDataTable(tempTemplateData)
        }
    
    }
    
    const tsHeaderBlurHandler = (e, index) => {
        let tempTemplateData = TemplateDataTable
        if (e.target.textContent === "Status") {
            tempTemplateData.testSteps.headers[index] = ''
            e.target.textContent = ''
            props.MyAlert.warning('Creating column name with either Status or Actual Result is Not Allowed')
            setTemplateDataTable({ ...tempTemplateData })
        } else if (e.target.textContent === "Actual Result") {
            tempTemplateData.testSteps.headers[index] = ''
            e.target.textContent = ''
            props.MyAlert.warning('Creating column name with Actual Result is Not Allowed')
            setTemplateDataTable({ ...tempTemplateData })
        }
    
    }
    const handelDropdown = () => {
      setShowOptions(!showOptions)
    }
    const testStepsDropdown = (index, data) => {
      return (
          <>
              {showOptions && rowId === index &&
                  <>
                      <div
                          className="shadow-none origin-top-right absolute mt-2 w-40 
                          rounded-md bg-white ring-1 ring-black ring-opacity-5 
                          focus:outline-none z-50 -right-14"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          tabIndex="-1"
                          ref={menuRef}>
                          <div className="my-1" contentEditable={false}>
                              <div className="cursor-pointer hover:bg-gray-200 p-1 fontPoppinsRegularMd text-left" onClick={() => addColDDTS(index)}><span className='ml-2'>Insert column left</span></div>
                              <div className="cursor-pointer hover:bg-gray-200 p-1 fontPoppinsRegularMd text-left" onClick={() => addColDDTS(index + 1)}><span className='ml-2'>Insert column right</span></div>
                              {(data === 'Status' || data === 'Actual Result') ? null : <div className="cursor-pointer hover:bg-gray-200 p-1 fontPoppinsRegularMd text-left " onClick={() => deleteColDDTS(index)}><span className='ml-2'>delete column</span></div>}
                          </div>
                      </div>
                  </>
              }
          </>
      )
    }
    
      const testStepsTable = (view) => {
        return (
            <>
                <div className='mx-2.5 table_container '>
                    <div className=" mt-3 my-5">
                        <div className="">
                            
                                <div className="flex justify-between h-16">
                                    <div className="mx-1 my-5"><h3 className="project_label_TCM fontPoppinsSemiboldLg text-sm flex-auto">Test Steps</h3></div>
                                    <div className="flex justify-evenly mx-4 my-5">
    
                                        {/* <div className="mx-2 cursor-pointer"><FiMaximize2 className=" text-lg mt-2 text-blue-800" /></div> */}
                                    </div>
                                </div>
                            
                            {/* {mode === "view" &&
                                <div className="flex justify-between h-16">
                                    <div className="mx-4 my-5"><h3 className="project_label_TCM fontPoppinsSemiboldLg text-sm flex-auto">Test Steps</h3></div>
                                    <div className="flex justify-evenly mx-4 my-5">
    
                                        <div className="mx-2 cursor-pointer">
                                            <FiMaximize2 className=" text-lg mt-2 text-gray-400 cursor-default" disabled={true} />
                                        </div>
                                    </div>
                                </div>
                            } */}
                            <div className="w-auto overflow-scroll " id="journal-scroll">
                                <table className="testStepTable" style={{width:"100%"}}>
                                    <thead style={{display:" table-header-group",
    verticalAlign: "middle"}} className="bg-gray-100">
                                        {TemplateDataTable.testSteps.headers.map((data, index) => (
                                            <>
                                                <th className="p-2 testStepTable" 
                                                
                                                // onInput={(e) => tsHeaderChangeHandler(e, index)} 
                                                // onBlur={(e) => tsHeaderBlurHandler(e, index)}
                                                >
                                                    <div className='flex justify-evenly'>
                                                        <div
                                                            // contentEditable={data === 'Status' || data === 'Actual Result' || view === 'modalView' ? false : true}
                                                            className={data === 'Status' || data === 'Actual Result' || view === 'modalView' ?
                                                                'focus:outline-none focus:border-opacity-0 w-4/5 text-left fontPoppinsMediumMd mt-2'
                                                                :
                                                                '  focus:outline-none w-4/5 text-left fontPoppinsMediumMd mt-2'}
                                                        >
                                                            {data}
                                                        </div>
                                                       
                                                            {/* <div contentEditable={false} className='w-1/5  relative'>
                                                                <button className="mt-2" onClick={() => { handelDropdown(); setRowId(index) }}>
                                                                    <MoreVertIcon fontSize='small' className=' text-blue-800 -mt-1' />{view !== 'modalView' && testStepsDropdown(index, data)}
                                                                </button>
                                                            </div> */}
                                                    </div>
                                                </th>
                                                <ColumnResizer minWidth={120} className="columnResizer" />
                                            </>
                                        ))}
                                    </thead>
                                    <tbody style={{height:"auto",overflowY:"auto",display:" table-row-group",
    verticalAlign: "middle"}}>
                                        {Array.from(Array(tsInitialRow)).map(data => (
                                            <tr style={{display:" table-row",
    verticalAlign: "inherit"}} className="h-9 testStepTable focus:outline-none" 
    // contentEditable={data === 'Status' || data === 'Actual Result' ? false : true}
    contentEditable={false}
    >
                                                {Array.from(Array(TemplateDataTable.testSteps.headers.length)).map(data => (
                                                    <> <td className='testStepTable' ></td>
                                                        <ColumnResizer minWidth={120} className="columnResizer" />
                                                    </>
                                                ))}
                                            </tr>
                                        ))}
    
                                    </tbody>
                                </table>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    const addColDDTS = (index) => {
      let tempTemplateData = TemplateDataTable
      tempTemplateData.testSteps.headers.splice(index, 0, 'NewColumn');
      setTemplateDataTable({ ...tempTemplateData })
      handelDropdown()
    }
    
    const deleteColDDTS = (index) => {
      let tempTemplateData = TemplateDataTable
      tempTemplateData.testSteps.headers.splice(index, 1);
      setTemplateDataTable({ ...tempTemplateData })
      handelDropdown()
    
    }
      
  return (
    <div className="tc-template-details" >
      <div className="grid grid-cols-12 flex test_case">
        <div className="col-span-10 heading">
          Manual Testcase Template Details - {location?.state?.name}
        </div>
        <div className="grid grid-cols-12 col-span-2 flex justify-end">
          <div className="grid grid-cols-12 col-span-3">
            <div className="flex back_btn testcase_btn cursor-pointer" onClick={()=>{navigateToTempList()}}>
              <span className="col-span-2 add_icon">
                {/* <Add /> */}
                <ArrowIcon />
              </span>
              <div className=" col-span-10 py-2 px-2">Back</div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 flex test_case">
        <div className="col-span-10 heading">
        <div className="mb-4">
                        <Label
                          label={
                      <>
                        <span className="text-red-400 mr-1">&#42;</span>{" "}
                        <span className="tc_lables">Template Name</span>
                      </>
                    }
                  />
                  <br></br>
                  <TextField
                    name="templateName"
                    id="templateName"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="off"
                    disabled={true}
                    value= {location?.state?.name}
                    className=" w-60 "
                    type="text"
                    
                  />
                </div>
        </div>
       
      </div>
      {/* testcase details body */}
      <div className="grid grid-cols-12">
        <div className="col-span-12 mt-8 ">
            {testCaseDetailSection()}
        </div>
      </div>
    </div>
  );
}
export default TestcaseTemplateDetails;
