import { useState } from "react";
import "../../project/project.scss";
import Modal from "react-modal";
import { CommonLoader } from "../../../pages/common/common-loader";
import TreeWithRadioButton from "../../../pages/common/table_tree/table-tree-with-radio-button";
import { Label } from "../../common/inputs/myInput";
import * as yup from "yup";
import { useFormik } from "formik";
import cx from "classnames";
import { TextField } from "@mui/material";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { createModuleReq,EditModuleReq } from "../../../api/api-services";
import { useSelector } from "react-redux";
import { fireCrowdUserData, selectedProjectData } from "../../../actions/firecrowd-User-slice";

const descriptionLabel = "DESCRIPTION";
const descriptionPlaceHolder = "Your project description goes here..";

function CreateModule(props) {
  console.log("CreateModule",props)
  const [openModal, setOpenModal] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [treeData, setTreeData] = useState(props?.treeData);
  const [selectTreeMode, setSelectTreeMode] = useState(false);
  const [descCount, setdescCount] = useState(
    props.header === "Edit Module" ? props.editDetails.project.desc.length : 0
  );
  const [selectedParentNode, setSelectedParentNode] = useState();
  const fireCrowdUser = useSelector(fireCrowdUserData)
  const selectedProj = useSelector(selectedProjectData)
  const localStorageSelectedProject = JSON.parse(localStorage.getItem("selected-project"));
  let proj = Object.keys(selectedProj).length > 0 ? selectedProj:localStorageSelectedProject;
  const [callingAPI,setCallingAPI]=useState(false)
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "82%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      // borderRadius: "4px",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };
  const validationSchema = yup.object({
    name: yup
    .string()
    .trim("Space is not allowed at start and end of the name")
    .strict(true)
    .min(3,"Name should be more than 2 character")
    .max(25,"Name should be less than 25 character")
    .required("Name is required")
    .matches(
      /^[a-zA-Z0-9-_() ]*$/,
      "Name should be alphanumeric"
    )
    .test("Module-name", "Name cannot be module", function (value) {
      if(value === "Module" || value === "module"){
        return false;
      } else {
        return true;
      }
    }),
    desc: yup.string(),
    parentName: yup.string().required("Parent Module is required"),
  });
  let initialValues;
  if (props?.mode !== "edit") {
    initialValues = {
      name: "",
      desc: "",
      parentName: props.data?.name ? props.data?.name : "",
    };
  } else {
    initialValues = {
      name: props?.data?.name ? props.data.name : "",
      desc: props?.data?.desc ? props.data.desc : "",
      parentName: props.data?.parentName ? props.data?.parentName : "",
    };
  }
  const checkForDuplicates = (node, enteredModuleName) => {
    if (node?.children?.length) {
        for (const childNode of node.children) {
            if (childNode.title.toLowerCase().trim() === enteredModuleName.toLowerCase().trim()
                &&
                childNode.folder
                &&
                props?.data?.id !== (childNode._key ? childNode._key : childNode.key)
            ) {
                return true;
            }
        };
        return false;
    } else {
        return false;
    }
}
  const onSubmit = async (values) => {
    console.log(values);
    let payload = {};
    if (values.name.toLowerCase().trim() === 'module' || values.name.toLowerCase().trim() === 'preconditions'|| values.name.toUpperCase().trim() === 'PRECONDITIONS'  ||  values.name.toLowerCase().trim() === 'postconditions'|| values.name.toUpperCase().trim() === 'POSTCONDITIONS') {
      formikDetails.setFieldError('name', `Name cannot be ${values.name}`)
  }else if (['root module','settings','execution dashboard','module','variables','history','automation steps', 'web service'].includes(values?.moduleName?.toLowerCase()?.trim())) {
      formikDetails.setFieldError('name', `Name cannot be ${values.name}`)
  } else if (props.data ? props.data.name.trim().toLowerCase() !== values.name.trim().toLowerCase() && checkForDuplicates(selectedParentNode, values.name) : checkForDuplicates(selectedParentNode, values.name)) {
      formikDetails.setFieldError('name', `Name already exists`)
  } else {
    if (props.mode !== "edit") {
      setCallingAPI(true)
      payload = { ...values };
      payload.projectId = proj?.project?.fireFlinkProjectId;
      payload.executionOrder =props.moduleIdForAdd && props.data ?props.data.lastExecutionOrder + 1:
        selectedParentNode?.[0].data.lastExecutionOrder + 1;
      payload.hierarchy = props.moduleIdForAdd && props.data ?props.data.hierarchy + 1:selectedParentNode?.[0].data.hierarchy + 1;
      payload.parentId = props.moduleIdForAdd?props.moduleIdForAdd:selectedParentNode?.[0].data.key;
      console.log(selectedParentNode, payload, "here");
      try {
        let response = await createModuleReq(payload,proj?.project?.fireFlinkProjectId,proj?.licenceID);
        console.log(response);
        if (response.data.message === "SUCCESS") {
          const search = window.location.search;
          const id = new URLSearchParams(search).get("id");
          props?.setIsLoading(true);
          props.MyAlert.success(`${payload?.name} added successfully`);
          props.getTreeData(id);
          props.closeModal();
        }else if (response.data.responseCode === 400 && response.data.message === `Resource with name '${payload.name}' already exists`) {
          // setCreateCalled(false);
          formikDetails.setFieldError('name', 'Name already exists')
      } else {
          props.MyAlert.warning(`${response.data.message}`);
          props.closeModal(false);
      }
      } catch (err) {
        console.error(err);
      }
       setCallingAPI(false)
    } else {
      setCallingAPI(true)
      if(props.data.name !== values.name || (props.data.desc !== values.desc && (props.data.desc || values.desc) ) ){
      payload = {
        name: values.name,
        desc: values.desc,
        parentId: props.data.parentId,
        parentName: props.data.parentName,
        executionOrder: props.data.executionOrder,
        hierarchy: props.data.hierarchy,
        assigneeCount:props?.data?.assigneeCount
        
      };
      payload.projectId = proj?.project?.fireFlinkProjectId;
      try {
        let response = await EditModuleReq(payload,proj?.project?.fireFlinkProjectId,proj?.licenceID,props.data.name,props.data.id);
        console.log(" createModuleReq response",response)
        if (response.data.status === "SUCCESS") {
          const search = window.location.search;
          const id = new URLSearchParams(search).get("id");
          props?.setIsLoading(true);
          props.MyAlert.success(`${payload?.name} updated successfully`);
          props.getTreeData(id);
          props.closeModal();
        }else if (response.data.responseCode === 400 && response.data.message === `Resource with name '${payload.name}' already exists`) {
          // setCreateCalled(false);
          formikDetails.setFieldError('name', 'Name already exists')
      } else {
         if(response.data.message!==null){
          props.MyAlert.warning(`${response.data.message}`);
         }else{
          if(response.data.status!==null){
            props.MyAlert.warning(`${response.data.status}`) 
          }
         }
          
          props.closeModal(false);
      }
      } catch (err) {
        console.error(err);
      }}else{
        props.MyAlert.info(`No changes were done to the Module`);
          props.closeModal();
      }
      setCallingAPI(false)
    }
  }
    
  };
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const selectingTree = (data) => {
    setSelectTreeMode(data);
  };
  const treeNodeSelected = (node) => {
    console.log(node);
    setSelectedParentNode(node);
    formikDetails.setFieldValue("parentName", node[0].data.name);
  };
  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex justify-between  rounded-tl-xl create-modal-heading h-10 px-2 items-center">
        <div>{selectTreeMode ? "Select Parent Module" : props?.mode !== "edit"? "Create Module": "Edit Module"}</div>
        <span className="flex items-center">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="modal-close-btn mr-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>
      <form onSubmit={formikDetails.handleSubmit}>
        {!selectTreeMode ? (
          <div className="px-2">
            <div className="my-4">
              <div className="mb-4">
                <Label
                  label={
                    <span className="login-labels popins-semi-bold">Name</span>
                  }
                  required={true}
                  error={
                    formikDetails.errors.name && formikDetails.touched.name
                  }
                />
              </div>
              <div className="popins-medium">
                <TextField
                  error={
                    formikDetails.errors.name && formikDetails.touched.name
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={props.mode === "edit"?props?.data?.assignee:false}
                  onClick={() => {}}
                  type="text"
                  name="name"
                  id="name"
                  inputProps={{
                    className: cx(
                      "!text-xs open-sans-regular boxedInput popins-medium"
                    ),
                    style: {
                      padding: "9.5px 6px",
                      height: "0.43em !important",
                    },
                  }}
                  autoComplete="off"
                  placeholder="Enter module name"
                  // disabled={editMode ? true : false}
                  onBlur={formikDetails.handleBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.name}
                />
              </div>
              {formikDetails.errors.name &&
              formikDetails.touched.name ? (
                <div className="text-red-500 error_text md:mt-1">
                  {formikDetails.errors.name}
                </div>
              ) : null}
            </div>
            <div className="my-5">
              <div className="mb-4">
                <Label
                  label={
                    <span className="login-labels popins-semi-bold">
                      Description
                    </span>
                  }
                />
              </div>
              <div>
                {/* <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      name="projectDesc"
                      id="projectDesc"
                      placeholder={descriptionPlaceHolder}
                      onBlur={formikDetails.handleBlur}
                      onChange={formikDetails.handleChange}
                      value={formikDetails.values.projectDesc}
                    /> */}

                <TextareaAutosize
                  name="desc"
                  id="desc"
                  value={formikDetails.values.desc}
                  placeholder={descriptionPlaceHolder}
                  onChange={formikDetails.handleChange}
                  style={{ height: "80px", resize: "none", overflow: "scroll" }}
                  onKeyUp={(e) => setdescCount(e.target.value.length)}
                  onBlur={formikDetails.handleBlur}
                  //  rowsMax={noOfRows}
                  maxLength="200"
                  className="project-description"
                ></TextareaAutosize>
                <div className="mt-1 mr-1 text-sm text-gray-500 text-right">
                  {descCount}/200
                </div>
              </div>
            </div>
            <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
              <button
                onClick={() => {
                  setOpenModal(false);
                  props.closeModal(false);
                }}
                type="button"
                className="w-24 gray-btn ml-3 button"
              >
                Cancel
              </button>
              <button
                type="submit"
                id="createBatch"
                onClick={() => {}}
                className="w-24 primary-btn button"
                disabled={callingAPI}
              >
                {props?.mode === "edit" ? "Update" : "Create"}
              </button>
            </div>
          </div>
        ) : null}
        {
          <div className="">
            {!selectTreeMode && (
              <div className="mb">
                <Label
                  label={
                    <span className="login-labels popins-semi-bold">
                      Parent Module
                    </span>
                  }
                  required={true}
                />
              </div>
            )}
            <TreeWithRadioButton
              selectingTree={selectingTree}
              moduleSelection={true}
              operation={
                props.data ? "edit" : props.moduleIdForAdd ? "edit" : null
              }
              moduleName={
                props.mode === "edit"
                  ? props.data?.parentName
                    ? props.data?.parentName
                    : ""
                  : props.data?.name
                  ? props.data?.name
                  : ""
              }
              hideRootRadioBtn={false}
              individualTree={
                props.moduleIdForAdd || props.individualTreeId ? true : false
              }
              disableRootRadioButton={false}
              //   defaultDisabled={[{ key: "folder", value: true }]}
              data={treeData}
              hideElements={true}
              hideElementsBtn={true}
              modal={true}
              placeholder={
                props.mode === "edit"
                  ? props.data?.parentName
                    ? props.data?.parentName
                    : ""
                  : props.data?.name
                  ? props.data?.name
                  : ""
              }
              nodeSelected={treeNodeSelected.bind(this)}
              buttonLabel="Module"
            />
            {formikDetails.errors.parentName &&
              formikDetails.touched.parentName ? (
                <div className="text-red-500 pl-2 error_text md:mt-1">
                  {formikDetails.errors.parentName}
                </div>
              ) : null}
          </div>
        }
      </form>
      {isLoading ? <CommonLoader /> : null}
    </Modal>
  );
}

export default CreateModule;
