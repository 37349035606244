import React, { useState, useEffect } from "react";
import SegmentChart from "../new_reports/segmentchart";
import SegmentTable from "../new_reports/segment_table";
import ModuleSegment from "../new_reports/module-segment";
import {
  getDefectsData,
  DownloadDefectExcleFile,
  getDefectSegmentData,
  getLocationSegmentData,
  getDefectsDataCount,
} from "../../../api/api-services";
import DefectExpandSegment from "./defect-expand-segment";
import DonutChart from "../charts/donut-chart";
import DynamicChart from "../charts/dynamic-chart";
import { CircularLoader } from "../../common/CircularLoader";

let Severity_Color = [
  "#00FA9A",
  "#008B8B",
  "#7B68EE",
  "#9400D3",
  "#DA70D6",
  "#C71585",
  "#D2691E",
  "#BC8F8F",
];
let Priority_Color = [
  "#ff0000",
  "#ee82ee",
  "#6a5acd",
  "#ffa500",
  "#0000ff",
  "#3cb371",
  " #bb5588",
  "#ffcc99",
];
let Module_Color = [
  "#DFFF00",
  "#FFBF00",
  "#FF9633",
  "#FF7F50",
  "#DE3163",
  "#9FE2BF",
  "#40E0D0",
  "#6495ED",
];
let location_color = [
  "#000080",
  "#00FFFF",
  "#800080",
  "#FF00FF",
  "#008080",
  "#008000",
  "#FFFF00",
  "#00FF00",
];
let webSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
};
let mobileSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
  deviceBrand: null,
  deviceName: null,
  deviceType: null,
};
let severityState = "CLOSED";
let severityPayload = [];

let priorityState = "CLOSED";
let priorityPayload = [];

let ModuleState = "CLOSED";
let LocationState = "CLOSED";

let SelectedLocationPayload=[];
let SelectedModulePayload=[];


function SegmentContainer(props) {
  let [displaySeverityapproverdefect, setSeverityDisplayapproverdefect] =
    useState(true);
  let [displaypriorityapproverdefect, setPriorityDisplayapproverdefect] =
    useState(true);
  let [displaymoduleapproverdefect, setModuleDisplayapproverdefect] =
    useState(true);
  let [displaylocationapproverdefect, setLocationDisplayapproverdefect] =
    useState(true);

  let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])?.project
    ?.fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"])?.licenceID;
  let projectID = JSON.parse(localStorage["selected-project"])?.id;

  let [severityDefects, setSeverityDefects] = useState([]);
  let [severityDefectPageNo, setSeverityPageNo] = useState(0);
  let [severitylastpage, setSeveritylastpage] = useState();

  let [priorityDefects, setPriorityDefects] = useState([]);
  let [priorityDefectPageNo, setPriorityPageNo] = useState(0);
  let [prioritylastpage, setPrioritylastpage] = useState();
  let [ModulePayload, setModulePayload] = useState([]);
   let [LocationPayload,setLocationPayload]=useState([]);
   let [expandLocationPayload,setExpandLocationPayload]=useState([]);
   let [expandModulePayload,setExpandModulePayload]=useState([]);
   

   

  let [severity, setSeverity] = useState({
    label: [],
    series: [],
    payload: [],
  });

  let [prority, setPriority] = useState({
    label: [],
    series: [],
    payload: [],
  });
  let [module, setModule] = useState({
    label: [],
    series: [],
    payload: [],
  });
  let [expandModule, setExpandModule] = useState({
    label: [],
    series: [],
    payload: [],
  });

  let [location, setLocation] = useState({
    label: [],
    series: [],
    payload: [],
  });
  let [expandLocation, setExpandLocation] = useState({
    label: [],
    series: [],
    payload: [],
  });

  let [severityCount, setSeverityCount] = useState(0);
  let [priorityCount, setPriorityCount] = useState(0);
  let [moduleCount, setModuleCount] = useState(0);
  let [locationCount, setLocationCount] = useState(0);

  const [severityDefectsCount, setSeverityDefectsCount] = useState();
  const [priorityDefectsCount, setPriorityDefectsCount] = useState();
  const [locationDefectsCount, setLocationDefectsCount] = useState();
  const [moduleDefectsCount, setModuleDefectsCount] = useState();

  const [expandModulePopUp, setExpandModulePopUp] = useState(false);
  const [expandLocationPopUp, setExpandLocationPopUp] = useState(false);

  let [severitydataDownloading,setSeverityDataDownloading]=useState(false);
  let [prioritydataDownloading,setPriorityDataDownloading]=useState(false);

  // let [moduleSelectedLabel,setModuleSelectedLabel]=useState([]);
  let [locationSelectedLabel,setlocationSelectedLabel]=useState([]);
  let [moduleSelectedLabel,setModuleSelectedLabel]=useState([]);

  let [severityDataLoading,setSeverityDataLoading]=useState();
  let [priorityDataLoading,setPriorityDataLoading]=useState();

  let [moduleDataLoading,setModuleDataLoading]=useState();
  let [locationDataLoading,setLocationDataLoading]=useState();

 


  useEffect(() => {
    setSeverityDisplayapproverdefect(true);
    setPriorityDisplayapproverdefect(true);
    setModuleDisplayapproverdefect(true);
    setLocationDisplayapproverdefect(true);
    getAllSegmentData("CLOSED");
    getAllLocationSegment("CLOSED");
  }, [props?.allowRejectedDefect]);

  async function getAllLocationSegment(state) {
    LocationState = state;
    setLocationDataLoading(true);
    try {
      let response = await getLocationSegmentData(
        licenseID,
        fireFlinkProjectId,
        projectID,
        state
      );
      console.log("getAllLocationSegment", response);
      if (response?.status === 200 && response?.data?.data!==null) {
        let tempData = response?.data?.data;
        // setLocationCount(response?.data?.totalDefects);
        localStorage.setItem(
          "location-defect",
          JSON.stringify(response?.data?.totalDefects)
        );

        tempData.map((item) => {
          let obj1 = {
            label: [],
            series: [],
            payload: [],
          };
          let obj2 = {
            label: [],
            series: [],
            payload: [],
          };
          let labelArray=[]

          item.defectData.map((data, index) => {
            if (index < 8) {
              let val=data?.name + "-" + data?.count
              labelArray.push(val)
              obj1.label.push(data?.name);
              obj1.series.push(data?.count);
              obj1.payload.push(data?.name);
              obj2.label.push(data?.name);
              obj2.series.push(data?.count);
              obj2.payload.push(data?.name);
            } else {
              let val=data?.name + "-" + data?.count
              labelArray.push(val)
              obj2.label.push(data?.name);
              obj2.series.push(data?.count);
              obj2.payload.push(data?.name);
            }
          });
          console.log("location1", obj2);
       
           setLocationPayload(labelArray)
           setExpandLocationPayload(labelArray)
          setLocation(obj1);
          setExpandLocation(obj2);
          setlocationSelectedLabel(obj2.label)
        });
      }else if (response?.data?.statusCode===404){
        let obj = {
          label: [],
          series: [],
          payload: [],
        };
        
         setLocationPayload([])
         setExpandLocationPayload([])
        setLocation(obj);
        setExpandLocation(obj);
        setlocationSelectedLabel([])
      }
    } catch (error) {
      console.log("error");
    }
    setLocationDataLoading(false)
  }

  async function getAllSegmentData(state) {
    severityState = state;
    priorityState = state;
    ModuleState = state;
    setSeverityDataLoading(true)
    setPriorityDataLoading(true)
    setModuleDataLoading(true)
    try {
      let response = await getDefectSegmentData(
        licenseID,
        fireFlinkProjectId,
        projectID,
        state
      );
      console.log("getAllSegmentmentData", response);
      let Severitylabels = [],
        Prioritylabels = [];
      if (response?.data?.statusCode === 200) {
        let tempdata = response?.data?.data;
        // setSeverityCount(response?.data?.totalDefects);
        localStorage.setItem(
          "severity-defect",
          JSON.stringify(response?.data?.totalDefects)
        );
        localStorage.setItem(
          "priority-defect",
          JSON.stringify(response?.data?.totalDefects)
        );
        localStorage.setItem(
          "module-defect",
          JSON.stringify(response?.data?.totalDefects)
        );
       
        // setPriorityCount(response?.data?.totalDefects);
        // setModuleCount(response?.data?.totalDefects);
        tempdata.map((item) => {
          if (item.name === "Severity") {
            let obj = {
              label: [],
              series: [],
              payload: [],
            };
            item.defectData.map((data) => {
              Severitylabels.push(data?.name);
              obj.label.push(data?.name);
              obj.series.push(data?.count);
              obj.payload.push(data?.name);
            });
            setSeverity(obj);
          } else if (item.name === "Priority") {
            let obj = {
              label: [],
              series: [],
              payload: [],
            };
            item.defectData.map((data) => {
              Prioritylabels.push(data.name);
              obj.label.push(data?.name);
              obj.series.push(data?.count);
              obj.payload.push(data?.name);
            });
            setPriority(obj);
          } else if (item.name === "Module") {
            let obj1 = {
              label: [],
              series: [],
              payload: [],
            };
            let obj2 = {
              label: [],
              series: [],
              payload: [],
            };
             let labelArray=[];
            item.defectData.map((data, index) => {
              if (index < 8) {
                let val=data?.name + "-" + data?.count
                labelArray.push(val)
                obj1.label.push(data?.name);
                obj1.series.push(data?.count);
                obj1.payload.push(data?.name);
                obj2.label.push(data?.name);
                obj2.series.push(data?.count);
                obj2.payload.push(data?.name);
              } else {
                let val=data?.name + "-" + data?.count
                labelArray.push(val)
                obj2.label.push(data?.name);
                obj2.series.push(data?.count);
                obj2.payload.push(data?.name);
              }
            });
            setModulePayload(labelArray);
            setExpandModulePayload(labelArray)
            setModule(obj1);
            setExpandModule(obj2);
            setModuleSelectedLabel(obj2.label)
            
          }
        });

        getAllseveritydefects(Severitylabels, 0, "CLOSED");
        getAllprioritydefects(Prioritylabels, 0, "CLOSED");
        setModuleDataLoading(false)
      }else if(response?.data?.statusCode === 404){
        let obj = {
          label: [],
          series: [],
          payload: [],
        }; 
        setSeverity(obj);
        setPriority(obj);
        setModulePayload([]);
        setExpandModulePayload([])
        setModule(obj);
        setExpandModule(obj);
        setSeverityDefectsCount(0);
        setPriorityDefectsCount(0)
        setModuleSelectedLabel([])
        setSeverityDataLoading(false)
        setPriorityDataLoading(false)
        setModuleDataLoading(false)
      }
    } catch (error) {
      console.log("error");
    }
    
  }

  async function getSeveritySegmentdata(state) {
    severityState = state;
    setSeverityDataLoading(true)
    try {
      let response = await getDefectSegmentData(
        licenseID,
        fireFlinkProjectId,
        projectID,
        state
      );
      console.log("getAllSegmentmentData", response);
      let Severitylabels = [];
      if (response?.data?.statusCode === 200) {
        let tempdata = response?.data?.data;
        // setSeverityCount(response?.data?.totalDefects);
        localStorage.setItem(
          "severity-defect",
          JSON.stringify(response?.data?.totalDefects)
        );
        tempdata.map((item) => {
          if (item.name === "Severity") {
            let obj = {
              label: [],
              series: [],
              payload: [],
            };
            item.defectData.map((data) => {
              Severitylabels.push(data?.name);
              obj.label.push(data?.name);
              obj.series.push(data?.count);
              obj.payload.push(data?.name);
            });
            setSeverity(obj);
          }
        });

        getAllseveritydefects(Severitylabels, 0, state);
      }else if(response?.data?.statusCode === 404){
        let obj={
          label: [],
          series: [],
          payload: [], 
        }
        setSeverity(obj);
        setSeverityDefects([])
        setSeverityDefectsCount(0);
        setSeverityDataLoading(false)
      }
    } catch (error) {
      console.log("error");
    }
  }

  async function getPrioritySegmentdata(state) {
    priorityState = state;
    setPriorityDataLoading(true)
    try {
      let response = await getDefectSegmentData(
        licenseID,
        fireFlinkProjectId,
        projectID,
        state
      );
      console.log("getAllSegmentmentData", response);
      let Prioritylabels = [];
      if (response?.data?.statusCode === 200) {
        let tempdata = response?.data?.data;
        setPriorityCount(response?.data?.totalDefects);
        localStorage.setItem(
          "priority-defect",
          JSON.stringify(response?.data?.totalDefects)
        );
        tempdata.map((item) => {
          if (item.name === "Priority") {
            let obj = {
              label: [],
              series: [],
              payload: [],
            };
            item.defectData.map((data) => {
              Prioritylabels.push(data.name);
              obj.label.push(data?.name);
              obj.series.push(data?.count);
              obj.payload.push(data?.name);
            });
            setPriority(obj);
          }
        });

        getAllprioritydefects(Prioritylabels, 0, state);
      }else if(response?.data?.statusCode === 404){
        let obj = {
          label: [],
          series: [],
          payload: [],
        };
        setPriority(obj);
        setPriorityDefects([]);
        setPriorityDefectsCount(0)
        setPriorityDataLoading(false)
      }
    } catch (error) {
      console.log("error");
    }
  }

  async function getModuleSegmentdata(state) {
    ModuleState = state;
    setModuleDataLoading(true)
    try {
      let response = await getDefectSegmentData(
        licenseID,
        fireFlinkProjectId,
        projectID,
        state
      );
      console.log("getAllSegmentmentData", response);
      let Severitylabels = [],
        Prioritylabels = [];
      if (response?.data?.statusCode === 200) {
        let tempdata = response?.data?.data;
        // setModuleCount(response?.data?.totalDefects);
        localStorage.setItem(
          "module-defect",
          JSON.stringify(response?.data?.totalDefects)
        );
        tempdata.map((item) => {
          if (item.name === "Module") {
            let obj1 = {
              label: [],
              series: [],
              payload: [],
            };
            let obj2 = {
              label: [],
              series: [],
              payload: [],
            };
            let labelArray=[]
            item.defectData.map((data, index) => {
              if (index < 8) {
                let val=data?.name + "-" + data?.count;
                labelArray.push(val)
                obj1.label.push(data?.name);
                obj1.series.push(data?.count);
                obj1.payload.push(data?.name);
                obj2.label.push(data?.name);
                obj2.series.push(data?.count);
                obj2.payload.push(data?.name);
              } else {
                let val=data?.name + "-" + data?.count
                labelArray.push(val)
                obj2.label.push(data?.name);
                obj2.series.push(data?.count);
                obj2.payload.push(data?.name);
              }
            });
            // ModulePayload=obj2.label
           
            setModulePayload(labelArray);
            setExpandModulePayload(labelArray)
            setModule(obj1);
            setExpandModule(obj2);
            setModuleSelectedLabel(obj2.label)
          }
        });
      }else if(response?.data?.statusCode === 404){
        let obj = {
          label: [],
          series: [],
          payload: [],
        };
        setModulePayload([]);
        setExpandModulePayload([])
        setModule(obj);
        setExpandModule(obj);
        setModuleSelectedLabel([])
      }
    } catch (error) {
      console.log("error");
    }
    setModuleDataLoading(false)
  }

  const getAllDefectsCount = async (payload, state, type) => {
    try {
      let responseObject = await getDefectsDataCount(
        fireFlinkProjectId,
        licenseID,
        payload,
        state
      );
      console.log("req count", payload);
      console.log("responseObject count", responseObject);
      if (responseObject?.data?.responseCode === 200) {
        switch (type) {
          case "severity":
            setSeverityDefectsCount(responseObject?.data?.totalDefectCount);
            break;
          case "priority":
            setPriorityDefectsCount(responseObject?.data?.totalDefectCount);
            break;
          case "module":
            setModuleDefectsCount(responseObject?.data?.totalDefectCount);
            break;

          case "location":
            setLocationDefectsCount(responseObject?.data?.totalDefectCount);
            break;

          default:
            break;
        }
      } else {
        switch (type) {
          case "severity":
            setSeverityDefectsCount(0);
            break;
          case "priority":
            setPriorityDefectsCount(0);
            break;
          case "module":
            setModuleDefectsCount(0);
            break;

          case "location":
            setLocationDefectsCount(0);
            break;

          default:
            break;
        }
      }
    } catch (err) {
      console.log("error");
    }
  };

  const getAllseveritydefects = async (payload, pageno, state) => {
    let segmentSearchFilters = {
      severity: payload,
      priority: null,
      modules: null,
      locations: null,
      createdOn: null,
    };

    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 10;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          fireFlinkProjectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
          setSeveritylastpage(res?.data?.lastPage);
          setSeverityPageNo(page + 1);
          severityState = state;
          severityPayload = payload;
          console.log("severityPayload", severityPayload);

          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
            setSeverityDefects([...tempArray]);
            getAllDefectsCount(filterdata, state, "severity");
          } else {
            let defectArray = [];
            defectArray = severityDefects.concat(tempArray);
            setSeverityDefects(defectArray);
          }
        }else{
          setSeverityDefects([])
        }
      } catch (error) {
        console.log("Error");
      }
    } else {
      setSeverityDefects([]);
      setSeverityDefectsCount(0);
    }
    setSeverityDataLoading(false)
  };

  const getAllprioritydefects = async (payload, pageno, state) => {
    let segmentSearchFilters = {
      severity: null,
      priority: payload,
      modules: null,
      locations: null,
      createdOn: null,
    };
    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 10;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          fireFlinkProjectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
          setPrioritylastpage(res?.data?.lastPage);
          setPriorityPageNo(page + 1);
          priorityState = state;
          priorityPayload = payload;
          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
            setPriorityDefects([...tempArray]);
            getAllDefectsCount(filterdata, state, "priority");
          } else {
            let defectArray = [];
            defectArray = priorityDefects.concat(tempArray);
            setPriorityDefects(defectArray);
          }
        } else {
          setPriorityDefects([]);
        }
      } catch (err) {
        console.log("error");
      }
    } else {
      setPriorityDefects([]);
      setPriorityDefectsCount(0);
    }
    setPriorityDataLoading(false)
  };

  function updateSegmentData(key, state, val) {
    switch (val) {
      case "severity": {
        setSeverityPageNo(0);
        setSeveritylastpage();
        if (key === "approved") {
          console.log("approved");
          getSeveritySegmentdata("CLOSED");
        } else {
          console.log("rejected");
          getSeveritySegmentdata("REJECTED");
        }
        setSeverityDisplayapproverdefect(state);

        break;
      }
      case "priority": {
        setPriorityPageNo(0);
        setPrioritylastpage();
        if (key === "approved") {
          // getAllprioritydefects(null, 0, "CLOSED");
          getPrioritySegmentdata("CLOSED");
        } else {
          console.log("rejected");
          // getAllprioritydefects(null, 0, "REJECTED");
          getPrioritySegmentdata("REJECTED");
        }
        setPriorityDisplayapproverdefect(state);

        break;
      }
      case "module": {
        SelectedModulePayload=[]
        if (key === "approved") {
          getModuleSegmentdata("CLOSED");
          ModuleState = "CLOSED";
        } else {
          getModuleSegmentdata("REJECTED");
          ModuleState = "REJECTED";
        }
        setModuleDisplayapproverdefect(state);

        break;
      }
      case "location": {
        SelectedLocationPayload=[]
        if (key === "approved") {
          console.log("approved");
          getAllLocationSegment("CLOSED");
          LocationState = "CLOSED";
        } else {
          console.log("rejected");
          getAllLocationSegment("REJECTED");
          LocationState = "REJECTED";
        }
        setLocationDisplayapproverdefect(state);

        break;
      }
      default: {
        break;
      }
    }
  }

  function selectSegement(val) {
    switch (val) {
      case "severity": {
        if (severitylastpage === false) {
          getAllseveritydefects(
            severityPayload,
            severityDefectPageNo,
            severityState
          );
        }
        break;
      }
      case "priority": {
        if (prioritylastpage === false) {
          getAllprioritydefects(
            priorityPayload,
            priorityDefectPageNo,
            priorityState
          );
        }
        break;
      }

      default:
        break;
    }
  }
  function saveFileFromBlob(blob, filename,label) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
    if(label==="severity"){
      setSeverityDataDownloading(false)
    }else{
      setPriorityDataDownloading(false)
    }
  }
  const downloadExcleFile = async (state, payload,label) => {
    if(label==="severity"){
      setSeverityDataDownloading(true)
    }else {
      setPriorityDataDownloading(true)
    }
    let filename =
      state === "CLOSED" ? "Approved_Defects.xlsx" : "Rejected_Defects.xlsx";
    try {
      let response = await DownloadDefectExcleFile(
        fireFlinkProjectId,
        licenseID,
        state,
        payload
      );
      console.log("response file", response);
      if (response.status === 200) {
        saveFileFromBlob(response.data, filename,label);
      }else{
        if(label==="severity"){
          setSeverityDataDownloading(false)
        }else{
          setPriorityDataDownloading(false)
        }
      }
    } catch (err) {
      console.log("error");
    }
  };

  function downloadFile(label) {
    switch (label) {
      case "severity": {
        let segmentSearchFilters = {
          severity: severityPayload,
          priority: null,
          modules: null,
          locations: null,
          createdOn: null,
        };
        let filterdata = {
          segmentSearchFilters: segmentSearchFilters,
          webSearchFilters: webSearchFilters,
          mobileSearchFilters: mobileSearchFilters,
        };
        downloadExcleFile(severityState, filterdata,label);
        break;
      }

      case "priority": {
        let segmentSearchFilters = {
          severity: null,
          priority: priorityPayload,
          modules: null,
          locations: null,
          createdOn: null,
        };
        let filterdata = {
          segmentSearchFilters: segmentSearchFilters,
          webSearchFilters: webSearchFilters,
          mobileSearchFilters: mobileSearchFilters,
        };
        downloadExcleFile(priorityState, filterdata);
        break;
      }

      default:
        break;
    }
  }

  function selectedChartlabelData(lable, value) {
    switch (lable) {
      case "severity": {
        let severityFilterData;
        // let severityEle = document.getElementById(lable + 0);
        // severityEle.scrollIntoViewIfNeeded({
        //   behavior: "auto",
        //   block: "start",
        //   inline: "nearest",
        // });
        setSeverityPageNo(0);
        setSeveritylastpage();
        console.log("severityPayload API", severityPayload);
        if (severityPayload.length === 1 && severityPayload[0] === value[0]) {
          severityFilterData = severity.label;
        } else {
          severityFilterData = value;
        }

        getAllseveritydefects(severityFilterData, 0, severityState);
        break;
      }
      case "priority": {
        let priorityFilterdata;
        // let priorityEle = document.getElementById(lable + 0);
        // priorityEle.scrollIntoViewIfNeeded({
        //   behavior: "auto",
        //   block: "start",
        //   inline: "nearest",
        // });
        setPriorityPageNo(0);
        setPrioritylastpage();
        if (priorityPayload.length === 1 && priorityPayload[0] === value[0]) {
          priorityFilterdata = prority.label;
        } else {
          priorityFilterdata = value;
        }

        getAllprioritydefects(priorityFilterdata, 0, priorityState);

        break;
      }
      case "module": {
        if (SelectedModulePayload.length === 1 && SelectedModulePayload[0] === value[0]) {
          setModulePayload(expandModulePayload);
          SelectedModulePayload=[]
          setModuleSelectedLabel(expandModule?.label)
        } else {
          let index=expandModule?.label.indexOf(value[0])
          let tempval=expandModulePayload[index]
          SelectedModulePayload=value;
          setModulePayload([tempval]);
          setModuleSelectedLabel(value)
          
        }
        break;
      }
      case "location" :{
        console.log("setLocationPayload",LocationPayload)
        if (SelectedLocationPayload.length === 1 &&  SelectedLocationPayload[0] === value[0]) {
          console.log("expandLocation?.label", LocationPayload)
          setLocationPayload(expandLocationPayload)
          SelectedLocationPayload=[]
          setlocationSelectedLabel(expandLocation?.label)
        } else {
          let index=expandLocation?.label.indexOf(value[0])
          let tempval=expandLocationPayload[index]
          SelectedLocationPayload=value
          setLocationPayload([tempval])
          setlocationSelectedLabel(value)
        
          
        }
        break;
      }
    

      default: {}
      
      
    }
  }

  function ClosedExpandSegmentPopUp(state) {
    setExpandModulePopUp(state);
  }

  function ClosedExpandLocationPopUp(state) {
    setExpandLocationPopUp(state);
  }

  return (
    <div>
      <div className="grid grid-cols-12 mt-4  col-span-12">
        <div className="col-span-6 reports_segemnts_container">
          <div className="col-span-6 grid grid-cols-12 flex">
            <div className="col-span-6 segment_header ml-2">Severity</div>
            {props.allowRejectedDefect && (
              <div className="col-span-6 flex segment_tab justify-end">
                <div
                  className={`mr-1.5 ${
                    displaySeverityapproverdefect
                      ? "selected_tab"
                      : "unselected_tab"
                  }`}
                  onClick={() => {
                    if (displaySeverityapproverdefect === false) {
                      updateSegmentData("approved", true, "severity");
                    }
                  }}
                >
                  Approved Defects
                </div>
                <div className="unselected_tab">|</div>
                <div
                  className={`ml-1.5 ${
                    displaySeverityapproverdefect
                      ? "unselected_tab"
                      : "selected_tab"
                  }`}
                  onClick={() => {
                    if (displaySeverityapproverdefect === true) {
                      updateSegmentData("rejected", false, "severity");
                    }
                  }}
                >
                  Rejected Defects
                </div>
              </div>
            )}
          </div>
          {
             severityDataLoading===false? (
              <>
               <div className="flex justify-center" style={{ height: "190px" }}>
            {
              severity?.label?.length>0?(
                
                  <SegmentChart
              labels={severity.label}
              series={severity.series}
              color={Severity_Color}
              value={"severity"}
              totalCount={severityCount}
              selectedChartlabelData={selectedChartlabelData}
            />
                ):(
                <DonutChart/>
              )
            }
            
          </div>
          <div className="segment-divider mb-2"></div>
          <div>
            <SegmentTable
              data={severityDefects}
              label={"severity"}
              selectAPI={selectSegement}
              downloadFile={downloadFile}
              defectCount={severityDefectsCount}
              downloadingfile={severitydataDownloading}
              payload={severityPayload}
              state={displaySeverityapproverdefect === true?"APPROVED":"REJECTED"}

            />
          </div>
              </>
             ):(
              <div className="mt-28">
                <CircularLoader/>
              </div>
             )
          }
         
        </div>
        <div className="col-span-6 reports_segemnts_container">
          <div className="col-span-6 grid grid-cols-12 flex">
            <div className="col-span-6 segment_header ml-2">Priority</div>
            {props.allowRejectedDefect && (
              <div className="col-span-6 flex segment_tab justify-end">
                <div
                  className={`mr-1.5 ${
                    displaypriorityapproverdefect
                      ? "selected_tab"
                      : "unselected_tab"
                  }`}
                  onClick={() => {
                    if (displaypriorityapproverdefect === false) {
                      updateSegmentData("approved", true, "priority");
                    }
                  }}
                >
                  Approved Defects
                </div>
                <div className="unselected_tab">|</div>
                <div
                  className={`ml-1.5 ${
                    displaypriorityapproverdefect
                      ? "unselected_tab"
                      : "selected_tab"
                  }`}
                  onClick={() => {
                    if (displaypriorityapproverdefect === true) {
                      updateSegmentData("rejected", false, "priority");
                    }
                  }}
                >
                  Rejected Defects
                </div>
              </div>
            )}
          </div>
          
            {
              priorityDataLoading===false ?(
                <>
                 <div className="flex justify-center" style={{ height: "190px" }}>
            {
              prority?.label?.length >0 ?( <SegmentChart
                labels={prority?.label}
                series={prority?.series}
                color={Priority_Color}
                value={"priority"}
                totalCount={priorityCount}
                selectedChartlabelData={selectedChartlabelData}
              />

              ):(
                <DonutChart/>
              )
            }
           
          </div>
          <div className="segment-divider mb-2"></div>
          <div>
            <SegmentTable
              data={priorityDefects}
              label={"priority"}
              selectAPI={selectSegement}
              downloadFile={downloadFile}
              defectCount={priorityDefectsCount}
              downloadingfile={prioritydataDownloading}
              payload={priorityPayload}
              state={displaypriorityapproverdefect === true?"APPROVED":"REJECTED"}
            />
          </div>
                </>
              ):(
                <div className="mt-28">
                  <CircularLoader/>
                </div>
              )
            }
              
              
            
            
          
         
        </div>
      </div>

      <div className="grid grid-cols-12 mt-4 col-span-12">
        <div className="col-span-6 reports_segemnts_container">
          <div className="col-span-6 grid grid-cols-12 flex">
            <div className="col-span-6 segment_header ml-2">Modules</div>
           
            {props.allowRejectedDefect && (
              <div className="col-span-6 flex segment_tab justify-end">
                <div
                  className={`mr-1.5 ${
                    displaymoduleapproverdefect
                      ? "selected_tab"
                      : "unselected_tab"
                  }`}
                  onClick={() => {
                    if (displaymoduleapproverdefect === false) {
                      updateSegmentData("approved", true, "module");
                    }
                  }}
                >
                  Approved Defects
                </div>
                <div className="unselected_tab">|</div>
                <div
                  className={`ml-1.5 ${
                    displaymoduleapproverdefect
                      ? "unselected_tab"
                      : "selected_tab"
                  }`}
                  onClick={() => {
                    if (displaymoduleapproverdefect === true) {
                      updateSegmentData("rejected", false, "module");
                    }
                  }}
                >
                  Rejected Defects
                </div>
              </div>
            )}
          </div>
          {
            moduleDataLoading===false?(
              <>
               <div className="flex justify-center" style={{ height: "190px" }}>
            {
              module?.label?.length > 0 ?(
                <SegmentChart
              labels={module.label}
              series={module.series}
              color={Module_Color}
              value={"module"}
              totalCount={moduleCount}
              selectedChartlabelData={selectedChartlabelData}
            />
              ):(
                <DonutChart/>
              )
            }
            
          </div>
          <div className="segment-divider mb-2 h-6">
            <div className={`flex justify-end See_more_btn ${expandModule?.label?.length > 8?"block":"hidden"}`}  onClick={() => {
                  setExpandModulePopUp(true);
                }}><span>See More</span></div>
          </div>
          <div>
            <ModuleSegment
              label={"module"}
              data={ModulePayload}
              displayapproverdefect={ModuleState}
              chartlabels={expandModule}
              selectedlabel={moduleSelectedLabel}
            />
          </div>
              </>
            ):(
              <div  className="mt-28">
                <CircularLoader/>
              </div>
            )
          }
         
        </div>
        <div className="col-span-6 reports_segemnts_container">
          <div className="col-span-6 grid grid-cols-12 flex">
            <div className="col-span-6 segment_header ml-2">Locations</div>
            {props.allowRejectedDefect && (
              <div className="col-span-6 flex segment_tab justify-end">
                <div
                  className={`mr-1.5 ${
                    displaylocationapproverdefect
                      ? "selected_tab"
                      : "unselected_tab"
                  }`}
                  onClick={() => {
                    if (displaylocationapproverdefect === false) {
                      updateSegmentData("approved", true, "location");
                    }
                  }}
                >
                  Approved Defects
                </div>
                <div className="unselected_tab">|</div>
                <div
                  className={`ml-1.5 ${
                    displaylocationapproverdefect
                      ? "unselected_tab"
                      : "selected_tab"
                  }`}
                  onClick={() => {
                    if (displaylocationapproverdefect === true) {
                      updateSegmentData("rejected", false, "location");
                    }
                  }}
                >
                  Rejected Defects
                </div>
              </div>
            )}
          </div>
          
          {
            locationDataLoading===false?(
              <>
              <div className="flex justify-center" style={{ height: "190px" }}>
                {
                  location?.label?.length >0 ?(
                    <SegmentChart
                    labels={location?.label}
                    series={location?.series}
                    color={location_color}
                    value={"location"}
                    totalCount={locationCount}
                    selectedChartlabelData={selectedChartlabelData}
                  />
                  ):(
                    <DonutChart/>
                  )
                }
                
              </div>
              <div className="segment-divider mb-2 h-6">
              <div className={`flex justify-end See_more_btn ${expandLocation?.label?.length > 8? "block" : "hidden"}`} onClick={() => {
                  setExpandLocationPopUp(true);
                }}>
                  <span>See More</span>
                  </div>
              </div>
              <div>
                <ModuleSegment
                  label={"location"}
                  data={LocationPayload}
                  displayapproverdefect={LocationState}
                  chartlabels={expandLocation}
                  selectedlabel={locationSelectedLabel}
                />
              </div>
            </>
            ):(
              <div className="mt-28">
                <CircularLoader/>
              </div>
            )
           
          }
        </div>
      </div>
      {expandModulePopUp && (
        <DefectExpandSegment closeModal={ClosedExpandSegmentPopUp} header={"Modules"}/>
      )}
      {
        expandLocationPopUp && (
          <DefectExpandSegment closeModal={ClosedExpandLocationPopUp} header={"Locations"}/>
        )
      }
    </div>
  );
}
export default SegmentContainer;
