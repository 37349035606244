import { useState } from "react";
import "../../project/project.scss";
import Modal from "react-modal";
import { CommonLoader } from "../../../pages/common/common-loader";
import TreeWithRadioButton from "../../../pages/common/table_tree/table-tree-with-radio-button";
import { Label } from "../../common/inputs/myInput";
import * as yup from "yup";
import { useFormik } from "formik";
import cx from "classnames";
import { TextField } from "@mui/material";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { createTestcase,editTestcase } from "../../../api/api-services";
import { useNavigate } from "react-router-dom";

const summaryPlaceHolder = "Your  description goes here..";

function CreatTeastCase(props) {
  console.log("props?.parentData",props?.parentData)
  console.log("props.childnode",props)

 
  const [openModal, setOpenModal] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [treeData, setTreeData] = useState(props?.treeData);
  const [selectTreeMode, setSelectTreeMode] = useState(false);
  const [descCount, setdescCount] = useState(
    props?.modeType === "edit" ?props?.data?.desc?.length : 0
    
  );
  const navigate = useNavigate();
  const[ParentData,setParentData]=useState(props?.parentData?props?.parentData:"");
  const[childNode,setChildNode]=useState(props.childnode?props.childnode:[]);
  const [callingAPI,setCallingAPI]=useState(false);
  const search = window.location.search;
  const modal_ID = new URLSearchParams(search).get("id");

  function findhierarchy(value){
    //  console.log("value node",value.data)
     return value.data.hierarchy+1;
  }

  function findExecutionOrder(value){
    //  console.log("value node",value.data)
     return value.data.lastExecutionOrder+1
  }
  
  
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "83%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };
  const validationSchema = yup.object({
    name: yup
      .string()
      .trim("Space is not allowed at starting and at the end")
      .strict(true)
      .min(3,"Name should be more than 2 character")
      .max(25,"Name should be less than 25 character")
      .required("Name is required")
      .matches(/^[a-zA-Z0-9-_() ]*$/, "Name should be alphanumeric"),
    desc: yup.string(),
    parentName: yup.string().required("Parent Module is required"),
  });
  let initialValues;
  if (props?.modeType === "edit") {
    initialValues = {
      
      name: props?.data?.name ? props?.data?.name : "",
      summary:props?.data?.desc?props?.data?.desc:"",
       parentName: props?.data?.parentName
       ? props?.data?.parentName
       : "",
    };
  } else {
    initialValues = {
      name: "",
      summary: "",
      parentName: props?.parentData?.node?.data?.name ? props?.parentData?.node?.data?.name: "",
      parentId:props?.parentData?.node.key?props?.parentData?.node.key:""
    };
  }
  const checkForDuplicates = (node, enteredModuleName) => {
    console.log("checkForDuplicates",node )
    if (node?.children?.length) {
        for (const childNode of node.children) {
            if (childNode.title.toLowerCase().trim() === enteredModuleName.toLowerCase().trim()
                
            ) {
                return true;
            }
        };
        return false;
    } else {
        return false;
    }
}

const hasDuplicate=(name,id)=>{
  console.log("childNode",childNode)
  console.log("name",name)
  let hasdulicate=false;
  for(const node of childNode){
    if(node.title.toLowerCase().trim()===name.toLowerCase().trim() && id!== node.key){
      hasdulicate=true
    }

  }
  if(hasdulicate){
    return true
  }else{
    return false
  }
  
}
  const onSubmit = async (values) => {
     let projectID = JSON.parse(localStorage.getItem("selected-project")).project
      .fireFlinkProjectId;
    let licenseId=JSON.parse(localStorage.getItem("selected-project")).licenceID
    console.log("parent data",ParentData)
    console.log("values222 trrrt",values.name)

    if(checkForDuplicates(ParentData.node,values.name)){
      formikDetails.setFieldError('name', `Name already exists`)
    }else{
      if(props?.modeType==="create"){
        setCallingAPI(true)
        let hierarchykey=findhierarchy(ParentData);
        let executionOrderkey=findExecutionOrder(ParentData);
        let requestbody = {
          name: values.name,
          scriptType: "Webservice",
          desc: values.summary,
          parentId: values.parentId ? values.parentId : props?.parentData?.node.data.parentId,
          parentName: values.parentName,
          executionOrder:executionOrderkey,
          hierarchy: hierarchykey,
          testCaseType: "manual",
          templateId: "TEMP1001",
          manualTestCase: {},
          type: "Script",
        };
      
        try {
          let response = await createTestcase(
            requestbody,
            values.parentId,
            projectID,
            licenseId
          
          );
          if(response.status===200){
            console.log("response",response);
            if(response.data.responseCode===400 && response.data.status===`Script ${values.name} with Webservice type already exists.`)  {
              formikDetails.setFieldError('name', `Name already exists`)
            }else{
              let scriptID=response.data.responseObject.id;
              props?.closeModal(false);
               navigate(`/project/testcases/testCase-Details?modeType="edit"&moduleId=${values.parentId}&scriptId=${scriptID}`,{replace: true,});
            }         
           
          }
          console.log("reponse 123", response);
        } catch (error) {
          console.log("error");
        }
      
        setCallingAPI(false)
        }else{
          console.log("edit test case",values)
          console.log("edit test case props",props)
          let payload={
            "name": values.name,
            "scriptType": props?.data?.scriptType ,
            "desc": values.summary,
             "executionOrder": props?.data?.executionOrder,
             "hierarchy": props?.data?.hierarchy,
             'path': props?.data?.path,
             "testCaseType" : props?.data.testCaseType,
             "parentId": props?.data?.parentId,
             "parentName": props?.data?.parentName,
             "assigneeCount":props?.data?.assigneeCount

    
          }
         
          if(values.name===props?.data?.name && values.summary===props?.data?.desc){
           
            props?.closeModal(false)
            props?.MyAlert.info(`No changes were done to the test script`)
          }else{
            if(hasDuplicate(values.name,props.data.id)){
              formikDetails.setFieldError('name', `Name already exists`)
            }else{
              try{
                let response=await editTestcase(payload,props?.data?.parentId,props?.data?.id,props.data.name,projectID,licenseId);
                if(response.data.responseCode===200){
                  const search = window.location.search;
                  const id = new URLSearchParams(search).get("id");
                  props?.setIsLoading(true);
                  props?.MyAlert.success(`${values.name} test script updated successfully`)
                  props.getTreeData(id);
                  props?.closeModal(false)
                  
                 
                }
        
              }catch(error){
                console.log("error")
              }
            }
           
            
          }
          setCallingAPI(false)
          
          
        }
    }
   
  };
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const selectingTree = (data) => {
    setSelectTreeMode(data);
  };
  const treeNodeSelected = (node) => {

    console.log("node123",node);
    setParentData(node[0])
    formikDetails.setFieldValue("parentName", node[0].data.name);
    // formikDetails.setFieldValue("parentId", node[0].node.key);
    formikDetails.setFieldValue("parentId", node[0].data.key);
  };
  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex justify-between create-modal-heading h-10 px-2 items-center rounded-tl-xl">
        <div>
          {selectTreeMode
            ? "Select Parent Module"
            : props.modeType === "create"
            ? "Create TestCase"
            : "Edit TestCase"}
        </div>
        <span className="flex items-center">
          <button
            onClick={() => {
              setOpenModal(false);
              props?.closeModal(false);
            }}
            type="button"
            className="modal-close-btn mr-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>
      <form onSubmit={formikDetails.handleSubmit}>
        {!selectTreeMode ? (
          <div className="px-2">
            <div className="my-4">
              <div className="mb-4">
                <Label
                  label={
                    <span className="login-labels popins-semi-bold">Name</span>
                  }
                  required={true}
                  error={
                    formikDetails.errors.name && formikDetails.touched.name
                  }
                />
              </div>
              <div className="popins-medium">
                <TextField
                  error={
                    formikDetails.errors.name && formikDetails.touched.name
                  }
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={props.modeType === "create"?false:props?.data?.assignee}
                  onClick={() => {}}
                  type="text"
                  name="name"
                  id="name"
                  inputProps={{
                    className: cx(
                      "!text-xs open-sans-regular boxedInput popins-medium"
                    ),
                    style: {
                      padding: "9.5px 14px",
                      height: "0.43em !important",
                    },
                  }}
                  autoComplete="off"
                  placeholder="Enter testcase name"
                  // disabled={editMode ? true : false}
                  onBlur={formikDetails.handleBlur}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.name}
                />
              </div>
              {formikDetails.errors.name && formikDetails.touched.name ? (
                <div className="text-red-500 error_text md:mt-1">
                  {formikDetails.errors.name}
                </div>
              ) : null}
            </div>
            <div className="my-5">
              <div className="mb-4">
                <Label
                  label={
                    <span className="login-labels popins-semi-bold">
                     Description
                    </span>
                  }
                />
              </div>
              <div>
               

                <TextareaAutosize
                  name="summary"
                  id="summary"
                  value={formikDetails.values.summary}
                  placeholder={summaryPlaceHolder}
                  onChange={formikDetails.handleChange}
                  style={{ height: "80px", resize: "none", overflow: "scroll" }}
                  onKeyUp={(e) => setdescCount(e.target.value.length)}
                  onBlur={formikDetails.handleBlur}
                  //  rowsMax={noOfRows}
                  maxLength="200"
                  className="project-description"
                ></TextareaAutosize>
                <div className="mt-1 mr-1 text-sm text-gray-500 text-right">
                  {descCount}/200
                </div>
              </div>
            </div>
            <div className="py-2 px-3 flex justify-end gap-4 user-create-button">
              <button
                onClick={() => {
                  setOpenModal(false);
                  props?.closeModal(false);
                }}
                type="button"
                className="w-24 gray-btn ml-3 button"
              >
                Cancel
              </button>
              <button
                type="submit"
                id="createBatch"
                onClick={() => {}}
                className="w-24 primary-btn button"
                disabled={callingAPI}
              >
                {props?.modeType === "edit" ? "Update" : "Create"}
              </button>
            </div>
          </div>
        ) : null}
        {
          <div className="px-2">
            {!selectTreeMode && (
              <div className="mb">
                <Label
                  label={
                    <span className="login-labels popins-semi-bold">
                      Parent Module
                    </span>
                  }
                  required={true}
                />
              </div>
            )}
            {props.modeType === "create" ? (
              <TreeWithRadioButton
                selectingTree={selectingTree}
                moduleSelection={true}
                operation={
                  props?.parentData
                    ? "edit"
                    : props?.moduleIdForAdd
                    ? "edit"
                    : null
                }
                moduleName={
                  props?.parentData?.node?.data?.name ? props?.parentData?.node?.data?.name : ""
                }
                hideRootRadioBtn={modal_ID?false:true}
                individualTree={
                  props?.moduleIdForAdd || props?.individualTreeId
                    ? true
                    : false
                }
                disableRootRadioButton={false}
                //   defaultDisabled={[{ key: "folder", value: true }]}
                data={treeData}
                hideElements={true}
                hideElementsBtn={true}
                placeholder={
                  props?.parentData?.node?.data?.name ? props?.parentData?.node?.data?.name : ""
                }
                // modal={modal_ID}
                modal={true}
                nodeSelected={treeNodeSelected.bind(this)}
                buttonLabel="Module"
              />
            ) 
            : (
              // <div className="select-testcase-parent-btn">
              //   {formikDetails.values.parentName}
              // </div>
              <div className="table-non-link-color-common font-style-common flex">
            
                <div
                  className={`ml-2 mr-3  flex items-center justify-center select-parent-btn w-40 cursor-pointer opacity-50`}
                >
                  <p className="datatrimmer" title={formikDetails.values.parentName}>
                    {formikDetails.values.parentName}
                  </p>
                </div>
            
      
              
            </div>
            )}
            
            {formikDetails.errors.parentName &&
            formikDetails.touched.parentName && !formikDetails.values.parentName ? (
              <div className="text-red-500 error_text md:mt-1">
                {formikDetails.errors.parentName}
              </div>
            ) : null}
          </div>
        }
      </form>
      {isLoading ? <CommonLoader /> : null}
    </Modal>
  );
}

export default CreatTeastCase;
