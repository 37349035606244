import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { TextareaAutosize, TextField } from "@mui/material";
import { Label } from "../../common/inputs/myInput";
import { getOptionLabel, getFeedbackQuestions } from "../common-functions";
import Rating from "@mui/material/Rating";

function FeedbackTemplateDetails(props) {
  
  console.log("props data defect template", props);
  const [openModal, setOpenModal] = useState(true);
  const [TemplateData, setTemplateData] = useState(
    JSON.parse(JSON.stringify(props?.data?.details))
  );

  const feedbackDetailDescElements = (data) => {
    switch (data.type) {
      case "text":
        return (
          <>
            <div className="">
              <TextareaAutosize
                disabled={true}
                title={data?.placeholder?.length > 30 ? data.placeholder : null}
                id="description"
                name="description"
                style={{ height: "60px" }}
                className="w-full border-2 rounded border-gray-300 pl-2"
                placeholder={
                  data?.placeholder?.length > 30
                    ? data?.placeholder?.substr(0, 30) + "..."
                    : data?.placeholder
                }
                value={data.defaultValue}
              />
            </div>
          </>
        );
      case "radioButton":
        return (
          <>
            <div className="">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div className="">
                    <input
                      type="radio"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="defect-template-btn-label">
                      {getOptionLabel(option)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      case "ratings":
        return (
          <>
            <div className="">
              <div className="grid grid-cols-2 gap-1">
                <Rating name="no-value" value={null} readOnly size="large" />
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <TextField disabled={true} className="w-60" />
          </>
        );
    }
  };

  const FeedbackDetailElements = (props) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <h6 className="defect-template-modal-values  mb-2 flex">
              {props.data.mandatory === "yes" ? (
                <span className="text-red-400 mr-1">&#42;</span>
              ) : null}

              {getFeedbackQuestions(props.data.label)}
            </h6>
          </div>
          <div className="">
            <div className="">{feedbackDetailDescElements(props.data)}</div>
          </div>
        </div>
      </>
    );
  };

  const feedbackDetailSection = () => {
    return (
      <>
        <div className="">
          <div className="">
            {TemplateData?.templateDetails?.map((data) => (
              <div className="my-5">{FeedbackDetailElements({ data })}</div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "82.5%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
      <div className="flex rounded-tl-xl justify-between defect-template-modal-heading h-10 p-2  cursor-default">
        {/* <div className="defect-template-name">Feedback Template Details-</div> */}
        <div className="flex ">
          <span>Feedback Template Details - </span>
          <span className="datatrimmer w-32" title={TemplateData?.templateName}>
            {TemplateData?.templateName}
          </span>
        </div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.closeModule(false);
          }}
          type="button"
          className="modal-close-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      <div className="main-content overflow-y-scroll " id="journal-scroll">
        <div className="pr-4">
          <div className="px-2">
            <div>
              {/* start   */}
              <div className="mb-5 mt-2 pb-7">
                <div className="mb-4">
                  <Label
                    label={
                      <>
                        <span className="text-red-400 mr-1">&#42;</span>{" "}
                        <span className="defect-template-modal-values ">
                          Template Name
                        </span>
                      </>
                    }
                  />
                  <TextField
                    name="templateName"
                    id="templateName"
                    disabled="true"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="off"
                    fullWidth
                    value={TemplateData?.templateName}
                    className="w-60"
                    type="text"
                  />
                </div>
                <div className="">
                  <span className="popins-semi-bold text-blue-600">
                  Questions
                  </span>
                </div>

                <div className="">{feedbackDetailSection()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-2 px-3 flex justify-end gap-4 user-create-button z-50">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModule(false);
            }}
            type="button"
            className="w-24 gray-btn ml-3 button"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}
export default FeedbackTemplateDetails;
