import { useEffect, useMemo, useState } from "react";
import Recaptcha from "react-recaptcha";
import PropTypes from "prop-types";
import cx from "classnames";
import { FormHelperText } from "@material-ui/core";
import {ENVIRONMENT_URLS} from "../common/env_Common_URL.js"
import { signInSiteKey } from  '../../api/api-services';
import {CAPTCH_TERMS} from '../../util/app-constants.js'

function RecaptchaDeclaration({
  handleVerifyCaptacha,
  wrapperClassName,
  handleCaptchaLoad,
  handleCaptchaExpiry,
  error,
}) {
  const grecaptchaObject = window.grecaptcha;
  const elementId = useMemo(() => `recaptcha-${Math.random()}`, []); // This will allow to have multiple recaptcha to render in one view
  const [siteKey, setSiteKey] = useState(null);
  function getHostname() {
    const urlData = window.location.href;
    if (urlData.includes("localhost")) {
      return ENVIRONMENT_URLS.LOCAL_HOST;
    }else if (urlData.includes("dev.testyantra")) {
      return ENVIRONMENT_URLS.DEV_URL;
    }else if (urlData.includes("test-firecrowd")) {
      return ENVIRONMENT_URLS.TEST_URL;
    }else if (urlData.includes("preprodcrowd.testyantra")) {
      return ENVIRONMENT_URLS.PRE_PROD_URL;
    }else if (urlData.includes("betafirecrowd")) {
      return ENVIRONMENT_URLS.DEV1_URL;
    }else if (urlData.includes("crowd.testyantra")) { 
      return ENVIRONMENT_URLS.PRODUCTION_URL;
    }
  
    return "";
  }

  useEffect(() => {
    try {
      window?.grecaptcha?.reset();
    } catch (error) {
      console.error("Failed to reset the Google ReCaptcha", error);
    }
  }, [elementId, siteKey]);

  useEffect(() => {
    const data = {
      hostname: getHostname(),
    };

    signInSiteKey(data)
      .then((res) => {
        const newSiteKey = res.data.responseObject.sitekey;
        localStorage.setItem("siteKeyValue", newSiteKey);
        setSiteKey(newSiteKey);
      })
      .catch((error) => {
        console.error("Failed to get Google ReCaptcha site key", error);
      });
  }, []);
  
  return siteKey ? (
    <div className={cx("my-1", wrapperClassName)}>
      <div className="" style={{fontFamily:"Poppins-Regular",fontSize:"12px",color:"#000000"}}>
        <span>
         {CAPTCH_TERMS?.substring(0,63)}
        </span>
        <br/>
        <span className="" style={{fontFamily:"Poppins-Regular",fontSize:"12px",color:"#3749C3"}}>
        {CAPTCH_TERMS?.substring(63)}
        </span>
      </div>
      <div className="mt-2">
        {elementId &&  (
            
           
          <Recaptcha
            elementID={elementId}
            sitekey={siteKey}
            render="explicit"
            verifyCallback={handleVerifyCaptacha}
            grecaptcha={grecaptchaObject}
            size="normal"
            onloadCallback={handleCaptchaLoad}
            expiredCallback={handleCaptchaExpiry}
          />
           
        )}
        <FormHelperText style={{fontFamily:"Poppins-Regular",fontSize:"12px",color:"#EA0322"}} error={!!error}>{error}</FormHelperText>
      </div>
    </div>
  ) : (
    <></>
  );
}

RecaptchaDeclaration.propTypes = {
  handleVerifyCaptacha: PropTypes.func,
  handleCaptchaLoad: PropTypes.func,
  handleCaptchaExpiry: PropTypes.func,
  wrapperClassName: PropTypes.string,
  error: PropTypes.string,
};

RecaptchaDeclaration.defaultProps = {
  handleVerifyCaptacha: () => {},
  handleCaptchaLoad: () => {},
  handleCaptchaExpiry: () => {},
  wrapperClassName: "",
  error: "",
};

export default RecaptchaDeclaration;
