import { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink} from "react-router-dom";
import "./page-left-menu.scss";
import {
  getAllProjectRequest,
  getUserPrivilege,
  getprojectList,
} from "../../api/api-services";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useLocation } from "react-router-dom";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import LiftOutlinedCircle from "../../assets/leftCircled.svg";



// import { ReactComponent as FireCrowdIcon } from "../../assets/Logo/fireflink.svg";
import { ReactComponent as FireCrowdIcon } from "../../assets/Logo/Fireflink_logo_new.svg";
import { ReactComponent as FireCrowdLogo } from "../../assets/fireCrowdLogo.svg";
import ArticleIcon from "@mui/icons-material/Article";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BugReportIcon from "@mui/icons-material/BugReport";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Divider from "@mui/material/Divider";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


import { ReactComponent as AllProjectsIcon } from "../../assets/left-menu-icons/project_menu.svg";

import { ReactComponent as Overview } from "../../assets/left-menu-icons/OverviewIcon.svg";


import { ReactComponent as TestCases } from "../../assets/left-menu-icons/testcase_icon.svg";

// import { ReactComponent as Report } from "../../assets/left-menu-icons/Report.svg";
import { ReactComponent as Report } from "../../assets/left-menu-icons/analytics_menu.svg";

import { ReactComponent as Config } from "../../assets/left-menu-icons/Config_icon.svg";

import { ReactComponent as ExpandIcon } from "../../assets/expand Icon.svg";
import { ReactComponent as ColpaseIcon } from "../../assets/Colapse icon.svg";
import { ReactComponent as CasesBox } from "../../assets/cases_box.svg";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer.js";
import { ReactComponent as DefectIcon } from "../../assets/left-menu-icons/Defect_icon.svg";

import {ReactComponent as UserIcon} from "../../assets/left-menu-icons/user_icon.svg"
import {ReactComponent as DashboardIcon} from "../../assets/left-menu-icons/dashboard_icon.svg"


import { Tooltip } from "@material-ui/core";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: "55px",
  width: "66px",
  borderRight: "none",
  [theme.breakpoints.up("sm")]: {
    // width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
function PageLeftMenu(props) {
  // const getAllProjects = JSON.parse(localStorage.getItem("getAllproject"));
  const [showSideMenu, setShowSideMenu] = useState(false);
  // const [projectList, setProjectList] = useState(getAllProjects);
  // const [projects, setProjects] = useState(getAllProjects);
  const [projectList, setProjectList] = useState([]);
  const [projects, setProjects] = useState([]);

  const [privilageApiResp, setPrivilageApiResp] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  // const tempDetails = new URLSearchParams(location.search).get('project_id');
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  let allProjects = [{ id: "", name: "All Projects" }];
  const [selectedProject, setSelectedProject] = useState({
    id: "",
    name: "All ProjectsB",
  });
  const [configMenu, setConfigMenu] = useState(false);
  const [inconfigMenu, setInconfigMenu] = useState(false);
  const [individualProject, setIndividualProject] = useState();
  const [configList, setconfigList] = useState([]);
  const [showConfig, setShowConfig] = useState(false);
  const { progress } = useContext(ProgressContainerContext);

  function getconfigList() {
    let role= localStorage.hasOwnProperty("user-privilege")
    ? JSON.parse(localStorage["user-privilege"]).role:""
    let List = [];
    if (privilageApiResp?.config?.userHierarchyTemplate?.access === true) {
      List.push({
        name: "User Hierarchy Template",
        path: "/project/configuration/user-Hierarchy-Template",
      });
    }
    if (privilageApiResp?.config?.platformDetails?.access === true) {
      List.push({
        name: "Platform Details",
        path: "/project/configuration/platform-Details",
      });
    }
    if (privilageApiResp?.config?.defectTemplate?.access === true) {
      List.push({
        name: "Defect Details Template",
        path: "/project/configuration/defect-Details-Template",
      });
    }
    if (privilageApiResp?.config?.defectTemplate?.access === true) {
      List.push({
        name: "Manual TestCase Template",
        path: "/project/configuration/manual-TestCase-Template",
      });
    }
    if( role==="PROJECT_OWNER" || role==="ADMIN"){
      List.push({
        name: "Integration",
        path: "/project/configuration/integration",
      });
    }
    if(role!=="PROJECT_OWNER"){
      List.push({
        name: "Feedback Template",
        path: "/project/configuration/feedback-Template",
      });
    //   List.push({
    //   name: "NDA Form",
    //   path: "/project/configuration/NDA-form",
    // });
      
    }
   
    

    setconfigList(List);
  }

  useEffect(() => {
    getconfigList();
  }, [privilageApiResp]);

  const initSelectedProject = () => {
    const project = localStorage.getItem("selected-projectId-Name");

    if (!project) {
      //  localStorage.setItem("selected-project", JSON.stringify(allProjects[0]));
      localStorage.setItem(
        "selected-projectId-Name",
        JSON.stringify(allProjects[0])
      );
    } else {
      setSelectedProject(JSON.parse(project));
    }
  };

  const handleDrawerClose = () => {
    setOpen(!open);
    props.leftMenuExpand(!open);
  };

  useEffect(() => {
    if (location.pathname.includes("/project/configuration")) {
      setInconfigMenu(true);

    } 
    else {
      if (configMenu) {
        setConfigMenu(false);
      }
      setInconfigMenu(false);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   // getAllproject();
  //   // setProjects(getAllProjects);
  //   // setProjectList(getAllProjects);
  //   initSelectedProject();

  // }, [showSideMenu]);
  // const myProjects = JSON.parse(localStorage.getItem("selected-project"));

  const myProjects = JSON.parse(
    localStorage.getItem("selected-projectId-Name")
  );

 

  // const getAllproject = async (e) => {
  //   let mailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
  //   try {
  //     const response = await getAllProjectRequest(mailId);
  //     if (response.status === 200) {
  //       console.log(response);
  //       setProjectList(response.data);
  //       setProjects(response.data);
  //     }
  //   } catch (error) {
  //     console.log("error");
  //   }
  // };
  // if click outside of dropdown also it will close
  let menuRef = useRef();
  useEffect(() => {
    let handeler = (event) => {
      if (menuRef.current && !menuRef?.current?.contains(event.target)) {
        setShowSideMenu(false);
        setConfigMenu(false);
        // setInconfigMenu(false);
        setShowConfigSubMenu(false);
      }
    };
    document.addEventListener("mousedown", handeler);
    return () => {
      document.removeEventListener("mouseleave", handeler);
    };
  }, []);
  useEffect(() => {
    setPrivilageApiResp(
      localStorage.hasOwnProperty("user-privilege")
        ? JSON.parse(localStorage["user-privilege"])
        : {}
    );
  }, [localStorage["user-privilege"]]);
  function filterProjectNames(e) {
    let searchValue = e.target.value;
    if (searchValue) {
      let tempSimilarArray = [];
      projects.map((proVal, index) => {
        let name = proVal.projectName;

        let similarExist = name.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );

        if (similarExist) {
          tempSimilarArray.push(proVal);
          setProjectList(tempSimilarArray);
        } else {
          setProjectList(tempSimilarArray);
        }
      });
    } else {
      setProjectList(projects);
    }
  }

  const getAllProjectList = async () => {
    let email = JSON.parse(localStorage["fire-crowd-user"]).userName;
    try {
      let response = await getprojectList(email);
      if (response.status === 200) {
        setProjectList(response.data);
        setProjects(response.data);
        setShowSideMenu(!showSideMenu);
      }
    } catch (error) {
      console.log("error");
    }
  };
  function detailsOfProject(tempdetails) {
    console.log("detailsOfProject", tempdetails);
    localStorage.setItem(
      "selected-projectId-Name",
      JSON.stringify(tempdetails)
    );
    //  localStorage.setItem("selected-project", JSON.stringify(tempdetails));
    navigate("/project/overview", { replace: true });
  }
  const getprivilege = async (e) => {
    console.log("getprivillage", e);
    let role;
    if (
      localStorage.hasOwnProperty("my-role") &&
      JSON.parse(localStorage["my-role"]) === "admin"
    ) {
      role = "ADMIN";
    } else {
      // e?.project.users.userList.map((user) => {
      //   if (
      //     user.email === JSON.parse(localStorage["fire-crowd-user"]).userName
      //   ) {
      //     localStorage.setItem("selected-user", JSON.stringify(user));
      //     role = user.role;
      //   }
      // });
      role = e.role;
    }

    console.log("user role", role);
    try {
      let response = await getUserPrivilege(role);
      if (response.status === 200) {
        localStorage.setItem("user-privilege", JSON.stringify(response.data));
        setPrivilageApiResp(response.data);
      }
    } catch {
      console.log("error");
    }
  };
  const [showConfigSubMenu, setShowConfigSubMenu] = useState(false);

  const handleMouseEnterConfig_sub = () => {
    setShowConfigSubMenu(true);
  };

  const handleMouseLeaveConfig_sub = () => {
    setShowConfigSubMenu(false);
  };
  return (
    <div ref={menuRef}>
      {showSideMenu && (
        <div>
          {!open ? (
            <>
              <div className=" dropdownList2 ">
                <ul className="left_navigation project-dropdown">
                  {projectList && projectList?.length >= 0 && (
                    <div>
                      <input
                        onChange={(e) => filterProjectNames(e)}
                        className="project-row-hover cursor-hand project-search-input-filed-style input-field-font-style-head input"
                        id="serachInputField"
                        autoComplete="off"
                        placeholder="Search project"
                        autoFocus={false}
                      />
                      {/* <SearchIcon className="searchedIcon" /> */}
                      <svg
                        className="searchedIcon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.29"
                        height="20.29"
                        viewBox="0 0 20.29 20.29"
                        class="searchedIcon"
                      >
                        <g
                          id="Icon_feather-search"
                          data-name="Icon feather-search"
                          transform="translate(-3.5 -3.5)"
                        >
                          <path
                            id="Path_4"
                            data-name="Path 4"
                            d="M20.39,12.445A7.945,7.945,0,1,1,12.445,4.5,7.945,7.945,0,0,1,20.39,12.445Z"
                            fill="none"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          ></path>
                          <path
                            id="Path_5"
                            data-name="Path 5"
                            d="M29.3,29.3l-4.32-4.32"
                            transform="translate(-6.919 -6.919)"
                            fill="none"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  )}
                  <div
                    id="journal-scroll"
                    className="overflow-x-hidden overflow-y-scroll cursor-pointer h-56"
                  >
                    <p
                      onClick={() => {
                        localStorage.setItem(
                          "selected-projectId-Name",
                          JSON.stringify(allProjects[0])
                        );
                        setShowSideMenu(false);
                        // navigate("/project/list");
                        navigate("/all-Projects");
                      }}
                      className="py-2 pl-7 ml-0.5 text-white hover:bg-white hover:text-blue-700"
                    >
                      {!projectList?.length ? (
                        <div>- No Projects -</div>
                      ) : (
                        "All Projects"
                      )}
                    </p>
                    {projectList &&
                      projectList?.map((allProject) => {
                        return (
                          <div className=" ">
                            <li
                              onClick={() => {
                                detailsOfProject(allProject);
                                setIndividualProject(allProject);
                                setSelectedProject(allProject);
                                getprivilege(allProject);

                                setShowSideMenu(false);
                              }}
                              className="py-2 ml-0.5 text-white hover:bg-white hover:text-blue-700"
                            >
                              <div
                                className="pl-4 ml-4 projectname-trimmer"
                                title={allProject?.projectName}
                              >
                                {allProject?.projectName}
                              </div>
                            </li>
                          </div>
                        );
                      })}
                  </div>
                </ul>
              </div>
            </>
          ) : (
            <>
              <div className=" dropdownList ">
                <ul className="left_navigation project-dropdown">
                  {projectList && projectList?.length >= 0 && (
                    <div>
                      <input
                        onChange={(e) => filterProjectNames(e)}
                        className="project-row-hover cursor-hand project-search-input-filed-style input-field-font-style-head input"
                        id="serachInputField"
                        autoComplete="off"
                        placeholder="Search project"
                        autoFocus={false}
                      />
                      {/* <SearchIcon className="searchedIcon" /> */}
                      <svg
                        className="searchedIcon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.29"
                        height="20.29"
                        viewBox="0 0 20.29 20.29"
                        class="searchedIcon"
                      >
                        <g
                          id="Icon_feather-search"
                          data-name="Icon feather-search"
                          transform="translate(-3.5 -3.5)"
                        >
                          <path
                            id="Path_4"
                            data-name="Path 4"
                            d="M20.39,12.445A7.945,7.945,0,1,1,12.445,4.5,7.945,7.945,0,0,1,20.39,12.445Z"
                            fill="none"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          ></path>
                          <path
                            id="Path_5"
                            data-name="Path 5"
                            d="M29.3,29.3l-4.32-4.32"
                            transform="translate(-6.919 -6.919)"
                            fill="none"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  )}
                  <div
                    id="journal-scroll"
                    className="overflow-x-hidden overflow-y-scroll cursor-pointer h-56"
                  >
                    <p
                      onClick={() => {
                        localStorage.setItem(
                          "selected-projectId-Name",
                          JSON.stringify(allProjects[0])
                        );
                        setShowSideMenu(false);
                        // navigate("/project/list");
                        navigate("/all-Projects");
                      }}
                      className="py-2 pl-7 ml-0.5 text-white project-dropdown hover:bg-white hover:text-blue-700"
                    >
                      {!projectList?.length ? (
                        <div>- No Projects -</div>
                      ) : (
                        "All Projects"
                      )}
                    </p>
                    {projectList &&
                      projectList?.map((allProject) => {
                        return (
                          <div className=" ">
                            <li
                              onClick={() => {
                                detailsOfProject(allProject);
                                setIndividualProject(allProject);
                                setSelectedProject(allProject);
                                setShowSideMenu(false);
                                getprivilege(allProject);
                              }}
                              className="py-2 pl-1 ml-0.5 text-white hover:bg-white hover:text-blue-700 "
                            >
                              <div
                                className="pl-2  ml-4 projectname-trimmer"
                                title={allProject?.projectName}
                              >
                                {allProject?.projectName}
                              </div>
                            </li>
                          </div>
                        );
                      })}
                  </div>
                </ul>
              </div>
            </>
          )}
        </div>
      )}

      <Box sx={{ display: "flex" }}>
        <Drawer variant="permanent" open={open}>
          <List>
            <div className="left-menu">
              {theme.direction === "!rtl" ? (
                <ChevronRightIcon />
              ) : (
                <>
                  <div className="logo-background h-14" >
                    {open ? (
                      <>
                        <div className="fireflink_crowd_logo">
                          <FireCrowdIcon onClick={()=>{localStorage.setItem(
                          "selected-projectId-Name",
                          JSON.stringify(allProjects[0])
                        );
                        setShowSideMenu(false);
                       
                        navigate("/all-Projects");}}/>
                          
                        </div>
                        <div className="liftCircle">
                          <ColpaseIcon
                            className="cursor-pointer"
                            onClick={handleDrawerClose}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="fire_crowd_logo">
                          <FireCrowdLogo onClick={()=>{localStorage.setItem(
                          "selected-projectId-Name",
                          JSON.stringify(allProjects[0])
                        );
                        setShowSideMenu(false);
                       
                        navigate("/all-Projects");}}/>
                        </div>

                        <div className="collapsedCircle">
                          <ExpandIcon
                            onClick={handleDrawerClose}
                            className="cursor-pointer"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              <div
                className={`cursor-pointer flex allProject focus:bg-slate-50 ${open?"expand_menu":"collapse_menu"}`}
                onClick={() => {
                  setConfigMenu(false);

                  if (!showSideMenu) {
                    getAllProjectList();
                  } else {
                    setShowSideMenu(!showSideMenu);
                  }
                }}
              >
                <Tooltip
                  title={
                    myProjects?.id === "" || myProjects?.id === undefined
                      ? "All Projects"
                      : myProjects?.projectName
                  }
                >
                  <div className="flex" >
                    <CasesBox className="mx-3" />
                    {
                      open?"":(
                        <div>
                        {showSideMenu ? (
                      <KeyboardArrowDownIcon className="collapsed_ChevronIcon" />
                    ) : (
                      <ChevronRightOutlinedIcon className="collapsed_ChevronIcon" />
                    )}
                        </div>
                      )
                    }
                   
                    {open ? (
                      <div className="project_name datatrimmer w-24">
                        {myProjects?.id === "" || myProjects?.id === undefined
                          ? "All Projects"
                          : myProjects?.projectName}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Tooltip>
                {showSideMenu ? (
                  <KeyboardArrowDownIcon className="ChevronIcon" />
                ) : (
                  <ChevronRightOutlinedIcon className="ChevronIcon" />
                )}
              </div>
              {myProjects?.id === "" || myProjects?.id === undefined ? (
                <>
                  <div className={`flex allProjectDrawer ${(localStorage.hasOwnProperty("my-role") &&
      JSON.parse(localStorage["my-role"]) === "admin")?"block":"hidden"}`}>
                <NavLink
                        
                        className={({ isActive }) =>
                          isActive && !configMenu && open
                            ? "active-drawer-close left-menu-items-select -ml-1 focus:outline-none"
                            : isActive && !configMenu && !open
                            ? "text-blue-700 -ml-1 active-drawer-close2 focus:outline-none"
                            : open
                            ? "flex  left-menu-items-unselect focus:outline-none"
                            : "flex  left-menu-unselect-items focus:outline-none"
                        }
                        style={{ pointerEvents: progress === 0 ? "" : "none" }}
                        to="/dashboard"
                      >
                       
                        <Tooltip title={open?"":"Dashboard"}>
                      <div className={`flex dashboard_icon  pt-1 pl-0.5 ${open?"":"w-11"} `} >
                        <DashboardIcon className="icons" />
                        <span className="ml-4">{open ? "Dashboard" : ""}</span>
                      </div>
                    </Tooltip>
                      </NavLink>
                 
                 
                </div>
                <div className="flex allProjectDrawer">
                  <NavLink
                    
                    className={({ isActive }) =>
                          isActive && !configMenu && open
                            ? "active-drawer-close left-menu-items-select -ml-1 focus:outline-none"
                            : isActive && !configMenu && !open
                            ? "text-blue-700 -ml-1 active-drawer-close2 focus:outline-none"
                            : open
                            ? "flex  left-menu-items-unselect focus:outline-none"
                            : "flex  left-menu-unselect-items focus:outline-none"
                        }
                   
                    to="/all-Projects"
                    style={{ pointerEvents: progress === 0 ? "" : "none" }}
                  >
                    <Tooltip title={open?"":"Projects"}>
                      <div className={`flex  all_project_icon pt-1 pl-0.5 ${open?"":"w-11"}`}>
                        <AllProjectsIcon className="icons"/>
                        {/* <Overview className="icons" /> */}
                       
                        <span className="ml-4">{open ? "Projects" : ""}</span>
                      </div>
                    </Tooltip>
                  </NavLink>
                 
                </div>
                {/* <div className="flex allProjectDrawer">
                <NavLink
                        className={({ isActive }) =>
                          isActive && !configMenu && open
                            ? "active-drawer-close left-menu-items-select -ml-1 focus:outline-none"
                            : isActive && !configMenu && !open
                            ? "text-blue-700 -ml-1 active-drawer-close2 focus:outline-none"
                            : open
                            ? "flex  left-menu-items-unselect focus:outline-none"
                            : "flex  left-menu-unselect-items focus:outline-none"
                        }
                        style={{ pointerEvents: progress === 0 ? "" : "none" }}
                        to="/video-Tutorials"
                      >
                        <Tooltip title={open?"":"Video Tutorials"}>
                          <div className="flex -ml-1 Defect_icon pl-0.5">
                         
                            <DefectIcon/>
                           
                            <span className=" mt-1 ml-2.5">
                              {open ? "Video Tutorials" : ""}
                            </span>
                          </div>
                        </Tooltip>
                      </NavLink>
                 
                </div> */}
              
                </>
                
              ) : (
                <>
                  <div
                    onClick={() => {
                      if (
                        configMenu &&
                        !location.pathname.includes("/project/configuration")
                      ) {
                        setConfigMenu(false);
                      }
                      setShowSideMenu(false);
                      setInconfigMenu(false);
                    }}
                    className="list -mr-2 "
                  >
                    <div
                      className={
                        privilageApiResp?.overview?.access === true
                          ? "flex"
                          : "flex hidden "
                      }
                    >
                      <NavLink
                        className={({ isActive }) =>
                          isActive && !configMenu && open
                            ? "active-drawer-close left-menu-items-select -ml-1 focus:outline-none"
                            : isActive && !configMenu && !open
                            ? "text-blue-700 -ml-1 active-drawer-close2 focus:outline-none"
                            : open
                            ? "flex  left-menu-items-unselect focus:outline-none"
                            : "flex  left-menu-unselect-items focus:outline-none"
                        }
                        style={{ pointerEvents: progress === 0 ? "" : "none" }}
                        to="/project/overview"
                      >
                        <Tooltip title={open?"":"Overview"}>
                          <div className="flex overview_icon pt-1 pl-0.5">
                            <Overview className="icons" />
                            <span className="ml-3.5 -mt-0.5">
                              {open ? "Overview" : ""}
                            </span>
                          </div>
                        </Tooltip>
                      </NavLink>
                    </div>

                    <div
                      className={
                        privilageApiResp?.user?.access === true
                          ? "flex "
                          : "flex hidden "
                      }
                    >
                      <NavLink
                        className={({ isActive }) =>
                          isActive && !configMenu && open
                            ? "active-drawer-close left-menu-items-select -ml-1 focus:outline-none"
                            : isActive && !configMenu && !open
                            ? "text-blue-700 -ml-1 active-drawer-close2 focus:outline-none"
                            : open
                            ? "flex  left-menu-items-unselect focus:outline-none"
                            : "flex  left-menu-unselect-items focus:outline-none"
                        }
                        style={{ pointerEvents: progress === 0 ? "" : "none" }}
                        to="/project/users"
                      >
                        <Tooltip title={open?"":"Users"}>
                          <div className="flex user_icon pl-0.5">
                            {/* <PeopleAltIcon /> */}
                            <UserIcon/>
                            <div className="ml-4 mt-1">
                              {open ? "Users" : ""}
                            </div>
                          </div>
                        </Tooltip>
                      </NavLink>
                    </div>
                    <div
                      className={
                        privilageApiResp?.testcases?.access === true
                          ? "flex "
                          : "flex hidden "
                      }
                    >
                      <NavLink
                        className={({ isActive }) =>
                          isActive && !configMenu && open
                            ? "active-drawer-close left-menu-items-select -ml-1 focus:outline-none"
                            : isActive && !configMenu && !open
                            ? "text-blue-700 -ml-1 active-drawer-close2 focus:outline-none"
                            : open
                            ? "flex  left-menu-items-unselect focus:outline-none"
                            : "flex  left-menu-unselect-items focus:outline-none"
                        }
                        style={{ pointerEvents: progress === 0 ? "" : "none" }}
                        to="/project/testcases"
                      >
                        <Tooltip title={open?"":"TestCases"}>
                          <div className="flex testcase_icon">
                            <TestCases />
                            <span className="ml-3.5 mt-1">
                              {open ? "TestCases" : ""}
                            </span>
                          </div>
                        </Tooltip>
                      </NavLink>
                    </div>

                    <div
                      className={
                        privilageApiResp?.defect?.access === true
                          ? "flex "
                          : "flex hidden "
                      }
                    >
                      <NavLink
                        className={({ isActive }) =>
                          isActive && !configMenu && open
                            ? "active-drawer-close left-menu-items-select -ml-1 focus:outline-none"
                            : isActive && !configMenu && !open
                            ? "text-blue-700 -ml-1 active-drawer-close2 focus:outline-none"
                            : open
                            ? "flex  left-menu-items-unselect focus:outline-none"
                            : "flex  left-menu-unselect-items focus:outline-none"
                        }
                        style={{ pointerEvents: progress === 0 ? "" : "none" }}
                        to="/project/defects"
                      >
                        <Tooltip title={open?"":"Defects"}>
                          <div className="flex -ml-1 Defect_icon pl-0.5">
                         
                            <DefectIcon/>
                           
                            <span className=" mt-1 ml-2.5">
                              {open ? "Defects" : ""}
                            </span>
                          </div>
                        </Tooltip>
                      </NavLink>
                    </div>
                    <div
                      className={
                        privilageApiResp?.reports?.access === true
                          ? "flex "
                          : "flex hidden "
                      }
                    >
                      <NavLink
                        className={({ isActive }) =>
                          isActive && !configMenu && open
                            ? "active-drawer-close left-menu-items-select -ml-1 focus:outline-none "
                            : isActive && !configMenu && !open
                            ? "text-blue-700 -ml-1 active-drawer-close2 focus:outline-none"
                            : open
                            ? "flex  left-menu-items-unselect focus:outline-none"
                            : "flex  left-menu-unselect-items focus:outline-none"
                        }
                        style={{ pointerEvents: progress === 0 ? "" : "none" }}
                        to="/project/analytics"
                      >
                        <Tooltip title={open?"":"Analytics"}>
                          <div className="flex report_icon pl-0.5">
                            <Report />
                            <span className="ml-4 mt-1">{open ? "Analytics" : ""}</span>
                          </div>
                        </Tooltip>
                      </NavLink>
                    </div>
                    <div
                      className={
                        privilageApiResp?.config?.access === true
                          ? "flex "
                          : "flex hidden "
                      }
                    >
                      <div class="fixed  dropdownList3 text-left">
                        <Tooltip  title={open?"":"Configuration"}>
                          <button
                            type="button"
                            className={
                              (configMenu || inconfigMenu) && open
                                ? "active-drawer-close left-menu-items-select -ml-1"
                                : (configMenu || inconfigMenu) && !open
                                ? "text-blue-700 -ml-1 active-drawer-close2 "
                                : `${
                                    !open
                                      ? "configMenuMinimized"
                                      : "dropdownList3"
                                  } snap-proximity flex  left-menu-items-unselect `
                            }
                            id="menu-button"
                            aria-expanded="true"
                            aria-haspopup="true"
                            onClick={() => {
                              setShowSideMenu(false);
                              setConfigMenu(true);
                            }}
                          >
                            <div className="config_icon pl-1">
                              <Config />
                            </div>
                            
                            <div>
                            <div
                              className={`${
                                (configMenu || inconfigMenu) && open
                                  ? "config_text"
                                  : "ml-3.5 config_label"
                              }`}
                            >
                              {open ? "Configuration" : ""}
                             
                               
                            </div>
                            {/* <div>
                              {">"}
                            </div> */}

                            </div>
                           
                            
                           
                           
                            
                          
                              
                           
                          </button>
                        </Tooltip>
                        {configMenu && (
                          <div
                            className={`${
                              !open ? "-right-16" : "-right-52"
                            } dropdownList4 relative rounded-md z-10 -mt-3 w-56 origin-top-right`}
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                            tabindex="-1"
                          >
                            <div className="left_navigation project-dropdown">
                              <div
                                id="journal-scroll"
                                className={`overflow-x-hidden overflow-y-scroll configSubMenu cursor-pointer py-1 ${open?"":"ml-2.5"}`}
                                role="none"
                              >
                                {configList.map((section) => {
                                  return (
                                    <div
                                      className=" "
                                      onMouseEnter={handleMouseLeaveConfig_sub}
                                    >
                                      <NavLink
                                        className={({ isActive }) =>
                                          isActive && !configMenu && open
                                            ? "projectname-trimmer"
                                            : isActive && !configMenu && !open
                                            ? "projectname-trimmer"
                                            : "projectname-trimmer"
                                        }
                                        title={section.name}
                                        to={section.path}
                                      >
                                        <div
                                          onMouseEnter={
                                            handleMouseLeaveConfig_sub
                                          }
                                          onClick={() => {
                                          
                                              setConfigMenu(false);
                                          
                                           
                                          }}
                                          className="my-2 py-2 pl-1 ml-0.5 text-white hover:bg-white hover:text-blue-700 "
                                        >
                                          <span
                                            className="ml-2"
                                            onMouseEnter={
                                              handleMouseLeaveConfig_sub
                                            }
                                          >
                                            {section.name}
                                          </span>
                                        </div>
                                      </NavLink>
                                    </div>
                                  );
                                })}
                                {privilageApiResp?.config?.defectTemplate
                                  ?.access === true &&
                                  JSON.parse(
                                    localStorage.getItem("user-privilege")
                                  ).role === "ACCOUNT_MANAGER" && (
                                    // <p onMouseEnter={handleMouseEnterConfig_sub} onClick={handleMouseLeaveConfig_sub} className="my-2  py-2 pl-4 ml-0.5 text-white hover:bg-white hover:text-blue-700 ">Import/Export</p>
                                    <p
                                      onClick={() => {
                                        navigate(
                                          "/project/configuration/import-Manual-TestCase"
                                        );
                                        handleMouseLeaveConfig_sub();
                                        setConfigMenu(false);
                                        
                                      }}
                                      className="my-2  py-2 pl-3 ml-0.5 text-white hover:bg-white hover:text-blue-700 "
                                    >
                                     Import Manual TestCase 
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* {
              
                  <div className={`fireflink_rights ${open?"all_label":"logo_label"}`}>{open?"© TestYantra all rights reserved":"© TestYantra"}</div>
               
              } */}
             
            </div>
            
          </List>
         
          <Divider />
        </Drawer>
      </Box>
     
    </div>
  );
}

export default PageLeftMenu;
