import React, { props } from "react";
import Chart from "react-apexcharts";

function ExecutionModuleChart(props) {
  console.log("props", props);
  function getlabels(data) {
    return data.map((label, index) => {
      return (
        <div className="flex">
          <div
            style={{ background: props?.colors[index] }}
            className="label_dot"
          ></div>
          <div>
            {props.header === "module" ? (
              <div className="label_text datatrimmer w-24">
                {label.split(/[/,:]/)[label.split(/[/,:]/).length - 1]}{" "}
              </div>
            ) : (
              <div className="label_text datatrimmer w-24">{label} </div>
            )}
          </div>

          <div className="label_text">- {props?.seriesdata[index]}</div>
        </div>
      );
    });
  }
  return (
    <>
      <Chart
        type="donut"
        // width={490}
        height={250}
        series={props.seriesdata}
        options={{
          chart: {
            type: "donut",
            width: 250,
            events: {
              dataPointSelection: function (event, chartContext, config) {
                props.selectedChartlabelData(props.header, [
                  props.labels[config.dataPointIndex],
                ]);
              },
            },
          },
          labels: props?.labels,
          colors: props?.colors,
          theme: {
            monochrome: {
              enabled: false,
            },
          },
          plotOptions: {
            pie: {
              expandOnClick: true,
              donut: {
                size: "80%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "22px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 600,
                    color: "#6F7075",
                    offsetY: -10,
                    label: "Total Defects",
                    formatter: function (val) {
                      if (props.header === "module") {
                        if (val === "Total Defects") {
                          return val;
                        } else {
                          return `${
                            val.split(/[/,:]/)[val.split(/[/,:]/).length - 1]
                          }`;
                        }
                      } else {
                        return val;
                      }
                    },
                  },

                  total: {
                    //  showAlways: true,
                    show: true,
                    fontSize: "12px",
                    color: "#333333",
                    fontFamily: "Poppins-Regular",
                    label: "Total Testcases",
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return JSON.parse(localStorage["total-testcase"]);
                      }, JSON.parse(localStorage["total-testcase"]));
                    },
                  },
                  value: {
                    color: "#282828",
                    fontSize: "24px",
                    fontFamily: "Poppins-SemiBold",
                    fontWeight: "10",
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            // width:100,
            show: false,
            position: "bottom",
            fontSize: "10px",
            fontFamily: "Poppins-Regular",
            onItemClick: {
              toggleDataSeries: false,
            },
            formatter: function (val, opts) {
              if (props.header === "module") {
                return `${
                  val.split(/[/,:]/)[val.split(/[/,:]/).length - 1] +
                  " - " +
                  opts.w.globals.series[opts.seriesIndex]
                }`;
              } else {
                return val + " - " + opts.w.globals.series[opts.seriesIndex];
              }
            },
          },
        }}
      />
      <div className="chart-labels-container">{getlabels(props?.labels)}</div>
    </>
  );
}

export default ExecutionModuleChart;
