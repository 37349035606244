import React, { useState, useEffect, Fragment } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import BackgroundDown from "../../assets/login_logo.png";
import style from "./signup.module.scss";
import cx from "classnames";
import { changePasswordRequest ,validateUserToken} from "../../api/api-services";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import LabeledPasswordInput from "../common/inputs/passwordInputs";
import KeyboardBackspaceSharpIcon from '@mui/icons-material/KeyboardBackspaceSharp';
import { getEncryptData } from "../../util/common-utils";


const SET_PASSWORD_LEFT_HEADING = "Reset Password Quickly";
const SET_PASSWORD_LEFT_MSG_ONE = "Forgot your password, Don't Worry";
const SET_PASSWORD_LEFT_MSG_TWO =
  "Retrieve your password quickly just following some quick steps";

const SET_PASSWORD_RIGHT_HEADING = "Set Password";
const SET_PASSWORD_RIGHT_MSG = "Set your account password below";

function SetPassword() {
  const forgotPasswordSchema = Yup.object({
    password: Yup.string()
      .matches(/^\S*$/, `Weak password, please match the password criteria`)

      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,20}$",
        `Weak password, please match the password criteria`
      )
      .required("New password is required"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .when("password", {
        is: (val) => val,
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Confirm password not matched with new password"
        ),
      }),
  });
  const STRONG_PASSWORD_PATTERNS = [
    {
      pattern: /^.{8,20}$/,
      message:
        "Should contains at least 8 characters and at most 20 characters",
    },
    {
      pattern: /.*[0-9].*/,
      message: "Should contains at least one digit",
    },
    {
      pattern: /.*[A-Z].*/,
      message: "Should contains at least one upper case alphabet",
    },
    {
      pattern: /.*[a-z].*/,
      message: "Should contains at least one lower case alphabet",
    },
    {
      pattern: /.*[!@#$%&*()+=^].*/,
      message:
        "Should contains at least one special character which includes !@#$%&*()+=^",
    },
    {
      pattern: /^\S*$/,
      message: "Should doesn't contain any white space",
    },
  ];

  const [data, setData] = useState({ emailId: "", token: "" });
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [activationStatus, setActivationStatus] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [tokenRef, setTokenRef] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      emailId: "",
      password: "",
      confirmPassword: "",
      passwordToken: "",
    },
    validationSchema: forgotPasswordSchema,

    onSubmit: (values) => {
      if (createUpdateCalled) {
        setCreateUpdateCalled(false);
        const payload = {
          emailId: data.emailId,
          password: getEncryptData(values.password),
          passwordToken: data.token,
          source: "FireCrowd",
        };
        if (data.emailId && data.token) {
          changePasswordRequest(payload)
            .then((res) => {
              const response = res.data;
              if (response.status === "SUCCESS") {
                // history.push("/set-password-successfully");
                navigate("/set-password-successfully", { replace: true });
              } else if (response.message === "Token is invalid/Expired"){
                // redirectTo(data.emailId);
                alert("Token is invalid/Expired")
               
              }
            })
            .catch((error) => {});
        }
      }
    },
  });

  function redirectTo(emailId) {
    // navigate(
    //   `/token-expired?emailId=${emailId}&activationStatus=${activationStatus}`
    // );
  }

  useEffect(() => {
    const search = window.location.search;
    const token = new URLSearchParams(search).get("token");
    const emailId = new URLSearchParams(search).get("emailId");
    // setData({ emailId, token });
    const activationStatus = new URLSearchParams(search).get(
      "activationStatus"
    );
    setActivationStatus(activationStatus);
    setData({emailId,token });
    verifyToken(null, emailId, token);
  }, []);
  useEffect(() => {
    if (tokenRef) {
      const search = window.location.search;
      const emailId = new URLSearchParams(search).get("emailId");
      !validToken && redirectTo(emailId);
    }
  }, [tokenRef]);
  function verifyToken(id, emailId, token) {
    let payload = {
      id: id,
      emailId: emailId,
      passwordToken: token,
      fromPage: "change-password",
      source:"FireCrowd"
    };
    validateUserToken(payload)
      .then(result => {
        if (result.data && result.data.responseCode === 200) {
          setValidToken(true);
        }
        setTokenRef(true);
      })
      .catch(error => {
        setTokenRef(true);
        console.error("Verify Token Failed!", error);
      });
  }

  
  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 min-h-screen">
        <div className={cx(style["password_activation_link"], "col-span-6 border-r-4")}>
          <div
            className={cx(style["password_activation_link_leftImage"],"grid grid-cols-span-12 col-span-6 flex justify-center")}
            id="leftImage"
          >
            <div className={cx(style["leftImage"])}>
              <img
               className={cx(style["logo_Icon"])}
                src={LogotextImage}
                height="200px"
                width="300px"
                alt="Qspider"
              />
            </div>
          </div>
          <div className="grid grid-cols-span-12 col-span-6 flex justify-center mt-5">
            <div>
              <img
                height="350px"
                width="350px"
                src={BackgroundDown}
                alt="Firecrowd"
              />
            </div>
          </div>
          <div
            className={cx(
              style["heading"],
              "grid grid-cols-span-12 col-span-6 flex justify-center mt-5"
            )}
          >
            <div>{SET_PASSWORD_LEFT_HEADING}</div>
          </div>
          <div
            className={cx(
              style["msg"],
              "grid grid-cols-span-12 col-span-6 flex justify-center mt-2"
            )}
          >
            <span>{SET_PASSWORD_LEFT_MSG_ONE}</span>
          </div>
          <div
            className={cx(
              style["msg"],
              "grid grid-cols-span-12 col-span-6 flex justify-center mt-1"
            )}
          >
            <span>{SET_PASSWORD_LEFT_MSG_TWO}</span>
          </div>
        </div>
        <div className={cx(style["password_activation_page"], "col-span-6 pt-28")}>
        <div className={cx(style["logotextImage"])}>
              <img
                src={LogotextImage}
                height="200px"
                width="300px"
                alt="Qspider"
              />
            </div>
          <div
            className=
              // style["heading"],
              "grid grid-cols-span-12 col-span-6 flex justify-center mt-4"
            
          >
            <div
              className={cx(
                style["heading"],
                "grid grid-cols-span-12 col-span-12"
              )}
            >
              {SET_PASSWORD_RIGHT_HEADING}
            </div>
            <div
              className={cx(style["msg"], "grid grid-cols-span-12 col-span-8")}
            >
              <span>{SET_PASSWORD_RIGHT_MSG}</span>
              <div
                //  class="warning-division warnDivSetP  mt-6 "
                className={cx(style["warning-division"], "mt-6")}
              >
                <div className="">
                  <div>
                    {formik.errors.password && formik.touched.password ? (
                      <>
                        {" "}
                        <span className="text-red-600">*</span>
                        <span> New Password </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span className="text-red-600">*</span>
                        <span> New Password </span>
                      </>
                    )}
                  </div>

                  <LabeledPasswordInput
                    placeholder="Enter new password"
                    error={formik.errors.password && formik.touched.password}
                    value={formik.values.password}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      setCreateUpdateCalled(true);
                    }}
                    onKeyDown={handleKeyDown}
                    onChange={formik.handleChange}
                    name="password"
                    helperText={
                      <div  className={cx(style["error_msg"], "relative")}>
                        {formik.touched.password && formik.errors.password}
                        {formik.values.password.length > 7 &&
                        formik.values.password.length < 21 &&
                        formik.values.password.match(
                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,20}$/
                        ) &&
                        formik.values.password.match(/^\S*$/) ? (
                          <p className="text-green-500">
                            Strong password, good
                          </p>
                        ) : null}
                      </div>
                    }
                  />

                  {STRONG_PASSWORD_PATTERNS.some(
                    ({ pattern }) => !pattern.test(formik.values.password)
                  ) &&
                    (formik.values.password || formik.touched.password) && (
                      <Tooltip
                        data-html="true"
                        title={
                          <div>
                            {STRONG_PASSWORD_PATTERNS.map((passwordPattern) => {
                              return (
                                <Fragment key={passwordPattern.message}>
                                  {!passwordPattern.pattern.test(
                                    formik.values.password
                                  ) && <p>{passwordPattern.message}</p>}
                                </Fragment>
                              );
                            })}
                          </div>
                        }
                        placement="bottom"
                      >
                        <span
                      
                          className={cx(style["infoErrorIcon"], "cursor-pointer",
                            // "absolute",
                        

                            {
                              "text-red-500":
                                formik.touched.password &&
                                formik.errors.password,
                              "text-blue-700 ml-auto":
                                !formik.touched.password ||
                                !formik.errors.password,
                            })}
                         
                        >
                          <InfoOutlinedIcon />
                        </span>
                      </Tooltip>
                    )}
                </div>
                <div>
                  <div>
                    {formik.errors.confirmPassword &&
                    formik.touched.confirmPassword ? (
                      <>
                        {" "}
                        <span className="text-red-600">*</span>
                        <span> Confirm Password</span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span className="text-red-600">*</span>
                        <span> Confirm Password </span>
                      </>
                    )}
                  </div>
                  <LabeledPasswordInput
                    placeholder="Confirm your password"
                    error={
                      formik.errors.confirmPassword &&
                      formik.touched.confirmPassword
                    }
                    value={formik.values.confirmPassword}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      setCreateUpdateCalled(true);
                    }}
                    onKeyDown={handleKeyDown}
                    //   className={cx(style["placeholderpass"])}
                    onChange={formik.handleChange}
                    name="confirmPassword"
                    helperText={
                      <div className={cx(style["error_msg"], "absolute")}>
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword}
                        {formik.values.confirmPassword ===
                          formik.values.password &&
                        formik.values.confirmPassword.length > 7 ? (
                          <p className="text-green-500">Password matched</p>
                        ) : null}
                      </div>
                    }
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className={cx(
                    style["forgotPassButton"],"mt-4"
                    // "flex justify-center py-2 mt-6 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium  hover:bg-blue-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  )}
                >
                  Set Password
                </button>
              </div>
              <div className={cx(style["Back_link_text"], "mt-4")}>
              <KeyboardBackspaceSharpIcon sx={{ fontSize: 16 }}/> {" "}
                <a href="/">Back to sign in</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
export default SetPassword;
