import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { Breadcrumbs as MUIBreadcrumbs } from "@material-ui/core";
import { withRouter, Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Homeicon } from "../../assets/home-icon.svg";
import { useSelector } from 'react-redux'
import { IndividualModuleTree } from '../../actions/individual-tree-Slice'

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const Breadcrumbs = (props) => {
  // const { history, location } = props
  // let role=JSON.parse(localStorage["user-privilege"])?.role
  let role=localStorage.hasOwnProperty("user-privilege")
  ? JSON.parse(localStorage["user-privilege"]).role:""
  const navigate = useNavigate();
  let location = useLocation();
  const individualTree = useSelector(IndividualModuleTree);
  const { pathname } = location
//   const {
//     history,
//     location: { pathname }
//   } = props;

  const pathnames = pathname.split("/").filter((x) => x);

  const handelNavigation = (namedata,navigationPath)=>{
    console.log("namedata",namedata,navigationPath);
   

    if(pathname.includes("/project/testcases") && namedata !== "project" && namedata !== "testCase-Details" && namedata !== "execution/details"){
        let parentData = [];
        if(namedata !== "testcases"){
          console.log(individualTree,"------------");
            parentData = JSON.parse(localStorage.getItem("moduleData"))
            for(let i=0;i<individualTree.length;i++){
                if(namedata === individualTree?.[i]?.id){
                    navigate(`/project/testcases${individualTree?.[i]?.searchKey.substr(8)}?id=${individualTree?.[i]?.id}`)
                    break;
                }
            }
        }else{
            navigate(navigationPath)
        }
    }else if(pathname.includes("/project/configuration")){
      if(namedata==="configuration"){
        if(role!=="PROJECT_OWNER"){
          navigate(`/project/configuration/user-Hierarchy-Template`)
        }
        
      }else if(namedata==="manual-TestCase-Template"){
       
          navigate(`/project/configuration/manual-TestCase-Template`)
        
       
      }else if(namedata==="integration"){
        navigate(`/project/configuration/integration`)
      }
    }else if(namedata==="dashboard"){
      navigate(`/dashboard`)
    }
  }

  const getPageName=(pagename)=>{
    if(pagename==="all-Projects"|| pagename==="testCase-Details"|| pagename==="user-Hierarchy-Template"|| pagename==="defect-Details-Template"|| pagename==="platform-Details"||pagename==="manual-TestCase-Template"||pagename==="import-Manual-TestCase" || pagename==="feedback-Template"
    ){
      return (pagename.charAt(0).toUpperCase() + pagename.slice(1)).replaceAll("-"," ")

    }if(pagename==="project-Analytics"){
      const search = window.location.search;
      const projectname = new URLSearchParams(search).get("projectname");
      return (pagename.charAt(0).toUpperCase() + pagename.slice(1) + " "+ ">" + " " + projectname)
    }
    else{
      return (pagename.charAt(0).toUpperCase() + pagename.slice(1))
    }
  }
  


  return (
    <MUIBreadcrumbs separator="›" aria-label="breadcrumb">
      {/* {pathnames.length > 0 ? <Typography /> : <Link /> } the same as next example */}
      <Link to="#" onClick={() => {navigate("/")}}>
        <Homeicon/>
      </Link>
      {pathnames.map((namedata, index) => {
        
        let pagename = namedata.replace("%20"," ")
        while(pagename.includes("%20")){
            pagename = pagename.replace("%20"," ")
        }
        if(pathname.includes("/project/testcases") && pagename !== "project" && pagename !== "testCase-Details" && pagename !== "execution/details"){
            let parentData = [];
            if(pagename !== "testcases"){
                parentData = JSON.parse(localStorage.getItem("moduleData"))
                for(let i=0;i<individualTree.length;i++){
                    if(pagename === individualTree?.[i]?.id){
                        pagename = individualTree?.[i]?.name
                        break;
                    }
                }
            }
        }

        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLastLink = index === pathnames.length - 1;
        return isLastLink ?
        <Typography className="selectedLink cursor-default">{getPageName(pagename)}</Typography>
        : (
          <div to="#" className="cursor-pointer breadcrumbsclass" key={pagename} onClick={(e) => {
            handelNavigation(namedata,routeTo)
            }}>
              {
                pagename==="manual-TestCase-Template"?<>{(pagename.charAt(0).toUpperCase() + pagename.slice(1)).replaceAll("-"," ")}</>:
                <>
                {pagename.charAt(0).toUpperCase() + pagename.slice(1)}
                </>
              }
              
              
              </div>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
