import React, { Fragment, useState, useMemo, useEffect} from "react";
import { useNavigate } from "react-router-dom";
// import LogotextImage from "../../assets/fire_crowd.svg";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import BackgroundDown from "../../assets/login_logo.png";
import cx from "classnames";
import { Link } from "react-router-dom";
import MyInput, { Label } from "../common/inputs/myInput";
import LabeledSelect from "../common/inputs/LabeledSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import LabeledPasswordInput from "../common/inputs/passwordInputs";
import { CommonLoader } from "../common/common-loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormHelperText, Tooltip } from "@material-ui/core";
import { isValidPhoneNumber} from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import style from "./signup.module.scss";
import { InputAdornment } from "@mui/material";
import CheckIcon from "@material-ui/icons/Check";
import {
  signUpRequest,
  existEmailRequest,
  defaultCountry,
 
} from "../../api/api-services";
import RecaptchaDeclaration from "./reCaptcha-declaration";
import {
  CREATE_ACCOUNT,
  HAVE_ACCOUNT,
  SIGNUP_NOTE,
  SIGNUP_LABELS,
} from "../../util/app-constants";
import { getEncryptData } from "../../util/common-utils";
const SignupForm = () => {
  const [phoneNumberFields, setPhoneNumberFields] = useState([
    { value: "", error: "", countryData: null },
  ]);
  const navigate = useNavigate();

  const grecaptchaObject = window.grecaptcha;
  const elementId = useMemo(() => `recaptcha-${Math.random()}`, []);
  const [userAlreadyExist, setUserAlreadyExist] = useState(false);
  const [emailError, setEmailError] = useState();
  const [isPaidPlan, setIsPaidPlan] = useState(false);
  const [presentCountry, setPresentCountry] = useState("");
  const [createCalled, setCreateCalled] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const FREE_LICENSE_TYPE = "C-Basic";
  const PAID_LICENSE_TYPE = "C-Professional";
  const EMAIL_PATTERN =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  
 
  const formik = useFormik({
    initialValues: {
      name: "",
      emailId: "",
      password: "",
      isVerified: true,
      confirmPassword: "",
      phoneNumbers: {
        primaryPhone: "",
        secondaryPhone: "",
      },
      organisationName: "",
      jobTitle: null,
      jobTitleOthers: "",
      licensesType: null,
      licensePlan: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .trim()
        .min(3, "Mininum 3 characters")
        .max(25, "Maximum 25 characters")
        .matches(/^[A-Za-z0-9 ]*$/, "Name can not have special characters")
        .required("Name is required"),
      emailId: Yup.string()
        .email("Enter valid email Id")
        .matches(
          /[A-Za-z0-9]+@[a-zA-Z]+\.[a-zA-Z]{2,3}/,
          "Enter valid email Id"
        )
        .required("Email is required"),
      password: Yup.string()
        .matches(/^\S*$/, `Weak password, please match the password criteria`)
        .matches(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{8,20}$",
          `Weak password, please match the password criteria`
        )
        .required("Password is required"),
      confirmPassword: Yup.string()
        .required("Confirm password is required")
        .when("password", {
          is: (val) => val,
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Confirm password not matched with new password"
          ),
        }),
      phoneNumbers: Yup.object().shape({
        primaryPhone: Yup.string()
          .min(9, "Phone number is required")
          .max(13, "Phone number is required")
          .required("Phone number is required"),
          
      }),
      organisationName: Yup.string()
        .trim()
        .min(3, "Mininum 3 characters")
        .max(60, "Maximum 60 characters")
        .required("Organisation name is required"),
      jobTitle: Yup.mixed().required("Job title is required"),
      jobTitleOthers: Yup.string()
        .min(2, "Minimum 2 characters")
        .max(50, "Maximum 50 characters")
        .when("jobTitle", {
          is: (jobTitle) => ["Others"].includes(jobTitle?.value),
          then: Yup.string()
            .required("Job title others is required")
            .trim("Job title is required")
            .matches(/^[A-Za-z0-9 ]*$/, "Job title others can not have special characters"),
          otherwise: Yup.string().optional(),
        }),
      licensesType: Yup.mixed().required("Please select a License Type"),
      ...(!isPaidPlan && {
        recaptcha: Yup.string().trim().required("Recaptcha is required"),
      }),
    }),
  });

  const STRONG_PASSWORD_PATTERNS = [
    {
      pattern: /^.{8,20}$/,
      message:
        "Should contain at least 8 characters and at most 20 characters",
    },
    {
      pattern: /.*[0-9].*/,
      message: "Should contain at least one digit",
    },
    {
      pattern: /.*[A-Z].*/,
      message: "Should contain at least one upper case alphabet",
    },
    {
      pattern: /.*[a-z].*/,
      message: "Should contain at least one lower case alphabet",
    },
    {
      pattern: /.*[!@#$%&*()+=^].*/,
      message:
        "Should contain at least one special character which includes !@#$%&*()+=^",
    },
    {
      pattern: /^\S*$/,
      message: "Should doesn't contain any white space",
    },
  ];
  const JOB_TITLE_OPTIONS = [
    { label: "Analyst", value: "Analyst" },
    { label: "Automation Architect", value: "Automation Architect" },
    { label: "CEO", value: "CEO" },
    { label: "CTO", value: "CTO" },
    { label: "Consultant", value: "Consultant" },
    { label: "Director", value: "Director" },
    { label: "DevOps Engineer", value: "DevOps Engineer" },
    { label: "Engineering Manager", value: "Engineering Manager" },
    { label: "Manager", value: "Manager" },
    { label: "QA Engineer", value: "QA Engineer" },
    { label: "QA Lead", value: "QA Lead" },
    { label: "Service Provider", value: "Service Provider" },
    { label: "Software Engineer", value: "Software Engineer" },
    { label: "VP", value: "VP" },
    { label: "Others", value: "Others" },
  ];
  function handleTrimmedBlur(event) {
    event.target.value = event.target.value.trim();
    formik.handleChange(event);
    formik.handleBlur(event);
  }
  function handleAddPhoneNumberField() {
    const values = [...phoneNumberFields];
    values.push({ value: null, erorr: null, countryData: null });
    setPhoneNumberFields(values);
  }
  function validatePhoneNumberField(phoneNumberIndex, event) {
    const values = [...phoneNumberFields];
    values[phoneNumberIndex].error = "";
    
    if (
     
      formik.values.phoneNumbers.primaryPhone?.length === 0 ||
      formik.values?.phoneNumbers?.primaryPhone.length <= 8
    ) {
      values[phoneNumberIndex].error = "Phone number is required";
      
    }
    if(isValidPhoneNumber("+" + formik.values.phoneNumbers.primaryPhone?.toString())===false){
      values[phoneNumberIndex].error = "Valid Phone number is required";
    }
   

    setPhoneNumberFields(values);
  }
  function resetPhoneNumberFieldError(phoneNumberIndex) {
    const values = [...phoneNumberFields];
    values[phoneNumberIndex].error = "";
    setPhoneNumberFields(values);
  }
  function handlePhoneNumberChange(phoneNumberIndex, phoneNumber, countryData) {
    if (phoneNumber) {
      const values = [...phoneNumberFields];
      values[phoneNumberIndex].value = phoneNumber;
      values[phoneNumberIndex].countryData = countryData;

      setPhoneNumberFields(values);
      formik.setFieldValue("phoneNumbers", {
        primaryPhone: values[0]?.value,
        secondaryPhone: values[1]?.value,
      });
    }
  }
  function handleRemovePhoneNumberField(phoneNumberIndex) {
    const values = [...phoneNumberFields];
    values.splice(phoneNumberIndex, 1);
    setPhoneNumberFields(values);
  }
  function validateSecondaryPhoneNumber(phoneNumberIndex, event) {
    const values = [...phoneNumberFields];
    values[phoneNumberIndex].error = "";
let inputNums=[2,4,6,8]
    if (
      event.target.value.length <= 8 ||
      formik.values.phoneNumbers.secondaryPhone?.length === 0 ||
      formik.values?.phoneNumbers?.secondaryPhone.length <= inputNums[1]
    ) {
      values[phoneNumberIndex].error = "Phone number is required";
      
    }
    if (
     
     ( formik.values.phoneNumbers.secondaryPhone?.length === 0 ||
      formik.values?.phoneNumbers?.secondaryPhone.length <= inputNums[3])
    ) {
      values[phoneNumberIndex].error = "Valid Phone number is required";
     
    }
    if (
     
      ( formik.values.phoneNumbers.secondaryPhone === formik.values.phoneNumbers.primaryPhone
       )
     ) {
       values[phoneNumberIndex].error = "The two numbers should not be the same";
      
     }
     if(isValidPhoneNumber("+" + formik.values.phoneNumbers.secondaryPhone?.toString())===false){
      values[phoneNumberIndex].error = "Valid Phone number is required";
    }
   

    setPhoneNumberFields(values);
  }
  useEffect(() => {
    if (formik.values?.jobTitle?.value !== "Others") {
      formik.setFieldValue("jobTitleOthers", "");
    }
  }, [formik.values?.jobTitle]);
  let handleFinish = () => {
    formik.handleSubmit();
    // setCreateCalled(true)
if(!formik.errors.jobTitleOthers){
    const { values } = formik;
    let payload = {
      name: values.name,
      emailId: values.emailId,
      password: getEncryptData(values.password),
      phoneNumbers: {
        primaryPhone:
          values.phoneNumbers.primaryPhone[0] !== "+"
            ? `+${values.phoneNumbers.primaryPhone}`
            : values.phoneNumbers.primaryPhone,
        ...(!!values.phoneNumbers.secondaryPhone && {
          secondaryPhone:
            values.phoneNumbers.secondaryPhone[0] !== "+"
              ? `+${values.phoneNumbers.secondaryPhone}`
              : values.phoneNumbers.secondaryPhone,
        }),
      },
      organisationName: values.organisationName,
      jobTitle: values.jobTitle?.value,
      ...(values.jobTitle?.value === "Others" && {
        jobTitleOthers: values.jobTitleOthers,
      }),
      source: "FireCrowd",
      action: "activation",
  };


    setEmailError("");
    checkValidEmail();
    signUpRequest(payload)
      .then((response) => {
        if (response.data && response.data.responseObject) {
          setCreateCalled(false)
          navigate(
            `/registration-successful?email=${values.emailId}`
          );

          console.log(response.data.responseObject, "postSignUp");
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.error("Failed while signing up API call", error);
        return false;
      });
  };
}

  function isEmailValid(email) {
    return EMAIL_PATTERN.test(email);
  }

  async function checkIfEmailExists() {
    const data = {
      emailId: formik.values.emailId,
      source: "FireCrowd"
    };
    try {
      const response = await existEmailRequest(data);
      let isExists = false;
      if (response.data?.responseObject) {
        isExists = true;
        setUserAlreadyExist(true);
        setEmailError(response.data?.message);
      } else {
        isExists = false;
        setUserAlreadyExist(false);
        setEmailError("");
      }
      return isExists;
    } catch {
      return true;
    }
  }
  function checkValidEmail() {
    if (formik.values.emailId) {
      isEmailValid(formik.values.emailId) && checkIfEmailExists();
    } else {
      setEmailError("");
    }
  }
  useEffect(() => {
    defaultCountry().then((res) => {
      setPresentCountry(res.data.country);
    });
  }, []);
  function verifyCaptcha(response) {
    formik.setFieldValue("recaptcha", response);
    setIsCaptchaVerified(!!response);
  }
  function resetCaptcha() {
    formik.setFieldValue("recaptcha", "");
    window?.grecaptcha?.reset();
    setIsCaptchaVerified(false);
  }

  return createCalled ?  <div><CommonLoader /></div> : (
    <div className="rootClass">
      <div className="min-h-screen bg-white flex mainContainer overflow-x-hidden">
        <div
          className={cx(style["pageImage"], "flex-1 login-left-container sm:w-40 md:w-48 lg:w-52 ")}
          id="leftImage"
        >
          <div className="">
            <img
              className={cx(style["fireCrowdIcon"], "sm:w-40 md:w-48 lg:w-52 sm:ml-0 md:ml-8 lg:ml-10 sm:px-1 md:px-8 lg:px-10")}
              src={LogotextImage}
             
            />
          </div>

          <div>
            <img
              className={cx(style["pageImageContainer"], "sm:w-40 md:w-64 lg:w-72 sm:ml-0 md:ml-12 lg:ml-14 sm:px-1 md:px-8 ")}
              src={BackgroundDown}
              alt="Qspider"
            />
            <div className="flex flex-col items-center justify-center">
              <h1 className={cx(style["page-for"],"sm:w-72 md:w-80 lg:w-96 sm:text-center md:text-center lg:text-center  sm:text-xl md:text-2lg lg:text-4xl")}>
                {SIGNUP_NOTE?.substring(0, 17)}
              </h1>
              <h2 className={style["page-note"]}>
                {SIGNUP_NOTE?.substring(17)}
              </h2>
            </div>
          </div>
        </div>
        <div className={cx(style["mainAccountDiv"], "px-16")}>
          <h1 className={cx(style["craeteAccount"], "mt-10")}>
            {CREATE_ACCOUNT}
          </h1>
          <div className={cx(style["haveAnaccount"],"mt-2")}>
            <span className="">
              {HAVE_ACCOUNT}?
            </span>
            <Link to="/" className="ml-2  text-blue-500">
              Sign in
            </Link>
          </div>
          <div className="mt-2">
            <form>
              <div className={cx(style["formDiv"], "flex md:shrink-0 sm:flex-col lg:flex-row md:flex-row")} >
                <div className="flex-initial w-96">
                  <MyInput
                    label={SIGNUP_LABELS.label.name}
                    required
                    placeholder={SIGNUP_LABELS.placeHolders.name}
                    error={formik.errors.name && formik.touched.name}
                    value={formik.values.name.replace(/[^A-Za-z ]/ig, '')}
                    onBlur={handleTrimmedBlur}
                    onChange={(e)=>{
                      formik.handleChange(e);
                     
                    }}
                    autoComplete="off"
                    name="name"
                    autoFocus
                    variant="outlined"
                    helperText={
                      <p  className={cx(style["regularFont","error_msg"])}>{formik.touched.name && formik.errors.name}</p>
                    }
                    wrapperClassName="w-4/5"
                  />
                  <MyInput
                    label={SIGNUP_LABELS.label.email}
                    required
                    placeholder={SIGNUP_LABELS.placeHolders.email}
                    error={formik.errors.emailId && formik.touched.emailId}
                    value={formik.values.emailId}
                    onBlur={(event) => {
                      handleTrimmedBlur(event);
                      checkValidEmail(event);
                    }}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    name="emailId"
                    variant="outlined"
                    onFocus={() => setEmailError("")}
                    helperText={
                      <span  className={cx(style["regularFont","error_msg"],)} >
                        {(formik.touched.emailId && formik.errors.emailId) ||
                          emailError}
                        {emailError && (
                          <span
                            className={cx(style["existEmailSignIn","error_msg"], " inline")}
                          >
                            <Link
                              to="/"
                             
                              className={cx(style["emailExist"], "-ml-0.5 inline text-blue-700")}
                            >
                              Sign In
                            </Link>
                          </span>
                        )}
                      </span>
                    }
                    wrapperClassName="w-4/5"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formik.errors.emailId || !formik.values.emailId ? (
                            <></>
                          ) : (
                            <span className="text-green-500">
                              <CheckIcon />
                            </span>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <LabeledPasswordInput
                    label={SIGNUP_LABELS.label.password}
                    required
                    placeholder={SIGNUP_LABELS.placeHolders.password}
                    error={formik.errors.password && formik.touched.password}
                    value={formik.values.password}
                    // onKeyDown={handleKeyDown}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    name="password"
                    wrapperClassName="w-4/5"
                    helperText={
                      <span  className={cx(style["regularFont","error_msg"], "flex justify-between")}>
                        {formik.touched.password && formik.errors.password}
                        {!formik.errors.password && formik.values.password ? (
                          <span 
                           className={cx(style["regularFont"], "text-green-500 text-xs")}
                          >
                            Strong password, good
                          </span>
                        ) : null}
                        {STRONG_PASSWORD_PATTERNS.some(
                          ({ pattern }) => !pattern.test(formik.values.password)
                        ) &&
                          (formik.values.password ||
                            formik.touched.password) && (
                            <Tooltip
                              data-html="true"
                              title={
                                <div>
                                  {STRONG_PASSWORD_PATTERNS.map(
                                    (passwordPattern) => {
                                      return (
                                        <Fragment key={passwordPattern.message}>
                                          {!passwordPattern.pattern.test(
                                            formik.values.password
                                          ) && <p   className={cx(style["regularFont"])}>{passwordPattern.message}</p>}
                                        </Fragment>
                                      );
                                    }
                                  )}
                                </div>
                              }
                              placement="bottom"
                            >
                              <span
                                className={cx(
                                  "cursor-pointer",
                                  style["password-info-icon"],
                                  {
                                    "text-red-700":
                                      formik.touched.password &&
                                      formik.errors.password,
                                    "text-blue-700 ml-auto":
                                      !formik.touched.password ||
                                      !formik.errors.password,
                                  }
                                )}
                              >
                                <InfoOutlinedIcon />
                              </span>
                            </Tooltip>
                          )}
                      </span>
                    }
                  />
                  <LabeledPasswordInput
                    label={SIGNUP_LABELS.label.confirm_password}
                    required
                    placeholder={SIGNUP_LABELS.placeHolders.confirm_password}
                    wrapperClassName="w-4/5"
                    error={
                      formik.errors.confirmPassword &&
                      formik.touched.confirmPassword
                    }
                    value={formik.values.confirmPassword}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                    // onKeyDown={handleKeyDown}
                    onChange={formik.handleChange}
                    name="confirmPassword"
                    helperText={
                      <span  className={cx(style["regularFont","error_msg"])}>
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword}
                        {!formik.errors.confirmPassword &&
                        formik.values.confirmPassword ===
                          formik.values.password &&
                        formik.values.confirmPassword.length > 7 ? (
                          <span  className={cx(style["regularFont"], "text-green-500 text-xs")}>
                            Password matched
                          </span>
                        ) : null}
                      </span>
                    }
                  />

                
                </div>

                <div className="flex-initial  w-96">
                  <div>
                    <Label
                      required
                      error={
                        formik.errors.phoneNumbers &&
                        formik.touched.phoneNumbers
                      }
                      label={SIGNUP_LABELS.label.phone}
                    />
                    <div className="flex  flex-col items-end w-4/5">
                      <div
                        className={cx(style["phone-input-wrapper"], "w-full", {
                          [style["error"]]:
                            (formik.errors.phoneNumbers &&
                              formik.touched.phoneNumbers) ||
                            (formik.values?.phoneNumbers?.primaryPhone.length <
                              5 &&
                              formik.touched.phoneNumbers) ||
                            !!phoneNumberFields[0]?.error,
                        })}
                      >
                        <PhoneInput
                          type="text"
                          placeholder="Enter phone number"
                          value={phoneNumberFields[0]?.value || ""}
                          searchPlaceholder="Search..."
                          searchNotFound="No Countries Found"
                          specialLabel=""
                          defaultCountry={
                            presentCountry && presentCountry.toLowerCase()
                          }
                          enableAreaCodeStretch
                          country={
                            presentCountry && presentCountry.toLowerCase()
                          }
                    
                          enableSearch
                          international                       
                          name="phoneNumbers.primaryPhone"
                          autoComplete="off"
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            validatePhoneNumberField(0, e);
                          }}
                          onKeyUp={() => resetPhoneNumberFieldError(0)}
                          onChange={(phoneNumber, country) =>
                            handlePhoneNumberChange(0, phoneNumber, country)
                          }
                          countryCodeEditable={false}
                        />
                        <div className="flex justify-between">
                          {phoneNumberFields[0].value &&
                          phoneNumberFields[0].value.length
                            .toString()
                            .replace(/^\s+|\s+$/g, "") > 12 &&
                          !isValidPhoneNumber(phoneNumberFields[0].value) ? (
                            <FormHelperText error>
                              Phone number is required (Valid)
                            </FormHelperText>
                          ) : (
                            <FormHelperText
                           style={{fontFamily:"Poppins-Regular",color:"#EA032"}}
                            className="absolute"
                              error={
                                !!(
                                  !!phoneNumberFields[0]?.error ||
                                  ((formik.touched.phoneNumbers ||
                                    formik.touched.phoneNumbers
                                      ?.primaryPhone) &&
                                    formik.errors.phoneNumbers?.primaryPhone)
                                )
                              }
                            >
                              {phoneNumberFields[0]?.error ||
                                ((formik.touched.phoneNumbers ||
                                  formik.touched.phoneNumbers?.primaryPhone) &&
                                  formik.errors.phoneNumbers?.primaryPhone)}
                            </FormHelperText>
                          )}
                          <div className="text-xs inline relative left-64">
                            {phoneNumberFields.length === 1 && (
                              <button
                                type="button"
                                data-value={phoneNumberFields.length}
                                disabled={
                                  !!(
                                    (!phoneNumberFields[0]?.value &&
                                      !isValidPhoneNumber(
                                        phoneNumberFields[0]?.value || ""
                                      )) ||
                                    phoneNumberFields[0]?.value.length <= 10 ||
                                    phoneNumberFields[0]?.value.length >= 14
                                  )
                                }
                                className={cx({
                                  "opacity-50":
                                  (
                                    !(phoneNumberFields[0]?.value &&
                                      isValidPhoneNumber("+" + formik.values.phoneNumbers.primaryPhone?.toString())===true) 
                                   
                                  ),
                                  "text-blue-700": (
                                    (phoneNumberFields[0]?.value &&
                                      isValidPhoneNumber("+" + formik.values.phoneNumbers.primaryPhone?.toString())===true) 
                                   
                                  ),
                                })}
                                onClick={() => {if(
                                  isValidPhoneNumber("+" + formik.values.phoneNumbers.primaryPhone?.toString())===true
                                ){handleAddPhoneNumberField()}}}
                              >
                                + Phone
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {phoneNumberFields.length > 1 && (
                    <div className="mt-3">
                    <div className="relative top-2"> <Label   label="Secondary Phone Number" /></div>
                     
                      <div className="flex  flex-col items-end w-4/5">
                        <div
                          className={cx(
                            style["phone-input-wrapper"],
                            "w-full",
                            {
                              [style["error"]]: !!phoneNumberFields[1]?.error,
                            }
                          )}
                        >
                          <PhoneInput
                            type="text"
                            className="mt-1.5"
                            placeholder="Enter secondary phone number"
                            value={phoneNumberFields[1]?.value || ""}
                            searchPlaceholder="Search..."
                            searchNotFound="No Countries Found"
                            specialLabel=""
                            defaultCountry="in"
                            enableAreaCodeStretch
                            country="in"
                            enableSearch
                            international
                            name="phoneNumbers.secondaryPhone"
                            autoComplete="off"
                           
                            onBlur={(e) => {
                            formik.handleBlur(e);
                            validateSecondaryPhoneNumber(1, e);
                            
                          }}
                            onChange={(phoneNumber, countryData) =>
                              handlePhoneNumberChange(
                                1,
                                phoneNumber,
                                countryData
                              )
                            }
                          />
                          {phoneNumberFields[1].value &&
                          phoneNumberFields[1].value.length
                            .toString()
                            .replace(/^\s+|\s+$/g, "") > 12 &&
                          !isValidPhoneNumber(phoneNumberFields[1].value) ? (
                            <FormHelperText error>
                              Phone number is required (Valid)
                            </FormHelperText>
                          ) : (
                            <FormHelperText
                            className="fixed"
                              error={
                                !!(
                                  !!phoneNumberFields[1]?.error ||
                                  ((formik.touched.phoneNumbers ||
                                    formik.touched.phoneNumbers
                                      ?.secondaryPhone) &&
                                    formik.errors.phoneNumbers?.secondaryPhone)
                                )
                              }
                            >
                              {phoneNumberFields[1]?.error ||
                                ((formik.touched.phoneNumbers ||
                                  formik.touched.phoneNumbers?.secondaryPhone) &&
                                  formik.errors.phoneNumbers?.secondaryPhone)}
                            </FormHelperText>
                          )}
                        </div>

                        <div className="text-xs inline">
                          <button
                            type="button"
                            
                            onClick={() => handleRemovePhoneNumberField(1)}
                            className={cx(style["regularBoldFont"], "text-red-500 text-xs")}
                            
                          >
                            - Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <MyInput
                    label={SIGNUP_LABELS.label.organisation_name}
                    required
                    placeholder={SIGNUP_LABELS.placeHolders.organisation_name}
                    error={
                      formik.errors.organisationName &&
                      formik.touched.organisationName
                    }
                    variant="outlined"
                    value={formik.values.organisationName.replace(/[^A-Za-z0-9-_ ]/ig, '')}
                    onBlur={handleTrimmedBlur}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    name="organisationName"
                    helperText={
                      <p className={cx(style["regularFont","error_msg"], "text-xs")}>
                        {formik.touched.organisationName &&
                          formik.errors.organisationName}
                      </p>
                    }
                    wrapperClassName="w-4/5 mt-2"
                  />
                  <LabeledSelect
                    label={SIGNUP_LABELS.label.job_title}
                    wrapperClassName="w-4/5 mt-1"
                    required
                    placeholder={SIGNUP_LABELS.placeHolders.job_title}
                    id="projecttype"
                    error={formik.errors.jobTitle && formik.touched.jobTitle}
                    value={formik?.values?.jobTitle}
                    onBlur={formik.handleBlur}
                    defaultValue=""
                    onChange={formik.handleChange}
                    name="jobTitle"
                    helperText={
                      <p className={cx(style["regularFont","error_message"], "text-xs")}>{formik.touched.jobTitle && formik.errors.jobTitle}</p>
                    }
                    options={JOB_TITLE_OPTIONS}
                  />
                  {formik.values?.jobTitle?.value === "Others" ? (
                    <MyInput
                      label="Others"
                      required
                      // style={{marginTop:"-7px"}}
                      placeholder={SIGNUP_LABELS.label.jobTitle}
                      error={
                        formik.errors.jobTitleOthers &&
                        formik.touched.jobTitleOthers
                      }
                      variant="outlined"
                      value={formik.values.jobTitleOthers.replace(/[^A-Za-z ]/ig, '')}
                      onBlur={handleTrimmedBlur}
                      onChange={formik.handleChange}
                      autoComplete="off"
                      name="jobTitleOthers"
                      helperText={
                        <p className={cx(style["regularFont","error_msg"], "text-xs")}>
                          {formik.touched.jobTitleOthers &&
                            formik.errors.jobTitleOthers}
                        </p>
                      }
                      wrapperClassName={cx("w-4/5", {
                        "pb-6": !(
                          formik.errors.jobTitleOthers &&
                          formik.touched.jobTitleOthers
                        ),
                        "pb-4":
                          formik.errors.jobTitleOthers &&
                          formik.touched.jobTitleOthers,
                      })}
                    />
                  ) : null}
                </div>
              </div>
              <div className="mt-3">
                    {!isPaidPlan && (
                      <div className="flex-initial mt-1">
                        <RecaptchaDeclaration
                          wrapperClassName="w-4/5"
                          handleVerifyCaptacha={verifyCaptcha}
                          handleCaptchaExpiry={resetCaptcha}
                          handleCaptchaLoad={resetCaptcha}
                          error={
                            formik.touched.recaptcha && formik.errors.recaptcha
                          }
                        />
                      </div>
                    )}
                  </div>
            </form>
            <div className="mt-4">
              <button
                type="submit"
                onClick={() => {
                  if (isCaptchaVerified  &&  ! formik.errors.phoneNumbers?.primaryPhone && ! phoneNumberFields[1]?.error && !createCalled) {
                    console.log("helllo")
                    formik.handleSubmit();
                    // setCreateCalled(true)
                    handleFinish();
                  } else {
                   
                    formik.handleSubmit();
                  }
                }}
                
                disabled={emailError}
                className={
                  !emailError
                    ? cx(
                        style["submitBtn"],
                        // "flex justify-center p-3   px-4 border border-transparent  shadow-sm text-sm font-medium bg-blue-900 hover:bg-blue-700 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      )
                    : cx(
                        style["submitBtn"],
                        // "flex justify-center p-3 opacity-50  px-4 border border-transparent  shadow-sm text-sm font-medium bg-blue-900 hover:bg-blue-700 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      )
                }
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
