import React, { useState, useEffect } from "react";
import "./project.scss";
import ProjectListItem from "./project-list-item";
import "./project.scss";
import Search from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import Add from "@mui/icons-material/Add";
import ProjectCreate from "./project-create";
import {
  getAllProjectRequest,
  deleteProjectRequest,
  getAllProjectAdminRequest,
  getUserByEmailId,
  getprojectList,
} from "../../api/api-services";
import { AttachmentSharp } from "@material-ui/icons";
import dateFormat from "dateformat";
import ProjectListLoader from "./project-list-loader";
import { ReactComponent as AddIcon } from "../../assets/addIcon.svg";
import { useAlert } from "../common/alert-service/useAlert";
import { ReactComponent as NoProjectsIcon } from "../../assets/noProjectsIcon.svg";
import { Tooltip } from "@mui/material";
import SearchField from "../common/search-field";
import NoRelevantDataIcon from "../../assets/reportsicon/no_reports_icon.png";

function ProjectList(props) {
  let [createProjectModal, setCreateProjectModal] = useState(false);
  let [projectList, setProjectList] = useState([]);
  let [totalProject,setTotalProject]=useState();
  let [ProjectNameList,setProjectNameList]=useState([])
  let [closeSearchField, setCloseSearchField] = useState(false)

  const [filterData, setFilterData] = useState([]);

  const [loading, setLoading] = useState(true);
  const { AlertContatiner, MyAlert } = useAlert();

  useEffect(() => {
    console.log("home page")
    getAllproject();
    
    // getAllProjectList();
  }, []);
  const [userPhoneNumber, setUserPhoneNumber] = useState();
  const getUserDetails= async (e)=>{
    let emailId=JSON.parse(localStorage["fire-crowd-user"]).userName
    try{
      let response= await getUserByEmailId(emailId);
      if(response?.data?.responseCode===200)
       
      setUserPhoneNumber(response.data.responseObject?.["phone number"]);
       
      console.log("getUserDetails",response);
    }catch(err){
      console.log("error")
    }
     
     
  }

  const getAllProjectList =async ()=>{
    let email=JSON.parse(localStorage["fire-crowd-user"]).userName;
    try{
      let response =await getprojectList(email);
      if(response.status===200){
        setProjectNameList(response.data)
       console.log("response",response);
        
      }
        
      
    }catch(error){
      console.log("error")
    }
  }

  // const getAllProjectList =async ()=>{
  //   let email=JSON.parse(localStorage["fire-crowd-user"]).userName;
  //   try{
  //     let response =await getprojectList(email);
  //     if(response.status===200){
  //       localStorage.setItem("getAllproject", JSON.stringify(response.data));
  //     }
        
      
  //   }catch(error){
  //     console.log("error")
  //   }
  // }

  const getAllproject = async (e) => {
    let mailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
    try {
      // const response =
      //   localStorage.hasOwnProperty("my-role") &&
      //   JSON.parse(localStorage["my-role"]) === "admin"
      //     ? await getAllProjectAdminRequest()
      //     : await getAllProjectRequest(mailId);
      const response= await getAllProjectRequest(mailId)
      if (response.status === 200) {
        // console.log("response", response);
        setProjectList(response.data);
        setFilterData(response.data);
 
        //  localStorage.setItem("getAllproject", JSON.stringify(response.data));

         setTotalProject(response.data.length);
       
      }
    } catch (error) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };
  // let allProjectDataLength = JSON.parse(
  //   localStorage.getItem("getAllproject")
  // )?.length;
  let allProjectDataLength = totalProject
  const deleteProject = async (project) => {
    let projectId = project.id;
    let hasfiles;
    if (project?.executionDetails?.testCases === null || project?.executionDetails?.testCases?.length===0) {
      if (project?.executionDetails?.attachments === null || project?.executionDetails?.attachments?.length===0) {
        if (project?.project?.projectType?.mobile !== null) {
          if (
            project?.project?.projectType?.mobile?.platform?.uploadApk !== "" &&
            project?.project?.projectType?.mobile?.platform?.uploadApk !==
              null &&
            project?.project?.projectType?.mobile?.platform?.uploadIPA !== "" &&
            project?.project?.projectType?.mobile?.platform?.uploadIPA !== null
          ) {
            hasfiles = true;
          } else {
            hasfiles = false;
          }
        } else if (project?.project?.projectType?.webAndMobile !== null) {
          if (
            project?.project?.projectType?.webAndMobile?.uploadApk !== "" &&
            project?.project?.projectType?.webAndMobile?.uploadApk !== null &&
            project?.project?.projectType?.webAndMobile?.uploadIPA !== "" &&
            project?.project?.projectType?.webAndMobile?.uploadIPA !== null
          ) {
            hasfiles = true;
          } else {
            hasfiles = false;
          }
        } else {
          hasfiles = false;
        }
      } else {
        hasfiles = true;
      }
    } else {
      hasfiles = true;
    }
    console.log("project id and hasfiles", projectId, hasfiles);
    console.log("delete project", project);
    try {
      const response = await deleteProjectRequest(projectId, hasfiles);
      if (response.status === 200) {
        props.MyAlert.success(
          `${project?.project?.projectName} project deleted successfully`
        );
        getAllproject();
      }
    } catch {
      console.log("error");
    }
  };

  function onCreateProjectModalClose(status) {
    setCreateProjectModal(status);
  }
  function onProjectCreated(status) {
    if (status) {
      getAllproject();
    }
  }
  function onProjectEdited(status) {
    if (status) {
      getAllproject();
    }
  }

  function handleSearch(e) {
    console.log("filterdata",filterData)
    let searchValue = e.target.value;
    if (searchValue) {
      let tempSimilarArray = filterData.filter((proVal, index) => {
        let name = proVal?.project?.projectName;
        let duration = proVal.duration?.toString();
        let numberOfTesters = proVal?.numberOfTesters?.toString();
        let startDate = dateFormat(
          proVal.startDate?.slice(0, 10),
          "dd/mm/yyyy"
        );
        // let accountManager = proVal?.project?.users.userList.find((user) => {
        //   if (user.role === "ACCOUNT_MANAGER") {
        //     return user.name;
        //   }
        // });
        let accountManager=proVal?.project?.users?.userResponse?.accountManagerName;
        let status = proVal?.projectStatus;
        // let myRole = proVal?.project?.users.userList.find((user) => {
        //   if (user.email === JSON.parse(localStorage['fire-crowd-user']).userName) {
        //     return user.role;
        //   }
        // });
        let myRole=proVal?.project?.users?.userResponse?.role
  
        let similarExist = name.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistByDuration = duration?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistByNumberOfTesters = numberOfTesters?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistByStartDate = startDate?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistByManager = accountManager?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistByStatus = status?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        let similarExistByRole = myRole?.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
  
        return (
          similarExist ||
          similarExistByDuration ||
          similarExistByNumberOfTesters ||
          similarExistByStartDate ||
          similarExistByManager ||
          similarExistByStatus ||
          similarExistByRole
        );
      });
      setProjectList(tempSimilarArray);
    } else {
      setProjectList(filterData);
    }
  }
  
  return (
    <div className="project_list">
      <AlertContatiner />
      <div className="grid grid-cols-12 flex header">
        <div className="col-span-9 header_text pl-4 pt-2">
          Projects List - {projectList?.length}
        </div>
        <div className="grid grid-cols-12 col-span-3">
        <SearchField setGlobalFilter={handleSearch} inputClass="ml-1" closeIconClass="" closeField={closeSearchField} setCloseField={setCloseSearchField} colSpan="col-span-7 ml-auto mr-6 " page="projectList" onClose={getAllproject} />
          <div className="grid grid-cols-12 col-span-5">
            <Tooltip title="Create Project">
            <div
              className="grid grid-cols-12 col-span-10 create_buttton cursor-pointer"
              onClick={() => {
                setCreateProjectModal(true);
                setCloseSearchField(true)
                getUserDetails();
                // getAllProjectList();
                
               
              }}
            >
              <span className="col-span-2 add_icon">
                {/* <Add /> */}
                <AddIcon />
              </span>
              <span className=" col-span-10 py-2 px-5">Project</span>
            </div>
            </Tooltip>
           
          </div>
        </div>
      </div>
      <div className="body">
        {allProjectDataLength > 0 && projectList.length > 0 && !loading ? (
          <ProjectListItem
            MyAlert={MyAlert}
            setCloseSearchField={setCloseSearchField}
            onProjectEdited={onProjectEdited}
            projectDetails={projectList}
            deleteProject={deleteProject}
          />
        ) : !allProjectDataLength && projectList.length > 0 && loading ? (
          <>
            <div className="project_item_container wrapper ">
              <ProjectListLoader />
            </div>
            <div className="project_item_container wrapper">
              <ProjectListLoader />
            </div>
          </>
        ) : allProjectDataLength > 0 && projectList.length === 0 && !loading ? (
          <>
            <div className="no_relevant_data">
                    <img  alt="warning" src={NoRelevantDataIcon}/>
                    <div className="text_msg">No Relevant Data Is Found !</div>
                  </div>
          </>
        ) : !allProjectDataLength && projectList.length === 0 && !loading ? (
          <div className="project_list_page_msg grid grid-cols-12">
          <div className="col-span-12 flex justify-center pt-56">
            <div className="msg_text">Click </div>
            <div
              className="grid grid-cols-6 col-span-3 create_buttton cursor-pointer"
              onClick={() => {
                
                setCreateProjectModal(true);
                setCloseSearchField(true)
                getUserDetails();
                // getAllProjectList();
              }}
            >
              <span className="col-span-2 add_icon">
                {/* <Add /> */}
                <AddIcon />
              </span>
              <span className="col-span-3 py-2 px-1">Project</span>
            </div>{" "}
            <div className="msg_text"> to create project</div>
          </div>
        </div>
        ) : (
          ""
        )}
      </div>
      {createProjectModal && (
        <ProjectCreate
          MyAlert={props.MyAlert}
          closeModal={onCreateProjectModalClose}
          onProjectCreated={onProjectCreated}
          header={"Create Project in 3 Steps"}
          userPhoneNumber={userPhoneNumber}
          ProjectNameList={ProjectNameList}
        />
      )}
    </div>
  );
}

export default ProjectList;
