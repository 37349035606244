import React, { useState, useEffect, useContext } from "react";
import { useTable, useGlobalFilter, useSortBy, } from "react-table";
import "./Dashboard.scss"
import { useNavigate } from "react-router-dom";
import {getProjectDetails,getUserDetailsByEmailID, getUserPrivilege} from "../../api/api-services";
import {ReactComponent as WebIcon} from "../../assets/Dashboard-icon/web_icon.svg";
import {ReactComponent as MobileIcon} from "../../assets/Dashboard-icon/mobile_icon.svg";
import {ReactComponent as WebandMobileIcon} from "../../assets/Dashboard-icon/webMobile_icon.svg";
import {ReactComponent as WatchIcon} from "../../assets/Dashboard-icon/smartwatch_icon.svg";
import {ReactComponent as TVIcon} from "../../assets/Dashboard-icon/tv_Icon.svg";
import {ReactComponent as StandaloneIcon} from "../../assets/Dashboard-icon/STANDALONE_icon.svg";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { ReactComponent as Search } from "../../assets/search.svg";
import NoDefectIcon from "../../assets/reportsicon/no_reports_icon.png";
import { CircularLoader } from "../common/CircularLoader";







function DashboardProjectTable(props) {
 
  const navigate = useNavigate();
  let [tabelData, setTableData] = useState([]);
  let [callingAPI,setCallingAPI]=useState(true)

  

  const getAlldata=()=>{
    let newarray=[];
    if(props?.label==="project"){
   
      props?.data.forEach((item)=>{
        let date=item?.date?.split(" ")[0]?.replaceAll("-","/");
        console.log("date",date)
        newarray.push({
       
          id:item.id,
          name:item.name,
          date:date,
          noOfTesters:item.noOfTesters,
          defectCount:item.defectCount,
          osCount:item.osCount,
          browserCount:item.browserCount,
          deviceCount:item.deviceCount,
          locationCount:item.locationCount,
          type:item.type



        })

      })
      setTableData([...newarray])
      setCallingAPI(false)
        
      
    }else{
     
      props?.data.forEach((item,index)=>{
        newarray.push({
          sl:index+1,
          ...item
       
        })
      })
      setTableData([...newarray])
       setCallingAPI(false)
    }
    
  }

  useEffect(()=>{
    setCallingAPI(true)
    getAlldata()

  },[props])

  let data = React.useMemo(() => tabelData, [tabelData]);
  let locationcolumns = React.useMemo(
    () => [
      {
        Header: "SERIAL NO",
        accessor: "sl",
        width: "12.3%",
        Cell: (e) => <div title={e.value}>{e.value}</div>,
      },
      {
        Header: "COUNTRY",
        accessor: "country",
        width: "20%",
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },
      {
        Header: "STATE",
        accessor: "state",
        width: "20%",
      },
      {
        Header: "CITY",
        accessor: "city",
        width: "20%",
        Cell: (e) => <div title={e.value}>{e.value}</div>,
      },
      {
        Header: "TOTAL USERS",
        accessor: "userCount",
        width: "20%",
        Cell: (e) => <div title={e.value}>{e.value}</div>,
      },
     
    ],
    []
  );
  let projectcolumns = React.useMemo(
    () => [
      {
        Header: "PROJECT NAME",
        accessor: "name",
        width: "15%",
        Cell: (e) =><Tooltip title={e.value}>
          <div  className="datatrimmer w-28">
          {e.value}
          </div>
        </Tooltip> 
      },
      {
        Header: "TYPE",
        accessor: "type",
        width: "10%",
        Cell: (e) => (
          <Tooltip title={e.value}>
             <div className="datatrimmer dashboard_project_type_icon">
            {e.value==="web"?(<><WebIcon/></>):e.value==="mobile"?(<div><MobileIcon/></div>):e.value==="webAndMobile"?(<><WebandMobileIcon/></>):e.value==="Television"?(<><TVIcon/></>):e.value==="Smart_Watches"?(<><WatchIcon/></>):(<><StandaloneIcon/></>)}
          </div>
          </Tooltip>
         
        ),
      },
      {
        Header: "DATE",
        accessor: "date",
        width: "12.3%",
        Cell: (e) => <div>{e.value}</div>,
      },
      {
        Header: "TEST ENGINEERS",
        accessor: "noOfTesters",
        width: "12.3%",
        Cell: (e) => <div>{e.value}</div>,
      },
      {
        Header: "DEFECTS",
        accessor: "defectCount",
        width: "12%",
        Cell: (e) => <div>{e.value}</div>,
      },
      {
        Header: "OS",
        accessor: "osCount",
        width: "12%",
        Cell: (e) => <div>{e.value}</div>,
      },
      {
        Header: "BROWSER",
        accessor: "browserCount",
        width: "12%",
        Cell: (e) => <div>{e.value}</div>,
      },
      {
        Header: "DEVICES",
        accessor: "deviceCount",
        width: "12%",
        Cell: (e) => <div>{e.value}</div>,
      },
      {
        Header: "LOCATION",
        accessor: "locationCount",
        width: "12%",
        Cell: (e) => <div>{e.value}</div>,
      },
    ],
    []
  );

  let columns=props?.label==="project"?projectcolumns:locationcolumns;

  const UserPrivilege=(projectName)=>{
    let role="ADMIN";
    getUserPrivilege(role).then((response)=>{
      console.log("reponse ",response)
      if (response.status === 200) {
        console.log("reponse ",response)
        localStorage.setItem("user-privilege", JSON.stringify(response.data));
        navigate(`/dashboard/project-Analytics?projectname=${projectName}`)
      }else{
        props?.MyAlert.info("Something went wrong please try again later")
      }

    }).catch((error)=>{
      console.log(error)
    })
  }

  const userDeatils=(projectid,userId,projectName)=>{
    getUserDetailsByEmailID(projectid,userId).then((response)=>{
      if(response.status===200){
        console.log("reponse ",response)
        localStorage.setItem("selected-user", JSON.stringify(response.data))
        UserPrivilege(projectName)
      }else{
        props?.MyAlert.info("Something went wrong please try again later")
      }
    

    }).catch((error)=>{
      console.log(error)
    })
  }

  const getprojectDeatils=(data)=>{
    console.log("getprojectDeatils",data?.original.name);
    let projectName=data?.original.name;
    let projectid=data?.original.id;
    let userId = JSON.parse(localStorage["fire-crowd-user"]).userName;
    getProjectDetails(projectid).then((response)=>{
      if(response.status === 200){
        console.log("reponse ",response)
        localStorage.setItem("selected-project", JSON.stringify(response.data));
        userDeatils(projectid,userId,projectName)
      }else{
        props?.MyAlert.info("Something went wrong please try again later")
      }
    }).catch((error)=>{
      console.log(error)
    })
   
   
   

  }

  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
  );
  const { globalFilter } = state;

  return (
    <div className="grid grid-cols-12 ">
      <div className="col-span-12 mt-4 Dashboard-tabel-header grid grid-cols-12 ">
        <div className="col-span-9">{props?.header}</div>
        <div className="col-span-3 flex justify-end">
        <div className="">
                <div className="DashboardSearch">
            <div>
              <input
                type="text"
                className="searchfield"
                 value={globalFilter || ""}
                autoFocus={false}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
                placeholder="Search"
              />
            </div>
            <div className="search_icon">
              <Search />
            </div>
          </div> 
                </div>
        </div>
      </div>
      <div className="Dashboard-table-container col-span-12 ">
      <div className="dashbord_table ">
       
            <table {...getTableProps()} className=" overflow-x-auto">
            <thead className="text-left">
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  
                  className="dashboard_table_header"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      width={column.width}
                      // {...column.getHeaderProps()}
                      {...column.getHeaderProps(props?.label==="project"?column.getSortByToggleProps():"")}
                      className={`dashboardheader h-10 ${
                       (column.Header === "TOTAL USERS"||column.Header === "TEST ENGINEERS"|| column.Header === "DEFECTS"|| column.Header === "OS"|| column.Header === "BROWSER"|| column.Header === "DEVICES"|| column.Header === "LOCATION" || column.Header === "DATE" || column.Header === "SERIAL NO")
                          ? "text-center"
                          : "text-left pl-3"
                      }`}
                      
                    >
                      {/* <div className="flex"> */}
                      <div>{column.render("Header")}</div>
                      {/* <div className="sort"> {column.isSorted?column.isSortedDesc?<ArrowDownwardOutlinedIcon/>:<ArrowUpwardOutlinedIcon/>:""}</div> */}
                     
                        
                    {/* </div> */}
                     
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
           
  
            <tbody {...getTableBodyProps()} className="dashboard_table_body">
              {
                (tabelData.length===0) ?(
                <div className="py-32">
                <CircularLoader/>
                </div>
                ):(
                  <>
                   {
                rows.length > 0 ?(
                  <>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className={`h-10 ${props?.label==="project"?"cursor-pointer project-details-row":"cursor-default"}`} id="Defect_Card" onClick={()=>{
                        props?.label==="project" && 
                        (getprojectDeatils(row))
                      }}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              width={cell?.column?.width}
                              {...cell.getCellProps()}
                              className={`datatrimmer reportTablecell ${
                                cell.column.Header === "BUG ID"
                                  ? // || cell.column.Header === "CREATED BY"
                                    " popins-semi-bold"
                                  : "popins"
                              } ${
                                 (cell.column.Header === "PROJECT NAME"||cell.column.Header === "TYPE"||
                                cell.column.Header === "COUNTRY"||cell.column.Header === "CITY"||cell.column.Header === "STATE")
                               
                                  ? "text-left pl-3":(cell.column.Header === "TOTAL USERS"|| cell.column.Header === "TOTAL USERS"||cell.column.Header === "TEST ENGINEERS"|| cell.column.Header === "DEFECTS"|| cell.column.Header === "OS"|| cell.column.Header === "BROWSER"|| cell.column.Header === "DEVICES"|| cell.column.Header === "LOCATION" ||cell.column.Header === "DATE" || cell.column.Header === "SERIAL NO")?"text-center"
                                  : "text-left pl-4"
                              }`}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
                ):(
                
                    <div className="noExeutionsreportsMsg">
                  <img  alt="warning" src={NoDefectIcon}/>
                  <div className="text_msg">No Relevant Data Is Found !</div>
                </div>
                     
                 
                )
              }
                  </>
                )
              }
             
             
            </tbody>
          </table>
        
      
       
      </div>
      </div>
      
    </div>
  );
}
export default DashboardProjectTable;
