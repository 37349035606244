import React, { useState, useEffect, useContext } from "react";
import { useTable, useGlobalFilter } from "react-table";
import Search from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import { ReactComponent as AddIcon } from "../../../assets/addIcon.svg";
import { Tooltip } from "@material-ui/core";
import { ReactComponent as EditIcon } from "../../../assets/editBlack.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/deleteBlack.svg";
import IconButton from "@mui/material/IconButton";
import "./manualTestCaseTemplate.scss";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getAllTestCaseTemplate,
  updateDefaultTemplateRequest,
} from "../../../api/api-services";
import SetDefaultModal from "../set-default-modal";
import SearchField from "../../common/search-field";
import { ReactComponent as Noreports } from "../../../assets/reportsicon/noReportsAvailable.svg";
import NoRelevantDataIcon from "../../../assets/reportsicon/no_reports_icon.png";

const ManualTestcaseTemplate = (props) => {
  let [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();
  function navigateToDetails() {
    navigate("/project/configuration/manual-TestCase-Template/details", {
      replace: true,
    });
  }
  const [testCaseDetails, setTestcaseDetails] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);
  const [isLoading, setIsLoading] = useState();
  const [defaultTempEvent, setDefaultTemplateEvent] = useState({});
  const [openChangeDefaultModal, setopenChangeDefaultModal] = useState(false);
  const [defaultTemplate, setDefaultTemplate] = useState({});
  const [templateName, setTemplateName] = useState("");
  let [closeSearchField, setCloseSearchField] = useState(false);
  let fireFlinkProjectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
  let project_Status=JSON.parse(localStorage["selected-project"]).projectStatus;


  async function getTestCaseTemplate(moduleId) {
    setIsLoading(true);
    try {
      const getdata = await getAllTestCaseTemplate(
        "config",
        fireFlinkProjectId,
        licenseId
      );

      if (getdata.data.responseObject.defaultTemplate) {
        setDefaultTemplate({ ...getdata.data.responseObject.defaultTemplate });
      }
      if (getdata.data.responseObject.templates) {
        const templates = getdata.data.responseObject.templates;
        const exists =
          templates.filter(function (o) {
            return o.hasOwnProperty("projectId");
          }).length > 0;

        if (exists) {
          setDisableBtn(true);
        } else {
          setDisableBtn(false);
        }
      }
      if (getdata.data.responseObject.templates) {
        setTestcaseDetails(getdata.data.responseObject.templates);
      } else {
        setIsLoading(false);
        setTestcaseDetails([]);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }
  useEffect(() => {
    if(project_Status!=="REJECTED" && project_Status!=="INREVIEW"){
      getTestCaseTemplate();
    }else{
      setIsLoading(false)
    }
    
  }, []);

  let data = React.useMemo(
    () => testCaseDetails,
    [testCaseDetails, defaultTemplate]
  );
  const stateColumnHtml = (e) => {
    return (
      <div className="actionIcons flex justify-start align-middle">
        <>
          <div className="editIcon">
            <Tooltip
              title={
                e.templateType === "Predefined"
                  ? "Predefined template cannot be Edited"
                  : "Edit"
              }
            >
              <IconButton
                sx={
                  e.templateType === "Predefined"
                    ? {
                        "&:hover": {
                          opacity: "0.4",
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }
                    : {
                        "&:hover": {
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }
                }
              >
                <button
                  className={
                    e.templateType === "Predefined" ? "opacity-40" : ""
                  }
                  disabled={e.templateType === "Predefined"}
                >
                  <EditIcon height="15px" width="15px" />
                </button>
              </IconButton>
            </Tooltip>
          </div>
          <div className="deleteIcon">
            <Tooltip
              title={
                e.templateType === "Predefined"
                  ? "Predefined template cannot be deleted"
                  : "Delete"
              }
            >
              <IconButton
                sx={
                  e.templateType === "Predefined"
                    ? {
                        "&:hover": {
                          opacity: "0.4",
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }
                    : {
                        "&:hover": {
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }
                }
              >
                <button
                  className={
                    e.templateType === "Predefined" ? "opacity-40" : ""
                  }
                  disabled={e.templateType === "Predefined"}
                >
                  <DeleteIcon height="15px" width="15px" />
                </button>
              </IconButton>
            </Tooltip>
          </div>
          <div className="deleteIcon flex">
            <Tooltip
              title={
                e.templateType === "Predefined"
                  ? "Predefined template cannot be downloaded"
                  : "Download"
              }
            >
              <IconButton
                style={{ width: "36px" }}
                sx={
                  e.templateType === "Predefined"
                    ? {
                        "&:hover": {
                          opacity: "0.4",
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }
                    : {
                        "&:hover": {
                          backgroundColor: "#FAF9F6",
                          boxShadow:
                            " 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                        },
                      }
                }
              >
                <ArticleOutlinedIcon />

                <DownloadOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </>
      </div>
    );
  };
  const projectId = JSON.parse(localStorage.getItem("selected-project")).id;
  const defaulTemplateRadioHandler = () => {
    const templateId = defaultTempEvent.row.original._id;
    let { Actions, ...tempTemplateData } = defaultTempEvent.row.original;
    setDefaultTemplate({ ...tempTemplateData });
    updateDefaultTemplateRequest(
      fireFlinkProjectId,
      licenseId,
      tempTemplateData,
      templateId
    )
      .then((results) => {
        if (results.data && results.data.responseCode === 200) {
          props.MyAlert.success(`Default Template updated successfully`);
          setDisableBtn(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error(
            "Update Default Template Error",
            error.response.message
          );
        }
      });
  };

  let columns = React.useMemo(
    () => [
      {
        id: "checkbox-table-column",
        className: "cursor-pointer",
        accessor: "_id",
        width: "3.6%",
        column: [],
        Cell: (e) => (
          <div className="ml-4  -left-3 cursor-pointer">
            {" "}
            <input
              type="radio"
              className="cursor-pointer"
              id={e.row.original._id}
              name="licenses"
              checked={e.row.original._id === defaultTemplate._id}
              value={e.row.original._id}
              disabled={["CLOSED","COMPLETED"].includes(project_Status)}
              onClick={() => {
                e.row.original._id === defaultTemplate._id
                  ? setopenChangeDefaultModal(false)
                  : setTemplateName(e.cell.row.original.name);
                setDefaultTemplateEvent(e);
                if (testCaseDetails.length > 1) {
                  setopenChangeDefaultModal(true);
                }
                setCloseSearchField(true);
                console.log(e.row.original, defaultTemplate);
              }}
            />
          </div>
        ),
      },

      {
        Header: "TEMPLATE NAME",
        accessor: "name",
        width: "20%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              //   event.preventDefault();
              //   setDefectsdeatils(e.cell.row.original);
              //   setDefectsPopUp(true);
              // navigateToDetails()
              navigate("/project/configuration/manual-TestCase-Template/details", {
                state: e.cell.row.original,
                replace: true,
              });
              console.log(e, e.cell);
            }}
            className="dataShowText w-32 table_cell_links"
            style={{ marginLeft: "-75%" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "TEMPLATE TYPE",
        accessor: "templateType",
        width: "15%",
        Cell: ({ value }) => {
          return (
            <div style={{ marginLeft: "-30%" }} className="datatrimmer w-32">
              {value}
            </div>
          );
        },
      },
      {
        Header: "MODIFIED BY",
        accessor: "modifiedByUname",
        width: "15%",
        Cell: ({ value }) => {
          return (
            <div
              title={value}
              style={{ marginLeft: "-22%" }}
              className="datatrimmer w-32"
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "MODIFIED ON",
        accessor: "modifiedOn",
        width: "15%",
        Cell: ({ value }) => {
          return (
            <div style={{ marginLeft: "-14%" }} className="datatrimmer w-32">
              {value}
            </div>
          );
        },
      },
      {
        Header: "ACTIONS",
        accessor: stateColumnHtml,
        width: "15%",
        Cell: (e) => {
          return <div style={{ marginLeft: "-10%" }}>{e.value}</div>;
        },
      },
    ],
    [testCaseDetails, defaultTemplate]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;

  return (
    <div className="testcase-templateList overflow-x-hidden">
      <div className="grid grid-cols-12 flex header content-between">
        <div className="col-span-8 heading pl-3">Manual Testcase Template</div>
        <div className="grid grid-cols-12 col-span-4 flex justify-end">
          <SearchField
            setGlobalFilter={setGlobalFilter}
            closeField={closeSearchField}
            setCloseField={setCloseSearchField}
            colSpan="col-span-7 ml-auto mt-0.5"
          />
          <div className="grid grid-cols-12 col-span-5 addTempBtn opacity-50">
            <Tooltip
              title="Add template"
              
              placement="top"
            >
              <div className="grid grid-cols-12 col-span-11 create_buttton cursor-pointer">
                <span className="col-span-2 ">
                  <AddIcon className="addDefectIcon" />
                </span>
                <span className=" col-span-10 absolute  left-3 mt-2 ml-6">
                 Template
                </span>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="">
        <table {...getTableProps()}>
          <thead className="text-left table_column_header h-10 border-b-2">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th width={column.width} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {
            isLoading===false  && (
              <tbody {...getTableBodyProps()} className="">
              {rows.length > 0 ? (
                <>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="h-10 table_cell_value configHover border-b-2"
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  {/* <div className="noDefectsIcon">
                    <div
                      className="flex justify-center"
                      style={{ marginTop: "10%" }}
                    >
                      <Noreports />
                    </div>
                    <div className="no_defect_reports_msg">
                      No Testcase Template found!
                    </div>
                  </div> */}
                   <div className="no_relevant_data">
                    <img  alt="warning" src={NoRelevantDataIcon}/>
                    <div className="text_msg">No Relevant Data Is Found !</div>
                  </div>
                </>
              )}
            </tbody>
            )
          }

         
        </table>
      </div>
      {openChangeDefaultModal && (
        <SetDefaultModal
          defaulTemplateRadioHandler={defaulTemplateRadioHandler}
          CloseModal={() => {
            setopenChangeDefaultModal(false);
          }}
          message={"Are you sure you want to change default template ?"}
        />
      )}
    </div>
  );
};

export default ManualTestcaseTemplate;
