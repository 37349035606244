import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./modal.scss";
import { SelectDropdown } from "../project/modals/select-dropdown";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MyInput, { Label } from "../common/inputs/myInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { UpdateDefectReq } from "../../api/api-services";

function DefectReasonModal(props) {
  let initialValues = {
    reasonOfReject: "Duplicate",
    reasonSummary: "",
    allowTestersEdit: false,
  };
  let selectedProject = JSON.parse(localStorage["selected-project"]);
  let selectedUser = JSON.parse(localStorage["selected-user"]);
  let actionReject = JSON.parse(localStorage.getItem("user-privilege")).defect
    .actions.reject;
  const validationSchema = yup.object({
    reasonOfReject: yup.string().required("Reason is required"),
  });
  let [instCount, setInstCount] = useState(0);
  const [reasons, setReasons] = useState([
    { label: "Duplicate", value: "Duplicate" },
    { label: "It's a requirement", value: "It's a requirement" },
    { label: "Not a defect", value: "Not a defect" },
    { label: "Others", value: "Others" },
  ]);
  let [reasonValue, setReasonValue] = useState([  { label: "Duplicate", value: "Duplicate" }]);
  function SelectedReason(data) {
    setReasonValue([...data]);
    formikDetails.setFieldValue("reasonOfReject", data[0]?.value);
  }

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "82.5%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderTopLeftRadius: "4px",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };
  const onSubmit = async (values) => {
    let projectId = selectedProject.project.fireFlinkProjectId;
    let licenceID = selectedProject.licenceID;
    let email = selectedUser.email;
    let leadEmail = selectedUser.lead;
    let payload = props.dataForApprove;
    try {
      payload.defectDetails["Assign to"].userEmail =
        payload.defectDetails.history[0].userEmail;
      let tempHistoryObj = {
        userId: selectedUser.id,
        userEmail: email,
        state: actionReject,
        userRole: selectedUser.role,
        lead: leadEmail,
        date: new Date(),
      };
      payload.defectDetails.history.push(tempHistoryObj);
      payload.state = actionReject;
      payload.defectDetails.State = actionReject;
      payload.defectDetails.allowTestersToEdit = values.allowTestersEdit;
      payload.defectDetails.Reason =
        values.reasonOfReject + " - " + values.reasonSummary;

      let defectid = props.dataForApprove.defectDetails.ID;
      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));
      formData.append("email", email);
      let response = await UpdateDefectReq(
        formData,
        projectId,
        licenceID,
        email,
        defectid
      );
      if (response.status === 200) {
        props.MyAlert.success("Defect successfully Updated");
        props.CloseDefectReasonModal(false);
        props?.updatetabeldata(response?.data?.defect)
        // props.callDefects(0);
      } else {
        props.CloseDefectReasonModal(false);
      }
    } catch (err) {
      console.log("error");
      props.CloseDefectReasonModal(false);
    }
  };
  const getUserRole = (email) => {
    let tempUserArr = selectedProject.project.users.userList;
    let tempRole = "";
    tempUserArr.map((val) => {
      if (val.email === email) {
        tempRole = val.role;
      }
    });
    return tempRole;
  };
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    // validationSchema,
  });
  return (
    <Modal
      isOpen={props.CloseDefectReasonModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        props.CloseDefectReasonModal(false);
        // setOpenModal(false);
      }}
    >
      <div className="defect-details">
        <div className="grid grid-cols-12 flex header rounded-tl-xl">
          <div className="col-span-11 heading pl-2">
            Reason for defect rejection
          </div>

          <div className="col-span-1 flex justify-end mr-1">
            <button
              onClick={() => {
                props.CloseDefectReasonModal(false);
              }}
              type="button"
              className="modal_clos_btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="pt-1 body">
          <div className="p-3.5 ">
            <div className="mb-2">
              <Label
                label={<span className="defect_labels">Reason</span>}
                required={true}
                error={
                  formikDetails.errors.reasonOfReject &&
                  formikDetails.touched.reasonOfReject
                }
              />
            </div>
            <div className="popins-medium selectDefectDropDown">
              <SelectDropdown
                data={reasons}
                selectedData={reasonValue}
                searchable={false}
                onChange={SelectedReason}
                onBlur={formikDetails.handleBlur}
              />
            </div>
            {formikDetails.errors.reasonOfReject &&
            formikDetails.touched.reasonOfReject ? (
              <div className="text-red-500 error_text md:mt-1">
                {formikDetails.errors.reasonOfReject}
              </div>
            ) : null}
          </div>
          <div className="pl-5">
            <div className="mb-2">
              <span className="defect_labels">Reason goes here</span>
            </div>
            <div style={{ width: "432px" }}>
              <TextareaAutosize
                name="reasonSummary"
                id="reasonSummary"
                value={formikDetails.values.reasonSummary}
                onChange={formikDetails.handleChange}
                style={{ height: "66px" }}
                onKeyUp={(e) => setInstCount(e.target.value.length)}
                //  rowsMax={noOfRows}
                maxLength="200"
                className="project-description"
              ></TextareaAutosize>
              <div className="mt-1 mr-1 text-sm text-gray-500 text-right">
                {instCount}/200
              </div>
            </div>
          </div>
          <div className="flex pl-5 ">
            <div className="mr-3">
              <input
                type="checkbox"
                id="allowTestersEdit"
                name="allowTestersEdit"
                value={formikDetails.allowTestersEdit}
                onChange={formikDetails.handleChange}
              />
            </div>
            <div>
              <span className="create-labels">
                Allow the testers to update the defect and resubmit it for
                approval....
              </span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 col-span-12 user-details_footer h-12">
          <div className="col-span-10"></div>
          <div className="grid grid-cols-12 col-span-2 flex justify-end relative -pt-2 ml-11">
            <div className="py-2 pt-1 relative px-3 flex justify-end  gap-4 button-content">
              <button
                onClick={() => {
                  props.CloseDefectReasonModal(false);
                }}
                type="button"
                className="w-24 gray-btn  button"
              >
                Cancel
              </button>
              <button
                type="submit"
                id="createBatch"
                className="w-24 primary-btn ml-3 button"
                onClick={formikDetails.handleSubmit}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default DefectReasonModal;
