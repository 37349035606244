import React ,{props} from "react";
import Chart from "react-apexcharts";

function DashBoardGreyChart(props) {
    let data=[1];
    return (
        <Chart 
        type="donut"
        width={550}
        height={300}
        series={data}
        options={{
            chart: {
                type: 'donut',
                // width: 380,
            },
            labels: ["nodata"],
           
            colors: ["#B2BABB"],
            theme: {
                monochrome: {
                    enabled: false
                }
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        size:"70%",
                        labels: {
                            show: true,
                            total: {
                                showAlways: true,
                                show: true,
                                fontFamily:"Poppins-Regular",
                                fontSize:"12px",
                                color: "#6F7075",
                                label: "Total",
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => {
                                      return 0;
                                    }, 0);
                                  },
                            },
                            value: {
                                color:"#434DB8",
                                fontSize:"14px",
                                fontFamily:"Poppins-SemiBold",
                                opacity: "1",
                              }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show:true,
                width:300,
                position: 'right',
                onItemClick: {
                 toggleDataSeries: false
             },
                 formatter: function (val, opts) {
                     return ""
                 },
                 markers:{
                    fillColors:["#FFFFFF"]
                 },

                
            },
            tooltip:{
                enabled: false
            },
            // responsive: [{
            //     breakpoint: 480,
            //     options: {
            //         // chart: {
            //         //     width: 200
            //         // },
            //         legend: {
            //             // width:300,
            //             position: 'right'
            //         }
            //     }
            // }]
        }}
        
        />
    ) 
}

export default DashBoardGreyChart;