import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import {
  getAllJiraList,
  getAllProjectsForDomain,
  getAllAssignableUsers,
  getbugDescription,
  postJiraIssue,
  detDefectDetailsByID
} from "../../../api/api-services";
import Select from "react-dropdown-select";
import { Tooltip, TextField, TextareaAutosize } from "@mui/material";
import styled from "@emotion/styled";
import { CircularLoader } from "../../common/CircularLoader";

function CreateJiraDefect(props) {
  console.log("JiraInstanceCreateModal", props);
  const [openModal, setOpenModal] = useState(true);
  const [detaislData, setDetailsData] = useState(props?.data);
  const [domains, setDomains] = useState([]);
  const [projects, setProjects] = useState([]);
  let projectID = JSON.parse(localStorage["selected-project"]).id;
  const [checkForEmptyDomain, setCheckForEmptyDomain] = useState("");
  const [checkForEmptyProject, setCheckForEmptyProject] = useState("");
  const [checkForEmptyIssueType, setCheckForEmptyIssueType] = useState("");
  const [checkForEmptyAssignee, setCheckForEmptyAssignee] = useState("");
  const [checkForEmptySummary, setCheckForEmptySummary] = useState("");

  const [checkForEmptyPriority, setCheckForEmptyPriority] = useState("");
  const [checkForEmptyDescription, setCheckForEmptyDescription] = useState("");

  const [projectValues, setProjectValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [maxLengthValidationForSummary, setMaxLengthValidationForSummary] =
    useState("");
  const [CallingAPI,setCallingAPI]=useState(false)

  const [selectedDomain, setSelectedDomain] = useState({});
  const [issueTypes, setIssueTypes] = useState([]);
  const [assigneeUsers, setAssigneeUsers] = useState([]);
  const [priorities, setPriorities] = useState([]);
  let projectDetails = JSON.parse(localStorage["selected-project"]);
  let projecttype =
    projectDetails?.project?.projectType?.web !== null
      ? "Web"
      : projectDetails?.project?.projectType?.mobile !== null
      ? "Mobile"
      : projectDetails?.project?.projectType?.webAndMobile !== null
      ? "Web and Mobile"
      : projectDetails?.project?.projectType?.webservice !== null
      ? "Web Service"
      : projectDetails?.project?.projectType?.others !== null
      ? "Others"
      : "Salesforce";

  const [formData, setFormData] = useState({
    domain: "",
    project: "",
    issueType: "",
    summary: "",
    description: "",
    assignee: null,
    priority: null,
  });
  let [SummaryData, setSummarydata] = useState(
    props?.data?.defectDetails?.Summary
  );
  let [platfrom, setPlatfrom] = useState(
    props?.data?.defectDetails?.platformDetails
  );
  let [Description, setDescription] = useState();
  const ref = useRef();
  const refIssueType = useRef();
  const refSummary = useRef("");
  const refAssignee = useRef();
  const refPriority = useRef();
  useEffect(() => {});
  useEffect(() => {
    getALLDomain();
    getDescription();
  }, [props]);
  const getDefectDetails=()=>{
    let ID=props?.data?.defectDetails?.ID;
    let projectID= projectDetails?.project?.fireFlinkProjectId
    let  licenseId= projectDetails?.licenceID;
    detDefectDetailsByID(ID,projectID, licenseId).then((res)=>{
      console.log("response",res)
      if(res?.data?.responseCode===200)
      {
        props?.UpdateJiraID(res?.data?.responseObject)
      }

    }).catch((error)=>{
      console.log(error)
    })
  }
  const getDescription = () => {
    let payload = {
      projectId: projectDetails?.project?.fireFlinkProjectId,
      licenseId: projectDetails?.licenceID,
      defectId: props?.data?.defectDetails?.ID,
    };
    getbugDescription(payload)
      .then((response) => {
        let tempdata = "";
        if (response?.data?.responseCode === 200) {
          let Descriptiondata = `Defect Details \n`;
          response?.data?.responseObject?.map((data) => {
            if (data !== "") {
              Descriptiondata += `${data}\n`;
            }
          });
          let description = `\nPlatform Details :\n`;
          if (projecttype === "Web and Mobile") {
            platfrom.map((item) => {
              if (item?.platformType === "mobile") {
                description += `\nMobile Platform Details :\nBrowser : ${item.browser}\nBrowser Version : ${item.browserVersion}\nOS : ${item.os}\nOS version :  ${item.osVersion}\nDevice Name :  ${item.deviceName} \nDevice brand : ${item.deviceBrand}\nDevice Type : ${item.deviceType}\nCountry : ${item.country}\nState : ${item.state}\nCity : ${item.city}\n`;
              } else {
                description += `\nWeb Platform Details :\nBrowser : ${item.browser}\nBrowser Version : ${item.browserVersion}\nOS : ${item.os}\nOS version :  ${item.osVersion}\nCountry : ${item.country}\nState : ${item.state}\nCity : ${item.city}\n`;
              }
            });
          } else if (projecttype === "Web") {
            platfrom.map((item) => {
              description += `\nBrowser : ${item.browser}\nBrowser Version : ${item.browserVersion}\nOS : ${item.os}\nOS version :  ${item.osVersion}\nCountry : ${item.country}\nState : ${item.state}\nCity : ${item.city}\n`;
            });
          } else if (projecttype === "Mobile") {
            platfrom.map((item) => {
              description += `\nBrowser : ${item.browser}\nBrowser Version : ${item.browserVersion}\nOS : ${item.os}\nOS version : ${item.osVersion}\nDevice Name : ${item.deviceName} \nDevice brand : ${item.deviceBrand}\nDevice Type : ${item.deviceType}\nCountry : ${item.country}\nState : ${item.state}\nCity : ${item.city}\n`;
            });
          } else {
            if (
              projectDetails?.project?.projectType?.others?.typeOfProject ===
              "Standalone_Application"
            ) {
              platfrom.map((item) => {
                description += `\nOS : ${item.os}\nOS Version : ${item.osVersion}\nCountry : ${item.country}\nState : ${item.state}\nCity : ${item.city}\n`;
              });
            } else {
              platfrom.map((item) => {
                description += `\nOS : ${item.os}\nOS Version : ${item.osVersion}\nDevice Name : ${item.deviceName} \nDevice brand : ${item.deviceBrand}\nDevice Type : ${item.deviceType}\nCountry : ${item.country}\nState : ${item.state}\nCity : ${item.city}\n`;
              });
            }
          }
          description += `\nTestYantra Crowd Defect ID : ${props?.data?.defectDetails?.ID}`;
          tempdata = Descriptiondata + description;
        }
        setFormData({
          ...formData,
          summary: SummaryData,
          description: tempdata,
        });
        console.log("response", tempdata);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const contentRenderer = ({ props, state, methods }) => {
    return (
      <div>
        <span className="fontPoppinsRegularMd">
          {state?.values?.length ? (
            state.values[0].label.length > 34 ? (
              <Tooltip title={state.values[0].label}>
                <span>{state.values[0].label.slice(0, 30) + "..."}</span>
              </Tooltip>
            ) : (
              state.values[0].label
            )
          ) : (
            <span className="fontPoppinsRegularSm">{props.placeholder}</span>
          )}
        </span>
      </div>
    );
  };

  const SearchAndToggle = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: #fbfcfd;

    input {
      margin: 6px 10px 6px;
      line-height: 30px;
      padding: 0 5px;
      border: 1px solid #ccc;
      border-radius: 3px;
      :focus {
        outline: none;
        border: 1px solid ${({ color }) => color};
      }
    }
  `;
  const ItemLabel = styled.div`
    margin: 5px 10px;
  `;

  const Item = styled.div`
    display: flex;
    margin: 10px;
    align-items: baseline;
    cursor: pointer;
    border-bottom: 1px dotted transparent;

    ${({ disabled }) =>
      disabled
        ? `
opacity: 0.5;
pointer-events: none;
cursor: not-allowed;
`
        : ""}
  `;
  const dropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(
      state.search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
      "i"
    );
    const options = props.options.filter((item) =>
      regexp.test(item[props.searchBy] || item[props.labelField])
    );
    return (
      <div>
        <SearchAndToggle>
          <input
            type="text"
            value={state.search}
            onChange={methods.setSearch}
            placeholder="Search and select..."
          />
        </SearchAndToggle>
        {options.length === 0 ? (
          <div className="noData pl-5 pt-5 pb-5">No options</div>
        ) : (
          <div className="exe-env-options-dropdown">
            {options.map((option) => {
              return (
                <div key={option[props.labelField]} className="">
                  <Item
                    disabled={option.disabled}
                    key={option[props.valueField]}
                    className=""
                    onClick={
                      option.disabled ? null : () => methods.addItem(option)
                    }
                  >
                    <Tooltip title={option[props.labelField]} placement="top">
                      <ItemLabel className="text-overflow-style">
                        {option[props.labelField]}
                      </ItemLabel>
                    </Tooltip>
                  </Item>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const getALLDomain = () => {
    getAllJiraList(projectID)
      .then((res) => {
        if (
          res.data.responseCode === 200 &&
          res?.data?.responseObject?.length === 0
        ) {
          props?.MyAlert.info("Please Create Jira instance");
        }
        const {
          data: { responseCode },
        } = res;
        if (responseCode === 200) {
          const {
            data: { responseObject },
          } = res;
          setDomains([
            ...responseObject.map((ele) => {
              ele.label = ele.domain;
              ele.value = ele.domain;
              return ele;
            }),
          ]);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };
  const getAllProjects = async (instanceId) => {
    setIsLoading(true);
    try {
      const res = await getAllProjectsForDomain(instanceId);
      console.log("response", res);
      const {
        data: { responseCode },
      } = res;
      if (responseCode === 200) {
        const {
          data: { responseObject },
        } = res;
        setIsLoading(false);
        setProjects([
          ...responseObject.projects?.map((ele) => {
            ele.label = ele.name;
            ele.value = ele.id;
            return ele;
          }),
        ]);
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error occour in GetAllProjects", err);
    }
  };

  const getIssueTypes = (issueTypes) => {
    setIsLoading(true);
    setIssueTypes([
      ...issueTypes
        ?.filter((issue) => !issue?.subtask)
        .map((ele) => {
          ele.label = ele?.name;
          ele.value = ele?.id;
          return ele;
        }),
    ]);
    setIsLoading(false);
  };

  const getAssignees = (instanceid, jiraProjectId) => {
    setIsLoading(true);
    getAllAssignableUsers(instanceid, jiraProjectId)
      .then((res) => {
        const {
          data: { responseCode },
        } = res;
        if (responseCode === 200) {
          const {
            data: { responseObject },
          } = res;
          setIsLoading(false);
          setAssigneeUsers([
            ...responseObject.map((ele) => {
              ele.label = ele.displayName;
              ele.value = ele.accountId;
              return ele;
            }),
          ]);
        }
      })
      .catch((error) => {
        console.log("Error");
      });
  };
  const getPriorities = (priorityTypes) => {
    setIsLoading(true);
    setPriorities([
      ...priorityTypes.map((ele) => {
        ele.label = ele.name;
        ele.value = ele.id;
        return ele;
      }),
    ]);
    setIsLoading(false);
  };
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "80%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  const handleInputChange = (name) => (event) => {
    setFormData({ ...formData, [name]: event.target.value });
  };

  const handleSelectChange =
    (name) =>
    ([obj]) => {
      setFormData({ ...formData, [name]: obj?.value });
      if (name === "domain") {
        setSelectedDomain({ ...obj });
        setProjects([]);
        setIssueTypes([]);
        setPriorities([]);
        setAssigneeUsers([]);
        getAllProjects(obj?.id);
        setProjectValues([]);
        ref.current.clearAll();
        refIssueType.current.clearAll();
        refAssignee.current.clearAll();
        refPriority.current.clearAll();
      } else if (name === "project") {
        console.log("obj1", obj);
        console.log("selectedDomain", selectedDomain);
        setProjectValues([
          {
            label: obj?.name,
            value: obj?.id,
          },
        ]);
        setIssueTypes([]);
        setPriorities([]);
        setAssigneeUsers([]);
        getIssueTypes(obj?.issuetypes);
        getAssignees(selectedDomain?.id, obj?.value);
        refIssueType.current.clearAll();
        refAssignee.current.clearAll();
        refPriority.current.clearAll();
      } else if (name === "issueType") {
        setPriorities([]);
        getPriorities(obj?.fields?.priority?.allowedValues || []);
      }
    };

  const createjiraDefect = async () => {
    setCallingAPI(true)
    let jiraDto = {
      assignee: formData?.assignee===null?null:{
        id: formData?.assignee,
      },
      description: formData?.description,

      issuetype: {
        id: formData?.issueType,
      },
      priority: formData?.priority===null?null:{
        id: formData?.priority,
      },
      project: {
        id: formData?.project,
      },
      summary: formData?.summary,
    
      licenseId: projectDetails?.licenceID,
      projectId: projectDetails?.project?.fireFlinkProjectId,
      defectId: props?.data?.defectDetails?.ID,
      attachmentList: props?.attchmentList
    };
    let instanceId = selectedDomain?.id;
    

    const formfieldData = new FormData();
    formfieldData.append("jiraDto", JSON.stringify(jiraDto));
    formfieldData.append("instanceId", instanceId);

    if (formData?.summary.length > 255) {
      setMaxLengthValidationForSummary("Summary can't exceed 255 characters");
      setCallingAPI(false)
      return true;
    }
    if (
      !Object.entries(formData).filter((ele) => {
        return (ele[0] !== "priority" &&  ele[0] !== "assignee") && (ele[1] === null || ele[1] === "");
      }).length
    ) {
      console.log("i am in");
      // setisLoadingBtn(true);
      try {
        const response = await postJiraIssue(formfieldData);
        console.log("res", response);
        if (response && response.data.responseCode === 200) {
          setCallingAPI(false)
          getDefectDetails()
           props?.MyAlert.success("Defect created successfully in jira");
           props.closeModal(false);
         
        } else if(response.data.responseCode=== 403){
          setCallingAPI(false)
          props?.MyAlert.danger(response?.data?.message);
         props.closeModal(false);
        }
        else {
          setCallingAPI(false)
          props?.MyAlert.info("Something went wrong please try again later");
          props.closeModal(false);
          // MyAlert.warning(res.data.message);
          // setisLoadingBtn(false);
        }
      } catch (err) {
        console.log("");
      }
    } else {
      setCallingAPI(false);
      setCheckForEmptyDomain("Domain Name is required");
      setCheckForEmptyProject("Project Name is required");
      setCheckForEmptyIssueType("Issue Type is required");
      setCheckForEmptySummary("Summary field is required");
      // setCheckForEmptyAssignee("Assignee field is required");
      // setCheckForEmptyPriority("Priority field is required");
      setCheckForEmptyDescription("Description field is required");
    }
  };

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px">
      <div className="create_instance">
        <div className="grid grid-cols-12  rounded-tl-xl flex header">
          <span className="col-span-11 heading pl-2.5">
            {" "}
            Create jira Defects
          </span>
          <div className="col-span-1 flex justify-end mr-1">
            <button
              onClick={() => {
                props.closeModal(false);
              }}
              type="button"
              className="modal_clos_btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="px-2 pt-2 body">
          <div className="mb-5">
            <div>
              <span className="text-red-600 mr-0.5">*</span>
              <span className="create-labels">Domain</span>
            </div>
            <div className="mt-1"></div>
            <div className="mt-1">
              <Select
                className="fontPoppinsRegularMd"
                options={domains.length ? domains : []}
                placeholder={"Select domain"}
                value={formData.domain}
                onChange={(values) => {
                  if (values.length) {
                    handleSelectChange("domain")(values);
                    setCheckForEmptyDomain("");
                  } else {
                    formData.domain = null;
                    setCheckForEmptyDomain("Domain Name is required");
                  }
                }}
                required
                contentRenderer={contentRenderer}
                dropdownRenderer={(innerProps, innerState, innerMethods) =>
                  dropdownRenderer(innerProps, innerState, innerMethods)
                }
              />
              {!formData?.domain && (
                <div className="errorMessage">{checkForEmptyDomain}</div>
              )}
            </div>
          </div>

          <div className="my-5">
            <div>
              <span className="text-red-600 mr-0.5">*</span>
              <span className="create-labels">Project</span>
            </div>
            <div className="mt-1"></div>
            <div className="mt-1">
              <Select
                ref={ref}
                className="fontPoppinsRegularMd"
                options={projects.length ? projects : []}
                placeholder={"Select project"}
                values={
                  projectValues.length > 0
                    ? projectValues.map((projectVal) => projectVal.label)
                    : []
                }
                defaultValue={""}
                onChange={(values) => {
                  if (values.length) {
                    handleSelectChange("project")(values);
                    setCheckForEmptyProject("");
                  } else {
                    formData.project = null;
                  }
                }}
                disabled={isLoading}
                required
                contentRenderer={contentRenderer}
                dropdownRenderer={(innerProps, innerState, innerMethods) =>
                  dropdownRenderer(innerProps, innerState, innerMethods)
                }
              />
              {!formData?.project && (
                <div className="errorMessage">{checkForEmptyProject}</div>
              )}
            </div>
          </div>

          <div className="my-5">
            <div>
              <span className="text-red-600 mr-0.5">*</span>
              <span className="create-labels">Issues Type</span>
            </div>
            <div className="mt-1"></div>
            <div className="mt-1">
              <Select
                ref={refIssueType}
                className="fontPoppinsRegularMd"
                options={issueTypes.length ? issueTypes : []}
                placeholder={"Select issue type"}
                value={formData.issueType}
                onChange={(values) => {
                  if (values.length) {
                    handleSelectChange("issueType")(values);
                  } else {
                    formData.issueType = null;
                  }
                }}
                disabled={isLoading}
                required
                contentRenderer={contentRenderer}
                dropdownRenderer={(innerProps, innerState, innerMethods) =>
                  dropdownRenderer(innerProps, innerState, innerMethods)
                }
              />
              {!formData?.issueType && (
                <div className="errorMessage">{checkForEmptyIssueType}</div>
              )}
            </div>
          </div>

          <div className="my-5">
            <div>
              {/* <span className="text-red-600 mr-0.5">*</span> */}
              <span className="create-labels">Assignee</span>
            </div>
            <div className="mt-1"></div>
            <div className="mt-1">
              <Select
                ref={refAssignee}
                className="fontPoppinsRegularMd"
                options={assigneeUsers.length ? assigneeUsers : []}
                placeholder={"Select assignee"}
                value={formData.assignee}
                onChange={(values) => {
                  if (values.length) {
                    handleSelectChange("assignee")(values);
                  } else {
                    formData.assignee = null;
                  }
                }}
                required
                disabled={isLoading}
                commented
                for
                implementation
                of
                seachable
                option
                with
                tooltip
                contentRenderer={contentRenderer}
                dropdownRenderer={(innerProps, innerState, innerMethods) =>
                  dropdownRenderer(innerProps, innerState, innerMethods)
                }
              />
              {/* {!formData?.assignee && (
                <div className="errorMessage">{checkForEmptyAssignee}</div>
              )} */}
            </div>
          </div>

          <div className="my-5">
            <div>
              {/* <span className="text-red-600 mr-0.5">*</span> */}
              <span className="create-labels">Priority</span>
            </div>
            <div className="mt-1"></div>
            <div className="mt-1">
              <Select
                ref={refPriority}
                className="fontPoppinsRegularMd"
                options={priorities.length ? priorities : []}
                placeholder={"Select priority"}
                value={formData.priority}
                onChange={(values) => {
                  if (values.length) {
                    handleSelectChange("priority")(values);
                  } else {
                    formData.priority = null;
                  }
                }}
                disabled={isLoading}
                contentRenderer={contentRenderer}
                dropdownRenderer={(innerProps, innerState, innerMethods) =>
                  dropdownRenderer(innerProps, innerState, innerMethods)
                }
              />
              {/* {!formData?.priority && (
                <div className="errorMessage">{checkForEmptyPriority}</div>
              )} */}
            </div>
          </div>

          <div className="my-5">
            <div>
              <span className="text-red-600 mr-0.5">*</span>
              <span className="create-labels">Summary</span>
            </div>

            <div className="mt-1">
              <TextField
                error={
                  formData?.summary.length > 255 &&
                  maxLengthValidationForSummary
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => {
                  console.log(props, props.editDetails);
                }}
                type="text"
                name="summary"
                id="summary"
                autoComplete="off"
                placeholder="Enter Summary"
                className="create-project-values"
                disabled={isLoading}
                onChange={handleInputChange("summary")}
                value={formData.summary}
                variant="outlined"
                size="small"
              />
              {formData?.summary.length > 255 && (
                <div className="errorMessage">
                  {maxLengthValidationForSummary}
                </div>
              )}
              {!formData?.summary && (
                <div className="errorMessage">{checkForEmptySummary}</div>
              )}
            </div>
          </div>

          <div className="my-5">
            <div>
              <span className="text-red-600 mr-0.5">*</span>
              <span className="create-labels">Description</span>
            </div>

            <div className="mt-1">
              <TextareaAutosize
                aria-label="minimum height"
                // className="rs-input-style-textarea"
                style={{
                  height: "210px",
                  overflow: "auto",
                  resize: "none",
                  background: "none",
                  fontSize: "12px",
                  border: "1px solid #dbd8d8",
                  borderRadius: "4px",
                  width: "100%",
                  paddingLeft: "4px",
                  fontFamily: "Poppins-Regular",
                }}
                minRows={3}
                placeholder="Enter description..."
                onChange={handleInputChange("description")}
                disabled={isLoading}
                value={formData.description}
              />
              {!formData?.description && (
                <div className="errorMessage">{checkForEmptyDescription}</div>
              )}
            </div>
          </div>
          {isLoading && (
            <div className="jira-defect-loader">
              <CircularLoader />
            </div>
          )}
        </div>
        <>
          <div className="pt-2 px-3 flex justify-end gap-4 button-content modal_footer">
            <button
              type="button"
              className="w-24 gray-btn ml-3 button"
              onClick={() => {
                props.closeModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="w-24 primary-btn  ml-3 button"
              disabled={CallingAPI}
              onClick={() => {
                // props.closeModal(false);
                createjiraDefect();
              }}
            >
              Create
            </button>
          </div>
        </>
      </div>
    </Modal>
  );
}
export default CreateJiraDefect;
