import React, { useEffect, useState } from "react";
import { getProjectDetails, downloadFileRequest,getprojectList, getUserDetailsByEmailID} from "../../api/api-services";
import Add from "@mui/icons-material/Add";
import { useNavigate, useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import { saveAs } from 'file-saver';
import { ReactComponent as Edit } from "../../assets/edit_white.svg";
import { ReactComponent as Attachments } from "../../assets/attachment_icon.svg";
import { ReactComponent as DownLoad } from "../../assets/download_icon.svg";
import ProjectCreate from "./project-create";
import { useAlert } from "../common/alert-service/useAlert";
import PlatformModal from "../project/modals/platform-modal";
import SupportingDocsPopup from "../project/modals/supprotnigndocsPopUp";
import DeviceDetector from "device-detector-js";
import AddTestingDeviceModal from "./modals/add-testingDevice-modal";
import RichTextEditor from "../config/rich-texteditor";
import { ReactComponent as EditIcon } from "../../assets/edit_blue.svg";
import EditExectiveReportsPopup from "./modals/edit-executive-reports";
import { TextareaAutosize } from "@material-ui/core";
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { saveSelectedProject } from "../../actions/firecrowd-User-slice";


const Android = "Android";
const Ios = "iOS";
const AndroidandIos = "Android & iOS";

function ProjectDetails(props) {
  let [projectDetails, setProjectDetails] = useState({});
  let [expandprojectDesc, setExpandprojectDesc] = useState(false);
  let [expandInstruction, setExpandInstruction] = useState(false);
  const [privilageApiResp, setPrivilageApiResp] = useState({});
  let [dataLoaded, setDataLoaded] = useState(false);
  let [pltafromDetails, setPlatformDetails] = useState(false);
  let [moretypes, setMoreTypes] = useState(false);
  let [supportingDocs, setSupportingDocs] = useState(false);
  const location = useLocation();
  let [editProjectModal, setEditProjectModal] = useState(false);
  const { AlertContatiner, MyAlert } = useAlert();
  // const tempDetails = JSON.parse(localStorage["selected-project"]).id;

  const tempDetails = JSON.parse(localStorage["selected-projectId-Name"]).id;
  let [accountmanager, setAccountmanager] = useState("--");
  let [myRole, setMyrole] = useState();
  let [customer, setCustomer] = useState("--");
  // let [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);
  const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);
  const [editReport,setEditReport]=useState(false);
  let Role=JSON.parse(localStorage["selected-projectId-Name"]).role;
  

  const { userAgent } = navigator;

  const deviceDetector = new DeviceDetector();

  let [ProjectNameList,setProjectNameList]=useState([]);
  let [userList,setUserList]=useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    console.log("projectdetails", tempDetails);
    // const projectId = JSON.parse(localStorage["selected-project"]).id;
    const projectId = JSON.parse(localStorage["selected-projectId-Name"]).id;
    ProjectDetailsAPI(projectId);
    UserDetails()

  }, [tempDetails]);
  useEffect(() => {
    setPrivilageApiResp(
      localStorage.hasOwnProperty("user-privilege")
        ? JSON.parse(localStorage["user-privilege"])
        : {}
    );
  }, [localStorage["user-privilege"]]);

  // useEffect(() => {
  //    getplatform();
   
  // }, []);

  const closePlatForm = () => {
    setOpenAddDeviceModal(false);
  };
  function onCloseAddPlatform(status) {
    setOpenAddDeviceModal(status);
    
  }

  function oncloseReportModal(status){
    setEditReport(status)
  }

  const  UserDetails = async ()=>{
    let projectId = JSON.parse(localStorage["selected-projectId-Name"]).id;
    let userId = JSON.parse(localStorage["fire-crowd-user"]).userName;
    try{
      let response= await getUserDetailsByEmailID(projectId, userId);
      if(response.status===200){
        setMyrole(response.data.role);
        localStorage.setItem("selected-user", JSON.stringify(response.data))
        if(response.data.role==="TESTER" && response.data.configuration===null){
          setOpenAddDeviceModal(true);
        }else{
          setOpenAddDeviceModal(false);

        }
      }
      

    }catch(error){
      console.log("error")
    }
  }

  
  

  const ProjectDetailsAPI = async (e) => {
  
    setProjectDetails()
    try {
      const response = await getProjectDetails(e);

      if (response.status === 200) {
        console.log("project Deatils", response);
        
        setDataLoaded(true);
        setProjectDetails(response.data);
        localStorage.setItem("selected-project", JSON.stringify(response.data));
        dispatch(saveSelectedProject(response.data))
        setUserList(response.data.project.users.userList)
        response?.data?.project?.users?.userList?.map((user) => {
          if (user.role === "ACCOUNT_MANAGER") {
            setAccountmanager(user.name);
          }
          if (user.role === "PROJECT_OWNER") {
            setCustomer(user.name);
          }
          
          
        });

        
      }
    } catch (e) {
      console.log("error");
    }
  };

  function onEditProjectModalClose(status) {
    setEditProjectModal(status);
  }
  function functiontypeModalClose(status) {
    setMoreTypes(status);
  }
  function supportingDocsModalClose(status) {
    setSupportingDocs(status);
  }
  const downloadFiles = async (path) => {
    try {
      const response = await downloadFileRequest(path);
      console.log("file response", response);
      if (response.status === 200) {
        let requestURLArr = response?.request?.responseURL.split("/");
        console.log("requestURLArr", requestURLArr[requestURLArr.length - 1]);
        // const url = window.URL.createObjectURL(
        //   new Blob([response.data]),
        // );
        const link = document.createElement("a");
        link.href = response?.request?.responseURL;
        // link.setAttribute(
        //   'download',
        //   requestURLArr[requestURLArr.length-1],
        // );
        link.download = requestURLArr[requestURLArr.length - 1];

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const getAllProjectList =async ()=>{
    let email=JSON.parse(localStorage["fire-crowd-user"]).userName;
    try{
      let response =await getprojectList(email);
      if(response.status===200){
        setProjectNameList(response.data)
        setEditProjectModal(true)
        
      }
        
      
    }catch(error){
      console.log("error")
    }
  }
  function getRole(role){
    switch(role){
      case "PROJECT_OWNER":{
        return "Project Owner"
      }
      case "PROJECT_MANAGER":{
        return "Project Manager"
      }
      case "ACCOUNT_MANAGER":{
        return "Account Manager"
      }
      case "TEST_LEAD":{
        return "Test Lead"
      }
      case "TESTER":{
        return "Test Engineer"
      }
      case "ADMIN":{
        return "Admin"
      }
      default:{
        return role;
      }
    }

  }
  function getstatus(val){
    switch(val){
      case "INREVIEW":{
        return "Inreview"
        break;
      }
      case "APPROVED":{
        return "Approved"
        break;
      }
      case "INPROGRESS":{
        return "In-Progress"
        break;
      }
      case "REJECTED":{
        return "Rejected"
        break;
      }
      case "CLOSED":{
        return "Closed"
        break;
      }
      default:{
        return val
        break;
      }
    

    }
  }

  function getCerificateValidation(label){
    switch(label){
      case  "All_Testers" :
        return([{ label: "All Testers", value: "All_Testers" }])
    
        case "Testers_Platforms" :
          return "Only Platforms";
   
          case "Testers_Defects" :
          return "Only Defects";
    
          case "Testers_Feedback" :
          return "Only Feedback";
       
          case "Testers_Platform_Feedback" :
          return "Platform with Feedback";
        
          case "Testers_Feedback_Defects" :
          return "Defects with Feedback";
       
          case "Testers_Feedback_Defects_Three" :
          return "Minimum Three Defects with Feedback"
        
          case "Testers_Feedback_Defects_Five" :
          return "Minimum Five Defects with Feedback"
     
          case "Testers_Defects_Three" :
          return "Minimum Three Defects"
          
          case "Testers_Defects_Five" :
          return "Minimum Five Defects"
        

        default:
          break;

    }
  }
  return (
    projectDetails !== undefined && (
      <div className="project_details_page">
        <AlertContatiner />
        {privilageApiResp?.overview?.actions?.edit === true ? (
          <div className="grid grid-cols-12 flex header ">
            <div className="col-span-11 heading pl-3" onClick={()=>{console.log(projectDetails?.projectStatus,myRole)}}>
              Project Details-{projectDetails?.project?.projectName}
            </div>
            <Tooltip  title={myRole === "ACCOUNT_MANAGER" &&
                  projectDetails?.projectStatus === "REJECTED"?"Project is in rejected state":"Edit"}>
            <div
              className={
                (myRole === "ADMIN" &&
                  projectDetails?.projectStatus !== "COMPLETED") ||
                (myRole === "PROJECT_OWNER" &&
                  (projectDetails?.projectStatus === "INREVIEW" ||
                    projectDetails?.projectStatus === "APPROVED" ||
                    projectDetails?.projectStatus === "INPROGRESS")) ||
                (myRole === "ACCOUNT_MANAGER" &&
                  (projectDetails?.projectStatus === "APPROVED" ||
                    projectDetails?.projectStatus === "INPROGRESS"))
                  ? "grid grid-cols-12 col-span-1 create_buttton cursor-pointer mr-2"
                  : "grid grid-cols-12 col-span-1 edit_btn cursor-pointer mr-2"
              }
              onClick={() => {
                (myRole === "ADMIN" &&
                  projectDetails?.projectStatus !== "COMPLETED") ||
                (myRole === "PROJECT_OWNER" &&
                  (projectDetails?.projectStatus === "INREVIEW" ||
                    projectDetails?.projectStatus === "APPROVED" ||
                    projectDetails?.projectStatus === "INPROGRESS")) ||
                (myRole === "ACCOUNT_MANAGER" &&
                  (projectDetails?.projectStatus === "APPROVED" ||
                    projectDetails?.projectStatus === "INPROGRESS"))
                  ?  setEditProjectModal(true)
                  : setEditProjectModal(false);
              }}
            >
              <div className="col-span-2 edit_icon">
                {/* <Add /> */}
                <Edit />
              </div>
              <div className="col-span-5  edit_text">Edit</div>
            </div>
            </Tooltip>
            
          </div>
        ) : (
          <div className="grid grid-cols-12 flex header">
            <div className="grid grid-cols-12 col-span-12 heading pl-3">
              <span className="project-details-heading">
                Project Details-{projectDetails?.project?.projectName}
              </span>
            </div>
          </div>
        )}

        {dataLoaded ? (
          <div className="pageContainer">
            <div className="grid grid-cols-12 flex mt-4 pl-6 ">
              <div className="col-span-4 ">
                <div className="labels">Name</div>
                <div className="values">
                  {projectDetails?.project?.projectName}
                </div>
              </div>
              <div className="col-span-4 ">
                <div className="labels">Account Manager</div>
                <div className="values">
                  {accountmanager}
                  {/* {projectDetails?.projectStatus === "INREVIEW" ? (
                    <div>--</div>
                  ) : (
                    <>
                      {projectDetails?.project?.users.userList.map((user) => {
                        if (user.role === "ACCOUNT_MANAGER") {
                          return <div>{user.name}</div>;
                        }
                      })}
                    </>
                  )} */}
                </div>
              </div>

              <div className="col-span-4 project_status">
                <div className="labels">Project Status</div>
                <div
                  className={
                    projectDetails?.projectStatus === "INREVIEW"
                      ? "status_inreview "
                      : projectDetails?.projectStatus === "APPROVED"
                      ? "status_approved"
                      : projectDetails?.projectStatus === "REJECTED"
                      ? "status_rejected"
                      : projectDetails?.projectStatus === "INPROGRESS"
                      ? "status_inprogress": projectDetails?.projectStatus === "CLOSED"?
                       "status_close":"status_completed"
                  }
                >
                
                    {getstatus(projectDetails?.projectStatus)}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 flex mt-4 pl-6 ">
              <div className="col-span-4 ">
                <div className="labels">My Role</div>
                <div className="values">{getRole(Role)}</div>
                
              </div>
              <div className="col-span-4 ">
                <div className="labels">Domain Name</div>
                <div className="values">{projectDetails?.domainName===null?"--":projectDetails?.domainName}</div>
              </div>

              <div className="col-span-4 project_status">
                <div className="labels">Customer Name</div>
                <div className="values">{customer}</div>
              </div>
            </div>
            <div className="grid grid-cols-12 mt-4 pl-6">
              <div className="col-span-11">
                <div className="labels">Description</div>
                <div className="description_value text-justify">
                  {(projectDetails?.project?.projectDesc === "" || projectDetails?.project?.projectDesc ===null) ? (
                    "--"
                  ) : expandprojectDesc ? (
                    <div
                      className="description cursor-pointer"
                      onClick={() => {
                        setExpandprojectDesc(false);
                      }}
                    >
                      {projectDetails?.project?.projectDesc}
                    </div>
                  ) : (
                    
                    <div>
                      <TextareaAutosize
                    value={projectDetails?.project?.projectDesc}
                    style={{width:"100%",resize:"none",outline:"none"}}
                    disabled={true}
                    className="tester_instruction"
                    />
                    </div>
                    
                  )}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 flex mt-4 pl-6">
              <div className="col-span-4">
                <div className="labels">Event Start Date</div>
                <div className="values">
                  {projectDetails?.startDate
                    ? dateFormat(
                        projectDetails?.startDate.slice(0, 10),
                        "dd/mm/yyyy"
                      )
                    : "--"}
                </div>
              </div>
              <div className="col-span-4">
                <div className="labels">Event Duration</div>
                <div className="values">{projectDetails?.duration} {""} {projectDetails?.duration===1?"Day":"Days"}</div>
              </div>
              <div className="col-span-4">
                <div className="labels">Number Of Test Engineers</div>
                <div className="values">{projectDetails?.numberOfTesters} {""} {projectDetails?.numberOfTesters===1?"Test Engineer":"Test Engineers"}</div>
              </div>
            </div>
            <div className="grid grid-cols-12 flex mt-4 pl-6">
              <div className="grid grid-cols-12 col-span-4">
                <div className="labels col-span-12">Execution Type</div>
                <div className="values col-span-12">
                  {projectDetails?.executionDetails?.guideType}
                </div>
                <div className="files_value  grid grid-cols-12 col-span-12">
                  {projectDetails?.executionDetails?.testCases === "" ||
                  projectDetails?.executionDetails?.testCases === null ? (
                    ""
                  ) : (
                    <>
                      <div className="attachment_icon">
                        <Attachments />
                      </div>
                      <div
                        className="datatrimmer col-span-5"
                        title={projectDetails?.executionDetails?.testCases?.slice(
                          projectDetails?.executionDetails?.testCases?.lastIndexOf(
                            "/"
                          ) + 1,
                          projectDetails?.executionDetails?.testCases?.length
                        )}
                      >
                        {projectDetails?.executionDetails?.testCases?.slice(
                          projectDetails?.executionDetails?.testCases?.lastIndexOf(
                            "/"
                          ) + 1,
                          projectDetails?.executionDetails?.testCases?.length
                        )}
                      </div>
                      <span
                        onClick={() => {
                          downloadFiles(
                            projectDetails?.executionDetails?.testCases
                          );
                        }}
                        className="-mt-2 cursor-pointer"
                      >
                        <DownLoad />
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className=" grid grid-cols-12 col-span-4">
                <div className="labels col-span-12">Testing Type</div>
                <div className="values  col-span-12 ">
                  {projectDetails?.testingTypes?.length === 1 ? (
                    <div>
                      {(projectDetails?.testingTypes[0]
                        ?.charAt(0)
                        .toUpperCase() +
                        projectDetails?.testingTypes[0]
                          ?.slice(1)
                          .toLowerCase())
                          .replaceAll("_", "-")}
                    </div>
                  ) : (
                    <div className="flex">
                      <div className="datatrimmer w-22">
                        {projectDetails?.testingTypes[0]
                          ?.charAt(0)
                          .toUpperCase() +
                          projectDetails?.testingTypes[0]
                            ?.slice(1)
                            .toLowerCase()
                            .replace("_", "-")}
                      </div>
                      <div
                        className="more_btn ml-3"
                        onClick={() => {
                          setMoreTypes(true);
                        }}
                      >
                        {projectDetails?.testingTypes?.length - 1} more
                      </div>
                    </div>
                  )}
                 
                </div>
              </div>
              <div className="col-span-4">
                <div className="labels">Project Type</div>
                <div className="values">
                  {projectDetails?.project?.projectType?.web !== null
                    ? "Web"
                    : projectDetails?.project?.projectType?.mobile !== null
                    ? "Mobile"
                    : projectDetails?.project?.projectType?.webAndMobile !==
                      null
                    ? "Web and Mobile"
                    : projectDetails?.project?.projectType?.webservice !== null
                    ? "Web Service"
                    : "Others"}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 flex  pl-6">
              <div className="col-span-3 values" title={projectDetails?.executionDetails?.uploadTestCaseByTestersAllowed ===
                false
                  ? ""
                  : "Allow crowd test engineers to explore and add additional testcase"}>
              
                {projectDetails?.executionDetails?.uploadTestCaseByTestersAllowed ===
                false
                  ? ""
                  : "Allow crowd test engineers to explore and add additional testcase"}
              
              </div>
            </div>
            {/*====== project type is web======= */}
            {projectDetails?.project?.projectType?.web !== null && (
              <div className="grid grid-cols-12 flex mt-4 pl-6">
                <div className="grid grid-cols-12 col-span-4">
                  <div className="col-span-10 labels">Web URL</div>
                  <div
                    className="col-span-10 values datatrimmer "
                    title={projectDetails?.project?.projectType?.web?.weburl}
                  >
                    {projectDetails?.project?.projectType?.web?.weburl === "" ||
                    projectDetails?.project?.projectType?.web?.weburl === null
                      ? "--"
                
                     : <a
            href={projectDetails?.project?.projectType?.web?.weburl}
            target="_blank"
            className="label-overflow tab-style-common"
          >
            {projectDetails?.project?.projectType?.web?.weburl}
          </a>}
                  </div>
                </div>
                <div className="grid grid-cols-12 col-span-3">
                  <div className="labels col-span-12">Supporting Documents</div>
                  <div className="files_value supporting_doc grid grid-cols-12 col-span-12 mt-2">
                    {projectDetails?.executionDetails?.attachments !== null &&
                    projectDetails?.executionDetails?.attachments?.length !==
                      0 ? (
                      <>
                        {projectDetails?.executionDetails?.attachments
                          ?.length === 1 ? (
                          <>
                            <div className="attachment_icon">
                              <Attachments />
                            </div>
                            <div
                              className="col-span-10 datatrimmer"
                              title={projectDetails?.executionDetails?.attachments[0]?.slice(
                                projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                  "/"
                                ) + 1,
                                projectDetails?.executionDetails?.attachments[0]
                                  ?.length
                              )}
                            >
                              {projectDetails?.executionDetails?.attachments[0]?.slice(
                                projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                  "/"
                                ) + 1,
                                projectDetails?.executionDetails?.attachments[0]
                                  ?.length
                              )}
                            </div>
                            <span
                              onClick={() => {
                                downloadFiles(
                                  projectDetails?.executionDetails
                                    ?.attachments[0]
                                );
                              }}
                              className="-mt-2 cursor-pointer"
                            >
                              <DownLoad />
                            </span>
                          </>
                        ) : (
                          <div className="flex col-span-11 ">
                            <div className="attachment_icon">
                              <Attachments />
                            </div>
                            <div className="datatrimmer w-20">
                              {projectDetails?.executionDetails?.attachments[0]?.slice(
                                projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                  "/"
                                ) + 1,
                                projectDetails?.executionDetails?.attachments[0]
                                  ?.length
                              )}
                            </div>
                            <span
                              onClick={() => {
                                downloadFiles(
                                  projectDetails?.executionDetails
                                    ?.attachments[0]
                                );
                              }}
                              className="-mt-2 cursor-pointer"
                            >
                              <DownLoad />
                            </span>
                            <div
                              className="more_btn ml-3"
                              onClick={() => {
                                setSupportingDocs(true);
                              }}
                            >
                              {projectDetails?.executionDetails?.attachments
                                ?.length - 1}{" "}
                              more
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      "--"
                    )}
                  </div>
                </div>
               
              </div>
            )}
            {/* =======project type is mobile===== */}
            {projectDetails?.project?.projectType?.mobile !== null && (
              <>
                <div className="grid grid-cols-12 flex mt-4 pl-6">
                  <div className="col-span-4">
                    <div className="labels">App Type</div>
                    <div className="values">
                      {projectDetails?.project?.projectType?.mobile?.appType}
                    </div>
                  </div>
                  <div className="col-span-4">
                    <div className="labels">Platform</div>
                    <div className="values">
                      {
                        projectDetails?.project?.projectType?.mobile?.platform
                          ?.platformType
                      }
                    </div>
                  </div>
                  <div className="col-span-4">
                    {projectDetails?.project?.projectType?.mobile?.platform
                      ?.platformType === Android ||
                    projectDetails?.project?.projectType?.mobile?.platform
                      ?.platformType === AndroidandIos ? (
                      <>
                        <div className="labels">App Package</div>
                        <div title={projectDetails?.project?.projectType?.mobile
                            ?.platform?.appPackage} className="values">
                          {projectDetails?.project?.projectType?.mobile
                            ?.platform?.appPackage === "" ||
                          projectDetails?.project?.projectType?.mobile?.platform
                            ?.appPackage === null
                            ? "--"
                            : projectDetails?.project?.projectType?.mobile
                                ?.platform?.appPackage}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="labels">Bundle ID</div>
                        <div title={projectDetails?.project?.projectType?.mobile
                            ?.platform?.bundleID} className="values">
                          {projectDetails?.project?.projectType?.mobile
                            ?.platform?.bundleID === "" ||
                          projectDetails?.project?.projectType?.mobile?.platform
                            ?.bundleID === null
                            ? "--"
                            : projectDetails?.project?.projectType?.mobile
                                ?.platform?.bundleID}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {projectDetails?.project?.projectType?.mobile?.platform
                  ?.platformType === Android && (
                  <>
                    <div className="grid grid-cols-12 flex mt-4 pl-6">
                      <div className="col-span-4">
                        <div className="labels">App Activity</div>
                        <div title={projectDetails?.project?.projectType?.mobile
                            ?.platform?.appActivity} className="values">
                          {projectDetails?.project?.projectType?.mobile
                            ?.platform?.appActivity === "" ||
                          projectDetails?.project?.projectType?.mobile?.platform
                            ?.appActivity === null
                            ? "--"
                            : projectDetails?.project?.projectType?.mobile
                                ?.platform?.appActivity}
                        </div>
                      </div>
                      <div className="grid grid-cols-12 col-span-4">
                      <div className="labels col-span-8">APK File</div>
                        <div className="files_value grid grid-cols-12 col-span-8">
                          {projectDetails?.project?.projectType?.mobile
                            ?.platform?.uploadApk === "" ||
                          projectDetails?.project?.projectType?.mobile?.platform
                            ?.uploadApk === null ? (
                            "--"
                          ) : (
                            <>
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-8 datatrimmer"
                                title={projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadApk.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadApk.length
                                )}
                              </div>
                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType?.mobile
                                      ?.platform?.uploadApk
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-12 col-span-3">
                        <div className="labels col-span-12">
                          Supporting Documents
                        </div>
                        <div className="files_value supporting_doc grid grid-cols-12 col-span-12 mt-2">
                          {projectDetails?.executionDetails?.attachments !==
                            null &&
                          projectDetails?.executionDetails?.attachments
                            ?.length !== 0 ? (
                            <>
                              {projectDetails?.executionDetails?.attachments
                                ?.length === 1 ? (
                                <>
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div
                                    className="col-span-10 datatrimmer"
                                    title={projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  >
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                </>
                              ) : (
                                <div className="flex col-span-10 ">
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div className="datatrimmer w-20">
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                  <div
                                    className="more_btn ml-3"
                                    onClick={() => {
                                      setSupportingDocs(true);
                                    }}
                                  >
                                    {projectDetails?.executionDetails
                                      ?.attachments?.length - 1}{" "}
                                    more
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            "--"
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {projectDetails?.project?.projectType?.mobile?.platform
                  ?.platformType === Ios && (
                  <>
                    <div className="grid grid-cols-12 flex mt-4 pl-6">
                      <div className="grid grid-cols-12 col-span-4">
                      <div className="labels col-span-8">IPA FILE</div>
                        <div className="files_value col-span-8">
                          {projectDetails?.project?.projectType?.mobile
                            ?.platform?.uploadIPA === "" ||
                          projectDetails?.project?.projectType?.mobile?.platform
                            ?.uploadIPA === null ? (
                            "--"
                          ) : (
                            <div className="flex">
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-8 datatrimmer"
                                title={projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadIPA.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadIPA.length
                                )}
                              </div>

                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType?.mobile
                                      ?.platform?.uploadIPA
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-12 col-span-3">
                        <div className="labels col-span-12">
                        Supporting Documents
                        </div>
                        <div className="files_value supporting_doc grid grid-cols-12 col-span-12 mt-2">
                          {projectDetails?.executionDetails?.attachments !==
                            null &&
                          projectDetails?.executionDetails?.attachments
                            ?.length !== 0 ? (
                            <>
                              {projectDetails?.executionDetails?.attachments
                                ?.length === 1 ? (
                                <>
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div
                                    className="col-span-10 datatrimmer"
                                    title={projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  >
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                </>
                              ) : (
                                <div className="flex col-span-10 ">
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div className="datatrimmer w-20">
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                  <div
                                    className="more_btn ml-3"
                                    onClick={() => {
                                      setSupportingDocs(true);
                                    }}
                                  >
                                    {projectDetails?.executionDetails
                                      ?.attachments?.length - 1}{" "}
                                    more
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            "--"
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {projectDetails?.project?.projectType?.mobile?.platform
                  ?.platformType === AndroidandIos && (
                  <>
                    <div className="grid grid-cols-12 flex mt-4 pl-6">
                      <div className="col-span-4">
                        <div className="labels">App Activity</div>
                        <div className="values" title={projectDetails?.project?.projectType?.mobile?.platform?.appActivity}>
                          {projectDetails?.project?.projectType?.mobile
                            ?.platform?.appActivity === "" ||
                          projectDetails?.project?.projectType?.mobile?.platform
                            ?.appActivity === null
                            ? "--"
                            : projectDetails?.project?.projectType?.mobile
                                ?.platform?.appActivity}
                        </div>
                      </div>
                      <div className="col-span-4">
                        <div className="labels">Bundle ID</div>
                        <div className="values" title={projectDetails?.project?.projectType?.mobile?.platform?.bundleID}>
                          {projectDetails?.project?.projectType?.mobile
                            ?.platform?.bundleID === "" ||
                          projectDetails?.project?.projectType?.mobile?.platform
                            ?.bundleID === null
                            ? "--"
                            : projectDetails?.project?.projectType?.mobile
                                ?.platform?.bundleID}
                        </div>
                      </div>
                      <div className="grid grid-cols-12 col-span-4">
                      <div className="labels col-span-8">APK File</div>
                        <div className="files_value grid grid-cols-12 col-span-8">
                          {projectDetails?.project?.projectType?.mobile
                            ?.platform?.uploadApk === "" ||
                          projectDetails?.project?.projectType?.mobile?.platform
                            ?.uploadApk === null ? (
                            "--"
                          ) : (
                            <>
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-8 datatrimmer"
                                title={projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadApk.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadApk.length
                                )}
                              </div>
                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType?.mobile
                                      ?.platform?.uploadApk
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </>
                          )}
                        </div>
                        {/* <div className="files_value grid grid-cols-12 col-span-8">
                          {projectDetails?.project?.projectType?.mobile
                            ?.platform?.uploadIPA === "" ||
                          projectDetails?.project?.projectType?.mobile?.platform
                            ?.uploadIPA === null ? (
                            "--"
                          ) : (
                            <>
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-8 datatrimmer"
                                title={projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadIPA.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadIPA.length
                                )}
                              </div>

                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType?.mobile
                                      ?.platform?.uploadIPA
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </>
                          )}
                        </div> */}
                      </div>
                    </div>
                    <div className="grid grid-cols-12 flex mt-4 pl-6">

                    <div className="grid grid-cols-12 col-span-4">
                      <div className="labels col-span-8">IPA File</div>
                        {/* <div className="files_value grid grid-cols-12 col-span-8">
                          {projectDetails?.project?.projectType?.mobile
                            ?.platform?.uploadApk === "" ||
                          projectDetails?.project?.projectType?.mobile?.platform
                            ?.uploadApk === null ? (
                            "--"
                          ) : (
                            <>
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-8 datatrimmer"
                                title={projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadApk.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadApk.length
                                )}
                              </div>
                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType?.mobile
                                      ?.platform?.uploadApk
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </>
                          )}
                        </div> */}
                        <div className="files_value grid grid-cols-12 col-span-8">
                          {projectDetails?.project?.projectType?.mobile
                            ?.platform?.uploadIPA === "" ||
                          projectDetails?.project?.projectType?.mobile?.platform
                            ?.uploadIPA === null ? (
                            "--"
                          ) : (
                            <>
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-8 datatrimmer"
                                title={projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadIPA.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.mobile?.platform?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType?.mobile
                                    ?.platform?.uploadIPA.length
                                )}
                              </div>

                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType?.mobile
                                      ?.platform?.uploadIPA
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </>
                          )}
                        </div>
                      </div>












                      <div className="grid grid-cols-12 col-span-3">
                        <div className="labels col-span-12">
                        Supporting Documents
                        </div>
                        <div className="files_value supporting_doc grid grid-cols-12 col-span-12 mt-2">
                          {projectDetails?.executionDetails?.attachments !==
                            null &&
                          projectDetails?.executionDetails?.attachments
                            ?.length !== 0 ? (
                            <>
                              {projectDetails?.executionDetails?.attachments
                                ?.length === 1 ? (
                                <>
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div
                                    className="col-span-10 datatrimmer"
                                    title={projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  >
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                </>
                              ) : (
                                <div className="flex col-span-10 ">
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div className="datatrimmer w-20">
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                  <div
                                    className="more_btn ml-3"
                                    onClick={() => {
                                      setSupportingDocs(true);
                                    }}
                                  >
                                    {projectDetails?.executionDetails
                                      ?.attachments?.length - 1}{" "}
                                    more
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            "--"
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {/*====== project type is web SERVICES======= */}
            {projectDetails?.project?.projectType?.webservice !== null && (
              <>
                <div className="grid grid-cols-12 flex mt-4 pl-6">
                  <div className="col-span-4">
                    <div className="labels">Base URI</div>
                    <div className="values">
                      {projectDetails?.project?.projectType?.webservice
                        ?.baseURI === ""
                        ? "--"
                        : projectDetails?.project?.projectType?.webservice
                            ?.baseURI}
                    </div>
                  </div>
                  <div className="col-span-4">
                    <div className="labels">Port</div>
                    <div className="values">
                      {projectDetails?.project?.projectType?.webservice
                        ?.port === ""
                        ? "--"
                        : projectDetails?.project?.projectType?.webservice
                            ?.port}
                    </div>
                  </div>
                  <div className="grid grid-cols-12 col-span-3">
                    <div className="labels col-span-12">
                    Supporting Documents
                    </div>
                    <div className="files_value supporting_doc grid grid-cols-12 col-span-12 mt-2">
                      {projectDetails?.executionDetails?.attachments !== null &&
                      projectDetails?.executionDetails?.attachments?.length !==
                        0 ? (
                        <>
                          {projectDetails?.executionDetails?.attachments
                            ?.length === 1 ? (
                            <>
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-10 datatrimmer"
                                title={projectDetails?.executionDetails?.attachments[0]?.slice(
                                  projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.executionDetails
                                    ?.attachments[0]?.length
                                )}
                              >
                                {projectDetails?.executionDetails?.attachments[0]?.slice(
                                  projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.executionDetails
                                    ?.attachments[0]?.length
                                )}
                              </div>
                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.executionDetails
                                      ?.attachments[0]
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </>
                          ) : (
                            <div className="flex col-span-10 ">
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div className="datatrimmer w-20">
                                {projectDetails?.executionDetails?.attachments[0]?.slice(
                                  projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.executionDetails
                                    ?.attachments[0]?.length
                                )}
                              </div>
                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.executionDetails
                                      ?.attachments[0]
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                              <div
                                className="more_btn ml-3"
                                onClick={() => {
                                  setSupportingDocs(true);
                                }}
                              >
                                {projectDetails?.executionDetails?.attachments
                                  ?.length - 1}{" "}
                                more
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        "--"
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {projectDetails?.project?.projectType?.webAndMobile !== null && (
              <>
                <div className="grid grid-cols-12 flex mt-4 pl-6">
                  <div className="grid grid-cols-12 col-span-4">
                    <div className="labels col-span-10">Web URL</div>
                    <div className="values col-span-10 datatrimmer" title={projectDetails?.project?.projectType?.webAndMobile?.url}>
                      {projectDetails?.project?.projectType?.webAndMobile?.url===""|| projectDetails?.project?.projectType?.webAndMobile?.url===null?"--":
                      <a
                      href={projectDetails?.project?.projectType?.webAndMobile?.url}
                      target="_blank"
                      className="label-overflow tab-style-common"
                    >
                      {projectDetails?.project?.projectType?.webAndMobile?.url}
                    </a>}
                      
                    </div>
                  </div>
                  <div className="col-span-4">
                    <div className="labels">App Type</div>
                    <div className="values">
                      {
                        projectDetails?.project?.projectType?.webAndMobile
                          ?.appType
                      }
                    </div>
                  </div>
                  <div className="col-span-4">
                    <div className="labels">Platform</div>
                    <div className="values">
                      {
                        projectDetails?.project?.projectType?.webAndMobile
                          ?.platform
                      }
                    </div>
                  </div>
                </div>
                {projectDetails?.project?.projectType?.webAndMobile
                  ?.platform === Android && (
                  <>
                    <div className="grid grid-cols-12 flex mt-4 pl-6">
                      <div className="col-span-4">
                        <div className="labels">App Package</div>
                        <div title={projectDetails?.project?.projectType?.webAndMobile
                            ?.appPackage} className="values">
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.appPackage === "" || projectDetails?.project?.projectType?.webAndMobile
                            ?.appPackage === null
                            ? "--"
                            : projectDetails?.project?.projectType?.webAndMobile
                                ?.appPackage}
                        </div>
                      </div>
                      <div className="col-span-4">
                        <div className="labels">App Activity</div>
                        <div title={projectDetails?.project?.projectType?.webAndMobile
                            ?.appActivity} className="values">
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.appActivity === ""|| projectDetails?.project?.projectType?.webAndMobile?.appActivity===null
                            ? "--"
                            : projectDetails?.project?.projectType?.webAndMobile
                                ?.appActivity}
                        </div>
                      </div>
                      <div className="grid grid-cols-12  col-span-4">
                      <div className="labels col-span-8">APK File</div>
                        <div className="files_value grid grid-cols-12 col-span-8">
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadApk === "" ||
                          projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadApk === null ? (
                            "--"
                          ) : (
                            <div className="flex">
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-8 datatrimmer"
                                title={projectDetails?.project?.projectType?.webAndMobile?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadApk.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.webAndMobile?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadApk.length
                                )}
                              </div>

                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType
                                      ?.webAndMobile?.uploadApk
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 flex mt-4 pl-6">
                      <div className="grid grid-cols-12 col-span-4">
                        <div className="labels col-span-12">
                        Supporting Documents
                        </div>
                        <div className="files_value supporting_doc grid grid-cols-12 col-span-12 mt-2">
                          {projectDetails?.executionDetails?.attachments !==
                            null &&
                          projectDetails?.executionDetails?.attachments
                            ?.length !== 0 ? (
                            <>
                              {projectDetails?.executionDetails?.attachments
                                ?.length === 1 ? (
                                <div className="flex">
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div
                                    className="col-span-10 datatrimmer"
                                    title={projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  >
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                </div>
                              ) : (
                                <div className="flex col-span-10 ">
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div className="datatrimmer w-20">
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                  <div
                                    className="more_btn ml-3"
                                    onClick={() => {
                                      setSupportingDocs(true);
                                    }}
                                  >
                                    {projectDetails?.executionDetails
                                      ?.attachments?.length - 1}{" "}
                                    more
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            "--"
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {projectDetails?.project?.projectType?.webAndMobile
                  ?.platform === Ios && (
                  <>
                    <div className="grid grid-cols-12 flex mt-4 pl-6">
                      <div className="col-span-4">
                        <div className="labels">Bundle ID</div>
                        <div className="values" title={projectDetails?.project?.projectType?.webAndMobile?.bundleId}>
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.bundleId === ""|| projectDetails?.project?.projectType?.webAndMobile?.bundleId===null
                            ? "--"
                            : projectDetails?.project?.projectType?.webAndMobile
                                ?.bundleId}
                        </div>
                      </div>
                      <div className="grid grid-cols-12 col-span-4">
                      <div className="labels col-span-8">IPA File</div>
                        <div className="files_value col-span-10">
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadIPA === "" ||
                          projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadIPA === null ? (
                            "--"
                          ) : (
                            <div className="flex">
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-10 datatrimmer"
                                title={projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadIPA.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadIPA.length
                                )}
                              </div>
                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType
                                      ?.webAndMobile?.uploadIPA
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-12 col-span-3">
                        <div className="labels col-span-12">
                        Supporting Documents
                        </div>
                        <div className="files_value supporting_doc grid grid-cols-12 col-span-12 mt-2">
                          {projectDetails?.executionDetails?.attachments !==
                            null &&
                          projectDetails?.executionDetails?.attachments
                            ?.length !== 0 ? (
                            <>
                              {projectDetails?.executionDetails?.attachments
                                ?.length === 1 ? (
                                <>
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div
                                    className="col-span-10 datatrimmer"
                                    title={projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  >
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                </>
                              ) : (
                                <div className="flex col-span-10 ">
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div className="datatrimmer w-20">
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                  <div
                                    className="more_btn ml-3"
                                    onClick={() => {
                                      setSupportingDocs(true);
                                    }}
                                  >
                                    {projectDetails?.executionDetails
                                      ?.attachments?.length - 1}{" "}
                                    more
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            "--"
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {projectDetails?.project?.projectType?.webAndMobile
                  ?.platform === AndroidandIos && (
                  <>
                    <div className="grid grid-cols-12 flex mt-4 pl-6">
                      <div className="col-span-4">
                        <div className="labels">App Package</div>
                        <div title={projectDetails?.project?.projectType?.webAndMobile
                            ?.appPackage} className="values">
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.appPackage === "" ||
                          projectDetails?.project?.projectType?.webAndMobile
                            ?.appPackage === null
                            ? "--"
                            : projectDetails?.project?.projectType?.webAndMobile
                                ?.appPackage}
                        </div>
                      </div>
                      <div className="col-span-4">
                        <div className="labels">App Activity</div>
                        <div title={projectDetails?.project?.projectType?.webAndMobile
                            ?.appActivity} className="values">
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.appActivity === "" ||
                          projectDetails?.project?.projectType?.webAndMobile
                            ?.appActivity === null
                            ? "--"
                            : projectDetails?.project?.projectType?.webAndMobile
                                ?.appActivity}
                        </div>
                      </div>
                      <div className="col-span-4">
                        <div className="labels">Bundle ID</div>
                        <div title={projectDetails?.project?.projectType?.webAndMobile
                            ?.bundleId} className="values">
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.bundleId === "" || projectDetails?.project?.projectType?.webAndMobile
                            ?.bundleId === null
                            ? "--"
                            : projectDetails?.project?.projectType?.webAndMobile
                                ?.bundleId}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 flex mt-4 pl-6">
                      <div className="grid grid-cols-12 col-span-4">
                      <div className="labels col-span-8">APK FILE</div>
                        <div className="files_value grid grid-cols-12 col-span-8">
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadApk === "" ||
                          projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadApk === null ? (
                            "--"
                          ) : (
                            <>
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-8 datatrimmer"
                                title={projectDetails?.project?.projectType?.webAndMobile?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadApk.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.webAndMobile?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadApk.length
                                )}
                              </div>
                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType
                                      ?.webAndMobile?.uploadApk
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </>
                          )}
                        </div>
                        {/* <div className="files_value grid grid-cols-12 col-span-8">
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadIPA === "" ||
                          projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadIPA === null ? (
                            "--"
                          ) : (
                            <>
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-8 datatrimmer"
                                title={projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadIPA.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadIPA.length
                                )}
                              </div>

                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType
                                      ?.webAndMobile?.uploadIPA
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </>
                          )}
                        </div> */}
                      </div>

                      <div className="grid grid-cols-12 col-span-4">
                      <div className="labels col-span-8">IPA File</div>
                        {/* <div className="files_value grid grid-cols-12 col-span-8">
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadApk === "" ||
                          projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadApk === null ? (
                            "--"
                          ) : (
                            <>
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-8 datatrimmer"
                                title={projectDetails?.project?.projectType?.webAndMobile?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadApk.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.webAndMobile?.uploadApk.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadApk.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadApk.length
                                )}
                              </div>
                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType
                                      ?.webAndMobile?.uploadApk
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </>
                          )}
                        </div> */}
                        <div className="files_value grid grid-cols-12 col-span-8">
                          {projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadIPA === "" ||
                          projectDetails?.project?.projectType?.webAndMobile
                            ?.uploadIPA === null ? (
                            "--"
                          ) : (
                            <>
                              <div className="attachment_icon">
                                <Attachments />
                              </div>
                              <div
                                className="col-span-8 datatrimmer"
                                title={projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadIPA.length
                                )}
                              >
                                {projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.slice(
                                  projectDetails?.project?.projectType?.webAndMobile?.uploadIPA.lastIndexOf(
                                    "/"
                                  ) + 1,
                                  projectDetails?.project?.projectType
                                    ?.webAndMobile?.uploadIPA.length
                                )}
                              </div>

                              <span
                                onClick={() => {
                                  downloadFiles(
                                    projectDetails?.project?.projectType
                                      ?.webAndMobile?.uploadIPA
                                  );
                                }}
                                className="-mt-2 cursor-pointer"
                              >
                                <DownLoad />
                              </span>
                            </>
                          )}
                        </div>
                      </div>








                      <div className="grid grid-cols-12 col-span-3">
                        <div className="labels col-span-12">
                        Supporting Documents
                        </div>
                        <div className="files_value supporting_doc grid grid-cols-12 col-span-12 mt-2">
                          {projectDetails?.executionDetails?.attachments !==
                            null &&
                          projectDetails?.executionDetails?.attachments
                            ?.length !== 0 ? (
                            <>
                              {projectDetails?.executionDetails?.attachments
                                ?.length === 1 ? (
                                <>
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div
                                    className="col-span-6 datatrimmer"
                                    title={projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  >
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                </>
                              ) : (
                                <div className="flex col-span-10 ">
                                  <div className="attachment_icon">
                                    <Attachments />
                                  </div>
                                  <div className="datatrimmer w-20">
                                    {projectDetails?.executionDetails?.attachments[0]?.slice(
                                      projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                        "/"
                                      ) + 1,
                                      projectDetails?.executionDetails
                                        ?.attachments[0]?.length
                                    )}
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFiles(
                                        projectDetails?.executionDetails
                                          ?.attachments[0]
                                      );
                                    }}
                                    className="-mt-2 cursor-pointer"
                                  >
                                    <DownLoad />
                                  </span>
                                  <div
                                    className="more_btn ml-3"
                                    onClick={() => {
                                      setSupportingDocs(true);
                                    }}
                                  >
                                    {projectDetails?.executionDetails
                                      ?.attachments?.length - 1}{" "}
                                    more
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            "--"
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

              {projectDetails?.project?.projectType?.others !== null && (
              <>
                <div className="grid grid-cols-12 flex mt-4 pl-6">
                <div className="col-span-4">
                    <div className="labels">Type Of Project</div>
                    <div className="values">
                      {
                        projectDetails?.project?.projectType?.others
                          ?.typeOfProject.replace("_"," ")
                      }
                    </div>
                  </div>
                   <div className="col-span-4">
                    <div className="labels">Platform</div>
                    <div className="values">
                      {
                        projectDetails?.project?.projectType?.others
                          ?.platformType
                      }
                    </div>
                  </div>
                  <div className="col-span-4">
                    <div className="labels">App Type</div>
                    <div className="values">
                      {
                        projectDetails?.project?.projectType?.others
                          ?.appType
                      }
                    </div>
                  </div>
                 
                </div>
                {
                   projectDetails?.project?.projectType?.others
                   ?.typeOfProject!=="Standalone_Application" ? (
                    <>
                    <div className="grid grid-cols-12 flex mt-4 pl-6">
                 
                 <div className="grid grid-cols-12 col-span-4">
                  <div className="labels col-span-10">Web URL</div>
                  <div className="values col-span-10 datatrimmer" title={projectDetails?.project?.projectType?.others?.weburl}>
                    {projectDetails?.project?.projectType?.others?.weburl===""|| projectDetails?.project?.projectType?.others?.weburl===null?"--":
                    <a
                    href={projectDetails?.project?.projectType?.others?.weburl}
                    target="_blank"
                    className="label-overflow tab-style-common"
                  >
                    {projectDetails?.project?.projectType?.others?.weburl}
                  </a>}
                    
                  </div>
                </div>
                <div className="grid grid-cols-12 col-span-3">
                  <div className="labels col-span-12">Supporting Documents</div>
                  <div className="files_value supporting_doc grid grid-cols-12 col-span-12 mt-2">
                    {projectDetails?.executionDetails?.attachments !== null &&
                    projectDetails?.executionDetails?.attachments?.length !==
                      0 ? (
                      <>
                        {projectDetails?.executionDetails?.attachments
                          ?.length === 1 ? (
                          <>
                            <div className="attachment_icon">
                              <Attachments />
                            </div>
                            <div
                              className="col-span-10 datatrimmer"
                              title={projectDetails?.executionDetails?.attachments[0]?.slice(
                                projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                  "/"
                                ) + 1,
                                projectDetails?.executionDetails?.attachments[0]
                                  ?.length
                              )}
                            >
                              {projectDetails?.executionDetails?.attachments[0]?.slice(
                                projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                  "/"
                                ) + 1,
                                projectDetails?.executionDetails?.attachments[0]
                                  ?.length
                              )}
                            </div>
                            <span
                              onClick={() => {
                                downloadFiles(
                                  projectDetails?.executionDetails
                                    ?.attachments[0]
                                );
                              }}
                              className="-mt-2 cursor-pointer"
                            >
                              <DownLoad />
                            </span>
                          </>
                        ) : (
                          <div className="flex col-span-11 ">
                            <div className="attachment_icon">
                              <Attachments />
                            </div>
                            <div className="datatrimmer w-20">
                              {projectDetails?.executionDetails?.attachments[0]?.slice(
                                projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                  "/"
                                ) + 1,
                                projectDetails?.executionDetails?.attachments[0]
                                  ?.length
                              )}
                            </div>
                            <span
                              onClick={() => {
                                downloadFiles(
                                  projectDetails?.executionDetails
                                    ?.attachments[0]
                                );
                              }}
                              className="-mt-2 cursor-pointer"
                            >
                              <DownLoad />
                            </span>
                            <div
                              className="more_btn ml-3"
                              onClick={() => {
                                setSupportingDocs(true);
                              }}
                            >
                              {projectDetails?.executionDetails?.attachments
                                ?.length - 1}{" "}
                              more
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      "--"
                    )}
                  </div>
                </div>
              </div>
                    </>
                   ):(
                    <>
                    <div className="grid grid-cols-12 flex mt-4 pl-6">
                 
                
                <div className="grid grid-cols-12 col-span-3">
                  <div className="labels col-span-12">Supporting Documents</div>
                  <div className="files_value supporting_doc grid grid-cols-12 col-span-12 mt-2">
                    {projectDetails?.executionDetails?.attachments !== null &&
                    projectDetails?.executionDetails?.attachments?.length !==
                      0 ? (
                      <>
                        {projectDetails?.executionDetails?.attachments
                          ?.length === 1 ? (
                          <>
                            <div className="attachment_icon">
                              <Attachments />
                            </div>
                            <div
                              className="col-span-10 datatrimmer"
                              title={projectDetails?.executionDetails?.attachments[0]?.slice(
                                projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                  "/"
                                ) + 1,
                                projectDetails?.executionDetails?.attachments[0]
                                  ?.length
                              )}
                            >
                              {projectDetails?.executionDetails?.attachments[0]?.slice(
                                projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                  "/"
                                ) + 1,
                                projectDetails?.executionDetails?.attachments[0]
                                  ?.length
                              )}
                            </div>
                            <span
                              onClick={() => {
                                downloadFiles(
                                  projectDetails?.executionDetails
                                    ?.attachments[0]
                                );
                              }}
                              className="-mt-2 cursor-pointer"
                            >
                              <DownLoad />
                            </span>
                          </>
                        ) : (
                          <div className="flex col-span-11 ">
                            <div className="attachment_icon">
                              <Attachments />
                            </div>
                            <div className="datatrimmer w-20">
                              {projectDetails?.executionDetails?.attachments[0]?.slice(
                                projectDetails?.executionDetails?.attachments[0]?.lastIndexOf(
                                  "/"
                                ) + 1,
                                projectDetails?.executionDetails?.attachments[0]
                                  ?.length
                              )}
                            </div>
                            <span
                              onClick={() => {
                                downloadFiles(
                                  projectDetails?.executionDetails
                                    ?.attachments[0]
                                );
                              }}
                              className="-mt-2 cursor-pointer"
                            >
                              <DownLoad />
                            </span>
                            <div
                              className="more_btn ml-3"
                              onClick={() => {
                                setSupportingDocs(true);
                              }}
                            >
                              {projectDetails?.executionDetails?.attachments
                                ?.length - 1}{" "}
                              more
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      "--"
                    )}
                  </div>
                </div>
              </div>
                    </>
                   )
                }

                
                
              </>
            )}


            <div className="grid grid-cols-12 flex mt-4 pl-6 pb-5">
              <div className="col-span-11">
                <div className="labels">Instruction To Test Engineers</div>
                <div className="description_value text-justify">
                  {(projectDetails?.executionDetails?.instructions === "" || projectDetails?.executionDetails?.instructions===null) ? (
                    "--"
                  ) : expandInstruction ? (
                    <div
                      className=" description cursor-pointer"
                      onClick={() => {
                        setExpandInstruction(false);
                      }}
                    >
                      {projectDetails?.executionDetails?.instructions}
                    </div>
                  ) : (
                  
                    <div>
                      <TextareaAutosize
                    value={projectDetails?.executionDetails?.instructions}
                    style={{width:"100%",resize:"none",outline:"none"}}
                    disabled={true}
                    className="tester_instruction"
                    />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {
              projectDetails?.defectExecutiveReport!==null && (
                <div className="grid grid-cols-12 flex mt-4 pl-6 pb-5">
              <div className="col-span-11">
                <div className="labels flex">
                  <span>Executive Report</span>
                  {
                    ((myRole==="ADMIN"|| myRole==="ACCOUNT_MANAGER") &&  (projectDetails?.projectStatus === "CLOSED"||projectDetails?.projectStatus === "COMPLETED")) && 
                    (
                      <span className="ml-3 executive_report_edit_icon cursor-pointer" onClick={()=>{setEditReport(true)}}><EditIcon/></span>
                    )
                  }
                  
                  </div>
                <div className="project-executive-report">
                  {
                    projectDetails?.defectExecutiveReport===null||projectDetails?.defectExecutiveReport===""?(<span className="values">--</span>):
                    (
                      <RichTextEditor
                      convertedContent={projectDetails?.defectExecutiveReport}
                      readOnly={true}
                      toolbarHidden={true}
                      />
                    )
                  }
                  
                </div>
              </div>
            </div>
              )
            }

            







          </div>
        ) : (
          <div className="pageContainer"></div>
        )}
        {editProjectModal && (
          <ProjectCreate
            MyAlert={MyAlert}
            closeModal={onEditProjectModalClose}
            header={"Edit Project"}
            editDetails={projectDetails}
            onProjectEdited={() => ProjectDetailsAPI(tempDetails)}
            ProjectNameList={ProjectNameList}
            userList={userList}
          />
        )}
        {/* {
           
           pltafromDetails &&
           (
            <PlatformModal/>
           )
        } */}
        {(openAddDeviceModal &&  !["CLOSED","REJECTED"].includes(JSON.parse(localStorage["selected-project"]).projectStatus )) && (
          <AddTestingDeviceModal  onCloseAddPlatform={onCloseAddPlatform}  MyAlert={MyAlert}/>
        )}
        {moretypes && (
          <SupportingDocsPopup
            functiontypeModalClose={functiontypeModalClose}
            list={projectDetails?.testingTypes}
            heading={"Testing Types"}
          />
        )}
        {supportingDocs && (
          <SupportingDocsPopup
            functiontypeModalClose={supportingDocsModalClose}
            list={projectDetails?.executionDetails?.attachments}
            heading={"Supporting Documents"}
            downloadFiles={downloadFiles}
          />
        )}
        {
          editReport && (
            <EditExectiveReportsPopup projectDetails={projectDetails} closemodal={oncloseReportModal} MyAlert={props.MyAlert} getProjectDeatails={ProjectDetailsAPI}/>
          )
        }
        
      </div>
    )
  );
}
export default ProjectDetails;
