import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/addIcon.svg";
import TestCaseDetails from "./testcase-details";
import TesterExecutionList from "../config/testCase/testers-executionList";
import TestCaseExecution from "./testcase-execution";
import ExecutionDetailsList from "../config/testCase/executionDetailsList";
import { useNavigate } from "react-router-dom";
import EdittestCase from "./edit-testCase";
import {
  getTestcaseDetailsById,
  getExecutionListByEmail,
  getAllTestCaseTemplate,
} from "../../api/api-services";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer";
import { Tooltip } from "@mui/material";

function TestCaseDetailsHome(props) {
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  const [detailsViewData, setdetailsViewData] = useState([]);
  const [testCaseData, setTestCaseData] = useState({});
  const [templateTableData, setTemplateTableData] = useState({});
  let [selectedTab, setSelectedTab] = useState("TestCaseDetails");
  let [showExecution, setShowExecution] = useState();
  const [loading, setLoading] = useState(true);
  const [executionList, setExecutionList] = useState([]);
  const [testcaseDetailsById, setTestcaseDetailsById] = useState();
  let myrole = JSON.parse(localStorage["user-privilege"]).role;
  const search = window.location.search;
  const modeType = new URLSearchParams(search).get("modeType");
  const ModuleID = new URLSearchParams(search).get("moduleId");
  const ScriptID = new URLSearchParams(search).get("scriptId");
  let projectId = JSON.parse(localStorage.getItem("selected-project")).project
    .fireFlinkProjectId;
  let licenseId = JSON.parse(
    localStorage.getItem("selected-project")
  ).licenceID;
  let emailId = JSON.parse(localStorage.getItem("selected-user")).email;
  const [editMode, setEditMode] = useState(
    modeType === '"edit"' ? true : false
  );
  let [expand, setExpand] = useState(true);
  const [dataForApproveFiltered, setDataForApproveFiltered] = useState({});
  let project_status = JSON.parse(
    localStorage.getItem("selected-project")
  ).projectStatus;
  let Execution_action = JSON.parse(localStorage.getItem("user-privilege"))
    .testcases.actions.execution;
  let [callingAPI, setCallingAPI] = useState(false);
  let [executionCount, setExecutionCount] = useState(0);

  const callListAPI = (value) => {
    getExecutionListTester(value);
  };
  function selectTab(value) {    
    if (value === "ExecutionDashBoard" && myrole === "TESTER") {
      setShowExecution(false);
      callListAPI(value);
    } else {
      setSelectedTab(value);
    }
  }
  function navigationToDashBord() {
    if (selectedTab === "TestCaseDetails") {
      selectTab("ExecutionDashBoard");
      setShowExecution(true);
    }
  }
  function closeExecutionscreen() {
    localStorage.setItem("exectuionOpened",false);
    setShowExecution(false);
  }

  useEffect(() => {
    const search = window.location.search;
  const modeType = new URLSearchParams(search).get("modeType");
  if(modeType=='"view"'){
    getScript();
  }
  // getScript();
   
  }, []);
  useEffect(() => {
    //
  }, [executionList]);

  async function getTemplateData(scriptData, projectName) {
    try {
      const responseData = await getAllTestCaseTemplate(
        "config",
        projectId,
        licenseId
      );

      if (responseData?.data?.responseCode === 200) {
        setdetailsViewData(
          responseData?.data?.responseObject?.defaultTemplate?.testCaseDetails
        );
        let table = JSON.parse(
          JSON.stringify(
            responseData?.data?.responseObject?.defaultTemplate?.testSteps
          )
        );
        setTemplateTableData(table);
        let testCaseDetails =
          responseData?.data?.responseObject?.defaultTemplate?.testCaseDetails;
        testCaseDetails = sortBasedOnOrder(testCaseDetails);
        const testSteps =
          responseData?.data?.responseObject?.defaultTemplate?.testSteps;
        setTestCaseData
        ({
          ...scriptData,
          manualTestCase: {
            ...scriptData.manualTestCase,
            testCaseDetails: testCaseDetails,
            testSteps: testSteps,
          },
        });
        
      } else {
        console.error("Template error", responseData?.data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  }
  const sortBasedOnOrder = (testCaseDetails) => {
    return testCaseDetails.sort((a, b) => {
      return a?.order - b?.order;
    });
  };
  const compareTestStepsObjects = (templateData, scriptData) => {
    let a = templateData?.headers;
    let b = scriptData?.headers;
    let testStepsObject = {};
    let matchedObject = {};
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedText = "";
      for (let j = 0; j < b.length; j++) {
        if (a[i]?.toLowerCase() === b[j]?.toLowerCase()) {
          match = true;
          matchedText = b[j];
          break;
        }
      }
      if (!match) {
        matchedObject[a[i]] = a[i];
      } else {
        matchedObject[matchedText] = matchedText;
      }
    }

    for (let i = 0; i < scriptData?.rows.length; i++) {
      const keys = Object.keys(matchedObject);
      const rowKeys = Object.keys(scriptData.rows[i]);
      for (let j = 0; j < keys.length; j++) {
        if (!rowKeys.includes(keys[j])) {
          scriptData.rows[i][keys[j]] = "";
        }
      }
    }
    testStepsObject.headers = Object.keys(matchedObject);
    testStepsObject.rows = scriptData.rows;
    return testStepsObject;
  };
  const compareTestCaseDetailsObjects = (a, b) => {
    let testCaseDetailsArray = [];
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedObject = {};
      for (let j = 0; j < b.length; j++) {
        if (
          a[i]?.label?.toLowerCase() === b[j]?.label?.toLowerCase() &&
          a[i]?.type?.toLowerCase() === b[j]?.type?.toLowerCase()
        ) {
          match = true;
          matchedObject = b[j];
          break;
        }
      }
      if (!match) {
        testCaseDetailsArray.push(a[i]);
      } else {
        a[i].value = matchedObject?.value;
        testCaseDetailsArray.push(a[i]);
      }
    }
    return testCaseDetailsArray;
  };
  const getScript = async () => {
    let moduleId = ModuleID;
    let ScriptId = ScriptID;
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });

    try {
      let response = await getTestcaseDetailsById(
        moduleId,
        ScriptId,
        projectId,
        licenseId
      );
      setTestcaseDetailsById(response?.data?.responseObject);
      let responseData = response?.data?.responseObject;
      let manualTestCaseObj = responseData?.manualTestCase;

      if (manualTestCaseObj) {
        setTestCaseData(response?.data?.responseObject);
        if (Object.entries(manualTestCaseObj).length) {
          const response = await getAllTestCaseTemplate(
            "config",
            projectId,
            licenseId
          );
          if (response?.data?.responseCode === 200) {
            let testCaseDetails = compareTestCaseDetailsObjects(
              response?.data?.responseObject?.defaultTemplate?.testCaseDetails,
              manualTestCaseObj?.testCaseDetails
            );
            setdetailsViewData(
              response?.data?.responseObject?.defaultTemplate?.testCaseDetails
            );
            const testSteps = compareTestStepsObjects(
              response?.data?.responseObject?.defaultTemplate?.testSteps,
              manualTestCaseObj?.testSteps
            );
            setTemplateTableData(testSteps);
            testCaseDetails = sortBasedOnOrder(testCaseDetails);
            setTestCaseData({
              ...responseData,
              manualTestCase: {
                ...manualTestCaseObj,
                testCaseDetails: testCaseDetails,
                testSteps: testSteps,
              },
            });
          } else {
            console.error(
              "Get All TestCase Template error",
              response?.data?.message
            );
          }
        } else {
          getTemplateData(responseData, responseData?.name);
        }
      } else {
        getTemplateData(responseData, responseData?.name);
      }
      if (response?.data?.responseCode === 200) {
        let data =
          response?.data?.responseObject?.manualTestCase?.testCaseDetails;
        let table = JSON.parse(
          JSON.stringify(
            response?.data?.responseObject?.manualTestCase?.testSteps
          )
        );
        setTemplateTableData(table);
        setdetailsViewData(data);
        setTestCaseData(response?.data?.responseObject);
      }

      resetProgress();
    } catch (error) {
      resetProgress();
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const getExecutionListTester = async (value) => {
    let scriptId = testCaseData.id;
    setCallingAPI(true);
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    try {
      let response = await getExecutionListByEmail(
        projectId,
        licenseId,
        emailId,
        scriptId,
        "",
        ""
      );
      setDataForApproveFiltered(response?.data?.responseObject);
      if (response?.data?.responseCode === 200) {
        let tempList = [];
        let data = response?.data?.responseObject;
        data.map((obj) => {
          let tempData = JSON.parse(JSON.stringify(testCaseData));
          tempData.executionId = obj.id;
          tempData.executionStatus = obj.executionStatus;
          tempData.manualTestCase.testSteps.rows.map((obj1, index1) => {
            obj1.Status =
              obj.results[index1]?.status !== undefined
                ? obj.results[index1].status
                : "";
            obj1["Actual Result"] = obj.results[index1]?.actualResult;
            tempData["platformName"] = obj.platformName;
            tempData["os"] = obj.os;
            tempData["osVersion"] = obj.osVersion;
            tempData["browser"] = obj.browser;
            tempData["browserVersion"] = obj.browserVersion;
            tempData["deviceBrand"] = obj.deviceBrand;
            tempData["deviceName"] = obj.deviceName;
            tempData["city"] = obj.location.city;
            tempData["executionStatus"] = obj.executionStatus;
            tempData["createdOn"]=obj?.createdOn;
            return obj1;
          });
          tempList.push(tempData);
        });
        executionCountSetter(tempList.length);
        setExecutionList(tempList);
        setSelectedTab(value);
      }
      resetProgress();
    } catch (error) {
      resetProgress();
      console.error(error);
    } finally {
      // setLoading(false);
    }
    setCallingAPI(false);
  };
  const executionCountSetter = (count) =>{
    setExecutionCount(count);
  }
  return (
    <div className="testcase_home">
      <div className="grid grid-cols-12 header">
        <div className="col-span-10 flex grid grid-cols-12">
          <div className="col-span-2 tab-lable">
            <span
              className={
                selectedTab === "TestCaseDetails"
                  ? "selected-tab cursor-pointer"
                  : "cursor-pointer"
              }
              onClick={() => {
                selectTab("TestCaseDetails");
              }}
            >
              Test Case Details
            </span>
          </div>
          <div className="col-span-8 tab-lable">
            <span
              className={
                selectedTab === "ExecutionDashBoard"
                  ? "selected-tab cursor-pointer"
                  : "cursor-pointer"
              }
              onClick={() => {
                selectTab("ExecutionDashBoard");
              }}
            >
              Execution Result
            </span>
          </div>
        </div>
        <div className="grid grid-cols-12 col-span-1 flex justify-end">
          <div
            className={`grid grid-cols-12 col-span-3 relative  ${
              Execution_action ? `block` : `hidden`
            }`}
          >
            <Tooltip
              title={
                testCaseData?.manualTestCase?.testSteps?.rows === undefined
                  ? "Cannot excute without teststeps"
                  : "Execute"
              }
            >
              <div
                className={`flex back_btn testcase_btn  cursor-pointer ${
                  selectedTab === "ExecutionDashBoard"
                    ? `${
                        project_status !== "INPROGRESS"||showExecution===true
                          ? "opacity-50 execution_btn"
                          : "execution_btn"
                      }`
                    :  `${project_status!=="INPROGRESS"?"execution_btn_Alignment opacity-50":"execution_btn_Alignment"}`
                }
                ${
                  testCaseData?.manualTestCase?.testSteps?.rows === undefined
                    ? "opacity-50"
                    : ""
                }
                

                `}
                onClick={() => {
                  if (project_status === "INPROGRESS" && testCaseData?.manualTestCase?.testSteps?.rows !== undefined)  {
                    if(selectedTab === "TestCaseDetails")
                      {
                        navigationToDashBord()
                      } else {
                        setShowExecution(true);              
                      }
                      localStorage.setItem("exectuionOpened",true);
                  }
                }}
              >               
                <span className="col-span-2 add_icon">
                  <ArrowIcon />
                </span>
                <div className=" col-span-10 py-2 px-2">Execute</div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 heading">
        {selectedTab === "TestCaseDetails" ? (
          <div className="col-span-12 grid grid-cols-12">
            <div className="col-span-9"></div>
          </div>
        ) : (
       
            <div className="col-span-12 ml-4">Execution Result - {executionCount}</div>
           
         
          
          
        )}
      </div>
      <div className="grid grid-cols-12 testcase_home_container">
        {selectedTab === "TestCaseDetails" ? (
          <div className="col-span-12">
            {editMode ? (
              <EdittestCase
                loading={loading}
                MyAlert={props.MyAlert}
                getScriptDetails={getScript}
                testCaseData={testCaseData}
                expand={expand}
                editMode={editMode}
                setEditMode={setEditMode}
              />
            ) : (
              <TestCaseDetails
                loading={loading}
                templateTableData={templateTableData}
                detailsViewData={detailsViewData}
                editMode={editMode}
                setEditMode={setEditMode}
                testcaseDetailsById={testcaseDetailsById}
              />
            )}
          </div>
        ) : (
          <div className="col-span-12">
            <div>
              <div className="grid grid-cols-12 execution_dashboard">
                <div className="grid grid-cols-12 col-span-12">
                  {showExecution && (
                    <div className="col-span-12">
                      <TestCaseExecution
                        MyAlert={props.MyAlert}
                        closeExecutionscreen={closeExecutionscreen}
                        testCaseTemplate={testCaseData}
                        testcaseRows={templateTableData}
                        testcaseExecutionList={executionList}
                        callList={callListAPI}
                      />
                    </div>
                  )}
                  <div className="col-span-12">
                    {myrole === "TESTER" ? (
                      <TesterExecutionList
                        expand={false}
                        testCaseTemplate={testCaseData}
                        testcaseExecutionList={executionList}
                        dataForApproveFiltered={dataForApproveFiltered}
                        showExecution={showExecution}
                        callingAPI={callingAPI}
                      />
                    ) : (
                      <ExecutionDetailsList
                        MyAlert={props.MyAlert}
                        testCaseTemplate={testCaseData}
                        callingAPI={callingAPI}
                        executionCountSetter={executionCountSetter}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default TestCaseDetailsHome;
