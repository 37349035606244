import React, { useState } from "react";
import { Label } from "../common/inputs/myInput";
import "./defect-field-forms.scss";
import { Dropdown } from "./dropdown";
import TextFieldForm from "./defectFieldForms/textFieldForm";
import TextAreaForm from "./defectFieldForms/textAreaForm";
import DateForm from "./defectFieldForms/dateForm";
import LinkForm from "./defectFieldForms/linkForm";
import RadioForms from "./defectFieldForms/radioForms"
import DropDownForm from "./defectFieldForms/dropDownForm";
import CheckBoxForm from "./defectFieldForms/checkBoxForm";
import AttachmentForm from "./defectFieldForms/attachmentForm";
import TextEditor from "./defectFieldForms/textEditor";
import {ReactComponent as ClosedIcon} from "../../assets/close_icon.svg"
const DefectFieldForm = (props) => {
  const [selectedCustomFieldArray, setSelectedCustomFieldArray] = useState([
    { label: "Text Field", value: "textField" },
    { label: "Text Area", value: "textArea" },
    { label: "Radio Button", value: "radioButton" },
    { label: "Link", value: "link" },
    { label: "Dropdown", value: "dropdown" },
    { label: "Date", value: "date" },
    { label: "Checkbox", value: "checkbox" },
    { label: "Attachment", value: "attachment" },
    { label: "Text Editor", value: "textEditor" },
  ]);
  const [selectedCustomValue, setSelectedCustomValue] = useState(Object.keys(props.editFieldValue).length !== 0 ? props.editFieldValue.type : 'textField');
  const [selectedCustomFieldValue, setSelectedCustomFieldValue] = useState();
  function selectFieldValue(data, e) {
    // formikDetails.setFieldValue("modual", data[0]?.value);
    // e.preventDefault()
    let selectedCValue = data[0]?.value;

    setSelectedCustomFieldValue([...data]);
    switch (selectedCValue) {
      case "textField":
        setSelectedCustomValue("textField");
        // setInitialModalStyle("modal_dialog_TC")
        // setDisabledDropDown(true)
        break;
      case "textArea":
        setSelectedCustomValue("textArea");

        break;
      case "radioButton":
        setSelectedCustomValue("radioButton");

        break;
      case "link":
        setSelectedCustomValue("link");

        break;
        case "dropdown":
        setSelectedCustomValue("dropdown");

        break;
        case "date":
        setSelectedCustomValue("date");

        break;
        case "checkbox":
        setSelectedCustomValue("checkbox");

        break;
        case "attachment":
        setSelectedCustomValue("attachment");

        break;
        case "textEditor":
        setSelectedCustomValue("textEditor");

        break;

      default:
        break;
    }
  }

  return (
    <div>
      <div className="">
        <div>
          <div className="flex">
          <div className="defecttemplate-labels popins-semi-bold px-2 my-4" onClick={()=>{console.log(props.editFieldValue)}}>
             {props.editFieldValue.type ? "Edit Field":"Add Field"}
          </div>
          <div className="closedIcon cursor-pointer" onClick={()=>{props.setAddField(false);props.setEditFieldValue({})}}>
            <ClosedIcon height="14px"/>
          </div>
          </div>
          <hr className="mt-2" style={{boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"}}></hr>

          <div className=" px-2 mainContainer">
            <div className="my-1 mb-7 selectCustomField">
              <div className="mb-4 ">
                <Label
                  label={
                    <span className="defecttemplate-labels popins-semi-bold ">
                      Select Custom Field
                    </span>
                  }
                  required={true}
                  
                />
              </div>
              <Dropdown
                data={selectedCustomFieldArray}
                selectedData={selectedCustomFieldValue}
                disabled={props.editFieldValue.label}
                searchable={false}
                onChange={(e) => {
                  selectFieldValue(e);
                }}
                placeholder={selectedCustomValue}
              />
            </div>
          </div>
        </div>
      </div>
      <div className=" text-gray-700 font-bold ml-4 -mt-5 mb-1 ">
        {selectedCustomFieldArray.map((val) => {
          if (val.value === selectedCustomValue) {
            return val.label;
          }
        })}
      </div>
      {selectedCustomValue === "textField" ? (
        <TextFieldForm
          setAddField={props.setAddField}
          addFormField={props.addFormField}
          editFieldValue={props.editFieldValue}
          MyAlert={props.MyAlert}
          clearEditValue={props.setEditFieldValue}
        />
      ) : selectedCustomValue === "textArea" ? (
        <TextAreaForm  setAddField={props.setAddField}
         editFieldValue={props.editFieldValue}
         clearEditValue={props.setEditFieldValue}
         MyAlert={props.MyAlert}
          addFormField={props.addFormField} />
      ) : selectedCustomValue === "date" ? (
        <DateForm  setAddField={props.setAddField}
         clearEditValue={props.setEditFieldValue}
         editFieldValue={props.editFieldValue}
         MyAlert={props.MyAlert}
          addFormField={props.addFormField}/>
      ): selectedCustomValue === "link" ? (
        <LinkForm  setAddField={props.setAddField}
         editFieldValue={props.editFieldValue}
         clearEditValue={props.setEditFieldValue}
         MyAlert={props.MyAlert}
          addFormField={props.addFormField}/>
      ): selectedCustomValue === "radioButton" ? (
        <RadioForms  setAddField={props.setAddField}
         editFieldValue={props.editFieldValue}
         MyAlert={props.MyAlert}
         clearEditValue={props.setEditFieldValue}
          addFormField={props.addFormField}/>
      ) : selectedCustomValue === "dropdown" ? (
        <DropDownForm  setAddField={props.setAddField}
         editFieldValue={props.editFieldValue}
         MyAlert={props.MyAlert}
         clearEditValue={props.setEditFieldValue}
          addFormField={props.addFormField}/>
      ): selectedCustomValue === "checkbox" ? (
        <CheckBoxForm  setAddField={props.setAddField}
         editFieldValue={props.editFieldValue}
         MyAlert={props.MyAlert}
         clearEditValue={props.setEditFieldValue}
          addFormField={props.addFormField}/>
      ): selectedCustomValue === "attachment" ? (
        <AttachmentForm  setAddField={props.setAddField}
         editFieldValue={props.editFieldValue}
         MyAlert={props.MyAlert}
         clearEditValue={props.setEditFieldValue}
          addFormField={props.addFormField}/>
      ) : selectedCustomValue === "textEditor" ? (
        <TextEditor  setAddField={props.setAddField}
         editFieldValue={props.editFieldValue}
         MyAlert={props.MyAlert}
         clearEditValue={props.setEditFieldValue}
          addFormField={props.addFormField}/>
      )   : null}
      
    </div>
  );
};

export default DefectFieldForm;
