import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { ReactComponent as Search } from "../../../assets/search.svg";
import { useTable, useGlobalFilter } from "react-table";
import NoDefectIcon from "../../../assets/reportsicon/no_reports_icon.png";
import {
  getDefectsData,
  DownloadDefectExcleFile,
  getDefectSegmentData,
  getLocationSegmentData,
  getDefectsDataCount,
  getUserDetailsByEmailID,getTemplateById
} from "../../../api/api-services";
import { CircularLoader } from "../../../pages/common/CircularLoader";
import DefectsDeatilsPopup from "../../../pages/modal/defects-deatils-popup";
import UserDeatilsPopup from "../../../pages/modal/user-deatils";


let webSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
};
let mobileSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
  deviceBrand: null,
  deviceName: null,
  deviceType: null,
};

function AllDefectsList(props) {
  console.log("AllDefectsList", props);
  console.log("module",props?.payload[0].split(/[/,:]/)[props?.payload[0].split(/[/,:]/).length-2]);
  const [openModal, setOpenModal] = useState(true);
  let [tabelData, setTableData] = useState([]);

  let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])?.project
    ?.fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"])?.licenceID;
  let projectID = JSON.parse(localStorage["selected-project"])?.id;

  let [pageNo,setPageNO]=useState(0);
  let [lastPage,setLastpage]=useState(false);
  let [defectCount,setDefectCount]=useState(0);
  let [dataLoading,setdataLoading]=useState(false);
  let [defectTemplatedetails, setDefectTempalteDetails] = useState();
  let [defectsPopUp, setDefectsPopUp] = useState(false);
  let [defectsdetails, setDefectsdetails] = useState();

  let [userDeatils, setusererDeatils] = useState();
  let [userPopup, setUserPopup] = useState(false);

  useEffect(()=>{
    if(props?.label==="severity"){
      getAllseveritydefects(props?.payload,0,props?.state)
    }else if (props?.label==="priority"){
      getAllprioritydefects(props?.payload,0,props?.state)
    }else if (props?.label==="module"){
      getAllmoduledefects(props?.payload,0,props?.state)
    }else {
      getAllLocationdefects(props?.payload,0,props?.state)
    }
   

  },[props]);

  const getAllDefectsCount = async (payload, state, type) => {
    try {
      let responseObject = await getDefectsDataCount(
        fireFlinkProjectId,
        licenseID,
        payload,
        state
      );
      console.log("req count", payload);
      console.log("responseObject count", responseObject);
      if (responseObject?.data?.responseCode === 200) {
        setDefectCount(responseObject?.data?.totalDefectCount)
       
         
        
      } else {
        setDefectCount(0)
       
      }
    } catch (err) {
      console.log("error");
    }
  };


  const getAllprioritydefects = async (payload, pageno, state) => {
    if(pageno===0){
      setdataLoading(true)
    }
    let segmentSearchFilters = {
      severity: null,
      priority: payload,
      modules: null,
      locations: null,
      createdOn: null,
    };
    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 30;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          fireFlinkProjectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
           setLastpage(res?.data?.lastPage);
           setPageNO(page + 1);

          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
             setTableData([...tempArray]);
             getAllDefectsCount(filterdata, state, "severity");
          } else {
            let defectArray = [];
             defectArray = tabelData.concat(tempArray);
             setTableData(defectArray);
          }
        } else {
           setTableData([])
        }
      } catch (error) {
        console.log("Error");
      }
    } else {
       setTableData([]);
       setDefectCount(0);
    }
    setdataLoading(false)
  };

  const getAllmoduledefects = async (payload, pageno, state) => {
  
    if(pageno===0){
      setdataLoading(true)
    }
    let segmentSearchFilters = {
      severity: null,
      priority: null,
      modules: payload,
      locations: null,
      createdOn: null,
    };
    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 30;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          fireFlinkProjectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
           setLastpage(res?.data?.lastPage);
           setPageNO(page + 1);

          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
             setTableData([...tempArray]);
             getAllDefectsCount(filterdata, state, "severity");
          } else {
            let defectArray = [];
             defectArray = tabelData.concat(tempArray);
             setTableData(defectArray);
          }
        } else {
           setTableData([])
        }
      } catch (error) {
        console.log("Error");
      }
    } else {
       setTableData([]);
       setDefectCount(0);
    }
    setdataLoading(false)
  };

  const getAllseveritydefects = async (payload, pageno, state) => {
    if(pageno===0){
      setdataLoading(true)
    }
    let segmentSearchFilters = {
      severity: payload,
      priority: null,
      modules: null,
      locations: null,
      createdOn: null,
    };

    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 30;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          fireFlinkProjectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
           setLastpage(res?.data?.lastPage);
           setPageNO(page + 1);

          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
             setTableData([...tempArray]);
             getAllDefectsCount(filterdata, state, "severity");
          } else {
            let defectArray = [];
             defectArray = tabelData.concat(tempArray);
             setTableData(defectArray);
          }
        } else {
           setTableData([])
        }
      } catch (error) {
        console.log("Error");
      }
    } else {
       setTableData([]);
       setDefectCount(0);
    }
    setdataLoading(false)
  };

  const getAllLocationdefects = async (payload, pageno, state) => {
    if(pageno===0){
      setdataLoading(true)
    }
    let segmentSearchFilters = {
      severity: null,
      priority: null,
      modules: null,
      locations: payload,
      createdOn: null,
    };
    let filterdata = {
      segmentSearchFilters: segmentSearchFilters,
      webSearchFilters: webSearchFilters,
      mobileSearchFilters: mobileSearchFilters,
    };
    let page = pageno,
      size = 30;
    let tempArray = [];
    if (payload.length > 0) {
      try {
        let res = await getDefectsData(
          fireFlinkProjectId,
          licenseID,
          filterdata,
          page,
          size,
          state
        );
        if (res?.data?.responseCode === 200) {
           setLastpage(res?.data?.lastPage);
           setPageNO(page + 1);

          res?.data?.responseObject.map((defect, index) => {
            tempArray.push({
              bugId: defect?.defectDetails?.ID,
              bugSummary: defect.defectDetails?.Summary,
              createdBy: defect?.defectDetails["Created By"]?.name,
              Details: defect,
            });
          });
          if (page === 0) {
             setTableData([...tempArray]);
             getAllDefectsCount(filterdata, state, "severity");
          } else {
            let defectArray = [];
             defectArray = tabelData.concat(tempArray);
             setTableData(defectArray);
          }
        } else {
           setTableData([])
        }
      } catch (error) {
        console.log("Error");
      }
    } else {
       setTableData([]);
       setDefectCount(0);
    }
    setdataLoading(false)
  };

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "68.5%",
      transform: "translate(-50%, -50%)",
      width: "63%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  const getUserDetails = async (e) => {
    console.log("get user deatil welcome");
    let emailId = e.Details.defectDetails.history[0].userEmail;
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    try {
      let response = await getUserDetailsByEmailID(projectId, emailId);
      if (response?.status === 200) {
        setusererDeatils(response.data);
        setUserPopup(true);
      }
      console.log("getUserDetails", response);
    } catch (err) {
      console.log("error");
    }
  }


  const getTemplateDetails = async (e) => {
    console.log("eeee", e.defectTemplateId);
    console.log("eeee Details", e);
    let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])
      .project.fireFlinkProjectId;
    let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
    let templateID = e.defectTemplateId;
    try {
      let response = await getTemplateById(
        fireFlinkProjectId,
        templateID,
        licenseId
      );
      if (response.data.responseCode === 200) {
        setDefectTempalteDetails(response);
        setDefectsdetails(e);
        setDefectsPopUp(true);
      } else {
        props.MyAlert.info("Something went wrong");
      }
    } catch (error) {
      console.log("errror");
    }
  };

  let columns = React.useMemo(
    () => [
      {
        Header: "BUG ID",
        accessor: "bugId",
        width: "8%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
               getTemplateDetails(e.cell.row.original.Details);
            }}
            className="reportTablecell popins cursor-pointer	datatrimmer"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "BUG SUMMARY",
        accessor: "bugSummary",
        width: "60.7%",
        Cell: (e) => (
          <div className="datatrimmer" title={e.value}>
            {e.value}
          </div>
        ),
      },

      {
        Header: "CREATED BY",
        accessor: "createdBy",
        width: "12.3%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();

               getUserDetails(e.cell.row.original);
            }}
            className="reportTablecell popins cursor-pointer	datatrimmer w-18"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
    ],
    []
  );
  let data = React.useMemo(() => tabelData, [tabelData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;

  const handlescroll = (event) => {

    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
      if(lastPage===false){
        if(props?.label==="severity"){
          getAllseveritydefects(props?.payload,pageNo,props?.state)
        }else if (props?.label==="priority"){
          getAllprioritydefects(props?.payload,pageNo,props?.state)
        }else if (props?.label==="module"){
          getAllmoduledefects(props?.payload,pageNo,props?.state)
        }else{
          getAllLocationdefects(props?.payload,pageNo,props?.state)
        }
      }
      
     
   
     
      
    
    }
  };

  function CloseDefectModal(state) {
   
    setDefectsPopUp(state);
  }

  function CloseTesterModal(state) {
   
    setUserPopup(state);
  }


  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
      <div className="all_defect_list">
        <div className="grid grid-cols-12  rounded-tl-xl flex header">
          {
            props?.payload?.length ===1?(
              props?.label==="module"?
              ( <div className="col-span-8 heading pl-2">Defect By {props?.payload[0].split(/[/,:]/)[props?.payload[0].split(/[/,:]/).length-2] } - {defectCount}</div> )
              :(
                <div className="col-span-8 heading pl-2">Defect By {props?.payload[0]}  - {defectCount}</div> 
              )
             
            ):(
              <div className="col-span-8 heading pl-2">Defect By {props?.label} - {defectCount}</div>
            )
          }
         
          <div className="col-span-3 pl-7">
            <div className="alldefect_search">
              <div>
                <input
                  type="text"
                  className="searchfield"
                  value={globalFilter || ""}
                  autoFocus={false}
                  onChange={(e) => {
                   setGlobalFilter(e.target.value);
                  }}
                  placeholder="Search"
                />
              </div>
              <div className="search_icon">
                <Search />
              </div>
            </div>
          </div>

          <div className="col-span-1 flex justify-end mr-1">
            <button
              onClick={() => {
                props.CloseModal(false);
              }}
              type="button"
              className="modal_clos_btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="pt-1 body">
          <div className="all_defects_table_container">
            <table {...getTableProps()}>
              <thead className="">
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="table_headers"
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        width={column.width}
                        {...column.getHeaderProps()}
                        className={`reportTableheader popins text-lg font-semibold ${
                          column.Header === "BUG ID"
                            ? "text-left pl-3"
                            : "text-left pl-3"
                        }`}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              {
                dataLoading ?(
                  <div style={{marginTop:"30%"}}>
                    <CircularLoader/>
                  </div>
                ):(
                  <>
                   <tbody {...getTableBodyProps()} className="segment_table_body"  onScroll={handlescroll}>
                {rows.length === 0 || data?.length === 0 ? (
                  <div className="all_defects_noreportIcon">
                    <img alt="warning" src={NoDefectIcon} />
                    <div className="text_msg">No Relevant Data Is Found !</div>
                  </div>
                ) : (
                  <>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="h-8">
                          {row.cells.map((cell) => {
                            return (
                              <td
                                width={cell?.column?.width}
                                {...cell.getCellProps()}
                                className={`datatrimmer reportTablecell ${
                                  cell.column.Header === "BUG ID"
                                    ? //  || cell.column.Header === "CREATED BY"
                                      "popins"
                                    : "popins"
                                } ${
                                  cell.column.Header === "BUG ID"
                                    ? "text-left pl-3"
                                    : "text-left pl-3"
                                }`}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
                  </>
                )
              }


             
            </table>
          </div>
        </div>
        {defectsPopUp && (
    
        
        <DefectsDeatilsPopup
        CloseDefectModal={CloseDefectModal}
        SelectedDefect={defectsdetails}
        defectTemplatedetails={defectTemplatedetails}
        />
     
      )}
       {userPopup && (
        <UserDeatilsPopup
        CloseTesterModal={CloseTesterModal}
        testerDeatils={userDeatils}
        />
        
      )}
      </div>
    </Modal>
  );
}
export default AllDefectsList;
