import { useEffect, useState } from "react";
import "../../project/project.scss";
import Modal from "react-modal";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { MultiSelect } from "react-multi-select-component";
import { Label } from "../../common/inputs/myInput";

import { makeStyles } from "@material-ui/core/styles";
import {assignTestcaseReq} from "../../../api/api-services"
// import { CommonLoader } from "@src/pages/common/common-loader";
import { CommonLoader } from "../../common/common-loader";
import {getAllLeadUseroftestcase} from "../../../api/api-services"

function Asignee_testCase(props) {
  const [openModal, setOpenModal] = useState(true);
  const [selectedAsignee, setSelectedAsignee] = useState([]);
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "82%",
      transform: "translate(-50%, -50%)",
      width: "37%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
    },
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: 405,
      padding: "10px",
    },
    indeterminateColor: {
      color: "#f50057",
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  // let options = props?.leadUserResponse;
 

  console.log(props.leadUserResponse, "..");
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  let userList=[];
  let [isloading,setisloading]=useState(false);
  // const isAllSelected =
  //   options?.length > 0 && selected?.length === options?.length;
  let selectedproject = JSON.parse(localStorage["selected-project"]);
  let loggedInUser = JSON.parse(localStorage["fire-crowd-user"]);
  let myrole = JSON.parse(localStorage.getItem("selected-user")).role;
  let [options,setOptions]=useState([])

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(
        selected.length === options.length ? [] : options?.map((v) => v.email)
      );
      return;
    }
    setSelected(value);
  };
useEffect(()=>{
  getUserLead()
},[])

  let getUserLead = async () => {
   
    if(myrole!=="TESTER"){
      try {
        let response = await getAllLeadUseroftestcase(
          selectedproject?.id,
          loggedInUser?.userName
        );
        console.log("getUserLead",response)
        if(response?.status===200){
          setOptions(response?.data)
        }
       
        // setLeadUserResponce(response.data);
      } catch (err) {
        console.error(err);
      }
    }
    
  };
 

  const AssignTestCase=async ()=>{
    setisloading(true)
    let projectId= JSON.parse(localStorage.getItem("selected-project")).project
    .fireFlinkProjectId;
    let licenseId=JSON.parse(localStorage.getItem("selected-project")).licenceID
    const user = selectedAsignee.map((item) => ({
      email: item.email,
      name: item.name,
    }));
    let payload={
      users:user,
      modules:props.payload
    }
    console.log("payload",payload)
    try{
      let response=await assignTestcaseReq(payload, projectId,licenseId);
      if(response.status===200){
         const search = window.location.search;
         const id = new URLSearchParams(search).get("id");
          props?.setIsLoading(true);
          props.MyAlert.success("Testcase Assigned Successfully")
         props.getTreeData(id);
      }else{
        props.MyAlert.info("Somthing Went Wrong")
      }

    }catch(error){
      console.log("error")
    }
    setisloading(false)
    props.closeModal(false);
  }

  

  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      style={customStylesPage}
      className="p-px"
    >
      <div className="flex justify-between create-modal-heading h-10 px-2 items-center rounded-tl-xl">
        <div>Assign to - {selectedAsignee ? selectedAsignee?.length : "0"}</div>
        <span className="flex items-center">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="modal-close-btn mr-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>
      {
         isloading && (
          <div><CommonLoader/></div>
        )
      }
       

          <form>
         
          <div className=" p-4">
            <Label
              label={
                <span className="login-labels popins-Regular">Assignee</span>
              }
              required={true}
            />
          </div>
          <MultiSelect
            className=" p-4 -mt-5 "
            options={options?.map((v) => {
              return {
                ...v,
                label: v.email + " - " + v.name,
                value: v.email + " - " + v.name,
              };
            })}
            value={selectedAsignee}
            onChange={setSelectedAsignee}
            labelledBy={"Select"}
            overrideStrings={{
              selectSomeItems: "Select options",
              allItemsAreSelected: selectedAsignee
                ?.map((item) => item?.email + " - " + item.name)
                .join(", "),
            }}
          />
  
          <div className="px-2">
            <div className="py-2 px-3 flex justify-end gap-4 user-create-button ">
              <button
                onClick={() => {
                  setOpenModal(false);
                  props.closeModal(false);
                }}
                type="button"
                className="w-24 gray-btn ml-3 button"
              >
                Cancel
              </button>
              <button
                type="submit"
                id="createBatch"
                disabled={selectedAsignee?.length === 0|| isloading}
                onClick={(event) => {
                  event.preventDefault();
                  // const selectedValues = selectedAsignee.map((item) => ({
                  //   email: item.email,
                  //   name: item.name,
                  // }));
                  // console.log(selectedValues, selectedAsignee);
                  AssignTestCase()
                }}
                className="w-24 primary-btn button"
              >
                Assign
              </button>
            </div>
          </div>
        </form>
        
      
     
    </Modal>
  );
}

export default Asignee_testCase;
