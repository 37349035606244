import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import righticon from "../../assets/right-img-circle.svg";
// import LogotextImage from "../../assets/fire_crowd.svg";
import LogotextImage from "../../assets/Logo/Fireflink_logo.svg";
import ClockImg from "../../assets/clock.png"
import BackgroundDown from "../../assets/login_logo.png";
import style from "./signup.module.scss";
import cx from "classnames";
import "./signup.scss";
import { ReactComponent as SuccessICON } from "../../assets/right-img-circle.svg";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Alert from "@material-ui/lab/Alert";
import {resetActivationLink} from "../../api/api-services"

function ActivationPending() {
  const [success, setSuccess] = useState({ open: false, message: "" });
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const [emailID, setEmailID] = useState();

  useEffect(() => {
    const search = window.location.search;
    const emailId = new URLSearchParams(search).get("emailId");
    setEmailID(emailId);
  }, []);

  function resentActivation(values) {
    if (createUpdateCalled) {
      setCreateUpdateCalled(false);
      const data = {
        emailId:emailID,
        source: "FireCrowd"
      };
      resetActivationLink(data)
        .then((res) => {
          if (res.data && res.data.responseObject) {
            setTimeout(() => {
              setCreateUpdateCalled(true);
            }, 10000);
            setSuccess({ open: true });
          }

          setTimeout(() => {
            setSuccess({ open: false });
          }, 5000);
        })
        .catch((error) => {
          console.error("resend activation link error", error);
        });
    }
    
    
  }

  return (
    <div className="grid grid-cols-12 min-h-screen ">
      <div
        className={cx(
          style["activation_success_link"],
          "col-span-6 border-r-4"
        )}
      >
        <div
          className={cx(
            style["activation_success_link_leftImage"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-10"
          )}
          id="leftImage"
        >
          <div className={cx(style["leftImage"])}>
            <img
              className={cx(style["logo_Icon"])}
              src={LogotextImage}
              height="200px"
              width="300px"
              alt="Qspider"
            />
          </div>
        </div>
        <div className="grid grid-cols-span-12 col-span-6 flex justify-center ">
          <div>
            <img
              height="350px"
              width="350px"
              src={BackgroundDown}
              alt="Firecrowd"
            />
          </div>
        </div>
        <div
          className={cx(
            style["heading"],
            "grid grid-cols-span-12 col-span-6 flex justify-center mt-5"
          )}
        >
          <div>Crowd Testing Platform</div>
        </div>
      </div>
      <div className="col-span-6 pt-20 grid grid-cols-span-12 token_expired">
        <div className="col-span-12 flex justify-center h-10">
          {success.open && (
            <Alert
              className={cx(style["warningAlert"])}
              iconMapping={{
                success: (
                  <CheckCircleIcon
                    fontSize="inherit"
                    className={cx(style["alertIconActivationLink"])}
                  />
                ),
              }}
            >
              <span>Activation link sent successfully</span>
            </Alert>
          )}{" "}
        </div>

        <div className="grid grid-cols-span-12 col-span-12 justify-center token_expired_icon ">
          <div className="expired_icon">
            <img alt="warning" src={ClockImg} />
          </div>
        </div>
        <div className="col-span-12 token_expired_header">
          <div className="flex justify-center header_text">
            Your activation link has expired!
          </div>
        </div>
        <div className="col-span-12 token_msg_text">
          <div className="flex justify-center token_msg">
            Please check your mail box for a latest link or click on
          </div>
        </div>
        <div className="col-span-12 activation_link">
          <div className="flex justify-center activation_text " >
            <span
            className="cursor-pointer"
            onClick={resentActivation}
            onBlur={() => {
              createUpdateCalled(true);
            }}
            >Resend Activation Link
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ActivationPending;
