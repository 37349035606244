import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { ReactComponent as ArrowIcon } from "../../assets/left-arrow-icon.svg";
import "./modal.scss";
import DefectDetails from "./defect-deatils";
import { ReactComponent as ApprovedShortBtn } from "../../assets/approvedShortBtn.svg";
import { ReactComponent as RejectedShortBtn } from "../../assets/rejectedShortBtn.svg";
import { ReactComponent as EditIcon } from "../../assets/editBlack.svg";

function DefectsDeatilsPopup(props) {
  const [openModal, setOpenModal] = useState(true);
  const myRole = JSON.parse(localStorage.getItem("user-privilege")).role;
  let loggedinUser = JSON.parse(localStorage["user-privilege"]);
  let [disbaleEditBtn,setDesiableEditBtn]=useState();
  let [defectStatusbtn,setDefectStatusbtn]=useState()
  let projectstate = JSON.parse(
    localStorage.getItem("selected-project")
  ).projectStatus;
  let emailId = JSON.parse(localStorage["selected-user"]).email;

  useEffect(()=>{
    if(myRole==="TESTER"){
      setDefectStatusbtn(true)
      if((loggedinUser?.defect?.actions?.edit === true &&
        props?.SelectedDefect?.defectDetails.State === "REJECTED" &&
        props?.SelectedDefect?.defectDetails.allowTestersToEdit === true &&
        (projectstate !== "CLOSED" &&  projectstate !== "COMPLETED" )) ||
      (props?.SelectedDefect?.defectDetails.State === "INREVIEW" && (projectstate !== "CLOSED" && projectstate !== "COMPLETED" ))){
        setDesiableEditBtn(false)
      }else{
        setDesiableEditBtn(true)
      }
       
    }else{
      if(["TEST_LEAD", "ACCOUNT_MANAGER", "PROJECT_MANAGER"]?.includes(
        myRole
      ) && props?.SelectedDefect?.defectDetails["Assign to"]?.userEmail === emailId ){
        setDefectStatusbtn(false)
      }else{
        setDefectStatusbtn(true)
      }
      if(props?.SelectedDefect?.defectDetails["Assign to"]?.userEmail ===
      emailId && loggedinUser?.defect?.actions?.edit === true &&  projectstate !== "COMPLETED"){
        setDesiableEditBtn(false)
      }else{
        setDesiableEditBtn(true)
      }
    }

  },[])

  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "65%",
      transform: "translate(-50%, -50%)",
      width: "70%",
      height: "100%",
      background: "#ffff 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
      opacity: "1",
      outline: "none",
    },
  };

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        // props.CloseDefectModal(false);
        // setOpenModal(false);
      }}
    >
      <div className="defect-details_modal">
        {myRole === "PROJECT_OWNER" ? (
          <div className="grid grid-cols-12 flex header">
            <div className="col-span-11 heading pl-2">Defect Details</div>
            <div className="col-span-1 flex justify-end mr-1">
              <button
                onClick={() => {
                  props.CloseDefectModal(false);
                }}
                type="button"
                className="modal_clos_btn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-12 flex header">
           {
            defectStatusbtn?(
              <>
              <div className={`col-span-11 heading pl-2`}>Defect Details</div>
            
            <div className="col-span-1 flex justify-end mr-1">
              <button className={`defectDetails-edit-icon ${disbaleEditBtn?"opacity-60 cursor-pointer":"opacity-100"}`}  disabled={disbaleEditBtn}>
                <EditIcon
                  onClick={() => {
                    props.CloseDefectModal(false);
                    props?.editDefect(props?.SelectedDefect);
                  }}
                />
              </button>
              <button
                onClick={() => {
                  props.CloseDefectModal(false);
                }}
                type="button"
                className="modal_clos_btn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
              </>
          ):(
            <>
            <div className={`col-span-9 heading pl-2`}>Defect Details</div>
           <div className="col-span-2 flex justify-end">
             <div
               className="mr-3"
               onClick={() => {
                 props.CloseDefectModal(false);
                 props?.ApproveOrRejectDefect(
                   "approve",
                   props?.SelectedDefect
                 );
               }}
             >
               <ApprovedShortBtn />
             </div>
             <div
               onClick={() => {
                 props.CloseDefectModal(false);
                 props?.ApproveOrRejectDefect("reject", props?.SelectedDefect);
               }}
             >
               <RejectedShortBtn />
             </div>
           </div>
           <div className="col-span-1 flex justify-end mr-1">
             <button className={`defectDetails-edit-icon ${disbaleEditBtn?"opacity-60 cursor-pointer":"opacity-100"}`}  disabled={disbaleEditBtn}>
               <EditIcon
                 onClick={() => {
                   props.CloseDefectModal(false);
                   props?.editDefect(props?.SelectedDefect);
                 }}
               />
             </button>
             <button
               onClick={() => {
                 props.CloseDefectModal(false);
               }}
               type="button"
               className="modal_clos_btn"
             >
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 className="h-5 w-5"
                 viewBox="0 0 20 20"
                 fill="currentColor"
               >
                 <path
                   fillRule="evenodd"
                   d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                   clipRule="evenodd"
                 />
               </svg>
             </button>
           </div>
           </>
           )
           }
            
          </div>
        )}

        <div className="pt-1 body">
          <div className="pl-4 pt-1">
            <DefectDetails
              SelectedDefect={props?.SelectedDefect}
              defectTemplatedetails={props.defectTemplatedetails}
            />
          </div>

         
        </div>

       
      </div>
    </Modal>
  );
}
export default DefectsDeatilsPopup;
