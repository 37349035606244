import React, { useState } from "react";

import { Label } from "../../common/inputs/myInput";

import "../../config/defect-field-forms.scss";

import RadioForms from "../defectFieldForms/radioForms";
import TextAreaForm from "../defectFieldForms/textAreaForm";
import RatingForm from "../defectFieldForms/ratingForms";

import { ReactComponent as ClosedIcon } from "../../../assets/close_icon.svg";
import { Dropdown } from "../../config/dropdown";
import TextForm from "../defectFieldForms/textForm";
import FeedbackRadioForms from "../defectFieldForms/feedbackRadioFrom";
const FeedbackFieldForm = (props) => {
  const [selectedCustomFieldArray, setSelectedCustomFieldArray] = useState([
    { label: "Text", value: "text" },
    { label: "Radio Button", value: "radioButton" },
    { label: "Ratings", value: "ratings" },
  ]);
  const [selectedCustomValue, setSelectedCustomValue] = useState(
    Object.keys(props.editFieldValue).length !== 0
      ? props.editFieldValue.type
      : "text"
  );
  const [selectedCustomFieldValue, setSelectedCustomFieldValue] = useState();
  function selectFieldValue(data, e) {
    // formikDetails.setFieldValue("modual", data[0]?.value);
    // e.preventDefault()
    let selectedCValue = data[0]?.value;

    setSelectedCustomFieldValue([...data]);
    switch (selectedCValue) {
      case "text":
        setSelectedCustomValue("text");
        // setInitialModalStyle("modal_dialog_TC")
        // setDisabledDropDown(true)
        break;
      case "ratings":
        setSelectedCustomValue("ratings");

        break;
      case "radioButton":
        setSelectedCustomValue("radioButton");

        break;
      default:
        break;
    }
  }

  return (
    <div>
      <div className="">
        <div>
          <div className="flex">
            <div
              className="defecttemplate-labels popins-semi-bold px-2 my-4"
              onClick={() => {
                console.log(props.editFieldValue);
              }}
            >
              {props.editFieldValue.type ? "Edit Field" : "Add Field"}
            </div>
            <div
              className="closedIcon cursor-pointer"
              onClick={() => {
                props.setAddField(false);
                props.setEditFieldValue({});
              }}
            >
              <ClosedIcon height="14px" />
            </div>
          </div>
          <hr
            className="mt-2"
            style={{
              boxShadow:
                "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
            }}
          ></hr>

          <div className=" px-2 mainContainer">
            <div className="my-1 mb-7 selectCustomField">
              <div className="mb-4 ">
                <Label
                  label={
                    <span className="defecttemplate-labels popins-semi-bold ">
                      Select Custom Field
                    </span>
                  }
                  required={true}
                />
              </div>
              <Dropdown
                data={selectedCustomFieldArray}
                selectedData={selectedCustomFieldValue}
                disabled={props.editFieldValue.label}
                searchable={false}
                onChange={(e) => {
                  selectFieldValue(e);
                }}
                placeholder={selectedCustomValue}
              />
            </div>
          </div>
        </div>
      </div>
      <div className=" text-gray-700 font-bold ml-4 -mt-5 mb-1 ">
        {selectedCustomFieldArray.map((val) => {
          if (val.value === selectedCustomValue) {
            return val.label;
          }
        })}
      </div>
      {selectedCustomValue === "text" ? (
        <TextForm
          setAddField={props.setAddField}
          editFieldValue={props.editFieldValue}
          clearEditValue={props.setEditFieldValue}
          MyAlert={props.MyAlert}
          addFormField={props.addFormField}
        />
      ) : selectedCustomValue === "radioButton" ? (
        <FeedbackRadioForms
          setAddField={props.setAddField}
          editFieldValue={props.editFieldValue}
          MyAlert={props.MyAlert}
          clearEditValue={props.setEditFieldValue}
          addFormField={props.addFormField}
        />
      ) : selectedCustomValue === "ratings" ? (
        <RatingForm
          setAddField={props.setAddField}
          editFieldValue={props.editFieldValue}
          MyAlert={props.MyAlert}
          clearEditValue={props.setEditFieldValue}
          addFormField={props.addFormField}
        />
      ) : null}
    </div>
  );
};

export default FeedbackFieldForm;
