import Recat, { useState, useEffect, useRef, useContext } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/addIcon.svg";
import "./testcases.scss";
import { FORM_FIELDS } from "../../pages/config/defectConstant";
import RichTextEditor from "../../pages/config/rich-texteditor";
import { Download } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import {
  getAllPlatformReq,
  updateScriptReq,
  getTestcaseDetailsById,
  getAllTestCaseTemplate,
} from "../../api/api-services";
import { useNavigate, useLocation } from "react-router-dom";
import ColumnResizer from "react-table-column-resizer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TestCaseDetailsRouting from "./testcase_details_selectedTabs";
import { Field, useFormik, FormikProvider } from "formik";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import {
  Select,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { MenuItem } from "@mui/material";
import DatePickerView from "react-datepicker";
import * as yup from "yup";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Autocomplete } from "@material-ui/lab";
import { ReactComponent as BackIconTC } from "../../assets/back_TestCase.svg";
import { ReactComponent as SaveIconTC } from "../../assets/newSaveIcon.svg";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer";

function EdittestCase(props) {
  console.log("EdittestCase", props);
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );

  const [initialValues, setInitialValues] = useState({});
  const [detailsViewData, setdetailsViewData] = useState([]);
  const [testCaseSteps, setTestCaseSteps] = useState();
  let myrole = JSON.parse(localStorage["user-privilege"]).role;
  const { TEXTBOX, LINK, ATTACHMENT, TEXTEDITOR } = FORM_FIELDS;
  let projectId = JSON.parse(localStorage["selected-project"]).id;
  let emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
  const navigate = useNavigate();
  const [rowId, setRowId] = useState();
  const [templateTableData, setTemplateTableData] = useState({});
  let tablepayloadData = {};
  const [isEditable, setIsEditable] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [tcPath, setTcpath] = useState("");
  const [backToTc, setbackToTc] = useState(false);
  const [testCaseTemplateFields, setTestCaseTemplateFields] = useState();

  const [validateSchema, setValidateSchema] = useState({});
  const [fileNameOptions, setFileNameOptions] = useState([]);

  const [testCaseData, setTestCaseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataTouched, setDataTouched] = useState(false);

  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [callingAPI, setCallingAPI] = useState(false);
  const [dataloading, setdataLoading] = useState();

  console.log(initialValues, "initialValues");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });

  let tempValidateSchema = "";
  let tempInitialValues = {};

  let fireFlinkProjectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseId = JSON.parse(localStorage["selected-project"]).licenceID;
  async function getTemplateData(scriptData, projectName) {
    const responseData = await getAllTestCaseTemplate(
      "config",
      fireFlinkProjectId,
      licenseId
    );
    let testCaseDetails =
      responseData?.data?.responseObject?.defaultTemplate?.testCaseDetails;

    testCaseDetails = sortBasedOnOrder(testCaseDetails);
    const testSteps =
      responseData?.data?.responseObject?.defaultTemplate?.testSteps;
    setTestCaseData({
      ...scriptData,
      manualTestCase: {
        ...scriptData.manualTestCase,
        testCaseDetails: testCaseDetails,
        testSteps: testSteps,
      },
    });
    createValidationSchema(testCaseDetails, testSteps, projectName);
    // setdataLoading(true)
  }
  const createValidationSchema = (
    testCaseDetailsResponse,
    testStepsResponse,
    projectName
  ) => {
    let testCaseDetails = JSON.parse(JSON.stringify(testCaseDetailsResponse));
    console.log(testCaseDetails, "testCaseDetails1");
    const checkValidationForAlphanumericSpecialCharacters =
      /^[A-Za-z0-9-_`~!@#$%^&*()+={}[\]\\|;:'",<.>\?/\s+]*$/;
    let testSteps = JSON.parse(JSON.stringify(testStepsResponse));
    if (testSteps?.rows?.length === 0 || !testSteps.rows) {
      let rowData = Array.from({ length: 3 }).map(() => {
        let rowPrototype = {};
        testSteps?.headers.map((data) => {
          rowPrototype[data] = "";
        });
        return { ...rowPrototype };
      });
      testSteps.rows = [...rowData];
      setTemplateTableData(testSteps);
      setdataLoading(true);
    } else {
      setTemplateTableData(testSteps);
      setdataLoading(true);
    }

    let fieldTypeArray = [
      "textField",
      "textArea",
      "dropdown",
      "checkbox",
      "radioButton",
      "link",
      "attachment",
      "date",
      "textEditor",
    ];
    for (let i = 0; i < testCaseDetails.length; i++) {
      let element = testCaseDetails[i];
      let validations = [];
      if (fieldTypeArray.includes(element.type)) {
        if (element.type === "checkbox") {
          element.validationType = "array";
        } else if (element.type === "date") {
          element.validationType = "date";
        } else if (element.type === "attachment") {
          element.validationType = "object";
        } else {
          element.validationType = "string";
        }
      }
      if (["textField", "textArea"].includes(element.type)) {
        let validationObj = {
          type: "matches",
          params: [
            checkValidationForAlphanumericSpecialCharacters,
            `Input should be alphanumeric`,
          ],
        };
        validations.push(
          validationObj,
          {
            type: "trim",
            params: ["Space is not allowed at starting and at the end"],
          },
          {
            type: "strict",
            params: [true],
          }
        );
      }
      if (element?.minLength) {
        let validationObj = {
          type: "min",
          params: [
            element.minLength,
            `Input cannot be less than ${element.minLength} characters`,
          ],
        };
        validations.push(validationObj);
      }
      if (element?.maxLength) {
        let validationObj = {
          type: "max",
          params: [
            element.maxLength,
            `Input cannot be more than ${element.maxLength} characters`,
          ],
        };
        validations.push(validationObj);
      }
      if (element.mandatory === "yes") {
        let validationObj = {
          type: "required",
          params: ["This field is required"],
        };
        validations.push(validationObj);

        if (element.type === "checkbox") {
          let validationObj = {
            type: "min",
            params: [1, "This field is required"],
          };
          validations.push(validationObj);
        }
      }
      if (element.type === "link") {
        let validationObj = {
          type: "url",
          params: ["Enter valid url"],
        };
        validations.push(validationObj);
      }
      element.validations = validations;
      element.id = element.label;
    }
    setTestCaseTemplateFields(testCaseDetails);
    createFormikSchema(testCaseDetails, projectName);
  };
  function createFormikSchema(testCaseDetails, projectName) {
    console.log(testCaseDetails, "testCaseDetails");
    testCaseDetails.forEach((item) => {
      if (item?.type === "checkbox") {
        if (item?.value?.length > 0) {
          tempInitialValues[item.label] = item.value;
        } else {
          tempInitialValues[item.label] = [];
        }
      } else if (["textField", "textArea"].includes(item?.type)) {
        if (item?.value?.length > 0) {
          tempInitialValues[item.label] = item.value[0] || "";
        } else {
          tempInitialValues[item.label] = item.defaultValue || "";
        }
      } else if (item?.type === "date") {
        if (item?.value?.length > 0) {
          const myDate = moment(
            item.value[0],
            item.dateFormat && typeof item.dateFormat === "string"
              ? item?.dateFormat?.toUpperCase()
              : "DD-MM-YYYY"
          ).toDate();
          tempInitialValues[item.label] = myDate || "";
        } else {
          tempInitialValues[item.label] = "";
        }
      } else {
        if (item?.value?.length > 0) {
          tempInitialValues[item.label] = item.value[0] || "";
        } else {
          tempInitialValues[item.label] = "";
        }
      }
      if (
        (item?.label === "Name" || item?.label === "Test Case Name") &&
        item.order === "1"
      ) {
        tempInitialValues[item.label] = projectName;
      }
    });
    setInitialValues(tempInitialValues);
    const yepSchema = testCaseDetails.reduce(createYupSchema, {});
    tempValidateSchema = yup.object().shape(yepSchema);
    setValidateSchema(tempValidateSchema);
  }

  function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  }
  const compareTestStepsObjects = (templateData, scriptData) => {
    let a = templateData?.headers;
    let b = scriptData?.headers;
    let testStepsObject = {};
    let matchedObject = {};
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedText = "";
      for (let j = 0; j < b.length; j++) {
        if (a[i]?.toLowerCase() === b[j]?.toLowerCase()) {
          match = true;
          matchedText = b[j];
          break;
        }
      }
      if (!match) {
        matchedObject[a[i]] = a[i];
      } else {
        matchedObject[matchedText] = matchedText;
      }
    }

    for (let i = 0; i < scriptData?.rows?.length; i++) {
      const keys = Object.keys(matchedObject);
      const rowKeys = Object.keys(scriptData.rows[i]);
      for (let j = 0; j < keys.length; j++) {
        if (!rowKeys.includes(keys[j])) {
          scriptData.rows[i][keys[j]] = "";
        }
      }
    }
    testStepsObject.headers = Object.keys(matchedObject);
    testStepsObject.rows = scriptData.rows;
    return testStepsObject;
  };
  const compareTestCaseDetailsObjects = (a, b) => {
    let testCaseDetailsArray = [];
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedObject = {};
      for (let j = 0; j < b?.length; j++) {
        if (
          a[i]?.label?.toLowerCase() === b[j]?.label?.toLowerCase() &&
          a[i]?.type?.toLowerCase() === b[j]?.type?.toLowerCase()
        ) {
          match = true;
          matchedObject = b[j];
          break;
        }
      }
      if (!match) {
        testCaseDetailsArray.push(a[i]);
      } else {
        a[i].value = matchedObject?.value;
        testCaseDetailsArray.push(a[i]);
      }
    }
    return testCaseDetailsArray;
  };

  const sortBasedOnOrder = (testCaseDetails) => {
    return testCaseDetails.sort((a, b) => {
      return a?.order - b?.order;
    });
  };

  async function getScript() {
    const search = window.location.search;
    const moduleId = new URLSearchParams(search).get("moduleId");
    const ScriptId = new URLSearchParams(search).get("scriptId");
    console.log(moduleId, ScriptId);
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });

    try {
      let response = await getTestcaseDetailsById(
        moduleId,
        ScriptId,
        fireFlinkProjectId,
        licenseId
      );
      let responseData = response?.data?.responseObject;
      if (response?.data?.responseCode === 200) {
        setTestCaseData(response?.data?.responseObject);

        let manualTestCaseObj = response?.data?.responseObject.manualTestCase;

        if (manualTestCaseObj) {
          console.log("responseData", responseData.path);
          setTestCaseData(response?.data?.responseObject);
          if (Object.entries(manualTestCaseObj).length) {
            const response = await getAllTestCaseTemplate(
              "config",
              fireFlinkProjectId,
              licenseId
            );
            if (response?.data?.responseCode === 200) {
              let testCaseDetails = compareTestCaseDetailsObjects(
                response?.data?.responseObject?.defaultTemplate
                  ?.testCaseDetails,
                manualTestCaseObj?.testCaseDetails
              );
              const testSteps = compareTestStepsObjects(
                response?.data?.responseObject?.defaultTemplate?.testSteps,
                manualTestCaseObj?.testSteps
              );
              testCaseDetails = sortBasedOnOrder(testCaseDetails);
              setTestCaseData({
                ...responseData,
                manualTestCase: {
                  ...manualTestCaseObj,
                  testCaseDetails: testCaseDetails,
                  testSteps: testSteps,
                },
              });
              createValidationSchema(
                testCaseDetails,
                testSteps,
                responseData?.name
              );
            }
          } else {
            getTemplateData(responseData, responseData?.name);
          }
        } else {
          getTemplateData(responseData, responseData?.name);
        }
      }
      resetProgress();
    } catch (error) {
      console.error(error);
      resetProgress();
    }
  }
  useEffect(() => {
    setdataLoading(false);
    getScript();
    console.log("useeffect called");
  }, []);
  const getAllplatform = async () => {
    try {
      let response = await getAllPlatformReq(projectId, emailId);
      if (response?.data?.statusCode === 200) {
        localStorage.setItem(
          "props.testCaseData-platform",
          JSON.stringify(response?.data?.platforms)
        );
        // navigateToTempList()
      } else {
        // props.MyAlert.info("Something went wrong");
      }
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    if (myrole === "TESTER") {
      getAllplatform();
    }
  }, []);
  const handleDataChange = (e, props) => {
    // setDataTouched(true);
    props.handleChange(e);
  };

  const formatDate = (dateVal) => {
    return dateVal.replaceAll("-", "/").replaceAll("mm", "MM");
  };
  const handleClickDatepickerIcon = (id) => {
    if (id) {
      document.getElementById(id).focus();
    }
  };
  const haveSameData = function (obj1, obj2) {
    const obj1Length = Object?.keys(obj1)?.length;
    const obj2Length = Object?.keys(obj2)?.length;
    if (obj1Length === obj2Length) {
      return Object?.keys(obj1)?.every(
        (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
      );
    }
    return false;
  };

  const handleOnSubmit = async (formValues) => {
    console.log("handleOnSubmit", formValues);
    const updatedData = JSON.parse(
      JSON.stringify(testCaseData.manualTestCase.testCaseDetails)
    );

    updatedData.forEach((data) => {
      if (data) {
        if (formValues.hasOwnProperty(data.label)) {
          if (formValues[data.label]) {
            if (data.type === "date") {
              data.value = [
                moment(formValues[data.label]).format(
                  data.dateFormat && typeof data.dateFormat === "string"
                    ? data.dateFormat.toUpperCase()
                    : "DD-MM-YYYY"
                ),
              ];
            } else if (data.type === "checkbox") {
              data.value = formValues[data.label];
            } else {
              data.value = [formValues[data.label]];
            }
          } else {
            if (data.label === "Module/Path") {
              // data.value=[testCaseData.path.split("/")[2]]
              data.value = [testCaseData.parentName];
            } else {
              data.value = [];
            }
          }
        }
      }
    });
    const testCaseDataCopy = { ...testCaseData };
    if (testCaseDataCopy?.manualTestCase) {
      testCaseDataCopy.manualTestCase.testCaseDetails = updatedData;
    }
    if (testCaseDataCopy?.manualTestCase) {
      testCaseDataCopy.manualTestCase.testSteps = tablepayloadData;
    }
    console.log(testCaseDataCopy, "formValues");
    try {
      const search = window.location.search;
      const moduleId = new URLSearchParams(search).get("moduleId");
      const ScriptId = new URLSearchParams(search).get("scriptId");
      setCallingAPI(true);

      if (
        haveSameData(
          JSON.stringify(testCaseDataCopy),
          JSON.stringify(props.testCaseData)
        )
      ) {
        props.MyAlert.info(`No changes were done to the field`);
        setCallingAPI(false);
        return;
      }

      const isTestDescriptionEmpty = tablepayloadData.rows.some((row) => {
        const testDescription = row["Test Description"];
        if (Array.isArray(testDescription)) {
          return testDescription.some((desc) => desc.trim() !== "");
        }
        return testDescription && testDescription.trim() !== "";
      });
      console.log(isTestDescriptionEmpty, "isTestDescriptionEmpty");
      if (!isTestDescriptionEmpty) {
        props.MyAlert.info("Please enter Test Description");
        setCallingAPI(false);
        return;
      }

      const nonEmptyRows = tablepayloadData.rows.filter((row) => {
        const rowValues = Object.values(row);
        const isEmptyRow = rowValues.every((value) => {
          if (Array.isArray(value)) {
            return (
              value.length === 0 || value.every((val) => val.trim() === "")
            );
          }
          // return !value || value.trim() === '';
          return !value;
        });
        return !isEmptyRow;
      });

      tablepayloadData.rows = nonEmptyRows;

      const response = await updateScriptReq(
        moduleId,
        ScriptId,
        testCaseDataCopy,
        fireFlinkProjectId,
        licenseId
      );

      if (response?.data?.responseCode === 200) {
        setDataTouched(false);
        setEditMode(false);
        props.getScriptDetails();
        props.setEditMode(false);
        setbackToTc(true);
        // if (history?.location?.state) {
        //   history.location.state = undefined;
        //   history.replace({ ...history.location });
        // }
        // getScript(moduleId, ScriptId);
        props.MyAlert.success(`Changes are saved successfully`);
      } else {
        props.MyAlert.warning(response?.data?.status);
        console.error(response);
      }
    } catch (err) {
      console.error(err);
    }
    setTestCaseData({ ...testCaseDataCopy });
    setCallingAPI(false);
  };
  const downloadFile = (file) => {
    // downloadFileFromCloud(file.id)
    //   .then((d) => {
    //     saveFileFromBlob(d.data, file.label);
    //   })
    //   .catch((err) => {
    //     console.error("donwload file from cloud", err);
    //   });
  };

  const testCaseComponents = (data, props, error) => {
    if (data && props) {
      console.log("testCaseComponents", props?.values[data.id]);
      const value = props?.values[data.id];
      // const value = data.value[0];
      const { id, type, enable, placeholder, maxLength } = data;
      switch (type) {
        case "textField":
          return enable === "yes" ? (
            <div
              style={{ height: "36px" }}
              className="mt-3 fontPoppinsRegularMd customEditTestCase user-input-container"
            >
              {id === "Module/Path" ? (
                <TextField
                  title={value}
                  error={formik.errors[id] && formik.touched[id] ? true : false}
                  className="w-64 "
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  autoComplete="off"
                  name={id}
                  id={id}
                  placeholder={placeholder}
                  onChange={(e) => handleDataChange(e, props)}
                  helperText={
                    formik.errors[id] && formik.touched[id] ? error : ""
                  }
                  // value={testCaseData.path.split("/")[2]}
                  value={testCaseData.parentName}
                  disabled
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
              ) : (
                <TextField
                  title={value}
                  error={formik.errors[id] && formik.touched[id] ? true : false}
                  className="w-64 "
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  autoComplete="off"
                  name={id}
                  id={id}
                  placeholder={placeholder}
                  onChange={(e) => handleDataChange(e, props)}
                  helperText={
                    formik.errors[id] && formik.touched[id] ? error : ""
                  }
                  value={value}
                  disabled={id === "Test Case Name" ? true : false}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
              )}
            </div>
          ) : (
            <label className="label-overflow fontPoppinsRegularMd testCase">
              {value}
            </label>
          );

        case "textArea":
          return (
            <>
              {enable === "yes" ? (
                <div className="-mt-1">
                  <div>
                    <TextareaAutosize
                      title={value}
                      name={id}
                      id={id}
                      onChange={(e) => handleDataChange(e, props)}
                      maxLength={maxLength}
                      value={value}
                      minRows={1}
                      maxRows={1}
                      placeholder={placeholder}
                      className="block w-64 fontPoppinsRegularMd input-style-textarea  popup-input-bg pl-px  mt-1  border-0 border-b input-field-color descriptionStyle"
                      disabled={!editMode}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                    />
                    {maxLength ? (
                      <div className="flex justify-between">
                        <div>
                          {formik.errors[id] && formik.touched[id] && (
                            <p className="MuiFormHelperText-root Mui-error mt-1">
                              {error}
                            </p>
                          )}
                        </div>
                        <div className="mt-1 text-sm text-gray-500 text-right  lg:mr-32 md:mr-10 sm:mr-16 ">
                          {value ? value.length : 0}/{maxLength}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {formik.errors[id] && formik.touched[id] && (
                          <p className="MuiFormHelperText-root Mui-error mt-1">
                            {error}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <label className="label-overflow testCase">{value}</label>
              )}
            </>
          );

        case "textEditor":
          return (
            <>
              <div className="mt-2">
                <FormikProvider value={formik}>
                  <Field name={id}>
                    {({ form, field }) => {
                      const { setFieldValue } = form;
                      const { value } = field;
                      return (
                        <div>
                          <RichTextEditor
                            readOnly={enable === "no" ? true : false}
                            toolbarHidden={enable === "no" ? true : false}
                            convertedContent={value}
                            setConvertedContent={(content) => {
                              if (content) {
                                const validateContent = JSON.parse(content);
                                if (
                                  validateContent?.blocks?.length === 1 &&
                                  validateContent?.blocks[0]?.text === ""
                                ) {
                                  setFieldValue(id, "");
                                } else {
                                  setFieldValue(id, content);
                                }
                              } else {
                                setFieldValue(id, "");
                              }
                            }}
                          />
                          <div>
                            {formik.errors[id] && formik.touched[id] && (
                              <p className="MuiFormHelperText-root Mui-error mt-1">
                                {error}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                </FormikProvider>
              </div>
            </>
          );

        case "radioButton":
          return (
            <>
              <div className="w-100 mt-2">
                <div className="grid grid-cols-2 gap-2">
                  {data.options.map((option, index) => (
                    <div key={index} className="flex" title={option}>
                      <input
                        type="radio"
                        disabled={!editMode}
                        onChange={(e) => handleDataChange(e, props)}
                        className="mr-2"
                        name={id}
                        id={id}
                        value={option}
                        checked={option === value}
                      />
                      <label className="text-xs overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                {formik.errors[id] && formik.touched[id] && (
                  <p className="MuiFormHelperText-root Mui-error mt-1.5">
                    {error}
                  </p>
                )}
              </div>
            </>
          );

        case "link":
          return (
            <>
              <div className="flex mt-3 user-input-container">
                <Tooltip title="Click here to navigate" placement="top">
                  <div
                    onClick={() => {
                      //   navigateToUrl(value, formik.errors[id]);
                    }}
                  >
                    {/* {value && !formik.errors[id] ? (
                      <LinkIconBlue className="h-4 w-4 mt-1 mr-2 origin-center cursor-pointer" />
                    ) : (
                      <LinkIcon className="h-4 w-4 mt-1 mr-2 origin-center cursor-pointer" />
                    )} */}
                  </div>
                </Tooltip>
                <TextField
                  title={value}
                  error={formik.errors[id] && formik.touched[id] ? true : false}
                  className="w-56 link-placeholder-text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="text"
                  autoComplete="off"
                  name={id}
                  id={id}
                  placeholder="Attached Link will be shown here if any URL is added"
                  onChange={(e) => handleDataChange(e, props)}
                  helperText={
                    formik.errors[id] && formik.touched[id] ? error : ""
                  }
                  value={value}
                  disabled={!editMode}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
              </div>
            </>
          );

        case "dropdown":
          return (
            <>
              {value !== undefined && (
                <div className="mt-2">
                  <FormControl>
                    <Select
                      onBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                      disabled={!editMode}
                      // MenuProps={{
                      //   getContentAnchorEl: null,
                      //   anchorOrigin: {
                      //     vertical: "bottom",
                      //     horizontal: "left",
                      //   },
                      // }}
                      id={id}
                      name={id}
                      onChange={(e) => handleDataChange(e, props)}
                      value={value}
                      defaultValue=""
                      className="w-64 select-selected-value fontPoppinsRegularMd "
                    >
                      {data.options.map((option, index) => (
                        <MenuItem
                          title={option}
                          key={index}
                          className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 text-xs w-64 overflow-hidden overflow-ellipsis whitespace-nowrap manual-test-case-select"
                          value={option}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.errors[id] && formik.touched[id] && (
                      <p className="MuiFormHelperText-root Mui-error">
                        {error}
                      </p>
                    )}
                  </FormControl>
                </div>
              )}
            </>
          );

        case "date":
          return (
            <>
              {value !== undefined && (
                <div className="flex mt-3">
                  <div className=" h-8 border-2 border-gray-300 w-60">
                    <FormikProvider value={formik}>
                      <Field name={id}>
                        {({ form, field }) => {
                          const { setFieldValue } = form;
                          const { value } = field;
                          return (
                            <DatePickerView
                              className="h-7"
                              id={id}
                              {...field}
                              disabled={!editMode}
                              selected={value}
                              onChange={(val) => {
                                setDataTouched(true);
                                setFieldValue(id, val);
                              }}
                              onKeyDown={(e) => e.preventDefault()}
                              dateFormat={
                                data.dateFormat &&
                                typeof data.dateFormat === "string"
                                  ? formatDate(data.dateFormat)
                                  : "dd/MM/yyyy"
                              }
                              maxDate={new Date()}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                              }}
                            />
                          );
                        }}
                      </Field>
                    </FormikProvider>
                  </div>
                  <div
                    className=" bg-gray-200"
                    onClick={() => editMode && handleClickDatepickerIcon(id)}
                  >
                    <CalendarTodayOutlinedIcon className="mt-1" />
                  </div>
                </div>
              )}

              <div className="text-xs opacity-50">
                [
                {data.dateFormat && Array.isArray(data.dateFormat)
                  ? data.dateFormat.join(",")
                  : data.dateFormat}
                ]
              </div>
              {formik.errors[id] && formik.touched[id] && (
                <p className="MuiFormHelperText-root Mui-error">{error}</p>
              )}
            </>
          );

        case "checkbox":
          return (
            <>
              <div className="w-100 mt-2">
                <div className="grid grid-cols-2 gap-2">
                  {data.options.map((option, index) => (
                    <div key={index} className="flex" title={option}>
                      <input
                        disabled={!editMode}
                        type="checkbox"
                        className="mr-2"
                        name={id}
                        id={id}
                        value={option}
                        onChange={(e) => {
                          handleDataChange(e, props);
                        }}
                        checked={
                          value && value.length > 0 && value.includes(option)
                        }
                      />
                      <label className="text-xs overflow-hidden overflow-ellipsis whitespace-nowrap">
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                {formik.errors[id] && formik.touched[id] && (
                  <p className="MuiFormHelperText-root Mui-error mt-1.5">
                    {error}
                  </p>
                )}
              </div>
            </>
          );

        case "attachment":
          return (
            <>
              <div className="mt-1">
                <FormikProvider value={formik}>
                  <Field name={id}>
                    {({ form, field }) => {
                      const { setFieldValue } = form;
                      const { value } = field;
                      return (
                        <>
                          <div className="flex flex-row ">
                            <div>
                              <Autocomplete
                                disabled={!editMode}
                                className={
                                  value
                                    ? "mt-1 open-sans-regular w-56 auto-complete"
                                    : "mt-1 open-sans-regular w-64 auto-complete"
                                }
                                value={value || null}
                                options={fileNameOptions}
                                renderOption={(option) => (
                                  <Typography
                                    title={option.label}
                                    className="select-selected-value text-gray-500"
                                  >
                                    {option.label}
                                  </Typography>
                                )}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, val) => {
                                  setDataTouched(true);
                                  if (val !== null) {
                                    fileNameOptions.forEach((file) => {
                                      if (val.id === file.id) {
                                        setFieldValue(
                                          id,
                                          val !== null ? file : ""
                                        );
                                      }
                                    });
                                  } else {
                                    setFieldValue(id, "");
                                  }
                                }}
                                name={id}
                                id={id}
                                renderInput={(params) => (
                                  <TextField
                                    onBlur={(e) => {
                                      formik.handleBlur(e);
                                    }}
                                    error={
                                      formik.errors[id] && formik.touched[id]
                                        ? true
                                        : false
                                    }
                                    {...params}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    className="test-case-font"
                                    placeholder="Search and select file name"
                                    variant="standard"
                                    helperText={
                                      formik.errors[id] && formik.touched[id]
                                        ? error
                                        : ""
                                    }
                                    name={id}
                                  />
                                )}
                              />
                            </div>
                            {value && (
                              <div className="ml-2 mt-2 w-20">
                                <img
                                  onClick={() => {
                                    downloadFile(value);
                                  }}
                                  className="more-button relative inline-block text-left ml-1 cursor-pointer"
                                  src="/assets/images/file_download.svg"
                                  alt=""
                                  height="25px"
                                  width="25px"
                                />
                              </div>
                            )}
                          </div>
                        </>
                      );
                    }}
                  </Field>
                </FormikProvider>
              </div>
            </>
          );

        default:
          return (
            <>
              <label className="label-overflow testCase"></label>
            </>
          );
      }
    }
  };
  const nonEditableView = (data) => {
    console.log(data, "dataaaa", testCaseData);
    let value = "";
    if (
      (data?.label === "Name" || data?.label === "Test Case Name") &&
      data?.order === "1"
    ) {
      // value = testCaseData.name;
      value = data?.value[0];
    } else if (data?.type === "attachment") {
      value =
        data.value && Array.isArray(data.value) ? data.value[0] : data.value;
    } else {
      value =
        data.value && Array.isArray(data.value)
          ? data.value.join(",")
          : data.value;
    }
    const defaultValue = data?.defaultValue || "";
    if (data.type === "link" && value) {
      return (
        <>
          <a
            href={value}
            target="_blank"
            className="label-overflow tab-style-common"
          >
            {value}
          </a>
        </>
      );
    }
    if (data.type === "attachment" && value) {
      return (
        <>
          <label className="label-overflow testCase fontPoppinsRegularMd">
            {value?.label || "--"}
          </label>
          {/* <img
          onClick={() => {
            downloadFile(value);
          }}
          className="more-button relative inline-block text-left ml-1 cursor-pointer"
          src="/assets/images/file_download.svg"
          alt=""
          height="25px"
          width="25px"
        /> */}
        </>
      );
    }
    if (data.type === "textEditor") {
      return (
        <div className="mt-2">
          <RichTextEditor
            convertedContent={value || ""}
            readOnly={true}
            toolbarHidden={true}
          />
        </div>
      );
    }
    return (
      <>
        <label className="label-overflow testCase fontPoppinsRegularMd">
          {value ? value : defaultValue ? defaultValue : "--"}
        </label>
      </>
    );
  };
  const testCaseDetailElements = (data, props, error, index) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="flex ">
              <h6 className="common-label-text labelWidth">
                {data ? (
                  <Tooltip title={data?.label} placement="top">
                    {editMode ? (
                      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap fontPoppinsMediumMd">
                        {data?.mandatory === "yes" ? (
                          <span className="text-red-400 mr-1">&#42;</span>
                        ) : null}
                        <span
                          className="fontPoppinsMediumMd"
                          style={{ color: "#8F8E8E" }}
                        >
                          {data?.label}
                        </span>
                      </div>
                    ) : (
                      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap fontPoppinsMediumMd">
                        <span className="fontPoppinsMediumMd">
                          {data?.label}
                        </span>
                      </div>
                    )}
                  </Tooltip>
                ) : null}
              </h6>
            </div>
          </div>
          {data.enable === "yes" && (
            <div className="contentDataStyle">
              {testCaseComponents(data, props, error, index)}
            </div>
          )}
        </div>
      </>
    );
  };
  const templateTableDataSetter = (data) => {
    setTemplateTableData({ ...data });
  };
  const TestStepsTable = (props) => {
    let [tableRowData, setTableRowData] = useState(props.tableData.rows);
    props?.tableData?.rows?.map((obj, index) => {
      let tempKeys = Object.keys(obj);
      tempKeys.map((obj1, index1) => {
        let tempArray = [];
        if (obj1 !== "SL No" && obj1 !== "Status") {
          if (!Array.isArray(obj[obj1])) {
            tempArray.push(obj[obj1]);
            obj[obj1] = tempArray;
          }
        }
      });
      return obj;
    });
    console.log(props.tableData.rows, "props.tableData.rows");
    tablepayloadData = props.tableData;

    const tsRowChangeHandler = (e, index, cellName, currentRowData) => {
      setDataTouched(true);
      let rowData = [...tableRowData];
      let editedValue;
      if (
        Array.isArray(currentRowData[cellName]) ||
        e.target.innerText.includes("\n")
      ) {
        editedValue = e.target.innerText.replaceAll("\n\n", "\n").split("\n");
        if (!editedValue[editedValue.length - 1]) {
          editedValue.pop();
        }
        rowData[index][cellName] = editedValue;
      } else {
        const value = e.target.innerText.trim(); // Trim the value
        if (value === "") {
          delete rowData[index][cellName]; // Remove the cell if value is empty
        } else {
          rowData[index][cellName] = value;
        }
      }
      tablepayloadData = { headers: props.tableData?.headers, rows: rowData };
    };

    const addRowDDTS = (index) => {
      setDataTouched(true);
      let rowData = tableRowData;
      let rowPrototype = {};
      props.tableData?.headers.map((data) => {
        rowPrototype[data] = "";
      });
      rowData.splice(index, 0, rowPrototype);
      props.templateTableDataSetter({
        headers: props.tableData?.headers,
        rows: rowData,
      });
    };
    const deleteRowDDTS = (index) => {
      setDataTouched(true);
      let rowData = tableRowData;
      if (tableRowData.length > 1) {
        rowData.splice(index, 1);
        props.templateTableDataSetter({
          headers: props.tableData?.headers,
          rows: rowData,
        });
      }
    };
    const AddRow = (props) => {
      const [showOptions, setShowOptions] = useState(false);
      const [rowId, setRowId] = useState();
      let menuRef = useRef();
      useEffect(() => {
        let handeler = (event) => {
          if (menuRef.current && !menuRef?.current?.contains(event.target)) {
            setShowOptions(false);
          }
        };
        document.addEventListener("mousedown", handeler);
        return () => {
          document.removeEventListener("mouseleave", handeler);
        };
      }, []);
      const handelDropdown = () => {
        setShowOptions(!showOptions);
      };
      return (
        <>
          {showOptions && rowId === props.index && (
            <>
              <div
                className="shadow-none  origin-top-right absolute  w-40 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
                ref={menuRef}
              >
                <div
                  style={{
                    width: "192px",
                    backgroundColor: "#ffffff",
                    border: "1px solid grey",
                    position: "absolute",
                    top: "40px",
                  }}
                  className="create_Issue_Modal -my-20"
                  contentEditable={false}
                >
                  <div
                    className="cursor-pointer hover:bg-gray-200 p-1 fontPoppinsRegularMd text-left"
                    onClick={() => addRowDDTS(props.index)}
                  >
                    <span className="ml-2">Insert row above</span>
                  </div>
                  <div
                    className="cursor-pointer hover:bg-gray-200 p-1 fontPoppinsRegularMd text-left"
                    onClick={() => addRowDDTS(props.index + 1)}
                  >
                    <span className=" ml-2">Insert row below</span>
                  </div>
                  {props.data.length === 1 ? null : (
                    <div
                      className="cursor-pointer hover:bg-gray-200 p-1 pl-0.5 fontPoppinsRegularMd text-left"
                      onClick={() => deleteRowDDTS(props.index)}
                    >
                      <span className="ml-2">Delete row</span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <Tooltip title="Insert/Delete" placement="top">
            <button
              disabled={!editMode}
              contentEditable={false}
              onClick={() => {
                handelDropdown();
                setRowId(props.index);
              }}
            >
              <MoreVertIcon />
            </button>
          </Tooltip>
        </>
      );
    };
    return (
      <>
        <div>
          <div className="shadow">
            <div
              className=" "
              id="journal-scroll"
              style={{
                width: "100%",
                overflow: "auto",
                //   maxHeight: "267px",
                // height: "auto",
                height: "348px",
                overflowY: "auto",
                position: "relative",
                marginTop: "5%",
              }}
            >
              <table className="testStepTable  w-full">
                <thead
                  style={{
                    display: " table-header-group",
                    verticalAlign: "middle",
                    position: "sticky",
                    top: "-1px",
                    zIndex: "1",
                  }}
                  className="bg-gray-100"
                  key={"tcmthead"}
                >
                  <tr key={"tableHeader"}>
                    <th
                      className="p-2 testStepTable"
                      key={"emptycelladdcolumn"}
                    ></th>
                    {props.tableData?.headers?.map((data, index) => {
                      console.log(data, "table header", index);
                      return (
                        !["actual result", "status"].includes(
                          data?.toLowerCase()
                        ) && (
                          <>
                            <th
                              className="p-2 testStepTable common-label-text fontPoppinsMediumMd text-blue-700"
                              key={data + index}
                            >
                              {data}{" "}
                            </th>
                            <ColumnResizer
                              minWidth={100}
                              className="columnResizer"
                            />
                          </>
                        )
                      );
                    })}
                  </tr>
                </thead>
                <tbody
                  key={"tcmtbody"}
                  style={{
                    height: "auto",
                    overflowY: "auto",
                    display: " table-row-group",
                    verticalAlign: "middle",
                  }}
                >
                  {tableRowData?.map((rowData, index) => (
                    <tr
                      style={{
                        display: " table-row",
                        verticalAlign: "inherit",
                      }}
                      className={`h-6 testStepTable ${
                        (index + 1) % 2 === 0
                          ? "test-case-table-even-row"
                          : "test-case-table-odd-row"
                      }`}
                      key={index}
                    >
                      <td
                        className="testStepTable fontPoppinsRegularSm"
                        key={"add" + index}
                      >
                        <AddRow index={index} data={[...tableRowData]} />
                      </td>
                      {props.tableData?.headers?.map((cellname, colIndex) => {
                        return (
                          !["actual result", "status"].includes(
                            cellname?.toLowerCase()
                          ) && (
                            <>
                              <td
                                className="testStepTable fontPoppinsRegularSm text-left pl-2 table-cell-text focus:border-opacity-0 focus:outline-none"
                                key={index + colIndex}
                                onInput={(e) =>
                                  tsRowChangeHandler(
                                    e,
                                    index,
                                    cellname,
                                    rowData
                                  )
                                }
                                contentEditable={editMode}
                                suppressContentEditableWarning={true}
                              >
                                {Array.isArray(rowData[cellname])
                                  ? rowData[cellname].map((data) => (
                                      <>
                                        {data}
                                        <br />
                                      </>
                                    ))
                                  : rowData[cellname]}{" "}
                              </td>
                              <ColumnResizer
                                minWidth={100}
                                className="columnResizer"
                              />
                            </>
                          )
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="page-table-body w-full" id="journal-scroll">
        {dataloading && (
          <>
            <div className="content_area_style" id="journal-scroll">
              <div className="tab-height content-height">
                <div
                  // className={
                  //   props.expand
                  //     ? "mt-3 mx-6 my-6 shadow-none flex block"
                  //     : "mt-3 mx-6 my-6 shadow-none flex hidden"
                  // }
                  className="mt-3 mx-6 my-6 shadow-none flex "
                >
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3, sm: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {testCaseTemplateFields &&
                      testCaseTemplateFields.length > 0 &&
                      testCaseTemplateFields.map((data, index) => {
                        let error =
                          formik.errors.hasOwnProperty(data.id) &&
                          formik.errors[data.id];

                        {
                          return data?.type === "textEditor" ? (
                            <Grid item xs={12} sm={12} md={12} key={data.id}>
                              {testCaseDetailElements(
                                data,
                                formik,
                                error,
                                index
                              )}
                              {console.log(data, formik, "grid", data.id)}
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              className=""
                              key={data.id}
                            >
                              {testCaseDetailElements(
                                data,
                                formik,
                                error,
                                index
                              )}
                              {console.log(data, formik, "grid", data.id)}
                            </Grid>
                          );
                        }
                      })}
                  </Grid>
                  <div className="flex absolute top-12 right-px cursor-pointer">
                    <BackIconTC
                      onClick={() => {
                        backToTc
                          ? props.setEditMode(false)
                          : navigate("/project/testcases");
                      }}
                    />
                    <div className="">
                      <button
                        // disabled={disableSaveButton}
                        type="button"
                        // onClick={() => {
                        //   if (props.editMode) {
                        //     // formik.handleSubmit();
                        //   } else {
                        //     props.setEditMode(true);
                        //   }
                        // }}
                        onClick={formik.handleSubmit}
                        className="ml-3  px-3.5"
                        disabled={callingAPI}
                      >
                        <SaveIconTC />
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  {props.expand ? (
                    <TestStepsTable
                      tableData={templateTableData}
                      templateTableDataSetter={templateTableDataSetter}
                    />
                  ) : (
                    ""
                  )}

                  {/* <Prompt
                    when={dataTouched}
                    message={JSON.stringify({
                      headerTitle: HEADER_TITLE,
                      subHeaderTitle: SUB_HEADER_TITLE,
                      bodyText: BODY_TEXT,
                      subBodyText: SUB_BODY_TEXT,
                    })}
                  /> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default EdittestCase;
