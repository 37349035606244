import { SvgIcon } from '@material-ui/core';
import {ReactComponent as exportAllSvg} from './skin/icons/expand-tree.svg'
import {ReactComponent as expanTreeDisable} from './skin/icons/expand-tree-disable.svg'
import {ReactComponent as showAllScriptsSvg} from './skin/icons/show-all-scripts.svg'
import {ReactComponent as showAllConditionsSvg} from './skin/icons/show-pre-post-conditions.svg'

export function ExpandAllIcon({disable}) {

  return disable ? <SvgIcon component={expanTreeDisable} />
  : (
    <SvgIcon component={exportAllSvg} />
  )
}

export function CollapseAllIcon({disable}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="24" viewBox="0 0 40 24">
      <g id="Group_5253" data-name="Group 5253" transform="translate(16305 -22010)">
        <g id="arrow_right_alt_black_48dp" transform="translate(-16265 22010) rotate(90)">
          <path id="Path_186" data-name="Path 186" d="M0,0H24V24H0Z" fill="none"/>
          <path id="Path_187" data-name="Path 187" d="M16.01,11H4v2H16.01v3L20,12,16.01,8Z" fill={disable?"#555555":"#1648c6"} />
        </g>
        <g id="account_tree_black_48dp" transform="translate(-16305 22010)">
          <rect id="Rectangle_968" data-name="Rectangle 968" width="24" height="24" fill="none"/>
          <path id="Path_185" data-name="Path 185" d="M22,11V3H15V6H9V3H2v8H9V8h2V18h4v3h7V13H15v3H13V8h2v3ZM7,9H4V5H7Zm10,6h3v4H17ZM17,5h3V9H17Z" fill={disable?"#555555":"#1648c6"}/>
        </g>
      </g>
    </svg>
  )
}

export function ShowAllChildsIcon() {
  return (
    <SvgIcon component={showAllScriptsSvg} />
  )
}

export function HideAllChildsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="24" viewBox="0 0 39 24">
      <g id="Group_5252" data-name="Group 5252" transform="translate(16270 -22010)">
        <g id="arrow_right_alt_black_48dp" transform="translate(-16231 22010) rotate(90)">
          <path id="Path_186" data-name="Path 186" d="M0,0H24V24H0Z" fill="none"/>
          <path id="Path_187" data-name="Path 187" d="M16.01,11H4v2H16.01v3L20,12,16.01,8Z" fill="#1648c6"/>
        </g>
        <g id="format_align_justify_black_48dp" transform="translate(-16270 22010)">
          <path id="Path_188" data-name="Path 188" d="M0,0H24V24H0Z" fill="none"/>
          <path id="Path_189" data-name="Path 189" d="M3,21H21V19H3Zm0-4H21V15H3Zm0-4H21V11H3ZM3,9H21V7H3ZM3,3V5H21V3Z" fill="#1648c6"/>
        </g>
      </g>
    </svg>
  )
}

export function ShowAllConditionIcon() {
  return (
    <svg id="sync_alt_black_48dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="Group_5878" data-name="Group 5878">
        <rect id="Rectangle_1429" data-name="Rectangle 1429" width="20" height="20" fill="none"/>
      </g>
      <g id="Group_5880" data-name="Group 5880" transform="translate(-2.495 -2.206)">
        <g id="Group_5879" data-name="Group 5879" transform="translate(3.2 4)">
          <path id="Path_367" data-name="Path 367" d="M21.592,6.65,17.907,4V6H5.391C4.626,6,4,6.45,4,7H4c0,.55.626,1,1.391,1H17.907v2l3.685-2.65A.408.408,0,0,0,21.592,6.65Z" transform="translate(-3.2 -4)" fill="#1648c6"/>
          <path id="Path_368" data-name="Path 368" d="M19.607,12H7.093V10L3.409,12.65a.406.406,0,0,0,0,.71L7.093,16V14H19.607c.765,0,1.39-.45,1.39-1h0C21,12.45,20.372,12,19.607,12Z" transform="translate(-3.2 0.412)" fill="#1648c6"/>
        </g>
      </g>
    </svg>
  )
}

export function HideAllConditionIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="sync_alt_black_48dp" transform="translate(20) rotate(90)">
        <g id="Group_5317" data-name="Group 5317">
          <rect id="Rectangle_1042" data-name="Rectangle 1042" width="20" height="20" fill="none"/>
        </g>
        <g id="Group_5319" data-name="Group 5319" transform="translate(-2.495 -2.206)">
          <g id="Group_5318" data-name="Group 5318" transform="translate(3.2 4)">
            <path id="Path_315" data-name="Path 315" d="M21.592,6.65,17.907,4V6H5.391C4.626,6,4,6.45,4,7H4c0,.55.626,1,1.391,1H17.907v2l3.685-2.65A.408.408,0,0,0,21.592,6.65Z" transform="translate(-3.2 -4)" fill="#1648c6"/>
            <path id="Path_316" data-name="Path 316" d="M19.607,12H7.093V10L3.409,12.65a.406.406,0,0,0,0,.71L7.093,16V14H19.607c.765,0,1.39-.45,1.39-1h0C21,12.45,20.372,12,19.607,12Z" transform="translate(-3.2 0.412)" fill="#1648c6"/>
          </g>
        </g>
      </g>
    </svg>
  )
}