import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { Label } from "../../common/inputs/myInput";
import { TextField } from "@mui/material";
import "../project.scss";
import { ReactComponent as WarningTimer } from "../../../assets/oldTimer.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../../assets/homeIcon.svg";
import { platFormEmailSend, getAllPlatformReq } from "../../../api/api-services";
import { ReactComponent as EmailMobileCombineIcon } from "../../../assets/emailMobileCombineIcon.svg";
import { ReactComponent as EmailMobileHandCombineIcon } from "../../../assets/emailMobileHandCombineIcon.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/verifyDeviceIcon.svg";
import { ReactComponent as TickIcon } from "../../../assets/tickIcon.svg";
import { ReactComponent as LoaderIcon } from "../../../assets/loaderIcons.svg";
import { ReactComponent as CheckCircle } from "../../../assets/checkCircle.svg";
import { ProgressContainerContext } from "../../common/inputs/ProgressContainer";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { ReactComponent as LocationHandCombineIcon } from "../../../assets/locationhandCombineIcon.svg";
function WarningLinkDeviceModal(props) {
  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [showTextField, setShowTextField] = useState(false);
  const [reSendMail, setReSendMail] = useState(false);
  const [emailChange, setEmailChange] = useState(false);
  const navigate = useNavigate();
  let allProjects = [{ id: "", name: "All Projects" }];
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "82.5%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      opacity: "1",
      outline: "none",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
      
    },
  };

  let initialValues = {
    label: props.prevEmail,
  };
  const validationSchema = yup.object({
    label: yup
      .string()
      .required("Email/Phone number is required")
      .test("test-name", "Enter Valid Phone/Email", function (value) {
        const emailRegex =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
        let isValidEmail = emailRegex.test(value);
        let isValidPhone = phoneRegex.test(value);
        if (!isValidEmail && !isValidPhone) {
          return false;
        }
        return true;
      }),
  });
  const formikDetails = useFormik({
    initialValues,

    validationSchema,
  });
  const createTestingDevice = async () => {
    let UserId = JSON.parse(localStorage["selected-user"]).id;
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    let emailId = formikDetails.values.label;
    formikDetails.handleSubmit();
    setEmailChange(true)
    try {
      const getdata = await platFormEmailSend(projectId, UserId, emailId);
      if (getdata.status === 200) {
        setShowTextField(false);
        setEmailChange(false)
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const reSendEmail = async () => {
    setReSendMail(true);
    let UserId = JSON.parse(localStorage["selected-user"]).id;
    let projectId = JSON.parse(localStorage["selected-project"]).id;
    // let emailId = props.prevEmail;
    let emailId = formikDetails.values.label
    formikDetails.handleSubmit();

    try {
      const getdata = await platFormEmailSend(projectId, UserId, emailId);
      setReSendMail(false);

      console.log(getdata, emailId);
    } catch (error) {
      console.error("error", error);
    }
  };
  const [iconsList, setIconsList] = useState([
    {
      icons: <EmailMobileCombineIcon height="30px" width="60px" />,
      selectedText: "1. Check your Email or Phone for Verification Link.",
    },
    {
      icons: <EmailMobileHandCombineIcon height="30px" width="60px" />,
      selectedText: "2. Click on link received from Fireflink Crowd.",
    },
    {
      icons: <LocationHandCombineIcon height="30px" width="60px" />,
      selectedText: " 3. Allow to Verify for Location.",
     
    },
    {
      icons: <VerifiedIcon height="30px" width="60px" />,
      selectedText: "4. Click on Verify button",
    },
  ]);

  const refresh = async () => {
    setIsLoading(true)
    resetProgress();
      startOrResumeProgress(
        {
          stopAt: 100,
          intervalDuration: 80,
        });
    let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    let emailId = JSON.parse(localStorage["fire-crowd-user"]).userName;
    try {
      let response = await getAllPlatformReq(projectId, emailId);
      console.log("refresh clicked", response);
      if (response.data.statusCode === 200) {
        props.setActivationPendingModal(false);
        console.log("refresh clicked", response);
      }
    }
    catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>
      <Modal isOpen={openModal} style={customStylesPage} className="p-px ">
        <div className="flex  rounded-tl-xl justify-between defect-template-modal-heading h-10 p-2">
          <div>Activation pending</div>
        
          <button className="refreshicon"
            onClick={() => {
              refresh();
              window.location.reload();
            }}><CachedOutlinedIcon></CachedOutlinedIcon>
          </button>
          {JSON.parse(localStorage.getItem("selected-user"))?.configuration ===
            null ? (
            <button
              onClick={() => {
                // navigate("/project/list", { replace: true });
                navigate("/all-Projects", { replace: true });
              
                localStorage.setItem(
                  "selected-projectId-Name",
                  JSON.stringify(allProjects[0])
                );
              }}
            >
              <HomeIcon />
            </button>
          ) : (
            <button
              onClick={() => {
                props.setActivationPendingModal(false);
              }}
              type="button"
              className="modal-close-btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </div>

        <div className=" overflow-y-scroll " id="journal-scroll">
          <div className="my-4  px-2 waringContentTextField">
            <div className="mb-4">
              <Label
                label={
                  <span className="textFieldHeader text-sm  popins-medium  ">
                    Email ID
                  </span>
                }
                required={true}
                error={
                  formikDetails.errors.label && formikDetails.touched.label
                }
              />
            </div>
            <TextField
              error={formikDetails.errors.label && formikDetails.touched.label}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              type="text"
              name="label"
              id="label"
              disabled={!showTextField}
              className={
                !showTextField
                  ? "custom_heightPlatform bg-gray-300"
                  : "custom_heightPlatform"
              }
              autoComplete="false"
              autoFocus={true}
              placeholder="Type here"
              onBlur={formikDetails.handleBlur}
              onChange={formikDetails.handleChange}
              value={formikDetails.values.label}
            />
            {formikDetails.errors.label && formikDetails.touched.label ? (
              <span className="text-red-500 error_text md:mt-1">
                {formikDetails.errors.label}
              </span>
            ) : null}
            <p className="noteWaringContentTextField">
              Note :&nbsp;Device Verification link will be sent to entered Email ID or Mobile no.
            </p>
            {showTextField && (
              <div
                className={
                  formikDetails.errors.label && formikDetails.touched.label
                    ? "py-2 customBtns flex justify-start gap-4  z-50"
                    : "py-2 customBtnsNoerrors flex justify-start gap-4  z-50"
                }
              >
                <button
                  onClick={() => {
                 
                    formikDetails.setErrors({});
                    setShowTextField(false);
               
                    console.log(formikDetails);
                  }}
                  type="button"
                  className="w-24 gray-btn ml-3 button"
                >
                  Cancel
                </button>
                <button
                  className="w-24 primary-btn button"
                  disabled={formikDetails.errors.label}
                  type="submit"
                  onClick={() => {
                    createTestingDevice();
                    console.log(formikDetails.values.label);
                  
                  }}
                >
                  Send
                </button>
              </div>
            )}
          </div>

          <div className="my-4 px-1 warningContents flex">
            <button className={showTextField ? "cursor-default mx-2 activeLink opacity-50" : "cursor-pointer mx-2 activeLink opacity-100"} disabled={showTextField} onClick={reSendEmail}>
              Resend Verification Link <span className="mx-2">|</span>
            </button>
            {!showTextField && (
              <p
                className={
                  showTextField ? "showText cursor-pointer" : "cursor-pointer"
                }
                onClick={() => {
                  setShowTextField(true);
                }}
              >
                Change Email/Phone Number
              </p>
            )}
          </div>

          <div className="guidanceContainer">
            {reSendMail ? (
              <div className="resentLink flex"><p className="mx-1 mt-0.5"><LoaderIcon /></p><p>Resending new Verification Link.</p></div>
            ) : emailChange ? (<div className="changeEmail">Email ID changed successfully.</div>) : (
              <div className="responseText flex">
                <p className="mx-1 mt-0.5"><CheckCircle /></p>
                <p> Verification link sent successfully.</p>
              </div>
            )}

            
            <div className="guidanceContainerDetails">
              <p className="guidanceHeader">Guidance to add testing device.</p>
              <hr className="customHr"></hr>
             
              <div className="grid grid-cols-12 gap-2 px-2 mt-9">
                <div className="col-span-4 flex justify-center">
                {iconsList[0].icons}
                </div>
                <div className="col-span-4 flex justify-center">
                {iconsList[1].icons}
                </div>
                <div className="col-span-4 flex justify-center">
                {iconsList[2].icons}
                </div>
              </div>
              <div className="grid grid-cols-12 gap-2 px-2 mt-2 detailsContainer">
                <div className="col-span-4 flex justify-center selectedText">
                {iconsList[0].selectedText}
                </div>
                <div className="col-span-4 flex justify-center selectedText">
                {iconsList[1].selectedText}
                </div>
                <div className="col-span-4 flex justify-center selectedText">
                {iconsList[2].selectedText}
                </div>
              </div>

              <div className="grid grid-cols-12 gap-2 px-2 mt-9">
                <div className="col-span-4 flex justify-center">
                {iconsList[3].icons}
                </div>
                <div className="col-span-8 flex justify-center">
                <p className="text-gray-500 flex ststusText">
                  Status :{" "}
                  <label className="text-blue-700 flex Verified mx-2">
                    Verified <TickIcon className="mx-2" />
                  </label>
                </p>
                </div>

              </div>
              <div className="grid grid-cols-12 gap-2 px-2 mt-2 detailsContainer">
                <div className="col-span-4 flex justify-center selectedText">
                {iconsList[3].selectedText}
                </div>
                <div className="col-span-8 flex justify-center selectedText">
                5. After successful verification of the device, "Status Verified" will be displayed
                </div>
                
              </div>

              

              <hr className="footerHr"></hr>
              <p className="footerNote">
                Note: Mandatory to verify device from testing device only.
              </p>
            </div>
          </div>


        </div>
      </Modal>
    </>
  );
}
export default WarningLinkDeviceModal;
