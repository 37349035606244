import { display, width } from "@mui/system";
import React from "react";
export default function FileUploadButton({
  showBtn,
  setShowBtn,
  value,
  onChange,
  deleteFile,
  id,
  disabled,
  ...rest
}) {
  const handleClick = (e) => {
    document.getElementById(id).value = null;
    setShowBtn(false);
    deleteFile();
    
  };
  return (
    <div className="flex relative">
      <span
        style={{ display: "inline-block", marginTop: "10px" }}
        className="big-size-file"
      >
        <button
          style={{
            padding: "1px 12px",
            border: " 1px solid #989797",
            borderRadius: "4px",
            color: "#727171",
            cursor: "pointer !important",
            fontSize: "10px",
            fontFamily: "OpenSans-Regular",
          }}
          
        >
          {value && showBtn ? "Replace file" : "Choose file"}
        </button>

        {value && showBtn ? (
          <>
            <span
              title={
                rest?.testDataFilesLength > 0 && rest?.called === "test-Data"
                  ? null
                  : value
              }
              style={{
                maxWidth: "100px",
                position: "relative",
                fontSize: "10px",
                fontFamily: "OpenSans-Regular",
                // color: "#1648C6",
                marginLeft: "10px",
              }}
              className={disabled ? "text-gray-700":"text-blue-700"}
            >
              {value && value.length > 10
                ? value.substring(0, 8) + "..."
                : value}
            </span>
          </>
        ) : rest?.testDataFilesLength > 0 &&
          rest?.called === "test-Data" ? null : (
          <span
            className="cursor-default"
            style={{
              fontSize: "10px",
              color: "#727171",
              fontFamily: "OpenSans-Regular",
              marginLeft: "10px",
            }}
          >
            No file chosen
          </span>
        )}
        <input
          type="file"
          id={id}
          disabled={disabled}
          onChange={(e) => {
            onChange(e);
            
          }}
          {...rest}
          className="fileStyle"
          title=""
          style={{
            position: "absolute",
            zIndex: 2,
            opacity: 0,
            height: "100%",
            width: "80px",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      </span>
      <div style={{ position: "absolute", left: "134px", top: "10px" }}>
        {value && showBtn ? (
          <button
            type="button"
            onClick={handleClick}
            className="float-right  rounded-md text-blue-500 hover:text-gray-500 focus:outline-none "
            style={{ marginTop: "11px", position: "relative" }}
            disabled={disabled}
          >
            <svg
              style={{ marginLeft: "23px", color: "#727171", marginTop: "-2px" }}
              xmlns="http://www.w3.org/2000/svg"
              className="h-3 w-3 z-100"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        ) : null}
      </div>
    </div>
  );
}
