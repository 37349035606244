
import React, { useEffect, useState } from "react";
import { ReactComponent as Search } from "../../../assets/search.svg";
import "./reports.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ModuleTable from "./module-table";
import {DownloadDefectExcleFile} from "../../../api/api-services";
import NoDefectIcon from "../../../assets/reportsicon/no_reports_icon.png";

let webSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
};
let mobileSearchFilters = {
  os: null,
  osVersion: null,
  browser: null,
  browserVersion: null,
  deviceBrand: null,
  deviceName: null,
  deviceType: null,
};

function ExpandDefectSegment(props){
  console.log("ExpandDefectSegment",props)
  let [LabelData,setlabelData]=useState();
 let [header,setHeader]=useState();
 let [moduleSelectedLabel,setModuleSelectedLabel]=useState([]);
 let [locationSelectedLabel,setlocationSelectedLabel]=useState([]);
 let fireFlinkProjectId = JSON.parse(localStorage["selected-project"])?.project?.fireFlinkProjectId;
 let licenseID = JSON.parse(localStorage["selected-project"])?.licenceID;
 const [filterData, setFilterData] = useState([]);
//  let [downlaoding,setDownlaoding]=useState(false)
let downloading=false;
let [selectlabel,setSelectLabel]=useState()





  useEffect(()=>{
    setlabelData(props?.data)
    setHeader(props?.label)
    setFilterData(props?.data)
    setSelectLabel(props?.selectlabel)
  },[props])

  function handleSearch(e) {
    console.log("filterdata",filterData)
    let searchValue = e.target.value;
    console.log("searchValue",searchValue.trim().length)
    if (searchValue) {
      let tempSimilarArray = filterData.filter((proVal, index) => {
        let label= proVal;
        
        
  
        let similarExist = label.match(
          new RegExp(searchValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
        );
        
  
        return (
          similarExist 
         
        );
      });
      setlabelData(tempSimilarArray);
    } else {
      setlabelData(filterData);
    }
  }

  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
    // setDownlaoding(false)
    downloading=false
  }
  const downloadExcleFile = async (state, payload) => {
    // setDownlaoding(true)
    downloading=true
    let filename =
      state === "CLOSED" ? "Approved_Defects.xlsx" : "Rejected_Defects.xlsx";
    try {
      let response = await DownloadDefectExcleFile(
        fireFlinkProjectId,
        licenseID,
        state,
        payload
      );
      console.log("response file", response);
      if (response.status === 200) {
        saveFileFromBlob(response.data, filename);
      }else{
        // setDownlaoding(false)
        downloading=false
      }
    } catch (err) {
      console.log("error");
    }
  };

  function getDownload(label){
    let state=props?.displayapproverdefect;
    switch (label) {
      case "module": {
        let modulepayload=selectlabel;
        // let modulepayload=moduleSelectedLabel.length!==0?moduleSelectedLabel:props?.chartlabels?.label;
        let segmentSearchFilters = {
          severity: null,
          priority: null,
          modules:  modulepayload,
          locations: null,
          createdOn: null,
        };
        let filterdata = {
          segmentSearchFilters: segmentSearchFilters,
          webSearchFilters: webSearchFilters,
          mobileSearchFilters: mobileSearchFilters,
        };
        downloadExcleFile(state, filterdata);
        break;
      }

      case "location":
        {
          let locationpayload=selectlabel;
          // let locationpayload=locationSelectedLabel.length!==0?locationSelectedLabel:props?.chartlabels?.label;
          let segmentSearchFilters = {
            severity: null,
            priority: null,
            modules: null,
            locations: locationpayload,
            createdOn: null,
          };
          let filterdata = {
            segmentSearchFilters: segmentSearchFilters,
            webSearchFilters: webSearchFilters,
            mobileSearchFilters: mobileSearchFilters,
          };
          downloadExcleFile(state, filterdata);
          break;
        }
      
      default:
        break;
    }

  }


  function getdownloadFilePayload(val,state,label){
    console.log("getdownloadFilePayload",val,state,label)
    // switch(val){
    //   case "module":{
    //     let templabel=label.split(/[-]/)[0];
        
    //     if(state==="add"){
    //       moduleSelectedLabel.push(templabel)
    //     }else{
    //       let index=moduleSelectedLabel.indexOf(templabel);
    //       moduleSelectedLabel.splice(index,1)
    //     }
    //     break;
    //   }
    //   case "location":{
    //     let templabel=label.split(/[-]/)[0]
    //     if(state==="add"){
    //       locationSelectedLabel.push(templabel)
    //     }else{
    //       let index=locationSelectedLabel.indexOf(templabel);
    //       locationSelectedLabel.splice(index,1)
    //     }
    //     break;
    //   }
    //   default :
    //     break
    // }
    // console.log(" moduleSelectedLabel", moduleSelectedLabel)
    // console.log(" locationSelectedLabel", locationSelectedLabel)

  }
 
    return(
        <div className="module_segment_container">
            <div className="grid grid-cols-12 table_header">
            <div className="col-span-4 header_text ml-2">
              {props?.label==="module"?"Modules":"Locations"} - {props?.data?.length}
            </div>
            <div className="col-span-8  grid grid-cols-12 header_btn">
                <div className="col-span-10 flex justify-end">
                <div className="expand_Defects_Search">
            <div>
              <input
                type="text"
                className="searchfield"
                autoFocus={false}
                onChange={(e) => {
                handleSearch(e)
                }}
                placeholder="Search"
              />
            </div>
            <div className="search_icon">
              <Search />
            </div>
          </div> 
                </div>
                <div className={`col-span-2 download_btn flex justify-end ${downloading?"opacity-50":"opacity-100"}`}>
                <button className="flex" onClick={()=>{if(downloading===false){getDownload(header)}}}>
                <div className="mt-1.5 ml-2">
                Download
                </div>
                <div className="ml-1.5">
                    <FileDownloadOutlinedIcon/>
                </div>
               
               

            </button>
                </div>
            </div>

            </div>
           
             {
              LabelData?.length >0?(
                <div style={{maxHeight:"230px",overflowY:"scroll"}}>
                {
                 LabelData?.map((item,index)=>{
                    return(
                      <div>
                  <ModuleTable label={header} data={item} downloadFilePayload={getdownloadFilePayload}
                  state={props?.displayapproverdefect}
                  />
                </div>
                    )
                  
                   
    
                  })
                }
                </div>
              ):(
                <div style={{maxHeight:"230px",overflowY:"scroll"}}>
                  <div className="expand_segment_noreportIcon">
                    <img  alt="warning" src={NoDefectIcon}/>
                    <div className="text_msg">No Relevant Data Is Found!</div>
                  </div>
                  </div>
              )
            }
            
           
        </div>
    )
}
export default  ExpandDefectSegment;
