import React, { useState, useEffect, useContext, Fragment } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextareaAutosize, TextField, Select } from "@mui/material";
import { Popover, Menu, Transition } from "@headlessui/react";
import IconButton from "@material-ui/core/IconButton";
import Modal from "react-modal";
import Tooltip from "@material-ui/core/Tooltip";

import { ReactComponent as RejectedIcon } from "../../../assets/rejectedBorderedIcon.svg";
import { ReactComponent as ApprovedIcon } from "../../../assets/approvedBorderedIcon.svg";
import { ReactComponent as ApprovedShortBtn } from "../../../assets/approvedShortBtn.svg";
import { ReactComponent as RejectedShortBtn } from "../../../assets/rejectedShortBtn.svg";
import { ReactComponent as ApprovalPendingIcon } from "../../../assets/approvalPending.svg";
import { ReactComponent as NoExecutionIcon } from "../../../assets/noExecutionIcon.svg";
import { ReactComponent as FilterIcon } from "../../../assets/filter.svg";

import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Grid from "@mui/material/Grid";
import FormControl from "@material-ui/core/FormControl";
import RichTextEditor from "../../config/rich-texteditor";
import "../../config/testCase/executiondetails.scss"
import { getLabel, getOptionLabel } from "../../config/common-functions";
import ApproveExecutionModal from "../../config/testCase/approveExecutionModal";
import RejectExecutionDefectModal from "../../config/testCase/rejectExecutionDefectModal";
 import { ProgressContainerContext } from "../../common/inputs/ProgressContainer";
import {
  getExecutionListByEmail,
  getExecutionListByStatus,
  getExecutionListCountByEmail,
  getExecutionListCountByStatus,
  updateTestcaseState,
} from "../../../api/api-services";
import { SelectDropdown } from "../../project/modals/select-dropdown";
import { ImportExport } from "@material-ui/icons";
// import NewFilters from "@src/pages/reports/new_reports/newFilter";
import NewFilters from "../../reports/new_reports/newFilter";
import ExecutionlistFilter from "../../config/testCase/executionlistFilter";

const ExecutionListModule = (props) => {
  const [statusWarnOpen, setStatusWarnOpen] = useState(false);
  const [defectReasonModalOpen, setDefectReasonModalOpen] = useState(false);
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  let [executionsList, setExecutionsList] = useState([]);
  const [dataForApprove, setDataForApprove] = useState({});
  const [dataForApproveFiltered, setDataForApproveFiltered] = useState([]);
  const [pageno, setpageNo] = useState(0);
  const [lastPage, setLastpage] = useState(false);
  const [openModal, setOpenModal] = useState(true);

  
 
  let projectId = JSON.parse(localStorage.getItem("selected-project")).project
    .fireFlinkProjectId;
  let TemplateData;
  if (props?.testCaseTemplate?.manualTestCase) {
    TemplateData = JSON.parse(
      JSON.stringify(props?.testCaseTemplate?.manualTestCase)
    );
  }
  let licenseId = JSON.parse(
    localStorage.getItem("selected-project")
  ).licenceID;
  let emailId = JSON.parse(localStorage["selected-user"]).email;
  let myRole = JSON.parse(localStorage.getItem("selected-user")).role;
  let leadEmailId = JSON.parse(localStorage["selected-user"]).lead;
  let [dataloading, setdataloading] = useState(true);
  let [callingAPI, setCalling] = useState();
  let projectType=JSON.parse(localStorage["selected-project"]).project?.projectType?.mobile!==null?"Mobile"
  :JSON.parse(localStorage["selected-project"]).project?.projectType?.web!==null?"Web"
  :JSON.parse(localStorage["selected-project"]).project?.projectType?.webAndMobile!==null?"Web And Mobile"
  :"Others";
  let projectstate = JSON.parse(
    localStorage.getItem("selected-project")
  ).projectStatus;

  let [filters,setfilters]=useState(false);
  let [executionCount,setExecutionCount]=useState(0)

  const customStylesPage = {
    overlay: {
        background: "rgba(90, 90, 90,0.5)",
    },
    content: {
        position: "fixed",
        top: "50%",
        left: "63%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        height: "100%",
        background: "#fbfcfd 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 11px #000000a1",
        opacity: "1",
        outline: "none",
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
    },
};

  const testCaseDetailDescElements = (data) => {
    switch (data.type) {
      case "textField":
        return (
          <div className="rs-user-input-container">
            <TextField
              onClick={() => {
                console.log(data?.value);
              }}
              disabled={true}
              InputProps={{ disableUnderline: true }}
              className="lg:w-72"
              title={data.placeholder.length > 30 ? data.placeholder : null}
              placeholder={
                data.placeholder.length > 30
                  ? data.placeholder.substr(0, 30) + "..."
                  : data.placeholder
              }
              value={data.value}
            />
          </div>
        );

      case "textArea":
        return (
          <>
            <div className="">
              <TextareaAutosize
                disabled={true}
                InputProps={{ disableUnderline: true }}
                title={data.placeholder.length > 30 ? data.placeholder : null}
                id="description"
                name="description"
                maxLength="200"
                maxRows={0}
                className=" bg-transparent block lg:w-72 border-0 border-b-2 rs-input-style-textarea popup-input-bg  -mt-1 pl-px  input-field-color descriptionStyle textarea-resize"
                placeholder={
                  data.placeholder.length > 30
                    ? data.placeholder.substr(0, 30) + "..."
                    : data.placeholder
                }
                value={data.defaultValue}
              />
              {data && data.minLength >= 0 && data.maxLength >= 0 ? (
                <div className="mt-1 text-sm text-gray-500 text-right mr-2">
                  {data.minLength}/{data.maxLength}
                </div>
              ) : data.minLength >= 0 && data.maxLength >= 0 ? (
                <div className="mt-1 text-sm text-gray-500 text-right mr-14">
                  {data.minLength}/{data.maxLength}
                </div>
              ) : null}
            </div>
          </>
        );

      case "radioButton":
        return (
          <>
            <div className="mt-3">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div>
                    <input
                      type="radio"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="details-data-style-common">
                      {getOptionLabel(option)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case "link":
        return (
          <>
            <div className=" flex mt-3">
              <div>
                <InsertLinkOutlinedIcon className="-mt-2 mr-2 origin-center rotate-90 opacity-60" />
              </div>
              <div
                aria-disabled={true}
                className="details-data-style-common opacity-50 w-72"
              >
                {getLabel(
                  "Attached Link will be shown here if any URL is added"
                )}
              </div>
            </div>
          </>
        );

      case "dropdown":
        return (
          <>
            <FormControl variant="standard">
              <Select
                variant="standard"
                disabled={data?.label === "Status" ? false : true}
                displayEmpty
                disableUnderline
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                }}
                renderValue={(selected) => {
                  if (!selected) return data?.placeholder;
                  else return selected;
                }}
                name="selectCustomField"
                id="selectCustomField"
                className="lg:w-72 lg:h-8 sm:w-40 fontPoppinsRegularMd"
              >
                {data.options.map((option, index) => (
                  <option
                    className="hover:text-blue-700 cursor-pointer hover:bg-blue-100 details-data-style-common lg:w-72 sm:w-40 fontPoppinsRegularMd"
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        );

      case "date":
        return (
          <>
            <div className="flex">
              <div className=" h-8 border-2 border-gray-300 w-72"></div>
              <div className=" bg-gray-200">
                <CalendarTodayOutlinedIcon className="mt-1" />
              </div>
            </div>
            {TemplateData?.templateType === "Predefined" ? (
              <div className="details-data-style-common opacity-50">
                [dd-mm-yyyy]
              </div>
            ) : (
              <div className="details-data-style-common opacity-50">
                [{data.dateFormat}]
              </div>
            )}
          </>
        );
      case "checkbox":
        return (
          <>
            <div className="lg:w-72">
              <div className="grid grid-cols-2 gap-1">
                {data.options.map((option, index) => (
                  <div>
                    <input
                      type="checkbox"
                      className="mr-2"
                      name={`option${index}`}
                      id={`option${index}`}
                      value={option}
                      disabled={true}
                    />
                    <label className="details-data-style-common">
                      {getOptionLabel(option)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </>
        );

      case "attachment":
        return (
          <div className="rs-user-input-container">
            <TextField
              InputProps={{ disableUnderline: true }}
              placeholder="Search and select file name"
              disabled={true}
              className="lg:w-72"
            />
          </div>
        );
      case "textEditor":
        return (
          <>
            <RichTextEditor
              convertedContent=""
              readOnly={true}
              toolbarHidden={false}
              className="w-full"
            />
          </>
        );

      default:
        return (
          <>
            <TextField disabled={true} className="w-80" />
          </>
        );
    }
  };

  const testCaseDetailElements = (props) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="labelInputs">
            <h6 className=" custom_labels">{getLabel(props.data.label)}</h6>
          </div>
          <div className="flex">
            <div className="contentDataStyle_custom_fields">
              {testCaseDetailDescElements(props.data)}
            </div>
            <div
              className={
                props.data.type !== "textEditor"
                  ? " flex lg:mr-10"
                  : " flex mt-5"
              }
            >
              {!["Test Case Name", "Requirement Id", "Status"].includes(
                props.data.label
              ) ? (
                <></>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  const testCaseDetailSection = (obj) => {
    console.log("testCaseDetailSection", obj);
    let tableRowData = obj.manualTestCase.testSteps.rows;
    let listData = obj.manualTestCase;

    return (
      <>
        <div className=" mt-3 mx-3 my-5 ">
          <div className=" responsiveTestDetails">
            <Accordion defaultExpanded={false} className="">
              <div className="flex flex-row">
              <div className="flex mt-3 w-full">
                    <div style={{ width: "100%" }} className="flex grid grid-cols-12">
                     

                      <div className="col-span-2">
                        <div className="testcase_lables">OS</div>
                        <div className="testcsae_values mt-1 datatrimmer w-28" title={obj?.os}>{obj?.os}</div>
                      </div>

                      <div className="col-span-2">
                        <div className="testcase_lables">OS Version</div>
                        <div className="testcsae_values mt-1 datatrimmer w-28" title={obj?.osVersion}>
                          {obj?.osVersion}
                        </div>
                      </div>

                      {obj?.deviceName !== undefined &&
                      obj.deviceName !== "" ? (
                        <>
                          <div className="col-span-2">
                            <div className="testcase_lables">Device Brand</div>
                            <div className="testcsae_values mt-1 datatrimmer w-28" title={obj?.deviceBrand}>
                              {obj?.deviceBrand}
                            </div>
                          </div>

                          <div className="col-span-2">
                            <div className="testcase_lables">Device Name</div>
                            <div className="testcsae_values mt-1 datatrimmer w-28" title={obj?.deviceName}>
                              {obj?.deviceName}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-span-2">
                            <div className="testcase_lables">Browser</div>
                            <div className="testcsae_values mt-1 datatrimmer w-28" title={obj?.browser}>
                              {projectType==="Others"?"--":obj?.browser}
                            </div>
                          </div>

                          <div className="col-span-2">
                            <div className="testcase_lables">
                              Browser Version
                            </div>
                            <div className="testcsae_values mt-1 datatrimmer w-28" title={obj?.browserVersion}>
                              {projectType==="Others"?"--":obj?.browserVersion}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="col-span-2">
                        <div className="testcase_lables">Location</div>
                        <div className="testcsae_values mt-1 datatrimmer w-28" title={obj?.city}>{obj?.city}</div>
                      </div>
                      {/* <div className="col-span-2">
                        <div className="testcase_lables">Created On</div>
                        <div className="testcsae_values mt-1 datatrimmer w-28" title={obj?.createdOn}>
                          {obj?.createdOn}
                        </div>
                      </div> */}
                      <div className="col-span-2">
                        <div className="testcase_lables">Status</div>
                        <div
                          className={`testcsae_values mt-1 ${
                            obj?.executionStatus.toUpperCase() === "PASS"
                              ? "text-green-700"
                              : obj?.executionStatus.toUpperCase() === "FAIL"
                              ? "text-red-700"
                              : "text-orange-500"
                          }`}
                        >
                          {obj?.executionStatus}
                        </div>
                      </div>
                    </div>
                  </div>
               
                <div className="h-auto backgroundColor">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="cursor-pointer" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  ></AccordionSummary>
                </div>
              </div>
              <div className="accordionDetails">
                <AccordionDetails className=" h-auto backgroundColor">
               
                   {/* <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  className=""
                >
                  {listData?.testCaseDetails?.slice(0, 7).map((data) =>
                    data?.type === "textEditor" ? (
                      <Grid item xs={12} sm={12} md={12}>
                        {testCaseDetailElements({ data })}
                      </Grid>
                    ) : (
                      <>
                      {
                        data?.label!=="Module/Path" && (
                          <Grid item xs={2} sm={4} md={12 / 6}>
                          <div>
                            <div className="testcase_lables">{data.label}</div>
                            <div
                              className="testcsae_values mt-1 datatrimmer w-24"
                              title={
                                Array.isArray(data.value)
                                  ? data.value[0]
                                  : data.value
                              }
                            >
                              {data.label === "Test Case Name"
                                ? obj.name
                                : Array.isArray(data.value)
                                ? data.value[0]
                                : data.value || "--"}
                            </div>
                          </div>
                        </Grid>
                        )
                      }
                      </>
                     
                    )
                  )}
                  <Grid item>
                    <div>
                      <div className="testcase_lables">Status</div>
                      <div
                        className={`testcsae_values mt-1 ${
                          obj?.executionStatus.toUpperCase() === "PASS"
                            ? "text-green-700"
                            : obj?.executionStatus.toUpperCase() === "FAIL"
                            ? "text-red-700"
                            : "text-orange-500"
                        }`}
                      >
                        {obj?.executionStatus}
                      </div>
                    </div>
                  </Grid>
                </Grid> */}
                  <div className="grid grid-cols-12">
                  {listData?.testCaseDetails?.slice(0, 7).map((data) =>
                      data?.type === "textEditor" ? (
                        <div className="col-span-11">
                          {testCaseDetailElements({ data })}
                        </div>
                      ) : (
                        <>
                        {
                          data.label!=="Module/Path" && (
                            <div className="col-span-2">
                            {/* {testCaseDetailElements({ data })} */}
                            <div>
                              <div className="testcase_lables">{data.label}</div>
                              <div
                                className="testcsae_values mt-1 datatrimmer w-24"
                                title={
                                  Array.isArray(data.value)
                                    ? data.value[0]
                                    : data.value
                                }
                              >
                            
                                {data.label === "Test Case Name"
                                  ? obj.name
                                  : Array.isArray(data.value)
                                  ? data.value[0] !== undefined &&
                                    data.value[0] !== ""
                                    ? data.value[0]
                                    : "--"
                                  : data.value !== undefined && data.value !== ""
                                  ? data.value
                                  : "--"}
                              </div>
                            </div>
                          </div>

                          )
                        }
                        </>
                        
                       
                      )
                    )}
                     {/* <div className="col-span-2">
                        <div className="testcase_lables">Status</div>
                        <div
                          className={`testcsae_values mt-1 ${
                            obj?.executionStatus.toUpperCase() === "PASS"
                              ? "text-green-700"
                              : obj?.executionStatus.toUpperCase() === "FAIL"
                              ? "text-red-700"
                              : "text-orange-500"
                          }`}
                        >
                          {obj?.executionStatus}
                        </div>
                      </div> */}
                      <div className="col-span-2">
                        <div className="testcase_lables">Created On</div>
                        <div className="testcsae_values mt-1 datatrimmer w-28" title={obj?.createdOn}>
                          {obj?.createdOn}
                        </div>
                      </div>

                  </div>
                  <div
                    className="mt-8"
                    style={{
                      width: "100%",
                      overflow: "auto",
                      maxHeight: "267px",
                      height: "auto",
                      overflowY: "auto",
                    }}
                  >
                    <table className="testStepTable  w-full">
                      <thead
                        style={{
                          display: " table-header-group",
                          verticalAlign: "middle",
                          position: "sticky",
                          top: "-1px",
                          zIndex: "1",
                        }}
                        className="bg-gray-100"
                      >
                        <tr key={"tableHeader"}>
                          {listData.testSteps?.headers?.map((data, index) => {
                            return (
                              <>
                                <th
                                  className="p-2 testStepTable common-label-text text-blue-800 fontPoppinsMediumMd"
                                  key={data + index}
                                >
                                  {data}{" "}
                                </th>
                              </>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          height: "auto",
                          overflowY: "auto",
                          display: " table-row-group",
                          verticalAlign: "middle",
                        }}
                      >
                        {tableRowData?.map((rowData, index) => (
                          <tr
                            style={{
                              display: " table-row",
                              verticalAlign: "inherit",
                            }}
                            className={`h-6 testStepTable ${
                              (index + 1) % 2 === 0
                                ? "test-case-table-even-row"
                                : "test-case-table-odd-row"
                            }`}
                            key={index}
                          >
                            {TemplateData.testSteps?.headers?.map(
                              (cellname, colIndex) => {
                                return (
                                  <>
                                    <td
                                      className={`testStepTable  fontPoppinsRegularSm text-left pl-2 table-cell-text focus:border-opacity-0 focus:outline-none ${
                                        cellname === "Status"
                                          ? rowData[cellname].toUpperCase() ===
                                            "PASS"
                                            ? "text-green-700"
                                            : rowData[
                                                cellname
                                              ].toUpperCase() === "FAIL"
                                            ? "text-red-700"
                                            : "text-orange-500"
                                          : ""
                                      }`}
                                      key={index + colIndex}
                                      contentEditable={false}
                                      suppressContentEditableWarning={true}
                                    >
                                      {Array.isArray(rowData[cellname])
                                        ? rowData[cellname].map((data) => (
                                            <>
                                              {data}

                                              <br />
                                            </>
                                          ))
                                        : rowData[cellname]}
                                    </td>
                                  </>
                                );
                              }
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </AccordionDetails>
              </div>
            </Accordion>
          </div>
        </div>
      </>
    );
  };
  const handlescroll = (event) => {
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
      if (!lastPage && !callingAPI) {
        getExecutionList(pageno);
      }
    }
  };
  function getExecutionListCount(Scriptid) {
    if (myRole !== "PROJECT_OWNER") {
      getExecutionListCountByEmail(Scriptid, emailId, projectId, licenseId)
        .then((result) => {
          console.log("getExecutionListCountByEmail", result);
          if (result.status === 200 && result?.data?.responseCode === 200) {
            setExecutionCount(result?.data?.responseObject)
          }
        })
        .catch((error) => {
          console.log("error");
        });
    } else {
      getExecutionListCountByStatus(Scriptid, "CLOSED", projectId, licenseId)
        .then((result) => {
          console.log("getExecutionListCountByStatus", result);
          if (result.status === 200 && result?.data?.responseCode === 200) {
            setExecutionCount(result?.data?.responseObject)
          }
        })
        .catch((error) => {
          console.log("error");
        });
    }
  }

  const getExecutionList = async (page) => {
    setCalling(true);
    let scriptId = props.testCaseTemplate.id;
    if (page === 0) {
      resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 80,
      });
    }

    try {
      let response =
        myRole === "PROJECT_OWNER"
          ? await getExecutionListByStatus(
              projectId,
              licenseId,
              scriptId,
              "CLOSED",
              page,
              10
            )
          : await getExecutionListByEmail(
              projectId,
              licenseId,
              emailId,
              scriptId,
              page,
              10
            );
      console.log(response, "resp onse");
      // setDataForApproveFiltered(response?.data?.responseObject);

      if (response?.data?.responseCode === 200) {
        let tempList = [];
        let data = response?.data?.responseObject;
        console.log("setDataForApproveFiltered", data);

        // setDataForApproveFiltered((prevData) => {
        //   // Combine the previous data with the new response data
        //   if (prevData && Array.isArray(prevData)) {
        //     console.log("setDataForApproveFiltered prev data", prevData);
        //     return [...prevData, ...data];
        //   }
        //   return data;
        // });
        if(page===0){
          setDataForApproveFiltered(data)
        }else{
          let tempdata=dataForApproveFiltered.concat(data)
          setDataForApproveFiltered(tempdata);
        }


        setLastpage(response?.data?.lastPage);
        data.map((obj) => {
          console.log("createdOn",obj)
          let tempData = JSON.parse(JSON.stringify(props.testCaseTemplate));
          tempData.executionId = obj.id;
          tempData.executionStatus = obj.executionStatus;
          tempData.manualTestCase.testSteps.rows.map((obj1, index1) => {
            obj1.Status =
              obj.results[index1]?.status !== undefined
                ? obj.results[index1].status
                : "";
            obj1["Actual Result"] = obj.results[index1]?.actualResult;
            tempData["platformName"] = obj.platformName;
            tempData["os"] = obj.os;
            tempData["osVersion"] = obj.osVersion;
            tempData["browser"] = obj.browser;
            tempData["browserVersion"] = obj.browserVersion;
            tempData["deviceBrand"] = obj.deviceBrand;
            tempData["deviceName"] = obj.deviceName;
            tempData["city"] = obj.location.city;
            tempData["executionStatus"] = obj.executionStatus;
            tempData["createdOn"] = obj?.createdOn;            
            return obj1;
          });
          tempList.push(tempData);
        });

        if (page === 0) {
          setExecutionsList(tempList);
          // props?.executionCountSetter(tempList.length);
          if (response?.data?.lastPage === false) {
            setpageNo(page + 1);
          }
           getExecutionListCount(scriptId);
        } else {
          let tempArray = executionsList.concat(tempList);
          setExecutionsList(tempArray)
          if (response?.data?.lastPage === false) {
            setpageNo(page + 1);
          }
        }
      }
      resetProgress();
    } catch (error) {
      resetProgress();
      console.error(error);
    } finally {
      // setLoading(false);
      setdataloading(false);
    }
    setCalling(false);
  };
  function CloseWarningStatus(state) {
    setStatusWarnOpen(state);
  }
  function CloseDefectReasonModal(state) {
    setDefectReasonModalOpen(state);
  }
  useEffect(() => {
    setdataloading(true);
    getExecutionList(0);
  }, []);
  const historyIcons = (state) => {
    return (
      <>
        {state === "REJECTED" ? (
          <RejectedIcon height="22px" width="95px" />
        ) : state === "APPROVED" ? (
          <ApprovedIcon height="22px" width="95px" />
        ) : state === "NOT_APPROVED" ? (
          <ApprovalPendingIcon height="22px" width="95px" />
        ) : (
          state
        )}
      </>
    );
  };
  const getStateFromHistory = (history) => {
    let tempState = "";
    let tempArray = history.slice(0).reverse();

    for (var i = 0; i < tempArray.length; i++) {
      if (myRole === "ACCOUNT_MANAGER" && emailId === tempArray[i].approver) {
        tempState = tempArray[i].status;
        break;
      } else if (leadEmailId === tempArray[i].approver) {
        tempState = tempArray[i].status;
        break;
      } else {
        tempState = "NOT_APPROVED";
        break;
      }
    }
    console.log("state", tempState);
    return tempState;
  };

  

  

  async function handleExectionStatus(state,result) {
    setdataloading(true)
    let scriptId = props?.testCaseTemplate?.id;
    let status =(myRole==="ACCOUNT_MANAGER" && state==="APPROVED")?"CLOSED": state;
    let executionStatus = result;
    let payload = [];
    try {
      let response = await updateTestcaseState(
        status,
        executionStatus,
        emailId,
        leadEmailId,
        scriptId,
        licenseId,
        projectId,
        payload
      );
      console.log("response", response);
      if (response?.status === 200) {
        setExecutionsList([]);
        setpageNo(0);
        setLastpage(false);
        setDataForApproveFiltered([]);

         getExecutionList(0);
        setfilters(false)
       
      }
    } catch (error) {
      setdataloading(false)
      console.log("error");
    }
     
  }

 

  

  return (
    <Modal    
    isOpen={openModal}
    style={customStylesPage}
    className="p-px"
    >
      <div className="executionListPopup">

      <div className="grid grid-cols-12 flex header rounded-tl-xl">
                    <div className="col-span-10 pl-2 pr-3 heading">TestCase Execution-{executionCount}</div>
                   
                    <Tooltip title="Filter">
                    <div className={`col-span-1 flex justify-end filter_icon ${projectstate!=="COMPLETED"?"opacity-100" :"opacity-50"}`} onClick={()=>{projectstate!=="COMPLETED" && setfilters(!filters)}}>
                      <FilterIcon/>
                    </div>
                    </Tooltip>
                    
                    <div className="col-span-1 flex justify-end mr-1.5 close_btn">
                       

<button
                            onClick={() => {
                              props.CloseModal(false);
                            }}
                            type="button"
                            className="modal_clos_btn mr-1"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                </div>

       < div className="body">
  
      {executionsList.length > 0 ? (
        <div className="paginationContainer" onScroll={handlescroll}>
          {executionsList.map((data, index) => (
            <div className=" overflow-x-hidden">
              <div className=" wrapperForExecution abc">
                <div className="customAccordian">
                  <div className="flex justify-between">
                    <div
                      className="exe_header"
                      onClick={() => {
                        console.log(data, dataForApproveFiltered);
                      }}
                    >
                      {" "}
                      Execution Id - {data.executionId}
                    </div>
                    <div className="cursor-pointer iconGroups  justify-end">
                      {dataForApproveFiltered?.map((v) => {
                        if (v.id === data.executionId) {
                          return (
                            <>
                              <div className="flex">
                                {myRole === "PROJECT_OWNER" ? (
                                  <></>
                                ) : v.status === "REJECTED" ? (
                                  <RejectedIcon height="22px" width="95px" />
                                ) : v.status === "CLOSED" ? (
                                  <ApprovedIcon height="22px" width="95px" />
                                ) : v.assignedTo === emailId ? (
                                  <>
                                    <div
                                      className={`mx-1 ${projectstate!=="COMPLETED"?"opacity-100":"opacity-50"}`}
                                      onClick={() => {
                                        if(projectstate!=="COMPLETED"){
                                          setStatusWarnOpen(true);
                                          setDataForApprove(
                                            dataForApproveFiltered?.find(
                                              (v) => v.id === data.executionId
                                            )
                                          );
                                        }
                                       
                                      }}
                                    >
                                      <ApprovedShortBtn />{" "}
                                    </div>
                                    <div
                                      className={`mx-1 ${projectstate!=="COMPLETED"?"opacity-100":"opacity-50"}`}
                                      onClick={() => {
                                        if(projectstate!=="COMPLETED"){
                                          setDefectReasonModalOpen(true);
                                          setDataForApprove(
                                            dataForApproveFiltered?.find(
                                              (v) => v.id === data.executionId
                                            )
                                          );
                                        }
                                       
                                      }}
                                    >
                                      <RejectedShortBtn />{" "}
                                    </div>
                                  </>
                                ) : (
                                  historyIcons(getStateFromHistory(v.history))
                                )}
                              </div>
                            </>
                          );
                        }
                      })}
                    </div>
                  </div>
                  {testCaseDetailSection(data)}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={`noDefects ${dataloading ? "hidden" : "block"}`}>
          <div>
            <NoExecutionIcon />
          </div>
          <div className="mt-3">
            <span className="noExeutionsMsg">No Executions found !</span>
          </div>
        </div>
      )}

      {statusWarnOpen && (
        <ApproveExecutionModal
          MyAlert={props.MyAlert}
          dataForApprove={dataForApprove}
          CloseWarningStatus={CloseWarningStatus}
        />
      )}
      {defectReasonModalOpen && (
        <RejectExecutionDefectModal
          MyAlert={props.MyAlert}
          dataForApprove={dataForApprove}
          CloseDefectReasonModal={CloseDefectReasonModal}
        />
      )}
      {
        filters && (
          <ExecutionlistFilter handleExectionStatus={handleExectionStatus} label={"ExecutionListPopUp"}/>
        )
      }
    </div>


      </div>
      
    </Modal>
   
  );
};

export default ExecutionListModule;
