import { useState, useEffect } from "react";
import "./project.scss";

function ProjectCheckboxItem(props) {
  console.log("ProjectCheckboxItem",props)
  return (
    <div className="flex gap-1 my-2">
      <div className="mr-1">
        <input
          type="checkbox"
          // checked={props.defaultCheck}
          defaultChecked={props?.defaultCheck}
          name={props.data.value}
          value={props.data.value}
          disabled={!props.loading ||props?.projectstatus==="CLOSED"}
          onChange={(e) => {
            props.setTestingTypeValue(e,e.target);
          }}
        />
      </div>
      <div className="create-project-values">{props.data.key}</div>
    </div>
  );
}

export default ProjectCheckboxItem;
