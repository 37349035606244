import React, { useState, useEffect } from "react";
import { useTable, useGlobalFilter } from "react-table";
import Close from "@mui/icons-material/Close";
// import Search from "@mui/icons-material/Search";
import BugReportIcon from "@mui/icons-material/BugReport";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./report-details.scss";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  getAllTestcaseReq,
  getUserDetailsByEmailID,
  getAllTesterReq,
  getAllModuleReq,
  getTestcaseDetailsById,
  getAllTestCaseTemplate,
  getTotalTestcaseCount,
  getAllUsersListhierarchyInReports,
  getAllDefectTemplate,
  DownloadDefectExcleFile,
  getDefectsDataCount,
} from "../../api/api-services";
import DefectFilterModal from "./defect-filter-modal";
import TestCaseFilterModal from "./testcase-filter-modal";
import DefectsDeatilsPopup from "../modal/defects-deatils-popup";
import TCReportDetails from "../modal/tcreport-details-popup";
import UserDeatilsPopup from "../modal/user-deatils";
import TCUserDeatilsPopup from "../modal/tcuser-details";
import NoDefectIcon from "../../assets/reportsicon/no_reports_icon.png";
import IconButton from "@mui/material/IconButton";
import TCResultDeatilsPopup from "../modal/tcuser-details";
import SearchField from "../common/search-field";
import { ReactComponent as Search } from "../../assets/search.svg";
import { CircularLoader } from "../common/CircularLoader";

let filterBasePayLoad = {
  modules: [],
  location: [],
  users: [],
  executionResult: [],
};
let payLoad = JSON.parse(JSON.stringify(filterBasePayLoad));

let filterBaseStatusPayLoad = {
  modules: [],
  location: [],
  users: [],
  executionResult: [],
  testCaseId: [],
};
let StatuspayLoad = JSON.parse(JSON.stringify(filterBaseStatusPayLoad));

let DefectPayload = {};

function TestCaseLogTabel(props) {
  let [defects, setdefects] = useState([]);
  let [showFilter, setShowFilter] = useState(false);
  let [showSearch, setShowSearch] = useState(false);

  const [modules, setModules] = useState([]);
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [executionResults, setExecutionResults] = useState([
    "PASS",
    "FAIL",
    "FLAKY",
    "NOT EXECUTED",
  ]);

  let loggedinUser = JSON.parse(localStorage["user-privilege"]);

  let [tcreportPopUp, setTCReportPopUp] = useState(false);

  let [tcreportdetails, setTCReportdetails] = useState();
  let [userDetails, setUserDetails] = useState();
  let [showcloseIcon, setShowCloseicon] = useState(false);

  let [tcResultPopup, setTCResultPopup] = useState(false);
  let [selectedFilters, setSelectedFilters] = useState({});
  let projectId = JSON.parse(localStorage["selected-project"]).project
    .fireFlinkProjectId;
  let licenseID = JSON.parse(localStorage["selected-project"]).licenceID;
  const crowdProjectId = JSON.parse(
    localStorage.getItem("selected-project")
  ).id;
  let [testcaseData, setTestCaseData] = useState([]);
  let [testcaseTemplateData, setTestCaseTemplateData] = useState([]);
  let [pageNo, setPageNo] = useState(0);

  let [lastpage, setlastpage] = useState();

  let [appliedFilter, setAppliedFilter] = useState(false);
  let [testScriptStatus, setTestScriptStatus] = useState("");

  if (loggedinUser?.role?.includes("_")) {
    loggedinUser.role = loggedinUser.role.replace("_", "-");
  }
  let pageSize = 15;
  let [Tclabel, setTclabel] = useState();
  let [testcaseDetails, setTestaCaseDetails] = useState();
  let [closeSearchField, setCloseSearchField] = useState(false);
  const [templateTableData, setTemplateTableData] = useState({});
  const [testCaseDeatilsData, setTestCaseDeatilsData] = useState({});
  const [TotaltestcaseCount, setToatlTestcaseCount] = useState(0);
  let [testcaseDataLoading,setTestcaseDataLoading]=useState()

  useEffect(() => {
    if(JSON.parse(localStorage["selected-project"]).projectStatus!=="INREVIEW" && JSON.parse(localStorage["selected-project"]).projectStatus!=="REJECTED"){
      payLoad = JSON.parse(JSON.stringify(filterBasePayLoad));
    getAllTestCase(payLoad, false, 0);
    getAllModule();
    getAllUsersList();
    }else{
      setTestcaseDataLoading(false) 
    }
    
  }, []);
  function getTestcaseCount(payload) {
   
    getTotalTestcaseCount(payload, projectId, licenseID)
      .then((response) => {
        console.log("getTestcaseCount", response);
        if (response.status === 200) {
          setToatlTestcaseCount(response.data);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }

  function getAllTestCase(payload, filterAdded, page) {
    console.log("getAllTestCase payload",payload)
    if(page===0){
      setTestcaseDataLoading(true)
    }
   
    getAllTestcaseReq(payload, page, pageSize, projectId, licenseID)
      .then((response) => {
        payLoad = payload;
        console.log("response", response);
        if (response?.status === 200 && response?.data?.responseObject) {
          if (page === 0) {
            setTestCaseData(response?.data?.responseObject?.testcaseLogs);
          } else {
            let data = testcaseData.concat(
              response?.data?.responseObject?.testcaseLogs
            );
            setTestCaseData(data);
          }
          // setTestCaseData(response?.data?.responseObject?.testcaseLogs);
          setlastpage(response?.data?.responseObject?.lastPage);
          if (response?.data?.responseObject?.lastPage === false) {
            setPageNo(page + 1);
          }
          if (page === 0) {
            getTestcaseCount(payload);
          }
          console.log("response", response);
        } else {
          if (response?.data?.responseCode === 404) {
            setTestCaseData([]);
            setPageNo(0);
            setlastpage(false);
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
      setTestcaseDataLoading(false)
  }

  function getAllModule() {
    getAllModuleReq(projectId, licenseID)
      .then((response) => {
        console.log("response getAllModule", response);
        if (response.status === 200 && response.data.responseCode === 200) {
          let tempArray = [];

          response.data.responseObject.map((obj) => {
            let tempname = obj.id + ":" + obj.name;

            tempArray.push(tempname);
          });
          setModules(tempArray);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }

  const Styles = styled.div`
    table {
      border-spacing: 0;
      border: 1px solid #707070;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        border-bottom: 1px solid #707070;
        border-right: 1px solid #707070;

        :last-child {
          border-right: 0;
        }
      }
    }
  `;
  const handlescroll = (event) => {
    if (
      Math.ceil(event.target.scrollTop + event.target.clientHeight) ===
      event.target.scrollHeight
    ) {
      if (lastpage === false && globalFilter===undefined) {
        getAllTestCase(payLoad, false, pageNo);
        // getAllDefects(payLoad, false, pageNo, defectState);
        console.log("handlescroll");
      }
    }
  };

  function CloseTCReportModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      setGlobalFilter();
      setShowCloseicon(false);
    }
    setTCReportPopUp(state);
  }

  function CloseTesterModal(state) {
    if (globalFilter !== undefined) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      setGlobalFilter();
      setShowCloseicon(false);
    }
    setTCResultPopup(state);
  }

  const getAllTestcaseWithFilter = (
    payload,
    filtersAdded,
    filters,
    callApi
  ) => {
    payLoad = payload;
    console.log("payLoad",payLoad)
    setPageNo(0);
    console.log("filters", filters);
    setSelectedFilters(filters);
    if (callApi) {
      getAllTestCase(payLoad, filtersAdded, 0);
      localStorage.setItem("currentReportTab", JSON.stringify("ExecutionReports"));
    }
  };
  const sortBasedOnOrder = (testCaseDetails) => {
    return testCaseDetails.sort((a, b) => {
      return a?.order - b?.order;
    });
  };

  function getTemplateData(scriptData, projectName) {
    getAllTestCaseTemplate("config", projectId, licenseID)
      .then((responseData) => {
        if (responseData?.data?.responseCode === 200) {
          // setdetailsViewData(
          //   responseData?.data?.responseObject?.defaultTemplate?.testCaseDetails
          // );
          let table = JSON.parse(
            JSON.stringify(
              responseData?.data?.responseObject?.defaultTemplate?.testSteps
            )
          );
          setTemplateTableData(table);
          let testCaseDetails =
            responseData?.data?.responseObject?.defaultTemplate
              ?.testCaseDetails;
          testCaseDetails = sortBasedOnOrder(testCaseDetails);
          const testSteps =
            responseData?.data?.responseObject?.defaultTemplate?.testSteps;
          setTestCaseDeatilsData({
            ...scriptData,
            manualTestCase: {
              ...scriptData.manualTestCase,
              testCaseDetails: testCaseDetails,
              testSteps: testSteps,
            },
          });
          setTCResultPopup(true);
          // createValidationSchema(testCaseDetails, testSteps, projectName);
        } else {
          console.error("Template error", responseData?.data?.message);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }

  function getTestCaseTemplate() {
    getAllTestCaseTemplate("config", projectId, licenseID)
      .then((response) => {
        if (response?.data?.responseCode === 200) {
          let manualTestCase = {
            testCaseDetails:
              response?.data?.responseObject?.defaultTemplate.testCaseDetails,
            testSteps:
              response?.data?.responseObject?.defaultTemplate?.testSteps,
          };
          console.log("manualTestCase", manualTestCase);
          setTCReportdetails(manualTestCase);
          setTclabel("Template");
          setTCReportPopUp(true);
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }
  const getScript = (values) => {
    console.log("getScripts 123", values);
    let moduleId = values.module.split(/[:]/)[0];
    let ScriptId = values.testCaseId;
    setTestScriptStatus(values?.executionResult);
    setTestaCaseDetails(values);

    getTestcaseDetailsById(moduleId, ScriptId, projectId, licenseID)
      .then((response) => {
        console.log("Response", response);
        if (response?.data?.responseCode === 200) {
          if (
            Object.keys(response?.data?.responseObject?.manualTestCase)
              .length !== 0
          ) {
            setTCReportdetails(response?.data?.responseObject?.manualTestCase);
            setTCReportPopUp(true);
            setTclabel("Script");
          } else {
            getTestCaseTemplate();
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };
  const getAllUsersList = async () => {
    try {
      let response = await getAllTesterReq(crowdProjectId);

      if (response.data) {
        console.log("users data", response.data);
        let tempuserArray = [],
          tempCityArray = [];
        response.data.map((obj) => {
          tempuserArray.push(obj.name+":"+obj.email);
          if (obj.configuration !== null) {
            obj.configuration.configPlatformDetails.map((obj1) => {
              if (!tempCityArray.includes(obj1.city)) {
                tempCityArray.push(obj1.city);
              }
            });
          }
        });
        setUsers(tempuserArray);
        setLocations(tempCityArray);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const compareTestStepsObjects = (templateData, scriptData) => {
    let a = templateData?.headers;
    let b = scriptData?.headers;
    let testStepsObject = {};
    let matchedObject = {};
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedText = "";
      for (let j = 0; j < b.length; j++) {
        if (a[i]?.toLowerCase() === b[j]?.toLowerCase()) {
          match = true;
          matchedText = b[j];
          break;
        }
      }
      if (!match) {
        matchedObject[a[i]] = a[i];
      } else {
        matchedObject[matchedText] = matchedText;
      }
    }

    for (let i = 0; i < scriptData?.rows.length; i++) {
      const keys = Object.keys(matchedObject);
      const rowKeys = Object.keys(scriptData.rows[i]);
      for (let j = 0; j < keys.length; j++) {
        if (!rowKeys.includes(keys[j])) {
          scriptData.rows[i][keys[j]] = "";
        }
      }
    }
    testStepsObject.headers = Object.keys(matchedObject);
    testStepsObject.rows = scriptData.rows;
    return testStepsObject;
  };
  const compareTestCaseDetailsObjects = (a, b) => {
    let testCaseDetailsArray = [];
    for (let i = 0; i < a.length; i++) {
      let match = false;
      let matchedObject = {};
      for (let j = 0; j < b.length; j++) {
        if (
          a[i]?.label?.toLowerCase() === b[j]?.label?.toLowerCase() &&
          a[i]?.type?.toLowerCase() === b[j]?.type?.toLowerCase()
        ) {
          match = true;
          matchedObject = b[j];
          break;
        }
      }
      if (!match) {
        testCaseDetailsArray.push(a[i]);
      } else {
        a[i].value = matchedObject?.value;
        testCaseDetailsArray.push(a[i]);
      }
    }
    return testCaseDetailsArray;
  };

  const getScriptDeatils = async (values) => {
    let moduleId = values.module.split(/[:]/)[0];
    let ScriptId = values.testCaseId;

    try {
      let response = await getTestcaseDetailsById(
        moduleId,
        ScriptId,
        projectId,
        licenseID
      );
      // setTestcaseDetailsById(response?.data?.responseObject);
      let responseData = response?.data?.responseObject;
      let manualTestCaseObj = responseData?.manualTestCase;

      if (manualTestCaseObj) {
        setTestCaseDeatilsData(response?.data?.responseObject);
        if (Object.entries(manualTestCaseObj).length) {
          const response = await getAllTestCaseTemplate(
            "config",
            projectId,
            licenseID
          );
          if (response?.data?.responseCode === 200) {
            let testCaseDetails = compareTestCaseDetailsObjects(
              response?.data?.responseObject?.defaultTemplate?.testCaseDetails,
              manualTestCaseObj?.testCaseDetails
            );
            // setdetailsViewData(
            //   response?.data?.responseObject?.defaultTemplate?.testCaseDetails
            // );
            const testSteps = compareTestStepsObjects(
              response?.data?.responseObject?.defaultTemplate?.testSteps,
              manualTestCaseObj?.testSteps
            );
            testCaseDetails = sortBasedOnOrder(testCaseDetails);
            setTestCaseDeatilsData({
              ...responseData,
              manualTestCase: {
                ...manualTestCaseObj,
                testCaseDetails: testCaseDetails,
                testSteps: testSteps,
              },
            });
            setTCResultPopup(true);
          } else {
            console.error(
              "Get All TestCase Template error",
              response?.data?.message
            );
          }
        } else {
          getTemplateData(responseData, responseData?.name);
        }
      } else {
        getTemplateData(responseData, responseData?.name);
      }
      if (response?.data?.responseCode === 200) {
        let data =
          response?.data?.responseObject?.manualTestCase?.testCaseDetails;
        let table = JSON.parse(
          JSON.stringify(
            response?.data?.responseObject?.manualTestCase?.testSteps
          )
        );
        setTemplateTableData(table);

        setTestCaseDeatilsData(response?.data?.responseObject);
        setTCResultPopup(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function getExecutionResult(values) {
    let testcaseID = [],
      status = [];
    testcaseID.push(values.testCaseId);
    if (values.executionResult === "FLAKY") {
      status.push("PASS");
      status.push("FAIL");
    } else {
      status.push(values.executionResult);
    }

    StatuspayLoad.modules = payLoad.modules;
    StatuspayLoad.location = payLoad.location;
    StatuspayLoad.users = payLoad.users;
    StatuspayLoad.executionResult = status;
    StatuspayLoad.testCaseId = testcaseID;
  }

  let columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "testCaseId",
        width: "25%",
        Cell: (e) => (
          <div
            title={e.value}
            onClick={(event) => {
              event.preventDefault();
              console.log(
                "e.cell.row.original.Details",
                e.cell.row.original.Details
              );
              getScript(e.cell.row.values);
              // setTCReportdetails(e.cell.row.original.Details);
              // setTCReportPopUp(true);
            }}
            className="reportTablecell popins cursor-pointer	"
            style={{ color: "#434db8" }}
          >
            {e.value}
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "testcaseName",
        width: "64.7%",
        Cell: (e) => (
          <div title={e.value} className="cursor-default datatrimmer w-48">
            {/* {e.value} */}
            {e.value}
          </div>
        ),
      },
      {
        Header: "Module",
        accessor: "module",
        width: "60%",
        Cell: (e) => (
          <div title={e.value} className="cursor-default">
            {/* {e.value} */}
            {e.value ? e.value.split(/[:]/)[1] : "--"}
          </div>
        ),
      },
      {
        Header: "No Of Testers",
        accessor: "noOfTesters",
        width: "30%",
      },
      {
        Header: "No Of Executions",
        accessor: "noOfExecutions",
        width: "30%",
        Cell: (e) => (
          <div title={e.value} style={{ marginLeft: "8%" }}>
            {e.value}
          </div>
        ),
      },
      {
        Header: "Execution Result",
        accessor: "executionResult",
        width: "50%",
        Cell: (e) => (
          <div
            // title={e.value}
            className="cursor-pointer"
            style={{
              color:
                e.value === "PASS"
                  ? "green"
                  : e.value === "FAIL"
                  ? "red"
                  : e.value === "FLAKY"
                  ? "orange"
                  : "gray",
            }}
            onClick={(event) => {
              console.log(e.value);
              event.preventDefault();
              getExecutionResult(e.cell.row.values);
              getScriptDeatils(e.cell.row.values);
            }}
          >
            <Tooltip
              title={`Pass:${e.cell.row.original.passedExecutions},Fail:${e.cell.row.original.failedExecutions}`}
              placement="bottom-start"
            >
              <div>{e.value !== null && e.value !== "" ? e.value : "--"}</div>
            </Tooltip>
          </div>
        ),
      },
    ],
    []
  );
  let data = React.useMemo(() => testcaseData, [testcaseData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  function CloseDefectFilterModal(state) {
    setShowFilter(state);
  }

  return (
    <div className="reports_tescasetlog_table_container">
      <div className="grid grid-cols-12 flex content-">
        <div className="col-span-8 heading pl-3"> TestCase Log - {TotaltestcaseCount}</div>
        <div
          className={`grid grid-cols-12 col-span-4 flex justify-end ${
            props?.allowRejectedDefect === true
              ? ""
              : "defect_common_tabel_header"
          } `}
        >
          <div className="search_section col-span-9">
            <div className="defectlog_search">
              <div>
                <input
                  type="text"
                  className="searchfield"
                  value={globalFilter || ""}
                  autoFocus={false}
                  onChange={(e) => {
                    setGlobalFilter(e.target.value);
                  }}
                  placeholder="Search"
                />
              </div>
              <div className="search_icon">
                <Search />
              </div>
            </div>
          </div>
         
          <div className={`col-span-3 defectlog_filter_btn ${(JSON.parse(localStorage["selected-project"]).projectStatus!=="INREVIEW" || JSON.parse(localStorage["selected-project"]).projectStatus!=="REJECTED")?"opacity-100":"opacity-50"}`}>
            <button
              className="flex cursor-pointer"
              disabled={(JSON.parse(localStorage["selected-project"]).projectStatus==="INREVIEW" || JSON.parse(localStorage["selected-project"]).projectStatus==="REJECTED")?true:false}
              onClick={() => {
                CloseDefectFilterModal(true);
              }}
            >
              <div className="mt-1.5 ml-2.5">Filter</div>
              <div className="ml-2">
                {/* <FileDownloadOutlinedIcon/> */}
                <FilterListIcon />
              </div>
            </button>
          </div>
         
        </div>
      </div>
      
      <div className="card_table_container mt-2">
      <table {...getTableProps()} className=" overflow-x-auto">
            <thead className="text-left">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="defect_table_header">
                  {headerGroup.headers.map((column) => (
                    <th
                      width={column.width}
                      {...column.getHeaderProps()}
                      className={` reportTableheader popins-semi-bold text-lg font-semibold ${
                        column.Header === "ID"
                        
                          ? "text-left pl-3"
                          : "text-left pl-3"
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {
              testcaseDataLoading===false? (
                <>
                 {data.length === 0 ? (
               <div className="noreportIconCenter">
               <img  alt="warning" src={NoDefectIcon}/>
               <div className="text_msg">No Relevant Data Is Found !</div>
             </div>
            ) : (
              <div>
                <tbody
                  {...getTableBodyProps()}
                  // className="border-t-2"
                   className="defect_table_body"
                  onScroll={handlescroll}
                >
                  {rows.length > 0 ? (
                    <>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="h-10 " id="defectlog" >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  width={cell?.column?.width}
                                  {...cell.getCellProps()}
                                  className={`datatrimmer reportTablecell ${
                                    cell.column.Header === "BUG ID" ||
                                    cell.column.Header === "CREATED BY"
                                      ? "reportBugIdColumn popins-semi-bold"
                                      : "popins"
                                  } ${
                                    cell.column.Header === "ID" 
                                      ? "text-left pl-2.5"
                                      : "text-left pl-3"
                                  }`}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                     
                      <div className="noreportIconCenter">
                    <img  alt="warning" src={NoDefectIcon}/>
                    <div className="text_msg">No Relevant Data Is Found !</div>
                  </div>
                    </>
                  )}
                </tbody>
              </div>
            )}

                </>
              ):(
                <div className="mt-28">
                  <CircularLoader/>
                </div>
              )
            }
           
          </table>
      </div>

      {showFilter && (
        <TestCaseFilterModal
          CloseDefectFilterModal={CloseDefectFilterModal}
          data={defects}
          Module={modules}
          Location={locations}
          User={users}
          ExecutionResult={executionResults}
          filterApply={getAllTestcaseWithFilter}
          filters={selectedFilters}
        />
      )}

      {tcreportPopUp && (
        <TCReportDetails
          CloseTCReportModal={CloseTCReportModal}
          SelectedDefect={tcreportdetails}
          testScriptStatus={testScriptStatus}
          label={Tclabel}
          testcaseDetails={testcaseDetails}
        />
      )}
      {tcResultPopup && (
        <TCResultDeatilsPopup
          CloseTesterModal={CloseTesterModal}
          testerDeatils={userDetails}
          payload={StatuspayLoad}
          testCaseTemplate={testCaseDeatilsData}
        />
      )}
    </div>
  );
}
export default TestCaseLogTabel;
