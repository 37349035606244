import React ,{useState,useEffect}from "react";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@material-ui/core";
import RichTextEditor from "../../pages/config/rich-texteditor";
import { FORM_FIELDS } from "../../pages/config/defectConstant";
import { Download } from "@mui/icons-material";
import {getTemplateById,downloadDefectFileReq} from "../../api/api-services";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import PlatformDetails from "./platform-details";
import { CommonLoader } from "../common/common-loader";


function DefectDetails (props){ 
  console.log("DefectDetails",props)
const [detailsViewData, setdetailsViewData] = useState([]);
const [defectTemplate,setDefectTemplate]=useState([])
const { TEXTBOX, LINK, ATTACHMENT, TEXTEDITOR } = FORM_FIELDS;
let myrole= JSON.parse(localStorage["user-privilege"]).role;
const [role, setRole] = useState();
const [reason,setReason]=useState();
const [header, setHeader] = useState();
const [userName, setUserName] = useState();
const [isloading,setIsLoading]=useState(true);

useEffect(() => {
  let data = props?.SelectedDefect?.defectDetails?.history;
  let details = data[data?.length - 1];
  
  setRole(details?.userRole);
  setHeader(details?.state);
  setUserName(details?.userEmail);
   if(props?.SelectedDefect?.state==="REJECTED"){
     let index=props?.SelectedDefect?.defectDetails?.Reason?.indexOf("-");
    let length=props?.SelectedDefect?.defectDetails?.Reason?.length;
    if(index+2===length){
      let content=props?.SelectedDefect?.defectDetails?.Reason?.replace("-","");
      setReason(content)
      
    }else{
      setReason(props?.SelectedDefect?.defectDetails?.Reason)
    }
   }
  
}, []);

useEffect(()=>{
  let temid=props?.SelectedDefect?.defectTemplateId;
  // getdefectDetailsData();
  getTemplateDetails(temid);
    
},[]);

const getTemplateDetails=(data)=>{
  setDefectTemplate(props.defectTemplatedetails.data.responseObject.defectDetails);
  mapKeyAndValue(props.defectTemplatedetails.data.responseObject.defectDetails,props?.SelectedDefect?.defectDetails)
  setIsLoading(false)
 }
  function saveFileFromBlob(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

 const downloadFile = (file) => {
  console.log("file id",file.id);
  let projectId = JSON.parse(localStorage["selected-project"]).project.fireFlinkProjectId;
  let licenceID = JSON.parse(localStorage["selected-project"]).licenceID;
   downloadDefectFileReq(file.id,projectId,licenceID)
    .then((d) => {
       saveFileFromBlob(d.data, file.name);
    })
    .catch((err) => {
      console.error("donwload file from cloud", err);
    });
};
function emailMask(data) {
  console.log("email masking")
  let length = data.length;
  let email = "*********" + data.slice(length / 2, length);
  return email;
}

// function mapKeyAndValue(defectTemplateData, defectDetails) {
//     console.log("defectTemplateData",defectTemplateData);
//     console.log("defectDetails",defectDetails)
//     defectTemplateData?.forEach((item) => {
//       if (["Assign to"].includes(item?.label)) {
//         item.value = defectDetails && defectDetails[item.label]?.userEmail;
//       } else if(["Created By", "Modified By"].includes(item?.label)){
//         item.value = defectDetails && defectDetails[item.label]?.name;
//       }
//       else {
//         item.value = defectDetails && defectDetails[item.label];
//       }
//     });
//     console.log("defectTemplateData",defectTemplateData);
//     let modual=defectTemplateData[2];
//     let summary=defectTemplateData[1];
//     console.log("summary",summary)
//     defectTemplateData[1]=modual;
//     defectTemplateData[2]=summary;
//     delete defectTemplateData[6];

//      setdetailsViewData(defectTemplateData);
  
//   }




function mapKeyAndValue(defectTemplateData, defectDetails) {
  console.log("defectTemplateData", defectTemplateData);
  console.log("defectDetails", defectDetails);

  defectTemplateData?.forEach((item) => {
    if (["Assign to"].includes(item?.label)) {
      item.value = defectDetails && defectDetails[item.label]?.userEmail;
    } else if (["Created By", "Modified By"].includes(item?.label)) {
      item.value = defectDetails && defectDetails[item.label]?.name;
    } else {
      item.value = defectDetails && defectDetails[item.label];
    }
  });

  console.log("defectTemplateData", defectTemplateData);

  // Swap elements if they are not null and have different indices
  const moduleIndex = defectTemplateData.findIndex(item => item.label === "Module");
  const summaryIndex = defectTemplateData.findIndex(item => item.label === "Summary");
  
  if (moduleIndex !== -1 && summaryIndex !== -1 && moduleIndex !== summaryIndex) {
    const temp = defectTemplateData[moduleIndex];
    defectTemplateData[moduleIndex] = defectTemplateData[summaryIndex];
    defectTemplateData[summaryIndex] = temp;
  }

  setdetailsViewData(defectTemplateData);
}


  const detailViewComponents = (data) => {
    let value;
    
    if (data?.type !== ATTACHMENT) {
      if(data?.label!=="Module"){
        value =
        data?.value && Array.isArray(data.value)
          ? data.value.join(",")
          : data.value; 
      }else{

          let temarray=data?.value?.split(":")[0]?.split("/")?.reverse()
          let tempindex=temarray?.indexOf("Root");
          value=temarray?.slice(0,tempindex)?.reverse().join("/")

        
      }
      
    }

    if (data.type === LINK && value) {
      return (
        <div title={value} className="datatrimmer w-30 ">
          <a
            href={value}
            target="_blank"
            className="label-overflow tab-style-common cursor-pointer"
          >
            {value}
          </a>
        </div>
      );
    }
    if (data.type === ATTACHMENT && data?.value && Array.isArray(data?.value)) {
      return (
        
        <div>
          {
            data?.value?.length===0 || data?.value==="" ?"--":
            (
              <>
              {data?.value?.map((option, index) => (
              
            <div key={index} className="flex my-2" title={option?.name}>
              
              <div className="datatrimmer w-24 cursor-default	"  title={option?.name}>{option?.name || "--"}</div>
              <div className="ml-2 cursor-pointer"><Download
                color="primary"
                // className="cursor-pointer"
                onClick={() => {
                  downloadFile(option);
                }}
              /></div>
              
            </div>
          ))}
              </>
            )
          }
          
        </div>
      );
    }
    if (data.type === TEXTEDITOR) {
      return (
         <div className="mt-2 text-justify defect-texteditor">
          {
            value!==""?
            (
              <RichTextEditor
           convertedContent={value || ""}
            readOnly={true}
            toolbarHidden={true}
          />
            ):"--"
          }
          
        
           
        </div>
      );
    }
    if(data.label==="Assign to"){

      return (
        <>
          {value !== " "?
            myrole!=="PROJECT_OWNER"?<><div title={value} className="datatrimmer w-30">
            <label className="label-overflow cursor-pointer text-justify">{value || "--"}</label>
          </div></>:<div className="datatrimmer w-30"><label className="label-overflow cursor-pointer text-justify">{"*********" +value.slice(value.length / 2, value.length)}</label></div>
            :
            <div title={value} className="datatrimmer w-30"><label className="label-overflow text-justify">{value || "--"}</label></div>
          }
        </>
      );
    }
    
    return (
      <>
        {value !== "--" && value?.length > 30 ?
          <div title={value} className="datatrimmer w-30">
            <label className="label-overflow cursor-pointer text-justify">{value || "--"}</label>
          </div>
          :
          <div title={value} className="datatrimmer w-30"><label className=" text-justify">{value || "--"}</label></div>
        }
      </>
    );
  };
  const detailViewElements = (data) => {
    
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="flex ">
              <label>
                {data ? (
                  <Tooltip title={data?.label} placement="top">
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap labels">
                      {data?.label.length > 32 ? `${data?.label.substring(0, 32)}...`.toUpperCase() : data?.label.toUpperCase()}
                    </div>
                  </Tooltip>
                ) : null}
              </label>
            </div>
          </div>
          <div className={`contentDataStyle  values ${data?.value?.length > 32 ? 'datatrimmer w-34' : ''}`} >{detailViewComponents(data)}</div>
        </div>
      </>
    );
  }
    return(
      <>
      {        
          isloading ?<CommonLoader/>:(
            <div>
            
            <Grid
                container
                spacing={{ xs: 2, md: 3, sm: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
              {console.log(JSON.parse(JSON.stringify(detailsViewData)),"detailsViewData")}
                {detailsViewData &&
                  detailsViewData.length > 0 &&
                  detailsViewData.map((data, index) => {
                    return [TEXTBOX, TEXTEDITOR].includes(data?.type) || data?.order==="2" ? (
                      <Grid item xs={11} sm={11} md={11} key={index}>
                        {detailViewElements(data)}
                      </Grid>
                    ) : (
                      <Grid item xs={4} sm={4} md={4} key={index}>
                        {detailViewElements(data)}
                      </Grid>
                    );
                  })}
              </Grid>
              <div className="">
            <div className="platform_details mt-4">Platform Details</div>
            <div>
              {/* <PlatformDetails
                Platformdetails={props?.SelectedDefect?.defectDetails}
              /> */}
              <PlatformDetails
              Platformdetails={props?.SelectedDefect?.defectDetails}
              />
            </div>
          </div>
          <div className="">
            <div className="platform_details my-4">Defect Status Details</div>
            <div className="grid grid-cols-12 flex content-between">
              <div className="col-span-4">
                <span className="labels">STATUS</span>
                <br></br>
                <span
                  className={
                    props?.SelectedDefect?.state === "INREVIEW"
                      ? "inreview_state"
                      : props?.SelectedDefect?.state === "APPROVED"
                      ? "approve_state"
                      : "rejected_state"
                  }
                >
                  {props?.SelectedDefect?.state}
                </span>
              </div>
              <div className="col-span-8">
                <span className="labels">{header} BY </span>
                <br></br>
                {
                    myrole!=="PROJECT_OWNER"?(
                      <span className="values">
                  {role}-{userName}
                </span>
                    ):(
                      <span className="values">
                  {role}-{"*********" +userName?.slice(userName?.length / 2, userName.length)}
                </span>
                    )
                }
                
              </div>
            </div>
            {props?.SelectedDefect?.state === "REJECTED" && (
              <div className="grid grid-cols-12 flex content-between mt-2">
                <div className="col-span-11">
                  <span className="labels">REASON FOR REJECTION </span>
                  <br></br>
                  <span className="values">
                    {reason}
                    
                  </span>
                </div>
              </div>
            )}

            
          </div>

        </div>
          )
            
          
      }
      </>
       
    )
}
export default DefectDetails;