import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import DashboardBarChart from "./Dashboard-bar-chart";
import DashboardPieChart from "./Dashboard-piechart";
import { getDashboradbarchartData ,getDashboadPieChartData} from "../../api/api-services";
import DashBoardGreyChart from "./Dashboard-grey-chart";
import DonutChart from "../reports/charts/donut-chart";
import DashboardPirchartExpandModal from "./Dashboard-piechart-expand-modal";

function DashboardProjectType() {
  let [barChartSeries, setBarchatSeries] = useState([]);
  let [barchartUsercount, setBarChartUserCount] = useState([]);
  let [projectCount, setProjectCount] = useState([]);
  let [selectedlabel,setSelectedLabel]=useState("All");
  let [user,setUser]=useState({series:[],label:[]});
  let [OSdata,setOSData]=useState({series:[],label:[]});
  let [Browserdata,setBrowserdata]=useState({series:[],label:[]});
  let [osCount,setOSCount]=useState();
  let  [BrowserCount,setBrowserCount]=useState();
  let [usersCount,setUserCount]=useState();
  let [expandModal,setExpanModal]=useState(false);
  let [selectlabel,setSelectlabel]=useState("")



  useEffect(() => {
    getbarchartData();
    getPieChartData("All");
  }, []);

  function closeExpandModal(state){
    setExpanModal(state)
  }

  const getPieChartData=(label)=>{
    getDashboadPieChartData(label).then((response)=>{
      console.log("getPieChartData",response);
      if(response?.status===200){
        let temp_Browser={series:[],label:[]};
        let temp_user={series:[],label:[]};
        let temp_OS={series:[],label:[]}
        setOSCount(response?.data?.osPieChart?.length);
        setBrowserCount(response?.data?.browserPieChart?.length);
        setUserCount(response?.data?.userPieChart?.length)
        response?.data?.browserPieChart.map((item,index)=>{
          if(index < 4){
            temp_Browser.series.push(item.userCount);
            temp_Browser.label.push(item.name)
          }
         
        });
        setBrowserdata({series:temp_Browser.series,label:temp_Browser.label})
        response?.data?.osPieChart.map((item,index)=>{
          if(index < 4){
            temp_OS.series.push(item.userCount);
            temp_OS.label.push(item.name)
          }
         
        });
        setOSData({series:temp_OS.series,label:temp_OS.label})
        response?.data?.userPieChart.map((item)=>{
          temp_user.series.push(item.userCount);
          if(item.name==="TESTER"){
            temp_user.label.push("TEST ENGINEERS")

          }else{
            temp_user.label.push(item.name)
          }
          
        });
        setUser({series:temp_user.series,label:temp_user.label})
        

      }

    }).catch((error)=>{
      console.log("error")
    })
  }

  const getbarchartData = () => {
    getDashboradbarchartData()
      .then((response) => {
        console.log("response", response);
        let temparray = [];
        let usercount = [];
        let projectcount = [];
        if (response?.status === 200) {
          temparray.push(response?.data?.webProjectPercentage);
          temparray.push(response?.data?.mobileProjectPercentage);
          temparray.push(response?.data?.webAndMobileProjectPercentage);
          temparray.push(response?.data?.smartWatchProjectPercentage);
          temparray.push(response?.data?.standaloneProjectPercentage);
          temparray.push(response?.data?.televisionProjectPercentage);
          projectcount.push(response?.data?.web);
          projectcount.push(response?.data?.mobile);
          projectcount.push(response?.data?.webAndMobile);
          projectcount.push(response?.data?.smartWatches);
          projectcount.push(response?.data?.standalone);
          projectcount.push(response?.data?.television);

          usercount.push(response?.data?.webProjectTestersCount);
          usercount.push(response?.data?.mobileProjectTestersCount);
          usercount.push(response?.data?.webAndMobileProjectTestersCount);
          usercount.push(response?.data?.smartWatchProjectTestersCount);
          usercount.push(response?.data?.standaloneProjectTesterCount);
          usercount.push(response?.data?.televisionProjectTesterCount);
          setBarchatSeries(temparray);
          setBarChartUserCount(usercount);
          setProjectCount(projectcount);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  

  function getlabels(labels,seriesdata,val) {
 
    let colors = ["#AED6F1","#D7BDE2","#ABEBC6","#F5CBA7","#ECEDC5","#F5B7B1"];
    if(val==="User"){
      return labels.map((label, index) => {
        return (
          <div className="flex cursor-default">
            <div
              style={{ background: colors[index] }}
              className="label_dot"
            ></div>
            <div>
              <div className="label_text pr-0.5" title={label}>{label==="PROJECT_MANAGER"?"Project Manager":label==="TEST_LEAD"?"Test Lead":"Test Engineers"}{" "} </div>
            </div>
  
            <div className="label_text">
              - {""}
              {seriesdata[index]}{" "}
            </div>
          </div>
        );
      });
    }else{
      return labels.map((label, index) => {
        return (
          <div className="flex cursor-default">
            <div
              style={{ background: colors[index] }}
              className="label_dot"
            ></div>
            <div>
              <div className="label_text datatrimmer w-16" title={label}>{label} </div>
            </div>
  
            <div className="label_text">
              - {""}
              {seriesdata[index]}{" "}
            </div>
          </div>
        );
      });
    }
    
   
  }
  return (
    <div className="grid grid-cols-12 ">
      <div className="Dashboard-tabel-header mt-6 col-span-12 ">Project Types</div>
      <div className="col-span-12 Dashboard-table-container">
        
        <DashboardBarChart
          series={barChartSeries}
          project={projectCount}
          user={barchartUsercount}
        />
      </div>
      <div className="col-span-12 Dashboard-piechart-container grid grid-cols-12">
        <div className="grid grid-cols-12 col-span-12">
          <div className="col-span-12 grid grid-cols-12">
          <div className="col-span-4 grid grid-cols-12">
         
         <div className={`col-span-12 dashboard-pie-chart-header ${usersCount===0?"label_user":"user_label"} `}>User</div>
         {
          usersCount===0 ?(
            <div className="grid grid-cols-12 col-span-12">

            <div className="col-span-11"> 
          
            <DashBoardGreyChart/>
          
            </div>
           
           
           
            </div>
            
          ):(<div className="grid grid-cols-12 col-span-12">

          <div className="col-span-6"> <DashboardPieChart 
          labels={user.label}
          series={user.series}
          header="User"

          /></div>
          <div className="col-span-6 dashboard-card-chart-labels">
          {getlabels(user.label,user.series,"User")}
          </div>
         
         
          </div>)
         }
         
           <div className="col-span-12">{""}</div>
       </div>
       <div className="col-span-4 grid grid-cols-12">
         <div className={`col-span-12  dashboard-pie-chart-header ${osCount===0?"label_OS":"OS_label"}`}>OS</div>
         {
          osCount===0?( <div className="grid grid-cols-12 col-span-12">
          <div className="col-span-11"> 
          <DashBoardGreyChart/>
          </div>
         
          </div>):( <div className="grid grid-cols-12 col-span-12">
          <div className="col-span-6"> <DashboardPieChart 
           labels={OSdata.label}
           series={OSdata.series}
           header="OS"
           count={osCount}
          /></div>
          <div className="col-span-6 dashboard-card-chart-labels">
          {getlabels(OSdata.label,OSdata.series,"OS")}
          </div>
         
          </div>)
         }
        
           <div className="col-span-12 see_more" onClick={()=>{setSelectlabel("OS");setExpanModal(true)}}>{osCount>4?"more...":""}</div>
       </div>
       <div className="col-span-4 grid grid-cols-12">
         <div className={`col-span-12  dashboard-pie-chart-header ${BrowserCount===0?"label_browser":"browser_label"}`}>Browser</div>
         {
          BrowserCount===0 ?(<div className="grid grid-cols-12 col-span-12">
          <div className="col-span-11"> 
       
          <DashBoardGreyChart/>
          </div>
          
          
         
          </div>):(<div className="grid grid-cols-12 col-span-12">
          <div className="col-span-6"> <DashboardPieChart 
           labels={Browserdata.label}
           series={Browserdata.series}
           header="Browser"
           count={BrowserCount}
          /></div>
          <div className="col-span-6 dashboard-card-chart-labels">
          {getlabels(Browserdata.label,Browserdata.series,"Browser")}
          </div>
          
         
          </div>)
         }
         
           <div className="col-span-12 see_more" onClick={()=>{setSelectlabel("Browser");setExpanModal(true)}}>{BrowserCount>4?"more...":""}</div>
       </div>
          </div>
          <div className="col-span-12 flex px-4 pie-chart-btn">
            <div className={`w-10 ${selectedlabel==="All"?"select_btn":"unselect_btn"}`} onClick={()=>{setSelectedLabel("All");getPieChartData("All")}}>All</div>
            <div className={`${selectedlabel==="web"?"select_btn":"unselect_btn"}`} onClick={()=>{setSelectedLabel("web");getPieChartData("web")}}>Web</div>
            <div  className={`${selectedlabel==="mobile"?"select_btn":"unselect_btn"}`} onClick={()=>{setSelectedLabel("mobile");getPieChartData("mobile")}}>Mobile</div>
            <div className={` ${selectedlabel==="webAndMobile"?"select_btn":"unselect_btn"}`} onClick={()=>{setSelectedLabel("webAndMobile");getPieChartData("webAndMobile")}}>Web And Mobile</div>
            <div className={` ${selectedlabel==="Smart_Watches"?"select_btn":"unselect_btn"}`} onClick={()=>{setSelectedLabel("Smart_Watches");getPieChartData("Smart_Watches")}}>Smart Watch</div>
            <div className={` ${selectedlabel==="Standalone_Application"?"select_btn":"unselect_btn"}`} onClick={()=>{setSelectedLabel("Standalone_Application");getPieChartData("Standalone_Application")}}> Standalone</div>
            <div className={` ${selectedlabel==="Television"?"select_btn":"unselect_btn"}`} onClick={()=>{setSelectedLabel("Television");getPieChartData("Television")}}>Television</div>

          </div>
        </div>
         
      </div>
      {
        expandModal && (
          <DashboardPirchartExpandModal header={selectlabel} closeModal={closeExpandModal} label={selectedlabel}/>
        )
      }
     
    </div>
  );
}
export default DashboardProjectType;
