import axios from "axios";
import { logger } from "../util/common-utils";

const instance = axios.create({});
const APP_MANAGEMENT_BASE_URL = process.env.REACT_APP_APP_MANAGEMENT_BASE_URL;
instance.interceptors.request.use(function (config) {
  if (config.type !== "signup" && config.type !== "signin") {
  const userSession = localStorage.getItem("fire-crowd-user");
  const isTokenExpired = localStorage.getItem("refreshToken");
  


  let token = "";

  if (userSession !== undefined && userSession !== null && userSession !== "") {
    token = JSON.parse(userSession).access_token;
  }
  
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

}

  // if config type is blob, should not add the Accept, Content-Type headers in request
  if (config.responseType === "blob") {
    return config;
  }

  if (config.type === "formdata") {
    config.headers["Content-Type"] = "multipart/form-data";
    return config;
  }

  config.headers["Accept"] = "application/json";
  config.headers["Content-Type"] = "application/json";
  return config;
});
const updateTokenData = (response) => {
  if (response.status === 200 && response?.data?.access_token) {
    let crowd_user=JSON.parse(localStorage["fire-crowd-user"]);
    crowd_user.access_token=response.data.access_token;
    crowd_user.refresh_token=response.data.refresh_token;
    localStorage.setItem("fire-crowd-user", JSON.stringify(crowd_user));
    logger("TokenData Updated!", response);
  }
};

const refreshTokenExpired = (error) => {
  logger("Refresh Token Expired! ", error);
  localStorage.clear();
  window.location.replace("/signin");
};
instance.interceptors.response.use(
  (response) => {
    
    const isTokenExpired = localStorage.getItem("refreshToken");
              if (isTokenExpired) {
                localStorage.removeItem("refreshToken");
                logger("Token Expired!", response);
              }
    return response
  },
  (error) => {
    console.log(error,"qqq")
    logger("Error Interceptor =>", error.response);
    // alert("Request Failed. Please try again in some time.");
          if (error.response.status === 401) {
        logger("Refresh Token API called!")
        localStorage.setItem("refreshToken", JSON.parse(localStorage.getItem("fire-crowd-user"))?.refresh_token);
        return axios
          .post(`${APP_MANAGEMENT_BASE_URL}/appmanagement/optimize/v1/public/user/refresh`, {
            emailId:JSON.parse(localStorage.getItem("fire-crowd-user"))?.userName,
            refreshToken: JSON.parse(localStorage.getItem("fire-crowd-user"))?.refresh_token,
            source: "FireCrowd"
          })
          .then((response) => {
            updateTokenData(response);
            const newAccessToken= response?.data?.access_token
            error.response.config.headers["Authorization"] = "Bearer " + newAccessToken;
            return axios(error.response.config);
          })
          .catch((error2) => {
            console.log("http error",error2)
            refreshTokenExpired(error2);
            return Promise.reject(error2);
          });
      }
    return error;
  }
);


export default instance;
