import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FilterBox } from "./filter-box";
import "./filters.scss";
import { FilterLabels } from "./filter-labels";
import ClearFilterWarningPopup from "./clear-filter-warning-popup";
import CloseIcon from "@mui/icons-material/Close";

let moduleSelected = [],
  moduleTempSelected = [],
  executionSelected = [],
  executionTempSelected = [],
  locationSelected = [],
  locationTempSelected = [],
  userSelected = [],
  userTempSelected = [];
let filterBasePayLoad = {
  modules: [],
  executionResult: [],
  location: [],
  users: [],
};
let filterPayLoad = JSON.parse(JSON.stringify(filterBasePayLoad));

function TestCaseFilterModal(props) {
  const [openModal, setOpenModal] = useState(true);
  const [tempState, setTempState] = useState(true);
  const [filterChanged, setFilterChanged] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [confirmClearFilter, setConfirmClearFilter] = useState(false);

  let [modulevalues, setModulevalues] = useState(props?.Module);
  let [locationvalues, setLocationvalues] = useState(props?.Location);
  let [uservalue, setUservalue] = useState(props?.User);
  let [executionvalue, setExecutionvalue] = useState(props?.ExecutionResult);
  useEffect(() => {
    let currentTab = JSON.parse(localStorage["currentReportTab"]);
    if (!(currentTab === "ExecutionReports")) {
      moduleSelected = [];
      moduleTempSelected = [];
      executionSelected = [];
      executionTempSelected = [];
      locationSelected = [];
      locationTempSelected = [];
      userSelected = [];
      userTempSelected = [];
      filterPayLoad = JSON.parse(JSON.stringify(filterBasePayLoad));
      setTempState(!tempState);
    }
  }, []);

  moduleTempSelected =
    moduleTempSelected.length > 0
      ? moduleTempSelected
      : props?.filters?.modules
      ? props?.filters?.modules
      : [];
  executionTempSelected =
    executionTempSelected.length > 0
      ? executionTempSelected
      : props?.filters?.execution
      ? props?.filters?.execution
      : [];
  locationTempSelected =
    locationTempSelected.length > 0
      ? locationTempSelected
      : props?.filters?.location
      ? props?.filters?.location
      : [];
  userTempSelected =
    userTempSelected.length > 0
      ? userTempSelected
      : props?.filters?.user
      ? props?.filters?.user
      : [];
  useEffect(() => {
    //for future
  }, [filterChanged]);
  function onCloseWarningPopup() {
    setConfirmClearFilter(false);
    props.CloseDefectFilterModal(false);
  }

  // const customStylesPage = {
  //   overlay: {
  //     background: "rgba(90, 90, 90,0.5)",
  //   },
  //   content: {
  //     position: "fixed",
  //     top: "50%",
  //     left: "75%",
  //     transform: "translate(-50%, -50%)",
  //     width: "50%",
  //     height: "100%",
  //     background: "#fbfcfd 0% 0% no-repeat padding-box",
  //     boxShadow: "0px 0px 11px #000000a1",
  //     opacity: "1",
  //     outline: "none",
  //     borderTopLeftRadius: "4px",
  //   },
  // };
  const handleFilterReq = (event) => {
    let filterVal = event.target.value;
    let filterKey = event.target.name;
    let tempSegment = filterKey.split("-")[0];
    let tempKey = filterKey.split("-")[1];
    console.log("tempSegment", tempSegment);
    console.log("tempKe", tempKey);
    console.log("handleFilterReq", event);

    if (event.target.checked) {
      if (tempSegment === "module") {
        moduleSelected.push(event.target);
        moduleTempSelected.push(filterVal);
        filterPayLoad.modules.push(filterVal);
      } else if (tempSegment === "execution") {
        executionSelected.push(event.target);
        executionTempSelected.push(filterVal);

        filterPayLoad.executionResult.push(filterVal);
      } else if (tempSegment === "location") {
        locationSelected.push(event.target);
        locationTempSelected.push(filterVal);
        filterPayLoad.location.push(filterVal);
      } else if (tempSegment === "user") {
        userSelected.push(event.target);
        userTempSelected.push(filterVal);
        filterPayLoad.users.push(filterVal.split(":")[1]);
      }
    } else {
      let tempIndex = "";

      if (tempSegment === "module") {
        tempIndex = moduleTempSelected.indexOf(filterVal);
        moduleSelected.splice(tempIndex, 1);
        moduleTempSelected.splice(tempIndex, 1);
        filterPayLoad.modules.splice(
          filterPayLoad.modules.indexOf(filterVal),
          1
        );
        if (filterPayLoad.modules.length === 0) {
          filterPayLoad.modules = [];
        }
      } else if (tempSegment === "execution") {
        tempIndex = executionTempSelected.indexOf(filterVal);
        executionSelected.splice(tempIndex, 1);
        executionTempSelected.splice(tempIndex, 1);
        filterPayLoad.executionResult.splice(
          filterPayLoad.executionResult.indexOf(filterVal),
          1
        );
        if (filterPayLoad.executionResult.length === 0) {
          filterPayLoad.executionResult = [];
        }
      } else if (tempSegment === "location") {
        tempIndex = locationTempSelected.indexOf(filterVal);
        locationSelected.splice(tempIndex, 1);
        locationTempSelected.splice(tempIndex, 1);
        filterPayLoad.location.splice(
          filterPayLoad.location.indexOf(filterVal),
          1
        );
        if (filterPayLoad.location.length === 0) {
          filterPayLoad.location = [];
        }
      } else if (tempSegment === "user") {
        tempIndex = userTempSelected.indexOf(filterVal);
        userSelected.splice(tempIndex, 1);
        userTempSelected.splice(tempIndex, 1);
        filterPayLoad.users.splice(
          filterPayLoad.users.indexOf(filterVal.split(":")[1]),
          1
        );
        if (filterPayLoad.users.length === 0) {
          filterPayLoad.users = [];
        }
      }
    }
    console.log("filter payload", filterPayLoad);
    setFilterChanged(!filterChanged);
  };
  const clearAllFilters = (callApi) => {
    filterPayLoad = JSON.parse(JSON.stringify(filterBasePayLoad));
    moduleSelected = [];
    executionSelected = [];
    locationSelected = [];
    userSelected = [];
    moduleTempSelected = [];
    executionTempSelected = [];
    locationTempSelected = [];
    userTempSelected = [];
    if (callApi) {
      props.filterApply(
        {
          modules: moduleTempSelected,
          executionResult: executionTempSelected,
          location: locationTempSelected,
          users: userTempSelected,
        },
        true,
        filterPayLoad,
        true
      );
    }
    setFilterChanged(!filterChanged);
    setFilterApplied(false);
  };
  return (
    <div className="testcase_filter_container">
      {/* <div className=" h-6 px-2 grid grid-cols-12">
        <div className="col-span-10 testcase_filter_container_header pt-2">Testcase Log Filter</div>
        <div className="flex justify-end col-span-2">
        <button
          onClick={() => {
            setOpenModal(false);
            props.CloseDefectFilterModal(false);
            if (!props.filters.hasOwnProperty("segment")) {
              clearAllFilters(false);
            }
          }}
         
          type="button"
        
        >
          <CloseIcon/>
        </button>
        </div>
       
      </div> */}

      <div className="flex  rounded-tl-xl justify-between defect-filter-modal-heading h-10 p-2">
        <div>Testcase Log Filter</div>
        <button
          onClick={() => {
            setOpenModal(false);
            props.CloseDefectFilterModal(false);
            if (!props.filters.hasOwnProperty("segment")) {
              clearAllFilters(false);
            }
          }}
          type="button"
          className="modal-close-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="testcase_new_filters">
      <div>
        {/* <div className="p-3 selected-filter-header">Selected Filter</div> */}
        <div className={`p-3 mx-2 my-2 filter-lables-container ${moduleSelected.length === 0 &&
            executionSelected.length === 0 &&
            locationSelected.length === 0 &&
            userSelected.length === 0?"hidden":"block"}`}>
          {moduleSelected.length === 0 &&
            executionSelected.length === 0 &&
            locationSelected.length === 0 &&
            userSelected.length === 0 && (
              <div className="flex justify-center">
                <span className="no-defects-label">No Selected Filter</span>
              </div>
            )}
          {moduleSelected.length > 0 && (
            <div>
              <div className="selected_filter_header">Module</div>
              <FilterLabels
                filterLabelsList={moduleSelected}
                onLabelsChange={handleFilterReq}
                label={"Testcase-module"}
              />
            </div>
          )}
          {executionSelected.length > 0 && (
            <div>
              <div className="selected_filter_header">Execution Results</div>
              <FilterLabels
                filterLabelsList={executionSelected}
                onLabelsChange={handleFilterReq}
              />
            </div>
          )}
          {locationSelected.length > 0 && (
            <div>
              <div className="selected_filter_header">Location</div>
              <FilterLabels
                filterLabelsList={locationSelected}
                onLabelsChange={handleFilterReq}
              />
            </div>
          )}
          {userSelected.length > 0 && (
            <div>
              <div className="selected_filter_header">User</div>
              <FilterLabels
                filterLabelsList={userSelected}
                onLabelsChange={handleFilterReq}
                label={"User"}
              />
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-12 flex">
        <div className="p-3 col-span-3">
          <div className="filter-header">Module</div>
          <div>
            <div>
              <FilterBox
                filterList={modulevalues}
                type={`module-type`}
                onClickFilterItem={handleFilterReq}
                addedFilters={moduleTempSelected}
              />
            </div>
          </div>
        </div>
        <div className="p-3 col-span-3">
          <div className="filter-header">Execution Result</div>
          <div>
            <div>
              <FilterBox
                filterList={executionvalue}
                type={`execution-type`}
                onClickFilterItem={handleFilterReq}
                addedFilters={executionTempSelected}
              />
            </div>
          </div>
        </div>
        <div className="p-3 col-span-3">
          <div className="filter-header">Location</div>
          <div className="flex">
            <div className="pr-3">
              <div>
                <FilterBox
                  filterList={locationvalues}
                  type={`location-type`}
                  onClickFilterItem={handleFilterReq}
                  addedFilters={locationTempSelected}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 col-span-3">
          <div className="filter-header">User</div>
          <div className="flex">
            <div className="pr-3">
              <div>
                <FilterBox
                  filterList={uservalue}
                  type={`user-type`}
                  onClickFilterItem={handleFilterReq}
                  addedFilters={userTempSelected}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

     
      <div className="py-2 px-3 flex justify-end gap-6  filter-modal-buttons">
        <button
          onClick={() => {
            // clearAllFilters();
            setConfirmClearFilter(true);
          }}
          type="button"
          className="w-24 clear-button button"
        >
          Clear
        </button>
        {/* <button
            onClick={() => {
              setOpenModal(false);
              props.CloseDefectFilterModal(false);
             
             
            }}
            type="button"
            className="w-24 gray-btn button"
          >
            Cancel
          </button> */}
        <button
          className={"w-24 primary-btn button"}
          onClick={() => {
            console.log(
              moduleTempSelected,
              executionTempSelected,
              locationTempSelected,
              userTempSelected
            );
            setOpenModal(false);
            props.CloseDefectFilterModal(false);
            setFilterApplied(true);
            props.filterApply(
              filterPayLoad,
              true,
              {
                modules: moduleTempSelected,
                execution: executionTempSelected,
                location: locationTempSelected,
                user: userTempSelected,
              },
              true
            );
          }}
        >
          Apply
        </button>
      </div>
      {confirmClearFilter && (
        <ClearFilterWarningPopup
          onCloseWarningPopup={onCloseWarningPopup}
          clearFilters={clearAllFilters}
        />
      )}
    </div>
  );
}

export default TestCaseFilterModal;
