import { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { TextField } from "@material-ui/core";
import "./project.scss";
import TYDateTimePicker from "../modal/ty-data-picker";
import ProjectRadioItem from "./project-radio-item";
import Modal from "react-modal";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileUploadButton from "../common/fileUpload_button";
import ProjectCheckboxItem from "./project-checkbox-item";
import { convertToAPIDate } from "../../util/common-utils";
import { createProject, editProject,CheckProjectExistReq } from "../../api/api-services";
import AttachFileSharpIcon from "@mui/icons-material/AttachFileSharp";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { CommonLoader } from "../common/common-loader";
import { ReactComponent as DangerAlert } from "../../assets/dangerAlert.svg";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { SelectDropdown } from "./modals/select-dropdown";
import WarningPopup from "../modal/warning-popup";
import ExectiveReportsPopup from "./modals/executive-reports-modal";
import { ProgressContainerContext } from "../common/inputs/ProgressContainer";


const stepOneHeading = "Step 1 : Lets start with the project";
const stepTwoHeading = "Step 2 : How do you like your project done";
const stepThreeHeading = "Step 3 : Project Details";
const eventStartDateLabel = "Event Start Date";
const eventDurationLabel = "Event Duration";
const eventDurationPlaceHolder = "Enter project duration in days";
const eventDurationError = "Event duration is required";
const eventDurationTwoDigitsError = "Event Duration cannot be more than 2 digits";
const projectTypeLabel = "Project Type";
const webUrlLabel = "Web URL";
const webUrlPlaceHolder = "Enter web url";
const appTypeLabel = "App Type";
const platformLabel = "Platform";
const appPackageLabel = "App Package";
const appActivityLabel = "App Activity";
const bundleIdLabel = "Bundle ID";
const nativeLabel = "Native";
const hybridLabel = "Hybrid";
const androidLabel = "Android";
const iosLabel = "iOS";
const androidIosLabel = "Android & iOS";
const baseUriLabel = "Base URI";
const portLabel = "Port";
const noOfTestersLabel = "Number Of Test engineers";
const noOfTestersPlaceHolder = "Enter number of Test engineers";
const noOfTestersError = "Number of Test engineers is required";
const guidedTesting = "Guided Testing";
const guidedTestingMenuDetail =
  "(Upload testcases, crowd test engineers will execute and raise the defects)";
const guidedTestingSubMenu =
  "Allow crowd test engineers to explore and add additional testcase";
const unguidedTesting = "Unguided Testing";
const unguidedTestingMenuDetail =
  "(Crowd test engineers will do exploratory  testing and raise the defects)";
const unguidedTestingSubMenu =
  "Allow crowd test engineers to explore and add additional testcase";
const uploadButtonText = "Upload Testcases (.xls)";
const typeOfTesting = "Type Of Testing";
const executionTypeLabel = "Execution Type";
const nameLabel = "Name";
const namePlaceHolder = "Enter your project name";
const nameError = "Name is required";
const descriptionLabel = "Description";
const descriptionPlaceHolder = "Your project description goes here..";
const startDateError = "Start date is required";
const testingTypeError = "Testing type is required";
const instructionsLabel = "Instruction To Test engineers";
const instructionsPlaceHolder = "Your instructions goes here..";
const supportingDocsLabel = "Supporting Documents";
const domainLabel = "Domain Name";
const television="Television";
const smart_watches="Smart Watches";
const Standalone_Application="Standalone Application"
const typeOfProjectLabel="Type of Project"

let fileName = "";
let uiSupportDocsArray = [];
let deleteFilesArray = [];
let attachmentsArray = [];

function ProjectCreate(props) {
  const { resetProgress, startOrResumeProgress } = useContext(
    ProgressContainerContext
  );
  console.log("createdBy", props);
  const [executionType, setExecutionType] = useState(
    props?.editDetails?.executionDetails?.guideType !== undefined
      ? props.editDetails.executionDetails.guideType
      : "GUIDED"
  );
  const [executionTypeAllow, setExecutionTypeAllow] = useState(
    props?.editDetails?.executionDetails.guideType === "GUIDED" &&
      props?.editDetails?.executionDetails.uploadTestCaseByTestersAllowed
      ? "GUIDED_ALLOW"
      : props?.editDetails?.executionDetails.guideType === "UNGUIDED" &&
        props?.editDetails?.executionDetails.uploadTestCaseByTestersAllowed
        ? "UNGUIDED_ALLOW"
        : ""
  );
  let myRole = localStorage.hasOwnProperty("user-privilege")
    ? JSON.parse(localStorage["user-privilege"]).role
    : "";

  // const [uploadFile, setUploadFile] = useState(true);
  const [uploadFile, setUploadFile] = useState(props?.editDetails?.executionDetails?.testCases?false:true);
  const [testcaseFile,setTestcaseFile]=useState(props?.editDetails?.executionDetails?.testCases?gettestcasefile(props?.editDetails?.executionDetails?.testCases):null)

  const [uploadedApkFile, setUploadedApkFile] = useState();
  const [uploadedIpaFile, setUploadedIpaFile] = useState();
  const [Para, setPara] = useState(null);
  const [Para2, setPara2] = useState(null);
  const [execleFile,setExecleFile]=useState(null)
  const [showAndroidBtn, setShowAndroidBtn] = useState(true);
  const [showIPABtn, setShowIPAbtn] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  // const [uploadedTestCases, setUploadTestCases] = useState(null);
  const [uploadedTestCases, setUploadTestCases] = useState(props?.editDetails?.executionDetails?.testCases?props?.editDetails?.executionDetails?.testCases:null);
  const [isLoading, setIsloading] = useState(true);
  const [docsChanged, setDocsChanged] = useState(false);
  let [projectNameExist,setProjectNameExist]=useState(false)

  
  const [projectList, setProjectList] = useState(props.ProjectNameList);
  const [startDatechange, setStartDateChange] = useState(false);


  const domainList = localStorage.hasOwnProperty("domain-list")
    ? JSON.parse(localStorage["domain-list"])
    : [];

  const [addExecutiveReport, setAddExecutiveReport] = useState(false);
  const [executiveReport, setExecutiveReport] = useState();
  const [certificateValidation,setCertificateValidation]=useState();

  let [projectstatus, setProjectStatus] = useState(
    props?.editDetails?.projectStatus
      ? [
        {
          label: props?.editDetails?.projectStatus,
          value: props?.editDetails?.projectStatus,
        },
      ]
      : []
  );
  let [updateStatus, setUpdateStatus] = useState();

  let [projectdomain, setProjectDomain] = useState(
    props?.editDetails?.domainName
      ? [
        {
          label: props?.editDetails?.domainName,
          value: props?.editDetails?.domainName,
        },
      ]
      : []
  );

  const [updateDomainName, setUpadteDomainName] = useState(props?.editDetails?.projectStatus === "INREVIEW" ? false : (props?.editDetails?.domainName === null && props?.editDetails?.projectStatus !== "INREVIEW") ? true : false);

  let [statusWarning, setStatusWarning] = useState(false);
  let [status, setStatus] = useState([]);

  const [descCount, setdescCount] = useState((props.header === "Edit Project" && props.editDetails.project.projectDesc!==null)?props.editDetails.project.projectDesc.length:0);
  let [instCount, setInstCount] = useState((props.header === "Edit Project" && props?.editDetails?.executionDetails?.instructions!==null)?props?.editDetails?.executionDetails?.instructions.length:0);

  useEffect(() => {
    //for future
  }, [docsChanged]);

  const projectTypeList = [
    { key: "Web", value: "web" },
    { key: "Mobile", value: "mobile" },
    { key: "Web & Mobile", value: "webAndMobile" },
    // { key: "WebServices", value: "webservice" },
    { key: "Others", value: "others" },
  ];
  const testingTypeList = [
    { key: "Functionality Testing", value: "FUNCTIONALITY_TESTING" },
    { key: "Usability Testing", value: "USABILITY_TESTING" },
    { key: "Accessibility Testing", value: "ACCESSIBILITY_TESTING" },
    { key: "End to End Testing", value: "END_TO_END_TESTING" },
    { key: "Exploratory Testing", value: "EXPLORATORY_TESTING" },
    { key: "Compatibility Testing", value: "COMPATIBILITY_TESTING" },
    { key: "Sociability Testing", value: "SOCIABILITY_TESTING" },
    { key: "Integration Testing", value: "INTEGRATION_TESTING" },
  ];

  useEffect(() => {
    getstatus();
  }, []);
  function gettestcasefile(filename){
 
    
    return filename?.slice(filename?.lastIndexOf("/") + 1,filename?.length)
    

  }
  function getstatus() {
    if (
      localStorage.hasOwnProperty("user-privilege") &&
      JSON.parse(localStorage["user-privilege"]).role === "ADMIN"
    ) {
      if (props?.editDetails?.projectStatus === "INREVIEW") {
        setStatus([
          { label: "REJECTED", value: "REJECTED" },
          { label: "APPROVED", value: "APPROVED" },
        ]);
      } else if (props?.editDetails?.projectStatus === "APPROVED") {
        setStatus([
          { label: "REJECTED", value: "REJECTED" },
          { label: "INPROGRESS", value: "INPROGRESS" },
        ]);
      } else if (props?.editDetails?.projectStatus === "INPROGRESS") {
        setStatus([{ label: "CLOSED", value: "CLOSED" }]);
      }  else if (props?.editDetails?.projectStatus === "CLOSED") {
        setStatus([{ label: "COMPLETED", value: "COMPLETED" }]);
      } 
      else if (props?.editDetails?.projectStatus === "REJECTED") {
        setStatus([
          { label: "APPROVED", value: "APPROVED" },
        ]);
      } else {
        setStatus([]);
      }
    } else {
      if (props?.editDetails?.projectStatus === "APPROVED") {
        setStatus([
          { label: "INPROGRESS", value: "INPROGRESS" },
          { label: "CLOSED", value: "CLOSED" },
        ]);
      } else if (props?.editDetails?.projectStatus === "INPROGRESS") {
        setStatus([{ label: "CLOSED", value: "CLOSED" }]);
      } else if (props?.editDetails?.projectStatus === "CLOSED") {
        setStatus([{ label: "COMPLETED", value: "COMPLETED" }]);
      } 
      else {
        setStatus([]);
      }
    }
  }

  
  function duplicatesCheck(projectname){
   if(JSON.parse(localStorage.getItem(["my-role"]))!=="admin"){
    let emailId = JSON.parse(localStorage["fire-crowd-user"]).userName
    if(props?.editDetails){
      let tempProps = JSON.parse(JSON.stringify(props));
    if(projectname!==tempProps.editDetails.project.projectName){
      CheckProjectExistReq(emailId,projectname).then((response)=>{
        console.log("response",response)
        if(response.status===200){
          if(response.data===true){
            setProjectNameExist(true)
            // formikDetails.setFieldError('projectName', `Project name is already exists`);
            
          }else{
            setProjectNameExist(false)
          }
          
        }
  
  
  
      }).catch((error)=>{
        console.log("")
      })
    }else{
      setProjectNameExist(false)
    }

    }else{
      CheckProjectExistReq(emailId,projectname).then((response)=>{
        console.log("response",response)
        if(response.status===200){
          if(response.data===true){
            setProjectNameExist(true)
            // formikDetails.setFieldError('projectName', `Project name is already exists`);
            
          }else{
            setProjectNameExist(false)
          }
          
        }
  
  
  
      }).catch((error)=>{
        console.log("")
      })
      
    }
    
    

   }
  }
  const validationSchema = yup.object({
    projectName: yup
      .string()
      .required(nameError)
      .trim()
      .matches(/^[A-Za-z0-9-_ ]*$/, "Project name should be alphanumeric")
      .min(3, "Project name should contain atleast 3 characters")
      .max(25, "Project name should contain max 25 characters"),
     
    startDate: yup.date().required(startDateError),

    duration: yup
      .number()
      .required(eventDurationError)
      .typeError("Event Duration should be numeric")
      .moreThan(0, "Event Duration should be greater than zero")
      .lessThan(100, eventDurationTwoDigitsError)
      .test(
        "cannotBeLess",
        "Duration cannot be less then previous",
        (value) => {
          return !(
            props?.editDetails?.projectStatus === "INPROGRESS" &&
            value < props?.editDetails?.duration
          );
        }
      ),
    numberOfTesters: yup
      .number()
      .required(noOfTestersError)
      .typeError("Number of testers should be numeric")
      .max(99999, "Number of tester should be less than lakh")
      .moreThan(0, "Number of testers should be greater than zero")
      .test("cannotBeLess", "Testers count cannot be reduced", (value) => {
        return !(
          props?.editDetails?.projectStatus === "INPROGRESS" &&
          value < props?.editDetails?.numberOfTesters
        );
      }),
    testingTypes: yup.array().min(1, testingTypeError),

    webUrl: yup
      .string()
      .when("projectType", {
        is: (v) => v === "web" || v === "webAndMobile",
        then: yup.string().nullable(true),
        otherwise: yup.string().nullable(true).optional(),
      })
      .matches(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        "Enter vaild url"
      )
      .test("mustContain", "Web url should be start with http:// or https://", (value) => {
        if (value) {
          return ((value.slice(0, 8) === "https://" || value.slice(0, 7) === "http://") ? true : false)
        } else {
          return true
        }


      }),

    appType: yup.string().when("projectType", {
      is: (v) => v === "mobile" || v === "webAndMobile" || v ==="others",
      then: yup.string().required("Select app type"),
      otherwise: yup.string().optional(),
    }),
    platformType: yup.string().when("projectType", {
      is: (v) => v === "mobile" || v === "webAndMobile " || v ==="others",
      then: yup.string().required("Select platform type"),
      otherwise: yup.string().optional(),
    }),
    typeOfProject:yup.string().when("projectType",{
      is:(v)=> v ==="others",
      then: yup.string().required("Select  type of project"),
      otherwise: yup.string().optional(),
    })
   
  });

  let initialValues;
  if (props?.editDetails) {
    let tempProps = JSON.parse(JSON.stringify(props));
    console.log("tempProps",tempProps)
    initialValues = {
      projectName: tempProps.editDetails.project.projectName,
      projectDesc: tempProps.editDetails.project.projectDesc,
      duration: tempProps.editDetails.duration,
      startDate: tempProps.editDetails.startDate,
      testingTypes: tempProps.editDetails.testingTypes,
      numberOfTesters: tempProps.editDetails.numberOfTesters,
      projectType: "web",
      webUrl: "",
      appType: "",
      platformType: "",
      appPackage: "",
      appActivity: "",
      bundleID: "",
      port: "",
      baseURI: "",
      instructions: tempProps?.editDetails?.executionDetails?.instructions,
      executionDetails: tempProps?.editDetails?.executionDetails?.guideType,
      domainName: tempProps?.editDetails?.domainName,
      typeOfProject:""
    };
    
  // console.log("tempProps?.editDetails?.executionDetails?.guideType",tempProps?.editDetails?.executionDetails?.guideType)
  //  if(tempProps?.editDetails?.executionDetails?.guideType==="GUIDED"){
  //   initialValues.testCases={
  //     name:tempProps?.editDetails?.executionDetails?.testCases!==null
  //     ?tempProps?.editDetails?.executionDetails?.testCases.slice(
  //       tempProps?.editDetails?.executionDetails?.testCases.lastIndexOf("/")+1,tempProps?.editDetails?.executionDetails?.testCases.length
  //     ):""
  //   }
  //    initialValues.testCases=tempProps?.editDetails?.executionDetails?.testCases

  //  }
    let tempProjectTypeArray = Object.keys(
      tempProps.editDetails.project.projectType
    );

    let tempProjectType = [];
    tempProjectType = tempProjectTypeArray.filter(
      (obj) => tempProps.editDetails.project.projectType[obj] !== null
    );
    initialValues.projectType = tempProjectType[0];
    let tempPlatform = "";
    switch (tempProjectType[0]) {
      case "web":
        initialValues.webUrl = tempProps.editDetails.project.projectType.web.weburl;
        break;
        case "others":
          initialValues.webUrl = tempProps.editDetails.project.projectType.others.weburl;
          initialValues.appType =tempProps.editDetails.project.projectType.others.appType;
          tempPlatform = tempProps.editDetails.project.projectType.others.platformType;
          initialValues.platformType = tempPlatform;
          initialValues.typeOfProject=tempProps.editDetails.project.projectType.others.typeOfProject;
          break;

      case "mobile":
        initialValues.appType =
          tempProps.editDetails.project.projectType.mobile.appType;
        tempPlatform = tempProps.editDetails.project.projectType.mobile.platform;
        initialValues.platformType = tempPlatform.platformType;

        initialValues.appPackage = tempPlatform.appPackage;
        initialValues.appActivity = tempPlatform.appActivity;
        initialValues.bundleID = tempPlatform.bundleID;
        if (
          initialValues.platformType === "Android" ||
          initialValues.platformType === "Android & iOS"
        ) {
          initialValues.apk = {
            name:
              tempPlatform.uploadApk !== null
                ? tempPlatform.uploadApk.slice(
                  tempPlatform.uploadApk.lastIndexOf("/") + 1,
                  tempPlatform.uploadApk.length
                )
                : "",
          };
          initialValues.apkFile = tempPlatform.uploadApk;
        }
        if (
          initialValues.platformType === "iOS" ||
          initialValues.platformType === "Android & iOS"
        ) {
          initialValues.ipa = {
            name:
              tempPlatform.uploadIPA !== null
                ? tempPlatform.uploadIPA.slice(
                  tempPlatform.uploadIPA.lastIndexOf("/") + 1,
                  tempPlatform.uploadIPA.length
                )
                : "",
          };
          initialValues.ipaFile = tempPlatform.uploadIPA;
        }

        break;
      case "webAndMobile":
        tempPlatform = tempProps.editDetails.project.projectType.webAndMobile;
        initialValues.webUrl = tempPlatform.url;
        initialValues.appType = tempPlatform.appType;
        initialValues.platformType = tempPlatform.platform;
        initialValues.appPackage = tempPlatform.appPackage;
        initialValues.appActivity = tempPlatform.appActivity;
        initialValues.bundleID = tempPlatform.bundleId;
        if (
          initialValues.platformType === "Android" ||
          initialValues.platformType === "Android & iOS"
        ) {
          initialValues.apk = {
            name:
              tempPlatform.uploadApk !== null
                ? tempPlatform.uploadApk.slice(
                  tempPlatform.uploadApk.lastIndexOf("/") + 1,
                  tempPlatform.uploadApk.length
                )
                : "",
          };
          initialValues.apkFile = tempPlatform.uploadApk;
        }
        if (
          initialValues.platformType === "iOS" ||
          initialValues.platformType === "Android & iOS"
        ) {
          initialValues.ipa = {
            name:
              tempPlatform.uploadIPA !== null
                ? tempPlatform.uploadIPA.slice(
                  tempPlatform.uploadIPA.lastIndexOf("/") + 1,
                  tempPlatform.uploadIPA.length
                )
                : "",
          };
          initialValues.ipaFile = tempPlatform.uploadIPA;
        }
        break;
      case "webservice":
        tempPlatform = tempProps.editDetails.project.projectType.webservice;
        initialValues.baseURI = tempPlatform.baseURI;
        initialValues.port = tempPlatform.port;
        break;
      default:
        break;
    }
  } else {
    initialValues = {
      projectName: "",
      projectDesc: null,
      duration: "",
      startDate: "",
      testingTypes: [],
      numberOfTesters: "",
      projectType: "web",
      webUrl: null,
      appType: "",
      platformType: "",
      appPackage: null,
      appActivity: null,
      bundleID: null,
      port: "",
      baseURI: "",
      instructions: null,
      typeOfProject:"",
    };
  }
  if (props?.editDetails?.executionDetails?.attachments !== null) {
    let tempArray = [];
    for (
      let i = 0;
      i < props?.editDetails?.executionDetails?.attachments.length;
      i++
    ) {
      let tempAttachemnt = props?.editDetails?.executionDetails?.attachments[i];
      let tempObj = {
        name: tempAttachemnt,
      };
      tempArray.push(tempObj);
    }
    if (uiSupportDocsArray.length === 0 && deleteFilesArray.length === 0) {
      uiSupportDocsArray = tempArray;
      attachmentsArray = tempArray;
    }
  } else {
    uiSupportDocsArray = [];
  }
  const supportDocsFileNameHandler = (fileName) => {
    if (fileName !== undefined)
      return fileName.slice(fileName.lastIndexOf("/") + 1, fileName.length);
    else return "";
  };
  const deleteApk = () => {
    if (props?.editDetails.project.projectType.mobile !== null) {
      if (
        props?.editDetails.project.projectType.mobile.platform.uploadApk !== null
      ) {
        deleteFilesArray.push(
          props?.editDetails.project.projectType.mobile.platform.uploadApk
        );
      }
    } else {
      if (
        props?.editDetails.project.projectType.webAndMobile.uploadApk !== null
      ) {
        deleteFilesArray.push(
          props?.editDetails.project.projectType.webAndMobile.uploadApk
        );
      }
    }
    setApkFile(null);
  };
  const deleteIpa = () => {
    if (props?.editDetails.project.projectType.mobile !== null) {
      if (
        props?.editDetails.project.projectType.mobile.platform.uploadIPA !== null
      ) {
        deleteFilesArray.push(
          props?.editDetails.project.projectType.mobile.platform.uploadIPA
        );
      }
    } else {
      if (
        props?.editDetails.project.projectType.webAndMobile.uploadIPA !== null
      ) {
        deleteFilesArray.push(
          props?.editDetails.project.projectType.webAndMobile.uploadIPA
        );
      }
    }
    setIpaFile(null);
  };
  const [typeOfprojects,setTypeOfprojetcs]=useState(initialValues.typeOfProject)
  const [projectType, setProjectType] = useState(initialValues.projectType);
  const [appType, setAppType] = useState(initialValues.appType);
  const [platformType, setPlatformType] = useState(initialValues.platformType);
  const [selectedApkFile, setSelectedApkFile] = useState(
    initialValues.apk ? initialValues.apk : null
  );
  const [selectedIpaFile, setSelectedIpaFile] = useState(
    initialValues.ipa ? initialValues.ipa : null
  );
  const [apkFile, setApkFile] = useState(
    initialValues.apkFile ? initialValues.apkFile : null
  );
  const [ipaFile, setIpaFile] = useState(
    initialValues.ipaFile ? initialValues.ipaFile : null
  );
  const [supportDocsArray, setSupportDocsArray] = useState(uiSupportDocsArray);
  const [testingType, setTestingType] = useState(initialValues.testingTypes);

  const [editPerforms, setEditPerforms] = useState(false);
  const onSubmit = (values) => {
    console.log("form values", values);
    // setIsloading(false);
    if(props?.editDetails){
      if (values.projectStatus === "APPROVED" && (props?.editDetails?.domainName === null && projectdomain.length === 0)) {
        console.log("projet status is approbved")
        setUpadteDomainName(true)
      }
      if (values.projectStatus === "REJECTED" && (props?.editDetails?.domainName === null && projectdomain.length === 0)) {
        setUpadteDomainName(true)
      }
  
    }
   
  
    const project = values;
    
    let projectObj = {
      createdBy:JSON.parse(localStorage.getItem("fire-crowd-user")).userName,
      duration: parseInt(values.duration),
      startDate: values.startDate,
      testingTypes: values.testingTypes,
      numberOfTesters: parseInt(values.numberOfTesters),
      eventEndDate:projectstatus[0]?.value==="COMPLETED"?props?.editDetails?.eventEndDate :null,
      executionDetails: {
        guideType: executionType,
        uploadTestCaseByTestersAllowed: [
          "GUIDED_ALLOW",
          "UNGUIDED_ALLOW",
        ].includes(executionTypeAllow)
          ? true
          : false,
        uploadTestCaseByCustomerAllowed:
          executionType === "GUIDED" ? true : false,
        testersAllowedToExplore: executionType === "GUIDED" ? false : true,
        instructions: values.instructions,
        attachments: props?.editDetails
          ? props?.editDetails.executionDetails.attachments
          : [],
      },
      project: {
        projectName: values.projectName,
        projectDesc: values.projectDesc,
        projectType: {},
      },
    };

    switch (projectType) {
      case "web":
        projectObj.project.projectType.web = {
          weburl: values.webUrl?.trim()?.length===0?null:values.webUrl,
        };
        break;
      case"others":
      projectObj.project.projectType.others = {
        typeOfProject:values.typeOfProject,

        weburl: values.typeOfProject==="Standalone_Application"?"":values.webUrl,
        appType: values.appType,
        platformType: values.platformType,
        
      };
      break;
      case "mobile":
        projectObj.project.projectType.mobile = {
          appType: values.appType,
          platform: {
            platformType: values.platformType,
          },
        };
        if (values.platformType === "Android" || values.platformType === "Android & iOS") {
          values.appPackage !== undefined &&
            values.appPackage !== "" &&
            (projectObj.project.projectType.mobile.platform.appPackage =
              values.appPackage);
          values.appActivity !== undefined &&
            values.appActivity !== "" &&
            (projectObj.project.projectType.mobile.platform.appActivity =
              values.appActivity);
        }
        if (values.platformType === "iOS" || values.platformType === "Android & iOS") {
          values.bundleID !== undefined &&
            values.bundleID !== "" &&
            (projectObj.project.projectType.mobile.platform.bundleID =
              values.bundleID);
        }

        projectObj.project.projectType.mobile.platform.uploadApk = apkFile;
        projectObj.project.projectType.mobile.platform.uploadIPA = ipaFile;
        break;
      case "webAndMobile":
        projectObj.project.projectType.webAndMobile = {
          url: values.webUrl,
          appType: values.appType,
          platform: values.platformType,
        };
      
            projectObj.project.projectType.webAndMobile.appPackage =
            values.appPackage !== undefined && values.appPackage !== null
              ? values.appPackage
              : null;
              projectObj.project.projectType.webAndMobile.appActivity =
          values.appActivity !== undefined && values.appActivity !== null
            ? values.appActivity
            : null;

        projectObj.project.projectType.webAndMobile.bundleId =
          values.bundleID !== undefined && values.bundleID !== null
            ? values.bundleID
            : null;
        projectObj.project.projectType.webAndMobile.uploadApk = apkFile;
        projectObj.project.projectType.webAndMobile.uploadIPA = ipaFile;
        break;
      case "webservice":
        projectObj.project.projectType.webservice = {
          port: values.port,
          baseURI: values.baseURI,
        };
        break;
      default:
        break;
    }
    if (props?.editDetails) {
      projectObj.id = props.editDetails.id;
      projectObj.createdBy = props.editDetails?.createdBy;
      projectObj.createdOn = props.editDetails?.createdOn;
      projectObj.project.defaultDefectLifecycleTemplateId =
        props?.editDetails?.project?.defaultDefectLifecycleTemplateId;
      projectObj.project.defaultDefectTemplateId =
        props?.editDetails?.project?.defaultDefectTemplateId;

      projectObj.projectStatus = projectstatus[0]?.value;
      projectObj.deleteFiles = deleteFilesArray;
      projectObj.usersHierarchyTemplateID =
        props?.editDetails?.usersHierarchyTemplateID;
      projectObj.project.users = {
        userList: props?.editDetails?.project?.users?.userList,
      };
      projectObj.licenceID = props.editDetails?.licenceID;
      projectObj.project.fireFlinkProjectId =
        props.editDetails.project?.fireFlinkProjectId;
      if (projectstatus[0]?.value !== "INREVIEW") {

        projectObj.domainName = projectdomain[0].value;


      } else {
        projectObj.domainName = props.editDetails?.domainName;
      }
      if (projectstatus[0]?.value === "CLOSED") {
        projectObj.defectExecutiveReport = executiveReport;
        projectObj.certificateValidations=certificateValidation;
      } else {
        projectObj.defectExecutiveReport =
          props.editDetails?.defectExecutiveReport;
          projectObj.certificateValidations =
          props.editDetails?.certificateValidations;
      }
    } else {
      if (
        localStorage.hasOwnProperty("my-role") &&
        JSON.parse(localStorage["my-role"]) !== "admin"
      ) {
        projectObj.project.users = {
          userList: [
            {
              name: JSON.parse(localStorage.getItem("fire-crowd-user")).name,
              email: JSON.parse(localStorage.getItem("fire-crowd-user"))
                .userName,
              role:
                localStorage.hasOwnProperty("my-role") &&
                  JSON.parse(localStorage["my-role"]) === "admin"
                  ? "ADMIN"
                  : "PROJECT_OWNER",
              lead: "none",
              phoneNumbers: {
                primaryPhone: props?.userPhoneNumber,
              },
            },
          ],
        };
      }
    }
    console.log("uploadedTestCases",uploadedTestCases)
    const formData = new FormData();
    formData.append("project", JSON.stringify(projectObj));
    formData.append("testCases", uploadedTestCases);
    formData.append("apk", selectedApkFile);
    formData.append("ipa", selectedIpaFile);
    formData.append(
      "createdBy",
      JSON.parse(localStorage.getItem("fire-crowd-user")).userName
    );
    supportDocsArray.forEach((fileDoc) =>
      formData.append("attachments", fileDoc)
    );

    if (props?.editDetails) {
      console.log("edit project",projectObj.projectStatus)
      if (startDatechange === false && updateDomainName === false && projectNameExist===false  && execleFile===null) {
        console.log("edit project")
        if (
          JSON.stringify(initialValues || initialValues.apk) ==
            JSON.stringify(project) &&
          showAndroidBtn === true &&
          showIPABtn === true &&
          executionType ===
            props?.editDetails?.executionDetails?.guideType &&
          props?.editDetails?.executionDetails.testersAllowedToExplore ===
            projectObj.executionDetails.testersAllowedToExplore &&
          !editPerforms &&
          projectObj.domainName === props?.editDetails?.domainName &&
          projectObj.projectStatus===props?.editDetails?.projectStatus && uploadedTestCases=== props?.editDetails?.executionDetails?.testCases
        ) {
          props.MyAlert.info(
            `No changes were done to "${values.projectName} project" `
          );
          
          return null;
        }else{
         
          setIsloading(false);
          editProject(formData, "CUSTOMER")
            .then((response) => {
              
              if (response.status === 200) {
               
                  reloadPage();
                console.log("response", response);
                setIsloading(true);
                setOpenModal(false);
                props.closeModal(false);
                props.onProjectEdited(true);
  
                props.MyAlert.success(
                  `"${values.projectName}" project updated successfully`
                );
              } else if (response?.response.status === 400) {
                props.MyAlert.danger(response.response.data.errorMessage);
                setIsloading(true);
              } else {
                 reloadPage();
                setIsloading(true);
                props.onProjectEdited(false);
              }
              clearDocArray();
            })
            .catch((error) => {
              console.log("error", error);
            });

        }
       
      }
      else {


         setIsloading(true);
     
        setOpenModal(true);
        

      }
    } else {
     
      if(projectNameExist===false && execleFile===null){
        setIsloading(false);
        createProject(formData, "CUSTOMER")
          .then((response) => {
            if (response.status === 200) {
              reloadPage();
              setIsloading(true);
              setOpenModal(false);
              props.closeModal(false);
              props.onProjectCreated(true);
              props.MyAlert.success(
                `"${values.projectName}" project created successfully`
              );
            } else if (response?.response.status === 400) {
               reloadPage();
              props.closeModal(false);
              props.onProjectCreated(false);
              props.MyAlert.danger(
                `"${values.projectName}" project name is already exist`
              );
            } else {
               reloadPage();
              setIsloading(true);
              props.onProjectCreated(false);
            }
            clearDocArray();
          })
          .catch((error) => {
            console.log("error");
          });
      }
     
    }
  };
  const formikDetails = useFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema,
  });
  const setProjectTypeVal = (pType) => {
    formikDetails.setFieldValue("projectType", pType);
    setProjectType(pType);
    setAppTypeVal("");
    setPlatformTypeVal("");
    formikDetails.setFieldValue("webUrl","");
    // formikDetails.setFieldValue("typeOfProject","")
     setTypeOfprojetcs("")

    clearMobileDetails();
  };
  const setAppTypeVal = (aType) => {
    formikDetails.setFieldValue("appType", aType);
    setAppType(aType);
  };
  const setPlatformTypeVal = (pType) => {
    formikDetails.setFieldValue("platformType", pType);
    setPlatformType(pType);
    clearMobileDetails();
  };
  const setTestingTypeValue = (e, tType) => {
    let tempArray = testingType;

    setEditPerforms(true);
    if (tType.checked) {
      tempArray.push(tType.value);
      console.log(tType.value);
    } else {
      let tempIndex = "";
      tempArray.map((obj, i) => {
        if (obj === tType.value) {
          tempIndex = i;
        }
      });
      tempArray.splice(tempIndex, 1);
    }
    setTestingType(tempArray);
    console.log("tesing value ", tempArray);
    formikDetails.values.testingTypes = tempArray;
    formikDetails.handleChange(e);
  };
  const customStylesPage = {
    overlay: {
      background: "rgba(90, 90, 90,0.5)",
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "80%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      height: "100%",
      background: "#fbfcfd 0% 0% no-repeat padding-box",
      boxShadow: "0px 0px 11px #000000a1",
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
      opacity: "1",
      outline: "none",
      index: "9999",
    },
  };
  const fileHandler = (event) => {
    console.log("fileHandler",event)
    
    let fileObj = event.target.files[0];
    fileName = fileObj.name;
    console.log("fileHandler123",fileName.match(/\.([^\.]+)$/)[1]);
    // setExecleFile
  
    if(fileName.indexOf("xlsx") > -1 ||fileName.indexOf("xls") > -1){
      setExecleFile(null)
      setTestcaseFile(fileName)
      setUploadTestCases(fileObj);
      setUploadFile(false);
    }else{
      setExecleFile('Please upload an "Excel" file')
    }
   
  };
  const fileUploaded = (event) => {
    console.log("fileUploaded",event)
    setExecleFile(null)
    setUploadFile(true);
    setTestcaseFile(null)
    setUploadTestCases(null);

  };
  const attachmentHandler = (event, attach) => {
    
    setEditPerforms(true);
    console.log("supportDocsArray1: ", supportDocsArray);
    console.log("uiSupportDocsArray1: ", uiSupportDocsArray);
    if (attach) {
      for (let i = 0; i < event.target.files.length; i++) {
        uiSupportDocsArray.push(event.target.files[i]);
      }
      setSupportDocsArray(uiSupportDocsArray);
    } else {
      let filterIndex = "";
      uiSupportDocsArray.map((obj, i) => {
        if (
          obj.name === event.target.files[0].name ||
          supportDocsFileNameHandler(obj.name) === event.target.files[0].name
        ) {
          filterIndex = i;
        }
      });
      attachmentsArray.map((obj, i) => {
        if (obj.name === event.target.files[0].name) {
          filterIndex = i;
        }
      });
      deleteFilesArray.push(uiSupportDocsArray[filterIndex].name);
      uiSupportDocsArray.splice(filterIndex, 1);
    }
    setDocsChanged(!docsChanged);
    console.log("supportDocsArray2: ", supportDocsArray);
    console.log("uiSupportDocsArray2: ", uiSupportDocsArray);
  };
  const changeHandler = (e) => {
    console.log("changeHandler == " + e.target);
    setSelectedApkFile(e.target.files[0]);
    setShowAndroidBtn(true);
  };
  const onHandleChange = (e) => {
    console.log("onHandleChange == " + e.target.files[0]);

    setSelectedIpaFile(e.target.files[0]);
    console.log("end onHandleChange == ");
    setShowIPAbtn(true);
  };
  const changeHandleripa = (e) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      if (
        props?.editDetails &&
        supportDocsFileNameHandler(initialValues?.ipa?.name) !== filename
      ) {
        deleteIpa();
      }
      let ext = filename.match(/\.([^\.]+)$/)[1];
      if (ext.indexOf("ipa") > -1) {
        setPara(null);
      } else if (typeof filename === "undefined") {
        setPara('Please upload an "Ipa" file');
        setSelectedIpaFile("");
        setUploadedIpaFile("");
      } else {
        setPara('Please upload an "Ipa" file');
        setSelectedIpaFile("");
        setUploadedIpaFile("");
      }
    }
    if (e.target.files.length === 0) {
      setSelectedIpaFile(selectedIpaFile);
    }
  };
  const changeHandlerapk = (e) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      if (
        props?.editDetails &&
        supportDocsFileNameHandler(initialValues?.apk?.name) !== filename
      ) {
        deleteApk();
      }
      let ext = filename.match(/\.([^\.]+)$/)[1];
      if (ext.indexOf("apk") > -1) {
        setPara2(null);
      } else if (typeof filename === "undefined") {
        setPara2('Please upload an "Apk" file');
        setUploadedApkFile("");
        setSelectedApkFile("");
      } else {
        setUploadedApkFile("");
        setSelectedApkFile("");
        setPara2('Please upload an "Apk" file');
      }
    }
    if (e.target.files.length === 0) {
      setSelectedApkFile(selectedApkFile);
    }
  };
  const handleStartDate = (date, e) => {
  


    if (props?.editDetails) {
      let changeddate = convertToAPIDate(date);
      let today = convertToAPIDate(new Date());
      if (changeddate !== today && projectstatus[0].value === "INPROGRESS") {
        setStartDateChange(true);
      } else if (changeddate === today && projectstatus[0].value === "INPROGRESS") {

        formikDetails.values.startDate = convertToAPIDate(date);
        setStartDateChange(false);
      } else {
        formikDetails.values.startDate = convertToAPIDate(date);
      }
    } else {
      formikDetails.values.startDate = convertToAPIDate(date);
    }
    formikDetails.setFieldError("startDate", "")

  };
  const clearDocArray = () => {
    console.log("clearDocArray", projectdomain[0]);
    uiSupportDocsArray = [];
  };
  const reloadPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  const emptyFile = (type) => {
    if (type === "Android") {
      setSelectedIpaFile();
    } else if (type === "iOS") {
      setSelectedApkFile();
    } else if (type === "Android & iOS") {
      //  need further
    } else {
      return null;
    }
  };
  const clearMobileDetails = () => {
    setSelectedApkFile();
    setSelectedIpaFile();
    
    formikDetails.setFieldValue("appPackage", null);
    formikDetails.setFieldValue("appActivity", null);
    formikDetails.setFieldValue("bundleID", null);
    formikDetails.setFieldValue("port", null);
    formikDetails.setFieldValue("baseURI", null);
  };
  function updateProjectStatus() {




    setStatusWarning(false);



    formikDetails.setFieldValue("projectStatus", updateStatus[0]?.value);
    setProjectStatus(updateStatus);
  

    if (updateStatus[0].value === "APPROVED" && (props?.editDetails?.domainName === null && projectdomain.length === 0)) {
      console.log("projet status is approbved")
      setUpadteDomainName(true)
    }
    if (updateStatus[0].value === "REJECTED" && (props?.editDetails?.domainName === null && projectdomain.length === 0)) {
      setUpadteDomainName(true)
    }

    if (updateStatus[0].value === "INPROGRESS") {
      console.log(
        "formikDetails.values.startDate",
        formikDetails.values.startDate
      );
      let date = convertToAPIDate(new Date());
      let formikdate = convertToAPIDate(formikDetails.values.startDate);
      if (props?.editDetails.startDate !== date || formikdate !== date) {
        setStartDateChange(true);
      }

    }

    if (updateStatus[0].value === "CLOSED") {
      if (myRole === "ADMIN" || myRole === "ACCOUNT_MANAGER") {
        setAddExecutiveReport(true);
        console.log("helllo myrole", myRole);
      }
    }
  }
  function handleProjectStatus(data) {
    let hasowner;

    if (data[0]?.value === "APPROVED") {
     
      props?.userList?.map((user) => {
        if (user.role === "PROJECT_OWNER") {
          hasowner = true;
        }
      });
      if (hasowner === true) {
        setStatusWarning(true);
        setUpdateStatus([...data]);
      } else {
        props.MyAlert.info(
          `Add project_owner role to "${props?.editDetails?.project?.projectName}" project  to change the project status to APPROVED`
        );
        setProjectStatus([
          {
            label: props?.editDetails?.projectStatus,
            value: props?.editDetails?.projectStatus,
          },
        ]);
      }
    } else {
      setStatusWarning(true);
      setUpdateStatus([...data]);
    }
  }
  function handleProjectDomain(data) {
    setProjectDomain([
      {
        label: data[0].label,
        value: data[0].value,
      },
    ]);
    if (projectstatus[0].value === "APPROVED" && data.length === 1) {
      setUpadteDomainName(false)
    } else {
      if (projectstatus[0].value === "REJECTED" && data.length === 1) {
        setUpadteDomainName(false)
      }
    }
  }

  function onCloseWarningPopup() {
    setStatusWarning(false);
    setProjectStatus([
      {
        label: props?.editDetails?.projectStatus,
        value: props?.editDetails?.projectStatus,
      },
    ]);
  }

  function onCloseExecutiveReportPopUp() {
    setAddExecutiveReport(false);
    setProjectStatus([
      {
        label: props?.editDetails?.projectStatus,
        value: props?.editDetails?.projectStatus,
      },
    ]);
  }
  function updateExecutiveReport(value,certificateValue) {
    console.log("executive report value", value,certificateValue);
    setCertificateValidation(certificateValue)
    setExecutiveReport(value);
    setAddExecutiveReport(false);
    props.MyAlert.success("Executive report and certificate validtaion added sucessfully");
  }

  function disableScroll() {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }

  return (
    <Modal isOpen={openModal} style={customStylesPage} className="p-px">
      <div className="flex justify-between create-modal-heading rounded-tl-xl p-2">
        <div>{props.header}</div>
        <div>
          <span className="modal-close-icon">
            <button
              onClick={() => {
                setOpenModal(false);
                props.closeModal(false);
                clearDocArray();
              }}
              type="button"
              className="modal-close-btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </span>
        </div>
      </div>
      <form onSubmit={formikDetails.handleSubmit}>
        <div className="createProject-main-content">
          <div>
            {localStorage.hasOwnProperty("user-privilege") &&
              (JSON.parse(localStorage["user-privilege"]).role === "ADMIN" ||
                JSON.parse(localStorage["user-privilege"]).role ===
                "ACCOUNT_MANAGER") ? (
              <div className="step-heading p-2 flex grid grid-cols-10">
                <div className="col-span-5">{stepOneHeading}</div>
                {props.header === "Edit Project" ? (
                  <div className="flex col-span-5 justify-end ">
                    <label className="create_status_label mr-1">Status </label>
                    <div
                      className="flex justify-end "
                      id="projectstatus_dropdown"
                    >
                      <SelectDropdown
                        data={status}
                        selectedData={projectstatus}
                        onChange={handleProjectStatus}
                        searchable={false}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="step-heading p-2">
                <div className="col-span-5">{stepOneHeading}</div>
              </div>
            )}

            <div className="px-2">
              <div>
                <div className="my-5">
                  <div>
                    <span className="text-red-600">*</span>
                    <span className="create-labels">{nameLabel}</span>
                  </div>
                  <div className="mt-0.5">
                    <TextField
                      error={
                        formikDetails.errors.projectName &&
                        formikDetails.touched.projectName
                      }
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onClick={() => {
                        console.log(props, props.editDetails);
                      }}
                      type="text"
                      name="projectName"
                      id="projectName"
                      autoComplete="off"
                      placeholder={namePlaceHolder}
                      className="create-project-values"
                      // disabled={props?.data ? true : false}
                      disabled={
                        props?.editDetails?.projectStatus === "INPROGRESS" ||
                          props?.editDetails?.projectStatus === "APPROVED" || !isLoading ||props?.editDetails?.projectStatus === "CLOSED"
                          ? true
                          : false
                      }
                      // onBlur={formikDetails.handleBlur}
                      onBlur={(event) => {
                        formikDetails.handleBlur(event);
                        duplicatesCheck(formikDetails.values.projectName)
                        
                      }}
                      onChange={formikDetails.handleChange}
                      value={formikDetails.values.projectName}
                      variant="outlined"
                      size="small"
                    />
                  </div>
                  {formikDetails.errors.projectName &&
                    formikDetails.touched.projectName ? (
                    <div className="text-red-500 errortext md:mt-1">
                      {formikDetails.errors.projectName}
                    </div>
                  ) : null}
                  {
                    projectNameExist?
                    (
                      <div className="text-red-500 errortext md:mt-1">
                      Project Name is already exist
                    </div>
                    )
                   
                    :null
                  }
                </div>
                {localStorage.hasOwnProperty("user-privilege") &&
                  (JSON.parse(localStorage["user-privilege"]).role === "ADMIN" ||
                    JSON.parse(localStorage["user-privilege"]).role ===
                    "ACCOUNT_MANAGER") &&
                  props.header === "Edit Project" &&
                  projectstatus[0].value !== "INREVIEW" ? (
                  <div className="my-5">
                    <div>
                      <span className="text-red-600">*</span>
                      <span className="create-labels">{domainLabel}</span>
                    </div>
                    <div className="mt-0.5">
                      <SelectDropdown
                        data={domainList.map((option) => ({
                          ...option,
                          label: option,
                          value: option,
                        }))}
                        searchable={false}
                        selectedData={projectdomain}
                        onChange={handleProjectDomain}
                        disabled={props?.editDetails?.projectStatus === "CLOSED"?true:false}
                      />
                    </div>

                    {
                      updateDomainName ? (
                        <div className="text-red-500 errortext md:mt-1">
                          This field is required
                        </div>
                      ) : null
                    }

                  </div>
                ) : (
                  ""
                )}

                <div className="my-5">
                  <div>
                    <span className="create-labels">{descriptionLabel}</span>
                  </div>
                  <div className="mt-0.5">
                  

                    <TextareaAutosize
                      name="projectDesc"
                      id="projectDesc"
                      value={formikDetails.values.projectDesc}
                      placeholder={descriptionPlaceHolder}
                      onChange={formikDetails.handleChange}
                      style={{ height: "80px", resize: "none", overflow: "scroll" }}
                      onKeyUp={(e) => setdescCount(e.target.value.length)}
                      onBlur={formikDetails.handleBlur}
                      maxLength="200"
                      className="project-description"
                      disabled={!isLoading || props?.editDetails?.projectStatus === "CLOSED"}
                    ></TextareaAutosize>
                    <div className="mt-1 mr-1 text-sm text-gray-500 text-right">
                      {descCount}/200
                    </div>
                  </div>
                </div>
                <div className="">
                  <div>
                    <span className="text-red-600">*</span>
                    <span className="create-labels">{eventStartDateLabel}</span>
                  </div>
                  <div className="startdate mt-0.5">
                    <TYDateTimePicker
                      editDate={props?.editDetails?.startDate}
                      parentProp={props}
                      name="startDate"
                      dateId="rangeFromDate"
                      minDate={true}
                      setDateRange={handleStartDate}
                      selectedDate={formikDetails.values.startDate}
                      formikError={
                        !formikDetails.values.startDate &&
                        formikDetails.errors.startDate &&
                        formikDetails.touched.startDate
                      }
                      loading={isLoading}
                    />
                  </div>
                  {formikDetails.errors.startDate &&
                    formikDetails.touched.startDate ? (
                    <div className="text-red-500 errortext md:mt-1">
                      {formikDetails.errors.startDate}
                    </div>
                  ) : null}
                  {startDatechange ? (
                    <div className="text-red-500 errortext md:mt-1">
                      {" "}
                      start date should match with today's date
                    </div>
                  ) : null}
                </div>
                <div className="my-5">
                  <div>
                    <span className="text-red-600">*</span>
                    <span className="create-labels">{eventDurationLabel}</span>
                  </div>
                  <div className="mt-0.5">
                    <TextField

                      error={
                        formikDetails.errors.duration &&
                        formikDetails.touched.duration
                      }
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="number"
                      name="duration"
                      id="duration"
                      autoComplete="off"
                      onFocus={disableScroll()}
                      placeholder={eventDurationPlaceHolder}
                      disabled={props?.data || !isLoading || props?.editDetails?.projectStatus === "CLOSED"? true : false}
                      onBlur={formikDetails.handleBlur}
                      onChange={formikDetails.handleChange}
                      value={formikDetails.values.duration}
                      inputProps={{ min: 0 }}
                      onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()}
                      variant="outlined"
                      size="small"
                    />
                  </div>
                  {formikDetails.errors.duration &&
                    formikDetails.touched.duration ? (
                    <div className="text-red-500 errortext md:mt-1">
                      {formikDetails.errors.duration}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="step-heading p-2">
              <span>{stepTwoHeading}</span>
            </div>
            <div className="p-2">
              <div className="my-5">
                <div>
                  <span className="text-red-600">*</span>
                  <span className="create-labels">{noOfTestersLabel}</span>
                </div>
                <div className="mt-0.5">
                  <TextField
                    error={
                      formikDetails.errors.numberOfTesters &&
                      formikDetails.touched.numberOfTesters
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="number"
                    name="numberOfTesters"
                    id="numberOfTesters"
                    autoComplete="off"
                    onFocus={disableScroll()}
                    placeholder={noOfTestersPlaceHolder}
                    disabled={props?.data || !isLoading ||props?.editDetails?.projectStatus === "CLOSED" ? true : false}
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails.values.numberOfTesters}
                    inputProps={{ min: 0 }}
                    onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()}
                    variant="outlined"
                    size="small"
                  />
                </div>
                {formikDetails.errors.numberOfTesters &&
                  formikDetails.touched.numberOfTesters ? (
                  <div className="text-red-500 errortext md:mt-1">
                    {formikDetails.errors.numberOfTesters}
                  </div>
                ) : null}
              </div>
              <div className="my-5">
                <div>
                  <span className="text-red-600">*</span>
                  <span className="create-labels">{executionTypeLabel}</span>
                </div>
                <div className="p-2">
                  <div className="flex">
                    <div className="mr-3">
                      <input
                        type="radio"
                        checked={executionType === "GUIDED"}
                        name="GUIDED"
                        value="GUIDED"
                        onChange={(e) => {
                          setExecutionType(e.target.value);
                          setExecutionTypeAllow("")
                        }}
                        disabled={
                          props?.editDetails?.projectStatus === "INPROGRESS" ||
                            props?.editDetails?.projectStatus === "APPROVED" ||!isLoading||props?.editDetails?.projectStatus === "CLOSED"
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div>
                      <span className="create-project-values">{guidedTesting}</span>
                      <span className=" create-project-values">
                        {guidedTestingMenuDetail}
                      </span>
                    </div>
                  </div>
                  <div className="pl-5 pt-3">
                    {uploadFile ? (
                      <Button
                        size="small"
                        variant="outlined"
                        component="label"
                        className="w-full"
                        endIcon={<FileUploadOutlinedIcon />}
                        disabled={executionType === "UNGUIDED" || !isLoading || props?.editDetails?.projectStatus === "CLOSED" ? true : false}
                      >
                        <div className="upload_btn_text">
                          {uploadButtonText}
                        </div>
                        <input
                          name="uploadButton"
                          id="uploadButton"
                          accept=".xlsx,.xls"
                          type="file"
                          disabled={!isLoading || props?.editDetails?.projectStatus === "CLOSED"}
                          hidden
                          onChange={(e) => {
                            fileHandler(e);
                          }}
                        />
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        variant="outlined"
                        component="label"
                        className="w-full"
                        endIcon={<DeleteForeverIcon />}
                        onClick={(e) => {
                          fileUploaded(e);
                        }}
                      >
                        <div className="upload_btn_text">{testcaseFile}</div>
                        {/* <div className="upload_btn_text">{supportDocsFileNameHandler(testcaseFile)}</div> */}
                      </Button>
                    )}
                  </div>
                  <div className="flex pl-5 pt-3">
                    <div className="mr-3">
                      <input
                        type="checkbox"
                        checked={executionTypeAllow === "GUIDED_ALLOW"?executionType !== "UNGUIDED"?true:false:false}
                        name="GUIDED_ALLOW"
                        value="GUIDED_ALLOW"
                        
                        onChange={(e) => {
                          if (e.target.checked) {
                            setExecutionTypeAllow(e.target.value);
                            setEditPerforms(true);

                          } else {
                            setExecutionTypeAllow(""); setEditPerforms(true);
                          }
                        }}
                        disabled={executionType === "UNGUIDED"  ||  props?.editDetails?.projectStatus === "APPROVED" ||
                        props?.editDetails?.projectStatus === "INPROGRESS" || !isLoading || props?.editDetails?.projectStatus === "CLOSED" ? true : false}
                      />
                    </div>
                    <div>
                      <span className="create-project-values" >
                        {guidedTestingSubMenu}
                      </span>
                    </div>
                  </div>
                  {execleFile && (
                            <div className="flex mt-3 alertDanger_icon">
                              <DangerAlert />
                              <p
                                id="para2  "
                                style={{
                                  color: "black",
                                  fontSize: "12px",
                                  fontFamily: "Poppins-Regular",
                                  marginTop: "2px",
                                  marginLeft: "5px",
                                }}
                              >
                                {execleFile}
                              </p>
                            </div>
                          )}
                </div>
              </div>
              <div className="p-2">
                <div className="flex">
                  <div className="mr-3">
                    <input
                      type="radio"
                      checked={executionType === "UNGUIDED"}
                      name="UNGUIDED"
                      value="UNGUIDED"
                      onChange={(e) => {
                        setExecutionType(e.target.value);
                        fileUploaded(e);
                        setUploadTestCases("");
                         setExecutionTypeAllow("")
                      }}
                      disabled={
                        props?.editDetails?.projectStatus === "INPROGRESS" ||
                          props?.editDetails?.projectStatus === "APPROVED" || !isLoading || props?.editDetails?.projectStatus === "CLOSED"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div>
                    <span className="create-project-values">{unguidedTesting}</span>
                    <span className="create-project-values">
                      {unguidedTestingMenuDetail}
                    </span>
                  </div>
                </div>
                <div className="flex p-5">
                  <div className="mr-3">
                    <input
                      type="checkbox"
                      checked={executionTypeAllow === "UNGUIDED_ALLOW"?executionType !== "GUIDED"?true:false:false}
                      name="UNGUIDED_ALLOW"
                      value="UNGUIDED_ALLOW"
                      
                      onChange={(e) => {
                        if (e.target.checked) {
                          setExecutionTypeAllow(e.target.value); setEditPerforms(true);
                        } else {
                          setExecutionTypeAllow("");
                          setEditPerforms(true);
                        }
                      }}
                      disabled={
                        executionType === "GUIDED" ||
                          props?.editDetails?.projectStatus === "APPROVED" ||
                          props?.editDetails?.projectStatus === "INPROGRESS" || !isLoading || props?.editDetails?.projectStatus === "CLOSED"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div>
                    <span className="create-project-values" >
                      {unguidedTestingSubMenu}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2">
              <div>
                <span className="text-red-600">*</span>
                <span className="create-labels">{typeOfTesting}</span>
              </div>
              <div className="my-1 px-2">
                {testingTypeList.map((obj, index) => {
                  return (
                    <ProjectCheckboxItem
                      defaultCheck={
                        props?.editDetails?.testingTypes.indexOf(obj.value) > -1
                      }
                      data={obj}
                      testingType={testingType}
                      setTestingTypeValue={setTestingTypeValue}
                      loading={isLoading}
                      projectstatus={props?.editDetails?.projectStatus}
                    />
                  );
                })}
              </div>
              {formikDetails.errors.testingTypes &&
                formikDetails.touched.testingTypes ? (
                <div className="text-red-500 errortext md:mt-1">
                  {formikDetails.errors.testingTypes}
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <div className="step-heading p-2">
              <span>{stepThreeHeading}</span>
            </div>
            <div className="mt-5 p-2">
              <div>
                <span className="text-red-600">*</span>
                <span className="create-labels">{projectTypeLabel}</span>
              </div>
              <div className="flex justify-between my-1 p-2 ">
                {projectTypeList.map((obj, index) => {
                  return (
                    <ProjectRadioItem
                      data={obj}
                      projectType={projectType}
                      setProjectTypeValue={setProjectTypeVal}
                      editDetails={props?.editDetails}
                      loading={isLoading}
                    />
                  );
                })}
              </div>
              <div className="mt-3 p-2">
                {
                  (projectType === "others") && (
                    <div>
                   
                    <div className="mt-5">
                      <div>
                        <span className="text-red-600">*</span>
                        <span className="create-labels">{typeOfProjectLabel}</span>
                      </div>
                      <div className="flex grid grid-cols-12 mt-2 mb-2">
                        <div className="flex mr-2 gap-1 col-span-3">
                          <div>
                            <input
                              type="radio"
                              checked={typeOfprojects=== "Television"}
                              name="typeOfProject"
                              value="Television"
                              onChange={(e) => {
                                formikDetails.handleChange(e)
                                setTypeOfprojetcs(e.target.value)
                              }}
                              disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                                props?.editDetails?.projectStatus
                              )|| !isLoading}
                            />
                          </div>
                          <div className="create-project-values mt-0.5">{television}</div>
                        </div>
                        <div className="flex mr-2 gap-1 col-span-4">
                          <div>
                            <input
                              type="radio"
                              checked={typeOfprojects === "Smart_Watches"}
                              name="typeOfProject"
                              value="Smart_Watches"
                              onChange={(e) => {
                                formikDetails.handleChange(e)
                                setTypeOfprojetcs(e.target.value)
                              }}
                              disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                                props?.editDetails?.projectStatus
                              )|| !isLoading}
                            />
                          </div>
                          <div className="create-project-values mt-0.5">{smart_watches}</div>
                        </div>

                        <div className="flex mr-2 gap-1 col-span-4">
                          <div>
                            <input
                              type="radio"
                              checked={typeOfprojects === "Standalone_Application"}
                              name="typeOfProject"
                              value="Standalone_Application"
                              onChange={(e) => {
                                formikDetails.handleChange(e)
                                setTypeOfprojetcs(e.target.value)
                              }}
                              disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                                props?.editDetails?.projectStatus
                              )|| !isLoading}
                            />
                          </div>
                          <div className="create-project-values mt-0.5">{Standalone_Application}</div>
                        </div>
                        
                      </div>
                      {formikDetails.errors.typeOfProject &&
                      formikDetails.touched.typeOfProject ? (
                        <div className="text-red-500 errortext md:mt-1">
                          {formikDetails.errors.typeOfProject}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  )
                }
                {(projectType === "web" || projectType === "webAndMobile" || (projectType === "others" && typeOfprojects !== "Standalone_Application")) && (
                  <div className="">
                    <div>
                      <span className="create-labels">{webUrlLabel}</span>
                    </div>
                    <div className={projectType === "web" ? "mt-1" : "mb-1 mt-1"}>
                      <TextField
                        error={
                          formikDetails.errors.webUrl &&
                          formikDetails.touched.webUrl
                        }
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        name="webUrl"
                        id="webUrl"
                        autoComplete="off"
                        placeholder={webUrlPlaceHolder}
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                        value={formikDetails.values.webUrl}
                        disabled={
                          (props?.editDetails?.projectStatus === "APPROVED" || props?.editDetails?.projectStatus === "INPROGRESS"|| props?.editDetails?.projectStatus === "CLOSED" || !isLoading) ? true : false
                        }
                        variant="outlined"
                        size="small"
                      />
                    </div>
                    {formikDetails.errors.webUrl &&
                      formikDetails.touched.webUrl ? (
                      <div className="text-red-500 errortext md:mt-1">
                        {formikDetails.errors.webUrl}
                      </div>
                    ) : null}
                  </div>
                )}
                {(projectType === "mobile" ||
                  projectType === "webAndMobile" || projectType === "others") && (
                  <div>
                    <div>
                      <div>
                        <div>
                          <span className="text-red-600">*</span>
                          <span className="create-labels">{appTypeLabel}</span>
                        </div>
                        <div className="flex grid grid-cols-12 mt-2 mb-2">
                          <div className="flex mr-2 gap-1 col-span-3">
                            <div>
                              <input
                                type="radio"
                                checked={appType === "Native"}
                                name="appType"
                                value="Native"
                                onChange={(e) => {
                                  formikDetails.handleChange(e);
                                  setAppTypeVal(e.target.value);
                                }}
                                disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                                  props?.editDetails?.projectStatus
                                ) || !isLoading}
                              />
                            </div>
                            <div className="create-project-values mt-0.5">{nativeLabel}</div>
                          </div>
                          <div className="flex gap-1 col-span-4">
                            <div>
                              <input
                                type="radio"
                                checked={appType === "Hybrid"}
                                name="appType"
                                value="Hybrid"
                                onChange={(e) => {
                                  formikDetails.handleChange(e);
                                  setAppTypeVal(e.target.value);
                                }}
                                disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                                  props?.editDetails?.projectStatus
                                )|| !isLoading}
                              />
                            </div>
                            <div className="create-project-values mt-0.5">{hybridLabel}</div>
                          </div>
                        </div>
                        {(formikDetails.values.platformType &&
                          !formikDetails.values.appType) ||
                        (!formikDetails.values.platformType &&
                          formikDetails.errors.appType &&
                          formikDetails.touched.appType) ? (
                          <div className="text-red-500 errortext md:mt-1">
                            {formikDetails.errors.appType}
                          </div>
                        ) : null}
                        
                      </div>
                      <div className="mt-5">
                        <div>
                          <span className="text-red-600">*</span>
                          <span className="create-labels">{platformLabel}</span>
                        </div>
                        <div className="flex grid grid-cols-12 mt-2 mb-2">
                          <div className="flex mr-2 gap-1 col-span-3">
                            <div>
                              <input
                                type="radio"
                                checked={platformType === "Android"}
                                name="platformType"
                                value="Android"
                                onChange={(e) => {
                                  formikDetails.handleChange(e);
                                  setPlatformType(e.target.value);
                                  emptyFile(e.target.value);
                                }}
                                disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                                  props?.editDetails?.projectStatus
                                )|| !isLoading}
                              />
                            </div>
                            <div className="create-project-values mt-0.5">{androidLabel}</div>
                          </div>
                          <div className="flex mr-2 gap-1 col-span-3">
                            <div>
                              <input
                                type="radio"
                                checked={platformType === "iOS"}
                                name="platformType"
                                value="iOS"
                                onChange={(e) => {
                                  formikDetails.handleChange(e);
                                  setPlatformType(e.target.value);
                                  emptyFile(e.target.value);
                                }}
                                disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                                  props?.editDetails?.projectStatus
                                )|| !isLoading}
                              />
                            </div>
                            <div className="create-project-values mt-0.5">{iosLabel}</div>
                          </div>
                          <div className="flex gap-1 col-span-4">
                            <div>
                              <input
                                type="radio"
                                checked={platformType === "Android & iOS"}
                                name="platformType"
                                value="Android & iOS"
                                onChange={(e) => {
                                  formikDetails.handleChange(e);
                                  setPlatformType(e.target.value);
                                  emptyFile(e.target.value);
                                }}
                                disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                                  props?.editDetails?.projectStatus
                                )|| !isLoading}
                              />
                            </div>
                            <div className="create-project-values mt-0.5">
                              {androidIosLabel}
                            </div>
                          </div>
                        </div>
                        {formikDetails.errors.platformType &&
                        formikDetails.touched.platformType ? (
                          <div className="text-red-500 errortext md:mt-1">
                            {formikDetails.errors.platformType}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {((platformType === "Android" ||
                      platformType === "Android & iOS") && projectType !== "others") && (
                      <div className="flex grid grid-cols-12 mt-5">
                        <div className="grid grid-cols-12 col-span-6">
                          <div className="create-labels col-span-8 mb-1">
                            {appPackageLabel}
                          </div>
                          
                          <TextField
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="text"
                            name="appPackage"
                            id="appPackage"
                            className="col-span-8"
                            autoComplete="off"
                            placeholder="Enter app package"
                            value={formikDetails.values.appPackage?.trimStart()}
                            onChange={formikDetails.handleChange}
                            disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                              props?.editDetails?.projectStatus
                            )|| !isLoading}
                             variant="outlined"
                             size="small"
                          />
                          
                          
                        </div>
                        <div className="grid grid-cols-12 col-span-6">
                          <div className="create-labels col-span-8 mb-1">
                            {appActivityLabel}
                          </div>
                        
                          <TextField
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="text"
                            name="appActivity"
                            id="appActivity"
                            className="col-span-8"
                            autoComplete="off"
                            placeholder="Enter app activity"
                            value={formikDetails.values.appActivity?.trimStart()}
                            onChange={formikDetails.handleChange}
                            disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                              props?.editDetails?.projectStatus
                            )|| !isLoading}
                            variant="outlined"
                            size="small"
                          />
                        
                          
                        </div>
                      </div>
                    )}
                    {((platformType === "iOS" ||
                      platformType === "Android & iOS") && projectType !== "others") && (
                      <div className="mr-5 mt-5">
                        <div>
                          <span className="create-labels">{bundleIdLabel}</span>
                        </div>
                        <div className="mt-0.5">
                        <TextField
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="text"
                          name="bundleID"
                          id="bundleID"
                          autoComplete="off"
                          placeholder="Enter bundle id"
                          value={formikDetails.values.bundleID?.trimStart()}
                          onChange={formikDetails.handleChange}
                          disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                            props?.editDetails?.projectStatus
                          )|| !isLoading}
                          variant="outlined"
                          size="small"
                        />
                        </div>
                       
                      </div>
                    )}
                    {
                      projectType !== "others" && (
                        <>
                        <div className="grid grid-cols-2  mt-2">
                      {platformType === "Android" ||
                      platformType === "Android & iOS" ? (
                        <div
                          style={{ marginTop: "20px" }}
                          className="big-size-file"
                        >
                          <div>
                            <div
                              htmlFor="apk"
                              className="block input-filed-label-style-common"
                            >
                              <span
                                className="create-labels "
                                style={{
                                  position: "relative",
                                }}
                              >
                                Upload Apk
                                <span
                                  id="info"
                                  data-title="Apk"
                                  style={{
                                    position: "absolute",
                                    right: "-30px",
                                    top: "0",
                                  }}
                                >
                                  <img
                                    src="/assets/images/info_icon.svg"
                                    width="15px"
                                    height="15px"
                                    style={{ marginTop: "3px" }}
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                          <FileUploadButton
                            value={
                              selectedApkFile
                                ? selectedApkFile.name
                                : uploadedApkFile
                                ? uploadedApkFile.name
                                : ""
                            }
                            name="apk"
                            id="apk"
                            title=""
                            deleteFile={deleteApk}
                            onChange={(e) => {
                              changeHandler(e);
                              changeHandlerapk(e);
                              setEditPerforms(true);
                            }}
                            showBtn={showAndroidBtn}
                            setShowBtn={setShowAndroidBtn}
                            accept=".apk"
                            className="max-w-lg text-blue-600 block w-24 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 cursor-pointer"
                            disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                              props?.editDetails?.projectStatus
                            )|| !isLoading}
                          />
                          {Para2 && (
                            <div className="flex mt-3 alertDanger_icon">
                              <DangerAlert />
                              <p
                                id="para2  "
                                style={{
                                  color: "black",
                                  fontSize: "12px",
                                  fontFamily: "Poppins-Regular",
                                  marginTop: "2px",
                                  marginLeft: "5px",
                                }}
                              >
                                {Para2}
                              </p>
                            </div>
                          )}
                        </div>
                      ) : null}
                      {platformType === "iOS" ||
                      platformType === "Android & iOS" ? (
                        <div
                          style={{
                            width: "240px",
                            marginTop: "20px",
                            marginLeft:
                              platformType === "Android & iOS" ? "27px" : "0px",
                           
                          }}
                          className="big-size-file"
                        >
                          <div>
                            <div
                              htmlFor="ipa"
                              className="block input-filed-label-style-common"
                            >
                              <span
                                className="create-labels "
                                style={{
                                  position: "relative",
                                }}
                              >
                                Upload Ipa
                                <span
                                  id="info"
                                  data-title="Ipa"
                                  style={{
                                    position: "absolute",
                                    right: "-30px",
                                    top: "0",
                                  }}
                                >
                                  <img
                                    src="/assets/images/info_icon.svg"
                                    width="15px"
                                    height="15px"
                                    style={{ marginTop: "3px" }}
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                          <FileUploadButton
                            value={
                              selectedIpaFile
                                ? selectedIpaFile.name
                                : uploadedIpaFile
                                ? uploadedIpaFile.name
                                : ""
                            }
                            accept=".ipa"
                            title=""
                            name="ipa"
                            showBtn={showIPABtn}
                            setShowBtn={setShowIPAbtn}
                            id="ipa"
                            deleteFile={deleteIpa}
                            onChange={(e) => {
                              onHandleChange(e);
                              changeHandleripa(e);
                              setEditPerforms(true);
                            }}
                            disabled={["INPROGRESS", "APPROVED","CLOSED"].includes(
                              props?.editDetails?.projectStatus
                            )|| !isLoading}
                            className="max-w-lg block w-28 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 cursor-pointer"
                          />
                          {Para && (
                            <div className="flex mt-3 alertDanger_icon">
                              <DangerAlert />
                              <p
                                id="para"
                                style={{
                                  color: "black",
                                  fontSize: "12px",
                                  fontFamily: "Poppins-Regular",
                                  marginTop: "2px",
                                  marginLeft: "5px",
                                }}
                              >
                                {Para}
                              </p>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                        </>
                      )
                    }
                    
                  </div>
                )}
                {projectType === "webservice" && (
                  <div className="flex grid grid-cols-12">
                    <div className=" grid grid-cols-12 col-span-6">
                      <div className="create-labels col-span-8">
                        {baseUriLabel}
                      </div>
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        name="baseURI"
                        id="baseURI"
                        className="col-span-8"
                        autoComplete="off"
                        placeholder="Enter base URI"
                        value={formikDetails.values.baseURI}
                        onChange={formikDetails.handleChange}
                        variant="outlined"
                        size="small"
                        disabled={!isLoading || props?.editDetails?.projectStatus === "CLOSED"}
                      />
                    </div>
                    <div className="grid grid-cols-12 col-span-6">
                      <div className="create-labels col-span-8">
                        {portLabel}
                      </div>
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        name="port"
                        id="port"
                        className="col-span-8"
                        autoComplete="off"
                        placeholder="Enter port number"
                        value={formikDetails.values.port}
                        onChange={formikDetails.handleChange}
                        variant="outlined"
                        size="small"
                        disabled={!isLoading || props?.editDetails?.projectStatus === "CLOSED"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="p-3">
              <div>
                <span className="create-labels">{instructionsLabel}</span>
              </div>
              <div className="mt-0.5">
               
                <TextareaAutosize
                  name="instructions"
                  id="instructions"
                  value={formikDetails.values.instructions}
                  placeholder={instructionsPlaceHolder}
                  onChange={formikDetails.handleChange}
                  style={{ height: "80px",resize: "none",overflow:"scroll"
                 }}
                  onKeyUp={(e) => setInstCount(e.target.value.length)}
                  maxLength="200"
                  className="project-description"
                  disabled={!isLoading || props?.editDetails?.projectStatus === "CLOSED"}
                ></TextareaAutosize>
                <div className="mt-1 mr-1 text-sm text-gray-500 text-right">
                  {instCount}/200
                </div>
              </div>
            </div>
            <div className="p-3">
              <div>
                <span className="create-labels">{supportingDocsLabel}</span>
              </div>
              <div className="flex gap-1">
                <div>
                  <input
                    name="supportDoc"
                    id="supportDoc"
                    type="file"
                    multiple
                    hidden
                    disabled={!isLoading || props?.editDetails?.projectStatus === "CLOSED"}
                    onChange={(e) => {
                      attachmentHandler(e, true);
                    }}
                  />
                  <label htmlFor="supportDoc" className="supportDoc_icon">
                    <AttachFileSharpIcon
                      color="primary"
                      className="cursor-pointer"
                    />
                  </label>
                </div>
                <div>
                  {uiSupportDocsArray.length > 0
                    ? uiSupportDocsArray.map((obj, index) => {
                        return (
                          <div className="grid grid-cols-10 flex gap-1">
                            <div className="text-blue-500  col-span-5 grid grid-cols-10 support_doc_value">
                              <span
                                className="datatrimmer col-span-8"
                                title={supportDocsFileNameHandler(obj.name)}
                              >
                                {supportDocsFileNameHandler(obj.name)}
                              </span>
                            </div>
                            <div className="col-span-2 mr-5 supportDoc_close_icon">
                             
                              <CloseRoundedIcon
                              
                                className="cursor-pointer"
                                color="primary"
                                onClick={() => {
                                 if(isLoading &&  props?.editDetails?.projectStatus !== "CLOSED"){
                                  let tempObj = {
                                    target: {
                                      files: [obj],
                                    },
                                  };
                                  attachmentHandler(tempObj, false);
                                 }
                                }}
                              />
                             
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-2 px-3 flex justify-end gap-4 button-content modal_footer">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
              clearDocArray();
              props.MyAlert.close()
            }}
            type="button"
            className="w-24 gray-btn ml-3 button"
          >
            Cancel
          </button>
          <button
            type="submit"
            id="createBatch"
            className="w-24 primary-btn button"
            disabled={isLoading === false ? true : false}
          >
            {props?.header === "Edit Project" ? "Update" : "Create"}
          </button>
        </div>
        {!isLoading ? <CommonLoader /> : null}
        {statusWarning && (
          <WarningPopup
            mode={"project status"}
            onCloseWarningPopup={onCloseWarningPopup}
            updateProjectStatus={updateProjectStatus}
            fromstatus={props?.editDetails?.projectStatus}
            tostatus={updateStatus[0].value}
          />
        )}
        {addExecutiveReport && (
          <ExectiveReportsPopup
            onCloseWarningPopup={onCloseExecutiveReportPopUp}
            projectDetails={props?.editDetails}
            updateExecutiveReport={updateExecutiveReport}
          />
        )}
      </form>
    </Modal>
  );
}

export default ProjectCreate;
