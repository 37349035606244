import React, { useState, useEffect } from "react";
import "./client-list.scss";
import Carousel, { consts } from "react-elastic-carousel";
import { ReactComponent as MacIcon } from "../../assets/platform/mac.svg";
import { ReactComponent as AndroidIcon } from "../../assets/platform/android.svg";
import { Tooltip } from "@mui/material";
import { ReactComponent as LeftArrow } from "../../assets/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../assets/right-arrow.svg";
import { ReactComponent as Blackberry } from "../../assets/allMobileLogo/os-blackberry-svgrepo-com (1).svg";
import { ReactComponent as Bada } from "../../assets/allMobileLogo/Samsung-Bada.svg";
import { ReactComponent as Palm } from "../../assets/allMobileLogo/palm-os-5.svg";
import { ReactComponent as Symbian } from "../../assets/allMobileLogo/cdnlogo.com_symbian-os (1).svg";
const MobileOsContent = (props) => {
  const [allOsInfo, setAllOsInfo] = useState(props?.allOsInfo);

  useEffect(() => {
    setAllOsInfo(props?.allOsInfo);
  }, [props?.allOsInfo]);
  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <div
          className={
            isEdge
              ? "invisible "
              : "visible customizedLeftArrow relative left-6"
          }
        >
          <LeftArrow height="22px" width="22px" />
        </div>
      ) : (
        <div
          className={
            isEdge
              ? "invisible "
              : "visible customizedRightArrow relative -left-6 "
          }
        >
          {" "}
          <RightArrow height="22px" width="22px" />
        </div>
      );
    return (
      <button
        onClick={() => {
          onClick();
        }}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  }

  function selectedBrowser(browsertype) {
    let osName = browsertype.toLowerCase();
    switch (osName) {
      case "ios":
        return <MacIcon />;
      case "ipados":
        return <MacIcon />;

      case "android":
        return <AndroidIcon />;
        
      case "blackberry os":
        return <Blackberry height="35px" width="30px" />;
        case "bada":
          return <Bada height="35px" width="30px" />;
          case "palm":
          return <Palm height="35px" width="30px" />;
          case "symbian":
        return <Symbian height="35px" width="30px" />;

      default:
        return <div className="datatrimmer w-10">{osName?.split(/\s(.+)/)[0]}</div>;
    }
  }

  return (
    <div className="mobile_versions_container">
      <div className="flex client_content_headings">
        {props?.mobileInfo === undefined ? (
          <>
            <p className="mt-1">
              {/* <Windows height="16px" width="16px" /> */}
            </p>
            <p className="mx-2 content_subheadings">
              <span className=" cursor-pointer">All</span> OS & OS Versions
            </p>
          </>
        ) : (
          <>
            {/* <p className="mt-1">
              <p className="flex">
                {" "}
                <span>{selectedBrowser(props?.mobileInfo?.deviceType)}</span>{" "}
                <span className="osName_browserVersion mx-1">
                  {props?.mobileInfo?.deviceType}
                </span>
              </p>
            </p> */}
            <p className="mx-1 content_subheadings">
              {/* <span className=" cursor-pointer">
                {props?.mobileInfo?.deviceName ?props?.mobileInfo?.deviceName:props?.mobileInfo?.deviceBrand}
              </span>{" "} */}
              &nbsp;OS & OS Versions
            </p>
          </>
        )}
      </div>
      <div className="mt-2">
        <div className="tableHead_wrapper">
          <table className=" text-left">
            <thead>
              <tr style={{background:"#F2F2F2",height:"30px"}}>
                <th className="labels w-1/6">Defects</th>
                <th className="labels w-1/6">OS</th>
                <th className="labels">OS Versions</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="tableBody_wrapper_mobile">
          <table className=" text-left">
            <tbody className="mt-2">
              {allOsInfo?.map((value) => {
                return (
                  <tr
                    className={
                      value?.osName === "Android"
                        ? "hover:bg-greenCustom-400"
                        :  value?.osName === "Bada"
                        ?"hover:bg-blue-100"
                        :  value?.osName === "Blackberry OS"
                        ?"hover:bg-gray-400"
                        : " hover:bg-neutral-300"
                    }
                    style={{ border:"1px solid #E5E5E5",margin:"4px 0px"}}
                  >
                    <td className="DefectValue_mob_os w-1/6 ">
                      {value?.defectCount}
                    </td>
                    <Tooltip
                      PopperProps={{
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            border: "1px solid #434DB8",
                            color: "deepskyblue",
                            background: " #FFFFFF 0% 0% no-repeat padding-box",
                            width: "95px",
                            height: "auto",
                            borderRadius: " 7px",
                            opacity: 1,
                            textAlign: "center",
                            boxShadow: "0px 3px 6px #0000005E",
                          },
                        },
                      }}
                      
                      title={
                                  <div className="text-xs">
                                   {value?.osName}:{value?.defectCount}
                                    <br />
                                    <span>
                                    { value.totalDefectPercent}
                                      %
                                    </span>
                                  </div>
                                }
                      placement="top"
                    >
                      <td className=" w-1/6 cursor-pointer">
                        {selectedBrowser(value?.osName)}{" "}
                      </td>
                    </Tooltip>
                    <td
                      className="browserVersionList flex "
                      style={{
                        width: "113%",
                        position: "relative",
                        left: " -31px",
                      }}
                    >
                      <>
                        {props?.mobileInfo === undefined ? (
                          <Carousel
                            renderArrow={myArrow}
                            itemsToShow={5}
                            pagination={false}
                          >
                            {value?.osVersionList?.map((version) => {
                              return (
                                <>
                                  <Tooltip
                                    PopperProps={{
                                      sx: {
                                        "& .MuiTooltip-tooltip": {
                                          border: "1px solid #434DB8",
                                          color: "deepskyblue",
                                          background:
                                            " #FFFFFF 0% 0% no-repeat padding-box",
                                          width: "118px",

                                          height: "auto",
                                          borderRadius: " 10px",
                                          opacity: 1,
                                          textAlign: "center",
                                          boxShadow: "0px 3px 6px #0000005E",
                                        },
                                      },
                                    }}
                                    title={
                                      <div className="text-xs">
                                        {version.osVersion}:
                                        {version.defectCount}
                                        <br />
                                        <span>
                                          {version.totalDefectPercent}%
                                        </span>
                                      </div>
                                    }
                                    placement="top"
                                  >
                                    <div
                                      className={
                                        value?.osName?.toLowerCase() ===
                                        "android"
                                          ? "color-palette__item_android "
                                          : value?.osName?.toLowerCase() ===
                                            "ios"
                                          ? "color-palette__item_ios"
  
                                          :value?.osName?.toLowerCase() === "blackberry os"
                                          ?"color-palette__item_blackberryOs"
                                          : "color-palette__item_chrome"
                                      }
                                      onClick={()=>{console.log(value )}}
                                    >
                                      {version.osVersion?.substring(0, 6)}
                                    </div>
                                  </Tooltip>
                                </>
                              );
                            })}
                          </Carousel>
                        ) : (
                          <>
                            {props?.mobileInfo?.osVersionList?.length ===
                            value?.osVersionList?.length ? (
                              <Carousel
                                renderArrow={myArrow}
                                itemsToShow={5}
                                pagination={false}
                              >
                                {value?.osVersionList?.map((version) => {
                                  return (
                                    <>
                                      <Tooltip
                                        PopperProps={{
                                          sx: {
                                            "& .MuiTooltip-tooltip": {
                                              border: "1px solid #434DB8",
                                              color: "deepskyblue",
                                              background:
                                                " #FFFFFF 0% 0% no-repeat padding-box",
                                              width: "118px",
                                              height: "auto",
                                              borderRadius: " 10px",
                                              textAlign: "center",
                                              opacity: 1,
                                              boxShadow:
                                                "0px 3px 6px #0000005E",
                                            },
                                          },
                                        }}
                                        title={
                                          <div className="text-xs">
                                            {version.osVersion}:
                                            {version.defectCount}
                                            <br />
                                            <span>
                                              {version.totalDefectPercent}%
                                            </span>
                                          </div>
                                        }
                                        placement="top"
                                      >
                                        <div
                                          className={
                                            value?.osName === "android"
                                              ? "color-palette__item_android "
                                              : value?.osName === "ios"
                                              ? "color-palette__item_ios"
                                              : value?.browserName === "Opera"
                                              ? "color-palette__item_opera"
                                              : "color-palette__item_chrome"
                                          }
                                        >
                                          {version.osVersion?.substring(0, 6)}
                                        </div>
                                      </Tooltip>
                                    </>
                                  );
                                })}
                              </Carousel>
                            ) : (
                              <Carousel
                                renderArrow={myArrow}
                                itemsToShow={5}
                                pagination={false}
                              >
                                {value?.osVersionList?.map((version) => {
                                  return (
                                    <>
                                      <Tooltip
                                        PopperProps={{
                                          sx: {
                                            "& .MuiTooltip-tooltip": {
                                              border: "1px solid #434DB8",
                                              color: "deepskyblue",
                                              background:
                                                " #FFFFFF 0% 0% no-repeat padding-box",
                                              width: "118px",
                                              height: "auto",
                                              borderRadius: " 10px",
                                              textAlign: "center",
                                              opacity: 1,
                                              boxShadow:
                                                "0px 3px 6px #0000005E",
                                            },
                                          },
                                        }}
                                        title={
                                          <div className="text-xs">
                                            {version.osVersion}:
                                            {version.defectCount}
                                            <br />
                                            <span>
                                              {version.totalDefectPercent}%
                                            </span>
                                          </div>
                                        }
                                        placement="top"
                                      >
                                        <div
                                          className={
                                            value?.osName === "android"
                                              ? "color-palette__item_android "
                                              : value?.osName === "ios"
                                              ? "color-palette__item_ios"
                                              : value?.browserName === "Opera"
                                              ? "color-palette__item_opera"
                                              : "color-palette__item_chrome"
                                          }
                                        >
                                          {version.osVersion?.substring(0, 6)}
                                        </div>
                                      </Tooltip>
                                    </>
                                  );
                                })}
                              </Carousel>
                            )}
                          </>
                        )}
                      </>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="osName_OsVersion datatrimmer mx-1">
          {props?.mobileInfo?.deviceName
            ? props?.mobileInfo?.deviceName
            : props?.mobileInfo?.deviceBrand}{" "}
        </div>
        <div className="totalOSDefect_Mobile">
          {/* {props?.mobileInfo?.deviceType}  */}
          Total Defects : {props?.webInfo?.osVersion}{" "}
          <span className="mx-1">
            {props?.mobileInfo
              ? props?.mobileInfo?.defectCount
              : props?.deviceInfo?.totalOsDefects}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MobileOsContent;
